import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

const stylesFn = () => ({
  tablePagination: {
    fontSize: '12px',
  },
  // tableRowRoot: {
  //   transition: 'max-height 0.5s ease-in-out'
  // }
});

const CustomTableBase = ({
  classes,
  tableContainerClass,
  tableHeads,
  tableRows,
  rowsPerPageOptions,
  shouldShowRowsPerPageOptions,
  colgroup,
  shouldAddfixedTable,
  verticalAlign,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);

  const onChangePage = (event, newPage) => setPage(newPage);

  const onChangeRowsPerPage = event => setRowsPerPage(+event.target.value);

  const tableRowsInThisPage = shouldShowRowsPerPageOptions
    ? tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : tableRows;

  useEffect(() => {
    if (tableRowsInThisPage.length === 0 && page > 0) {
      setPage(p => Math.max(p - 1, 0));
    }
  }, [tableRowsInThisPage, page]);

  const calcCellExtraStyle = tableRow => {
    const styleObj = {};
    if (tableRow.isMarkedRow) {
      styleObj.backgroundColor = '#fff8de';
    }
    if (verticalAlign) {
      styleObj.verticalAlign = 'unset';
    }
    return styleObj;
  };

  return (
    <React.Fragment>
      <div className={tableContainerClass}>
        <MaterialTable
          size="small"
          cellPadding={0}
          cellSpacing={0}
          style={shouldAddfixedTable ? { tableLayout: 'fixed' } : null}
        >
          {// eslint-disable-next-line no-unneeded-ternary
          colgroup ? colgroup : null}
          {tableHeads.length > 0 && (
            <TableHead>
              <TableRow>
                {tableHeads.map(tableHead => (
                  <TableCell key={tableHead.id}>{tableHead.node}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {tableRowsInThisPage.map(tableRow => (
              <TableRow
                key={tableRow.id}
                hover
                onClick={tableRow.onClick || (() => {})}
                style={
                  tableRow.maxHeight ? { maxHeight: tableRow.maxHeight } : null
                }
                // classes={{
                //   root: classes.tableRowRoot,
                // }}
              >
                {tableRow.cells.map(cell => (
                  <TableCell key={cell.id} style={calcCellExtraStyle(tableRow)}>
                    {cell.node}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </div>

      {shouldShowRowsPerPageOptions &&
        tableRows.length > rowsPerPageOptions[0] && (
          <div>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={tableRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              classes={{
                caption: classes.tablePagination,
                menuItem: classes.tablePagination,
                input: classes.tablePagination,
              }}
            />
          </div>
        )}
    </React.Fragment>
  );
};

CustomTableBase.defaultProps = {
  rowsPerPageOptions: [5, 10, 25],
  shouldShowRowsPerPageOptions: true,
};

CustomTableBase.propTypes = {
  classes: PropTypes.object.isRequired,
  tableContainerClass: PropTypes.string,
  tableHeads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      node: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          node: PropTypes.node.isRequired,
        })
      ).isRequired,
    }).isRequired
  ).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  shouldShowRowsPerPageOptions: PropTypes.bool,
  colgroup: PropTypes.node,
  shouldAddfixedTable: PropTypes.bool,
  verticalAlign: PropTypes.bool,
};

export const CustomTable = withStyles(stylesFn)(React.memo(CustomTableBase));
CustomTable.displayName = 'CustomTable';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import { Chip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { EasierCustomTable } from '../../../../Core/Components/CustomTable/EasierCustomTable';
import { DAY_JS_DATE_FORMATS } from '../../../../utils/constants';
import styles from './DoneAssessmentsTable.scss';
// import { useTranslation } from '../../../../Core/hooks/useTranslation';
import { paragraphFontSize } from '../../../../cssInJs/constants';

const DoneAssessmentsTableBase = ({
  doneAssessments,
  onDeleteAssessment,
  isNonf,
  symptomsTrackingAnswers,
  // assessmentVersion,
}) => {
  const { customerId } = sessionStorage;
  const rawTableHeads = ['#', 'Date', 'Type', 'Done After', 'Action'];

  if (isNonf) {
    rawTableHeads[2] = '';
  }

  const colsKeys = rawTableHeads;
  const tableHeadsRow = rawTableHeads.map(content => <span>{content}</span>);

  const rowsKeys = Object.keys(doneAssessments);

  const viewAssessment = useCallback(
    assessmentId => {
      window.open(
        `assessment.html?customerId=${customerId}&&assessmentId=${assessmentId}`
      );
    },
    [customerId]
  );

  const onAssessmentRowClick = useCallback(rowId => viewAssessment(rowId), [
    viewAssessment,
  ]);

  const calcType = (assessmentId, assessment) => {
    const doneQuestionnaries = !!Object.values(symptomsTrackingAnswers).find(
      ansObj => ansObj.assessmentId === assessmentId
    );
    if (doneQuestionnaries && assessment.cpt && assessment.eeg) {
      return 'Full';
    }
    if (doneQuestionnaries && assessment.cpt) {
      return 'Questionnaires + CPT';
    }
    if (doneQuestionnaries && assessment.eeg) {
      return 'Questionnaires + EEG';
    }
    if (assessment.cpt && assessment.eeg) {
      return 'CPT + EEG';
    }
    if (assessment.cpt) {
      return 'CPT';
    }
    if (assessment.eeg) {
      return 'EEG';
    }
    if (doneQuestionnaries) {
      return 'Questionnaires';
    }
    return '';
  };

  const rows = Object.entries(doneAssessments).map(
    ([assessmentId, assessment], i) => {
      const minsUsageWhenDoneFormatted = parseInt(
        assessment.minsUsageWhenDone,
        10
      );
      return [
        <span>{Object.entries(doneAssessments).length - i}</span>,
        <span>
          {dayJS(assessment.endTimestamp).format(
            DAY_JS_DATE_FORMATS.americanWithTime
          )}
        </span>,
        <span>{calcType(assessmentId, assessment)}</span>,
        !isNonf ? (
          <span>
            {`${minsUsageWhenDoneFormatted}${
              assessment.minsUsageWhenDone === 1
                ? ' (minute of neurofeedback)'
                : ' (minutes of neurofeedback)'
            }`}
          </span>
        ) : (
          <span />
        ),
        <span className={styles.actions_container}>
          <span>
            <a
              href="#"
              onClick={event =>
                event.preventDefault() ||
                event.stopPropagation() ||
                onDeleteAssessment(assessmentId)
              }
            >
              <DeleteIcon style={{ color: '#818181' }} />
            </a>
            {assessment.isBaseline && (
              <Chip
                label={
                  <span style={{ fontSize: paragraphFontSize }}>Baseline</span>
                }
                color="primary"
              />
            )}
          </span>
        </span>,
      ];
    }
  );

  return (
    <div className={styles.root}>
      <EasierCustomTable
        rows={[tableHeadsRow, ...rows]}
        colsKeys={colsKeys}
        rowsKeys={rowsKeys}
        onRowClick={onAssessmentRowClick}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </div>
  );
};

DoneAssessmentsTableBase.propTypes = exact({
  doneAssessments: PropTypes.object.isRequired,
  onDeleteAssessment: PropTypes.func.isRequired,
  isNonf: PropTypes.bool.isRequired,
  symptomsTrackingAnswers: PropTypes.object.isRequired,
  // assessmentVersion: PropTypes.string,
});

export const DoneAssessmentsTable = React.memo(DoneAssessmentsTableBase);
DoneAssessmentsTable.displayName = 'DoneAssessmentsTable';

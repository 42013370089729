import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
// import FlipMove from 'react-flip-move';
import HelpIcon from '@material-ui/icons/Help';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import styles from './DeployedProgramsStep.scss';
import { AddAdditionalProgramCTA } from './AddAdditionalProgramCTA/AddAdditionalProgramCTA';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { DeployedProgramCard } from '../../DeployedProgramCard/DeployedProgramCard';
import { CustomSelect } from '../../../Core/Components/CustomSelect/CustomSelect';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';

const DeployedProgramsStepBase = ({
  programs,
  onAddProgram,
  order,
  onOrderChange,
  orderOptions,
  // shouldNotifyByEmail,
  // onNotifyByEmailChange,
  onChangeProgramName,
  onCancelProgram,
  onEditProgram,
  shouldShowAddProgram,
  programNameMaxLength,
  longProgramNameErrorMsg,
  warningMsg,
  onChangeItemOrder,
  patientId,
  expiration,
  resetExpiration,
  onExpirationChange,
  expirationOptions,
  onDateSelected,
  deployedProgramsExpirationTimestamp,
  isCalendarFocused,
  setIsCalendarFocused,
  autoDeployableConfigs,
  clinicExpirationEnabled,
}) => {
  const [programNameErrorMsg, setProgramNameErrorMsg] = useState(null);

  // Styling date picker
  useEffect(() => {
    const el1 = document.getElementsByClassName('SingleDatePickerInput');
    const el2 = document.getElementsByClassName('DateInput_input');
    if (el1 && el1[0] && el2 && el2[0]) {
      el1[0].style.paddingTop = '3px';
      el1[0].style.paddingBottom = '3px';
      el1[0].style.borderRadius = '4px';
      el2[0].style.fontSize = '14px';
      el2[0].style.fontWeight = 400;
      el2[0].style.borderBottom = 0;
      if (moment(deployedProgramsExpirationTimestamp) < Date.now()) {
        el1[0].style.backgroundColor = '#feddcf';
        el2[0].style.backgroundColor = '#feddcf';
        el2[0].style.color = '#f94944';
      } else {
        el1[0].style.backgroundColor = 'white';
        el2[0].style.backgroundColor = 'white';
        el2[0].style.color = 'unset';
      }
    }
  }, [deployedProgramsExpirationTimestamp, expiration]);

  useEffect(() => {
    if (!deployedProgramsExpirationTimestamp && !isCalendarFocused) {
      resetExpiration(false);
    }
  }, [deployedProgramsExpirationTimestamp, isCalendarFocused]);

  const [status] = useFirebaseDB({
    path: `/deployedSession/${sessionStorage.userId}/${patientId}/status/state`,
  });

  const [roundsStatus] = useFirebaseDB({
    path: `/trainingSessions/${patientId}`,
  });

  const [presence] = useFirebaseDB({
    path: `userInfo/${patientId}/presence`,
  });

  let selectedDate = null;
  if (deployedProgramsExpirationTimestamp) {
    selectedDate = moment(deployedProgramsExpirationTimestamp);
  }

  return (
    <div className={styles.deployed_programs}>
      {warningMsg && (
        <div className={styles.custom_alert}>
          <CustomAlert severity="warning">{warningMsg}</CustomAlert>
        </div>
      )}
      <div
        className={styles.inputs_container}
        style={
          autoDeployableConfigs || !clinicExpirationEnabled
            ? { justifyContent: 'end' }
            : null
        }
      >
        {programs.length > 0 && (
          <div className={styles.input_container}>
            <div
              className={styles.input_container_label}
              style={{ marginRight: 10, fontWeight: 500 }}
            >
              Client’s access to programs:
            </div>
            <CustomSelect
              selectedOption={order}
              onChange={onOrderChange}
              allOptions={orderOptions}
            />
            {autoDeployableConfigs || !clinicExpirationEnabled ? null : (
              <div
                className={styles.input_container_label}
                style={{ marginRight: 10, marginLeft: 10, fontWeight: 500 }}
              >
                until
              </div>
            )}

            {// eslint-disable-next-line no-nested-ternary
            autoDeployableConfigs ||
            !clinicExpirationEnabled ? null : expiration === 'CUSTOM' ||
              expiration === 'NEXT_BC' ? (
              <SingleDatePicker
                date={selectedDate}
                onDateChange={date => {
                  onDateSelected({ date });
                }}
                focused={isCalendarFocused}
                onFocusChange={({ focused }) => {
                  setIsCalendarFocused(focused);
                }}
                id="single_date_picker"
                showClearDate
                numberOfMonths={1}
                isOutsideRange={day => {
                  const today = new Date();
                  const oneYearFromNow = new Date();
                  oneYearFromNow.setFullYear(today.getFullYear() + 1);
                  return (
                    day.isAfter(oneYearFromNow) ||
                    day.isBefore(today) ||
                    day.isSame(today, 'day')
                  );
                }}
                readOnly
              />
            ) : (
              <CustomSelect
                selectedOption={expiration}
                onChange={onExpirationChange}
                allOptions={expirationOptions}
                style={{ maxWidth: 160, minWidth: 162 }}
              />
            )}

            <div className={styles.tooltip_wrapper}>
              <TooltipWrapper
                title={
                  <TooltipTitle>
                    <p>
                      In Sequential mode, the app will require the user to
                      complete all sessions in program A, and only then move to
                      the next program.
                    </p>
                    <p>
                      In Alternating mode, the app will require the user to run
                      a session from program A, then a session from program B,
                      then a session from A, then B, etc…
                    </p>
                    <p>
                      In User Choice mode, the user will be free to choose their
                      training program as they wish.
                    </p>
                    {autoDeployableConfigs ||
                    !clinicExpirationEnabled ? null : (
                      <p style={{ margin: 0 }}>
                        When a date is selected, the client will lose access to
                        their training on that date at 12:00 AM in your local
                        time zone.
                      </p>
                    )}
                  </TooltipTitle>
                }
              >
                <HelpIcon />
              </TooltipWrapper>
            </div>
          </div>
        )}
      </div>
      <div className={styles.programs}>
        {/* <FlipMove> */}
        {programs.map((program, index) => (
          <Paper
            className={styles.deployed_program_paper}
            key={program.id}
            style={{ marginBottom: 20 }}
          >
            <DeployedProgramCard
              deployedProgram={program}
              onChangeProgramName={name =>
                onChangeProgramName(name, program.id)
              }
              onCancelProgram={() => onCancelProgram(program.id)}
              onEditProgram={() => onEditProgram(program.id)}
              validateProgramName={name => {
                let newProgramNameErrorMsg = null;
                if (name.trim() === '') {
                  newProgramNameErrorMsg =
                    'Program name should not be an empty string';
                } else if (name.length > programNameMaxLength) {
                  newProgramNameErrorMsg = longProgramNameErrorMsg;
                }
                setProgramNameErrorMsg(newProgramNameErrorMsg);
                return newProgramNameErrorMsg === null;
              }}
              programNameErrorMsg={programNameErrorMsg}
              onChangeItemOrder={onChangeItemOrder}
              numOfPrograms={programs.length}
              index={index}
              status={status}
              presence={presence}
              roundsStatus={roundsStatus}
              isExpired={selectedDate && selectedDate < Date.now()}
            />
          </Paper>
        ))}
        {/* </FlipMove> */}
      </div>

      {shouldShowAddProgram && (
        <div>
          <AddAdditionalProgramCTA onAddProgram={onAddProgram} />
        </div>
      )}
    </div>
  );
};

DeployedProgramsStepBase.propTypes = exact({
  programs: PropTypes.array.isRequired,
  onAddProgram: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  orderOptions: PropTypes.array.isRequired,
  // shouldNotifyByEmail: PropTypes.bool.isRequired,
  // onNotifyByEmailChange: PropTypes.func.isRequired,
  onChangeProgramName: PropTypes.func.isRequired,
  onCancelProgram: PropTypes.func.isRequired,
  onEditProgram: PropTypes.func.isRequired,
  shouldShowAddProgram: PropTypes.bool.isRequired,
  programNameMaxLength: PropTypes.number.isRequired,
  longProgramNameErrorMsg: PropTypes.string.isRequired,
  warningMsg: PropTypes.string,
  onChangeItemOrder: PropTypes.func,
  patientId: PropTypes.string,
  expiration: PropTypes.string,
  resetExpiration: PropTypes.func,
  onExpirationChange: PropTypes.func,
  expirationOptions: PropTypes.array,
  onDateSelected: PropTypes.func,
  isCalendarFocused: PropTypes.bool,
  setIsCalendarFocused: PropTypes.func,
  autoDeployableConfigs: PropTypes.bool,
  deployedProgramsExpirationTimestamp: PropTypes.string,
  clinicExpirationEnabled: PropTypes.bool,
});

export const DeployedProgramsStep = React.memo(DeployedProgramsStepBase);
DeployedProgramsStep.displayName = 'DeployedProgramsStep';

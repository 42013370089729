import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './WhiteLabelingPopOut.scss';
// import { FeatureChip } from '../../Core/Components/FeatureChip/FeatureChip';
// import whiteLabelingImage from '../../../assets/white-labeling-prompt.png';
import brandingImage from '../../../assets/branding.png';
import appIcon from '../../../assets/app-icon.png';
import emailIcon from '../../../assets/email-icon.png';
import reportIcon from '../../../assets/report-icon.png';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { LogoDialog } from './LogoDialog';

const InstructionsParagraph = ({ clinicNonfPlan }) => (
  // <p>
  //   Your logo needs to be sent to{' '}
  //   {shouldLinkMail ? (
  //     <a href="mailto:support@myndlift.com">support@myndlift.com</a>
  //   ) : (
  //     'support@myndlift.com'
  //   )}{' '}
  //   and will be deployed in the app and dashboard within 48 hours.
  // </p>
  <p style={{ marginTop: 40, fontWeight: 300 }}>
    {clinicNonfPlan
      ? 'This feature is automatically enabled for Premium Plan users.'
      : 'This feature is automatically enabled for Scale Plan users.'}
  </p>
);

InstructionsParagraph.propTypes = exact({
  clinicNonfPlan: PropTypes.bool.isRequired,
});

const WhiteLabelingPopOutBase = ({
  isOpen,
  onClose,
  shouldPromptWhiteLabelingFeature,
}) => {
  const [isLogoDialogOpen, setIsLogoDialogOpen] = useState(false);
  const [prevSelectImage, setPrevSelectImage] = useState(null);

  const onUpgradeToScale = useCallback(async () => {
    await new MyEvent('on_white_labeling_pop_out_upgrade_to_scale_click').log();
    window.open('/new_account.html', '_self');
  }, []);

  const t = useTranslation();

  const onImageFileChange = async event => {
    if (event.target.files.length) {
      const imgId = Math.random()
        .toString(36)
        .substring(7);
      const path = `app_logo/${sessionStorage.userId}/${imgId}`;
      const [file] = event.target.files;
      const storage = window.firebase.storage();
      const imageRef = storage.ref(path);
      try {
        await imageRef.put(file);
        // setImgPrev(file);
        // const logoSettingsData = {...logoSettingsDefaultData};
        // logoSettingsData.logoName = imgId;
        // database.ref(`appLogoSettings/${sessionStorage.userId}`).set(logoSettingsData);
        imageRef
          .getDownloadURL()
          .then(res => {
            setPrevSelectImage(res);
            document.getElementById('image-input-1').value = null;
            setIsLogoDialogOpen(true);
          })
          .catch(() => {});
      } catch (err) {
        // TODO
        // console.log(err);
      }
    }
  };

  const [clinicNonfPlan] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}`,
  });

  return (
    <Dialog
      classes={{ paper: styles.dialog_paper_root }}
      open={isOpen}
      onClose={onClose}
    >
      <div className={styles.root}>
        <Button onClick={onClose} style={{ position: 'absolute', top: '10px' }}>
          <CloseIcon />
        </Button>
        <div>
          <div className={styles.content}>
            <div className={styles.header_content}>
              <h2>{t('add-your-branding-to-myndlift-app')}</h2>

              <p>{t('let-your-users-get-full-experience')}</p>
            </div>

            <h3 className={styles.logo_title}>
              {t('your-logo-will-appear-on')}
            </h3>

            <div className={styles.features}>
              <div>
                <img src={appIcon} style={{ maxWidth: 19 }} />
                <p>{t('relevant-myndlift-screens')}</p>
              </div>
              <div>
                <img src={reportIcon} style={{ maxWidth: 19 }} />
                <p>{t('reports-shared-with-clients')}</p>
              </div>
              <div>
                <img src={emailIcon} style={{ maxWidth: 22 }} />
                <p>{t('email-from-myndlift-app')}</p>
              </div>
            </div>

            <p className={styles.note}>
              <span>*Note</span>: app icon cannot be changed
            </p>

            {/* <div className={styles.divider} /> */}

            {shouldPromptWhiteLabelingFeature ? (
              <React.Fragment>
                {/* <div className={styles.feature_chip_container}>
                  <FeatureChip featureName="Scale Feature" />
                </div> */}

                <div className={styles.footer_content}>
                  <InstructionsParagraph clinicNonfPlan={clinicNonfPlan} />
                </div>
              </React.Fragment>
            ) : (
              <div
                className={styles.instructions}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 80,
                }}
              >
                {/* <InstructionsParagraph shouldLinkMail /> */}
                <Button
                  onClick={() => {
                    // setIsLogoDialogOpen(true);
                    document.getElementById('image-input-1').click();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ fontSize: 16 }}
                >
                  UPLOAD YOUR LOGO
                </Button>
                <input
                  accept="image/png, image/gif, image/jpeg"
                  hidden
                  id="image-input-1"
                  type="file"
                  onChange={onImageFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            )}
          </div>

          {shouldPromptWhiteLabelingFeature && (
            <div className={styles.button_container}>
              <Button
                variant="contained"
                color="primary"
                onClick={onUpgradeToScale}
              >
                <span className={styles.btn}>{t('upgrade-to-scale')}</span>
              </Button>
            </div>
          )}
        </div>

        <div className={styles.image_container}>
          <img src={brandingImage} />
        </div>
      </div>
      <LogoDialog
        isOpen={isLogoDialogOpen}
        onClose={() => {
          setIsLogoDialogOpen(false);
        }}
        closeOuterPopup={onClose}
        prevSelectImage={prevSelectImage}
      />
    </Dialog>
  );
};

WhiteLabelingPopOutBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  shouldPromptWhiteLabelingFeature: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
});

export const WhiteLabelingPopOut = withEntryComponent(
  React.memo(WhiteLabelingPopOutBase)
);
WhiteLabelingPopOut.displayName = 'WhiteLabelingPopOut';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles from './CustomAlert.scss';

// The Custom Alert API should match the Alert component api in material ui
// I could not use that now because its introduced in newer versions in material ui
// But making the api the same, makes it easier later to replace this component with the native material ui component
const CustomAlertBase = ({
  children,
  severity,
  styleToAdd,
  showIcon = true,
}) => {
  const severityIcons = {
    warning: WarningIcon,
    info: InfoIcon,
    error: ErrorIcon,
    success: CheckCircleIcon,
  };
  const Icon = severityIcons[severity];
  return (
    <div
      className={classNames(styles.root, styles[severity])}
      style={styleToAdd}
    >
      {showIcon ? (
        <Icon color="inherit" style={{ fontSize: 22, marginRight: 12 }} />
      ) : null}
      <p>{children}</p>
    </div>
  );
};

CustomAlertBase.propTypes = exact({
  children: PropTypes.node.isRequired,
  severity: PropTypes.string.isRequired,
  styleToAdd: PropTypes.object,
  showIcon: PropTypes.bool,
});

export const CustomAlert = React.memo(CustomAlertBase);
CustomAlert.displayName = 'CustomAlert';

import React, { useState, useCallback, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { useHistory } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Button,
  Checkbox,
  DialogContent,
  Input,
  TextField,
  Popover,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import styles from './LagoControl.scss';
import { LagoListItem } from '../LagoListItem/LagoListItem';
import { LagoPreview } from '../LagoPreview/LagoPreview';
import { LagoEditScreenDialog } from '../LagoEditScreenDialog/LagoEditScreenDialog';
import { LagoUploadedImages } from '../LagoUploadedImages/LagoUploadedImages';
import placeHolder from '../../../../assets/add-photo.png';
import target from '../../../../assets/target.png';
import time from '../../../../assets/time.png';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { LagoVariants } from '../LagoVariants/LagoVariants';
import { LagoGoalConfig } from '../LagoGoalConfig/LagoGoalConfig';
import { LagoJournalDialog } from '../LagoJournalDialog/LagoJournalDialog';
import { LagoSuccessThresholds } from '../LagoSuccessThresholds/LagoSuccessThresholds';
import { LagoCheckins } from '../LagoCheckins/LagoCheckins';
import { LagoCollectionDialog } from '../LagoCollectionDialog/LagoCollectionDialog';
import { LagoArticleLessonDialog } from '../LagoArticleLessonDialog/LagoArticleLessonDialog';
import { LagoCollectionJournalDialog } from '../LagoCollectionJournalDialog/LagoCollectionJournalDialog';
import { LagoCategories } from '../LagoCategories/LagoCategories';
// import { useClinicInfo } from '../../../Core/hooks/useClinicInfo';
// import { LagoNavigator } from '../LagoNavigator/LagoNavigator';

const lagoEventLogger = new MyEventWrapper('lago');

const LagoControlBase = () => {
  const [isConnected] = useFirebaseDB({
    path: '.info/connected',
  });

  // const clinicInfo = useClinicInfo();

  const screenStages = ['Main', 'Journey', 'Mini-course', 'Module', 'Lesson'];
  const screenStagesSubKey = [
    null,
    'minicourses',
    'modules',
    'lessons',
    'screens',
  ];
  const [screenStage, setScreenStage] = useState(screenStages[0]);
  const currentStage = screenStages.indexOf(screenStage);
  const nextStage =
    currentStage === screenStages.length - 1
      ? 'Screen'
      : screenStages[currentStage + 1];

  const [dataPath, setDataPath] = useState(
    'lago/educational/defaults/journeys'
  );
  const [dataSubPath, setDataSubPath] = useState(
    'lago/educational/defaults/journeys'
  );

  const MAIN_INDEX = 0;
  const JOURNEY_INDEX = 1;
  const MINI_COURSE_INDEX = 2;
  const MODULE_INDEX = 3;
  const LESSON_INDEX = 4;

  const PRESET_SCREEN_INDEX = 10000;

  const DEFAULT_ESTIMATED_MINUTES = 4;

  const IMAGE_PATH_PREFIX = 'lago/educational/defaults/images/';
  const VIDEO_PATH_PREFIX = 'lago/educational/defaults/videos/';

  const history = useHistory();

  const introScreenOptions = ['intro', 'journeys', 'collections'];

  const [introScreen, setIntroScreen] = useState(introScreenOptions[0]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemHistory, setSelectedItemHistory] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [addedItemTitle, setAddedItemTitle] = useState('');
  const [isScreenEditor, setIsScreenEditor] = useState(false);
  const [isVariantEditor, setIsVariantEditor] = useState(false);
  const [isNewVariant, setIsNewVariant] = useState(false);
  const [isFromEditModal, setIsFromEditModal] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [estimatedMins, setEstimatedMins] = useState(0);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [displayUploadedImages, setDisplayUploadedImages] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState('');
  const [displayVariantDialog, setDisplayVariantDialog] = useState(false);
  const [variantToEdit, setVariantToEdit] = useState('');
  // SCREEN TYPES
  // html
  const [htmlText, setHtmlText] = useState('');
  const [htmlBtnValue, setHtmlBtnValue] = useState('');
  const [htmlImgPath, setHtmlImgPath] = useState('');
  // video
  const [videoPrev, setVideoPrev] = useState('');
  const [fileSizeError, setFileSizeError] = useState(false);
  // quiz
  const [quizType, setQuizType] = useState('exercise');
  const [quizTextQuestion, setQuizTextQuestion] = useState(
    EditorState.createEmpty()
  );
  const [quizCoises, setQuizCoises] = useState([]);
  const [quizNewCoise, setQuizNewCoise] = useState('');
  const [quizAfterCorrect, setQuizAfterCorrect] = useState(
    EditorState.createEmpty()
  );
  const [quizBtnValue, setQuizBtnValue] = useState('Continue');
  const [quizOtherStr, setQuizOtherStr] = useState('');
  const [isMultipleChoise, setIsMultipleChoise] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [quizBtnAfterAnswerValue, setQuizBtnAfterAnswerValue] = useState(null);
  const [quizMaxNum, setQuizMaxNum] = useState(5);
  const [isOtherCorrect, setIsOtherCorrect] = useState(false);
  const [firstRatingText, setFirstRatingText] = useState('Terrible');
  const [lastRatingText, setLastRatingText] = useState('Excellent');
  // VARIANTS
  const [variantTitle, setVariantTitle] = useState('');
  const [tempScreenFilters, setTempScreenFilters] = useState([]);
  // GOALS
  const [isGoalConfigDialogOpen, setIsGoalConfigDialogOpen] = useState(false);
  const [selectedGoalLessonId, setSelectedGoalLessonId] = useState('');
  const [isFromGoalEdit, setIsFromGoalEdit] = useState(false);
  // JOURNALS
  const [isJournalDialogOpen, setIsJournalDialogOpen] = useState(false);
  const [isFromJournalEdit, setIsFromJournalEdit] = useState(false);
  const [selectedJournal, setSelectedJournal] = useState('');
  const [journalTitle, setJournalTitle] = useState('');
  const [journalAllowFeedback, setJournalAllowFeedback] = useState(true);
  const [journalType, setJournalType] = useState('journal');
  // const [journalEstimatedMinutes, setJournalEstimatedMinutes] = useState(0);
  // FILES
  // const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  // Success Thresholds
  const [
    isSuccessThresholdsDialogOpen,
    setIsSuccessThresholdsDialogOpen,
  ] = useState(false);
  const [minimumPracticeDaysInput, setMinimumPracticeDaysInput] = useState(
    null
  );
  const [minimumJournalEntriesInput, setMinimumJournalEntriesInput] = useState(
    null
  );
  const [isThresholdEnable, setIsThresholdEnable] = useState(false);
  // CHECKINS
  const [isCheckinsDialogOpen, setIsCheckinsDialogOpen] = useState(false);
  const [selectedChekin, setSelectedChekin] = useState(null);
  const [isFromCheckinEdit, setIsFromCheckinEdit] = useState(false);
  const [checkinSkill, setCheckinSkill] = useState('');
  const [isCheckinEnable, setIsCheckinEnabled] = useState(false);
  // move module
  const [isMoveModuleDialogOpen, setIsMoveModuleDialogOpen] = useState(false);
  const [fromModule, setFromModule] = useState('');
  const [fromCourse, setFromCourse] = useState('');
  const [moveModuleFilter, setMoveModuleFilter] = useState('');
  // module description
  const [moduleDescription, setModuleDescription] = useState('');
  const [
    toggleEditmoduleDescription,
    setToggleEditModuleDescription,
  ] = useState('');
  // security and permissions
  const [displayPermissionModal, setDisplayPermissionModal] = useState(false);
  const [isFinalizedState, setIsFinalizedState] = useState(false);
  const [previewHieght, setPreviewHieght] = useState(null);
  // lesson notification
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(
    false
  );
  const [
    selectedNotificationsLesson,
    setSelectedNotificationsLesson,
  ] = useState('');
  const [lessonNotificationTitle, setLessonNotificationTitle] = useState('');
  const [lessonNotificationBody, setLessonNotificationBody] = useState('');
  const [lessonNotificationEnabled, setLessonNotificationEnabled] = useState(
    false
  );
  const [
    lessonNotificationTitleTooltip,
    setLessonNotificationTitleTooltip,
  ] = useState('');
  const [
    lessonNotificationBodyTooltip,
    setLessonNotificationBodyTooltip,
  ] = useState('');

  // resources
  const [addCollectionModalOpen, setAddCollectionModalOpen] = useState(false);
  const [collectionTitle, setCollectionTitle] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [editCollectionDialogOpen, setEditCollectionDialogOpen] = useState(
    false
  );
  const [selectedCollection, setSelectedCollection] = useState('');
  const [selectedArticleLesson, setSelectedArticleLesson] = useState('');
  const [isFromArticleEdit, setIsFromArticleEdit] = useState('');
  const [collectionToDelete, setCollectionToDelete] = useState('');
  const [articleToDelete, setArticleToDelete] = useState(false);
  const [journalToDelete, setJournalToDelete] = useState(false);
  const [selectedCollectionJournal, setSelectedCollectionJournal] = useState(
    ''
  );
  const [
    isFromJournalCollectionEdit,
    setIsFromJournalCollectionEdit,
  ] = useState(false);
  const [showOrderAlert, setShowOrderAlert] = useState(false);
  const [toggleCategoriesDialog, setToggleCategoriesDialog] = useState(false);

  const [
    journeyDashboardDescription,
    setJourneyDashboardDescription,
  ] = useState('');

  useEffect(() => {
    switch (screenStage) {
      case 'Main':
        // setDataPath('lago/educational/defaults/journeys');
        setDataSubPath('lago/educational/defaults/journeys');
        break;
      case 'Journey':
        setDataPath(`lago/educational/defaults/journeys/${selectedItem}`);
        setDataSubPath('lago/educational/defaults/minicourses');
        break;
      case 'Mini-course':
        setDataPath(`lago/educational/defaults/minicourses/${selectedItem}`);
        setDataSubPath('lago/educational/defaults/modules');
        break;
      case 'Module':
        setDataPath(`lago/educational/defaults/modules/${selectedItem}`);
        setDataSubPath('lago/educational/defaults/lessons');
        break;
      case 'Lesson':
        setDataPath(`lago/educational/defaults/lessons/${selectedItem}`);
        setDataSubPath('lago/educational/defaults/screens');
        break;
      default:
        // setDataPath('lago/educational/defaults/journeys');
        setDataSubPath('lago/educational/defaults/journeys');
    }
  }, [screenStage]);

  useEffect(() => {
    if (selectedItem) {
      history.push(`?id=${selectedItem}`);
    } else {
      history.push('?');
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      sessionStorage.isAdmin !== 'true' &&
      sessionStorage.isContentWriter !== 'true'
    ) {
      setDisplayPermissionModal(true);
    }
    if (sessionStorage.isAdmin === 'true') {
      setIsFinalizedState(true);
    }
  }, []);

  const [primaryData, setPrimaryData] = useFirebaseDB({
    path: dataPath,
  });

  // eslint-disable-next-line no-unused-vars
  const [itemsData, setItemsData, addItemData, removeItemData] = useFirebaseDB({
    path: dataSubPath,
    initialValue: [],
  });

  const [screenData, setScreenData] = useFirebaseDB({
    path: `lago/educational/defaults/screens/${selectedScreen}`,
    initialValue: [],
  });

  const [lessonsItemsData] = useFirebaseDB({
    path: 'lago/educational/defaults/lessons',
  });

  const [coursesItemsData] = useFirebaseDB({
    path: 'lago/educational/defaults/minicourses',
  });

  const [modulesItemsData] = useFirebaseDB({
    path: 'lago/educational/defaults/modules',
  });

  const [
    journeysItemsData,
    // eslint-disable-next-line no-unused-vars
    setJourneysItemsData,
    // eslint-disable-next-line no-unused-vars
    addJourneysItemsData,
    deleteJourneysItemsData,
  ] = useFirebaseDB({
    path: 'lago/educational/defaults/journeys',
  });

  const [
    screensItemsData,
    setScreensItemsData,
    addScreensItemsData,
    // deleteScreensItemsData,
  ] = useFirebaseDB({
    path: 'lago/educational/defaults/screens',
  });

  // eslint-disable-next-line no-unused-vars
  const [tags, setTags, addTag] = useFirebaseDB({
    path: 'lago/educational/defaults/tags',
  });

  // eslint-disable-next-line no-unused-vars
  const [goals, setGoals, addGoal, removeGoal] = useFirebaseDB({
    path: 'lago/educational/defaults/goals',
  });

  // eslint-disable-next-line no-unused-vars
  const [reminders, setReminders, addReminders, removeReminder] = useFirebaseDB(
    {
      path: 'lago/educational/defaults/reminders',
    }
  );

  const [journals, setJournals, addJournal, deleteJournal] = useFirebaseDB({
    path: 'lago/educational/defaults/journals',
  });

  const [checkins, setCheckins, addCheckin] = useFirebaseDB({
    path: 'lago/educational/defaults/checkins',
  });

  const [
    resourcesDrafts,
    setResourcesDrafts,
    addResourcesDraft,
    deleteResourcesDraft,
  ] = useFirebaseDB({
    path: 'lago/educational/defaults/resources/drafts',
  });

  const [
    resourcesPublished,
    // eslint-disable-next-line no-unused-vars
    setResourcesPublished,
    // eslint-disable-next-line no-unused-vars
    addResourcesPublished,
    deleteResourcesPublished,
  ] = useFirebaseDB({
    path: 'lago/educational/defaults/resources/published',
  });

  const [collectionData, setCollectionData] = useFirebaseDB({
    path: `lago/educational/defaults/resources/drafts/${selectedCollection}`,
  });

  const [
    // eslint-disable-next-line no-unused-vars
    articlesData,
    // eslint-disable-next-line no-unused-vars
    setArticlesData,
    // eslint-disable-next-line no-unused-vars
    addArticlesData,
    deleteArticlesData,
  ] = useFirebaseDB({
    path: `lago/educational/defaults/resources/drafts/${selectedCollection}/articles`,
  });

  const [
    // eslint-disable-next-line no-unused-vars
    articlesDataPublished,
    // eslint-disable-next-line no-unused-vars
    setArticlesDataPublished,
    // eslint-disable-next-line no-unused-vars
    addArticlesDataPublished,
    deleteArticlesDataPublished,
  ] = useFirebaseDB({
    path: `lago/educational/defaults/resources/published/${selectedCollection}/articles`,
  });

  const [
    // eslint-disable-next-line no-unused-vars
    journalsData,
    // eslint-disable-next-line no-unused-vars
    setJournalsData,
    // eslint-disable-next-line no-unused-vars
    addJournalsData,
    deleteJournalsData,
  ] = useFirebaseDB({
    path: `lago/educational/defaults/resources/drafts/${selectedCollection}/journals`,
  });

  const [
    // eslint-disable-next-line no-unused-vars
    journalsDataPublished,
    // eslint-disable-next-line no-unused-vars
    setJournalsDataPublished,
    // eslint-disable-next-line no-unused-vars
    addJournalsDataPublished,
    deleteJournalsDataPublished,
  ] = useFirebaseDB({
    path: `lago/educational/defaults/resources/published/${selectedCollection}/journals`,
  });

  const [articleData, setArticleData] = useFirebaseDB({
    path: `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}`,
  });

  const [journalCollectionData, setJournalCollectionData] = useFirebaseDB({
    path: `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}`,
  });

  const [publishedCollectionData, setPublishedCollectionData] = useFirebaseDB({
    path: `lago/educational/defaults/resources/published/${selectedCollection}`,
  });

  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories, addCategory] = useFirebaseDB({
    path: 'lago/educational/defaults/categories',
  });

  const parseDate = stamp => {
    const a = new Date(stamp);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    // eslint-disable-next-line prefer-template
    const time1 = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
    return time1;
  };

  const onSelectTag = useCallback(tagId => {
    setSelectedTagId(tagId);
  });

  const onAddTag = useCallback((placeholder, description, tagName) => {
    const newTag = {
      dateAdded: Date.now(),
      lastEdited: Date.now(),
      placeholder: { en: placeholder },
      internalDescription: description,
      tagName: { en: tagName },
      journeyId: selectedItemHistory[0] ? selectedItemHistory[0] : selectedItem,
    };
    const addedTag = addTag(newTag);
    onSelectTag(addedTag.key);
  });

  const resetScreenStateHtml = useCallback(() => {
    setHtmlText('');
    setHtmlBtnValue('');
    setHtmlImgPath('');
  });

  const resetScreenStateVideo = useCallback(() => {
    setVideoPrev('');
    setVideoFile(null);
  });

  const resetScreenStateQuiz = useCallback(() => {
    setQuizType('exercise');
    setQuizTextQuestion(EditorState.createEmpty());
    setQuizCoises([]);
    setQuizNewCoise('');
    setQuizAfterCorrect(EditorState.createEmpty());
    setQuizBtnValue('Continue');
    setQuizOtherStr('');
    setIsMultipleChoise(false);
    setSelectedTagId(null);
    setHtmlImgPath('');
    setQuizBtnAfterAnswerValue('');
    setIsOtherCorrect(false);
    setFirstRatingText('Terrible');
    setLastRatingText('Excellent');
  });

  const findNextIndex = useCallback(items => {
    let nextIndex = 0;
    Object.keys(items).forEach(key => {
      if (items[key] >= nextIndex && items[key] < PRESET_SCREEN_INDEX) {
        nextIndex = items[key] + 1;
      }
    });
    return nextIndex;
  });

  const editItem = useCallback(() => {
    const copy = JSON.parse(JSON.stringify(primaryData));
    copy.title.en = addedItemTitle;
    if (currentStage === JOURNEY_INDEX) {
      copy.dashboardDescription = journeyDashboardDescription;
    }
    setPrimaryData(copy);
    // for events
    switch (currentStage) {
      case JOURNEY_INDEX:
        lagoEventLogger.log('journey_edited', {
          journeyId: selectedItem,
          title: addedItemTitle,
        });
        break;
      case MINI_COURSE_INDEX:
        lagoEventLogger.log('course_edited', {
          courseId: selectedItem,
          title: addedItemTitle,
        });
        break;
      case MODULE_INDEX:
        lagoEventLogger.log('module_edited', {
          moduleId: selectedItem,
          title: addedItemTitle,
        });
        break;
      case LESSON_INDEX:
        lagoEventLogger.log('lesson_edited', {
          lessonId: selectedItem,
          title: addedItemTitle,
        });
        break;
      default:
        break;
    }
  });

  const addItem = useCallback(() => {
    if (currentStage === MAIN_INDEX) {
      const addedItem = addItemData({
        title: { en: addedItemTitle },
        dashboardDescription: journeyDashboardDescription,
      });
      lagoEventLogger.log('journey_add_clicked', {
        journeyId: addedItem.key,
        title: addedItemTitle,
      });
    }
    if (currentStage >= JOURNEY_INDEX && currentStage <= MODULE_INDEX) {
      let addedItem;
      if (currentStage === MINI_COURSE_INDEX) {
        addedItem = addItemData({
          title: { en: addedItemTitle },
          successThresholds: {
            minPracticeDays: 0,
            minJournalEntries: 0,
            isEnabled: false,
          },
        });
        lagoEventLogger.log('module_add_clicked', {
          moduleId: addedItem.key,
          title: addedItemTitle,
        });
      } else if (currentStage === MODULE_INDEX) {
        addedItem = addItemData({
          title: { en: addedItemTitle },
          estimatedMins: DEFAULT_ESTIMATED_MINUTES,
        });
        lagoEventLogger.log('lesson_add_clicked', {
          lessonId: addedItem.key,
          title: addedItemTitle,
        });
      } else {
        addedItem = addItemData({
          title: { en: addedItemTitle },
        });
        lagoEventLogger.log('course_add_clicked', {
          courseId: addedItem.key,
          title: addedItemTitle,
        });
      }
      const copy = JSON.parse(JSON.stringify(primaryData));
      if (!copy[screenStagesSubKey[currentStage]]) {
        copy[screenStagesSubKey[currentStage]] = {};
      }
      const nextIndex = findNextIndex(copy[screenStagesSubKey[currentStage]]);
      copy[screenStagesSubKey[currentStage]][addedItem.key] = nextIndex;
      setPrimaryData({
        ...copy,
      });
    }
    if (currentStage === LESSON_INDEX) {
      const addedItem = addItemData({
        title: { en: addedItemTitle },
        screenType: 'content',
      });
      const copy = JSON.parse(JSON.stringify(primaryData));
      if (!copy[screenStagesSubKey[currentStage]]) {
        copy[screenStagesSubKey[currentStage]] = {};
      }
      const nextIndex = findNextIndex(copy[screenStagesSubKey[currentStage]]);
      copy[screenStagesSubKey[currentStage]][addedItem.key] = nextIndex;
      setPrimaryData({
        ...copy,
      });
    }
  });

  const onDeleteItemConfirmed = useCallback(itemKey => {
    if (currentStage === MAIN_INDEX) {
      if (collectionToDelete) {
        deleteResourcesDraft(itemKey);
        deleteResourcesPublished(itemKey);
        return;
      }
      if (selectedArticleLesson) {
        // deleteScreensItemsData(itemKey);
        const copyPrimary = JSON.parse(JSON.stringify(articleData));
        const indexToDelete = copyPrimary.screens[itemKey];
        Object.keys(copyPrimary.screens).forEach(key => {
          if (copyPrimary.screens[key] > indexToDelete) {
            copyPrimary.screens[key] -= 1;
          }
        });
        delete copyPrimary.screens[itemKey];
        setArticleData(copyPrimary);
        const publishDate = Date.now();
        database
          .ref(
            `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}/lastEdited`
          )
          .set(publishDate);
        return;
      }

      if (selectedCollectionJournal) {
        // deleteScreensItemsData(itemKey);
        const copyPrimary = JSON.parse(JSON.stringify(journalCollectionData));
        const indexToDelete = copyPrimary.screens[itemKey];
        Object.keys(copyPrimary.screens).forEach(key => {
          if (copyPrimary.screens[key] > indexToDelete) {
            copyPrimary.screens[key] -= 1;
          }
        });
        delete copyPrimary.screens[itemKey];
        setJournalCollectionData(copyPrimary);
        const publishDate = Date.now();
        database
          .ref(
            `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}/lastEdited`
          )
          .set(publishDate);
        return;
      }

      if (selectedCollection) {
        if (articleToDelete) {
          deleteArticlesData(itemKey);
          deleteArticlesDataPublished(itemKey);
        }
        if (journalToDelete) {
          deleteJournalsData(itemKey);
          deleteJournalsDataPublished(itemKey);
        }
        return;
      }

      deleteJourneysItemsData(itemKey);
      // remove journey's journals
      Object.keys(journals).forEach(journalId => {
        if (journals[journalId].journeyId === itemKey) {
          deleteJournal(journalId);
        }
      });
    }
    if (currentStage >= JOURNEY_INDEX && currentStage <= LESSON_INDEX) {
      if (isFromGoalEdit) {
        const copy = JSON.parse(JSON.stringify(screensItemsData));
        delete copy[itemKey];
        setScreensItemsData(copy);
        const copyPrimary = JSON.parse(JSON.stringify(lessonsItemsData));
        const indexToDelete =
          copyPrimary[selectedGoalLessonId].screens[itemKey];
        Object.keys(copyPrimary[selectedGoalLessonId].screens).forEach(key => {
          if (
            copyPrimary[selectedGoalLessonId].screens[key] > indexToDelete &&
            copyPrimary[selectedGoalLessonId].screens[key] < PRESET_SCREEN_INDEX
          ) {
            copyPrimary[selectedGoalLessonId].screens[key] -= 1;
          }
        });
        delete copyPrimary[selectedGoalLessonId].screens[itemKey];
        // setLessonsItemsData(copyPrimary);
        database
          .ref(`lago/educational/defaults/lessons/${selectedGoalLessonId}`)
          .set(copyPrimary[selectedGoalLessonId]);
      } else if (isFromJournalEdit) {
        const copy = JSON.parse(JSON.stringify(screensItemsData));
        delete copy[itemKey];
        setScreensItemsData(copy);

        const copyPrimary = JSON.parse(JSON.stringify(journals));
        const indexToDelete = copyPrimary[selectedJournal].screens[itemKey];
        Object.keys(copyPrimary[selectedJournal].screens).forEach(key => {
          if (copyPrimary[selectedJournal].screens[key] > indexToDelete) {
            copyPrimary[selectedJournal].screens[key] -= 1;
          }
        });
        delete copyPrimary[selectedJournal].screens[itemKey];
        setJournals(copyPrimary);
      } else if (isFromCheckinEdit) {
        const copy = JSON.parse(JSON.stringify(screensItemsData));
        delete copy[itemKey];
        setScreensItemsData(copy);

        const copyPrimary = JSON.parse(JSON.stringify(checkins));
        const indexToDelete = copyPrimary[selectedChekin].screens[itemKey];
        Object.keys(copyPrimary[selectedChekin].screens).forEach(key => {
          if (copyPrimary[selectedChekin].screens[key] > indexToDelete) {
            copyPrimary[selectedChekin].screens[key] -= 1;
          }
        });
        delete copyPrimary[selectedChekin].screens[itemKey];
        setCheckins(copyPrimary);
      } else {
        if (currentStage === MINI_COURSE_INDEX) {
          let goalLessonIdToDelete = '';
          // eslint-disable-next-line no-unused-expressions
          itemsData[itemKey] &&
            itemsData[itemKey].lessons &&
            Object.keys(itemsData[itemKey].lessons).forEach(lessonId => {
              if (
                itemsData[itemKey].lessons[lessonId] === PRESET_SCREEN_INDEX
              ) {
                goalLessonIdToDelete = lessonId;
              }
            });
          if (goalLessonIdToDelete) {
            if (
              goals[lessonsItemsData[goalLessonIdToDelete].goalId].reminderId
            ) {
              removeReminder(
                goals[lessonsItemsData[goalLessonIdToDelete].goalId].reminderId
              );
            }
            removeGoal(lessonsItemsData[goalLessonIdToDelete].goalId);
          }
          Object.keys(journals).forEach(journalId => {
            if (
              journals[journalId].moduleId &&
              journals[journalId].moduleId === itemKey
            ) {
              deleteJournal(journalId);
            }
          });
        }
        if (currentStage === JOURNEY_INDEX) {
          Object.keys(journals).forEach(journalId => {
            if (
              journals[journalId].courseId &&
              journals[journalId].courseId === itemKey
            ) {
              deleteJournal(journalId);
            }
          });
          // eslint-disable-next-line no-unused-expressions
          itemsData[itemKey] &&
            itemsData[itemKey].modules &&
            Object.keys(itemsData[itemKey].modules).forEach(moduleId => {
              let goalLessonIdToDelete = '';
              Object.keys(modulesItemsData[moduleId].lessons).forEach(
                lessonId => {
                  if (
                    modulesItemsData[moduleId].lessons[lessonId] ===
                    PRESET_SCREEN_INDEX
                  ) {
                    goalLessonIdToDelete = lessonId;
                  }
                }
              );
              if (goalLessonIdToDelete) {
                if (
                  goals[lessonsItemsData[goalLessonIdToDelete].goalId]
                    .reminderId
                ) {
                  removeReminder(
                    goals[lessonsItemsData[goalLessonIdToDelete].goalId]
                      .reminderId
                  );
                }
                removeGoal(lessonsItemsData[goalLessonIdToDelete].goalId);
              }
            });
        }
        removeItemData(itemKey);
        const copyPrimary = JSON.parse(JSON.stringify(primaryData));
        const indexToDelete =
          copyPrimary[screenStagesSubKey[currentStage]][itemKey];
        Object.keys(copyPrimary[screenStagesSubKey[currentStage]]).forEach(
          key => {
            if (
              copyPrimary[screenStagesSubKey[currentStage]][key] > indexToDelete
            ) {
              copyPrimary[screenStagesSubKey[currentStage]][key] -= 1;
            }
          }
        );
        delete copyPrimary[screenStagesSubKey[currentStage]][itemKey];
        setPrimaryData(copyPrimary);
      }
    }
    if (currentStage === LESSON_INDEX || isFromGoalEdit) {
      resetScreenStateHtml();
      resetScreenStateVideo();
      resetScreenStateQuiz();
    }
  });

  const onEditItemClicked = useCallback(() => {
    setVariantToEdit('');
    setIsScreenEditor(true);
    setIsDialogOpen(true);
    setIsVariantEditor(false);
  });

  const onAddNewVariantClicked = useCallback((e, key) => {
    e.stopPropagation();
    setSelectedScreen(key);
    setIsNewVariant(true);
    setDisplayVariantDialog(true);
  });

  const editVariant = useCallback(() => {
    setDisplayVariantDialog(true);
  });

  const variantClicked = useCallback(variantKey => {
    setVariantToEdit(variantKey);
    lagoEventLogger.log('edit_variant_clicked', { variantId: variantKey });
  });

  const noVariantClicked = useCallback(() => {
    setVariantToEdit('');
  });

  useEffect(() => {
    resetScreenStateHtml();
    resetScreenStateVideo();
    resetScreenStateQuiz();
    setVariantTitle('');
    setIsFromEditModal(false);
    setTempScreenFilters([]);
    setEditorState(EditorState.createEmpty());
  }, [isNewVariant]);

  const saveEditTitle = useCallback(isFromEdit => {
    if (isFromEdit) {
      editItem();
    }
    if (isFromEdit === false) {
      addItem();
    }
  });

  const removeImage = useCallback(() => {
    if (primaryData && primaryData.img) {
      const copy = JSON.parse(JSON.stringify(primaryData));
      copy.img = '';
      setPrimaryData(copy);
      const el = document.getElementById('image-input');
      el.value = '';
    }
  });

  const removeScreenImage = useCallback(() => {
    setHtmlImgPath('');
    const el = document.getElementById('image-screen-input');
    el.value = '';
  });

  const onUploadedImageClicked = useCallback(imagePath => {
    lagoEventLogger.log('upload_image_clicked');
    if (isScreenEditor) {
      setHtmlImgPath(imagePath);
      return;
    }
    if (isFromGoalEdit) {
      const copy = JSON.parse(JSON.stringify(goals));
      copy[lessonsItemsData[selectedGoalLessonId].goalId].img = imagePath;
      setGoals(copy);
      return;
    }
    if (isFromJournalEdit) {
      const copy = JSON.parse(JSON.stringify(journals));
      copy[selectedJournal].img = imagePath;
      setJournals(copy);
      return;
    }
    const copy = JSON.parse(JSON.stringify(primaryData));
    copy.img = imagePath;
    setPrimaryData(copy);
  });

  const onScreenImageFileChange = useCallback(async event => {
    lagoEventLogger.log('upload_image_clicked');
    setIsLoadingImage(true);
    const path =
      IMAGE_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const [file] = event.target.files;
    const storage = window.firebase.storage();
    const imageRef = storage.ref(path);
    await imageRef.put(file);
    imageRef.getDownloadURL().then(res => {
      setHtmlImgPath(res);
      setIsLoadingImage(false);
    });
  });

  const onImageFileChange = useCallback(
    async event => {
      lagoEventLogger.log('upload_image_clicked');
      setIsLoadingImage(true);
      const path =
        IMAGE_PATH_PREFIX +
        Math.random()
          .toString(36)
          .substring(7);
      const [file] = event.target.files;
      // setImageFile(file);
      const storage = window.firebase.storage();
      const imageRef = storage.ref(path);
      try {
        await imageRef.put(file);
        imageRef
          .getDownloadURL()
          .then(res => {
            const copy = JSON.parse(JSON.stringify(primaryData));
            copy.img = res;
            setPrimaryData(copy);
            setIsLoadingImage(false);
          })
          .catch(() => {
            setIsLoadingImage(false);
          });
      } catch (err) {
        setIsLoadingImage(false);
      }
    },
    [dataPath, primaryData, setPrimaryData]
  );

  // get all images
  useEffect(() => {
    const allImages = [];
    const moduleIdsListForCheckins = [];
    const itemHistory = selectedItemHistory[0]
      ? selectedItemHistory[0]
      : selectedItem;
    // eslint-disable-next-line no-unused-expressions
    journeysItemsData &&
      journeysItemsData[itemHistory] &&
      Object.keys(journeysItemsData[itemHistory].minicourses).map(
        // eslint-disable-next-line array-callback-return
        minicourseId => {
          if (
            coursesItemsData &&
            coursesItemsData[minicourseId] &&
            coursesItemsData[minicourseId].img
          ) {
            allImages.push(coursesItemsData[minicourseId].img);
          }
          // eslint-disable-next-line no-unused-expressions
          coursesItemsData &&
            coursesItemsData[minicourseId] &&
            coursesItemsData[minicourseId].modules &&
            Object.keys(coursesItemsData[minicourseId].modules).map(
              // eslint-disable-next-line array-callback-return
              moduleId => {
                moduleIdsListForCheckins.push(moduleId);
                if (modulesItemsData[moduleId].img) {
                  allImages.push(modulesItemsData[moduleId].img);
                }
                // eslint-disable-next-line no-unused-expressions
                modulesItemsData &&
                  modulesItemsData[moduleId] &&
                  modulesItemsData[moduleId].lessons &&
                  Object.keys(modulesItemsData[moduleId].lessons).map(
                    // eslint-disable-next-line array-callback-return
                    lessonId => {
                      if (lessonsItemsData[lessonId].img) {
                        allImages.push(lessonsItemsData[lessonId].img);
                      }
                      // eslint-disable-next-line no-unused-expressions
                      lessonsItemsData &&
                        lessonsItemsData[lessonId] &&
                        lessonsItemsData[lessonId].screens &&
                        Object.keys(lessonsItemsData[lessonId].screens).map(
                          // eslint-disable-next-line array-callback-return
                          screenId => {
                            Object.keys(
                              screensItemsData[screenId].variants
                              // eslint-disable-next-line array-callback-return
                            ).map(variantId => {
                              if (
                                screensItemsData[screenId].variants[variantId]
                                  .img
                              ) {
                                allImages.push(
                                  screensItemsData[screenId].variants[variantId]
                                    .img
                                );
                              }
                            });
                          }
                        );
                    }
                  );
              }
            );
          // eslint-disable-next-line no-unused-expressions
          journals &&
            // eslint-disable-next-line array-callback-return
            Object.keys(journals).map(journalId => {
              if (journals[journalId].journeyId === itemHistory) {
                if (journals[journalId].img) {
                  allImages.push(journals[journalId].img);
                }
                // eslint-disable-next-line no-unused-expressions
                journals[journalId].screens &&
                  // eslint-disable-next-line array-callback-return
                  Object.keys(journals[journalId].screens).map(screenId => {
                    // eslint-disable-next-line array-callback-return
                    Object.keys(screensItemsData[screenId].variants).map(
                      // eslint-disable-next-line array-callback-return
                      variantId => {
                        if (
                          screensItemsData[screenId].variants[variantId].img
                        ) {
                          allImages.push(
                            screensItemsData[screenId].variants[variantId].img
                          );
                        }
                      }
                    );
                  });
              }
            });
          // eslint-disable-next-line no-unused-expressions
          goals &&
            // eslint-disable-next-line array-callback-return
            Object.keys(goals).map(goalId => {
              if (goals[goalId].journeyId === itemHistory) {
                if (goals[goalId].img) {
                  allImages.push(goals[goalId].img);
                }
                if (lessonsItemsData[goals[goalId].lessonId].screens) {
                  Object.keys(
                    lessonsItemsData[goals[goalId].lessonId].screens
                    // eslint-disable-next-line array-callback-return
                  ).map(screenId => {
                    // eslint-disable-next-line array-callback-return
                    Object.keys(screensItemsData[screenId].variants).map(
                      // eslint-disable-next-line array-callback-return
                      variantId => {
                        if (
                          screensItemsData[screenId].variants[variantId].img
                        ) {
                          allImages.push(
                            screensItemsData[screenId].variants[variantId].img
                          );
                        }
                      }
                    );
                  });
                }
              }
            });
          // eslint-disable-next-line no-unused-expressions
          checkins &&
            // eslint-disable-next-line array-callback-return
            Object.keys(checkins).map(checkinId => {
              // eslint-disable-next-line array-callback-return
              moduleIdsListForCheckins.forEach(moduleId => {
                if (checkins[checkinId].moduleId === moduleId) {
                  // eslint-disable-next-line no-unused-expressions
                  checkins &&
                    checkins[checkinId] &&
                    checkins[checkinId].screens &&
                    // eslint-disable-next-line array-callback-return
                    Object.keys(checkins[checkinId].screens).map(screenId => {
                      // eslint-disable-next-line array-callback-return
                      Object.keys(screensItemsData[screenId].variants).map(
                        // eslint-disable-next-line array-callback-return
                        variantId => {
                          if (
                            screensItemsData[screenId].variants[variantId].img
                          ) {
                            allImages.push(
                              screensItemsData[screenId].variants[variantId].img
                            );
                          }
                        }
                      );
                    });
                }
              });
            });
        }
      );
    // uploaded images for articles
    if (
      (!journeysItemsData || !journeysItemsData[itemHistory]) &&
      resourcesDrafts
    ) {
      // eslint-disable-next-line array-callback-return
      Object.keys(resourcesDrafts).map(draftId => {
        // eslint-disable-next-line no-unused-expressions
        resourcesDrafts[draftId].articles &&
          // eslint-disable-next-line array-callback-return
          Object.keys(resourcesDrafts[draftId].articles).map(articleId => {
            if (
              resourcesDrafts[draftId].articles &&
              resourcesDrafts[draftId].articles[articleId] &&
              resourcesDrafts[draftId].articles[articleId].img
            ) {
              allImages.push(resourcesDrafts[draftId].articles[articleId].img);
            }
            // itereate all screens
            if (
              resourcesDrafts[draftId].articles &&
              resourcesDrafts[draftId].articles[articleId] &&
              resourcesDrafts[draftId].articles[articleId].screens
            ) {
              // eslint-disable-next-line array-callback-return
              Object.keys(
                resourcesDrafts[draftId].articles[articleId].screens
                // eslint-disable-next-line array-callback-return
              ).map(screenId => {
                // eslint-disable-next-line array-callback-return
                Object.keys(screensItemsData[screenId].variants).map(
                  // eslint-disable-next-line array-callback-return
                  variantId => {
                    if (screensItemsData[screenId].variants[variantId].img) {
                      allImages.push(
                        screensItemsData[screenId].variants[variantId].img
                      );
                    }
                  }
                );
              });
            }
          });
        // eslint-disable-next-line no-unused-expressions
        resourcesDrafts[draftId].journals &&
          // eslint-disable-next-line array-callback-return
          Object.keys(resourcesDrafts[draftId].journals).map(journalId => {
            if (
              resourcesDrafts[draftId].journals &&
              resourcesDrafts[draftId].journals[journalId] &&
              resourcesDrafts[draftId].journals[journalId].img
            ) {
              allImages.push(resourcesDrafts[draftId].journals[journalId].img);
            }
            // itereate all screens
            if (
              resourcesDrafts[draftId].journals &&
              resourcesDrafts[draftId].journals[journalId] &&
              resourcesDrafts[draftId].journals[journalId].screens
            ) {
              // eslint-disable-next-line array-callback-return
              Object.keys(
                resourcesDrafts[draftId].journals[journalId].screens
                // eslint-disable-next-line array-callback-return
              ).map(screenId => {
                // eslint-disable-next-line array-callback-return
                Object.keys(screensItemsData[screenId].variants).map(
                  // eslint-disable-next-line array-callback-return
                  variantId => {
                    if (screensItemsData[screenId].variants[variantId].img) {
                      allImages.push(
                        screensItemsData[screenId].variants[variantId].img
                      );
                    }
                  }
                );
              });
            }
          });
      });
    }
    setUploadedImages([...new Set(allImages)]);
  }, [displayUploadedImages]);

  const onVideoFileChange = useCallback(async event => {
    const [file] = event.target.files;
    if (file.size / 1024 / 1024 > 750) {
      setFileSizeError(true);
      setTimeout(() => {
        setFileSizeError(false);
      }, 1500);
      return;
    }
    setVideoFile(file);
  });

  const parseTags = useCallback(textEntities => {
    const tagsMap = {};
    Object.keys(textEntities).forEach(entity => {
      if (textEntities[entity].type === 'MENTION') {
        const tagId = textEntities[entity].data.url.substring(37, 57);
        tagsMap[textEntities[entity].data.value] = tagId;
      }
    });
    return tagsMap;
  });

  const addCustomStyle = useCallback(
    htmlStr =>
      `<span style="font-size: 24px;font-family: Avenir;">${htmlStr}</span>`
  );

  const onMoveModuleClicked = useCallback((e, id) => {
    e.stopPropagation();
    setIsMoveModuleDialogOpen(true);
    setFromModule(id);
    setFromCourse(selectedItem);
  });

  const moveModule = useCallback(async courseTo => {
    // eslint-disable-next-line  no-undef
    fireFunctionPost('lago-moveModule', {
      moduleId: fromModule,
      currentCourseId: fromCourse,
      futureCourseId: courseTo,
    });
    setIsMoveModuleDialogOpen(false);
    setMoveModuleFilter('');
    setFromModule('');
    setFromCourse('');
  });

  const displayNotificationsModal = (e, id) => {
    e.stopPropagation();
    setIsNotificationDialogOpen(true);
    setSelectedNotificationsLesson(id);
    if (itemsData[id].notification) {
      setLessonNotificationTitle(
        itemsData[id].notification.notificationTitle.en
      );
      setLessonNotificationBody(itemsData[id].notification.notificationBody.en);
      setLessonNotificationEnabled(itemsData[id].notification.isEnabled);
    }
  };

  const onCloseNotificationsModal = () => {
    setIsNotificationDialogOpen(false);
    setSelectedNotificationsLesson('');
    setLessonNotificationTitle('');
    setLessonNotificationBody('');
    setLessonNotificationEnabled(false);
    setSelectedNotificationsLesson('');
  };

  const saveLessonsNotification = () => {
    const copy = JSON.parse(
      JSON.stringify(lessonsItemsData[selectedNotificationsLesson])
    );
    copy.notification = {
      notificationTitle: { en: lessonNotificationTitle },
      notificationBody: { en: lessonNotificationBody },
      isEnabled: lessonNotificationEnabled,
    };

    database
      .ref(`lago/educational/defaults/lessons/${selectedNotificationsLesson}`)
      .set(copy);
  };

  const onLessonHover = id => {
    if (
      lessonsItemsData &&
      lessonsItemsData[id] &&
      lessonsItemsData[id].notification
    ) {
      setLessonNotificationTitleTooltip(
        lessonsItemsData[id].notification.notificationTitle.en
      );
      setLessonNotificationBodyTooltip(
        lessonsItemsData[id].notification.notificationBody.en
      );
    } else {
      setLessonNotificationTitleTooltip('');
      setLessonNotificationBodyTooltip('');
    }
  };

  const saveModuleDescription = () => {
    const copy = JSON.parse(JSON.stringify(primaryData));
    if (!copy.moduleDescription) {
      copy.moduleDescription = {};
    }
    copy.moduleDescription.en = moduleDescription;
    setPrimaryData(copy);
    setToggleEditModuleDescription(false);
  };

  const saveHtml = useCallback(() => {
    resetScreenStateQuiz();
    resetScreenStateVideo();
    setHtmlText(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    let defaultVariantId;
    Object.keys(screensItemsData[selectedScreen].variants).forEach(
      variantId => {
        if (
          screensItemsData[selectedScreen].variants[variantId].variantConfig
            .isDefaultVariant
        ) {
          defaultVariantId = variantId;
        }
      }
    );
    const variantKey =
      isVariantEditor && !isNewVariant ? variantToEdit : defaultVariantId;
    const htmlTags = parseTags(
      convertToRaw(editorState.getCurrentContent()).entityMap
    );
    const copy = JSON.parse(JSON.stringify(screensItemsData));
    copy[selectedScreen].variants[variantKey].title = { en: '' };
    copy[selectedScreen].variants[variantKey].title.en = addedItemTitle;
    copy[selectedScreen].variants[variantKey].btnText = { en: '' };
    copy[selectedScreen].variants[variantKey].btnText.en = htmlBtnValue;
    copy[selectedScreen].variants[variantKey].img = htmlImgPath || '';
    copy[selectedScreen].variants[variantKey].screenType = 'content';
    copy[selectedScreen].variants[variantKey].html = { en: '' };
    copy[selectedScreen].variants[variantKey].html.en = addCustomStyle(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    copy[selectedScreen].variants[variantKey].htmlTags = htmlTags;
    if (copy[selectedScreen].vidUrl) {
      copy[selectedScreen].vidUrl = null;
    }
    if (copy[selectedScreen].quizDetails) {
      copy[selectedScreen].quizDetails = null;
    }
    if (isVariantEditor) {
      copy[selectedScreen].variants[variantKey].variantConfig = {
        variantTitle,
        isDefaultVariant: false,
        conditions: [],
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      copy[selectedScreen].variants[
        variantKey
      ].variantConfig.conditions = conditions;
    }
    setScreenData(copy[selectedScreen]);
    setIsVariantEditor(false);
    setIsNewVariant(false);
    setDisplayVariantDialog(false);

    lagoEventLogger.log('screen_edited', {
      screeenId: selectedScreen,
      title: addedItemTitle,
      screenType: 'content',
    });
  });

  const saveNewHtml = useCallback(() => {
    resetScreenStateQuiz();
    resetScreenStateVideo();
    setHtmlText(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    const variantKey = Math.random()
      .toString(36)
      .substring(7);
    const htmlTags = parseTags(
      convertToRaw(editorState.getCurrentContent()).entityMap
    );

    if (isVariantEditor) {
      const variantToAdd = {
        title: { en: addedItemTitle },
        screenType: 'content',
        btnText: { en: htmlBtnValue },
        html: {
          en: addCustomStyle(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
          ),
        },
        htmlTags,
        img: htmlImgPath || '',
        variantConfig: {
          variantTitle,
          isDefaultVariant: false,
          conditions: [],
        },
        dateAdded: Date.now(),
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      variantToAdd.variantConfig.conditions = conditions;
      const screenCopy = JSON.parse(JSON.stringify(screenData));
      screenCopy.variants[variantKey] = variantToAdd;
      setScreenData(screenCopy);
      setIsVariantEditor(false);
      setIsNewVariant(false);
      setDisplayVariantDialog(false);
      lagoEventLogger.log('add_variant_clicked', {
        variantId: variantKey,
        screenType: 'html',
      });
      return;
    }
    const itemToAdd = { variants: {} };
    itemToAdd.variants[variantKey] = {
      title: { en: addedItemTitle },
      screenType: 'content',
      btnText: { en: htmlBtnValue },
      html: {
        en: addCustomStyle(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        ),
      },
      htmlTags,
      img: htmlImgPath || '',
      variantConfig: {
        variantTitle: 'default',
        isDefaultVariant: true,
        conditions: [],
      },
      dateAdded: Date.now(),
    };

    let addedItem = {};
    if (isFromArticleEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(articleData));
      if (!copy.screens) {
        copy.screens = {};
      }
      copy.screens[addedItem.key] = Object.keys(copy.screens).length;
      database
        .ref(
          `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}`
        )
        .set(copy);
    } else if (isFromJournalCollectionEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(journalCollectionData));
      if (!copy.screens) {
        copy.screens = {};
      }
      copy.screens[addedItem.key] = Object.keys(copy.screens).length;
      database
        .ref(
          `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}`
        )
        .set(copy);
    } else if (isFromGoalEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(
        JSON.stringify(lessonsItemsData[selectedGoalLessonId])
      );
      if (!copy.screens) {
        copy.screens = {};
      }
      copy.screens[addedItem.key] = Object.keys(copy.screens).length;
      database
        .ref(`lago/educational/defaults/lessons/${selectedGoalLessonId}`)
        .set(copy);
    } else if (isFromJournalEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(journals));
      if (!copy[selectedJournal].screens) {
        copy[selectedJournal].screens = {};
      }
      copy[selectedJournal].screens[addedItem.key] = Object.keys(
        copy[selectedJournal].screens
      ).length;
      setJournals(copy);
    } else if (isFromCheckinEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(checkins));
      if (!copy[selectedChekin].screens) {
        copy[selectedChekin].screens = {};
      }
      copy[selectedChekin].screens[addedItem.key] = Object.keys(
        copy[selectedChekin].screens
      ).length;
      setCheckins(copy);
    } else {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(primaryData));
      if (!copy[screenStagesSubKey[currentStage]]) {
        copy[screenStagesSubKey[currentStage]] = {};
      }
      copy[screenStagesSubKey[currentStage]][addedItem.key] = Object.keys(
        copy[screenStagesSubKey[currentStage]]
      ).length;
      setPrimaryData({
        ...copy,
      });
    }

    setSelectedScreen(addedItem.key);
    setSelectedVariant(variantKey);

    lagoEventLogger.log('screen_add_clicked', {
      screeenId: addedItem.key,
      title: addedItemTitle,
      screenType: 'content',
    });
  });

  const editVideoTitle = useCallback(() => {
    resetScreenStateQuiz();
    resetScreenStateHtml();
    let defaultVariantId;
    Object.keys(screensItemsData[selectedScreen].variants).forEach(
      variantId => {
        if (
          screensItemsData[selectedScreen].variants[variantId].variantConfig
            .isDefaultVariant
        ) {
          defaultVariantId = variantId;
        }
      }
    );
    const variantKey =
      isVariantEditor && !isNewVariant ? variantToEdit : defaultVariantId;
    const copy = JSON.parse(JSON.stringify(screensItemsData));
    if (copy[selectedScreen].variants[variantKey].html) {
      copy[selectedScreen].variants[variantKey].html = null;
    }
    if (copy[selectedScreen].variants[variantKey].quizDetails) {
      copy[selectedScreen].variants[variantKey].quizDetails = null;
    }
    copy[selectedScreen].variants[variantKey].title = { en: '' };
    copy[selectedScreen].variants[variantKey].title.en = addedItemTitle;
    if (isVariantEditor) {
      copy[selectedScreen].variants[variantKey].variantConfig = {
        variantTitle,
        isDefaultVariant: false,
        conditions: [],
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      copy[selectedScreen].variants[
        variantKey
      ].variantConfig.conditions = conditions;
    }
    setIsVariantEditor(false);
    setIsNewVariant(false);
    setDisplayVariantDialog(false);
    setScreenData(copy[selectedScreen]);
    setVideoPrev(copy[selectedScreen].variants[variantKey].vidUrl.en);
  });

  const saveVideo = useCallback(async () => {
    resetScreenStateQuiz();
    resetScreenStateHtml();
    let defaultVariantId;
    Object.keys(screensItemsData[selectedScreen].variants).forEach(
      variantId => {
        if (
          screensItemsData[selectedScreen].variants[variantId].variantConfig
            .isDefaultVariant
        ) {
          defaultVariantId = variantId;
        }
      }
    );
    const variantKey =
      isVariantEditor && !isNewVariant ? variantToEdit : defaultVariantId;
    const copy = JSON.parse(JSON.stringify(screensItemsData));
    if (copy[selectedScreen].variants[variantKey].html) {
      copy[selectedScreen].variants[variantKey].html = null;
    }
    if (copy[selectedScreen].variants[variantKey].quizDetails) {
      copy[selectedScreen].variants[variantKey].quizDetails = null;
    }
    copy[selectedScreen].variants[variantKey].title = { en: '' };
    copy[selectedScreen].variants[variantKey].title.en = addedItemTitle;
    copy[selectedScreen].variants[variantKey].screenType = 'vid';
    copy[selectedScreen].variants[variantKey].vidUrl = { en: '' };
    if (isVariantEditor) {
      copy[selectedScreen].variants[variantKey].variantConfig = {
        variantTitle,
        isDefaultVariant: false,
        conditions: [],
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      copy[selectedScreen].variants[
        variantKey
      ].variantConfig.conditions = conditions;
    }
    setIsVariantEditor(false);
    setIsNewVariant(false);
    setDisplayVariantDialog(false);
    setIsLoadingVideo(true);

    lagoEventLogger.log('screen_edited', {
      screeenId: selectedScreen,
      title: addedItemTitle,
      screenType: 'vid',
    });

    const path =
      VIDEO_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const storage = window.firebase.storage();
    const videoRef = storage.ref(path);
    try {
      await videoRef.put(videoFile);
      videoRef
        .getDownloadURL()
        .then(res => {
          copy[selectedScreen].variants[variantKey].vidUrl.en = res;
          setScreenData(copy[selectedScreen]);
          setVideoPrev(res);
          setIsLoadingVideo(false);
        })
        .catch(() => {
          setIsLoadingVideo(false);
        });
    } catch (err) {
      setIsLoadingVideo(false);
    }
  });

  const [videoLink, setVideoLink] = useState('');
  const [newItemKey, setNewItemKey] = useState('');
  const [newVariantKey, setNewVariantKey] = useState('');

  useEffect(() => {
    const copy = JSON.parse(JSON.stringify(screensItemsData));
    if (
      copy &&
      copy[newItemKey] &&
      copy[newItemKey].variants &&
      copy[newItemKey].variants[newVariantKey] &&
      copy[newItemKey].variants[newVariantKey]
    ) {
      copy[newItemKey].variants[newVariantKey].vidUrl = { en: videoLink };
      setScreenData(copy[newItemKey]);
    }
  }, [videoLink]);

  const saveNewVideo = useCallback(async () => {
    resetScreenStateHtml();
    resetScreenStateQuiz();
    const variantKey = Math.random()
      .toString(36)
      .substring(7);
    if (isVariantEditor) {
      const variantToAdd = {
        title: { en: addedItemTitle },
        screenType: 'vid',
        vidUrl: { en: null },
        variantConfig: {
          variantTitle,
          isDefaultVariant: false,
          conditions: [],
        },
        dateAdded: Date.now(),
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      variantToAdd.variantConfig.conditions = conditions;
      const screenCopy = JSON.parse(JSON.stringify(screenData));
      screenCopy.variants[variantKey] = variantToAdd;
      setScreenData(screenCopy);
      setIsVariantEditor(false);
      setIsNewVariant(false);
      setDisplayVariantDialog(false);
      setIsLoadingVideo(true);
      const path =
        VIDEO_PATH_PREFIX +
        Math.random()
          .toString(36)
          .substring(7);
      const storage = window.firebase.storage();
      const videoRef = storage.ref(path);
      try {
        await videoRef.put(videoFile);
        videoRef
          .getDownloadURL()
          .then(res => {
            setVideoPrev(res);
            if (isFromArticleEdit) {
              const copy = JSON.parse(JSON.stringify(articleData));
              if (!copy.screens) {
                copy.screens = {};
              }
              copy.screens[selectedScreen] = Object.keys(copy.screens).length;
              database
                .ref(
                  `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}`
                )
                .set(copy);
            } else if (isFromJournalCollectionEdit) {
              const copy = JSON.parse(JSON.stringify(journalCollectionData));
              if (!copy.screens) {
                copy.screens = {};
              }
              copy.screens[selectedScreen] = Object.keys(copy.screens).length;
              database
                .ref(
                  `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}`
                )
                .set(copy);
            } else if (isFromGoalEdit) {
              const copy = JSON.parse(
                JSON.stringify(lessonsItemsData[selectedGoalLessonId])
              );
              if (!copy.screens) {
                copy.screens = {};
              }
              copy.screens[selectedScreen] = Object.keys(copy.screens).length;
              database
                .ref(
                  `lago/educational/defaults/lessons/${selectedGoalLessonId}`
                )
                .set(copy);
            } else if (isFromCheckinEdit) {
              const copy = JSON.parse(JSON.stringify(checkins));
              if (!copy[selectedChekin].screens) {
                copy[selectedChekin].screens = {};
              }
              copy[selectedChekin].screens[selectedScreen] = Object.keys(
                copy[selectedChekin].screens
              ).length;
              setCheckins(copy);
            } else if (isFromJournalEdit) {
              const copy = JSON.parse(JSON.stringify(journals));
              if (!copy[selectedJournal].screens) {
                copy[selectedJournal].screens = {};
              }
              copy[selectedJournal].screens[selectedScreen] = Object.keys(
                copy[selectedJournal].screens
              ).length;
              setJournals(copy);
            } else {
              const copy = JSON.parse(JSON.stringify(primaryData));
              if (!copy[screenStagesSubKey[currentStage]]) {
                copy[screenStagesSubKey[currentStage]] = {};
              }
              copy[screenStagesSubKey[currentStage]][
                selectedScreen
              ] = Object.keys(copy[screenStagesSubKey[currentStage]]).length;
              setPrimaryData({
                ...copy,
              });
            }
            setNewItemKey(selectedScreen);
            setNewVariantKey(variantKey);
            // setSelectedScreen(selectedScreen);
            setVideoLink(res);
            setSelectedVariant(variantKey);
            setIsLoadingVideo(false);
            lagoEventLogger.log('add_variant_clicked', {
              variantId: variantKey,
              screenType: 'vid',
            });
          })
          .catch(() => {
            setIsLoadingVideo(false);
          });
      } catch (err) {
        setIsLoadingVideo(false);
      }
      return;
    }
    const itemToAdd = { variants: {} };
    itemToAdd.variants[variantKey] = {
      title: { en: addedItemTitle },
      screenType: 'vid',
      vidUrl: { en: null },
      variantConfig: {
        variantTitle: 'default',
        isDefaultVariant: true,
        conditions: [],
      },
      dateAdded: Date.now(),
    };
    const addedItem = addScreensItemsData(itemToAdd);
    setIsLoadingVideo(true);
    const storage = window.firebase.storage();
    const videoRef = storage.ref(
      `${dataSubPath}/${addedItem.key}-${variantKey}-${
        isFromJournalEdit ? 'journal' : ''
      }`
    );
    lagoEventLogger.log('screen_add_clicked', {
      screeenId: addedItem.key,
      title: addedItemTitle,
      screenType: 'vid',
    });
    try {
      await videoRef.put(videoFile);
      videoRef
        .getDownloadURL()
        .then(res => {
          setVideoPrev(res);
          if (isFromArticleEdit) {
            const copy = JSON.parse(JSON.stringify(articleData));
            if (!copy.screens) {
              copy.screens = {};
            }
            copy.screens[addedItem.key] = Object.keys(copy.screens).length;
            database
              .ref(
                `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}`
              )
              .set(copy);
          } else if (isFromJournalCollectionEdit) {
            const copy = JSON.parse(JSON.stringify(journalCollectionData));
            if (!copy.screens) {
              copy.screens = {};
            }
            copy.screens[addedItem.key] = Object.keys(copy.screens).length;
            database
              .ref(
                `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}`
              )
              .set(copy);
          } else if (isFromGoalEdit) {
            const copy = JSON.parse(
              JSON.stringify(lessonsItemsData[selectedGoalLessonId])
            );
            if (!copy.screens) {
              copy.screens = {};
            }
            copy.screens[addedItem.key] = Object.keys(copy.screens).length;
            database
              .ref(`lago/educational/defaults/lessons/${selectedGoalLessonId}`)
              .set(copy);
          } else if (isFromJournalEdit) {
            const copy = JSON.parse(JSON.stringify(journals));
            if (!copy[selectedJournal].screens) {
              copy[selectedJournal].screens = {};
            }
            copy[selectedJournal].screens[addedItem.key] = Object.keys(
              copy[selectedJournal].screens
            ).length;
            setJournals(copy);
          } else if (isFromCheckinEdit) {
            const copy = JSON.parse(JSON.stringify(checkins));
            if (!copy[selectedChekin].screens) {
              copy[selectedChekin].screens = {};
            }
            copy[selectedChekin].screens[addedItem.key] = Object.keys(
              copy[selectedChekin].screens
            ).length;
            setCheckins(copy);
          } else {
            const copy = JSON.parse(JSON.stringify(primaryData));
            if (!copy[screenStagesSubKey[currentStage]]) {
              copy[screenStagesSubKey[currentStage]] = {};
            }
            copy[screenStagesSubKey[currentStage]][addedItem.key] = Object.keys(
              copy[screenStagesSubKey[currentStage]]
            ).length;
            setPrimaryData({
              ...copy,
            });
          }
          setNewItemKey(addedItem.key);
          setNewVariantKey(variantKey);
          setSelectedScreen(addedItem.key);
          setVideoLink(res);
          setSelectedVariant(variantKey);
          setIsLoadingVideo(false);
        })
        .catch(() => {
          setIsLoadingVideo(false);
        });
    } catch (err) {
      setIsLoadingVideo(false);
    }
  });

  const saveQuiz = useCallback(() => {
    resetScreenStateVideo();
    resetScreenStateHtml();
    const answerOptions = {};
    const correctId = [];
    for (let i = 0; i < quizCoises.length; i += 1) {
      answerOptions[quizCoises[i].key] = {
        index: i + 1,
        txt: { en: quizCoises[i].text },
      };
      if (quizCoises[i].isCorrect) {
        correctId.push(quizCoises[i].key);
      }
    }
    if (isOtherCorrect) {
      correctId.push('specialOtherId');
    }
    const copy = JSON.parse(JSON.stringify(screensItemsData));
    let defaultVariantId;
    Object.keys(screensItemsData[selectedScreen].variants).forEach(
      variantId => {
        if (
          screensItemsData[selectedScreen].variants[variantId].variantConfig
            .isDefaultVariant
        ) {
          defaultVariantId = variantId;
        }
      }
    );
    const variantKey =
      isVariantEditor && !isNewVariant ? variantToEdit : defaultVariantId;
    const htmlTags = parseTags(
      convertToRaw(quizTextQuestion.getCurrentContent()).entityMap
    );
    copy[selectedScreen].variants[variantKey].title = { en: '' };
    copy[selectedScreen].variants[variantKey].title.en = addedItemTitle;
    copy[selectedScreen].variants[variantKey].img = htmlImgPath || '';
    copy[selectedScreen].variants[variantKey].btnText = { en: '' };
    copy[selectedScreen].variants[variantKey].btnText.en = quizBtnValue;
    copy[selectedScreen].variants[variantKey].quizDetails = {};
    copy[selectedScreen].variants[variantKey].screenType = 'quiz';
    copy[selectedScreen].variants[variantKey].quizDetails.quizType = quizType;
    copy[selectedScreen].variants[
      variantKey
    ].quizDetails.answerOptions = answerOptions;
    copy[selectedScreen].variants[
      variantKey
    ].quizDetails.quizAnsweredBtnText = { en: quizBtnAfterAnswerValue };
    copy[selectedScreen].variants[variantKey].htmlTags = htmlTags;
    copy[selectedScreen].variants[variantKey].html = { en: '' };
    copy[selectedScreen].variants[variantKey].html.en = addCustomStyle(
      draftToHtml(convertToRaw(quizTextQuestion.getCurrentContent()))
    );
    copy[selectedScreen].variants[
      variantKey
    ].quizDetails.isMultiChoice = isMultipleChoise;
    if (quizType === 'exercise' || quizType === 'data') {
      if (isOtherCorrect) {
        copy[selectedScreen].variants[variantKey].quizDetails.strPlaceholder = {
          // eslint-disable-next-line no-unneeded-ternary
          en: quizOtherStr ? quizOtherStr : 'Other',
        };
      } else {
        copy[selectedScreen].variants[variantKey].quizDetails.strPlaceholder = {
          en: quizOtherStr,
        };
      }
    }
    if (quizType === 'exercise') {
      copy[selectedScreen].variants[
        variantKey
      ].quizDetails.correctAnswerExplanation = {
        en: addCustomStyle(
          draftToHtml(convertToRaw(quizAfterCorrect.getCurrentContent()))
        ),
      };
      copy[selectedScreen].variants[
        variantKey
      ].quizDetails.correctAnswerId = correctId;
      const correctAnswerExplanationTags = parseTags(
        convertToRaw(quizAfterCorrect.getCurrentContent()).entityMap
      );
      copy[selectedScreen].variants[
        variantKey
      ].quizDetails.correctAnswerExplanationTags = correctAnswerExplanationTags;
    }
    if (
      quizType === 'data' ||
      quizType === 'journal' ||
      quizType === 'emoji' ||
      quizType === 'numbered'
    ) {
      copy[selectedScreen].variants[
        variantKey
      ].quizDetails.tagId = selectedTagId;
    }
    if (quizType === 'numbered') {
      copy[selectedScreen].variants[variantKey].quizDetails.maxNum = quizMaxNum;
      copy[selectedScreen].variants[
        variantKey
      ].quizDetails.firstRatingText = firstRatingText;
      copy[selectedScreen].variants[
        variantKey
      ].quizDetails.lastRatingText = lastRatingText;
    }
    if (quizType === 'journal') {
      copy[selectedScreen].variants[variantKey].quizDetails.strPlaceholder = {
        // eslint-disable-next-line no-unneeded-ternary
        en: quizOtherStr ? quizOtherStr : 'Type something...',
      };
    }
    if (copy[selectedScreen].variants[variantKey].vidUrl) {
      copy[selectedScreen].variants[variantKey].vidUrl = null;
    }
    if (isVariantEditor) {
      copy[selectedScreen].variants[variantKey].variantConfig = {
        variantTitle,
        isDefaultVariant: false,
        conditions: [],
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      copy[selectedScreen].variants[
        variantKey
      ].variantConfig.conditions = conditions;
    }
    setScreenData(copy[selectedScreen]);
    setIsVariantEditor(false);
    setIsNewVariant(false);
    setDisplayVariantDialog(false);

    lagoEventLogger.log('screen_edited', {
      screeenId: selectedScreen,
      title: addedItemTitle,
      screenType: 'quiz',
    });
  });

  const saveNewQuiz = useCallback(() => {
    resetScreenStateVideo();
    resetScreenStateHtml();
    const answerOptions = {};
    const correctId = [];
    for (let i = 0; i < quizCoises.length; i += 1) {
      answerOptions[quizCoises[i].key] = {
        index: i + 1,
        txt: { en: quizCoises[i].text },
      };
      if (quizCoises[i].isCorrect) {
        correctId.push(quizCoises[i].key);
      }
    }
    if (isOtherCorrect) {
      correctId.push('specialOtherId');
    }
    const variantKey = Math.random()
      .toString(36)
      .substring(7);
    const htmlTags = parseTags(
      convertToRaw(quizTextQuestion.getCurrentContent()).entityMap
    );
    if (isVariantEditor) {
      const variantToAdd = {
        title: { en: addedItemTitle },
        screenType: 'quiz',
        btnText: { en: quizBtnValue },
        html: {
          en: addCustomStyle(
            draftToHtml(convertToRaw(quizTextQuestion.getCurrentContent()))
          ),
        },
        htmlTags,
        img: htmlImgPath || '',
        quizDetails: {
          quizType,
          answerOptions,
          quizAnsweredBtnText: { en: quizBtnAfterAnswerValue },
        },
        variantConfig: {
          variantTitle,
          isDefaultVariant: false,
          conditions: [],
        },
        dateAdded: Date.now(),
      };
      const conditions = tempScreenFilters.map(flter => {
        return {
          tagId: flter.tagKey,
          operator: flter.operator.name,
          val: flter.operator.inputValue,
        };
      });
      if (quizType === 'exercise') {
        variantToAdd.quizDetails.correctAnswerExplanation = {
          en: addCustomStyle(
            draftToHtml(convertToRaw(quizAfterCorrect.getCurrentContent()))
          ),
        };
        variantToAdd.quizDetails.correctAnswerId = correctId;
        const correctAnswerExplanationTags = parseTags(
          convertToRaw(quizAfterCorrect.getCurrentContent()).entityMap
        );
        variantToAdd.quizDetails.correctAnswerExplanationTags = correctAnswerExplanationTags;
      }
      variantToAdd.quizDetails.isMultiChoice = isMultipleChoise;
      if (isOtherCorrect) {
        variantToAdd.quizDetails.strPlaceholder = {
          // eslint-disable-next-line no-unneeded-ternary
          en: quizOtherStr ? quizOtherStr : 'Other',
        };
      } else {
        variantToAdd.quizDetails.strPlaceholder = {
          en: quizOtherStr,
        };
      }

      if (
        quizType === 'data' ||
        quizType === 'journal' ||
        quizType === 'emoji' ||
        quizType === 'numbered'
      ) {
        variantToAdd.quizDetails.tagId = selectedTagId;
      }
      if (quizType === 'numbered') {
        variantToAdd.quizDetails.maxNum = quizMaxNum;
        variantToAdd.quizDetails.firstRatingText = firstRatingText;
        variantToAdd.quizDetails.lastRatingText = lastRatingText;
      }
      if (quizType === 'journal') {
        variantToAdd.quizDetails.strPlaceholder = {
          // eslint-disable-next-line no-unneeded-ternary
          en: quizOtherStr ? quizOtherStr : 'Type something...',
        };
      }
      variantToAdd.variantConfig.conditions = conditions;
      const screenCopy = JSON.parse(JSON.stringify(screenData));
      screenCopy.variants[variantKey] = variantToAdd;
      setScreenData(screenCopy);
      setIsVariantEditor(false);
      setIsNewVariant(false);
      setDisplayVariantDialog(false);
      lagoEventLogger.log('add_variant_clicked', {
        variantId: variantKey,
        screenType: 'quiz',
      });
      return;
    }
    const itemToAdd = { variants: {} };
    itemToAdd.variants[variantKey] = {
      title: { en: addedItemTitle },
      screenType: 'quiz',
      btnText: { en: quizBtnValue },
      html: {
        en: addCustomStyle(
          draftToHtml(convertToRaw(quizTextQuestion.getCurrentContent()))
        ),
      },
      htmlTags,
      img: htmlImgPath || '',
      quizDetails: {
        quizType,
        answerOptions,
        quizAnsweredBtnText: { en: quizBtnAfterAnswerValue },
      },
      variantConfig: {
        variantTitle: 'default',
        isDefaultVariant: true,
        conditions: [],
      },
      dateAdded: Date.now(),
    };
    if (quizType === 'exercise') {
      itemToAdd.variants[variantKey].quizDetails.correctAnswerExplanation = {
        en: addCustomStyle(
          draftToHtml(convertToRaw(quizAfterCorrect.getCurrentContent()))
        ),
      };
      itemToAdd.variants[variantKey].quizDetails.correctAnswerId = correctId;
      const correctAnswerExplanationTags = parseTags(
        convertToRaw(quizAfterCorrect.getCurrentContent()).entityMap
      );
      itemToAdd.variants[
        variantKey
      ].quizDetails.correctAnswerExplanationTags = correctAnswerExplanationTags;
    }
    itemToAdd.variants[variantKey].quizDetails.isMultiChoice = isMultipleChoise;
    if (isOtherCorrect) {
      itemToAdd.variants[variantKey].quizDetails.strPlaceholder = {
        // eslint-disable-next-line no-unneeded-ternary
        en: quizOtherStr ? quizOtherStr : 'Other',
      };
    } else {
      itemToAdd.variants[variantKey].quizDetails.strPlaceholder = {
        en: quizOtherStr,
      };
    }
    if (
      quizType === 'data' ||
      quizType === 'journal' ||
      quizType === 'emoji' ||
      quizType === 'numbered'
    ) {
      itemToAdd.variants[variantKey].quizDetails.tagId = selectedTagId;
    }
    if (quizType === 'numbered') {
      itemToAdd.variants[variantKey].quizDetails.maxNum = quizMaxNum;
      itemToAdd.variants[
        variantKey
      ].quizDetails.firstRatingText = firstRatingText;
      itemToAdd.variants[
        variantKey
      ].quizDetails.lastRatingText = lastRatingText;
    }
    if (quizType === 'journal') {
      itemToAdd.variants[variantKey].quizDetails.strPlaceholder = {
        // eslint-disable-next-line no-unneeded-ternary
        en: quizOtherStr ? quizOtherStr : 'Type something...',
      };
    }
    let addedItem = {};
    if (isFromArticleEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(articleData));
      if (!copy.screens) {
        copy.screens = {};
      }
      copy.screens[addedItem.key] = Object.keys(copy.screens).length;
      database
        .ref(
          `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}`
        )
        .set(copy);
    } else if (isFromJournalCollectionEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(journalCollectionData));
      if (!copy.screens) {
        copy.screens = {};
      }
      copy.screens[addedItem.key] = Object.keys(copy.screens).length;
      database
        .ref(
          `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}`
        )
        .set(copy);
    } else if (isFromGoalEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(
        JSON.stringify(lessonsItemsData[selectedGoalLessonId])
      );
      if (!copy.screens) {
        copy.screens = {};
      }
      copy.screens[addedItem.key] = Object.keys(copy.screens).length;
      database
        .ref(`lago/educational/defaults/lessons/${selectedGoalLessonId}`)
        .set(copy);
    } else if (isFromJournalEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(journals));
      if (!copy[selectedJournal].screens) {
        copy[selectedJournal].screens = {};
      }
      copy[selectedJournal].screens[addedItem.key] = Object.keys(
        copy[selectedJournal].screens
      ).length;
      setJournals(copy);
    } else if (isFromCheckinEdit) {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(checkins));
      if (!copy[selectedChekin].screens) {
        copy[selectedChekin].screens = {};
      }
      copy[selectedChekin].screens[addedItem.key] = Object.keys(
        copy[selectedChekin].screens
      ).length;
      setCheckins(copy);
    } else {
      addedItem = addScreensItemsData(itemToAdd);
      const copy = JSON.parse(JSON.stringify(primaryData));
      if (!copy[screenStagesSubKey[currentStage]]) {
        copy[screenStagesSubKey[currentStage]] = {};
      }
      copy[screenStagesSubKey[currentStage]][addedItem.key] = Object.keys(
        copy[screenStagesSubKey[currentStage]]
      ).length;
      setPrimaryData({
        ...copy,
      });
    }
    setSelectedScreen(addedItem.key);
    setSelectedVariant(variantKey);

    lagoEventLogger.log('screen_add_clicked', {
      screeenId: addedItem.key,
      title: addedItemTitle,
      screenType: 'quiz',
    });
  });

  // const navigatorItemClicked = (e, item, stage) => {
  //   e.stopPropagation();
  //   setSelectedItem(item);
  //   setScreenStage(screenStages[stage]);
  //   listItemClicked(item);
  // }

  const listItemClicked = useCallback(item => {
    resetScreenStateHtml();
    resetScreenStateQuiz();
    resetScreenStateVideo();
    setIsFromEditModal(true);

    if (currentStage === MAIN_INDEX && !selectedCollection) {
      if (journeysItemsData[item].isFinalized) {
        setIsFinalizedState(true);
      }
    }
    if (currentStage === MAIN_INDEX) {
      setJourneyDashboardDescription(
        journeysItemsData[item] && journeysItemsData[item].dashboardDescription
      );
    }
    if (
      currentStage < screenStages.length - 1 &&
      !isFromGoalEdit &&
      !isFromJournalEdit &&
      !isFromCheckinEdit &&
      !isFromArticleEdit &&
      !isFromJournalCollectionEdit &&
      !selectedCollection
    ) {
      if (currentStage > MAIN_INDEX) {
        const copy = [...selectedItemHistory];
        copy.push(selectedItem);
        setSelectedItemHistory(copy);
      }
      setSelectedItem(item);
      setScreenStage(screenStages[currentStage + 1]);
      if (primaryData && primaryData.title) {
        setAddedItemTitle(primaryData.title.en);
      }
    } else {
      // screen management
      setSelectedScreen(item);
      let data;
      if (
        isFromGoalEdit ||
        isFromJournalEdit ||
        isFromCheckinEdit ||
        isFromArticleEdit ||
        isFromJournalCollectionEdit ||
        selectedCollection
      ) {
        data = JSON.parse(JSON.stringify(screensItemsData));
      } else {
        data = JSON.parse(JSON.stringify(itemsData));
      }
      let selectedVariant1;
      if (variantToEdit) {
        selectedVariant1 = variantToEdit;
      } else {
        let defaultVariantId;
        Object.keys(data[item].variants).forEach(variantId => {
          if (data[item].variants[variantId].variantConfig.isDefaultVariant) {
            defaultVariantId = variantId;
          }
        });
        // eslint-disable-next-line prefer-destructuring
        selectedVariant1 = defaultVariantId;
      }
      setSelectedVariant(selectedVariant1);
      if (
        data[item].variants[selectedVariant1] &&
        data[item].variants[selectedVariant1].title &&
        data[item].variants[selectedVariant1].title.en
      ) {
        setAddedItemTitle(data[item].variants[selectedVariant1].title.en);
      }
      if (
        data[item].variants[selectedVariant1] &&
        data[item].variants[selectedVariant1].screenType === 'content'
      ) {
        setSelectedTab(0);
        resetScreenStateVideo();
        resetScreenStateQuiz();
        const html = data[item].variants[selectedVariant1].html.en;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
        setHtmlText(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        if (
          data[item].variants[selectedVariant1] &&
          data[item].variants[selectedVariant1].btnText &&
          data[item].variants[selectedVariant1].btnText.en
        ) {
          setHtmlBtnValue(data[item].variants[selectedVariant1].btnText.en);
        } else {
          setHtmlBtnValue('');
        }
        if (
          data[item].variants[selectedVariant1] &&
          data[item].variants[selectedVariant1].img
        ) {
          setHtmlImgPath(data[item].variants[selectedVariant1].img);
        } else {
          setHtmlImgPath('');
        }
      } else if (
        data[item].variants[selectedVariant1] &&
        data[item].variants[selectedVariant1].screenType === 'vid'
      ) {
        setSelectedTab(1);
        setVideoFile(null);
        resetScreenStateQuiz();
        resetScreenStateHtml();
        if (data[item].variants[selectedVariant1].vidUrl) {
          const copy = data[item].variants[selectedVariant1].vidUrl.en;
          setVideoPrev(copy);
        }
      } else if (
        data[item].variants[selectedVariant1] &&
        data[item].variants[selectedVariant1].screenType === 'quiz'
      ) {
        setSelectedTab(2);
        resetScreenStateVideo();
        resetScreenStateHtml();
        const html = data[item].variants[selectedVariant1].html.en;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          setQuizTextQuestion(EditorState.createWithContent(contentState));
        }
        if (
          data[item].variants[selectedVariant1] &&
          data[item].variants[selectedVariant1].img
        ) {
          setHtmlImgPath(data[item].variants[selectedVariant1].img);
        } else {
          setHtmlImgPath('');
        }
        setQuizType(data[item].variants[selectedVariant1].quizDetails.quizType);
        setQuizBtnValue(data[item].variants[selectedVariant1].btnText.en);
        if (
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'exercise' ||
          data[item].variants[selectedVariant1].quizDetails.quizType === 'data'
        ) {
          const choises = [];
          if (
            data[item].variants[selectedVariant1].quizDetails.correctAnswerId ||
            data[item].variants[selectedVariant1].quizDetails.quizType ===
              'data'
          ) {
            Object.keys(
              data[item].variants[selectedVariant1].quizDetails.answerOptions
            ).forEach(choiseKey => {
              const choise = {
                text:
                  data[item].variants[selectedVariant1].quizDetails
                    .answerOptions[choiseKey].txt.en,
                key: choiseKey,
                index:
                  data[item].variants[selectedVariant1].quizDetails
                    .answerOptions[choiseKey].index,
              };
              if (
                data[item].variants[selectedVariant1].quizDetails.quizType ===
                'exercise'
              ) {
                choise.isCorrect = data[item].variants[
                  selectedVariant1
                ].quizDetails.correctAnswerId.includes(choiseKey);
              }
              choises.push(choise);
              if (
                data[item].variants[selectedVariant1].quizDetails
                  .correctAnswerId &&
                data[item].variants[
                  selectedVariant1
                ].quizDetails.correctAnswerId.includes('specialOtherId')
              ) {
                setIsOtherCorrect(true);
              }
            });
          }

          choises.sort((a, b) => a.index - b.index);
          setQuizCoises(choises);
          setIsMultipleChoise(
            data[item].variants[selectedVariant1].quizDetails.isMultiChoice
          );
        }
        if (
          data[item].variants[selectedVariant1].quizDetails.strPlaceholder &&
          data[item].variants[selectedVariant1].quizDetails.strPlaceholder.en
        ) {
          setQuizOtherStr(
            data[item].variants[selectedVariant1].quizDetails.strPlaceholder.en
          );
          if (
            data[item].variants[selectedVariant1].quizDetails.quizType ===
            'data'
          ) {
            setIsOtherCorrect(true);
          }
        }
        if (
          data[item].variants[selectedVariant1].quizDetails.quizType ===
          'exercise'
        ) {
          if (
            data[item].variants[selectedVariant1].quizDetails
              .correctAnswerExplanation
          ) {
            const correctAnswerExplanation =
              data[item].variants[selectedVariant1].quizDetails
                .correctAnswerExplanation.en;
            const contentBlock1 = htmlToDraft(correctAnswerExplanation);
            if (contentBlock1) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock1.contentBlocks
              );
              setQuizAfterCorrect(EditorState.createWithContent(contentState));
            }
          }
        }
        if (
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'data' ||
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'exercise'
        ) {
          if (
            data[item].variants[selectedVariant1].quizDetails
              .quizAnsweredBtnText.en
          ) {
            setQuizBtnAfterAnswerValue(
              data[item].variants[selectedVariant1].quizDetails
                .quizAnsweredBtnText.en
            );
          }
        }
        if (
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'data' ||
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'journal' ||
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'emoji' ||
          data[item].variants[selectedVariant1].quizDetails.quizType ===
            'numbered'
        ) {
          if (data[item].variants[selectedVariant1].quizDetails.tagId) {
            setSelectedTagId(
              data[item].variants[selectedVariant1].quizDetails.tagId
            );
          }
        }
        if (
          data[item].variants[selectedVariant1].quizDetails.quizType ===
          'numbered'
        ) {
          if (data[item].variants[selectedVariant1].quizDetails.maxNum) {
            setQuizMaxNum(
              data[item].variants[selectedVariant1].quizDetails.maxNum
            );
          }
          if (
            data[item].variants[selectedVariant1].quizDetails.firstRatingText ||
            data[item].variants[selectedVariant1].quizDetails
              .firstRatingText === ''
          ) {
            setFirstRatingText(
              data[item].variants[selectedVariant1].quizDetails.firstRatingText
            );
          }
          if (
            data[item].variants[selectedVariant1].quizDetails.lastRatingText ||
            data[item].variants[selectedVariant1].quizDetails.lastRatingText ===
              ''
          ) {
            setLastRatingText(
              data[item].variants[selectedVariant1].quizDetails.lastRatingText
            );
          }
        }
      }
    }
  });

  const deleteVariant = useCallback(variantKey => {
    lagoEventLogger.log('delete_variant_confirmed', { variantId: variantKey });
    const screensCopy = JSON.parse(JSON.stringify(screenData));
    delete screensCopy.variants[variantKey];
    setScreenData(screensCopy);
    setVariantToEdit('');
    setDisplayVariantDialog(false);
  });

  useEffect(() => {
    if (selectedScreen) {
      listItemClicked(selectedScreen);
    }
  }, [variantToEdit, selectedVariant]);

  useEffect(() => {
    setHtmlText(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  const backButtonPressed = useCallback(() => {
    lagoEventLogger.log('back_button_pressed');
    if (currentStage === MAIN_INDEX) {
      setIntroScreen(introScreenOptions[0]);
      return;
    }
    setScreenStage(screenStages[currentStage - 1]);
    const copy = [...selectedItemHistory];
    const lastItem = copy.pop();
    setSelectedItemHistory(copy);
    setSelectedTab(0);
    setSelectedScreen(null);
    // setEstimatedMins(0);
    resetScreenStateVideo();
    resetScreenStateHtml();
    resetScreenStateQuiz();
    setIsScreenEditor(false);
    setSelectedGoalLessonId('');
    setPreviewHieght(null);
    if (currentStage > MAIN_INDEX) {
      setSelectedItem(lastItem);
    } else {
      setSelectedItem(null);
    }
    if (currentStage === JOURNEY_INDEX && sessionStorage.isAdmin !== 'true') {
      setIsFinalizedState(false);
    }
  });

  const renderBreadcrumbs = useCallback(() => {
    let breadCrumb = '';
    for (let i = 0; i <= screenStages.indexOf(screenStage); i += 1) {
      breadCrumb = `${breadCrumb} ${screenStages[i]} →`;
    }
    return <div className={styles.breadCrumb}>{breadCrumb}</div>;
  });

  const calcTitle = useCallback(() => {
    if (screenStage === 'Main') {
      return screenStage;
    }
    if (primaryData && primaryData.title && primaryData.title.en) {
      return primaryData.title.en;
    }
    return screenStage;
  });

  // const uploadImgEditorCallback = async file => {
  //   const storage = window.firebase.storage();
  //   const imgRef = storage.ref(`${dataSubPath}/${selectedScreen}`);
  //   await imgRef.put(file);
  //   return imgRef.getDownloadURL().then(res => {
  //     return { data: { link: res } };
  //   });
  // };

  let filteredData = {};
  if (
    currentStage >= JOURNEY_INDEX &&
    currentStage <= LESSON_INDEX &&
    primaryData
  ) {
    if (primaryData[screenStagesSubKey[currentStage]]) {
      const filteredDataKeys = Object.keys(itemsData).filter(key =>
        Object.keys(primaryData[screenStagesSubKey[currentStage]]).includes(key)
      );
      filteredDataKeys.sort(
        (a, b) =>
          primaryData[screenStagesSubKey[currentStage]][a] -
          primaryData[screenStagesSubKey[currentStage]][b]
      );
      filteredDataKeys.forEach(key => {
        filteredData[key] = itemsData[key];
      });
    }
  } else {
    filteredData = itemsData;
  }

  const renderElementImage = () => {
    if (currentStage >= MINI_COURSE_INDEX || currentStage === JOURNEY_INDEX) {
      if (!isLoadingImage) {
        return (
          <div className={styles.upload_image_container}>
            <img
              className={styles.lago_control_img}
              src={
                primaryData && primaryData.img ? primaryData.img : placeHolder
              }
              alt="placeHolder"
            />
            <div className={styles.upload_image_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-input').click();
                }}
                color="primary"
                variant="contained"
                disabled={isFinalizedState}
              >
                upload image
              </Button>
              <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px' }}
                disabled={isFinalizedState}
              >
                select uploaded
              </Button>
              {primaryData && primaryData.img ? (
                <Button
                  onClick={removeImage}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                  disabled={isFinalizedState}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
        );
      }
      return <CircularProgress />;
    }
    return <div className={styles.lago_control_img} />;
  };

  const orderIndexes = useCallback((sourceIdx, destinationIdx, draggableId) => {
    let copy;
    let copyKey;

    if (selectedArticleLesson) {
      copy = JSON.parse(JSON.stringify(articleData));
      copyKey = 'screens';
    } else if (selectedCollectionJournal) {
      copy = JSON.parse(JSON.stringify(journalCollectionData));
      copyKey = 'screens';
    } else if (isGoalConfigDialogOpen) {
      copy = JSON.parse(JSON.stringify(lessonsItemsData[selectedGoalLessonId]));
      copyKey = 'screens';
    } else if (isJournalDialogOpen && selectedJournal) {
      copy = JSON.parse(JSON.stringify(journals[selectedJournal]));
      copyKey = 'screens';
    } else if (isCheckinsDialogOpen && selectedChekin) {
      copy = JSON.parse(JSON.stringify(checkins[selectedChekin]));
      copyKey = 'screens';
    } else {
      copy = JSON.parse(JSON.stringify(primaryData));
      copyKey = screenStagesSubKey[currentStage];
    }
    // const copy = JSON.parse(JSON.stringify(primaryData));
    if (sourceIdx > destinationIdx) {
      Object.keys(copy[copyKey]).forEach(key => {
        if (
          copy[copyKey][key] >= destinationIdx &&
          copy[copyKey][key] <= sourceIdx
        ) {
          copy[copyKey][key] += 1;
        }
        if (key === draggableId) {
          copy[copyKey][key] = destinationIdx;
        }
      });
    }
    if (sourceIdx < destinationIdx) {
      Object.keys(copy[copyKey]).forEach(key => {
        if (
          copy[copyKey][key] <= destinationIdx &&
          copy[copyKey][key] >= sourceIdx
        ) {
          copy[copyKey][key] -= 1;
        }
        if (key === draggableId) {
          copy[copyKey][key] = destinationIdx;
        }
      });
    }
    if (selectedArticleLesson) {
      setArticleData(copy);
    } else if (selectedCollectionJournal) {
      setJournalCollectionData(copy);
    } else if (isGoalConfigDialogOpen) {
      database
        .ref(`lago/educational/defaults/lessons/${selectedGoalLessonId}`)
        .set(copy);
    } else if (isJournalDialogOpen && selectedJournal) {
      database
        .ref(`lago/educational/defaults/journals/${selectedJournal}`)
        .set(copy);
    } else if (isCheckinsDialogOpen && selectedChekin) {
      database
        .ref(`lago/educational/defaults/checkins/${selectedChekin}`)
        .set(copy);
    } else {
      setPrimaryData(copy);
    }
  });

  const handleOnDragEnd = useCallback(result => {
    if (isFinalizedState) return;
    if (!result.destination) return;
    orderIndexes(
      result.source.index,
      result.destination.index,
      result.draggableId
    );
  });

  const handleOnDragEndCollection = result => {
    if (
      Object.keys(resourcesDrafts).length !==
      Object.keys(resourcesPublished).length
    ) {
      setShowOrderAlert(true);
      setTimeout(() => {
        setShowOrderAlert(false);
      }, 3000);
      return;
    }
    const copy = JSON.parse(JSON.stringify(resourcesDrafts));

    if (result.source.index > result.destination.index) {
      Object.keys(copy).forEach(key => {
        if (
          copy[key].index >= result.destination.index &&
          copy[key].index <= result.source.index
        ) {
          copy[key].index += 1;
        }
        if (key === result.draggableId) {
          copy[key].index = result.destination.index;
        }
      });
    }
    if (result.source.index < result.destination.index) {
      Object.keys(copy).forEach(key => {
        if (
          copy[key].index <= result.destination.index &&
          copy[key].index >= result.source.index
        ) {
          copy[key].index -= 1;
        }
        if (key === result.draggableId) {
          copy[key].index = result.destination.index;
        }
      });
    }
    setResourcesDrafts(copy);
    setResourcesPublished(copy);
  };

  const renderItemsList = () => {
    if (currentStage === MAIN_INDEX) {
      return (
        <div className={styles.lago_control_list_container}>
          {itemsData
            ? Object.keys(filteredData).map(key => (
                <span key={key}>
                  <LagoListItem
                    id={key}
                    element={itemsData[key]}
                    onItemClicked={() => listItemClicked(key)}
                    onDeleteItemClicked={e => {
                      e.stopPropagation();
                      setKeyToDelete(key);
                      setDisplayDeleteDialog(true);
                    }}
                    currentStage={currentStage}
                    onEditItemClicked={onEditItemClicked}
                    isFinalizedState={isFinalizedState}
                  />
                </span>
              ))
            : null}
        </div>
      );
    }
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={dataSubPath}>
          {provided => (
            <div
              className={styles.lago_control_list_container}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemsData
                ? Object.keys(filteredData).map((key, index) => {
                    if (
                      primaryData &&
                      primaryData[screenStagesSubKey[currentStage]] &&
                      primaryData[screenStagesSubKey[currentStage]] &&
                      primaryData[screenStagesSubKey[currentStage]][key] >= 0
                    ) {
                      return (
                        <Draggable
                          key={key}
                          draggableId={key}
                          index={
                            primaryData &&
                            primaryData[screenStagesSubKey[currentStage]] &&
                            primaryData[screenStagesSubKey[currentStage]][key]
                              ? primaryData[screenStagesSubKey[currentStage]][
                                  key
                                ]
                              : index
                          }
                        >
                          {provided1 => (
                            <div
                              ref={provided1.innerRef}
                              {...provided1.draggableProps}
                              {...provided1.dragHandleProps}
                            >
                              <LagoListItem
                                id={key}
                                element={itemsData[key]}
                                onItemClicked={() => listItemClicked(key)}
                                onDeleteItemClicked={e => {
                                  e.stopPropagation();
                                  setKeyToDelete(key);
                                  setDisplayDeleteDialog(true);
                                }}
                                currentStage={currentStage}
                                onEditItemClicked={onEditItemClicked}
                                onAddNewVariantClicked={e =>
                                  onAddNewVariantClicked(e, key)
                                }
                                editVariant={editVariant}
                                variantClicked={variantClicked}
                                noVariantClicked={noVariantClicked}
                                onMoveModuleClicked={onMoveModuleClicked}
                                isFinalizedState={isFinalizedState}
                                selectedScreen={selectedScreen}
                                setPreviewHieght={setPreviewHieght}
                                displayNotificationsModal={
                                  displayNotificationsModal
                                }
                                setSelectedNotificationsLesson={
                                  setSelectedNotificationsLesson
                                }
                                onLessonHover={onLessonHover}
                                lessonNotificationTitleTooltip={
                                  lessonNotificationTitleTooltip
                                }
                                lessonNotificationBodyTooltip={
                                  lessonNotificationBodyTooltip
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                    return null;
                  })
                : null}
              {provided.placeholder}
              {isLoadingVideo ? <CircularProgress /> : null}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const onSaveJournal = useCallback(
    (newJournalTitle, newJournalAllowFeedback, newJournalType) => {
      const copy = JSON.parse(JSON.stringify(journals));
      copy[selectedJournal].title = {};
      copy[selectedJournal].title.en = newJournalTitle;
      copy[selectedJournal].allowFeedback = {};
      copy[
        selectedJournal
      ].allowFeedback.clinicFeedback = newJournalAllowFeedback;
      copy[selectedJournal].journalType = newJournalType;
      copy[selectedJournal].estimatedMins = DEFAULT_ESTIMATED_MINUTES;
      copy[selectedJournal].isEnabled = true;
      setJournals(copy);
      setIsJournalDialogOpen(false);
      setSelectedJournal('');
      setIsFromJournalEdit(false);
      setJournalTitle('');
      setJournalAllowFeedback(true);
      setJournalType('journal');
      // setJournalEstimatedMinutes(0);
      resetScreenStateVideo();
      resetScreenStateHtml();
      resetScreenStateQuiz();
      lagoEventLogger.log('journal_clicked');
    }
  );

  const setArticleEditDialogOpen = useCallback(() => {
    setIsScreenEditor(true);
    setIsFromEditModal(false);
    setIsFromArticleEdit(true);
    setIsDialogOpen(true);
    setAddedItemTitle('');
    setEditorState(EditorState.createEmpty());
    resetScreenStateHtml();
    resetScreenStateQuiz();
    resetScreenStateVideo();
  });

  const setJournalCollectionEditDialogOpen = useCallback(() => {
    setIsScreenEditor(true);
    setIsFromEditModal(false);
    setIsFromJournalCollectionEdit(true);
    setIsDialogOpen(true);
    setAddedItemTitle('');
    setEditorState(EditorState.createEmpty());
    resetScreenStateHtml();
    resetScreenStateQuiz();
    resetScreenStateVideo();
  });

  const setJournalEditDialogOpen = useCallback(() => {
    setIsScreenEditor(true);
    setIsFromEditModal(false);
    setIsFromJournalEdit(true);
    setIsDialogOpen(true);
    setAddedItemTitle('');
    setEditorState(EditorState.createEmpty());
    resetScreenStateHtml();
    resetScreenStateQuiz();
    resetScreenStateVideo();
  });

  const setGoalEditDialogOpen = useCallback(() => {
    setIsScreenEditor(true);
    setIsFromEditModal(false);
    setIsDialogOpen(true);
    setIsFromGoalEdit(true);
    setAddedItemTitle('');
    setEditorState(EditorState.createEmpty());
    resetScreenStateHtml();
    resetScreenStateQuiz();
    resetScreenStateVideo();
  });

  const setCheckinEditDialogOpen = useCallback(() => {
    setIsScreenEditor(true);
    setIsFromEditModal(false);
    setIsDialogOpen(true);
    setIsFromCheckinEdit(true);
    setAddedItemTitle('');
    setEditorState(EditorState.createEmpty());
    resetScreenStateHtml();
    resetScreenStateQuiz();
    resetScreenStateVideo();
  });

  const [itemJournalId, setItemJournalId] = useState([]);

  useEffect(() => {
    // setItemJournalId([]);
    let copy = [];
    if (currentStage >= JOURNEY_INDEX && currentStage <= MODULE_INDEX) {
      // eslint-disable-next-line no-unused-expressions
      journals &&
        Object.keys(journals).forEach(journalId => {
          if (currentStage === JOURNEY_INDEX) {
            if (
              journals[journalId].journeyId === selectedItem &&
              !journals[journalId].courseId &&
              !journals[journalId].moduleId
            ) {
              copy.push(journalId);
              copy = [...new Set(copy)];
            }
          }
          if (currentStage === MINI_COURSE_INDEX) {
            if (
              journals[journalId].courseId === selectedItem &&
              !journals[journalId].moduleId
            ) {
              copy.push(journalId);
              copy = [...new Set(copy)];
            }
          }
          if (currentStage === MODULE_INDEX) {
            if (journals[journalId].moduleId === selectedItem) {
              copy.push(journalId);
              copy = [...new Set(copy)];
            }
          }
        });
    }
    setItemJournalId(copy);
  }, [primaryData, journals]);

  const calcRestrictedToEnableGoal = useCallback(() => {
    const checkGoalId =
      lessonsItemsData &&
      lessonsItemsData[selectedGoalLessonId] &&
      lessonsItemsData[selectedGoalLessonId].goalId;
    return (
      !!(
        selectedGoalLessonId &&
        lessonsItemsData[selectedGoalLessonId].screens &&
        Object.keys(lessonsItemsData[selectedGoalLessonId].screens).length >= 1
      ) &&
      goals[checkGoalId].title.en &&
      goals[checkGoalId].instructions.en
    );
  });

  const calcGoalImg = useCallback(() => {
    const goalId =
      lessonsItemsData &&
      lessonsItemsData[selectedGoalLessonId] &&
      lessonsItemsData[selectedGoalLessonId].goalId;
    return goals && goals[goalId] && goals[goalId].img;
  });

  const [goalCheckbox, setGoalCheckbox] = useState(false);

  const calcIfGoalEnabled = useCallback(lessonId => {
    let goalLessonId;
    if (lessonId) {
      goalLessonId = lessonId;
    } else {
      goalLessonId = selectedGoalLessonId;
    }
    const checkGoalId =
      lessonsItemsData &&
      lessonsItemsData[goalLessonId] &&
      lessonsItemsData[goalLessonId].goalId;
    setGoalCheckbox(goals[checkGoalId] && goals[checkGoalId].isEnabled);
  });

  useEffect(() => {
    if (screenStage === 'Module') {
      if (primaryData.moduleDescription && primaryData.moduleDescription.en) {
        setModuleDescription(primaryData.moduleDescription.en);
      } else {
        setModuleDescription('');
      }
      let isGoalExisct = false;
      if (primaryData.lessons) {
        Object.keys(primaryData.lessons).forEach(lessonId => {
          if (itemsData[lessonId] && itemsData[lessonId].goalId) {
            setSelectedGoalLessonId(lessonId);
            calcIfGoalEnabled(lessonId);
            isGoalExisct = true;
          }
        });
      }
      if (!isGoalExisct) {
        setGoalCheckbox(false);
      }
    }
  }, [primaryData, goals]);

  const toggleGoalEnabled = useCallback(val => {
    const checkGoalId =
      lessonsItemsData &&
      lessonsItemsData[selectedGoalLessonId] &&
      lessonsItemsData[selectedGoalLessonId].goalId;
    const copy = JSON.parse(JSON.stringify(goals));
    copy[checkGoalId].isEnabled = val;
    setGoals(copy);
    calcIfGoalEnabled();
  });

  const addGoalLesson = () => {
    const copy = JSON.parse(JSON.stringify(primaryData));
    if (!copy.lessons) {
      copy.lessons = {};
    }
    if (!copy.lessons[selectedGoalLessonId]) {
      copy.lessons[selectedGoalLessonId] = PRESET_SCREEN_INDEX;
      setPrimaryData(copy);
    }
  };

  const removeGoalLesson = () => {
    const copy = JSON.parse(JSON.stringify(primaryData));
    if (copy.lessons && copy.lessons[selectedGoalLessonId]) {
      delete copy.lessons[selectedGoalLessonId];
      setPrimaryData(copy);
    }
  };

  const toggleGoalEnableClicked = useCallback(checked => {
    if (checked) {
      if (calcRestrictedToEnableGoal()) {
        addGoalLesson();
        toggleGoalEnabled(true);
      } else {
        removeGoalLesson();
        toggleGoalEnabled(false);
      }
    } else {
      removeGoalLesson();
      toggleGoalEnabled(false);
    }
  });

  const calcNumOfEmojiQuiz = useCallback(journalId => {
    let numOfEmojiQuiz = 0;
    if (
      journals[journalId] &&
      journals[journalId].screens &&
      Object.keys(journals[journalId].screens).length
    ) {
      Object.keys(journals[journalId].screens).forEach(screenId => {
        if (
          screensItemsData[screenId].variants[
            Object.keys(screensItemsData[screenId].variants)[0]
          ].screenType === 'quiz'
        ) {
          if (
            screensItemsData[screenId].variants[
              Object.keys(screensItemsData[screenId].variants)[0]
            ].quizDetails.quizType === 'emoji'
          ) {
            numOfEmojiQuiz += 1;
          }
        }
      });
    }
    return numOfEmojiQuiz;
  });

  const calcIfJournalExisct = useCallback(journalId => {
    let isJournalExisct = false;
    if (currentStage === JOURNEY_INDEX) {
      if (
        journals[journalId].journeyId === selectedItem &&
        !journals[journalId].courseId &&
        !journals[journalId].moduleId
      ) {
        if (
          journals[journalId].estimatedMins &&
          journals[journalId].title &&
          calcNumOfEmojiQuiz(journalId)
        ) {
          isJournalExisct = true;
        }
      }
    }
    if (currentStage === MINI_COURSE_INDEX) {
      if (journals[journalId].courseId === selectedItem) {
        if (
          journals[journalId].estimatedMins &&
          journals[journalId].title &&
          calcNumOfEmojiQuiz(journalId)
        ) {
          isJournalExisct = true;
        }
      }
    }
    if (currentStage === MODULE_INDEX) {
      if (journals[journalId].moduleId === selectedItem) {
        if (
          journals[journalId].estimatedMins &&
          journals[journalId].title &&
          calcNumOfEmojiQuiz(journalId)
        ) {
          isJournalExisct = true;
        }
      }
    }
    return isJournalExisct;
  });

  const toggleJournalEnabled = useCallback((val, journalId) => {
    const copy = JSON.parse(JSON.stringify(journals));
    copy[journalId].isEnabled = val;
    setJournals(copy);
  });

  const toggleJournalEnableClicked = useCallback((checked, journalId) => {
    if (checked) {
      if (calcIfJournalExisct(journalId)) {
        toggleJournalEnabled(true, journalId);
      } else {
        toggleJournalEnabled(false, journalId);
      }
    } else {
      toggleJournalEnabled(false, journalId);
    }
  });

  const calcNumOfEnabledJournals = useCallback(() => {
    let answer = false;
    itemJournalId.forEach(journalId => {
      if (journals && journals[journalId] && journals[journalId].isEnabled) {
        answer = true;
      }
    });
    return answer;
  });

  const resetThresholdsState = useCallback(() => {
    setMinimumPracticeDaysInput(0);
    setMinimumJournalEntriesInput(0);
    setIsThresholdEnable(false);
  });

  const resetCheckinState = useCallback(() => {
    setIsCheckinEnabled(false);
    setCheckinSkill('');
    setSelectedChekin('');
  });

  useEffect(() => {
    if (screenStage === 'Module') {
      if (primaryData.successThresholds) {
        setMinimumPracticeDaysInput(
          primaryData.successThresholds.minPracticeDays
        );
        setMinimumJournalEntriesInput(
          primaryData.successThresholds.minJournalEntries
        );
        setIsThresholdEnable(primaryData.successThresholds.isEnabled);
      }
      if (primaryData.checkinId) {
        setIsCheckinEnabled(checkins[primaryData.checkinId].isEnabled);
        setCheckinSkill(checkins[primaryData.checkinId].skillName.en);
        setSelectedChekin(primaryData.checkinId);
      }
    } else {
      resetThresholdsState();
      resetCheckinState();
    }
  }, [primaryData]);

  const onSuccessThresholdsSave = useCallback(() => {
    database
      .ref(
        `lago/educational/defaults/modules/${selectedItem}/successThresholds`
      )
      .set({
        minPracticeDays: minimumPracticeDaysInput,
        minJournalEntries: minimumJournalEntriesInput,
        isEnabled: true,
      });
    setIsThresholdEnable(true);
  });

  const toggleSuccessThresholdEnableClicked = useCallback(checked => {
    database
      .ref(
        `lago/educational/defaults/modules/${selectedItem}/successThresholds/isEnabled`
      )
      .set(checked);
    setIsThresholdEnable(checked);
  });

  const toggleCheckinEnableClicked = useCallback(checked => {
    database
      .ref(`lago/educational/defaults/checkins/${selectedChekin}/isEnabled`)
      .set(checked);
    setIsCheckinEnabled(checked);
  });

  const calcCheckinNumScreens = useCallback(() => {
    if (
      checkins &&
      primaryData &&
      primaryData.checkinId &&
      checkins[primaryData.checkinId] &&
      checkins[primaryData.checkinId].screens
    ) {
      return Object.keys(checkins[primaryData.checkinId].screens).length;
    }
    return 0;
  });

  const addCollection = () => {
    addResourcesDraft({
      resourceName: { en: collectionTitle },
      instructionText: { en: collectionDescription },
      index: Object.keys(resourcesDrafts).length,
    });
    setCollectionTitle('');
    setCollectionDescription('');
    setAddCollectionModalOpen(false);
  };

  const onCollectionClicked = collectionId => {
    setSelectedCollection(collectionId);
    setEditCollectionDialogOpen(true);
  };

  const deleteCollectionClicked = (e, resourceId) => {
    e.stopPropagation();
    setCollectionToDelete(resourceId);
    setKeyToDelete(resourceId);
    setDisplayDeleteDialog(true);
  };

  const saveEditCollectionTitleClicked = titleInput => {
    const copy = JSON.parse(JSON.stringify(collectionData));
    copy.resourceName.en = titleInput;
    setCollectionData(copy);
  };

  const saveEditCollectionDescriptionClicked = descriptionInput => {
    const copy = JSON.parse(JSON.stringify(collectionData));
    if (!copy.instructionText) {
      copy.instructionText = { en: '' };
    }
    copy.instructionText.en = descriptionInput;
    setCollectionData(copy);
  };

  const addArticle = newArticeTitle => {
    const copy = JSON.parse(JSON.stringify(collectionData));
    const articleId = Math.random()
      .toString(36)
      .substring(7);
    if (!copy.articles) {
      copy.articles = {};
    }
    copy.articles[articleId] = {
      title: {
        en: newArticeTitle,
      },
      isEnabled: true,
      lastEdited: Date.now(),
      audience: 'all',
    };
    setCollectionData(copy);
  };

  const addCollectionJournal = newJournalTitle => {
    const copy = JSON.parse(JSON.stringify(collectionData));
    const journalId = Math.random()
      .toString(36)
      .substring(7);
    if (!copy.journals) {
      copy.journals = {};
    }
    copy.journals[journalId] = {
      title: {
        en: newJournalTitle,
      },
      allowFeedback: true,
      isEnabled: true,
      lastEdited: Date.now(),
      journalType: 'journal',
    };
    setCollectionData(copy);
  };

  const onArticleLessonClicked = articleLessonId => {
    setSelectedArticleLesson(articleLessonId);
  };

  const onCollectionJournalClicked = journalId => {
    setSelectedCollectionJournal(journalId);
  };

  const updateLastEditArticle = () => {
    const editDate = Date.now();
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}/lastEdited`
      )
      .set(editDate);
  };

  const updateLastEditJournal = () => {
    const editDate = Date.now();
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}/lastEdited`
      )
      .set(editDate);
  };

  const publishArticleClicked = () => {
    const publishDate = Date.now();
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}/lastEdited`
      )
      .set(publishDate);
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}/lastPublishDate`
      )
      .set(publishDate);
    let newCollection = {};
    if (!publishedCollectionData) {
      newCollection = {
        resourceName: { en: collectionData.resourceName.en },
      };
      setPublishedCollectionData(newCollection);
    }
    // const copy = publishedCollectionData
    //   ? JSON.parse(JSON.stringify(collectionData))
    //   : {
    //       resourceName: { en: collectionData.resourceName.en },
    //       instructionText: { en: collectionData.instructionText.en },
    //       index: collectionData.index,
    //       img: collectionData.img,
    //     };
    // copy.resourceName.en = collectionData.resourceName.en;
    // copy.instructionText = {};
    // copy.instructionText.en = collectionData.instructionText
    //   ? collectionData.instructionText.en
    //   : '';
    // copy.index = collectionData.index;
    // copy.img = collectionData.img ? collectionData.img : null;
    // setPublishedCollectionData(copy);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/articles/${selectedArticleLesson}`
      )
      .set(articleData);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/articles/${selectedArticleLesson}/lastEdited`
      )
      .set(publishDate);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/articles/${selectedArticleLesson}/lastPublishDate`
      )
      .set(publishDate);
  };

  const publishJournalClicked = () => {
    const publishDate = Date.now();
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}/lastEdited`
      )
      .set(publishDate);
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}/lastPublishDate`
      )
      .set(publishDate);
    let newCollection = {};
    if (!publishedCollectionData) {
      newCollection = {
        resourceName: { en: collectionData.resourceName.en },
      };
      setPublishedCollectionData(newCollection);
    }
    // const copy = publishedCollectionData
    //   ? JSON.parse(JSON.stringify(collectionData))
    //   : {
    //       resourceName: { en: collectionData.resourceName.en },
    //       instructionText: { en: collectionData.instructionText.en },
    //       index: collectionData.index,
    //       img: collectionData.img,
    //     };
    // copy.resourceName.en = collectionData.resourceName.en;
    // copy.instructionText = {};
    // copy.instructionText.en = collectionData.instructionText
    //   ? collectionData.instructionText.en
    //   : '';
    // copy.index = collectionData.index;
    // copy.img = collectionData.img ? collectionData.img : null;
    // setPublishedCollectionData(copy);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/journals/${selectedCollectionJournal}`
      )
      .set(journalCollectionData);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/journals/${selectedCollectionJournal}/lastEdited`
      )
      .set(publishDate);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/journals/${selectedCollectionJournal}/lastPublishDate`
      )
      .set(publishDate);
  };

  const unpublishArticleClicked = () => {
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}/lastPublishDate`
      )
      .set(null);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/articles/${selectedArticleLesson}`
      )
      .set(null);
  };

  const unpublishJournalClicked = () => {
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}/lastPublishDate`
      )
      .set(null);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/journals/${selectedCollectionJournal}`
      )
      .set(null);
  };

  const saveCollectionData = () => {
    let newCollection = {};
    if (!publishedCollectionData) {
      newCollection = {
        resourceName: { en: collectionData.resourceName.en },
      };
      setPublishedCollectionData(newCollection);
    }
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/resourceName`
      )
      .set({ en: collectionData.resourceName.en });
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/instructionText`
      )
      .set(
        collectionData.instructionText && collectionData.instructionText.en
          ? { en: collectionData.instructionText.en }
          : null
      );
    database
      .ref(
        `lago/educational/defaults/resources/drafts/${selectedCollection}/index`
      )
      .set(collectionData.index);
    database
      .ref(
        `lago/educational/defaults/resources/published/${selectedCollection}/img`
      )
      .set(collectionData.img ? collectionData.img : null);
  };

  const selectCategoryClicked = () => {
    setToggleCategoriesDialog(true);
  };

  const onAddCategory = categoryName => {
    addCategory({ name: { en: categoryName } });
  };

  const onSaveCategoriesClicked = selectedCategoriesIds => {
    if (selectedCollectionJournal) {
      database
        .ref(
          `lago/educational/defaults/resources/drafts/${selectedCollection}/journals/${selectedCollectionJournal}/categoryIds`
        )
        .set(selectedCategoriesIds);
    }
    if (selectedArticleLesson) {
      database
        .ref(
          `lago/educational/defaults/resources/drafts/${selectedCollection}/articles/${selectedArticleLesson}/categoryIds`
        )
        .set(selectedCategoriesIds);
    }
  };

  const listEl = useRef(null);

  const renderMoreItems = () => {
    if (
      currentStage === MAIN_INDEX &&
      introScreen === introScreenOptions[2] &&
      resourcesDrafts
    ) {
      const sortedDrafts = Object.keys(resourcesDrafts).sort(
        (a, b) => resourcesDrafts[a].index - resourcesDrafts[b].index
      );
      return (
        <div className={styles.more_items_container}>
          <div className={styles.more_items_title} ref={listEl}>
            Resource collections
          </div>
          <DragDropContext onDragEnd={handleOnDragEndCollection}>
            <Droppable droppableId={resourcesDrafts}>
              {provided => (
                <div
                  // className={styles.lago_control_list_container}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={{ width: '50%' }}
                >
                  {sortedDrafts.map((resourceId, index) => {
                    return (
                      <Draggable
                        key={resourceId}
                        draggableId={resourceId}
                        index={
                          resourcesDrafts && resourcesDrafts.index
                            ? resourcesDrafts.index
                            : index
                        }
                      >
                        {provided1 => (
                          <div
                            ref={provided1.innerRef}
                            {...provided1.draggableProps}
                            {...provided1.dragHandleProps}
                          >
                            <div
                              onClick={() => {
                                onCollectionClicked(resourceId);
                              }}
                              key={resourceId}
                              style={{
                                fontSize: '1.5rem',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              •{' '}
                              {resourcesDrafts[resourceId].resourceName &&
                                resourcesDrafts[resourceId].resourceName.en}
                              <Button
                                onClick={e =>
                                  deleteCollectionClicked(e, resourceId)
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            onClick={() => {
              setAddCollectionModalOpen(true);
            }}
          >
            Add collection
          </Button>
          <Popover open={showOrderAlert} anchorEl={listEl.current}>
            <div
              style={{
                backgroundColor: '#fdeded',
                color: '#6f3533',
                padding: 10,
              }}
            >
              Cannot change order while having unpublished collection!
            </div>
          </Popover>
          <Dialog open={addCollectionModalOpen}>
            <div style={{ padding: '0 50px' }}>
              <DialogTitle>Collection name:</DialogTitle>
              <Input
                value={collectionTitle}
                onChange={e => {
                  setCollectionTitle(e.target.value);
                }}
                style={{ width: '100%' }}
              />
              <DialogTitle>Collection description:</DialogTitle>
              <Input
                value={collectionDescription}
                onChange={e => {
                  setCollectionDescription(e.target.value);
                }}
                style={{ width: '100%' }}
              />
              <DialogActions>
                <Button onClick={addCollection} disabled={!collectionTitle}>
                  save
                </Button>
                <Button
                  onClick={() => {
                    setAddCollectionModalOpen(false);
                    setCollectionTitle('');
                  }}
                >
                  cancel
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          <LagoCollectionDialog
            open={editCollectionDialogOpen}
            onClose={() => {
              setEditCollectionDialogOpen(false);
              setSelectedCollection('');
            }}
            collection={resourcesDrafts && resourcesDrafts[selectedCollection]}
            saveEditCollectionTitleClicked={saveEditCollectionTitleClicked}
            saveEditCollectionDescriptionClicked={
              saveEditCollectionDescriptionClicked
            }
            addArticle={addArticle}
            onArticleLessonClicked={onArticleLessonClicked}
            setKeyToDelete={setKeyToDelete}
            setDisplayDeleteDialog={setDisplayDeleteDialog}
            addJournal={addCollectionJournal}
            onCollectionJournalClicked={onCollectionJournalClicked}
            setArticleToDelete={setArticleToDelete}
            setJournalToDelete={setJournalToDelete}
            setCollectionData={setCollectionData}
            // collectionId={selectedCollection}
            // setDisplayUploadedImages={setDisplayUploadedImages}
            saveCollectionData={saveCollectionData}
          />
          <LagoArticleLessonDialog
            open={!!selectedArticleLesson}
            onClose={() => {
              setSelectedArticleLesson('');
            }}
            articleData={articleData}
            setArticleEditDialogOpen={setArticleEditDialogOpen}
            screensItemsData={screensItemsData}
            onItemClicked={listItemClicked}
            onEditItemClicked={onEditItemClicked}
            onAddNewVariantClicked={onAddNewVariantClicked}
            editVariant={editVariant}
            variantClicked={variantClicked}
            setKeyToDelete={setKeyToDelete}
            setDisplayDeleteDialog={setDisplayDeleteDialog}
            setArticleData={setArticleData}
            handleOnDragEnd={handleOnDragEnd}
            // setDisplayUploadedImages={setDisplayUploadedImages}
            isLoadingVideo={isLoadingVideo}
            publishArticleClicked={publishArticleClicked}
            parseDate={parseDate}
            unpublishArticleClicked={unpublishArticleClicked}
            updateLastEditArticle={updateLastEditArticle}
            setIsFromArticleEdit={setIsFromArticleEdit}
            selectCategoryClicked={selectCategoryClicked}
            categories={categories}
          />
          <LagoCollectionJournalDialog
            open={!!selectedCollectionJournal}
            onClose={() => {
              setSelectedCollectionJournal('');
            }}
            journalCollectionData={journalCollectionData}
            setJournalCollectionEditDialogOpen={
              setJournalCollectionEditDialogOpen
            }
            screensItemsData={screensItemsData}
            onItemClicked={listItemClicked}
            onEditItemClicked={onEditItemClicked}
            onAddNewVariantClicked={onAddNewVariantClicked}
            editVariant={editVariant}
            variantClicked={variantClicked}
            setKeyToDelete={setKeyToDelete}
            setDisplayDeleteDialog={setDisplayDeleteDialog}
            setJournalCollectionData={setJournalCollectionData}
            handleOnDragEnd={handleOnDragEnd}
            // setDisplayUploadedImages={setDisplayUploadedImages}
            isLoadingVideo={isLoadingVideo}
            publishJournalClicked={publishJournalClicked}
            parseDate={parseDate}
            unpublishJournalClicked={unpublishJournalClicked}
            updateLastEditJournal={updateLastEditJournal}
            setIsFromJournalCollectionEdit={setIsFromJournalCollectionEdit}
            selectCategoryClicked={selectCategoryClicked}
            categories={categories}
          />
          <LagoCategories
            open={toggleCategoriesDialog}
            onClose={() => {
              setToggleCategoriesDialog(false);
            }}
            categories={categories}
            onAddCategory={onAddCategory}
            onSaveCategoriesClicked={onSaveCategoriesClicked}
            itemData={
              // eslint-disable-next-line no-nested-ternary
              selectedCollectionJournal
                ? journalCollectionData
                : selectedArticleLesson
                ? articleData
                : null
            }
            resourcesDrafts={resourcesDrafts}
            resourcesPublished={resourcesPublished}
          />
        </div>
      );
    }
    return (
      <div className={styles.more_items_container}>
        {currentStage === MAIN_INDEX ? null : (
          <div className={styles.more_items_title}>More</div>
        )}
        {currentStage !== MAIN_INDEX &&
          itemJournalId.map(journalId => {
            return (
              <div key={journalId}>
                <img
                  src={
                    journals &&
                    itemJournalId &&
                    journals[journalId] &&
                    journals[journalId].img
                      ? journals[journalId].img
                      : placeHolder
                  }
                  width={30}
                  height={30}
                  style={{ marginRight: 5 }}
                />
                <span style={{ width: '150px', display: 'inline-block' }}>
                  Work
                </span>
                <Button
                  // disabled={isFinalizedState}
                  onClick={() => {
                    setIsJournalDialogOpen(true);
                    setIsFromJournalEdit(true);
                    setAddedItemTitle('');
                    setSelectedJournal(journalId);
                  }}
                >
                  <EditIcon />
                </Button>
                <Switch
                  onChange={e =>
                    toggleJournalEnableClicked(e.target.checked, journalId)
                  }
                  disabled={isFinalizedState}
                  checked={
                    journals &&
                    journals[journalId] &&
                    journals[journalId].isEnabled
                  }
                  color="primary"
                />
              </div>
            );
          })}
        {(currentStage === MODULE_INDEX && itemJournalId.length) ||
        currentStage === MAIN_INDEX ? null : (
          <div>
            <img
              src={placeHolder}
              width={30}
              height={30}
              style={{ marginRight: 5 }}
            />
            <span style={{ width: '150px', display: 'inline-block' }}>
              Work
            </span>
            <Button
              disabled={isFinalizedState}
              onClick={() => {
                setIsJournalDialogOpen(true);
                setIsFromJournalEdit(true);
                setAddedItemTitle('');
                const itemToAdd = {};
                if (currentStage === JOURNEY_INDEX) {
                  itemToAdd.journeyId = selectedItem;
                }
                if (currentStage === MINI_COURSE_INDEX) {
                  // eslint-disable-next-line prefer-destructuring
                  itemToAdd.journeyId = selectedItemHistory[0];
                  itemToAdd.courseId = selectedItem;
                }
                if (currentStage === MODULE_INDEX) {
                  // eslint-disable-next-line prefer-destructuring
                  itemToAdd.journeyId = selectedItemHistory[0];
                  // eslint-disable-next-line prefer-destructuring
                  itemToAdd.courseId = selectedItemHistory[1];
                  itemToAdd.moduleId = selectedItem;
                }
                itemToAdd.isEnabled = false;
                const addedItem = addJournal(itemToAdd);
                setSelectedJournal(addedItem.key);
              }}
            >
              <AddIcon />
            </Button>
            <Switch
              onChange={e => toggleJournalEnableClicked(e.target.checked)}
              disabled
              checked={false}
              color="primary"
            />
          </div>
        )}
        {screenStage === 'Module' ? (
          <div>
            <div>
              {/* <Checkbox checked={goalCheckbox} color="primary" disabled /> */}
              <img
                src={calcGoalImg() ? calcGoalImg() : placeHolder}
                width={30}
                height={30}
                style={{ marginRight: 5 }}
              />
              <span style={{ width: '150px', display: 'inline-block' }}>
                Goal config
              </span>
              <Button
                disabled={!selectedGoalLessonId && isFinalizedState}
                onClick={() => {
                  setIsGoalConfigDialogOpen(true);
                  setIsFromGoalEdit(true);
                  if (!selectedGoalLessonId) {
                    const newGoalId = Math.random()
                      .toString(36)
                      .substring(7);
                    const itemToAdd = {
                      title: { en: "Let's practice" },
                      estimatedMins: DEFAULT_ESTIMATED_MINUTES,
                      goalId: newGoalId,
                    };
                    const addedItem = addItemData(itemToAdd);
                    const copy = JSON.parse(JSON.stringify(primaryData));
                    if (!copy[screenStagesSubKey[currentStage]]) {
                      copy[screenStagesSubKey[currentStage]] = {};
                    }
                    copy[screenStagesSubKey[currentStage]][
                      addedItem.key
                    ] = PRESET_SCREEN_INDEX;
                    setPrimaryData({
                      ...copy,
                    });
                    setSelectedGoalLessonId(addedItem.key);
                    const goalsCopy = goals
                      ? JSON.parse(JSON.stringify(goals))
                      : {};
                    goalsCopy[newGoalId] = {
                      title: {
                        en:
                          primaryData && primaryData.title
                            ? primaryData.title.en
                            : '',
                      },
                      instructions: {
                        en: '',
                      },
                      labels: {
                        en: [
                          {
                            answer: '',
                            subTitle: '',
                          },
                        ],
                      },
                      reminderId: '',
                      isEnabled: false,
                      lessonId: addedItem.key,
                      journeyId: selectedItemHistory[0],
                    };
                    setGoals(goalsCopy);
                  }
                }}
              >
                {selectedGoalLessonId ? <EditIcon /> : <AddIcon />}
              </Button>
              <Switch
                onChange={e => toggleGoalEnableClicked(e.target.checked)}
                disabled={!calcRestrictedToEnableGoal() || isFinalizedState}
                checked={goalCheckbox}
                color="primary"
              />
            </div>
            <div>
              <img
                src={time}
                width={30}
                height={30}
                style={{ marginRight: 5 }}
              />
              <span style={{ width: '150px', display: 'inline-block' }}>
                Success thresholds
              </span>
              <Button onClick={() => setIsSuccessThresholdsDialogOpen(true)}>
                {minimumPracticeDaysInput === null ||
                minimumJournalEntriesInput === null ? (
                  <AddIcon />
                ) : (
                  <EditIcon />
                )}
              </Button>

              <Switch
                onChange={e =>
                  toggleSuccessThresholdEnableClicked(e.target.checked)
                }
                disabled={
                  minimumPracticeDaysInput === null ||
                  minimumJournalEntriesInput === null ||
                  isFinalizedState
                }
                checked={isThresholdEnable}
                color="primary"
              />
            </div>
            <div>
              <img
                src={target}
                width={30}
                height={30}
                style={{ marginRight: 5 }}
              />
              <span style={{ width: '150px', display: 'inline-block' }}>
                Check-ins
              </span>
              <Button
                disabled={
                  primaryData && !primaryData.checkinId && isFinalizedState
                }
                onClick={() => {
                  if (!primaryData.checkinId) {
                    const newCheckin = {
                      moduleId: selectedItem,
                      screens: {},
                      skillName: { en: '' },
                      isEnabled: false,
                    };
                    const addedCheckin = addCheckin(newCheckin);
                    const copy = JSON.parse(JSON.stringify(primaryData));
                    copy.checkinId = addedCheckin.key;
                    setPrimaryData(copy);
                    setSelectedChekin(addedCheckin.key);
                  } else {
                    setSelectedChekin(primaryData.checkinId);
                  }
                  setIsCheckinsDialogOpen(true);
                  setIsFromCheckinEdit(true);
                }}
              >
                {primaryData && primaryData.checkinId ? (
                  <EditIcon />
                ) : (
                  <AddIcon />
                )}
              </Button>
              <Switch
                onChange={e => toggleCheckinEnableClicked(e.target.checked)}
                disabled={
                  !checkinSkill ||
                  calcCheckinNumScreens() < 1 ||
                  isFinalizedState
                }
                checked={isCheckinEnable}
                color="primary"
              />
            </div>
          </div>
        ) : null}
        <LagoGoalConfig
          open={isGoalConfigDialogOpen}
          onClose={() => {
            setIsGoalConfigDialogOpen(false);
            setIsFromGoalEdit(false);
            // if (!goalCheckbox) {
            //   removeGoalLesson();
            // }
          }}
          goalLesson={itemsData[selectedGoalLessonId]}
          goalLessonId={selectedGoalLessonId}
          setGoalEditDialogOpen={setGoalEditDialogOpen}
          screensItemsData={screensItemsData}
          addScreensItemsData={addScreensItemsData}
          lessonsItemsData={lessonsItemsData}
          // setLessonsItemsData={setLessonsItemsData}
          // list item
          onItemClicked={listItemClicked}
          setKeyToDelete={setKeyToDelete}
          setDisplayDeleteDialog={setDisplayDeleteDialog}
          currentStage={4}
          onEditItemClicked={onEditItemClicked}
          onAddNewVariantClicked={onAddNewVariantClicked}
          editVariant={editVariant}
          variantClicked={variantClicked}
          tags={tags}
          parseTags={parseTags}
          setDisplayUploadedImages={setDisplayUploadedImages}
          isLoadingVideo={isLoadingVideo}
          handleOnDragEnd={handleOnDragEnd}
          journeyId={
            selectedItemHistory[0] ? selectedItemHistory[0] : selectedItem
          }
          isFinalizedState={isFinalizedState}
        />
        <LagoSuccessThresholds
          open={isSuccessThresholdsDialogOpen}
          onClose={() => {
            setIsSuccessThresholdsDialogOpen(false);
          }}
          onSuccessThresholdsSave={onSuccessThresholdsSave}
          minimumPracticeDaysInput={minimumPracticeDaysInput}
          setMinimumPracticeDaysInput={setMinimumPracticeDaysInput}
          minimumJournalEntriesInput={minimumJournalEntriesInput}
          setMinimumJournalEntriesInput={setMinimumJournalEntriesInput}
          isJournalExisct={calcNumOfEnabledJournals()}
          isFinalizedState={isFinalizedState}
        />
        <LagoCheckins
          open={isCheckinsDialogOpen}
          onClose={() => {
            setIsCheckinsDialogOpen(false);
            setIsFromCheckinEdit(false);
            resetScreenStateVideo();
            resetScreenStateHtml();
            resetScreenStateQuiz();
          }}
          selectedChekin={selectedChekin}
          checkins={checkins}
          setCheckins={setCheckins}
          screensItemsData={screensItemsData}
          onItemClicked={listItemClicked}
          setKeyToDelete={setKeyToDelete}
          setDisplayDeleteDialog={setDisplayDeleteDialog}
          currentStage={4}
          onEditItemClicked={onEditItemClicked}
          onAddNewVariantClicked={onAddNewVariantClicked}
          editVariant={editVariant}
          variantClicked={variantClicked}
          setCheckinEditDialogOpen={setCheckinEditDialogOpen}
          checkinSkill={checkinSkill}
          setCheckinSkill={setCheckinSkill}
          handleOnDragEnd={handleOnDragEnd}
          isLoadingVideo={isLoadingVideo}
          toggleCheckinEnableClicked={toggleCheckinEnableClicked}
          isFinalizedState={isFinalizedState}
        />

        <LagoJournalDialog
          open={isJournalDialogOpen}
          onClose={() => {
            // if (calcNumOfEmojiQuiz(selectedJournal) !== 1) {
            //   const copy = JSON.parse(JSON.stringify(journals));
            //   copy[selectedJournal].isEnabled = false;
            //   setJournals(copy);
            // }
            setIsJournalDialogOpen(false);
            setSelectedJournal('');
            setIsFromJournalEdit(false);
            setJournalTitle('');
            setJournalAllowFeedback(true);
            setJournalType('journal');
            // setJournalEstimatedMinutes(0);
            resetScreenStateVideo();
            resetScreenStateHtml();
            resetScreenStateQuiz();
          }}
          setJournalEditDialogOpen={setJournalEditDialogOpen}
          selectedJournal={selectedJournal}
          journals={journals}
          screensItemsData={screensItemsData}
          onItemClicked={listItemClicked}
          currentStage={currentStage}
          onEditItemClicked={onEditItemClicked}
          onAddNewVariantClicked={onAddNewVariantClicked}
          editVariant={editVariant}
          variantClicked={variantClicked}
          setKeyToDelete={setKeyToDelete}
          setDisplayDeleteDialog={setDisplayDeleteDialog}
          setJournals={setJournals}
          onSaveJournal={onSaveJournal}
          journalTitle={journalTitle}
          setJournalTitle={setJournalTitle}
          journalAllowFeedback={journalAllowFeedback}
          setJournalAllowFeedback={setJournalAllowFeedback}
          journalType={journalType}
          setJournalType={setJournalType}
          // journalEstimatedMinutes={journalEstimatedMinutes}
          // setJournalEstimatedMinutes={setJournalEstimatedMinutes}
          setDisplayUploadedImages={setDisplayUploadedImages}
          isLoadingVideo={isLoadingVideo}
          handleOnDragEnd={handleOnDragEnd}
          isFinalizedState={isFinalizedState}
        />
      </div>
    );
  };

  let filteredCuorsesList = [];
  if (
    journeysItemsData &&
    selectedItemHistory &&
    selectedItemHistory[0] &&
    journeysItemsData[selectedItemHistory[0]].minicourses
  ) {
    filteredCuorsesList = Object.keys(
      journeysItemsData[selectedItemHistory[0]].minicourses
    );
  }

  if (coursesItemsData) {
    if (moveModuleFilter) {
      filteredCuorsesList = filteredCuorsesList.filter(courseId =>
        coursesItemsData[courseId].title.en.includes(moveModuleFilter)
      );
    }
  }

  const onCloseEditDialog = useCallback(() => {
    if (isVariantEditor) {
      setIsDialogOpen(false);
    } else {
      setIsScreenEditor(false);
      setIsDialogOpen(false);
      setIsFromEditModal(null);
      setAddedItemTitle('');
      // if (currentStage === LESSON_INDEX) {
      //   setEstimatedMins(primaryData.estimatedMins);
      // }
      setSelectedTab(0);
      const html =
        itemsData[selectedScreen] &&
        itemsData[selectedScreen].html &&
        itemsData[selectedScreen].html.en
          ? itemsData[selectedScreen].html.en
          : '';
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
      resetScreenStateHtml();
      resetScreenStateQuiz();
      resetScreenStateVideo();
    }
    setIsFromArticleEdit(false);
    setIsFromJournalCollectionEdit(false);
  });

  const onCloseSaveEditDialog = useCallback(() => {
    setIsScreenEditor(false);
    setIsDialogOpen(false);
    setIsFromEditModal(null);
    setAddedItemTitle('');
    setSelectedTab(0);
    if (selectedArticleLesson) {
      updateLastEditArticle();
    }
    if (selectedCollectionJournal) {
      updateLastEditJournal();
    }
    setIsFromArticleEdit(false);
    setIsFromJournalCollectionEdit(false);
  });

  return (
    <div className={styles.lago_control_container}>
      {
        <Dialog open={isConnected !== null ? !isConnected : false} fullScreen>
          no internet!
        </Dialog>
      }
      {
        <Dialog open={displayPermissionModal} fullScreen>
          permission denied!
        </Dialog>
      }
      {
        <Dialog open={introScreen === introScreenOptions[0]} fullWidth>
          <DialogTitle>
            <span style={{ fontSize: '2em' }}>LAGO Manager</span>
          </DialogTitle>
          <DialogContent>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: 300 }}
            >
              <div
                className={styles.intro_item}
                onClick={() => {
                  setIntroScreen(introScreenOptions[1]);
                }}
              >
                • Journeys
              </div>
              <div
                className={styles.intro_item}
                onClick={() => {
                  setIntroScreen(introScreenOptions[2]);
                }}
              >
                • Resource collections
              </div>
            </div>
          </DialogContent>
        </Dialog>
      }
      {/* <LagoNavigator /> */}
      <div className={styles.navigator_container}>
        <Button
          onClick={backButtonPressed}
          disabled={isLoadingVideo}
          style={{
            width: '30px',
            height: '30px',
            alignSelf: 'flex-start',
            marginTop: '10px',
            marginRight: '20px',
            marginBottom: '10px',
          }}
          color="primary"
          variant="contained"
        >
          <ArrowBackIcon />
        </Button>
        {renderBreadcrumbs()}
      </div>
      {introScreen === introScreenOptions[1] ? (
        <div className={styles.lago_control_title}>
          {calcTitle()}
          {currentStage > MAIN_INDEX ? (
            <Button
              onClick={() => {
                setIsFromEditModal(true);
                setIsDialogOpen(true);
                setAddedItemTitle(primaryData.title.en);
              }}
              disabled={isFinalizedState}
            >
              <EditIcon />
            </Button>
          ) : null}
        </div>
      ) : null}
      {currentStage === JOURNEY_INDEX ? (
        <div className={styles.lago_control_title}>
          {journeyDashboardDescription}
        </div>
      ) : null}
      {currentStage === MODULE_INDEX && toggleEditmoduleDescription ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            multiline
            placeholder="No module description available"
            value={moduleDescription}
            onChange={e => {
              if (e.target.value.length <= 140) {
                setModuleDescription(e.target.value);
              }
            }}
          />
          <Button onClick={saveModuleDescription}>
            <SaveIcon />
          </Button>
          <Button
            onClick={() => {
              setToggleEditModuleDescription(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      ) : null}
      {currentStage === MODULE_INDEX && !toggleEditmoduleDescription ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {moduleDescription || 'No module description available'}
          </div>
          <Button
            onClick={() => {
              setToggleEditModuleDescription(true);
            }}
          >
            <EditIcon />
          </Button>
        </div>
      ) : null}
      {/* {currentStage === LESSON_INDEX ? (
        <div>Estimated minutes: {estimatedMins}</div>
      ) : null} */}
      {introScreen === introScreenOptions[1] ? (
        <div className={styles.lago_control_button_and_image_container}>
          <div className={styles.lago_control_button_container}>
            <Button
              onClick={() => {
                setIsFromEditModal(false);
                if (currentStage === screenStages.length - 1) {
                  setIsScreenEditor(true);
                  setEditorState(EditorState.createEmpty());
                  resetScreenStateQuiz();
                  resetScreenStateHtml();
                  resetScreenStateVideo();
                }
                setIsDialogOpen(true);
                setAddedItemTitle('');
              }}
              color="primary"
              variant="contained"
              size="large"
              disabled={isFinalizedState}
            >
              <span className={styles.btn_txt}>Add {nextStage}</span>
            </Button>
          </div>
          {renderElementImage()}
          <input
            accept="image/png, image/gif, image/jpeg"
            hidden
            id="image-input"
            type="file"
            onChange={onImageFileChange}
            style={{ display: 'none' }}
          />
        </div>
      ) : null}
      <LagoUploadedImages
        open={displayUploadedImages}
        onClose={() => setDisplayUploadedImages(false)}
        uploadedImages={uploadedImages}
        onUploadedImageClicked={onUploadedImageClicked}
      />
      <div className={styles.lago_control_list_and_preview_container}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {introScreen === introScreenOptions[1] ? renderItemsList() : null}
          {currentStage >= MAIN_INDEX && currentStage <= MODULE_INDEX
            ? renderMoreItems()
            : null}
        </div>
        {screenStage === 'Lesson' ? (
          <div
            className={styles.lago_control_preview_container}
            style={
              previewHieght
                ? {
                    // position: 'absolute',
                    marginTop: previewHieght - 350,
                  }
                : null
            }
          >
            {/* {itemsData[selectedScreen] &&
            itemsData[selectedScreen].variants &&
            itemsData[selectedScreen].variants[variantToEdit] &&
            itemsData[selectedScreen].variants[variantToEdit].variantConfig &&
            itemsData[selectedScreen].variants[variantToEdit].variantConfig
              .variantTitle ? (
              <div className={styles.preview_title}>
                {
                  itemsData[selectedScreen].variants[variantToEdit]
                    .variantConfig.variantTitle
                }
              </div>
            ) : null} */}
            <LagoPreview
              htmlText={htmlText}
              htmlBtnValue={htmlBtnValue}
              htmlImgPath={htmlImgPath}
              videoPrev={videoPrev}
              isLoadingVideo={isLoadingVideo}
              quizTextQuestion={draftToHtml(
                convertToRaw(quizTextQuestion.getCurrentContent())
              )}
              quizCoises={quizCoises}
              quizBtnValue={quizBtnValue}
              screenType={
                itemsData &&
                selectedScreen &&
                itemsData[selectedScreen] &&
                itemsData[selectedScreen].variants &&
                itemsData[selectedScreen].variants[selectedVariant] &&
                itemsData[selectedScreen].variants[selectedVariant].screenType
                  ? itemsData[selectedScreen].variants[selectedVariant]
                      .screenType
                  : null
              }
            />
          </div>
        ) : null}
      </div>
      <LagoEditScreenDialog
        isOpen={isDialogOpen}
        onClose={onCloseEditDialog}
        onCloseSave={onCloseSaveEditDialog}
        saveHtml={() => saveHtml(editorState)}
        saveNewHtml={() => saveNewHtml(editorState)}
        editorState={editorState}
        setEditorState={setEditorState}
        saveEditTitle={saveEditTitle}
        titleValue={addedItemTitle}
        setTitleValue={e => setAddedItemTitle(e.target.value)}
        isFromEdit={isFromEditModal}
        isScreenEditor={isScreenEditor}
        onVideoFileChange={onVideoFileChange}
        videoFile={videoFile}
        saveVideo={saveVideo}
        saveNewVideo={saveNewVideo}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        // uploadCallback={uploadImgEditorCallback}
        // htmlBtnValue={htmlBtnValue}
        // setHtmlBtnValue={setHtmlBtnValue}
        htmlImgPath={htmlImgPath}
        onScreenImageFileChange={onScreenImageFileChange}
        quizType={quizType}
        setQuizType={setQuizType}
        quizTextQuestion={quizTextQuestion}
        setQuizTextQuestion={setQuizTextQuestion}
        quizCoises={quizCoises}
        setQuizCoises={setQuizCoises}
        quizNewCoise={quizNewCoise}
        setQuizNewCoise={setQuizNewCoise}
        quizAfterCorrect={quizAfterCorrect}
        setQuizAfterCorrect={setQuizAfterCorrect}
        quizBtnValue={quizBtnValue}
        setQuizBtnValue={setQuizBtnValue}
        saveQuiz={saveQuiz}
        saveNewQuiz={saveNewQuiz}
        // estimatedMins={estimatedMins}
        // setEstimatedMins={setEstimatedMins}
        currentStage={currentStage}
        setDisplayUploadedImages={setDisplayUploadedImages}
        setQuizOtherStr={setQuizOtherStr}
        quizOtherStr={quizOtherStr}
        isMultipleChoise={isMultipleChoise}
        setIsMultipleChoise={setIsMultipleChoise}
        tags={tags}
        onAddTag={onAddTag}
        onSelectTag={onSelectTag}
        selectedTagId={selectedTagId}
        // quizBtnAfterAnswerValue={quizBtnAfterAnswerValue}
        // setQuizBtnAfterAnswerValue={setQuizBtnAfterAnswerValue}
        // isFromGoalEdit={isFromGoalEdit}
        quizMaxNum={quizMaxNum}
        setQuizMaxNum={setQuizMaxNum}
        firstRatingText={firstRatingText}
        setFirstRatingText={setFirstRatingText}
        lastRatingText={lastRatingText}
        setLastRatingText={setLastRatingText}
        isOtherCorrect={isOtherCorrect}
        setIsOtherCorrect={setIsOtherCorrect}
        // isFromJournalEdit={isFromJournalEdit}
        videoPrev={videoPrev}
        editVideoTitle={editVideoTitle}
        isLoadingImage={isLoadingImage}
        // isFromCheckinEdit={isFromCheckinEdit}
        setAddedItemTitle={setAddedItemTitle}
        removeScreenImage={removeScreenImage}
        journeyId={
          selectedItemHistory[0] ? selectedItemHistory[0] : selectedItem
        }
        fileSizeError={fileSizeError}
        isFinalizedState={isFinalizedState}
        journeyDashboardDescription={journeyDashboardDescription}
        setJourneyDashboardDescription={setJourneyDashboardDescription}
      />
      <LagoVariants
        open={displayVariantDialog}
        onClose={() => {
          setDisplayVariantDialog(false);
          setVariantToEdit('');
          setIsVariantEditor(false);
        }}
        tags={tags}
        // onSaveVariantClicked={onSaveVariantClicked}
        variantToEdit={variantToEdit}
        selectedScreen={selectedScreen}
        deleteVariantClicked={deleteVariant}
        setIsDialogOpen={setIsDialogOpen}
        setIsScreenEditor={setIsScreenEditor}
        setIsVariantEditor={setIsVariantEditor}
        // variantTitle={variantTitle}
        setVariantTitle={setVariantTitle}
        tempScreenFilters={tempScreenFilters}
        setTempScreenFilters={setTempScreenFilters}
        setVariantToEdit={setVariantToEdit}
        isNewVariant={isNewVariant}
        setIsNewVariant={setIsNewVariant}
        journeyId={
          selectedItemHistory[0] ? selectedItemHistory[0] : selectedItem
        }
        isFinalizedState={isFinalizedState}
        currentStage={currentStage}
      />
      <Dialog
        open={displayDeleteDialog}
        onClose={() => setDisplayDeleteDialog(false)}
      >
        <DialogTitle>
          <div style={{ fontSize: '2rem' }}>
            Are you sure you want to delete{' '}
            {itemsData &&
            keyToDelete &&
            itemsData[keyToDelete] &&
            itemsData[keyToDelete].title &&
            itemsData[keyToDelete].title.en
              ? itemsData[keyToDelete].title.en
              : ''}
            ?
          </div>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              onDeleteItemConfirmed(keyToDelete);
              setKeyToDelete('');
              setCollectionToDelete('');
              setArticleToDelete(false);
              setJournalToDelete(false);
              setDisplayDeleteDialog(false);
            }}
            style={{ width: '100px', height: '50px', fontSize: '16px' }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDisplayDeleteDialog(false);
              setKeyToDelete('');
              setCollectionToDelete('');
              setArticleToDelete(false);
              setJournalToDelete(false);
            }}
            style={{ width: '100px', height: '50px', fontSize: '16px' }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isMoveModuleDialogOpen}
        onClose={() => {
          setIsMoveModuleDialogOpen(false);
          setFromModule('');
          setFromCourse('');
          setMoveModuleFilter('');
        }}
        maxWidth="md"
        fullWidth
        classes={{ paper: styles.dialogPaper }}
      >
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.5em',
            fontWeight: 'bold',
            marginTop: '10px',
          }}
        >
          Please select minicourse
        </div>
        <Input
          value={moveModuleFilter}
          onChange={e => setMoveModuleFilter(e.target.value)}
          style={{ width: '100px', margin: '20px' }}
        />
        <div className={styles.move_module_items}>
          {filteredCuorsesList.map(courseId => {
            return (
              <div
                onClick={() => {
                  moveModule(courseId);
                }}
                className={styles.move_module_item}
                key={courseId}
              >
                {coursesItemsData &&
                  coursesItemsData[courseId] &&
                  coursesItemsData[courseId].title &&
                  coursesItemsData[courseId].title.en}
              </div>
            );
          })}
        </div>
        <DialogActions>
          <Button
            onClick={() => {
              setIsMoveModuleDialogOpen(false);
              setFromModule('');
              setFromCourse('');
              setMoveModuleFilter('');
            }}
            style={{ width: '100px', height: '50px', fontSize: '16px' }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableRestoreFocus
        open={isNotificationDialogOpen}
        onClose={onCloseNotificationsModal}
        maxWidth="xs"
        fullWidth
        classes={{ paper: styles.dialogPaper }}
      >
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.5em',
            fontWeight: 'bold',
            marginTop: '10px',
          }}
        >
          Lessons notifications:
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Input
            placeholder="Notification title"
            value={lessonNotificationTitle}
            onChange={e => setLessonNotificationTitle(e.target.value)}
            style={{ width: '200px', margin: '20px' }}
          />
          <Input
            placeholder="Notification body"
            value={lessonNotificationBody}
            onChange={e => setLessonNotificationBody(e.target.value)}
            style={{ width: '200px', margin: '20px' }}
          />
          <div style={{ display: 'flex' }}>
            <Checkbox
              checked={lessonNotificationEnabled}
              onChange={() => {
                setLessonNotificationEnabled(!lessonNotificationEnabled);
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>Enable</div>
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={onCloseNotificationsModal}
            style={{ width: '100px', height: '50px', fontSize: '16px' }}
          >
            cancel
          </Button>
          <Button
            onClick={() => {
              saveLessonsNotification();
              onCloseNotificationsModal();
            }}
            style={{ width: '100px', height: '50px', fontSize: '16px' }}
            disabled={!lessonNotificationBody || !lessonNotificationTitle}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
      {screenStage === 'Lesson' && selectedScreen ? (
        <span>
          <h4>Screen analytics</h4>
          <iframe
            title="Screen analytics"
            height={500}
            width="100%"
            src={`https://datastudio.google.com/embed/reporting/b69034a0-eb0f-47d7-b561-dd8684a95cdc/page/gSdUC?params=%7B%22ds0.screenid%22%3A%22${selectedScreen}%22%7D`}
          />
        </span>
      ) : null}
    </div>
  );
};

LagoControlBase.propTypes = exact({});

export const LagoControl = React.memo(LagoControlBase);
LagoControl.displayName = 'LagoControl';

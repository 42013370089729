import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import capitalize from 'lodash/capitalize';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import {
  LinearProgress,
  Button,
  Dialog,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import ISO6391 from 'iso-639-1';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import styles from './ListingStatus.scss';
import { UnavailableListingDialog } from '../UnavailableListingDialog/UnavailableListingDialog';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { AddEditListingDialog } from '../AddEditListingDialog/AddEditListingDialog';
import checkedImage from '../../../../assets/checked.png';
import unCheckedImage from '../../../../assets/unchecked.png';
import failedCircleImage from '../../../../assets/failed-circle.png';
import { usePreviousValue } from '../../../Core/hooks/usePreviousValue';
import { ListingItem } from '../../ListingItem/ListingItem';
import { ConfirmationDialog } from '../../../Core/Components/ConfirmationDialog/ConfirmationDialog';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const allLanguages = ISO6391.getAllNames();

const biographyMaxLength = 300;

const SuccessLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#51e5d6',
  },
  barColorPrimary: {
    backgroundColor: '#51e5d6',
  },
  root: {
    borderRadius: '4px',
  },
})(LinearProgress);

const FailureLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#ebebeb',
  },
  barColorPrimary: {
    backgroundColor: '#f38787',
  },
  root: {
    borderRadius: '4px',
  },
})(LinearProgress);

const ListingDeletionConfirmationDialog = props => (
  <ConfirmationDialog
    {...props}
    confirmationMsg="Are u sure you want to delete this listing? you won't be able to get it back after deleting it"
    confirmBtnText="Delete"
  />
);

const homePageEventLogger = new MyEventWrapper('home_page');

const ListingStatusBase = ({ usageStats, onComponentRenderedCompletely }) => {
  const t = useTranslation();

  const clinicId = sessionStorage.userId;
  const listingsDatabaseRef = database.ref(
    `providerListings/${clinicId}/listings`
  );
  const [isListingsDialogOpen, setIsListingsDialogOpen] = useState(false);
  const [
    isUnavailableListingsDialogOpen,
    setIsUnavailableListingsDialogOpen,
  ] = useState(false);
  const [
    isAddEditListingsDialogOpen,
    setIsAddEditListingsDialogOpen,
  ] = useState(false);

  const [
    addEditListingDialogRenderedStep,
    setAddEditListingDialogRenderedStep,
  ] = useState('form');

  const [listingIdToBeDeleted, setListingIdToBeDeleted] = useState(null);

  const onFillListingInfo = useCallback(event => {
    event.preventDefault();
    homePageEventLogger.log('on_editing_listing_info');
    setIsListingsDialogOpen(true);
  }, []);

  const onCloseFillListingInfoDialog = useCallback(() => {
    setIsListingsDialogOpen(false);
  }, []);

  const maxListingsInitialValueRef = useRef(Symbol('iv'));
  const [maxListings] = useFirebaseDB({
    path: `providerListings/${clinicId}/maxListings`,
    defaultValue: 0,
    initialValue: maxListingsInitialValueRef.current,
  });

  const listingsInitialValueRef = useRef({});
  const [listings] = useFirebaseDB({
    path: `providerListings/${clinicId}/listings`,
    defaultValue: useRef({}).current,
    initialValue: listingsInitialValueRef.current,
  });

  const hasFilledListings = Object.keys(listings).length > 0;

  const [defaultCriteriaConfig] = useFirebaseDB({
    path: 'clinicConfig/providerListingConfigs/defaultCriteriaConfig',
    onceListener: true,
  });

  const clinicCriteriaConfigInitialValueRef = useRef(Symbol('iv'));
  const [clinicCriteriaConfig] = useFirebaseDB({
    path: `providerListings/${clinicId}/providerListingCriteriaConfig`,
    onceListener: true,
    initialValue: clinicCriteriaConfigInitialValueRef.current,
  });

  const defaultListingConfigInitialValueRef = useRef(Symbol('iv'));
  const [defaultListingConfig] = useFirebaseDB({
    path: 'clinicConfig/providerListingConfigs/defaultConfig',
    initialValue: defaultListingConfigInitialValueRef.current,
    onceListener: true,
  });

  const clinicListingConfigInitialValueRef = useRef(Symbol('iv'));
  const [clinicListingConfig] = useFirebaseDB({
    path: `providerListings/${clinicId}/providerListingConfig`,
    initialValue: clinicListingConfigInitialValueRef.current,
    onceListener: true,
  });

  const isComponentReadyToRender =
    defaultCriteriaConfig !== null &&
    defaultListingConfig !== null &&
    clinicCriteriaConfig !== clinicCriteriaConfigInitialValueRef.current &&
    clinicListingConfig !== clinicListingConfigInitialValueRef.current &&
    listings !== listingsInitialValueRef.current &&
    maxListings !== maxListingsInitialValueRef.current;

  const hasOnComponentRenderedCompletelyCalledRef = useRef(false);
  useEffect(() => {
    if (
      isComponentReadyToRender &&
      hasOnComponentRenderedCompletelyCalledRef.current === false
    ) {
      onComponentRenderedCompletely();
      hasOnComponentRenderedCompletelyCalledRef.current = true;
    }
  }, [isComponentReadyToRender, onComponentRenderedCompletely]);

  const prvListings = usePreviousValue(listings);

  const [listingsWithImages, setListingsWithImages] = useState([]);
  const [topSpecialties, setTopSpecialties] = useState([]);
  const [otherSpecialties, setOtherSpecialties] = useState([]);
  const onTopSpecialtiesAdd = useCallback(
    specialty => {
      setTopSpecialties([...topSpecialties, specialty]);
    },
    [topSpecialties]
  );
  const onTopSpecialtiesDelete = useCallback(
    deletedSpecialty => {
      setTopSpecialties(
        topSpecialties.filter(specialty => specialty !== deletedSpecialty)
      );
    },
    [topSpecialties]
  );
  const onOtherSpecialtiesAdd = useCallback(
    specialty => {
      setOtherSpecialties([...otherSpecialties, specialty]);
    },
    [otherSpecialties]
  );
  const onOtherSpecialtiesDelete = useCallback(
    deletedSpecialty =>
      setOtherSpecialties(
        otherSpecialties.filter(specialty => specialty !== deletedSpecialty)
      ),
    [otherSpecialties]
  );

  const [languages, setLanguages] = useState([]);
  const onLanguagesChange = useCallback(event => {
    setLanguages(event.target.value);
  }, []);

  const [imageFile, setImageFile] = useState(null);
  const [imageFileLastModifiedDate, setImageFileLastModifiedDate] = useState(
    null
  );
  const [listingIdInEdit, setListingIdInEdit] = useState(null);
  const prvListingIdInEdit = usePreviousValue(listingIdInEdit);

  const [biography, setBiography] = useState('');
  const onBiographyChange = event => {
    setBiography(event.target.value);
  };

  const onImageFileChange = useCallback(event => {
    const [file] = event.target.files;
    setImageFileLastModifiedDate(Date.now());
    setImageFile(file);
  }, []);

  const onAddNewListing = useCallback(() => {
    homePageEventLogger.log('on_attempt_add_a_listing');
    if (Object.keys(listings).length < maxListings) {
      setIsAddEditListingsDialogOpen(true);
    } else {
      setIsUnavailableListingsDialogOpen(true);
    }
  }, [listings, maxListings]);

  const formRef = useRef();
  const { register, handleSubmit, reset, errors, formState } = useForm();

  const closeAddEditListingDialog = useCallback(() => {
    setIsAddEditListingsDialogOpen(false);
    setAddEditListingDialogRenderedStep('form');
    setBiography('');
    setTopSpecialties([]);
    setOtherSpecialties([]);
    setLanguages([]);
    setImageFile(null);
    setListingIdInEdit(null);
    setImageFileLastModifiedDate(null);
    reset({});
  }, [reset]);

  const validateCustomFields = () => {
    const newErrors = {};
    if (topSpecialties.length === 0) {
      newErrors.topSpecialties = {
        type: 'customError',
        message: t('error-top-specialties-are-required'),
      };
    }
    if (topSpecialties.length > 3) {
      newErrors.topSpecialties = {
        type: 'customError',
        message: t('error-max-3-top-specialties'),
      };
    }
    if (otherSpecialties.length === 0) {
      newErrors.otherSpecialties = {
        type: 'customError',
        message: t('error-other-specialty-is-required'),
      };
    }
    if (otherSpecialties.length > 3) {
      newErrors.otherSpecialties = {
        type: 'customError',
        message: t('error-max-3-other-specialties'),
      };
    }
    if (languages.length === 0) {
      newErrors.languages = {
        type: 'customError',
        message: t('error-language-is-required'),
      };
    }
    if (languages.length > 5) {
      newErrors.languages = {
        type: 'customError',
        message: t('error-max-5-languages'),
      };
    }
    if (imageFile === null) {
      newErrors.imageUpload = {
        type: 'customError',
        message: t('error-image-is-required'),
      };
    }
    if (biography.trim() === '') {
      newErrors.biography = {
        type: 'customError',
        message: t('error-biography-is-required'),
      };
    }
    if (biography.length > biographyMaxLength) {
      newErrors.biography = {
        type: 'customError',
        message: `Biography should be less than ${biographyMaxLength} characters`,
      };
    }
    return newErrors;
  };

  const fullErrors =
    formState.isSubmitting || formState.isSubmitted
      ? { ...errors, ...validateCustomFields() }
      : errors;

  const onSubmit = async data => {
    const listingData = {
      ...data,
      biography,
      topSpecialties,
      otherSpecialties,
      languages,
      imageName: imageFile.name,
      ...(imageFileLastModifiedDate
        ? {
            imageLastUpdated: imageFileLastModifiedDate,
          }
        : {}),
    };

    if (Object.keys(validateCustomFields()).length !== 0) {
      return;
    }

    setAddEditListingDialogRenderedStep('loading');

    const storage = window.firebase.storage();
    if (listingIdInEdit) {
      if (imageFileLastModifiedDate !== null) {
        const imagePath = `providerListings/${sessionStorage.userId}/${listingIdInEdit}`;
        const imageRef = storage.ref(imagePath);

        await imageRef.put(imageFile);
      }
      await listingsDatabaseRef.child(listingIdInEdit).update(listingData);
      homePageEventLogger.log('on_edit_listing', listingData);
    } else {
      const listingNewKey = listingsDatabaseRef.push().key;

      const imagePath = `providerListings/${sessionStorage.userId}/${listingNewKey}`;
      const imageRef = storage.ref(imagePath);

      await imageRef.put(imageFile);
      await listingsDatabaseRef
        .child(listingNewKey)
        .set({ ...listingData, isShowing: true });
      homePageEventLogger.log('on_add_listing', listingData);
    }
    setAddEditListingDialogRenderedStep('listingAdded');
  };

  const onAddEditListingDialogConfirm = useCallback(() => {
    if (addEditListingDialogRenderedStep === 'form') {
      formRef.current.querySelector('input[type="submit"]').click();
    } else {
      homePageEventLogger.log(
        'on_close_add_edit_listing_dialog_after_successful_operation'
      );
      closeAddEditListingDialog();
    }
  }, [addEditListingDialogRenderedStep, closeAddEditListingDialog]);

  const onListingDeletionConfirmationDialogConfirm = async () => {
    homePageEventLogger.log('on_delete_listing', {
      listingId: listingIdToBeDeleted,
    });
    await listingsDatabaseRef.child(listingIdToBeDeleted).set(null);

    const storage = window.firebase.storage();
    const imagePath = `providerListings/${sessionStorage.userId}/${listingIdToBeDeleted}`;
    const imageRef = storage.ref(imagePath);
    imageRef.delete();

    setListingIdToBeDeleted(null);
  };
  const onListingDeletionConfirmationDialogCancel = () => {
    homePageEventLogger.log('on_cancel_delete_listing', {
      listingId: listingIdToBeDeleted,
    });
    setListingIdToBeDeleted(null);
  };

  const onEditListing = listingId => {
    setListingIdInEdit(listingId);
  };

  useEffect(() => {
    (async () => {
      if (listings !== prvListings) {
        const imagesLoadedPromises = [];
        Object.entries(listings).map(([listingId, listing]) => {
          const storage = window.firebase.storage();
          const imagePath = `providerListings/${clinicId}/${listingId}`;
          const imageRef = storage.ref(imagePath);
          const downloadImageURLPromise = imageRef.getDownloadURL();
          imagesLoadedPromises.push(downloadImageURLPromise);
          downloadImageURLPromise.then(imageURL => {
            // eslint-disable-next-line no-param-reassign
            listing.imageSrc = imageURL;
          });

          // eslint-disable-next-line no-param-reassign
          listing.listingId = listingId;
          return listing;
        });
        await Promise.all(imagesLoadedPromises);
        setListingsWithImages(listings);
      }
    })();
  }, [listings, prvListings, clinicId]);

  useEffect(() => {
    if (listingIdInEdit && prvListingIdInEdit !== listingIdInEdit) {
      const listingInEdit = listings[listingIdInEdit];
      reset(listingInEdit);
      setBiography(listingInEdit.biography);
      setTopSpecialties(listingInEdit.topSpecialties);
      setOtherSpecialties(listingInEdit.otherSpecialties);
      setLanguages(listingInEdit.languages);
      setImageFile({ name: listingInEdit.imageName });
      setIsAddEditListingsDialogOpen(true);
    }
  }, [listingIdInEdit, prvListingIdInEdit, reset, listings]);

  const renderListingsItems = () => {
    const numberOfShowedListings = Object.values(listingsWithImages).reduce(
      (acc, listing) => (listing.isShowing ? acc + 1 : acc),
      0
    );

    const getIfIsShowingIconButtonDisabled = listing =>
      (!listing.isShowing && numberOfShowedListings >= maxListings) ||
      maxListings === 0;

    return Object.entries(listingsWithImages).map(([listingId, listing]) => (
      <ListingItem
        key={listingId}
        listing={listing}
        rootClassName={styles.listing_item}
        customChipClassName={styles.custom_chip}
        styles={styles}
        extraContent={
          <div className={styles.actions_container}>
            <TooltipWrapper
              disableFocusListener={!getIfIsShowingIconButtonDisabled(listing)}
              disableHoverListener={!getIfIsShowingIconButtonDisabled(listing)}
              disableTouchListener={!getIfIsShowingIconButtonDisabled(listing)}
              title={
                <TooltipTitle>{t('you-reached-maximum-listings')}</TooltipTitle>
              }
            >
              <span>
                <IconButton
                  color="primary"
                  onClick={event => {
                    homePageEventLogger.log('on_changing_is_showing_status', {
                      listingId,
                    });
                    event.stopPropagation();
                    listingsDatabaseRef
                      .child(listingId)
                      .child('isShowing')
                      .set(!listing.isShowing);
                  }}
                  disabled={getIfIsShowingIconButtonDisabled(listing)}
                >
                  {listing.isShowing ? (
                    <VisibilityIcon style={{ fontSize: 24 }} />
                  ) : (
                    <VisibilityOffIcon style={{ fontSize: 24 }} />
                  )}
                </IconButton>
              </span>
            </TooltipWrapper>
            <IconButton
              color="primary"
              onClick={event => {
                event.stopPropagation();
                homePageEventLogger.log('on_attempt_edit_listing', {
                  listingId,
                });
                onEditListing(listingId);
              }}
            >
              <EditIcon style={{ fontSize: 24 }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={event => {
                event.stopPropagation();
                homePageEventLogger.log('on_attempt_delete_listing', {
                  listingId,
                });
                setListingIdToBeDeleted(listingId);
              }}
            >
              <DeleteIcon style={{ fontSize: 24 }} />
            </IconButton>
          </div>
        }
      />
    ));
  };

  const render = () => {
    if (!isComponentReadyToRender) {
      return null;
    }

    const criteriaConfig =
      clinicCriteriaConfig !== null
        ? clinicCriteriaConfig
        : defaultCriteriaConfig;

    const listingConfig = clinicListingConfig || defaultListingConfig;

    const numberOfPassedChecks = criteriaConfig.reduce(
      (acc, criteriaObject) => {
        const type =
          typeof criteriaObject.criteria === 'number' ? 'number' : 'boolean';

        const hasPassed =
          get(
            usageStats,
            [criteriaObject.period, criteriaObject.fieldName],
            type === 'number' ? 0 : false
          ) >= criteriaObject.criteria;

        return hasPassed ? acc + 1 : acc;
      },
      hasFilledListings ? 1 : 0
    );

    const numberOfChecks = 1 + criteriaConfig.length; // 1 for the add listing check
    const formattedPercentageOfPassedChecks = parseInt(
      (numberOfPassedChecks * 100) / numberOfChecks,
      10
    );
    const hasPassedAllChecks = numberOfPassedChecks === numberOfChecks;

    return (
      <div style={{ marginRight: 15, marginTop: 20 }}>
        <ExpansionPanel className={styles.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={styles.listing_status_header}>
              <p>
                Website Listing Status:{' '}
                <span
                  style={{
                    color: hasPassedAllChecks ? '#51e5d6' : '#f69191',
                    fontWeight: 500,
                    fontSize: 'inherit',
                  }}
                >
                  {hasPassedAllChecks ? 'Listed' : 'Not Listed'}
                </span>
              </p>
              <span>
                {hasPassedAllChecks || numberOfPassedChecks === 0
                  ? ''
                  : 'Almost there!'}{' '}
                You currently meet {formattedPercentageOfPassedChecks}% of the
                criteria to be listed{' '}
                <a
                  href="https://dashboard.myndlift.com/providers-search.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  as a provider on Myndlift’s website
                </a>
                .
              </span>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={styles.status_info}>
              {criteriaConfig.map(criteriaObject => {
                const type =
                  typeof criteriaObject.criteria === 'number'
                    ? 'number'
                    : 'boolean';
                const hasPassed =
                  get(
                    usageStats,
                    [criteriaObject.period, criteriaObject.fieldName],
                    type === 'number' ? 0 : false
                  ) >= criteriaObject.criteria;
                const shouldHideBar = criteriaObject.shouldHideBar || false;

                const formattedText = criteriaObject.text
                  .replace('{criteria}', criteriaObject.criteria)
                  .replace('{period}', criteriaObject.period.replace('-', ''));

                const percentage =
                  type === 'number' &&
                  (get(
                    usageStats,
                    [criteriaObject.period, criteriaObject.fieldName],
                    0
                  ) *
                    100) /
                    criteriaObject.criteria;
                return (
                  <div className={styles.check} key={criteriaObject.id}>
                    <div>
                      <img src={hasPassed ? checkedImage : unCheckedImage} />

                      <span>{formattedText}</span>
                    </div>
                    {type === 'number' && (
                      <div>
                        <div className={styles.numbered_info}>
                          <span>
                            {parseInt(
                              get(
                                usageStats,
                                [
                                  criteriaObject.period,
                                  criteriaObject.fieldName,
                                ],
                                0
                              ),
                              10
                            )}
                          </span>
                          <span>{criteriaObject.unit}</span>
                        </div>
                        {shouldHideBar ? (
                          <div hidden />
                        ) : (
                          <div style={{ color: '#f38787', width: 90 }}>
                            {hasPassed ? (
                              <SuccessLinearProgress
                                variant="determinate"
                                value={percentage}
                              />
                            ) : (
                              <FailureLinearProgress
                                variant="determinate"
                                value={percentage}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {type === 'boolean' && (
                      <div>
                        <span
                          style={{
                            color: hasPassed ? '#51e5d6' : '#f69191',
                            fontWeight: 500,
                          }}
                        >
                          {hasPassed ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}

              <div className={styles.check}>
                <div>
                  <img
                    src={hasFilledListings ? checkedImage : unCheckedImage}
                  />

                  <span>{t('enter-information-as-it-appears-in-listing')}</span>
                </div>
                <div>
                  <div className={styles.fill_listing_info_div}>
                    <p
                      style={{
                        color: hasFilledListings ? '#51e5d6' : '#f69191',
                        fontWeight: 500,
                      }}
                    >
                      {hasFilledListings > 0 ? 'Completed' : 'Incomplete'}
                    </p>
                    <a href="#" onClick={onFillListingInfo}>
                      {hasFilledListings ? 'Add/edit listing' : 'Enter info'}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Dialog open={isListingsDialogOpen} maxWidth="md">
          <div className={styles.add_listing_dialog_root}>
            <div className={styles.dialog_header}>
              <div>
                <p>{t('provider-listing')}</p>
                <span>{t('add-to-provider-listing')}</span>
              </div>

              <div>
                <span style={{ fontWeight: 500 }}>
                  {Object.keys(listings).length}/{maxListings} listings
                </span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onAddNewListing}
                >
                  <span className={styles.btn}>{t('add-clinician')}</span>
                </Button>
              </div>

              <div className={styles.close_icon_container}>
                <CloseIcon
                  onClick={onCloseFillListingInfoDialog}
                  style={{ fontSize: 18 }}
                />
              </div>
            </div>

            <div className={styles.listings_table_container}>
              {Object.keys(listings).length > 0 ? (
                renderListingsItems()
              ) : (
                <div className={styles.no_listings}>
                  <img src={failedCircleImage} />
                  <p>{t('no-listings-on-website-yet')}</p>
                </div>
              )}
            </div>
          </div>
        </Dialog>

        <UnavailableListingDialog
          isOpen={isUnavailableListingsDialogOpen}
          onConfirm={() => {
            homePageEventLogger.log('on_closing_unavailable_listing_dialog');
            setIsUnavailableListingsDialogOpen(false);
          }}
          listingConfig={listingConfig}
          maxListings={maxListings}
        />

        <ListingDeletionConfirmationDialog
          isOpen={!!listingIdToBeDeleted}
          onConfirm={onListingDeletionConfirmationDialogConfirm}
          onClose={onListingDeletionConfirmationDialogCancel}
          onCancel={onListingDeletionConfirmationDialogCancel}
        />

        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <AddEditListingDialog
            isOpen={isAddEditListingsDialogOpen}
            onConfirm={onAddEditListingDialogConfirm}
            register={register}
            errors={mapValues(fullErrors, (error, name) => {
              const formattedNames = {
                clinicName: 'Clinic name',
                firstName: 'First name',
                lastName: 'Last name',
                zipCode: 'Zip code',
              };
              if (error.type === 'required') {
                return {
                  ...error,
                  message: `${capitalize(
                    formattedNames[name] || name
                  )} is required`,
                };
              }
              return error;
            })}
            onClose={() => {
              closeAddEditListingDialog();
              homePageEventLogger.log('on_closing_add_edit_listing_dialog');
            }}
            renderedStep={addEditListingDialogRenderedStep}
            {...{
              topSpecialties,
              otherSpecialties,
              onTopSpecialtiesAdd,
              onTopSpecialtiesDelete,
              onOtherSpecialtiesAdd,
              onOtherSpecialtiesDelete,
              allLanguages,
              languages,
              onLanguagesChange,
              onImageFileChange,
              imageFile,
              biography,
              onBiographyChange,
              biographyMaxLength,
              isEditMode: !!listingIdInEdit,
            }}
          />
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </div>
    );
  };

  return render();
};

ListingStatusBase.propTypes = exact({
  usageStats: PropTypes.object,
  onComponentRenderedCompletely: PropTypes.func.isRequired,
});

export const ListingStatus = React.memo(ListingStatusBase);
ListingStatus.displayName = 'ListingStatus';

import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import classNames from 'classnames';

import doneAnimationImageSrc from '../../../assets/done-animation.gif';
import failedCircleImageSrc from '../../../assets/failed-circle.png';
import giftIcon from '../../../assets/gift-icon.png';
import styles from './CreditCardDetailsDialog.scss';
import { Loader } from '../../Core/Components/Loader/Loader';

const CreditCardDetailsDialogBase = (
  {
    renderedStep,
    onCancel,
    onConfirm,
    isOpen,
    isPrimaryBtnDisabled,
    prvCardLastFourDigits,
    creditCardCVCError,
    creditCardExpiryError,
    creditCardNumberError,
    onCreditCardNumberChange,
    onCreditCardCVCChange,
    onCreditCardExpiryChange,
    onClose,
    dialogHeader,
    isSecondaryBtnShown,
    isCloseIconShown,
    packageDetailsForDeactivation,
    balance,
    isFreePlan,
    defaultBlockedUsageCredit,
    userPrice,
    shouldRenderAsComponentNotDialog,
    userOutstandingInvoicesAmount,
  },
  ref
) => {
  const cardNumberElementRef = useRef();
  const cardExpiryElementRef = useRef();
  const cardCVCElementRef = useRef();

  useImperativeHandle(ref, () => ({
    clearInputs: () => {
      cardNumberElementRef.current.clear();
      cardExpiryElementRef.current.clear();
      cardCVCElementRef.current.clear();
    },
    disableChangeListeners: () => {
      cardNumberElementRef.current.off('change');
      cardExpiryElementRef.current.off('change');
      cardCVCElementRef.current.off('change');
    },
    enableChangeListeners: () => {
      cardNumberElementRef.current.on('change', onCreditCardNumberChange);
      cardExpiryElementRef.current.on('change', onCreditCardExpiryChange);
      cardCVCElementRef.current.on('change', onCreditCardCVCChange);
    },
  }));

  const getPrimaryBtnText = () => {
    switch (renderedStep) {
      case 'creditCardWasNotUpdated':
      case 'creditCardChargeFailed':
        return 'TRY AGAIN';

      case 'creditCardUpdatedSuccessfully':
      case 'creditCardChargedSuccessfully':
        return 'Done';

      default:
        return 'Save';
    }
  };

  const totalBalance = balance - (userOutstandingInvoicesAmount || 0);

  const renderDialogMainContent = () => (
    <React.Fragment>
      <div
        // We want to hide instead of not rendering because create token depends on them
        style={{
          display: renderedStep === 'cardDetailsInput' ? 'initial' : 'none',
          width: shouldRenderAsComponentNotDialog ? '100%' : 'none',
        }}
      >
        {prvCardLastFourDigits && (
          <div className={styles.prv_card_info}>
            <p>
              Your credit card on file ends in{' '}
              <span style={{ fontWeight: 500 }}>{prvCardLastFourDigits}</span>
            </p>
          </div>
        )}

        <div className={styles.input_container}>
          <p>Credit Card Number</p>
          <div>
            <CardNumberElement
              onChange={onCreditCardNumberChange}
              onReady={element => {
                cardNumberElementRef.current = element;
              }}
            />
          </div>
          <p className={styles.error}>{creditCardNumberError}</p>
        </div>
        <div className={styles.row}>
          <div className={styles.input_container}>
            <p>Expiration Date</p>
            <div>
              <CardExpiryElement
                onChange={onCreditCardExpiryChange}
                onReady={element => {
                  cardExpiryElementRef.current = element;
                }}
              />
            </div>

            <p className={styles.error}>{creditCardExpiryError}</p>
          </div>
          <div className={styles.input_container}>
            <p>CVC Code</p>
            <div>
              <CardCvcElement
                onChange={onCreditCardCVCChange}
                onReady={element => {
                  cardCVCElementRef.current = element;
                }}
              />
            </div>
            <p className={styles.error}>{creditCardCVCError}</p>
          </div>
        </div>
      </div>

      {renderedStep === 'loading' && <Loader />}

      {renderedStep === 'creditCardUpdatedSuccessfully' && (
        <div className={styles.info_container}>
          <Avatar
            alt="done"
            src={doneAnimationImageSrc}
            className={styles.avatar}
          />

          <p className={styles.info_text}>Credit card updated successfully!</p>
        </div>
      )}

      {renderedStep === 'creditCardChargedSuccessfully' && (
        <div className={styles.info_container}>
          <Avatar
            alt="done"
            src={doneAnimationImageSrc}
            className={styles.avatar}
          />

          <p className={styles.info_text}>Payment processed successfully!</p>
        </div>
      )}

      {renderedStep === 'creditCardWasNotUpdated' && (
        <div
          className={classNames(
            styles.info_container,
            styles.credit_card_was_not_updated
          )}
        >
          <Avatar
            alt="fail"
            src={failedCircleImageSrc}
            className={styles.avatar}
          />

          <p className={styles.info_text}>Credit card was not updated!</p>
          <p>We were unable to process the credit card.</p>
        </div>
      )}

      {renderedStep === 'creditCardChargeFailed' && (
        <div
          className={classNames(
            styles.info_container,
            styles.credit_card_was_not_updated
          )}
        >
          <Avatar
            alt="fail"
            src={failedCircleImageSrc}
            className={styles.avatar}
          />

          <p className={styles.info_text}>Payment failed</p>
          <p>We were unable to process the payment.</p>
        </div>
      )}
    </React.Fragment>
  );

  const calcExtraChargeText = () => {
    if (isFreePlan) {
      return (
        <div style={{ marginBottom: 10 }}>
          {totalBalance < 0 && (
            <div className={styles.extra_charge_text} style={{ color: 'red' }}>
              - You owe a balance of ${totalBalance * -1} , which will be
              deducted now.
            </div>
          )}
          With your On-Demand plan, there are no recurring subscription fees.
          You'll only incur a ${userPrice} charge per active client per month if
          a client's neurofeedback training exceeds 5 minutes per billing cycle.
          <a href="/new_account.html" target="_blank">
            {' '}
            View other plans
            <OpenInNewIcon
              style={{ fontSize: 12, position: 'relative', top: 2, left: 2 }}
            />
          </a>
          {defaultBlockedUsageCredit ? (
            <div className={styles.gift_banner}>
              <img
                src={giftIcon}
                style={{
                  marginRight: 10,
                  width: 14,
                  height: 14,
                  position: 'relative',
                  top: -2,
                }}
              />
              You have ${defaultBlockedUsageCredit} in usage credit
            </div>
          ) : null}
        </div>
      );
    }
    if (packageDetailsForDeactivation && packageDetailsForDeactivation.plan) {
      if (totalBalance < 0) {
        return (
          <div>
            <div className={styles.extra_charge_text} style={{ color: 'red' }}>
              - You owe a balance of ${totalBalance * -1} , which will be
              deducted now.
            </div>
            <div className={styles.extra_charge_text}>
              - To activate the Silver plan, you will also get charged $
              {packageDetailsForDeactivation.isAnnual
                ? Math.round(
                    packageDetailsForDeactivation.plan.annualMonthlyBasePrice *
                      12
                  )
                : packageDetailsForDeactivation.plan.basePrice}{' '}
              now.
            </div>
          </div>
        );
      }
      return (
        <div className={styles.extra_charge_text}>
          You will now get charged $
          {packageDetailsForDeactivation.isAnnual
            ? Math.round(
                packageDetailsForDeactivation.plan.annualMonthlyBasePrice * 12
              )
            : packageDetailsForDeactivation.plan.basePrice}{' '}
          to activate the plan. Please enter your credit card details:
        </div>
      );
    }
    if (totalBalance < 0) {
      return (
        <div className={styles.extra_charge_text} style={{ color: 'red' }}>
          - You owe a balance of ${totalBalance * -1} , which will be deducted
          now.
        </div>
      );
    }
    return null;
  };

  const shouldShowDialogActions = renderedStep !== 'loading';

  const primaryBtnText = getPrimaryBtnText();

  const shouldRenderSecondaryBtn =
    isSecondaryBtnShown &&
    renderedStep !== 'creditCardWasNotUpdated' &&
    renderedStep !== 'creditCardChargeFailed' &&
    renderedStep !== 'paymentFailed' &&
    renderedStep !== 'creditCardUpdatedSuccessfully' &&
    renderedStep !== 'creditCardChargedSuccessfully';

  if (shouldRenderAsComponentNotDialog) {
    return renderDialogMainContent();
  }

  return (
    <React.Fragment>
      <Dialog
        classes={{ paper: styles.content_container }}
        open={isOpen}
        onClose={onClose}
      >
        <DialogContent>
          <div>
            {renderedStep === 'creditCardChargedSuccessfully' ? (
              <div>
                <h3 className={styles.dialog_header}>
                  The{' '}
                  {packageDetailsForDeactivation &&
                    packageDetailsForDeactivation.plan &&
                    packageDetailsForDeactivation.plan.bundleName}{' '}
                  Plan is now active!
                </h3>
                <div
                  style={{
                    textAlign: 'center',
                    color: 'black',
                    marginBottom: 10,
                  }}
                >
                  The plan was successfully activated and you can start using
                  your dashboard now.
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h3 className={styles.dialog_header}>
                  {// eslint-disable-next-line prefer-template
                  dialogHeader +
                    ' ' +
                    (packageDetailsForDeactivation &&
                    packageDetailsForDeactivation.plan
                      ? // eslint-disable-next-line prefer-template
                        packageDetailsForDeactivation.plan.bundleName + ' Plan'
                      : '')}
                </h3>
                {calcExtraChargeText()}
              </React.Fragment>
            )}

            {isCloseIconShown && renderedStep !== 'loading' && (
              <div className={styles.close_icon_container}>
                <CloseIcon onClick={onClose} />
              </div>
            )}
          </div>
          {renderDialogMainContent()}
        </DialogContent>
        {shouldShowDialogActions && (
          <DialogActions>
            {shouldRenderSecondaryBtn && (
              <Button onClick={onCancel} color="default" variant="text">
                <span className={styles.button}>Cancel</span>
              </Button>
            )}

            <Button
              onClick={onConfirm}
              color="primary"
              variant="contained"
              disabled={isPrimaryBtnDisabled}
              autoFocus
              style={{ backgroundColor: '#2196f3' }}
            >
              <span className={styles.button}>{primaryBtnText}</span>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export const CreditCardDetailsDialog = React.memo(
  forwardRef(CreditCardDetailsDialogBase)
);
CreditCardDetailsDialogBase.displayName = 'CreditCardDetailsDialog';

CreditCardDetailsDialogBase.defaultProps = {
  isSecondaryBtnShown: true,
  isCloseIconShown: true,
};

CreditCardDetailsDialogBase.propTypes = exact({
  renderedStep: PropTypes.oneOf([
    'cardDetailsInput',
    'loading',
    'creditCardUpdatedSuccessfully',
    'creditCardWasNotUpdated',
    'creditCardChargedSuccessfully',
    'creditCardChargeFailed',
  ]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPrimaryBtnDisabled: PropTypes.bool,
  prvCardLastFourDigits: PropTypes.string,
  creditCardCVCError: PropTypes.string,
  creditCardExpiryError: PropTypes.string,
  creditCardNumberError: PropTypes.string,
  onCreditCardNumberChange: PropTypes.func.isRequired,
  onCreditCardCVCChange: PropTypes.func.isRequired,
  onCreditCardExpiryChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogHeader: PropTypes.string.isRequired,
  isSecondaryBtnShown: PropTypes.bool,
  isCloseIconShown: PropTypes.bool,
  packageDetailsForDeactivation: PropTypes.object,
  balance: PropTypes.number,
  isFreePlan: PropTypes.bool,
  defaultBlockedUsageCredit: PropTypes.number,
  userPrice: PropTypes.number,
  shouldRenderAsComponentNotDialog: PropTypes.bool,
  userOutstandingInvoicesAmount: PropTypes.number,
});

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper, Button, Chip } from '@material-ui/core';
import styles from './ScheduledAssessmentCard.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

const ScheduledAssessmentCardBase = ({
  assessmentStagesInfo,
  recurringAssessmentInterval,
  nextRecurringAssessmentTime,
  isRecurringAssessmentEnabled,
  isBeforeNextSessionAssessmentEnabled,
  currentAssessmentStatus,
  onCancel,
  onEdit,
  assessmentStages,
  intervalType,
  headsetOnly,
}) => {
  const t = useTranslation();

  const camelToStr = str => {
    const result = str.replace(/([A-Z])/g, ' $1');
    return result[0].toUpperCase() + result.substring(1);
  };

  const selectedEEGSensingRegions = assessmentStages.filter(stage =>
    Object.values(assessmentStagesInfo.eegIds).includes(stage)
  );
  const isCPTEnabled = assessmentStages.find(
    stage => stage === assessmentStagesInfo.ids.CPT
  );
  const isQuestionnairesEnabled = assessmentStages.find(
    stage => stage === assessmentStagesInfo.ids.QUESTIONNAIRES
  );

  function calcText() {
    if (intervalType === 'sessions' || intervalType === 'minutes') {
      if (nextRecurringAssessmentTime !== 1) {
        return intervalType;
      }
      return intervalType.slice(0, -1);
    }
    if (nextRecurringAssessmentTime !== 1) {
      return 'days';
    }
    return 'day';
  }

  return (
    <Paper className={styles.root}>
      <p>
        {t('brain-assessments')}: <span style={{ color: '#008080' }}>ON</span>
      </p>
      <p>
        {isRecurringAssessmentEnabled && isBeforeNextSessionAssessmentEnabled && (
          <span>
            The assessment is scheduled to occur{' '}
            <span style={{ fontWeight: 500 }}>
              immediately before the next app access
            </span>
            , and every{' '}
            <span style={{ fontWeight: 500 }}>
              {recurringAssessmentInterval}{' '}
              {recurringAssessmentInterval !== 1
                ? intervalType
                : intervalType.slice(0, -1)}
            </span>
            {intervalType === 'sessions' || intervalType === 'minutes'
              ? ' of training.'
              : '.'}
          </span>
        )}

        {!isBeforeNextSessionAssessmentEnabled &&
          (intervalType === 'sessions' || intervalType === 'minutes') && (
            <span>
              The assessment is scheduled to occur every{' '}
              <span style={{ fontWeight: 500 }}>
                {recurringAssessmentInterval}{' '}
                {recurringAssessmentInterval !== 1
                  ? intervalType
                  : intervalType.slice(0, -1)}
              </span>
              {intervalType === 'sessions' || intervalType === 'minutes'
                ? ' of training.'
                : '.'}
            </span>
          )}

        {!isBeforeNextSessionAssessmentEnabled &&
          (intervalType === 'weeks' ||
            intervalType === 'months' ||
            intervalType === 'days') && (
            <span>
              The assessment is scheduled to occur every{' '}
              <span style={{ fontWeight: 500 }}>
                {recurringAssessmentInterval}{' '}
                {recurringAssessmentInterval !== 1
                  ? intervalType
                  : intervalType.slice(0, -1)}
              </span>
              .
            </span>
          )}

        {!isRecurringAssessmentEnabled && (
          <span>
            The assessment is scheduled to occur immediately before the app
            access.
          </span>
        )}
      </p>
      {isRecurringAssessmentEnabled &&
        !currentAssessmentStatus &&
        !isBeforeNextSessionAssessmentEnabled &&
        (intervalType === 'sessions' || intervalType === 'minutes') &&
        !Number.isNaN(nextRecurringAssessmentTime) &&
        (nextRecurringAssessmentTime <= 0 ? (
          <p>
            Next recurring assessment will show for your user immediately before
            the next session
          </p>
        ) : (
          <p>
            <span style={{ fontWeight: 'bold' }}>
              {nextRecurringAssessmentTime} {calcText()}
            </span>{' '}
            {intervalType === 'sessions' || intervalType === 'minutes'
              ? ' of training'
              : ''}{' '}
            remaining until the next assessment.
          </p>
        ))}

      {currentAssessmentStatus && (
        <p className={styles.current_assessment_status}>
          There’s an assessment in progress currently in{' '}
          <span>{currentAssessmentStatus.stage}</span>{' '}
          <span>
            {currentAssessmentStatus.subStage
              ? `(${camelToStr(currentAssessmentStatus.subStage)})`
              : ''}
          </span>{' '}
          stage.
        </p>
      )}

      {assessmentStages.length > 0 && (
        <div className={styles.tags_container}>
          {isQuestionnairesEnabled && (
            <Chip
              classes={{ label: styles.chip_label }}
              label="Symptom questionnaires"
            />
          )}
          {(selectedEEGSensingRegions.length > 0 ||
            (headsetOnly &&
              !!assessmentStages.find(stage => stage === 'Headset'))) && (
            <Chip
              classes={{ label: styles.chip_label }}
              label={
                headsetOnly ? (
                  <>Headband-only EEG sensing</>
                ) : (
                  <>
                    {// eslint-disable-next-line no-nested-ternary
                    selectedEEGSensingRegions.length ===
                    Object.values(assessmentStagesInfo.eegIds).length
                      ? 'Full'
                      : selectedEEGSensingRegions.length ===
                          Object.values(assessmentStagesInfo.eegIds).length -
                            1 &&
                        !selectedEEGSensingRegions.find(
                          region => region === 'Back'
                        )
                      ? 'Recommended'
                      : 'Partial'}{' '}
                    EEG sensing ({selectedEEGSensingRegions.length}{' '}
                    {selectedEEGSensingRegions.length === 1
                      ? 'region'
                      : 'regions'}
                    )
                  </>
                )
              }
            />
          )}
          {isCPTEnabled && (
            <Chip
              classes={{ label: styles.chip_label }}
              label="Continuous performance task"
            />
          )}
        </div>
      )}

      <div className={styles.buttons_container}>
        <Button onClick={onCancel}>
          <span className={styles.custom_btn}>{t('stop')}</span>
        </Button>
        <Button
          color="primary"
          onClick={onEdit}
          disabled={!!currentAssessmentStatus}
          variant="contained"
        >
          <span className={styles.custom_btn}>{t('edit')}</span>
        </Button>
      </div>
    </Paper>
  );
};

ScheduledAssessmentCardBase.propTypes = exact({
  recurringAssessmentInterval: PropTypes.number,
  nextRecurringAssessmentTime: PropTypes.number,
  isRecurringAssessmentEnabled: PropTypes.bool,
  isBeforeNextSessionAssessmentEnabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  currentAssessmentStatus: PropTypes.object,
  assessmentStages: PropTypes.array.isRequired,
  assessmentStagesInfo: PropTypes.object.isRequired,
  intervalType: PropTypes.string.isRequired,
  headsetOnly: PropTypes.bool,
});

export const ScheduledAssessmentCard = React.memo(ScheduledAssessmentCardBase);
ScheduledAssessmentCard.displayName = 'ScheduledAssessmentCard';

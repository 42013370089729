import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  FormControl,
  RootRef,
  withStyles,
  InputLabel,
  Select,
  OutlinedInput,
} from '@material-ui/core';
import { paragraphFontSize } from '../../../cssInJs/constants';

const stylesFn = () => ({
  formControl: {
    minWidth: 230,
    '& legend': {
      borderBottom: 0,
    },
  },
  inputLabelRoot: {
    fontSize: paragraphFontSize,
  },
  selectRoot: {
    fontSize: paragraphFontSize,
    lineHeight: 'initial',
    height: 'initial',
    '&>*': {
      lineHeight: 'initial',
      height: 'initial',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});

const CustomSelectBase = ({
  classes,
  inputLabelText,
  selectedOption,
  allOptions,
  onChange,
  isSmallSelect,
  style,
}) => {
  const inputLabelRootRef = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabelRootRef.current.offsetWidth);
  }, []);

  const isAllOptionsArrOfObjects = allOptions.every(
    option => typeof option === 'object'
  );

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      style={style}
    >
      <RootRef rootRef={inputLabelRootRef}>
        <InputLabel
          htmlFor={`select-${inputLabelText}`}
          classes={{ root: classes.inputLabelRoot }}
        >
          {inputLabelText}
        </InputLabel>
      </RootRef>
      <Select
        native
        value={selectedOption}
        onChange={onChange}
        input={
          <OutlinedInput
            id={`select-${inputLabelText}`}
            labelWidth={labelWidth}
            classes={{ root: classes.outlinedInput }}
          />
        }
        classes={{
          root: classes.selectRoot,
          select: isSmallSelect ? classes.select : null,
        }}
        autoWidth
        style={isSmallSelect ? { height: 42, backgroundColor: 'white' } : null}
      >
        {isAllOptionsArrOfObjects
          ? allOptions.map(option => {
              if (option.hidden) {
                return;
              }
              // eslint-disable-next-line consistent-return
              return (
                <option
                  key={option.value}
                  value={option.value}
                  data-display-text={option.dataDisplayText || ''}
                  disabled={option.disabled}
                >
                  {option.text}
                </option>
              );
            })
          : allOptions.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
      </Select>
    </FormControl>
  );
};

CustomSelectBase.propTypes = exact({
  classes: PropTypes.object.isRequired,
  inputLabelText: PropTypes.string,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  allOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
          ]),
        }),
      ])
    ).isRequired,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  isSmallSelect: PropTypes.bool,
  style: PropTypes.object,
});

export const CustomSelect = React.memo(withStyles(stylesFn)(CustomSelectBase));
CustomSelect.displayName = 'CustomSelect';

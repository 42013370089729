import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper, Button } from '@material-ui/core';
import styles from './EmptySymptomTrackerTab.scss';
import noQuestionnaireImagePNG from '../../../../../assets/no_questionnaire_icon.png';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

const EmptySymptomTrackerTabBase = ({
  onSetupSymptomTracker,
  shouldUseNewSymptomTracker,
}) => {
  const t = useTranslation();
  return (
    <Paper>
      <div className={styles.root}>
        <div className={styles.no_report_image_container}>
          <img src={noQuestionnaireImagePNG} alt="no-symptom-tracker" />
        </div>
        <div className={styles.content}>
          <h3>{t('start_track_client_progress')}</h3>
          {shouldUseNewSymptomTracker ? (
            <p>
              Use standardized questionnaires to monitor your client's progress.
              You can add/remove as you see fit. Learn more{' '}
              <a
                href="https://intercom.help/dashboard-myndlift/en/articles/4488496-symptom-questionnaires-for-tracking-progress"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . Your client will be prompted to answer the questions in the app
              during the assessment
            </p>
          ) : (
            <p>{t('start-tracking-description')}</p>
          )}
        </div>

        <div className={styles.button_container}>
          <Button
            variant="contained"
            onClick={onSetupSymptomTracker}
            color="primary"
          >
            <span className={styles.btn}>{t('setup_tracking')}</span>
          </Button>
        </div>
      </div>
    </Paper>
  );
};

EmptySymptomTrackerTabBase.propTypes = exact({
  onSetupSymptomTracker: PropTypes.func.isRequired,
  shouldUseNewSymptomTracker: PropTypes.bool.isRequired,
});

export const EmptySymptomTrackerTab = React.memo(EmptySymptomTrackerTabBase);
EmptySymptomTrackerTab.displayName = 'EmptySymptomTrackerTab';

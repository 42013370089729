exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ModalFooter_modal_footer{display:flex;padding:12px 12px;flex-shrink:0}@media only screen and (max-width: 480px){.ModalFooter_modal_footer{flex-direction:column}}.ModalFooter_modal_footer_left_side{display:flex;align-items:center;padding-left:14px;margin-right:6px}@media only screen and (max-width: 480px){.ModalFooter_modal_footer_left_side{margin-bottom:16px}}.ModalFooter_modal_footer_right_side{margin-left:auto;text-align:right}.ModalFooter_secondary_button{font-size:14px !important;margin-right:13px !important}.ModalFooter_primary_button{font-size:14px !important}\n", ""]);

// Exports
exports.locals = {
	"modal_footer": "ModalFooter_modal_footer",
	"modal_footer_left_side": "ModalFooter_modal_footer_left_side",
	"modal_footer_right_side": "ModalFooter_modal_footer_right_side",
	"secondary_button": "ModalFooter_secondary_button",
	"primary_button": "ModalFooter_primary_button"
};
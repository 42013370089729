import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Loader } from '../../../Core/Components/Loader/Loader';
import styles from './CancelDowngradingDialog.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';

export const CANCEL_DOWNGRADING_DIALOG_STEPS = {
  LOADING: 'loading',
  CONFIRM_CANCELING_DOWNGRADING: 'confirmCancelingDowngrading',
  DOWNGRADING_CANCELED: 'downgradingCanceled',
};

const CancelDowngradingDialogBase = ({
  currentPlanName,
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  renderedStep,
}) => {
  const t = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        {renderedStep === CANCEL_DOWNGRADING_DIALOG_STEPS.LOADING && <Loader />}
        {renderedStep ===
          CANCEL_DOWNGRADING_DIALOG_STEPS.CONFIRM_CANCELING_DOWNGRADING && (
          <div>
            <h3 className={styles.dialog_header}>
              If you cancel the downgrade, you will stay on the{' '}
              {currentPlanName} plan
            </h3>
          </div>
        )}
        {renderedStep ===
          CANCEL_DOWNGRADING_DIALOG_STEPS.DOWNGRADING_CANCELED && (
          <div>
            <h3 className={styles.dialog_header}>
              Congratulations! You are staying on the {currentPlanName} plan.
            </h3>
          </div>
        )}
      </DialogContent>
      {renderedStep !== CANCEL_DOWNGRADING_DIALOG_STEPS.LOADING && (
        <DialogActions>
          {renderedStep ===
            CANCEL_DOWNGRADING_DIALOG_STEPS.CONFIRM_CANCELING_DOWNGRADING && (
            <Button onClick={onCancel} color="default" variant="text">
              <span className={styles.button}>{t('nevermind')}</span>
            </Button>
          )}
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            <span className={styles.button}>
              {renderedStep ===
              CANCEL_DOWNGRADING_DIALOG_STEPS.CONFIRM_CANCELING_DOWNGRADING
                ? t('cancel-downgrade')
                : t('done')}
            </span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

CancelDowngradingDialogBase.propTypes = exact({
  currentPlanName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  renderedStep: PropTypes.oneOf([
    CANCEL_DOWNGRADING_DIALOG_STEPS.LOADING,
    CANCEL_DOWNGRADING_DIALOG_STEPS.CONFIRM_CANCELING_DOWNGRADING,
    CANCEL_DOWNGRADING_DIALOG_STEPS.DOWNGRADING_CANCELED,
  ]),
});

export const CancelDowngradingDialog = React.memo(CancelDowngradingDialogBase);

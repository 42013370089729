import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import styles from './ConfirmationDialog.scss';

const ConfirmationDialogBase = ({
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  confirmationMsg,
  confirmBtnText,
  cancelBtnText,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        <span className={styles.dialog_header}>{confirmationMsg}</span>
      </DialogTitle>

      <DialogActions>
        <Button onClick={onCancel} variant="text" color="default">
          <span className={styles.button}>{cancelBtnText}</span>
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          <span className={styles.button}>{confirmBtnText}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialogBase.defaultProps = {
  onClose: () => {},
  confirmBtnText: 'confirm',
  cancelBtnText: 'cancel',
};

ConfirmationDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmationMsg: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
});

export const ConfirmationDialog = React.memo(ConfirmationDialogBase);
ConfirmationDialog.displayName = 'ConfirmationDialog';

import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import React, { memo } from 'react';
import {
  Dialog,
  //  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { ModalFooter } from '../../../Core/Components/Modal/ModalFooter';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const CoppaPopUpBase = ({
  isDialogOpen,
  onClose,
  dialogContent,
  primaryBtnContent,
  secondaryBtnContent,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
}) => {
  const t = useTranslation();

  return (
    <Dialog open={isDialogOpen} onClose={onClose || (() => {})}>
      {/* <DialogTitle style={{ alignSelf: 'center' }}> */}
      {/* <div style={{ fontSize: 16, fontWeight: 400 }}></div> */}
      {/* </DialogTitle> */}
      <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
        <div
          style={{
            display: 'flex',
            fontSize: 16,
            fontWeight: 500,
            color: '#000',
          }}
        >
          {t(dialogContent)}
        </div>
      </DialogContent>
      <ModalFooter
        primaryBtnContent={t(primaryBtnContent)}
        secondaryBtnContent={t(secondaryBtnContent)}
        onPrimaryBtnClick={onPrimaryBtnClick}
        onSecondaryBtnClick={onSecondaryBtnClick}
      />
    </Dialog>
  );
};

CoppaPopUpBase.propTypes = exact({
  isDialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  dialogContent: PropTypes.string,
  primaryBtnContent: PropTypes.string.isRequired,
  secondaryBtnContent: PropTypes.string.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
});

export const CoppaPopUp = memo(CoppaPopUpBase);
CoppaPopUp.displayName = 'CoppaPopUp';

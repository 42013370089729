exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".YearWrap_wrapper{height:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-rendering:optimizeLegibility;background-color:#060412;letter-spacing:-0.2rem;line-height:0.65}@media only screen and (max-width: 480px){.YearWrap_wrapper{height:100vh}}@media screen and (max-width: 350px){.YearWrap_wrapper{height:auto}}.YearWrap_lottie_background{width:100%;height:110%}.YearWrap_logo_wrapper{width:98%;height:-moz-fit-content;height:fit-content;display:flex;justify-content:flex-start;align-items:center;margin:auto;padding:0 5px}.YearWrap_logo_img{height:auto;width:30vw;height:10vw}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "YearWrap_wrapper",
	"lottie_background": "YearWrap_lottie_background",
	"logo_wrapper": "YearWrap_logo_wrapper",
	"logo_img": "YearWrap_logo_img"
};
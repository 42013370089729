import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './Automations.scss';

const FeedbackDialogBase = ({
  isFeedbackPopupOpen,
  setIsFeedbackPopupOpen,
  feedbackPopupText,
  setFeedbackPopupText,
  submitFeedback,
}) => {
  return (
    <Dialog open={isFeedbackPopupOpen} fullWidth>
      <div style={{ padding: 20 }}>
        <div className={styles.feedback_dialog_title_container}>
          <div className={styles.feedback_dialog_title}>
            Have an idea for a new auto-message trigger?
          </div>
          <div
            onClick={() => {
              setIsFeedbackPopupOpen(false);
            }}
            style={{ cursor: 'pointer' }}
          >
            <CloseIcon />
          </div>
        </div>
        <TextField
          value={feedbackPopupText}
          onChange={e => {
            setFeedbackPopupText(e.target.value);
          }}
          placeholder="Type your ideas or feedback here…"
          multiline
          rows={5}
          maxRows={5}
          style={{ width: '100%', padding: 10, marginBottom: 10 }}
          InputProps={{
            classes: {
              input: styles.resize,
            },
          }}
          variant="outlined"
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button
            onClick={submitFeedback}
            color="primary"
            variant="contained"
            style={{ fontSize: 16 }}
            disabled={!feedbackPopupText}
          >
            Send
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

FeedbackDialogBase.propTypes = exact({
  isFeedbackPopupOpen: PropTypes.bool.isRequired,
  setIsFeedbackPopupOpen: PropTypes.func.isRequired,
  feedbackPopupText: PropTypes.string.isRequired,
  setFeedbackPopupText: PropTypes.func.isRequired,
  submitFeedback: PropTypes.func.isRequired,
});

export const FeedbackDialog = React.memo(FeedbackDialogBase);
FeedbackDialog.displayName = 'FeedbackDialog';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UserCard_size_17{font-size:17px}.UserCard_size_10{font-size:10px}.UserCard_size_11{font-size:11px}.UserCard_size_13{font-size:13px}.UserCard_size_15{font-size:15px}.UserCard_size_14{font-size:14px}.UserCard_size_16{font-size:16px}.UserCard_size_12{font-size:12px}.UserCard_extra_header{font-size:18px;font-weight:500;color:#000000}.UserCard_header,.UserCard_email_container p{font-size:17px;font-weight:500;color:#000000}.UserCard_header_only_font_size{font-size:17px;font-weight:500}.UserCard_sub_header{font-size:13px;color:#878787}.UserCard_sub_header_black{font-size:13px;color:black}.UserCard_sub_header_only_font_size{font-size:13px}.UserCard_paragraph_only_font_size{font-size:12px}.UserCard_tab_font_size{font-size:13px}.UserCard_pop_out_header{font-size:20px}.UserCard_title,.UserCard_name{font-size:15px;font-weight:500;color:#484848}.UserCard_title_black{font-size:15px;font-weight:500;color:black}.UserCard_title_only_font_size{font-size:15px}.UserCard_paragraph,.UserCard_age,.UserCard_number_label{font-size:12px;color:#484848}.UserCard_btn{font-size:14px}.UserCard_card{display:flex;justify-content:space-between;padding:22px 45px 42px 45px;flex-wrap:wrap}.UserCard_card>div{margin-top:20px}@media only screen and (max-width: 768px){.UserCard_card{padding:22px 20px 42px 20px}}.UserCard_avatar_container{display:flex}.UserCard_avatar{width:84px;height:84px;margin-right:24px}.UserCard_name_container{display:flex;flex-direction:column;justify-content:center}.UserCard_name_container>p{line-height:1.44;margin-bottom:6px}.UserCard_email_container{display:flex;height:100%;align-items:center}.UserCard_email_container p{margin:0}.UserCard_number_of_sessions_container{display:flex;align-items:center}.UserCard_divider{height:100%;border:1px solid #707070;margin-left:10px;margin-right:10px;opacity:0.2}.UserCard_number{font-size:24px;font-weight:bold;margin-bottom:6px}.UserCard_number_label{text-align:left;margin-bottom:6px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "UserCard_size_17",
	"size_10": "UserCard_size_10",
	"size_11": "UserCard_size_11",
	"size_13": "UserCard_size_13",
	"size_15": "UserCard_size_15",
	"size_14": "UserCard_size_14",
	"size_16": "UserCard_size_16",
	"size_12": "UserCard_size_12",
	"extra_header": "UserCard_extra_header",
	"header": "UserCard_header",
	"email_container": "UserCard_email_container",
	"header_only_font_size": "UserCard_header_only_font_size",
	"sub_header": "UserCard_sub_header",
	"sub_header_black": "UserCard_sub_header_black",
	"sub_header_only_font_size": "UserCard_sub_header_only_font_size",
	"paragraph_only_font_size": "UserCard_paragraph_only_font_size",
	"tab_font_size": "UserCard_tab_font_size",
	"pop_out_header": "UserCard_pop_out_header",
	"title": "UserCard_title",
	"name": "UserCard_name",
	"title_black": "UserCard_title_black",
	"title_only_font_size": "UserCard_title_only_font_size",
	"paragraph": "UserCard_paragraph",
	"age": "UserCard_age",
	"number_label": "UserCard_number_label",
	"btn": "UserCard_btn",
	"card": "UserCard_card",
	"avatar_container": "UserCard_avatar_container",
	"avatar": "UserCard_avatar",
	"name_container": "UserCard_name_container",
	"number_of_sessions_container": "UserCard_number_of_sessions_container",
	"divider": "UserCard_divider",
	"number": "UserCard_number"
};
import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import styles from './ConfirmCancelingDialog.scss';

const ConfirmCancelingDialogBase = ({
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  isOpen,
}) => {
  return (
    <CustomDialog
      isOpen={isOpen}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={onSecondaryBtnClick}
      primaryBtnContent="YES"
      secondaryBtnContent="NO"
    >
      <p className={styles.dialog_header}>
        Are you sure you want to cancel? you recent changes will not be saved.
      </p>
    </CustomDialog>
  );
};

ConfirmCancelingDialogBase.propTypes = exact({
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
});

export const ConfirmCancelingDialog = React.memo(ConfirmCancelingDialogBase);
ConfirmCancelingDialog.displayName = 'ConfirmCancelingDialog';

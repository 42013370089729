exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ChangeCreditCardDetailsIfNeeded_size_17{font-size:17px}.ChangeCreditCardDetailsIfNeeded_size_10{font-size:10px}.ChangeCreditCardDetailsIfNeeded_size_11{font-size:11px}.ChangeCreditCardDetailsIfNeeded_size_13{font-size:13px}.ChangeCreditCardDetailsIfNeeded_size_15{font-size:15px}.ChangeCreditCardDetailsIfNeeded_size_14{font-size:14px}.ChangeCreditCardDetailsIfNeeded_size_16{font-size:16px}.ChangeCreditCardDetailsIfNeeded_size_12{font-size:12px}.ChangeCreditCardDetailsIfNeeded_extra_header{font-size:18px;font-weight:500;color:#000000}.ChangeCreditCardDetailsIfNeeded_header{font-size:17px;font-weight:500;color:#000000}.ChangeCreditCardDetailsIfNeeded_header_only_font_size{font-size:17px;font-weight:500}.ChangeCreditCardDetailsIfNeeded_sub_header{font-size:13px;color:#878787}.ChangeCreditCardDetailsIfNeeded_sub_header_black{font-size:13px;color:black}.ChangeCreditCardDetailsIfNeeded_sub_header_only_font_size{font-size:13px}.ChangeCreditCardDetailsIfNeeded_paragraph_only_font_size{font-size:12px}.ChangeCreditCardDetailsIfNeeded_tab_font_size{font-size:13px}.ChangeCreditCardDetailsIfNeeded_pop_out_header{font-size:20px}.ChangeCreditCardDetailsIfNeeded_title{font-size:15px;font-weight:500;color:#484848}.ChangeCreditCardDetailsIfNeeded_title_black{font-size:15px;font-weight:500;color:black}.ChangeCreditCardDetailsIfNeeded_title_only_font_size{font-size:15px}.ChangeCreditCardDetailsIfNeeded_paragraph{font-size:12px;color:#484848}.ChangeCreditCardDetailsIfNeeded_btn{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ChangeCreditCardDetailsIfNeeded_size_17",
	"size_10": "ChangeCreditCardDetailsIfNeeded_size_10",
	"size_11": "ChangeCreditCardDetailsIfNeeded_size_11",
	"size_13": "ChangeCreditCardDetailsIfNeeded_size_13",
	"size_15": "ChangeCreditCardDetailsIfNeeded_size_15",
	"size_14": "ChangeCreditCardDetailsIfNeeded_size_14",
	"size_16": "ChangeCreditCardDetailsIfNeeded_size_16",
	"size_12": "ChangeCreditCardDetailsIfNeeded_size_12",
	"extra_header": "ChangeCreditCardDetailsIfNeeded_extra_header",
	"header": "ChangeCreditCardDetailsIfNeeded_header",
	"header_only_font_size": "ChangeCreditCardDetailsIfNeeded_header_only_font_size",
	"sub_header": "ChangeCreditCardDetailsIfNeeded_sub_header",
	"sub_header_black": "ChangeCreditCardDetailsIfNeeded_sub_header_black",
	"sub_header_only_font_size": "ChangeCreditCardDetailsIfNeeded_sub_header_only_font_size",
	"paragraph_only_font_size": "ChangeCreditCardDetailsIfNeeded_paragraph_only_font_size",
	"tab_font_size": "ChangeCreditCardDetailsIfNeeded_tab_font_size",
	"pop_out_header": "ChangeCreditCardDetailsIfNeeded_pop_out_header",
	"title": "ChangeCreditCardDetailsIfNeeded_title",
	"title_black": "ChangeCreditCardDetailsIfNeeded_title_black",
	"title_only_font_size": "ChangeCreditCardDetailsIfNeeded_title_only_font_size",
	"paragraph": "ChangeCreditCardDetailsIfNeeded_paragraph",
	"btn": "ChangeCreditCardDetailsIfNeeded_btn"
};
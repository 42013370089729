/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
import styles from './CustomerStoriesPagesStyles.scss';

export const focusScoreStorySequences = focus_improvement_percentage => {
  // const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  // const backgroundAnimationDelay = 1000;
  const backgroundAnimationDuration = 2000;

  const seq1WordsLength = 'You’ve made some gains.'.split(' ').length;
  const seq2WordsLength = 'Your focus score went up by'.split(' ').length;
  const seq3WordsLength = `${focus_improvement_percentage}%`.split('').length;

  const seq1Variants = {
    initial: { opacity: 0, y: -20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: (2 * textAnimationDuration) / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq2Variants = {
    initial: { opacity: 0 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: (2 * textAnimationDuration) / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  // const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  // const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  // const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  // const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  // const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  const seq1 = {
    id: 'seq1',
    // words: seq1Words,
    sentence: 'You’ve made some gains.',
    // wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay: 0,
    // exitDelay:
    //   seq1EnterTime + backgroundAnimationDuration - textAnimationDuration,
    style: {},
    className: styles.focusScore_seq_1,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        y: 20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          // staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq2 = {
    id: 'seq2',
    // words: seq2Words,
    sentence: 'Your focus score went up by',
    wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq2Variants,
    enterDelay: seq1TotalTime + backgroundAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.focusScore_seq_2,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
        },
      },
      exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq3 = {
    id: 'seq3',
    enterDelay: seq1TotalTime + seq2EnterTime + backgroundAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq3-word${1}`,
        text: `${focus_improvement_percentage}%`,
        enterDelay: seq1TotalTime + seq2EnterTime + backgroundAnimationDuration,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            y: 20,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
            },
          },
          // exit: {},
        },
        // style: { display: 'flex' },
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.focusScore_seq_3,
    variants: {
      initial: { opacity: 0, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  return [seq1, seq2, seq3];
};

import CircularProgress from '@material-ui/core/CircularProgress';
import dayJS from 'dayjs';
import Modal from '@material-ui/core/Modal';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import dayjsDuration from 'dayjs/plugin/duration';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import uuidv4 from 'uuid/v4';
import countryTelData from 'country-telephone-data';
import { format } from 'date-fns';
// import { Button } from '@material-ui/core';
import { ModalFooter } from '../../Core/Components/Modal/ModalFooter';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { withTranslation } from '../../Core/hocs/withTranslation/withTranslation';
import {
  appendDivToBody,
  defensiveThrow,
  removeElement,
  safeParseDate,
  injectIDs,
  removeAllAfterLastChar,
  isDefined,
  postToFunctionsWithDefaultData,
} from '../../utils/utils';
import styles from './AddClientModal.scss';
import { ClientDetailsForm } from './ClientDetailsForm/ClientDetailsForm';
import { ClientSuccessfullyRegistered } from './ClientSuccessfullyRegistered/ClientSuccessfullyRegistered';
import { SelectTags } from './SelectTags/SelectTags';
import { SelectQuestionnaires } from './SelectQuestionnaires/SelectQuestionnaires';
import { DeployAssessment } from './DeployAssessment/DeployAssessment';
// import { defaultAfterTrainingAssessmentIntervalMinutes } from '../../models/assessments/assessments';
import { TrackingSchedule } from './TrackingSchedule/TrackingSchedule';
import { DigitalCare } from './DigitalCare/DigitalCare';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { mdMediaMaxWidth } from '../../cssInJs/constants';
import { legacyQuestionsQuestionnaireId } from '../../models/symptom-tracker/symptom-tracker';
import { CopyIcon } from '../../Core/Icons/CopyIcon';
import {
  androidVersionCompatiableWithSymptomQuestionnaires,
  iOSVersionCompatiableWithSymptomQuestionnaires,
} from '../../models/symptom-tracker/new-symptom-tracker-app-versions';
import { addClientEventLogger } from './addClientModalUtils';
import { NeuroPromoDialog } from '../NeuroPromoDialog/NeuroPromoDialog';
import { CoppaPopUp } from './CoppaPopUp/CoppaPopUp';
import { getAge } from '../../models/person/person';

// import { CreateProgramForUserWrapper } from '../CreateProgramForUserWrapper/CreateProgramForUserWrapper';

dayJS.extend(dayjsDuration);

const userPageLogger = new MyEventWrapper('user_page');

const clonedCountryTelData = cloneDeep(countryTelData);
const countriesDataList = clonedCountryTelData.allCountries.map(country => {
  const newCountry = cloneDeep(country);
  newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
  return newCountry;
});

const onViewPricingPlansClicked = async () => {
  await new MyEvent('view_pricing_plans_clicked').log({
    from_where: 'add-client-modal',
  });
  window.open('new_account.html', '_blank');
};

const registerUser = async (userData, coppaConsent) => {
  try {
    // eslint-disable-next-line no-undef
    const registerUserResult = await fireFunctionPost('register', {
      ...userData,
    });
    const userPassword = registerUserResult.verificationCode;
    const userId = registerUserResult.gtoken;
    const { result, message } = registerUserResult;
    if (userId && coppaConsent.isConsentNeeded) {
      const currentTimestamp = Date.now();
      await database
        .ref(`termsConsent/coppa/patients/${userId}/v2024-06-15`)
        .set({
          consentGiven: coppaConsent.isConsentGiven,
          timestamp: currentTimestamp,
        });
    }

    return {
      userPassword,
      userId,
      result,
      message,
    };
  } catch (err) {
    throw err;
  }
};

const loadUserSelectedDisorders = async userId => {
  const snapshot = await database
    .ref(`brainDisordersSymptoms/${userId}`)
    .once('value', v => v);

  return snapshot.val();
};

const getDefaultDate = () => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 30);
  return currentDate;
};

const defaultClientDetailsValues = {
  country: 'United States',
  birthday: getDefaultDate(),
};

export const addClientSteps = {
  createAccount: 'createAccount',
  selectTags: 'selectTags',
  selectQuestionnaires: 'selectQuestionnaires',
  deployAssessment: 'deployAssessment',
  trackingSchedule: 'trackingSchedule',
  digitalCare: 'digitalCare',
};

const addClientAllOrderedSteps = [
  addClientSteps.createAccount,
  addClientSteps.selectTags,
  addClientSteps.selectQuestionnaires,
  addClientSteps.deployAssessment,
  addClientSteps.trackingSchedule,
  addClientSteps.digitalCare,
];

const getFormattedAddClientSteps = () => ({
  createAccount: 'Personal Info',
  selectTags: 'Tags',
  selectQuestionnaires: 'Questionnaires',
  deployAssessment: 'Assessment',
  trackingSchedule: 'Tracking schedule',
  digitalCare: 'Digital Care',
});

const addClientScreens = {
  loading: 'loading',
  userCreation: 'userCreation',
  failedToCreateClient: 'failedToCreateClient',
  clientWasSuccessfullyRegistered: 'clientWasSuccessfullyRegistered',
};

const defaultAssessmentOptions = {
  isSymptomQuestionnaireEnabled: true,
  isEEGEnabled: true,
  isCPTEnabled: true,
  shouldBeTrackingBeforeFirstSession: true,
  shouldBeTrackingPerMinutes: true,
  perMinutesTracking: 6,
  intervalType: 'weeks',
  isAssessmentOpen: true,
};

const coppaPopUPDefaults = {
  dialogContent:
    "I received parental consent to collect and handle this child's information, as required by law.",
  primaryBtnContent: 'YES, I CONFIRM',
  secondaryBtnContent: "NO, I HAVEN'T",
};

const initialRenderedScreen = addClientScreens.userCreation;

const generalHealthQuestionnaireId = '-MErOA0mosgJcptREuBL';
// const defaultQuestionnaires = [
//   generalHealthQuestionnaireId,
//   'incorrectIdThatShouldBeFilteredOut',
// ];

const entrySharingOptions = [
  {
    label: 'Client can request feedback from me',
    value: 'enableTextBasedFeedback',
  },
  {
    label: 'Client can reply to my feedback',
    value: 'enableJournalReply',
  },
];

const defaultBptJourneyId = window.environmentConfig.isDev
  ? '-McjC8yfZvirlTgodd-p'
  : '-Mr3xQQj3eF0tflppE-v';

const AddClientModalBase = ({
  t,
  closeModal,
  isModalOpen,
  brainDisorders,
  clinicId,
  isEditingMode,
  userId,
  allQuestionnaires,
  allTags,
  shouldUseNewSymptomTracker,
  userInfo,
  initialSelectedTags,
  initialSelectedQuestionnaires,
  initialAssessmentOptions,
  initialStep,
  finalStep,
  renderSuccessMessage,
  assessmentStagesInfo,
  initialSelectedAssessmentRegions = Object.values(assessmentStagesInfo.eegIds),
  isCustomerAppCompatiableWithSymptomQuestionnaires,
  isSelfRegistration,
  setIsRegistrationDialogOpen,
  nfFlag,
  shouldHideBack,
  assessmentAutoDeployableConfigs,
  questionnairesAutoDeployableConfigs,
  doneAssessments,
}) => {
  const defaultQuestionnaires = allQuestionnaires
    ? Object.keys(allQuestionnaires).filter(
        questionnaireId => allQuestionnaires[questionnaireId].includeByDefault
      )
    : [generalHealthQuestionnaireId];

  const [activeStep, setActiveStep] = useState(initialStep);
  const [selectedDisorders, setSelectedDisorders] = useState([]);
  const [selectedOriginalDisorders, setSelectedOriginalDisorders] = useState(
    []
  );
  const [isFormPrimaryBtnLoading, setIsFormPrimaryBtnLoading] = useState(false);
  const [userPassword, setUserPassword] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [newRegisteredUserId, setNewRegisteredUserId] = useState(null);
  const clientDetailsFormValuesRef = useRef();
  const clientDetailsFormRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [renderedScreen, setRenderedScreen] = useState(initialRenderedScreen);

  const [isCoppaDialogOpen, setIsCoppaDialogOpen] = useState(false);
  const [coppaConsent, setCoppaConsent] = useState({
    isConsentNeeded: false,
    isConsentGiven: false,
  });

  const [deleteCoppaConsent, setDeleteCoppaConsent] = useState(false);

  const [selectedTags, setSelectedTags] = useState(initialSelectedTags);

  const [failureScreenMsg, setFailureScreenMsg] = useState(null);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  // const [
  //   isShowingGoToUserProfileBtn,
  //   setIsShowingGoToUserProfileBtn,
  // ] = useState(false);

  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState(
    initialSelectedQuestionnaires
  );

  const [assessmentOptions, setAssessmentOptions] = useState({
    ...initialAssessmentOptions,
    intervalType: defaultAssessmentOptions.intervalType,
    isAssessmentOpen: defaultAssessmentOptions.isAssessmentOpen,
  });

  const [addClientReachedLimitModal, setAddClientReachedLimitModal] = useState(
    false
  );

  const [canAccessNf, setCanAccessNf] = useState(null);

  const [journeys, setJourneys] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState('');
  const [isJourneyEnabled, setIsJourneyEnabled] = useState(true);
  const [categories, setCategories] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isCategoriesEnabled, setIsCategoriesEnabled] = useState(true);
  const [
    selectedEntrySharingOptions,
    setSelectedEntrySharingOptions,
  ] = useState(['enableTextBasedFeedback', 'enableJournalReply']);
  const [isEntrySharingEnabled, setIsEntrySharingEnabled] = useState(true);
  const [deployedJourney, setDeployedJourney] = useState(null);
  const [lagoClinicConfig, setLagoClinicConfig] = useState(null);
  const [isFreeAccessError, setIsFreeAccessError] = useState(false);
  const [clipboardClicked, setClipboardClicked] = useState(false);
  const [registrationCode, setRegistrationCode] = useState(false);
  const [usedAssessmentBeforeJune24, setUsedAssessmentBeforeJune24] = useState(
    null
  );

  const [autoDefaultQuestionnaires, setAutoDefaultQuestionnaires] = useState(
    []
  );

  const [
    isAssessmentEnabledFromDefault,
    setIsAssessmentEnabledFromDefault,
  ] = useState(false);

  const [coppaConfig, setCoppaConfig] = useState(null);

  const [isTeamMembersMenuOpen, setIsTeamMembersMenuOpen] = useState(false);
  const [teamMembersList, setTeamMembersList] = useState(null);
  const [newUserAssignedTo, setNewUserAssignedTo] = useState({
    assignedTo: {},
  });

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref('termsConsent/coppa/config/v2024-06-15')
        .once('value', v => v);
      setCoppaConfig(snapshot.val());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(`clinicConfig/nfFlag/${sessionStorage.userId}/isEnabled`)
        .once('value', v => v);
      setCanAccessNf(snapshot.val());
    })();
  });

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(
          `/clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/enabled`
        )
        .once('value', v => v);
      setIsAssessmentEnabledFromDefault(snapshot.val());
    })();
  });

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(`userInfo/${sessionStorage.userId}/registrationCode`)
        .once('value', v => v);
      setRegistrationCode(snapshot.val());
    })();
  });

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(
          `clinicConfig/assessments/usedAssessmentBeforeJune24/${sessionStorage.userId}`
        )
        .once('value', v => v);
      setUsedAssessmentBeforeJune24(snapshot.val());
    })();
  });

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(`clinics/${sessionStorage.userId}/subEmails`)
        .once('value', v => v);
      setTeamMembersList(snapshot.val());
      if (snapshot.val()) {
        if (
          JSON.parse(
            // eslint-disable-next-line no-undef
            localStorage.getItem(
              `${
                sessionStorage.isTeamMember === 'true'
                  ? sessionStorage.teamMemberId
                  : sessionStorage.userId
              }-teamMemberAssign`
            )
          )
        ) {
          const newAssignedTo = JSON.parse(
            // eslint-disable-next-line no-undef
            localStorage.getItem(
              `${
                sessionStorage.isTeamMember === 'true'
                  ? sessionStorage.teamMemberId
                  : sessionStorage.userId
              }-teamMemberAssign`
            )
          );
          setNewUserAssignedTo({ ...newAssignedTo });
        } else if (sessionStorage.isTeamMember === 'true') {
          const newAssignedTo = { assignedTo: {} };
          const teamMembersListDB = snapshot.val();
          Object.keys(teamMembersListDB).forEach(teamMemberItemId => {
            if (
              teamMembersListDB[teamMemberItemId].userId &&
              sessionStorage.teamMemberId ===
                teamMembersListDB[teamMemberItemId].userId
            ) {
              newAssignedTo.assignedTo[
                teamMembersListDB[teamMemberItemId].userId
              ] =
                sessionStorage.teamMemberId ===
                teamMembersListDB[teamMemberItemId].userId;
            }
          });

          setNewUserAssignedTo({ ...newAssignedTo });
        } else {
          const newAssignedTo = { assignedTo: {} };
          const teamMembersListDB = snapshot.val();
          Object.keys(teamMembersListDB).forEach(teamMemberItemId => {
            if (teamMembersListDB[teamMemberItemId].userId) {
              newAssignedTo.assignedTo[
                teamMembersListDB[teamMemberItemId].userId
              ] = true;
            }
          });

          setNewUserAssignedTo({ ...newAssignedTo });
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!journeys && !lagoClinicConfig && !categories) {
        const snapshot0 = await database
          .ref('lago/educational/defaults/categories')
          .once('value', v => v);
        setCategories(snapshot0.val());
        // setSelectedCategories(Object.keys(snapshot0.val()));
        const snapshot = await database
          .ref(`clinicConfig/lagoEnabled/${sessionStorage.userId}`)
          .once('value', v => v);
        setLagoClinicConfig(snapshot.val());
        setSelectedJourney(snapshot.val().defaultJourneyId);
        setIsJourneyEnabled(snapshot.val().enableDefaultContentJourneys);
        setIsCategoriesEnabled(snapshot.val().enableDefaultResources);
        setIsEntrySharingEnabled(snapshot.val().enableDefaultJournalSharing);
        if (
          !snapshot.val().journalSharingSettings ||
          !snapshot.val().journalSharingSettings.enableDefaultJournalReply
        ) {
          const copy = [...selectedEntrySharingOptions];
          const index = copy.indexOf('enableJournalReply');
          if (index !== -1) {
            copy.splice(index, 1);
          }
          setSelectedEntrySharingOptions(copy);
        }
        if (
          !snapshot.val().journalSharingSettings ||
          !snapshot.val().journalSharingSettings
            .enableDefaultTextBasedFeedbackRequest
        ) {
          const copy = [...selectedEntrySharingOptions];
          const index = copy.indexOf('enableTextBasedFeedback');
          if (index !== -1) {
            copy.splice(index, 1);
          }
          setSelectedEntrySharingOptions(copy);
        }
        let selcetedCategoriesCopy = [];
        if (snapshot.val().defaultResourceCategories) {
          const categoriesToRemove = Object.keys(
            snapshot.val().defaultResourceCategories
          ).filter(
            categoryId =>
              !snapshot.val().defaultResourceCategories[categoryId] ||
              snapshot.val().defaultResourceCategories[categoryId].isEnabled ===
                false
          );
          selcetedCategoriesCopy = Object.keys(snapshot0.val()).filter(
            el => !categoriesToRemove.includes(el)
          );
        } else {
          Object.keys(snapshot0.val()).forEach(categoryId => {
            selcetedCategoriesCopy.push(categoryId);
          });
        }
        setSelectedCategories(selcetedCategoriesCopy);
        const snapshot1 = await database
          .ref('lago/educational/defaults/journeys')
          .once('value', v => v);
        setJourneys(snapshot1.val());
        // setSelectedJourney(Object.keys(snapshot.val())[0])
        if (userId) {
          const snapshot2 = await database
            .ref(`lago/educational/users/${userId}`)
            .once('value', v => v);
          if (snapshot2.val()) {
            const snapshot3 = await database
              .ref(
                `deployedSession/${sessionStorage.userId}/${userId}/journeyDetails/percentageDone`
              )
              .once('value', v => v);

            setDeployedJourney({
              ...snapshot2.val(),
              percentageDone: snapshot3.val(),
            });
            setSelectedJourney(snapshot2.val().journeyConfig.journeyId);
          }
        }
      }
    })();
  });

  useEffect(() => {
    if (!nfFlag) {
      setAssessmentOptions({
        ...assessmentOptions,
        isEEGEnabled: false,
        isCPTEnabled: false,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isCustomerAppCompatiableWithSymptomQuestionnaires) {
      setAssessmentOptions(prvAssessmentOptions => ({
        ...prvAssessmentOptions,
        isSymptomQuestionnaireEnabled: false,
      }));
    }
  }, [isCustomerAppCompatiableWithSymptomQuestionnaires]);

  useEffect(() => {
    if (assessmentAutoDeployableConfigs && isAssessmentEnabledFromDefault) {
      const assessmentSelectedStages =
        assessmentAutoDeployableConfigs &&
        assessmentAutoDeployableConfigs.selectedStages
          ? assessmentAutoDeployableConfigs &&
            assessmentAutoDeployableConfigs.selectedStages
          : [];
      const initialAssessmentOptionsDefaults = {
        shouldBeTrackingPerMinutes: assessmentAutoDeployableConfigs.recurring,
        shouldBeTrackingBeforeFirstSession:
          assessmentAutoDeployableConfigs.beforeNextSession,
        perMinutesTracking: assessmentAutoDeployableConfigs.frequency,
        intervalType: assessmentAutoDeployableConfigs.intervalType,
        isSymptomQuestionnaireEnabled: assessmentSelectedStages.includes(
          assessmentStagesInfo.ids.QUESTIONNAIRES
        ),
        isCPTEnabled: assessmentSelectedStages.includes(
          assessmentStagesInfo.ids.CPT
        ),
        isEEGEnabled:
          Object.values(assessmentStagesInfo.eegIds).some(sensingRegionId =>
            assessmentSelectedStages.includes(sensingRegionId)
          ) || assessmentSelectedStages.find(stage => stage === 'Headset'),
        isAssessmentOpen: !!assessmentSelectedStages,
        stagesType: assessmentAutoDeployableConfigs.stagesType,
      };
      // eslint-disable-next-line no-use-before-define
      setSelectedAssessmentRegions(
        assessmentAutoDeployableConfigs &&
          assessmentAutoDeployableConfigs.selectedStages &&
          Object.values(assessmentStagesInfo.eegIds).some(sensingRegionId =>
            assessmentSelectedStages.includes(sensingRegionId)
          )
          ? assessmentAutoDeployableConfigs.selectedStages.filter(stage =>
              Object.keys(assessmentStagesInfo.eegNames).includes(stage)
            )
          : Object.keys(assessmentStagesInfo.eegNames)
      );
      setAssessmentOptions(initialAssessmentOptionsDefaults);
    }
  }, [
    assessmentAutoDeployableConfigs,
    isAssessmentEnabledFromDefault,
    isModalOpen,
  ]);

  useEffect(() => {
    if (questionnairesAutoDeployableConfigs) {
      setAutoDefaultQuestionnaires(questionnairesAutoDeployableConfigs);
    }
  }, [questionnairesAutoDeployableConfigs]);

  const [selectedAssessmentRegions, setSelectedAssessmentRegions] = useState(
    initialSelectedAssessmentRegions.filter(id => id !== 'Back')
  );

  const [formInitialValues, setFormInitialValues] = useState(
    defaultClientDetailsValues
  );

  const [
    shouldShowQuestionnaireForSelfRegUser,
    setShouldShowQuestionnaireForSelfRegUser,
  ] = useState(false);

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${mdMediaMaxWidth}px)`,
  });

  const getAddClientOrderedSteps = () => {
    const {
      isSymptomQuestionnaireEnabled,
      isEEGEnabled,
      isCPTEnabled,
    } = assessmentOptions;

    const initialStepIndex = isDefined(initialStep)
      ? addClientAllOrderedSteps.indexOf(initialStep)
      : 0;
    const finalStepIndex = isDefined(finalStep)
      ? addClientAllOrderedSteps.indexOf(finalStep)
      : addClientAllOrderedSteps.length - 1;

    if (
      (activeStep === addClientSteps.deployAssessment ||
        activeStep === addClientSteps.digitalCare) &&
      finalStep === addClientSteps.digitalCare &&
      !isSymptomQuestionnaireEnabled &&
      !isEEGEnabled &&
      !isCPTEnabled
    ) {
      return [
        ...addClientAllOrderedSteps.slice(0, 4),
        ...addClientAllOrderedSteps.slice(5),
      ];
    }
    if (
      isSelfRegistration &&
      doneAssessments &&
      Object.keys(doneAssessments).length
    ) {
      return [
        ...addClientAllOrderedSteps.slice(
          0,
          shouldShowQuestionnaireForSelfRegUser ? 3 : 2
        ),
        ...addClientAllOrderedSteps.slice(5),
      ];
    }
    return addClientAllOrderedSteps.slice(initialStepIndex, finalStepIndex + 1);
  };
  const orderedSteps = getAddClientOrderedSteps();

  const getUserAge = () => {
    const birthday = userInfo
      ? safeParseDate({
          str: get(userInfo, 'privateDetails.dateOfBirth'),
        })
      : formInitialValues.birthday;

    const age = dayJS(new Date()).diff(dayJS(birthday), 'year', true);
    return age;
  };

  const isQuestionnaireSuitableBasedOnUserAge = questionnaire => {
    const userAge = getUserAge();
    return (
      userAge >= (questionnaire.lowerAgeCutoff || -Infinity) &&
      userAge <= (questionnaire.upperAgeCutoff || Infinity)
    );
  };

  const onAddTag = tag => {
    addClientEventLogger.log('on_add_tag', {
      ...tag,
    });
    return setSelectedTags(prvTags => uniqBy([...prvTags, tag], 'id'));
  };

  const onDeleteTag = tag => {
    addClientEventLogger.log('on_delete_tag', {
      ...tag,
    });
    setSelectedTags(prvTags =>
      prvTags.filter(tagObject => tagObject.id !== tag.id)
    );
  };

  const onAddQuestionnaire = questionnaire => {
    addClientEventLogger.log('on_add_questionnaire', {
      ...questionnaire,
    });
    return setSelectedQuestionnaires(prvQuestionnaire =>
      uniqBy([...prvQuestionnaire, questionnaire], 'id')
    );
  };
  const onDeleteQuestionnaire = questionnaire => {
    addClientEventLogger.log('on_delete_questionnaire', {
      ...questionnaire,
    });
    setSelectedQuestionnaires(prvQuestionnaire =>
      prvQuestionnaire.filter(
        questionnaireObject => questionnaireObject.id !== questionnaire.id
      )
    );
  };

  const onDiscardAllQuestionnaires = () => {
    addClientEventLogger.log('on_discard_all_questionnaires');
    setSelectedQuestionnaires([]);
  };

  useEffect(() => {
    if (activeStep === addClientSteps.selectTags && selectedTags.length !== 0) {
      setErrorMsg(null);
    }
  }, [activeStep, selectedTags.length]);

  const shouldSkipDeployAssessmentStep = useCallback(() => {
    const {
      isCPTEnabled,
      isEEGEnabled,
      isSymptomQuestionnaireEnabled,
    } = assessmentOptions;

    return (
      !isCPTEnabled &&
      !isEEGEnabled &&
      !isSymptomQuestionnaireEnabled &&
      finalStep === addClientSteps.digitalCare
    );
  }, [assessmentOptions, finalStep]);

  const getStructuredClientDetailsFromForm = useCallback(
    ({ isEditing = false } = {}) => {
      const clientDetails = clientDetailsFormValuesRef.current;
      if (isEditing) {
        return {
          firstName: clientDetails.firstName,
          lastName: clientDetails.lastName,
          isRealEmail: clientDetails.isClientRealEmail,
          privateDetails: {
            assessment: clientDetails.notes || '',
            dateOfBirth: format(clientDetails.birthday, 'yyyy-MM-dd'),
            gender: clientDetails.gender,
          },
          ...newUserAssignedTo,
        };
      }

      const { dialCode } = countriesDataList.find(
        countryData => countryData.name === clientDetails.country
      );

      const categoriesConfig = {};
      Object.keys(categories).forEach(categoryId => {
        categoriesConfig[categoryId] = {
          isEnabled: !!selectedCategories.find(
            selectedCategoryId => selectedCategoryId === categoryId
          ),
        };
      });

      return {
        assessment: clientDetails.notes || '',
        dateOfBirth: format(clientDetails.birthday, 'yyyy-MM-dd'),
        firstName: clientDetails.firstName,
        lastName: clientDetails.lastName,
        gender: clientDetails.gender,
        phoneNumber: clientDetails.phone
          ? `+${dialCode}${clientDetails.phone}`
          : '',
        clinicId,
        forPatient: true,
        isRealEmail: clientDetails.isClientRealEmail,
        email: clientDetails.email.toLowerCase(),
        shouldUseNewSymptomTracker: true,
        journeyId: selectedJourney,
        lagoSettings: {
          isEnabled: isJourneyEnabled,
          EnableJournalSharing: isEntrySharingEnabled,
          'journalSharingSettings-enableJournalReply': !isEntrySharingEnabled
            ? isEntrySharingEnabled
            : !!selectedEntrySharingOptions.find(
                selectedEntrySharingOption =>
                  selectedEntrySharingOption === 'enableJournalReply'
              ),
          'journalSharingSettings-enableTextBasedFeedback': !isEntrySharingEnabled
            ? isEntrySharingEnabled
            : !!selectedEntrySharingOptions.find(
                selectedEntrySharingOption =>
                  selectedEntrySharingOption === 'enableTextBasedFeedback'
              ),
          enableResources: isCategoriesEnabled,
          'journeyConfig-categories': categoriesConfig,
        },
        ...newUserAssignedTo,
      };
    },
    [
      clinicId,
      selectedJourney,
      isJourneyEnabled,
      isEntrySharingEnabled,
      selectedEntrySharingOptions,
      categories,
      selectedCategories,
      newUserAssignedTo,
    ]
  );

  const createClient = useCallback(
    async assessmentOptionsEvent => {
      const userData = getStructuredClientDetailsFromForm();
      try {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          `${
            sessionStorage.isTeamMember === 'true'
              ? sessionStorage.teamMemberId
              : sessionStorage.userId
          }-teamMemberAssign`,
          JSON.stringify(newUserAssignedTo)
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
      // eslint-disable-next-line no-shadow
      const { userPassword, userId, result, message } = await registerUser(
        userData,
        coppaConsent
      );

      if (result) {
        sessionStorage.customerId = userId;
        sessionStorage.customerEmail = userData.email;
        sessionStorage.customerInfo = JSON.stringify(userData);
        setUserEmail(userData.email);
        setUserPassword(userPassword);
        setNewRegisteredUserId(userId);

        addClientEventLogger.log('new_client_save', {
          ...userData,
          userId,
          fbtoken: null,
          entered_from: window.location.pathname.substring(1),
          is_assessment_deployed:
            assessmentOptionsEvent.isCPTEnabled ||
            assessmentOptionsEvent.isEEGEnabled ||
            assessmentOptionsEvent.isSymptomQuestionnaireEnabled,
        });

        if (
          assessmentOptionsEvent.isCPTEnabled ||
          assessmentOptionsEvent.isEEGEnabled ||
          assessmentOptionsEvent.isSymptomQuestionnaireEnabled
        ) {
          userPageLogger.log(
            'on_schedule_assessment_dialog_primary_btn_click',
            {
              customerId: userId,
              ...assessmentOptions,
              entered_from: 'client_creation',
              modification: 'add',
            }
          );
        }
        return userId;
      }
      throw new Error(message);
    },
    [getStructuredClientDetailsFromForm, coppaConsent]
  );

  const updateClientDetailsIfNeeded = useCallback(async () => {
    if (initialStep === addClientSteps.createAccount) {
      const userData = getStructuredClientDetailsFromForm({ isEditing: true });
      await database.ref(`userInfo/${userId}`).update(userData);
      await database
        .ref(`/deployedSession/${sessionStorage.userId}/${userId}/assignedTo`)
        .update(userData.assignedTo);
      if (coppaConsent.isConsentNeeded) {
        await database
          .ref(`termsConsent/coppa/patients/${userId}/v2024-06-15`)
          .set({
            consentGiven: coppaConsent.isConsentGiven,
            timestamp: Date.now(),
          });
      } else if (deleteCoppaConsent) {
        await database
          .ref(`termsConsent/coppa/patients/${userId}/v2024-06-15`)
          .remove();
      }
      addClientEventLogger.log('client_edit', {
        userId,
        ...userData,
        fbtoken: null,
      });
    }

    return userId;
  }, [getStructuredClientDetailsFromForm, initialStep, userId, coppaConsent]);

  const didClinicActUponStep = useCallback(
    step => {
      const actualFinalStep =
        finalStep === addClientSteps.digitalCare &&
        shouldSkipDeployAssessmentStep()
          ? addClientSteps.deployAssessment
          : finalStep;
      return (
        orderedSteps.indexOf(step) >= orderedSteps.indexOf(initialStep) &&
        orderedSteps.indexOf(step) <= orderedSteps.indexOf(actualFinalStep)
      );
    },
    [finalStep, initialStep, orderedSteps, shouldSkipDeployAssessmentStep]
  );

  const createClientWithSymptomTracking = useCallback(async () => {
    setLoadingText(
      isEditingMode ? t('updating-user-info...') : t('creating-user...')
    );
    if (!isEditingMode) {
      setTimeout(() => {
        setLoadingText('Setting configurations');
      }, 2000);
      setTimeout(() => {
        setLoadingText('Initializing account');
      }, 4000);
    }

    const sortSelectedAssessmentRegions = regions => {
      const newRegions = [...regions];
      return newRegions.sort((region1, region2) => {
        const sortedRegions = Object.values(assessmentStagesInfo.eegIds);
        return sortedRegions.indexOf(region1) - sortedRegions.indexOf(region2);
      });
    };

    try {
      const clientId = isEditingMode
        ? await updateClientDetailsIfNeeded()
        : await createClient(assessmentOptions);

      const updateDeployedQuestionnaires = () =>
        database
          .ref(`symptomTracker/deployedQuestionnaires/${clientId}`)
          .set(selectedQuestionnaires.map(questionnaire => questionnaire.id));

      const clearAnsweredQuestionnairesDataForApps = () =>
        database.ref(`symptomTracker/formCache/${clientId}`).set(null);

      const updateAssessmentIfNeededAfterQuestionnairesUpdated = () => {
        if (selectedQuestionnaires.length === 0) {
          if (
            assessmentOptions.isSymptomQuestionnaireEnabled &&
            !assessmentOptions.isEEGEnabled &&
            !assessmentOptions.isCPTEnabled
          ) {
            setAssessmentOptions(prvAssessmentOptions => ({
              ...prvAssessmentOptions,
              shouldBeTrackingBeforeFirstSession: false,
              shouldBeTrackingPerMinutes: false,
            }));

            const assessmentToBeDeployed = {
              recurring: false,
              beforeNextSession: false,
              afterTrainingDuration: assessmentOptions.perMinutesTracking,
              selectedStages: [],
              stagesType: assessmentOptions.stagesType || 'stages',
            };

            return database
              .ref(`assessments/deployedAssessments/${clientId}`)
              .update(assessmentToBeDeployed);
          }
          const selectedStagesWithoutQuestionnaires = [
            // eslint-disable-next-line  no-nested-ternary
            ...(assessmentOptions.isEEGEnabled
              ? assessmentOptions.stagesType === 'headsetStages'
                ? ['Headset']
                : sortSelectedAssessmentRegions(selectedAssessmentRegions)
              : []),
            ...(assessmentOptions.isCPTEnabled
              ? [assessmentStagesInfo.ids.CPT]
              : []),
          ];
          return database
            .ref(`assessments/deployedAssessments/${clientId}/selectedStages`)
            .set(selectedStagesWithoutQuestionnaires);
        }

        return null;
      };

      const updateSelectedTags = () => {
        setLoadingText(t('optimizing-tracking...'));
        database
          .ref(`symptomTracker/selectedTags/${clientId}`)
          .set(selectedTags.map(tag => tag.id));
      };

      const updateDeployedAssessment = async () => {
        const isSelectedStages = !![
          ...(assessmentOptions.isSymptomQuestionnaireEnabled
            ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
            : []),
          // eslint-disable-next-line  no-nested-ternary
          ...(assessmentOptions.isEEGEnabled
            ? assessmentOptions.stagesType === 'headsetStages'
              ? ['Headset']
              : sortSelectedAssessmentRegions(selectedAssessmentRegions)
            : []),
          ...(assessmentOptions.isCPTEnabled
            ? [assessmentStagesInfo.ids.CPT]
            : []),
        ].length;
        if (isSelectedStages) {
          setLoadingText(t('deploying-assessment...'));
        }
        const assessmentToBeDeployed = {
          recurring:
            assessmentOptions.shouldBeTrackingPerMinutes && isSelectedStages,
          beforeNextSession:
            isSelectedStages &&
            (!assessmentOptions.shouldBeTrackingPerMinutes ||
              assessmentOptions.shouldBeTrackingBeforeFirstSession),
          afterTrainingDuration:
            (assessmentOptions.shouldBeTrackingPerMinutes &&
              assessmentOptions.perMinutesTracking) ||
            defaultAssessmentOptions.perMinutesTracking,
          selectedStages: [
            ...(assessmentOptions.isSymptomQuestionnaireEnabled
              ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
              : []),
            // eslint-disable-next-line  no-nested-ternary
            ...(assessmentOptions.isEEGEnabled
              ? assessmentOptions.stagesType === 'headsetStages'
                ? ['Headset']
                : sortSelectedAssessmentRegions(selectedAssessmentRegions)
              : []),
            ...(assessmentOptions.isCPTEnabled
              ? [assessmentStagesInfo.ids.CPT]
              : []),
          ],
          frequency:
            (assessmentOptions.shouldBeTrackingPerMinutes &&
              assessmentOptions.perMinutesTracking) ||
            defaultAssessmentOptions.perMinutesTracking,
          intervalType: assessmentOptions.intervalType,
          creationDate: Date.now(),
          isAssessmentOpen:
            assessmentOptions.isAssessmentOpen && isSelectedStages,
          stagesType: assessmentOptions.stagesType || 'stages',
        };
        const minsUsage = await database
          .ref(`achievements/usages/${clientId}/totalUsage/minsUsage`)
          .once('value')
          .then(snapshot => snapshot.val() || 0);

        const numSessions = await database
          .ref(`achievements/usages/${clientId}/totalUsage/numOfSessions`)
          .once('value')
          .then(snapshot => snapshot.val() || 0);

        await database.ref(`assessments/deployedAssessments/${clientId}`).set({
          ...assessmentToBeDeployed,
          minsUsage,
          numSessions,
        });
      };

      const updateBrainDisordersData = () => {
        const brainDisorderData = selectedDisorders.reduce(
          (acc, cur) => ({ ...acc, [cur]: true }),
          {}
        );

        return database
          .ref(`brainDisordersSymptoms/${userId}`)
          .set(brainDisorderData);
      };

      const updateJourneyConfig = () => {
        const categoriesConfig = {};
        Object.keys(categories).forEach(categoryId => {
          categoriesConfig[categoryId] = {
            isEnabled: !!selectedCategories.find(
              selectedCategoryId => selectedCategoryId === categoryId
            ),
          };
        });

        const journeyConfigData = {
          journeyId: selectedJourney,
          lagoSettings: {
            isEnabled: isJourneyEnabled,
            EnableJournalSharing: isEntrySharingEnabled,
            'journalSharingSettings-enableJournalReply': !isEntrySharingEnabled
              ? isEntrySharingEnabled
              : !!selectedEntrySharingOptions.find(
                  selectedEntrySharingOption =>
                    selectedEntrySharingOption === 'enableJournalReply'
                ),
            'journalSharingSettings-enableTextBasedFeedback': !isEntrySharingEnabled
              ? isEntrySharingEnabled
              : !!selectedEntrySharingOptions.find(
                  selectedEntrySharingOption =>
                    selectedEntrySharingOption === 'enableTextBasedFeedback'
                ),
            enableResources: isCategoriesEnabled,
            'journeyConfig-categories': categoriesConfig,
          },
        };

        return database
          .ref(`lago/educational/tempJourneyPath/${userId}`)
          .set(journeyConfigData);
      };

      if (isEditingMode) {
        if (!shouldUseNewSymptomTracker) {
          await updateBrainDisordersData();
        }

        if (didClinicActUponStep(addClientSteps.selectTags)) {
          await updateSelectedTags();
        }

        if (didClinicActUponStep(addClientSteps.selectQuestionnaires)) {
          await updateDeployedQuestionnaires();
          await updateAssessmentIfNeededAfterQuestionnairesUpdated();
          clearAnsweredQuestionnairesDataForApps();
        }

        if (didClinicActUponStep(addClientSteps.deployAssessment)) {
          await updateDeployedAssessment();
        }
        // if (didClinicActUponStep(addClientSteps.digitalCare)) {
        //   await updateJourneyConfig();
        // }
        await updateJourneyConfig();
      } else {
        await updateSelectedTags();
        await updateDeployedQuestionnaires();
        await updateDeployedAssessment();
      }
      // eslint-disable-next-line no-unused-expressions
      setIsRegistrationDialogOpen && setIsRegistrationDialogOpen(false);
      setRenderedScreen(addClientScreens.clientWasSuccessfullyRegistered);
    } catch (err) {
      setFailureScreenMsg(err.message || err.toString());
      setRenderedScreen(addClientScreens.failedToCreateClient);
      defensiveThrow({ error: err });
    }
  }, [
    isEditingMode,
    t,
    assessmentStagesInfo,
    updateClientDetailsIfNeeded,
    createClient,
    selectedQuestionnaires,
    assessmentOptions,
    selectedAssessmentRegions,
    selectedTags,
    selectedDisorders,
    userId,
    shouldUseNewSymptomTracker,
    didClinicActUponStep,
    selectedJourney,
    isJourneyEnabled,
    isEntrySharingEnabled,
    selectedEntrySharingOptions,
    categories,
    selectedCategories,
  ]);

  const updateDeployedAssessmentStages = async clientId => {
    const sortSelectedAssessmentRegions = regions => {
      const newRegions = [...regions];
      return newRegions.sort((region1, region2) => {
        const sortedRegions = Object.values(assessmentStagesInfo.eegIds);
        return sortedRegions.indexOf(region1) - sortedRegions.indexOf(region2);
      });
    };
    const isSelectedStages = !![
      ...(assessmentOptions.isSymptomQuestionnaireEnabled
        ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
        : []),
      // eslint-disable-next-line  no-nested-ternary
      ...(assessmentOptions.isEEGEnabled
        ? assessmentOptions.stagesType === 'headsetStages'
          ? ['Headset']
          : sortSelectedAssessmentRegions(selectedAssessmentRegions)
        : []),
      ...(assessmentOptions.isCPTEnabled ? [assessmentStagesInfo.ids.CPT] : []),
    ].length;
    // setLoadingText(t('deploying-assessment...'));
    const assessmentToBeDeployed = {
      recurring: assessmentOptions.shouldBeTrackingPerMinutes,
      beforeNextSession:
        isSelectedStages &&
        (!assessmentOptions.shouldBeTrackingPerMinutes ||
          assessmentOptions.shouldBeTrackingBeforeFirstSession),
      afterTrainingDuration:
        (assessmentOptions.shouldBeTrackingPerMinutes &&
          assessmentOptions.perMinutesTracking) ||
        defaultAssessmentOptions.perMinutesTracking,
      selectedStages: [
        ...(assessmentOptions.isSymptomQuestionnaireEnabled
          ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
          : []),
        // eslint-disable-next-line  no-nested-ternary
        ...(assessmentOptions.isEEGEnabled
          ? assessmentOptions.stagesType === 'headsetStages'
            ? ['Headset']
            : sortSelectedAssessmentRegions(selectedAssessmentRegions)
          : []),
        ...(assessmentOptions.isCPTEnabled
          ? [assessmentStagesInfo.ids.CPT]
          : []),
      ],
      frequency:
        (assessmentOptions.shouldBeTrackingPerMinutes &&
          assessmentOptions.perMinutesTracking) ||
        defaultAssessmentOptions.perMinutesTracking,
      intervalType: assessmentOptions.intervalType,
      creationDate: Date.now(),
      isAssessmentOpen: assessmentOptions.isAssessmentOpen && isSelectedStages,
      stagesType: assessmentOptions.stagesType || 'stages',
    };
    const minsUsage = await database
      .ref(`achievements/usages/${clientId}/totalUsage/minsUsage`)
      .once('value')
      .then(snapshot => snapshot.val() || 0);

    const numSessions = await database
      .ref(`achievements/usages/${clientId}/totalUsage/numOfSessions`)
      .once('value')
      .then(snapshot => snapshot.val() || 0);
    await database.ref(`assessments/deployedAssessments/${clientId}`).set({
      ...assessmentToBeDeployed,
      minsUsage,
      numSessions,
    });
  };

  useEffect(() => {
    if (isCreatingUser) {
      setIsCreatingUser(false);
      createClientWithSymptomTracking();
    }
  }, [createClientWithSymptomTracking, isCreatingUser]);

  const onNextBtnClick = () => {
    addClientEventLogger.log('on_next_btn_click', {
      currentStep: activeStep,
    });
    switch (activeStep) {
      case finalStep:
        if (finalStep === addClientSteps.createAccount) {
          clientDetailsFormRef.current.submitForm();
        }

        if (
          isEditingMode &&
          userInfo &&
          !shouldUseNewSymptomTracker &&
          didClinicActUponStep(addClientSteps.selectTags) &&
          selectedDisorders.length === 0
        ) {
          setErrorMsg(t('please-select-one-disorder-tag'));
        } else if (
          isEditingMode &&
          userInfo &&
          shouldUseNewSymptomTracker &&
          didClinicActUponStep(addClientSteps.selectTags) &&
          selectedTags.length === 0
        ) {
          setErrorMsg(t('please-select-one-tag'));
        } else if (
          didClinicActUponStep(addClientSteps.digitalCare) &&
          assessmentOptions.shouldBeTrackingPerMinutes &&
          (Number.isNaN(parseInt(assessmentOptions.perMinutesTracking, 10)) ||
            parseInt(assessmentOptions.perMinutesTracking, 10) <= 0)
        ) {
          setErrorMsg(t('per-minutes-tracking-should-be-a-number'));
        } else {
          setRenderedScreen(addClientScreens.loading);
          setErrorMsg(null);
          setIsCreatingUser(true);
        }
        break;
      case addClientSteps.createAccount:
        clientDetailsFormRef.current.submitForm();
        break;
      case addClientSteps.selectTags:
        if (selectedTags.length === 0) {
          setErrorMsg(t('please-select-one-tag'));
        } else {
          setErrorMsg(null);
          if (isEmpty(initialSelectedTags)) {
            const recommendedQuestionnaires = selectedTags.reduce(
              (acc, tagObject) => {
                (tagObject.recommendedQuestionnaires || []).forEach(
                  questionnaireId => {
                    const questionnaire = allQuestionnaires[questionnaireId];
                    if (
                      questionnaire &&
                      isQuestionnaireSuitableBasedOnUserAge(questionnaire)
                    ) {
                      acc.push({
                        id: questionnaireId,
                        ...questionnaire,
                      });
                    }
                  }
                );
                return acc;
              },
              []
            );

            const deafultQuestionnaires = autoDefaultQuestionnaires.map(id => {
              let questionnaire = allQuestionnaires[id];
              let idToStore = id;
              if (!isQuestionnaireSuitableBasedOnUserAge(questionnaire)) {
                questionnaire =
                  allQuestionnaires[questionnaire.alternativeQuestionnaire];
                idToStore = questionnaire.alternativeQuestionnaire;
              }
              return {
                id: idToStore,
                ...questionnaire,
              };
            });

            const allRecommendedQuestionnaires = defaultQuestionnaires
              .filter(id => allQuestionnaires[id])
              .map(id => ({ ...allQuestionnaires[id], id }))
              .concat(recommendedQuestionnaires)
              .concat(deafultQuestionnaires);

            const uniqueRecommendedQuestionnaires = uniqBy(
              allRecommendedQuestionnaires,
              'id'
            );
            setSelectedQuestionnaires(uniqueRecommendedQuestionnaires);
            if (!nfFlag) {
              const shouldTurnOnCPT = selectedTags.find(
                selectedTag => allTags[selectedTag.id].recommnedCPT
              );
              if (shouldTurnOnCPT) {
                setAssessmentOptions({
                  ...assessmentOptions,
                  isCPTEnabled: true,
                });
              }
            }
            if (
              isSelfRegistration &&
              doneAssessments &&
              Object.keys(doneAssessments).length
            ) {
              const isAddingNewQuestionnaires = recommendedQuestionnaires.filter(
                recommendedQuestionnaire =>
                  !defaultQuestionnaires.includes(recommendedQuestionnaire.id)
              );
              if (!isAddingNewQuestionnaires.length) {
                setActiveStep(addClientSteps.digitalCare);
                return;
              }
              setShouldShowQuestionnaireForSelfRegUser(true);
            }
          }
          setActiveStep(addClientSteps.selectQuestionnaires);
        }
        break;
      case addClientSteps.selectQuestionnaires:
        if (selectedQuestionnaires.length === 0) {
          setAssessmentOptions(prvAssessmentOptions => ({
            ...prvAssessmentOptions,
            isSymptomQuestionnaireEnabled: false,
          }));
        }
        if (
          isSelfRegistration &&
          doneAssessments &&
          Object.keys(doneAssessments).length
        ) {
          setActiveStep(addClientSteps.digitalCare);
          return;
        }
        setActiveStep(addClientSteps.deployAssessment);
        break;
      case addClientSteps.deployAssessment:
        if (shouldSkipDeployAssessmentStep()) {
          setActiveStep(addClientSteps.digitalCare);
        } else {
          setActiveStep(addClientSteps.trackingSchedule);
        }
        break;
      case addClientSteps.trackingSchedule:
        if (
          selectedTags.find(
            selectedTag => selectedTag.category === 'Parent of child with ADHD'
          )
        ) {
          setSelectedJourney(defaultBptJourneyId);
        }
        setActiveStep(addClientSteps.digitalCare);
        break;
      default:
        defensiveThrow({ error: new Error('Unknown step in AddClientModal') });
    }
  };
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const resetState = () => {
    setActiveStep(initialStep);
    setSelectedTags([]);
    setSelectedQuestionnaires([]);
    setAssessmentOptions(defaultAssessmentOptions);
    setSelectedDisorders(selectedOriginalDisorders);
    setSelectedAssessmentRegions(initialSelectedAssessmentRegions);
    setErrorMsg(null);
    setIsFormPrimaryBtnLoading(false);
    setFailureScreenMsg(null);
    setLoadingText(null);
    // setIsShowingGoToUserProfileBtn(false);
    setFormInitialValues(defaultClientDetailsValues);
    setRenderedScreen(addClientScreens.userCreation);
    setShowMoreDetails(false);
    setCoppaConsent({
      isConsentNeeded: false,
      isConsentGiven: false,
    });
  };

  const closeModalAndResetState = () => {
    // form.reset();

    resetState();
    closeModal();
  };

  const onBackBtnClick = () => {
    addClientEventLogger.log('on_back_btn_click', {
      currentStep: activeStep,
    });
    setErrorMsg(null);
    switch (activeStep) {
      case initialStep:
        closeModalAndResetState();
        break;
      default:
        setActiveStep(orderedSteps[orderedSteps.indexOf(activeStep) - 1]);
    }
  };

  // eslint-disable-next-line consistent-return
  const onSave = async values => {
    const values2 = values;
    values2.isClientRealEmail = JSON.parse(values.isClientRealEmail);
    clientDetailsFormValuesRef.current = values;
    setFormInitialValues(values);
    if (isEditingMode) {
      const customerNewAge = dayJS(new Date()).diff(
        dayJS(values.birthday),
        'year',
        true
      );
      const customerOldAge = getAge(userInfo.privateDetails.dateOfBirth);
      const complianceAge = (coppaConfig && coppaConfig.complianceAge) || 13;
      if (
        (customerOldAge >= complianceAge && customerNewAge < complianceAge) ||
        (customerOldAge < complianceAge && customerNewAge >= complianceAge)
      ) {
        if (customerNewAge < complianceAge) {
          setCoppaConsent({
            ...coppaConsent,
            isConsentNeeded: true,
          });
          setIsCoppaDialogOpen(true);
        } else {
          setCoppaConsent({
            ...coppaConsent,
            isConsentNeeded: false,
          });
          setDeleteCoppaConsent(true);
          if (shouldUseNewSymptomTracker) {
            setActiveStep(addClientSteps.selectTags);
          }
        }
      } else {
        if (shouldUseNewSymptomTracker) {
          setActiveStep(addClientSteps.selectTags);
        }
      }
    } else {
      setIsFormPrimaryBtnLoading(true);
      setIsFreeAccessError(false);
      const doesUserExist = await postToFunctionsWithDefaultData({
        path: 'manage_users-emailInfo',
        data: { email: values.email },
      });
      setIsFormPrimaryBtnLoading(false);

      if (doesUserExist.data.exists) {
        if (
          doesUserExist.data.isPatient &&
          doesUserExist.data.type === 'free_user'
        ) {
          setErrorMsg(
            'This client registered in-app. To link them to your clinic, share your code:'
          );
          setIsFreeAccessError(true);
        } else {
          setErrorMsg(t('another-user-exists-with-this-email'));
        }
      } else {
        const age = dayJS(new Date()).diff(
          dayJS(values.birthday),
          'year',
          true
        );
        const complianceAge = (coppaConfig && coppaConfig.complianceAge) || 13;
        if (age < complianceAge) {
          setCoppaConsent({
            ...coppaConsent,
            isConsentNeeded: true,
          });
          setIsCoppaDialogOpen(true);
        } else {
          setCoppaConsent({
            ...coppaConsent,
            isConsentNeeded: false,
          });
          setErrorMsg(null);
          setActiveStep(addClientSteps.selectTags);
        }
      }
    }
  };

  const onCoppaPopUpConfirmBtnClick = () => {
    const eventName = isEditingMode
      ? 'coppa_update_user_consent_dialog'
      : 'coppa_new_user_consent_dialog';

    const eventParams = isEditingMode
      ? { action: 'given', user_id: userInfo.clinicId }
      : { action: 'given' };
    addClientEventLogger.log(eventName, eventParams);
    setCoppaConsent({
      ...coppaConsent,
      isConsentGiven: true,
    });
    setIsCoppaDialogOpen(false);
    setErrorMsg(null);
    if (isEditingMode) {
      if (shouldUseNewSymptomTracker) {
        setActiveStep(addClientSteps.selectTags);
      }
    } else {
      setActiveStep(addClientSteps.selectTags);
    }
  };

  const onCoppaPopUpDisapproveBtnClick = () => {
    const eventName = isEditingMode
      ? 'coppa_update_user_consent_dialog'
      : 'coppa_new_user_consent_dialog';

    const eventParams = isEditingMode
      ? { action: 'rejected', user_id: userInfo.clinicId }
      : { action: 'rejected' };
    addClientEventLogger.log(eventName, eventParams);
    setIsCoppaDialogOpen(false);
    setErrorMsg(t('Clients under the age of 13 must have parental consent.'));
  };

  const onCoppaPopUpClose = () => {
    setIsCoppaDialogOpen(false);
  };

  const createProgramModalContainerDivIdRef = useRef();

  const setInitialFormValuesForEditing = useCallback(() => {
    const initialFormData = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      birthday: safeParseDate({
        str: get(userInfo, 'privateDetails.dateOfBirth'),
      }),
      gender: userInfo.privateDetails.gender
        ? userInfo.privateDetails.gender
        : 'Female',
      phone: userInfo.privateDetails.phoneNumber,
      notes: userInfo.privateDetails.assessment,
      email: userInfo.email,
      isClientRealEmail: userInfo.isRealEmail || false,
    };
    setFormInitialValues(initialFormData);
  }, [userInfo]);

  const setInitialStateForEditingCustomersUsingOldSymptomTracker = useCallback(() => {
    Promise.all([
      loadUserSelectedDisorders(userId),
      // eslint-disable-next-line no-shadow
    ]).then(([selectedDisorders]) => {
      setInitialFormValuesForEditing();

      if (selectedDisorders !== null) {
        const selectedBrainDisorders = Object.keys(selectedDisorders);
        setSelectedDisorders(selectedBrainDisorders);
        setSelectedOriginalDisorders(selectedBrainDisorders);
      }
    });

    const createProgramModalContainerDivId = `create-program-for-add-new-client-${uuidv4()}`;
    appendDivToBody({ id: createProgramModalContainerDivId });

    createProgramModalContainerDivIdRef.current = createProgramModalContainerDivId;

    return () => removeElement({ id: createProgramModalContainerDivId });
  }, [setInitialFormValuesForEditing, userId]);

  useEffect(() => {
    if (isEditingMode && initialStep === addClientSteps.createAccount) {
      if (shouldUseNewSymptomTracker) {
        setInitialFormValuesForEditing();
        return () => {};
      }
      return setInitialStateForEditingCustomersUsingOldSymptomTracker();
    }

    return () => {};
  }, [
    initialStep,
    isEditingMode,
    setInitialFormValuesForEditing,
    setInitialStateForEditingCustomersUsingOldSymptomTracker,
    shouldUseNewSymptomTracker,
    userId,
  ]);

  const onDisorderClick = disorder => {
    let newSelectedBrainDisorders;
    if (selectedDisorders.includes(disorder)) {
      newSelectedBrainDisorders = selectedDisorders.filter(
        selectedDisorder => selectedDisorder !== disorder
      );
    } else {
      newSelectedBrainDisorders = cloneDeep(selectedDisorders);
      newSelectedBrainDisorders.push(disorder);
    }

    setSelectedDisorders(newSelectedBrainDisorders);
  };

  const renderLoader = () => (
    <div className={styles.progress_container}>
      {loadingText && <p>{loadingText}</p>}
      <CircularProgress />
    </div>
  );

  const toggleShowMoreDetails = shouldShow => {
    setShowMoreDetails(shouldShow);
  };

  const setAssignedTo = obj => {
    setNewUserAssignedTo({ assignedTo: { ...obj } });
  };

  const renderForm = () => (
    <ClientDetailsForm
      selectedDisorders={selectedDisorders}
      onSave={onSave}
      onCancel={closeModalAndResetState}
      isEditingMode={isEditingMode}
      brainDisorders={brainDisorders}
      onDisorderClick={onDisorderClick}
      formInitialValues={formInitialValues}
      shouldShowDisorderTags={isEditingMode && !shouldUseNewSymptomTracker}
      ref={clientDetailsFormRef}
      showMoreDetails={showMoreDetails}
      toggleShowMoreDetails={toggleShowMoreDetails}
      isTeamMembersMenuOpen={isTeamMembersMenuOpen}
      setIsTeamMembersMenuOpen={setIsTeamMembersMenuOpen}
      setAssignedTo={setAssignedTo}
      teamMembersUserAssignedTo={newUserAssignedTo}
      teamMembersList={teamMembersList}
    />
  );

  const renderCoppaPopUp = () => (
    <CoppaPopUp
      isDialogOpen={isCoppaDialogOpen}
      dialogContent={coppaPopUPDefaults.dialogContent}
      primaryBtnContent={coppaPopUPDefaults.primaryBtnContent}
      secondaryBtnContent={coppaPopUPDefaults.secondaryBtnContent}
      onPrimaryBtnClick={onCoppaPopUpConfirmBtnClick}
      onSecondaryBtnClick={onCoppaPopUpDisapproveBtnClick}
      onClose={onCoppaPopUpClose}
    />
  );

  const renderSelectTags = () => {
    const allTagsWithIds = injectIDs(allTags);
    const sortedTags = Object.values(allTagsWithIds).sort((tag1, tag2) => {
      if (tag1.category === 'Other' || tag2.category === 'Healthy') {
        return 1;
      }
      if (tag1.category === 'Healthy' || tag2.category === 'Other') {
        return -1;
      }
      return tag1.category.localeCompare(tag2.category);
    });
    return (
      <SelectTags
        tags={sortedTags}
        onAddTag={onAddTag}
        onDeleteTag={onDeleteTag}
        selectedTags={selectedTags}
        shouldShowDescription={isEmpty(initialSelectedTags)}
        nfFlag={nfFlag}
        isSelfRegThatDoneAssessment={
          isSelfRegistration &&
          doneAssessments &&
          Object.keys(doneAssessments).length
        }
      />
    );
  };

  const renderSelectQuestionnaires = () => {
    const suitableQuestionnairesBasedOnAge = pickBy(
      allQuestionnaires,
      questionnaire => isQuestionnaireSuitableBasedOnUserAge(questionnaire)
    );
    const questionnaires = Object.values(
      injectIDs(suitableQuestionnairesBasedOnAge)
    )
      .sort((questionnaire1, questionnaire2) =>
        questionnaire1.name.localeCompare(questionnaire2.name)
      )
      .filter(
        questionnaire => questionnaire.id !== legacyQuestionsQuestionnaireId
      );
    return (
      <SelectQuestionnaires
        selectedQuestionnaires={selectedQuestionnaires}
        onAddQuestionnaire={onAddQuestionnaire}
        onDeleteQuestionnaire={onDeleteQuestionnaire}
        onDiscardAllQuestionnaires={onDiscardAllQuestionnaires}
        questionnaires={questionnaires}
        shouldShowDescription={isEmpty(initialSelectedTags)}
        isSelfRegThatDoneAssessment={
          isSelfRegistration &&
          doneAssessments &&
          Object.keys(doneAssessments).length
        }
      />
    );
  };

  const createCheckboxChangeCallbackForAssessmentOptions = name => event => {
    const { checked } = event.target;
    addClientEventLogger.log('on_assessment_option_checkbox_change', {
      name,
      checked,
    });
    if (name === 'isEEGEnabled' && checked) {
      setSelectedAssessmentRegions(
        Object.values(assessmentStagesInfo.eegIds).filter(id => id !== 'Back')
      );
    }
    if (
      name === 'shouldBeTrackingBeforeFirstSession' ||
      name === 'isAssessmentOpen'
    ) {
      return setAssessmentOptions(prvOptions => ({
        ...prvOptions,
        shouldBeTrackingBeforeFirstSession: checked,
        isAssessmentOpen: checked,
      }));
    }
    return setAssessmentOptions(prvOptions => ({
      ...prvOptions,
      [name]: checked,
    }));
  };

  const createDropDownChangeCallbackForAssessmentOptions = name => event => {
    const { value } = event.target;
    addClientEventLogger.log('on_assessment_option_checkbox_change', {
      name,
      value,
    });

    return setAssessmentOptions(prvOptions => ({
      ...prvOptions,
      [name]: value === 'true' || value === 'false' ? JSON.parse(value) : value,
    }));
  };

  const [displaySetupQuestionnaires, setDisplaySetupQuestionnaires] = useState(
    false
  );

  const onSetupQuestionnaires = () => {
    setDisplaySetupQuestionnaires(true);
  };

  const updateDeployedQuestionnaires = clientId => {
    const sortSelectedAssessmentRegions = regions => {
      const newRegions = [...regions];
      return newRegions.sort((region1, region2) => {
        const sortedRegions = Object.values(assessmentStagesInfo.eegIds);
        return sortedRegions.indexOf(region1) - sortedRegions.indexOf(region2);
      });
    };
    database
      .ref(`symptomTracker/deployedQuestionnaires/${clientId}`)
      .set(selectedQuestionnaires.map(questionnaire => questionnaire.id));
    if (selectedQuestionnaires.length === 0) {
      setAssessmentOptions(prvAssessmentOptions => ({
        ...prvAssessmentOptions,
        isSymptomQuestionnaireEnabled: false,
      }));
      if (
        assessmentOptions.isSymptomQuestionnaireEnabled &&
        !assessmentOptions.isEEGEnabled &&
        !assessmentOptions.isCPTEnabled
      ) {
        setAssessmentOptions(prvAssessmentOptions => ({
          ...prvAssessmentOptions,
          shouldBeTrackingBeforeFirstSession: false,
          shouldBeTrackingPerMinutes: false,
        }));

        const assessmentToBeDeployed = {
          recurring: false,
          beforeNextSession: false,
          afterTrainingDuration: assessmentOptions.perMinutesTracking,
          selectedStages: [],
          stagesType: assessmentOptions.stagesType || 'stages',
        };

        return database
          .ref(`assessments/deployedAssessments/${clientId}`)
          .update(assessmentToBeDeployed);
      }
      const selectedStagesWithoutQuestionnaires = [
        // eslint-disable-next-line  no-nested-ternary
        ...(assessmentOptions.isEEGEnabled
          ? assessmentOptions.stagesType === 'headsetStages'
            ? ['Headset']
            : sortSelectedAssessmentRegions(selectedAssessmentRegions)
          : []),
        ...(assessmentOptions.isCPTEnabled
          ? [assessmentStagesInfo.ids.CPT]
          : []),
      ];
      return database
        .ref(`assessments/deployedAssessments/${clientId}/selectedStages`)
        .set(selectedStagesWithoutQuestionnaires);
    }
    setAssessmentOptions(prvAssessmentOptions => ({
      ...prvAssessmentOptions,
      isSymptomQuestionnaireEnabled: true,
    }));
    return null;
  };

  const onJourneySelected = e => {
    addClientEventLogger.log('on_digital_care_dropdown_change', {
      name: 'digital_journey',
      value_after: e.target.value,
    });
    setSelectedJourney(e.target.value);
  };

  const onCategorySelected = e => {
    addClientEventLogger.log('on_digital_care_dropdown_change', {
      name: 'resources',
      value_after: e.target.value,
    });
    setSelectedCategories(e.target.value);
  };

  const onSharingOptionSelected = e => {
    addClientEventLogger.log('on_digital_care_dropdown_change', {
      name: 'sharing',
      value_after: e.target.value,
    });
    setSelectedEntrySharingOptions(e.target.value);
  };

  const renderDeployAssessment = shouldShowSetupBtn => {
    const isSymptomQuestionnaireCheckboxDisabledReason = (() => {
      if (selectedQuestionnaires.length === 0) {
        return t('symptom-tracking-requires-one-questionnaire');
      }
      if (isEditingMode && !isCustomerAppCompatiableWithSymptomQuestionnaires) {
        return `In order to be able to select questionnaires the user needs to update to a version above ${androidVersionCompatiableWithSymptomQuestionnaires} in Android or above ${iOSVersionCompatiableWithSymptomQuestionnaires} in iOS.`;
      }
      return null;
    })();
    return (
      <DeployAssessment
        isSymptomQuestionnaireCheckboxDisabledReason={
          isSymptomQuestionnaireCheckboxDisabledReason
        }
        isSymptomQuestionnaireChecked={
          assessmentOptions.isSymptomQuestionnaireEnabled
        }
        isEEGMeasuresChecked={assessmentOptions.isEEGEnabled}
        isCPTChecked={assessmentOptions.isCPTEnabled}
        selectedAssessmentOptions={selectedAssessmentRegions}
        onSelectedAssessmentOptionsChange={event => {
          if (event.target.value.length > 5) {
            return;
          }
          if (event.target.value.length > 0) {
            setSelectedAssessmentRegions(event.target.value);
            addClientEventLogger.log(
              'on_eeg_regions_change',
              event.target.value
            );
          }
        }}
        assessmentOptions={Object.entries(
          assessmentStagesInfo.eegNames
        ).map(([value, label]) => ({ value, label }))}
        onIsSymptomQuestionnaireCheckedChange={createCheckboxChangeCallbackForAssessmentOptions(
          'isSymptomQuestionnaireEnabled'
        )}
        onIsEEGMeasuresCheckedChange={createCheckboxChangeCallbackForAssessmentOptions(
          'isEEGEnabled'
        )}
        onIsCPTCheckedChange={createCheckboxChangeCallbackForAssessmentOptions(
          'isCPTEnabled'
        )}
        selectedQuestionnaires={selectedQuestionnaires}
        shouldShowSetupBtn={shouldShowSetupBtn}
        onSetupQuestionnaires={onSetupQuestionnaires}
        nfFlag={nfFlag}
        setAddClientReachedLimitModal={setAddClientReachedLimitModal}
        canAccessNf={canAccessNf}
        isHeadsetOnly={assessmentOptions.stagesType === 'headsetStages'}
        setIsHeadsetOnly={isChecked => {
          setAssessmentOptions({
            ...assessmentOptions,
            stagesType: isChecked ? 'headsetStages' : 'stages',
          });
        }}
        usedAssessmentBeforeJune24={usedAssessmentBeforeJune24}
      />
    );
  };

  const renderTrackingSchedule = () => {
    const onPerMinutesTrackingChange = event => {
      const newPerMinutesTracking = parseInt(event.target.value, 10);
      setAssessmentOptions(prvAssessmentOptions => ({
        ...prvAssessmentOptions,
        perMinutesTracking: newPerMinutesTracking,
      }));
    };
    const {
      shouldBeTrackingPerMinutes,
      perMinutesTracking,
      shouldBeTrackingBeforeFirstSession,
      intervalType,
    } = assessmentOptions;

    return (
      <TrackingSchedule
        shouldBeTrackingBeforeFirstSession={shouldBeTrackingBeforeFirstSession}
        onShouldBeTrackingBeforeFirstSessionChange={createCheckboxChangeCallbackForAssessmentOptions(
          'shouldBeTrackingBeforeFirstSession'
        )}
        onIsAssessmentOpenChange={createCheckboxChangeCallbackForAssessmentOptions(
          'isAssessmentOpen'
        )}
        shouldBeTrackingPerMinutes={shouldBeTrackingPerMinutes}
        onShouldBeTrackingPerMinutesChange={createDropDownChangeCallbackForAssessmentOptions(
          'shouldBeTrackingPerMinutes'
        )}
        perMinutesTracking={perMinutesTracking}
        onPerMinutesTrackingChange={onPerMinutesTrackingChange}
        intervalType={intervalType}
        onIntervalTypeChange={createDropDownChangeCallbackForAssessmentOptions(
          'intervalType'
        )}
        nfFlag={nfFlag}
      />
    );
  };

  const renderDigitalCare = () => {
    return (
      <DigitalCare
        journeys={journeys}
        onJourneySelected={onJourneySelected}
        selectedJourney={selectedJourney}
        isJourneyEnabled={isJourneyEnabled}
        setIsJourneyEnabled={setIsJourneyEnabled}
        categories={categories}
        onCategorySelected={onCategorySelected}
        selectedCategories={selectedCategories}
        isCategoriesEnabled={isCategoriesEnabled}
        setIsCategoriesEnabled={setIsCategoriesEnabled}
        entrySharingOptions={entrySharingOptions}
        selectedEntrySharingOptions={selectedEntrySharingOptions}
        onSharingOptionSelected={onSharingOptionSelected}
        isEntrySharingEnabled={isEntrySharingEnabled}
        setIsEntrySharingEnabled={setIsEntrySharingEnabled}
        deployedJourney={deployedJourney}
      />
    );
  };

  const renderClientSuccessfullyRegistered = () => {
    return (
      <React.Fragment>
        <div
          className={styles.modal_body}
          style={{ backgroundColor: '#f6f6f6', padding: 0 }}
        >
          {renderSuccessMessage({
            isEditingMode,
            userEmail,
            userPassword,
            selectedQuestionnaires,
            assessmentOptions,
            newRegisteredUserId,
            clinicId,
            closeModalAndResetState,
            renderDeployAssessment,
            setAssessmentOptions,
            updateDeployedAssessmentStages,
            renderSelectQuestionnaires,
            displaySetupQuestionnaires,
            setDisplaySetupQuestionnaires,
            updateDeployedQuestionnaires,
            isSelfRegistration,
            userId,
            nfFlag,
            journeyName: journeys[selectedJourney].title.en,
            isJourneyEnabled,
            isEntrySharingEnabled,
            selectedEntrySharingOptions,
            isCategoriesEnabled,
            shouldHideBack,
            newUserAssignedTo,
          })}
        </div>

        {/* <div
          className={styles.client_successfully_registered_modal_footer_wrapper}
        >
          {!isEditingMode && (
            <CreateProgramForUserWrapper
              customerId={newRegisteredUserId}
              onWrappedComponentClick={() => {
                addClientEventLogger.log('on_create_program_click');
                setIsShowingGoToUserProfileBtn(true);
              }}
              isWrappedComponentHidden={isShowingGoToUserProfileBtn}
            />
          )}

          {isShowingGoToUserProfileBtn && (
            <Button
              variant="text"
              color="default"
              onClick={() =>
                window.open(
                  `/userPage.html?customerId=${newRegisteredUserId}`,
                  '_self'
                )
              }
            >
              <span className={styles.btn}>{t('go-user-profile')}</span>
            </Button>
          )}
          <ModalFooter
            primaryBtnContent={t('done')}
            onPrimaryBtnClick={() => {
              addClientEventLogger.log('on_done_click');
              closeModalAndResetState();
            }}
          />
        </div> */}
      </React.Fragment>
    );
  };

  const renderFailedToCreateScreen = () => {
    return (
      <React.Fragment>
        <div className={styles.modal_body}>
          <div className={styles.failure_screen}>
            <p>Failed to create user</p>
            <p>{failureScreenMsg}</p>
          </div>

          <ModalFooter
            primaryBtnContent="try again"
            secondaryBtnContent="close"
            onPrimaryBtnClick={() => {
              addClientEventLogger.log('on_try_again');
              setActiveStep(initialStep);
              setRenderedScreen(addClientScreens.userCreation);
            }}
            onSecondaryBtnClick={() => {
              addClientEventLogger.log('on_close_after_error');
              closeModalAndResetState();
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const renderRegistrationCode = () => {
    const copyToClipboard = () => {
      // eslint-disable-next-line no-undef
      navigator.clipboard.writeText(registrationCode);
      setClipboardClicked(true);
      setTimeout(() => {
        setClipboardClicked(false);
      }, 2000);
      new MyEvent('self_reg_registration_code_copied').log();
    };
    return (
      <div className={styles.register_code} onClick={copyToClipboard}>
        {registrationCode}
        <span className={styles.copy_icon}>
          <CopyIcon fill="#ffffff" />
        </span>
        <span
          className={styles.clipboard_message}
          style={clipboardClicked ? { opacity: 1 } : { opacity: 0 }}
        >
          Copied to clipboard
        </span>
      </div>
    );
  };

  const footerLeftContent = (() => {
    if (errorMsg) {
      return (
        <div className={styles.error_msg}>
          <div>{errorMsg}</div>
          {isFreeAccessError ? renderRegistrationCode() : null}
        </div>
      );
    }

    switch (activeStep) {
      case addClientSteps.selectTags:
        return `Total number of tags selected: ${selectedTags.length}`;
      case addClientSteps.selectQuestionnaires:
        return `Number of questionnaires selected: ${
          selectedQuestionnaires.length
        } (${selectedQuestionnaires.reduce(
          (acc, questionnaire) => acc + questionnaire.questions.length,
          0
        )} total questions)`;
      default:
        return null;
    }
  })();

  const renderUserCreationScreen = () => {
    const primaryBtnTextContent = (() => {
      const {
        isSymptomQuestionnaireEnabled,
        isEEGEnabled,
        isCPTEnabled,
      } = assessmentOptions;
      if (
        activeStep === addClientSteps.deployAssessment &&
        !isSymptomQuestionnaireEnabled &&
        !isEEGEnabled &&
        !isCPTEnabled
      ) {
        // if (isEditingMode) {
        //   return t('save');
        // }
        return 'Next';
      }
      return activeStep === finalStep ||
        (isEditingMode && !shouldUseNewSymptomTracker)
        ? t('save')
        : t('next');
    })();

    const primaryBtnContent =
      activeStep === addClientSteps.createAccount && isFormPrimaryBtnLoading ? (
        <>
          <div className={styles.btn_loader_container}>
            <CircularProgress size={24} color="inherit" />
          </div>
        </>
      ) : (
        primaryBtnTextContent
      );

    const formattedAddClientSteps = getFormattedAddClientSteps();
    return (
      <>
        {orderedSteps.length > 1 && (
          <div className={styles.stepper_container}>
            <Stepper alternativeLabel>
              {orderedSteps.map(label => {
                return (
                  <Step
                    key={label}
                    className={styles.step}
                    active={activeStep === label}
                    completed={
                      orderedSteps.indexOf(activeStep) >
                      orderedSteps.indexOf(label)
                    }
                  >
                    <StepLabel>
                      <span className={styles.step_label}>
                        {isMatchingXSScreen
                          ? ''
                          : formattedAddClientSteps[label]}
                      </span>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        )}

        <>
          <div className={styles.content}>
            {activeStep === addClientSteps.createAccount && renderForm()}
            {activeStep === addClientSteps.createAccount &&
              isCoppaDialogOpen &&
              renderCoppaPopUp()}
            {activeStep === addClientSteps.selectTags && renderSelectTags()}
            {activeStep === addClientSteps.selectQuestionnaires &&
              renderSelectQuestionnaires()}
            {activeStep === addClientSteps.deployAssessment &&
              renderDeployAssessment()}
            {activeStep === addClientSteps.trackingSchedule &&
              renderTrackingSchedule()}
            {activeStep === addClientSteps.digitalCare && renderDigitalCare()}
          </div>
          <ModalFooter
            primaryBtnContent={primaryBtnContent}
            secondaryBtnContent={
              // eslint-disable-next-line no-nested-ternary
              shouldHideBack
                ? null
                : activeStep === initialStep
                ? t('cancel')
                : t('back')
            }
            onPrimaryBtnClick={onNextBtnClick}
            onSecondaryBtnClick={onBackBtnClick}
            leftContent={footerLeftContent}
          />
        </>
      </>
    );
  };

  return (
    <Modal open={!!isModalOpen}>
      <div
        id="add-client-modal"
        className={classNames(styles.paper, styles.modal, {
          [styles.small_paper]:
            renderedScreen ===
              addClientScreens.clientWasSuccessfullyRegistered && isEditingMode,
          [styles.large_paper]:
            activeStep === addClientSteps.digitalCare &&
            renderedScreen === addClientScreens.userCreation,
        })}
      >
        {renderedScreen === addClientScreens.userCreation &&
          renderUserCreationScreen()}
        {renderedScreen === addClientScreens.loading && renderLoader()}
        {renderedScreen === addClientScreens.clientWasSuccessfullyRegistered &&
          renderClientSuccessfullyRegistered()}
        {renderedScreen === addClientScreens.failedToCreateClient &&
          renderFailedToCreateScreen()}

        <NeuroPromoDialog
          addClientReachedLimitModal={addClientReachedLimitModal}
          setAddClientReachedLimitModal={setAddClientReachedLimitModal}
          onViewPricingPlansClicked={onViewPricingPlansClicked}
          canAccessNf={canAccessNf}
        />
      </div>
    </Modal>
  );
};

AddClientModalBase.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  isModalOpen: PropTypes.bool.isRequired,
  brainDisorders: PropTypes.array,
  clinicId: PropTypes.string,
  isEditingMode: PropTypes.bool,
  shouldUseNewSymptomTracker: PropTypes.bool,
  userId: PropTypes.string,
  allQuestionnaires: PropTypes.object.isRequired,
  allTags: PropTypes.object.isRequired,
  initialSelectedTags: PropTypes.array,
  initialSelectedQuestionnaires: PropTypes.array,
  initialAssessmentOptions: PropTypes.object,
  userInfo: PropTypes.object,
  initialStep: PropTypes.string,
  finalStep: PropTypes.string,
  renderSuccessMessage: PropTypes.func,
  initialSelectedAssessmentRegions: PropTypes.array,
  isCustomerAppCompatiableWithSymptomQuestionnaires: PropTypes.bool,
  assessmentStagesInfo: PropTypes.object.isRequired,
  isSelfRegistration: PropTypes.bool,
  setIsRegistrationDialogOpen: PropTypes.func,
  nfFlag: PropTypes.bool,
  shouldHideBack: PropTypes.bool,
  assessmentAutoDeployableConfigs: PropTypes.object,
  questionnairesAutoDeployableConfigs: PropTypes.object,
  doneAssessments: PropTypes.object,
};

AddClientModalBase.defaultProps = {
  brainDisorders: [],
  initialSelectedTags: [],
  initialSelectedQuestionnaires: [],
  initialAssessmentOptions: defaultAssessmentOptions,
  isEditingMode: false,
  initialStep: addClientSteps.createAccount,
  finalStep: addClientSteps.digitalCare,
  isCustomerAppCompatiableWithSymptomQuestionnaires: true,
  /* eslint-disable react/prop-types */
  renderSuccessMessage: ({
    isEditingMode,
    userEmail,
    userPassword,
    assessmentOptions,
    newRegisteredUserId,
    clinicId,
    closeModalAndResetState,
    renderDeployAssessment,
    setAssessmentOptions,
    updateDeployedAssessmentStages,
    renderSelectQuestionnaires,
    displaySetupQuestionnaires,
    setDisplaySetupQuestionnaires,
    updateDeployedQuestionnaires,
    isSelfRegistration,
    userId,
    nfFlag,
    journeyName,
    isJourneyEnabled,
    isCategoriesEnabled,
    isEntrySharingEnabled,
    selectedEntrySharingOptions,
    shouldHideBack,
    newUserAssignedTo,
  }) => (
    <ClientSuccessfullyRegistered
      userPassword={userPassword}
      userEmail={userEmail}
      header={
        isEditingMode ? 'Client information Updated' : 'Client Registered'
      }
      // isDeployedAssessmentMessageShown={
      //   assessmentOptions.shouldBeTrackingBeforeFirstSession ||
      //   assessmentOptions.shouldBeTrackingPerMinutes
      // }
      isEEGEnabled={assessmentOptions.isEEGEnabled}
      isSymptomQuestionnaireEnabled={
        assessmentOptions.isSymptomQuestionnaireEnabled
      }
      isCPTEnabled={assessmentOptions.isCPTEnabled}
      newRegisteredUserId={newRegisteredUserId}
      clinicId={clinicId}
      closeModalAndResetState={closeModalAndResetState}
      renderDeployAssessment={renderDeployAssessment}
      setAssessmentOptions={setAssessmentOptions}
      assessmentOptions={assessmentOptions}
      updateDeployedAssessmentStages={updateDeployedAssessmentStages}
      renderSelectQuestionnaires={renderSelectQuestionnaires}
      displaySetupQuestionnaires={displaySetupQuestionnaires}
      setDisplaySetupQuestionnaires={setDisplaySetupQuestionnaires}
      updateDeployedQuestionnaires={updateDeployedQuestionnaires}
      isSelfRegistration={isSelfRegistration || shouldHideBack}
      userId={userId}
      nfFlag={nfFlag}
      journeyName={journeyName}
      isJourneyEnabled={isJourneyEnabled}
      isCategoriesEnabled={isCategoriesEnabled}
      isEntrySharingEnabled={isEntrySharingEnabled}
      selectedEntrySharingOptions={selectedEntrySharingOptions}
      teamMembersUserAssignedTo={newUserAssignedTo}
    />
  ),
  /* eslint-enable react/prop-types */
};

export const AddClientModal = withEntryComponent(
  withTranslation(AddClientModalBase)
);
AddClientModal.displayName = 'AddClientModal';

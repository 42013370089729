import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import styles from './Stepper.scss';
import lastIdleImage from '../../../../assets/last_inactive.png';
import lastActiveImage from '../../../../assets/last_active.png';
import firstIdleImage from '../../../../assets/first_inactive.png';
import firstActiveImage from '../../../../assets/first_active.png';

const StepperBase = ({ activeStep, steps, newFlow }) => {
  const activeStepIndex = steps.indexOf(activeStep);
  return (
    <div className={styles.steps}>
      {steps.map((step, index) => {
        const image = (() => {
          if (step === activeStep) {
            return index === 0 ? firstActiveImage : lastActiveImage;
          }
          return index === 0 ? firstIdleImage : lastIdleImage;
        })();

        return (
          <div
            key={step}
            className={classNames(styles.step_root, {
              [styles.active]: step === activeStep,
            })}
          >
            <div
              className={classNames(styles.step_container, {
                [styles.active]: step === activeStep,
              })}
            >
              <img src={image} />
              <div
                className={classNames(styles.step, {
                  [styles.active]: step === activeStep,
                })}
                key={step}
                style={newFlow ? { marginTop: 3 } : null}
              >
                {index < activeStepIndex && (
                  <CheckIcon
                    style={{
                      color: '#4be0d0',

                      marginRight: 5,
                      fontSize: 22,
                    }}
                  />
                )}
                <span>{step}</span>
              </div>
            </div>
            <p className={styles.step_text_mobile}>
              Step {activeStepIndex + 1} out of {steps.length}
            </p>
          </div>
        );
      })}
    </div>
  );
};

StepperBase.propTypes = exact({
  activeStep: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  newFlow: PropTypes.bool,
});

export const Stepper = React.memo(StepperBase);
Stepper.displayName = 'Stepper';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import exact from 'prop-types-exact';
import mapValues from 'lodash/mapValues';
import transform from 'lodash/transform';
import invert from 'lodash/invert';
import stateAbbreviations from 'states-abbreviations';
import { useForm } from 'react-hook-form';
import { Checkbox, Dialog, Popover, TextField } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import logoImage from '../../../assets/logo2024.png';
import searchImage from '../../../assets/search_icon.png';
import MsgSentImage from '../../../assets/message_sent.png';
import SadCloudImage from '../../../assets/sad-cloud.png';
import brainLoadingImage from '../../../assets/registration-flow-loading-brain.gif';
import styles from './ProvidersSearchPage.scss';
import { getCurrentURLParams } from '../../utils/utils';
import { ListingItem } from '../../Components/ListingItem/ListingItem';

const stateAbbreviationsToState = transform(
  stateAbbreviations,
  (acc, value, key) => {
    /* trim because the library that i used had a bug some states were written like this "california " */
    acc[key.toLowerCase().trim()] = value.toLowerCase().trim();
  }
);
const stateToStateAbbreviations = invert(stateAbbreviationsToState);

const searchQueryFromUrl = getCurrentURLParams().searchQuery;

const postData = async (url = '', data = {}) => {
  const response = await window.fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const logEvent = async (eventName, params = {}) =>
  postData(`${environmentConfig.functions}eventLog`, {
    eventName,
    eventDetails: params,
  });

const ProvidersSearchPageBase = () => {
  const [providerListings] = useFirebaseDB({
    path: 'providerListings',
    onceListener: true,
  });

  const [defaultCategories] = useFirebaseDB({
    path: '/clinicConfig/providerListingConfigs/defaultCategories',
    onceListener: true,
  });

  const specialtyFilterSelectRef = useRef(null);
  const selectedFilterSpecialtiesStateRef = useRef(null);
  const searchResultsWithoutfilterStateRef = useRef(null);

  const [listingsWithImages, setListingsWithImages] = useState(null);
  const [listingToSendMailTo, setListingToSendMailTo] = useState({});
  const [searchQuery, setSearchQuery] = useState(searchQueryFromUrl || '');
  const [searchedForQuery, setSearchedForQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isSendMsgFormOpen, setIsSendMsgFormOpen] = useState(false);
  const [sendMsgFormRenderedStep, setSendMsgFormRenderedStep] = useState(
    'form'
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filterSpecialties, setFilterSpecialties] = useState([]);
  const [selectedFilterSpecialties, setSelectedFilterSpecialties] = useState(
    []
  );
  const [specialtiesSearchQuery, setSpecialtiesSearchQuery] = useState('');
  const [specialtiesSearchResult, setSpecialtiesSearchResult] = useState([]);
  const [searchResultsWithoutfilter, setSearchResultWithoutFilter] = useState(
    []
  );
  const [countriesList, setCountriesList] = useState([]);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  selectedFilterSpecialtiesStateRef.current = selectedFilterSpecialties;
  searchResultsWithoutfilterStateRef.current = searchResultsWithoutfilter;

  const filterSearchResultsBySpecialties = (
    updatedSpecialties,
    searchResultToFilter
  ) => {
    if (updatedSpecialties.length === 0) {
      setSearchResults(searchResultsWithoutfilter);
      return;
    }
    const filteredSearchResults = new Set();

    updatedSpecialties.forEach(specialty => {
      defaultCategories[specialty].forEach(elementInCategory => {
        const lowercaseElement = elementInCategory.toLowerCase();
        const temp = [...searchResultToFilter].filter(result => {
          return (
            result.topSpecialties.some(item =>
              item.toLowerCase().includes(lowercaseElement)
            ) ||
            result.otherSpecialties.some(item =>
              item.toLowerCase().includes(lowercaseElement)
            )
          );
        });
        temp.forEach(result => filteredSearchResults.add(result));
      });
    });
    const uniqueFilteredResults = Array.from(filteredSearchResults);

    setSearchResults(uniqueFilteredResults);
  };

  const handleSettingSearchResults = unFilteredSearchResults => {
    if (selectedFilterSpecialtiesStateRef.current.length === 0) {
      setSearchResultWithoutFilter(unFilteredSearchResults);
      return setSearchResults(unFilteredSearchResults);
    }
    filterSearchResultsBySpecialties(
      selectedFilterSpecialtiesStateRef.current,
      unFilteredSearchResults
    );
    return setSearchResultWithoutFilter(unFilteredSearchResults);
  };

  const searchForQuery = useCallback(
    query => {
      setSearchedForQuery(query);
      if (query === '') {
        setSearchResultWithoutFilter([]);
        return setSearchResults([]);
      }
      const lowerCaseQuery = query.toLowerCase();
      // if (
      //   lowerCaseQuery === 'usa' ||
      //   lowerCaseQuery === 'united states of america'
      // ) {
      //   return (
      //     listing.country.toLowerCase().includes(lowerCaseQuery) ||
      //     listing.country.toLowerCase().includes('united states')
      //   );
      // }

      const exactMatches = [];
      const abbreviationMatches = [];
      const prefixMatches = [];
      // const includesMatches = [];

      listingsWithImages.forEach(listing => {
        const { city, country, state } = listing;
        const lowerCaseCity = city.toLowerCase();
        const lowerCaseCountry = country.toLowerCase();
        const lowerCaseState = state.toLowerCase();

        // Exact Match
        if (
          lowerCaseCity === lowerCaseQuery ||
          lowerCaseCountry === lowerCaseQuery ||
          lowerCaseState === lowerCaseQuery
        ) {
          exactMatches.push(listing);
        } else if (
          // Abbreviation Match
          (stateToStateAbbreviations[lowerCaseQuery] &&
            lowerCaseState ===
              stateToStateAbbreviations[lowerCaseQuery].toLowerCase()) ||
          (stateAbbreviationsToState[lowerCaseQuery] &&
            lowerCaseState ===
              stateAbbreviationsToState[lowerCaseQuery].toLowerCase()) ||
          ((lowerCaseQuery === 'usa' ||
            lowerCaseQuery === 'united states of america') &&
            lowerCaseCountry.includes('united states'))
        ) {
          abbreviationMatches.push(listing);
        } else if (
          // Prefix Match
          lowerCaseCity.startsWith(lowerCaseQuery) ||
          lowerCaseCountry.startsWith(lowerCaseQuery) ||
          lowerCaseState.startsWith(lowerCaseQuery)
        ) {
          prefixMatches.push(listing);
        }
        // Includes/Substring Match
        // else if (
        //   lowerCaseCity.includes(lowerCaseQuery) ||
        //   lowerCaseCountry.includes(lowerCaseQuery) ||
        //   lowerCaseState.includes(lowerCaseQuery)
        // ) {
        //   includesMatches.push(listing);
        // }
      });

      // Merge all match arrays while preserving the desired order
      const searchResultsResults = [
        ...exactMatches,
        ...abbreviationMatches,
        ...prefixMatches,
        // ...includesMatches,
      ];

      return handleSettingSearchResults(searchResultsResults);
    },
    [listingsWithImages]
  );

  const onSearch = useCallback(
    (query = searchQuery) => {
      searchForQuery(query.trim());

      window.history.replaceState(null, null, `?searchQuery=${query.trim()}`);
      const selectedSpecialty =
        selectedFilterSpecialties.length === 0
          ? 'All'
          : selectedFilterSpecialties;

      logEvent('providers_search_page_on_search', {
        query,
        selectedSpecialty,
      });
    },
    [searchQuery, searchForQuery, selectedFilterSpecialties]
  );

  useEffect(() => {
    (async () => {
      if (providerListings !== null) {
        const imagesLoadedPromises = [];

        const providerListingsWithImages = mapValues(
          providerListings,
          (listingsData, clinicId) =>
            Object.entries(listingsData.listings || [])
              .filter(([, listing]) => listing.isShowing)
              .slice(0, listingsData.maxListings)
              .map(([listingId, listing]) => {
                const storage = window.firebase.storage();
                const imagePath = `providerListings/${clinicId}/${listingId}`;
                const imageRef = storage.ref(imagePath);
                const downloadImageURLPromise = imageRef.getDownloadURL();
                imagesLoadedPromises.push(downloadImageURLPromise);
                downloadImageURLPromise.then(imageURL => {
                  // eslint-disable-next-line no-param-reassign
                  listing.imageSrc = imageURL;
                });

                // eslint-disable-next-line no-param-reassign
                listing.listingId = listingId;
                // eslint-disable-next-line no-param-reassign
                listing.clinicId = clinicId;
                return listing;
              })
        );

        await Promise.all(imagesLoadedPromises);
        const newListingsWithImages = Object.values(
          providerListingsWithImages
        ).reduce((acc, cur) => acc.concat(cur), []);
        setListingsWithImages(newListingsWithImages);

        const countries = newListingsWithImages.reduce((acc, listing) => {
          const { country, state, city } = listing;

          // Find existing country object or create a new one
          let countryObj = acc.find(item => item.countryName === country);
          if (!countryObj) {
            countryObj = { countryName: country, items: [] };
            acc.push(countryObj);
          }

          // Check if the city exists in the country's items
          const cityExists = countryObj.items.some(item => item.city === city);

          // If the city doesn't exist, add it
          if (!cityExists) {
            countryObj.items.push({ city, state });
          }

          return acc;
        }, []);
        setCountriesList(countries);
      }
    })();
  }, [providerListings]);

  useEffect(() => {
    if (defaultCategories !== null) {
      const specialties = Object.keys(defaultCategories);
      setFilterSpecialties(specialties);
      setSpecialtiesSearchResult(specialties);
    }
  }, [defaultCategories]);

  useEffect(() => {
    if (listingsWithImages !== null) {
      if (searchQueryFromUrl) {
        searchForQuery(searchQueryFromUrl);
      }
      document.getElementById('loader-container').style.display = 'none';
    }
  }, [searchForQuery, listingsWithImages]);

  const generateAutoCompleteSuggetions = query => {
    const lowerCaseQuery = query.toLowerCase();

    const suggestionSet = new Set();

    countriesList.forEach(country => {
      const { countryName, items } = country;
      if (
        countryName
          .toLowerCase()
          .trim()
          .startsWith(lowerCaseQuery) &&
        countryName.toLowerCase().length > 3
      ) {
        suggestionSet.add(countryName.toLowerCase().trim());
      }
      items.forEach(item => {
        const { city, state } = item;
        if (
          city
            .toLowerCase()
            .trim()
            .startsWith(lowerCaseQuery) &&
          city.toLowerCase().length > 3
        ) {
          suggestionSet.add(city.toLowerCase().trim());
        }
        if (
          state
            .toLowerCase()
            .trim()
            .startsWith(lowerCaseQuery) &&
          state.toLowerCase().length > 3
        ) {
          suggestionSet.add(state.toLowerCase().trim());
        }
      });
    });
    const suggestions = [...suggestionSet];
    setSuggestionsList(suggestions);
  };

  function capitalizeWords(str) {
    const capitalize = word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };
    if (typeof str !== 'string' || str.length === 0) {
      return '';
    }
    return str
      .split(' ')
      .map(word => capitalize(word))
      .join(' ');
  }

  const handleAutoCompleteNavigation = e => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex(prevIndex =>
        prevIndex < suggestionsList.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter') {
      setIsAutoCompleteOpen(false);
      setSuggestionsList([]);
      setSelectedIndex(-1);
      if (selectedIndex !== -1) {
        // If there's a suggestion highlighted
        const newQuery = capitalizeWords(suggestionsList[selectedIndex]);
        setSearchQuery(newQuery);
        onSearch(newQuery);
      } else {
        // If no suggestion is highlighted, call the search function
        onSearch(searchQuery);
      }
    }
  };

  const onSpecialtiesChangeQuery = event => {
    const query = event.target.value;
    let filteredspecialties = [];
    if (query === '') {
      filteredspecialties = filterSpecialties;
    } else {
      const tempArr = filterSpecialties.filter(specialty => {
        return specialty.toLowerCase().includes(query);
      });
      filteredspecialties = tempArr;
    }
    setSpecialtiesSearchResult(filteredspecialties);
    setSpecialtiesSearchQuery(query);
  };

  const onfilterCheckBoxCheced = item => {
    const updatedSpecialties = selectedFilterSpecialties.includes(item)
      ? [...selectedFilterSpecialties].filter(spec => spec !== item)
      : [...selectedFilterSpecialties, item];
    setSelectedFilterSpecialties(updatedSpecialties);
    selectedFilterSpecialtiesStateRef.current = updatedSpecialties;
    filterSearchResultsBySpecialties(
      updatedSpecialties,
      searchResultsWithoutfilter
    );
  };

  const onChanceSearchQuery = event => {
    const query = event.target.value;

    setSearchQuery(query);
    setIsAutoCompleteOpen(true);
    generateAutoCompleteSuggetions(query);
  };

  const { register, handleSubmit, reset, errors } = useForm();

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      logEvent('providers_search_page_on_message_modal', {
        result: 'input_error',
      });
    }
  }, [errors]);

  const closeSubmitSendMsgForm = () => {
    setIsSendMsgFormOpen(false);
    setListingToSendMailTo({});
    setSendMsgFormRenderedStep('form');
    reset();
  };

  const onSubmitSendMsgForm = async data => {
    const { clinicId, listingId } = listingToSendMailTo;
    setSendMsgFormRenderedStep('loading');
    try {
      const response = await postData(
        `${environmentConfig.functions}providerInquiry`,
        {
          clinicId,
          listingId,
          userMessage: data.msg,
          userName: data.name,
          userEmail: data.email,
          userPhone: data.phone,
        }
      );
      if (response && response.result === true) {
        setSendMsgFormRenderedStep('msgWasSentSuccessfully');
        logEvent('providers_search_page_on_message_modal', {
          result: 'successful',
        });
      } else {
        setSendMsgFormRenderedStep('msgWasNotSentSuccessfully');
        logEvent('providers_search_page_on_message_modal', {
          result: 'other_error',
        });
      }
    } catch (error) {
      setSendMsgFormRenderedStep('msgWasnNotSentSuccessfully');
      logEvent('providers_search_page_on_message_modal', {
        result: 'other_error',
      });
    }
  };

  return (
    <div
      className={styles.root}
      style={{ display: listingsWithImages !== null ? 'block' : 'none' }}
    >
      <div className={styles.logo_container}>
        <a
          href="https://www.myndlift.com "
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logoImage} />
        </a>
        <div
          id="back_button"
          className={styles.back_btn}
          onClick={() => {
            window.open(document.referrer, '_self');
          }}
        >
          Back
        </div>
      </div>

      <div className={styles.content}>
        <h1>Nearby Myndlift Providers</h1>

        <div className={styles.search_container}>
          <div className={styles.search_input_container}>
            <div
              onBlur={() => {
                setIsAutoCompleteOpen(false);
                setSuggestionsList([]);
                setSelectedIndex(-1);
              }}
            >
              <input
                type="text"
                value={searchQuery}
                onChange={onChanceSearchQuery}
                onKeyDown={event => {
                  handleAutoCompleteNavigation(event);
                }}
                placeholder="Search by city, state, or country"
              />
              <img src={searchImage} />
              <div
                style={{
                  visibility: isAutoCompleteOpen ? 'visible' : 'hidden',
                }}
              >
                {searchQuery !== '' &&
                  suggestionsList.length !== 0 &&
                  suggestionsList.map((suggestion, index) => (
                    <p
                      onMouseDown={event => {
                        event.preventDefault();
                        const newQuery = capitalizeWords(suggestion);
                        setSearchQuery(newQuery);
                        setIsAutoCompleteOpen(false);
                        setSuggestionsList([]);
                        setSelectedIndex(-1);
                        onSearch(newQuery);
                      }}
                      key={suggestion}
                      style={{
                        backgroundColor:
                          index === selectedIndex ? '#f1f1f1' : '#ffffff',
                      }}
                      onMouseOver={() => setSelectedIndex(index)}
                      onMouseOut={() => setSelectedIndex(-1)}
                      onFocus={() => setSelectedIndex(index)}
                      onBlur={() => setSelectedIndex(-1)}
                    >
                      {suggestion}
                    </p>
                  ))}
              </div>
            </div>
            <div>
              <div
                onClick={event => {
                  setIsPopoverOpen(!isPopoverOpen);
                  event.stopPropagation();
                }}
                ref={specialtyFilterSelectRef}
              >
                <input
                  placeholder={`Specialty: ${
                    selectedFilterSpecialties.length === 0
                      ? 'All'
                      : `${selectedFilterSpecialties.length} Selected`
                  }`}
                />
                {/* this overlay is to prevent typing in the input above it and
                 capture click events to open specialties popover */}
                <div id="overlay_for_input_and_popover" />
                {isPopoverOpen ? (
                  <ExpandLessIcon style={{ color: '#757575' }} />
                ) : (
                  <ExpandMoreIcon style={{ color: '#757575' }} />
                )}
              </div>
              <button type="button" onClick={() => onSearch(searchQuery)}>
                search
              </button>
            </div>
            <Popover
              id="specialty_filter_popOver"
              open={isPopoverOpen}
              anchorEl={specialtyFilterSelectRef.current}
              onClose={() => setIsPopoverOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: styles.specialty_filter_popover }}
            >
              <input
                type="text"
                value={specialtiesSearchQuery}
                onChange={onSpecialtiesChangeQuery}
                placeholder="Search"
              />
              <img src={searchImage} />
              <p
                style={{
                  visibility:
                    selectedFilterSpecialties.length === 0
                      ? 'hidden'
                      : 'visible',
                }}
                onClick={() => {
                  const tempArr = [];
                  setSelectedFilterSpecialties(tempArr);
                  selectedFilterSpecialtiesStateRef.current = [];
                  setSearchResults(searchResultsWithoutfilter);
                }}
              >
                <u>Remove All</u>
              </p>
              <div
                className={styles.popover_checkboxs_container}
                title="scroll for more"
              >
                {(defaultCategories !== null
                  ? specialtiesSearchResult
                  : []
                ).map(item => {
                  let defaltColor = '#757575';
                  if (selectedFilterSpecialties.length > 0) {
                    if (selectedFilterSpecialties.includes(item)) {
                      defaltColor = '#4BE0D0';
                    }
                  }
                  return (
                    <div key={item}>
                      <Checkbox
                        style={{
                          color: defaltColor,
                        }}
                        className={styles.checkboxMui}
                        checked={
                          selectedFilterSpecialties.length > 0 &&
                          selectedFilterSpecialties.includes(item)
                        }
                        onChange={() => onfilterCheckBoxCheced(item)}
                      />
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
            </Popover>
          </div>
        </div>
        <div className={styles.search_results_container}>
          <p
            style={{
              visibility: searchedForQuery !== null ? 'visible' : 'hidden',
            }}
          >
            {searchResults.length > 0 ? (
              <React.Fragment>
                Showing {searchResults.length}{' '}
                {searchResults.length === 1 ? 'result' : 'results'} for{' '}
                <span style={{ fontWeight: 900 }}>“{searchedForQuery}”</span>{' '}
              </React.Fragment>
            ) : (
              <React.Fragment>
                No results for{' '}
                <span style={{ fontWeight: 900 }}>“{searchedForQuery}”</span>.
                <br />
                {selectedFilterSpecialties.length > 0 && (
                  <span>
                    Please try another search or remove the specialty filter.
                  </span>
                )}
              </React.Fragment>
            )}
          </p>

          <div>
            {searchResults.map(listing => {
              return (
                <ListingItem
                  key={listing.listingId}
                  listing={listing}
                  onSendMsg={event => {
                    event.stopPropagation();
                    setListingToSendMailTo(listing);
                    setIsSendMsgFormOpen(true);
                    logEvent('providers_search_page_on_card_button_click', {
                      action: 'send_message',
                    });
                  }}
                  rootClassName={styles.listing_item}
                  customChipClassName={styles.custom_chip}
                  styles={styles}
                  logEvent={logEvent}
                />
              );
            })}
          </div>
        </div>

        {searchedForQuery !== null && searchResults.length === 0 && (
          <div className={styles.empty_search_results_alternative}>
            <p>Don't see a provider near you? Not a problem.</p>
            <p>
              That’s why we offer professional at-home training. Your kit will
              arrive in the mail and you will be matched with a professional
              Neuro Coach to start training using the Myndlift app.
            </p>
            <button
              type="button"
              onClick={() => window.open('https://signup.myndlift.com/')}
            >
              <span>Check Out The Remote Program</span>
            </button>
          </div>
        )}
      </div>

      <Dialog
        open={isSendMsgFormOpen}
        maxWidth="lg"
        onClose={() => {
          if (sendMsgFormRenderedStep !== 'loading') {
            closeSubmitSendMsgForm();
          }
        }}
        classes={{ paper: styles.send_msg_dialog_paper_root }}
      >
        <form onSubmit={handleSubmit(onSubmitSendMsgForm)}>
          {sendMsgFormRenderedStep === 'form' && (
            <div className={styles.form_root}>
              <div className={styles.header}>
                <img src={listingToSendMailTo.imageSrc} />
                <p>
                  Send a message to {listingToSendMailTo.firstName}{' '}
                  {listingToSendMailTo.lastName}
                </p>
              </div>

              <div className={styles.form_content}>
                <div className={styles.inputs_container}>
                  <TextField
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    inputProps={{
                      name: 'name',
                      placeholder: 'Your name',
                    }}
                    inputRef={register({
                      required: 'Your name is required!',
                    })}
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                  />

                  <TextField
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    inputProps={{
                      name: 'email',
                      placeholder: 'Your email',
                    }}
                    inputRef={register({
                      required: 'Your email is required!',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                  />

                  <TextField
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    inputProps={{
                      name: 'phone',
                      placeholder: 'Your phone',
                    }}
                    inputRef={register({
                      required: 'Your phone is required!',
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: 'Invalid phone number',
                      },
                    })}
                    error={!!errors.phone}
                    helperText={errors.phone && errors.phone.message}
                  />

                  <TextField
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    inputProps={{
                      name: 'msg',
                      placeholder: 'Message (up to 200 words)',
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      classes: {
                        multiline: styles.multiline_textarea,
                      },
                    }}
                    inputRef={register({
                      required: 'Message is required!',
                      validate: text =>
                        text.length > 200
                          ? 'Message should be less than 200 characters'
                          : true,
                    })}
                    error={!!errors.msg}
                    helperText={errors.msg && errors.msg.message}
                    multiline
                    rows={5}
                  />

                  <button type="submit" style={{ textTransform: 'capitalize' }}>
                    <span>Send Message</span>
                  </button>
                </div>

                <div className={styles.text_content}>
                  <div>
                    <p>Don’t be shy.</p>
                    <p>
                      Our Myndlift providers are here to help you and are
                      pleased to hear from you.
                    </p>
                    <p>
                      Feel free to ask what you want but keep it short, 200
                      words or less, this is just an initial contact.
                    </p>
                    <p>Remember to double check your email and phone number.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {sendMsgFormRenderedStep === 'loading' && (
            <div className={styles.loader_container}>
              <img src={brainLoadingImage} />
            </div>
          )}
          {sendMsgFormRenderedStep === 'msgWasSentSuccessfully' && (
            <div className={styles.msg_was_sent_successfully}>
              <img src={MsgSentImage} />
              <p>
                Your message was sent to {listingToSendMailTo.firstName}{' '}
                {listingToSendMailTo.lastName}.
              </p>
              <button
                type="button"
                onClick={() => {
                  closeSubmitSendMsgForm();
                }}
              >
                <span>Done</span>
              </button>
            </div>
          )}
          {sendMsgFormRenderedStep === 'msgWasNotSentSuccessfully' && (
            <div className={styles.msg_was_sent_successfully}>
              <img src={SadCloudImage} />
              <p>Error accured, message didnt sent</p>
              <button
                type="button"
                onClick={() => {
                  closeSubmitSendMsgForm();
                }}
              >
                <span>Close</span>
              </button>
            </div>
          )}
        </form>
      </Dialog>
    </div>
  );
};

ProvidersSearchPageBase.propTypes = exact({});

export const ProvidersSearchPage = React.memo(ProvidersSearchPageBase);
ProvidersSearchPage.displayName = 'ProvidersSearchPage';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import doneAnimationAsset from '../../../../assets/done-animation.gif';
import styles from './CustomDialogSuccessMessage.scss';

const CustomDialogSuccessMessageBase = ({ headerText, content, column }) => {
  return (
    <div className={styles.root}>
      <div
        className={styles.avatar_container}
        style={column ? { display: 'flex', flexDirection: 'column' } : null}
      >
        <Avatar
          alt="success-image"
          src={doneAnimationAsset}
          className={styles.avatar}
          style={column ? { width: 80, height: 80, marginBottom: 20 } : null}
        />
        <h3 className={classNames(styles.dialog_header, styles.no_margin)}>
          {headerText}
        </h3>
      </div>
      <div className={styles.content_container}>{content}</div>
    </div>
  );
};

CustomDialogSuccessMessageBase.propTypes = exact({
  headerText: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  column: PropTypes.bool,
});

export const CustomDialogSuccessMessage = React.memo(
  CustomDialogSuccessMessageBase
);
CustomDialogSuccessMessage.displayName = 'CustomDialogSuccessMessage';

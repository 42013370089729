import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from 'react';
import { CreditCardDetailsDialog } from './CreditCardDetailsDialog';

const CreditCardDetailsDialogWithValidationBase = (props, ref) => {
  const creditCardDetailsDialogRef = useRef();

  const [creditCardNumberError, setCreditCardNumberError] = useState(null);
  const [creditCardExpiryError, setCreditCardExpiryError] = useState(null);
  const [creditCardCVCError, setCreditCardCVCError] = useState(null);

  const [
    isCreditCardDialogPrimaryBtnDisabled,
    setIsCreditCardDialogPrimaryBtnDisabled,
  ] = useState(true);

  const onCreditCardNumberChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardNumberError(e.error.message);
    } else if (e.empty) {
      setCreditCardNumberError('Card Number Is Required');
    } else {
      setCreditCardNumberError(null);
    }
  }, []);

  const onCreditCardExpiryChange = useCallback(e => {
    if (e.error && e.error.message && !e.empty) {
      setCreditCardExpiryError(e.error.message);
    } else if (e.empty) {
      setCreditCardExpiryError('Card Expiry Date Is Required');
    } else {
      setCreditCardExpiryError(null);
    }
  }, []);

  const onCreditCardCVCChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardCVCError(e.error.message);
    } else if (e.empty) {
      setCreditCardCVCError('Card CVC Is Required');
    } else {
      setCreditCardCVCError(null);
    }
  }, []);

  useEffect(() => {
    if (creditCardNumberError || creditCardExpiryError || creditCardCVCError) {
      setIsCreditCardDialogPrimaryBtnDisabled(true);
    } else {
      setIsCreditCardDialogPrimaryBtnDisabled(false);
    }
  }, [creditCardNumberError, creditCardExpiryError, creditCardCVCError]);

  useImperativeHandle(ref, () => ({
    resetDefaultState: () => {
      creditCardDetailsDialogRef.current.disableChangeListeners();
      creditCardDetailsDialogRef.current.clearInputs();

      setCreditCardNumberError(null);
      setCreditCardExpiryError(null);
      setCreditCardCVCError(null);

      // a hack to stop empty errors from appearing, unfortunately stripe elements clear method
      // triggers the change method.Ironically they don't show errors for the expiry date element
      setTimeout(
        () => creditCardDetailsDialogRef.current.enableChangeListeners(),
        250
      );
    },
  }));

  return (
    <CreditCardDetailsDialog
      creditCardCVCError={creditCardCVCError}
      creditCardExpiryError={creditCardExpiryError}
      creditCardNumberError={creditCardNumberError}
      onCreditCardNumberChange={onCreditCardNumberChange}
      onCreditCardCVCChange={onCreditCardCVCChange}
      onCreditCardExpiryChange={onCreditCardExpiryChange}
      ref={creditCardDetailsDialogRef}
      isPrimaryBtnDisabled={isCreditCardDialogPrimaryBtnDisabled}
      {...props}
    />
  );
};

export const CreditCardDetailsDialogWithValidation = React.memo(
  forwardRef(CreditCardDetailsDialogWithValidationBase)
);
CreditCardDetailsDialogWithValidation.displayName =
  'CreditCardDetailsDialogWithValidation';

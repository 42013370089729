import React, { useEffect, useRef, useState } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ArrowDropDown, Search, Clear } from '@material-ui/icons';
import { Popover } from '@material-ui/core';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import styles from './FrequencySelectionCard.scss';
import { AddFrequencyWidget } from '../AddFrequencyWidget/AddFrequencyWidget';

const FrequencySelectionCardBase = ({
  ids,
  backgroundColor,
  freqCardProps,
  checkboxStates,
}) => {
  const t = useTranslation();
  const { parentId, parentTitle } = ids;

  const {
    convertToFrequencyName,
    // receivedFrequenciesMap,
    frequenciesConfig,
    addOrRemoveFrequency,
    checkedBoxes,
  } = freqCardProps;

  const selectFreqDropDownRef = useRef(null);
  const dropdownInputRef = useRef(null);

  const [isPopoverOpen, setIsPopOverOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    if (dropdownInputRef.current) {
      dropdownInputRef.current[isPopoverOpen ? 'focus' : 'blur']();
    }
  }, [isPopoverOpen]);

  const handleSearch = event => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value === '') {
      // setIsPopOverOpen(false);
      setFilteredItems(Object.keys(frequenciesConfig));
      return;
    }

    setIsPopOverOpen(true);
    const refinedValue = value.trim().toLowerCase();
    const filtered = Object.keys(frequenciesConfig).filter(item => {
      return convertToFrequencyName(frequenciesConfig, item)
        .toLowerCase()
        .includes(refinedValue);
    });
    setFilteredItems(filtered);
  };

  const handlePopOverOnClose = () => {
    setIsPopOverOpen(false);
    setTimeout(() => {}, 0);
  };

  const handlePopOverOnOpen = () => {
    setIsPopOverOpen(true);
  };

  const renderPopupContent = freq => {
    return (
      <div
        key={`${parentId}_${freq}`}
        className={styles.popup_list_item_container}
      >
        <label
          htmlFor={`${parentId}_${freq}`}
          className={classNames(
            styles.popup_list_item_label,
            checkboxStates[`${parentId}_${freq}`]?.disabled
              ? styles.label_disabled
              : styles.label_enabled
          )}
        >
          <input
            type="checkbox"
            id={`${parentId}_${freq}`}
            name={`${parentId}_${freq}`}
            value={convertToFrequencyName(frequenciesConfig, freq)}
            onChange={() =>
              addOrRemoveFrequency(`${parentId}_${freq}`, parentId, freq)
            }
            checked={checkboxStates[`${parentId}_${freq}`]?.checked || false}
            disabled={checkboxStates[`${parentId}_${freq}`]?.disabled || false}
          />
          <p>{convertToFrequencyName(frequenciesConfig, freq)}</p>
        </label>
      </div>
    );
  };

  const getPopupContent = () => {
    if (filteredItems.length === 0 && searchTerm) {
      return (
        <p style={{ placeSelf: 'center', marginBottom: '9px' }}>
          <b>{t('no-such-frequency')}</b>
        </p>
      );
    }
    if (filteredItems.length > 0) {
      return filteredItems.map(freq => renderPopupContent(freq));
    }
    return Object.keys(frequenciesConfig).map(freq => renderPopupContent(freq));
  };

  return (
    <div className={styles.card_container}>
      <div className={styles.card_header} style={{ backgroundColor }}>
        <h2>
          {parentTitle} {t('frequencies')}{' '}
        </h2>
        <h5>
          {t('select-the-frequency')} <b>{parentTitle}</b>
        </h5>
      </div>
      <div className={styles.card_body}>
        <div className={styles.card_body_left_side}>
          <div
            className={styles.dropdown_container}
            ref={selectFreqDropDownRef}
          >
            <Search />
            <input
              type="text"
              id={`${parentId}_dropdown_input`}
              ref={dropdownInputRef}
              placeholder={t('search-for-frequency')}
              onChange={handleSearch}
              onClick={() => {
                setIsPopOverOpen(true);
              }}
              value={searchTerm}
            />
            <ArrowDropDown
              onClick={handlePopOverOnOpen}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <AddFrequencyWidget />
          <Popover
            id="select_freq_dropdown-popover"
            open={isPopoverOpen}
            anchorEl={selectFreqDropDownRef.current}
            onClose={handlePopOverOnClose}
            onClick={() => {
              if (dropdownInputRef.current) dropdownInputRef.current.focus();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: styles.dropdown_select_popover }}
            disableAutoFocus
            disableEnforceFocus
          >
            {frequenciesConfig && checkboxStates && getPopupContent()}
          </Popover>
        </div>
        <div className={styles.card_body_right_side}>
          <b>
            <p style={{ marginBottom: '9px' }}>{parentTitle}</p>
          </b>
          <div
            id={`${parentId}_checkedBoxes_container`}
            className={styles.checked_boxes_container}
          >
            {checkedBoxes &&
              checkedBoxes[parentId].map(freq => {
                return (
                  <div
                    className={styles.single_checked_box_container}
                    key={`${parentId}_${freq}_checked`}
                  >
                    <p style={{ margin: 0 }}>
                      {convertToFrequencyName(frequenciesConfig, freq)}
                    </p>
                    <Clear
                      className={styles.remove_checked_box_icon}
                      onClick={() =>
                        addOrRemoveFrequency(
                          `${parentId}_${freq}`,
                          parentId,
                          freq
                        )
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

FrequencySelectionCardBase.propTypes = exact({
  ids: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  freqCardProps: PropTypes.object.isRequired,
  checkboxStates: PropTypes.object,
});

export const FrequencySelectionCard = withEntryComponent(
  React.memo(FrequencySelectionCardBase)
);

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { CircularProgressbar } from 'react-circular-progressbar';
import HelpIcon from '@material-ui/icons/Help';
import { Paper, Button } from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import 'react-circular-progressbar/dist/styles.css';
import { setFixedDecimalsIfNeeded } from '../../utils/utils';
import styles from './AssessmentPageV2.scss';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { FullBrainMapModal } from './FullBrainMapModal/FullBrainMapModal';
import museOnMaleHeadPNG from '../../../assets/muse-on-male-head.png';
import museOnFemaleHeadPNG from '../../../assets/muse-on-female-head.png';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { AmplitudePerFrequencyGraph } from './AmplitudePerFrequencyGraph/AmplitudePerFrequencyGraph';
import { PerformanceGraph } from './PerformanceGraph/PerformanceGraph';
import { getSwingleCategoriesScores } from '../../models/swingle-checks/swingle-checks';
import { SwingleChecks } from '../SwingleChecks/SwingleChecks';
import { CPTPerformanceGraph } from '../CPTPerformanceGraph/CPTPerformanceGraph';
import { CustomSelect } from '../../Core/Components/CustomSelect/CustomSelect';
import {
  amplitudesPerFrequencyValueTypes,
  channelsRelevantToAmplitudesDistrubutionGraph,
} from '../../models/assessments/assessments';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import { CHANNEL_IDS_TO_NAMES } from '../../models/channels/channels';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { QuestionnaireResults } from '../QuestionnaireResults/QuestionnaireResults';
import { getQuestionnairesScorePercentage } from '../../models/symptom-tracker/symptom-tracker';

const assessmentPageLogger = new MyEventWrapper('assessment_page');
const minimumNumberOfPointsForPerformanceGraph = 3;

const AssessmentPageV2Base = ({
  patientEmail,
  patientFullName,
  patientAge,
  assessment,
  swingleChecks,
  swingleResults,
  patientGender,
  frequenciesToImages,
  frequenciesAmplitudesByChannel,
  CPTResults,
  isFirstAssessment,
  shouldShowBrainMaps,
  questionnaires,
  questionnaireResults,
  answerScales,
}) => {
  const t = useTranslation();
  const [isFullBrainMapModalOpen, setIsFullBrainMapOpen] = useState(false);
  const onShowFullBrainMap = useCallback(() => setIsFullBrainMapOpen(true), []);
  const onFullBrainMapModalDone = useCallback(
    () => setIsFullBrainMapOpen(false),
    []
  );
  const [
    amplitudesPerFrequencyValuesType,
    setAmplitudesPerFrequencyValuesType,
  ] = useState(amplitudesPerFrequencyValueTypes.relative);

  const onAmplitudesPerFrequencyValuesTypeChange = useCallback(event => {
    assessmentPageLogger.log('on_amplitudes_per_frequency_values_type_change', {
      newType: event.target.value,
      customerId: sessionStorage.customerId,
    });
    setAmplitudesPerFrequencyValuesType(event.target.value);
  }, []);

  const CPTPerformanceGraphData = Object.keys(
    pick(CPTResults, [
      'accuracy',
      'commissionErrors',
      'omissionErrors',
      'reactionTime',
      'responsesSD',
    ])
  ).map(resultKey => ({
    category: resultKey,
    score: CPTResults[resultKey],
    performanceScore: CPTResults.performance[resultKey].zScore,
    performanceRank: CPTResults.performance[resultKey].qRank,
  }));

  const assessmentDate = dayJS(assessment.endTimestamp).format(
    DAY_JS_DATE_FORMATS.dayMonthYearSlashes
  );

  const amplitudesPerFrequencyValueTypesOptions = [
    {
      text: t('relative_values'),
      value: amplitudesPerFrequencyValueTypes.relative,
    },
    {
      text: t('absolute_values'),
      value: amplitudesPerFrequencyValueTypes.absolute,
    },
  ];

  const renderBrainMapsOverview = () => (
    <Paper data-test-id="brain-maps-overview">
      <div className={styles.brain_maps_container}>
        <h3 className={styles.header}>{t('brain-maps-overview')}</h3>
        <p>{t('brain-maps-overview-description')}</p>
        <div className={styles.brain_maps_with_text_container}>
          <div
            className={styles.brain_map_with_text}
            data-test-id="brain-map-with-text"
          >
            <p>{t('alpha')}</p>
            <p>{t('eyes-open')}</p>
            <img src={frequenciesToImages['Alpha (8-12Hz)'][0]} />
          </div>
          <div
            className={styles.brain_map_with_text}
            data-test-id="brain-map-with-text"
          >
            <p>{t('beta')}</p>
            <p>{t('eyes_closed_option')}</p>
            <img src={frequenciesToImages['Beta (16-24Hz)'][1]} />
          </div>
          <div
            className={styles.brain_map_with_text}
            data-test-id="brain-map-with-text"
          >
            <p>{t('theta')}</p>
            <p>{t('eyes-open')}</p>
            <img src={frequenciesToImages['Theta (3-7Hz)'][0]} />
          </div>
          <div
            className={styles.brain_map_with_text}
            data-test-id="brain-map-with-text"
          >
            <p>{t('theta')}</p>
            <p>{t('eyes_closed_option')}</p>
            <img src={frequenciesToImages['Theta (3-7Hz)'][1]} />
          </div>
        </div>

        <div className={styles.show_brain_map_btn_container}>
          <Button
            color="primary"
            variant="contained"
            onClick={onShowFullBrainMap}
          >
            <span className={styles.btn}>{t('show-full-brain-map')}</span>
          </Button>
        </div>
      </div>
    </Paper>
  );

  const renderPowerDistributionGraphs = () => (
    <div>
      <Paper>
        <div
          className={styles.power_distribution_container}
          data-test-id="power-distribution-graphs"
        >
          <div
            className={
              styles.amplitudes_per_frequency_value_type_select_container
            }
          >
            <div>
              <h3 className={styles.header}>{t('power_distribution')}</h3>
              <p>{t('power_distribution_overview')}</p>
            </div>
            <div className={styles.select_values_types_container}>
              <CustomSelect
                inputLabelText={t('select_values_types')}
                selectedOption={amplitudesPerFrequencyValuesType}
                allOptions={amplitudesPerFrequencyValueTypesOptions}
                onChange={onAmplitudesPerFrequencyValuesTypeChange}
                data-test-id="select-values-types"
              />

              <TooltipWrapper
                title={
                  <TooltipTitle>
                    {t('select_values_types_extra_info')}
                  </TooltipTitle>
                }
              >
                <HelpIcon />
              </TooltipWrapper>
            </div>
          </div>
          <div className={styles.power_distribution_graphs}>
            {intersection(
              Object.keys(frequenciesAmplitudesByChannel),
              channelsRelevantToAmplitudesDistrubutionGraph
            ).map(channel => {
              const amplitudesByChannel =
                frequenciesAmplitudesByChannel[channel];

              const data = Object.entries(amplitudesByChannel).map(
                ([frequency, amplitudesByValueType]) => {
                  const amplitudes =
                    amplitudesByValueType[amplitudesPerFrequencyValuesType];
                  const processedAmplitudes = mapValues(amplitudes, value =>
                    amplitudesPerFrequencyValuesType ===
                    amplitudesPerFrequencyValueTypes.relative
                      ? value * 100
                      : value
                  );

                  return {
                    name: frequency,
                    ...processedAmplitudes,
                  };
                }
              );

              const shouldEnableUnderTask = data.some(dataUnit =>
                has(dataUnit, 'underTask')
              );

              const legendItems = {
                eyesOpened: true,
                eyesClosed: true,
                ...(shouldEnableUnderTask ? { underTask: true } : {}),
              };

              return (
                <Paper
                  key={channel}
                  data-test-id={`power-distribution-graph-container-${channel}`}
                >
                  <div className={styles.channel_info}>
                    <h4 data-test-id="channel-name">
                      {CHANNEL_IDS_TO_NAMES[channel]}
                    </h4>
                    <h5>
                      {amplitudesPerFrequencyValuesType ===
                      amplitudesPerFrequencyValueTypes.relative
                        ? 'Relative Amplitude'
                        : 'Absolute Amplitude'}
                    </h5>
                  </div>

                  <div
                    className={styles.amplitude_per_frequency_graph_container}
                  >
                    <AmplitudePerFrequencyGraph
                      data={data}
                      legendItems={legendItems}
                      isInPreview
                      dataMax={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? 60
                          : 5
                      }
                      dataMin={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? 'dataMin'
                          : 0
                      }
                      yAxisTickFormatter={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? tick => parseInt(tick, 10)
                          : tick => setFixedDecimalsIfNeeded({ number: tick })
                      }
                      unit={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? '%'
                          : '(µV)'
                      }
                      data-test-id="power-distribution-graph"
                    />
                  </div>
                </Paper>
              );
            })}
          </div>
        </div>
      </Paper>
    </div>
  );

  const renderCPTPerformanceGraph = () => (
    <div>
      <Paper>
        <div className={styles.performance_radar_container}>
          <div className={styles.progress_graph_container}>
            <div>
              <h3 className={styles.header}>{t('cpt_performance_table')}</h3>
              <p>{t('cpt_performance_table_overview')}</p>
            </div>
            <div>
              <CircularProgressbar
                value={CPTResults.overallScore}
                text={`${CPTResults.overallScore}%`}
                className={styles.circular_progress_bar_text}
              />
            </div>
          </div>

          <CPTPerformanceGraph
            data={CPTPerformanceGraphData}
            data-test-id="component-cpt-performance-graph"
          />
        </div>
      </Paper>
    </div>
  );

  const getSwingleData = () => {
    if (swingleResults) {
      const swingleChecksWithIds = mapValues(swingleChecks, (value, key) => ({
        ...value,
        id: key,
      }));

      const relevantSwingleChecksWithIds = pick(
        swingleChecksWithIds,
        Object.keys(swingleResults)
      );

      const swingleChecksGroupedByCategory = groupBy(
        relevantSwingleChecksWithIds,
        check => check.category.en
      );

      const categoryScores = getSwingleCategoriesScores({
        checks: relevantSwingleChecksWithIds,
        results: swingleResults,
      });

      return {
        swingleChecksGroupedByCategory,
        categoryScores,
      };
    }

    return {
      swingleChecksGroupedByCategory: null,
      categoryScores: null,
    };
  };

  const getPerformanceGraphData = () => {
    const { categoryScores } = getSwingleData();
    const performanceGraphDataBasedOnSwingle = categoryScores
      ? Object.keys(categoryScores).map(
          category => ({
            category,
            score: categoryScores[category],
          }),
          []
        )
      : [];

    const performanceGraphDataBasedOnCPT = CPTResults
      ? [
          { category: 'Focus Index', score: CPTResults.focusIndex },
          { category: 'Timing Index', score: CPTResults.timingIndex },
        ]
      : [];

    const performanceGraphDataBasedOnSymptomTracking =
      questionnaireResults &&
      !isEmpty(questionnaireResults.standardizedTotalScores)
        ? [
            {
              category: 'Questionnaire results',
              score: getQuestionnairesScorePercentage({
                questionnaires,
                questionnaireResults,
              }),
            },
          ]
        : [];

    const performanceGraphData = [
      ...performanceGraphDataBasedOnSymptomTracking,
      ...performanceGraphDataBasedOnCPT,
      ...performanceGraphDataBasedOnSwingle,
    ];

    return performanceGraphData;
  };

  const renderPerformanceGraph = () => {
    const performanceGraphData = getPerformanceGraphData();

    return (
      <div>
        <Paper>
          <div className={styles.performance_radar_container}>
            <h3 className={styles.header}>{t('performance_radar')}</h3>
            <p>{t('performance_radar_overview')}</p>
            <PerformanceGraph
              data={performanceGraphData}
              data-test-id="component-performance-graph"
            />
          </div>
        </Paper>
      </div>
    );
  };

  const renderSwingleChecks = () => {
    const { swingleChecksGroupedByCategory, categoryScores } = getSwingleData();
    return (
      <div>
        <SwingleChecks
          swingleChecksGroupedByCategory={swingleChecksGroupedByCategory}
          swingleChecksResults={swingleResults}
          swingleCategoryScores={categoryScores}
          data-test-id="component-swingle-checks"
        />
      </div>
    );
  };

  const renderQuestionnaireResults = () => {
    return (
      <div>
        <QuestionnaireResults
          questionnaires={questionnaires}
          questionnaireResults={questionnaireResults}
          answerScales={answerScales}
        />
      </div>
    );
  };

  const renderCustomerCard = () => (
    <Paper className={styles.user_card} data-test-id="customer-card">
      <div className={styles.user_avatar_container}>
        <img
          className={styles.avatar}
          src={
            patientGender === 'male' ? museOnMaleHeadPNG : museOnFemaleHeadPNG
          }
        />
        <div>
          <p className={styles.title_black}>{patientFullName}</p>
          <p>
            {patientAge} {t('years-old').toLowerCase()}
          </p>
        </div>
      </div>
      <div className={styles.user_info_container}>
        <p>
          <span>
            <AccessibilityIcon />
          </span>
          {patientEmail}
        </p>
        <p>
          <span>
            <DateRangeIcon />
          </span>
          {assessmentDate}
        </p>
      </div>
    </Paper>
  );

  return (
    <React.Fragment>
      <div className={styles.root}>
        <div className={styles.user_card_and_brain_maps}>
          {renderCustomerCard()}

          {getPerformanceGraphData().length >
            minimumNumberOfPointsForPerformanceGraph &&
            renderPerformanceGraph()}
        </div>
        {questionnaireResults && renderQuestionnaireResults()}
        {!isEmpty(CPTResults) && renderCPTPerformanceGraph()}
        {swingleResults && renderSwingleChecks()}
        {frequenciesToImages &&
          shouldShowBrainMaps &&
          renderBrainMapsOverview()}
        {frequenciesAmplitudesByChannel && renderPowerDistributionGraphs()}
      </div>

      {frequenciesToImages && (
        <FullBrainMapModal
          isOpen={isFullBrainMapModalOpen}
          onDone={onFullBrainMapModalDone}
          frequenciesToImages={frequenciesToImages}
          isFirstAssessment={isFirstAssessment}
          data-test-id="brain-maps-modal"
        />
      )}
    </React.Fragment>
  );
};

AssessmentPageV2Base.propTypes = exact({
  patientEmail: PropTypes.string.isRequired,
  patientFullName: PropTypes.string.isRequired,
  patientAge: PropTypes.number.isRequired,
  assessment: PropTypes.object.isRequired,
  swingleChecks: PropTypes.object.isRequired,
  swingleResults: PropTypes.object,
  patientGender: PropTypes.oneOf(['male', 'female']).isRequired,
  frequenciesToImages: PropTypes.object,
  frequenciesAmplitudesByChannel: PropTypes.object,
  CPTResults: PropTypes.object,
  isFirstAssessment: PropTypes.bool.isRequired,
  shouldShowBrainMaps: PropTypes.bool.isRequired,
  questionnaires: PropTypes.object.isRequired,
  answerScales: PropTypes.object.isRequired,
  questionnaireResults: PropTypes.object,
});

export const AssessmentPageV2 = React.memo(
  withEntryComponent(AssessmentPageV2Base)
);
AssessmentPageV2.displayName = 'AssessmentPageV2';

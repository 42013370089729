import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
// import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import Switch from '@material-ui/core/Switch';
import { Button } from '@material-ui/core';
import { withTranslation } from '../../Core/hocs/withTranslation/withTranslation';
import { capitalizeFirstLetter } from '../../utils/utils';
import { RightDownArrow } from '../../Core/Icons/RightDownArrow';
import styles from './ProgramSettingsStep.scss';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { SwitchToNormalHeadsetDialogHooker } from '../SwitchToNormalHeadsetDialogHooker/SwitchToNormalHeadsetDialogHooker';
import { CustomerInfoProvider } from '../../Core/contexts/contexts';
import eyesClosedAsset from '../../../assets/eyes-closed-2.png';

class ProgramSettingsStepBase extends React.Component {
  state = {
    isThresholdSettingsHidden: true,
  };

  constructor(props) {
    super(props);
    this.buttonToSwitchHeadsetToNormalRef = React.createRef();
  }

  getSimpleModeTimeDetailsText = () => {
    const { t } = this.props;
    const duration = this.getDurationObjectBasedOnDuration();
    if (duration) {
      const { minutes, rounds } = duration;
      return `${rounds} ${t('rounds')}, ${minutes} ${t('minutes_each')}`;
    }
    return '';
  };

  getDurationObjectBasedOnDuration = () =>
    this.props.selectedProtocol.durations.find(
      duration =>
        duration.minutes * duration.rounds === this.props.sessionDuration
    );

  getEstimatedPositiveFeedbackText = () => {
    // const { t } = this.props;
    const successRate = this.props.overAllSuccessRate;
    const textSuccessRate = Number.isNaN(successRate) ? 0 : successRate;
    return `~${textSuccessRate}% positive feedback`;
  };

  getAdvancedModeTimeDetailsText = () => {
    const { t } = this.props;
    const minutes = this.props.roundDuration * this.props.numOfRounds;
    const intMinutes = Math.floor(minutes);
    const seconds = Math.ceil((minutes - intMinutes) * 60);
    const minutesAnd = `${intMinutes === 1 ? t('minute') : t('minutes')} ${t(
      'and'
    )}`;
    return `${intMinutes || 0} ${minutesAnd} ${seconds || 0} ${t(
      'seconds-training'
    )}`;
  };

  getSelectedChannelsNames = () => {
    const selectedChannels = {};
    Object.entries(this.props.patientProtocolDetails.channelDetails).forEach(
      ([channelType, channel]) => {
        selectedChannels[channelType] =
          channel.auxiliaryLocation || channel.name;
      }
    );

    return transformChannelNamesForView({
      trainingChannel: selectedChannels.training,
      referenceChannel: selectedChannels.reference,
    });
  };

  getSelectedChannelsLabel = () => {
    const { t } = this.props;
    const selectedChannels = this.getSelectedChannelsNames();
    return `${selectedChannels.trainingChannel} ${t('active')} - ${
      selectedChannels.referenceChannel
    } ${t('reference')}`;
  };

  renderFrequenciesInputs = () => {
    const { extractFrequencyName, onFrequencyChange } = this.props;
    const frequencies = {
      inhibiting: this.props.patientProtocolDetails.inhibitingFrequencies,
      rewarding: this.props.patientProtocolDetails.rewardFrequencies,
    };

    return Object.entries(frequencies).map(
      ([frequencyType, specificTypeFrequencies]) =>
        specificTypeFrequencies.map((frequency, frequencyIndex) => {
          const frequencyName =
            extractFrequencyName(
              frequency.minFrequency,
              frequency.maxFrequency
            ) || '';

          const frequencyId = `${frequencyName}-${frequencyType}`;
          return (
            <div
              key={frequencyId}
              className={styles.advanced_mode_frequencies_container}
            >
              <div style={{ width: '100%' }}>
                <p className={styles.advanced_mode_frequency_type_label}>
                  {capitalizeFirstLetter(frequencyType).replace(
                    'Rewarding',
                    'Enhancing'
                  )}
                </p>
                <p>
                  {capitalizeFirstLetter(frequencyName)} (
                  {frequency.minFrequency}-{frequency.maxFrequency}Hz)
                </p>
                <FormControl
                  classes={{
                    root: styles.mu_form_control_input,
                  }}
                  error={!!this.props.errors[frequencyId]}
                >
                  <Input
                    type="number"
                    value={frequency.calibrationPercentile}
                    onChange={onFrequencyChange}
                    inputProps={{
                      'data-frequency-type': frequencyType,
                      'data-frequency-index': frequencyIndex,
                      'data-frequency-name': frequencyName,
                      min: this.props.minFrequency,
                      max: this.props.maxFrequency,
                    }}
                    classes={{
                      input: styles.advanced_mode_input,
                    }}
                  />
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {this.props.errors[frequencyId]}
                  </FormHelperText>
                </FormControl>
              </div>
              {this.props.patientProtocolDetails.calibEnabled !== false && (
                <div className={styles.advanced_mode_frequency_percentage}>
                  <p>%</p>
                </div>
              )}
            </div>
          );
        })
    );
  };

  renderAdvancedProgramSettings = () => {
    const {
      protocols,
      onProtocolChange,
      numOfRounds,
      roundDuration,
      onRoundDurationChange,
      onNumOfRoundsChange,
      isEyesClosed,
      onEyesClosedChange,
      patientProtocolDetailsName,
      headsetDetails,
      patientId,
      onSwitchHeadsetToNormalModeCompleted,
      t,
      onSessionDurationChanged,
    } = this.props;

    const isContinuityCustomer = headsetDetails.isDispatched;
    return (
      <React.Fragment>
        <div
          className={styles.advanced_mode_choose_protocol_container}
          style={{ display: isContinuityCustomer ? 'none' : undefined }}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontWeight: 500 }}>Training Protocol</div>
              <div>{this.getEstimatedPositiveFeedbackText()}</div>
            </div>
            {this.props.errors.estimatedPositiveFeedback && (
              <p className={styles.estimated_positive_feedback_error}>
                {this.props.errors.estimatedPositiveFeedback}
              </p>
            )}
            <FormControl
              classes={{
                root: styles.mu_form_control_root,
              }}
            >
              <NativeSelect
                value={patientProtocolDetailsName}
                onChange={onProtocolChange}
                classes={{
                  icon: styles.mu_native_select_icon,
                  root: styles.mu_select_menu,
                }}
                IconComponent={RightDownArrow}
              >
                {protocols
                  .filter(protocol => !protocol.archived)
                  .map(protocol => (
                    <option
                      key={protocol.protocolName}
                      value={protocol.protocolName}
                    >
                      {protocol.protocolName}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
            <div style={{ display: 'flex' }}>
              <p className={styles.edit_custom_protocol_link}>
                <div
                  // href="/createProtocol.html"
                  // target="_blank"
                  style={{
                    color: '#2196f3',
                    cursor: 'pointer',
                    fontWeight: 400,
                  }}
                  onClick={() => {
                    new MyEvent(
                      'fast_create_session_advanced_click_add_custom_protocols'
                    ).log({
                      clinicId: sessionStorage.userId,
                      customerId: patientId,
                    });
                    window.open('/view_protocols.html', '_blank');
                  }}
                >
                  <EditIcon style={{ fontSize: 12, marginRight: 5 }} />
                  {t('edit_add_custom_protocols')}
                </div>
              </p>
              <p
                style={{
                  display: isContinuityCustomer ? 'none' : undefined,
                  fontWeight: 400,
                  marginLeft: 10,
                  color: '#2196F3',
                }}
                className={classNames(
                  // styles.advanced_mode_threshold_settings_label,
                  styles.cursor_pointer
                )}
                onClick={() => {
                  this.setState(prvState => ({
                    isThresholdSettingsHidden: !prvState.isThresholdSettingsHidden,
                  }));
                  new MyEvent(
                    'fast_create_session_advanced_click_on_threshold_configurations'
                  ).log({
                    value: !this.state.isThresholdSettingsHidden,
                    clinicId: sessionStorage.userId,
                    customerId: patientId,
                  });
                }}
              >
                <SettingsIcon style={{ fontSize: 12, marginRight: 5 }} />
                {`${t('threshold_settings_label')}`}
              </p>
            </div>
          </div>
          <div className={styles.option_label_container}>
            {!this.props.toggleRounds ? (
              <div>
                <div className={styles.advanced_mode_time_settings_container}>
                  <p
                    className={styles.option_label}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    Session Duration (Minutes)
                  </p>

                  <FormControl
                    classes={{
                      root: styles.mu_form_control_input,
                    }}
                    error={
                      !!(
                        this.props.errors.numOfRounds ||
                        this.props.errors.roundDuration
                      )
                    }
                  >
                    <Input
                      type="number"
                      value={numOfRounds * roundDuration}
                      onChange={e => onSessionDurationChanged(e.target.value)}
                      classes={{
                        input: styles.advanced_mode_input,
                      }}
                      inputProps={{
                        min: 1,
                      }}
                    />
                    {!!(
                      this.props.errors.numOfRounds ||
                      this.props.errors.roundDuration
                    ) && (
                      <FormHelperText
                        classes={{ root: styles.form_helper_text_error }}
                      >
                        {this.props.errors.numOfRounds ||
                          this.props.errors.roundDuration}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            ) : (
              <div
                className={styles.advanced_mode_time_settings_container}
                style={{ display: isContinuityCustomer ? 'none' : undefined }}
              >
                <div>
                  <p
                    className={styles.option_label}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t('rounds-upper-case')}
                  </p>
                  <FormControl
                    classes={{
                      root: styles.mu_form_control_input,
                    }}
                    error={!!this.props.errors.numOfRounds}
                  >
                    <Input
                      type="number"
                      value={numOfRounds}
                      onChange={e => onNumOfRoundsChange(e.target.value)}
                      classes={{
                        input: styles.advanced_mode_input,
                      }}
                      inputProps={{
                        min: 1,
                      }}
                    />
                    {this.props.errors.numOfRounds && (
                      <FormHelperText
                        classes={{ root: styles.form_helper_text_error }}
                      >
                        {this.props.errors.numOfRounds}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div style={{ marginLeft: 5 }}>
                  <p
                    className={styles.option_label}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t('round-duration')}
                  </p>
                  <FormControl
                    classes={{
                      root: styles.mu_form_control_input,
                    }}
                    error={!!this.props.errors.roundDuration}
                  >
                    <Input
                      type="number"
                      value={roundDuration}
                      onChange={e => onRoundDurationChange(e.target.value)}
                      inputProps={{
                        min: 1,
                      }}
                      classes={{
                        input: styles.advanced_mode_input,
                      }}
                    />
                    {this.props.errors.roundDuration && (
                      <FormHelperText
                        classes={{ root: styles.form_helper_text_error }}
                      >
                        {this.props.errors.roundDuration}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                {/* <div className={styles.advanced_mode_time_summary_container}>
                <Chip
                  label={this.getAdvancedModeTimeDetailsText()}
                  classes={{ label: styles.chip_label }}
                />
              </div> */}
              </div>
            )}
            <p
              className={styles.edit_custom_protocol_link}
              style={
                // !this.state.toggleRounds &&
                // eslint-disable-next-line no-extra-boolean-cast
                !!(
                  this.props.errors.numOfRounds ||
                  this.props.errors.roundDuration
                )
                  ? {
                      color: '#2196f3',
                      cursor: 'pointer',
                      fontWeight: 400,
                      marginTop: -20,
                    }
                  : {
                      color: '#2196f3',
                      cursor: 'pointer',
                      fontWeight: 400,
                    }
              }
              onClick={this.props.onToggleRoundsClicked}
            >
              <EditIcon style={{ fontSize: 12, marginRight: 5 }} />
              {!this.state.toggleRounds
                ? // eslint-disable-next-line no-restricted-globals
                  `${isNaN(numOfRounds) ? 0 : numOfRounds} round${
                    numOfRounds > 1 ? 's' : ''
                    // eslint-disable-next-line no-restricted-globals
                  }, ${isNaN(roundDuration) ? 0 : roundDuration} min each`
                : `${
                    numOfRounds === 0 ||
                    roundDuration === 0 ||
                    // eslint-disable-next-line no-restricted-globals
                    isNaN(roundDuration) ||
                    // eslint-disable-next-line no-restricted-globals
                    isNaN(numOfRounds)
                      ? 0
                      : numOfRounds * roundDuration
                  } minute${
                    numOfRounds * roundDuration > 1 ? 's' : ''
                  } training`}
            </p>
          </div>
        </div>

        <div style={{ display: isContinuityCustomer ? 'none' : undefined }}>
          {/* <p
            className={classNames(
              styles.advanced_mode_threshold_settings_label,
              styles.cursor_pointer
            )}
            onClick={() => {
              this.setState(prvState => ({
                isThresholdSettingsHidden: !prvState.isThresholdSettingsHidden,
              }));
              new MyEvent(
                'fast_create_session_advanced_click_on_threshold_configurations'
              ).log({
                value: !this.state.isThresholdSettingsHidden,
                clinicId: sessionStorage.userId,
                customerId: patientId,
              });
            }}
          >
            {`${this.state.isThresholdSettingsHidden ? '+' : '-'} ${t(
              'threshold_settings_label'
            )}`}
          </p> */}
          <div>
            {/* <Divider
              className={
                !this.state.isThresholdSettingsHidden ? 'hidden' : undefined
              }
            /> */}
            <Collapse in={!this.state.isThresholdSettingsHidden}>
              <div
                className={classNames(
                  styles.advanced_mode_threshold_settings_container,
                  {
                    [styles.expanded]: !this.state.isThresholdSettingsHidden,
                  }
                )}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginRight: 0,
                    paddingTop: 10,
                  }}
                >
                  <p>{t('threshold_settings_advanced_introduction')}</p>
                  <CloseIcon
                    style={{
                      fontSize: 16,
                      cursor: 'pointer',
                      marginTop: 10,
                      marginLeft: 40,
                    }}
                    onClick={() => {
                      this.setState({
                        isThresholdSettingsHidden: true,
                      });
                    }}
                  />
                </div>
                {this.renderFrequenciesInputs()}
              </div>
            </Collapse>
          </div>
        </div>

        <div style={{ display: isContinuityCustomer ? 'none' : undefined }}>
          <div className={styles.advanced_mode_eyes_closed_settings_container}>
            <img
              src={eyesClosedAsset}
              style={{ width: 23, height: 23, marginRight: 10 }}
            />
            <p className={styles.option_label}>{t('eyes_closed_training')}</p>
            <div className={styles.advanced_mode_eyes_closed_switch_and_info}>
              <TooltipWrapper
                title={t('eyes_closed_tooltip')}
                placement="right"
                classes={{
                  tooltip: styles.advanced_mode_eyes_closed_tooltip,
                }}
              >
                <InfoIcon />
              </TooltipWrapper>
            </div>
            <div className={styles.advanced_mode_eyes_closed_switch_and_info}>
              <Switch
                checked={isEyesClosed}
                onChange={onEyesClosedChange}
                color="primary"
              />
            </div>
          </div>
        </div>

        {this.props.isEditingMode && (
          <div
            className={styles.selected_channels_container}
            style={{ display: isContinuityCustomer ? 'none' : undefined }}
          >
            <div className={styles.selected_channels_chip_container}>
              <p className={styles.advanced_mode_threshold_settings_label}>
                {t('training_channels')}
              </p>
              <Chip
                label={this.getSelectedChannelsLabel()}
                classes={{
                  label: styles.chip_label,
                  root: styles.selected_channels_label,
                }}
              />
              <a
                className={styles.chose_different_channels_a}
                onClick={this.props.onChoseDifferentChannelsClick}
                href="#"
              >
                {t('chose_different_channels')}
              </a>
            </div>
          </div>
        )}

        {/* <div
          className={styles.advanced_mode_time_settings_container}
          style={{ display: isContinuityCustomer ? 'none' : undefined }}
        >
          <div>
            <p className={styles.option_label}>{t('rounds-upper-case')}</p>
            <FormControl
              classes={{
                root: styles.mu_form_control_input,
              }}
              error={!!this.props.errors.numOfRounds}
            >
              <Input
                type="number"
                value={numOfRounds}
                onChange={onNumOfRoundsChange}
                classes={{
                  input: styles.advanced_mode_input,
                }}
                inputProps={{
                  min: 1,
                }}
              />
              {this.props.errors.numOfRounds && (
                <FormHelperText
                  classes={{ root: styles.form_helper_text_error }}
                >
                  {this.props.errors.numOfRounds}
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <div>
            <p className={styles.option_label}>{t('round-duration')}</p>
            <FormControl
              classes={{
                root: styles.mu_form_control_input,
              }}
              error={!!this.props.errors.roundDuration}
            >
              <Input
                type="number"
                value={roundDuration}
                onChange={onRoundDurationChange}
                inputProps={{
                  min: 1,
                }}
                classes={{
                  input: styles.advanced_mode_input,
                }}
              />
              {this.props.errors.roundDuration && (
                <FormHelperText
                  classes={{ root: styles.form_helper_text_error }}
                >
                  {this.props.errors.roundDuration}
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <div className={styles.advanced_mode_time_summary_container}>
            <Chip
              label={this.getAdvancedModeTimeDetailsText()}
              classes={{ label: styles.chip_label }}
            />
          </div>
        </div> */}

        {this.props.isEditingMode && (
          <div className={styles.sessions_options_container}>
            {isContinuityCustomer && (
              <div className={styles.continuity_customer_note_container}>
                <p>
                  This user is using a headset on the Continuity Plan which
                  prevents you from viewing their data or changing their
                  training.
                </p>
                <p>
                  To restore this functionality, switch back to Normal Mode.
                </p>

                <div className={styles.btn_container}>
                  <Button
                    variant="contained"
                    buttonRef={this.buttonToSwitchHeadsetToNormalRef}
                  >
                    <span className={styles.btn}>SWITCH TO NORMAL MODE</span>
                  </Button>
                </div>
              </div>
            )}
            <div className={styles.num_of_sessions_option_container}>
              <p className={styles.option_label}>
                {t('total-number-sessions-p')}
              </p>
              <FormControl
                classes={{
                  root: styles.mu_form_control_num_of_sessions_input,
                }}
                error={!!this.props.errors.numOfSessions}
              >
                <Input
                  type="number"
                  value={this.props.numOfSessions}
                  onChange={this.props.onNumOfSessionsChange}
                  inputProps={{
                    min: this.props.numOfCompletedSessions,
                  }}
                  classes={{
                    input: styles.advanced_mode_input,
                  }}
                  error={!!this.props.errors.numOfSessions}
                />
                <FormHelperText
                  classes={{ root: styles.mu_num_of_sessions_error_text }}
                >
                  {this.props.errors.numOfSessions}
                </FormHelperText>
              </FormControl>
              <div className={styles.sessions_completed_so_far}>
                <p>
                  {t('number-sessions-so-far')}{' '}
                  {this.props.numOfCompletedSessions}
                </p>
              </div>
            </div>
          </div>
        )}

        {isContinuityCustomer && (
          <CustomerInfoProvider value={{ id: patientId }}>
            <SwitchToNormalHeadsetDialogHooker
              buttonRef={this.buttonToSwitchHeadsetToNormalRef}
              headsetDetails={headsetDetails}
              onSwitchCompleted={onSwitchHeadsetToNormalModeCompleted}
            />
          </CustomerInfoProvider>
        )}
      </React.Fragment>
    );
  };

  renderBasicProgramSettings = () => {
    const {
      selectedProtocol,
      onProtocolChange,
      protocols,
      sessionDuration,
      onSessionDurationChange,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <div style={{ display: 'flex', minWidth: '100%' }}>
          <div className={styles.basic_options_container}>
            <div className={styles.basic_option}>
              <p className={styles.input_label}>{t('training-protocol')}</p>
              <FormControl
                classes={{
                  root: styles.mu_form_control_root,
                }}
              >
                <NativeSelect
                  value={selectedProtocol.protocolName}
                  onChange={onProtocolChange}
                  classes={{
                    icon: styles.mu_native_select_icon,
                    root: styles.mu_select_menu,
                  }}
                  IconComponent={RightDownArrow}
                >
                  {protocols
                    .filter(protocol => protocol.isSimple && !protocol.archived)
                    .map(protocol => (
                      <option
                        key={protocol.protocolName}
                        value={protocol.protocolName}
                      >
                        {protocol.protocolName}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
              <p style={{ maxWidth: '420px' }}>
                {selectedProtocol.description}
              </p>
            </div>

            <div className={styles.basic_option}>
              <p className={styles.input_label}>
                {t('session-duration-minutes')}
              </p>
              <FormControl
                classes={{
                  root: styles.mu_form_control_root,
                }}
              >
                <NativeSelect
                  value={sessionDuration}
                  onChange={onSessionDurationChange}
                  classes={{
                    icon: styles.mu_native_select_icon,
                    root: styles.mu_select_menu,
                  }}
                  IconComponent={RightDownArrow}
                >
                  {selectedProtocol.durations
                    .map(
                      durationObject =>
                        durationObject.minutes * durationObject.rounds
                    )
                    .map(duration => (
                      <option key={duration} value={duration}>
                        {duration}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
              <div className={styles.time_container_simple}>
                <Chip
                  label={this.getSimpleModeTimeDetailsText()}
                  classes={{ label: styles.chip_label, root: styles.chip_root }}
                  style={
                    this.getSimpleModeTimeDetailsText()
                      ? null
                      : { display: 'none' }
                  }
                />
              </div>
            </div>
          </div>

          <Hidden xsDown>
            <div className={styles.protocol_image_container}>
              <img
                className={styles.protocol_image}
                src={selectedProtocol.image}
                alt={selectedProtocol.protocolName}
              />
            </div>
          </Hidden>
        </div>

        {this.props.isEditingMode && (
          <div>
            <span style={{ fontWeight: 500 }}>Recommended eye state:</span>
            <Chip
              label={this.props.isEyesClosed ? 'Eyes Closed' : 'Eyes Open'}
              classes={{
                label: styles.chip_label,
                // root: styles.selected_channels_label,
              }}
              style={{ marginLeft: 20 }}
            />
          </div>
        )}

        {this.props.isEditingMode && (
          <div
            className={styles.selected_channels_container}
            // style={{ display: isContinuityCustomer ? 'none' : undefined }}
          >
            <div className={styles.selected_channels_chip_container}>
              <p className={styles.advanced_mode_threshold_settings_label}>
                Recommended training channels:
              </p>
              <Chip
                label={this.getSelectedChannelsLabel()}
                classes={{
                  label: styles.chip_label,
                  root: styles.selected_channels_label,
                }}
              />
              <a
                className={styles.chose_different_channels_a}
                onClick={this.props.onChoseDifferentChannelsClick}
                href="#"
              >
                {t('choose-a-different-placement')}
              </a>
            </div>
          </div>
        )}

        {this.props.isEditingMode && (
          <div className={styles.sessions_options_container}>
            {/* {isContinuityCustomer && (
                <div className={styles.continuity_customer_note_container}>
                  <p>
                    This user is using a headset on the Continuity Plan which
                    prevents you from viewing their data or changing their
                    training.
                  </p>
                  <p>
                    To restore this functionality, switch back to Normal Mode.
                  </p>

                  <div className={styles.btn_container}>
                    <Button
                      variant="contained"
                      buttonRef={this.buttonToSwitchHeadsetToNormalRef}
                    >
                      <span className={styles.btn}>SWITCH TO NORMAL MODE</span>
                    </Button>
                  </div>
                </div>
              )} */}
            <div className={styles.num_of_sessions_option_container}>
              <p className={styles.option_label}>
                {t('total-number-sessions-p')}
              </p>
              <FormControl
                classes={{
                  root: styles.mu_form_control_num_of_sessions_input,
                }}
                error={!!this.props.errors.numOfSessions}
              >
                <Input
                  type="number"
                  value={this.props.numOfSessions}
                  onChange={this.props.onNumOfSessionsChange}
                  inputProps={{
                    min: this.props.numOfCompletedSessions,
                  }}
                  classes={{
                    input: styles.advanced_mode_input,
                  }}
                  error={!!this.props.errors.numOfSessions}
                />
                <FormHelperText
                  classes={{ root: styles.mu_num_of_sessions_error_text }}
                >
                  {this.props.errors.numOfSessions}
                </FormHelperText>
              </FormControl>
              <div className={styles.sessions_completed_so_far}>
                <p>
                  {t('number-sessions-so-far')}{' '}
                  {this.props.numOfCompletedSessions}
                </p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    return this.props.isProgramSettingsAdvancedMode
      ? this.renderAdvancedProgramSettings()
      : this.renderBasicProgramSettings();
  }
}

ProgramSettingsStepBase.propTypes = exact({
  isProgramSettingsAdvancedMode: PropTypes.bool,
  isEditingMode: PropTypes.bool,
  selectedProtocol: PropTypes.object,
  t: PropTypes.func.isRequired,
  errors: PropTypes.object,
  patientProtocolDetails: PropTypes.object,
  onNumOfSessionsChange: PropTypes.func,
  protocols: PropTypes.array.isRequired,
  onProtocolChange: PropTypes.func.isRequired,
  numOfRounds: PropTypes.number,
  roundDuration: PropTypes.number,
  onRoundDurationChange: PropTypes.func,
  onNumOfRoundsChange: PropTypes.func,
  onChoseDifferentChannelsClick: PropTypes.func,
  isEyesClosed: PropTypes.bool,
  onEyesClosedChange: PropTypes.func,
  patientProtocolDetailsName: PropTypes.string,
  numOfCompletedSessions: PropTypes.number,
  numOfSessions: PropTypes.number,
  sessionDuration: PropTypes.number,
  onSessionDurationChange: PropTypes.func,
  minFrequency: PropTypes.number,
  maxFrequency: PropTypes.number,
  extractFrequencyName: PropTypes.func,
  onFrequencyChange: PropTypes.func,
  overAllSuccessRate: PropTypes.number.isRequired,
  headsetDetails: PropTypes.object,
  patientId: PropTypes.string,
  onSwitchHeadsetToNormalModeCompleted: PropTypes.func,
  onSessionDurationChanged: PropTypes.func,
  toggleRounds: PropTypes.bool,
  onToggleRoundsClicked: PropTypes.func,
});

ProgramSettingsStepBase.defaultProps = {
  errors: {},
  isEditingMode: false,
};

export const ProgramSettingsStep = withTranslation(ProgramSettingsStepBase);

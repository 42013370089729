import React from 'react';

export const LocalizationContext = React.createContext();

export const LocalizationProvider = LocalizationContext.Provider;
export const LocalizationConsumer = LocalizationContext.Consumer;

export const ClinicInfoContext = React.createContext();
export const ClinicInfoProvider = ClinicInfoContext.Provider;

export const CustomerInfoContext = React.createContext();
export const CustomerInfoProvider = CustomerInfoContext.Provider;

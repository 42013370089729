import { useState, useEffect } from 'react';

export const useBoundingRect = ref => {
  const [, setBoundingRect] = useState(ref ? ref.current : null);

  useEffect(() => {
    setBoundingRect(ref ? ref.current.getBoundingClientRect() : null);

    const onWindowResize = () => {
      setBoundingRect(ref ? ref.current.getBoundingClientRect() : null);
    };

    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [ref]);

  return ref && ref.current ? ref.current.getBoundingClientRect() : null;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import styles from './ClinicProfile.scss';
import clinicProfilePlaceholderImage from '../../../assets/clinic-profile-pic-placeholder.png';
import clinicProfileBackgroundImage from '../../../assets/clinic-profile-background.png';
import { getLogoImage } from '../../models/logo/logo';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { SelfRegistrationDialog } from '../SelfRegistrationDialog/SelfRegistrationDialog';
import { LogoDialog } from '../WhiteLabelingPopOut/LogoDialog';

const ClinicProfileBase = ({
  onLearnMoreAboutWhiteLabeling,
  onComponentReady,
}) => {
  const [clinicLogoInfo, setClinicLogoInfo] = useState(null);
  const [isRegistrationDialogOpen, setIsRegistrationDialogOpen] = useState(
    false
  );
  const [isLogoDialogOpen, setIsLogoDialogOpen] = useState(false);

  const [clinicUserInfo] = useFirebaseDB({
    path: `userInfo/${sessionStorage.userId}`,
  });

  const [clinicNonfPlan] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}`,
  });

  const [appLogoSettings] = useFirebaseDB({
    path: `appLogoSettings/${sessionStorage.userId}`,
  });

  useEffect(() => {
    (async () => {
      if (clinicLogoInfo === null) {
        const logoImageInfo = await getLogoImage({
          clinicId: sessionStorage.userId,
        });

        setClinicLogoInfo(logoImageInfo);
        onComponentReady();
      }
    })();
  });

  useEffect(() => {
    (async () => {
      if (appLogoSettings) {
        const logoImageInfo = await getLogoImage({
          clinicId: sessionStorage.userId,
        });
        setClinicLogoInfo(logoImageInfo);
        onComponentReady();
      } else {
        setClinicLogoInfo(null);
        onComponentReady();
      }
    })();
  }, [appLogoSettings]);

  const shouldShowWhiteLabelingCustomImage =
    clinicLogoInfo && clinicLogoInfo.customLogo && clinicLogoInfo.logoImage;
  const logoImage = shouldShowWhiteLabelingCustomImage
    ? clinicLogoInfo.logoImage
    : clinicProfilePlaceholderImage;

  const clinicName = clinicUserInfo
    ? `${clinicUserInfo.firstName} ${clinicUserInfo.lastName}`
    : `${sessionStorage.clinicFirstName} ${sessionStorage.clinicLastName}`;

  return (
    <div
      className={styles.root}
      style={{
        background: `url(${clinicProfileBackgroundImage}) no-repeat left top`,
        backgroundSize: '100%',
        position: 'relative',
      }}
    >
      <img
        src={logoImage}
        className={
          shouldShowWhiteLabelingCustomImage
            ? styles.custom_logo_image
            : styles.logo_image
        }
        onClick={
          shouldShowWhiteLabelingCustomImage
            ? () => {}
            : onLearnMoreAboutWhiteLabeling
        }
      />
      {shouldShowWhiteLabelingCustomImage ? (
        <EditIcon
          style={{
            position: 'absolute',
            bottom: 15,
            left: 20,
            color: '#808080',
            cursor: 'pointer',
            border: '1px white solid',
            borderRadius: '50%',
            fontSize: 18,
            backgroundColor: 'white',
          }}
          onClick={() => {
            setIsLogoDialogOpen(true);
          }}
        />
      ) : null}
      <div>
        <p>{clinicName}</p>
        {/* {!(
          clinicLogoInfo &&
          clinicLogoInfo.customLogo &&
          clinicLogoInfo.logoImage
        ) &&
          sessionStorage.isTeamMember !== 'true' && (
            <React.Fragment>
              <a href="#" onClick={onLearnMoreAboutWhiteLabeling}>
                Want users to see your own brand?
              </a>
            </React.Fragment>
          )} */}
        {clinicUserInfo && clinicUserInfo.registrationCode ? (
          <div
            className={styles.registration_code}
            onClick={() => {
              setIsRegistrationDialogOpen(true);
              new MyEvent('self_reg_registration_code_clicked').log();
            }}
          >
            Code: {clinicUserInfo.registrationCode}{' '}
            <ArrowForwardIosIcon style={{ width: 10, height: 10 }} />
          </div>
        ) : null}
      </div>
      <SelfRegistrationDialog
        isOpen={isRegistrationDialogOpen}
        onClose={() => {
          setIsRegistrationDialogOpen(false);
        }}
        registrationCode={clinicUserInfo && clinicUserInfo.registrationCode}
        onAddClientClicked={() => {
          // eslint-disable-next-line no-undef
          openAddClientModal();
        }}
        noAddClient
        clinicNonfPlan={clinicNonfPlan}
      />
      <LogoDialog
        isOpen={isLogoDialogOpen}
        onClose={() => {
          setIsLogoDialogOpen(false);
        }}
        closeOuterPopup={() => {}}
      />
    </div>
  );
};

ClinicProfileBase.propTypes = exact({
  onLearnMoreAboutWhiteLabeling: PropTypes.func.isRequired,
  onComponentReady: PropTypes.func.isRequired,
});

export const ClinicProfile = React.memo(ClinicProfileBase);
ClinicProfile.displayName = 'ClinicProfile';

import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import styles from './LagoUploadedImages.scss';

const LagoUploadedImagesBase = ({
  open,
  onClose,
  uploadedImages,
  onUploadedImageClicked,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '20px' }}>Select image</span>
      </DialogTitle>
      <DialogContent>
        <div className={styles.lago_upload_images_modal_container}>
          {uploadedImages.map(image => (
            <img
              src={image}
              className={styles.lago_upload_image_item}
              onClick={() => {
                onUploadedImageClicked(image);
                onClose();
              }}
              key={image}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          color="primary"
        >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoUploadedImagesBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadedImages: PropTypes.array.isRequired,
  onUploadedImageClicked: PropTypes.func.isRequired,
});

export const LagoUploadedImages = React.memo(LagoUploadedImagesBase);
LagoUploadedImages.displayName = 'LagoUploadedImages';

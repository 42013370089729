import React, { useRef, useCallback, useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import {
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { capitalize, groupBy } from 'lodash';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import classNames from 'classnames';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { useCustomerInfo } from '../../../Core/hooks/useCustomerInfo';
import { injectIDs } from '../../../utils/utils';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './InsightsTab.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { getInsightItemDetails } from '../../../models/insights/insights';
import { InsightsBarsGraph } from '../../InsightsGraphs/InsightsBarsGraph/InsightsBarsGraph';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { NoDataIndicator } from '../../NoDataIndicator/NoDataIndicator';
import { VerticalMoreMenu } from '../../SessionToSessionReportGenerator/VerticalMoreMenu/VerticalMoreMenu';

dayjs.extend(dayjsCustomParseFormat);

const formatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
});

const initialValue = Symbol('iv');

const InsightsTabBase = ({
  userPageLogger,
  alphaWeightedPeak,
  isAlphaPeakGraphSeen,
  setIsAlphaPeakGraphSeen,
  isAlphaPeakBaseline,
}) => {
  const customerInfo = useCustomerInfo();
  const t = useTranslation();

  // const [alphaWeightedPeak] = useFirebaseDB({
  //   path: `trends/${customerInfo.id}/alpha_weighted_peak`,
  //   initialValue,
  //   defaultValue: useRef({}).current,
  // });

  const [patientTags] = useFirebaseDB({
    path: `/sessionMentalTags/patientTags/${customerInfo.id}`,
    initialValue,
    defaultValue: useRef({}).current,
  });

  const [insightItems] = useFirebaseDB({
    path: `insights/${customerInfo.id}/items`,
    initialValue,
    defaultValue: useRef({}).current,
  });

  const [insightsConfig] = useFirebaseDB({
    path: 'insightsConfig',
    initialValue,
  });

  // alpha peak data(graph) will be displayed after this number of sessions:
  // const numOfSessionsThreshold = 10;

  // dots will be shown if the num of sessions is less than this number:
  const numOfSessionsForDotsThreshold = 40;

  const sessionFormattedData = useRef(null);
  const sessionFeedbackIDRef = useRef(null);

  const sessionFeedbackCardRef = useRef(null);
  const sessionFeedbackRef = useRef(null);
  const [height, setHeight] = useState('0px');

  // eslint-disable-next-line
  const [isSessionFeedback, setIsSessionFeedback] = useState(false);
  const [sessionFeedback, setSessionFeedback] = useState(null);

  useEffect(() => {
    if (sessionFeedbackRef.current) {
      if (sessionFeedback && Object.keys(sessionFeedback).length > 0) {
        // Feedback has content, expand to full height
        const fullHeight = sessionFeedbackRef.current.scrollHeight;
        const cardHeight = sessionFeedbackCardRef.current.scrollHeight ?? 0;

        // setHeight(`${fullHeight}px`);
        setHeight(`${cardHeight !== 0 ? cardHeight + 50 : fullHeight}px`);
      } else {
        // Feedback is empty, collapse to 0 height
        setHeight('0px');
      }
    }
  }, [sessionFeedback]);

  const isComponentReadyToRender =
    insightItems !== initialValue && insightsConfig !== initialValue;

  const insights = Object.values(injectIDs({ ...insightItems }))
    .filter(insight => insight.timestamp)
    .sort((insight1, insight2) => insight2.timestamp - insight1.timestamp);

  const insightsGroupedByStartDate = groupBy(insights, insight => {
    return dayjs(insight.timestamp)
      .startOf('month')
      .format(DAY_JS_DATE_FORMATS.american);
  });

  const reformatSessionsData = (originalData, originalPatientTags) => {
    const formattedData = Object.entries(originalData).map(
      ([sessionID, sessionData]) => {
        const sessionFeedbackTags = originalPatientTags[sessionID] || null;
        return {
          sessionID,
          sessionNumber: sessionData.number,
          timestamp: sessionData.timestamp,
          'Alpha Peak': sessionData.smoothed_value,
          Baseline: [sessionData.baseline_start, sessionData.baseline_end],
          sessionFeedbackTags,
        };
      }
    );
    return formattedData;
  };

  const renderAlphaPeakGraph = useCallback(() => {
    const originalSessionsData = alphaWeightedPeak?.sessions;
    // const formattedData = useMemo(() => {
    //   return reformatSessionsData(originalSessionsData, patientTags);
    // }, [alphaWeightedPeak, patientTags]);

    const numOfSessions = Object.keys(alphaWeightedPeak?.sessions).length;

    const formattedData = reformatSessionsData(
      originalSessionsData,
      patientTags
    );

    sessionFormattedData.current = formattedData;

    // eslint-disable-next-line react/prop-types
    const renderTooltip = ({ active, payload }) => {
      // eslint-disable-next-line react/prop-types
      if (active && payload && payload.length) {
        // eslint-disable-next-line react/prop-types
        const { timestamp, 'Alpha Peak': alphaPeak } = payload[0].payload;

        const date = new Date(timestamp);
        const formattedDate = formatter.format(date);
        const formattedAlphaPeak = alphaPeak.toFixed(2);

        return (
          <div className={styles.tooltip_wrapper}>
            <p className={styles.tooltip_date}>{formattedDate}</p>
            <div className={styles.tooltip_alpha_peak_value_container}>
              <div className={styles.tooltip_alpha_peak_value_icon} />
              <p className={styles.tooltip_alpha_peak_value}>
                {formattedAlphaPeak} Hz
              </p>
            </div>
          </div>
        );
      }
      return null;
    };

    // eslint-disable-next-line
    const CustomLegend = ({ payload }) => {
      const orderedPayload = [
        // eslint-disable-next-line react/prop-types
        payload.find(item => item.dataKey === 'Alpha Peak'),
        // eslint-disable-next-line react/prop-types
        payload.find(item => item.dataKey === 'Baseline'),
      ];

      return (
        <ul className={styles.legend_ul}>
          {orderedPayload.map((entry, index) => (
            <li
              key={`item-${entry.value}`}
              className={
                index === 0
                  ? styles.legend_ul_circle_li
                  : styles.legend_ul_rect_li
              }
            >
              <span
                style={{
                  backgroundColor: entry.color,
                }}
                className={
                  index === 0
                    ? styles.legend_ul_circle_li_icon
                    : styles.legend_ul_rect_li_icon
                }
              />
              {entry.value}
            </li>
          ))}
        </ul>
      );
    };

    const min = alphaWeightedPeak.general_stats.min_val;
    const max = alphaWeightedPeak.general_stats.max_val;

    function generateTicks(minVal, maxVal, interval = 0.25, subIntervals = 3) {
      // Round down minVal and round up maxVal to the nearest quarter
      const minTick = Math.floor(minVal * 4) / 4;
      const maxTick = Math.ceil(maxVal * 4) / 4;

      const ticks = [];
      let currentTick = minTick;

      // Generate ticks
      while (currentTick <= maxTick) {
        ticks.push(parseFloat(currentTick.toFixed(3))); // Keep three decimal places for precision
        currentTick += interval / subIntervals;
      }

      // Ensure the maxTick is included if it was not added by the loop
      if (ticks[ticks.length - 1] < maxTick) {
        ticks.push(parseFloat(maxTick.toFixed(3)));
      }

      // Ensure the ticks array is unique and sorted
      return [...new Set(ticks)].sort((a, b) => a - b);
    }

    const ticks = generateTicks(min, max);
    const tickCount = ticks.length;

    const changeIsGraphSeenAndSendEvent = () => {
      if (isAlphaPeakBaseline && !isAlphaPeakGraphSeen) {
        setIsAlphaPeakGraphSeen(true);
        userPageLogger.log('alpha_peak_graph_action', {
          client_id: customerInfo.id,
          action: 'graph_interaction',
          has_baseline_values: isAlphaPeakBaseline,
        });
      }
    };

    const handleOnMouseMove = e => {
      // This onMouseMove handler checks and calls the changeIsGraphSeenAndSendEvent function as needed.
      //
      // The logic for setting the sessionFeedback data is designed to keep the isSessionFeedback state true
      // as long as the mouse is within the graph area. This is due to an issue with the Recharts library
      // that prevents accurate tooltip rendering if the state is updated while the mouse is moving.
      //
      // Attempts to change the isSessionFeedback state to false when there's no data interfere with the
      // active and payload attributes of the chart, disrupting tooltip functionality.
      // setState is the issue somehow. only when set false in the most inner if condition.
      //
      // Therefore, the current implementation is left as is to ensure the tooltip renders correctly.
      //
      // To-do: Consider using setTimeout to delay state updates as a potential solution,
      // but note that switching to the onMouseOver event did not work as of September 23, 2024.

      if (e && e.activeTooltipIndex !== undefined) {
        changeIsGraphSeenAndSendEvent(true);
        if (patientTags !== initialValue && patientTags) {
          const prevActivePoint = sessionFeedbackIDRef.current;
          const currentActivePoint =
            formattedData[e.activeTooltipIndex]?.sessionID;
          if (prevActivePoint !== currentActivePoint) {
            setIsSessionFeedback(true);
            setSessionFeedback(
              formattedData[e.activeTooltipIndex]?.sessionFeedbackTags
            );
            sessionFeedbackIDRef.current = currentActivePoint;
          }
        }
      } else {
        setIsSessionFeedback(false);
        setSessionFeedback({});
        sessionFeedbackIDRef.current = null;
      }
    };

    return (
      <ResponsiveContainer width="100%" height={480}>
        <ComposedChart
          data={formattedData}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 50,
          }}
          onMouseMove={handleOnMouseMove}
          onClick={changeIsGraphSeenAndSendEvent}
          onMouseEnter={changeIsGraphSeenAndSendEvent}
          onMouseLeave={() => {
            changeIsGraphSeenAndSendEvent();
            setIsSessionFeedback(false);
            setSessionFeedback({});
            sessionFeedbackIDRef.current = null;
          }}
        >
          <CartesianGrid
            vertical={false}
            onMouseEnter={changeIsGraphSeenAndSendEvent}
          />
          <XAxis dataKey="timestamp" hide />
          <YAxis
            dataKey="Alpha Peak"
            axisLine={false}
            domain={[ticks[0], ticks[tickCount - 1]]}
            ticks={ticks} // Set the generated tick points
            tickFormatter={value => {
              const tickIndex = ticks.indexOf(value);
              // Customize labels: label only for 1st, 4th, 7th, 10th ticks
              if (tickIndex % 3 === 0) {
                return parseFloat(value.toFixed(2)); // Show the label with 2 decimal places
              }
              return ''; // Hide labels for other ticks
            }}
          />
          <Area
            type="linear"
            dataKey="Baseline"
            fill="#E3D0F6"
            stroke="#E3D0F6"
            strokeWidth={0.1}
            fillOpacity={0.8}
            connectNulls
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
          <Line
            type="linear"
            dataKey="Alpha Peak"
            stroke="#4ddfcf"
            strokeWidth={3}
            connectNulls
            dot={
              numOfSessions < numOfSessionsForDotsThreshold
                ? { fill: '#4ddfcf', strokeWidth: 3 }
                : false
            }
            activeDot={
              numOfSessions < numOfSessionsForDotsThreshold
                ? { stroke: '#4ddfcf', fill: '#4ddfcf', strokeWidth: 4 }
                : false
            }
            isAnimationActive={false}
          />
          <Tooltip
            content={renderTooltip}
            cursor={{ stroke: '#4ddfcf', strokeWidth: 2 }}
          />
          <Legend
            wrapperStyle={{ right: '0', bottom: '30px' }}
            content={<CustomLegend />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }, [alphaWeightedPeak, patientTags]);

  const renderSessionFeedback = () => {
    if (sessionFeedback && Object.keys(sessionFeedback).length > 0) {
      return (
        <div
          className={styles.session_feedback_wrapper}
          ref={sessionFeedbackCardRef}
        >
          <p className={styles.in_this_session_title}>In this session:</p>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Object.values(sessionFeedback?.tags ?? {}).map(el => {
              return (
                <p style={{ margin: '0 5px 0 0' }} key={el.name}>
                  {el.emoji} {el.name}
                </p>
              );
            })}
          </div>
          {sessionFeedback?.notes && (
            <div style={{ marginTop: '5px' }}>
              <p style={{ margin: '0 5px 0 0' }}>
                <span className={styles.in_this_session_title}>Notes: </span>
                {sessionFeedback?.notes ?? ''}
              </p>
            </div>
          )}
        </div>
      );
    }
    return <div />;
  };

  const renderNotEnoughSessions = () => {
    return (
      <>
        <NoDataIndicator />
        <p style={{ textAlign: 'center' }}>
          Data will be displayed after client completes 10 eyes-open, clean
          sessions.
        </p>
      </>
    );
  };

  const getCSVDataArray = () => {
    const firstRow = [
      'Session number',
      'Session date',
      'Smoothed alpha peak value',
      'Baseline range start',
      'Baseline range end',
    ];

    const rows = sessionFormattedData.current.map(obj => {
      const sessionDate = new Date(obj.timestamp);
      return [
        obj.sessionNumber,
        `"${formatter.format(sessionDate)}"`, // wrapping date in " " to enclose
        parseFloat(obj['Alpha Peak'].toFixed(2)),
        parseFloat(obj.Baseline[0].toFixed(2)),
        parseFloat(obj.Baseline[1].toFixed(2)),
      ];
    });

    const allRows = [firstRow, ...rows];
    return allRows;
  };

  const verticalMoreMenuItems = [
    {
      text: 'Export CSV',
      onClick: () => {
        // eslint-disable-next-line
        showPageLoader();
        const CSVArray = getCSVDataArray();
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const clientInfo = JSON.parse(sessionStorage.customerInfo);
        const clientEmail = clientInfo?.email ?? '';
        const CSVFileName = `${clientEmail}_${day}_${month}_${year}_alpha_peak`;
        // setTimeout(() => {
        // eslint-disable-next-line
        hideLoading();
        convertToCSVAndDownload(CSVArray, CSVFileName);
        userPageLogger.log('alpha_peak_graph_action', {
          client_id: customerInfo.id,
          action: 'Csv_export',
          has_baseline_values: isAlphaPeakBaseline,
        });
        // }, 500);
      },
      key: 'csv',
    },
  ];

  const renderInsightItem = insightItem => {
    const insightItemDetails = getInsightItemDetails({
      insightItem,
      insightsConfig,
    });

    if (insightItemDetails) {
      const {
        titleParts,
        message,
        graphData,
        protocolName,
        date,
        icon,
        unit,
        iconWidth,
        iconTopMargin,
        yAxisDashboard,
        valueColor,
        isGraphHidden,
      } = insightItemDetails;

      return (
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              expanded: styles.expansion_panel_expanded,
              content: styles.expansion_panel_summary_content,
            }}
          >
            <div className={styles.insight_overview}>
              <div className={styles.icon_container}>
                <img
                  src={icon}
                  style={{ width: iconWidth, marginTop: iconTopMargin }}
                />
              </div>
              <div>
                <p className={styles.insight_title}>
                  {titleParts[0]} -{' '}
                  <span style={{ color: valueColor || 'initial' }}>
                    {titleParts[1]}
                  </span>
                </p>
                <p className={styles.insight_message}>{message}</p>
              </div>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <InsightsBarsGraph
              data={graphData}
              yAxisLabel={yAxisDashboard}
              {...{
                protocolName,
                date,
                unit,
                isGraphHidden,
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
    return null;
  };

  return isComponentReadyToRender ? (
    <>
      <Paper
        className={classNames(
          styles.paper
          // styles.alpha_peak_graph_paper,
          // sessionFeedback && Object.keys(sessionFeedback).length > 0
          //   ? styles.after_expansion
          //   : ''
        )}
      >
        <div className={styles.graph_paper_header_wrapper}>
          <p className={styles.paper_header}>
            {t('alpha-peak')}{' '}
            {isAlphaPeakBaseline && !isAlphaPeakGraphSeen && (
              <span
                style={{
                  color: 'red',
                  position: 'relative',
                  top: -2,
                  fontSize: '0.7em',
                }}
              >
                ⬤
              </span>
            )}
          </p>
          {isAlphaPeakBaseline && (
            <div style={{ marginBottom: '20px' }}>
              <VerticalMoreMenu menuItems={verticalMoreMenuItems} />
            </div>
          )}
        </div>
        <p>
          {t('alpha-peak-description')}{' '}
          <a
            href="https://www.myndlift.com/post/announcing-alpha-peak-your-brain-performance-tracker"
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
          >
            Learn more.
          </a>
        </p>

        {// eslint-disable-next-line
        patientTags !== initialValue ? (
          // alphaWeightedPeak && Object.keys(alphaWeightedPeak?.sessions ?? {}).length >= numOfSessionsThreshold
          alphaWeightedPeak && isAlphaPeakBaseline ? (
            <>
              {renderAlphaPeakGraph()}
              <div
                className={styles.session_feedback_container}
                ref={sessionFeedbackRef}
                id="session-feedback"
                style={{
                  // eslint-disable-next-line
                  height: height,
                  transition: 'height 0.5s ease-in-out', // Smooth height transition
                  overflow: 'hidden', // Hide content during transition
                }}
              >
                {renderSessionFeedback()}
              </div>
            </>
          ) : (
            renderNotEnoughSessions()
          )
        ) : (
          <Loader />
        )}
      </Paper>
      <Paper className={styles.paper}>
        <p className={styles.paper_header}>{capitalize(t('insights'))}</p>
        <p>
          {Object.entries(insightsGroupedByStartDate).length === 0
            ? t('insights-empty-description')
            : t('insights-description')}
        </p>
        {Object.entries(insightsGroupedByStartDate).length !== 0 && (
          <div className={styles.insights_release_warning}>
            <CustomAlert severity="info">
              {t('insights-can-be-added-to-report')}
            </CustomAlert>
          </div>
        )}
      </Paper>
      {Object.entries(insightsGroupedByStartDate).map(([startDate, items]) => {
        const endDate = dayjs(startDate, DAY_JS_DATE_FORMATS.american)
          .endOf('month')
          .format(DAY_JS_DATE_FORMATS.american);

        return (
          items.some(item =>
            getInsightItemDetails({
              insightItem: item,
              insightsConfig,
            })
          ) && (
            <div key={startDate}>
              <p className={styles.date_header}>
                {startDate} - {endDate}
              </p>
              <div className={styles.insights}>
                {items.map(insightItem => (
                  <div key={insightItem.id}>
                    {renderInsightItem(insightItem)}
                  </div>
                ))}
              </div>
            </div>
          )
        );
      })}
    </>
  ) : (
    <Loader />
  );
};

InsightsTabBase.propTypes = exact({
  userPageLogger: PropTypes.object,
  alphaWeightedPeak: PropTypes.object,
  isAlphaPeakGraphSeen: PropTypes.bool,
  setIsAlphaPeakGraphSeen: PropTypes.func,
  isAlphaPeakBaseline: PropTypes.bool,
});

export const InsightsTab = React.memo(InsightsTabBase);
InsightsTab.displayName = 'InsightsTab';

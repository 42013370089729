import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import placeHolder from '../../../../assets/add-photo.png';
import { LagoListItem } from '../LagoListItem/LagoListItem';
import styles from './LagoCollectionJournalDialog.scss';

const LagoCollectionJournalDialogBase = ({
  open,
  onClose,
  journalCollectionData,
  setJournalCollectionEditDialogOpen,
  screensItemsData,
  onItemClicked,
  onEditItemClicked,
  onAddNewVariantClicked,
  editVariant,
  variantClicked,
  setKeyToDelete,
  setDisplayDeleteDialog,
  setJournalCollectionData,
  handleOnDragEnd,
  // setDisplayUploadedImages,
  isLoadingVideo,
  publishJournalClicked,
  parseDate,
  unpublishJournalClicked,
  updateLastEditJournal,
  setIsFromJournalCollectionEdit,
  selectCategoryClicked,
  categories,
}) => {
  const [toggleEditTitle, setToggleEditTitle] = useState(false);
  const [titleInput, setTitleInput] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [toggleAllowFeedback, setToggleAllowFeedback] = useState(false);
  const [journalType, setJournalType] = useState('');

  useEffect(() => {
    if (journalCollectionData && journalCollectionData.allowFeedback) {
      setToggleAllowFeedback(journalCollectionData.allowFeedback);
    }
    if (journalCollectionData && journalCollectionData.journalType) {
      setJournalType(journalCollectionData.journalType);
    }
  }, [journalCollectionData]);

  const toggleAllowFeedbackClicked = value => {
    setToggleAllowFeedback(value);
    const copy = JSON.parse(JSON.stringify(journalCollectionData));
    copy.allowFeedback = value;
    setJournalCollectionData(copy);
    updateLastEditJournal();
  };

  const journalTypeChange = value => {
    setJournalType(value);
    const copy = JSON.parse(JSON.stringify(journalCollectionData));
    copy.journalType = value;
    setJournalCollectionData(copy);
    updateLastEditJournal();
  };

  const IMAGE_PATH_PREFIX = 'lago/educational/defaults/images/';

  const onImageFileChange = async event => {
    setIsLoadingImage(true);
    const path =
      IMAGE_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const [file] = event.target.files;
    // setImageFile(file);
    const storage = window.firebase.storage();
    const imageRef = storage.ref(path);
    try {
      await imageRef.put(file);
      imageRef
        .getDownloadURL()
        .then(res => {
          const copy = JSON.parse(JSON.stringify(journalCollectionData));
          copy.img = res;
          setJournalCollectionData(copy);
          setIsLoadingImage(false);
          updateLastEditJournal();
        })
        .catch(() => {
          setIsLoadingImage(false);
        });
    } catch (err) {
      // TODO
    }
  };

  const removeImage = () => {
    const copy = JSON.parse(JSON.stringify(journalCollectionData));
    copy.img = '';
    setJournalCollectionData(copy);
    const el = document.getElementById('image-input-journal-collection');
    el.value = '';
    updateLastEditJournal();
  };

  const saveNewTitle = () => {
    const copy = JSON.parse(JSON.stringify(journalCollectionData));
    copy.title.en = titleInput;
    setJournalCollectionData(copy);
    setToggleEditTitle(false);
    updateLastEditJournal();
  };

  let sortedArticleScreens = [];
  if (journalCollectionData && journalCollectionData.screens) {
    sortedArticleScreens = Object.keys(journalCollectionData.screens).sort(
      (a, b) => {
        return (
          journalCollectionData.screens[a] - journalCollectionData.screens[b]
        );
      }
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={styles.lago_dialog_container}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {toggleEditTitle ? (
          <div>
            <TextField
              value={titleInput}
              onChange={e => {
                setTitleInput(e.target.value);
              }}
            />
            <Button onClick={saveNewTitle} disabled={!titleInput}>
              <SaveIcon />
            </Button>
            <Button
              onClick={() => {
                setToggleEditTitle(false);
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        ) : (
          <div>
            {journalCollectionData &&
              journalCollectionData.title &&
              journalCollectionData.title.en}
            <Button
              onClick={() => {
                setTitleInput(journalCollectionData.title.en);
                setToggleEditTitle(true);
              }}
            >
              <EditIcon />
            </Button>
          </div>
        )}
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={selectCategoryClicked}
              variant="contained"
              color="primary"
              style={{ width: 200, marginBottom: 5, marginTop: 10 }}
            >
              select category
            </Button>
            {journalCollectionData && journalCollectionData.categoryIds && (
              <div>
                <div>Selected categories:</div>
                <div>
                  {journalCollectionData.categoryIds.map(categoryId => {
                    return (
                      categories[categoryId] && (
                        <div>{categories[categoryId].name.en}</div>
                      )
                    );
                  })}
                </div>
              </div>
            )}
            <Button
              variant="contained"
              color="secondary"
              style={{ width: 200, marginBottom: 5, marginTop: 10 }}
              onClick={publishJournalClicked}
              // disabled={
              //   (journalCollectionData && !journalCollectionData.screens) ||
              //   (journalCollectionData &&
              //     !Object.keys(journalCollectionData.screens).length)
              // }
              disabled={
                journalCollectionData &&
                (!journalCollectionData.categoryIds ||
                  !journalCollectionData.categoryIds.length)
              }
            >
              publish
            </Button>
            {journalCollectionData && journalCollectionData.lastPublishDate ? (
              <Button
                variant="contained"
                color="secondary"
                style={{ marginBottom: 5, width: 200, marginTop: 10 }}
                onClick={unpublishJournalClicked}
              >
                unpublish
              </Button>
            ) : null}
          </div>
          {journalCollectionData &&
          (!journalCollectionData.lastPublishDate ||
            journalCollectionData.lastPublishDate <
              journalCollectionData.lastEdited) ? (
            <div>You have unpublished changes</div>
          ) : null}
          {journalCollectionData && journalCollectionData.lastPublishDate ? (
            <div>
              Last published: {parseDate(journalCollectionData.lastPublishDate)}
            </div>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.journal_dialog_container}>
          <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId={screensItemsData}>
                {provided => (
                  <div
                    // className={styles.lago_control_list_container}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // style={{ width: '50%' }}
                  >
                    {journalCollectionData &&
                      journalCollectionData.screens &&
                      sortedArticleScreens.map((screenId, index) => {
                        return (
                          <Draggable
                            key={screenId}
                            draggableId={screenId}
                            index={
                              journalCollectionData &&
                              journalCollectionData.screens &&
                              journalCollectionData.screens[screenId]
                                ? journalCollectionData.screens[screenId]
                                : index
                            }
                          >
                            {provided1 => (
                              <div
                                ref={provided1.innerRef}
                                {...provided1.draggableProps}
                                {...provided1.dragHandleProps}
                              >
                                <LagoListItem
                                  key={screenId}
                                  element={screensItemsData[screenId]}
                                  isFromArticle
                                  onItemClicked={() => onItemClicked(screenId)}
                                  onEditItemClicked={onEditItemClicked}
                                  onAddNewVariantClicked={e => {
                                    onAddNewVariantClicked(e, screenId);
                                    setIsFromJournalCollectionEdit(true);
                                  }}
                                  editVariant={editVariant}
                                  variantClicked={variantClicked}
                                  onDeleteItemClicked={e => {
                                    e.stopPropagation();
                                    setKeyToDelete(screenId);
                                    setDisplayDeleteDialog(true);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isLoadingVideo ? <CircularProgress /> : null}
            <Button
              onClick={setJournalCollectionEditDialogOpen}
              disabled={isLoadingVideo}
            >
              add screen
            </Button>
          </div>

          <div className={styles.upload_image_container}>
            {isLoadingImage ? (
              <CircularProgress />
            ) : (
              <img
                className={styles.lago_control_img}
                src={
                  journalCollectionData && journalCollectionData.img
                    ? journalCollectionData.img
                    : placeHolder
                }
                alt="placeHolder"
              />
            )}
            <div className={styles.upload_image_btns}>
              <Button
                onClick={() => {
                  document
                    .getElementById('image-input-journal-collection')
                    .click();
                }}
                color="primary"
                variant="contained"
                style={{ maxWidth: '120px' }}
                disabled={isLoadingVideo}
              >
                upload image
              </Button>
              {/* <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px', maxWidth: '120px' }}
                disabled={isLoadingVideo}
              >
                select uploaded
              </Button> */}
              {journalCollectionData && journalCollectionData.img ? (
                <Button
                  onClick={() => {
                    removeImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                  disabled={isLoadingVideo}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        {journalType === 'journal' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>Allow provider feedback</div>
            <Switch
              color="primary"
              checked={toggleAllowFeedback}
              onChange={e => {
                toggleAllowFeedbackClicked(e.target.checked);
              }}
            />
          </div>
        ) : null}
        <Select
          value={journalType}
          onChange={e => journalTypeChange(e.target.value)}
        >
          <MenuItem value="exercise">Exercise</MenuItem>
          <MenuItem value="journal">Journal</MenuItem>
        </Select>
        <input
          accept="image/png, image/gif, image/jpeg"
          hidden
          id="image-input-journal-collection"
          type="file"
          onChange={onImageFileChange}
          style={{ display: 'none' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          size="large"
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          disabled={isLoadingVideo}
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoCollectionJournalDialogBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  journalCollectionData: PropTypes.object,
  setJournalCollectionEditDialogOpen: PropTypes.func,
  screensItemsData: PropTypes.object,
  onItemClicked: PropTypes.func,
  onEditItemClicked: PropTypes.func,
  onAddNewVariantClicked: PropTypes.func,
  editVariant: PropTypes.func,
  variantClicked: PropTypes.func,
  setKeyToDelete: PropTypes.func,
  setDisplayDeleteDialog: PropTypes.func,
  setJournalCollectionData: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  // setDisplayUploadedImages: PropTypes.func,
  isLoadingVideo: PropTypes.bool,
  publishJournalClicked: PropTypes.func,
  parseDate: PropTypes.func,
  unpublishJournalClicked: PropTypes.func,
  updateLastEditJournal: PropTypes.func,
  setIsFromJournalCollectionEdit: PropTypes.func,
  selectCategoryClicked: PropTypes.func,
  categories: PropTypes.object,
});

export const LagoCollectionJournalDialog = React.memo(
  LagoCollectionJournalDialogBase
);
LagoCollectionJournalDialog.displayName = 'LagoCollectionJournalDialog';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import styles from './BlurOverlayIfContinuityCustomer.scss';
import { SwitchToNormalHeadsetDialogHooker } from '../../SwitchToNormalHeadsetDialogHooker/SwitchToNormalHeadsetDialogHooker';

const BlurOverlayIfContinuityCustomerBase = ({
  children,
  headsetDetails,
  onHeadsetDetailsUpdated,
}) => {
  const [isSwitchCompleted, setIsSwitchCompleted] = useState(false);
  const [switchToNormalNodeBtnRef, setSwitchToNormalNodeBtnRef] = useState({
    current: null,
  });

  const shouldBlur = headsetDetails.isDispatched && !isSwitchCompleted;
  return (
    <>
      <div className={styles.root}>
        <div className={classNames({ [styles.blurred_content]: shouldBlur })}>
          {children}
        </div>
        {shouldBlur && (
          <>
            <div className={styles.overlay} />
            <div className={styles.overlay_content}>
              {' '}
              <p>
                This user is currently using headset{' '}
                {headsetDetails.lastUsedHeadsetId || 'unknown-headset'} which is
                in Continuity Mode.
              </p>
              <p>To view their data, switch the headset back to Normal Mode.</p>{' '}
              <Button
                variant="contained"
                color="primary"
                buttonRef={ref => {
                  if (switchToNormalNodeBtnRef.current === null && ref) {
                    setSwitchToNormalNodeBtnRef({ current: ref });
                  }
                }}
              >
                <span className={styles.btn}>SWITCH TO NORMAL MODE</span>
              </Button>
            </div>
          </>
        )}
      </div>

      {shouldBlur && switchToNormalNodeBtnRef.current && (
        <SwitchToNormalHeadsetDialogHooker
          buttonRef={switchToNormalNodeBtnRef}
          headsetDetails={headsetDetails}
          onSwitchCompleted={() => {
            setIsSwitchCompleted(true);
            onHeadsetDetailsUpdated();
          }}
        />
      )}
    </>
  );
};

BlurOverlayIfContinuityCustomerBase.propTypes = exact({
  children: PropTypes.node.isRequired,
  headsetDetails: PropTypes.object.isRequired,
  onHeadsetDetailsUpdated: PropTypes.func.isRequired,
});

export const BlurOverlayIfContinuityCustomer = React.memo(
  BlurOverlayIfContinuityCustomerBase
);
BlurOverlayIfContinuityCustomer.displayName = 'BlurOverlayIfContinuityCustomer';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import styles from './ModalFooter.scss';

const ModalFooterBase = ({
  leftContent,
  primaryBtnContent,
  secondaryBtnContent,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  isPrimaryBtnDisabled,
  isSecondaryBtnDisabled,
  primaryBtnProps,
  customClasses,
}) => (
  <div className={styles.modal_footer}>
    {leftContent && (
      <div className={styles.modal_footer_left_side}>{leftContent}</div>
    )}

    <div className={styles.modal_footer_right_side}>
      {secondaryBtnContent && (
        <Button
          className={classNames(
            styles.secondary_button,
            customClasses.secondary_button
          )}
          onClick={onSecondaryBtnClick}
          disabled={isSecondaryBtnDisabled}
          variant="text"
          color="default"
        >
          {secondaryBtnContent}
        </Button>
      )}

      {primaryBtnContent && (
        <Button
          variant="contained"
          className={styles.primary_button}
          color="primary"
          onClick={onPrimaryBtnClick}
          disabled={isPrimaryBtnDisabled}
          {...primaryBtnProps}
        >
          {primaryBtnContent}
        </Button>
      )}
    </div>
  </div>
);

ModalFooterBase.propTypes = {
  leftContent: PropTypes.node,
  primaryBtnContent: PropTypes.node,
  secondaryBtnContent: PropTypes.node,
  onPrimaryBtnClick: PropTypes.func,
  onSecondaryBtnClick: PropTypes.func,
  isPrimaryBtnDisabled: PropTypes.bool,
  isSecondaryBtnDisabled: PropTypes.bool,
  primaryBtnProps: PropTypes.object,
  customClasses: PropTypes.object,
};

ModalFooterBase.defaultProps = {
  leftContent: null,
  primaryBtnContent: null,
  secondaryBtnContent: null,
  onPrimaryBtnClick: () => {},
  onSecondaryBtnClick: () => {},
  primaryBtnProps: {},
  customClasses: {},
};

export const ModalFooter = ModalFooterBase;

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import cloneDeep from 'lodash/cloneDeep';
import {
  Paper,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  withStyles,
  RootRef,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/Publish';
import { Loader } from '../../../Core/Components/Loader/Loader';
import styles from './HeadsetsUsageDetails.scss';
import { paragraphFontSize } from '../../../cssInJs/constants';
import { CustomTable } from '../../../Core/Components/CustomTable/CustomTable';
import { UsageDetailsPerUserDialog } from './UsageDetailsPerUserDialog/UsageDetailsPerUserDialog';
import { formatSecondsToMMSS } from '../../../utils/utils';
import { ContinuityChip } from '../../ContinuityChip/ContinuityChip';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const stylesFn = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 175,
  },
  inputLabelRoot: {
    fontSize: paragraphFontSize,
  },
  selectRoot: {
    fontSize: paragraphFontSize,
    lineHeight: 'initial',
  },
});

const HeadsetsUsageDetailsBase = ({
  selectedBillingCycle,
  billingCycles,
  onBillingCycleChange,
  classes,
  clinicNonfPlan,
}) => {
  const t = useTranslation();
  const [
    headsetIdForUsageDetailsPerUserDialog,
    setHeadsetIdForUsageDetailsPerUserDialog,
  ] = useState(null);

  const onUsageDetailsPerUserDialogClose = useCallback(() => {
    setHeadsetIdForUsageDetailsPerUserDialog(null);
  }, []);

  const [billingInputLabelWidth, setBillingInputLabelWidth] = useState(0);

  const [nonfUsersInfos, setNonfUsersInfos] = useState(null);

  useEffect(() => {
    if (selectedBillingCycle && selectedBillingCycle.nfUsers) {
      const customersInfos = {};
      Object.keys(selectedBillingCycle.nfUsers).forEach(userId => {
        customersInfos[userId] = selectedBillingCycle.nfUsers[userId];
      });
      setNonfUsersInfos(customersInfos);
    }
  }, [selectedBillingCycle]);

  const numberOfIncludedHeadsets = selectedBillingCycle.headsetsIncluded;
  const extraHeadsetUsagePrice = selectedBillingCycle.price;

  const headsets =
    (selectedBillingCycle.headsets &&
      Object.keys(selectedBillingCycle.headsets).map(headsetKey => ({
        id: headsetKey,
        ...selectedBillingCycle.headsets[headsetKey],
      }))) ||
    [];

  const sortedHeadsets = cloneDeep(headsets).sort(
    (headset1, headset2) => headset2.isActive - headset1.isActive
  );

  const getHeadsetFee = ({ headset, headsetIndex }) => {
    if (headset.isActive) {
      return headsetIndex >= numberOfIncludedHeadsets
        ? extraHeadsetUsagePrice
        : 'included';
    }

    return 0;
  };

  const getFormattedHeadsetFee = ({ headset, headsetIndex }) => {
    return typeof getHeadsetFee({ headset, headsetIndex }) === 'number'
      ? `$${getHeadsetFee({ headset, headsetIndex })}`
      : getHeadsetFee({ headset, headsetIndex });
  };

  const rawTableHeads = ['Headset Serial Number', 'Minutes Used', 'Fee'];

  const tableHeads = [...rawTableHeads, 'Usage Details'].map(tableHeadText => ({
    node: <span className={styles.head_cell}>{tableHeadText}</span>,
    id: tableHeadText,
  }));

  const onSeeDetailsClick = useCallback(
    headsetId => setHeadsetIdForUsageDetailsPerUserDialog(headsetId),
    []
  );

  const columnsIds = [
    'headsetSerialNumber',
    'minutesUsed',
    'fee',
    'usageDetails',
  ];
  const getRawTableRows = ({ isExportingForCSV }) =>
    sortedHeadsets.map((headset, headsetIndex) => [
      isExportingForCSV ? (
        headset.id
      ) : (
        <>
          <span>{headset.id}</span>
          {headset.isDispatched && (
            <span style={{ marginLeft: 40 }}>
              <ContinuityChip />
            </span>
          )}
        </>
      ),
      formatSecondsToMMSS({
        seconds: Object.values(headset.users || {}).reduce(
          (acc, headsetUsage) => acc + (headsetUsage.totalDuration || 0),
          0
        ),
        shouldShowSeconds: false,
      }),
      getFormattedHeadsetFee({ headset, headsetIndex }),
    ]);

  const rawTableRows = getRawTableRows({ isExportingForCSV: false });
  const rawTableRowsCSV = getRawTableRows({ isExportingForCSV: true });

  const pushAndReturnArr = (arr, element) => {
    arr.push(element);
    return arr;
  };

  const tableRows = sortedHeadsets.map((headset, headsetIndex) => ({
    id: headset.id,
    cells: pushAndReturnArr(
      rawTableRows[headsetIndex].map(cellContent => (
        <span className={styles.cell}>{cellContent}</span>
      )),
      <a
        onClick={() => onSeeDetailsClick(headset.id)}
        role="button"
        tabIndex={0}
      >
        <span className={styles.cell}>{t('see-details')}</span>
      </a>
    ).map((node, index) => ({
      id: columnsIds[index],
      node,
    })),
  }));

  const formattedSelectedBillingCycle =
    selectedBillingCycle.id &&
    billingCycles.find(
      billingCycle => billingCycle.id === selectedBillingCycle.id
    ).label;

  const onDownloadAsCSV = useCallback(() => {
    convertToCSVAndDownload(
      [rawTableHeads].concat(rawTableRowsCSV),
      `billing-${formattedSelectedBillingCycle.split('/').join('_')}`
    );
  }, [rawTableHeads, rawTableRowsCSV, formattedSelectedBillingCycle]);

  const onDownloadAsCSVNonf = (tableHeadsNonf, tableRowsNonf) => {
    convertToCSVAndDownload(
      [tableHeadsNonf].concat(tableRowsNonf),
      `billing-${formattedSelectedBillingCycle.split('/').join('_')}`
    );
  };

  const renderTable = () => {
    if (selectedBillingCycle && selectedBillingCycle.nfUsers) {
      const tableHeadsNonf = [
        {
          id: 'Client Name',
          node: <span className={styles.head_cell}>Client Name</span>,
        },
        {
          id: 'Client Email',
          node: <span className={styles.head_cell}>Client Email</span>,
        },
        {
          id: 'Minutes of Training',
          node: <span className={styles.head_cell}>Minutes of Training</span>,
        },
        {
          id: 'Fee',
          node: <span className={styles.head_cell}>Fee</span>,
        },
      ];

      const tableRowsNonf = Object.keys(selectedBillingCycle.nfUsers).map(
        nfUserId => {
          return {
            cells: [
              {
                id: 'Client Name',
                node: (
                  <span className={styles.cell}>
                    <a
                      onClick={async () => {
                        try {
                          const userInfo = await database
                            .ref(`userInfo/${nfUserId}`)
                            .once('value');
                          if (userInfo.val()) {
                            window.open(
                              `userPage.html?customerId=${nfUserId}`,
                              '_blank'
                            );
                          }
                        } catch (e) {
                          showNotification('danger', 'This user was deleted');
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {nonfUsersInfos &&
                        nonfUsersInfos[nfUserId] &&
                        nonfUsersInfos[nfUserId].name}
                    </a>
                  </span>
                ),
              },
              {
                id: 'Client Email',
                node: (
                  <span className={styles.cell}>
                    {nonfUsersInfos &&
                      nonfUsersInfos[nfUserId] &&
                      nonfUsersInfos[nfUserId].email}
                  </span>
                ),
              },
              {
                id: 'Minutes of Training',
                node: (
                  <span className={styles.cell}>
                    {Math.round(
                      selectedBillingCycle.nfUsers[nfUserId].secondsOfNF / 60
                    )}
                  </span>
                ),
              },
              {
                id: 'Fee',
                node: (
                  <span className={styles.cell}>
                    {selectedBillingCycle.nfUsers[nfUserId]
                      .proratedAmountToPay || 0}
                  </span>
                ),
              },
            ],
            id: nfUserId,
          };
        }
      );

      const tableRowsNonfCsv = Object.keys(selectedBillingCycle.nfUsers).map(
        nfUserId => {
          return [
            nonfUsersInfos &&
              nonfUsersInfos[nfUserId] &&
              nonfUsersInfos[nfUserId].name,
            nonfUsersInfos &&
              nonfUsersInfos[nfUserId] &&
              nonfUsersInfos[nfUserId].email,
            Math.round(selectedBillingCycle.nfUsers[nfUserId].secondsOfNF / 60),
            selectedBillingCycle.nfUsers[nfUserId].proratedAmountToPay || 0,
          ];
        }
      );

      return (
        <div>
          <div className={styles.table_container}>
            <CustomTable
              tableHeads={tableHeadsNonf}
              tableRows={tableRowsNonf}
            />
          </div>
          <div
            className={styles.download_as_csv_container}
            onClick={() =>
              onDownloadAsCSVNonf(
                tableHeadsNonf.map(tableHead => tableHead.id),
                tableRowsNonfCsv
              )
            }
          >
            <DownloadIcon transform="scale(-1,-1)" />
            <a role="button" tabIndex={0}>
              {t('download-as-csv')}
            </a>
          </div>
        </div>
      );
    }

    return selectedBillingCycle.headsets ? (
      <React.Fragment>
        <div className={styles.table_container}>
          <CustomTable tableHeads={tableHeads} tableRows={tableRows} />
        </div>
        <div
          className={styles.download_as_csv_container}
          onClick={onDownloadAsCSV}
        >
          <DownloadIcon transform="scale(-1,-1)" />
          <a role="button" tabIndex={0}>
            {t('download-as-csv')}
          </a>
        </div>
      </React.Fragment>
    ) : (
      <div className={styles.no_usage_data}>
        <p>{t('no-headset-usage-detected')}</p>
      </div>
    );
  };

  return selectedBillingCycle && billingCycles ? (
    <React.Fragment>
      <Paper>
        <div>
          <div className={styles.header}>
            <div>
              <h3>
                {clinicNonfPlan
                  ? 'Neuro Client Usage Details'
                  : t('headset-usage-details')}
              </h3>
              <p>
                {clinicNonfPlan
                  ? 'Here you can track the usage by neuro clients during the current billing cycle:'
                  : t('headset-usage-details-description')}
              </p>
            </div>
            <div>
              <FormControl variant="outlined" className={classes.formControl}>
                <RootRef
                  rootRef={elm => {
                    if (elm !== null) {
                      setBillingInputLabelWidth(elm.offsetWidth);
                    }
                  }}
                >
                  <InputLabel
                    htmlFor="select-sessions-slices"
                    classes={{ root: classes.inputLabelRoot }}
                  >
                    {t('billing-cycle')}
                  </InputLabel>
                </RootRef>

                <Select
                  native
                  value={selectedBillingCycle.id}
                  onChange={onBillingCycleChange}
                  input={<OutlinedInput labelWidth={billingInputLabelWidth} />}
                  classes={{
                    root: classes.selectRoot,
                  }}
                >
                  {billingCycles.map(billingCycle => (
                    <option value={billingCycle.id} key={billingCycle.id}>
                      {billingCycle.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {renderTable()}
        </div>
      </Paper>

      <UsageDetailsPerUserDialog
        isOpen={headsetIdForUsageDetailsPerUserDialog !== null}
        headsetId={headsetIdForUsageDetailsPerUserDialog}
        headsetUsageDetails={
          headsetIdForUsageDetailsPerUserDialog &&
          selectedBillingCycle.headsets[headsetIdForUsageDetailsPerUserDialog]
        }
        onClose={onUsageDetailsPerUserDialogClose}
        onConfirm={onUsageDetailsPerUserDialogClose}
        billingCycle={formattedSelectedBillingCycle}
      />
    </React.Fragment>
  ) : (
    <Loader />
  );
};

HeadsetsUsageDetailsBase.propTypes = exact({
  selectedBillingCycle: PropTypes.object.isRequired,
  billingCycles: PropTypes.array.isRequired,
  onBillingCycleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinicNonfPlan: PropTypes.object,
});

export const HeadsetsUsageDetails = React.memo(
  withStyles(stylesFn)(HeadsetsUsageDetailsBase)
);

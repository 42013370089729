exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TeachableIntegrationSignup_dialog_header{font-size:20px;font-weight:900;margin-bottom:10px}.TeachableIntegrationSignup_dialog_header{font-size:16px}.TeachableIntegrationSignup_form_helper_text_error{font-size:10px !important;margin-left:20px !important}.TeachableIntegrationSignup_dialog_input_container{margin-top:20px}.TeachableIntegrationSignup_buttons_container{display:flex;justify-content:flex-end}.TeachableIntegrationSignup_dialog_button{font-size:14px !important;margin-left:10px !important}.TeachableIntegrationSignup_dialog_success_button{font-size:14px !important}.TeachableIntegrationSignup_succsess_message_container{display:flex;flex-direction:column;justify-content:center;align-items:center}.TeachableIntegrationSignup_resize{font-size:50px !important;width:200px !important}@media only screen and (max-width: 768px){.TeachableIntegrationSignup_dialog_input{position:relative;left:-20px}.TeachableIntegrationSignup_form_helper_text_error{position:relative;left:-20px}}\n", ""]);

// Exports
exports.locals = {
	"dialog_header": "TeachableIntegrationSignup_dialog_header",
	"form_helper_text_error": "TeachableIntegrationSignup_form_helper_text_error",
	"dialog_input_container": "TeachableIntegrationSignup_dialog_input_container",
	"buttons_container": "TeachableIntegrationSignup_buttons_container",
	"dialog_button": "TeachableIntegrationSignup_dialog_button",
	"dialog_success_button": "TeachableIntegrationSignup_dialog_success_button",
	"succsess_message_container": "TeachableIntegrationSignup_succsess_message_container",
	"resize": "TeachableIntegrationSignup_resize",
	"dialog_input": "TeachableIntegrationSignup_dialog_input"
};
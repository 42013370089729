exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CallLogs_container{position:relative}.CallLogs_info_row{display:flex;justify-content:space-between;padding-right:18px}.CallLogs_info_row_tooltip{display:flex;justify-content:space-between}.CallLogs_divider{margin:10px 0;border:solid 0.5px}.CallLogs_tooltip_container{background-color:white;width:250px;padding:0 15px 15px 15px;position:absolute;z-index:1;box-shadow:2px 2px 2px 0 rgba(0,0,0,0.16);right:18px;border-radius:5px}.CallLogs_hoverable{color:#2798f3;font-weight:900}.CallLogs_tooltip_arrow{width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-bottom:15px solid white;position:relative;top:-15px;left:190px}.CallLogs_title{font-weight:900;font-size:15px;margin-bottom:10px;color:black}.CallLogs_blocker{position:fixed;top:0;left:0;bottom:0;right:0;content:' '}\n", ""]);

// Exports
exports.locals = {
	"container": "CallLogs_container",
	"info_row": "CallLogs_info_row",
	"info_row_tooltip": "CallLogs_info_row_tooltip",
	"divider": "CallLogs_divider",
	"tooltip_container": "CallLogs_tooltip_container",
	"hoverable": "CallLogs_hoverable",
	"tooltip_arrow": "CallLogs_tooltip_arrow",
	"title": "CallLogs_title",
	"blocker": "CallLogs_blocker"
};
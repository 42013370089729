import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import { SwingleCheck } from '../../SwingleChecks/SwingleCheck/SwingleCheck';
import styles from './AssessmentNeuromarkersChecks.scss';

// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';

const AssessmentNeuromarkersChecksBase = ({
  preNeuromarkersChecksResults,
  postNeuromarkersChecksResults,
  neuromarkersChecks,
  isInPreview,
  isSomeDataHidden,
  preAssessment,
  postAssessment,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  noDataIndicator,
  commonSwingleChecksResultsKeys,
  ageBin,
}) => {
  const preRawResultsById = {};
  const preNoiseResultsById = {};
  const preZScoreResultsById = {};

  const postRawResultsById = {};
  const postNoiseResultsById = {};
  const postZScoreResultsById = {};

  commonSwingleChecksResultsKeys.forEach(id => {
    preRawResultsById[id] = preNeuromarkersChecksResults[id].raw;
    preNoiseResultsById[id] = preNeuromarkersChecksResults[id].noise;
    preZScoreResultsById[id] = preNeuromarkersChecksResults[id].z_score;
    postRawResultsById[id] = postNeuromarkersChecksResults[id].raw;
    postNoiseResultsById[id] = postNeuromarkersChecksResults[id].noise;
    postZScoreResultsById[id] = postNeuromarkersChecksResults[id].z_score;
  });

  // const t = useTranslation();
  return (
    <AssessmentComparisonGraph
      {...{
        isInPreview,
        isSomeDataHidden,
        preAssessment,
        postAssessment,
        onPreAssessmentChange,
        onPostAssessmentChange,
        assessmentOptions,
        preAssessmentTimestamp,
        postAssessmentTimestamp,
      }}
    >
      {noDataIndicator || (
        <div>
          <div className={styles.swingle_checks_container}>
            {commonSwingleChecksResultsKeys.map(swingleId => {
              if (swingleId === 'scaler_version') return null;
              const check = { ...neuromarkersChecks[swingleId] };
              if (!Object.keys(check).length) return null;
              const cutoffsV3 = Object.keys(
                neuromarkersChecks[swingleId].cutoffs_v3
              );

              cutoffsV3.forEach(cutoff => {
                if (
                  check.cutoffs_v3[cutoff] &&
                  check.cutoffs_v3[cutoff][ageBin]
                ) {
                  check[cutoff] = check.cutoffs_v3[cutoff][ageBin];
                }
              });
              return (
                <div className="print-item">
                  <SwingleCheck
                    check={check}
                    result={preRawResultsById[swingleId]}
                    swingleNoise={preNoiseResultsById[swingleId]}
                    zScore={preZScoreResultsById[swingleId]}
                    postResult={postRawResultsById[swingleId]}
                    postSwingleNoise={postNoiseResultsById[swingleId]}
                    postZScore={postZScoreResultsById[swingleId]}
                    classes={{
                      checkGraph: styles.check_graph,
                      checkContainer: styles.check_container,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </AssessmentComparisonGraph>
  );
};

AssessmentNeuromarkersChecksBase.defaultProps = {
  noDataIndicator: null,
};

AssessmentNeuromarkersChecksBase.propTypes = exact({
  preNeuromarkersChecksResults: PropTypes.object.isRequired,
  postNeuromarkersChecksResults: PropTypes.object.isRequired,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  isSomeDataHidden: PropTypes.bool.isRequired,
  noDataIndicator: PropTypes.node,
  commonSwingleChecksResultsKeys: PropTypes.array,
  neuromarkersChecks: PropTypes.object,
  ageBin: PropTypes.object,
});

export const AssessmentNeuromarkersChecks = React.memo(
  AssessmentNeuromarkersChecksBase
);
AssessmentNeuromarkersChecks.displayName = 'AssessmentNeuromarkersChecks';

import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import {
  Paper,
  CircularProgress,
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import EditIcon from '@material-ui/icons/Edit';

import DefaultAvatarPNG from '../../../assets/default-avatar.png';
import { getLogoImage } from '../../models/logo/logo';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
// import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';
import { AssessmentSettings } from '../SettingsSection/AssessmentSettings';
import { ProgramsSettings } from '../SettingsSection/ProgramsSettings';
import { FeedbackDialog } from './FeedbackDialog';
import { ChangesLostDialog } from './ChangesLostDialog';
import { ClinicDetailsDialog } from './ClinicDetailsDialog';
import { TriggerItem } from './TriggerItem';
import enabledNotifByEmailPNG from '../../../assets/enabled-noti-by-email-with-border.png';
import disabledEmailNotifPNG from '../../../assets/disabled-email-notif-with-border.png';
import styles from './Automations.scss';

const AutomationsLogger = new MyEventWrapper('human_centric');

const initialValue = Symbol('iv');

const AutomationsBase = () => {
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );
  const [selectedTriggerIndex, setSelectedTriggerIndex] = useState(0);
  const [selectedTriggerId, setSelectedTriggerId] = useState(null);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const [feedbackPopupText, setFeedbackPopupText] = useState('');
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');
  const [currentSelectionStartIndex, setCurrentSelectionStartIndex] = useState(
    null
  );
  const [currentSelectionEndIndex, setCurrentSelectionEndIndex] = useState(
    null
  );
  const [clinicName, setClinicName] = useState('');
  const [clinicImageUrl, setClinicImageUrl] = useState('');
  const [clinicBio, setClinicBio] = useState('');
  const [isClinicDetailsPopupOpen, setIsClinicDetailsPopupOpen] = useState(
    false
  );
  const [clinicNameInput, setClinicNameInput] = useState('');
  const [clinicImageUrlInput, setClinicImageUrlInput] = useState('');
  const [clinicBioInput, setClinicBioInput] = useState('');
  const [isLoadingClinicDetails, setIsLoadingClinicDetails] = useState(false);
  const [edittedDynamicTriggerID, setEdittedDynamicTriggerID] = useState(null);
  const [isEdittedTriggerEnabled, setIsEdittedTriggerEnabled] = useState(false);
  const [selectedTriggerVariantId, setSelectedTriggerVariantId] = useState(
    null
  );
  const [changesLostPopupOpen, setChangesLostPopupOpen] = useState(false);
  const [
    selectedTriggerIndexToChangeTo,
    setSelectedTriggerIndexToChangeTo,
  ] = useState(null);
  const [
    selectedTriggerIdToChangeTo,
    setSelectedTriggerIdToChangeTo,
  ] = useState(null);
  const [
    selectedTriggerVariantIdToChangeTo,
    setSelectedTriggerVariantIdToChangeTo,
  ] = useState(null);
  const [messageBeforeEdit, setMessageBeforeEdit] = useState('');

  const [hideAutoDeployFromPrefrences] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${sessionStorage.userId}/clinicFeatureDefaults/hideAutoDeployFromPrefrences`,
    onceListener: true,
    initialValue,
  });

  const [defaultTriggers] = useFirebaseDB({
    path: '/humanCentric/triggers/default',
    onceListener: true,
    initialValue,
  });

  const [dynamicVariables] = useFirebaseDB({
    path: '/humanCentric/dynamicVariables',
    onceListener: true,
    initialValue,
  });

  const [remoteClinics] = useFirebaseDB({
    path: '/remoteClinics/clinicInfo',
    onceListener: true,
    initialValue,
  });

  const [clinicTriggers] = useFirebaseDB({
    path: `/humanCentric/triggers/clinics/${sessionStorage.userId}`,
    initialValue,
  });

  const [clinicDetails] = useFirebaseDB({
    path: `/humanCentric/clinicDetails/${sessionStorage.userId}`,
    initialValue,
  });

  const [notifyAutomationsMessagesByEmail] = useFirebaseDB({
    path: selectedTriggerVariantId
      ? `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/variants/${selectedTriggerVariantId}/notifyByEmail`
      : `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/notifyByEmail`,
    initialValue,
  });

  const onNotifyByEmailChange = checked => {
    database
      .ref(
        selectedTriggerVariantId
          ? `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/variants/${selectedTriggerVariantId}/notifyByEmail`
          : `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/notifyByEmail`
      )
      .set(checked);
  };

  const onToggleTrigger = (triggerId, val, triggerVariantId) => {
    database
      .ref(
        triggerVariantId
          ? `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${triggerId}/variants/${triggerVariantId}/isEnable`
          : `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${triggerId}/isEnable`
      )
      .set(val);

    // eslint-disable-next-line  no-undef
    const identifyEvent = new amplitude.Identify();
    identifyEvent.append(triggerId, val);
    // eslint-disable-next-line  no-undef
    amplitude.identify(identifyEvent);
  };

  const onEditMessage = () => {
    if (edittedDynamicTriggerID) {
      return;
    }
    if (
      selectedTriggerVariantId &&
      clinicTriggers?.customizedTriggersProperties?.[selectedTriggerId]
        ?.variants?.[selectedTriggerVariantId]?.customizedMessage
    ) {
      setEditedMessage(
        clinicTriggers?.customizedTriggersProperties?.[
          selectedTriggerId
        ]?.variants?.[selectedTriggerVariantId]?.customizedMessage.replace(
          /\\n/g,
          '\n'
        )
      );
      setMessageBeforeEdit(
        clinicTriggers?.customizedTriggersProperties?.[
          selectedTriggerId
        ]?.variants?.[selectedTriggerVariantId]?.customizedMessage.replace(
          /\\n/g,
          '\n'
        )
      );
      setIsEditingMode(true);
      return;
    }

    if (
      clinicTriggers?.customizedTriggersProperties?.[selectedTriggerId]
        ?.customizedMessage
    ) {
      setEditedMessage(
        clinicTriggers.customizedTriggersProperties[
          selectedTriggerId
        ].customizedMessage.replace(/\\n/g, '\n')
      );
      setMessageBeforeEdit(
        clinicTriggers.customizedTriggersProperties[
          selectedTriggerId
        ].customizedMessage.replace(/\\n/g, '\n')
      );
    } else {
      setEditedMessage(
        defaultTriggers[selectedTriggerId].defaultMessage.replace(/\\n/g, '\n')
      );
      setMessageBeforeEdit(
        defaultTriggers[selectedTriggerId].defaultMessage.replace(/\\n/g, '\n')
      );
    }
    setIsEditingMode(true);
  };

  const onChangeLostDialogConfirm = () => {
    setSelectedTriggerIndex(selectedTriggerIndexToChangeTo);
    setSelectedTriggerId(selectedTriggerIdToChangeTo);
    setSelectedTriggerVariantId(selectedTriggerVariantIdToChangeTo);
    setIsEditingMode(false);
    setSelectedTriggerIndexToChangeTo(null);
    setSelectedTriggerIdToChangeTo(null);
    setSelectedTriggerVariantIdToChangeTo(null);
    setChangesLostPopupOpen(false);
  };

  const submitFeedback = async () => {
    setIsFeedbackPopupOpen(false);
    try {
      await database
        .ref(`/humanCentric/feedback/${sessionStorage.userId}`)
        .push({
          timestamp: Date.now(),
          feedback: feedbackPopupText,
        });
      AutomationsLogger.log('submit_feedback', {
        text: feedbackPopupText,
      });
      setFeedbackPopupText('');
      showNotification('success', 'Feedback sent!');
    } catch (e) {
      setFeedbackPopupText('');
      showNotification('danger', `something went wrong ${e}`);
    }
  };

  const onCancelEdit = () => {
    setIsEditingMode(false);
  };

  const onSaveEdit = () => {
    database
      .ref(
        selectedTriggerVariantId
          ? `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/variants/${selectedTriggerVariantId}/customizedMessage`
          : `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/customizedMessage`
      )
      .set(editedMessage.replace(/\n/g, '\\n'));
    setIsEditingMode(false);
    AutomationsLogger.log('auto_message_action', {
      action: 'update_message',
      triggerTitle: defaultTriggers[selectedTriggerId].title,
      triggerId: selectedTriggerId,
    });
  };

  const onRestoreMessageClicked = () => {
    setEditedMessage(
      defaultTriggers[selectedTriggerId].defaultMessage.replace(/\\n/g, '\n')
    );
    AutomationsLogger.log('auto_message_action', {
      action: 'restore_default',
      triggerTitle: defaultTriggers[selectedTriggerId].title,
      triggerId: selectedTriggerId,
    });
  };

  const onEditMessageSelectionChange = e => {
    const { selectionStart, selectionEnd } = e.target;
    setCurrentSelectionStartIndex(selectionStart);
    setCurrentSelectionEndIndex(selectionEnd);
  };

  const onAddDynamicVariableToText = dynamicVariableKey => {
    const textToInsert = dynamicVariables[dynamicVariableKey].displayName;
    const newText =
      editedMessage.substring(0, currentSelectionStartIndex) +
      textToInsert +
      editedMessage.substring(currentSelectionEndIndex);
    setEditedMessage(newText);

    const newCursorPosition = currentSelectionStartIndex + textToInsert.length;

    setTimeout(() => {
      const textarea = document.getElementById('automations-text-input');
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      textarea.focus();
    }, 0);
  };

  const onSaveClinicDetails = async () => {
    const updatedItems = [];
    if (clinicImageUrl !== clinicImageUrlInput) {
      updatedItems.push('image');
    }
    if (clinicName !== clinicNameInput) {
      updatedItems.push('display_name');
    }
    if (clinicBio !== clinicBioInput) {
      updatedItems.push('bio');
    }
    AutomationsLogger.log('bio_update', { updated_items: updatedItems });

    setIsClinicDetailsPopupOpen(false);
    setClinicImageUrl(clinicImageUrlInput);
    setClinicName(clinicNameInput);
    setClinicBio(clinicBioInput);
    try {
      await database
        .ref(`/humanCentric/clinicDetails/${sessionStorage.userId}`)
        .set({
          imageUrl: clinicImageUrlInput,
          displayName: clinicNameInput,
          bio: clinicBioInput,
        });
      showNotification('success', 'Clinic details updated!');
    } catch (e) {
      showNotification('danger', `something went wrong ${e}`);
    }
  };

  const saveNewClinicTrigger = async inputVal => {
    let shouldExit = false;
    if (
      defaultTriggers[selectedTriggerId].dynamicVariableDetails.defaultValue ===
      +inputVal
    ) {
      shouldExit = true;
    }
    if (
      clinicTriggers?.customizedTriggersProperties?.[selectedTriggerId]
        ?.variants
    ) {
      const variants = Object.keys(
        clinicTriggers?.customizedTriggersProperties?.[selectedTriggerId]
          ?.variants
      );
      variants.forEach(variantId => {
        if (
          +clinicTriggers?.customizedTriggersProperties?.[selectedTriggerId]
            ?.variants[variantId].value === +inputVal ||
          +defaultTriggers[selectedTriggerId]?.dynamicVariableDetails
            .defaultValue === +inputVal
        ) {
          shouldExit = true;
        }
      });
    }
    if (shouldExit) {
      showNotification('danger', 'Cannot have duplicate variants');
      setEdittedDynamicTriggerID(null);
      return;
    }
    try {
      await database
        .ref(
          `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${selectedTriggerId}/variants`
        )
        .push({
          value: +inputVal,
          isEnable: isEdittedTriggerEnabled,
        });
      showNotification('success', 'Trigger was added!');
      setEdittedDynamicTriggerID(null);
    } catch (e) {
      showNotification('danger', `something went wrong ${e}`);
      setEdittedDynamicTriggerID(null);
    }
  };

  const cancelEditingTrigger = () => {
    setEdittedDynamicTriggerID(null);
  };

  const onDeleteVariant = async (triggerId, triggerVariantId) => {
    try {
      setSelectedTriggerId(triggerId);
      setSelectedTriggerVariantId(null);
      await database
        .ref(
          `/humanCentric/triggers/clinics/${sessionStorage.userId}/customizedTriggersProperties/${triggerId}/variants/${triggerVariantId}`
        )
        .set(null);
      showNotification('success', 'Trigger was deleted!');
    } catch (e) {
      showNotification('danger', `something went wrong ${e}`);
    }
  };

  const renderMessagePreview = () => {
    if (!selectedTriggerId) {
      return null;
    }
    if (isEditingMode) {
      return (
        <TextField
          value={editedMessage}
          onChange={e => setEditedMessage(e.target.value)}
          multiline
          InputProps={{
            classes: {
              input: styles.text_input,
            },
          }}
          onMouseUp={onEditMessageSelectionChange}
          onKeyUp={onEditMessageSelectionChange}
          placeholder="Message body"
          id="automations-text-input"
        />
      );
    }
    if (
      selectedTriggerVariantId &&
      clinicTriggers?.customizedTriggersProperties?.[selectedTriggerId]
        ?.variants?.[selectedTriggerVariantId]?.customizedMessage
    ) {
      const splitted = clinicTriggers?.customizedTriggersProperties?.[
        selectedTriggerId
      ]?.variants?.[selectedTriggerVariantId]?.customizedMessage.split('\\n');
      return splitted.map(str => <div style={{ marginTop: 5 }}>{str}</div>);
    }
    const splitted = clinicTriggers?.customizedTriggersProperties?.[
      selectedTriggerId
    ]?.customizedMessage
      ? clinicTriggers?.customizedTriggersProperties?.[
          selectedTriggerId
        ]?.customizedMessage.split('\\n')
      : defaultTriggers[selectedTriggerId].defaultMessage.split('\\n');
    return (
      <div>
        {splitted.map(str => (
          <div style={{ minHeight: 20, whiteSpace: 'pre-wrap' }}>{str}</div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (
      defaultTriggers !== initialValue &&
      hideAutoDeployFromPrefrences !== initialValue &&
      dynamicVariables !== initialValue &&
      remoteClinics !== initialValue &&
      clinicTriggers !== initialValue &&
      clinicDetails !== initialValue &&
      notifyAutomationsMessagesByEmail !== initialValue
    ) {
      setIsComponentReadyToRender(true);
    }
  }, [
    hideAutoDeployFromPrefrences,
    defaultTriggers,
    dynamicVariables,
    remoteClinics,
    clinicTriggers,
    clinicDetails,
    notifyAutomationsMessagesByEmail,
  ]);

  useEffect(() => {
    setSelectedTriggerId(Object.keys(defaultTriggers)[0]);
  }, [defaultTriggers]);

  useEffect(() => {
    (async () => {
      if (clinicDetails === initialValue) {
        return;
      }
      setIsLoadingClinicDetails(true);
      if (!clinicDetails) {
        const logoData = await getLogoImage({
          clinicId: sessionStorage.userId,
        });
        if (logoData.logoImage && logoData.customLogo) {
          setClinicImageUrl(logoData.logoImage);
          setClinicImageUrlInput(logoData.logoImage);
        }
        const snapshot = await database
          .ref(`userInfo/${sessionStorage.userId}/displayName`)
          .once('value', v => v);
        setClinicName(snapshot.val());
        setClinicNameInput(snapshot.val());
      } else {
        if (!clinicDetails.imageUrl) {
          const logoData = await getLogoImage({
            clinicId: sessionStorage.userId,
          });
          if (logoData.logoImage && logoData.customLogo) {
            setClinicImageUrl(logoData.logoImage);
            setClinicImageUrlInput(logoData.logoImage);
          }
        } else {
          setClinicImageUrl(clinicDetails.imageUrl);
          setClinicImageUrlInput(clinicDetails.imageUrl);
        }
        if (clinicDetails.bio) {
          setClinicBio(clinicDetails.bio);
          setClinicBioInput(clinicDetails.bio);
        }
        if (clinicDetails.displayName) {
          setClinicName(clinicDetails.displayName);
          setClinicNameInput(clinicDetails.displayName);
        } else {
          const snapshot = await database
            .ref(`userInfo/${sessionStorage.userId}/displayName`)
            .once('value', v => v);
          setClinicName(snapshot.val());
          setClinicNameInput(snapshot.val());
        }
      }
      setIsLoadingClinicDetails(false);
    })();
  }, [clinicDetails]);

  if (!isComponentReadyToRender) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}
      >
        <CircularProgress />
      </div>
    );
  }

  const sortedDefaultTriggers = Object.keys(defaultTriggers).sort(
    (a, b) => defaultTriggers[a].order - defaultTriggers[b].order
  );

  return (
    <div className={styles.page_container}>
      <div className={styles.page_title}>Automations</div>
      <Paper>
        <div className={styles.content_container}>
          <div className={styles.right_content_container}>
            <div className={styles.right_content_top}>
              <div className={styles.right_content_title}>
                Auto-messages
                <span className={styles.card_new_label}>NEW</span>
              </div>
              <div
                className={styles.learn_more}
                onClick={() => {
                  AutomationsLogger.log('learn_more_clicked');
                  window.open(
                    'https://intercom.help/dashboard-myndlift/en/articles/9455047-one-way-automated-manual-messaging',
                    '_blank'
                  );
                }}
              >
                <ImportContactsIcon style={{ fontSize: 14, marginRight: 5 }} />{' '}
                Learn more
              </div>
            </div>
            <div className={styles.triggers_list_container}>
              {sortedDefaultTriggers.map((triggerId, index) => {
                if (
                  !remoteClinics[sessionStorage.userId] &&
                  defaultTriggers[triggerId].isMtrOnly
                ) {
                  return null;
                }

                const triggerVariants =
                  clinicTriggers?.customizedTriggersProperties?.[triggerId]
                    ?.variants;

                return (
                  <>
                    <TriggerItem
                      setSelectedTriggerIndex={setSelectedTriggerIndex}
                      setSelectedTriggerId={setSelectedTriggerId}
                      triggerId={triggerId}
                      index={index}
                      selectedTriggerIndex={selectedTriggerIndex}
                      defaultTriggers={defaultTriggers}
                      onToggleTrigger={onToggleTrigger}
                      clinicTriggers={clinicTriggers}
                      setEdittedDynamicTriggerID={setEdittedDynamicTriggerID}
                      setSelectedTriggerVariantId={setSelectedTriggerVariantId}
                      selectedTriggerVariantId={selectedTriggerVariantId}
                      isEditingMode={isEditingMode}
                      setChangesLostPopupOpen={setChangesLostPopupOpen}
                      setSelectedTriggerIndexToChangeTo={
                        setSelectedTriggerIndexToChangeTo
                      }
                      setSelectedTriggerIdToChangeTo={
                        setSelectedTriggerIdToChangeTo
                      }
                      setSelectedTriggerVariantIdToChangeTo={
                        setSelectedTriggerVariantIdToChangeTo
                      }
                      messageBeforeEdit={messageBeforeEdit}
                      editedMessage={editedMessage}
                      setIsEditingMode={setIsEditingMode}
                    />
                    {edittedDynamicTriggerID === triggerId &&
                    selectedTriggerId === triggerId ? (
                      <TriggerItem
                        setSelectedTriggerIndex={() => {}}
                        setSelectedTriggerId={() => {}}
                        triggerId={triggerId}
                        index={index}
                        selectedTriggerIndex={selectedTriggerIndex}
                        defaultTriggers={defaultTriggers}
                        onToggleTrigger={() => {
                          setIsEdittedTriggerEnabled(!isEdittedTriggerEnabled);
                        }}
                        clinicTriggers={clinicTriggers}
                        setEdittedDynamicTriggerID={setEdittedDynamicTriggerID}
                        isEdittngTrigger
                        saveNewClinicTrigger={saveNewClinicTrigger}
                        cancelEditingTrigger={cancelEditingTrigger}
                        isEdittedTriggerEnabled={isEdittedTriggerEnabled}
                        setSelectedTriggerVariantId={
                          setSelectedTriggerVariantId
                        }
                        selectedTriggerVariantId={selectedTriggerVariantId}
                        isEditingMode={isEditingMode}
                        setChangesLostPopupOpen={setChangesLostPopupOpen}
                        setSelectedTriggerIndexToChangeTo={
                          setSelectedTriggerIndexToChangeTo
                        }
                        setSelectedTriggerIdToChangeTo={
                          setSelectedTriggerIdToChangeTo
                        }
                        setSelectedTriggerVariantIdToChangeTo={
                          setSelectedTriggerVariantIdToChangeTo
                        }
                        messageBeforeEdit={messageBeforeEdit}
                        editedMessage={editedMessage}
                        setIsEditingMode={setIsEditingMode}
                      />
                    ) : null}
                    {Object.keys(triggerVariants || {})
                      .reverse()
                      .map(triggerVariantId => {
                        return (
                          <TriggerItem
                            setSelectedTriggerIndex={setSelectedTriggerIndex}
                            setSelectedTriggerId={setSelectedTriggerId}
                            triggerId={triggerId}
                            index={index}
                            selectedTriggerIndex={selectedTriggerIndex}
                            defaultTriggers={defaultTriggers}
                            onToggleTrigger={onToggleTrigger}
                            clinicTriggers={clinicTriggers}
                            setEdittedDynamicTriggerID={
                              setEdittedDynamicTriggerID
                            }
                            triggerVariantId={triggerVariantId}
                            setSelectedTriggerVariantId={
                              setSelectedTriggerVariantId
                            }
                            onDeleteVariant={onDeleteVariant}
                            selectedTriggerVariantId={selectedTriggerVariantId}
                            isEditingMode={isEditingMode}
                            setChangesLostPopupOpen={setChangesLostPopupOpen}
                            setSelectedTriggerIndexToChangeTo={
                              setSelectedTriggerIndexToChangeTo
                            }
                            setSelectedTriggerIdToChangeTo={
                              setSelectedTriggerIdToChangeTo
                            }
                            setSelectedTriggerVariantIdToChangeTo={
                              setSelectedTriggerVariantIdToChangeTo
                            }
                            messageBeforeEdit={messageBeforeEdit}
                            editedMessage={editedMessage}
                            setIsEditingMode={setIsEditingMode}
                          />
                        );
                      })}
                  </>
                );
              })}
            </div>
            <div
              className={styles.feedback_opener}
              onClick={() => {
                setIsFeedbackPopupOpen(true);
              }}
            >
              Can’t find what you’re looking for?
            </div>
          </div>
          <div className={styles.left_content_container}>
            <div className={styles.left_content_top}>
              <div className={styles.right_content_title}>Preview</div>
              <div
                className={styles.notify_by_email}
                style={
                  edittedDynamicTriggerID ? { visibility: 'hidden' } : null
                }
              >
                <Tooltip
                  title={
                    <TooltipTitle>Also notify client via email</TooltipTitle>
                  }
                >
                  <img
                    src={
                      notifyAutomationsMessagesByEmail
                        ? enabledNotifByEmailPNG
                        : disabledEmailNotifPNG
                    }
                    className={styles.messeges_btn}
                    onClick={() => {
                      AutomationsLogger.log('noitfy_email_toggle', {
                        enabled: !notifyAutomationsMessagesByEmail,
                      });
                      onNotifyByEmailChange(!notifyAutomationsMessagesByEmail);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <div style={{ position: 'relative', top: -15 }}>
              The in-app message that will be sent under your name to the client
              when triggered.
            </div>
            <div className={styles.edit_message_container}>
              <div
                className={styles.edit_message_edit_btn}
                onClick={onEditMessage}
                style={
                  edittedDynamicTriggerID || isEditingMode
                    ? { visibility: 'hidden' }
                    : null
                }
              >
                <EditIcon />
              </div>
              {renderMessagePreview()}
              <div
                className={styles.edit_message_btns_container}
                style={!isEditingMode ? { visibility: 'hidden' } : null}
              >
                {Object.entries(dynamicVariables).map(
                  ([dynamicVariableKey, dynamicVariableData]) => {
                    return (
                      <div
                        key={dynamicVariableKey}
                        className={styles.edit_message_div_btn}
                        onClick={() => {
                          onAddDynamicVariableToText(dynamicVariableKey);
                        }}
                        style={
                          isEditingMode
                            ? null
                            : { cursor: 'auto', color: 'gray' }
                        }
                      >
                        + ADD{' '}
                        {dynamicVariableData.displayName
                          .split('[')
                          .join('')
                          .split(']')
                          .join('')
                          .toUpperCase()}
                      </div>
                    );
                  }
                )}
                <div
                  className={styles.edit_message_div_btn}
                  onClick={onRestoreMessageClicked}
                >
                  RESTORE DEFAULT
                </div>
                <div>
                  <Button
                    color="primary"
                    style={{ fontSize: 14, marginRight: 10 }}
                    onClick={onCancelEdit}
                    disabled={!isEditingMode}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ fontSize: 14 }}
                    onClick={onSaveEdit}
                    disabled={!isEditingMode || !editedMessage}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.triangle} />
            {!isLoadingClinicDetails ? (
              <div className={styles.clinic_info_edit_container}>
                <div style={{ width: 100, height: 100, marginRight: 20 }}>
                  <img
                    src={clinicImageUrl || DefaultAvatarPNG}
                    className={styles.clinic_info_edit_img}
                  />
                </div>
                <div className={styles.clinic_info_edit_info}>
                  <div className={styles.clinic_info_edit_info_name}>
                    {clinicName}
                    <span
                      className={styles.clinic_info_edit_info_edit_btn}
                      onClick={() => {
                        setIsClinicDetailsPopupOpen(true);
                      }}
                    >
                      Edit
                    </span>
                  </div>
                  <div
                    className={styles.clinic_info_edit_info_bio}
                    style={
                      clinicBio ? { color: 'unset', cursor: 'auto' } : null
                    }
                    onClick={() => {
                      if (clinicBio) {
                        return;
                      }
                      setIsClinicDetailsPopupOpen(true);
                    }}
                  >
                    {clinicBio || 'Add a bio (recommended)'}
                  </div>
                </div>
              </div>
            ) : (
              <CircularProgress size={12} style={{ marginTop: 50 }} />
            )}
          </div>
        </div>
      </Paper>
      <FeedbackDialog
        isFeedbackPopupOpen={isFeedbackPopupOpen}
        setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
        feedbackPopupText={feedbackPopupText}
        setFeedbackPopupText={setFeedbackPopupText}
        submitFeedback={submitFeedback}
      />
      <ClinicDetailsDialog
        isClinicDetailsPopupOpen={isClinicDetailsPopupOpen}
        setIsClinicDetailsPopupOpen={setIsClinicDetailsPopupOpen}
        clinicNameInput={clinicNameInput}
        setClinicNameInput={setClinicNameInput}
        clinicImageUrlInput={clinicImageUrlInput}
        setClinicImageUrlInput={setClinicImageUrlInput}
        clinicBioInput={clinicBioInput}
        setClinicBioInput={setClinicBioInput}
        onSaveClinicDetails={onSaveClinicDetails}
        clinicImageUrl={clinicImageUrl}
      />
      <ChangesLostDialog
        changesLostDialogOpen={changesLostPopupOpen}
        setChangesLostDialogOpen={setChangesLostPopupOpen}
        onConfirm={onChangeLostDialogConfirm}
      />
      {hideAutoDeployFromPrefrences ? null : (
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <AssessmentSettings />
        </Paper>
      )}
      {hideAutoDeployFromPrefrences ? null : (
        <Paper style={{ padding: 20 }}>
          <ProgramsSettings />
        </Paper>
      )}
    </div>
  );
};

AutomationsBase.propTypes = exact({});

export const Automations = React.memo(AutomationsBase);
Automations.displayName = 'Automations';

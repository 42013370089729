import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, Button } from '@material-ui/core';
import styles from './Automations.scss';

const ChangesLostDialogBase = ({
  changesLostDialogOpen,
  setChangesLostDialogOpen,
  onConfirm,
}) => {
  return (
    <Dialog open={changesLostDialogOpen} fullWidth>
      <div style={{ padding: 20 }}>
        <div>
          <div className={styles.feedback_dialog_title}>Unsaved Changes.</div>
          <div style={{ marginLeft: 10 }}>
            You have unsaved changes. If you leave without saving, your changes
            will be lost.
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginTop: 20,
          }}
        >
          <Button
            onClick={() => {
              setChangesLostDialogOpen(false);
            }}
            color="primary"
            style={{ fontSize: 16, marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            style={{ fontSize: 16 }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ChangesLostDialogBase.propTypes = exact({
  changesLostDialogOpen: PropTypes.bool.isRequired,
  setChangesLostDialogOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
});

export const ChangesLostDialog = React.memo(ChangesLostDialogBase);
ChangesLostDialog.displayName = 'ChangesLostDialog';

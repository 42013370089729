import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, Button, DialogActions } from '@material-ui/core';
import styles from './UnavailableListingDialog.scss';
import listingUnavailableImage from '../../../../assets/listing_unavailable.png';
import { EasierCustomTable } from '../../../Core/Components/CustomTable/EasierCustomTable';

const UnavailableListingDialogBase = ({
  onConfirm,
  isOpen,
  listingConfig,
  maxListings,
}) => {
  return (
    <Dialog open={isOpen} maxWidth="xl">
      <div className={styles.content}>
        <img src={listingUnavailableImage} />
        <p>Maximum Listings</p>
        <p>
          We’re sorry, you can only add up to {maxListings} clinicians at this
          time.
        </p>

        <div className={styles.table_container}>
          <EasierCustomTable
            rows={[
              [listingConfig.text, 'Listings'],
              ...listingConfig.ranges.map(range => [
                range.name,
                `Up to ${range.listings} ${
                  range.listings === 1 ? 'listing' : 'listings'
                }`,
              ]),
            ]}
            rowsKeys={listingConfig.ranges.map(range => range.start)}
            colsKeys={listingConfig.ranges.map(range => range.listings)}
          />
        </div>
      </div>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          <span className={styles.btn}>got it</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnavailableListingDialogBase.propTypes = exact({
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  listingConfig: PropTypes.object.isRequired,
  maxListings: PropTypes.number.isRequired,
});

export const UnavailableListingDialog = React.memo(
  UnavailableListingDialogBase
);
UnavailableListingDialog.displayName = 'UnavailableListingDialog';

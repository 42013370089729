import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import { Paper } from '@material-ui/core';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import { EasierCustomTable } from '../../Core/Components/CustomTable/EasierCustomTable';
import styles from './MTRCallsLog.scss';

const MTRCallsLogBase = ({ customerId, customerInfo }) => {
  const [callsCapacityInfo] = useFirebaseDB({
    path: `remoteUsers/callsCapacity/${customerId}`,
    shouldConnect: !!customerInfo.isRemoteUser,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
  });

  const [scheduledCalls] = useFirebaseDB({
    path: `remoteUsers/scheduledCalls/${customerId}`,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
    shouldConnect: !!customerInfo.isRemoteUser,
  });

  const [remoteAdminUsers] = useFirebaseDB({
    path: 'remoteAdminUsers',
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
    shouldConnect: !!customerInfo.isRemoteUser,
  });

  const [stripeId, setStripeId] = useState('');
  const [subscriptions, setSubscriptions] = useState(null);
  const [userActivationCode, setUserActivationCode] = useState('');

  const rawTableHeads = ['Type', 'Status', 'Date'];

  const rows = Object.values(scheduledCalls)
    .sort((call1, call2) => call1.timestamp - call2.timestamp)
    .map(scheduledCall => {
      const status = (() => {
        if (scheduledCall.canceled) {
          return 'Canceled';
        }
        return scheduledCall.timestamp <= Date.now() ? 'Passed' : 'Upcoming';
      })();

      const originalScheduleDate = scheduledCall.timestamp
        ? dayJS(scheduledCall.timestamp).format(
            DAY_JS_DATE_FORMATS.dayMonthYearSlashes
          )
        : '';

      return [
        scheduledCall.isNeuroCoachEvent ? 'Neuro Coach' : 'Care Team',
        status,
        originalScheduleDate,
      ];
    });
  const rowsKeys = ['head', ...Object.keys(scheduledCalls)];
  const rowsWithHead = [rawTableHeads, ...rows].map(row =>
    row.map(text => <span className={styles.cell}>{text}</span>)
  );

  useEffect(() => {
    if (remoteAdminUsers) {
      Object.keys(remoteAdminUsers).forEach(remoteAdminUserId => {
        if (remoteAdminUsers[remoteAdminUserId].users) {
          if (
            Object.keys(remoteAdminUsers[remoteAdminUserId].users).find(
              userId => userId === customerId
            )
          ) {
            setUserActivationCode(
              remoteAdminUsers[remoteAdminUserId].users[customerId]
                .activationCode
            );
            setStripeId(remoteAdminUsers[remoteAdminUserId].stripeId);
            const subscriptionsFromDb = database
              .ref(
                `remoteBilling/currentPlans/${remoteAdminUserId}/subscriptions`
              )
              .once('value')
              .then(snapshot => snapshot.val());
            subscriptionsFromDb.then(res => {
              setSubscriptions(res);
            });
          }
        }
      });
    }
  }, [customerId, remoteAdminUsers]);

  let stripeLinkPrefix;
  let activationLinkPrefix;
  if (environmentConfig.isDev) {
    stripeLinkPrefix = 'https://dashboard.stripe.com/test/customers/';
    activationLinkPrefix = 'https://dashboard.stripe.com/test/subscriptions/';
  } else {
    stripeLinkPrefix = 'https://dashboard.stripe.com/customers/';
    activationLinkPrefix = 'https://dashboard.stripe.com/subscriptions/';
  }

  return customerInfo.isRemoteUser ? (
    <Paper className={styles.paper}>
      <div>
        Customer's stripe page:
        <div>
          <a
            href={`${stripeLinkPrefix}${stripeId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${stripeLinkPrefix}${stripeId}`}
          </a>
        </div>
      </div>
      <div>
        <div>Activation code link:</div>
        {subscriptions &&
        userActivationCode &&
        subscriptions[userActivationCode] &&
        subscriptions[userActivationCode].subscriptionId ? (
          <div>
            <a
              href={`${activationLinkPrefix}${subscriptions[userActivationCode].subscriptionId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${activationLinkPrefix}${subscriptions[userActivationCode].subscriptionId}`}
            </a>
          </div>
        ) : null}
      </div>
      <div>Activation code: {userActivationCode}</div>
      <h2>
        Calls Capacity: {callsCapacityInfo.callsUsed} out of{' '}
        {callsCapacityInfo.callsAvailable}{' '}
      </h2>

      <EasierCustomTable
        rows={rowsWithHead}
        rowsKeys={rowsKeys}
        colsKeys={rawTableHeads}
        shouldShowRowsPerPageOptions={false}
      />
    </Paper>
  ) : null;
};

MTRCallsLogBase.defaultProps = {
  customerInfo: {},
  customerId: '',
};

MTRCallsLogBase.propTypes = exact({
  customerId: PropTypes.string,
  customerInfo: PropTypes.object,
});

export const MTRCallsLog = React.memo(MTRCallsLogBase);
MTRCallsLog.displayName = 'MTRCallsLog';

import React, { useEffect, useState } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import styles from './SaveProtocolWidget.scss';

const SaveProtocolWidgetBase = ({
  protocolName,
  setProtocolName,
  protocolDescription,
  setProtocolDescription,
  onSaveProtocolClicked,
}) => {
  const t = useTranslation();
  const featureToggleParamName = 'Dashboard_feature_toggle';

  const [
    protocolDescriptionFeatureToggle,
    setProtocolDescriptionFeatureToggle,
  ] = useState(null);

  useEffect(() => {
    if (window.remoteConfig) {
      window.remoteConfig.settings = {
        minimumFetchIntervalMillis: 3600000, // 3600000 for 1 hour
      };

      const fetchRemoteConfig = async () => {
        try {
          await window.remoteConfig.fetchAndActivate();
          const remoteConfigValue = window.remoteConfig.getValue(
            featureToggleParamName
          );

          // If the value is a JSON string, parse it
          // eslint-disable-next-line
          const toggleValue = JSON.parse(remoteConfigValue._value);
          setProtocolDescriptionFeatureToggle(toggleValue);
          // eslint-disable-next-line
        } catch (error) {
          // console.error(
          //   'Error fetching or parsing remote config value:',
          //   error
          // );
          setProtocolDescriptionFeatureToggle(false);
        }
      };

      fetchRemoteConfig();
    } else {
      // console.error('Remote Config is not initialized.');
      setProtocolDescriptionFeatureToggle(false);
    }
    // eslint-disable-next-line
  }, [remoteConfig]);

  return (
    <>
      <div className={styles.widget_container}>
        <div className={styles.cards_container}>
          <div className={styles.card_header}>
            <h2>{t('protocol-name')}</h2>
            <small>{t('save-protocol-description')}</small>
          </div>
          <div className={styles.card_body}>
            <div className={classNames(styles.input_container)}>
              <input
                type="text"
                id="protocol_name"
                value={protocolName}
                onChange={e => {
                  setProtocolName(e.target.value);
                }}
              />
            </div>
          </div>
          {protocolDescriptionFeatureToggle && (
            <>
              <div className={styles.card_header}>
                <h2>{t('protocol-description-widget-title')}</h2>
                <small>{t('protocol-description-widget-description')}</small>
              </div>
              <div className={styles.card_body}>
                <div className={classNames(styles.textarea_container)}>
                  <textarea
                    type="text"
                    id="protocol_description-create"
                    placeholder={t('add-a-description-placeholder')}
                    value={protocolDescription}
                    onChange={e => {
                      setProtocolDescription(e.target.value);
                    }}
                    maxLength="300"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={styles.btn_card_body}
          style={{ justifyContent: 'flex-end' }}
        >
          <div className={styles.btn_container}>
            <Button
              variant="contained"
              color="primary"
              style={{
                padding: '8px 40px',
                // borderRadius: '5px',
                fontSize: '13px',
              }}
              id="save"
              onClick={onSaveProtocolClicked}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

SaveProtocolWidgetBase.propTypes = exact({
  protocolName: PropTypes.string.isRequired,
  setProtocolName: PropTypes.func.isRequired,
  protocolDescription: PropTypes.string.isRequired,
  setProtocolDescription: PropTypes.func.isRequired,
  onSaveProtocolClicked: PropTypes.func.isRequired,
});

export const SaveProtocolWidget = withEntryComponent(
  React.memo(SaveProtocolWidgetBase)
);

import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { OutstandingPaymentPopUp } from './OutstandingPaymentPopUp';
import { stripeScriptURL } from '../../../utils/constants';
import { useScript } from '../../../Core/hooks/useScript';

const OutstandingPaymentPopUpStripeContainerBase = () => {
  const [stripe, setStripe] = useState(null);
  const [isStripeScriptLoaded] = useScript(stripeScriptURL);

  useEffect(() => {
    if (
      (isStripeScriptLoaded || window.isStripeScriptLoaded) &&
      stripe === null
    ) {
      window.isStripeScriptLoaded = true;
      setStripe(window.Stripe(environmentConfig.stripePublicKey));
    }
  }, [isStripeScriptLoaded, stripe]);

  return (
    <StripeProvider stripe={window.stripeForStripePage || stripe}>
      <Elements>
        <OutstandingPaymentPopUp />
      </Elements>
    </StripeProvider>
  );
};

OutstandingPaymentPopUpStripeContainerBase.propTypes = exact({});

export const OutstandingPaymentPopUpStripeContainer = React.memo(
  OutstandingPaymentPopUpStripeContainerBase
);
OutstandingPaymentPopUpStripeContainer.displayName =
  'OutstandingPaymentPopUpStripeContainer';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import noop from 'lodash/noop';
import styles from './CustomDialog.scss';
import { ModalHeader } from '../Modal/ModalHeader';
import { ModalFooter } from '../Modal/ModalFooter';
import { Loader } from '../Loader/Loader';
import { usePreviousValue } from '../../hooks/usePreviousValue';

const CustomDialogBase = ({
  isOpen,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onClose,
  header,
  subHeader,
  primaryBtnContent,
  secondaryBtnContent,
  shouldShowCloseIcon,
  headerLeftContent,
  headerRightContent,
  customClasses,
  shouldUseCustomDialogStyles,
  primaryBtnProps,
  children,
  shouldShowButtons,
  isShowingLoader,
  maxWidth,
  isPrimaryBtnDisabled,
  disableBackdropClick,
}) => {
  const prvChildren = usePreviousValue(children, {
    shouldUpdate: !isShowingLoader,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={isShowingLoader ? noop : onClose}
      classes={{
        paper: classNames(
          { [styles.dialog_paper]: shouldUseCustomDialogStyles },
          customClasses.dialog_paper
        ),
      }}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
    >
      {(headerLeftContent || headerRightContent || header || subHeader) && (
        <ModalHeader
          leftContent={
            headerLeftContent ||
            ((header || subHeader) && (
              <div className={styles.header_container}>
                {header && <h4 className={styles.dialog_header}>{header}</h4>}
                {subHeader && <p>{subHeader}</p>}
              </div>
            ))
          }
          rightContent={headerRightContent}
          isCloseIconShown={shouldShowCloseIcon && !isShowingLoader}
          onCloseIconClick={onClose}
        />
      )}

      <div className={styles.content_and_footer}>
        <div
          className={classNames(
            styles.content_container,
            customClasses.content_container
          )}
        >
          <div style={{ visibility: isShowingLoader ? 'hidden' : 'revert' }}>
            {isShowingLoader ? prvChildren : children}
          </div>
          {isShowingLoader && (
            <div className={styles.loader_container}>
              <Loader padding="0px 0px" />
            </div>
          )}
        </div>
        {shouldShowButtons && (
          <div
            style={{ visibility: isShowingLoader ? 'hidden' : 'revert' }}
            className={customClasses.footer_container}
          >
            <ModalFooter
              secondaryBtnContent={secondaryBtnContent}
              primaryBtnContent={primaryBtnContent}
              onPrimaryBtnClick={onPrimaryBtnClick}
              onSecondaryBtnClick={onSecondaryBtnClick}
              primaryBtnProps={primaryBtnProps}
              customClasses={customClasses.footer}
              isPrimaryBtnDisabled={isPrimaryBtnDisabled}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

CustomDialogBase.defaultProps = {
  onPrimaryBtnClick: () => {},
  onSecondaryBtnClick: () => {},
  onClose: () => {},
  primaryBtnContent: <span className={styles.btn}>save</span>,
  secondaryBtnContent: <span className={styles.btn}>cancel</span>,
  shouldShowCloseIcon: true,
  customClasses: {},
  shouldUseCustomDialogStyles: true,
  primaryBtnProps: {},
  headerLeftContent: null,
  headerRightContent: null,
  shouldShowButtons: true,
  isShowingLoader: false,
  maxWidth: 'sm',
};

CustomDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onPrimaryBtnClick: PropTypes.func,
  onSecondaryBtnClick: PropTypes.func,
  onClose: PropTypes.func,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  primaryBtnContent: PropTypes.node,
  secondaryBtnContent: PropTypes.node,
  shouldShowCloseIcon: PropTypes.bool,
  customClasses: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  shouldUseCustomDialogStyles: PropTypes.bool,
  primaryBtnProps: PropTypes.object,
  headerLeftContent: PropTypes.node,
  headerRightContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  shouldShowButtons: PropTypes.bool,
  isShowingLoader: PropTypes.bool,
  maxWidth: PropTypes.string,
  isPrimaryBtnDisabled: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
});

export const CustomDialog = React.memo(CustomDialogBase);
CustomDialog.displayName = 'CustomDialog';

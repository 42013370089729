exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SelectChannelsStep_root{display:flex;flex-direction:column;width:100%}@media only screen and (max-width: 960px){.SelectChannelsStep_root{align-items:center}}.SelectChannelsStep_head_top_view_container{width:100%;text-align:center}@media only screen and (max-width: 960px){.SelectChannelsStep_head_top_view_container{width:90%}}@media only screen and (max-width: 768px){.SelectChannelsStep_head_top_view_container{width:60%}}@media only screen and (max-width: 540px){.SelectChannelsStep_head_top_view_container{width:70%}}@media only screen and (max-width: 465px){.SelectChannelsStep_head_top_view_container{width:80%}}.SelectChannelsStep_channels_buttons_container{display:flex;justify-content:center;flex-direction:row;margin-bottom:30px;flex-wrap:wrap}@media only screen and (max-width: 530px){.SelectChannelsStep_channels_buttons_container{display:block}}.SelectChannelsStep_channel_button{padding:15px 30px;box-shadow:0 3px 6px 0 rgba(0,0,0,0.16);border:solid 1px #e8e8e8;background-color:#f7f7f7;cursor:pointer;text-align:center;display:flex;flex-direction:column;justify-content:center}.SelectChannelsStep_active_channel_not_selected{border:solid 2px #279bfd;background-color:rgba(39,155,253,0.11)}.SelectChannelsStep_reference_channel_not_selected{border:solid 2px #3cd6be;background-color:rgba(60,214,190,0.11)}.SelectChannelsStep_rootstyle_head_top_down_view{max-width:40%}@media only screen and (max-width: 960px){.SelectChannelsStep_rootstyle_head_top_down_view{min-width:50%}}@media only screen and (max-width: 768px){.SelectChannelsStep_rootstyle_head_top_down_view{min-width:100%}}\n", ""]);

// Exports
exports.locals = {
	"root": "SelectChannelsStep_root",
	"head_top_view_container": "SelectChannelsStep_head_top_view_container",
	"channels_buttons_container": "SelectChannelsStep_channels_buttons_container",
	"channel_button": "SelectChannelsStep_channel_button",
	"active_channel_not_selected": "SelectChannelsStep_active_channel_not_selected",
	"reference_channel_not_selected": "SelectChannelsStep_reference_channel_not_selected",
	"rootstyle_head_top_down_view": "SelectChannelsStep_rootstyle_head_top_down_view"
};
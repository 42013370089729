import { useEffect, useState } from 'react';

export const useAsyncState = (asyncCallback, { initialState } = {}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async () => {
      setState(await asyncCallback());
    })();
  }, [asyncCallback]);

  return state;
};

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ReactHtmlParser from 'react-html-parser';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, withStyles } from '@material-ui/core';
import { scrollTo, defensiveThrow } from '../../../utils/utils';

const stylesFn = () => ({
  underline: {
    '&::after': {
      borderBottomColor: '#696a71',
    },
  },
  textField: {
    '& .MuiInput-underline::after': {
      borderBottomColor: '#696a71',
    },
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#696a71',
      },
      '&:hover fieldset': {
        borderColor: 'navy',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#696a71',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#696a71',
      },
    },
  },
});

const FAQSBase = ({
  classes,
  faqs,
  onSearch,
  onClearSearch,
  styles,
  expandedFaqs,
  onFaqClick,
  answersVariables,
  onFaqExpanded,
  resizeSearch,
}) => {
  const rootDivRef = useRef();
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);

  useEffect(() => {
    const internalAnchorElements = [
      ...rootDivRef.current.querySelectorAll('a[href^="#"]'),
    ];

    const onInternalAnchorElementClick = event => {
      event.preventDefault();
      const id = event.target.getAttribute('href').replace('#', '');
      const possibleIds = [
        'payment-options',
        'checkout-form',
        'headset-options',
      ];
      if (possibleIds.indexOf(id) < 0) {
        defensiveThrow({
          error: new Error(
            `This id: ${id} does not exist in remote payment page`
          ),
        });
      }
      scrollTo(id);
    };

    internalAnchorElements.forEach(element => {
      element.addEventListener('click', onInternalAnchorElementClick);
    });

    return () => {
      internalAnchorElements.forEach(element => {
        element.removeEventListener('click', onInternalAnchorElementClick);
      });
    };
  }, [faqs]);

  const sortedFaqs = faqs.sort((a, b) => {
    if (!a.order) return 1;
    if (!b.order) return -1;
    return a.order - b.order;
  });

  return (
    <div className={styles.faqs_root} ref={rootDivRef}>
      <div>
        <div
          style={{ visibility: isSearchFieldVisible ? 'hidden' : 'visible' }}
        >
          <h2>Frequently asked questions</h2>
          <div
            className={styles.search_icon_container}
            onClick={() => setIsSearchFieldVisible(true)}
          >
            <SearchIcon />
          </div>
        </div>
        {isSearchFieldVisible && (
          <div className={`${styles.search_field} ${styles['item-enter']}`}>
            <TextField
              label="Looking for something?"
              fullWidth
              onChange={onSearch}
              InputLabelProps={{
                classes: { shrink: styles.shrinked_search_label },
                style: resizeSearch ? { fontSize: 16 } : null,
              }}
              InputProps={{
                classes: { underline: classes.underline },
                style: resizeSearch ? { fontSize: 16 } : null,
              }}
              classes={{ root: classes.textField }}
              id="faqs_input"
            />
            <div
              className={styles.close_icon_container}
              onClick={() => {
                setIsSearchFieldVisible(false);
                onClearSearch();
              }}
            >
              <CloseIcon />
            </div>
          </div>
        )}
      </div>

      {sortedFaqs.map((faq, index) => (
        <div key={faq.question}>
          <ExpansionPanel
            classes={{ root: styles.expansion_panel_root }}
            {...(onFaqClick
              ? {
                  expanded: expandedFaqs.includes(faq.question),
                }
              : {})}
            onChange={(e, expanded) => {
              if (onFaqExpanded) {
                onFaqExpanded(faq.question, expanded);
              }
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{ root: styles.expansion_panel_summary_root }}
              {...(onFaqClick
                ? {
                    onClick: () => onFaqClick(faq),
                  }
                : {})}
            >
              <p className={styles.faq_question}>
                {ReactHtmlParser(
                  faq.question
                    .split('\n')
                    .map(answer => {
                      if (answersVariables && faq.variables) {
                        let newAnswer = answer;
                        faq.variables.forEach(variable => {
                          while (newAnswer.includes(`{{${variable}}}`)) {
                            try {
                              newAnswer = newAnswer.replace(
                                `{{${variable}}}`,
                                answersVariables[variable]
                              );
                            } catch (e) {
                              newAnswer = '';
                            }
                          }
                        });
                        return newAnswer.trim();
                      }
                      return answer.trim();
                    })
                    .join('\n'),
                  {
                    // eslint-disable-next-line consistent-return
                    transform: node => {
                      // guard against XSS
                      if (node.name !== 'a' && node.type !== 'text') {
                        return null;
                      }
                    },
                  }
                )}
              </p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {ReactHtmlParser(
                  faq.answer
                    .split('\n')
                    .map(answer => {
                      if (answersVariables && faq.variables) {
                        let newAnswer = answer;
                        faq.variables.forEach(variable => {
                          while (newAnswer.includes(`{{${variable}}}`)) {
                            try {
                              newAnswer = newAnswer.replace(
                                `{{${variable}}}`,
                                answersVariables[variable]
                              );
                            } catch (e) {
                              newAnswer = '';
                            }
                          }
                        });
                        return newAnswer.trim();
                      }
                      return answer.trim();
                    })
                    .join('\n'),
                  {
                    // eslint-disable-next-line consistent-return
                    transform: node => {
                      // guard against XSS
                      if (node.name !== 'a' && node.type !== 'text') {
                        return null;
                      }
                    },
                  }
                )}
              </p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {index !== faqs.length - 1 && (
            <div className={styles.border_container}>
              <div className={styles.border} />
            </div>
          )}
        </div>
      ))}
      {faqs.length === 0 && (
        <div className={styles.text_container}>
          <p>
            Sorry, we could not find any results to match your search criteria.
            Please try again with some different keywords.
            <span style={{ opacity: 0 }}>
              --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
              --- --- --- --- --- --- --- --- --- --- --- --- ---
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

FAQSBase.propTypes = exact({
  classes: PropTypes.object.isRequired,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  expandedFaqs: PropTypes.arrayOf(PropTypes.string),
  onFaqClick: PropTypes.func,
  answersVariables: PropTypes.object,
  onFaqExpanded: PropTypes.func,
  resizeSearch: PropTypes.bool,
});

export const FAQS = withStyles(stylesFn)(React.memo(FAQSBase));
FAQS.displayName = 'FAQS';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
// import partition from 'lodash/partition';
import AddIcon from '@material-ui/icons/Add';
import { Button, Menu, MenuItem, Chip, ListSubheader } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './SelectQuestionnaires.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { addClientEventLogger } from '../addClientModalUtils';

const getNumberOfQuestionsText = questionnaire =>
  questionnaire.questions.length === 1
    ? '1 question'
    : `${questionnaire.questions.length} questions`;

const SelectQuestionnairesBase = ({
  selectedQuestionnaires,
  onAddQuestionnaire,
  onDeleteQuestionnaire,
  onDiscardAllQuestionnaires,
  questionnaires,
  shouldShowDescription,
  isSelfRegThatDoneAssessment,
}) => {
  const t = useTranslation();
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const onOpenQuestionnairesMenu = event =>
    setMenuAnchorElement(event.currentTarget);

  // const partitionedQuestionnaires = partition(
  //   questionnaires,
  //   questionnaire => !questionnaire.clinicId
  // );

  const partitionedQuestionnaires = [];
  partitionedQuestionnaires[0] = questionnaires.filter(
    questionnaire => questionnaire.isStandardized
  );
  partitionedQuestionnaires[1] = questionnaires.filter(
    questionnaire =>
      questionnaire.includeByDefault && !questionnaire.isStandardized
  );
  partitionedQuestionnaires[2] = questionnaires.filter(
    questionnaire => questionnaire.clinicId && !questionnaire.isDeleted
  );

  const mapQuestionnaireToMenuItem = questionnaire => {
    if (questionnaire.fullName) {
      return (
        <TooltipWrapper
          title={<TooltipTitle>{questionnaire.fullName}</TooltipTitle>}
          placement="right-start"
        >
          <MenuItem
            onClick={() => {
              setMenuAnchorElement(null);
              onAddQuestionnaire(questionnaire);
            }}
            key={questionnaire.id}
          >
            <span className={styles.questionnaire_name}>
              {questionnaire.name}
            </span>
          </MenuItem>
        </TooltipWrapper>
      );
    }
    return (
      <MenuItem
        onClick={() => {
          setMenuAnchorElement(null);
          onAddQuestionnaire(questionnaire);
        }}
        key={questionnaire.id}
      >
        <span className={styles.questionnaire_name}>{questionnaire.name}</span>
      </MenuItem>
    );
  };

  return (
    <div>
      {isSelfRegThatDoneAssessment ? (
        <CustomAlert
          severity="success"
          styleToAdd={{ position: 'relative', top: -30 }}
        >
          This client completed the default assessment. Any new questionnaires
          will be added to their next assessment.
        </CustomAlert>
      ) : null}
      {shouldShowDescription && (
        <p>
          {t('recommended-questionnaires-description')}{' '}
          <a
            href="https://intercom.help/dashboard-myndlift/en/articles/4488496-symptom-questionnaires-for-tracking-progress"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about these questionnaires
          </a>
          .
        </p>
      )}

      <div>
        <Button
          variant="text"
          color="primary"
          onClick={onOpenQuestionnairesMenu}
        >
          <AddIcon />{' '}
          <span className={styles.btn_text}>{t('add-questionnaire')}</span>
        </Button>

        <Button
          variant="text"
          color="primary"
          onClick={onDiscardAllQuestionnaires}
        >
          <CloseIcon />{' '}
          <span className={styles.btn_text}>{t('discard-all')}</span>
        </Button>
      </div>

      <div className={styles.selected_questionnaires_container}>
        {selectedQuestionnaires.map(questionnaire => {
          if (questionnaire.fullName) {
            return (
              <TooltipWrapper
                title={<TooltipTitle>{questionnaire.fullName}</TooltipTitle>}
              >
                <Chip
                  classes={{
                    label: styles.chip_label,
                    root: styles.chip_root,
                  }}
                  label={`${questionnaire.name} (${getNumberOfQuestionsText(
                    questionnaire
                  )})`}
                  onDelete={() => onDeleteQuestionnaire(questionnaire)}
                  key={questionnaire.id}
                />
              </TooltipWrapper>
            );
          }
          return (
            <Chip
              classes={{
                label: styles.chip_label,
                root: styles.chip_root,
              }}
              label={`${questionnaire.name} (${getNumberOfQuestionsText(
                questionnaire
              )})`}
              onDelete={() => onDeleteQuestionnaire(questionnaire)}
              key={questionnaire.id}
            />
          );
        })}
      </div>

      <Menu
        anchorEl={menuAnchorElement}
        keepMounted
        open={!!menuAnchorElement}
        onClose={() => setMenuAnchorElement(null)}
      >
        <ListSubheader className={styles.list_sub_header}>
          <span className={styles.questionnaire_name}>{t('standardized')}</span>
        </ListSubheader>
        {partitionedQuestionnaires[0].map(mapQuestionnaireToMenuItem)}

        <ListSubheader className={styles.list_sub_header}>
          <span className={styles.questionnaire_name}>Default</span>
        </ListSubheader>
        {partitionedQuestionnaires[1].map(mapQuestionnaireToMenuItem)}

        <div>
          <ListSubheader className={styles.list_sub_header}>
            <span className={styles.questionnaire_name}>{t('custom')}</span>
          </ListSubheader>
          {partitionedQuestionnaires[2].map(mapQuestionnaireToMenuItem)}
          <MenuItem
            onClick={() => {
              setMenuAnchorElement(null);
              window.open('/manage-questionnaires.html');
              addClientEventLogger.log('on_create_custom_questionnaire');
            }}
            key="add custom questionnaire"
          >
            <span className={styles.link_to_create_custom_questionnaire}>
              {t('create-a-questionnaire')}
            </span>
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
};

SelectQuestionnairesBase.propTypes = exact({
  selectedQuestionnaires: PropTypes.array.isRequired,
  onAddQuestionnaire: PropTypes.func.isRequired,
  onDeleteQuestionnaire: PropTypes.func.isRequired,
  onDiscardAllQuestionnaires: PropTypes.func.isRequired,
  questionnaires: PropTypes.array.isRequired,
  shouldShowDescription: PropTypes.bool.isRequired,
  isSelfRegThatDoneAssessment: PropTypes.bool,
});

export const SelectQuestionnaires = React.memo(SelectQuestionnairesBase);
SelectQuestionnaires.displayName = 'SelectQuestionnaires';

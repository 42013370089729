import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import styles from './NFAddonsDialog.scss';
import { getEventLogger } from '../../utils/utils';

const logClinicRegistrationEvent = getEventLogger({
  prefix: 'clinic_register',
});

const NFAddonsDialogBase = ({
  priceDialogOpen,
  setPriceDialogOpen,
  NFPackages,
}) => {
  return (
    <Dialog
      open={priceDialogOpen}
      onClose={() => {
        setPriceDialogOpen(false);
      }}
      maxWidth="md"
      scroll="body"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginRight: 10,
        }}
        onClick={() => {
          setPriceDialogOpen(false);
        }}
      >
        <CloseIcon />
      </div>
      <div className={styles.price_dialog_view}>
        <div className={styles.price_dialog_title}>
          How we calculate the Neuro price
        </div>
        <div className={styles.price_dialog_container}>
          <div className={styles.price_dialog_container_top}>
            <div className={styles.price_dialog_dual_item}>
              <div className={styles.price_dialog_dual_item_left}>
                Base price
              </div>
              <div>
                <div className={styles.price_dialog_dual_item_right_top}>
                  ${NFPackages.basePrice}/
                  <span style={{ fontWeight: 400, fontSize: 14 }}>mo</span>
                </div>
                <div style={{ fontSize: 12 }}>
                  monthly or{' '}
                  <span style={{ fontWeight: 500 }}>
                    ${NFPackages.annualMonthlyBasePrice}
                    /mo
                  </span>{' '}
                  annually
                </div>
              </div>
            </div>
            <div className={styles.divider} />
            <div>
              {NFPackages.features.map(feature => {
                return (
                  <div
                    className={styles.feature_item}
                    key={feature.feature}
                    style={{ fontSize: 14 }}
                  >
                    <CheckIcon style={{ color: '#829EDE', marginRight: 5 }} />
                    {feature.feature}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.price_dialog_container_buttom}>
            <div
              className={styles.price_dialog_dual_item}
              style={{ marginTop: 15 }}
            >
              <div className={styles.price_dialog_dual_item_left}>
                Training usage
              </div>
              <div>
                <div className={styles.price_dialog_dual_item_right_top}>
                  + ${NFPackages.userPrice}/
                  <span style={{ fontWeight: 400, fontSize: 14 }}>
                    active client
                  </span>
                </div>
                <div style={{ textAlign: 'end', fontSize: 12 }}>
                  Prorated pricing*
                </div>
              </div>
            </div>
            <div className={styles.divider} />
            <div style={{ fontSize: 14, fontWeight: 500, marginTop: 15 }}>
              A monthly charge when an existing client does more than 5 minutes
              of neurofeedback training in the billing period.
            </div>
            <div style={{ fontSize: 12, marginTop: 15 }}>
              *If a client becomes active partway through the billing period,
              you’re only charged for the days from the training start date.
            </div>
          </div>
        </div>
        <div style={{ fontSize: 14, marginBottom: 20 }}>
          To learn more about our pricing,
          <span
            style={{ cursor: 'pointer', color: '#0075F3', fontWeight: 500 }}
            onClick={() => {
              const planInfoStorage = JSON.parse(
                // eslint-disable-next-line no-undef
                localStorage.getItem('planInfo')
              );
              logClinicRegistrationEvent({
                name: 'contact_sales_clicked',
                data: {
                  session_id: planInfoStorage.sessionId,
                  isNF: true,
                  from_where: 'addon_popup',
                },
              });
              window.open(
                `https://calendly.com/myndlift-demo/myndlift-demo-v2?month=2022-06&utm_campaign=cliniccheckout&utm_source=dashboard&utm_content=${
                  sessionStorage.userId
                    ? sessionStorage.userId
                    : planInfoStorage.sessionId
                }`,
                '_blank'
              );
            }}
          >
            {' '}
            contact us
          </span>
        </div>
      </div>
    </Dialog>
  );
};

NFAddonsDialogBase.propTypes = exact({
  priceDialogOpen: PropTypes.bool.isRequired,
  setPriceDialogOpen: PropTypes.func.isRequired,
  NFPackages: PropTypes.object.isRequired,
});

export const NFAddonsDialog = React.memo(NFAddonsDialogBase);
NFAddonsDialog.displayName = 'NFAddonsDialog';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Range } from 'rc-slider';
import styles from './SessionFilters.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import 'rc-slider/assets/index.css';

const handleStyle = {
  background: '#50a0ff',
  borderColor: '#50a0ff',
  width: 22,
  height: 22,
  marginTop: '-9px',
};

export const SliderFilterContent = ({
  minValue,
  maxValue,
  minValueFormatted,
  maxValueFormatted,
  onMinValueChange,
  onMaxValueChange,
  onSliderValuesChange,
  min,
  max,
  step,
}) => {
  const t = useTranslation();
  // this is a hack because of a bug in the range component ... it is calling the onChange
  // because of change from values from outside even though the user is not changing
  const [shouldCallRangeOnChange, setShouldCallRangeOnChange] = useState(false);

  return (
    <div className={styles.slider_filter_content}>
      <div className={styles.slider_filter_content_inputs_container}>
        <div>
          <p>{t('minimum')}</p>
          <TextField
            error={minValue > maxValue}
            value={minValueFormatted || minValue}
            onChange={onMinValueChange}
            variant="outlined"
            inputProps={{
              'aria-label': 'bare',
              min,
              style: { textAlign: 'center' },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              className: styles.slider_filter_input,
            }}
            disabled={min === max}
          />
        </div>
        <div className={styles.slider_filter_word_to_container}>
          <p>To</p>
        </div>
        <div>
          <p>{t('maximum')}</p>

          <TextField
            error={minValue > maxValue}
            value={maxValueFormatted || maxValue}
            onChange={onMaxValueChange}
            variant="outlined"
            inputProps={{
              'aria-label': 'bare',
              max,
              style: { textAlign: 'center' },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              className: styles.slider_filter_input,
            }}
            disabled={min === max}
          />
        </div>
      </div>
      <div className={styles.slider_filter_content_slider_container}>
        <Range
          step={step}
          value={[minValue, maxValue]}
          min={min}
          max={max}
          count={2}
          onChange={values =>
            shouldCallRangeOnChange && onSliderValuesChange(values)
          }
          onBeforeChange={() => setShouldCallRangeOnChange(true)}
          onAfterChange={() => setShouldCallRangeOnChange(false)}
          handleStyle={[handleStyle, handleStyle]}
          trackStyle={[{ background: '#50a0ff' }]}
          disabled={min === max}
          className={styles.range_root}
        />
      </div>
    </div>
  );
};

SliderFilterContent.propTypes = {
  minValue: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  maxValue: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onMinValueChange: PropTypes.func.isRequired,
  onMaxValueChange: PropTypes.func.isRequired,
  minValueFormatted: PropTypes.string,
  maxValueFormatted: PropTypes.string,
  onSliderValuesChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

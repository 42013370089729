import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import UpgradeDialogImg from '../../../assets/neuro_promo.png';
import { ModalFooter } from '../../Core/Components/Modal/ModalFooter';

const NeuroPromoDialogBase = ({
  addClientReachedLimitModal,
  setAddClientReachedLimitModal,
  canAccessNf,
  onViewPricingPlansClicked,
  fromWhere,
}) => {
  if (canAccessNf === false) {
    return (
      <Dialog
        open={addClientReachedLimitModal}
        onClose={() => {
          setAddClientReachedLimitModal(false);
        }}
      >
        <img
          src={UpgradeDialogImg}
          width={120}
          height={135}
          style={{ alignSelf: 'center', marginTop: 20 }}
        />
        <DialogTitle style={{ alignSelf: 'center' }}>
          <div style={{ fontSize: 16, fontWeight: 400 }}>
            Offer neurofeedback training and EEG sensing
          </div>
        </DialogTitle>
        <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
          <div style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}>
            Myndlift allows you to offer seamless neurofeeedback with fully
            customizable training protocols, a comprehensive clinical dashboard,
            and a consumer-grade app.
          </div>
          <div
            style={{
              fontSize: 13,
              fontWeight: 600,
              textAlign: 'center',
              marginTop: 10,
            }}
          >
            Contact sales to find out more on how you can start.
          </div>
        </DialogContent>
        <ModalFooter
          primaryBtnContent="contact sales"
          secondaryBtnContent="cancel"
          onPrimaryBtnClick={() => {
            const planInfoStorage = JSON.parse(
              // eslint-disable-next-line no-undef
              localStorage.getItem('planInfo')
            );
            new MyEvent('contact_sales_modal').log({
              action: 'clicked',
              from_where: fromWhere,
            });
            window.open(
              `https://calendly.com/myndlift-demo/myndlift-demo-v2?month=2022-06&utm_campaign=cliniccheckout&utm_source=dashboard&utm_content=${
                sessionStorage.userId
                  ? sessionStorage.userId
                  : planInfoStorage.sessionId
              }`,
              '_blank'
            );
          }}
          onSecondaryBtnClick={() => {
            new MyEvent('contact_sales_modal').log({
              action: 'close',
              from_where: fromWhere,
            });
            setAddClientReachedLimitModal(false);
          }}
        />
      </Dialog>
    );
  }

  return (
    <Dialog
      open={addClientReachedLimitModal}
      onClose={() => {
        setAddClientReachedLimitModal(false);
      }}
    >
      <img
        src={UpgradeDialogImg}
        width={120}
        height={135}
        style={{ alignSelf: 'center', marginTop: 20 }}
      />
      <DialogTitle style={{ alignSelf: 'center' }}>
        <div style={{ fontSize: 16, fontWeight: 400 }}>
          Want neurofeedback? We’ve got you!
        </div>
      </DialogTitle>
      <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
        <div style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}>
          Add Neuro to your plan to start assessing clients using EEG and to set
          them up with neurofeedback training programs.
        </div>
      </DialogContent>
      <ModalFooter
        primaryBtnContent="add neuro"
        secondaryBtnContent="cancel"
        onPrimaryBtnClick={() => {
          onViewPricingPlansClicked();
          new MyEvent('add_neuro_modal').log({
            action: 'clicked',
            from_where: fromWhere,
          });
        }}
        onSecondaryBtnClick={() => {
          new MyEvent('add_neuro_modal').log({
            action: 'close',
            from_where: fromWhere,
          });
          setAddClientReachedLimitModal(false);
        }}
      />
    </Dialog>
  );
};

NeuroPromoDialogBase.propTypes = exact({
  addClientReachedLimitModal: PropTypes.bool.isRequired,
  setAddClientReachedLimitModal: PropTypes.func.isRequired,
  canAccessNf: PropTypes.bool,
  onViewPricingPlansClicked: PropTypes.func,
  fromWhere: PropTypes.string,
});

export const NeuroPromoDialog = React.memo(NeuroPromoDialogBase);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import styles from './UsageDetailsPerUserDialog.scss';
import { CustomTable } from '../../../../Core/Components/CustomTable/CustomTable';
import { formatSecondsToMMSS } from '../../../../utils/utils';

const UsageDetailsPerUserDialogBase = ({
  headsetUsageDetails,
  headsetId,
  billingCycle,
  onClose,
  onConfirm,
  isOpen,
}) => {
  const rawTableHeads = ['Client Name', 'Client Email', 'Minutes Used'];
  const tableHeads = rawTableHeads.map(tableHeadText => ({
    node: <span className={styles.head_cell}>{tableHeadText}</span>,
    id: tableHeadText,
  }));

  const columnsIds = ['clientName', 'clientEmail', 'minutesUsed'];
  const rawTableRows =
    headsetUsageDetails &&
    Object.values(headsetUsageDetails.users).map(userHeadsetUsageDetails => [
      userHeadsetUsageDetails.name,
      userHeadsetUsageDetails.email,
      formatSecondsToMMSS({
        seconds: userHeadsetUsageDetails.totalDuration,
        shouldShowSeconds: false,
      }),
    ]);

  const tableRows =
    headsetUsageDetails &&
    Object.keys(headsetUsageDetails.users).map((userId, userIndex) => ({
      id: userId,
      cells: rawTableRows[userIndex]
        .map((rawTextContent, contentIndex) =>
          contentIndex === 0 ? (
            <span className={styles.cell}>
              <a
                role="button"
                tabIndex={0}
                onClick={async () => {
                  try {
                    const customerId = userId;
                    const customerInfo = await database
                      .ref(`userInfo/${customerId}`)
                      .once('value')
                      .then(snapshot => snapshot.val());
                    sessionStorage.customerInfo = JSON.stringify(customerInfo);
                    sessionStorage.customerId = customerId;
                    window.open(
                      `userPage.html?customerId=${sessionStorage.customerId}`,
                      '_self'
                    );
                  } catch (e) {
                    showNotification('danger', 'This user was deleted');
                  }
                }}
              >
                {rawTextContent}
              </a>
            </span>
          ) : (
            <span className={styles.cell}>{rawTextContent}</span>
          )
        )
        .map((node, index) => ({
          id: columnsIds[index],
          node,
        })),
    }));

  const onDownloadAsCSV = useCallback(() => {
    convertToCSVAndDownload(
      [rawTableHeads].concat(rawTableRows),
      `headset-${headsetId}-${billingCycle}`
    );
  }, [rawTableHeads, rawTableRows, billingCycle, headsetId]);

  return headsetId !== null ? (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent classes={{ root: styles.dialog_content_root }}>
        <div>
          <div className={styles.header}>
            <h3>Usage For Headset {headsetId}</h3>
            <p>Billing Cycle: {billingCycle}</p>
          </div>

          <div className={styles.table_container}>
            <CustomTable tableHeads={tableHeads} tableRows={tableRows} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDownloadAsCSV} color="default" variant="text">
          <span className={styles.button}>Download as CSV</span>
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          <span className={styles.button}>Done</span>
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

UsageDetailsPerUserDialogBase.propTypes = exact({
  headsetUsageDetails: PropTypes.object,
  headsetId: PropTypes.string,
  billingCycle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
});

export const UsageDetailsPerUserDialog = React.memo(
  UsageDetailsPerUserDialogBase
);

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import ReactJson from 'react-json-view';
import {
  Button,
  TextField,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core';
import styles from './DynamicAPIs.scss';
import { injectIDs } from '../../utils/utils';
import { Loader } from '../../Core/Components/Loader/Loader';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { WrapConditionally } from '../../Core/Components/WrapConditionally.jsx/WrapConditionally';

const UserProfile = ({ userInfo }) => {
  const introductionWord = userInfo.clinicId ? 'Customer' : 'Clinic';
  return (
    <div className={styles.user_profile}>
      <p>
        {introductionWord} id: {userInfo.id}
      </p>
      <p>
        {introductionWord} email: {userInfo.email}
      </p>
    </div>
  );
};

UserProfile.propTypes = exact({
  userInfo: PropTypes.object.isRequired,
});

const APIsButtons = ({ APIs, onDynamicAPIButtonClick }) => {
  return (
    <div className={styles.buttons_container}>
      {APIs.map(api => (
        <WrapConditionally
          key={api.id}
          condition={!!api?.description}
          wrap={children => (
            <TooltipWrapper
              title={<TooltipTitle>{api.description}</TooltipTitle>}
            >
              <div>{children}</div>
            </TooltipWrapper>
          )}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => onDynamicAPIButtonClick(api)}
            key={`button-${api.id}`}
          >
            {api.buttonText || api.id}
          </Button>
        </WrapConditionally>
      ))}
    </div>
  );
};

APIsButtons.propTypes = exact({
  APIs: PropTypes.array.isRequired,
  onDynamicAPIButtonClick: PropTypes.func.isRequired,
});

const API_FORM_DIALOG_RENDERED_STEPS = {
  form: 'form',
  loading: 'loading',
  apiCallFailed: 'apiCallFailed',
  apiCallSucceeded: 'apiCallSucceeded',
  confirmationMsg: 'confirmationMsg',
};

const APIFormDialog = ({
  API,
  onAPIFieldChange,
  formValues,
  formErrors,
  onConfirm,
  onCancel,
  renderedStep,
  error: errorDescription,
  onClose,
  onBackToForm,
  onConfirmAPICall,
  apiStepIndex,
  prvAPIsResults,
}) => {
  const InputField = useCallback(
    ({ field, name, value, onChange, placeholder, errorMsg }) => {
      if (
        field.type === 'string' ||
        field.type === 'float' ||
        field.type === 'integer'
      ) {
        return (
          <TextField
            label={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={`${placeholder}`}
            error={!!errorMsg}
            helperText={errorMsg}
          />
        );
      }

      if (field.type === 'long') {
        return (
          <TextField
            label={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={`${placeholder}`}
            error={!!errorMsg}
            helperText={errorMsg}
            multiline
            rows={field.rows}
            maxRows={field.rows}
          />
        );
      }

      if (field.type === 'boolean') {
        return (
          <div style={{ marginTop: 12, position: 'relative' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  color="primary"
                  name={name}
                />
              }
              label={<>{placeholder}</>}
              labelPlacement="start"
              className={styles.form_control_label_checkbox}
              classes={{
                label: styles.label_checkbox,
              }}
            />
            <p style={{ position: 'absolute', top: -10 }}>{name}</p>
          </div>
        );
      }

      if (field.type === 'select') {
        const options = field.preFill
          ? get(prvAPIsResults, field.preFill.split('<>'))
          : field.options;
        return (
          <TextField
            select
            label={name}
            value={value}
            onChange={onChange}
            name={name}
            error={!!errorMsg}
            helperText={errorMsg || placeholder}
          >
            {options.map(option =>
              typeof option === 'object' ? (
                <MenuItem key={option.value} value={option.value}>
                  <span className={styles.form_font}>{option.label}</span>
                </MenuItem>
              ) : (
                <MenuItem key={option} value={option}>
                  <span className={styles.form_font}>{option}</span>
                </MenuItem>
              )
            )}
          </TextField>
        );
      }

      return null;
    },
    [prvAPIsResults]
  );

  const apiCurrentStep = API.steps[apiStepIndex];
  const currentStepFields = apiCurrentStep.fields;
  const renderForm = () => (
    <div className={styles.fields_container}>
      {Object.entries(currentStepFields).map(([fieldName, field]) => (
        <InputField
          field={field}
          key={fieldName}
          value={
            formValues[fieldName] !== undefined ? formValues[fieldName] : ''
          }
          onChange={event => onAPIFieldChange(event, field)}
          placeholder={field.placeholder || fieldName}
          name={fieldName}
          errorMsg={formErrors[fieldName]}
        />
      ))}
    </div>
  );

  return (
    <React.Fragment>
      <Dialog open className={styles.dialog_root}>
        <DialogTitle>
          API call: {apiCurrentStep.endpointType}:{apiCurrentStep.endpointName}
        </DialogTitle>
        <DialogContent>
          {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.form && renderForm()}
          {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.loading && (
            <Loader />
          )}
          {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.apiCallSucceeded && (
            <div>
              <p>API call succeeded</p>
            </div>
          )}
          {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.confirmationMsg && (
            <div>
              <p>{apiCurrentStep.confirmationMsg}</p>
              <ReactJson
                src={formValues}
                theme="monokai"
                collapsed={false}
                name="API payload"
                displayObjectSize
                displayDataTypes
                enableClipboard={false}
              />
            </div>
          )}
          {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.apiCallFailed && (
            <div>
              <p>API call failed</p>
              {errorDescription && (
                <p style={{ color: '#D8000C' }}>{errorDescription}</p>
              )}
            </div>
          )}
        </DialogContent>
        {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.form && (
          <DialogActions>
            <Button onClick={onCancel} color="default">
              Cancel
            </Button>
            <Button onClick={onConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        )}
        {renderedStep === API_FORM_DIALOG_RENDERED_STEPS.confirmationMsg && (
          <DialogActions>
            <Button onClick={onBackToForm} color="default">
              back
            </Button>
            <Button onClick={onConfirmAPICall} color="primary">
              Confirm
            </Button>
          </DialogActions>
        )}
        {(renderedStep === API_FORM_DIALOG_RENDERED_STEPS.apiCallSucceeded ||
          renderedStep === API_FORM_DIALOG_RENDERED_STEPS.apiCallFailed) && (
          <DialogActions>
            <Button onClick={onClose} color="primary">
              ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

APIFormDialog.propTypes = exact({
  API: PropTypes.object.isRequired,
  onAPIFieldChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  renderedStep: PropTypes.oneOf([
    API_FORM_DIALOG_RENDERED_STEPS.form,
    API_FORM_DIALOG_RENDERED_STEPS.loading,
    API_FORM_DIALOG_RENDERED_STEPS.confirmationMsg,
    API_FORM_DIALOG_RENDERED_STEPS.apiCallSucceeded,
    API_FORM_DIALOG_RENDERED_STEPS.apiCallFailed,
  ]).isRequired,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  confirmationMsg: PropTypes.string,
  onBackToForm: PropTypes.func.isRequired,
  onConfirmAPICall: PropTypes.func.isRequired,
  apiStepIndex: PropTypes.number.isRequired,
  prvAPIsResults: PropTypes.object.isRequired,
});

const DynamicAPIsBase = ({ userInfo, APIs }) => {
  const filteredAPIs = injectIDs(
    pickBy(
      APIs,
      api =>
        !api.isDeleted &&
        !api?.deprecated === true &&
        (userInfo.clinicId
          ? api.accountType === 'customer' || api.accountType === 'any'
          : api.accountType === 'clinic' || api.accountType === 'any')
    )
  );

  const accountLessAPIs = injectIDs(
    pickBy(APIs, api => !api.isDeleted && api.accountType === 'none')
  );

  const [currentAPI, setCurrentAPI] = useState(null);
  const [currentAPIStepIndex, setCurrentAPIStepIndex] = useState(null);
  const [APIFormRenderedStep, setAPIFormRenderedStep] = useState(null);
  const [APIFormErrorDescription, setAPIFormErrorDescription] = useState(null);
  const onDynamicAPIButtonClick = useCallback(api => {
    const booleanFields = {};
    api.steps.forEach(step => {
      Object.keys(step.fields).forEach(fieldName => {
        if (step.fields[fieldName].type === 'boolean') {
          booleanFields[fieldName] = false;
        }
      });
    });
    // eslint-disable-next-line no-use-before-define
    setFormValues(booleanFields);
    setCurrentAPI(api);
    setCurrentAPIStepIndex(0);
    setAPIFormRenderedStep(API_FORM_DIALOG_RENDERED_STEPS.form);
  }, []);

  const [formValues, setFormValues] = useState({});
  const [prvAPIsResults, setPrvAPIsResults] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [displayDataStudio, setDisplayDataStudio] = useState(false);
  const [displayHeadsetSearch, setDisplayHeadsetSearch] = useState(false);

  const onAPIFieldChange = useCallback(
    (event, field) => {
      const fieldName = event.target.name;
      const fieldValue = (() => {
        if (field.type === 'boolean') {
          return event.target.checked;
        }
        const inputValue = event.target.value;
        if (field.type === 'integer') {
          return parseInt(inputValue, 10);
        }
        if (field.type === 'float') {
          return parseFloat(inputValue, 10);
        }

        return inputValue;
      })();

      setFormValues({ ...formValues, [fieldName]: fieldValue });
    },
    [formValues, setFormValues]
  );

  const validateFormValues = useCallback(() => {
    const apiFields = currentAPI.steps[currentAPIStepIndex].fields;
    const errors = Object.entries(apiFields).reduce(
      (acc, [fieldName, field]) => {
        const fieldValue = formValues[fieldName];

        if (fieldValue !== undefined) {
          if (field.type === 'float' && !Number.isFinite(fieldValue)) {
            acc[fieldName] = `${fieldName} should be float`;
          }

          if (field.type === 'integer' && !Number.isInteger(fieldValue)) {
            acc[fieldName] = `${fieldName} should be integer`;
          }
        }

        if (field.isRequired) {
          if (
            fieldValue === undefined ||
            (field.type === 'string' && fieldValue.trim() === '')
          ) {
            acc[fieldName] = `${fieldName} is required`;
          }
        }

        return acc;
      },
      {}
    );

    return errors;
  }, [formValues, currentAPIStepIndex, currentAPI]);

  const fireAPICall = useCallback(async () => {
    const currentAPIStep = currentAPI.steps[currentAPIStepIndex];
    try {
      setAPIFormRenderedStep(API_FORM_DIALOG_RENDERED_STEPS.loading);
      const apiCall = (() => {
        // if (currentAPIStep.endpointType === 'node') {
        //   return () =>
        //     fireNodePost(currentAPIStep.endpointName, {
        //       clientId: userInfo.id,
        //       ...formValues,
        //     });
        // }

        if (currentAPIStep.endpointType === 'functions') {
          return () =>
            // eslint-disable-next-line no-undef
            fireFunctionPost(currentAPIStep.endpointName, {
              clientId: userInfo.id,
              ...formValues,
            });
        }

        // if (currentAPIStep.endpointType === 'python') {
        //   return () =>
        //     // eslint-disable-next-line no-undef
        //     fireFunctionPost(currentAPIStep.endpointName, {
        //       clientId: userInfo.id,
        //       ...formValues,
        //     });
        // }

        // was created to make testing easier, but not intended for real use
        if (currentAPIStep.endpointType === 'absolute') {
          return () =>
            window
              .fetch(currentAPIStep.endpointName, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ clientId: userInfo.id, ...formValues }),
              })
              .then(response => response.json());
        }

        throw new Error(
          `Unsupported endpointType: ${currentAPIStep.endpointType}`
        );
      })();

      const response = await apiCall();

      if (response.result && response.result === true) {
        setPrvAPIsResults({
          ...prvAPIsResults,
          [currentAPIStep.endpointType]: {
            [currentAPIStep.endpointName]: response,
          },
        });
        setFormValues({});
        setFormErrors({});

        if (currentAPIStepIndex === currentAPI.steps.length - 1) {
          setAPIFormRenderedStep(
            API_FORM_DIALOG_RENDERED_STEPS.apiCallSucceeded
          );
        } else {
          setCurrentAPIStepIndex(prvIndex => prvIndex + 1);
          setAPIFormRenderedStep(API_FORM_DIALOG_RENDERED_STEPS.form);
        }
      } else {
        throw new Error(
          response.message || 'API CALL FAILED FOR UNKNOWN REASON'
        );
      }
    } catch (err) {
      const msg =
        err.result === false ? err.message : err.stack || err.toString();
      setAPIFormRenderedStep(API_FORM_DIALOG_RENDERED_STEPS.apiCallFailed);
      setAPIFormErrorDescription(msg);
    }
  }, [currentAPI, formValues, currentAPIStepIndex, prvAPIsResults, userInfo]);

  const closeAPIFormDialog = () => {
    setCurrentAPI(null);
    setAPIFormRenderedStep(API_FORM_DIALOG_RENDERED_STEPS.form);
    setFormValues({});
    setFormErrors({});
  };

  return (
    <React.Fragment>
      {!isEmpty(userInfo) && (
        <Paper className={styles.content_container}>
          <UserProfile userInfo={userInfo} />
          <APIsButtons
            APIs={Object.values(filteredAPIs)}
            onDynamicAPIButtonClick={onDynamicAPIButtonClick}
          />
        </Paper>
      )}

      <Paper className={styles.content_container}>
        <p>APIs that require no accounts</p>
        <APIsButtons
          APIs={Object.values(accountLessAPIs)}
          onDynamicAPIButtonClick={onDynamicAPIButtonClick}
        />
      </Paper>

      {!isEmpty(userInfo) && !displayDataStudio && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDisplayDataStudio(true);
          }}
          style={{ fontSize: '1.1em', marginBottom: 20 }}
        >
          Show crashes stats
        </Button>
      )}

      {!displayHeadsetSearch && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDisplayHeadsetSearch(true);
          }}
          style={{ fontSize: '1.1em', marginBottom: 20 }}
        >
          Search Headband
        </Button>
      )}

      {!isEmpty(userInfo) && displayDataStudio && (
        <Paper>
          <iframe
            title="Crashes analytics"
            width="100%"
            height="450"
            src={`https://datastudio.google.com/embed/reporting/a680fce0-2bb4-4515-a529-09992963d392/page/5u8WC?params=%7B%22ds0.userid%22:%22${userInfo.id}%22%7D`}
          />
        </Paper>
      )}

      {displayHeadsetSearch && (
        <Paper>
          <iframe
            title="Search Headband"
            width="100%"
            height="450"
            src="https://lookerstudio.google.com/embed/reporting/291bf9ef-f01d-4df9-aed6-9f6d388d148b/page/K9GPD"
          />
        </Paper>
      )}

      {currentAPI && (
        <APIFormDialog
          API={currentAPI}
          apiStepIndex={currentAPIStepIndex}
          onCancel={closeAPIFormDialog}
          onConfirm={() => {
            const errors = validateFormValues();
            if (Object.values(errors).length === 0) {
              setAPIFormRenderedStep(
                API_FORM_DIALOG_RENDERED_STEPS.confirmationMsg
              );
            } else {
              setFormErrors(errors);
            }
          }}
          onAPIFieldChange={onAPIFieldChange}
          formValues={formValues}
          formErrors={formErrors}
          prvAPIsResults={prvAPIsResults}
          renderedStep={APIFormRenderedStep}
          error={APIFormErrorDescription}
          onClose={closeAPIFormDialog}
          onBackToForm={() =>
            setAPIFormRenderedStep(API_FORM_DIALOG_RENDERED_STEPS.form)
          }
          onConfirmAPICall={fireAPICall}
        />
      )}
    </React.Fragment>
  );
};

DynamicAPIsBase.defaultProps = {
  userInfo: {},
};

DynamicAPIsBase.propTypes = exact({
  userInfo: PropTypes.object,
  APIs: PropTypes.object.isRequired,
});

export const DynamicAPIs = React.memo(DynamicAPIsBase);
DynamicAPIs.displayName = 'DynamicAPIs';

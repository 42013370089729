import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  TextField,
  DialogActions,
  Button,
  InputAdornment,
} from '@material-ui/core';
import countryTelData from 'country-telephone-data';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import StarsIcon from '@material-ui/icons/Stars';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import LanguageIcon from '@material-ui/icons/Language';
import ChipInput from 'material-ui-chip-input';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import styles from './AddEditListingDialog.scss';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { Loader } from '../../../Core/Components/Loader/Loader';
import listingAddedImage from '../../../../assets/listing_added.png';
import { removeAllAfterLastChar, bifurcateArrBy } from '../../../utils/utils';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { paragraphFontSize, xsMediaMaxWidth } from '../../../cssInJs/constants';
import { useMediaMatch } from '../../../Core/hooks/useMediaMatch';

const clonedCountryTelData = cloneDeep(countryTelData);
const countriesDataList = clonedCountryTelData.allCountries.map(country => {
  const newCountry = cloneDeep(country);
  newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
  return newCountry;
});

const preferredCountriesNames = [
  'United States',
  'United Kingdom',
  'Germany',
  'Canada',
  'Israel',
];

const [
  preferredCountriesDataList,
  otherCountriesDataList,
] = bifurcateArrBy(countriesDataList, countryData =>
  preferredCountriesNames.includes(countryData.name)
);

const mapCountriesDataListToOptions = countryData => (
  <option
    key={`${countryData.name} ${countryData.dialCode}`}
    value={countryData.name}
  >
    {countryData.name}
  </option>
);

const AddEditListingDialogBase = ({
  isOpen,
  register,
  onClose,
  onConfirm,
  errors,
  biography,
  onBiographyChange,
  topSpecialties,
  otherSpecialties,
  onTopSpecialtiesAdd,
  onTopSpecialtiesDelete,
  onOtherSpecialtiesAdd,
  onOtherSpecialtiesDelete,
  onImageFileChange,
  languages,
  allLanguages,
  onLanguagesChange,
  imageFile,
  isEditMode,
  renderedStep,
  biographyMaxLength,
}) => {
  const t = useTranslation();

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  const renderStepForm = () => (
    <React.Fragment>
      <div className={styles.dialog_header}>
        <div className={styles.content}>
          <p>{t('list-a-clinician')}</p>
          <p>{t('list-a-clinician-description')}</p>
          <div className={styles.close_icon_container}>
            <CloseIcon onClick={onClose} style={{ fontSize: 18 }} />
          </div>
        </div>
      </div>
      <div className={styles.form_content}>
        <div className={styles.fields}>
          <div className={errors.clinicName ? styles.error : ''}>
            <LocalHospitalIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Clinic Name"
              inputProps={{
                name: 'clinicName',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.clinicName}
              helperText={errors.clinicName && errors.clinicName.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>

          <div className={errors.firstName ? styles.error : ''}>
            <PersonIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="First Name"
              inputProps={{
                name: 'firstName',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>

          <div className={errors.lastName ? styles.error : ''}>
            <PersonIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Last Name"
              inputProps={{
                name: 'lastName',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>

          <div className={errors.address ? styles.error : ''}>
            <LocationCityIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Address"
              inputProps={{
                name: 'address',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.address}
              helperText={errors.address && errors.address.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>

          <div className={errors.city ? styles.error : ''}>
            <LocationCityIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="City"
              inputProps={{
                name: 'city',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.city}
              helperText={errors.city && errors.city.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>
          <div className={errors.state ? styles.error : ''}>
            <LocationCityIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="State"
              inputProps={{
                name: 'state',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.state}
              helperText={errors.state && errors.state.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>
          <div className={errors.country ? styles.error : ''}>
            <LocationCityIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Country"
              inputProps={{
                name: 'country',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              select
              SelectProps={{
                native: true,
                style: { fontSize: paragraphFontSize },
              }}
              inputRef={register({ required: true })}
              error={!!errors.country}
              helperText={errors.country && errors.country.message}
              fullWidth={isMatchingXSScreen}
            >
              {preferredCountriesDataList.map(mapCountriesDataListToOptions)}
              <optgroup label={t('other_countries')}>
                {otherCountriesDataList.map(mapCountriesDataListToOptions)}
              </optgroup>
            </TextField>
          </div>
          <div className={errors.zipCode ? styles.error : ''}>
            <GpsFixedIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Zip Code"
              inputProps={{
                name: 'zipCode',
                style: {
                  width: isMatchingXSScreen ? 'unset' : 116,
                },
              }}
              inputRef={register({ required: true })}
              error={!!errors.zipCode}
              helperText={errors.zipCode && errors.zipCode.message}
              fullWidth={isMatchingXSScreen}
            />
          </div>
        </div>

        <div className={styles.fields}>
          <div style={{ flex: 1 }} className={errors.phone ? styles.error : ''}>
            <PhoneIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Phone"
              inputProps={{
                name: 'phone',
              }}
              fullWidth
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Invalid phone',
                },
              })}
              error={!!errors.phone}
              helperText={errors.phone && errors.phone.message}
            />
          </div>
          <div className={styles.break_mobile} />
          <div style={{ flex: 1 }} className={errors.email ? styles.error : ''}>
            <EmailIcon />
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              label="Email"
              inputProps={{
                name: 'email',
              }}
              fullWidth
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              })}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
            />
          </div>
        </div>

        <div className={styles.fields}>
          <div style={{ flex: 1 }}>
            <StarsIcon />
            <div className={styles.chip_input_container}>
              <ChipInput
                label="Top Specialties (up to 3 by order)"
                value={topSpecialties}
                onAdd={onTopSpecialtiesAdd}
                onDelete={onTopSpecialtiesDelete}
                fullWidth
                error={!!errors.topSpecialties}
                helperText={
                  errors.topSpecialties && errors.topSpecialties.message
                }
                newChipKeyCodes={[13, 188]}
              />
            </div>
          </div>
          <div className={styles.break_mobile} />
          <div style={{ flex: 1 }}>
            <StarsIcon />
            <div className={styles.chip_input_container}>
              <ChipInput
                label="Other specialties"
                value={otherSpecialties}
                onAdd={onOtherSpecialtiesAdd}
                onDelete={onOtherSpecialtiesDelete}
                fullWidth
                error={!!errors.otherSpecialties}
                helperText={
                  errors.otherSpecialties && errors.otherSpecialties.message
                }
                newChipKeyCodes={[13, 188]}
              />
            </div>
          </div>
        </div>

        <div
          className={styles.fields}
          style={{
            marginTop:
              errors.topSpecialties || errors.otherSpecialties ? 27 : 0,
          }}
        >
          <div
            style={{ flex: 1 }}
            className={errors.languages ? styles.error : ''}
          >
            <LanguageIcon />

            <CheckboxesSelect
              inputLabelText="Languages"
              selectedOptions={languages}
              allOptions={allLanguages}
              onChange={onLanguagesChange}
              inputType="input"
              fullWidth
              error={!!errors.languages}
              helperText={errors.languages && errors.languages.message}
            />
          </div>

          <div
            style={{ position: 'relative', flex: 1 }}
            className={errors.imageUpload ? styles.error : ''}
          >
            <AddAPhotoIcon />
            <TextField
              label={imageFile ? '' : 'Image Upload'}
              inputProps={{
                name: 'imageUpload',
                style: {
                  width: '90%',
                },
              }}
              InputLabelProps={{ shrink: false }}
              error={!!errors.imageUpload}
              helperText={errors.imageUpload && errors.imageUpload.message}
              onClick={() => {}}
              value={imageFile ? imageFile.name : ''}
              fullWidth
            />

            <div
              className={styles.text_upload_btn_container}
              onClick={() => {
                document.getElementById('image-input').click();
              }}
            >
              <span> Upload</span>
            </div>
            <input
              accept="image/png, image/gif, image/jpeg"
              hidden
              id="image-input"
              type="file"
              onChange={onImageFileChange}
              style={{ display: 'none' }}
            />
          </div>
        </div>

        <div className={styles.fields}>
          <div
            style={{
              width: '100%',
              marginRight: -10,
              marginTop: 23,
              position: 'relative',
            }}
            className={classNames(styles.text_field_container, {
              [styles.error]: !!errors.biography,
            })}
          >
            <TextField
              InputLabelProps={isEditMode ? { shrink: true } : {}}
              inputProps={{
                name: 'biography',
                style: {
                  fontSize: 12,
                  paddingRight: 42,
                },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ marginTop: -50, color: '#5e5e5e' }}>
                      <ImportContactsIcon color="inherit" />
                    </div>
                  </InputAdornment>
                ),
              }}
              fullWidth
              multiline
              rows={6}
              value={biography}
              onChange={onBiographyChange}
              error={!!errors.biography}
              helperText={errors.biography && errors.biography.message}
              variant="outlined"
              placeholder="Biography"
            />

            <p
              style={{
                color:
                  biography.length > biographyMaxLength ? '#f44336' : '#838383',
              }}
              className={styles.biography_chars_count}
            >
              {biography.length}/{biographyMaxLength}
            </p>
          </div>
        </div>
      </div>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          <span className={styles.btn}>
            {isEditMode ? 'Save' : 'Add clinician'}
          </span>
        </Button>
      </DialogActions>
    </React.Fragment>
  );

  const renderListingAddedStep = () => (
    <React.Fragment>
      <div className={styles.listing_added_content}>
        <img src={listingAddedImage} />
        <p>{isEditMode ? 'Listing Updated!' : 'Listing Added!'}</p>
        <p>{t('please-note-the-following')}</p>
        <p>
          - Messages from users will be{' '}
          <span style={{ fontWeight: 500 }}>
            sent to the email address provided
          </span>{' '}
          in the listing.
        </p>
        <p>- {t('listing-is-live-now')}</p>
        <p>- {t('we-might-hide-listing')}</p>
      </div>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          <span className={styles.btn}>{t('confirm')}</span>
        </Button>
      </DialogActions>
    </React.Fragment>
  );

  return (
    <Dialog open={isOpen} classes={{ paper: styles.dialog_paper_root }}>
      {renderedStep === 'form' && renderStepForm()}
      {renderedStep === 'loading' && (
        <div style={{ padding: '30px 80px' }}>
          <Loader />
        </div>
      )}
      {renderedStep === 'listingAdded' && renderListingAddedStep()}
    </Dialog>
  );
};

AddEditListingDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  biography: PropTypes.string.isRequired,
  onBiographyChange: PropTypes.func.isRequired,
  topSpecialties: PropTypes.array.isRequired,
  otherSpecialties: PropTypes.array.isRequired,
  onTopSpecialtiesAdd: PropTypes.func.isRequired,
  onTopSpecialtiesDelete: PropTypes.func.isRequired,
  onOtherSpecialtiesAdd: PropTypes.func.isRequired,
  onOtherSpecialtiesDelete: PropTypes.func.isRequired,
  onImageFileChange: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  allLanguages: PropTypes.array.isRequired,
  onLanguagesChange: PropTypes.func.isRequired,
  imageFile: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  renderedStep: PropTypes.string.isRequired,
  biographyMaxLength: PropTypes.number.isRequired,
});

export const AddEditListingDialog = React.memo(AddEditListingDialogBase);
AddEditListingDialog.displayName = 'AddEditListingDialog';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import DefaultAvatarPNG from '../../../assets/default-avatar.png';
import UploadPNG from '../../../assets/upload.png';
import { AutomationsLogoDialog } from './AutomationsLogoDialog';
// import UploadSvg from '../../../assets/upload.svg';
import styles from './Automations.scss';

const ClinicDetailsDialogBase = ({
  isClinicDetailsPopupOpen,
  setIsClinicDetailsPopupOpen,
  clinicNameInput,
  setClinicNameInput,
  clinicImageUrlInput,
  setClinicImageUrlInput,
  clinicBioInput,
  setClinicBioInput,
  onSaveClinicDetails,
  clinicImageUrl,
}) => {
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isLogoDialogOpen, setIsLogoDialogOpen] = useState(false);

  const onImageFileChange = async event => {
    setIsUploadingImage(true);
    if (event.target.files.length) {
      const imgId = Math.random()
        .toString(36)
        .substring(7);
      const path = `automations_clinic_details_image/${sessionStorage.userId}/${imgId}`;
      const [file] = event.target.files;
      const storage = window.firebase.storage();
      const imageRef = storage.ref(path);
      try {
        await imageRef.put(file);
        imageRef
          .getDownloadURL()
          .then(res => {
            setClinicImageUrlInput(res);
          })
          .catch(() => {});
      } catch (err) {
        showNotification('danger', `something went wrong ${err}`);
      }
    }
    setIsUploadingImage(false);
  };

  return (
    <Dialog open={isClinicDetailsPopupOpen} fullWidth>
      <div style={{ padding: 20 }}>
        <div className={styles.clinic_details_popup_title}>
          <div style={{ display: 'flex' }}>
            Your details
            <Tooltip
              title={
                <TooltipTitle>
                  Adding details for each team member is currently not
                  supported.
                </TooltipTitle>
              }
            >
              <HelpIcon style={{ marginLeft: 10, size: 12 }} />
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setIsClinicDetailsPopupOpen(false);
            }}
            style={{ cursor: 'pointer' }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={styles.clinic_details_popup_content_container}>
          {isUploadingImage ? (
            <div
              style={{
                width: 100,
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <img
              src={clinicImageUrlInput || DefaultAvatarPNG}
              className={styles.clinic_details_popup_img}
            />
          )}
          <div className={styles.clinic_details_popup_content}>
            <div className={styles.clinic_details_popup_item_title}>
              Display picture
            </div>
            <div className={styles.clinic_details_popup_item_description}>
              Clients will see this picture in-app. We recommend uploading a
              picture of yourself.
            </div>
            <Button
              onClick={() => {
                if (clinicImageUrlInput) {
                  setIsLogoDialogOpen(true);
                } else {
                  document.getElementById('image-input').click();
                }
              }}
              color="primary"
              //   variant="contained"
              style={{ fontSize: 14, marginTop: 10 }}
            >
              <img
                src={UploadPNG}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              Upload
            </Button>
            <input
              accept="image/png, image/gif, image/jpeg"
              hidden
              id="image-input"
              type="file"
              onChange={onImageFileChange}
              style={{ display: 'none' }}
            />
            <div className={styles.clinic_details_popup_item_title}>
              Display name
            </div>
            <div className={styles.clinic_details_popup_item_description}>
              Please use the name your clients recognize (e.g., Dr. John Doe).
            </div>
            <TextField
              value={clinicNameInput}
              onChange={e => {
                setClinicNameInput(e.target.value);
              }}
              placeholder="Display name"
              InputProps={{
                classes: {
                  input: styles.text_input_clinic_name,
                },
              }}
            />
            <div className={styles.clinic_details_popup_item_title}>Bio</div>
            <div className={styles.clinic_details_popup_item_description}>
              Provide a brief bio for your clients to view in-app.
            </div>
            <TextField
              value={clinicBioInput}
              onChange={e => {
                if (e.target.value.length >= 300) {
                  return;
                }
                setClinicBioInput(e.target.value);
              }}
              placeholder="Add a bio (recommended)"
              InputProps={{
                classes: {
                  input: styles.text_input_clinic_name,
                },
              }}
              style={{ width: '100%' }}
              multiline
              rows={3}
            />
            <div className={styles.clinic_details_popup_item_save_btn}>
              <Button
                onClick={onSaveClinicDetails}
                color="primary"
                variant="contained"
                style={{ fontSize: 14 }}
                disabled={!clinicNameInput}
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
        <AutomationsLogoDialog
          isOpen={isLogoDialogOpen}
          onClose={() => {
            setClinicImageUrlInput(clinicImageUrl);
            setIsLogoDialogOpen(false);
          }}
          setClinicImageUrlInput={setClinicImageUrlInput}
          clinicImageUrlInput={clinicImageUrlInput}
        />
      </div>
    </Dialog>
  );
};

ClinicDetailsDialogBase.propTypes = exact({
  isClinicDetailsPopupOpen: PropTypes.bool.isRequired,
  setIsClinicDetailsPopupOpen: PropTypes.func.isRequired,
  clinicNameInput: PropTypes.string.isRequired,
  setClinicNameInput: PropTypes.func.isRequired,
  clinicImageUrlInput: PropTypes.string.isRequired,
  setClinicImageUrlInput: PropTypes.func.isRequired,
  clinicBioInput: PropTypes.string.isRequired,
  setClinicBioInput: PropTypes.func.isRequired,
  onSaveClinicDetails: PropTypes.func.isRequired,
  clinicImageUrl: PropTypes.string,
});

export const ClinicDetailsDialog = React.memo(ClinicDetailsDialogBase);
ClinicDetailsDialog.displayName = 'ClinicDetailsDialog';

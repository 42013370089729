import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Input, CircularProgress } from '@material-ui/core';
import { LagoListItem } from '../LagoListItem/LagoListItem';

import styles from './LagoCheckins.scss';

const lagoEventLogger = new MyEventWrapper('lago');

const LagoCheckinsBase = ({
  open,
  onClose,
  selectedChekin,
  checkins,
  setCheckins,
  screensItemsData,
  onItemClicked,
  setKeyToDelete,
  setDisplayDeleteDialog,
  currentStage,
  onEditItemClicked,
  onAddNewVariantClicked,
  editVariant,
  variantClicked,
  setCheckinEditDialogOpen,
  checkinSkill,
  setCheckinSkill,
  handleOnDragEnd,
  isLoadingVideo,
  toggleCheckinEnableClicked,
  isFinalizedState,
}) => {
  const onCheckinSave = () => {
    const copy = JSON.parse(JSON.stringify(checkins));
    copy[selectedChekin].skillName.en = checkinSkill;
    if (
      selectedChekin &&
      copy[selectedChekin] &&
      copy[selectedChekin].screens &&
      Object.keys(copy[selectedChekin].screens).length
    ) {
      copy[selectedChekin].isEnabled = true;
      toggleCheckinEnableClicked(true);
    } else {
      copy[selectedChekin].isEnabled = false;
      toggleCheckinEnableClicked(false);
    }
    setCheckins(copy);
    onClose();
    lagoEventLogger.log('checkin_clicked');
  };

  let sortedCheckinScreens = [];
  if (
    checkins &&
    checkins[selectedChekin] &&
    checkins[selectedChekin].screens
  ) {
    sortedCheckinScreens = Object.keys(checkins[selectedChekin].screens);
    sortedCheckinScreens.sort(
      (a, b) =>
        checkins[selectedChekin].screens[a] -
        checkins[selectedChekin].screens[b]
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        <div style={{ fontSize: '1.5em', textAlign: 'center' }}>Check-ins</div>
      </DialogTitle>
      <DialogContent>
        <Button
          onClick={setCheckinEditDialogOpen}
          style={{ maxWidth: '100px', margin: '10px', marginTop: '10px' }}
          variant="contained"
          disabled={isFinalizedState}
        >
          add screen
        </Button>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId={screensItemsData}>
            {provided => (
              <div
                // className={styles.lago_control_list_container}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ width: '50%' }}
              >
                {checkins &&
                  checkins[selectedChekin] &&
                  checkins[selectedChekin].screens &&
                  sortedCheckinScreens.map((screenId, index) => {
                    return (
                      <Draggable
                        key={screenId}
                        draggableId={screenId}
                        index={
                          checkins &&
                          checkins[selectedChekin] &&
                          checkins[selectedChekin].screens &&
                          checkins[selectedChekin].screens[screenId]
                            ? checkins[selectedChekin].screens[screenId]
                            : index
                        }
                      >
                        {provided1 => (
                          <div
                            ref={provided1.innerRef}
                            {...provided1.draggableProps}
                            {...provided1.dragHandleProps}
                          >
                            <LagoListItem
                              key={screenId}
                              element={screensItemsData[screenId]}
                              onItemClicked={() => onItemClicked(screenId)}
                              onDeleteItemClicked={e => {
                                e.stopPropagation();
                                setKeyToDelete(screenId);
                                setDisplayDeleteDialog(true);
                              }}
                              currentStage={currentStage}
                              onEditItemClicked={onEditItemClicked}
                              onAddNewVariantClicked={e =>
                                onAddNewVariantClicked(e, screenId)
                              }
                              editVariant={editVariant}
                              variantClicked={variantClicked}
                              isCheckingEdit
                              isFinalizedState={isFinalizedState}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isLoadingVideo ? <CircularProgress /> : null}
        <div style={{ marginLeft: '10px' }}>
          <div className={styles.more_items_title}>More</div>
          <span style={{ marginLeft: '5px' }}>
            • This module’s skill name:{' '}
          </span>
          <Input
            value={checkinSkill}
            onChange={e => setCheckinSkill(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{
            width: '100px',
            height: '50px',
            fontSize: '16px',
            marginRight: '10px',
          }}
          color="primary"
          disabled={isLoadingVideo}
        >
          cancel
        </Button>
        <Button
          onClick={onCheckinSave}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          color="primary"
          variant="contained"
          disabled={!checkinSkill || isLoadingVideo || isFinalizedState}
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoCheckinsBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedChekin: PropTypes.string,
  checkins: PropTypes.object,
  setCheckins: PropTypes.func,
  screensItemsData: PropTypes.object,
  onItemClicked: PropTypes.func,
  setKeyToDelete: PropTypes.func,
  setDisplayDeleteDialog: PropTypes.func,
  currentStage: PropTypes.number,
  onEditItemClicked: PropTypes.func,
  onAddNewVariantClicked: PropTypes.func,
  editVariant: PropTypes.func,
  variantClicked: PropTypes.func,
  setCheckinEditDialogOpen: PropTypes.func,
  checkinSkill: PropTypes.string,
  setCheckinSkill: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  isLoadingVideo: PropTypes.bool,
  toggleCheckinEnableClicked: PropTypes.func,
  isFinalizedState: PropTypes.bool,
});

export const LagoCheckins = React.memo(LagoCheckinsBase);
LagoCheckins.displayName = 'LagoCheckins';

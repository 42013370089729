import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CircularProgress, TextField } from '@material-ui/core';
import styles from './BillingSummary.scss';
import HipaaPng from '../../../../assets/iso-hipaa-grey.png';

const BillingSummaryBase = ({
  onContinueClicked,
  packageData,
  isAnnual,
  isLoading,
  currentStep,
  couponCode,
  setCouponCode,
  onApllyCoupon,
  isLoadingCoupon,
  couponCodeError,
  couponDiscount,
  isNfAdded,
  hardwareItems,
  hardwareData,
  onRemoveHardware,
  registrationError,
  isHardwareStore,
  NFPackages,
  shippingPrice,
  setTotalPrice,
  shouldAddExtraTax,
  isFromShop,
}) => {
  const hardwareIds = hardwareItems && Object.keys(hardwareItems);
  const filteredHardwareIds =
    hardwareIds &&
    hardwareIds.filter(hardwareId => hardwareItems[hardwareId].isSelected);

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const calcPrice = () => {
    if (isAnnual) {
      return isNfAdded
        ? NFPackages.annualMonthlyBasePrice + packageData.annualMonthlyBasePrice
        : packageData.annualMonthlyBasePrice;
    }
    return isNfAdded
      ? NFPackages.basePrice + packageData.basePrice
      : packageData.basePrice;
  };

  // eslint-disable-next-line no-shadow
  const calcTotalPrice = filteredHardwareIds => {
    if (hardwareItems && filteredHardwareIds && filteredHardwareIds.length) {
      let sum = shippingPrice;
      filteredHardwareIds.forEach(hardwareId => {
        sum +=
          hardwareData[hardwareId].price *
          (hardwareItems[hardwareId].quantityToPurchase || 1);
      });
      return sum;
    }
    return 0;
  };

  const calcButtonText = () => {
    if (isLoading) {
      return <CircularProgress style={{ color: 'white' }} />;
    }
    if (currentStep === 'Start Trial') {
      return 'START USING MYNDLIFT';
    }
    return 'CONTINUE';
  };

  // eslint-disable-next-line no-shadow
  const calcCuoponDiscount = couponDiscount => {
    if (couponDiscount.amount) {
      return couponDiscount.amount;
    }
    return 0;
  };

  const calcTotalPriceWithDiscount = () => {
    return (
      calcTotalPrice(filteredHardwareIds) - calcCuoponDiscount(couponDiscount)
    );
  };

  const calcTaxPrice = () => {
    if (shouldAddExtraTax) {
      const finalPrice = couponDiscount
        ? calcTotalPriceWithDiscount()
        : calcTotalPrice(filteredHardwareIds);
      if (finalPrice > 0) {
        return finalPrice * 0.17;
      }
      return 0;
    }
    return 0;
  };

  const calcDueToday = () => {
    const finalPrice =
      couponDiscount && filteredHardwareIds.length
        ? calcTotalPriceWithDiscount()
        : calcTotalPrice(filteredHardwareIds);
    if (finalPrice > 0) {
      return numberWithCommas((finalPrice + calcTaxPrice()).toFixed(2));
    }
    return 0;
  };

  useEffect(() => {
    if (setTotalPrice) {
      setTotalPrice(
        couponDiscount
          ? calcTotalPriceWithDiscount()
          : calcTotalPrice(filteredHardwareIds)
      );
    }
  });

  return (
    <div
      style={isFromShop && window.innerWidth < 12000 ? { width: '90%' } : null}
      className={styles.billing_summary_body}
    >
      <div>
        <div className={styles.billing_summary_container}>
          <div
            className={styles.billing_summary_dual_item}
            style={{ fontWeight: 900 }}
          >
            <div>{packageData.bundleName}</div>
            <div>{packageData.trialDays} day free trial</div>
          </div>
          {isNfAdded ? (
            <div
              className={styles.billing_summary_dual_item}
              style={{ fontWeight: 900 }}
            >
              <div>Neuro add-on</div>
              <div>{NFPackages.trialDays} day free trial</div>
            </div>
          ) : null}
          {filteredHardwareIds &&
            filteredHardwareIds.map(hardwareId => {
              return (
                <div
                  className={styles.billing_summary_dual_item}
                  style={{ fontWeight: 900 }}
                >
                  <div>
                    <div>{hardwareData[hardwareId].name}</div>
                    <div style={{ fontWeight: 400, fontSize: 14 }}>
                      Quantity:{' '}
                      {hardwareItems[hardwareId].quantityToPurchase || 1}
                      <span
                        style={{
                          color: '#EA6969',
                          cursor: 'pointer',
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          onRemoveHardware(hardwareId);
                        }}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                  <div>
                    $
                    {hardwareData[hardwareId].price *
                      (hardwareItems[hardwareId].quantityToPurchase || 1)}
                  </div>
                </div>
              );
            })}
          {filteredHardwareIds.length !== 0 && (
            <div className={styles.billing_summary_dual_item}>
              <div>Shipping</div>
              <div>${shippingPrice}</div>
            </div>
          )}
          {shouldAddExtraTax ? (
            <div className={styles.billing_summary_dual_item}>
              <div>Tax</div>
              <div>${calcTaxPrice().toFixed(2)}</div>
            </div>
          ) : null}
          {couponDiscount && couponDiscount.amount ? (
            <div
              className={styles.billing_summary_dual_item}
              style={{ fontWeight: 900 }}
            >
              <div>Coupon: {couponCode}</div>
              <div>
                -$
                {calcCuoponDiscount(couponDiscount) > 0
                  ? calcCuoponDiscount(couponDiscount).toFixed(2)
                  : 0}
              </div>
            </div>
          ) : null}
          {isNfAdded && (currentStep === 'Start Trial' || isHardwareStore) ? (
            <div className={styles.inputs}>
              <div className={styles.inputs_container}>
                <TextField
                  label="Coupon Code"
                  error={!!couponCodeError}
                  helperText={couponCodeError}
                  placeholder="Enter coupon code"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={couponCode}
                  onChange={e => {
                    setCouponCode(e.target.value);
                  }}
                />
              </div>
              <span className={styles.coupun_button} onClick={onApllyCoupon}>
                {isLoadingCoupon ? (
                  <CircularProgress
                    style={{ color: '#4BE0D0', width: 18, height: 18 }}
                  />
                ) : (
                  'APPLY'
                )}
              </span>
            </div>
          ) : null}
          <div className={styles.billing_summary_divider} />
          <div className={styles.billing_summary_dual_item}>
            <div>Total after trial</div>
            <div>
              <div style={{ textAlign: 'end' }}>
                ${numberWithCommas(calcPrice())}/mo
              </div>
              <div style={{ fontSize: 14 }}>
                {isAnnual
                  ? `Billed as $${numberWithCommas(
                      isNfAdded
                        ? (NFPackages.annualMonthlyBasePrice +
                            packageData.annualMonthlyBasePrice) *
                            12
                        : packageData.annualMonthlyBasePrice * 12
                    )}`
                  : 'Billed monthly'}
              </div>
            </div>
          </div>
          {couponDiscount &&
          couponDiscount.amount &&
          filteredHardwareIds.length ? (
            <div
              className={styles.billing_summary_dual_item}
              style={{ fontWeight: 900, color: '#4BE0D0' }}
            >
              <div>Your savings today</div>
              <div>${calcCuoponDiscount(couponDiscount).toFixed(2)}</div>
            </div>
          ) : null}
          <div
            className={styles.billing_summary_dual_item}
            style={{ fontWeight: 900 }}
          >
            <div>Due today</div>
            <div>${calcDueToday()}</div>
          </div>
          <div
            className={styles.billing_summary_button}
            onClick={onContinueClicked}
          >
            {calcButtonText()}
          </div>
        </div>
        {currentStep === 'Start Trial' ? (
          <div style={{ marginTop: 20, fontSize: 14, textAlign: 'center' }}>
            All transactions are secure and encrypted.
          </div>
        ) : null}
        {registrationError ? (
          <div style={{ color: 'red', textAlign: 'center' }}>
            Something went wrong
          </div>
        ) : null}
        {currentStep === 'Create Account' ? (
          <div className={styles.terms_and_conditions}>
            By continuing, you’re agreeing to our
            <a
              href="/privacy/terms-of-service-for-practitioners.pdf"
              target="_blank"
            >
              {' '}
              Terms of Service
            </a>{' '}
            and
            <a
              href="/privacy/privacy-notice-for-practitioners.pdf"
              target="_blank"
            >
              {' '}
              Privacy Policy
            </a>
          </div>
        ) : null}
      </div>
      <div className={styles.iso_logo_container}>
        <img src={HipaaPng} className={styles.iso_logo_image} />
      </div>
    </div>
  );
};

BillingSummaryBase.propTypes = exact({
  onContinueClicked: PropTypes.func.isRequired,
  packageData: PropTypes.object.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  currentStep: PropTypes.string,
  couponCode: PropTypes.string,
  setCouponCode: PropTypes.func,
  onApllyCoupon: PropTypes.func,
  isLoadingCoupon: PropTypes.bool,
  couponCodeError: PropTypes.string,
  isNfAdded: PropTypes.bool,
  hardwareItems: PropTypes.object,
  hardwareData: PropTypes.object,
  onRemoveHardware: PropTypes.func,
  registrationError: PropTypes.bool,
  isHardwareStore: PropTypes.bool,
  couponDiscount: PropTypes.object,
  NFPackages: PropTypes.object,
  shippingPrice: PropTypes.number,
  setTotalPrice: PropTypes.func,
  shouldAddExtraTax: PropTypes.bool,
  isFromShop: PropTypes.bool,
});

export const BillingSummary = React.memo(BillingSummaryBase);
BillingSummary.displayName = 'BillingSummary';

/* eslint-disable react/prop-types */
// import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';
import CallIcon from '@material-ui/icons/Call';
import CreateIcon from '@material-ui/icons/Create';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';
import GenderIcon from '@material-ui/icons/Wc';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import classNames from 'classnames';
import countryTelData from 'country-telephone-data';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import React, { useCallback, useImperativeHandle, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useField, useForm } from 'react-final-form-hooks';
import * as Yup from 'yup';
import { AssignedUsers } from '../../AssignedUsers/AssignedUsers';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { RightDownArrow } from '../../../Core/Icons/RightDownArrow';
import { GENDERS } from '../../../models/person/person';
import {
  bifurcateArrBy,
  capitalizeFirstLetter,
  removeAllAfterLastChar,
} from '../../../utils/utils';
import styles from './ClientDetailsForm.scss';

const clonedCountryTelData = cloneDeep(countryTelData);
const countriesDataList = clonedCountryTelData.allCountries.map(country => {
  const newCountry = cloneDeep(country);
  newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
  return newCountry;
});

const preferredCountriesNames = [
  'United States',
  'United Kingdom',
  'Germany',
  'Canada',
  'Israel',
];

const [
  preferredCountriesDataList,
  otherCountriesDataList,
] = bifurcateArrBy(countriesDataList, countryData =>
  preferredCountriesNames.includes(countryData.name)
);

const mapCountriesDataListToOptions = countryData => (
  <option
    key={`${countryData.name} ${countryData.dialCode}`}
    value={countryData.name}
  >
    {`${countryData.name} +(${countryData.dialCode})`}
  </option>
);

const ClientDetailsFormBase = (
  {
    selectedDisorders,
    onSave,
    isEditingMode,
    brainDisorders,
    onDisorderClick,
    formInitialValues,
    shouldShowDisorderTags,
    showMoreDetails,
    toggleShowMoreDetails,
    isTeamMembersMenuOpen,
    setIsTeamMembersMenuOpen,
    teamMembersUserAssignedTo,
    setAssignedTo,
    teamMembersList,
  },
  ref
) => {
  const t = useTranslation();

  const shouldShowTeamMemberAssign =
    teamMembersList &&
    Object.keys(teamMembersList).find(id => teamMembersList[id].userId);

  const onSubmit = async values => {
    onSave(values);
  };

  const validate = useCallback(
    async values => {
      const schema = Yup.object().shape({
        firstName: Yup.string()
          .trim()
          .required('Required'),
        lastName: Yup.string()
          .trim()
          .required('Required'),
        birthday: Yup.date().required('Required'),
        gender: Yup.string().required('Required'),
        isClientRealEmail: Yup.boolean().required('Required'),
        phone: Yup.string()
          .test('len', 'Must be at least 7 numbers', value => {
            if (value === undefined || value === '') {
              return true;
            }
            const strValue = `${value}`;
            return strValue && strValue.length >= 7;
          })
          .test('whole-number', 'Must be a valid number', value => {
            if (value === undefined || value === '') {
              return true;
            }
            if (isEditingMode) {
              return true;
            }
            const doesOnlyContainDigits = /^\d+$/.test(value);
            return doesOnlyContainDigits;
          }),
        email: Yup.string()
          .email('Must be an email address format (ie example@mail.com)')
          .test(
            'no-firebase-forbidden-chars',
            'Email cannot contain any of the following characters ($ # [ ] / \\)',
            value => {
              const firebaseForbiddenChars = ['$', '#', '[', ']', '/', '\\'];
              return (
                value &&
                value
                  .split('')
                  .every(char => firebaseForbiddenChars.indexOf(char) === -1)
              );
            }
          )
          .required('Required'),
      });

      try {
        await schema.validate(values, { abortEarly: false });
        return {};
      } catch (err) {
        const errors = err.inner.reduce(
          (formError, innerError) => ({
            ...formError,
            [innerError.path]: innerError.message,
          }),
          {}
        );

        return errors;
      }
    },
    [isEditingMode]
  );

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate,
    ...{
      initialValues: formInitialValues,
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      form.submit();
    },
  }));

  const firstNameField = useField('firstName', form);
  const lastNameField = useField('lastName', form);
  const birthDayField = useField('birthday', form);
  const genderField = useField('gender', form);
  const countryField = useField('country', form);
  const phoneField = useField('phone', form);
  const emailField = useField('email', form);
  const isClientRealEmailField = useField('isClientRealEmail', form);
  const notesField = useField('notes', form);

  return (
    <React.Fragment>
      <div>
        <form onSubmit={handleSubmit}>
          <div className={styles.multiple_inputs_container}>
            <div className={styles.input_container}>
              <div className={styles.input_container_icon}>
                <PersonIcon />
              </div>
              <FormControl
                error={firstNameField.meta.error && firstNameField.meta.touched}
                classes={{
                  root: styles.mu_form_control_root,
                }}
              >
                <Input
                  type="text"
                  placeholder={t('first-name')}
                  {...firstNameField.input}
                />
                {firstNameField.meta.touched && firstNameField.meta.error && (
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {firstNameField.meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={styles.input_container}>
              <div className={styles.input_container_icon}>
                <PersonIcon />
              </div>
              <FormControl
                classes={{
                  root: styles.mu_form_control_root,
                }}
                error={lastNameField.meta.error && lastNameField.meta.touched}
              >
                <Input
                  type="text"
                  placeholder={t('last-name')}
                  {...lastNameField.input}
                />
                {lastNameField.meta.touched && lastNameField.meta.error && (
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {lastNameField.meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={styles.input_container}>
              <div className={styles.input_container_icon}>
                <DateRangeIcon />
              </div>
              <FormControl
                classes={{
                  root: styles.mu_form_control_root,
                }}
                error={birthDayField.meta.error && birthDayField.meta.touched}
              >
                <div
                  className={classNames(styles.date_picker_container, {
                    [styles.date_picker_container_error]:
                      birthDayField.meta.error && birthDayField.meta.touched,
                  })}
                >
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={birthDayField.input.onChange}
                    onFocus={birthDayField.input.onFocus}
                    onBlur={birthDayField.input.onBlur}
                    selected={birthDayField.input.value}
                    filterDate={date => date.getTime() < new Date().getTime()}
                    yearDropdownItemNumber={7}
                    placeholderText={t('date-of-birth')}
                    ref={datePicker => {
                      if (datePicker) {
                        // stop mobile keyboards from appearing
                        datePicker.input.setAttribute('readonly', true);
                      }
                    }}
                  />
                </div>
                {birthDayField.meta.touched && birthDayField.meta.error && (
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {birthDayField.meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={styles.input_container}>
              <div className={styles.input_container_icon}>
                <GenderIcon />
              </div>
              <FormControl
                classes={{
                  root: styles.mu_form_control_gender_root,
                }}
                error={genderField.meta.error && genderField.meta.touched}
              >
                <NativeSelect
                  classes={{
                    icon: styles.mu_native_select_icon,
                    root: styles.mu_select_menu,
                  }}
                  IconComponent={RightDownArrow}
                  {...genderField.input}
                >
                  <option value="" disabled>
                    Select gender
                  </option>
                  {Object.entries(GENDERS).map(([genderKey, genderValue]) => (
                    <option key={genderKey} value={genderValue}>
                      {capitalizeFirstLetter(t(genderValue.toLowerCase()))}
                    </option>
                  ))}
                </NativeSelect>
                {genderField.meta.touched && genderField.meta.error && (
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {firstNameField.meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={styles.email_input_container}>
              <div className={styles.input_container}>
                <div className={styles.input_container_icon}>
                  <EmailIcon color={isEditingMode ? 'disabled' : undefined} />
                </div>
                <FormControl
                  error={emailField.meta.error && emailField.meta.touched}
                  classes={{
                    root: classNames(
                      styles.email_input,
                      styles.mu_form_control_root
                    ),
                  }}
                >
                  <Input
                    type="text"
                    placeholder={t('email-address')}
                    {...emailField.input}
                    disabled={isEditingMode}
                  />
                  {emailField.meta.touched && emailField.meta.error && (
                    <FormHelperText
                      classes={{ root: styles.form_helper_text_error }}
                    >
                      {emailField.meta.error}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className={styles.input_container}>
                {/* <FormControl>
                  <Checkbox
                    onChange={isClientRealEmailField.input.onChange}
                    checked={isClientRealEmailField.input.value}
                    color="primary"
                  />
                </FormControl> */}
                <div className={styles.email_select_container}>
                  <FormControl
                    classes={{
                      root: styles.mu_form_control_email_root,
                    }}
                    error={
                      isClientRealEmailField.meta.error &&
                      isClientRealEmailField.meta.touched
                    }
                  >
                    <NativeSelect
                      classes={{
                        icon: styles.mu_native_select_icon,
                        root: styles.mu_select_menu,
                      }}
                      IconComponent={RightDownArrow}
                      {...isClientRealEmailField.input}
                    >
                      <option value="" disabled>
                        Choose from below
                      </option>
                      <option value="true">{t('is_client_real_email')}</option>
                      <option value="false">
                        {t('is_client_not_real_email')}
                      </option>
                    </NativeSelect>
                    {isClientRealEmailField.meta.touched &&
                      isClientRealEmailField.meta.error && (
                        <FormHelperText
                          classes={{ root: styles.form_helper_text_error }}
                        >
                          {isClientRealEmailField.meta.error}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>

                <div className={styles.is_client_real_email_container}>
                  {/* <p>{t('is_client_real_email')}</p> */}
                  <TooltipWrapper
                    title={t('is_client_real_email_description')}
                    placement="right"
                    classes={{
                      tooltip: styles.is_client_real_email_tooltip,
                    }}
                  >
                    <InfoIcon />
                  </TooltipWrapper>
                </div>
              </div>
            </div>

            {showMoreDetails && (
              <div
                className={styles.input_container}
                style={{ flexWrap: isEditingMode ? 'nowrap' : 'wrap' }}
              >
                <div style={{ display: 'flex' }}>
                  <div className={styles.input_container_icon}>
                    <CallIcon />
                  </div>
                  {!isEditingMode && (
                    <div className={styles.country_input_container}>
                      <FormControl
                        classes={{
                          root: classNames(
                            styles.country_input,
                            styles.mu_form_control_root
                          ),
                        }}
                      >
                        <NativeSelect
                          classes={{
                            icon: styles.mu_native_select_icon,
                            root: styles.mu_select_menu,
                          }}
                          IconComponent={RightDownArrow}
                          {...countryField.input}
                        >
                          {preferredCountriesDataList.map(
                            mapCountriesDataListToOptions
                          )}
                          <optgroup label={t('other_countries')}>
                            {otherCountriesDataList.map(
                              mapCountriesDataListToOptions
                            )}
                          </optgroup>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  )}
                </div>

                <FormControl
                  error={phoneField.meta.error && phoneField.meta.touched}
                  classes={{
                    root: styles.mu_form_control_root,
                  }}
                >
                  <Input
                    type="text"
                    placeholder={t('contact-number')}
                    {...phoneField.input}
                  />
                  {phoneField.meta.touched && phoneField.meta.error && (
                    <FormHelperText
                      classes={{ root: styles.form_helper_text_error }}
                    >
                      {phoneField.meta.error}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            )}
          </div>

          {showMoreDetails && (
            <div className={styles.input_container}>
              <div className={styles.input_container_icon}>
                <CreateIcon />
              </div>
              <FormControl
                error={notesField.meta.error && notesField.meta.touched}
                classes={{
                  root: styles.mu_form_control_notes_root,
                }}
              >
                <Input
                  type="text"
                  placeholder={t('notes')}
                  multiline
                  fullWidth
                  {...notesField.input}
                />
              </FormControl>
            </div>
          )}

          {shouldShowTeamMemberAssign ? (
            <div className={styles.assign_user_box}>
              <AccountBoxIcon />
              <div style={{ marginLeft: 10, marginRight: 10 }}>Assigned to</div>
              <div
                onClick={() => {
                  if (isTeamMembersMenuOpen) {
                    return;
                  }
                  setIsTeamMembersMenuOpen(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <AssignedUsers
                  isTeamMembersMenuOpen={isTeamMembersMenuOpen}
                  setIsTeamMembersMenuOpen={setIsTeamMembersMenuOpen}
                  teamMembersList={teamMembersList}
                  teamMembersUserAssignedTo={teamMembersUserAssignedTo}
                  setAssignedTo={setAssignedTo}
                  componentLocation="add_client_modal"
                />
              </div>
            </div>
          ) : null}

          {
            <span
              className={styles.display_details}
              onClick={() => toggleShowMoreDetails(!showMoreDetails)}
            >
              {!showMoreDetails
                ? '+ Add more details (optional)'
                : '- Show less details (optional)'}
            </span>
          }

          {shouldShowDisorderTags && (
            <div>
              <div className={styles.select_all_that_apply_container}>
                <p>{t('select-the-tags-that-apply')}</p>
              </div>

              <div className={styles.brain_disorders_container}>
                {brainDisorders.map(brainDisorder => (
                  <div key={brainDisorder.name}>
                    <Chip
                      classes={{
                        label: styles.chip_label,
                        root: classNames({
                          [styles.chip_selected_root]: selectedDisorders.includes(
                            brainDisorder.name
                          ),
                        }),
                      }}
                      label={brainDisorder.name}
                      onClick={() => onDisorderClick(brainDisorder.name)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export const ClientDetailsForm = React.memo(forwardRef(ClientDetailsFormBase));
ClientDetailsForm.displayName = 'ClientDetailsForm';

// define propTypes after forwardRef or else eslint will throw warning
ClientDetailsFormBase.propTypes = exact({
  selectedDisorders: PropTypes.array.isRequired,
  brainDisorders: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  showMoreDetails: PropTypes.bool.isRequired,
  toggleShowMoreDetails: PropTypes.func.isRequired,
  isTeamMembersMenuOpen: PropTypes.bool.isRequired,
  setIsTeamMembersMenuOpen: PropTypes.func.isRequired,
  teamMembersUserAssignedTo: PropTypes.object.isRequired,
  setAssignedTo: PropTypes.func.isRequired,
  teamMembersList: PropTypes.obj,
});

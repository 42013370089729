import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './AddToReportModal.scss';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';

export const AddToReportModalBase = ({
  t,
  classes,
  onClickAway,
  onCancelClick,
  onAddToReportBtnClick,
  addableContent,
  addableContentHeader,
  isAddToReportBtnDisabled,
}) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <Paper classes={{ root: styles.add_to_report_modal_paper_root }}>
      <div className={styles.add_to_report_modal_body}>
        <div className={styles.add_to_report_modal_section}>
          <div>
            <p>{t('add_to_report_title')}</p>
          </div>
          <div>{addableContent}</div>
        </div>
      </div>

      <div className={styles.add_to_report_modal_actions_container}>
        <Button
          variant="text"
          className={classes.button}
          onClick={onCancelClick}
          classes={{ root: styles.cancel_button }}
          color="default"
        >
          {t('cancel')}
        </Button>

        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onAddToReportBtnClick}
          disabled={
            addableContentHeader.trim() === '' || isAddToReportBtnDisabled
          }
          data-test-id="add-to-report-btn"
        >
          {t('add_to_report')}
        </Button>
      </div>
    </Paper>
  </ClickAwayListener>
);

AddToReportModalBase.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onClickAway: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onAddToReportBtnClick: PropTypes.func.isRequired,
  addableContent: PropTypes.node.isRequired,
  addableContentHeader: PropTypes.string.isRequired,
  isAddToReportBtnDisabled: PropTypes.bool,
};

const stylesFn = theme => ({
  button: {
    margin: theme.spacing.unit,
    borderRadius: '4px',
    fontSize: '14px',
  },
});

export const AddToReportModal = withTranslation(
  withStyles(stylesFn)(AddToReportModalBase)
);

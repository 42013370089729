exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoListItem_lago_list_item_container{margin:10px;font-size:1.5rem;border:1px;cursor:pointer;display:flex;justify-content:space-between;align-items:center}.LagoListItem_lago_list_btn_container{margin-left:50px;display:flex}.LagoListItem_lago_list_item_text{max-width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:flex;align-items:center}@media only screen and (max-width: 1200px){.LagoListItem_lago_list_item_text{max-width:150px}}@media only screen and (max-width: 480px){.LagoListItem_lago_list_item_text{max-width:150px}}.LagoListItem_variant_item_container:hover{font-weight:600}.LagoListItem_variant_item_edit{margin:5px}.LagoListItem_variant_item_edit:hover{font-weight:800}\n", ""]);

// Exports
exports.locals = {
	"lago_list_item_container": "LagoListItem_lago_list_item_container",
	"lago_list_btn_container": "LagoListItem_lago_list_btn_container",
	"lago_list_item_text": "LagoListItem_lago_list_item_text",
	"variant_item_container": "LagoListItem_variant_item_container",
	"variant_item_edit": "LagoListItem_variant_item_edit"
};
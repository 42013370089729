import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import dayJS from 'dayjs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditIcon from '@material-ui/icons/Edit';
import RedoIcon from '@material-ui/icons/Redo';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import RightIcon from '@material-ui/icons/ArrowRight';
import { withStyles, Avatar, CircularProgress } from '@material-ui/core';
import Switch from 'react-switch';
import { injectStripe } from 'react-stripe-elements';
import styles from './ClinicAccountPage.scss';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { CustomTable } from '../../Core/Components/CustomTable/CustomTable';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import { PackagePlans, PACKAGE_PLANS_TABS } from '../PackagePlans/PackagePlans';
import { NonfPackagePlan } from '../NonfPackagePlan/NonfPackagePlan';
// import { StartTrialBanner } from '../StartTrialBanner/StartTrialBanner';
import { DeactivateAccountDialog } from './DeactivateAccountDialog/DeactivateAccountDialog';
import { CancelAccountDeactivationDialog } from './CancelAccountDeactivationDialog/CancelAccountDeactivationDialog';
import { mdMediaMaxWidth, subHeaderFontSize } from '../../cssInJs/constants';
import failedCircleImageSrc from '../../../assets/failed-circle.png';
import selfRegistrationDemoSrc from '../../../assets/self-registration-demo3.png';
import musesSrc from '../../../assets/museheadband.jpg';
import electrodeSrc from '../../../assets/electrode.png';
import pasteSrc from '../../../assets/paste2.png';
import WarningImg from '../../../assets/warning.png';
import doneAnimationImageSrc from '../../../assets/done-animation.gif';
import { PACKAGES_TYPES } from '../../models/packages/packages';
import {
  ChangePlanDialog,
  CHANGE_PLAN_DIALOG_RENDERED_STEPS,
  CHANGE_TYPES,
} from './ChangePlanDialog/ChangePlanDialog';
import {
  ActivateAccountDialog,
  ACTIVATE_ACCOUNT_DIALOG_STEPS,
} from './ActivateAccountDialog/ActivateAccountDialog';
import { HeadsetsUsageDetails } from './HeadsetsUsageDetails/HeadsetsUsageDetails';
import {
  FIREBASE_ORDER_BY_FUNCTIONS,
  // FIREBASE_LIMIT_TO_FUNCTIONS,
} from '../../models/firebase/firebase';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import {
  CANCEL_DOWNGRADING_DIALOG_STEPS,
  CancelDowngradingDialog,
} from './CancelDowngradingDialog/CancelDowngradingDialog';
import { DeactivateAddonsDialog } from './DeactivateAddonsDialog/DeactivateAddonsDialog';
import { AddReactivateAddonsDialog } from './AddReactivateAddonsDialog/AddReactivateAddonsDialog';
import { CreditCardDetailsDialogWithValidation } from '../CreditCardDetailsDialog/CreditCardDetailsDialogWithValidation';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { defensiveThrow, getCurrentURLParams } from '../../utils/utils';
import { ChangeCreditCardDetailsIfNeededWrapper } from '../ChangeCreditCardDetailsIfNeeded/ChangeCreditCardDetailsIfNeededWrapper';
import {
  EditClinicInformationDialog,
  EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS,
} from './EditClinicInformationDialog/EditClinicInformationDialog';
import { OrdersHistory } from './OrdersHistory/OrdersHistory';

const throttledShowNotification = throttle(
  window.showNotification || (() => {}),
  11000
);

const packagePlansColorsNonf = ['#4BE0D0', '#ABA5FC', '#AB7EE8'];

const NUMBER_OF_USERS_ON_UNLIMITED_PLAN = 999;

const packagePlansColors = [
  ['#4baaa6', '#439995'],
  ['#4B7AAA', '#426E99'],
  ['#6C4BAA', '#614498'],
  ['#703680', '#4b2456'],
  ['#4baaa6', '#439995'],
  ['#4B7AAA', '#426E99'],
  ['#6C4BAA', '#614498'],
  ['#703680', '#4b2456'],
];
const downgradeColor = '#8d8d8d';

const mediaQueryListMedium = window.matchMedia(
  `(max-width: ${mdMediaMaxWidth}px)`
);

const initialDeactivationReasons = [
  'Don’t need digital clinic tools',
  'Don’t need neurofeedback',
  "Couldn't figure how to price it",
  'No interested clients',
  'Too expensive',
  'Technical issues',
  'Other',
].reduce((acc, cur) => ({ ...acc, [cur]: false }), {});

const initialDeactivationAddonsReasons = [
  'Don’t need digital clinic tools',
  'Don’t need neurofeedback',
  'Couldn’t figure how to price it',
  'No interested clients',
  'Too expensive',
  'Technical issues',
  'Other',
].reduce((acc, cur) => ({ ...acc, [cur]: false }), {});

const newAccountPageLogger = new MyEventWrapper('new_account_page');

const CREDIT_CARD_DETAILS_DIALOG_OPENERS = {
  ACTIVATE_ACCOUNT: Symbol('Activate Account'),
  EDIT_PAYMENT: Symbol('Edit Payment'),
  CHANGE_PLAN: Symbol('Change Plan'),
};

const stylesFn = () => ({
  buttonRoot: {
    borderRadius: '20px',
  },
  upgradeButtonRoot: {
    borderRadius: '20px',
    background: '#4baaa6',
    color: 'white',
    '&:hover': {
      background: '#439995',
    },
  },
});

const CustomLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#EFEFEF',
  },
  barColorPrimary: {
    backgroundColor: '#51e5d6',
    borderRadius: '8px',
  },
  root: {
    borderRadius: '8px',
    height: '16px',
  },
})(LinearProgress);

const { tab } = getCurrentURLParams();

const ClinicAccountPageBase = React.memo(
  ({ classes, clinicId, stripe, onComponentReady }) => {
    const isStripeLoaded = stripe !== null;
    const t = useTranslation();

    const [
      displayLegacyDiactivationDialog,
      setDisplayLegacyDiactivationDialog,
    ] = useState(false);

    const [
      displayShouldArchiveUsersDialog,
      setDisplayShouldArchiveUsersDialog,
    ] = useState(false);
    const [wantedPlanUsers, setWantedPlanUsers] = useState(null);

    const [
      displayShouldCancelDeactivationDialog,
      setDisplayShouldCancelDeactivationDialog,
    ] = useState(false);
    const [
      displayTooManyUsersForReactivation,
      setDisplayTooManyUsersForReactivation,
    ] = useState(false);

    const [
      displayTooManyUsersForReactivationLoading,
      setDisplayTooManyUsersForReactivationLoading,
    ] = useState(false);

    const [extraPatients, setExtraPatients] = useState(0);

    const [isMediumScreen, setIsMediumScreen] = useState(
      mediaQueryListMedium.matches
    );

    const [currentTab, setCurrentTab] = useState(tab || 'overview');
    const [
      isDeactivateAccountDialogOpen,
      setIsDeactivateAccountDialogOpen,
    ] = useState(false);
    const [
      deactivateAccountDialogStep,
      setDeactivateAccountDialogStep,
    ] = useState('reasonsForDeactivation');

    const [activeHeadsetDuration, setActiveHeadsetDuration] = useState(null);
    const [usageData, setUsageData] = useState(null);

    const [
      isEditClinicInformationDialogOpen,
      setIsEditClinicInformationDialogOpen,
    ] = useState(false);
    const [
      editClinicInformationRenderedStep,
      setEditClinicInformationRenderedStep,
    ] = useState(EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FORM);

    const [
      isCancelDeactivationDialogOpen,
      setIsCancelDeactivationDialogOpen,
    ] = useState(false);
    const [
      cancelDeactivationDialogRenderedStep,
      setCancelDeactivationDialogRenderedStep,
    ] = useState('loading');

    const [
      isActivateAccountDialogOpen,
      setIsActivateAccountDialogOpen,
    ] = useState(false);

    const [
      cancelDowngradingDialogRenderedStep,
      setCancelDowngradingDialogRenderedStep,
    ] = useState(null);

    const activateAccountDialogDefaultRenderedStep =
      ACTIVATE_ACCOUNT_DIALOG_STEPS.LOADING;
    const [
      activateAccountDialogRenderedStep,
      setActivateAccountDialogRenderedStep,
    ] = useState(activateAccountDialogDefaultRenderedStep);

    const [toggleAnual, setToggleAnual] = useState(false);
    const [disableToggle, setDisableToggle] = useState(false);

    const [
      isDeactivateAddonsDialogOpen,
      setIsDeactivateAddonsDialogOpen,
    ] = useState(false);
    const [
      deactivateAddonsDialogStep,
      setDeactivateAddonsDialogStep,
    ] = useState(0);
    const [deactivateAddonsReasons, setDeactivateAddonsReasons] = useState(
      initialDeactivationAddonsReasons
    );
    const [deactivationAddonComment, setDeactivationAddonComment] = useState(
      ''
    );

    const [
      isAddReactivateAddonsDialogOpen,
      setIsAddReactivateAddonsDialogOpen,
    ] = useState(false);
    const [
      addReactivateAddonsSuccess,
      setAddReactivateAddonsSuccess,
    ] = useState(false);
    const [isloadingAddreactivate, setIsloadingAddreactivate] = useState(false);

    const [isBlockedToFreeWithDebt, setIsBlockedToFreeWithDebt] = useState(
      false
    );
    const [isBlockedToPlan, setIsBlockedToPlan] = useState(false);

    const [togglePlanPagination, setTogglePlanPagination] = useState(false);

    const [isLoadingUpgradeWithCard, setIsLoadingUpgradeWithCard] = useState(
      false
    );

    const [isRetryCharge, setIsRetryCharge] = useState(false);

    const [
      displaySuccessfullRecharge,
      setDisplaySuccessfullRecharge,
    ] = useState(false);

    const closeActivateAccountDialog = useCallback(() => {
      setIsActivateAccountDialogOpen(false);
      setActivateAccountDialogRenderedStep(
        activateAccountDialogDefaultRenderedStep
      );
    }, [activateAccountDialogDefaultRenderedStep]);

    const onActivateAccountDialogClose = useCallback(() => {
      if (
        activateAccountDialogRenderedStep !==
        ACTIVATE_ACCOUNT_DIALOG_STEPS.LOADING
      ) {
        closeActivateAccountDialog();
        newAccountPageLogger.log('on_activate_account_dialog_close');
      }
    }, [closeActivateAccountDialog, activateAccountDialogRenderedStep]);

    const onActivateAccountDialogConfirm = useCallback(() => {
      closeActivateAccountDialog();
      newAccountPageLogger.log('on_activate_account_dialog_confirm');
    }, [closeActivateAccountDialog]);

    const closeCancelDeactivationDialog = useCallback(() => {
      setIsCancelDeactivationDialogOpen(false);
      setCancelDeactivationDialogRenderedStep('loading');
    }, []);

    const onCancelDeactivationDialogConfirm = useCallback(() => {
      newAccountPageLogger.log('on_cancel_deactivation_dialog_done');
      closeCancelDeactivationDialog();
    }, [closeCancelDeactivationDialog]);

    const onCancelDeactivationDialogClose = useCallback(() => {
      if (cancelDeactivationDialogRenderedStep !== 'loading') {
        newAccountPageLogger.log('on_cancel_deactivation_dialog_close');
        closeCancelDeactivationDialog();
      }
    }, [cancelDeactivationDialogRenderedStep, closeCancelDeactivationDialog]);

    const onCancelDeactivation = useCallback(async () => {
      newAccountPageLogger.log('on_cancel_deactivation_click');
      setIsCancelDeactivationDialogOpen(true);
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('billing-changeActivation', {
          shouldDeactivate: false,
        });
        if (data.result === true) {
          setCancelDeactivationDialogRenderedStep('deactivationCanceled');
        } else {
          // TODO should handle error
        }
      } catch (err) {
        // TODO show an error in the modal
      }
    }, []);

    const [
      isCreditCardDetailsDialogOpen,
      setIsCreditCardDetailsDialogOpen,
    ] = useState(false);

    const [
      creditCardDetailsDialogOpenedBy,
      setCreditCardDetailsDialogOpenedBy,
    ] = useState(null);

    const [NFPackages, setNFPackages] = useState(null);
    const [displayHardwareReminder, setDisplayHardwareReminder] = useState(
      false
    );

    const creditCardDetailsDialogHeader =
      creditCardDetailsDialogOpenedBy ===
      CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
        ? 'Activate'
        : 'Change Your Credit Card';

    const openCreditCardDetailsDialog = openedBy => {
      setCreditCardDetailsDialogOpenedBy(openedBy);
      setIsCreditCardDetailsDialogOpen(true);
    };

    const onEditPayment = useCallback(() => {
      openCreditCardDetailsDialog(
        CREDIT_CARD_DETAILS_DIALOG_OPENERS.EDIT_PAYMENT
      );
      newAccountPageLogger.log('on_edit_payment_method_click');
    }, []);

    const onEditClinicInformation = useCallback(() => {
      setIsEditClinicInformationDialogOpen(true);
      // newAccountPageLogger.log('on_edit_payment_method_click');
      newAccountPageLogger.log('on_edit_account_info', {
        engaged_element: 'open',
      });
    }, []);

    const creditCardDetailsDialogRef = useRef();

    const [
      creditCardDetailsRenderedStep,
      setCreditCardDetailsRenderedStep,
    ] = useState('cardDetailsInput');

    const resetCreditCardDetailsDialogDefaultState = () => {
      creditCardDetailsDialogRef.current.resetDefaultState();
      setCreditCardDetailsRenderedStep('cardDetailsInput');
    };

    const closeCreditCardDetailsDialog = useCallback(() => {
      setIsCreditCardDetailsDialogOpen(false);
      setCreditCardDetailsDialogOpenedBy(null);
      setTimeout(() => resetCreditCardDetailsDialogDefaultState(), 50);
      if (isBlockedToFreeWithDebt) {
        // eslint-disable-next-line no-undef, no-restricted-globals
        location.reload(true);
      }
    }, []);

    const emptyObjectRef = useRef(null);
    emptyObjectRef.current = emptyObjectRef.current || {};
    // const limitToArgumentsRef = useRef(null);
    // limitToArgumentsRef.current = limitToArgumentsRef.current || [24];
    const [selectedBillingCycle, setSelectedBillingCycle] = useState(null);
    const [billingCyclesData] = useFirebaseDB({
      path: `usageDetails/${clinicId}`,
      onceListener: true,
      initialValue: emptyObjectRef.current,
      defaultValue: emptyObjectRef.current,
      orderByType: FIREBASE_ORDER_BY_FUNCTIONS.ORDER_BY_KEY,
      // limitToType: FIREBASE_LIMIT_TO_FUNCTIONS.LIMIT_TO_LAST,
      // limitToArguments: limitToArgumentsRef.current,
    });

    const onBillingCycleChange = useCallback(
      e => {
        const selectedBillingCycleId = e.target.value;
        setSelectedBillingCycle({
          ...billingCyclesData[selectedBillingCycleId],
          id: selectedBillingCycleId,
        });
      },
      [billingCyclesData]
    );

    const [prvCardLastFourDigits, setPrvCardLastFourDigits] = useState(null);
    const [userInfo] = useFirebaseDB({
      path: `userInfo/${clinicId}`,
    });

    const [defaultGroupId] = useFirebaseDB({
      path: 'config/defaultGroupId',
    });

    const [addonGroupId] = useFirebaseDB({
      path: 'config/defaultNFGroupId',
    });

    const [nextPlan] = useFirebaseDB({
      path: `billing/customPlans/baseHeadset/${clinicId}/nextPlan`,
    });

    const [nextPlanNonf] = useFirebaseDB({
      path: `billing/customPlans/nonfPlan/${clinicId}/nextPlan`,
    });

    const [clinicNonfPlan] = useFirebaseDB({
      path: `billing/customPlans/nonfPlan/${clinicId}`,
    });

    const [nfFlag] = useFirebaseDB({
      path: `billing/customPlans/nonfPlan/${clinicId}/nfAddOnPlanId`,
    });

    const [usersIncluded] = useFirebaseDB({
      path: `billing/customPlans/nonfPlan/${clinicId}/usersIncluded`,
    });

    const [canAccessNf] = useFirebaseDB({
      path: `clinicConfig/nfFlag/${clinicId}/isEnabled`,
    });

    const [nfFlagPendingDeactivation] = useFirebaseDB({
      path: `billing/currentPackages/${clinicId}/NFAddonDeactivationRequested`,
    });

    const [ordersHistory] = useFirebaseDB({
      path: `inventory/initialShopOrdersHistory/${clinicId}`,
    });

    const [groupId, setGroupId] = useState(null);
    const [currentPackage, setCurrentPackage] = useState(null);

    const [
      isActivatingCurrentPackage,
      setIsActivatingCurrentPackage,
    ] = useState(null);
    const [currentPackageIndex] = useFirebaseDB({
      path: `billing/customPlans/baseHeadset/${sessionStorage.userId}/selectedPlan/packageIndex`,
    });
    const [currentPackageIndexNonfPlan] = useFirebaseDB({
      path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/selectedPlan/packageIndex`,
    });
    const [packages, setPackages] = useState(null);
    const [dormantPackage, setDormantPackage] = useState(null);
    const [accountBalance, setAccountBalance] = useState(null);

    const [creditOrChargeExtra, setCreditOrChargeExtra] = useState(0);
    const [creditOrChargeExtraNf, setCreditOrChargeExtraNf] = useState(0);
    const [deactivationNfCredit, setDeactivationNfCredit] = useState(0);

    const [nonfBillingInvoices, setNonfBillingInvoices] = useState(null);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);

    const [isTeamMember, setIsTeamMember] = useState(false);

    const [
      userOutstandingInvoicesAmount,
      setUserOutstandingInvoicesAmount,
    ] = useState(0);

    const [
      packageDetailsForDeactivation,
      setPackageDetailsForDeactivation,
    ] = useState(null);

    const [dormantPlansInfo, setDormantPlansInfo] = useState(null);
    const [userPrice, setUserPrice] = useState(null);

    const shouldRenderDormant =
      (clinicNonfPlan && clinicNonfPlan.isDormantPlan) ||
      (currentPackage &&
        currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE);

    useEffect(() => {
      if (
        isActivatingCurrentPackage &&
        currentPackage &&
        currentPackage.packageType !== PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
      ) {
        setIsActivatingCurrentPackage(false);
        setActivateAccountDialogRenderedStep(
          ACTIVATE_ACCOUNT_DIALOG_STEPS.ACCOUNT_ACTIVATED
        );
      }
    }, [currentPackage, isActivatingCurrentPackage]);

    useEffect(() => {
      (async () => {
        if (accountBalance === null) {
          try {
            // eslint-disable-next-line no-undef
            const { balance } = await fireFunctionPost(
              'billing-accountBalance'
            );
            setAccountBalance(balance);
          } catch (err) {
            setAccountBalance(0);
          }
        }
      })();
    }, [accountBalance]);

    useEffect(() => {
      (async () => {
        try {
          // eslint-disable-next-line no-undef
          const userOutstandingInvoices = await fireFunctionPost(
            'billing-fetchOutstandingInvoices'
          );
          if (
            userOutstandingInvoices &&
            userOutstandingInvoices.outstandingInvoices &&
            userOutstandingInvoices.outstandingInvoices.length
          ) {
            let outstandingInvoicesAmount = 0;
            userOutstandingInvoices.outstandingInvoices.forEach(invoice => {
              outstandingInvoicesAmount += invoice.amount_due;
            });
            setUserOutstandingInvoicesAmount(outstandingInvoicesAmount);
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      })();
    }, [userOutstandingInvoicesAmount]);

    useEffect(() => {
      (async () => {
        if (
          nfFlag &&
          currentPackage &&
          currentPackage.packageType !== PACKAGES_TYPES.BLOCKED_PACKAGE
        ) {
          try {
            // eslint-disable-next-line no-undef
            const { charge } = await fireFunctionPost(
              'billing-addOnActivationCharge'
            );
            setCreditOrChargeExtraNf(charge);
          } catch (err) {
            // setCreditOrChargeExtraNf(0);
          }
        }
      })();
    }, [creditOrChargeExtraNf, nfFlag, currentPackage]);

    useEffect(() => {
      (async () => {
        if (clinicNonfPlan) {
          try {
            setIsLoadingInvoices(true);
            // eslint-disable-next-line no-undef
            const { invoices } = await fireFunctionPost(
              'billing-nonfBillingInvoices'
            );
            setNonfBillingInvoices(invoices);
            setIsLoadingInvoices(false);
          } catch (err) {
            // console.log(err)
          }
        }
      })();
    }, [clinicNonfPlan]);

    useEffect(() => {
      if (sessionStorage.isTeamMember === 'true') {
        // window.open('/index.html', '_self');
        setIsTeamMember(true);
      }
    }, []);

    // Needed when a fresh user activates his account, so that we can enable him to use site features
    if (
      currentPackage !== null &&
      sessionStorage.packageType !== currentPackage.packageType
    ) {
      sessionStorage.packageType = currentPackage.packageType;
    }

    const currentPackageEndDate = dayJS(
      currentPackage && currentPackage.expiration
    ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear);

    const currentPackageStartDate = dayJS(
      currentPackage && currentPackage.start
    ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear);

    const updateClinicUsageData = useCallback(async () => {
      // eslint-disable-next-line no-undef
      const clinicUsageData = await fireFunctionPost('billing-newBillingUsage');
      setUsageData(clinicUsageData);
    }, []);

    useEffect(() => {
      const currentPackageDatabaseRef = database.ref(
        `billing/currentPackages/${clinicId}`
      );

      const onCurrentPackageValue = async snapshot => {
        await updateClinicUsageData();
        setCurrentPackage(snapshot.val());
      };

      currentPackageDatabaseRef.on('value', onCurrentPackageValue);

      return () => {
        currentPackageDatabaseRef.off('value', onCurrentPackageValue);
      };
    }, [clinicId, updateClinicUsageData]);

    const onFAQSClick = () => {
      window.open(`faqs.html?new=${!!clinicNonfPlan}`, '_self');
    };

    const onCreditCardDetailsDialogConfirm = useCallback(() => {
      newAccountPageLogger.log('on_credit_card_details_dialog_confirm');
      if (creditCardDetailsRenderedStep === 'cardDetailsInput') {
        const handleSuccessfulRegisterPayment = async () => {
          if (
            creditCardDetailsDialogOpenedBy ===
            CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
          ) {
            setCreditCardDetailsRenderedStep('creditCardChargedSuccessfully');
            if (isBlockedToFreeWithDebt) {
              // eslint-disable-next-line no-undef
              await fireFunctionPost('billing-moveBlockedToDormantPlan', {
                planConfigs: { ...dormantPlansInfo },
              });
            }
          } else if (
            creditCardDetailsDialogOpenedBy ===
            CREDIT_CARD_DETAILS_DIALOG_OPENERS.CHANGE_PLAN
          ) {
            const lastFourDigits = ( // eslint-disable-next-line no-undef
              await fireFunctionPost('billing-paymentSourceInfo')
            ).cardInfo.last4;
            setPrvCardLastFourDigits(lastFourDigits);
            closeCreditCardDetailsDialog();
          } else {
            setCreditCardDetailsRenderedStep('creditCardUpdatedSuccessfully');
            const lastFourDigits = ( // eslint-disable-next-line no-undef
              await fireFunctionPost('billing-paymentSourceInfo')
            ).cardInfo.last4;
            setPrvCardLastFourDigits(lastFourDigits);
            try {
              // eslint-disable-next-line no-undef
              const { balance } = await fireFunctionPost(
                'billing-accountBalance'
              );
              setAccountBalance(balance);
            } catch (e) {
              setAccountBalance(0);
            }
          }
        };

        const handleFailedRegisterPayment = () => {
          if (
            creditCardDetailsDialogOpenedBy ===
            CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
          ) {
            setCreditCardDetailsRenderedStep('creditCardChargeFailed');
          } else {
            setCreditCardDetailsRenderedStep('creditCardWasNotUpdated');
          }
        };

        setCreditCardDetailsRenderedStep('loading');
        stripe.createToken().then(async result => {
          if (result.error) {
            if (result.error.type === 'validation_error') {
              setCreditCardDetailsRenderedStep('cardDetailsInput');
            } else {
              handleFailedRegisterPayment();
            }
          } else {
            try {
              // eslint-disable-next-line no-undef
              const data = await fireFunctionPost(
                'billing-registerPaymentMethod',
                {
                  details: result,
                  planDetails: packageDetailsForDeactivation,
                }
              );

              if (data.result === true) {
                handleSuccessfulRegisterPayment();
              } else {
                handleFailedRegisterPayment();
              }
            } catch (err) {
              handleFailedRegisterPayment();
            }
          }
        });
      }

      if (
        creditCardDetailsRenderedStep === 'creditCardWasNotUpdated' ||
        creditCardDetailsRenderedStep === 'creditCardChargeFailed'
      ) {
        resetCreditCardDetailsDialogDefaultState();
      }

      if (
        creditCardDetailsRenderedStep === 'creditCardUpdatedSuccessfully' ||
        creditCardDetailsRenderedStep === 'creditCardChargedSuccessfully'
      ) {
        closeCreditCardDetailsDialog();
        if (isBlockedToPlan) {
          // eslint-disable-next-line no-undef, no-restricted-globals
          location.reload(true);
        }
      }
    }, [
      creditCardDetailsRenderedStep,
      stripe,
      closeCreditCardDetailsDialog,
      creditCardDetailsDialogOpenedBy,
    ]);

    useEffect(() => {
      // eslint-disable-next-line consistent-return
      (async () => {
        try {
          const dormantPlans = await database
            .ref('config/dormantPlans')
            .once('value')
            .then(snapshot => snapshot.val());

          const dormantPackageData = await database
            .ref(
              `billing/packages/${dormantPlans.packageType}/${dormantPlans.groupId}/${dormantPlans.packageIndex}`
            )
            .once('value')
            .then(snapshot => snapshot.val());

          const userPriceRef = await database
            .ref(
              `billing/packages/customNONFPackages/${dormantPlans.groupId}/${dormantPlans.packageIndex}/addOnOptions/nfAddOns/${dormantPlans.nfGroupId}/userPrice`
            )
            .once('value')
            .then(snapshot => snapshot.val());

          setDormantPlansInfo(dormantPlans);
          if (!dormantPackage) {
            setDormantPackage(dormantPackageData);
          }
          setUserPrice(userPriceRef);
          if (
            // eslint-disable-next-line no-undef
            (await isDistributorOrChildOfDistributorClinic()) ||
            // eslint-disable-next-line no-undef
            isTrialClinic()
          ) {
            // eslint-disable-next-line consistent-return
            return window.open(
              // eslint-disable-next-line prefer-template
              '/account.html' + (tab ? `?tab=${tab}` : ''),
              '_self'
            );
          }
        } catch (error) {
          defensiveThrow({ error });
        }
        if (!currentPackage || groupId || packages || !defaultGroupId) {
          // eslint-disable-next-line consistent-return
          return;
        }
        if (clinicNonfPlan && clinicNonfPlan.isDormantPlan) {
          const allowedGroupId = await database
            .ref('config/nonfDefaultGroupId')
            .once('value')
            .then(snapshot => snapshot.val());

          const userPackages = await database
            .ref(`billing/packages/customNONFPackages/${allowedGroupId}`)
            .once('value')
            .then(snapshot => snapshot.val());

          // const dormantPlans = await database
          //   .ref('config/dormantPlans')
          //   .once('value')
          //   .then(snapshot => snapshot.val());

          // const dormantPackageData = await database
          //   .ref(
          //     `billing/packages/${dormantPlans.packageType}/${dormantPlans.groupId}/${dormantPlans.packageIndex}`
          //   )
          //   .once('value')
          //   .then(snapshot => snapshot.val());

          setGroupId(allowedGroupId);
          setPackages(userPackages);
          // setDormantPackage(dormantPackageData);
          // setDormantPlansInfo(dormantPlans);
          setToggleAnual(true);
        } else if (clinicNonfPlan) {
          const allowedGroupId = await database
            .ref(`billing/customPlans/nonfPlan/${clinicId}/allowedGroupId`)
            .once('value')
            .then(snapshot => snapshot.val());
          const userPackages = await database
            .ref(`billing/packages/customNONFPackages/${allowedGroupId}`)
            .once('value')
            .then(snapshot => snapshot.val());

          const NFPackagesRef = await database
            .ref(`billing/packages/customNFUserPackages/${addonGroupId}/0`)
            .once('value')
            .then(snapshot => snapshot.val());

          if (!groupId) {
            setGroupId(allowedGroupId);
          }
          if (!packages) {
            setPackages(userPackages);
          }
          setNFPackages(NFPackagesRef);
          setToggleAnual(currentPackage.isAnnual);
        } else if (
          currentPackage &&
          currentPackage.packageType !== PACKAGES_TYPES.BLOCKED_PACKAGE
        ) {
          const allowedGroupId = await database
            .ref(`billing/customPlans/baseHeadset/${clinicId}/allowedGroupId`)
            .once('value')
            .then(snapshot => snapshot.val());

          const userPackages = await database
            .ref(`billing/packages/customPackages/${allowedGroupId}`)
            .once('value')
            .then(snapshot => snapshot.val());

          setGroupId(allowedGroupId);
          setPackages(userPackages);
        } else if (
          currentPackage &&
          currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE
        ) {
          // const dormantPlans = await database
          //   .ref('config/dormantPlans')
          //   .once('value')
          //   .then(snapshot => snapshot.val());

          // const dormantPackageData = await database
          //   .ref(
          //     `billing/packages/${dormantPlans.packageType}/${dormantPlans.groupId}/${dormantPlans.packageIndex}`
          //   )
          //   .once('value')
          //   .then(snapshot => snapshot.val());

          const allowedGroupId = await database
            .ref('config/nonfDefaultGroupId')
            .once('value')
            .then(snapshot => snapshot.val());

          const userPackages = await database
            .ref(`billing/packages/customNONFPackages/${allowedGroupId}`)
            .once('value')
            .then(snapshot => snapshot.val());

          const NFPackagesRef = await database
            .ref(`billing/packages/customNFUserPackages/${addonGroupId}/0`)
            .once('value')
            .then(snapshot => snapshot.val());

          setGroupId(defaultGroupId);
          setPackages(userPackages);
          setNFPackages(NFPackagesRef);
          // setDormantPackage(dormantPackageData);
          // setDormantPlansInfo(dormantPlans);
        } else {
          const userPackages = await database
            .ref(`billing/packages/customPackages/${defaultGroupId}`)
            .once('value')
            .then(snapshot => snapshot.val());
          setGroupId(defaultGroupId);
          setPackages(userPackages);
        }
      })();
    }, [
      defaultGroupId,
      currentPackage,
      clinicId,
      groupId,
      packages,
      dormantPackage,
    ]);

    useEffect(() => {
      if (packages) {
        const packageWithMonthly = packages.find(pack => !pack.hideMonthly);
        const isDormantAnnualOnly =
          clinicNonfPlan && clinicNonfPlan.isDormantPlan;
        if (!packageWithMonthly || isDormantAnnualOnly) {
          setToggleAnual(true);
          setDisableToggle(true);
        }
      }
    }, [packages, clinicNonfPlan]);

    const onDeactivateAccount = useCallback(() => {
      setIsDeactivateAccountDialogOpen(true);
    }, []);

    const [deactivationComment, setDeactivationComment] = useState('');
    const [deactivationCommentError, setDeactivationCommentError] = useState(
      null
    );
    const [deactivationReasons, setDeactivationReasons] = useState(
      initialDeactivationReasons
    );
    const [deactivationReasonsError, setDeactivationReasonsError] = useState(
      null
    );

    const [
      deactivationAddonsReasonsError,
      setDeactivationAddonsReasonsError,
    ] = useState(null);

    const [
      deactivationAddonCommentError,
      setDeactivationAddonCommentError,
    ] = useState(null);

    const onDeactivationCommentChange = useCallback(event => {
      if (event.target.value.length <= 2000) {
        setDeactivationComment(event.target.value);
      } else {
        throttledShowNotification(
          'danger',
          'Comment should not be longer than 2000 characters'
        );
      }
    }, []);

    const onDeactivationAddonCommentChange = useCallback(event => {
      if (event.target.value.length <= 2000) {
        setDeactivationAddonComment(event.target.value);
      } else {
        throttledShowNotification(
          'danger',
          'Comment should not be longer than 2000 characters'
        );
      }
    }, []);

    const onDeactivateAddons = async () => {
      const isSomeDeactivationReasonSelected = Object.values(
        deactivateAddonsReasons
      ).some(value => value === true);
      const isDeactivationCommentEmpty = deactivationAddonComment === '';
      if (!isSomeDeactivationReasonSelected) {
        setDeactivationAddonsReasonsError(
          'Please pick one of the following reasons for your deactivation'
        );
      }
      if (isDeactivationCommentEmpty) {
        setDeactivationAddonCommentError(
          'Please provide more info why are you deactivating'
        );
      }
      if (!isDeactivationCommentEmpty && isSomeDeactivationReasonSelected) {
        setDeactivateAddonsDialogStep(2);
        try {
          // eslint-disable-next-line no-undef
          const data = await fireFunctionPost(
            'billing-clinicAddRemoveNFAddOn',
            {
              shouldAdd: false,
              comment: deactivationAddonComment,
              reasons: deactivateAddonsReasons,
            }
          );

          if (data.result === true) {
            newAccountPageLogger.log('deactivate_nf_add_on_modal', {
              action: 'deactivate',
            });
            setDeactivateAddonsDialogStep(3);
            setDeactivationNfCredit(data.credit);
          }
        } catch (err) {
          // TODO handle error
        }
      }
    };

    const addReactivateUser = async () => {
      new MyEvent('add_nf_add_on_modal_step_2').log({ action: 'activate' });
      setIsloadingAddreactivate(true);
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('billing-clinicAddRemoveNFAddOn', {
          shouldAdd: true,
        });

        if (data.result === true) {
          await updateClinicUsageData();
          setAddReactivateAddonsSuccess(true);
          setIsloadingAddreactivate(false);
        }
      } catch (err) {
        setIsloadingAddreactivate(false);
      }
    };

    // const onDeactivationCommentBlur = useCallback(
    //   event => {
    //     if (
    //       event.target.value.trim() !== '' &&
    //       Object.values(deactivationReasons).find(val => !!val)
    //     ) {
    //       newAccountPageLogger.log('deactivations_reasons', {
    //         ...deactivationReasons,
    //         comment: event.target.value,
    //       });
    //     }
    //   },
    //   [deactivationReasons]
    // );

    const onDeactivationReasonClick = reasonKey => {
      setDeactivationReasons(prvDeactivationReasons => {
        const newDeactivationReasons = {
          ...prvDeactivationReasons,
          [reasonKey]: !prvDeactivationReasons[reasonKey],
        };

        return newDeactivationReasons;
      });
    };

    const onDeactivationAddonsReasonClick = reasonKey => {
      setDeactivateAddonsReasons(prvDeactivationReasons => {
        const newDeactivationReasons = {
          ...prvDeactivationReasons,
          [reasonKey]: !prvDeactivationReasons[reasonKey],
        };
        return newDeactivationReasons;
      });
    };

    const closeDeactivationAccountDialog = useCallback(() => {
      setIsDeactivateAccountDialogOpen(false);
      setDeactivationComment('');
      setDeactivationReasons(initialDeactivationReasons);
      setDeactivationCommentError(null);
      setDeactivationReasonsError(null);
      setDeactivateAccountDialogStep('reasonsForDeactivation');
    }, []);

    const isSomeDeactivationReasonSelected = Object.values(
      deactivationReasons
    ).some(value => value === true);
    const isDeactivationCommentEmpty = deactivationComment === '';

    const onDeactivateAccountConfirm = useCallback(async () => {
      if (deactivateAccountDialogStep === 'doYouWantToDeactivateMyAccount') {
        newAccountPageLogger.log('on_deactivate_account_dialog_confirm', {
          entered_from: 'lets__chat',
        });

        setDeactivateAccountDialogStep('areYouSure');
      } else if (deactivateAccountDialogStep === 'reasonsForDeactivation') {
        if (isDeactivationCommentEmpty) {
          setDeactivationCommentError(
            'Please provide more info why are you deactivating'
          );
        }
        if (!isSomeDeactivationReasonSelected) {
          setDeactivationReasonsError(
            'Please pick one of the following reasons for your deactivation'
          );
        }

        if (isSomeDeactivationReasonSelected && !isDeactivationCommentEmpty) {
          newAccountPageLogger.log('deactivations_reasons', {
            ...deactivationReasons,
            comment: deactivationComment,
          });
          setDeactivateAccountDialogStep('doYouWantToDeactivateMyAccount');
        }
      } else if (deactivateAccountDialogStep === 'areYouSure') {
        newAccountPageLogger.log('on_deactivate_account_dialog_confirm', {
          entered_from: deactivateAccountDialogStep,
        });
        setDeactivateAccountDialogStep('loading');
        try {
          // eslint-disable-next-line no-undef
          const data = await fireFunctionPost('billing-changeActivation', {
            shouldDeactivate: true,
            comment: deactivationComment,
            reasons: deactivationReasons,
          });

          if (data.result === true) {
            setDeactivateAccountDialogStep('accountDeactivated');
          }
        } catch (err) {
          // TODO handle error
        }
      } else {
        closeDeactivationAccountDialog();
      }
    }, [
      deactivateAccountDialogStep,
      closeDeactivationAccountDialog,
      deactivationReasons,
      deactivationComment,
      isSomeDeactivationReasonSelected,
      isDeactivationCommentEmpty,
    ]);

    const onDeactivateAccountCancel = useCallback(() => {
      newAccountPageLogger.log('on_deactivate_account_dialog_cancel', {
        entered_from: deactivateAccountDialogStep,
      });
      if (deactivateAccountDialogStep === 'doYouWantToDeactivateMyAccount') {
        window.open('https://calendly.com/myndlift-care/check-in', '_blank');
      }
      closeDeactivationAccountDialog();
    }, [closeDeactivationAccountDialog, deactivateAccountDialogStep]);

    const onDeactivateAccountDialogClose = useCallback(() => {
      if (deactivateAccountDialogStep !== 'loading') {
        newAccountPageLogger.log('on_deactivate_account_dialog_close', {
          entered_from: deactivateAccountDialogStep,
        });
        closeDeactivationAccountDialog();
      }
    }, [deactivateAccountDialogStep, closeDeactivationAccountDialog]);

    const onCreditCardDetailsDialogClose = useCallback(() => {
      if (creditCardDetailsRenderedStep !== 'loading') {
        newAccountPageLogger.log('on_credit_card_details_dialog_close');
        closeCreditCardDetailsDialog();
      }
    }, [creditCardDetailsRenderedStep, closeCreditCardDetailsDialog]);

    const onCreditCardDetailsDialogCancel = useCallback(() => {
      newAccountPageLogger.log('on_credit_card_details_dialog_cancel');
      closeCreditCardDetailsDialog();
      setDisplayTooManyUsersForReactivationLoading(false);
    }, [closeCreditCardDetailsDialog]);

    const [isChangePlanDialogOpen, setIsChangePlanDialogOpen] = useState(false);
    const [planInReview, setPlanInReview] = useState({});

    const changePlanDialogDefaultRenderedStep =
      CHANGE_PLAN_DIALOG_RENDERED_STEPS.CONFIRM_CHANGE;

    const [
      changePlanDialogRenderedStep,
      setChangePlanDialogRenderedStep,
    ] = useState(changePlanDialogDefaultRenderedStep);

    const onChangePlanDialogUseDifferentCard = useCallback(() => {
      newAccountPageLogger.log('on_use_different_card_in_change_plan_dialog');
      openCreditCardDetailsDialog(
        CREDIT_CARD_DETAILS_DIALOG_OPENERS.CHANGE_PLAN
      );
    }, []);

    const closeChangePlanDialog = useCallback(() => {
      setChangePlanDialogRenderedStep(changePlanDialogDefaultRenderedStep);
      setIsChangePlanDialogOpen(false);
    }, [changePlanDialogDefaultRenderedStep]);

    const onChangePlanDialogConfirm = useCallback(async () => {
      newAccountPageLogger.log('on_change_plan_dialog_confirm');

      if (
        changePlanDialogRenderedStep ===
        CHANGE_PLAN_DIALOG_RENDERED_STEPS.CONFIRM_CHANGE
      ) {
        if (clinicNonfPlan && !prvCardLastFourDigits) {
          setIsLoadingUpgradeWithCard(true);
          stripe.createToken().then(async result => {
            if (result.error) {
              if (result.error.type === 'validation_error') {
                setIsLoadingUpgradeWithCard(false);
                return;
              }

              setIsLoadingUpgradeWithCard(false);
              setChangePlanDialogRenderedStep(
                CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
              );
              return;
            }
            try {
              // eslint-disable-next-line no-undef
              const data = await fireFunctionPost(
                'billing-registerPaymentMethod',
                {
                  details: result,
                  planDetails: {
                    packageIndex: planInReview.index,
                    isAnnual: true,
                    groupId,
                  },
                }
              );

              if (data.result === true) {
                // handleSuccessfulRegisterPayment();
                const lastFourDigits = ( // eslint-disable-next-line no-undef
                  await fireFunctionPost('billing-paymentSourceInfo')
                ).cardInfo.last4;
                setPrvCardLastFourDigits(lastFourDigits);
                setIsLoadingUpgradeWithCard(false);
                setChangePlanDialogRenderedStep(
                  CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY
                );
              } else {
                setIsLoadingUpgradeWithCard(false);
                setChangePlanDialogRenderedStep(
                  CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
                );
                return;
              }
            } catch (err) {
              setIsLoadingUpgradeWithCard(false);
              setChangePlanDialogRenderedStep(
                CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
              );
              // return;
            }
          });
        } else if (clinicNonfPlan && clinicNonfPlan.isDormantPlan) {
          setChangePlanDialogRenderedStep(
            CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING
          );
          try {
            // eslint-disable-next-line no-undef
            const data = await fireFunctionPost(
              'billing-registerPaymentMethod',
              {
                planDetails: {
                  packageIndex: planInReview.index,
                  isAnnual: true,
                  groupId,
                },
              }
            );

            if (data.result === true) {
              await updateClinicUsageData();
              try {
                // eslint-disable-next-line no-undef
                const { balance } = await fireFunctionPost(
                  'billing-accountBalance'
                );
                setAccountBalance(balance);
              } catch (err) {
                setAccountBalance(0);
              }

              setChangePlanDialogRenderedStep(
                CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY
              );
            } else {
              setChangePlanDialogRenderedStep(
                CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
              );
            }
          } catch (err) {
            setChangePlanDialogRenderedStep(
              CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
            );
          }
        } else {
          setChangePlanDialogRenderedStep(
            CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING
          );
          try {
            // eslint-disable-next-line no-undef
            const data = await fireFunctionPost(
              'billing-clinicChangePlanRequest',
              {
                packageIndex: planInReview.index,
                isAnnual:
                  (clinicNonfPlan && clinicNonfPlan.showNextPlan) ||
                  planInReview.type === 'annually',
              }
            );

            if (data.result === true) {
              await updateClinicUsageData();

              try {
                // eslint-disable-next-line no-undef
                const { balance } = await fireFunctionPost(
                  'billing-accountBalance'
                );
                setAccountBalance(balance);
              } catch (err) {
                setAccountBalance(0);
              }

              setChangePlanDialogRenderedStep(
                CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY
              );
            } else {
              setChangePlanDialogRenderedStep(
                CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
              );
            }
          } catch (err) {
            setChangePlanDialogRenderedStep(
              CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
            );
          }
        }
      }
      if (
        changePlanDialogRenderedStep ===
          CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY ||
        changePlanDialogRenderedStep ===
          CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED
      ) {
        closeChangePlanDialog();
        // eslint-disable-next-line no-undef, no-restricted-globals
        location.reload(true);
      }
    }, [
      changePlanDialogRenderedStep,
      closeChangePlanDialog,
      planInReview,
      updateClinicUsageData,
    ]);

    const onChangePlanDialogClose = useCallback(() => {
      newAccountPageLogger.log('on_change_plan_dialog_close');
      if (
        changePlanDialogRenderedStep !==
          CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING &&
        !isLoadingUpgradeWithCard
      ) {
        closeChangePlanDialog();
      }
    }, [changePlanDialogRenderedStep, closeChangePlanDialog]);

    const closeEditClinicInformationDialog = () => {
      setIsEditClinicInformationDialogOpen(false);
      setTimeout(() => {
        setEditClinicInformationRenderedStep(
          EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FORM
        );
      }, 500); // just to improve experience when closing
    };

    const onEditClinicInformationDialogCancel = useCallback(() => {
      newAccountPageLogger.log('on_edit_clinic_information_cancel');
      newAccountPageLogger.log('on_edit_account_info', {
        engaged_element: 'cancel',
      });
      closeEditClinicInformationDialog();
    }, []);

    const onEditClinicInformationDialogPrimaryBtnClick = useCallback(() => {
      if (
        editClinicInformationRenderedStep !==
        EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FORM
      ) {
        closeEditClinicInformationDialog();
      }
    }, [editClinicInformationRenderedStep]);

    const onEditClinicInformationDialogSave = useCallback(
      async values => {
        setEditClinicInformationRenderedStep(
          EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.LOADER
        );
        const { firstName, lastName, clinicName, phone } = values;
        const clinicUserInfoUpdate = {
          firstName,
          lastName,
          clinicName,
        };
        newAccountPageLogger.log('on_edit_account_info', {
          engaged_element: 'save',
        });
        try {
          await database
            .ref(`userInfo/${clinicId}`)
            .update(clinicUserInfoUpdate);

          await database
            .ref(`userInfo/${clinicId}/privateDetails/phoneNumber`)
            .set(phone);

          setEditClinicInformationRenderedStep(
            EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.INFO_WAS_UPDATED
          );

          newAccountPageLogger.log('on_edit_clinic_information_save', {
            firstName,
            lastName,
            clinicName,
            phone,
          });
        } catch (err) {
          setEditClinicInformationRenderedStep(
            EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FAILEd_TO_UPDATE
          );
        }
      },
      [clinicId]
    );

    const calcPackageIndex = () => {
      if (clinicNonfPlan) {
        return (
          currentPackageIndexNonfPlan !== null ||
          currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE
        );
      }
      return (
        currentPackageIndex !== null ||
        currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE
      );
    };

    const isDataLoaded =
      userInfo &&
      currentPackage &&
      calcPackageIndex() &&
      activeHeadsetDuration &&
      usageData &&
      groupId &&
      packages &&
      accountBalance !== null;

    const isComponentReadyToRender = isDataLoaded && isStripeLoaded;
    const formattedNextBillingCycle =
      currentPackage &&
      dayJS(currentPackage.expiration).format(
        DAY_JS_DATE_FORMATS.namedMonthDayYear
      );

    const clinicDetailsCardWidth = 300;
    const tabsContainerStyle = isMediumScreen
      ? { marginTop: 20 }
      : {
          marginLeft: clinicDetailsCardWidth + 30,
          width: '100%',
        };
    const clinicCardDetailsStyle = isMediumScreen
      ? {
          width: '100%',
        }
      : {
          width: clinicDetailsCardWidth,
          position: 'fixed',
        };

    useEffect(() => {
      (async () => {
        const headsetDuration = await getActiveHeadsetDuration();
        setActiveHeadsetDuration(headsetDuration);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        if (
          currentPackage &&
          currentPackage.packageType !== PACKAGES_TYPES.HEADSET_PACKAGE &&
          currentPackage.packageType !== PACKAGES_TYPES.BLOCKED_PACKAGE
        ) {
          const cardLastFourDigits = ( // eslint-disable-next-line no-undef
            await fireFunctionPost('billing-paymentSourceInfo')
          ).cardInfo.last4;
          setPrvCardLastFourDigits(cardLastFourDigits);
        }
      })();
    }, [currentPackage]);

    useEffect(() => {
      if (currentPackage && isComponentReadyToRender) {
        fillTimelineTab(currentPackage);
      }
    }, [isComponentReadyToRender, currentPackage]);

    useEffect(() => {
      if (isComponentReadyToRender) {
        onComponentReady();
      }
    }, [isComponentReadyToRender, onComponentReady]);

    useEffect(() => {
      const onWindowResize = () => {
        if (isMediumScreen !== mediaQueryListMedium.matches) {
          setIsMediumScreen(mediaQueryListMedium.matches);
        }
      };

      window.addEventListener('resize', onWindowResize);

      return () => window.removeEventListener('resize', onWindowResize);
    }, [isMediumScreen]);

    const onActivatingPreActivationAccount = useCallback(async () => {
      newAccountPageLogger.log('on_activate_account_click');
      try {
        setIsActivateAccountDialogOpen(true);
        setIsActivatingCurrentPackage(true);
        // eslint-disable-next-line no-undef
        fireFunctionPost('billing-activatePlan');
      } catch (err) {
        // TODO should handle network errors
      }
    }, []);

    const onCancelDowngrading = useCallback(async () => {
      newAccountPageLogger.log('on_cancel_downgrading');

      setCancelDowngradingDialogRenderedStep(
        CANCEL_DOWNGRADING_DIALOG_STEPS.CONFIRM_CANCELING_DOWNGRADING
      );
    }, []);

    const onCancelDowngradingDialogConfirm = useCallback(async () => {
      newAccountPageLogger.log('on_cancel_downgrading_dialog_confirm', {
        renderedStep: cancelDowngradingDialogRenderedStep,
      });
      if (
        cancelDowngradingDialogRenderedStep ===
        CANCEL_DOWNGRADING_DIALOG_STEPS.CONFIRM_CANCELING_DOWNGRADING
      ) {
        setCancelDowngradingDialogRenderedStep(
          CANCEL_DOWNGRADING_DIALOG_STEPS.LOADING
        );
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('billing-cancelDowngrade');
        if (data.result === true) {
          setCancelDowngradingDialogRenderedStep(
            CANCEL_DOWNGRADING_DIALOG_STEPS.DOWNGRADING_CANCELED
          );
        } else {
          // TODO should handle error
        }
      }

      if (
        cancelDowngradingDialogRenderedStep ===
        CANCEL_DOWNGRADING_DIALOG_STEPS.DOWNGRADING_CANCELED
      ) {
        setCancelDowngradingDialogRenderedStep(null);
      }
    }, [cancelDowngradingDialogRenderedStep]);

    const onCancelDowngradingDialogCancel = useCallback(async () => {
      newAccountPageLogger.log('on_cancel_downgrading_dialog_cancel');
      setCancelDowngradingDialogRenderedStep(null);
    }, []);

    // const onGetMoreCredit = useCallback(event => {
    //   event.preventDefault();
    //   newAccountPageLogger.log('on_get_more_credit_click');
    //   // eslint-disable-next-line no-undef
    //   openReferralPopOut();
    // }, []);

    const calcRenewalDate = () => {
      // if (nextPlanNonf) {
      //   return nextPlanNonf.isAnnual
      //     ? nextPlanNonf.annualExpiration
      //     : nextPlanNonf.expiration;
      // }
      return currentPackage.isAnnual
        ? currentPackage.annualExpiration
        : currentPackage.expiration;
    };

    const renderNoActivePlan = () => (
      <div className={styles.no_active_plan}>
        <Avatar
          alt="fail"
          src={failedCircleImageSrc}
          className={styles.fail_avatar}
        />
        <div style={{ fontSize: 18, fontWeight: 500 }}>
          You have no active plan
        </div>
        <p>please choose a plan to activate your account and run sessions.</p>
      </div>
    );

    const renderActivePlanDetails = () => {
      const extraHeadsetsCharge = usageData.units * usageData.price;
      const formattedExtraHeadsetsCharge =
        extraHeadsetsCharge === Math.floor(extraHeadsetsCharge)
          ? extraHeadsetsCharge
          : extraHeadsetsCharge.toFixed(2);

      const getNextChargeTextAndValue = () => {
        const monthlyChargeText = clinicNonfPlan
          ? '/month'
          : 'Next monthly base charge';
        const yearlyChargeText = clinicNonfPlan
          ? '/year'
          : 'Next annual base charge';
        if (currentPackage.deactivationRequested) {
          return currentPackage.isAnnual
            ? [yearlyChargeText, 0]
            : [monthlyChargeText, 0];
        }
        if (nextPlan) {
          const nextPackage = packages[nextPlan.packageIndex];

          return nextPlan.isAnnual
            ? [
                yearlyChargeText,
                Math.round(nextPackage.annualMonthlyBasePrice * 12),
              ]
            : [monthlyChargeText, nextPackage.basePrice];
        }
        if (nextPlanNonf) {
          const nextPackage = packages[nextPlanNonf.packageIndex];

          return nextPlanNonf.isAnnual
            ? [
                yearlyChargeText,
                Math.round(nextPackage.annualMonthlyBasePrice * 12),
              ]
            : [monthlyChargeText, nextPackage.basePrice];
        }
        return currentPackage.isAnnual
          ? [yearlyChargeText, Math.round(currentPackage.basePrice * 12)]
          : [monthlyChargeText, currentPackage.basePrice];
      };
      const [nextChargeText, nextChargeValue] = getNextChargeTextAndValue();

      const calcNeuroPrice = () => {
        if (NFPackages) {
          if (nextPlanNonf) {
            return nextPlanNonf.isAnnual
              ? NFPackages.annualMonthlyBasePrice * 12
              : NFPackages.basePrice;
          }
          return currentPackage.isAnnual
            ? NFPackages.annualMonthlyBasePrice * 12
            : NFPackages.basePrice;
        }
        return 0;
      };

      if (clinicNonfPlan) {
        return (
          <div className={styles.usage_cards_container}>
            <div
              className={styles.usage_card}
              style={
                window.innerWidth < 1200 ||
                usersIncluded === NUMBER_OF_USERS_ON_UNLIMITED_PLAN
                  ? { width: '100%' }
                  : { width: '59%' }
              }
            >
              <div className={styles.usage_card_title}>Subscription plan</div>
              <div className={styles.usage_card_contnent}>
                <div className={styles.usage_card_contnent_item}>
                  <div className={styles.usage_card_contnent_item_title}>
                    Plan
                  </div>
                  <div className={styles.usage_card_contnent_item_data}>
                    {currentPackage.bundleName}
                  </div>
                </div>
                <div className={styles.usage_card_contnent_item}>
                  <div className={styles.usage_card_contnent_item_title}>
                    Add-on
                  </div>
                  <div className={styles.usage_card_contnent_item_data}>
                    {nfFlag && !nfFlagPendingDeactivation ? 'Neuro' : '-'}
                  </div>
                </div>
                {currentPackage.deactivationRequested ? null : (
                  <div className={styles.usage_card_contnent_item}>
                    <div className={styles.usage_card_contnent_item_title}>
                      Total cost
                    </div>
                    <div className={styles.usage_card_contnent_item_data}>
                      $
                      {nfFlag && !nfFlagPendingDeactivation
                        ? nextChargeValue + calcNeuroPrice() + nextChargeText
                        : nextChargeValue + nextChargeText}
                    </div>
                  </div>
                )}
                <div className={styles.usage_card_contnent_item}>
                  <div className={styles.usage_card_contnent_item_title}>
                    {currentPackage.deactivationRequested
                      ? 'Cancellation date'
                      : 'Renewal date'}
                  </div>
                  <div className={styles.usage_card_contnent_item_data}>
                    {dayJS(calcRenewalDate()).format(
                      DAY_JS_DATE_FORMATS.namedMonthDayYear
                    )}
                  </div>
                </div>
                {currentPackage.deactivationRequested ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onCancelDeactivation}
                  >
                    stop cancellation
                  </Button>
                ) : null}
              </div>
            </div>
            {usersIncluded === NUMBER_OF_USERS_ON_UNLIMITED_PLAN ? null : (
              <div
                className={styles.usage_card}
                style={
                  window.innerWidth < 1200
                    ? { width: '100%', marginTop: 15 }
                    : { width: '40%' }
                }
              >
                <div className={styles.usage_card_title}>Plan usage</div>
                <div className={styles.usage_card_contnent}>
                  <div
                    className={styles.usage_card_contnent_item}
                    style={{ width: '100%' }}
                  >
                    <div className={styles.usage_card_contnent_item_title_dual}>
                      <div>Clients</div>
                      <div>
                        {currentPackage.numberOfUsers} / {usersIncluded}
                      </div>
                    </div>
                    <div className={styles.new_stats_progress_bar_container}>
                      <CustomLinearProgress
                        variant="determinate"
                        value={
                          (currentPackage.numberOfUsers / usersIncluded) * 100
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {nfFlag ? (
              <div
                className={styles.usage_card}
                style={
                  window.innerWidth < 1200
                    ? { width: '100%', marginTop: 15 }
                    : { width: '59%', marginTop: 15 }
                }
              >
                <div
                  className={styles.usage_card_title}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>Neuro usage</div>
                  <div style={{ color: '#6A6A6A', fontSize: 12 }}>
                    {currentPackageStartDate} - {currentPackageEndDate}
                  </div>
                </div>
                <div className={styles.usage_card_contnent}>
                  <div className={styles.usage_card_contnent_item}>
                    <div
                      className={styles.usage_card_contnent_item_title}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Active neuro clients
                      <Tooltip
                        title={
                          <TooltipTitle>
                            A neuro client is considered active only if they
                            train more than 5 minutes in the given billing
                            period
                          </TooltipTitle>
                        }
                        arrow
                      >
                        <InfoIcon
                          style={{
                            fill: '#878787',
                            fontSize: 17,
                            marginLeft: 10,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles.usage_card_contnent_item_data}>
                      {usageData.numberOfActiveNeuroClients}
                    </div>
                  </div>
                  <div className={styles.usage_card_contnent_item}>
                    <div className={styles.usage_card_contnent_item_title}>
                      Total cost
                    </div>
                    <div className={styles.usage_card_contnent_item_data}>
                      ${usageData.neuroTotalCost}
                    </div>
                  </div>

                  <div
                    className={styles.usage_card_contnent_item}
                    style={{
                      color: '#0075F3',
                      justifyContent: 'flex-end',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setCurrentTab('usageDetails');
                    }}
                  >
                    View usage details
                  </div>
                </div>
              </div>
            ) : null}
            {nfFlag ? (
              <div
                className={styles.usage_card}
                style={
                  window.innerWidth < 1200
                    ? { width: '100%', marginTop: 15 }
                    : { width: '40%', marginTop: 15 }
                }
              >
                <div className={styles.usage_card_title}>Upcoming payment</div>
                <div className={styles.usage_card_contnent}>
                  <div className={styles.usage_card_contnent_item}>
                    <div
                      className={styles.usage_card_contnent_item_title}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Payment total
                      <Tooltip
                        title={
                          <TooltipTitle>
                            The upcoming payment total including active neuro
                            client charges.
                          </TooltipTitle>
                        }
                        arrow
                      >
                        <InfoIcon
                          style={{
                            fill: '#878787',
                            fontSize: 17,
                            marginLeft: 10,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles.usage_card_contnent_item_data}>
                      ${usageData.upcomingPayment}
                    </div>
                  </div>
                  <div className={styles.usage_card_contnent_item}>
                    <div className={styles.usage_card_contnent_item_title}>
                      Billing date
                    </div>
                    <div className={styles.usage_card_contnent_item_data}>
                      {dayJS(usageData.billingDate).format(
                        DAY_JS_DATE_FORMATS.namedMonthDayYear
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      }

      return (
        <div className={styles.big_info_card}>
          <div className={styles.title}>
            <h3>{clinicNonfPlan ? 'Usage' : 'Headset Usage'}</h3>
            <p>
              From {currentPackageStartDate} to {currentPackageEndDate}
            </p>
          </div>

          <div className={styles.big_info_card_content}>
            <div className={styles.left_side_content}>
              <div className={styles.info_row}>
                <div className={styles.info_title}>
                  <SpaceBarIcon transform="scale(-1,-1)" />
                  <p>Active headsets:</p>
                </div>
                <p>
                  {
                    // TODO Check if the following is still needed, used to cause an error
                    // When activating a deactivated account
                  }
                  {(usageData.detailedUsage &&
                    Object.values(usageData.detailedUsage).filter(
                      headsetUsage => headsetUsage.isActive
                    ).length) ||
                    0}
                </p>
              </div>

              <div className={styles.info_row}>
                <div className={styles.info_title}>
                  <SpaceBarIcon transform="scale(-1,-1)" />
                  <p>Active headsets included in your plan:</p>
                </div>
                <p>{currentPackage.headsetsIncluded || 0}</p>
              </div>

              <div className={styles.info_row}>
                <div className={styles.info_title}>
                  <SpaceBarIcon transform="scale(-1,-1)" />
                  <p>Extra active headsets:</p>
                </div>
                <p>{usageData.units}</p>
              </div>
            </div>

            <div className={styles.right_side_content}>
              <div className={styles.info_row}>
                <div className={styles.info_title}>
                  <CalendarTodayIcon />
                  <p>{nextChargeText}:</p>
                </div>
                <p>${nextChargeValue}</p>
              </div>

              <div className={styles.info_row}>
                <div className={styles.info_title}>
                  <CreditCardIcon />
                  <p>Extra headsets charge:</p>
                </div>
                <p>${formattedExtraHeadsetsCharge}</p>
              </div>

              <div className={styles.info_row}>
                <div className={styles.info_title}>
                  <CreditCardIcon />
                  <p>Total upcoming payment:</p>
                </div>
                <p>${usageData.balance}</p>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const getIsClinicUpgrading = () => {
      if (!nextPlan) {
        return false;
      }

      const nextPlanObject = packages[nextPlan.packageIndex];
      const isNextPlanAnnually = nextPlan.isAnnual;
      const isCurrentPlanAnnually = currentPackage.isAnnual;
      const bothPlansAreOfTheSameType =
        isNextPlanAnnually === isCurrentPlanAnnually;
      const isClinicUpgrading =
        (!isCurrentPlanAnnually && isNextPlanAnnually) ||
        (bothPlansAreOfTheSameType &&
          nextPlanObject.basePrice > currentPackage.basePrice);

      return isClinicUpgrading;
    };

    const onPlanChosen = async ({
      plan,
      planType,
      planIndex,
      changeType,
      isCurrentPlan = false,
    }) => {
      newAccountPageLogger.log('on_plan_chosen', {
        planType,
        planIndex,
        changeType,
        planName: plan.bundleName,
        isCurrentPlan,
      });
      const isCurrentPlanAnnual = planType === 'annually';

      if (currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE) {
        setPackageDetailsForDeactivation({
          isAnnual: isCurrentPlanAnnual,
          packageIndex: planIndex,
          plan,
        });
        setDisplayTooManyUsersForReactivation(true);
        setDisplayTooManyUsersForReactivationLoading(true);
        // eslint-disable-next-line no-undef
        const res = await fireFunctionPost(
          'nonf_billing-calculateExtraPatients',
          { packageIndex: planIndex }
        );

        if (res.extraPatients) {
          setExtraPatients(res.extraPatients);
          setDisplayTooManyUsersForReactivationLoading(false);
          return;
        }
        setDisplayTooManyUsersForReactivation(false);

        setCreditCardDetailsRenderedStep('loading');
        openCreditCardDetailsDialog(
          CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
        );

        // await fireNodePost('subscribeToPackage', {
        //   packageIndex: planIndex,
        //   groupId,
        //   isAnnual: isCurrentPlanAnnual,
        // });
        setIsBlockedToPlan(true);
        setCreditCardDetailsRenderedStep('cardDetailsInput');
      } else {
        const planName = (() => {
          // eslint-disable-next-line prefer-destructuring
          const generalUpgradeColor = packagePlansColors[planIndex][0];
          const annualPackageUpgradeColor = '#2196f3';
          if (clinicNonfPlan) {
            if (clinicNonfPlan.isDormantPlan) {
              return <span> {plan.bundleName} Plan </span>;
            }
            return (
              <span style={{ color: 'black', fontWeight: 500 }}>
                {plan.bundleName}
                {isCurrentPlan ? (
                  <span>
                    {' '}
                    {planType === 'annually' ? 'Anuual to' : 'Monthly'}
                    {planType === 'annually' ? (
                      <span
                        style={{
                          color: '#4BE0D0',
                          fontWeight: 900,
                        }}
                      >
                        {' '}
                        save 16%
                      </span>
                    ) : null}
                  </span>
                ) : (
                  ' Plan'
                )}
              </span>
            );
          }

          if (planType === 'annually' && isCurrentPlan) {
            return (
              <span style={{ color: annualPackageUpgradeColor }}>
                Annual Plan
              </span>
            );
          }

          if (planType === 'monthly' && isCurrentPlan) {
            return <span style={{ color: downgradeColor }}>Monthly Plan</span>;
          }

          return (
            <span
              style={{
                color:
                  (changeType === CHANGE_TYPES.UPGRADE &&
                    generalUpgradeColor) ||
                  downgradeColor,
              }}
            >
              {`${plan.bundleName} Plan`}
            </span>
          );
        })();

        const currentPackageFeatures =
          packages[
            clinicNonfPlan ? currentPackageIndexNonfPlan : currentPackageIndex
          ].features;
        const planInReviewFeatures = plan.features;
        const lostFeatures = currentPackageFeatures.reduce(
          (acc, currentPackageFeature, index) => {
            const planInReviewFeature = planInReviewFeatures[index];
            if (
              (currentPackageFeature.featureKey !==
                'hardwareDiscountPercentage' &&
                currentPackageFeature.includedMonthly &&
                (!planInReviewFeature ||
                  !planInReviewFeature.includedMonthly)) ||
              (index === 0 && !!clinicNonfPlan) ||
              currentPackageFeature.displayOnChangePlan
            ) {
              acc.push(currentPackageFeature.feature);
            }

            const getDiscountFromHardwareDiscountText = text =>
              parseInt(text, 10) || 0;

            if (
              planInReviewFeature &&
              currentPackageFeature.featureKey ===
                'hardwareDiscountPercentage' &&
              currentPackageFeature.feature !== planInReviewFeature.feature
            ) {
              const hardwareDiscountDifferenceText = `${getDiscountFromHardwareDiscountText(
                currentPackageFeature.feature
              ) -
                getDiscountFromHardwareDiscountText(
                  planInReviewFeature.feature
                )}% Hardware discount`;
              acc.push(hardwareDiscountDifferenceText);
            }
            return acc;
          },
          []
        );

        const addedFeatures = planInReviewFeatures.reduce(
          (acc, planInReviewFeature, index) => {
            const currentPackageFeature = currentPackageFeatures[index];
            if (
              (planInReviewFeature.includedMonthly &&
                (!currentPackageFeature ||
                  !currentPackageFeature.includedMonthly)) ||
              index === 0 || // always show up to x clients feature
              planInReviewFeature.displayOnChangePlan
            ) {
              acc.push(planInReviewFeature.feature);
            }
            return acc;
          },
          []
        );

        const planToReview = {
          name: planName,
          type: planType,
          monthlyPrice:
            planType === 'annually'
              ? plan.annualMonthlyBasePrice
              : plan.basePrice,
          changeType,
          headsetsIncluded: plan.headsetsIncluded,
          index: planIndex,
          lostFeatures,
          addedFeatures,
          isCurrentPlan,
        };

        setPlanInReview(planToReview);
        setIsChangePlanDialogOpen(true);

        if (clinicNonfPlan) {
          try {
            setChangePlanDialogRenderedStep(
              CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING
            );
            // eslint-disable-next-line no-undef
            const { charge } = await fireFunctionPost(
              'billing-upgradeDowngradeCharge',
              {
                packageIndex: planIndex,
              }
            );

            if (charge || charge === 0) {
              setCreditOrChargeExtra(charge);
            }
            setChangePlanDialogRenderedStep(
              changePlanDialogDefaultRenderedStep
            );
          } catch (err) {
            setChangePlanDialogRenderedStep(
              changePlanDialogDefaultRenderedStep
            );
          }
        }
      }
    };

    const onRetryChargeClicked = async () => {
      new MyEvent('on_retry_charge_clicked').log();
      setIsRetryCharge(true);
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('billing-retryCharge');
        if (data.result === true) {
          setIsRetryCharge(false);
          setDisplaySuccessfullRecharge(true);
          try {
            // eslint-disable-next-line no-undef
            const { balance } = await fireFunctionPost(
              'billing-accountBalance'
            );
            setAccountBalance(balance);
            new MyEvent('retry_charge_success').log();
          } catch (err) {
            setAccountBalance(0);
            new MyEvent('retry_charge_failed').log();
          }
        } else {
          setIsRetryCharge(false);
          showNotification(
            'danger',
            'Payment failed! Please update your payment method or try again.'
          );
          new MyEvent('retry_charge_failed').log();
        }
      } catch (e) {
        setIsRetryCharge(false);
        showNotification(
          'danger',
          'Payment failed! Please update your payment method or try again.'
        );
        new MyEvent('retry_charge_failed').log();
      }
    };

    // const addRemoveAddonClicked = () => {
    //   if (currentPackage.deactivationRequested) {
    //     setDisplayShouldCancelDeactivationDialog(true);
    //     new MyEvent('cancel_deactivation_modal').log({ action: 'open' });
    //     return;
    //   }
    //   if (nfFlag && !nfFlagPendingDeactivation) {
    //     setIsDeactivateAddonsDialogOpen(true);
    //     newAccountPageLogger.log('deactivate_nf_add_on_modal', {
    //       action: 'open',
    //     });
    //   } else {
    //     if (nfFlagPendingDeactivation) {
    //       new MyEvent('add_nf_add_on_modal_step_1').log({
    //         action: 'open',
    //       });
    //       setIsAddReactivateAddonsDialogOpen(true);
    //     }
    //     setDisplayHardwareReminder(true);
    //   }
    // };

    const renderPackagePlans = () => {
      const currentPackageBasePrice = currentPackage.basePrice;
      const currentPackageType = currentPackage.isAnnual
        ? 'annually'
        : 'monthly';

      const getMaybeWhyPlanDisabled = (plan, planType) => {
        const isPlanMonthly = planType === 'monthly';
        const isClinicUpgrading = getIsClinicUpgrading();
        const nextPlanObject = nextPlan && packages[nextPlan.packageIndex];

        const billingCycleDateUnit = environmentConfig.isDev ? 'day' : 'month';
        const isPackageExpirationLessThanOneBillingCycle =
          dayJS(currentPackage.annualExpiration).diff(
            dayJS(),
            billingCycleDateUnit,
            true
          ) <= 1;

        if (
          !isPlanMonthly &&
          currentPackageBasePrice > plan.basePrice &&
          !isPackageExpirationLessThanOneBillingCycle
        ) {
          return 'no-downgrade-for-annual-plan';
        }

        if (isPlanMonthly && !isPackageExpirationLessThanOneBillingCycle) {
          return 'no-monthly-plans';
        }

        if (isClinicUpgrading && nextPlanObject.basePrice > plan.basePrice) {
          return 'no-downgrade-plans';
        }

        return null;
      };

      const getActionBasedOnPlan = (plan, planType, planIndex) => {
        const isPlanAnnual = planType === 'annually';
        const nextPlanObject = nextPlan && packages[nextPlan.packageIndex];
        const isNextPlanAnnual = nextPlan && nextPlan.isAnnual;
        const nextPlanName = nextPlanObject && nextPlanObject.bundleName;
        const whyPlanDisabled = getMaybeWhyPlanDisabled(plan, planType);
        const isPlanDisabled = !!whyPlanDisabled;
        const tooltipText =
          (whyPlanDisabled === 'no-downgrade-plans' &&
            'You can only downgrade from your new plan once it goes into effect') ||
          (whyPlanDisabled === 'no-monthly-plans' &&
            'You can switch to a monthly plan only when there are less than 30 days left for your annual billing cycle to end') ||
          (whyPlanDisabled === 'no-downgrade-for-annual-plan' &&
            'Since you’re on annual plan, you can only downgrade 30 days before the plan ends');

        if (currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE) {
          return (
            <Button
              variant="contained"
              classes={{ root: classes.upgradeButtonRoot }}
              onClick={() => onPlanChosen({ plan, planType, planIndex })}
            >
              ACTIVATE
            </Button>
          );
        }

        if (currentPackageIndex === planIndex) {
          if (currentPackageType === planType) {
            return (
              <div>
                <p className={styles.current_plan}>Current plan</p>
                {isPlanAnnual && (
                  <p>
                    Plan ends on:{' '}
                    {dayJS(
                      currentPackage && currentPackage.annualExpiration
                    ).format(DAY_JS_DATE_FORMATS.dayMonthYearSlashes)}
                  </p>
                )}
              </div>
            );
          }

          return currentPackageType === 'monthly' ? (
            <TooltipWrapper
              title={<TooltipTitle>{tooltipText}</TooltipTitle>}
              disableHoverListener={!isPlanDisabled}
              disableFocusListener={!isPlanDisabled}
              disableTouchListener={!isPlanDisabled}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.buttonRoot }}
                  onClick={() =>
                    onPlanChosen({
                      plan,
                      planType,
                      planIndex,
                      changeType: CHANGE_TYPES.UPGRADE,
                      isCurrentPlan: true,
                    })
                  }
                  disabled={
                    (nextPlanName === plan.bundleName &&
                      isNextPlanAnnual === isPlanAnnual) ||
                    isPlanDisabled
                  }
                >
                  SWITCH TO ANNUAL PLAN
                </Button>
              </div>
            </TooltipWrapper>
          ) : (
            <TooltipWrapper
              title={<TooltipTitle>{tooltipText}</TooltipTitle>}
              disableHoverListener={!isPlanDisabled}
              disableFocusListener={!isPlanDisabled}
              disableTouchListener={!isPlanDisabled}
            >
              <div>
                <Button
                  variant="contained"
                  color="default"
                  classes={{ root: classes.buttonRoot }}
                  onClick={() =>
                    onPlanChosen({
                      plan,
                      planType,
                      planIndex,
                      changeType: CHANGE_TYPES.DOWNGRADE,
                      isCurrentPlan: true,
                    })
                  }
                  disabled={
                    (nextPlanName === plan.bundleName &&
                      isNextPlanAnnual === isPlanAnnual) ||
                    isPlanDisabled
                  }
                >
                  SWITCH TO MONTHLY PLAN
                </Button>
              </div>
            </TooltipWrapper>
          );
        }

        if (currentPackageBasePrice < plan.basePrice) {
          return (
            <TooltipWrapper
              title={<TooltipTitle>{tooltipText}</TooltipTitle>}
              disableHoverListener={!isPlanDisabled}
              disableFocusListener={!isPlanDisabled}
              disableTouchListener={!isPlanDisabled}
            >
              <div>
                <Button
                  variant="contained"
                  classes={{ root: classes.upgradeButtonRoot }}
                  onClick={() =>
                    onPlanChosen({
                      plan,
                      planType,
                      planIndex,
                      changeType: CHANGE_TYPES.UPGRADE,
                    })
                  }
                  disabled={
                    (nextPlanName === plan.bundleName &&
                      isNextPlanAnnual === isPlanAnnual) ||
                    isPlanDisabled
                  }
                >
                  upgrade
                </Button>
              </div>
            </TooltipWrapper>
          );
        }

        return (
          <TooltipWrapper
            title={<TooltipTitle>{tooltipText}</TooltipTitle>}
            disableHoverListener={!isPlanDisabled}
            disableFocusListener={!isPlanDisabled}
            disableTouchListener={!isPlanDisabled}
          >
            <div>
              <Button
                variant="text"
                onClick={() =>
                  onPlanChosen({
                    plan,
                    planType,
                    planIndex,
                    changeType: CHANGE_TYPES.DOWNGRADE,
                  })
                }
                disabled={
                  (nextPlanName === plan.bundleName &&
                    isNextPlanAnnual === isPlanAnnual) ||
                  isPlanDisabled
                }
              >
                downgrade
              </Button>
            </div>
          </TooltipWrapper>
        );
      };

      const packagePlans = packages.reduce(
        (acc, cur, index) => [
          ...acc,
          {
            ...cur,
            type: 'monthly',
            action: getActionBasedOnPlan(cur, 'monthly', index),
          },
          {
            ...cur,
            type: 'annually',
            action: getActionBasedOnPlan(cur, 'annually', index),
          },
        ],
        []
      );

      return (
        <PackagePlans
          plans={packagePlans}
          colors={packagePlansColors}
          defaultTab={
            currentPackage.isAnnual
              ? PACKAGE_PLANS_TABS.ANNUALLY_PLANS
              : PACKAGE_PLANS_TABS.MONTHLY_PLANS
          }
        />
      );
    };

    const renderNonfPackagePlans = () => {
      const renderNonfPackagePlansCards = () => {
        if (!packages) return null;
        let shouldShowOnlyCurrentPlan = true;
        Object.keys(packages).forEach(packagePlan => {
          if (
            !(
              packages[packagePlan].hideMonthly &&
              packages[packagePlan].hideAnnual
            )
          ) {
            shouldShowOnlyCurrentPlan = false;
          }
        });
        return Object.keys(packages).map((packagePlan, index) => {
          if (
            !toggleAnual &&
            packages[packagePlan].hideMonthly &&
            !(clinicNonfPlan && clinicNonfPlan.showNextPlan) &&
            !shouldShowOnlyCurrentPlan
          ) {
            return null;
          }
          if (
            shouldRenderDormant &&
            !togglePlanPagination &&
            Object.keys(packages).length - 1 === index
          ) {
            return null;
          }
          if (
            shouldShowOnlyCurrentPlan &&
            !(
              index === +currentPackageIndexNonfPlan &&
              clinicNonfPlan.selectedPlan.isAnnual === toggleAnual
            )
          ) {
            return null;
          }
          return (
            <NonfPackagePlan
              key={packages[packagePlan].bundleName}
              packagePlan={packages[packagePlan]}
              // eslint-disable-next-line no-undef
              color={
                packagePlansColorsNonf[index % packagePlansColorsNonf.length]
              }
              isAnnual={toggleAnual}
              onPackageSelected={() => {
                // if (
                //   currentPackage.packageType !==
                //   PACKAGES_TYPES.BLOCKED_PACKAGE
                // ) {
                //   return;
                // }
                if (currentPackage.deactivationRequested) {
                  setDisplayShouldCancelDeactivationDialog(true);
                  new MyEvent('cancel_deactivation_modal').log({
                    action: 'open',
                  });
                  return;
                }
                if (
                  +currentPackageIndexNonfPlan > index &&
                  currentPackage.numberOfUsers >
                    packages[packagePlan].usersIncluded
                ) {
                  setWantedPlanUsers({
                    usersIncluded: packages[packagePlan].usersIncluded,
                    planName: packages[packagePlan].bundleName,
                  });
                  setDisplayShouldArchiveUsersDialog(true);
                  return;
                }
                if (
                  currentPackage.packageType ===
                    PACKAGES_TYPES.BLOCKED_PACKAGE ||
                  +currentPackageIndexNonfPlan !== index ||
                  (clinicNonfPlan.selectedPlan.isAnnual !== toggleAnual &&
                    !nextPlanNonf)
                ) {
                  // changeType;
                  const changeType =
                    // eslint-disable-next-line no-nested-ternary
                    +currentPackageIndexNonfPlan === index
                      ? CHANGE_TYPES.SWITCH
                      : +currentPackageIndexNonfPlan > index
                      ? CHANGE_TYPES.DOWNGRADE
                      : CHANGE_TYPES.UPGRADE;
                  // if (
                  //   +currentPackageIndexNonfPlan === index ||
                  //   clinicNonfPlan.selectedPlan.isAnnual !== toggleAnual
                  // ) {
                  //   // eslint-disable-next-line no-unused-expressions
                  //   toggleAnual
                  //     ? (changeType = CHANGE_TYPES.UPGRADE)
                  //     : (changeType = CHANGE_TYPES.DOWNGRADE);
                  // } else {
                  //   changeType =
                  //     +currentPackageIndexNonfPlan > index
                  //       ? CHANGE_TYPES.DOWNGRADE
                  //       : CHANGE_TYPES.UPGRADE;
                  // }

                  onPlanChosen({
                    plan: packages[packagePlan],
                    planType: toggleAnual ? 'annually' : 'monthly',
                    planIndex: index,
                    changeType,
                    isCurrentPlan: +currentPackageIndexNonfPlan === index,
                  });
                }
              }}
              packageIndex={index}
              // selectedPackage={selectedPackage}
              isFromClinicAccount
              currentPackageIndexNonfPlan={+currentPackageIndexNonfPlan}
              nextPlanNonf={nextPlanNonf}
              currentPlan={clinicNonfPlan}
              isMostPopular={
                index === Math.floor(Object.keys(packages).length / 2)
              }
              deactivationRequested={currentPackage.deactivationRequested}
              formattedNextBillingCycle={
                currentPackage.isAnnual
                  ? dayJS(currentPackage.annualExpiration).format(
                      DAY_JS_DATE_FORMATS.namedMonthDayYear
                    )
                  : formattedNextBillingCycle
              }
              shouldShowAnnualPrice={disableToggle}
              isCurrentPlanDormant={
                clinicNonfPlan && clinicNonfPlan.isDormantPlan
              }
            />
          );
        });
      };
      const renderDormantPlan = () => {
        if (togglePlanPagination) {
          return null;
        }
        if (!shouldRenderDormant) {
          return null;
        }
        return (
          <NonfPackagePlan
            key="dormant"
            packagePlan={dormantPackage}
            // eslint-disable-next-line no-undef
            color="#707070"
            isAnnual={toggleAnual}
            onPackageSelected={async () => {
              if (clinicNonfPlan && clinicNonfPlan.isDormantPlan) {
                return;
              }
              // eslint-disable-next-line no-undef
              showPageLoader();

              await new MyEvent('cancel_deactivation_modal').log({
                action: 'open',
                isFreePlan: true,
              });

              if (accountBalance < 0) {
                // eslint-disable-next-line no-undef
                hideLoading();
                setIsBlockedToFreeWithDebt(true);
                openCreditCardDetailsDialog(
                  CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
                );
                setCreditCardDetailsRenderedStep('cardDetailsInput');
                return;
              }
              // eslint-disable-next-line no-undef
              const data = await fireFunctionPost(
                'billing-moveBlockedToDormantPlan',
                {
                  planConfigs: { ...dormantPlansInfo },
                }
              );
              if (data.result === true) {
                // eslint-disable-next-line no-undef, no-restricted-globals
                location.reload(true);
              }
              // }
            }}
            // packageIndex={0}

            isFromClinicAccount
            // currentPackageIndexNonfPlan={+currentPackageIndexNonfPlan}
            nextPlanNonf={nextPlanNonf}
            currentPlan={clinicNonfPlan}
            // isMostPopular={
            //   index === Math.floor(Object.keys(packages).length / 2)
            // }
            // deactivationRequested={currentPackage.deactivationRequested}
            formattedNextBillingCycle={
              currentPackage.isAnnual
                ? dayJS(currentPackage.annualExpiration).format(
                    DAY_JS_DATE_FORMATS.namedMonthDayYear
                  )
                : formattedNextBillingCycle
            }
            // shouldShowAnnualPrice={disableToggle}
            isDormant
            isCurrentPlanDormant={
              clinicNonfPlan && clinicNonfPlan.isDormantPlan
            }
            shouldShowAnnualPrice
          />
        );
      };
      return (
        <div style={{ paddingBottom: 40 }}>
          <div
            className={styles.start_trial_switch_container}
            style={disableToggle ? { display: 'none' } : null}
          >
            <div style={!toggleAnual ? { fontWeight: 900 } : null}>
              Monthly Billing
            </div>
            <div className={styles.start_trial_switch}>
              <Switch
                onColor="#C3E0FF"
                offColor="#C3E0FF"
                offHandleColor="#0079FA"
                onHandleColor="#0079FA"
                onChange={() => {
                  setToggleAnual(!toggleAnual);
                  newAccountPageLogger.log('toggle_plans_interval', {
                    isAnnual: !toggleAnual,
                    isNF: canAccessNf,
                  });
                }}
                checked={toggleAnual}
                uncheckedIcon={false}
                checkedIcon={false}
                height={14}
                width={28}
              />
            </div>
            <div style={toggleAnual ? { fontWeight: 900 } : null}>
              Annual Billing{' '}
              <span className={styles.start_trial_switch_discount}>
                (Save 16%)
              </span>
            </div>
          </div>
          <div className={styles.nonf_package_plans_container}>
            {shouldRenderDormant && togglePlanPagination && (
              <div
                style={{ left: -40 }}
                className={styles.package_pagination}
                onClick={() => {
                  setTogglePlanPagination(false);
                }}
              >
                <LeftIcon style={{ fontSize: 40 }} />
              </div>
            )}
            {shouldRenderDormant && renderDormantPlan()}
            {renderNonfPackagePlansCards()}
            {shouldRenderDormant && !togglePlanPagination && (
              <div
                style={{ left: '100%' }}
                className={styles.package_pagination}
                onClick={() => {
                  setTogglePlanPagination(true);
                }}
              >
                <RightIcon style={{ fontSize: 40 }} />
              </div>
            )}
          </div>
          {/* {NFPackages && !nfFlag && (
            <StartTrialBanner
              isNf={canAccessNf}
              isNfAdded={!!nfFlag}
              setIsNfAdded={addRemoveAddonClicked}
              isFromDashboard
              isAnnual={currentPackage.isAnnual}
              nfFlagPendingDeactivation={nfFlagPendingDeactivation}
              NFPackages={NFPackages}
              isInactive={
                currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE
              }
            />
          )} */}
        </div>
      );
    };

    const renderNextPlanInfoBar = () => {
      const nextPlanObject = packages[nextPlan.packageIndex];
      const isNextPlanAnnually = nextPlan.isAnnual;
      const isClinicUpgrading = getIsClinicUpgrading();

      const infoIconColor = isClinicUpgrading ? '#6cce8e' : downgradeColor;
      const planName = (() => {
        if (currentPackage.bundleName === nextPlanObject.bundleName) {
          if (isNextPlanAnnually) {
            return 'Annual Plan';
          }
          return 'Monthly Plan';
        }
        return `${isNextPlanAnnually ? 'Annual' : 'Monthly'} ${
          nextPlanObject.bundleName
        } Plan`;
      })();

      return (
        <Paper>
          <div className={styles.plan_change_info_bar}>
            <InfoIcon style={{ color: infoIconColor }} />
            {isClinicUpgrading ? (
              <span className={styles.info}>
                Thanks for upgrading to {planName}! Your new plan will go into
                effect as of {formattedNextBillingCycle}
              </span>
            ) : (
              <div>
                <span className={styles.info}>
                  You are downgrading to {planName}! Your new plan will go into
                  effect as of {formattedNextBillingCycle}
                </span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCancelDowngrading}
                >
                  Cancel Downgrading
                </Button>
              </div>
            )}
          </div>
        </Paper>
      );
    };

    const renderPreActivationPlanOverview = () => {
      return (
        <div className={styles.big_info_card}>
          <div className={styles.title}>
            <h3>Activate your account</h3>
          </div>

          <p>
            Your account will be automatically activated on{' '}
            {formattedNextBillingCycle}.
          </p>
          <p>Activate your account now to start using Myndlift.</p>

          <Button
            variant="contained"
            color="primary"
            onClick={onActivatingPreActivationAccount}
          >
            ACTIVATE NOW
          </Button>
        </div>
      );
    };

    const renderOverviewTabContent = () => {
      // const shouldRenderPackagePlans =
      //   currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE ||
      //   currentPackage.packageType === PACKAGES_TYPES.BASE_HEADSET_PACKAGE;

      const currentPlanOverview = (() => {
        switch (currentPackage.packageType) {
          case PACKAGES_TYPES.BLOCKED_PACKAGE:
            return renderNoActivePlan();
          case PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE:
            return renderPreActivationPlanOverview();

          default:
            return renderActivePlanDetails();
        }
      })();

      return (
        <div className={styles.overview_tab_content}>
          {nextPlan && groupId && renderNextPlanInfoBar()}
          {clinicNonfPlan &&
          currentPackage.packageType !== PACKAGES_TYPES.BLOCKED_PACKAGE &&
          !currentPackage.deactivationRequested ? (
            <div>{currentPlanOverview}</div>
          ) : (
            <Paper>{currentPlanOverview}</Paper>
          )}

          {currentPackage.packageType === PACKAGES_TYPES.BASE_HEADSET_PACKAGE &&
            !clinicNonfPlan && (
              <Paper>
                <div className={styles.plans_container}>
                  <h3>Plans</h3>

                  {renderPackagePlans()}
                </div>
              </Paper>
            )}
          {(clinicNonfPlan ||
            currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE) && (
            <Paper>
              <div className={styles.plans_container}>
                <h3>Plans</h3>

                {renderNonfPackagePlans()}
              </div>
            </Paper>
          )}
        </div>
      );
    };

    const renderHeadsetsUsageDetailsTabContent = () => {
      const billingCycles = Object.keys(billingCyclesData)
        .map(billingCycleDataKey => {
          const billingCycleStartTimestamp = parseInt(
            billingCycleDataKey.split('-')[1],
            10
          );
          const billingCycleExpirationTimestamp = parseInt(
            billingCycleDataKey.split('-')[2],
            10
          );

          const billingCycleStartDate = dayJS(
            billingCycleStartTimestamp
          ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear);
          const billingCycleEndDate = dayJS(
            billingCycleExpirationTimestamp
          ).format(DAY_JS_DATE_FORMATS.namedMonthDayYear);

          return {
            id: billingCycleDataKey,
            label: `${billingCycleStartDate}-${billingCycleEndDate}`,
          };
        })
        .reverse();

      // Round here so we match the rounding happening at the backend
      // The backend rounds because it needs to do so in order to insert data to bigQuery
      const roundedCurrentPackageStart =
        Math.floor(currentPackage.start / 1000) * 1000;
      const roundedCurrentPackageExpiration =
        Math.floor(currentPackage.expiration / 1000) * 1000;
      const currentBillingCycleId = `bc-${roundedCurrentPackageStart}-${roundedCurrentPackageExpiration}`;

      if (
        !billingCycles.find(
          billingCycle => billingCycle.id === currentBillingCycleId
        )
      ) {
        billingCycles.unshift({
          id: currentBillingCycleId,
          label: `${dayJS(currentPackage.start).format(
            DAY_JS_DATE_FORMATS.namedMonthDayYear
          )} - ${dayJS(currentPackage.expiration).format(
            DAY_JS_DATE_FORMATS.namedMonthDayYear
          )}`,
        });
      }

      const billingCycle =
        selectedBillingCycle ||
        (billingCyclesData[billingCycles[0].id] && {
          ...billingCyclesData[billingCycles[0].id],
          id: billingCycles[0].id,
        }) ||
        {};

      return (
        <div className={styles.headsets_usage_tab_content}>
          <HeadsetsUsageDetails
            selectedBillingCycle={billingCycle}
            billingCycles={billingCycles}
            onBillingCycleChange={onBillingCycleChange}
            clinicNonfPlan={clinicNonfPlan}
          />
        </div>
      );
    };

    const renderNonfBillingHistory = () => {
      if (isLoadingInvoices) {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <CircularProgress />
          </div>
        );
      }
      const tableHeadsBillingHistory = [
        {
          id: 'date',
          node: <span className={styles.head_cell}>Date</span>,
        },
        {
          id: 'item',
          node: <span className={styles.head_cell}>Item</span>,
        },
        {
          id: 'charges',
          node: <span className={styles.head_cell}>Charges</span>,
        },
        {
          id: 'receipt',
          node: <span className={styles.head_cell}>Receipt</span>,
        },
      ];

      const tableRowsBillingHistory =
        nonfBillingInvoices &&
        nonfBillingInvoices.map(nonfBillingInvoice => {
          return {
            cells: [
              {
                id: 'date',
                node: (
                  <span className={styles.cell}>
                    {dayJS(nonfBillingInvoice.date).format(
                      DAY_JS_DATE_FORMATS.namedMonthDayYear
                    )}
                  </span>
                ),
              },
              {
                id: 'item',
                node: (
                  <span className={styles.cell}>{nonfBillingInvoice.item}</span>
                ),
              },
              {
                id: 'charges',
                node: (
                  <span className={styles.cell}>
                    <div>{nonfBillingInvoice.charged}</div>
                    <div style={{ color: '#8fdae1', fontWeight: 900 }}>
                      {nonfBillingInvoice.credited}
                    </div>
                    <div>{nonfBillingInvoice.chargedCredit}</div>
                  </span>
                ),
              },
              {
                id: 'receipt',
                node: (
                  <span
                    className={styles.cell}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      newAccountPageLogger.log('receipt_opened', {
                        timestamp: nonfBillingInvoice.date,
                        amount_paid: nonfBillingInvoice.chargedFloat,
                        amount_credit: nonfBillingInvoice.creditedFloat,
                        amount_paid_credited:
                          nonfBillingInvoice.chargedCreditFloat,
                        invoice_id: nonfBillingInvoice.receipt_url,
                      });
                      window.open(nonfBillingInvoice.receipt_url, '_blank');
                    }}
                  >
                    <ReceiptIcon style={{ width: 20, height: 20 }} />
                  </span>
                ),
              },
            ],
            id: `${nonfBillingInvoice.date} `,
          };
        });

      return nonfBillingInvoices ? (
        <div>
          <div
            className={styles.table_container}
            style={{ backgroundColor: 'white', marginTop: 30 }}
          >
            <CustomTable
              tableHeads={tableHeadsBillingHistory}
              tableRows={tableRowsBillingHistory}
            />
          </div>
        </div>
      ) : null;
    };

    const rendersOrdersTab = () => (
      <OrdersHistory ordersHistory={ordersHistory} />
    );

    const renderTabContent = () => (
      <React.Fragment>
        {currentTab === 'overview' && renderOverviewTabContent()}
        {currentTab === 'usageDetails' &&
          renderHeadsetsUsageDetailsTabContent()}
        <div
          style={{
            display:
              currentTab === 'billingHistory' && clinicNonfPlan === null
                ? 'initial'
                : 'none',
          }}
          className={styles.billing_history_tab_content}
          id="tab-content"
        />
        {currentTab === 'orders' && rendersOrdersTab()}
        {currentTab === 'billingHistory' &&
          clinicNonfPlan &&
          renderNonfBillingHistory()}
      </React.Fragment>
    );

    const renderClinicCardDetails = () => {
      const isABlockedPackage =
        currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE;
      const isAPreActivationPackage =
        currentPackage.packageType === PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE;

      const accountBalanceColor =
        (accountBalance === 0 && 'inherit') ||
        (accountBalance > 0 && '#4BE0D0') ||
        '#F77F7F';
      return (
        <div style={clinicCardDetailsStyle}>
          <Paper>
            <div
              className={
                isMediumScreen
                  ? styles.clinic_card_medium_screen
                  : styles.clinic_card_regular_screen
              }
            >
              <div className={styles.clinic_card_details_top}>
                <h3>{`${userInfo.firstName} ${userInfo.lastName}`}</h3>
                <p className={styles.clinic_email}>{userInfo.email}</p>
                {userInfo.privateDetails.phoneNumber && (
                  <p className={styles.clinic_phone}>
                    {userInfo.privateDetails.phoneNumber}
                  </p>
                )}
                <div className={styles.credit_balance}>
                  <TooltipWrapper
                    title={
                      <TooltipTitle>
                        This will be deduced from your next charge
                      </TooltipTitle>
                    }
                  >
                    <p>
                      <span>
                        Credit balance:{' '}
                        <span
                          style={{
                            color: accountBalanceColor,
                            fontSize: subHeaderFontSize,
                            fontWeight: 500,
                          }}
                        >
                          {accountBalance < 0 ? '-' : ''}$
                          {accountBalance < 0
                            ? accountBalance * -1
                            : accountBalance}
                        </span>
                      </span>
                      <InfoIcon style={{ fontSize: 17 }} />
                    </p>
                  </TooltipWrapper>
                  {// eslint-disable-next-line no-nested-ternary
                  accountBalance < 0 && !userInfo.isCCRequired ? (
                    !isRetryCharge ? (
                      <div
                        className={styles.retry_charge}
                        onClick={onRetryChargeClicked}
                      >
                        <RedoIcon
                          style={{
                            fontSize: 12,
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        />
                        <span style={{ fontSize: 12 }}>Retry Charge</span>
                      </div>
                    ) : (
                      <CircularProgress
                        size={10}
                        style={{ position: 'relative', top: 3, right: -5 }}
                      />
                    )
                  ) : null}
                  {/* {clinicNonfPlan ? null : (
                    <a href="#" onClick={onGetMoreCredit}>
                      Get more credit
                    </a>
                  )} */}
                </div>

                {clinicNonfPlan && clinicNonfPlan.usageCredit ? (
                  <div className={styles.credit_balance}>
                    <TooltipWrapper
                      title={
                        <TooltipTitle>
                          This will be deducted from your next neuro usage
                          charge
                        </TooltipTitle>
                      }
                    >
                      <p>
                        <span>
                          Usage credit balance:{' '}
                          <span
                            style={{
                              color: '#4BE0D0',
                              fontSize: subHeaderFontSize,
                              fontWeight: 500,
                            }}
                          >
                            ${clinicNonfPlan.usageCredit}
                          </span>
                        </span>
                        <InfoIcon style={{ fontSize: 17 }} />
                      </p>
                    </TooltipWrapper>
                    {/* {clinicNonfPlan ? null : (
                    <a href="#" onClick={onGetMoreCredit}>
                      Get more credit
                    </a>
                  )} */}
                  </div>
                ) : null}
              </div>
              <hr />
              <div className={styles.clinic_card_details_bottom}>
                {!isABlockedPackage && (
                  <div onClick={onEditClinicInformation}>
                    <EditIcon style={{ fontSize: 17 }} />
                    <p>{t('edit_clinic_information')}</p>
                  </div>
                )}
                {!isABlockedPackage && (
                  <div onClick={onEditPayment}>
                    <EditIcon style={{ fontSize: 17 }} />
                    <p>
                      {userInfo.isCCRequired
                        ? 'Add payment method'
                        : t('edit_payment_method')}
                    </p>
                  </div>
                )}
                <div onClick={onFAQSClick}>
                  <HelpIcon style={{ fontSize: 17 }} />
                  <p>{t('billing_faqs')}</p>
                </div>
                {!currentPackage.deactivationRequested &&
                  !isABlockedPackage &&
                  !isAPreActivationPackage &&
                  !(clinicNonfPlan && clinicNonfPlan.isDormantPlan) && (
                    <div
                      onClick={() => {
                        newAccountPageLogger.log('on_deactivate_account_click');
                        // eslint-disable-next-line no-unused-expressions
                        onDeactivateAccount();
                      }}
                    >
                      <CloseIcon style={{ fontSize: 17 }} />
                      <p>{t('deactivate_account')}</p>
                    </div>
                  )}
                {currentPackage.deactivationRequested && !isABlockedPackage && (
                  <div onClick={onCancelDeactivation}>
                    <CloseIcon style={{ fontSize: 17 }} />
                    <p>STOP CANCELLATION</p>
                  </div>
                )}
              </div>
            </div>
          </Paper>
        </div>
      );
    };

    if (isTeamMember) {
      return (
        <div className={styles.team_member_container}>
          <Paper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={WarningImg}
                width={75}
                height={75}
                style={{ margin: 20 }}
              />
              <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
                You don’t have permission to access Account and Billing
              </div>
              <div style={{ marginBottom: 20 }}>
                Please contact the creator of the account to apply any changes
                to the subscription plan and billing.
              </div>
            </div>
          </Paper>
        </div>
      );
    }

    return (
      <>
        <ActivateAccountDialog
          isOpen={isActivateAccountDialogOpen}
          onClose={onActivateAccountDialogClose}
          onConfirm={onActivateAccountDialogConfirm}
          renderedStep={activateAccountDialogRenderedStep}
        />
        {isComponentReadyToRender && (
          <>
            <div className={styles.clinic_account_page}>
              {renderClinicCardDetails()}

              <div style={tabsContainerStyle}>
                <Paper>
                  <Tabs
                    value={currentTab}
                    onChange={(event, newCurrentTab) => {
                      newAccountPageLogger.log('on_tab_change', {
                        newCurrentTab,
                      });
                      setCurrentTab(newCurrentTab);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label={t('overview')} value="overview" />
                    {clinicNonfPlan && !nfFlag ? null : (
                      <Tab label={t('usage_details')} value="usageDetails" />
                    )}
                    <Tab
                      label={
                        <div className={styles.tab_With_new}>
                          Orders
                          <span
                            style={{
                              fontSize: 8,
                              color: '#ffffff',
                              marginLeft: 5,
                              backgroundColor: '#4ce0d0',
                              paddingLeft: 5,
                              paddingRight: 5,
                              paddingTop: 2,
                              paddingBottom: 2,
                              borderRadius: 15,
                            }}
                          >
                            NEW
                          </span>
                        </div>
                      }
                      value="orders"
                    />
                    <Tab label={t('billing_history')} value="billingHistory" />
                  </Tabs>
                </Paper>

                {renderTabContent()}
              </div>
            </div>
            <ChangeCreditCardDetailsIfNeededWrapper />

            <>
              <DeactivateAccountDialog
                isOpen={isDeactivateAccountDialogOpen}
                onCancel={onDeactivateAccountCancel}
                onConfirm={onDeactivateAccountConfirm}
                step={deactivateAccountDialogStep}
                onDeactivationCommentChange={onDeactivationCommentChange}
                // onDeactivationCommentBlur={onDeactivationCommentBlur}
                onDeactivationReasonClick={onDeactivationReasonClick}
                deactivationComment={deactivationComment}
                deactivationReasons={deactivationReasons}
                onClose={onDeactivateAccountDialogClose}
                deactivationDate={
                  clinicNonfPlan
                    ? dayJS(calcRenewalDate()).format(
                        DAY_JS_DATE_FORMATS.american
                      )
                    : formattedNextBillingCycle
                }
                deactivationReasonsError={
                  !isSomeDeactivationReasonSelected && deactivationReasonsError
                }
                deactivationCommentError={
                  isDeactivationCommentEmpty && deactivationCommentError
                }
                userPrice={userPrice}
                dormantPackageName={dormantPackage && dormantPackage.bundleName}
              />

              <ChangePlanDialog
                isOpen={isChangePlanDialogOpen}
                onConfirm={onChangePlanDialogConfirm}
                renderedStep={changePlanDialogRenderedStep}
                planName={planInReview.name}
                planMonthlyPrice={
                  currentPackage &&
                  planInReview.type === 'annually' &&
                  currentPackage.isAnnual &&
                  !clinicNonfPlan
                    ? planInReview.monthlyPrice - currentPackage.basePrice
                    : planInReview.monthlyPrice
                }
                planType={planInReview.type}
                changeType={planInReview.changeType}
                headsetsIncluded={planInReview.headsetsIncluded}
                creditCardLashFourDigits={prvCardLastFourDigits}
                onClose={onChangePlanDialogClose}
                onUseDifferentCard={onChangePlanDialogUseDifferentCard}
                nextBillingCycle={formattedNextBillingCycle}
                lostFeatures={planInReview.lostFeatures}
                isNonfPlan={!!clinicNonfPlan}
                addedFeatures={planInReview.addedFeatures}
                isCurrentPlan={planInReview.isCurrentPlan}
                creditOrChargeExtra={creditOrChargeExtra}
                chargeDate={
                  currentPackage.isAnnual
                    ? dayJS(currentPackage.annualExpiration).format(
                        DAY_JS_DATE_FORMATS.namedMonthDayYear
                      )
                    : ''
                }
                isCurrentPlanDurmant={
                  clinicNonfPlan && clinicNonfPlan.isDormantPlan
                }
                showNextPlan={clinicNonfPlan && clinicNonfPlan.showNextPlan}
                isLoadingUpgradeWithCard={isLoadingUpgradeWithCard}
                balance={accountBalance}
              />

              <CreditCardDetailsDialogWithValidation
                prvCardLastFourDigits={
                  creditCardDetailsDialogOpenedBy ===
                  CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
                    ? null
                    : prvCardLastFourDigits
                }
                isOpen={isCreditCardDetailsDialogOpen}
                onCancel={onCreditCardDetailsDialogCancel}
                renderedStep={creditCardDetailsRenderedStep}
                onConfirm={onCreditCardDetailsDialogConfirm}
                ref={creditCardDetailsDialogRef}
                onClose={onCreditCardDetailsDialogClose}
                dialogHeader={creditCardDetailsDialogHeader}
                packageDetailsForDeactivation={packageDetailsForDeactivation}
                balance={accountBalance}
                userOutstandingInvoicesAmount={userOutstandingInvoicesAmount}
              />

              <CancelAccountDeactivationDialog
                isOpen={isCancelDeactivationDialogOpen}
                onClose={onCancelDeactivationDialogClose}
                onConfirm={onCancelDeactivationDialogConfirm}
                step={cancelDeactivationDialogRenderedStep}
              />

              <CancelDowngradingDialog
                isOpen={cancelDowngradingDialogRenderedStep !== null}
                renderedStep={cancelDowngradingDialogRenderedStep}
                onConfirm={onCancelDowngradingDialogConfirm}
                onCancel={onCancelDowngradingDialogCancel}
                currentPlanName={currentPackage && currentPackage.bundleName}
                onClose={onCancelDowngradingDialogCancel}
              />

              <EditClinicInformationDialog
                isOpen={isEditClinicInformationDialogOpen}
                renderedStep={editClinicInformationRenderedStep}
                onSave={onEditClinicInformationDialogSave}
                onCancel={onEditClinicInformationDialogCancel}
                onClose={onEditClinicInformationDialogCancel}
                clinicInformation={{
                  firstName: userInfo.firstName,
                  lastName: userInfo.lastName,
                  clinicName: userInfo.clinicName,
                  phone: userInfo.privateDetails.phoneNumber,
                }}
                onPrimaryBtnClick={onEditClinicInformationDialogPrimaryBtnClick}
              />

              {clinicNonfPlan && NFPackages && (
                <DeactivateAddonsDialog
                  isOpen={isDeactivateAddonsDialogOpen}
                  onClose={() => {
                    setIsDeactivateAddonsDialogOpen(false);
                    setDeactivateAddonsDialogStep(0);
                    setDeactivateAddonsReasons(
                      initialDeactivationAddonsReasons
                    );
                    setDeactivationAddonsReasonsError(null);
                    setDeactivationAddonCommentError(null);
                    newAccountPageLogger.log('deactivate_nf_add_on_modal', {
                      action: 'close',
                    });
                  }}
                  onConfirm={() => {
                    if (deactivateAddonsDialogStep === 0) {
                      setDeactivateAddonsDialogStep(1);
                    } else {
                      onDeactivateAddons();
                    }
                  }}
                  step={deactivateAddonsDialogStep}
                  deactivationReasons={deactivateAddonsReasons}
                  onDeactivationReasonClick={onDeactivationAddonsReasonClick}
                  deactivationReasonsError={deactivationAddonsReasonsError}
                  deactivationComment={deactivationAddonComment}
                  onDeactivationCommentChange={onDeactivationAddonCommentChange}
                  deactivationCommentError={deactivationAddonCommentError}
                  formattedNextBillingCycle={
                    currentPackage.isAnnual
                      ? dayJS(usageData.billingDate).format(
                          DAY_JS_DATE_FORMATS.namedMonthDayYear
                        )
                      : formattedNextBillingCycle
                  }
                  creditOrChargeExtraNf={deactivationNfCredit}
                  isAnnual={
                    nextPlanNonf
                      ? nextPlanNonf.isAnnual
                      : currentPackage.isAnnual
                  }
                  currentPackage={currentPackage}
                />
              )}

              {clinicNonfPlan && NFPackages && (
                <AddReactivateAddonsDialog
                  isOpen={isAddReactivateAddonsDialogOpen}
                  onClose={() => {
                    new MyEvent('add_nf_add_on_modal_step_2').log({
                      action: 'cancel',
                    });
                    setIsAddReactivateAddonsDialogOpen(false);
                    setAddReactivateAddonsSuccess(false);
                  }}
                  onConfirm={addReactivateUser}
                  creditCardLashFourDigits={prvCardLastFourDigits}
                  onUseDifferentCard={onChangePlanDialogUseDifferentCard}
                  addReactivateAddonsSuccess={addReactivateAddonsSuccess}
                  isloadingAddreactivate={isloadingAddreactivate}
                  isReactivation={!!nfFlagPendingDeactivation}
                  formattedNextBillingCycle={
                    currentPackage.isAnnual
                      ? dayJS(currentPackage.annualExpiration).format(
                          DAY_JS_DATE_FORMATS.dayMonthYearSlashes
                        )
                      : formattedNextBillingCycle
                  }
                  creditOrChargeExtraNf={creditOrChargeExtraNf}
                  NFPackages={NFPackages}
                  isAnnual={currentPackage.isAnnual}
                />
              )}

              <Dialog
                open={displayLegacyDiactivationDialog}
                onClose={() => {
                  setDisplayLegacyDiactivationDialog(false);
                }}
                classes={{ paper: styles.dialog_root }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDisplayLegacyDiactivationDialog(false);
                    }}
                  />
                </div>
                <DialogTitle style={{ paddingTop: 0 }}>
                  <div style={{ fontSize: 18, textAlign: 'center' }}>
                    Did you know that Myndlift can be used with all of your
                    clients at no extra charge and without the need for a
                    headset?
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontSize: 16 }}>
                      Read more about how you can make use of this{' '}
                      <a
                        href="https://intercom.help/dashboard-myndlift/en/articles/5860210-4-tips-for-using-myndlift-with-all-of-your-clients"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          newAccountPageLogger.log(
                            'on_deactivate_read_more_clicked'
                          );
                        }}
                      >
                        here.
                      </a>
                    </div>
                    <img
                      src={selfRegistrationDemoSrc}
                      style={{ width: 322, height: 246, margin: 10 }}
                    />
                    <div style={{ fontSize: 16 }}>
                      Need a tour or better pricing? Book a call with our team
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDisplayLegacyDiactivationDialog(false);
                      newAccountPageLogger.log(
                        'on_deactivate_book_call_clicked'
                      );
                      window.open(
                        'https://calendly.com/myndlift-care/dashboard-overview?utm_campaign=deaccamp&utm_source=dashboard',
                        '_blank'
                      );
                    }}
                    style={{ width: '120px', height: '40px', fontSize: '12px' }}
                    color="primary"
                    variant="contained"
                  >
                    book a call
                  </Button>
                  <Button
                    onClick={() => {
                      setDisplayLegacyDiactivationDialog(false);
                      onDeactivateAccount();
                    }}
                    style={{ width: '200px', height: '40px', fontSize: '12px' }}
                  >
                    cancel my subscription
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={displayShouldArchiveUsersDialog}
                onClose={() => {
                  setDisplayShouldArchiveUsersDialog(false);
                }}
                classes={{ paper: styles.dialog_root }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDisplayShouldArchiveUsersDialog(false);
                    }}
                  />
                </div>
                <DialogTitle>
                  <div style={{ fontSize: 18, textAlign: 'center' }}>
                    Archive{' '}
                    {(currentPackage && currentPackage.numberOfUsers) -
                      (wantedPlanUsers && wantedPlanUsers.usersIncluded)}{' '}
                    client
                    {(currentPackage && currentPackage.numberOfUsers) -
                      (wantedPlanUsers && wantedPlanUsers.usersIncluded) >
                    1
                      ? 's'
                      : ''}{' '}
                    to downgrade
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 16,
                    }}
                  >
                    <div>
                      Current number of clients in your dashboard:{' '}
                      {currentPackage && currentPackage.numberOfUsers} clients
                    </div>
                    <div>
                      Maximum number of clients in{' '}
                      {wantedPlanUsers && wantedPlanUsers.planName}:{' '}
                      {wantedPlanUsers && wantedPlanUsers.usersIncluded} clients
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                      To avoid cancelling {nfFlag ? 'programs and' : ''}{' '}
                      assessments for clients you’d like to keep in your new
                      plan, please archive{' '}
                      {(currentPackage && currentPackage.numberOfUsers) -
                        (wantedPlanUsers && wantedPlanUsers.usersIncluded)}{' '}
                      client
                      {(currentPackage && currentPackage.numberOfUsers) -
                        (wantedPlanUsers && wantedPlanUsers.usersIncluded) >
                      1
                        ? 's'
                        : ''}{' '}
                      before downgrading.
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDisplayShouldArchiveUsersDialog(false);
                    }}
                    style={{ width: '120px', height: '40px', fontSize: 14 }}
                  >
                    cancel
                  </Button>
                  <Button
                    onClick={() => {
                      window.open('/customers.html', '_self');
                    }}
                    style={{ width: '200px', height: '40px', fontSize: 14 }}
                    color="primary"
                    variant="contained"
                  >
                    view clients
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={displayShouldCancelDeactivationDialog}
                onClose={() => {
                  setDisplayShouldCancelDeactivationDialog(false);
                  new MyEvent('cancel_deactivation_modal').log({
                    action: 'close',
                  });
                }}
                classes={{ paper: styles.dialog_root }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDisplayShouldCancelDeactivationDialog(false);
                      new MyEvent('cancel_deactivation_modal').log({
                        action: 'close',
                      });
                    }}
                  />
                </div>
                <DialogTitle>
                  <div style={{ fontSize: 18, textAlign: 'center' }}>
                    Would you like to stop the cancellation request?
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 16,
                      textAlign: 'center',
                    }}
                  >
                    You previously requested to cancel this subscription. Simply
                    click “Stop Cancellation” to keep this subscription.
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      onCancelDeactivation();
                      setDisplayShouldCancelDeactivationDialog(false);
                      new MyEvent('cancel_deactivation_modal').log({
                        action: 'cancel_deactivation',
                      });
                    }}
                    style={{ width: '200px', height: '40px', fontSize: 14 }}
                    color="primary"
                    variant="contained"
                  >
                    STOP CANCELLATION
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={displayTooManyUsersForReactivation}
                onClose={() => {
                  setDisplayTooManyUsersForReactivation(false);
                }}
                classes={{ paper: styles.dialog_root }}
              >
                {displayTooManyUsersForReactivationLoading ? (
                  <DialogContent>
                    <CircularProgress />
                  </DialogContent>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: 10,
                        marginTop: 10,
                      }}
                    >
                      <CloseIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setDisplayTooManyUsersForReactivation(false);
                        }}
                      />
                    </div>
                    <DialogTitle>
                      <div style={{ fontSize: 18, textAlign: 'center' }}>
                        Archive clients to activate this plan
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 14,
                          textAlign: 'center',
                        }}
                      >
                        <div>
                          Current number of clients in your dashboard:{' '}
                          {extraPatients +
                            (packageDetailsForDeactivation &&
                              packageDetailsForDeactivation.plan &&
                              packageDetailsForDeactivation.plan
                                .usersIncluded)}{' '}
                          clients{' '}
                        </div>
                        <div>
                          Maximum number of clients in{' '}
                          {packageDetailsForDeactivation &&
                            packageDetailsForDeactivation.plan &&
                            packageDetailsForDeactivation.plan.bundleName}{' '}
                          plan:{' '}
                          {packageDetailsForDeactivation &&
                            packageDetailsForDeactivation.plan &&
                            packageDetailsForDeactivation.plan
                              .usersIncluded}{' '}
                          clients
                        </div>
                        <div style={{ marginTop: 20 }}>
                          To activate this plan, the least active{' '}
                          {extraPatients} will be archived. The archived clients
                          data won’t be deleted and you will be able to
                          unarchive them anytime there is available space for
                          them.
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setDisplayTooManyUsersForReactivation(false);
                        }}
                        style={{ width: '200px', height: '40px', fontSize: 14 }}
                      >
                        cancel
                      </Button>
                      <Button
                        onClick={async () => {
                          setDisplayTooManyUsersForReactivation(false);
                          setCreditCardDetailsRenderedStep('loading');
                          openCreditCardDetailsDialog(
                            CREDIT_CARD_DETAILS_DIALOG_OPENERS.ACTIVATE_ACCOUNT
                          );

                          // await fireNodePost('subscribeToPackage', {
                          //   packageIndex:
                          //     packageDetailsForDeactivation.packageIndex,
                          //   groupId,
                          //   isAnnual: packageDetailsForDeactivation.isAnnual,
                          // });

                          setCreditCardDetailsRenderedStep('cardDetailsInput');
                        }}
                        style={{ width: '200px', height: '40px', fontSize: 14 }}
                        color="primary"
                        variant="contained"
                      >
                        continue
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>

              <Dialog
                open={displayHardwareReminder}
                onClose={() => {
                  setDisplayHardwareReminder(false);
                }}
                classes={{ paper: styles.dialog_root }}
                // maxWidth="md"
                // fullWidth
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDisplayHardwareReminder(false);
                    }}
                  />
                </div>
                <DialogTitle style={{ paddingTop: 0 }}>
                  <div style={{ fontSize: 18, textAlign: 'center' }}>
                    Do you have the required hardware?
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 16,
                      textAlign: 'center',
                    }}
                  >
                    <div>
                      You’ll need hardware to be able to use Neuro’s EEG sensing
                      and neurofeedback training.
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      Please make sure you have the following items, which you
                      can order through Myndlift’s hardware shop:
                    </div>
                    <div className={styles.hardware_popup_items}>
                      <div
                        className={styles.hardware_popup_item_container}
                        style={{ width: 170 }}
                      >
                        <img
                          src={musesSrc}
                          className={styles.hardware_popup_item_img}
                        />
                        <div className={styles.hardware_popup_item_text}>
                          <div style={{ fontWeight: 500 }}>Muse headband</div>
                          <div>Supports Muse 2 and Muse S</div>
                        </div>
                      </div>
                      <div className={styles.hardware_popup_item_container}>
                        <img
                          src={electrodeSrc}
                          className={styles.hardware_popup_item_img}
                        />
                        <div className={styles.hardware_popup_item_text}>
                          <div style={{ fontWeight: 500 }}>Electrode</div>
                          <div>for flexible sensing</div>
                        </div>
                      </div>
                      <div className={styles.hardware_popup_item_container}>
                        <img
                          src={pasteSrc}
                          className={styles.hardware_popup_item_img}
                        />
                        <div className={styles.hardware_popup_item_text}>
                          <div style={{ fontWeight: 500 }}>
                            Conductive paste
                          </div>
                          <div>for better signal</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={async () => {
                      await new MyEvent('add_nf_add_on_modal_step_1').log({
                        action: 'order_hardware',
                      });
                      window.open('/shop/shop-hardware.html', '_self');
                    }}
                    style={{ width: '250px', height: '40px', fontSize: 14 }}
                  >
                    NO, I NEED TO ORDER IT
                  </Button>
                  <Button
                    onClick={() => {
                      new MyEvent('add_nf_add_on_modal_step_1').log({
                        action: 'already_have',
                      });
                      setDisplayHardwareReminder(false);
                      setIsAddReactivateAddonsDialogOpen(true);
                    }}
                    style={{ width: '250px', height: '40px', fontSize: 14 }}
                    color="primary"
                    variant="contained"
                  >
                    YES, I HAVE THE HARDWARE
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          </>
        )}
        <Dialog
          open={displayLegacyDiactivationDialog}
          onClose={() => {
            setDisplayLegacyDiactivationDialog(false);
          }}
          classes={{ paper: styles.dialog_root }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: 10,
              marginTop: 10,
            }}
          >
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                newAccountPageLogger.log('on_deactivate_account_dialog_close', {
                  entered_from: 'noExtraCharge',
                });
                setDisplayLegacyDiactivationDialog(false);
              }}
            />
          </div>
          <DialogTitle style={{ paddingTop: 0 }}>
            <div style={{ fontSize: 18, textAlign: 'center' }}>
              Did you know that Myndlift can be used with all of your clients at
              no extra charge and without the need for a headset?
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: 16 }}>
                Read more about how you can make use of this{' '}
                <a
                  href="https://intercom.help/dashboard-myndlift/en/articles/5860210-4-tips-for-using-myndlift-with-all-of-your-clients"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    newAccountPageLogger.log('on_deactivate_read_more_clicked');
                  }}
                >
                  here.
                </a>
              </div>
              <img
                src={selfRegistrationDemoSrc}
                style={{ width: 322, height: 246, margin: 10 }}
              />
              <div style={{ fontSize: 16 }}>
                Need a tour or better pricing? Book a call with our team
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDisplayLegacyDiactivationDialog(false);
                newAccountPageLogger.log('on_deactivate_book_call_clicked');
                window.open(
                  'https://calendly.com/myndlift-care/dashboard-overview?utm_campaign=deaccamp&utm_source=dashboard',
                  '_blank'
                );
              }}
              style={{ width: '120px', height: '40px', fontSize: '12px' }}
              color="primary"
              variant="contained"
            >
              book a call
            </Button>
            <Button
              onClick={() => {
                newAccountPageLogger.log(
                  'on_deactivate_account_dialog_confirm',
                  { entered_from: 'noExtraCharge' }
                );
                setDisplayLegacyDiactivationDialog(false);
                onDeactivateAccount();
              }}
              style={{ width: '200px', height: '40px', fontSize: '12px' }}
            >
              cancel my subscription
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={displaySuccessfullRecharge}
          onClose={() => {
            setDisplaySuccessfullRecharge(false);
          }}
          classes={{ paper: styles.dialog_root }}
        >
          <div className={styles.info_container}>
            <Avatar
              alt="done"
              src={doneAnimationImageSrc}
              className={styles.avatar}
            />

            <p className={styles.info_text}>
              Credit card ending with {prvCardLastFourDigits} was charged and
              the payment was processed successfully.
            </p>
          </div>
          <DialogActions>
            <Button
              onClick={() => {
                setDisplaySuccessfullRecharge(false);
              }}
              style={{ width: '120px', height: '40px', fontSize: '16px' }}
              color="primary"
              variant="contained"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

ClinicAccountPageBase.propTypes = {
  stripe: PropTypes.object,
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
  onComponentReady: PropTypes.func.isRequired,
};

export const ClinicAccountPage = React.memo(
  injectStripe(withStyles(stylesFn)(ClinicAccountPageBase))
);
ClinicAccountPage.displayName = 'ClinicAccountPage';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button } from '@material-ui/core';
import { UserInfoAvatar } from '../../UserInfoAvatar/UserInfoAvatar';
import { getAge } from '../../../models/person/person';
import styles from './UserInfo.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { useAsyncState } from '../../../Core/hooks/useAsyncState';
import {
  getCustomerTextLevel,
  getCustomerXP,
} from '../../../models/customer/customer';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';

const UserInfoBase = ({
  userInfo,
  numberOfSessions,
  sessionsDuration,
  onNotesBtnClick,
  isNotesBtnDisabled,
  customerId,
  // sessionStatus,
}) => {
  const t = useTranslation();

  const { email, firstName, lastName, privateDetails } = userInfo;
  const { gender, phoneNumber, dateOfBirth } = privateDetails;
  const age = getAge(dateOfBirth);

  const xp = useAsyncState(() => getCustomerXP({ customerId }));
  const level = useAsyncState(() => getCustomerTextLevel({ customerId }));

  const isLoading = xp === undefined || level === undefined;
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <UserInfoAvatar
        name={`${firstName} ${lastName}`}
        gender={gender}
        email={email}
      />
      <div className={styles.user_details} data-test-id="user-details">
        <div>
          <p className={styles.age}>{age || 0} years old</p>

          <TooltipWrapper
            title={<TooltipTitle>{phoneNumber}</TooltipTitle>}
            classes={{ tooltip: styles.tooltip }}
          >
            <p className={styles.phone_number}>{phoneNumber}</p>
          </TooltipWrapper>
          <p>{xp} XP</p>
          <Button
            color="primary"
            onClick={onNotesBtnClick}
            disabled={isNotesBtnDisabled}
          >
            <span>{t('tags')}</span>
          </Button>
        </div>
        <div>
          <p>
            {numberOfSessions}{' '}
            {numberOfSessions === 1
              ? t('session').toLowerCase()
              : t('sessions').toLowerCase()}
          </p>
          <p>
            {// eslint-disable-next-line no-restricted-globals
            isNaN(sessionsDuration) ? 0 : sessionsDuration}{' '}
            {sessionsDuration === 1 ? t('minute') : t('minutes')} trained
          </p>
          <p>Level: {level}</p>
        </div>
      </div>
    </div>
  );
};

UserInfoBase.propTypes = exact({
  userInfo: PropTypes.object.isRequired,
  numberOfSessions: PropTypes.number.isRequired,
  sessionsDuration: PropTypes.number.isRequired,
  onNotesBtnClick: PropTypes.func.isRequired,
  isNotesBtnDisabled: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  // sessionStatus: PropTypes.string,
});

export const UserInfo = React.memo(UserInfoBase);
UserInfo.displayName = 'UserInfo';

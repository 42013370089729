import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import styles from './FrequencyDetailsDialog.scss';
import { paragraphFontSize, defaultLineHeight } from '../../cssInJs/constants';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { capitalizeFirstLetter } from '../../utils/utils';
import {
  FREQUENCY_MIN_RANGE,
  FREQUENCY_MAX_RANGE,
} from '../../models/frequencies/frequencies';

export const FREQUENCY_DETAILS_DIALOG_MODES = {
  ADDING_FREQUENCY: 'addingFrequency',
  EDITING_FREQUENCY: 'editingFrequency',
};

const textFieldWidth = 260;

const FrequencyDetailsDialogBase = ({
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  mode,
  minFrequency,
  maxFrequency,
  frequencyName,
  frequencyDescription,
  minFrequencyError,
  maxFrequencyError,
  frequencyNameError,
  frequencyDescriptionError,
  onMinFrequencyChange,
  onMaxFrequencyChange,
  onFrequencyNameChange,
  onFrequencyDescriptionChange,
  isPrimaryBtnDisabled,
  isFrequencyNameInputDisabled,
  frequencyRangeError,
  isResetDescriptionToDefaultBtnShown,
  isResetDescriptionToDefaultBtnDisabled,
  onResetDescriptionToDefault,
}) => {
  const t = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: styles.dialog_paper }}
    >
      <DialogContent classes={{ root: styles.dialog_content_root }}>
        <div>
          <div className={styles.header}>
            {mode === FREQUENCY_DETAILS_DIALOG_MODES.ADDING_FREQUENCY && (
              <div>
                <h3>{capitalizeFirstLetter(t('add-new-frequency'))}</h3>
                <p>{t('add-new-frequency-description')}</p>
              </div>
            )}
            {mode === FREQUENCY_DETAILS_DIALOG_MODES.EDITING_FREQUENCY && (
              <React.Fragment>
                <div>
                  <h3>{capitalizeFirstLetter(t('edit-frequency'))}</h3>
                  <p>{t('will-be-shown-in-app-and-reports')}</p>
                </div>
                {isResetDescriptionToDefaultBtnShown && (
                  <div>
                    <Button
                      variant="text"
                      color="default"
                      onClick={onResetDescriptionToDefault}
                      tabIndex={0}
                      disabled={isResetDescriptionToDefaultBtnDisabled}
                    >
                      <span className={styles.paragraph_only_font_size}>
                        {t('reset_description_to_default')}
                      </span>
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>

          <div className={styles.inputs_container}>
            <div className={styles.input_row}>
              <div>
                <TextField
                  id="outlined-bare"
                  label="Low (Hz)"
                  value={minFrequency}
                  error={!!(minFrequencyError || frequencyRangeError)}
                  onChange={onMinFrequencyChange}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  inputProps={{
                    'aria-label': 'bare',
                    min: FREQUENCY_MIN_RANGE,
                    max: FREQUENCY_MAX_RANGE,
                    style: {
                      width: textFieldWidth,
                      fontSize: paragraphFontSize,
                    },
                  }}
                  disabled={
                    mode === FREQUENCY_DETAILS_DIALOG_MODES.EDITING_FREQUENCY
                  }
                />
                <p
                  style={{
                    visibility:
                      frequencyRangeError || minFrequencyError
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  {frequencyRangeError || minFrequencyError}
                </p>
              </div>

              <div>
                <TextField
                  id="outlined-bare"
                  label="High (Hz)"
                  type="number"
                  value={maxFrequency}
                  onChange={onMaxFrequencyChange}
                  margin="normal"
                  variant="outlined"
                  error={!!(maxFrequencyError || frequencyRangeError)}
                  inputProps={{
                    'aria-label': 'bare',
                    min: FREQUENCY_MIN_RANGE,
                    max: FREQUENCY_MAX_RANGE,
                    style: {
                      width: textFieldWidth,
                      fontSize: paragraphFontSize,
                    },
                  }}
                  disabled={
                    mode === FREQUENCY_DETAILS_DIALOG_MODES.EDITING_FREQUENCY
                  }
                />
                <p
                  style={{
                    visibility: maxFrequencyError ? 'visible' : 'hidden',
                  }}
                >
                  {maxFrequencyError}
                </p>
              </div>
            </div>

            <div>
              <TextField
                id="outlined-bare"
                label="Frequency Name"
                value={frequencyName}
                onChange={onFrequencyNameChange}
                margin="normal"
                fullWidth
                variant="outlined"
                inputProps={{
                  'aria-label': 'bare',
                  style: { fontSize: paragraphFontSize },
                }}
                disabled={isFrequencyNameInputDisabled}
                error={!!frequencyNameError}
              />
              <p
                style={{
                  visibility: frequencyNameError ? 'visible' : 'hidden',
                }}
              >
                {frequencyNameError}
              </p>
            </div>

            <div>
              <TextField
                id="outlined-bare"
                label="Description"
                value={frequencyDescription}
                onChange={onFrequencyDescriptionChange}
                margin="normal"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                error={!!frequencyDescriptionError}
                inputProps={{
                  'aria-label': 'bare',
                  style: {
                    fontSize: paragraphFontSize,
                    lineHeight: defaultLineHeight,
                  },
                }}
              />
              <p
                style={{
                  visibility: frequencyDescriptionError ? 'visible' : 'hidden',
                }}
              >
                {frequencyDescriptionError}
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default" variant="text">
          <span className={styles.btn}>{t('cancel')}</span>
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
          disabled={isPrimaryBtnDisabled}
        >
          <span className={styles.btn}>{t('save')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FrequencyDetailsDialogBase.defultProps = {
  isFrequencyNameInputDisabled: false,
  isResetDescriptionToDefaultBtnShown: false,
};

FrequencyDetailsDialogBase.propTypes = exact({
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([
    FREQUENCY_DETAILS_DIALOG_MODES.ADDING_FREQUENCY,
    FREQUENCY_DETAILS_DIALOG_MODES.EDITING_FREQUENCY,
  ]),
  minFrequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxFrequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  frequencyName: PropTypes.string,
  frequencyDescription: PropTypes.string,
  onMinFrequencyChange: PropTypes.func.isRequired,
  onMaxFrequencyChange: PropTypes.func.isRequired,
  onFrequencyNameChange: PropTypes.func.isRequired,
  onFrequencyDescriptionChange: PropTypes.func.isRequired,
  isPrimaryBtnDisabled: PropTypes.bool.isRequired,
  frequencyNameError: PropTypes.string,
  frequencyDescriptionError: PropTypes.string,
  minFrequencyError: PropTypes.string,
  maxFrequencyError: PropTypes.string,
  isFrequencyNameInputDisabled: PropTypes.bool,
  frequencyRangeError: PropTypes.string,
  isResetDescriptionToDefaultBtnShown: PropTypes.bool,
  isResetDescriptionToDefaultBtnDisabled: PropTypes.bool,
  onResetDescriptionToDefault: PropTypes.func,
});

export const FrequencyDetailsDialog = React.memo(FrequencyDetailsDialogBase);

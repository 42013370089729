import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CircularProgressbar } from 'react-circular-progressbar';
import HelpIcon from '@material-ui/icons/Help';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Paper,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Switch,
} from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { BarChart, ResponsiveContainer, XAxis, YAxis, Bar } from 'recharts';

import domtoimage from 'dom-to-image';
// import fileDownload from 'js-file-download';
import { jsPDF } from 'jspdf';

import 'react-circular-progressbar/dist/styles.css';
import {
  setFixedDecimalsIfNeeded,
  getCurrentURLParams,
} from '../../utils/utils';
import styles from './AssessmentPage.scss';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { FullBrainMapModal } from './FullBrainMapModal/FullBrainMapModal';
import logoImg from '../../../../img/modern_logo_black.png';
import museOnMaleHeadPNG from '../../../assets/muse-on-male-head.png';
import museOnFemaleHeadPNG from '../../../assets/muse-on-female-head.png';
// import relaxationNeuromarkersPNG from '../../../assets/relaxation_neuromarkers.png';
import rewardsPNG from '../../../assets/CPT-icon.png';
import focusNeuromarkersPNG from '../../../assets/brainmaps-icon.png';
import focusQuestionnairesPNG from '../../../assets/eegdistribution-icon.png';
import ColorBarPNG from '../../../assets/colorbar_scale.png';
import AIPNG from '../../../assets/ai-icon-black.png';
import ThumbsupPNG from '../../../assets/thumbsup-feedback.png';
import ThumbsdownPNG from '../../../assets/thumbsdown-feedback.png';
import ThumbsdownGrayPNG from '../../../assets/gray-thumbs-down.png';
import ThumbsupGrayPNG from '../../../assets/gray-thumbs-up.png';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { useImagesLoadingInfo } from '../../Core/hooks/useImagesLoadingInfo';
import { Loader } from '../../Core/Components/Loader/Loader';
import { AmplitudePerFrequencyGraph } from './AmplitudePerFrequencyGraph/AmplitudePerFrequencyGraph';
// import { PerformanceGraph } from './PerformanceGraph/PerformanceGraph';
import { getSwingleCategoriesScores } from '../../models/swingle-checks/swingle-checks';
import { SwingleChecks } from '../SwingleChecks/SwingleChecks';
import { CPTPerformanceGraph } from '../CPTPerformanceGraph/CPTPerformanceGraph';
import { CustomSelect } from '../../Core/Components/CustomSelect/CustomSelect';
import {
  amplitudesPerFrequencyValueTypes,
  channelsRelevantToAmplitudesDistrubutionGraph,
  channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment,
} from '../../models/assessments/assessments';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import {
  CHANNEL_IDS_TO_NAMES,
  electrodesNameAndOrder,
} from '../../models/channels/channels';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { QuestionnaireResults } from '../QuestionnaireResults/QuestionnaireResults';
// import { getQuestionnairesScorePercentage } from '../../models/symptom-tracker/symptom-tracker';
import { xsMediaMaxWidth } from '../../cssInJs/constants';

export const assessmentPageTabs = {
  main: 'main',
  eeg: 'eeg',
  neuromarkers: 'neuromarkers',
};

const brainMapsToShow = ['Theta', 'Alpha', 'Beta', 'High Beta'];
const brainMapsToShowWithRange = {
  Theta: 'Theta (4 - 7 Hz)',
  Alpha: 'Alpha (8 - 12 Hz)',
  Beta: 'Beta (16 - 20 Hz)',
  'High Beta': 'High Beta (21- 30 Hz)',
};

const assessmentPageLogger = new MyEventWrapper('assessment_page');
// const minimumNumberOfPointsForPerformanceGraph = 3;

const MAX_ALOUD_NOISE = 0.75;

const AssessmentPageBase = ({
  patientEmail,
  patientFullName,
  patientAge,
  assessment,
  swingleChecks,
  swingleResults,
  swingleChecksOrder,
  patientGender,
  frequenciesToImages,
  frequenciesAmplitudesByChannel,
  CPTResults,
  isFirstAssessment,
  shouldShowBrainMaps,
  questionnaires,
  questionnaireResults,
  answerScales,
  brainMapsNoiseData,
  frequenciesToImagesFullNames,
  isNoiseNormalized,
  symptomTrackerQuestionnaires,
  isLoadingBrainmaps,
  shouldTakeHeadsetOnly,
  // shouldShowNoiseGraphForHeadsetAssessment,
  assessmentNumber,
  // isClinicInsightsEnbled,
  isClinicView,
  insightsAllowedVersions,
  logoImageInfo,
  shouldShowEegGraph,
}) => {
  /* eslint-disable dot-notation */

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth - 1}px)`,
  });

  const [brainMapsRootStatefulRef, setBrainMapsRootStatefulRef] = useState({
    current: null,
  });

  const brainMapsImagesLoadingInfo = useImagesLoadingInfo(
    brainMapsRootStatefulRef
  );

  const { assessmentId } = getCurrentURLParams();

  // const shouldRenderNoiseGraph =
  //   !isLoadingBrainmaps &&
  //   Object.keys(brainMapsNoiseData).length &&
  //   (!shouldTakeHeadsetOnly || shouldShowNoiseGraphForHeadsetAssessment);

  const A4_HIEGHT = 295;
  const A4_WIDTH = 210;

  const t = useTranslation();

  const [isInsightDialogOpen, setIsInsightDialogOpen] = useState(false);
  const [insightDialogText, setInsightDialogText] = useState('');
  const [
    isShareWithClientDialogOpen,
    setIsShareWithClientDialogOpen,
  ] = useState(false);
  const [shareWithClientVal, setShareWithClientVal] = useState(
    assessment &&
      assessment.insights &&
      assessment.insights.assessmentDescription &&
      assessment.insights.assessmentDescription.shareWithClient
  );
  const [editInsightsToggle, setEditInsightsToggle] = useState(false);
  const [editInsightsText, setEditInsightsText] = useState(
    (assessment &&
      assessment.insights &&
      assessment.insights.assessmentDescription &&
      assessment.insights.assessmentDescription.editedInsight &&
      assessment.insights.assessmentDescription.editedInsight.replace(
        /\\n/g,
        '\n'
      )) ||
      (assessment &&
        assessment.insights &&
        assessment.insights.assessmentDescription &&
        assessment.insights.assessmentDescription.summary &&
        assessment.insights.assessmentDescription.summary.replace(/\\n/g, '\n'))
  );
  const [showEditedInsight, setShowEditedInsight] = useState(
    assessment &&
      assessment.insights &&
      assessment.insights.assessmentDescription &&
      assessment.insights.assessmentDescription.showEditedInsight
  );
  const [isLoadingInsightText, setIsLoadingInsightText] = useState(false);
  const [isSubmittingInsight, setIsSubmittingInsight] = useState(false);

  const [
    amplitudesPerFrequencyValuesType,
    setAmplitudesPerFrequencyValuesType,
  ] = useState(amplitudesPerFrequencyValueTypes.relative);

  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const [isBrainmapsExpanded, setIsBrainmapsExpanded] = useState(false);

  const [showNoiseGraphForSupport, setShowNoiseGraphForSupport] = useState(
    false
  );
  const [
    noiseGraphForSupportCounter,
    setNoiseGraphForSupportCounter,
  ] = useState(0);

  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false);

  const shouldShowLoader =
    !brainMapsImagesLoadingInfo.areAllImagesLoaded && !isLoadingPDF;

  const onAmplitudesPerFrequencyValuesTypeChange = useCallback(event => {
    assessmentPageLogger.log('on_amplitudes_per_frequency_values_type_change', {
      newType: event.target.value,
      customerId: sessionStorage.customerId,
    });
    setAmplitudesPerFrequencyValuesType(event.target.value);
  }, []);

  const CPTPerformanceGraphData = Object.keys(
    pick(CPTResults, [
      'accuracy',
      'commissionErrors',
      'omissionErrors',
      'reactionTime',
      'responsesSD',
    ])
  ).map(resultKey => ({
    category: resultKey,
    score: CPTResults[resultKey],
    performanceScore: CPTResults.performance[resultKey].zScore,
    performanceRank: CPTResults.performance[resultKey].qRank,
  }));

  const assessmentDate = dayJS(assessment.endTimestamp).format(
    DAY_JS_DATE_FORMATS.american
  );

  const assessmentTime = dayJS(assessment.endTimestamp).format(
    DAY_JS_DATE_FORMATS.ampm
  );

  const amplitudesPerFrequencyValueTypesOptions = [
    {
      text: t('relative_values'),
      value: amplitudesPerFrequencyValueTypes.relative,
    },
    {
      text: t('absolute_values'),
      value: amplitudesPerFrequencyValueTypes.absolute,
    },
  ];

  const eyesClosedGraphData =
    !brainMapsNoiseData ||
    brainMapsNoiseData === {} ||
    !showNoiseGraphForSupport
      ? null
      : Object.keys(brainMapsNoiseData.eyesClosed)
          .sort(
            (a, b) =>
              electrodesNameAndOrder[a].order - electrodesNameAndOrder[b].order
          )
          .map(electrode => {
            return {
              name: electrodesNameAndOrder[electrode].name,
              value: brainMapsNoiseData.eyesClosed[electrode] * 100,
            };
          });

  const eyesOpenGraphData =
    !brainMapsNoiseData ||
    brainMapsNoiseData === {} ||
    !showNoiseGraphForSupport
      ? null
      : Object.keys(brainMapsNoiseData.eyesOpened)
          .sort(
            (a, b) =>
              electrodesNameAndOrder[a].order - electrodesNameAndOrder[b].order
          )
          .map(electrode => {
            return {
              name: electrodesNameAndOrder[electrode].name,
              value: brainMapsNoiseData.eyesOpened[electrode] * 100,
            };
          });

  const defaultQuestionnariesIds = [];
  const regularQuestionnariesIds = [];
  Object.keys(
    (questionnaireResults && questionnaireResults.questions) || {}
  ).forEach(questionId => {
    if (
      questionnaires[questionnaireResults.questions[questionId].questionnaireId]
        .hideResult
    ) {
      defaultQuestionnariesIds.push(
        questionnaireResults.questions[questionId].questionnaireId
      );
    } else {
      regularQuestionnariesIds.push(
        questionnaireResults.questions[questionId].questionnaireId
      );
    }
  });
  const getSwingleData = () => {
    if (swingleResults) {
      const swingleChecksWithIds = mapValues(swingleChecks, (value, key) => ({
        ...value,
        id: key,
      }));

      const relevantSwingleChecksWithIds = pick(
        swingleChecksWithIds,
        Object.keys(swingleResults)
      );

      const swingleChecksGroupedByCategory = groupBy(
        relevantSwingleChecksWithIds,
        check => check.category.en
      );

      const categoryScores = getSwingleCategoriesScores({
        checks: relevantSwingleChecksWithIds,
        results: swingleResults,
      });

      return {
        swingleChecksGroupedByCategory,
        categoryScores,
      };
    }

    return {
      swingleChecksGroupedByCategory: null,
      categoryScores: null,
    };
  };

  const hideShowAll = (element, shouldHide = true) => {
    const elementsToHide = element.getElementsByClassName('hide-on-print');
    for (let i = 0; i < elementsToHide.length; i += 1) {
      elementsToHide[i].style.display = shouldHide ? 'none' : 'block';
    }
    const elementsToHideSpan = element.getElementsByClassName(
      'hide-on-print-span'
    );
    for (let i = 0; i < elementsToHideSpan.length; i += 1) {
      elementsToHideSpan[i].style.display = shouldHide ? 'none' : 'inline';
    }
    const elementsToHideFlex = element.getElementsByClassName(
      'hide-on-print-flex'
    );
    for (let i = 0; i < elementsToHideFlex.length; i += 1) {
      elementsToHideFlex[i].style.display = shouldHide ? 'none' : 'flex';
    }
  };

  const expandAll = async element => {
    const btns = element.getElementsByClassName('expandable-in-report');
    for (let i = 0; i < btns.length; i += 1) {
      if (btns[i].getAttribute('aria-expanded') === 'false') {
        btns[i].click();
      }
    }
    await new Promise(resolve => setTimeout(resolve, 200 * btns.length));
  };

  const onExportPDF = async () => {
    setIsLoadingPDF(true);
    setIsBrainmapsExpanded(true);
    setIsReadMoreClicked(true);
    new MyEvent('assessment_download_pdf_clicked').log({
      patientId: sessionStorage.customerId,
      assessmentId,
      from: 'session_to_session_report_viewer',
      is_text_summary_shared: !!shareWithClientVal,
      is_text_summary_edited: !!showEditedInsight,
    });
    const timeStartedLoading = Date.now();
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', 'a4', true);
    let position = 10;
    const elementToPrint = document.getElementById('to-print');
    hideShowAll(elementToPrint, true);
    await expandAll(elementToPrint);
    const elementsToPrint = elementToPrint.getElementsByClassName(
      'assessment-report-element'
    );
    for (let i = 0; i < elementsToPrint.length; i += 1) {
      if (
        elementsToPrint[i].id === 'swingles' ||
        elementsToPrint[i].id === 'questionnaire-results' ||
        elementsToPrint[i].id === 'brain-maps'
      ) {
        let pageAddedForBrainmaps = false;
        let pageAddedForSwingles = false;
        const largeEl = elementsToPrint[i];
        const smallElements = largeEl.getElementsByClassName('print-item');
        for (let j = 0; j < smallElements.length; j += 1) {
          // eslint-disable-next-line no-await-in-loop
          const blob = await domtoimage.toBlob(smallElements[j]);
          // eslint-disable-next-line no-undef
          const img = new Image();
          const imgData = URL.createObjectURL(blob);
          img.src = imgData;
          // eslint-disable-next-line space-before-function-paren, no-loop-func
          img.onload = function() {
            // eslint-disable-next-line react/no-this-in-sfc
            const imgHeight = (this.height * A4_WIDTH) / this.width;
            if (imgHeight > A4_HIEGHT - position) {
              doc.addPage();
              position = 10;
            }
            if (
              elementsToPrint[i].id === 'brain-maps' &&
              !pageAddedForBrainmaps
            ) {
              doc.addPage();
              position = 10;
              pageAddedForBrainmaps = true;
            }
            if (elementsToPrint[i].id === 'swingles' && !pageAddedForSwingles) {
              doc.addPage();
              position = 10;
              pageAddedForSwingles = true;
            }

            doc.addImage(
              img,
              'PNG',
              1,
              position,
              A4_WIDTH,
              imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight,
              undefined,
              'FAST'
            );
            position += imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight;
          };
        }
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line no-await-in-loop
      const blob = await domtoimage.toBlob(elementsToPrint[i]);
      if (!blob) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line no-undef
      const img = new Image();
      const imgData = URL.createObjectURL(blob);
      img.src = imgData;
      // eslint-disable-next-line space-before-function-paren, no-loop-func
      img.onload = function() {
        const imgHeight =
          // eslint-disable-next-line no-nested-ternary
          elementsToPrint[i].id === 'logo-img'
            ? 11
            : elementsToPrint[i].id === 'customer-card' ||
              elementsToPrint[i].id === 'customer-card-2'
            ? // eslint-disable-next-line react/no-this-in-sfc
              this.height / 4
            : // eslint-disable-next-line react/no-this-in-sfc
              (this.height * A4_WIDTH) / this.width;
        if (imgHeight > A4_HIEGHT - position) {
          doc.addPage();
          position = 10;
        }

        doc.addImage(
          img,
          'PNG',
          elementsToPrint[i].id === 'logo-img' ||
            elementsToPrint[i].id === 'customer-card' ||
            elementsToPrint[i].id === 'customer-card-2' ||
            elementsToPrint[i].id === 'insights_el'
            ? 5
            : 1,
          position,
          // eslint-disable-next-line no-nested-ternary
          elementsToPrint[i].id === 'insights_el'
            ? A4_WIDTH - 10
            : // eslint-disable-next-line no-nested-ternary
            elementsToPrint[i].id === 'logo-img'
            ? 32
            : elementsToPrint[i].id === 'customer-card' ||
              elementsToPrint[i].id === 'customer-card-2'
            ? // eslint-disable-next-line react/no-this-in-sfc
              this.width / 4
            : A4_WIDTH,
          imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight,
          undefined,
          'FAST'
        );
        position += imgHeight + 5 > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight + 5;
      };
    }
    setTimeout(() => {
      hideShowAll(elementToPrint, false);
      doc.save(`Assessment- ${patientFullName}, ${assessmentDate}`);
      new MyEvent('assessment_download_pdf_done').log({
        patientId: sessionStorage.customerId,
        assessmentId,
        from: 'session_to_session_report_viewer',
        delay: Date.now() - timeStartedLoading,
      });
      setIsLoadingPDF(false);
    }, 1000);
  };

  const submitInsightReview = () => {
    setIsSubmittingInsight(true);
    setTimeout(() => {
      setIsSubmittingInsight(false);
    }, 2000);
    const assessmentInsightDatabaseRef = database.ref(
      `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/feedback`
    );

    assessmentInsightDatabaseRef.push({
      time: Date.now(),
      feedback: insightDialogText,
      isPositive: isInsightDialogOpen === 'up',
    });
    assessmentInsightDatabaseRef.off();
    assessmentPageLogger.log('feedback_sumitted', {
      positive: isInsightDialogOpen === 'up',
      text: insightDialogText,
    });
    setInsightDialogText('');
    setIsInsightDialogOpen(false);
  };

  const setShareWithClient = val => {
    setShareWithClientVal(val);
    const assessmentInsightShareDatabaseRef = database.ref(
      `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/shareWithClient`
    );
    assessmentInsightShareDatabaseRef.set(val);
    assessmentPageLogger.log('share_insights_with_ckient_clicked', {
      value: val,
      is_edited: showEditedInsight,
      ai_version: assessment.insights.assessmentDescription.version,
    });
  };

  const onEditInsights = () => {
    setIsReadMoreClicked(true);
    if (
      assessment.insights.assessmentDescription.editedInsight === null ||
      assessment.insights.assessmentDescription.editedInsight === undefined
    ) {
      const assessmentEditedInsightDatabaseRef = database.ref(
        `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/editedInsight`
      );
      assessmentEditedInsightDatabaseRef.set(
        assessment.insights.assessmentDescription.summary
      );
    }

    setEditInsightsToggle(true);
    assessmentPageLogger.log('edit_insigts_btn_clicked', {
      ai_version: assessment.insights.assessmentDescription.version,
    });
  };

  const onSaveInsightsText = () => {
    if (!editInsightsText.trim()) {
      return;
    }
    setIsLoadingInsightText(true);
    setTimeout(() => {
      setIsLoadingInsightText(false);
    }, 3000);
    setEditInsightsToggle(false);
    const showEditedInsightDatabaseRef = database.ref(
      `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/showEditedInsight`
    );
    showEditedInsightDatabaseRef.set(true);
    setShowEditedInsight(true);
    const assessmentEditedInsightDatabaseRef = database.ref(
      `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/editedInsight`
    );
    assessmentEditedInsightDatabaseRef.set(
      editInsightsText.replace(/\n/g, '\\n')
    );

    assessmentPageLogger.log('save_edited_insigts_clicked', {
      len_characters_before:
        assessment.insights.assessmentDescription.summary.length,
      len_characters_after: editInsightsText.replace(/\n/g, '\\n').length,
      ai_version: assessment.insights.assessmentDescription.version,
    });
  };

  const onBackToAIInsights = () => {
    const showEditedInsightDatabaseRef = database.ref(
      `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/showEditedInsight`
    );
    showEditedInsightDatabaseRef.set(false);
    const assessmentEditedInsightDatabaseRef = database.ref(
      `assessments/results/${sessionStorage.customerId}/${assessmentId}/insights/assessmentDescription/editedInsight`
    );
    assessmentEditedInsightDatabaseRef.set(
      assessment.insights.assessmentDescription.summary
    );
    setEditInsightsText(
      assessment.insights.assessmentDescription.summary.replace(/\\n/g, '\n')
    );
    setShowEditedInsight(false);
    setEditInsightsToggle(false);
    assessmentPageLogger.log('generate_with_ai_clicked', {
      ai_version: assessment.insights.assessmentDescription.version,
    });
  };

  const onCancelEditInsightsText = () => {
    setEditInsightsText(
      (assessment &&
        assessment.insights &&
        assessment.insights.assessmentDescription &&
        assessment.insights.assessmentDescription &&
        assessment.insights.assessmentDescription.editedInsight &&
        assessment.insights.assessmentDescription.editedInsight.replace(
          /\\n/g,
          '\n'
        )) ||
        (assessment &&
          assessment.insights &&
          assessment.insights.assessmentDescription &&
          assessment.insights.assessmentDescription.summary &&
          assessment.insights.assessmentDescription.summary.replace(
            /\\n/g,
            '\n'
          ))
    );
    setEditInsightsToggle(false);
    assessmentPageLogger.log('cancel_edit_inisghts_clicked', {
      ai_version: assessment.insights.assessmentDescription.version,
    });
  };

  const displayNoiseGraphForSupport = () => {
    if (noiseGraphForSupportCounter === 0) {
      setTimeout(() => {
        setNoiseGraphForSupportCounter(0);
      }, 10000);
    }
    setNoiseGraphForSupportCounter(noiseGraphForSupportCounter + 1);
    if (noiseGraphForSupportCounter === 4) {
      setShowNoiseGraphForSupport(true);
    }
  };

  // const renderPerformanceGraph = () => {
  //   const performanceGraphData = getPerformanceGraphData();

  //   return (
  //     <div className="assessment-report-element">
  //       <Paper>
  //         <div
  //           className={styles.performance_radar_container}
  //           style={isMatchingXSScreen ? null : { display: 'flex' }}
  //         >
  //           <div className={styles.performance_radar_title_container}>
  //             {/* <img
  //               src={relaxationNeuromarkersPNG}
  //               className={styles.performance_radar_img}
  //             /> */}
  //             <div>
  //               <h3 className={styles.header} style={{ marginBottom: 15 }}>
  //                 {t('performance_radar')}
  //               </h3>
  //               {/* <p>{t('performance_radar_overview')}</p> */}
  //             </div>
  //           </div>
  //           <div style={isMatchingXSScreen ? null : { width: '70%' }}>
  //             <PerformanceGraph
  //               data={performanceGraphData}
  //               data-test-id="component-performance-graph"
  //             />
  //           </div>
  //         </div>
  //       </Paper>
  //     </div>
  //   );
  // };

  const renderBrinmapImageLoader = () => (
    <div>
      <Loader />
    </div>
  );

  const renderAllBrainMaps = () => {
    return (
      <div style={{ marginLeft: 30, marginRight: 30 }}>
        <FullBrainMapModal
          frequenciesToImages={frequenciesToImages}
          isFirstAssessment={isFirstAssessment}
          data-test-id="brain-maps-modal"
          frequenciesToImagesFullNames={frequenciesToImagesFullNames}
          isLoadingPDF={isLoadingPDF}
          // brainMapsNotToShow={brainMapsToShow}
        />
      </div>
    );
  };

  const renderBrainMapsOverview = () => (
    <Paper
      data-test-id="brain-maps-overview"
      className="assessment-report-element"
      id="brain-maps"
    >
      <div
        style={{
          cursor: 'auto',
          padding: 0,
          display: shouldShowLoader ? 'none' : 'block',
        }}
        ref={ref => {
          if (ref !== null && ref !== brainMapsRootStatefulRef.current) {
            setBrainMapsRootStatefulRef({
              current: ref,
            });
          }
        }}
      >
        <div className={styles.brain_maps_container}>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="print-item"
          >
            <img
              src={focusNeuromarkersPNG}
              style={
                isMatchingXSScreen
                  ? { width: 100, height: 100 }
                  : { width: 100, height: 100, marginRight: 20 }
              }
            />
            <div>
              <h3 className={styles.header}>Brain Maps</h3>
              {isMatchingXSScreen ? null : (
                <p style={{ paddingRight: 10 }}>
                  {t('brain-maps-overview-description')}
                </p>
              )}
            </div>
          </div>
          {!isMatchingXSScreen ? null : (
            <p style={{ marginTop: 20, marginBottom: 20 }}>
              {t('brain-maps-overview-description')}
            </p>
          )}

          {isMatchingXSScreen ? (
            <div>
              <div className={styles.brain_map_row}>
                <div className={styles.brain_map_item} />
                <div className={styles.brain_map_item}>Eyes Open</div>
                <div className={styles.brain_map_item}>Eyes Closed</div>
              </div>
              {brainMapsToShow.map(item => {
                return (
                  <div className={styles.brain_map_row}>
                    <div className={styles.brain_map_item}>
                      <div
                        className={styles.brain_map_label}
                        style={{ textAlign: 'center' }}
                      >
                        {item}
                      </div>
                    </div>
                    <div className={styles.brain_map_item}>
                      <img
                        src={frequenciesToImages[item][0]}
                        alt="Brain map"
                        className={styles.brain_map_image}
                      />
                    </div>
                    <div className={styles.brain_map_item}>
                      <img
                        src={frequenciesToImages[item][1]}
                        alt="Brain map"
                        className={styles.brain_map_image}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="hide-on-print-span">
              <div className={styles.brain_map_row}>
                <div className={styles.brain_map_item} style={{ width: 60 }} />
                <div className={styles.brain_map_item} />
                {brainMapsToShow.map(item => (
                  <div className={styles.brain_map_item}>
                    <div className={styles.brain_map_label}>
                      {brainMapsToShowWithRange[item]}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.brain_map_row}>
                <div className={styles.brain_map_item} style={{ width: 60 }} />
                <div className={styles.brain_map_item}>Eyes Open</div>
                {brainMapsToShow.map(item => (
                  <div className={styles.brain_map_item}>
                    <img
                      src={frequenciesToImages[item][0]}
                      alt="Brain map"
                      className={styles.brain_map_image}
                    />
                  </div>
                ))}
              </div>
              <div className={styles.brain_map_row}>
                <div className={styles.brain_map_item} style={{ width: 60 }} />
                <div className={styles.brain_map_item}>Eyes Closed</div>
                {brainMapsToShow.map(item => (
                  <div className={styles.brain_map_item}>
                    <img
                      src={frequenciesToImages[item][1]}
                      alt="Brain map"
                      className={styles.brain_map_image}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
            }}
            className="hide-on-print"
          >
            <img
              src={ColorBarPNG}
              style={
                isMatchingXSScreen
                  ? { width: 265, height: 34 }
                  : { width: 265, height: 34, position: 'relative', left: 50 }
              }
            />
          </div>
        </div>
      </div>
      {isBrainmapsExpanded && renderAllBrainMaps()}
      <div
        className={styles.expand_btn}
        onClick={() => {
          setIsBrainmapsExpanded(!isBrainmapsExpanded);
        }}
        style={isLoadingPDF ? { visibility: 'hidden' } : null}
      >
        {isBrainmapsExpanded ? 'HIDE MAPS' : 'SHOW ALL BRAIN MAPS'}
        {isBrainmapsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </Paper>
  );

  const renderPowerDistributionGraphs = () => (
    <div className="assessment-report-element">
      <Paper>
        <div
          className={styles.power_distribution_container}
          data-test-id="power-distribution-graphs"
        >
          <div
            className={
              styles.amplitudes_per_frequency_value_type_select_container
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={focusQuestionnairesPNG}
                style={{ width: 100, height: 100, marginRight: 10 }}
                onClick={displayNoiseGraphForSupport}
              />
              <div style={{ paddingTop: 10 }}>
                <h3 className={styles.header}>{t('power_distribution')}</h3>
                <p>{t('power_distribution_overview')}</p>
              </div>
            </div>
            {isLoadingPDF ? null : (
              <div className={styles.select_values_types_container}>
                <CustomSelect
                  inputLabelText={t('select_values_types')}
                  selectedOption={amplitudesPerFrequencyValuesType}
                  allOptions={amplitudesPerFrequencyValueTypesOptions}
                  onChange={onAmplitudesPerFrequencyValuesTypeChange}
                  data-test-id="select-values-types"
                />

                <TooltipWrapper
                  title={
                    <TooltipTitle>
                      {t('select_values_types_extra_info')}
                    </TooltipTitle>
                  }
                >
                  <HelpIcon />
                </TooltipWrapper>
              </div>
            )}
          </div>

          <div className={styles.power_distribution_graphs}>
            {intersection(
              Object.keys(frequenciesAmplitudesByChannel),
              shouldTakeHeadsetOnly
                ? channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment
                : channelsRelevantToAmplitudesDistrubutionGraph
            ).map(channel => {
              const amplitudesByChannel =
                frequenciesAmplitudesByChannel[channel];

              const data = Object.entries(amplitudesByChannel).map(
                ([frequency, amplitudesByValueType]) => {
                  const amplitudes =
                    amplitudesByValueType[amplitudesPerFrequencyValuesType];
                  const processedAmplitudes = mapValues(amplitudes, value =>
                    amplitudesPerFrequencyValuesType ===
                    amplitudesPerFrequencyValueTypes.relative
                      ? value * 100
                      : value
                  );

                  return {
                    name: frequency,
                    ...processedAmplitudes,
                  };
                }
              );

              const shouldEnableUnderTask = data.some(dataUnit =>
                has(dataUnit, 'underTask')
              );

              const legendItems = {
                eyesOpened: true,
                eyesClosed: true,
                ...(shouldEnableUnderTask ? { underTask: true } : {}),
              };

              const channelNoise = {
                eyesClosed:
                  brainMapsNoiseData &&
                  brainMapsNoiseData.eyesClosed &&
                  brainMapsNoiseData.eyesClosed[channel] &&
                  brainMapsNoiseData.eyesClosed[channel] > MAX_ALOUD_NOISE,
                eyesOpened:
                  brainMapsNoiseData &&
                  brainMapsNoiseData.eyesOpened &&
                  brainMapsNoiseData.eyesOpened[channel] &&
                  brainMapsNoiseData.eyesOpened[channel] > MAX_ALOUD_NOISE,
              };

              return (
                <Paper
                  key={channel}
                  data-test-id={`power-distribution-graph-container-${channel}`}
                >
                  <div className={styles.channel_info}>
                    <h4 data-test-id="channel-name">
                      {CHANNEL_IDS_TO_NAMES[channel]}
                    </h4>
                    <h5>
                      {amplitudesPerFrequencyValuesType ===
                      amplitudesPerFrequencyValueTypes.relative
                        ? 'Relative Amplitude'
                        : 'Absolute Amplitude'}
                    </h5>
                  </div>

                  <div
                    className={styles.amplitude_per_frequency_graph_container}
                  >
                    <AmplitudePerFrequencyGraph
                      data={data}
                      legendItems={legendItems}
                      isInPreview
                      dataMax={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? 60
                          : 5
                      }
                      dataMin={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? 'dataMin'
                          : 0
                      }
                      yAxisTickFormatter={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? tick => parseInt(tick, 10)
                          : tick => setFixedDecimalsIfNeeded({ number: tick })
                      }
                      unit={
                        amplitudesPerFrequencyValuesType ===
                        amplitudesPerFrequencyValueTypes.relative
                          ? '%'
                          : '(µV)'
                      }
                      data-test-id="power-distribution-graph"
                      channelNoise={channelNoise}
                    />
                  </div>
                </Paper>
              );
            })}
          </div>
        </div>
      </Paper>
    </div>
  );

  const renderCPTPerformanceGraph = () => (
    <div className="assessment-report-element">
      <Paper>
        <div className={styles.performance_radar_container}>
          <div className={styles.progress_graph_container}>
            <img src={rewardsPNG} style={{ width: 100, height: 100 }} />
            <div>
              <h3 className={styles.header}>{t('cpt_performance_table')}</h3>
              {isMatchingXSScreen ? null : (
                <p>{t('cpt_performance_table_overview')}</p>
              )}
            </div>
            <div>
              <CircularProgressbar
                value={CPTResults.overallScore}
                text={`${CPTResults.overallScore}%`}
                className={styles.circular_progress_bar_text}
              />
            </div>
          </div>
          {!isMatchingXSScreen ? null : (
            <p style={{ marginBottom: 20 }}>
              {t('cpt_performance_table_overview')}
            </p>
          )}

          <CPTPerformanceGraph
            data={CPTPerformanceGraphData}
            data-test-id="component-cpt-performance-graph"
          />
        </div>
      </Paper>
    </div>
  );

  const renderSwingleChecks = () => {
    const { swingleChecksGroupedByCategory, categoryScores } = getSwingleData();
    return (
      <div className="assessment-report-element" id="swingles">
        <SwingleChecks
          swingleChecksGroupedByCategory={swingleChecksGroupedByCategory}
          swingleChecksResults={swingleResults}
          swingleCategoryScores={categoryScores}
          data-test-id="component-swingle-checks"
          swingleNoise={assessment.swingleNoise}
          swingleChecksOrder={swingleChecksOrder}
        />
      </div>
    );
  };

  const renderQuestionnaireResults = () => {
    return (
      <div className="assessment-report-element" id="questionnaire-results">
        <QuestionnaireResults
          questionnaires={questionnaires}
          questionnaireResults={questionnaireResults}
          answerScales={answerScales}
          allQuestionnaires={questionnaires}
        />
      </div>
    );
  };

  const renderViewEegButton = () => (
    <Button
      onClick={() => {
        window.open(
          `eeg_analysis.html?customerId=${sessionStorage.customerId}&assessmentId=${assessmentId}`,
          '_blank'
        );
      }}
      color="primary"
      // variant="contained"
      style={{ fontSize: 14, width: 180, height: 40 }}
      disabled={editInsightsToggle}
    >
      <div>VIEW EEG</div>
    </Button>
  );

  const renderPDFButton = () => (
    <Button
      onClick={onExportPDF}
      color="primary"
      variant="contained"
      style={{ fontSize: 14, width: 180, height: 40 }}
      disabled={editInsightsToggle}
    >
      <div>DOWNLOAD AS PDF</div>
    </Button>
  );

  const renderPDFLoader = () => {
    return (
      <div className={styles.pdf_loading}>
        <div className={styles.pdf_loading_content}>
          <CircularProgress />
          <div style={{ fontSize: 16, fontWeight: 900, marginTop: 10 }}>
            Generating PDF
          </div>
          <div style={{ fontSize: 14, fontWeight: 500 }}>
            This will take a few seconds. Please don’t close this tab.
          </div>
        </div>
      </div>
    );
  };

  const renderCustomerCard = () => (
    <Paper className={styles.user_card} data-test-id="customer-card">
      <div className="assessment-report-element" id="customer-card">
        <div className={styles.user_avatar_container}>
          <img
            className={styles.avatar}
            src={
              patientGender === 'male' ? museOnMaleHeadPNG : museOnFemaleHeadPNG
            }
          />
          <div>
            <p className={styles.title_black}>{patientFullName}</p>
            <p>
              {patientGender.charAt(0).toUpperCase() + patientGender.slice(1)},{' '}
              {patientAge} {t('years-old').toLowerCase()}
            </p>
          </div>
        </div>
      </div>
      <div className="assessment-report-element" id="customer-card-2">
        <div className={styles.user_info_container}>
          <p>
            <span>
              <EmailIcon />
            </span>
            {patientEmail}
          </p>
          <p>
            <span>
              <DateRangeIcon />
            </span>
            {assessmentDate}
          </p>
          <p>
            <span>
              <WatchLaterIcon />
            </span>
            {assessmentTime}
          </p>
          <p>
            <span>
              <InsertChartIcon />
            </span>
            Assessment #{assessmentNumber}
          </p>
        </div>
      </div>

      {swingleResults &&
        shouldShowEegGraph &&
        isClinicView &&
        renderViewEegButton()}
      {window.innerWidth > 960 ? renderPDFButton() : null}
    </Paper>
  );

  const renderNoiseGraph = () => (
    <Paper style={{ padding: 20 }} className="assessment-report-element">
      <div>
        <h3 className={styles.header}>EEG Noise</h3>
        <p>
          {isNoiseNormalized
            ? 'The chart estimates the normalized noise amount for each electrode during the assessment, which is derived by deducting the mean value of noise for each channel. Assessment results only include data from clean segments of the recordings, and noise is the total portion filtered out. The lower the score, the better.'
            : 'Estimate of the amount of noise for each electrode during the assessment. The results only include data from clean segments of the recordings and the noise percentage shows the total portion that was filtered out.'}
        </p>
      </div>
      <div className={styles.noise_graph_container}>
        <Paper style={{ padding: 20 }}>
          <p style={{ fontWeight: 'bold' }}>Eyes Open</p>
          {eyesOpenGraphData && (
            <ResponsiveContainer width="100%" height={360}>
              <BarChart data={eyesOpenGraphData}>
                <XAxis
                  dataKey="name"
                  interval={0}
                  fontSize={isMatchingXSScreen ? 9 : 11}
                />
                <YAxis domain={[0, 100]} tickFormatter={tick => `${tick}%`} />
                <Bar dataKey="value" barSize={10} fill="#3c78d7" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Paper>
        <Paper style={{ padding: 20 }}>
          <p style={{ fontWeight: 'bold' }}>Eyes Closed</p>
          {eyesClosedGraphData && (
            <ResponsiveContainer width="100%" height={360}>
              <BarChart data={eyesClosedGraphData}>
                <XAxis
                  dataKey="name"
                  interval={0}
                  fontSize={isMatchingXSScreen ? 9 : 11}
                />
                <YAxis domain={[0, 100]} tickFormatter={tick => `${tick}%`} />
                <Bar dataKey="value" barSize={10} fill="#3c78d7" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Paper>
      </div>
    </Paper>
  );

  const renderDefaultQuestionnaries = () => {
    return (
      <Paper style={{ padding: 20 }} className="assessment-report-element">
        {[...new Set(defaultQuestionnariesIds)].map(
          (defaultQuestionnariesId, index) => {
            const questionsOrder =
              questionnaires[defaultQuestionnariesId].questions;
            return (
              <div>
                <h3
                  className={styles.header}
                  style={
                    index > 0
                      ? { margin: '20px 0', paddingTop: 20, fontSize: 15 }
                      : { margin: '20px 0', fontSize: 15 }
                  }
                >
                  {questionnaires[defaultQuestionnariesId].name}
                </h3>
                <MaterialTable component={Paper}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontSize: 14 }}>Question</TableCell>
                      <TableCell style={{ fontSize: 14 }}>Answer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questionsOrder.map(
                      // eslint-disable-next-line array-callback-return, consistent-return
                      questionData => {
                        let title = '';
                        let rating = '';
                        symptomTrackerQuestionnaires[
                          defaultQuestionnariesId
                        ].questions.forEach(question => {
                          if (question.questionId === questionData.questionId) {
                            title = question.title.en;
                            rating =
                              answerScales[question.answerScaleId].values[
                                questionnaireResults.questions[
                                  questionData.questionId
                                ].rating
                              ];
                          }
                        });
                        return (
                          <TableRow key={questionData.questionId}>
                            <TableCell style={{ fontSize: 12 }}>
                              {title}
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }}>
                              {rating}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </MaterialTable>
              </div>
            );
          }
        )}
      </Paper>
    );
  };

  const renderBrainMapsLoader = () => {
    return (
      <Paper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <CircularProgress />
          <span style={{ marginTop: 10 }}>Generating results</span>
        </div>
      </Paper>
    );
  };

  const renderInsights = () => {
    if (
      insightsAllowedVersions.indexOf(
        assessment.insights.assessmentDescription.version
      ) === -1
    ) {
      return null;
    }

    const renderLoadingBanner = () => {
      return (
        <div className={styles.insights_banner}>
          <span className={styles.insights_banner_inner_animation}>
            Generating text by AI...
          </span>
        </div>
      );
    };

    const renderMessageText = () => {
      if (assessment.insights.assessmentDescription.loading === 'IN_PROGRESS') {
        return renderLoadingBanner();
      }
      // eslint-disable-next-line no-nested-ternary
      const splitted = (isLoadingInsightText
        ? editInsightsText.replace(/\n/g, '\\n')
        : showEditedInsight
        ? assessment.insights.assessmentDescription.editedInsight || ''
        : assessment.insights.assessmentDescription.summary || ''
      ).split('\\n');

      return editInsightsToggle ? (
        <div>
          <TextField
            value={editInsightsText}
            onChange={e => setEditInsightsText(e.target.value)}
            multiline
            classes={{
              root: styles.textfield,
            }}
            InputProps={{
              classes: {
                input: styles.text_input,
              },
            }}
          />
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          {isClinicView ? (
            <span
              style={{
                position: 'sticky',
                left: '100%',
                top: 20,
                cursor: 'pointer',
              }}
              onClick={onEditInsights}
              className="hide-on-print-span"
            >
              <EditIcon style={{ color: '#008eff' }} fontSize="small" />
            </span>
          ) : null}
          {splitted.map(str => (
            <div style={{ marginTop: 5 }}>{str}</div>
          ))}
          {isClinicView && !isReadMoreClicked ? (
            <div className={styles.insights_view_more_container}>
              <div
                className={styles.insights_view_more}
                onClick={() => {
                  setIsReadMoreClicked(true);
                  assessmentPageLogger.log('insigths_read_more_clicked');
                }}
              >
                <span
                  style={{
                    backgroundColor: 'white',
                    padding: 5,
                    color: '#008eff',
                  }}
                >
                  View More
                </span>
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    return (
      <Paper style={{ padding: 20 }} id="insights">
        <div
          id="insights_el"
          className={shareWithClientVal ? 'assessment-report-element' : ''}
        >
          <div className={styles.insights_header}>
            <div className={styles.insights_title_container}>
              <img
                src={AIPNG}
                style={{ height: 20, width: 20, marginRight: 10 }}
              />
              <div className={styles.insights_title}>Summary</div>
              {!showEditedInsight ? (
                <span className="hide-on-print">
                  <div className={styles.insights_title_label}>BETA</div>
                </span>
              ) : null}
              {!showEditedInsight ? (
                <span className="hide-on-print">
                  <div className={styles.insights_title_label_2}>
                    Generated with AI
                  </div>
                </span>
              ) : null}
            </div>
            {isClinicView ? (
              <span className="hide-on-print">
                {// eslint-disable-next-line no-nested-ternary
                editInsightsToggle ? (
                  <div />
                ) : showEditedInsight ? (
                  <div
                    className={styles.ai_toggle_btn}
                    onClick={onBackToAIInsights}
                  >
                    <ReplayIcon style={{ marginRight: 5 }} />
                    Restore AI Suggestion
                  </div>
                ) : (
                  <div className={styles.insights_thumbs_container}>
                    <img
                      src={ThumbsupGrayPNG}
                      style={{
                        margin: 5,
                        cursor: 'pointer',
                        height: 14,
                        width: 14,
                      }}
                      onClick={() => {
                        setIsInsightDialogOpen('up');
                        assessmentPageLogger.log('thumb_feedback_clicked', {
                          positive: true,
                        });
                      }}
                    />
                    <img
                      src={ThumbsdownGrayPNG}
                      style={{
                        margin: 5,
                        cursor: 'pointer',
                        height: 14,
                        width: 14,
                      }}
                      onClick={() => {
                        setIsInsightDialogOpen('down');
                        assessmentPageLogger.log('thumb_feedback_clicked', {
                          positive: false,
                        });
                      }}
                    />
                  </div>
                )}
              </span>
            ) : null}
          </div>
          <div
            className={styles.insights_data}
            style={
              // eslint-disable-next-line no-nested-ternary
              !isClinicView
                ? null
                : // eslint-disable-next-line no-nested-ternary
                !isReadMoreClicked
                ? { overflowY: 'hidden', paddingTop: 0 }
                : // eslint-disable-next-line no-nested-ternary
                isLoadingPDF
                ? { maxHeight: 'unset', minHeight: 'unset', paddingTop: 0 }
                : { paddingTop: 0 }
            }
          >
            {renderMessageText()}
          </div>
          {isClinicView ? (
            <>
              {editInsightsToggle ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  <div
                    onClick={onCancelEditInsightsText}
                    style={{
                      marginRight: 20,
                      marginTop: 10,
                      cursor: 'pointer',
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    onClick={onSaveInsightsText}
                    className={styles.save_edit_insight}
                    style={
                      editInsightsText.trim()
                        ? null
                        : { cursor: 'auto', color: 'unset' }
                    }
                  >
                    <SaveIcon style={{ marginRight: 5 }} />
                    Save changes
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    fontWeight: 900,
                    position: 'relative',
                  }}
                  className="hide-on-print-flex"
                >
                  Share with client
                  <Switch
                    checked={!!shareWithClientVal}
                    onChange={e => {
                      if (
                        assessment.insights.assessmentDescription
                          .shareWithClient === null ||
                        assessment.insights.assessmentDescription
                          .shareWithClient === undefined
                      ) {
                        setIsShareWithClientDialogOpen(true);
                        return;
                      }
                      setShareWithClient(e.target.checked);
                    }}
                    color="primary"
                  />
                </div>
              )}
            </>
          ) : null}
          <Dialog open={isInsightDialogOpen} fullWidth>
            {isSubmittingInsight ? (
              <CircularProgress />
            ) : (
              <div className={styles.insights_dialog}>
                <div className={styles.insights_dialog_header}>
                  <div>
                    <img
                      src={
                        isInsightDialogOpen === 'up'
                          ? ThumbsupPNG
                          : ThumbsdownPNG
                      }
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />
                    Provide additional feedback
                  </div>
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsInsightDialogOpen(false);
                      setInsightDialogText('');
                    }}
                  />
                </div>
                <TextField
                  value={insightDialogText}
                  onChange={e => setInsightDialogText(e.target.value)}
                  placeholder={
                    isInsightDialogOpen === 'up'
                      ? 'What do you like about this AI insight?'
                      : 'What was the issue with the response? How could it be improved?'
                  }
                  multiline
                  rows={5}
                  maxRows={5}
                  style={{ width: '100%', padding: 10, marginBottom: 10 }}
                  InputProps={{
                    classes: {
                      input: styles.resize,
                    },
                  }}
                  variant="outlined"
                />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    onClick={() => {
                      submitInsightReview();
                    }}
                    color="primary"
                    variant="contained"
                    style={{ fontSize: 16 }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Dialog>
          <Dialog open={isShareWithClientDialogOpen} fullWidth>
            <div className={styles.insights_dialog}>
              <div className={styles.insights_dialog_header}>
                <div>
                  Are you sure you want to share the summary with this client?
                </div>
              </div>
              <div style={{ fontSize: 16, marginBottom: 10 }}>
                The client will get notified in-app about the summary and will
                be able to see it in their assessment results.
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={() => {
                    setShareWithClient(false);
                    setIsShareWithClientDialogOpen(false);
                  }}
                  color="primary"
                  style={{ fontSize: 16 }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setShareWithClient(true);
                    setIsShareWithClientDialogOpen(false);
                  }}
                  color="primary"
                  variant="contained"
                  style={{ fontSize: 16 }}
                >
                  Yes, share now
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </Paper>
    );
  };

  return (
    <React.Fragment>
      {isLoadingPDF && renderPDFLoader()}
      <div className={styles.root} id="to-print">
        <img
          src={
            logoImageInfo && logoImageInfo.customLogo && logoImageInfo.logoImage
              ? logoImageInfo.logoImage
              : logoImg
          }
          style={
            logoImageInfo && logoImageInfo.customLogo && logoImageInfo.logoImage
              ? {
                  maxWidth: 100,
                  maxHeight: 100,
                  boxSizing: 'border-box',
                  borderRadius: 10,
                  marginTop:
                    sessionStorage.isMobileAppView === 'true' ? 60 : 10,
                  marginBottom: 20,
                }
              : {
                  width: 128,
                  height: 45,
                  marginBottom: 20,
                  marginTop:
                    sessionStorage.isMobileAppView === 'true' ? 60 : 10,
                  position: 'relative',
                  top: sessionStorage.isMobileAppView === 'true' ? 0 : -10,
                }
          }
          className="assessment-report-element"
          id="logo-img"
        />
        <div className={styles.user_card_and_brain_maps}>
          {isClinicView && renderCustomerCard()}

          {/* {getPerformanceGraphData().length >=
            minimumNumberOfPointsForPerformanceGraph &&
            renderPerformanceGraph()} */}

          {assessment.insights &&
            assessment.insights.assessmentDescription &&
            assessment.insights.assessmentDescription.loading !== 'FAILED' &&
            (isClinicView ||
              assessment.insights.assessmentDescription.shareWithClient) &&
            renderInsights()}
        </div>

        {questionnaireResults && regularQuestionnariesIds.length
          ? renderQuestionnaireResults()
          : null}
        {!isEmpty(CPTResults) && renderCPTPerformanceGraph()}
        {shouldShowLoader && frequenciesToImages && renderBrinmapImageLoader()}
        {frequenciesToImages &&
          shouldShowBrainMaps &&
          renderBrainMapsOverview()}
        {swingleResults && renderSwingleChecks()}
        {isLoadingBrainmaps ? renderBrainMapsLoader() : null}
        {frequenciesAmplitudesByChannel && renderPowerDistributionGraphs()}
        {defaultQuestionnariesIds.length ? renderDefaultQuestionnaries() : null}
        {showNoiseGraphForSupport ? renderNoiseGraph() : null}
      </div>
    </React.Fragment>
  );
};

AssessmentPageBase.propTypes = exact({
  patientEmail: PropTypes.string.isRequired,
  patientFullName: PropTypes.string.isRequired,
  patientAge: PropTypes.number.isRequired,
  assessment: PropTypes.object.isRequired,
  swingleChecks: PropTypes.object.isRequired,
  swingleChecksOrder: PropTypes.array.isRequired,
  swingleResults: PropTypes.object,
  patientGender: PropTypes.oneOf(['male', 'female']).isRequired,
  frequenciesToImages: PropTypes.object,
  frequenciesAmplitudesByChannel: PropTypes.object,
  CPTResults: PropTypes.object,
  isFirstAssessment: PropTypes.bool.isRequired,
  shouldShowBrainMaps: PropTypes.bool.isRequired,
  questionnaires: PropTypes.object.isRequired,
  answerScales: PropTypes.object.isRequired,
  questionnaireResults: PropTypes.object,
  brainMapsNoiseData: PropTypes.object,
  frequenciesToImagesFullNames: PropTypes.array,
  isNoiseNormalized: PropTypes.bool,
  symptomTrackerQuestionnaires: PropTypes.object,
  isLoadingBrainmaps: PropTypes.bool,
  shouldTakeHeadsetOnly: PropTypes.bool,
  // shouldShowNoiseGraphForHeadsetAssessment: PropTypes.bool,
  assessmentNumber: PropTypes.number,
  // isClinicInsightsEnbled: PropTypes.bool,
  isClinicView: PropTypes.bool,
  insightsAllowedVersions: PropTypes.array,
  logoImageInfo: PropTypes.object,
  shouldShowEegGraph: PropTypes.bool,
});

export const AssessmentPage = React.memo(
  withEntryComponent(AssessmentPageBase)
);
AssessmentPage.displayName = 'AssessmentPage';

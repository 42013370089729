import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  Button,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './SettingsSection.scss';

const RemoveMFADialogBase = ({ isOpen, onClose, removeMFA }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog open={isOpen}>
      <div className={styles.dialog_content}>
        <p className={styles.dialog_content_title}>
          Remove 2 factor authentication to this clinic
        </p>
        <div style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ marginRight: 10 }} />
          This action will log you out.
        </div>
      </div>

      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={isLoading}>
          <span className={styles.btn}>Cancel</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsLoading(true);
            removeMFA();
          }}
          disabled={isLoading}
        >
          <span className={styles.btn}>
            {isLoading ? <CircularProgress size={14} /> : 'OK'}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RemoveMFADialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  removeMFA: PropTypes.func.isRequired,
});

export const RemoveMFADialog = React.memo(RemoveMFADialogBase);
RemoveMFADialog.displayName = 'RemoveMFADialog';

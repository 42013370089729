/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
import styles from './CustomerStoriesPagesStyles.scss';

export const brainwaveStorySequences = (
  superstar_brainwave_frequency,
  superstar_brainwave_improvement_percentage
) => {
  // const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  // const backgroundAnimationDelay = 2000;
  // const backgroundAnimationDuration = 4458;

  const seq1WordsLength = 'And your superstar brainwave was'.split(' ').length;
  const seq2WordsLength = 1;
  const seq3WordsLength = `${superstar_brainwave_frequency} brainwave`.split(
    ' '
  ).length;
  const seq4WordsLength = `Improved by ${superstar_brainwave_improvement_percentage}%`.split(
    ' '
  ).length;

  const seq1Variants = {
    initial: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    // exit: {
    //   opacity: 0,
    //   y: -20,
    //   transition: {
    //     duration: (2 * textAnimationDuration) / 1000,
    //     ease: 'easeInOut',
    //   },
    // },
  };

  const seq3Variants = {
    initial: { opacity: 0 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {},
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  // const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  // const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  // const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  // const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  // const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  // const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  // const seq4EnterTime = seq4WordsLength * textAnimationDuration;
  // const seq4ExitTime = seq4WordsLength * textAnimationDuration;
  // const seq4TotalTime = seq4EnterTime + seq4ExitTime;

  const seq3Words = `${superstar_brainwave_frequency} brainwave`
    .split(' ')
    .map((word, idx) => {
      return {
        id: `seq3-word${idx + 1}`,
        text: word,
        enterDelay:
          seq1EnterTime +
          2 * seq2EnterTime +
          textAnimationDuration * (idx + 1) +
          8 * textAnimationDuration,
        // exitDelay: 0,
        variants: seq3Variants,
        style: {
          margin: '7px 5px',
          color: idx < seq3WordsLength - 1 ? '#04a56d' : 'white',
        },
      };
    });

  const seq4Words = `Improved by ${superstar_brainwave_improvement_percentage}%`
    .split(' ')
    .map((word, idx) => {
      return {
        id: `seq4-word${idx + 1}`,
        text: word,
        enterDelay:
          seq1EnterTime +
          2 * seq2EnterTime +
          seq3EnterTime +
          textAnimationDuration * (idx + 1) +
          10 * textAnimationDuration,
        // exitDelay: 0,
        variants: seq3Variants,
        style: {
          margin: '7px 5px',
          color: idx === 2 ? '#04a56d' : 'white',
        },
      };
    });

  const seq1 = {
    id: 'seq1',
    // words: seq1Words,
    sentence: 'And your superstar brainwave was',
    // wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay: 0,
    exitDelay: seq1EnterTime + 8 * seq2EnterTime,
    style: {},
    className: styles.brainwave_seq_1,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        y: -20,
        // display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          //   staggerChildren: 0.1,
          //   staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq2Left = {
    id: 'seq2-left',
    // words: seq2Words,
    sentence: '🥁',
    wordsClassName: styles.brainwave_seq_2_words,
    wordsStyles: {},
    wordSpanAttribute: {
      role: 'img',
      'aria-label': 'drum',
      class: styles.brainwave_seq_2_words,
    },
    wordsVariants: seq1Variants,
    enterDelay: seq1EnterTime,
    exitDelay: seq1EnterTime + 3 * seq2EnterTime,
    style: { display: 'inline-block' },
    className: styles.brainwave_seq_2_left,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'inline-block',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        y: -20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.1,
          // staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [
      {
        keyframes: {
          rotate: [10, -10, 8, -8, 0], // Tilting effect
          scale: [1, 1.1, 1], // Bouncing effect
        },
        delay: 2 * seq2EnterTime + seq1EnterTime,
        transition: {
          duration: textAnimationDuration / 1000, // Adjust based on your requirement
          ease: 'easeInOut',
          //   staggerChildren: 0.2, // Stagger effect for child elements
          //   when: 'beforeChildren', // Ensure animation starts before children animate
          repeat: Infinity, // Loop the animation for a continuous effect
          //   repeatType: 'mirror', // Smooth looping
        },
      },
    ],
    staggerChildren: true,
  };

  const seq2Right = {
    id: 'seq2-right',
    // words: seq2Words,
    sentence: '🥁',
    wordsClassName: styles.brainwave_seq_2_words,
    // wordsStyles: {},
    wordsVariants: seq1Variants,
    wordSpanAttribute: {
      role: 'img',
      'aria-label': 'drum',
      class: styles.brainwave_seq_2_words,
    },
    enterDelay: seq1EnterTime,
    exitDelay: seq1EnterTime + 3 * seq2EnterTime,
    style: { display: 'inline-block', padding: '8vh 0 0 0' },
    className: styles.brainwave_seq_2_right,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'inline-block',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        y: -20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.1,
          // staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [
      {
        keyframes: {
          rotate: [-10, 10, -8, 8, 0], // Tilting effect
          scale: [1, 1.1, 1], // Bouncing effect
        },
        delay: 2 * seq2EnterTime + seq1EnterTime,
        transition: {
          duration: textAnimationDuration / 1000, // Adjust based on your requirement
          ease: 'easeInOut',
          //   staggerChildren: 0.2, // Stagger effect for child elements
          //   when: 'beforeChildren', // Ensure animation starts before children animate
          //   repeat: Infinity, // Loop the animation for a continuous effect
          //   repeatType: 'mirror', // Smooth looping
        },
      },
    ],
    staggerChildren: true,
  };

  const seq3 = {
    id: 'seq3',
    words: seq3Words,
    sentence: `${superstar_brainwave_frequency} brainwave`,
    // wordsClassName: '',
    // wordsStyles: { margin: '7px 5px' },
    // wordsVariants: seq1Variants,
    enterDelay: seq1EnterTime + 2 * seq2EnterTime + 8 * textAnimationDuration,
    // exitDelay:
    //   seq1EnterTime + backgroundAnimationDuration - 8 * textAnimationDuration,
    style: {},
    className: styles.brainwave_seq_3,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          //   duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          //   staggerChildren: 0.2,
          //   when: 'beforeChildren',
        },
      },
      //   exit: {},
    },
    extraAnimations: [],
    // staggerChildren: true,
  };

  const seq4 = {
    id: 'seq4',
    words: seq4Words,
    // sentence: `Improved by ${superstar_brainwave_improvement_percentage}%`,
    // wordsClassName: '',
    // wordsStyles: { margin: '7px 5px' },
    // wordsVariants: seq1Variants,
    enterDelay:
      seq1EnterTime +
      2 * seq2EnterTime +
      seq3EnterTime +
      10 * textAnimationDuration,
    // exitDelay: 0,
    className: styles.brainwave_seq_4,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          //   duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          //   staggerChildren: 0.2,
          //   when: 'beforeChildren',
        },
      },
      //   exit: {},
    },
    extraAnimations: [],
    // staggerChildren: true,
  };

  return [seq1, seq2Left, seq2Right, seq3, seq4];
};

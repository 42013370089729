import React, { useState } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  TextField,
  CircularProgress,
  // Select,
  // MenuItem,
  // DialogContentText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import placeHolder from '../../../../assets/add-photo.png';
import { LagoListItem } from '../LagoListItem/LagoListItem';
import styles from './LagoCollectionDialog.scss';

const LagoCollectionDialogBase = ({
  open,
  onClose,
  collection,
  saveEditCollectionTitleClicked,
  saveEditCollectionDescriptionClicked,
  addArticle,
  onArticleLessonClicked,
  setKeyToDelete,
  setDisplayDeleteDialog,
  addJournal,
  onCollectionJournalClicked,
  setArticleToDelete,
  setJournalToDelete,
  // collectionId,
  setCollectionData,
  saveCollectionData,
  // setDisplayUploadedImages
}) => {
  const [toggleEditTitle, setToggleEditTitle] = useState(false);
  const [toggleEditDescription, setToggleEditDescription] = useState(false);
  const [titleInput, setTitleInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [toggleAddArticleDialog, setToggleAddArticleDialog] = useState(false);
  const [newArticeTitle, setNewArticeTitle] = useState('');
  const [toggleAddJournalDialog, setToggleAddJournalDialog] = useState(false);
  const [newJournalTitle, setNewJournalTitle] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const IMAGE_PATH_PREFIX = 'lago/educational/defaults/images/';

  // const toggleIsEnabled = (value, id, isFromArticle) => {
  //   if (isFromArticle) {
  //     database
  //       .ref(
  //         `lago/educational/defaults/resources/drafts/${collectionId}/articles/${id}/isEnabled`
  //       )
  //       .set(value);
  //   } else {
  //     database
  //       .ref(
  //         `lago/educational/defaults/resources/drafts/${collectionId}/journals/${id}/isEnabled`
  //       )
  //       .set(value);
  //   }
  // };

  const onImageFileChange = async event => {
    setIsLoadingImage(true);
    const path =
      IMAGE_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const [file] = event.target.files;
    // setImageFile(file);
    const storage = window.firebase.storage();
    const imageRef = storage.ref(path);
    try {
      await imageRef.put(file);
      imageRef
        .getDownloadURL()
        .then(res => {
          const copy = JSON.parse(JSON.stringify(collection));
          copy.img = res;
          setCollectionData(copy);
          setIsLoadingImage(false);
        })
        .catch(() => {
          setIsLoadingImage(false);
        });
    } catch (err) {
      // TODO
    }
  };

  const removeImage = () => {
    const copy = JSON.parse(JSON.stringify(collection));
    copy.img = '';
    setCollectionData(copy);
    const el = document.getElementById('image-input-collection');
    el.value = '';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={styles.lago_dialog_container}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <div>
          {toggleEditTitle ? null : (
            <span style={{ fontSize: '20px', textAlign: 'center' }}>
              {collection &&
                collection.resourceName &&
                collection.resourceName.en}
            </span>
          )}
          {toggleEditTitle ? (
            <div>
              <TextField
                value={titleInput}
                onChange={e => {
                  setTitleInput(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  setToggleEditTitle(false);
                }}
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  saveEditCollectionTitleClicked(titleInput);
                  setToggleEditTitle(false);
                }}
              >
                <SaveIcon />
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                setTitleInput(collection.resourceName.en);
                setToggleEditTitle(true);
              }}
            >
              <EditIcon />
            </Button>
          )}
        </div>
        <div>
          {toggleEditDescription ? null : (
            <span style={{ fontSize: '20px', textAlign: 'center' }}>
              {collection &&
                collection.instructionText &&
                collection.instructionText.en}
            </span>
          )}
          {toggleEditDescription ? (
            <div>
              <TextField
                value={descriptionInput}
                onChange={e => {
                  setDescriptionInput(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  setToggleEditDescription(false);
                }}
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  saveEditCollectionDescriptionClicked(descriptionInput);
                  setToggleEditDescription(false);
                }}
              >
                <SaveIcon />
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                setDescriptionInput(
                  collection &&
                    collection.instructionText &&
                    collection.instructionText.en
                    ? collection.instructionText.en
                    : ''
                );
                setToggleEditDescription(true);
              }}
            >
              <EditIcon />
            </Button>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.journal_dialog_container}>
          <div style={{ width: '70%' }}>
            <div>
              <div>Articles</div>
              {collection &&
                collection.articles &&
                Object.keys(collection.articles).map(articleId => {
                  return (
                    // <div> • {collection.articles[articleId].title.en}</div>
                    <div key={articleId} style={{ display: 'flex' }}>
                      <div style={{ width: 300 }}>
                        <LagoListItem
                          id={articleId}
                          element={collection.articles[articleId]}
                          onItemClicked={() =>
                            onArticleLessonClicked(articleId)
                          }
                          onDeleteItemClicked={e => {
                            e.stopPropagation();
                            setKeyToDelete(articleId);
                            setArticleToDelete(true);
                            setDisplayDeleteDialog(true);
                          }}
                          // currentStage={currentStage}
                          // onEditItemClicked={onEditItemClicked}
                          // isFinalizedState={isFinalizedState}
                        />
                      </div>
                      {/* <div>
                        <Switch
                          checked={collection.articles[articleId].isEnabled}
                          onChange={e => {
                            toggleIsEnabled(e.target.checked, articleId, true);
                          }}
                          color="primary"
                        />
                      </div> */}
                    </div>
                  );
                })}
              <Button
                onClick={() => {
                  setToggleAddArticleDialog(true);
                }}
              >
                Add article
              </Button>
            </div>
            <div>
              <div>Exercises</div>
              {collection &&
                collection.journals &&
                Object.keys(collection.journals).map(journalId => {
                  return (
                    // <div> • {collection.articles[articleId].title.en}</div>
                    <div key={journalId} style={{ display: 'flex' }}>
                      <div style={{ width: 300 }}>
                        <LagoListItem
                          id={journalId}
                          element={collection.journals[journalId]}
                          onItemClicked={() =>
                            onCollectionJournalClicked(journalId)
                          }
                          onDeleteItemClicked={e => {
                            e.stopPropagation();
                            setKeyToDelete(journalId);
                            setJournalToDelete(true);
                            setDisplayDeleteDialog(true);
                          }}
                          // currentStage={currentStage}
                          // onEditItemClicked={onEditItemClicked}
                          // isFinalizedState={isFinalizedState}
                        />
                      </div>
                      {/* <div>
                        <Switch
                          checked={collection.journals[journalId].isEnabled}
                          onChange={e => {
                            toggleIsEnabled(e.target.checked, journalId, false);
                          }}
                          color="primary"
                        />
                      </div> */}
                    </div>
                  );
                })}
              <Button
                onClick={() => {
                  setToggleAddJournalDialog(true);
                }}
              >
                Add work
              </Button>
            </div>
            <Dialog open={toggleAddArticleDialog}>
              <DialogTitle>Add article</DialogTitle>
              <DialogContent>
                <TextField
                  value={newArticeTitle}
                  onChange={e => {
                    setNewArticeTitle(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    addArticle(newArticeTitle);
                    setToggleAddArticleDialog(false);
                    setNewArticeTitle('');
                  }}
                >
                  save
                </Button>
                <Button
                  onClick={() => {
                    setNewArticeTitle('');
                    setToggleAddArticleDialog(false);
                    setNewArticeTitle('');
                  }}
                >
                  cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={toggleAddJournalDialog}>
              <DialogTitle>Add work</DialogTitle>
              <DialogContent>
                <TextField
                  value={newJournalTitle}
                  onChange={e => {
                    setNewJournalTitle(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    addJournal(newJournalTitle);
                    setToggleAddJournalDialog(false);
                  }}
                >
                  save
                </Button>
                <Button
                  onClick={() => {
                    setToggleAddJournalDialog(false);
                    setNewJournalTitle('');
                  }}
                >
                  cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className={styles.upload_image_container}>
            {isLoadingImage ? (
              <CircularProgress />
            ) : (
              <img
                className={styles.lago_control_img}
                src={
                  collection && collection.img ? collection.img : placeHolder
                }
                alt="placeHolder"
              />
            )}
            <div className={styles.upload_image_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-input-collection').click();
                }}
                color="primary"
                variant="contained"
                style={{ maxWidth: '120px' }}
              >
                upload image
              </Button>
              {/* <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px', maxWidth: '120px' }}
              >
                select uploaded
              </Button> */}
              {collection && collection.img ? (
                <Button
                  onClick={() => {
                    removeImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                  // disabled={isLoadingVideo}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          size="large"
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          // disabled={isLoadingVideo}
        >
          close
        </Button>
        <Button
          onClick={saveCollectionData}
          color="primary"
          size="large"
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          variant="contained"
          // disabled={isLoadingVideo}
        >
          save
        </Button>
      </DialogActions>
      <input
        accept="image/png, image/gif, image/jpeg"
        hidden
        id="image-input-collection"
        type="file"
        onChange={onImageFileChange}
        style={{ display: 'none' }}
      />
    </Dialog>
  );
};

LagoCollectionDialogBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  collection: PropTypes.object,
  saveEditCollectionTitleClicked: PropTypes.func,
  saveEditCollectionDescriptionClicked: PropTypes.func,
  addArticle: PropTypes.func,
  onArticleLessonClicked: PropTypes.func,
  setKeyToDelete: PropTypes.func,
  setDisplayDeleteDialog: PropTypes.func,
  addJournal: PropTypes.func,
  onCollectionJournalClicked: PropTypes.func,
  setArticleToDelete: PropTypes.func,
  setJournalToDelete: PropTypes.func,
  setCollectionData: PropTypes.func,
  saveCollectionData: PropTypes.func,
  // setDisplayUploadedImages: PropTypes.func,
  // collectionId: PropTypes.string,
});

export const LagoCollectionDialog = React.memo(LagoCollectionDialogBase);
LagoCollectionDialog.displayName = 'LagoCollectionDialog';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Stepper_step{font-size:inherit;font-weight:inherit;color:inherit;position:absolute;top:42%;left:46%;transform:translate(-50%, -50%);white-space:nowrap;display:flex;align-items:center}.Stepper_step_root{display:flex;flex-direction:column}@media only screen and (max-width: 768px){.Stepper_step_root:not(.Stepper_active){display:none}}.Stepper_step_root .Stepper_step.Stepper_active{color:white;top:40%}@media only screen and (max-width: 768px){.Stepper_step_root{flex-direction:row;align-items:center}}.Stepper_step_container{position:relative}.Stepper_step_container.Stepper_active{top:-6px}@media only screen and (max-width: 768px){.Stepper_step_container.Stepper_active{top:6px}}.Stepper_steps{display:flex}.Stepper_step_text_mobile{display:none;font-size:12px;font-weight:300;line-height:1.33;text-align:left;color:#9b9b9b;text-align:center;margin:0px;margin-left:10px}@media only screen and (max-width: 768px){.Stepper_step_text_mobile{display:block}}\n", ""]);

// Exports
exports.locals = {
	"step": "Stepper_step",
	"step_root": "Stepper_step_root",
	"active": "Stepper_active",
	"step_container": "Stepper_step_container",
	"steps": "Stepper_steps",
	"step_text_mobile": "Stepper_step_text_mobile"
};
import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CircularProgress } from '@material-ui/core';
import styles from './Loader.scss';

export const Loader = ({ size, padding, color }) => (
  <div className={styles.progress_container} style={{ padding }}>
    <CircularProgress size={size} color={color} />
  </div>
);

Loader.defaultProps = {
  padding: '90px 90px',
};

Loader.propTypes = exact({
  size: PropTypes.number,
  padding: PropTypes.string,
  color: PropTypes.string,
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Switch from '@material-ui/core/Switch';
import styles from './ScheduleAssessmentsCard.scss';

const userPageLogger = new MyEventWrapper('user_page');

const ScheduleAssessmentsCardBase = ({
  title,
  cardText,
  imgSrc,
  checked,
  onChange,
  buttomLeft,
  mins,
  disabled,
  disabledImgSrc,
  isLast,
  footerStyleFlag,
  customerId,
  imgLabel,
  smallCard,
  warning,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false); // for animation purpose

  return (
    <div
      className={classNames(
        styles.card_container,
        isLast ? styles.last : null,
        isExpanded ? styles.expanded : null,
        smallCard ? styles.small_card_container : styles.large_card_container
      )}
      style={imgLabel ? { position: 'relative' } : null}
    >
      <img
        className={smallCard ? styles.card_img_small : styles.card_img_large}
        src={checked && !disabled ? imgSrc : disabledImgSrc}
      />
      {imgLabel ? (
        <div
          className={styles.image_label}
          style={smallCard ? { left: 180 } : { left: 115 }}
        >
          {imgLabel}
        </div>
      ) : null}
      <div className={styles.card_data_container}>
        <div className={styles.card_upper_part}>
          <div
            onClick={() => {
              if (!isExpanded) {
                setTimeout(() => setIsExpanded2(!isExpanded2), 250);
              } else {
                setIsExpanded2(!isExpanded2);
              }
              if (isExpanded) {
                setTimeout(() => setIsExpanded(!isExpanded), 100);
              } else {
                setIsExpanded(!isExpanded);
              }
              userPageLogger.log('on_expandable_click', {
                customerId,
                isExpanded: !isExpanded,
                title,
              });
            }}
            className={styles.card_title_container}
          >
            <div className={styles.card_title}>{title}</div>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          <div>{`(${mins} mins)`}</div>
        </div>
        {isExpanded2 ? (
          <div className={classNames(styles.card_text, styles.opacity_one)}>
            {cardText()}
          </div>
        ) : (
          <div className={classNames(styles.card_text, styles.opacity_zero)}>
            {cardText()}
          </div>
        )}
        <div
          className={styles.card_footer_container}
          style={footerStyleFlag ? { alignItems: 'unset' } : null}
        >
          <div className={styles.card_switch}>
            <Switch
              onChange={onChange}
              checked={checked}
              color="primary"
              disabled={disabled ? !!disabled : false}
            />
          </div>
          {buttomLeft ? buttomLeft() : <div />}
        </div>
      </div>
      {warning ? <div className={styles.card_warning}>{warning}</div> : null}
    </div>
  );
};

ScheduleAssessmentsCardBase.propTypes = exact({
  title: PropTypes.string.isRequired,
  cardText: PropTypes.func.isRequired,
  imgSrc: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  buttomLeft: PropTypes.func,
  mins: PropTypes.number.isRequired,
  disabled: PropTypes.string,
  disabledImgSrc: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  footerStyleFlag: PropTypes.bool,
  customerId: PropTypes.string,
  imgLabel: PropTypes.string,
  smallCard: PropTypes.bool,
  warning: PropTypes.string,
});

export const ScheduleAssessmentsCard = React.memo(ScheduleAssessmentsCardBase);
ScheduleAssessmentsCard.displayName = 'ScheduleAssessmentsCard';

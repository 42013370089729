import React, { useEffect, useState } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Button,
  Input,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './LagoEditScreenDialog.scss';
import placeHolder from '../../../../assets/add-photo.png';
import { LagoTags } from '../LagoTags/LagoTags';

const LagoEditScreenDialogBase = ({
  isOpen,
  onClose,
  onCloseSave,
  saveHtml,
  saveNewHtml,
  editorState,
  setEditorState,
  saveEditTitle,
  titleValue,
  setTitleValue,
  isFromEdit,
  isScreenEditor,
  onVideoFileChange,
  videoFile,
  saveVideo,
  saveNewVideo,
  selectedTab,
  setSelectedTab,
  // uploadCallback,
  // htmlBtnValue,
  // setHtmlBtnValue,
  htmlImgPath,
  onScreenImageFileChange,
  quizType,
  setQuizType,
  quizTextQuestion,
  setQuizTextQuestion,
  quizCoises,
  setQuizCoises,
  quizNewCoise,
  setQuizNewCoise,
  quizAfterCorrect,
  setQuizAfterCorrect,
  quizBtnValue,
  setQuizBtnValue,
  saveQuiz,
  saveNewQuiz,
  // estimatedMins,
  // setEstimatedMins,
  currentStage,
  setDisplayUploadedImages,
  quizOtherStr,
  setQuizOtherStr,
  isMultipleChoise,
  setIsMultipleChoise,
  tags,
  onAddTag,
  onSelectTag,
  selectedTagId,
  // quizBtnAfterAnswerValue,
  // setQuizBtnAfterAnswerValue,
  // isFromGoalEdit,
  quizMaxNum,
  setQuizMaxNum,
  firstRatingText,
  setFirstRatingText,
  lastRatingText,
  setLastRatingText,
  isOtherCorrect,
  setIsOtherCorrect,
  // isFromJournalEdit,
  videoPrev,
  editVideoTitle,
  isLoadingImage,
  // isFromCheckinEdit,
  setAddedItemTitle,
  removeScreenImage,
  journeyId,
  fileSizeError,
  isFinalizedState,
  journeyDashboardDescription,
  setJourneyDashboardDescription,
}) => {
  const LENGTH_EDITOR_STATE_VALIDATION = 8;

  const HTML_SELCTED_TAB = 0;
  const VIDEO_SELCTED_TAB = 1;
  const QUIZ_SELCTED_TAB = 2;

  const MIN_QUIZ_CHOISES = 2;
  const MAX_QUIZ_CHOISES = 30;
  const QUIZ_CHOISE_MAX_LENGTH = 77;
  const OTHER_ANSWER_MAX_LENGTH = 36;
  const CONTINUE_BTN_MAX_LENGTH = 38;

  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [missingFields, setMissingFields] = useState([]);
  const [isTitleEdited, setIsTitleEdited] = useState(false);
  const [editChoiseToggle, setEditChoiseToggle] = useState(false);
  const [editChoiseText, setEditChoiseText] = useState('');
  const [choiseToEdit, setChoiseToEdit] = useState('');

  useEffect(() => {
    if (isFromEdit) {
      setIsTitleEdited(true);
    } else {
      setIsTitleEdited(false);
    }
  }, [isFromEdit]);

  const onSave = () => {
    if (isScreenEditor) {
      if (isFromEdit) {
        if (selectedTab === HTML_SELCTED_TAB) {
          saveHtml(editorState);
        }
        if (selectedTab === VIDEO_SELCTED_TAB) {
          if (!videoFile && videoPrev) {
            editVideoTitle();
          }
          saveVideo();
        }
        if (selectedTab === QUIZ_SELCTED_TAB) {
          saveQuiz();
        }
      } else {
        if (selectedTab === HTML_SELCTED_TAB) {
          saveNewHtml();
        }
        if (selectedTab === VIDEO_SELCTED_TAB) {
          saveNewVideo();
        }
        if (selectedTab === QUIZ_SELCTED_TAB) {
          saveNewQuiz();
        }
      }
    } else {
      saveEditTitle(isFromEdit);
    }
    onCloseSave();
    setIsTitleEdited(false);
  };

  useEffect(() => {
    if (tags) {
      const suggestions = [];
      Object.keys(tags).forEach(tagKey => {
        if (
          tags[tagKey].journeyId === journeyId ||
          (currentStage === 0 && !tags[tagKey].journeyId)
        ) {
          suggestions.push({
            text: tags[tagKey].tagName.en,
            value: tags[tagKey].tagName.en,
            // eslint-disable-next-line no-script-url
            url: `javascript:function a() {const tagId=${tagKey} return false; }`,
          });
        }
      });
      setMentionSuggestions(suggestions);
    }
  }, [tags, journeyId]);

  const resetEditChoise = () => {
    setEditChoiseToggle(false);
    setEditChoiseText('');
    setChoiseToEdit('');
  };

  const onEditChoiseSaveClicked = () => {
    const copy = [...quizCoises];
    copy.forEach(choise => {
      if (choiseToEdit === choise.key) {
        // eslint-disable-next-line no-param-reassign
        choise.text = editChoiseText;
      }
    });
    setQuizCoises(copy);
    resetEditChoise();
  };

  const addNewChoiseClicked = () => {
    const newChoise = {
      text: quizNewCoise,
      isCorrect: false,
      key: Math.random()
        .toString(36)
        .substring(7),
    };
    const copy = [...quizCoises];
    copy.push(newChoise);
    setQuizCoises(copy);
    setQuizNewCoise('');
  };

  const deleteChoiseClicked = choise => {
    const copy = [...quizCoises];
    const index = quizCoises.findIndex(el => el.key === choise.key);
    copy.splice(index, 1);
    setQuizCoises(copy);
  };

  const setIsCorrect = (choise, isCorrect) => {
    const choiseIndex = quizCoises.findIndex(el => el.key === choise.key);
    const copy = [...quizCoises];
    copy[choiseIndex].isCorrect = isCorrect;
    setQuizCoises(copy);
  };

  const clearEditors = quizTypeSelected => {
    if (quizTypeSelected === 'data') {
      setQuizAfterCorrect(EditorState.createEmpty());
    }
  };

  const titleAutoFill = (editorNewState, isSecondary = false) => {
    const autofillArray = editorNewState
      .getCurrentContent()
      .getPlainText()
      .split(/\s+/);
    if (autofillArray.length > 1 && !isTitleEdited && !isSecondary) {
      // eslint-disable-next-line prefer-template
      setAddedItemTitle(autofillArray[0] + ' ' + autofillArray[1]);
    }
  };

  // for fixing a bug related to mentions
  const setEditorReference = ref => {
    if (
      ref &&
      ref.style &&
      ref.style.userSelect &&
      ref.style.userSelect === 'text'
    ) {
      // eslint-disable-next-line no-param-reassign
      ref.style.userSelect = 'none';
    }
  };

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  useEffect(() => {
    if (isScreenEditor) {
      const isOtherCorrectToNumber = isOtherCorrect ? 1 : 0;
      const numOfCorrectAnswers = quizCoises.filter(choise => choise.isCorrect)
        .length;
      const missingFieldsTexts = [];
      if (!titleValue) {
        missingFieldsTexts.push('Missing title');
      }
      if (selectedTab === HTML_SELCTED_TAB) {
        if (
          draftToHtml(convertToRaw(editorState.getCurrentContent())).length <=
          LENGTH_EDITOR_STATE_VALIDATION
        ) {
          setIsSaveDisabled(true);
          if (
            draftToHtml(convertToRaw(editorState.getCurrentContent())).length <=
            LENGTH_EDITOR_STATE_VALIDATION
          ) {
            missingFieldsTexts.push('Missing content');
          }
        } else {
          setIsSaveDisabled(false);
        }
      } else if (selectedTab === VIDEO_SELCTED_TAB) {
        if (!videoFile && !videoPrev) {
          setIsSaveDisabled(true);
          missingFieldsTexts.push('Missing video');
        } else {
          setIsSaveDisabled(false);
        }
      } else if (selectedTab === QUIZ_SELCTED_TAB) {
        if (
          (((quizType === 'exercise' && isMultipleChoise) ||
            (quizType === 'data' && isMultipleChoise) ||
            quizType === 'journal') &&
            !quizBtnValue) ||
          (quizType === 'journal' &&
            quizBtnValue.length > CONTINUE_BTN_MAX_LENGTH) ||
          ((quizType === 'exercise' || quizType === 'data') &&
            isMultipleChoise &&
            quizBtnValue.length > CONTINUE_BTN_MAX_LENGTH) ||
          ((quizType === 'exercise' || quizType === 'data') &&
            quizCoises.length < MIN_QUIZ_CHOISES) ||
          !quizType ||
          ((quizType === 'exercise' ||
            quizType === 'data' ||
            quizType === 'journal' ||
            quizType === 'emoji' ||
            quizType === 'numbered') &&
            draftToHtml(convertToRaw(quizTextQuestion.getCurrentContent()))
              .length <= LENGTH_EDITOR_STATE_VALIDATION) ||
          (quizType === 'exercise' &&
            draftToHtml(convertToRaw(quizAfterCorrect.getCurrentContent()))
              .length <= LENGTH_EDITOR_STATE_VALIDATION) ||
          (quizType === 'exercise' &&
            !quizCoises.find(choise => choise.isCorrect) &&
            !isOtherCorrect &&
            isMultipleChoise) ||
          ((quizType === 'data' ||
            quizType === 'journal' ||
            quizType === 'emoji' ||
            quizType === 'numbered') &&
            !selectedTagId) ||
          (quizType === 'exercise' &&
            !isMultipleChoise &&
            numOfCorrectAnswers + isOtherCorrectToNumber !== 1) ||
          ((quizType === 'exercise' || quizType === 'data') &&
            quizOtherStr.length > OTHER_ANSWER_MAX_LENGTH)
        ) {
          setIsSaveDisabled(true);
          if (
            ((quizType === 'exercise' && isMultipleChoise) ||
              (quizType === 'data' && isMultipleChoise) ||
              quizType === 'journal') &&
            !quizBtnValue
          ) {
            missingFieldsTexts.push('Missing button text');
          }
          if (
            (quizType === 'journal' &&
              quizBtnValue.length > CONTINUE_BTN_MAX_LENGTH) ||
            ((quizType === 'exercise' || quizType === 'data') &&
              isMultipleChoise &&
              quizBtnValue.length > CONTINUE_BTN_MAX_LENGTH)
          ) {
            missingFieldsTexts.push(
              `Button text length limited to ${CONTINUE_BTN_MAX_LENGTH}`
            );
          }
          if (
            (quizType === 'exercise' ||
              quizType === 'data' ||
              quizType === 'journal' ||
              quizType === 'emoji' ||
              quizType === 'numbered') &&
            draftToHtml(convertToRaw(quizTextQuestion.getCurrentContent()))
              .length <= LENGTH_EDITOR_STATE_VALIDATION
          ) {
            missingFieldsTexts.push('Missing text');
          }
          if (
            (quizType === 'exercise' || quizType === 'data') &&
            quizCoises.length < MIN_QUIZ_CHOISES
          ) {
            missingFieldsTexts.push('Quiz  require at least 2 choices');
          }
          if (!quizType) {
            missingFieldsTexts.push('Missing quiz type');
          }
          if (
            quizType === 'exercise' &&
            draftToHtml(convertToRaw(quizAfterCorrect.getCurrentContent()))
              .length <= LENGTH_EDITOR_STATE_VALIDATION
          ) {
            missingFieldsTexts.push('Missing after correct answer content');
          }
          if (
            quizType === 'exercise' &&
            !quizCoises.find(choise => choise.isCorrect) &&
            !isOtherCorrect &&
            isMultipleChoise
          ) {
            missingFieldsTexts.push('Missing correct choice');
          }
          if (
            (quizType === 'data' ||
              quizType === 'journal' ||
              quizType === 'emoji' ||
              quizType === 'numbered') &&
            !selectedTagId
          ) {
            missingFieldsTexts.push('Missing selected tag');
          }
          if (
            quizType === 'exercise' &&
            !isMultipleChoise &&
            numOfCorrectAnswers + isOtherCorrectToNumber !== 1
          ) {
            missingFieldsTexts.push('Requires only one correct answer');
          }
          if (
            (quizType === 'exercise' || quizType === 'data') &&
            quizOtherStr.length > OTHER_ANSWER_MAX_LENGTH
          ) {
            missingFieldsTexts.push(
              `Other option limited to ${OTHER_ANSWER_MAX_LENGTH} characters`
            );
          }
        } else {
          setIsSaveDisabled(false);
        }
      }
      setMissingFields(missingFieldsTexts);
    }
    // else {
    //   if (
    //     ((currentStage === 3 && !isFromEdit) ||
    //       (currentStage === 4 && isFromEdit && !isScreenEditor))
    //       // && estimatedMins <= 0
    //   ) {
    //     setIsSaveDisabled(true);
    //   } else {
    //     setIsSaveDisabled(false);
    //   }
    // }
  }, [
    isScreenEditor,
    titleValue,
    selectedTab,
    // htmlBtnValue,
    editorState,
    videoFile,
    quizBtnValue,
    quizCoises,
    quizType,
    quizTextQuestion,
    quizAfterCorrect,
    // estimatedMins,
    selectedTagId,
    isOtherCorrect,
    isMultipleChoise,
    quizOtherStr,
  ]);

  const renderSmallEditor = (
    title,
    currentEditorState,
    setCurrentEditorState,
    isSecondary = false
  ) => {
    return (
      <div className={styles.text_editor_container}>
        <div className={styles.small_editor_title}>
          {title}{' '}
          {!currentEditorState.getCurrentContent().getPlainText().length ? (
            <span style={{ color: 'red' }}> *</span>
          ) : null}
          :
        </div>
        <Editor
          editorRef={setEditorReference}
          editorState={currentEditorState}
          editorClassName={styles.editorClassName}
          editorStyle={{
            minHeight: '40vh',
            backgroundColor: 'white',
            fontFamily: 'Avenir',
          }}
          onEditorStateChange={editorNewState => {
            setCurrentEditorState(editorNewState);
            titleAutoFill(editorNewState, isSecondary);
          }}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: mentionSuggestions,
          }}
          toolbar={{
            options: [
              'emoji',
              'colorPicker',
              'inline',
              'list',
              'textAlign',
              'fontSize',
            ],
            colorPicker: {
              colors: [
                'rgb(97,189,109)',
                'rgb(26,188,156)',
                'rgb(84,172,210)',
                'rgb(44,130,201)',
                'rgb(147,101,184)',
                'rgb(71,85,119)',
                'rgb(204,204,204)',
                'rgb(65,168,95)',
                'rgb(0,168,133)',
                'rgb(61,142,185)',
                'rgb(41,105,176)',
                'rgb(85,57,130)',
                'rgb(40,50,78)',
                'rgb(0,0,0)',
                'rgb(247,218,100)',
                'rgb(251,160,38)',
                'rgb(235,107,86)',
                'rgb(226,80,65)',
                'rgb(163,143,132)',
                'rgb(239,239,239)',
                'rgb(255,255,255)',
                'rgb(250,197,28)',
                'rgb(243,121,52)',
                'rgb(209,72,65)',
                'rgb(184,49,47)',
                'rgb(124,112,107)',
                'rgb(209,213,216)',
                '#676A81',
              ],
            },
            fontFamily: {
              options: ['Avenir'],
            },
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            fontSize: { options: [24, 22, 18] },
          }}
          stripPastedStyles
        />
      </div>
    );
  };

  const renderScreenEditor = () => {
    if (selectedTab === HTML_SELCTED_TAB) {
      return (
        <div>
          <div className={styles.upload_image_editor_container}>
            {!isLoadingImage ? (
              <img
                className={styles.editor_img}
                src={htmlImgPath || placeHolder}
                alt="placeHolder"
              />
            ) : (
              <CircularProgress />
            )}
            <input
              accept="image/png, image/gif, image/jpeg"
              hidden
              id="image-screen-input"
              type="file"
              onChange={onScreenImageFileChange}
              style={{ display: 'none' }}
            />
            <div className={styles.upload_image_editor_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-screen-input').click();
                }}
                color="primary"
                variant="contained"
                disabled={isFinalizedState}
              >
                upload image
              </Button>
              <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px' }}
                disabled={isFinalizedState}
              >
                select uploaded
              </Button>
              {htmlImgPath ? (
                <Button
                  onClick={() => {
                    removeScreenImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                  disabled={isFinalizedState}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>

          <div className={styles.text_editor_container}>
            <Editor
              editorRef={setEditorReference}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName={styles.editorClassName}
              wrapperStyle={{}}
              editorStyle={{
                minHeight: '40vh',
                backgroundColor: 'white',
                fontFamily: 'Avenir',
              }}
              toolbarStyle={{}}
              onEditorStateChange={editorNewState => {
                setEditorState(editorNewState);
                titleAutoFill(editorNewState);
              }}
              mention={{
                separator: ' ',
                trigger: '@',
                suggestions: mentionSuggestions,
              }}
              toolbar={{
                options: [
                  'emoji',
                  'colorPicker',
                  'inline',
                  'list',
                  'textAlign',
                  'fontSize',
                ],
                fontFamily: {
                  options: ['Avenir'],
                },
                colorPicker: {
                  colors: [
                    'rgb(97,189,109)',
                    'rgb(26,188,156)',
                    'rgb(84,172,210)',
                    'rgb(44,130,201)',
                    'rgb(147,101,184)',
                    'rgb(71,85,119)',
                    'rgb(204,204,204)',
                    'rgb(65,168,95)',
                    'rgb(0,168,133)',
                    'rgb(61,142,185)',
                    'rgb(41,105,176)',
                    'rgb(85,57,130)',
                    'rgb(40,50,78)',
                    'rgb(0,0,0)',
                    'rgb(247,218,100)',
                    'rgb(251,160,38)',
                    'rgb(235,107,86)',
                    'rgb(226,80,65)',
                    'rgb(163,143,132)',
                    'rgb(239,239,239)',
                    'rgb(255,255,255)',
                    'rgb(250,197,28)',
                    'rgb(243,121,52)',
                    'rgb(209,72,65)',
                    'rgb(184,49,47)',
                    'rgb(124,112,107)',
                    'rgb(209,213,216)',
                    '#676A81',
                  ],
                },
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                fontSize: { options: [24, 22, 18] },
              }}
              stripPastedStyles
            />
          </div>
          {/* <div className={styles.add_btn_label}>Screen button text:</div>
          <Input
            placeholder="Please add button text"
            style={{ fontSize: '1.5rem', marginTop: '10px' }}
            value={htmlBtnValue}
            onChange={e => setHtmlBtnValue(e.target.value)}
          /> */}
        </div>
      );
    }
    if (selectedTab === VIDEO_SELCTED_TAB) {
      const renderFilePath = () => {
        if (videoFile && videoFile.name) {
          return videoFile.name;
        }
        if (!videoFile && isScreenEditor && videoPrev) {
          return videoPrev;
        }
        return '';
      };
      return (
        <div>
          <div
            onClick={() => {
              document.getElementById('video-input').click();
            }}
          >
            <Button color="primary">select video</Button>
          </div>
          <input
            accept="video/*"
            hidden
            id="video-input"
            type="file"
            onChange={onVideoFileChange}
            style={{ display: 'none' }}
          />
          <div className={styles.video_file_label}>{renderFilePath()}</div>
          {fileSizeError ? (
            <span style={{ color: 'red' }}>
              File size should be up to 750MB
            </span>
          ) : null}
        </div>
      );
    }
    if (selectedTab === QUIZ_SELCTED_TAB) {
      let numOfCorrects = 0;
      quizCoises.forEach(choise => {
        if (choise.isCorrect) {
          numOfCorrects += 1;
        }
      });
      return (
        <div>
          <div className={styles.upload_image_editor_container}>
            {!isLoadingImage ? (
              <img
                className={styles.editor_img}
                src={htmlImgPath || placeHolder}
                alt="placeHolder"
              />
            ) : (
              <CircularProgress />
            )}
            <input
              accept="image/png, image/gif, image/jpeg"
              hidden
              id="image-screen-input"
              type="file"
              onChange={onScreenImageFileChange}
              style={{ display: 'none' }}
            />
            <div className={styles.upload_image_editor_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-screen-input').click();
                }}
                color="primary"
                variant="contained"
              >
                upload image
              </Button>
              <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px' }}
              >
                select uploaded
              </Button>
              {htmlImgPath ? (
                <Button
                  onClick={() => {
                    removeScreenImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
          <div className={styles.select_container}>
            <InputLabel
              id="quiz_type"
              style={{ marginRight: '5px', fontSize: '1.5rem' }}
            >
              Quiz Type:{' '}
            </InputLabel>
            <Select
              value={quizType}
              onChange={e => {
                setQuizType(e.target.value);
                clearEditors(e.target.value);
              }}
              // labelId="quiz_type"
              style={{ minWidth: '100px', padding: '5px', fontSize: '1.5rem' }}
            >
              <MenuItem value="exercise">Exercise</MenuItem>
              <MenuItem value="data">Data</MenuItem>
              <MenuItem value="journal">Free-text</MenuItem>
              <MenuItem value="emoji">Emoji</MenuItem>
              <MenuItem value="numbered">Numbered</MenuItem>
            </Select>
          </div>
          {quizType === 'data' ||
          quizType === 'exercise' ||
          quizType === 'journal' ||
          quizType === 'emoji' ||
          quizType === 'numbered'
            ? renderSmallEditor(
                'Quiz text question',
                quizTextQuestion,
                setQuizTextQuestion
              )
            : null}
          {quizType === 'data' || quizType === 'exercise' ? (
            <div className={styles.choises_container}>
              {quizCoises.map(choise => (
                <div className={styles.choise_item_container} key={choise.key}>
                  <Button
                    onClick={() => deleteChoiseClicked(choise)}
                    variant="contained"
                    style={{
                      minWidth: '20px',
                      height: '20px',
                      margin: '10px',
                    }}
                    size="small"
                  >
                    X
                  </Button>
                  <Button
                    onClick={() => {
                      setEditChoiseToggle(true);
                      setChoiseToEdit(choise.key);
                      setEditChoiseText(choise.text);
                    }}
                    variant="contained"
                    style={{
                      minWidth: '20px',
                      height: '20px',
                      margin: '10px',
                    }}
                    size="small"
                  >
                    🖊
                  </Button>
                  {quizType === 'exercise' ? (
                    <Checkbox
                      checked={choise.isCorrect}
                      onChange={e => setIsCorrect(choise, e.target.checked)}
                      color="primary"
                      disabled={
                        !isMultipleChoise &&
                        (!!numOfCorrects || isOtherCorrect) &&
                        !choise.isCorrect
                      }
                    />
                  ) : null}
                  <div>{choise.text}</div>
                </div>
              ))}
              {editChoiseToggle ? (
                <div style={{ display: 'flex' }}>
                  <Input
                    value={editChoiseText}
                    onChange={e => setEditChoiseText(e.target.value)}
                  />
                  <Button
                    onClick={onEditChoiseSaveClicked}
                    disabled={!editChoiseText}
                  >
                    save
                  </Button>
                  <Button onClick={resetEditChoise}>cancel</Button>
                </div>
              ) : null}
              <div>
                <TextField
                  label="New Choice"
                  style={{ fontSize: '1.5rem', marginTop: '10px' }}
                  value={quizNewCoise}
                  onChange={e => setQuizNewCoise(e.target.value)}
                />
                <Button
                  onClick={addNewChoiseClicked}
                  disabled={
                    !quizNewCoise ||
                    quizCoises.length >= MAX_QUIZ_CHOISES ||
                    quizNewCoise.length > QUIZ_CHOISE_MAX_LENGTH
                  }
                  style={{ marginTop: '25px' }}
                >
                  + Add new choice
                </Button>
                <div>
                  <Checkbox
                    checked={isMultipleChoise}
                    onChange={e => setIsMultipleChoise(e.target.checked)}
                    color="primary"
                  />
                  <span>Is multiple choice</span>
                </div>
              </div>
            </div>
          ) : null}
          {quizType === 'data' ||
          quizType === 'journal' ||
          quizType === 'emoji' ||
          quizType === 'numbered' ? (
            <div className={styles.data_quiz_btns_container}>
              <div>
                {selectedTagId ? (
                  <div style={{ marginBottom: '10px' }}>
                    <div>selected tag:</div>
                    <div style={{ fontWeight: 500 }}>
                      {tags[selectedTagId] &&
                      tags[selectedTagId].tagName &&
                      tags[selectedTagId].tagName.en
                        ? tags[selectedTagId].tagName.en
                        : null}
                    </div>
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  onClick={() => setIsTagsDialogOpen(true)}
                >
                  select tag
                </Button>
                <LagoTags
                  open={isTagsDialogOpen}
                  onClose={() => setIsTagsDialogOpen(false)}
                  tags={tags}
                  onAddTag={onAddTag}
                  onSelectTag={onSelectTag}
                  journeyId={journeyId}
                  currentStage={currentStage}
                />
              </div>
            </div>
          ) : null}

          {(quizType === 'data' && isMultipleChoise) ||
          (quizType === 'exercise' && isMultipleChoise) ||
          quizType === 'journal' ? (
            <TextField
              label={quizBtnValue ? 'Add button text' : 'Add button text *'}
              style={{ fontSize: '1.5rem', marginTop: '10px' }}
              value={quizBtnValue}
              onChange={e => setQuizBtnValue(e.target.value)}
            />
          ) : null}
          {quizType === 'exercise'
            ? renderSmallEditor(
                'Question explenation after answer',
                quizAfterCorrect,
                setQuizAfterCorrect,
                true
              )
            : null}
          {quizType === 'numbered' ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Select
                value={quizMaxNum}
                onChange={e => {
                  setQuizMaxNum(e.target.value);
                }}
                style={{
                  width: '100px',
                  padding: '5px',
                  fontSize: '1.5rem',
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: '5px',
                }}
              >
                <MenuItem value={5}>1 - 5</MenuItem>
                <MenuItem value={10}>1 - 10</MenuItem>
              </Select>
              <div>
                First rating text:
                <Input
                  value={firstRatingText}
                  onChange={e => {
                    setFirstRatingText(e.target.value);
                  }}
                />
              </div>
              <div>
                Last rating text:
                <Input
                  value={lastRatingText}
                  onChange={e => {
                    setLastRatingText(e.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className={styles.quiz_inputs_container}>
            {quizType === 'data' ||
            quizType === 'exercise' ||
            quizType === 'journal' ? (
              <div style={{ display: 'flex' }}>
                {quizType === 'journal' ? null : (
                  <Checkbox
                    checked={isOtherCorrect}
                    onChange={e => setIsOtherCorrect(e.target.checked)}
                    color="primary"
                    disabled={!isMultipleChoise && !!numOfCorrects}
                    style={{ marginTop: '10px' }}
                  />
                )}
                <TextField
                  label={
                    quizType === 'data' || quizType === 'exercise'
                      ? 'Other'
                      : 'Placeholder'
                  }
                  style={{ fontSize: '1.5rem', marginTop: '10px' }}
                  value={quizOtherStr}
                  onChange={e => setQuizOtherStr(e.target.value)}
                />
              </div>
            ) : null}
            {/* {quizType === 'data' || quizType === 'exercise' ? (
              <TextField
                label="Please add button after answer text"
                style={{ fontSize: '1.5rem', marginTop: '10px' }}
                value={quizBtnAfterAnswerValue}
                onChange={e => setQuizBtnAfterAnswerValue(e.target.value)}
              />
            ) : null} */}
          </div>
        </div>
      );
    }
    return null;
  };

  // const renderEstimatedMinsInput = () => {
  //   if (
  //     (currentStage === 3 &&
  //       !isFromEdit &&
  //       !isFromGoalEdit &&
  //       !isFromJournalEdit &&
  //       !isFromCheckinEdit) ||
  //     (currentStage === 4 && isFromEdit && !isScreenEditor)
  //   ) {
  //     return (
  //       <div
  //         className={styles.modal_title_container}
  //         style={{ marginTop: '10px' }}
  //       >
  //         <span className={styles.modal_title_label}>
  //           Estimated minutes to complete:
  //         </span>
  //         <Input
  //           type="number"
  //           value={estimatedMins}
  //           onChange={e => setEstimatedMins(+e.target.value)}
  //           style={{ fontSize: '1.5rem', width: '50px' }}
  //         />
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setMissingFields([]);
        onClose();
      }}
      className={styles.lago_dialog_container}
      maxWidth="xl"
      fullWidth={isScreenEditor}
      disableBackdropClick
    >
      <DialogContent
        style={{ minHeight: '20vh', maxHeight: '90vh', minWidth: '20vw' }}
      >
        <div className={styles.modal_title_container}>
          <span className={styles.modal_title_label}>
            Title {titleValue ? null : <span style={{ color: 'red' }}>*</span>}:
          </span>
          <Input
            value={titleValue}
            onChange={e => {
              setTitleValue(e);
              setIsTitleEdited(true);
            }}
            style={{ fontSize: '1.5rem' }}
          />
        </div>
        {currentStage === 0 ||
          (currentStage === 1 && isFromEdit && (
            <div className={styles.modal_title_container}>
              <span className={styles.modal_title_label}>
                Clinical Dashboard Description:
                <Input
                  value={journeyDashboardDescription}
                  onChange={e => {
                    setJourneyDashboardDescription(e.target.value);
                  }}
                  style={{ fontSize: '1.5rem' }}
                />
              </span>
            </div>
          ))}
        {/* {renderEstimatedMinsInput()} */}
        <div>
          {isScreenEditor ? (
            <Tabs
              value={selectedTab}
              onChange={(e, v) => {
                if (!isFinalizedState) {
                  setSelectedTab(v);
                }
              }}
            >
              <Tab label="Text screen" />
              <Tab label="Video screen" />
              <Tab label="Quiz screen" />
            </Tabs>
          ) : null}
          {isScreenEditor ? renderScreenEditor() : null}
        </div>
        {missingFields.length ? (
          <div className={styles.missing_fields_container}>
            {missingFields.map(missingField => (
              <div key={missingField} className={styles.missing_fields_item}>
                {missingField}
              </div>
            ))}
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setMissingFields([]);
            setIsSaveDisabled(false);
            onClose();
            setIsTitleEdited(false);
          }}
          color="primary"
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
        >
          cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onSave}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          disabled={!titleValue.trim() || isSaveDisabled || isFinalizedState}
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoEditScreenDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseSave: PropTypes.func.isRequired,
  saveHtml: PropTypes.func.isRequired,
  saveNewHtml: PropTypes.func.isRequired,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  saveEditTitle: PropTypes.func.isRequired,
  titleValue: PropTypes.string,
  setTitleValue: PropTypes.func,
  isFromEdit: PropTypes.bool,
  isScreenEditor: PropTypes.bool,
  onVideoFileChange: PropTypes.func,
  videoFile: PropTypes.object,
  saveVideo: PropTypes.func,
  saveNewVideo: PropTypes.func,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  // uploadCallback: PropTypes.func,
  // htmlBtnValue: PropTypes.string,
  // setHtmlBtnValue: PropTypes.func,
  htmlImgPath: PropTypes.string,
  onScreenImageFileChange: PropTypes.func,
  quizType: PropTypes.string,
  setQuizType: PropTypes.func,
  quizTextQuestion: PropTypes.object,
  setQuizTextQuestion: PropTypes.func,
  quizCoises: PropTypes.array,
  setQuizCoises: PropTypes.func,
  quizNewCoise: PropTypes.string,
  setQuizNewCoise: PropTypes.func,
  quizAfterCorrect: PropTypes.object,
  setQuizAfterCorrect: PropTypes.func,
  quizBtnValue: PropTypes.string,
  setQuizBtnValue: PropTypes.func,
  saveQuiz: PropTypes.func,
  saveNewQuiz: PropTypes.func,
  // estimatedMins: PropTypes.number,
  // setEstimatedMins: PropTypes.func,
  currentStage: PropTypes.number,
  setDisplayUploadedImages: PropTypes.func,
  quizOtherStr: PropTypes.string,
  setQuizOtherStr: PropTypes.func,
  isMultipleChoise: PropTypes.bool,
  setIsMultipleChoise: PropTypes.func,
  tags: PropTypes.object,
  onAddTag: PropTypes.func,
  onSelectTag: PropTypes.func,
  selectedTagId: PropTypes.string,
  // quizBtnAfterAnswerValue: PropTypes.string,
  // setQuizBtnAfterAnswerValue: PropTypes.func,
  // isFromGoalEdit: PropTypes.bool,
  setQuizMaxNum: PropTypes.func,
  quizMaxNum: PropTypes.number,
  firstRatingText: PropTypes.string,
  setFirstRatingText: PropTypes.func,
  lastRatingText: PropTypes.string,
  setLastRatingText: PropTypes.func,
  isOtherCorrect: PropTypes.bool,
  setIsOtherCorrect: PropTypes.func,
  // isFromJournalEdit: PropTypes.bool,
  videoPrev: PropTypes.string,
  editVideoTitle: PropTypes.func,
  isLoadingImage: PropTypes.bool,
  // isFromCheckinEdit: PropTypes.bool,
  setAddedItemTitle: PropTypes.func,
  removeScreenImage: PropTypes.func,
  journeyId: PropTypes.string,
  fileSizeError: PropTypes.bool,
  isFinalizedState: PropTypes.bool,
  journeyDashboardDescription: PropTypes.string,
  setJourneyDashboardDescription: PropTypes.func,
});

export const LagoEditScreenDialog = React.memo(LagoEditScreenDialogBase);
LagoEditScreenDialog.displayName = 'LagoEditScreenDialog';

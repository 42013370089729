import { convertArrayToCSV } from 'convert-array-to-csv';
import uuidv4 from 'uuid/v4';
import * as assessments from './src/models/assessments/assessments';
import { getAge } from './src/models/person/person';
import * as utils from './src/utils/utils';
import * as symptomTracker from './src/models/symptom-tracker/symptom-tracker';
import * as customer from './src/models/customer/customer';
import { getLogoImage } from './src/models/logo/logo';
import { initAssessmentPage } from './src/models/page-initializers/assessment-initializer';

export const models = {
  assessments,
  person: {
    getAge,
  },
  utils,
  customer,
  logo: {
    getLogoImage,
  },
  pageInitializers: {
    initAssessmentPage,
  },
  symptomTracker,
  externals: {
    convertArrayToCSV,
  },
  uuidv4,
};

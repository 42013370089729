exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CircleSso_root{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.CircleSso_login_container{width:500px;display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:100px;border-radius:10px;padding:20px;background-color:white}.CircleSso_login_title{font-size:22px;margin-bottom:10px;font-weight:900}.CircleSso_login_text{font-size:18px;margin-bottom:10px;text-align:center}.CircleSso_btns_container{display:flex;justify-content:center;margin-top:20px;margin-bottom:20px}\n", ""]);

// Exports
exports.locals = {
	"root": "CircleSso_root",
	"login_container": "CircleSso_login_container",
	"login_title": "CircleSso_login_title",
	"login_text": "CircleSso_login_text",
	"btns_container": "CircleSso_btns_container"
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  // Button,
  Tooltip,
  // Chip,
  Divider,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import dayJS from 'dayjs';
import EdiText from 'react-editext';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
// import EventAvailableIcon from '@material-ui/icons/EventAvailable';
// import DateRangeIcon from '@material-ui/icons/DateRange';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TimerIcon from '@material-ui/icons/Timer';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import UpPng from '../../../assets/move-up.png';
import DownPng from '../../../assets/move-down.png';

import styles from './DeployedProgramCard.scss';

import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
// import { useTranslation } from '../../Core/hooks/useTranslation';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { CustomDialog } from '../../Core/Components/CustomDialog/CustomDialog';
import { WrapConditionally } from '../../Core/Components/WrapConditionally.jsx/WrapConditionally';

const stylesFn = () => ({
  buttonRoot: {
    borderRadius: '20px',
  },
  upgradeButtonRoot: {
    borderRadius: '20px',
    background: '#4baaa6',
    color: 'white',
    '&:hover': {
      background: '#439995',
    },
  },
});

const CustomLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#EFEFEF',
  },
  barColorPrimary: {
    backgroundColor: '#51e5d6',
    borderRadius: '8px',
  },
  root: {
    borderRadius: '8px',
    height: '16px',
  },
})(LinearProgress);

const CustomRedLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#EFEFEF',
  },
  barColorPrimary: {
    backgroundColor: '#feddcf',
    borderRadius: '8px',
  },
  root: {
    borderRadius: '8px',
    height: '16px',
  },
})(LinearProgress);

const EditTextWrapper = props => (
  <EdiText
    saveButtonContent={<CheckIcon style={{ color: '#2196f3' }} />}
    editButtonContent={<EditIcon style={{ fontSize: 17, color: '#2196f3' }} />}
    cancelButtonContent={<CloseIcon style={{ color: '#2196f3' }} />}
    saveButtonClassName={styles.edit_text_button}
    editButtonClassName={styles.edit_text_button}
    cancelButtonClassName={styles.edit_text_button}
    editOnViewClick
    hideIcons
    submitOnEnter
    submitOnUnfocus
    cancelOnEscape
    startEditingOnFocus
    tabIndex={0}
    showButtonsOnHover
    {...props}
  />
);

const sessionStatusesToPreventOrderChangeOffline = [
  'RESUMED',
  'PAUSED',
  'STOPPED',
  'BREAK_TIME',
];
const sessionStatusesToPreventOrderChangeOnline = [
  'RESUMED',
  'PAUSED',
  'STOPPED',
  'BREAK_TIME',
  'Activity selection',
  'Hardware tutorial',
  'Headband battery alert',
  'Headband connection',
  'signalCheck',
  'calibration',
  'resumed',
  'paused',
  'breakTime',
];

const iconStyle = { fontSize: 18 };
const DeployedProgramCardBase = ({
  deployedProgram,
  onEditProgram,
  onCancelProgram,
  onChangeProgramName,
  validateProgramName,
  programNameErrorMsg,
  renderCarouselControl,
  autoDeployableConfigs,
  onChangeItemOrder,
  numOfPrograms,
  index,
  status,
  presence,
  roundsStatus,
  isExpired,
}) => {
  // const t = useTranslation();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // eslint-disable-next-line no-undef
  const { trainingChannel, referenceChannel } = getChannelNames({
    protocol: deployedProgram.protocolDetails,
  });

  const lastSessionDoneDate = deployedProgram.lastSessionDone
    ? dayJS(deployedProgram.lastSessionDone).format(
        DAY_JS_DATE_FORMATS.american
      )
    : 'None';

  // eslint-disable-next-line no-undef
  const protocolOverAllSuccessRate = getOverAllSuccessRate(
    // eslint-disable-next-line no-undef
    new ProtocolDetails(deployedProgram.protocolDetails)
  );

  // const isDeployedProgramCompleted =
  //   deployedProgram.sessionsOfProgramDone >= deployedProgram.sessionsInProgram;

  const isChangeOrderDisabled = () => {
    if (
      (presence === 'online' &&
        !!sessionStatusesToPreventOrderChangeOnline.find(
          statusString => statusString === status
        )) ||
      (presence !== 'online' &&
        !!sessionStatusesToPreventOrderChangeOffline.find(
          statusString => statusString === status
        ))
    ) {
      return true;
    }
    if (roundsStatus) {
      const lastSession = Object.keys(roundsStatus).pop();
      if (!roundsStatus[lastSession].full && roundsStatus[lastSession].rounds) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div className={styles.program_header}>
        <div className={styles.program_name}>
          {onChangeProgramName ? (
            <>
              <EditTextWrapper
                type="text"
                value={deployedProgram.name?.en ?? 'Main Training'}
                onSave={value => onChangeProgramName(value)}
                viewContainerClassName={styles.edit_text_container_header}
                validation={validateProgramName}
                validationMessage={
                  <div className={styles.edit_text_validation_msg}>
                    {programNameErrorMsg}
                  </div>
                }
              />
            </>
          ) : (
            <p>{deployedProgram.name?.en ?? 'Main Training'}</p>
          )}

          {/* {isDeployedProgramCompleted && (
            <Chip
              label="Completed"
              size={24}
              style={{
                background: 'rgb(40, 197, 181)',
                color: 'white',
                fontSize: 'inherit',
                fontWeight: 'bold',
              }}
            />
          )} */}
        </div>
        {renderCarouselControl ? null : (
          <div style={{ display: 'flex' }}>
            {numOfPrograms > 1 && index > 0 ? (
              <div>
                <WrapConditionally
                  condition={isChangeOrderDisabled()}
                  wrap={children => (
                    <TooltipWrapper
                      title={
                        <TooltipTitle>
                          The order can’t be changed while the client is
                          training.
                        </TooltipTitle>
                      }
                    >
                      <div>{children}</div>
                    </TooltipWrapper>
                  )}
                >
                  <img
                    src={UpPng}
                    style={{
                      color: '#959595',
                      cursor: isChangeOrderDisabled()
                        ? 'not-allowed'
                        : 'pointer',
                      width: 20,
                      height: 20,
                      marginRight: 20,
                    }}
                    onClick={() => {
                      if (isChangeOrderDisabled()) {
                        return;
                      }
                      onChangeItemOrder(deployedProgram.id, true);
                    }}
                  />
                </WrapConditionally>
              </div>
            ) : null}
            {numOfPrograms > 1 && index < numOfPrograms - 1 ? (
              <div>
                <WrapConditionally
                  condition={isChangeOrderDisabled()}
                  wrap={children => (
                    <TooltipWrapper
                      title={
                        <TooltipTitle>
                          The order can’t be changed while the client is
                          training.
                        </TooltipTitle>
                      }
                    >
                      <div>{children}</div>
                    </TooltipWrapper>
                  )}
                >
                  <img
                    src={DownPng}
                    style={{
                      color: '#959595',
                      cursor: isChangeOrderDisabled()
                        ? 'not-allowed'
                        : 'pointer',
                      width: 20,
                      height: 20,
                      marginRight: 20,
                    }}
                    onClick={() => {
                      if (isChangeOrderDisabled()) {
                        return;
                      }
                      onChangeItemOrder(deployedProgram.id, false);
                    }}
                  />
                </WrapConditionally>
              </div>
            ) : null}
            <DeleteIcon
              style={{ color: '#959595', cursor: 'pointer' }}
              onClick={() => setIsDeleteDialogOpen(true)}
            />
            <EditIcon
              style={{ marginLeft: 20, color: '#2196f3', cursor: 'pointer' }}
              onClick={onEditProgram}
            />
          </div>
        )}
        {!autoDeployableConfigs ? null : renderCarouselControl()}
      </div>
      <div className={styles.content}>
        <div>
          <div>
            <SwapVertIcon style={iconStyle} />
            <Tooltip
              title={
                <TooltipTitle>{deployedProgram.protocolName}</TooltipTitle>
              }
            >
              <p className={styles.protocol_container}>
                {deployedProgram.protocolName}
              </p>
            </Tooltip>
          </div>

          {/* <div>
            <LocationSearchingIcon style={iconStyle} />
            <p>
              {trainingChannel} active - {referenceChannel} reference
            </p>
          </div> */}
        </div>

        <div>
          <div>
            <TimerIcon style={iconStyle} />
            <p>
              {deployedProgram.roundsNumber}{' '}
              {deployedProgram.roundsNumber === 1 ? 'round' : 'rounds'} -{' '}
              {deployedProgram.roundDuration}{' '}
              {deployedProgram.roundDuration === 1 ? 'minute' : 'minutes each'}
            </p>
          </div>

          {/* <div>
            <EventAvailableIcon style={iconStyle} />
            <p>
              {deployedProgram.sessionsOfProgramDone}/
              {deployedProgram.sessionsInProgram}{' '}
              {t('sessions_done_in_this_program')}
            </p>
          </div> */}
        </div>

        <div>
          <div>
            <TrendingUpIcon style={iconStyle} />
            <p>{protocolOverAllSuccessRate}% Positive Feedback</p>
          </div>

          {/* <div>
            <DateRangeIcon style={iconStyle} />
            <p>Last session done: {lastSessionDoneDate}</p>
          </div> */}
        </div>
        <div>
          <div>
            <LocationSearchingIcon style={iconStyle} />
            <p>
              {trainingChannel} active - {referenceChannel} reference
            </p>
          </div>
        </div>
      </div>

      {autoDeployableConfigs ? null : <Divider style={{ marginBottom: 20 }} />}
      {autoDeployableConfigs ? null : (
        <div className={styles.card_bottom}>
          <div
            style={{
              width:
                // eslint-disable-next-line no-nested-ternary
                window.innerWidth < 960
                  ? '100%'
                  : renderCarouselControl
                  ? '55%'
                  : '80%',
            }}
          >
            <p style={{ marginBottom: 10, textAlign: 'start' }}>
              {deployedProgram.sessionsOfProgramDone}/
              {deployedProgram.sessionsInProgram} Sessions Completed
            </p>
            {!isExpired ? (
              <CustomLinearProgress
                variant="determinate"
                value={
                  (deployedProgram.sessionsOfProgramDone /
                    deployedProgram.sessionsInProgram) *
                  100
                }
              />
            ) : (
              <CustomRedLinearProgress
                variant="determinate"
                value={
                  (deployedProgram.sessionsOfProgramDone /
                    deployedProgram.sessionsInProgram) *
                  100
                }
              />
            )}
          </div>
          <div
            style={{
              width:
                // eslint-disable-next-line no-nested-ternary
                window.innerWidth < 960
                  ? '100%'
                  : renderCarouselControl
                  ? '15%'
                  : '20%',
              textAlign: window.innerWidth > 960 ? 'end' : 'start',
              visibility:
                lastSessionDoneDate === 'None' && !renderCarouselControl
                  ? 'hidden'
                  : 'visible',
            }}
          >
            <p style={{ marginBottom: 5 }}>Latest session:</p>
            <p>{lastSessionDoneDate}</p>
          </div>
          {renderCarouselControl ? (
            <div style={{ width: window.innerWidth < 960 ? '100%' : '30%' }}>
              {renderCarouselControl()}
            </div>
          ) : null}
        </div>
      )}

      {/* <div className={styles.buttons_container}>
        {onCancelProgram && (
          <Button onClick={onCancelProgram}>
            <span className={styles.btn}>
              {isDeployedProgramCompleted ? 'Archive' : t('cancel')}
            </span>
          </Button>
        )}

        {onEditProgram && (
          <Button onClick={onEditProgram}>
            <span className={styles.btn}>{t('edit')}</span>
          </Button>
        )}
      </div> */}
      <CustomDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onPrimaryBtnClick={() => {
          setIsDeleteDialogOpen(false);
          onCancelProgram();
        }}
        onSecondaryBtnClick={() => {
          setIsDeleteDialogOpen(false);
        }}
        primaryBtnContent="delete"
        secondaryBtnContent="cancel"
        shouldShowCloseIcon={false}
      >
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: 16, fontWeight: 900 }}>
            Are you sure you want to delete this program?
          </div>
          <p style={{ fontSize: 14, marginTop: 20 }}>
            If you delete it, you won’t be able to access it anymore.
          </p>
        </div>
      </CustomDialog>
    </>
  );
};

DeployedProgramCardBase.propTypes = exact({
  deployedProgram: PropTypes.object.isRequired,
  onEditProgram: PropTypes.func,
  onCancelProgram: PropTypes.func,
  onChangeProgramName: PropTypes.func,
  validateProgramName: PropTypes.func,
  programNameErrorMsg: PropTypes.string,
  renderCarouselControl: PropTypes.func,
  autoDeployableConfigs: PropTypes.bool,
  onChangeItemOrder: PropTypes.func,
  numOfPrograms: PropTypes.number,
  index: PropTypes.number,
  presence: PropTypes.string,
  status: PropTypes.string,
  roundsStatus: PropTypes.object,
  isExpired: PropTypes.bool,
});

export const DeployedProgramCard = React.memo(
  withStyles(stylesFn)(DeployedProgramCardBase)
);
DeployedProgramCard.displayName = 'DeployedProgramCard';

import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { isDeepEqualWithTrueForFunctions } from '../../utils/utils';

export class DeepEqualityComponent extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isDeepEqualWithTrueForFunctions(this.props, nextProps) ||
      !isEqual(this.state, nextState)
    );
  }
}

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Checkbox, Switch, Popover } from '@material-ui/core';
import styles from './AssignedUsers.scss';

const teamMembersBgColors = [
  { bg: '#EAFEFC', letter: '#58B6A8' },
  { bg: '#D9E8FD', letter: '#087EF9' },
  { bg: '#FFF6DD', letter: '#D1AE4E' },
  { bg: '#FBEAFE', letter: '#A36BAD' },
];

const assignUserEventLogger = new MyEventWrapper('assign_user');

const AssignedUsersBase = ({
  isTeamMembersMenuOpen,
  setIsTeamMembersMenuOpen,
  teamMembersList,
  teamMembersUserAssignedTo,
  setAssignedTo,
  componentLocation,
}) => {
  const calcNameShortcut = name => {
    const splittedNameArr = name.split(' ');
    return (
      splittedNameArr[0].slice(0, 1).toUpperCase() +
      (splittedNameArr[1] ? splittedNameArr[1].slice(0, 1).toUpperCase() : '')
    );
  };

  const anchorEl = useRef(null);

  const renderCheckboxList = (namesList, teamMembersListById) => {
    return (
      <Popover
        open={!!isTeamMembersMenuOpen}
        anchorEl={anchorEl.current}
        onClose={() => {
          setIsTeamMembersMenuOpen(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {Object.keys(teamMembersListById).length > 1 ? (
          <div
            className={styles.team_member_menu_item}
            style={{ marginLeft: 15 }}
          >
            <div>Assign to everyone</div>
            <Switch
              checked={
                Object.keys(teamMembersListById).length === namesList.length
              }
              onChange={e => {
                const teamMemberIds = Object.keys(teamMembersListById);
                const newAssignedToObject = {};
                teamMemberIds.forEach(id => {
                  newAssignedToObject[id] = e.target.checked;
                });
                setAssignedTo(newAssignedToObject);
                assignUserEventLogger.log('client_assigned_to_team_member', {
                  action_type: 'select_all',
                  assigned_to_self: true,
                  total_selected_team_members: Object.values(
                    newAssignedToObject
                  ).reduce((a, item) => a + item, 0),
                  fron_where: componentLocation,
                });
              }}
              color="primary"
            />
          </div>
        ) : null}
        {Object.keys(teamMembersListById).map(teamMemberId => {
          return (
            <div className={styles.team_member_menu_item}>
              <Checkbox
                checked={
                  !!(
                    teamMembersUserAssignedTo &&
                    teamMembersUserAssignedTo.assignedTo &&
                    teamMembersUserAssignedTo.assignedTo[teamMemberId]
                  )
                }
                onChange={e => {
                  if (
                    teamMembersUserAssignedTo.assignedTo === null ||
                    teamMembersUserAssignedTo.assignedTo === undefined
                  ) {
                    const teamMemberIds = Object.keys(teamMembersListById);
                    const newAssignedToObject = {};
                    teamMemberIds.forEach(id => {
                      newAssignedToObject[id] = id === teamMemberId;
                    });
                    setAssignedTo(newAssignedToObject);
                    assignUserEventLogger.log(
                      'client_assigned_to_team_member',
                      {
                        action_type: e.target.checked ? 'select' : 'unselect',
                        assigned_to_self:
                          (sessionStorage.isTeamMember === 'true'
                            ? sessionStorage.teamMemberId
                            : sessionStorage.userId) === teamMemberId,
                        total_selected_team_members: Object.values(
                          newAssignedToObject
                        ).reduce((a, item) => a + item, 0),
                        fron_where: componentLocation,
                      }
                    );
                  } else {
                    const assignedToCopy = {
                      ...teamMembersUserAssignedTo.assignedTo,
                    };
                    assignedToCopy[teamMemberId] = !assignedToCopy[
                      teamMemberId
                    ];
                    setAssignedTo(assignedToCopy);
                    assignUserEventLogger.log(
                      'client_assigned_to_team_member',
                      {
                        action_type: e.target.checked ? 'select' : 'unselect',
                        assigned_to_self:
                          (sessionStorage.isTeamMember === 'true'
                            ? sessionStorage.teamMemberId
                            : sessionStorage.userId) === teamMemberId,
                        total_selected_team_members: Object.values(
                          assignedToCopy
                        ).reduce((a, item) => a + item, 0),
                        fron_where: componentLocation,
                      }
                    );
                  }
                }}
                color="primary"
              />
              <div>{teamMembersListById[teamMemberId].name}</div>
            </div>
          );
        })}
      </Popover>
    );
  };

  if (!teamMembersList) {
    return;
  }
  const namesList = [];
  const teamMembersListById = {};

  Object.keys(teamMembersList).forEach((teamMemberItemId, index) => {
    if (teamMembersList[teamMemberItemId].userId) {
      teamMembersListById[teamMembersList[teamMemberItemId].userId] = {
        ...teamMembersList[teamMemberItemId],
        index,
      };
    }
  });

  Object.keys(teamMembersListById).forEach(teamMemberId => {
    if (
      teamMembersUserAssignedTo &&
      teamMembersUserAssignedTo.assignedTo &&
      teamMembersUserAssignedTo.assignedTo[teamMemberId]
    ) {
      namesList.push(teamMembersListById[teamMemberId].name);
    }
  });

  // eslint-disable-next-line no-shadow
  const renderTeamMembers = (namesList, teamMembersListById) => {
    if (!namesList.length) {
      // eslint-disable-next-line consistent-return
      return (
        <div style={{ position: 'relative' }} ref={anchorEl}>
          <div className={styles.empty_team_member_item}>+</div>
        </div>
      );
    }
    if (Object.keys(teamMembersListById).length === namesList.length) {
      // eslint-disable-next-line consistent-return
      return (
        <div style={{ position: 'relative' }} ref={anchorEl}>
          <div className={styles.everyone_team_member_item}>EVERYONE</div>
        </div>
      );
    }
    // eslint-disable-next-line consistent-return
    return (
      <div className={styles.team_members_container} ref={anchorEl}>
        {namesList.map((name, index) => {
          const id = Object.keys(teamMembersListById).find(
            id2 => teamMembersListById[id2].name === name
          );
          const colorIndex = teamMembersListById[id].index;
          if (index < 2) {
            return (
              <div
                className={styles.team_member_item}
                style={
                  index === 0
                    ? {
                        backgroundColor:
                          teamMembersBgColors[
                            colorIndex % teamMembersBgColors.length
                          ].bg,
                        color:
                          teamMembersBgColors[
                            colorIndex % teamMembersBgColors.length
                          ].letter,
                      }
                    : {
                        backgroundColor:
                          teamMembersBgColors[
                            colorIndex % teamMembersBgColors.length
                          ].bg,
                        color:
                          teamMembersBgColors[
                            colorIndex % teamMembersBgColors.length
                          ].letter,
                        position: 'relative',
                        right: 15,
                      }
                }
              >
                {calcNameShortcut(name)}
              </div>
            );
          }
          return null;
        })}
        {namesList.length > 2 ? (
          <div
            className={styles.team_member_item}
            style={{
              backgroundColor: '#ededed',
              color: '#626262',
              position: 'relative',
              right: 30,
            }}
          >
            +{namesList.length - 2}
          </div>
        ) : null}
      </div>
    );
  };
  // eslint-disable-next-line consistent-return
  return (
    <div>
      {renderTeamMembers(namesList, teamMembersListById)}
      {renderCheckboxList(namesList, teamMembersListById)}
    </div>
  );
};

AssignedUsersBase.propTypes = exact({
  isTeamMembersMenuOpen: PropTypes.bool,
  setIsTeamMembersMenuOpen: PropTypes.func,
  teamMembersList: PropTypes.object,
  teamMembersUserAssignedTo: PropTypes.object,
  setAssignedTo: PropTypes.func,
  componentLocation: PropTypes.string,
});

export const AssignedUsers = React.memo(AssignedUsersBase);
AssignedUsers.displayName = 'AssignedUsers';

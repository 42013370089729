import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import isEmpty from 'lodash/isEmpty';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import { SwingleChecks } from '../../SwingleChecks/SwingleChecks';
import styles from './AssessmentSwingleChecks.scss';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { useTranslation } from '../../../Core/hooks/useTranslation';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';

const AssessmentSwingleChecksBase = ({
  swingleChecksGroupedByCategory,
  preSwingleChecksResults,
  postSwingleChecksResults,
  postSwingleCategoryScores,
  preSwingleCategoryScores,
  selectedCatagories,
  allCatagories,
  onSelectedCatagoriesChange,
  isInPreview,
  isSomeDataHidden,
  preAssessment,
  postAssessment,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  noDataIndicator,
  preSwingleNoise,
  postSwingleNoise,
  swingleChecksOrder,
}) => {
  const t = useTranslation();
  return (
    <AssessmentComparisonGraph
      {...{
        isInPreview,
        isSomeDataHidden,
        preAssessment,
        postAssessment,
        onPreAssessmentChange,
        onPostAssessmentChange,
        assessmentOptions,
        preAssessmentTimestamp,
        postAssessmentTimestamp,
      }}
    >
      {noDataIndicator || (
        <div>
          {!isInPreview && (
            <div className={styles.catagories_select}>
              <CheckboxesSelect
                selectedOptions={selectedCatagories}
                allOptions={allCatagories}
                onChange={onSelectedCatagoriesChange}
                inputLabelText={t('catagories_to_show')}
              />
            </div>
          )}
          <div
            className={styles.swingle_checks_container}
            style={{
              marginBottom: isEmpty(swingleChecksGroupedByCategory) ? 0 : 19,
            }}
          >
            <SwingleChecks
              {...{
                swingleChecksGroupedByCategory,
                swingleChecksResults: preSwingleChecksResults,
                postSwingleChecksResults,
                swingleCategoryScores: preSwingleCategoryScores,
                postSwingleCategoryScores,
                swingleNoise: preSwingleNoise,
                postSwingleNoise,
                swingleChecksOrder,
              }}
              classes={{
                checkGraph: styles.check_graph,
                checkContainer: styles.check_container,
              }}
            />
          </div>
        </div>
      )}
    </AssessmentComparisonGraph>
  );
};

AssessmentSwingleChecksBase.defaultProps = {
  noDataIndicator: null,
};

AssessmentSwingleChecksBase.propTypes = exact({
  swingleChecksGroupedByCategory: PropTypes.object.isRequired,
  preSwingleChecksResults: PropTypes.object.isRequired,
  postSwingleChecksResults: PropTypes.object.isRequired,
  postSwingleCategoryScores: PropTypes.object.isRequired,
  preSwingleCategoryScores: PropTypes.object.isRequired,
  commonSwingleChecksGraphCatagories: PropTypes.array,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  selectedCatagories: PropTypes.array.isRequired,
  allCatagories: PropTypes.array,
  onSelectedCatagoriesChange: PropTypes.func,
  isSomeDataHidden: PropTypes.bool.isRequired,
  noDataIndicator: PropTypes.node,
  preSwingleNoise: PropTypes.object,
  postSwingleNoise: PropTypes.object,
  swingleChecksOrder: PropTypes.array,
});

export const AssessmentSwingleChecks = React.memo(AssessmentSwingleChecksBase);
AssessmentSwingleChecks.displayName = 'AssessmentSwingleChecks';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ConfirmGenerateReportDialog_size_17{font-size:17px}.ConfirmGenerateReportDialog_size_10{font-size:10px}.ConfirmGenerateReportDialog_size_11{font-size:11px}.ConfirmGenerateReportDialog_size_13{font-size:13px}.ConfirmGenerateReportDialog_size_15{font-size:15px}.ConfirmGenerateReportDialog_size_14{font-size:14px}.ConfirmGenerateReportDialog_size_16{font-size:16px}.ConfirmGenerateReportDialog_size_12{font-size:12px}.ConfirmGenerateReportDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.ConfirmGenerateReportDialog_header,.ConfirmGenerateReportDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.ConfirmGenerateReportDialog_header_only_font_size{font-size:17px;font-weight:500}.ConfirmGenerateReportDialog_sub_header{font-size:13px;color:#878787}.ConfirmGenerateReportDialog_sub_header_black{font-size:13px;color:black}.ConfirmGenerateReportDialog_sub_header_only_font_size{font-size:13px}.ConfirmGenerateReportDialog_paragraph_only_font_size{font-size:12px}.ConfirmGenerateReportDialog_tab_font_size{font-size:13px}.ConfirmGenerateReportDialog_pop_out_header{font-size:20px}.ConfirmGenerateReportDialog_title{font-size:15px;font-weight:500;color:#484848}.ConfirmGenerateReportDialog_title_black{font-size:15px;font-weight:500;color:black}.ConfirmGenerateReportDialog_title_only_font_size{font-size:15px}.ConfirmGenerateReportDialog_paragraph{font-size:12px;color:#484848}.ConfirmGenerateReportDialog_btn{font-size:14px}.ConfirmGenerateReportDialog_dialog_paragraph{margin-bottom:0px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ConfirmGenerateReportDialog_size_17",
	"size_10": "ConfirmGenerateReportDialog_size_10",
	"size_11": "ConfirmGenerateReportDialog_size_11",
	"size_13": "ConfirmGenerateReportDialog_size_13",
	"size_15": "ConfirmGenerateReportDialog_size_15",
	"size_14": "ConfirmGenerateReportDialog_size_14",
	"size_16": "ConfirmGenerateReportDialog_size_16",
	"size_12": "ConfirmGenerateReportDialog_size_12",
	"extra_header": "ConfirmGenerateReportDialog_extra_header",
	"header": "ConfirmGenerateReportDialog_header",
	"dialog_header": "ConfirmGenerateReportDialog_dialog_header",
	"header_only_font_size": "ConfirmGenerateReportDialog_header_only_font_size",
	"sub_header": "ConfirmGenerateReportDialog_sub_header",
	"sub_header_black": "ConfirmGenerateReportDialog_sub_header_black",
	"sub_header_only_font_size": "ConfirmGenerateReportDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "ConfirmGenerateReportDialog_paragraph_only_font_size",
	"tab_font_size": "ConfirmGenerateReportDialog_tab_font_size",
	"pop_out_header": "ConfirmGenerateReportDialog_pop_out_header",
	"title": "ConfirmGenerateReportDialog_title",
	"title_black": "ConfirmGenerateReportDialog_title_black",
	"title_only_font_size": "ConfirmGenerateReportDialog_title_only_font_size",
	"paragraph": "ConfirmGenerateReportDialog_paragraph",
	"btn": "ConfirmGenerateReportDialog_btn",
	"dialog_paragraph": "ConfirmGenerateReportDialog_dialog_paragraph"
};
import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import styles from './DeactivateAccountDialog.scss';
// import sadCloudImageSrc from '../../../../assets/sad-cloud.png';
import { Loader } from '../../../Core/Components/Loader/Loader';

const DeactivateAccountDialogBase = ({
  isOpen,
  onCancel,
  onConfirm,
  onClose,
  step,
  onDeactivationCommentChange,
  onDeactivationCommentBlur,
  onDeactivationReasonClick,
  deactivationComment,
  deactivationReasons,
  deactivationDate,
  deactivationCommentError,
  deactivationReasonsError,
  userPrice,
  dormantPackageName,
}) => {
  const primaryBtnText =
    (step === 'accountDeactivated' && 'done') ||
    (step === 'doYouWantToDeactivateMyAccount' && 'schedule call') ||
    (step === 'reasonsForDeactivation' && 'nevermind') ||
    (step === 'areYouSure' && 'no, keep my subscription');
  const secondaryBtnText =
    (step === 'doYouWantToDeactivateMyAccount' && 'no, cancel') ||
    (step === 'reasonsForDeactivation' && 'cancel my subscription') ||
    (step === 'areYouSure' && 'yes, cancel now');

  const shouldRenderDialogActions = step !== 'loading';

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        {step !== 'loading' && (
          <div className={styles.close_icon_container}>
            <CloseIcon onClick={onClose} />
          </div>
        )}

        {step === 'doYouWantToDeactivateMyAccount' && (
          <div>
            <div className={styles.deactivate_title}>
              Before you cancel, let’s chat!
            </div>
            <p
              className={styles.main_text}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={styles.deactivate_description_item}>
                Our care team can tailor a plan just for you. Schedule a call
                now to explore better options.
              </div>
            </p>
          </div>
        )}
        {step === 'areYouSure' && (
          <div>
            <div className={styles.deactivate_second_title}>
              Are you sure you want to cancel your subscription?
            </div>
            <p
              className={styles.main_text}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <div className={styles.deactivate_description_item}>
                  <CloseIcon className={styles.close_icon} />
                  <div>
                    Your subscription will move to the {dormantPackageName}{' '}
                    plan.
                  </div>
                </div>
                <div className={styles.deactivate_description_item}>
                  <CloseIcon className={styles.close_icon} />
                  <div>
                    The {dormantPackageName} plan includes no subscription fees.
                    You can still use the assessment and other features. Fees
                    apply only when neurofeedback training is used.
                  </div>
                </div>
                <div className={styles.deactivate_description_item}>
                  <CloseIcon className={styles.close_icon} />
                  <div>
                    Current programs and credit will be canceled at the end of
                    the billing cycle, {deactivationDate}.
                  </div>
                </div>
                <div className={styles.deactivate_description_item}>
                  <CloseIcon className={styles.close_icon} />
                  <div>
                    You will lose your current pricing, which might not be
                    available in the future.
                  </div>
                </div>
              </div>
              <div className={styles.deactivate_message}>
                You’ll be moved to this plan on {deactivationDate}:
              </div>
              <div className={styles.free_benefits_container}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ fontWeight: 500, fontSize: 18 }}>
                    {dormantPackageName}
                  </div>
                  <div style={{ fontSize: 16 }}>$0/year</div>
                </div>
                <div>
                  <div
                    className={styles.deactivate_description_2_items_container}
                  >
                    <div className={styles.deactivate_description_item_check}>
                      <CheckIcon className={styles.check_icon} />
                      <div>Max of 3 active clients</div>
                    </div>
                    <div className={styles.deactivate_description_item_check}>
                      <CheckIcon className={styles.check_icon} />
                      <div>Symptom tracking tools</div>
                    </div>
                  </div>
                  <div
                    className={styles.deactivate_description_2_items_container}
                  >
                    <div className={styles.deactivate_description_item_check}>
                      <CheckIcon className={styles.check_icon} />
                      <div>
                        Neurofeedback training (prorated usage-only fees)*
                      </div>
                    </div>
                    <div className={styles.deactivate_description_item_check}>
                      <CheckIcon className={styles.check_icon} />
                      <div>Comprehensive assessments</div>
                    </div>
                  </div>
                  <div
                    className={styles.deactivate_description_2_items_container}
                  >
                    <div className={styles.deactivate_description_item_check} />
                    <div className={styles.deactivate_description_item_check}>
                      <CloseIcon
                        style={{ marginRight: 10, color: '#B2B2B2' }}
                      />
                      <div>No team member access</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                *A prorated usage fee of ${userPrice} per client per month is
                applicable for clients who exceed 5 minutes of neurofeedback
                training.
              </div>
            </p>
          </div>
        )}
        {step === 'reasonsForDeactivation' && (
          <div className={styles.step2_container}>
            <p
              className={styles.main_text}
              style={{ fontWeight: 500, fontSize: 16 }}
            >
              Can you please tell us why you’d like to cancel?
            </p>
            {deactivationReasonsError && (
              <p className={styles.error_msg}>{deactivationReasonsError}</p>
            )}
            <div className={styles.deactivation_reasons}>
              {Object.keys(deactivationReasons).map(reasonKey => (
                <Chip
                  key={reasonKey}
                  label={reasonKey}
                  classes={{
                    root: deactivationReasons[reasonKey]
                      ? styles.deactivation_reason_chip_root_selected
                      : styles.deactivation_reason_chip_root,
                  }}
                  onClick={() => onDeactivationReasonClick(reasonKey)}
                />
              ))}
            </div>

            {deactivationCommentError && (
              <p className={styles.error_msg}>{deactivationCommentError}</p>
            )}
            <div>
              <FormControl classes={{ root: styles.form_control_root }}>
                <TextField
                  placeholder="Can you please share more? (required)"
                  variant="outlined"
                  value={deactivationComment}
                  onChange={onDeactivationCommentChange}
                  onBlur={onDeactivationCommentBlur}
                  error={!!deactivationCommentError}
                />
              </FormControl>
            </div>
          </div>
        )}
        {step === 'loading' && <Loader />}
        {step === 'accountDeactivated' && (
          <div className={styles.step3_container}>
            <p className={styles.main_text} style={{ fontSize: 18 }}>
              {' '}
              Subscription successfully canceled!
            </p>
            <p>
              Your plan will switch to the {dormantPackageName} plan on{' '}
              {deactivationDate}. Enjoy your current plan's benefits and reduced
              active client fee until then. Once the {dormantPackageName} plan
              goes into effect, no recurring subscription fees will be charged,
              but exceeding 5 minutes of neurofeedback training incurs a
              prorated charge of ${userPrice}/mo per active client.
            </p>
          </div>
        )}
      </DialogContent>
      {shouldRenderDialogActions && (
        <DialogActions
          style={
            step === 'areYouSure'
              ? { flexDirection: 'row-reverse', justifyContent: 'end' }
              : null
          }
        >
          <Button
            onClick={onCancel}
            color="primary"
            variant="contained"
            autoFocus
          >
            <span className={styles.button}>{primaryBtnText}</span>
          </Button>
          {secondaryBtnText && (
            <Button onClick={onConfirm} color="default" variant="text">
              <span className={styles.button}>{secondaryBtnText}</span>
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

DeactivateAccountDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  onDeactivationCommentChange: PropTypes.func.isRequired,
  onDeactivationCommentBlur: PropTypes.func.isRequired,
  onDeactivationReasonClick: PropTypes.func.isRequired,
  deactivationComment: PropTypes.string.isRequired,
  deactivationReasons: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
  deactivationDate: PropTypes.string,
  deactivationCommentError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  deactivationReasonsError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  userPrice: PropTypes.number,
  dormantPackageName: PropTypes.string,
});

export const DeactivateAccountDialog = React.memo(DeactivateAccountDialogBase);

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  NativeSelect,
} from '@material-ui/core';
import styles from './TrackingSchedule.scss';
import { paragraphFontSize } from '../../../cssInJs/constants';
// import { useTranslation } from '../../../Core/hooks/useTranslation';
// import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';

const TrackingScheduleBase = ({
  shouldBeTrackingBeforeFirstSession,
  onShouldBeTrackingBeforeFirstSessionChange,
  shouldBeTrackingPerMinutes,
  onShouldBeTrackingPerMinutesChange,
  perMinutesTracking,
  onPerMinutesTrackingChange,
  intervalType,
  onIntervalTypeChange,
  onIsAssessmentOpenChange,
  nfFlag,
}) => {
  // const t = useTranslation();
  return (
    <div>
      {/* {!shouldBeTrackingBeforeFirstSession && !shouldBeTrackingPerMinutes && (
        <div className={styles.alert_wrapper}>
          <CustomAlert severity="warning">
            {t('user-will-not-be-prompted-to-do-assessment')}
          </CustomAlert>
        </div>
      )} */}
      {/* <p>{t('track-schedule-description')}</p> */}

      <div className={styles.check_boxes}>
        <div className={styles.recurring_container}>
          <NativeSelect
            classes={{
              icon: styles.mu_native_select_icon,
              root: styles.mu_select_menu,
            }}
            // IconComponent={ArrowDropDownIcon}
            value={shouldBeTrackingPerMinutes}
            onChange={onShouldBeTrackingPerMinutesChange}
          >
            <option value="false">Once</option>
            <option value="true">Recurring</option>
          </NativeSelect>
          {shouldBeTrackingPerMinutes && (
            <div className={styles.interval_container}>
              <p className={styles.every}>
                <span>every</span>
              </p>
              <div className={styles.text_field}>
                <TextField
                  id="standard-basic"
                  margin="normal"
                  onChange={onPerMinutesTrackingChange}
                  value={perMinutesTracking}
                  inputProps={{
                    min: 1,
                    type: 'number',
                    style: {
                      fontSize: paragraphFontSize,
                      width: 60,
                    },
                  }}
                  disabled={!shouldBeTrackingPerMinutes}
                />
              </div>
              <NativeSelect
                classes={{
                  icon: styles.mu_native_select_icon,
                  root: styles.mu_select_menu,
                }}
                // IconComponent={ArrowDropDownIcon}
                value={intervalType}
                onChange={onIntervalTypeChange}
              >
                <option value="days">days</option>
                <option value="weeks">weeks</option>
                <option value="months">months</option>
                {nfFlag ? (
                  <option value="minutes">minutes of neurofeedback</option>
                ) : null}
                {nfFlag ? (
                  <option value="sessions">sessions of neurofeedback</option>
                ) : null}
              </NativeSelect>
            </div>
          )}
          {(intervalType === 'days' ||
            intervalType === 'weeks' ||
            intervalType === 'months') && (
            <p className={styles.after_select_text}>
              starting on next app access
            </p>
          )}
        </div>
        {(intervalType === 'minutes' || intervalType === 'sessions') &&
          JSON.parse(shouldBeTrackingPerMinutes) && (
            <FormControlLabel
              classes={{
                label: styles.check_box_label,
                root: styles.check_box_root,
              }}
              control={
                <Checkbox
                  checked={shouldBeTrackingBeforeFirstSession}
                  onChange={e => {
                    onShouldBeTrackingBeforeFirstSessionChange(e);
                    onIsAssessmentOpenChange(e);
                  }}
                  color="primary"
                />
              }
              label="Starting on next app access"
            />
          )}
        {/* <FormControlLabel
          classes={{ label: styles.check_box_label }}
          control={
            <Checkbox
              checked={shouldBeTrackingPerMinutes}
              onChange={onShouldBeTrackingPerMinutesChange}
              color="primary"
            />
          }
          label={
            <div className={styles.input_container}>
              <p>
                <span>{t('every')}</span>
              </p>
              <TextField
                id="standard-basic"
                margin="normal"
                onChange={onPerMinutesTrackingChange}
                value={perMinutesTracking}
                inputProps={{
                  min: 1,
                  type: 'number',
                  style: {
                    fontSize: paragraphFontSize,
                    width: 60,
                  },
                }}
                disabled={!shouldBeTrackingPerMinutes}
              />

              <p>
                <span> {t('minutes_of_training')}</span>
              </p>
            </div>
          }
        /> */}
      </div>
    </div>
  );
};

TrackingScheduleBase.propTypes = exact({
  shouldBeTrackingBeforeFirstSession: PropTypes.bool.isRequired,
  onShouldBeTrackingBeforeFirstSessionChange: PropTypes.func.isRequired,
  shouldBeTrackingPerMinutes: PropTypes.bool.isRequired,
  onShouldBeTrackingPerMinutesChange: PropTypes.func.isRequired,
  perMinutesTracking: PropTypes.number.isRequired,
  onPerMinutesTrackingChange: PropTypes.func.isRequired,
  intervalType: PropTypes.string.isRequired,
  onIntervalTypeChange: PropTypes.func.isRequired,
  onIsAssessmentOpenChange: PropTypes.func.isRequired,
  nfFlag: PropTypes.bool,
});

export const TrackingSchedule = React.memo(TrackingScheduleBase);
TrackingSchedule.displayName = 'TrackingSchedule';

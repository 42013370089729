exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoVariants_add_filter_btn{cursor:pointer;color:#2296f3;margin-top:10px}.LagoVariants_variant_tags_container{position:absolute;border:1px solid black;padding:5px;overflow:scroll;height:400px;margin-top:15px;min-width:250px}.LagoVariants_dialogPaper{min-height:80vh}.LagoVariants_variant_tag_item_container{cursor:pointer;margin:10px;font-size:1.1rem}.LagoVariants_variant_tag_item_container:hover{font-weight:bold}.LagoVariants_variant_select_tag_title{font-weight:bold;margin:10px;margin-bottom:15px}.LagoVariants_variant_filter_item_container{display:flex;align-items:center}.LagoVariants_variant_operators_container{border:1px black solid;position:absolute;top:75px;left:300px;width:300px}\n", ""]);

// Exports
exports.locals = {
	"add_filter_btn": "LagoVariants_add_filter_btn",
	"variant_tags_container": "LagoVariants_variant_tags_container",
	"dialogPaper": "LagoVariants_dialogPaper",
	"variant_tag_item_container": "LagoVariants_variant_tag_item_container",
	"variant_select_tag_title": "LagoVariants_variant_select_tag_title",
	"variant_filter_item_container": "LagoVariants_variant_filter_item_container",
	"variant_operators_container": "LagoVariants_variant_operators_container"
};
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
import styles from './CustomerStoriesPagesStyles.scss';

export const bestAlphaPeakStorySequences = (
  bestDateFormatted,
  best_alpha_peak_value,
  best_alpha_peak_mental_tags
) => {
  const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  const backgroundAnimationDelay = 0;
  const backgroundAnimationDuration = 1700;

  const seq1WordsLength = 'We all have good and bad days.'.split(' ').length;
  const seq2WordsLength = 'What does your Alpha Peak say?'.split(' ').length;
  const seq3WordsLength = 'You were on top of the game on'.split(' ').length;
  const seq4WordsLength = `${bestDateFormatted}.`.split(' ').length;
  const seq5WordsLength = `${best_alpha_peak_value}Hz`.split(' ').length;
  const seq6WordsLength = 'Highest Alpha Peak'.split(' ').length;

  let seq7WordsLength = 0;
  let seq8WordsLength = 0;
  if (best_alpha_peak_mental_tags) {
    seq7WordsLength = 'On this day: '.split(' ').length;
    seq8WordsLength = best_alpha_peak_mental_tags.split(' ').length;
  }

  const seq1Variants = {
    initial: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: (2 * textAnimationDuration) / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq2Variants = {
    initial: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      x: 20,
      transition: {
        duration: textAnimationDuration / 2 / 1000,
        ease: 'easeInOut',
      },
    },
  };

  let seq3Variants;
  let seq4Variants;
  let seq6Variants;
  seq3Variants = seq4Variants = seq6Variants = {
    initial: { opacity: 0 }, // Starts visible with no change
    enter: {
      opacity: 1,
      transition: {
        // duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const extraAnimationDelay =
    seq1EnterTime +
    backgroundAnimationDelay +
    backgroundAnimationDuration +
    2 * textAnimationDuration;

  const extraAnimationDuration = 3 * textAnimationDuration;

  const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  // const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  // const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  const seq4EnterTime = seq4WordsLength * textAnimationDuration;
  // const seq4ExitTime = seq4WordsLength * textAnimationDuration;
  // const seq4TotalTime = seq4EnterTime + seq4ExitTime;

  const extraAnimationDelay2 =
    seq1TotalTime +
    seq2TotalTime +
    seq3EnterTime +
    seq4EnterTime +
    backgroundAnimationDuration +
    10 * textAnimationDuration;

  const extraAnimationDuration2 = 3 * textAnimationDuration;

  const seq5EnterTime = seq5WordsLength * textAnimationDuration;
  // const seq5ExitTime = seq5WordsLength * textAnimationDuration;
  // const seq5TotalTime = seq5EnterTime + seq5ExitTime;

  const seq6EnterTime = seq6WordsLength * textAnimationDuration;
  // const seq6ExitTime = seq6WordsLength * textAnimationDuration;
  // const seq6TotalTime = seq6EnterTime + seq6ExitTime;

  // const seq7EnterTime = seq7WordsLength * textAnimationDuration;
  // const seq7ExitTime = seq7WordsLength * textAnimationDuration;
  // const seq7TotalTime = seq7EnterTime + seq7ExitTime;

  // const seq8EnterTime = seq8WordsLength * textAnimationDuration;
  // const seq8ExitTime = seq8WordsLength * textAnimationDuration;
  // const seq8TotalTime = seq8EnterTime + seq8ExitTime;

  const seq2Words = 'What does your Alpha Peak say?'
    .split(' ')
    .map((word, idx) => {
      return {
        id: `seq3-word${idx + 1}`,
        text: word,
        enterDelay:
          extraAnimationDelay +
          extraAnimationDuration +
          textAnimationDuration * (idx + 1) -
          2 * textAnimationDuration,
        exitDelay:
          seq1EnterTime +
          seq2EnterTime +
          backgroundAnimationDuration +
          textAnimationDuration * reverseDelay(idx, seq2WordsLength) -
          15 * textAnimationDuration,
        variants: seq2Variants,
        style: {
          margin: '7px 5px',
          color: idx === 3 || idx === 4 ? '#CA93D2' : 'white',
        },
      };
    });

  const seq1 = {
    id: 'seq1',
    // words: seq1Words,
    sentence: 'We all have good and bad days.',
    // wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay: backgroundAnimationDuration - 3 * textAnimationDuration,
    exitDelay:
      seq1EnterTime +
      seq2EnterTime +
      backgroundAnimationDuration +
      5 * textAnimationDuration,
    style: {},
    className: styles.bestAlphaPeak_seq_1,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        x: -20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.1,
          // staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [
      {
        keyframes: { y: -140 },
        delay: extraAnimationDelay,
        transition: {
          duration: extraAnimationDuration, // Duration in seconds
          ease: 'easeInOut', // Optional easing function
        },
      },
    ],
    staggerChildren: true,
  };

  const seq2 = {
    id: 'seq2',
    words: seq2Words,
    // sentence: 'What does your Alpha Peak say?',
    // wordsClassName: '',
    // wordsStyles: { margin: '7px 5px', color: '#ca93d2' },
    // wordsVariants: seq2Variants,
    enterDelay:
      extraAnimationDelay + extraAnimationDuration - 2 * textAnimationDuration,
    exitDelay:
      seq1EnterTime +
      seq2TotalTime +
      backgroundAnimationDuration -
      5 * textAnimationDuration,
    style: {},
    className: styles.bestAlphaPeak_seq_2,
    variants: {
      initial: { opacity: 1, display: 'flex', y: -160 }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          // staggerChildren: 0.2,
          // when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        x: 20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          // staggerChildren: 0.1,
          // staggerDirection: -1,
          // when: 'afterChildren',
        },
      },
    },
    extraAnimations: [],
    // staggerChildren: true,
  };

  const seq3 = {
    id: 'seq3',
    // words: seq3Words,
    sentence: 'You were on top of the game on',
    wordsVariants: seq3Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '10px 5px' },
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      backgroundAnimationDuration +
      6 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.bestAlphaPeak_seq_3,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [
      {
        keyframes: { y: best_alpha_peak_mental_tags ? -150 : -100 },
        delay: extraAnimationDelay2,
        transition: {
          duration: extraAnimationDuration2, // Duration in seconds
          ease: 'easeInOut', // Optional easing function
        },
      },
    ],
    staggerChildren: true,
  };

  const seq4 = {
    id: 'seq4',
    // words: seq4Words,
    sentence: `${bestDateFormatted}.`,
    wordsVariants: seq4Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '10px 5px' },
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      backgroundAnimationDuration +
      6 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.bestAlphaPeak_seq_4,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [
      {
        keyframes: { y: best_alpha_peak_mental_tags ? -150 : -100 },
        delay: extraAnimationDelay2,
        transition: {
          duration: extraAnimationDuration2, // Duration in seconds
          ease: 'easeInOut', // Optional easing function
        },
      },
    ],
    staggerChildren: true,
  };

  const seq5 = {
    id: 'seq5',
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      seq4EnterTime +
      extraAnimationDelay2 +
      extraAnimationDuration2 +
      backgroundAnimationDuration -
      47 * textAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq5-word${1}`,
        text: `${best_alpha_peak_value}Hz`,
        enterDelay:
          seq1TotalTime +
          seq2TotalTime +
          seq3EnterTime +
          seq4EnterTime +
          extraAnimationDelay2 +
          extraAnimationDuration2 +
          backgroundAnimationDuration -
          47 * textAnimationDuration,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            y: best_alpha_peak_mental_tags ? -50 : 0,
            // transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            transition: {
              duration: textAnimationDuration / 1000,
              // ease: 'easeInOut',
              staggerChildren: 0.3, // Stagger by 0.1 seconds between each child
            },
          },
          // exit: {},
        },
        // style: {},
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 100,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              // ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
      },
    ],
    // style: {},
    className: styles.bestAlphaPeak_seq_5,
    variants: {
      initial: {
        opacity: 0,
        y: -100,
      },
      enter: {
        opacity: 1,
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
        },
      },
    },
    extraAnimations: [],
  };

  const seq6 = {
    id: 'seq6',
    // words: seq6Words,
    sentence: 'Highest Alpha Peak',
    wordsVariants: seq4Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      seq4EnterTime +
      seq5EnterTime +
      extraAnimationDelay2 +
      extraAnimationDuration2 +
      backgroundAnimationDuration -
      40 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.bestAlphaPeak_seq_6,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
        y: best_alpha_peak_mental_tags ? -150 : -100,
      },
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq7 = {
    id: 'seq7',
    // words: seq7Words,
    sentence: 'On this day: ',
    wordsVariants: seq4Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      seq4EnterTime +
      seq5EnterTime +
      seq6EnterTime +
      extraAnimationDelay2 +
      extraAnimationDuration2 +
      backgroundAnimationDuration -
      35 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.bestAlphaPeak_seq_7,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
        y: -150,
      },
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq8 = {
    id: 'seq8',
    // words: seq8Words,
    sentence: best_alpha_peak_mental_tags,
    wordsVariants: seq4Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      seq4EnterTime +
      seq5EnterTime +
      seq6EnterTime +
      seq6EnterTime +
      extraAnimationDelay2 +
      extraAnimationDuration2 +
      backgroundAnimationDuration -
      35 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.bestAlphaPeak_seq_8,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
        y: -150,
      },
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  return [
    seq1,
    seq2,
    seq3,
    seq4,
    seq5,
    seq6,
    ...(best_alpha_peak_mental_tags ? [seq7] : []),
    ...(best_alpha_peak_mental_tags ? [seq8] : []),
  ];
};

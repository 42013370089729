import { useEffect, useState } from 'react';

export const usePreviousValue = (value, { shouldUpdate = true } = {}) => {
  const [values, setValues] = useState([value, value]);
  useEffect(() => {
    if (shouldUpdate) {
      setValues(currentValues => [currentValues[1], value]);
    }
  }, [shouldUpdate, value]);
  return values[0];
};

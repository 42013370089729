import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  FormControl,
  RootRef,
  withStyles,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  MenuItem,
  Input,
  FormHelperText,
  Switch,
} from '@material-ui/core';
import { subHeaderFontSize } from '../../../cssInJs/constants';

const stylesFn = () => ({
  formControl: {
    minWidth: 100,
    '& legend': {
      borderBottom: 0,
    },
  },
  inputLabelRoot: {
    fontSize: subHeaderFontSize,
  },
  selectRoot: {
    fontSize: subHeaderFontSize,
  },
  listItemRoot: {
    fontSize: subHeaderFontSize,
  },
  outlinedInput: {
    maxWidth: 245,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const CheckboxesSelectBase = ({
  classes,
  inputLabelText,
  selectedOptions,
  allOptions,
  onChange,
  inputType,
  fullWidth,
  error,
  helperText,
  renderValue,
  disabled,
  displayEmpty,
  selectTitle,
  extraToggleText,
  onExtraToggleChange,
  extraToggleValue,
  isCheckboxesDisabled,
}) => {
  const inputLabelRootRef = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabelRootRef.current.offsetWidth);
  }, []);

  const allOptionsUnifiedStructure =
    typeof allOptions[0] === 'object'
      ? allOptions
      : allOptions.map(option => ({
          label: option,
          value: option,
        }));

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      fullWidth={fullWidth}
      error={error}
    >
      <RootRef rootRef={inputLabelRootRef}>
        <InputLabel
          htmlFor={`select-${inputLabelText}`}
          classes={{
            root: classes.inputLabelRoot,
            shrink: classes.input_label_shrinked,
          }}
        >
          {inputLabelText}
        </InputLabel>
      </RootRef>
      <Select
        displayEmpty={displayEmpty}
        multiple
        renderValue={renderValue}
        value={selectedOptions}
        onChange={onChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        fullWidth={fullWidth}
        input={
          inputType === 'outlinedInput' ? (
            <OutlinedInput
              id={`select-${inputLabelText}`}
              labelWidth={labelWidth}
              classes={{ root: classes.outlinedInput }}
            />
          ) : (
            <Input
              id={`select-${inputLabelText}`}
              classes={{ root: classes.input }}
            />
          )
        }
        classes={{
          root: classes.selectRoot,
        }}
        autoWidth
        disabled={disabled}
      >
        {selectTitle ? (
          <MenuItem disabled style={{ opacity: 1, backgroundColor: 'white' }}>
            <ListItemText
              primary={selectTitle}
              classes={{
                root: classes.listItemRoot,
                primary: classes.listItemRoot,
              }}
            />
          </MenuItem>
        ) : null}
        {allOptionsUnifiedStructure.map(option => (
          <MenuItem
            key={option.value}
            value={!isCheckboxesDisabled && option.value}
            disabled={isCheckboxesDisabled}
          >
            <Checkbox
              checked={
                !isCheckboxesDisabled &&
                selectedOptions.indexOf(option.value) > -1
              }
              color="primary"
            />
            <ListItemText
              primary={option.label}
              classes={{
                root: classes.listItemRoot,
                primary: classes.listItemRoot,
              }}
            />
          </MenuItem>
        ))}
        {extraToggleText ? (
          <MenuItem>
            <span style={{ fontSize: 13 }}>{extraToggleText}</span>
            <Switch
              onChange={onExtraToggleChange}
              color="primary"
              checked={extraToggleValue}
            />
          </MenuItem>
        ) : null}
      </Select>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CheckboxesSelectBase.defaultProps = {
  inputType: 'outlinedInput',
  fullWidth: false,
  helperText: null,
  error: false,
  renderValue: values => values.join(','),
  disabled: false,
};

CheckboxesSelectBase.propTypes = exact({
  classes: PropTypes.object.isRequired,
  inputLabelText: PropTypes.string,
  selectedOptions: PropTypes.array.isRequired,
  allOptions: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.oneOf(['input', 'outlinedInput']),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  renderValue: PropTypes.func,
  displayEmpty: PropTypes.bool,
  selectTitle: PropTypes.string,
  extraToggleText: PropTypes.node,
  onExtraToggleChange: PropTypes.func,
  extraToggleValue: PropTypes.bool,
  isCheckboxesDisabled: PropTypes.bool,
});

export const CheckboxesSelect = React.memo(
  withStyles(stylesFn)(CheckboxesSelectBase)
);
CheckboxesSelect.displayName = 'CheckboxesSelect';

exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SymptomTrackerTable_size_17{font-size:17px}.SymptomTrackerTable_size_10{font-size:10px}.SymptomTrackerTable_size_11{font-size:11px}.SymptomTrackerTable_size_13{font-size:13px}.SymptomTrackerTable_size_15{font-size:15px}.SymptomTrackerTable_size_14{font-size:14px}.SymptomTrackerTable_size_16{font-size:16px}.SymptomTrackerTable_size_12{font-size:12px}.SymptomTrackerTable_extra_header{font-size:18px;font-weight:500;color:#000000}.SymptomTrackerTable_header{font-size:17px;font-weight:500;color:#000000}.SymptomTrackerTable_header_only_font_size{font-size:17px;font-weight:500}.SymptomTrackerTable_sub_header{font-size:13px;color:#878787}.SymptomTrackerTable_sub_header_black{font-size:13px;color:black}.SymptomTrackerTable_sub_header_only_font_size{font-size:13px}.SymptomTrackerTable_paragraph_only_font_size,.SymptomTrackerTable_root th,.SymptomTrackerTable_root td{font-size:12px}.SymptomTrackerTable_tab_font_size{font-size:13px}.SymptomTrackerTable_pop_out_header{font-size:20px}.SymptomTrackerTable_title{font-size:15px;font-weight:500;color:#484848}.SymptomTrackerTable_title_black{font-size:15px;font-weight:500;color:black}.SymptomTrackerTable_title_only_font_size{font-size:15px}.SymptomTrackerTable_paragraph{font-size:12px;color:#484848}.SymptomTrackerTable_btn{font-size:14px}.SymptomTrackerTable_root{overflow:auto}@media only screen and (max-width: 768px){.SymptomTrackerTable_root th,.SymptomTrackerTable_root td{padding:4px 24px}}@media only screen and (max-width: 480px){.SymptomTrackerTable_root th,.SymptomTrackerTable_root td{padding:4px 12px}}\n", ""]);

// Exports
exports.locals = {
	"size_17": "SymptomTrackerTable_size_17",
	"size_10": "SymptomTrackerTable_size_10",
	"size_11": "SymptomTrackerTable_size_11",
	"size_13": "SymptomTrackerTable_size_13",
	"size_15": "SymptomTrackerTable_size_15",
	"size_14": "SymptomTrackerTable_size_14",
	"size_16": "SymptomTrackerTable_size_16",
	"size_12": "SymptomTrackerTable_size_12",
	"extra_header": "SymptomTrackerTable_extra_header",
	"header": "SymptomTrackerTable_header",
	"header_only_font_size": "SymptomTrackerTable_header_only_font_size",
	"sub_header": "SymptomTrackerTable_sub_header",
	"sub_header_black": "SymptomTrackerTable_sub_header_black",
	"sub_header_only_font_size": "SymptomTrackerTable_sub_header_only_font_size",
	"paragraph_only_font_size": "SymptomTrackerTable_paragraph_only_font_size",
	"root": "SymptomTrackerTable_root",
	"tab_font_size": "SymptomTrackerTable_tab_font_size",
	"pop_out_header": "SymptomTrackerTable_pop_out_header",
	"title": "SymptomTrackerTable_title",
	"title_black": "SymptomTrackerTable_title_black",
	"title_only_font_size": "SymptomTrackerTable_title_only_font_size",
	"paragraph": "SymptomTrackerTable_paragraph",
	"btn": "SymptomTrackerTable_btn"
};
import mapValues from 'lodash/mapValues';
import zipObject from 'lodash/zipObject';
import set from 'lodash/set';
import pick from 'lodash/pick';
import flatten from 'flat';
import invert from 'lodash/invert';
import { CHANNEL_IDS } from '../channels/channels';
import { getFirebaseData } from '../firebase/firebase';

const frequencyNamesToNamesWithRanges = {
  Delta: 'Delta',
  Theta: 'Theta',
  'Slow Waves': 'Slow Waves',
  'Low Alpha': 'Low Alpha',
  Alpha: 'Alpha',
  'High Alpha': 'High Alpha',
  'Low Beta': 'Low Beta',
  Beta: 'Beta',
  'High Beta': 'High Beta',
  // SMR: 'SMR',
};

export const sortedFrequencies = [
  'Delta',
  'Theta',
  'Slow Waves',
  'Low Alpha',
  'High Alpha',
  'Alpha',
  'Low Beta',
  'Beta',
  'High Beta',
  // 'SMR',
];

const sortedFrequencyNamesWithRanges = sortedFrequencies.map(
  frequencyName => frequencyNamesToNamesWithRanges[frequencyName]
);

const frequenciesToImagesNamesInStorage = {
  Delta: 'QEEG_delta',
  Theta: 'QEEG_theta',
  'Slow Waves': 'QEEG_slow',
  'Low Alpha': 'QEEG_low_alpha',
  Alpha: 'QEEG_alpha',
  'High Alpha': 'QEEG_high_alpha',
  'Low Beta': 'QEEG_low_beta',
  Beta: 'QEEG_beta',
  'High Beta': 'QEEG_high_beta',
  // SMR: 'QEEG_smr',
};

export const frequenciesToImagesFullNames = {
  Delta: 'Delta waves (1 - 3 Hz)',
  Theta: 'Theta waves (4 - 7 Hz)',
  'Slow Waves': 'Slow waves (1 - 8 Hz)',
  'Low Alpha': 'Low Alpha waves (8 - 9 Hz)',
  'High Alpha': 'High Alpha waves (11 - 12 Hz)',
  Alpha: 'Alpha waves (8 - 12 Hz)',
  'Low Beta': 'Low Beta waves (12 - 15 Hz)',
  Beta: 'Beta waves (16 - 20 Hz)',
  'High Beta': 'High Beta waves (21 - 30 Hz)',
  // SMR: 'QEEG_smr',
};

const frequenciesToImagesNamesInStorageOld = {
  [frequencyNamesToNamesWithRanges.Alpha]: 'alpha_amplitude(8-12)',
  [frequencyNamesToNamesWithRanges['High Alpha']]:
    'fast_alpha_amplitude(11-12)',
  [frequencyNamesToNamesWithRanges['Low Alpha']]: 'slow_alpha_amplitude(8-9)',
  [frequencyNamesToNamesWithRanges.Beta]: 'beta_amplitude(16-24)',
  [frequencyNamesToNamesWithRanges['Low Beta']]: 'low_beta_amplitude(12-15)',
  // [frequencyNamesToNamesWithRanges.Gamma]: 'gamma_amplitude(31-40)',
  [frequencyNamesToNamesWithRanges['High Beta']]: 'hi_beta_amplitude(25-30)',
  [frequencyNamesToNamesWithRanges.Theta]: 'theta_amplitude(3-7)',
  [frequencyNamesToNamesWithRanges.Delta]: 'delta_amplitude(1-3)',
};

const frequencyKeysToFrequencyNames = mapValues(
  invert(frequenciesToImagesNamesInStorageOld),
  frequencyName => frequencyName.split('(')[0].trim()
);

export const getFrequenciesToImagesFullNames = async () => {
  const frequenciesMap = await database
    .ref('assessments/config/processing_v2/frequencies')
    .once('value')
    .then(snapshot => snapshot.val());

  const frequenciesToImagesFullNamesFromFB = {};
  frequenciesMap.forEach(frequencie => {
    frequenciesToImagesFullNamesFromFB[
      frequencie.display_name
    ] = `${frequencie.display_name} (${frequencie.f_min} - ${frequencie.f_max} Hz)`;
  });
  return frequenciesToImagesFullNamesFromFB;
};

export const getFrequencyAssessmentBrainMaps = async ({
  customerId,
  assessmentId,
  frequencyNamesToIds = pick(
    frequenciesToImagesNamesInStorage,
    sortedFrequencyNamesWithRanges
  ),
}) => {
  const storage = window.firebase.storage();
  const imagePath = `eeg_assessments/${customerId}/${assessmentId}`;

  const urls = mapValues(frequencyNamesToIds, async value => {
    try {
      const eyesOpenedUrl = await storage
        .ref(`${imagePath}/${value}_eyesOpened_no_scale.png`)
        .getDownloadURL();
      const eyesClosedUrl = await storage
        .ref(`${imagePath}/${value}_eyesClosed_no_scale.png`)
        .getDownloadURL();

      return [eyesOpenedUrl, eyesClosedUrl];
    } catch (e) {
      const eyesOpenedUrl = await storage
        .ref(`${imagePath}/${value}_eyesOpened.png`)
        .getDownloadURL();
      const eyesClosedUrl = await storage
        .ref(`${imagePath}/${value}_eyesClosed.png`)
        .getDownloadURL();

      return [eyesOpenedUrl, eyesClosedUrl];
    }
  });

  return zipObject(Object.keys(urls), await Promise.all(Object.values(urls)));
};

export const getFrequencyAmplitudesAssessment = async ({
  customerId,
  assessmentId,
}) => {
  const amplitudesDistribution = await database
    .ref(`assessments/results/${customerId}/${assessmentId}/eeg`)
    .once('value')
    .then(snapshot => snapshot.val());

  const flattenedAmplitudesDistribution = flatten(amplitudesDistribution);
  return Object.entries(flattenedAmplitudesDistribution).reduce(
    (acc, [flattenedKey, amplitude]) => {
      const [frequencyKey, state, valueType, channel] = flattenedKey.split('.');
      if (
        [
          CHANNEL_IDS.Cz,
          CHANNEL_IDS.O1,
          CHANNEL_IDS.F3,
          CHANNEL_IDS.F4,
          CHANNEL_IDS.Fz,
          CHANNEL_IDS.Pz,
          CHANNEL_IDS.AF7,
          CHANNEL_IDS.AF8,
          CHANNEL_IDS.Tp9,
          CHANNEL_IDS.Tp10,
        ].indexOf(channel > -1)
      ) {
        const frequencyName = frequencyKeysToFrequencyNames[frequencyKey];
        if (frequencyName && frequencyName !== 'undefined') {
          set(acc, [channel, frequencyName, valueType, state], amplitude);
        }
      }
      return acc;
    },
    {}
  );
};

export const ASSESSMENT_COLORS = {
  NEUTRAL: 'grey',
  POSITIVE: '#69e8c1',
  NEGATIVE: '#ff8383',
  BETWEEN_NEGATIVE_AND_ALMOST_POSITIVE: 'orange',
  ALMOST_POSITIVE: '#EDE066',
};

export const amplitudesPerFrequencyValueTypes = {
  relative: 'relative',
  absolute: 'absolute',
};

export const getAssessmentStagesInfo = async (
  clinicId = sessionStorage.userId,
  { mightUseExtendedStages = true } = {}
) => {
  const hasExtendedStages = mightUseExtendedStages && clinicId;
  const stages = hasExtendedStages
    ? await getFirebaseData('assessments/config/extendedStages')
    : await getFirebaseData('assessments/config/stages');

  const stagesArr = Object.values(stages);
  const ids = stagesArr.reduce((acc, cur) => {
    acc[cur.id.toUpperCase()] = cur.id;
    return acc;
  }, {});
  const eegIds = stagesArr
    .filter(stage => stage.subStages)
    .reduce((acc, cur) => {
      acc[cur.id.toUpperCase()] = cur.id;
      return acc;
    }, {});
  const eegNames = stagesArr
    .filter(stage => stage.subStages)
    .reduce((acc, cur) => {
      // eslint-disable-next-line no-multi-assign
      acc[cur.id] = acc[cur.id] =
        cur.channel === 'O1'
          ? `${cur.channel} Sensing (Not recomended)`
          : `${cur.channel} Sensing`;
      return acc;
    }, {});
  const names = {
    ...stagesArr
      .filter(stage => stage.subStages)
      .reduce((acc, cur) => {
        acc[cur.id] = cur.stageName;
        return acc;
      }, {}),
    ...eegNames,
  };
  return {
    ids,
    names,
    eegIds,
    eegNames,
  };
};

export const getStandardAssessmentStagesInfo = clinicId => {
  return getAssessmentStagesInfo(clinicId, { mightUseExtendedStages: false });
};

export const channelsRelevantToAmplitudesDistrubutionGraph = [
  CHANNEL_IDS.Cz,
  CHANNEL_IDS.O1,
  CHANNEL_IDS.F3,
  CHANNEL_IDS.F4,
  CHANNEL_IDS.Fz,
  CHANNEL_IDS.Pz,
];

export const channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment = [
  CHANNEL_IDS.AF7,
  CHANNEL_IDS.AF8,
  CHANNEL_IDS.Tp9,
  CHANNEL_IDS.Tp10,
];

export const defaultAfterTrainingAssessmentIntervalMinutes = 300;

export const CPTCategories = {
  reactionTime: 'reactionTime',
  omissionErrors: 'omissionErrors',
  commissionErrors: 'commissionErrors',
  responsesSD: 'responsesSD',
  accuracy: 'accuracy',
  overallScore: 'overallScore',
  timingScore: 'timingScore',
};

export const CPTCategoriesFormattedNames = {
  [CPTCategories.reactionTime]: 'Reaction Time',
  [CPTCategories.omissionErrors]: 'Omission Errors',
  [CPTCategories.commissionErrors]: 'Commission Errors',
  [CPTCategories.responsesSD]: 'Reaction Time Variability',
  [CPTCategories.accuracy]: 'Accuracy',
  [CPTCategories.overallScore]: 'Overall Score',
  [CPTCategories.timingScore]: 'Timing score',
};

export const amplitudesStates = {
  preEyesOpened: 'preEyesOpened',
  postEyesOpened: 'postEyesOpened',
  preEyesClosed: 'preEyesClosed',
  postEyesClosed: 'postEyesClosed',
  preUnderTask: 'preUnderTask',
  postUnderTask: 'postUnderTask',
};

export const amplitudesStatesFormattedNames = {
  [amplitudesStates.preEyesOpened]: 'Pre Eyes Open',
  [amplitudesStates.postEyesOpened]: 'Post Eyes Open',
  [amplitudesStates.preEyesClosed]: 'Pre Eyes Closed',
  [amplitudesStates.postEyesClosed]: 'Post Eyes Closed',
  [amplitudesStates.preUnderTask]: 'Pre Under Task',
  [amplitudesStates.postUnderTask]: 'Post Under Task',
};

export const eegMinutesDuration = {
  Central: 2.5,
  Back: 1.5,
  Left: 1,
  Right: 1,
  Frontal: 1,
};

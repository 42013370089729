exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FeedbackBox_size_17{font-size:17px}.FeedbackBox_size_10{font-size:10px}.FeedbackBox_size_11{font-size:11px}.FeedbackBox_size_13{font-size:13px}.FeedbackBox_size_15{font-size:15px}.FeedbackBox_size_14{font-size:14px}.FeedbackBox_size_16{font-size:16px}.FeedbackBox_size_12{font-size:12px}.FeedbackBox_extra_header{font-size:18px;font-weight:500;color:#000000}.FeedbackBox_header{font-size:17px;font-weight:500;color:#000000}.FeedbackBox_header_only_font_size{font-size:17px;font-weight:500}.FeedbackBox_sub_header{font-size:13px;color:#878787}.FeedbackBox_sub_header_black{font-size:13px;color:black}.FeedbackBox_sub_header_only_font_size{font-size:13px}.FeedbackBox_paragraph_only_font_size{font-size:12px}.FeedbackBox_tab_font_size{font-size:13px}.FeedbackBox_pop_out_header{font-size:20px}.FeedbackBox_title,.FeedbackBox_card_header{font-size:15px;font-weight:500;color:#484848}.FeedbackBox_title_black{font-size:15px;font-weight:500;color:black}.FeedbackBox_title_only_font_size{font-size:15px}.FeedbackBox_paragraph,.FeedbackBox_tags .FeedbackBox_tag,.FeedbackBox_label{font-size:12px;color:#484848}.FeedbackBox_btn{font-size:14px}.FeedbackBox_root{padding:20px 20px}.FeedbackBox_card_header{font-weight:500}.FeedbackBox_rating_container{display:flex;align-items:center;margin-bottom:20px}.FeedbackBox_rating_container>p{margin:0;margin-right:20px}.FeedbackBox_tags{display:flex;flex-wrap:wrap}.FeedbackBox_tags .FeedbackBox_tag{margin:0px 10px 10px 0px;background-color:#2196f3;color:white}.FeedbackBox_tags_container{margin-bottom:10px}.FeedbackBox_tags_container>p{margin-bottom:10px}.FeedbackBox_comment_container>p{margin-bottom:10px}.FeedbackBox_label{font-weight:500}.FeedbackBox_mental_tags_container{display:flex;flex-direction:column;margin-bottom:10px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "FeedbackBox_size_17",
	"size_10": "FeedbackBox_size_10",
	"size_11": "FeedbackBox_size_11",
	"size_13": "FeedbackBox_size_13",
	"size_15": "FeedbackBox_size_15",
	"size_14": "FeedbackBox_size_14",
	"size_16": "FeedbackBox_size_16",
	"size_12": "FeedbackBox_size_12",
	"extra_header": "FeedbackBox_extra_header",
	"header": "FeedbackBox_header",
	"header_only_font_size": "FeedbackBox_header_only_font_size",
	"sub_header": "FeedbackBox_sub_header",
	"sub_header_black": "FeedbackBox_sub_header_black",
	"sub_header_only_font_size": "FeedbackBox_sub_header_only_font_size",
	"paragraph_only_font_size": "FeedbackBox_paragraph_only_font_size",
	"tab_font_size": "FeedbackBox_tab_font_size",
	"pop_out_header": "FeedbackBox_pop_out_header",
	"title": "FeedbackBox_title",
	"card_header": "FeedbackBox_card_header",
	"title_black": "FeedbackBox_title_black",
	"title_only_font_size": "FeedbackBox_title_only_font_size",
	"paragraph": "FeedbackBox_paragraph",
	"tags": "FeedbackBox_tags",
	"tag": "FeedbackBox_tag",
	"label": "FeedbackBox_label",
	"btn": "FeedbackBox_btn",
	"root": "FeedbackBox_root",
	"rating_container": "FeedbackBox_rating_container",
	"tags_container": "FeedbackBox_tags_container",
	"comment_container": "FeedbackBox_comment_container",
	"mental_tags_container": "FeedbackBox_mental_tags_container"
};
import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Swal from 'sweetalert2';
import dayJS from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import countryTelData from 'country-telephone-data';
import {
  Checkbox,
  Button,
  TextField,
  CircularProgress,
  // Select,
  // MenuItem,
  // Switch,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ReactCodeInput from 'react-code-input';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Stepper } from '../../StandAloneComponents/MTRSubscriptionPage/Stepper/Stepper';
import { BillingSummary } from './BillingSummary/BillingSummary';
import { NFAddonsDialog } from '../NFAddonsDialog/NFAddonsDialog';
import Logo from '../../../assets/logo-horizontal.png';
import ConfettiGif from '../../../assets/confetti-animation.gif';
import styles from './RegisterNonfClinicRegistration.scss';
import {
  isValidEmail,
  isValidPhone,
  // postToFunctionsWithDefaultData,
  removeAllAfterLastChar,
  getEventLogger,
} from '../../utils/utils';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';

const countriesDataList = cloneDeep(countryTelData).allCountries.map(
  country => {
    const newCountry = cloneDeep(country);
    newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
    newCountry.iso2 = country.iso2;
    return newCountry;
  }
);

const stepperSteps = [
  'Create Account',
  'Build Trial',
  'Start Trial',
  'Confirmation',
];

const stripeElementStyle = {
  base: {
    color: '#585858',
  },
};

const logClinicRegistrationEvent = getEventLogger({
  prefix: 'clinic_register',
});

const RegisterNonfClinicRegistrationBase = ({
  stripe,
  hardware,
  NFPackages,
  hardwareBanner,
  shippingPrice,
  addonGroupId,
}) => {
  const inputEl = useRef(null);
  const cardElementRef = useRef();
  const containerEl = useRef();

  const planInfo = JSON.parse(
    // eslint-disable-next-line no-undef
    localStorage.getItem('planInfo')
  );

  const { packageIndex, groupId, isAnnual, isNf, isNfAdded } = planInfo;

  const [currentStep, setCurrentStep] = useState(stepperSteps[0]);
  const [isStepOneFirstStep, setIsStepOneFirstStep] = useState(true);
  const [isStepTwoFirstStep, setIsStepTwoFirstStep] = useState(true);

  const [packageData, setPackageData] = useState(null);

  // choose plan inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [allowSendMails, setAllowSendMails] = useState(true);

  // choose plan input errors
  const [phoneError, setPhoneError] = useState(false);
  const [phoneLengthError, setPhoneLengthError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailExistError, setEmailExistError] = useState(false);
  const [paswordLengthError, setPaswordLengthError] = useState(false);
  const [paswordMatchError, setPaswordMatchError] = useState(false);

  const [displayFirstStepError, setDisplayFirstStepError] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [validationLoader, setValidationLoader] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isPaymentFormSubmitted, setIsPaymentFormSubmitted] = useState(false);

  // mail validaition
  const [emailValidationCode, setEmailValidationCode] = useState('');
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(false);
  const [invalidResendCode, setInvalidResendCode] = useState(false);

  const [isAnnualToSubmit, setIsAnnualToSubmit] = useState(isAnnual);
  const [isNfAddedToSubmit, setisNfAddedToSubmit] = useState(isNfAdded);

  // cuopon
  const [couponCode, setCouponCode] = useState('');
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);

  const [couponDiscount, setCouponDiscount] = useState(null);
  const [couponCodeError, setCouponCodeError] = useState(null);

  // payment stage inputs
  const [firstNamePayment, setFirstNamePayment] = useState('');
  const [lastNamePayment, setLastNamePayment] = useState('');
  const [country, setCountry] = useState('us');
  const [stateInput, setStateInput] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');
  // payment hardware fields
  const [phoneNumberPayment, setPhoneNumberPayment] = useState('');
  const [fullNamePayment, setFullNamePayment] = useState('');
  const [emailPayment, setEmailPayment] = useState('');
  const [aptPayment, setAptPayment] = useState('');
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [country2, setCountry2] = useState('us');
  const [city2, setCity2] = useState('');
  const [stateInput2, setStateInput2] = useState('');
  const [street2, setStreet2] = useState('');
  const [zipCode2, setZipCode2] = useState('');
  // payment hardware input errors
  const [phonePaymentError, setPhonePaymentError] = useState(false);
  const [phonePaymentLengthError, setPhonePaymentLengthError] = useState(false);
  const [emailPaymentError, setEmailPaymentError] = useState(false);

  // payment stage errors
  const [creditCardError, setCreditCardError] = useState(null);

  const [hardwareItems, setHardwareItems] = useState({});
  const [hardwareItemToShowPopup, setHardwareItemToShowPopup] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);

  const [priceDialogOpen, setPriceDialogOpen] = useState(false);

  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    const registrationData = JSON.parse(
      // eslint-disable-next-line no-undef
      localStorage.getItem('registrationData')
    );
    if (registrationData) {
      setFirstName(registrationData.firstName);
      setLastName(registrationData.lastName);
      setPhoneNumber(registrationData.phoneNumber);
      setClinicName(registrationData.clinicName);
      setEmail(registrationData.email);
      setPassword(registrationData.password);
      setPasswordRepeat(registrationData.passwordRepeat);
      setEmailValidationCode(registrationData.emailValidationCode);
      setStreet(registrationData.street);
      setAptPayment(registrationData.aptPayment);
      setZipCode(registrationData.zipCode);
    }
    const storedHardware = JSON.parse(
      // eslint-disable-next-line no-undef
      localStorage.getItem('hardwareItems')
    );
    if (storedHardware) {
      setHardwareItems(storedHardware);
    } else {
      const hardwareDefault = {};
      Object.keys(hardware).forEach(hardwareId => {
        hardwareDefault[hardwareId] = {};
        hardwareDefault[hardwareId].quantity = 1;
        hardwareDefault[hardwareId].quantityToPurchase = 0;
        hardwareDefault[hardwareId].isSelected = false;
      });
      setHardwareItems(hardwareDefault);
    }
    // eslint-disable-next-line no-undef
    if (localStorage.getItem('currentStep')) {
      // eslint-disable-next-line no-undef
      setCurrentStep(localStorage.getItem('currentStep'));
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('currentStep', currentStep);
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === stepperSteps[2]) {
      const registrationData = JSON.parse(
        // eslint-disable-next-line no-undef
        localStorage.getItem('registrationData')
      );
      if (registrationData) {
        setFirstNamePayment(registrationData.firstName);
        setLastNamePayment(registrationData.lastName);
        if (isNf) {
          setPhoneNumberPayment(registrationData.phoneNumber);
          setEmailPayment(registrationData.email);
          setFullNamePayment(
            `${registrationData.firstName} ${registrationData.lastName}`
          );
        }
      }
    }
  }, [currentStep]);

  useEffect(() => {
    (async () => {
      const data = await database
        .ref(`billing/packages/customNONFPackages/${groupId}`)
        .once('value');
      setPackageData(data.val()[packageIndex]);
    })();
  }, []);

  const arrangeHardware = () => {
    const additionalHardware = {};
    if (isNfAddedToSubmit) {
      Object.keys(hardwareItems).forEach(itemId => {
        if (hardwareItems[itemId].isSelected) {
          additionalHardware[itemId] = {
            quantity: hardwareItems[itemId].quantityToPurchase
              ? // eslint-disable-next-line prefer-template
                hardwareItems[itemId].quantityToPurchase + ''
              : '1',
            sku: hardware[itemId].SKU,
          };
        }
      });
    }
    return additionalHardware;
  };

  const handlePayment = () => {
    stripe.createToken().then(async result => {
      if (result.error) {
        setIsLoading(false);
        setCreditCardError(result.error.message);
      } else {
        let res;
        try {
          const additionalHardware = arrangeHardware();
          const registrationData = JSON.parse(
            // eslint-disable-next-line no-undef
            localStorage.getItem('registrationData')
          );

          const planInfoStorage = JSON.parse(
            // eslint-disable-next-line no-undef
            localStorage.getItem('planInfo')
          );

          res = await fireFunctionPost('clinics-registerWithPayment', {
            nonf: true, // temp
            privateLink: isNf,
            accountDetails: {
              email,
              password,
              firstName,
              lastName,
              clinicName,
              phoneNumber,
            },
            planDetails: {
              packageIndex,
              isAnnual: isAnnualToSubmit,
              includesNFAddOn: isNfAddedToSubmit,
            },
            paymentToken: result.token.id,
            verificationCode: registrationData.emailValidationCode,
            couponCode:
              couponDiscount && couponDiscount.amount ? couponCode : '',
            shippingDetails: {
              City: city,
              Country: country,
              State: stateInput,
              Name: isNfAddedToSubmit
                ? fullNamePayment
                : `${firstNamePayment} ${lastNamePayment}`,
              Email: isNfAddedToSubmit ? emailPayment : email,
              PhoneNumber: phoneNumber,
              StreetAddressLine1: `${street} ${aptPayment}`,
              Zipcode: zipCode,
            },
            billingDetails: {
              City: isSameAddress || isNf ? city : city2,
              Country: isSameAddress || isNf ? country : country2,
              State: isSameAddress || isNf ? stateInput : stateInput2,
              Name: fullNamePayment,
              Email: emailPayment,
              PhoneNumber: phoneNumber,
              StreetAddressLine1: isSameAddress || isNf ? street : street2,
              Zipcode: isSameAddress || isNf ? zipCode : zipCode2,
            },
            additionalHardware: isNfAddedToSubmit ? additionalHardware : null,
            trackingDetails: null, // temp
            sessionId: planInfoStorage.sessionId,
            promoEmails: allowSendMails,
          });
          if (res.result === true) {
            setIsLoading(false);
            const { gtoken, fbtoken, intercomInfo } = res;
            sessionStorage.fbtoken = fbtoken;
            sessionStorage.userId = gtoken;
            sessionStorage.intercomInfo = JSON.stringify(intercomInfo);
            const userInfo = res[gtoken];
            sessionStorage.userInfo = JSON.stringify(userInfo);
            await new Promise(resolve =>
              // eslint-disable-next-line no-undef
              loginToFirebaseWithToken(res, resolve)
            );
            logClinicRegistrationEvent({
              name: 'start_trial_cont_clicked',
              data: {
                session_id: planInfoStorage.sessionId,
                nfGroupId: addonGroupId,
                nfPlanInd: 0,
                include_nf_add_on: isNfAddedToSubmit,
                isNF: isNf,
                isAnnual: isAnnualToSubmit,
                groupId,
                planInd: packageIndex,
                can_send_promo_emails: allowSendMails,
                email,
              },
            });
            setCurrentStep(stepperSteps[3]);
            // eslint-disable-next-line no-undef
            localStorage.setItem('registrationData', null);
            // eslint-disable-next-line no-undef
            localStorage.setItem('hardwareItems', null);
            // eslint-disable-next-line no-undef
            localStorage.setItem('currentStep', stepperSteps[0]);
          } else {
            setRegistrationError(true);
          }
        } catch (e) {
          if (e.message === 'Payment Issue') {
            setCreditCardError(e.message);
            Swal.fire({
              title: 'Error processing credit card',
              text:
                'Unfortunately, we were unable to charge your credit card. Please contact your credit card provider or enter a different card to continue.\nIf the issue persists, please contact support@myndlift.com',
              icon: 'warning',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            });
          }
          setIsLoading(false);
          setRegistrationError(true);
        }
      }
    });
  };

  const clearErrors = () => {
    setPhoneError(false);
    setPhoneLengthError(false);
    setEmailError(false);
    setPaswordLengthError(false);
    setPaswordMatchError(false);
    setInvalidCode(false);
    setEmailExistError(false);
  };

  const paymentInputValidation = () => {
    let answer = true;
    setIsPaymentFormSubmitted(true);
    if (
      !firstNamePayment.trim() ||
      !lastNamePayment.trim() ||
      !city ||
      !street ||
      !zipCode ||
      (!stateInput && country === 'us')
    ) {
      answer = false;
    }
    if (isNf) {
      if (!phoneNumberPayment || !emailPayment || !fullNamePayment) {
        answer = false;
      }
      if (
        !isSameAddress &&
        (!city2 || !street2 || !zipCode2 || (!stateInput && country2 === 'us'))
      ) {
        answer = false;
      }
      if (phoneNumberPayment.length < 5) {
        setPhonePaymentLengthError(true);
        answer = false;
      } else if (!isValidPhone(phoneNumberPayment)) {
        setPhonePaymentError(true);
        answer = false;
      }
      if (!isValidEmail(emailPayment)) {
        setEmailPaymentError(true);
        answer = false;
      }
    }
    return answer;
  };
  // eslint-disable-next-line no-shadow
  // const validateIfEmailIsUsed = async email => {
  //   const doesUserExistRequest = await postToFunctionsWithDefaultData({
  //     path: 'doesUserExist',
  //     data: { email, isClinic: true },
  //   });
  //   return doesUserExistRequest.exists;
  // };

  const choosePlanInputValidation = () => {
    let answer = true;
    setIsFormSubmitted(true);
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !phoneNumber ||
      !clinicName.trim() ||
      !email ||
      !password ||
      !passwordRepeat
    ) {
      answer = false;
    }
    if (phoneNumber.length < 5) {
      setPhoneLengthError(true);
      answer = false;
    } else if (!isValidPhone(phoneNumber)) {
      setPhoneError(true);
      answer = false;
    }
    if (!isValidEmail(email)) {
      setEmailError(true);
      answer = false;
    }
    // else if (await validateIfEmailIsUsed(email)) {
    //   setEmailExistError(true);
    //   answer = false;
    // }
    if (password.length < 6) {
      setPaswordLengthError(true);
      answer = false;
    } else if (password !== passwordRepeat) {
      setPaswordMatchError(true);
      answer = false;
    }
    return answer;
  };

  const onBackClicked = async () => {
    // eslint-disable-next-line no-undef
    const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
    setEmailValidationCode('');
    if (currentStep === stepperSteps[0] && isStepOneFirstStep) {
      await logClinicRegistrationEvent({
        name: 'create_acnt_back_clicked',
        data: {
          session_id: planInfoStorage.sessionId,
          nfGroupId: addonGroupId,
          nfPlanInd: 0,
          include_nf_add_on: isNfAddedToSubmit,
          isNF: isNf,
          isAnnual: isAnnualToSubmit,
          groupId,
          planInd: packageIndex,
          can_send_promo_emails: allowSendMails,
          email,
        },
      });

      window.open(
        `start-trial.html?${isNf ? 'nf=' : ''}${isNf ? true : ''}`,
        '_self'
      );
    } else if (currentStep === stepperSteps[0] && !isStepOneFirstStep) {
      await logClinicRegistrationEvent({
        name: 'create_acnt_back_clicked',
        data: {
          session_id: planInfoStorage.sessionId,
          nfGroupId: addonGroupId,
          nfPlanInd: 0,
          include_nf_add_on: isNfAddedToSubmit,
          isNF: isNf,
          isAnnual: isAnnualToSubmit,
          groupId,
          planInd: packageIndex,
          can_send_promo_emails: allowSendMails,
          email,
        },
      });
      setIsStepOneFirstStep(true);
    }
    if (currentStep === stepperSteps[1]) {
      if (isStepTwoFirstStep) {
        logClinicRegistrationEvent({
          name: 'build_trial_back_clicked',
          data: {
            session_id: planInfoStorage.sessionId,
            nfGroupId: addonGroupId,
            nfPlanInd: 0,
            include_nf_add_on: isNfAddedToSubmit,
            isNF: isNf,
            isAnnual: isAnnualToSubmit,
            groupId,
            planInd: packageIndex,
            can_send_promo_emails: allowSendMails,
            email,
          },
        });
      } else {
        logClinicRegistrationEvent({
          name: 'shop_back_clicked',
          data: {
            session_id: planInfoStorage.sessionId,
            nfGroupId: addonGroupId,
            nfPlanInd: 0,
            include_nf_add_on: isNfAddedToSubmit,
            isNF: isNf,
            isAnnual: isAnnualToSubmit,
            groupId,
            planInd: packageIndex,
            can_send_promo_emails: allowSendMails,
            email,
          },
        });
      }
      if (isNf && isNfAddedToSubmit && !isStepTwoFirstStep) {
        setIsStepTwoFirstStep(true);
      } else {
        setIsStepOneFirstStep(true);
        setCurrentStep(stepperSteps[0]);
      }
    }
    if (currentStep === stepperSteps[2]) {
      logClinicRegistrationEvent({
        name: 'start_trial_back_clicked',
        data: {
          session_id: planInfoStorage.sessionId,
          nfGroupId: addonGroupId,
          nfPlanInd: 0,
          include_nf_add_on: isNfAddedToSubmit,
          isNF: isNf,
          isAnnual: isAnnualToSubmit,
          groupId,
          planInd: packageIndex,
          can_send_promo_emails: allowSendMails,
          email,
        },
      });
      setCurrentStep(stepperSteps[1]);
    }
  };

  const onContinueClicked = async () => {
    // eslint-disable-next-line no-undef
    const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
    if (currentStep === stepperSteps[0] && isStepOneFirstStep) {
      setIsLoading(true);
      clearErrors();
      if (choosePlanInputValidation()) {
        try {
          const registrationData = JSON.parse(
            // eslint-disable-next-line no-undef
            localStorage.getItem('registrationData')
          );
          if (
            registrationData &&
            registrationData.validatedEmail === email &&
            registrationData.emailValidationCode
          ) {
            setIsLoading(false);
            setCurrentStep(stepperSteps[1]);
            // eslint-disable-next-line no-undef
            localStorage.setItem(
              'registrationData',
              JSON.stringify({
                firstName,
                lastName,
                phoneNumber,
                clinicName,
                email,
                password,
                passwordRepeat,
                emailValidationCode: registrationData.emailValidationCode,
                street,
                aptPayment,
                zipCode,
                validatedEmail: email,
              })
            );
            logClinicRegistrationEvent({
              name: 'create_acnt_cont_clicked',
              data: {
                session_id: planInfoStorage.sessionId,
                nfGroupId: addonGroupId,
                nfPlanInd: 0,
                include_nf_add_on: isNfAddedToSubmit,
                isNF: isNf,
                isAnnual: isAnnualToSubmit,
                groupId,
                planInd: packageIndex,
                can_send_promo_emails: allowSendMails,
                email,
              },
            });
            return;
          }
          if (
            !registrationData ||
            !registrationData.validatedEmail ||
            (registrationData && registrationData.validatedEmail !== email)
          ) {
            // eslint-disable-next-line no-undef
            await fireFunctionPost(
              'verifications-sendClinicRegistrationVerificationCode',
              {
                email,
                eventData: {
                  session_id: planInfoStorage.sessionId,
                  nfGroupId: addonGroupId,
                  nfPlanInd: 0,
                  include_nf_add_on: isNfAddedToSubmit,
                  isNF: isNf,
                  isAnnual: isAnnualToSubmit,
                  groupId,
                  planInd: packageIndex,
                  can_send_promo_emails: allowSendMails,
                },
              }
            );
          }

          // eslint-disable-next-line no-undef
          localStorage.setItem(
            'registrationData',
            JSON.stringify({
              firstName,
              lastName,
              phoneNumber,
              clinicName,
              email,
              password,
              passwordRepeat,
              emailValidationCode,
              street,
              aptPayment,
              zipCode,
              validatedEmail: email,
            })
          );
          setIsStepOneFirstStep(false);
          setIsLoading(false);
          logClinicRegistrationEvent({
            name: 'create_acnt_cont_clicked',
            data: {
              session_id: planInfoStorage.sessionId,
              nfGroupId: addonGroupId,
              nfPlanInd: 0,
              include_nf_add_on: isNfAddedToSubmit,
              isNF: isNf,
              isAnnual: isAnnualToSubmit,
              groupId,
              planInd: packageIndex,
              can_send_promo_emails: allowSendMails,
              email,
            },
          });
        } catch (e) {
          if (e.message === 'email already exists') {
            setEmailExistError(true);
          }
          setIsLoading(false);
          setDisplayFirstStepError(true);
        }
      } else {
        setIsLoading(false);
      }
    }
    if (currentStep === stepperSteps[1]) {
      if (isStepTwoFirstStep) {
        logClinicRegistrationEvent({
          name: 'build_trial_cont_clicked',
          data: {
            session_id: planInfoStorage.sessionId,
            nfGroupId: addonGroupId,
            nfPlanInd: 0,
            include_nf_add_on: isNfAddedToSubmit,
            isNF: isNf,
            isAnnual: isAnnualToSubmit,
            groupId,
            planInd: packageIndex,
            can_send_promo_emails: allowSendMails,
            email,
          },
        });
      } else {
        logClinicRegistrationEvent({
          name: 'shop_cont_clicked',
          data: {
            session_id: planInfoStorage.sessionId,
            nfGroupId: addonGroupId,
            nfPlanInd: 0,
            include_nf_add_on: isNfAddedToSubmit,
            isNF: isNf,
            isAnnual: isAnnualToSubmit,
            groupId,
            planInd: packageIndex,
            can_send_promo_emails: allowSendMails,
            email,
          },
        });
      }

      if (isStepTwoFirstStep && isNf && isNfAddedToSubmit) {
        setIsStepTwoFirstStep(false);
      } else {
        setCurrentStep(stepperSteps[2]);
      }
    }
    if (currentStep === stepperSteps[2]) {
      setIsLoading(true);
      if (paymentInputValidation()) {
        handlePayment();
      } else {
        setIsLoading(false);
      }
    }
  };

  const onCreditCardChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardError(e.error.message);
    } else if (e.empty) {
      setCreditCardError('Credit card details are required');
    } else {
      setCreditCardError(null);
    }
  }, []);

  const onCountryChange = event => {
    const newValue = event.target.value;
    setCountry(newValue);
  };

  const onCountryChange2 = event => {
    const newValue = event.target.value;
    setCountry2(newValue);
  };

  const resendValidationCode = async () => {
    if (isResendCodeDisabled) {
      return;
    }
    try {
      // eslint-disable-next-line no-undef
      const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
      // eslint-disable-next-line no-undef
      await fireFunctionPost(
        'verifications-sendClinicRegistrationVerificationCode',
        {
          email,
          eventData: {
            session_id: planInfoStorage.sessionId,
            nfGroupId: addonGroupId,
            nfPlanInd: 0,
            include_nf_add_on: isNfAddedToSubmit,
            isNF: isNf,
            isAnnual: isAnnualToSubmit,
            groupId,
            planInd: packageIndex,
            can_send_promo_emails: allowSendMails,
          },
        }
      );
      setInvalidResendCode(false);
      setIsResendCodeDisabled(true);
      setTimeout(() => {
        setIsResendCodeDisabled(false);
      }, 2000);
    } catch (e) {
      setInvalidResendCode(e.message);
    }
  };

  const onApllyCoupon = async () => {
    const additionalHardware = arrangeHardware();
    // eslint-disable-next-line no-undef
    const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
    setIsLoadingCoupon(true);
    let response;
    try {
      // eslint-disable-next-line no-undef
      response = await fireFunctionPost('shop-clinicRegisterDiscountAmount', {
        nonf: true, // temp
        couponCode,
        planDetails: {
          packageIndex,
          isAnnual: isAnnualToSubmit,
        },
        additionalHardware,
        trackingDetails: { sessionId: planInfoStorage.sessionId },
      });
    } catch (e) {
      setCouponCodeError('Invalid Coupon');
    }

    // const responseData = await response.json();
    setIsLoadingCoupon(false);
    if (
      response &&
      response.result &&
      response.discountedAmount !== undefined &&
      response.discountedAmount > 0
    ) {
      setCouponDiscount({
        type: response.discountType,
        amount: response.discountedAmount,
      });

      // setLastValidCouponCode(couponCode);

      if (!response.result) {
        setCouponCodeError('Invalid Coupon');
      } else {
        setCouponCodeError(null);
        logClinicRegistrationEvent({
          name: 'coupon_added',
          data: {
            session_id: planInfoStorage.sessionId,
            nfGroupId: addonGroupId,
            nfPlanInd: 0,
            include_nf_add_on: isNfAddedToSubmit,
            isNF: isNf,
            isAnnual: isAnnualToSubmit,
            groupId,
            planInd: packageIndex,
            can_send_promo_emails: allowSendMails,
            email,
            from_where: currentStep,
            couponCode,
            couponType: response.discountType,
            discountAmount: response.discountedAmount,
          },
        });
      }
    } else {
      setCouponCodeError(response.message || 'Invalid Coupon');
    }
  };

  const loginToDashboard = async () => {
    // eslint-disable-next-line no-undef
    const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
    await logClinicRegistrationEvent({
      name: 'login_clicked',
      data: {
        session_id: planInfoStorage.sessionId,
        nfGroupId: addonGroupId,
        nfPlanInd: 0,
        include_nf_add_on: isNfAddedToSubmit,
        isNF: isNf,
        isAnnual: isAnnualToSubmit,
        groupId,
        planInd: packageIndex,
        can_send_promo_emails: allowSendMails,
        email,
        clinicId: sessionStorage.userId,
        total_price: totalPrice,
      },
    });

    window.open('/', '_self');
  };

  const onHardwareItemQuantityChanged = (itemId, value) => {
    const copy = { ...hardwareItems };
    if (!copy[itemId]) {
      copy[itemId] = {};
    }
    copy[itemId].quantity = value;
    setHardwareItems(copy);
  };

  const onHardwareItemSelected = itemId => {
    const copy = { ...hardwareItems };
    if (!copy[itemId]) {
      copy[itemId] = {};
    }
    if (copy[itemId].quantity < 1) {
      return;
    }
    copy[itemId].isSelected = true;
    copy[itemId].quantityToPurchase = copy[itemId].quantity;
    setHardwareItemToShowPopup(itemId);
    setTimeout(() => {
      setHardwareItemToShowPopup(null);
    }, 1000);
    setHardwareItems(copy);
    // eslint-disable-next-line no-undef
    localStorage.setItem('hardwareItems', JSON.stringify(copy));
  };

  const onRemoveHardware = itemId => {
    const copy = { ...hardwareItems };
    copy[itemId].isSelected = false;
    setHardwareItems(copy);
  };

  const calcHardwareItems = () => {
    let count = 0;
    Object.keys(hardwareItems).forEach(itemId => {
      if (hardwareItems[itemId].isSelected) {
        count += 1;
      }
    });
    return count;
  };

  const calcDate = () => {
    if (packageData) {
      const timeStamp = Date.now();
      const newTimestamp = timeStamp + 86400000 * packageData.trialDays;
      return dayJS(newTimestamp).format('DD/MM/YYYY');
    }
    return '';
  };

  const calcShippingPrice = () => {
    // eslint-disable-next-line no-undef
    if (countriesJSON[country.toUpperCase()].continent === 'EU') {
      return shippingPrice.EU.price;
    }
    if (country === 'us') {
      return shippingPrice.US.price;
    }
    return shippingPrice.default.price;
  };

  const renderCreateAccount = () => {
    if (isStepOneFirstStep) {
      return (
        <div className={styles.choose_plan_container}>
          <div>
            <div
              className={styles.account_info_container}
              style={{ paddingBottom: 1 }}
            >
              <div className={styles.account_info_title}>
                Account Information
              </div>
              <div className={styles.inputs}>
                <div className={styles.inputs_container}>
                  <TextField
                    label="First name"
                    error={isFormSubmitted && !firstName.trim()}
                    helperText={
                      isFormSubmitted &&
                      !firstName.trim() &&
                      'First Name is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <TextField
                    label="Last name"
                    error={isFormSubmitted && !lastName.trim()}
                    helperText={
                      isFormSubmitted &&
                      !lastName.trim() &&
                      'Last Name is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={lastName}
                    onChange={e => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.inputs_container}>
                  <TextField
                    label="Phone"
                    error={
                      isFormSubmitted &&
                      (!phoneNumber || phoneError || phoneLengthError)
                    }
                    helperText={
                      phoneLengthError
                        ? 'Phone should be at least 5 numbers'
                        : phoneError && 'Invalid phone number'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={phoneNumber}
                    onChange={e => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                  <TextField
                    label="Clinic Name"
                    error={isFormSubmitted && !clinicName.trim()}
                    helperText={
                      isFormSubmitted &&
                      !clinicName.trim() &&
                      'Clinic name is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={clinicName}
                    onChange={e => {
                      setClinicName(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputs_container}>
                  <TextField
                    label="Email"
                    error={
                      isFormSubmitted &&
                      (!email || emailError || emailExistError)
                    }
                    helperText={
                      emailError
                        ? 'Email is not valid'
                        : emailExistError && 'Email already exists'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputs_container}>
                  <TextField
                    label="Password"
                    error={isFormSubmitted && (!password || paswordLengthError)}
                    helperText={
                      paswordLengthError &&
                      'Password should be at least 6 characters'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    inputProps={{
                      type: 'password',
                      autocomplete: 'new-password',
                    }}
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />

                  <TextField
                    label="Repeat Password"
                    error={
                      isFormSubmitted && (!passwordRepeat || paswordMatchError)
                    }
                    helperText={
                      paswordMatchError &&
                      'Confirm password is not matching password'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    inputProps={{
                      type: 'password',
                    }}
                    value={passwordRepeat}
                    onChange={e => {
                      setPasswordRepeat(e.target.value);
                    }}
                  />
                </div>
                <div
                  // className={styles.inputs_container}
                  style={{ alignItems: 'center' }}
                >
                  <Checkbox
                    checked={allowSendMails}
                    onChange={e => {
                      setAllowSendMails(e.target.checked);
                    }}
                    color="primary"
                  />
                  It’s okay to send me emails about Myndlift.
                </div>
              </div>
              {displayFirstStepError ? (
                <div
                  style={{ color: 'red', textAlign: 'center', marginTop: 10 }}
                >
                  Something went wrong
                </div>
              ) : null}
            </div>
          </div>
          {packageData && (
            <BillingSummary
              onContinueClicked={onContinueClicked}
              packageData={packageData}
              isAnnual={isAnnualToSubmit}
              isLoading={isLoading}
              currentStep={currentStep}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              onApllyCoupon={onApllyCoupon}
              isLoadingCoupon={isLoadingCoupon}
              couponCodeError={couponCodeError}
              isNfAdded={isNfAddedToSubmit}
              hardwareItems={hardwareItems}
              hardwareData={hardware}
              onRemoveHardware={onRemoveHardware}
              NFPackages={NFPackages}
              shippingPrice={calcShippingPrice()}
              shouldAddExtraTax={country === 'il'}
            />
          )}
        </div>
      );
    }
    return (
      <div className={styles.mail_varification_container}>
        <div className={styles.mail_varification_title}>
          Check your email for a code
        </div>
        <div className={styles.mail_varification_description}>
          We’ve sent a verification code to {email}
        </div>
        <div className={styles.mail_varification_description}>
          The code expires shortly, so please enter it now:
        </div>
        <div
          style={{
            marginTop: 20,
            width: 400,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReactCodeInput
            type="text"
            fields={6}
            inputStyle={{
              // borderLeft: 'none',
              // borderRight: 'none',
              // borderTop: 'none',
              width: window.innerWidth < 1200 ? 45 : 65,
              height: 80,
              margin: 0,
              // boxShadow: '0px 0px 10px #00000029',
              border: '0.1px solid #E0E0E0',
              // borderRadius: 5,
              // paddingLeft: 5,
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: window.innerWidth < 1200 ? 18 : 36,
              textTransform: 'capitalize',
            }}
            inputStyleInvalid={{
              color: 'red',
              border: '1px solid red',
              width: window.innerWidth < 1200 ? 45 : 65,
              height: 80,
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: window.innerWidth < 1200 ? 18 : 36,
              textTransform: 'capitalize',
            }}
            isValid={!invalidCode}
            value={emailValidationCode}
            onChange={async value => {
              setEmailValidationCode(value.toUpperCase());
              if (value.length === 6) {
                setValidationLoader(true);
                let res;
                try {
                  const planInfoStorage = JSON.parse(
                    // eslint-disable-next-line no-undef
                    localStorage.getItem('planInfo')
                  );
                  // eslint-disable-next-line no-undef
                  res = await fireFunctionPost(
                    'verifications-validateClinicVerificationCode',
                    {
                      email,
                      verificationCode: value.toUpperCase(),
                      eventData: { session_id: planInfoStorage.sessionId },
                    }
                  );
                  if (res) {
                    setCurrentStep(stepperSteps[1]);
                    setValidationLoader(false);
                    // eslint-disable-next-line no-undef
                    localStorage.setItem(
                      'registrationData',
                      JSON.stringify({
                        firstName,
                        lastName,
                        phoneNumber,
                        clinicName,
                        email,
                        password,
                        passwordRepeat,
                        emailValidationCode: value.toUpperCase(),
                        street,
                        aptPayment,
                        zipCode,
                        validatedEmail: email,
                      })
                    );
                  }
                } catch (e) {
                  setInvalidCode(true);
                  setValidationLoader(false);
                }
              }
            }}
            ref={inputEl}
            autoFocus={false}
            filterChars={[
              ' ',
              '~',
              '`',
              '!',
              '@',
              '#',
              '$',
              '%',
              '^',
              '&',
              '*',
              '(',
              ')',
              '_',
              '-',
              '{',
              '}',
              '+',
              '=',
              '[',
              ']',
              '|',
              ':',
              ';',
              '<',
              '>',
              ',',
              '.',
              '?',
              '/',
              '\\',
              "'",
              '"',
            ]}
          />
        </div>
        {validationLoader ? (
          <CircularProgress style={{ marginTop: 20 }} />
        ) : null}
        {invalidCode ? (
          <div style={{ marginTop: 20, color: 'red' }}>
            The code you entered is invalid. Please try again.
          </div>
        ) : null}
        <div className={styles.mail_varification_note}>
          Didn’t get a code?
          <span
            className={styles.mail_varification_resend}
            onClick={resendValidationCode}
            style={
              isResendCodeDisabled ? { cursor: 'auto', color: '#6A6A6A' } : null
            }
          >
            {' '}
            {isResendCodeDisabled ? 'Sent!' : 'Resend'}{' '}
          </span>
        </div>
        {invalidResendCode ? (
          <div style={{ color: 'red' }}>{invalidResendCode}</div>
        ) : null}
      </div>
    );
  };

  const renderBuildTrial = () => {
    if (isNf && !isStepTwoFirstStep) {
      return (
        <div
          className={styles.choose_plan_container}
          style={{ maxWidth: 1200, margin: '50px auto', padding: 0 }}
        >
          <div className={styles.select_hardware_container}>
            <div className={styles.select_hardware_title_container}>
              <div className={styles.select_hardware_title}>
                Select Hardware
              </div>
              <div className={styles.select_hardware_title_label}>
                Myndlift is compatible with Muse 2 & Muse S
              </div>
            </div>
            <div
              style={{ fontWeight: 500 }}
              className={styles.select_hardware_description}
            >
              Since you added Neuro to your plan, you’ll need a Muse headset and
              an electrode.
            </div>
            {hardwareBanner ? (
              <div className={styles.select_hardware_banner}>
                {hardwareBanner}
              </div>
            ) : null}
            <div className={styles.hardware_items_list}>
              {Object.keys(hardware).map(itemId => {
                return (
                  <div key={itemId} className={styles.hardware_item}>
                    <div className={styles.hardware_item_top}>
                      <img
                        src={hardware[itemId].imgPath}
                        className={styles.hardware_item_img}
                      />
                      <div style={{ position: 'relative' }}>
                        <div className={styles.hardware_item_title}>
                          {hardware[itemId].name}
                        </div>
                        <div>${hardware[itemId].price}</div>
                        <div className={styles.hardware_item_quantity}>
                          Quantity
                          <input
                            type="number"
                            min="1"
                            className={styles.hardware_item_quantity_input}
                            value={
                              hardwareItems[itemId] &&
                              hardwareItems[itemId].quantity
                            }
                            onChange={e => {
                              onHardwareItemQuantityChanged(
                                itemId,
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div
                          className={styles.hardware_item_button}
                          onClick={() => {
                            onHardwareItemSelected(itemId);
                          }}
                        >
                          ADD TO CART
                        </div>
                        <div
                          className={styles.add_to_cart_label}
                          style={
                            itemId === hardwareItemToShowPopup
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                        >
                          <CheckIcon
                            style={{
                              color: 'white',
                              width: 14,
                              height: 14,
                              marginRight: 5,
                            }}
                          />
                          Added to cart
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.hardware_item_description}
                      dangerouslySetInnerHTML={{
                        __html: hardware[itemId].description,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {packageData && (
            <BillingSummary
              onContinueClicked={onContinueClicked}
              packageData={packageData}
              isAnnual={isAnnualToSubmit}
              isLoading={isLoading}
              currentStep={currentStep}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              onApllyCoupon={onApllyCoupon}
              isLoadingCoupon={isLoadingCoupon}
              couponCodeError={couponCodeError}
              couponDiscount={couponDiscount}
              isNfAdded={isNfAddedToSubmit}
              hardwareItems={hardwareItems}
              hardwareData={hardware}
              onRemoveHardware={onRemoveHardware}
              isHardwareStore
              NFPackages={NFPackages}
              shippingPrice={calcShippingPrice()}
              shouldAddExtraTax={country === 'il'}
              isFromShop
            />
          )}
        </div>
      );
    }
    return (
      <div className={styles.choose_plan_container}>
        <div>
          <div className={styles.account_info_container}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <div>
                <div className={styles.build_trial_head_container}>
                  <div>
                    <div className={styles.build_trial_title}>
                      {packageData.bundleName}
                    </div>
                    <div
                      className={styles.build_trial_title_desc}
                      style={{ paddingRight: 10 }}
                    >
                      {packageData.planDescription}
                    </div>
                  </div>
                  <div className={styles.build_trial_select_package}>
                    <div
                      onClick={() => {
                        setIsAnnualToSubmit(false);
                        const planInfoStorage = JSON.parse(
                          // eslint-disable-next-line no-undef
                          localStorage.getItem('planInfo')
                        );
                        logClinicRegistrationEvent({
                          name: 'interval_toggled',
                          data: {
                            session_id: planInfoStorage.sessionId,
                            isNF: isNf,
                            isAnnual: false,
                            from_where: 'build_trial',
                          },
                        });
                      }}
                      className={styles.build_trial_select_package_item}
                      style={
                        !isAnnualToSubmit
                          ? { border: '1px solid #0075F3' }
                          : null
                      }
                    >
                      <div
                        className={styles.build_trial_select_package_item_price}
                      >
                        ${packageData.basePrice}/
                        <span
                          className={
                            styles.build_trial_select_package_item_price_label
                          }
                        >
                          mo
                        </span>
                      </div>
                      <div>Billed monthly</div>
                    </div>
                    <div
                      className={styles.build_trial_select_package_item}
                      onClick={() => {
                        setIsAnnualToSubmit(true);
                        const planInfoStorage = JSON.parse(
                          // eslint-disable-next-line no-undef
                          localStorage.getItem('planInfo')
                        );
                        logClinicRegistrationEvent({
                          name: 'interval_toggled',
                          data: {
                            session_id: planInfoStorage.sessionId,
                            isNF: isNf,
                            isAnnual: true,
                            from_where: 'build_trial',
                          },
                        });
                      }}
                      style={
                        isAnnualToSubmit
                          ? { border: '1px solid #0075F3' }
                          : null
                      }
                    >
                      <div
                        className={styles.build_trial_select_package_item_price}
                      >
                        ${packageData.annualMonthlyBasePrice}/
                        <span
                          className={
                            styles.build_trial_select_package_item_price_label
                          }
                        >
                          mo
                        </span>
                      </div>
                      <div>
                        Billed annually
                        <span
                          style={{
                            color: '#4BE0D0',
                            fontSize: 12,
                            fontWeight: 900,
                          }}
                        >
                          {' '}
                          save 16%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.build_trial_features_list}>
                  {packageData.features.map(feature => {
                    return (
                      <TooltipWrapper
                        title={
                          <TooltipTitle>{feature.description}</TooltipTitle>
                        }
                      >
                        <div className={styles.build_trial_feature}>
                          <CheckIcon
                            style={{ color: '#7a87fc', marginRight: 5 }}
                          />
                          {feature.feature}
                        </div>
                      </TooltipWrapper>
                    );
                  })}
                </div>
              </div>
              <div
                className={styles.change_plan_btn}
                onClick={async () => {
                  const planInfoStorage = JSON.parse(
                    // eslint-disable-next-line no-undef
                    localStorage.getItem('planInfo')
                  );
                  planInfoStorage.isAnnual = isAnnualToSubmit;
                  // eslint-disable-next-line no-undef
                  localStorage.setItem(
                    'planInfo',
                    JSON.stringify(planInfoStorage)
                  );

                  await logClinicRegistrationEvent({
                    name: 'change_plan_clicked',
                    data: {
                      session_id: planInfoStorage.sessionId,
                      nfGroupId: addonGroupId,
                      nfPlanInd: 0,
                      include_nf_add_on: isNfAddedToSubmit,
                      isNF: isNf,
                      isAnnual: isAnnualToSubmit,
                      groupId,
                      planInd: packageIndex,
                      can_send_promo_emails: allowSendMails,
                      email,
                    },
                  });
                  window.open(
                    `start-trial.html?${isNf ? 'nf=' : ''}${isNf ? true : ''}`,
                    '_self'
                  );
                }}
              >
                Change Plan
              </div>
            </div>
          </div>
          {isNf ? (
            <div>
              <div className={styles.build_trial_addon_title}>
                Available add-ons to trial:
              </div>
              <div
                className={styles.account_info_container}
                style={
                  window.innerWidth < 1200
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }
                    : { display: 'flex', justifyContent: 'space-between' }
                }
              >
                <div className={styles.account_info_container_left}>
                  <div className={styles.build_trial_addon_inner_title}>
                    Neuro
                  </div>
                  <div className={styles.build_trial_addon_inner_subtitle}>
                    Offer neurofeedback training & brain sensing
                  </div>
                  <div>
                    {NFPackages.features.map(feature => {
                      return (
                        <div className={styles.build_trial_feature}>
                          <CheckIcon
                            style={{ color: '#7a87fc', marginRight: 5 }}
                          />
                          {feature.feature}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.account_info_container_right}>
                  <div className={styles.account_info_container_right_top}>
                    <div className={styles.addon_price}>
                      <span style={{ fontSize: 16, fontWeight: 900 }}>
                        from{' '}
                      </span>
                      <span style={{ fontSize: 22, fontWeight: 900 }}>
                        ${NFPackages.basePrice}/
                      </span>
                      <span style={{ fontSize: 16 }}>mo</span>
                    </div>
                    <div
                      className={styles.account_info_underline_text}
                      onClick={() => {
                        const planInfoStorage = JSON.parse(
                          // eslint-disable-next-line no-undef
                          localStorage.getItem('planInfo')
                        );
                        logClinicRegistrationEvent({
                          name: 'how_price_calculated_clicked',
                          data: {
                            session_id: planInfoStorage.sessionId,
                            from_where: 'build_trial',
                            isNF: true,
                          },
                        });
                        setPriceDialogOpen(true);
                      }}
                      style={{ textAlign: 'end' }}
                    >
                      How the price is calculated
                    </div>
                    <div
                      className={styles.account_info_addon_button}
                      onClick={() => {
                        const planInfoStorage = JSON.parse(
                          // eslint-disable-next-line no-undef
                          localStorage.getItem('planInfo')
                        );
                        logClinicRegistrationEvent({
                          name: 'nf_add_on_add_remove',
                          data: {
                            session_id: planInfoStorage.sessionId,
                            from_where: 'build_trial',
                            nfGroupId: addonGroupId,
                            nfPlanInd: 0,
                            include_nf_add_on: !isNfAddedToSubmit,
                            isNF: true,
                          },
                        });
                        setisNfAddedToSubmit(!isNfAddedToSubmit);
                      }}
                    >
                      {isNfAddedToSubmit ? 'REMOVE' : 'ADD TO TRIAL'}
                    </div>
                  </div>
                  {/* <div
                    className={styles.account_info_container_right_buttom}
                    onClick={async () => {
                      const planInfoStorage = JSON.parse(
                        // eslint-disable-next-line no-undef
                        localStorage.getItem('planInfo')
                      );
                      await logClinicRegistrationEvent({
                        name: 'how_does_it_work_clicked',
                        data: {
                          session_id: planInfoStorage.sessionId,
                          from_where: 'build_trial',
                          isNF: true,
                        },
                      });
                      window.open('https://www.myndlift.com/neuro', '_blank');
                    }}
                  >
                    How does it work?
                  </div> */}
                </div>
              </div>
              <NFAddonsDialog
                priceDialogOpen={priceDialogOpen}
                setPriceDialogOpen={setPriceDialogOpen}
                NFPackages={NFPackages}
              />
            </div>
          ) : null}
        </div>
        {packageData && (
          <BillingSummary
            onContinueClicked={onContinueClicked}
            packageData={packageData}
            isAnnual={isAnnualToSubmit}
            isLoading={isLoading}
            currentStep={currentStep}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            onApllyCoupon={onApllyCoupon}
            isLoadingCoupon={isLoadingCoupon}
            couponCodeError={couponCodeError}
            couponDiscount={couponDiscount}
            isNfAdded={isNfAddedToSubmit}
            hardwareItems={hardwareItems}
            hardwareData={hardware}
            onRemoveHardware={onRemoveHardware}
            NFPackages={NFPackages}
            shippingPrice={calcShippingPrice()}
            shouldAddExtraTax={country === 'il'}
          />
        )}
      </div>
    );
  };

  const renderStartTrial = () => {
    const hardwareIds = hardwareItems && Object.keys(hardwareItems);
    const filteredHardwareIds =
      hardwareIds &&
      hardwareIds.filter(hardwareId => hardwareItems[hardwareId].isSelected);
    if (isNf && filteredHardwareIds.length) {
      return (
        <div className={styles.choose_plan_container}>
          <div>
            <div className={styles.account_info_container}>
              <div className={styles.start_trial_title}>
                Shipping Information
              </div>
              <div className={styles.inputs}>
                <div className={styles.inputs_container}>
                  <TextField
                    label="Name"
                    error={isPaymentFormSubmitted && !fullNamePayment.trim()}
                    helperText={
                      isPaymentFormSubmitted &&
                      !fullNamePayment.trim() &&
                      'Name is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={fullNamePayment}
                    onChange={e => {
                      setFullNamePayment(e.target.value);
                    }}
                  />
                  <TextField
                    label="Email"
                    error={
                      isPaymentFormSubmitted &&
                      (!emailPayment || emailPaymentError)
                    }
                    helperText={emailPaymentError && 'Email is not valid'}
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={emailPayment}
                    onChange={e => {
                      setEmailPayment(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.inputs_container}>
                  <TextField
                    label="Country"
                    select
                    name="Country"
                    onChange={onCountryChange}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    fullWidth
                    value={country}
                    inputProps={{
                      name: 'country',
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      classes: {
                        root: styles.select_country_root,
                      },
                    }}
                  >
                    {countriesDataList.map(countryItem => (
                      <option key={countryItem.iso2} value={countryItem.iso2}>
                        {countryItem.name}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    label="State"
                    error={
                      isPaymentFormSubmitted && !stateInput && country === 'us'
                    }
                    helperText={
                      isPaymentFormSubmitted &&
                      !stateInput &&
                      country === 'us' &&
                      'State is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={stateInput}
                    onChange={e => {
                      setStateInput(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputs_container}>
                  <TextField
                    label="City"
                    error={isPaymentFormSubmitted && !city}
                    helperText={
                      isPaymentFormSubmitted && !city && 'City is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={city}
                    onChange={e => {
                      setCity(e.target.value);
                    }}
                  />
                  <TextField
                    label="Street"
                    error={isPaymentFormSubmitted && !street}
                    helperText={
                      isPaymentFormSubmitted && !street && 'Street is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={street}
                    onChange={e => {
                      setStreet(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputs_container}>
                  <TextField
                    label="Apt, Floor"
                    // error={isPaymentFormSubmitted && !aptPayment}
                    // helperText={
                    //   paswordLengthError &&
                    //   'Password should be at least 6 characters'
                    // }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    // inputProps={{
                    //   type: 'password',
                    //   autocomplete: "new-password"
                    // }}
                    value={aptPayment}
                    onChange={e => {
                      setAptPayment(e.target.value);
                    }}
                  />
                  <TextField
                    label="Zip/Postal Code"
                    error={isPaymentFormSubmitted && !zipCode}
                    helperText={
                      isPaymentFormSubmitted && !zipCode && 'Zip is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    // inputProps={{
                    //   type: 'password',
                    //   autocomplete: "new-password"
                    // }}
                    value={zipCode}
                    onChange={e => {
                      setZipCode(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.inputs_container}>
                  <TextField
                    label="Phone"
                    error={
                      isPaymentFormSubmitted &&
                      (!phoneNumberPayment ||
                        phonePaymentError ||
                        phonePaymentLengthError)
                    }
                    helperText={
                      phonePaymentError
                        ? 'Phone should be at least 5 numbers'
                        : phonePaymentLengthError && 'Invalid phone number'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    // inputProps={{
                    //   type: 'password',
                    //   autocomplete: "new-password"
                    // }}
                    value={phoneNumberPayment}
                    onChange={e => {
                      setPhoneNumberPayment(e.target.value);
                    }}
                  />
                  <div style={{ visibility: 'hidden', width: '100%' }} />
                </div>
              </div>
            </div>
            <div
              className={styles.account_info_container}
              style={{ marginTop: 25 }}
            >
              <div className={styles.start_trial_title}>
                <div>Payment Information</div>
                <div className={styles.start_trial_title_label}>
                  <LockIcon />
                  Secured by Stripe
                </div>
              </div>
              <div className={styles.inputs}>
                <div className={styles.stripe_element}>
                  <CardElement
                    onChange={onCreditCardChange}
                    onReady={element => {
                      cardElementRef.current = element;
                    }}
                    style={stripeElementStyle}
                    hidePostalCode
                  />
                  <p className={styles.error}>{creditCardError}</p>
                </div>
                <div className={styles.inputs_container}>
                  <TextField
                    label="First name"
                    error={isPaymentFormSubmitted && !firstNamePayment.trim()}
                    helperText={
                      isPaymentFormSubmitted &&
                      !firstNamePayment.trim() &&
                      'First Name is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={firstNamePayment}
                    onChange={e => {
                      setFirstNamePayment(e.target.value);
                    }}
                  />
                  <TextField
                    label="Last name"
                    error={isPaymentFormSubmitted && !lastNamePayment.trim()}
                    helperText={
                      isPaymentFormSubmitted &&
                      !lastNamePayment.trim() &&
                      'Last Name is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={lastNamePayment}
                    onChange={e => {
                      setLastNamePayment(e.target.value);
                    }}
                  />
                </div>
                {isSameAddress ? null : (
                  <div className={styles.inputs}>
                    <div className={styles.inputs_container}>
                      <TextField
                        label="Country"
                        select
                        name="Country"
                        onChange={onCountryChange2}
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        fullWidth
                        value={country2}
                        inputProps={{
                          name: 'country',
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          classes: {
                            root: styles.select_country_root,
                          },
                        }}
                      >
                        {countriesDataList.map(countryItem => (
                          <option
                            key={countryItem.iso2}
                            value={countryItem.iso2}
                          >
                            {countryItem.name}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        label="State"
                        error={
                          isPaymentFormSubmitted &&
                          !isSameAddress &&
                          !stateInput2 &&
                          country2 === 'us'
                        }
                        helperText={
                          isPaymentFormSubmitted &&
                          !isSameAddress &&
                          !stateInput2 &&
                          country2 === 'us' &&
                          'State is required'
                        }
                        variant="outlined"
                        classes={{ root: styles.textfield }}
                        fullWidth
                        FormHelperTextProps={{
                          classes: {
                            root: styles.form_helper_text,
                          },
                        }}
                        InputLabelProps={{
                          // shrink: true,
                          classes: {
                            root: styles.label_text,
                          },
                        }}
                        value={stateInput2}
                        onChange={e => {
                          setStateInput2(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.inputs_container}>
                      <TextField
                        label="City"
                        error={
                          isPaymentFormSubmitted && !isSameAddress && !city2
                        }
                        helperText={
                          isPaymentFormSubmitted &&
                          !isSameAddress &&
                          !city2 &&
                          'City is required'
                        }
                        variant="outlined"
                        classes={{ root: styles.textfield }}
                        fullWidth
                        FormHelperTextProps={{
                          classes: {
                            root: styles.form_helper_text,
                          },
                        }}
                        InputLabelProps={{
                          // shrink: true,
                          classes: {
                            root: styles.label_text,
                          },
                        }}
                        value={city2}
                        onChange={e => {
                          setCity2(e.target.value);
                        }}
                      />
                      <TextField
                        label="Street"
                        error={
                          isPaymentFormSubmitted && !isSameAddress && !street2
                        }
                        helperText={
                          isPaymentFormSubmitted &&
                          !isSameAddress &&
                          !street2 &&
                          'Street is required'
                        }
                        variant="outlined"
                        classes={{ root: styles.textfield }}
                        fullWidth
                        FormHelperTextProps={{
                          classes: {
                            root: styles.form_helper_text,
                          },
                        }}
                        InputLabelProps={{
                          // shrink: true,
                          classes: {
                            root: styles.label_text,
                          },
                        }}
                        value={street2}
                        onChange={e => {
                          setStreet2(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.inputs_container}>
                      <TextField
                        label="Zip/Postal Code"
                        error={
                          isPaymentFormSubmitted && !isSameAddress && !zipCode2
                        }
                        helperText={
                          isPaymentFormSubmitted &&
                          !isSameAddress &&
                          !zipCode2 &&
                          'Zip is required'
                        }
                        variant="outlined"
                        classes={{ root: styles.textfield }}
                        fullWidth
                        FormHelperTextProps={{
                          classes: {
                            root: styles.form_helper_text,
                          },
                        }}
                        InputLabelProps={{
                          // shrink: true,
                          classes: {
                            root: styles.label_text,
                          },
                        }}
                        // inputProps={{
                        //   type: 'password',
                        //   autocomplete: "new-password"
                        // }}
                        value={zipCode2}
                        onChange={e => {
                          setZipCode2(e.target.value);
                        }}
                      />
                      <div style={{ visibility: 'hidden', width: '100%' }} />
                    </div>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={isSameAddress}
                  onChange={e => {
                    setIsSameAddress(e.target.checked);
                  }}
                  color="primary"
                />
                <div>
                  The billing address is the same as the shipping address
                </div>
              </div>
            </div>
            <div>
              {calcHardwareItems() ? (
                <div className={styles.billing_info_explain_item}>
                  <CheckIcon style={{ color: '#4BE0D0' }} />
                  You will get billed for the selected hardware
                </div>
              ) : null}
              <div className={styles.billing_info_explain_item}>
                <CheckIcon style={{ color: '#4BE0D0' }} />
                You won’t get billed for the plan until {calcDate()}
              </div>
              <div className={styles.billing_info_explain_item}>
                <CheckIcon style={{ color: '#4BE0D0' }} />
                Cancel anytime before {calcDate()}
              </div>
            </div>
          </div>
          {packageData && (
            <BillingSummary
              onContinueClicked={onContinueClicked}
              packageData={packageData}
              isAnnual={isAnnualToSubmit}
              isLoading={isLoading}
              currentStep={currentStep}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              onApllyCoupon={onApllyCoupon}
              isLoadingCoupon={isLoadingCoupon}
              couponCodeError={couponCodeError}
              couponDiscount={couponDiscount}
              isNfAdded={isNfAddedToSubmit}
              hardwareItems={hardwareItems}
              hardwareData={hardware}
              onRemoveHardware={onRemoveHardware}
              registrationError={registrationError}
              NFPackages={NFPackages}
              shippingPrice={calcShippingPrice()}
              setTotalPrice={setTotalPrice}
              shouldAddExtraTax={country === 'il'}
            />
          )}
        </div>
      );
    }
    return (
      <div className={styles.choose_plan_container}>
        <div>
          <div className={styles.account_info_container}>
            <div className={styles.start_trial_title}>
              <div>Payment Information</div>
              <div className={styles.start_trial_title_label}>
                <LockIcon />
                Secured by Stripe
              </div>
            </div>
            <div className={styles.inputs}>
              <div className={styles.stripe_element}>
                <CardElement
                  onChange={onCreditCardChange}
                  onReady={element => {
                    cardElementRef.current = element;
                  }}
                  style={stripeElementStyle}
                  hidePostalCode
                />
                <p className={styles.error}>{creditCardError}</p>
              </div>
              <div className={styles.inputs_container}>
                <TextField
                  label="First name"
                  error={isPaymentFormSubmitted && !firstNamePayment.trim()}
                  helperText={
                    isPaymentFormSubmitted &&
                    !firstNamePayment.trim() &&
                    'First name is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={firstNamePayment}
                  onChange={e => {
                    setFirstNamePayment(e.target.value);
                  }}
                />
                <TextField
                  label="Last name"
                  error={isPaymentFormSubmitted && !lastNamePayment.trim()}
                  helperText={
                    isPaymentFormSubmitted &&
                    !lastNamePayment.trim() &&
                    'Last name is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={lastNamePayment}
                  onChange={e => {
                    setLastNamePayment(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputs_container}>
                <TextField
                  label="Country"
                  select
                  name="Country"
                  onChange={onCountryChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  value={country}
                  inputProps={{
                    name: 'country',
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    classes: {
                      root: styles.select_country_root,
                    },
                  }}
                >
                  {countriesDataList.map(countryItem => (
                    <option key={countryItem.iso2} value={countryItem.iso2}>
                      {countryItem.name}
                    </option>
                  ))}
                </TextField>
                <TextField
                  label="State"
                  error={
                    isPaymentFormSubmitted && !stateInput && country === 'us'
                  }
                  helperText={
                    isPaymentFormSubmitted &&
                    !stateInput &&
                    country === 'us' &&
                    'State is missing'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={stateInput}
                  onChange={e => {
                    setStateInput(e.target.value);
                  }}
                />
              </div>

              <div className={styles.inputs_container}>
                <TextField
                  label="City"
                  error={isPaymentFormSubmitted && !city}
                  helperText={
                    isPaymentFormSubmitted && !city && 'City is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={city}
                  onChange={e => {
                    setCity(e.target.value);
                  }}
                />
                <TextField
                  label="Street"
                  error={isPaymentFormSubmitted && !street}
                  helperText={
                    isPaymentFormSubmitted && !street && 'Street is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={street}
                  onChange={e => {
                    setStreet(e.target.value);
                  }}
                />
              </div>

              <div className={styles.inputs_container}>
                <TextField
                  label="Zip/Postal Code"
                  error={isPaymentFormSubmitted && !zipCode}
                  helperText={
                    isPaymentFormSubmitted && !zipCode && 'Zip is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  // inputProps={{
                  //   type: 'password',
                  //   autocomplete: "new-password"
                  // }}
                  value={zipCode}
                  onChange={e => {
                    setZipCode(e.target.value);
                  }}
                />
                <div style={{ visibility: 'hidden', width: '100%' }} />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.billing_info_explain_item}>
              <CheckIcon style={{ color: '#4BE0D0' }} />
              You won’t get billed today
            </div>
            <div className={styles.billing_info_explain_item}>
              <CheckIcon style={{ color: '#4BE0D0' }} />
              Cancel anytime before {calcDate()}
            </div>
          </div>
        </div>
        {packageData && (
          <BillingSummary
            onContinueClicked={onContinueClicked}
            packageData={packageData}
            isAnnual={isAnnualToSubmit}
            isLoading={isLoading}
            currentStep={currentStep}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            onApllyCoupon={onApllyCoupon}
            isLoadingCoupon={isLoadingCoupon}
            couponCodeError={couponCodeError}
            isNfAdded={isNfAddedToSubmit}
            hardwareItems={hardwareItems}
            hardwareData={hardware}
            onRemoveHardware={onRemoveHardware}
            registrationError={registrationError}
            NFPackages={NFPackages}
            shippingPrice={calcShippingPrice()}
            setTotalPrice={setTotalPrice}
            shouldAddExtraTax={country === 'il'}
          />
        )}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className={styles.confirmation_container}>
        <img src={ConfettiGif} className={styles.confirmation_image} />
        <div className={styles.confirmation_title}>
          You’ve successfully registered with Myndlift!
        </div>
        {isNfAddedToSubmit ? (
          <div>
            <div
              className={styles.confirmation_description}
              style={{ marginTop: 15 }}
            >
              We are processing your hardware order and will ship it as soon as
              possible.
            </div>
            <div
              className={styles.confirmation_description}
              style={{ marginBottom: 15 }}
            >
              You’ll receive an email from us with the tracking number once it
              gets shipped.
            </div>
          </div>
        ) : null}
        <div
          className={styles.confirmation_description}
          style={{ marginTop: 15 }}
        >
          We’ve sent you an email outlining the next steps.
        </div>
        {/* <div className={styles.ifrmae_wrapper}>
          <iframe
            src={`https://calendly.com/myndlift-care/dashboard-overview?utm_campaign=registered&utm_source=dashboard&utm_content=${sessionStorage.userId}`}
            className={styles.confirmation_iframe}
            title="Schedule a call"
          />
        </div> */}
        <div
          className={styles.confirmation_description}
          style={{ marginTop: 15 }}
        >
          Start exploring your dashboard:
        </div>
        <div className={styles.confirmation_button} onClick={loginToDashboard}>
          LOG INTO YOUR DASHBOARD
        </div>
      </div>
    );
  };

  const renderCurrentStep = () => {
    if (currentStep === stepperSteps[0]) {
      return renderCreateAccount();
    }
    if (currentStep === stepperSteps[1] && packageData) {
      return renderBuildTrial();
    }
    if (currentStep === stepperSteps[2]) {
      return renderStartTrial();
    }
    if (currentStep === stepperSteps[3]) {
      return renderConfirmation();
    }
    return null;
  };

  return (
    <div className={styles.registration_flow_container} ref={containerEl}>
      <div className={styles.registration_flow_header}>
        <img src={Logo} className={styles.registration_flow_header_img} />
      </div>
      {currentStep !== stepperSteps[3] ? (
        <Button
          style={{ position: 'absolute', top: 100, left: 20 }}
          onClick={onBackClicked}
        >
          <ArrowBackIcon />
        </Button>
      ) : null}
      <div className={styles.stepper_container}>
        <Stepper steps={stepperSteps} activeStep={currentStep} newFlow />
      </div>
      <div className={styles.slide_in_right}>{renderCurrentStep()}</div>
    </div>
  );
};

RegisterNonfClinicRegistrationBase.propTypes = exact({
  stripe: PropTypes.object.isRequired,
  hardware: PropTypes.object.isRequired,
  NFPackages: PropTypes.object.isRequired,
  hardwareBanner: PropTypes.string,
  shippingPrice: PropTypes.object.isRequired,
  addonGroupId: PropTypes.string,
});

export const RegisterNonfClinicRegistration = React.memo(
  injectStripe(RegisterNonfClinicRegistrationBase)
);
RegisterNonfClinicRegistration.displayName = 'RegisterNonfClinicRegistration';

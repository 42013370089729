import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
  CartesianGrid,
  Label,
  Text,
} from 'recharts';
import { Chip } from '@material-ui/core';
import styles from './InsightsBarsGraph.scss';

const InsightsBarsGraphBase = ({
  data,
  unit,
  date,
  protocolName,
  yAxisLabel,
  isGraphHidden,
}) => {
  const renderLabel = passedProps => {
    // x y are just numbers based on trial
    return (
      <Text {...passedProps} x={20} y={210} verticalAnchor="start">
        {passedProps.value}
      </Text>
    );
  };

  const valueFormatter = value => {
    if (value === 0 || value === '0') {
      return '';
    }
    return data.some(dataObj => dataObj.y > 15)
      ? `${Math.round(+value)}${unit}`
      : `${+value.toFixed(2)}${unit}`;
  };

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        {protocolName && (
          <div className={styles.protocol_name_container}>
            <Chip label={protocolName} className={styles.chip} />
          </div>
        )}
        {date && (
          <div className={styles.protocol_name_container}>
            <Chip label={date} className={styles.chip} />
          </div>
        )}
      </div>
      {!isGraphHidden && (
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="x" tickMargin={7} tick={{ fontSize: 12 }} />
            <YAxis tick={{ fontSize: 12 }} unit={unit}>
              {yAxisLabel && (
                <Label
                  value={yAxisLabel}
                  position="insideLeft"
                  content={renderLabel}
                  angle={-90}
                  style={{ fontSize: 12, fill: '#666' }}
                />
              )}
            </YAxis>
            <Tooltip formatter={valueFormatter} />

            <Bar key="y" dataKey="y" fill="rgb(33, 229, 215)" maxBarSize={90}>
              <LabelList
                dataKey="y"
                position="top"
                formatter={valueFormatter}
              />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

InsightsBarsGraphBase.defaultProps = {
  unit: '',
};

InsightsBarsGraphBase.propTypes = exact({
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  unit: PropTypes.string,
  yAxisLabel: PropTypes.string,
  date: PropTypes.string.isRequired,
  protocolName: PropTypes.string.isRequired,
  isGraphHidden: PropTypes.bool,
});

export const InsightsBarsGraph = React.memo(InsightsBarsGraphBase);
InsightsBarsGraph.displayName = 'InsightsBarsGraph';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Paper from '@material-ui/core/Paper';
import HelpIcon from '@material-ui/icons/Help';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './SwingleChecks.scss';
import { SwingleCheck } from './SwingleCheck/SwingleCheck';
import increasingArrowPNG from '../../../assets/increasing-arrow.png';
import decreasingArrowPNG from '../../../assets/decreasing-arrow.png';
import { ASSESSMENT_COLORS } from '../../models/assessments/assessments';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';

const SwingleChecksBase = ({
  swingleChecksGroupedByCategory,
  swingleChecksResults,
  postSwingleChecksResults,
  swingleCategoryScores,
  postSwingleCategoryScores,
  classes,
  swingleNoise,
  postSwingleNoise,
  swingleChecksOrder,
}) => {
  const t = useTranslation();

  const sortedSwingleChecksGroupedByCategory = swingleChecksOrder
    ? Object.entries(swingleChecksGroupedByCategory).sort(
        (a, b) =>
          swingleChecksOrder.indexOf(a[0]) - swingleChecksOrder.indexOf(b[0])
      )
    : Object.entries(swingleChecksGroupedByCategory);

  return (
    <div>
      {sortedSwingleChecksGroupedByCategory.map(([category, checks]) => {
        const postCategoryScore = get(postSwingleCategoryScores, [category]);
        const differenceBetweenPostAndPreScore = parseInt(
          postCategoryScore - swingleCategoryScores[category],
          10
        );

        const formattedDifferenceBetweenPostAndPreScore =
          differenceBetweenPostAndPreScore > 0
            ? `+${differenceBetweenPostAndPreScore}%`
            : `${differenceBetweenPostAndPreScore}%`;
        const differenceBetweenPostAndPreScoreIconPNG =
          differenceBetweenPostAndPreScore > 0
            ? increasingArrowPNG
            : decreasingArrowPNG;
        const differenceBetweenPostAndPreScoreTextColor =
          (differenceBetweenPostAndPreScore === 0 && 'grey') ||
          (differenceBetweenPostAndPreScore > 0 &&
            ASSESSMENT_COLORS.POSITIVE) ||
          (differenceBetweenPostAndPreScore < 0 && ASSESSMENT_COLORS.NEGATIVE);

        checks.sort((a, b) => a.swingleIndex - b.swingleIndex);

        return (
          <Paper
            key={category}
            classes={{ root: styles.category_expansion_panel_root }}
            className="print-item"
            defaultExpanded
            style={{padding: 20}}
          >
            <div
              // expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              // id="panel1a-header"
              // className="expandable-in-report"
            >
              <div
                className={
                  postSwingleCategoryScores
                    ? styles.swingle_category_info_comparison_mode
                    : styles.swingle_category_info
                }
              >
                <div className={styles.swingle_category_tooltip_container}>
                  <p className={styles.expandable_head}>{category}</p>
                  <TooltipWrapper
                    title={
                      <TooltipTitle>
                        {t(
                          `swingle_category_${snakeCase(category)}_description`
                        )}
                      </TooltipTitle>
                    }
                  >
                    <HelpIcon
                      onClick={event => {
                        // We dont want the expandable menu to be opened when clicking on this icon, so hover can be viewed easily in mobile
                        event.stopPropagation();
                      }}
                      className="hide-on-print"
                    />
                  </TooltipWrapper>
                </div>

                {!postSwingleCategoryScores && (
                  <div className={styles.progress}>
                    <CircularProgressbar
                      value={swingleCategoryScores[category]}
                      text={`${swingleCategoryScores[category]}%`}
                      className={styles.circular_progress_bar_text}
                    />
                  </div>
                )}
                {postSwingleCategoryScores && (
                  <div className={styles.result_change_percentage_container}>
                    <div>
                      <p>Pre</p>
                      <div className={styles.progress_comparison}>
                        <CircularProgressbar
                          value={swingleCategoryScores[category]}
                          text={`${swingleCategoryScores[category]}%`}
                        />
                      </div>
                    </div>
                    <div className={styles.vertical_border} />
                    <div>
                      <p>Post</p>
                      <div className={styles.progress_comparison}>
                        <CircularProgressbar
                          value={postCategoryScore}
                          text={`${postCategoryScore}%`}
                        />
                      </div>
                      {differenceBetweenPostAndPreScore !== 0 &&
                        !Number.isNaN(differenceBetweenPostAndPreScore) && (
                          <span>
                            (
                            <img
                              src={differenceBetweenPostAndPreScoreIconPNG}
                              style={{ marginLeft: 1 }}
                            />
                            <span
                              style={{
                                color: differenceBetweenPostAndPreScoreTextColor,
                                marginRight: 3,
                              }}
                            >
                              {formattedDifferenceBetweenPostAndPreScore}
                            </span>
                            )
                          </span>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div style={{ width: '100%' }}>
                {checks.map(check => (
                  <SwingleCheck
                    key={check.id}
                    check={check}
                    result={swingleChecksResults[check.id]}
                    postResult={
                      postSwingleChecksResults &&
                      postSwingleChecksResults[check.id]
                    }
                    classes={classes}
                    swingleNoise={swingleNoise && swingleNoise[check.id]}
                    postSwingleNoise={
                      postSwingleNoise && postSwingleNoise[check.id]
                    }
                  />
                ))}
              </div>
            </div>
          </Paper>
        );
      })}
    </div>
  );
};

SwingleChecksBase.propTypes = exact({
  swingleChecksGroupedByCategory: PropTypes.object.isRequired,
  swingleChecksResults: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    ])
  ).isRequired,
  postSwingleChecksResults: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    ])
  ),
  swingleCategoryScores: PropTypes.objectOf(PropTypes.number.isRequired)
    .isRequired,
  postSwingleCategoryScores: PropTypes.objectOf(PropTypes.number.isRequired),
  classes: PropTypes.objectOf(PropTypes.string),
  swingleNoise: PropTypes.object,
  postSwingleNoise: PropTypes.object,
  swingleChecksOrder: PropTypes.array,
});

export const SwingleChecks = React.memo(SwingleChecksBase);
SwingleChecks.displayName = 'SwingleChecks';

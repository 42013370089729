/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
// notes:
// 1-if staggerChildren is true in a parent(sequence) means =>
// exit and enter delay in words array and the words array wont be used and timing will be handled by framer-motion

import styles from './CustomerStoriesPagesStyles.scss';

export const welcomeStorySequences = (year = new Date().getFullYear()) => {
  const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  // const backgroundAnimationDelay = 1200;
  const backgroundAnimationDuration = 3940;

  const seq1WordsLength = '"A billion thanks."'.split(' ').length;
  const seq2WordsLength = 'Your neurons.'.split(' ').length;
  const seq3WordsLength = 'That’s for training your brain in'.split(' ').length;
  const seq4WordsLength = year.split('').length;
  const seq5WordsLength = 'Let’s explore what you did'.split(' ').length;

  const seq1Variants = {
    initial: { opacity: 0, y: -20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq2Variants = {
    initial: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      x: 20,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq3Variants = {
    initial: { opacity: 0, y: 0 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq5Variants = {
    initial: { opacity: 0 }, // Starts visible with no change
    enter: { opacity: 1 }, // No transition for entry, just stays visible
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  const seq2EnterTime = seq2WordsLength * textAnimationDuration;

  const seq1Words = '"A billion thanks."'.split(' ').map((word, idx) => {
    return {
      id: `seq1-word${idx + 1}`,
      text: word,
      enterDelay: textAnimationDuration * (idx + 1),
      exitDelay:
        seq1EnterTime +
        seq2EnterTime +
        (2 / 3) * backgroundAnimationDuration +
        textAnimationDuration * (idx + 1) -
        2 * textAnimationDuration, // this textAnimationDuration is subtracted because backgroundAnimationDuration starts with the last word of seq2 so it covers the time
      variants: seq1Variants,
      style: {
        margin: '5px',
      },
    };
  });
  const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const seq2Words = 'Your neurons.'.split(' ').map((word, idx) => {
    return {
      id: `seq2-word${idx + 1}`,
      text: word,
      enterDelay: seq1EnterTime + textAnimationDuration * (idx + 1),
      exitDelay:
        seq1EnterTime +
        // seq2EnterTime + // this was removed because the order of the setTimeout is Scheduled after render each render
        (2 / 3) * backgroundAnimationDuration +
        textAnimationDuration * reverseDelay(idx, seq2WordsLength) -
        3 * textAnimationDuration, // see seq1 for this subtraction, multiplied to match seq1 words because if timeout order
      variants: seq2Variants,
      style: {
        margin: '5px',
      },
    };
  });
  const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  const seq4EnterTime = seq4WordsLength * textAnimationDuration;
  const seq3Words = 'That’s for training your brain in'
    .split(' ')
    .map((word, idx) => {
      return {
        id: `seq3-word${idx + 1}`,
        text: word,
        enterDelay:
          seq1TotalTime +
          seq2TotalTime +
          backgroundAnimationDuration +
          textAnimationDuration * (idx + 1) -
          6 * textAnimationDuration,
        exitDelay:
          seq1TotalTime +
          seq2TotalTime +
          backgroundAnimationDuration +
          // seq3EnterTime +
          seq4EnterTime +
          textAnimationDuration * (idx + 1) -
          6 * textAnimationDuration,
        variants: seq3Variants,
        style: { margin: '5px', display: 'inline-block' },
        // staggerChildren: true,
      };
    });
  const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  const seq4ExitTime = seq4WordsLength * textAnimationDuration;
  const seq4TotalTime = seq4EnterTime + seq4ExitTime;

  // const seq5EnterTime = seq5WordsLength * textAnimationDuration;
  const seq5Words = 'Let’s explore what you did'.split(' ').map((word, idx) => {
    return {
      id: `seq5-word${idx + 1}`,
      text: word,
      enterDelay:
        seq1TotalTime +
        seq2TotalTime +
        backgroundAnimationDuration +
        seq3TotalTime +
        seq4TotalTime +
        textAnimationDuration * (idx + 1) -
        3.5 * textAnimationDuration,
      // exitDelay: textAnimationDuration * seq1WordsLength + textAnimationDuration * (idx + 1),
      variants: seq5Variants,
      style: {
        margin: '7px 5px',
      },
    };
  });
  // const seq5ExitTime = 0;
  // const seq5TotalTime = seq5EnterTime + seq5ExitTime;

  const seq1 = {
    id: 'seq1',
    words: seq1Words,
    enterDelay: 0,
    exitDelay:
      seq1EnterTime +
      seq2EnterTime +
      backgroundAnimationDuration +
      seq1ExitTime -
      5 * textAnimationDuration,
    style: {},
    className: styles.welcome_seq_1,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  const seq2 = {
    id: 'seq2',
    words: seq2Words,
    enterDelay: seq1EnterTime,
    exitDelay:
      seq1EnterTime +
      seq2EnterTime +
      backgroundAnimationDuration +
      seq1ExitTime -
      6 * textAnimationDuration,
    style: {},
    className: styles.welcome_seq_2,
    variants: {
      initial: { opacity: 1, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  const seq3 = {
    id: 'seq3',
    words: seq3Words,
    sentence: 'That’s for training your brain in',
    wordsVariants: seq3Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      backgroundAnimationDuration -
      6 * textAnimationDuration,
    exitDelay:
      seq1TotalTime +
      seq2TotalTime +
      backgroundAnimationDuration +
      // seq3EnterTime +
      // seq4EnterTime +
      seq3ExitTime +
      seq4ExitTime -
      25 * textAnimationDuration,
    style: {},
    className: styles.welcome_seq_3,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
          when: 'beforeChildren',
        },
      }, // No transition for entry, just stays visible
      exit: {
        opacity: 0,
        x: -20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.1, // Stagger by 0.1 seconds between each child
          // staggerDirection: -1,
          when: 'afterChildren',
        },
      }, // Stagger by 0.1 seconds between each child}, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq4 = {
    id: 'seq4',
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      backgroundAnimationDuration -
      6 * textAnimationDuration,
    exitDelay:
      seq1TotalTime +
      seq2TotalTime +
      backgroundAnimationDuration +
      // seq3EnterTime +
      // seq4EnterTime +
      seq3ExitTime +
      seq4ExitTime +
      23 * textAnimationDuration,
    words: [
      {
        id: `seq4-word${1}`,
        text: year,
        enterDelay:
          seq1TotalTime +
          seq2TotalTime +
          seq3EnterTime +
          backgroundAnimationDuration -
          6 * textAnimationDuration,
        exitDelay:
          seq1TotalTime +
          seq2TotalTime +
          backgroundAnimationDuration -
          // seq3EnterTime +
          // seq4EnterTime -
          23 * textAnimationDuration,
        variants: {
          initial: {
            opacity: 0,
            y: 20,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
            },
          },
          exit: {
            opacity: 0,
            x: 20,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.1, // Stagger by 0.1 seconds between each child
              staggerDirection: -1,
            },
          },
        },
        style: {},
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          exit: {
            opacity: 0,
            x: 20,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
        },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.welcome_seq_4,
    variants: {
      initial: { opacity: 1, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  const seq5 = {
    id: 'seq5',
    words: seq5Words,
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      backgroundAnimationDuration +
      seq3TotalTime +
      seq4TotalTime -
      3.5 * textAnimationDuration,
    //   exitDelay: seq1TotalTime * 1000,
    style: {},
    className: styles.welcome_seq_5,
    variants: {
      initial: { opacity: 1, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      // exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  return [seq1, seq2, seq3, seq4, seq5];
};

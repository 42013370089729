import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import nth from 'lodash/nth';
import isUndefined from 'lodash/isUndefined';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import axios from 'axios';
import { parseISO } from 'date-fns';
import { snakeCase } from 'lodash';
import { postcodeValidator } from 'postcode-validator';
import { DashboardDefensiveError } from '../models/errors/errors';
import { shareASaleEventSrc } from './constants';

export const capitalizeFirstLetter = word =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const toLowerCase = word => word.toLowerCase();

export const toDashedWords = word => word.split(' ').join('-');

export const toDashedLowerCase = word => toDashedWords(toLowerCase(word));

export const removeAllAfterLastChar = (word, char) =>
  word.substr(
    0,
    word.lastIndexOf(char) === -1 ? Infinity : word.lastIndexOf(char)
  );

export const doesStrContainAnyOfTheFollowing = (str, arrOfStrings) =>
  arrOfStrings.some(substring => str.includes(substring));

export const bifurcateArrBy = (arr, fn) =>
  // eslint-disable-next-line no-sequences
  arr.reduce((acc, val, i) => (acc[fn(val, i) ? 0 : 1].push(val), acc), [
    [],
    [],
  ]);

export const removeElement = ({
  id = throw new Error('Remove element must receive element id as a paramter'),
} = {}) => {
  document.getElementById(id).outerHTML = '';
};

export const appendDivToBody = ({ id }) => {
  const div = document.createElement('div');
  div.id = id;
  document.body.appendChild(div);
  return div;
};

export const addEllipsis = ({ str, strLengthWithoutEllipsis }) =>
  `${str.slice(0, strLengthWithoutEllipsis)} ...`;

export const addZerosToNumberToMatchLength = (number, desiredNumberLength) => {
  const strNumber = `${number}`;
  if (desiredNumberLength === 0) {
    return strNumber;
  }
  return '0'.repeat(desiredNumberLength - strNumber.length) + strNumber;
};

export const formatSecondsToMMSS = ({
  seconds,
  desiredLengthForMinutes = 0,
  shouldShowSeconds = true,
}) => {
  const minutesValue = addZerosToNumberToMatchLength(
    shouldShowSeconds ? Math.floor(seconds / 60) : Math.round(seconds / 60),
    desiredLengthForMinutes
  );
  const secondsValue = addZerosToNumberToMatchLength(seconds % 60, 2);

  return shouldShowSeconds
    ? `${minutesValue}:${secondsValue}`
    : `${minutesValue}`;
};

export const MMSStoSeconds = formattedSeconds => {
  const values = formattedSeconds.split(':');
  return parseInt(values[0], 10) * 60 + parseInt(values[1], 10);
};

export const renameKeysBy = (obj, fn) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = fn(key);
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

export const reduceToAverageOverKeys = (arr, keys) => {
  const average = keys.reduce((acc, cur) => ({ ...acc, [cur]: 0 }), {});
  arr.forEach((obj, index) => {
    keys.forEach(key => {
      average[key] += obj[key] || 0;
      if (index === arr.length - 1) {
        average[key] /= index + 1;
      }
    });
  });

  return average;
};

export const avg = arr => arr.reduce((acc, cur) => acc + cur, 0) / arr.length;
export const median = arr => {
  const midIndex = Math.floor(arr.length / 2);
  if (arr.length % 2 === 0) {
    const leftIndex = midIndex - 1;
    return avg([arr[leftIndex], arr[midIndex]]);
  }

  return arr[midIndex];
};

export const reduceToMedianOverKeys = (arr, keys) => {
  const keysToValues = {};

  // arr.forEach(obj => {
  //   keys.forEach(key => {
  //     if (!keysToValues[key]) {
  //       keysToValues[key] = [];
  //     }
  //     keysToValues[key].push(obj[key] || 0);
  //   });
  // });
  keys.forEach(key => {
    arr.forEach(obj => {
      if (!keysToValues[key]) {
        keysToValues[key] = [];
      }
      if (obj[key]) {
        keysToValues[key].push(obj[key] || 0);
      }
    });
  });

  const keysToSortedValues = mapValues(keysToValues, values =>
    values.sort((a, b) => a - b)
  );
  return mapValues(keysToSortedValues, values =>
    values.length ? median(values) : 0
  );
};

export const floatMinutesToSeconds = minutes =>
  Math.floor(minutes) * 60 + (minutes - Math.floor(minutes)) * 60;

export const floatSecondsToFloatMinutes = seconds => seconds / 60;

export const secondsToStrMinutes = seconds => {
  if (seconds % 60 >= 10) {
    return `${Math.floor(seconds / 60)}:${seconds % 60}`;
  }

  return `${Math.floor(seconds / 60)}:0${seconds % 60}`;
};

export const isCharADigit = char => !Number.isNaN(parseInt(char, 10));

export const isDeepEqualWithTrueForFunctions = (baseObject, targetObject) =>
  isEqual(Object.keys(baseObject), Object.keys(targetObject)) &&
  Object.keys(baseObject).every(key => {
    if (
      typeof baseObject[key] === 'function' &&
      typeof baseObject[key] === typeof targetObject[key]
    ) {
      return true;
    }

    return isEqual(baseObject[key], targetObject[key]);
  });

export const nearestDividingNumber = ({
  number,
  numberToBeDivided,
  direction,
}) => {
  let dividingNumber = number;
  if (direction === 'up') {
    while (dividingNumber % numberToBeDivided !== 0) {
      dividingNumber += 1;
    }
  } else if (direction === 'down') {
    while (dividingNumber % numberToBeDivided !== 0) {
      dividingNumber -= 1;
    }
  } else {
    throw new Error('unsupported direction for nearestDividingNumber function');
  }

  return dividingNumber;
};

export const pushToArrAndReturnIt = ({ arr, element }) => {
  arr.push(element);
  return arr;
};

export const doesStrContainAnyFirebaseForbiddenChar = str => {
  const firebaseForbiddenChars = ['.', '$', '#', '[', ']', '/', '\\'];

  return str.split('').some(c => firebaseForbiddenChars.indexOf(c) !== -1);
};

export const doesNonEmptyStrHasOnlyDigits = str => /^\d+$/.test(str);

export const isValidEmail = email =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isValidZipCode = (zipCode, country) => {
  if (!zipCode) {
    return false;
  }
  if (country === 'ca' || country === 'us') {
    return postcodeValidator(zipCode, country.toUpperCase());
  }
  return true;
};

export const isValidPhone = str =>
  str[0] === '+'
    ? doesNonEmptyStrHasOnlyDigits(str.slice(1))
    : doesNonEmptyStrHasOnlyDigits(str);

export const defensiveThrow = ({ error, message }) => {
  // eslint-disable-next-line no-undef
  if (!firebase.auth().currentUser) {
    return;
  }
  const defensiveError = message && new DashboardDefensiveError(message);
  if (environmentConfig.isDev) {
    if (message) {
      // eslint-disable-next-line no-alert
      window.alert(defensiveError);
      throw defensiveError;
    }
    // eslint-disable-next-line no-alert
    window.alert(error);
    throw error;
  } else {
    if (message) {
      Sentry.captureException(defensiveError);
    } else {
      Sentry.captureException(error);
    }
  }
};

export const asyncWait = waitFor =>
  new Promise(resolve => setTimeout(resolve, waitFor));

export const setFixedDecimalsIfNeeded = ({
  number,
  numberOfFixedDecimals = 2,
}) => {
  const numberWithFixedNumberOfDigits = number.toFixed(numberOfFixedDecimals);
  return parseFloat(numberWithFixedNumberOfDigits, 10) ===
    parseInt(numberWithFixedNumberOfDigits, 10)
    ? `${parseInt(numberWithFixedNumberOfDigits, 10)}`
    : numberWithFixedNumberOfDigits;
};

export const getPercentageOfChange = (value1, value2) =>
  ((value2 - value1) / Math.abs(value1)) * 100;

export const getURLParams = ({ searchString }) => {
  const searchParams = new URLSearchParams(searchString);
  return [...searchParams.entries()].reduce(
    (acc, [searchKey, searchValue]) => ({ ...acc, [searchKey]: searchValue }),
    {}
  );
};

export const getCurrentURLParams = () =>
  getURLParams({ searchString: window.location.search });

export const scrollTo = (id, moreOffset = 0) => {
  const element = document.getElementById(id);
  window.scrollTo({
    top: element.offsetTop + moreOffset,
    left: 0,
    behavior: 'smooth',
  });
};

export const postMessageToApp = msg => {
  try {
    if (window.Android && window.Android.receiveMessageFromJS) {
      window.Android.receiveMessageFromJS(msg);
    }
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[msg]
    ) {
      window.webkit.messageHandlers[msg].postMessage(msg);
    }
  } catch (error) {
    defensiveThrow({ error });
  }
};

export const postMessageToAppWithData = (msg, data, msg2) => {
  try {
    if (window.Android && window.Android.receiveMessageFromJS) {
      window.Android.receiveMessageFromJS(msg2);
    }
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[msg]
    ) {
      window.webkit.messageHandlers[msg].postMessage(data);
    }
  } catch (error) {
    defensiveThrow({ error });
  }
};

export const notifyAppThatComponentIsLoaded = () =>
  postMessageToApp('finishLoading');

export const notifyAppThatUserHasRegistered = activationCode =>
  postMessageToAppWithData(
    'finishedCreatingActivationCode',
    {
      activationCode,
    },
    `finishedCreatingActivationCode ${activationCode}`
  );
export const notifyAppThatStoryShareWasClicked = () =>
  postMessageToApp('shareClicked');

export const injectIDs = (object, keyName = 'id') =>
  mapValues(object, (value, key) => ({ ...value, [keyName]: key }));

export const convertObjectToArrWithIds = (object, keyName = 'id') => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    acc.push({ [keyName]: key, ...value });
    return acc;
  }, []);
};

export const isInteger = strInput => `${parseInt(strInput, 10)}` === strInput;
export const isFloat = strInput => {
  const rightSideAfterLastDot = strInput.includes('.')
    ? nth(strInput.split('.'), -1)
    : '';
  const numberOfDecimalDigits = rightSideAfterLastDot
    ? rightSideAfterLastDot.length
    : 0;
  return (
    `${parseFloat(strInput, 10).toFixed(numberOfDecimalDigits)}` === strInput
  );
};

export const ifNeededRedirectToLoginPage = () => {
  if (sessionStorage.isLogged !== 'true') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.get('viewer') === 'customer') {
      urlSearchParams.append('loginType', 'customer');
    }
    window.open(`login.html?${urlSearchParams.toString()}`, '_self');
  }
};

export const isDefined = value => !isUndefined(value);

export const intersectionBetweenObjects = (...objects) => {
  const commonKeys = intersection(...objects.map(obj => Object.keys(obj)));
  return objects.map(obj => pick(obj, commonKeys));
};

const createRequestPayLoad = async ({ data, shouldAppendDefaultData }) => {
  return {
    ...(shouldAppendDefaultData
      ? {
          userId: sessionStorage.userId,
          patientId: sessionStorage.customerId,
          // eslint-disable-next-line no-undef
          fbtoken: await getToken(),
        }
      : {}),
    ...data,
  };
};

export const postToNode = async ({
  path,
  data = {},
  shouldAppendDefaultData = false,
}) => {
  const response = await axios.post(
    `${environmentConfig.appengine.node}${path}`,
    await createRequestPayLoad({ data, shouldAppendDefaultData })
  );
  return response.data;
};

export const postToNodeWithDefaultData = ({ path, data }) =>
  postToNode({ path, data, shouldAppendDefaultData: true });

export const postToFunctions = async ({
  path,
  data,
  shouldAppendDefaultData = false,
}) => {
  const response = await axios.post(
    `${environmentConfig.functions}${path}`,
    await createRequestPayLoad({ data, shouldAppendDefaultData })
  );
  return response.data;
};

export const postToFunctionsWithDefaultData = ({ path, data }) =>
  postToFunctions({ path, data, shouldAppendDefaultData: true });

export const logEvent = async ({ name, data, requestExtraData = {} }) => {
  try {
    Sentry.addBreadcrumb({
      message: name,
      category: 'action',
      data,
    });
    // eslint-disable-next-line no-undef
    amplitude.track(name, data);
    return postToFunctions({
      path: 'eventLog',
      data: {
        eventName: name,
        eventDetails: data,
        ...requestExtraData,
      },
    });
  } catch (e) {
    Sentry.captureException(e);
    return Promise.reject(e);
  }
};

export const getEventLogger = ({
  prefix,
  commonData = {},
  commonRequestExtraData = {},
}) => {
  return ({ name, data, requestExtraData = {} }) =>
    logEvent({
      name: name ? `${prefix}_${name}` : prefix,
      data: { ...commonData, ...data },
      requestExtraData: { ...commonRequestExtraData, ...requestExtraData },
    });
};

export const safeParseDate = ({ str, format = 'YYYY-MM-DD' }) => {
  try {
    const dateObject = parseISO(str, format);
    if (Number.isNaN(dateObject.getTime())) {
      throw new Error('Invalid date');
    }
    return dateObject;
  } catch (err) {
    return undefined;
  }
};

export const downloadURIContent = ({ content, name }) => {
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name);
  link.click();
};
// eslint-disable-next-line no-unused-vars
export const fetchHeadsetDetails = async ({ customerId } = {}) => {
  // const headsetDetails = await postToNodeWithDefaultData({
  //   path: 'userDispatchDetails',
  //   data: customerId
  //     ? {
  //         patientId: customerId,
  //       }
  //     : {},
  // });

  const headsetDetails = {
    lastUsedHeadsetId: null,
    isDispatched: false,
    isHeadsetActive: false,
    dispatchedHeadsetId: null,
    result: true,
  };

  const dispatchedHeadsetCredit = await database
    .ref(
      `billing/currentPackages/${sessionStorage.userId}/dispatchedHeadsets/${headsetDetails.dispatchedHeadsetId}/credit`
    )
    .once('value')
    .then(snapshot => snapshot.val());

  return { ...headsetDetails, dispatchedHeadsetCredit };
};

export const cacheImagesByURIs = (...uris) => {
  return uris
    .filter(uri => !uri.startsWith('data:'))
    .map(
      url =>
        new Promise((resolve, reject) => {
          const img = new window.Image();
          img.src = url;
          img.onload = resolve;
          img.onerror = err => reject(err);
        })
    );
};

export const throwForRequiredParam = paramName => {
  return defensiveThrow({ message: `${paramName} param is required` });
};

export const areTwoElementsOverlapping = (el1, el2) => {
  const el1BoundingRect = el1.getBoundingClientRect();
  const el2BoundingRect = el2.getBoundingClientRect();
  const notOverlapping =
    el1BoundingRect.right < el2BoundingRect.left ||
    el1BoundingRect.left > el2BoundingRect.right ||
    el1BoundingRect.bottom < el2BoundingRect.top ||
    el1BoundingRect.top > el2BoundingRect.bottom;
  return !notOverlapping;
};

export const isATouchDevice = () =>
  window.matchMedia('(pointer: coarse)').matches;

export const snakeUpperCase = str => {
  return snakeCase(str).toUpperCase();
};

export const triggerShareASaleService = (val, orderId) => {
  const img = document.createElement('img');

  img.src = shareASaleEventSrc
    .replace('{{id}}', orderId)
    .replace('{{val}}', val);

  img.width = 1;
  img.height = 1;
  img.border = 0;

  document.body.appendChild(img);

  const script = document.createElement('script');
  script.src = 'https://www.dwin1.com/75564.js';
  script.type = 'text/javascript';
  script.defer = 'defer';

  document.body.appendChild(script);
};

export const detectOS = () => {
  const { userAgentData, userAgent } = window.navigator;

  if (userAgentData && userAgentData.platform) {
    return userAgentData.platform;
  }

  const platforms = [
    { name: 'Windows', regex: /Win/i },
    { name: 'Mac OS', regex: /Mac/i },
    { name: 'iOS', regex: /iPhone|iPad|iPod/i },
    { name: 'Android', regex: /Android/i },
    { name: 'Linux', regex: /Linux/i },
  ];

  const detectedPlatform = platforms.find(platform =>
    platform.regex.test(userAgent)
  );
  return detectedPlatform ? detectedPlatform.name : 'unknown';
};

export const formatNumberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getOnlyNumbersFromString = string => {
  const notANumberRegex = new RegExp(/[^0-9]/g);
  return string.replace(notANumberRegex, '');
};

export const countryFlags = {
  AF: { flag: '🇦🇫', name: 'Afghanistan' },
  AX: { flag: '🇦🇽', name: 'Åland Islands' },
  AL: { flag: '🇦🇱', name: 'Albania' },
  DZ: { flag: '🇩🇿', name: 'Algeria' },
  AS: { flag: '🇦🇸', name: 'American Samoa' },
  AD: { flag: '🇦🇩', name: 'Andorra' },
  AO: { flag: '🇦🇴', name: 'Angola' },
  AI: { flag: '🇦🇮', name: 'Anguilla' },
  AQ: { flag: '🇦🇶', name: 'Antarctica' },
  AG: { flag: '🇦🇬', name: 'Antigua and Barbuda' },
  AR: { flag: '🇦🇷', name: 'Argentina' },
  AM: { flag: '🇦🇲', name: 'Armenia' },
  AW: { flag: '🇦🇼', name: 'Aruba' },
  AU: { flag: '🇦🇺', name: 'Australia' },
  AT: { flag: '🇦🇹', name: 'Austria' },
  AZ: { flag: '🇦🇿', name: 'Azerbaijan' },
  BS: { flag: '🇧🇸', name: 'Bahamas' },
  BH: { flag: '🇧🇭', name: 'Bahrain' },
  BD: { flag: '🇧🇩', name: 'Bangladesh' },
  BB: { flag: '🇧🇧', name: 'Barbados' },
  BY: { flag: '🇧🇾', name: 'Belarus' },
  BE: { flag: '🇧🇪', name: 'Belgium' },
  BZ: { flag: '🇧🇿', name: 'Belize' },
  BJ: { flag: '🇧🇯', name: 'Benin' },
  BM: { flag: '🇧🇲', name: 'Bermuda' },
  BT: { flag: '🇧🇹', name: 'Bhutan' },
  BO: { flag: '🇧🇴', name: 'Bolivia' },
  BA: { flag: '🇧🇦', name: 'Bosnia and Herzegovina' },
  BW: { flag: '🇧🇼', name: 'Botswana' },
  BV: { flag: '🇧🇻', name: 'Bouvet Island' },
  BR: { flag: '🇧🇷', name: 'Brazil' },
  IO: { flag: '🇮🇴', name: 'British Indian Ocean Territory' },
  BN: { flag: '🇧🇳', name: 'Brunei Darussalam' },
  BG: { flag: '🇧🇬', name: 'Bulgaria' },
  BF: { flag: '🇧🇫', name: 'Burkina Faso' },
  BI: { flag: '🇧🇮', name: 'Burundi' },
  KH: { flag: '🇰🇭', name: 'Cambodia' },
  CM: { flag: '🇨🇲', name: 'Cameroon' },
  CA: { flag: '🇨🇦', name: 'Canada' },
  CV: { flag: '🇨🇻', name: 'Cape Verde' },
  KY: { flag: '🇰🇾', name: 'Cayman Islands' },
  CF: { flag: '🇨🇫', name: 'Central African Republic' },
  TD: { flag: '🇹🇩', name: 'Chad' },
  CL: { flag: '🇨🇱', name: 'Chile' },
  CN: { flag: '🇨🇳', name: 'China' },
  CX: { flag: '🇨🇽', name: 'Christmas Island' },
  CC: { flag: '🇨🇨', name: 'Cocos (Keeling) Islands' },
  CO: { flag: '🇨🇴', name: 'Colombia' },
  KM: { flag: '🇰🇲', name: 'Comoros' },
  CG: { flag: '🇨🇬', name: 'Congo' },
  CD: { flag: '🇨🇩', name: 'Congo, Democratic Republic of the' },
  CK: { flag: '🇨🇰', name: 'Cook Islands' },
  CR: { flag: '🇨🇷', name: 'Costa Rica' },
  CI: { flag: '🇨🇮', name: "Cote D'Ivoire" },
  HR: { flag: '🇭🇷', name: 'Croatia' },
  CU: { flag: '🇨🇺', name: 'Cuba' },
  CY: { flag: '🇨🇾', name: 'Cyprus' },
  CZ: { flag: '🇨🇿', name: 'Czech Republic' },
  DK: { flag: '🇩🇰', name: 'Denmark' },
  DJ: { flag: '🇩🇯', name: 'Djibouti' },
  DM: { flag: '🇩🇲', name: 'Dominica' },
  DO: { flag: '🇩🇴', name: 'Dominican Republic' },
  EC: { flag: '🇪🇨', name: 'Ecuador' },
  EG: { flag: '🇪🇬', name: 'Egypt' },
  SV: { flag: '🇸🇻', name: 'El Salvador' },
  GQ: { flag: '🇬🇶', name: 'Equatorial Guinea' },
  ER: { flag: '🇪🇷', name: 'Eritrea' },
  EE: { flag: '🇪🇪', name: 'Estonia' },
  ET: { flag: '🇪🇹', name: 'Ethiopia' },
  FK: { flag: '🇫🇰', name: 'Falkland Islands (Malvinas)' },
  FO: { flag: '🇫🇴', name: 'Faroe Islands' },
  FJ: { flag: '🇫🇯', name: 'Fiji' },
  FI: { flag: '🇫🇮', name: 'Finland' },
  FR: { flag: '🇫🇷', name: 'France' },
  GF: { flag: '🇬🇫', name: 'French Guiana' },
  PF: { flag: '🇵🇫', name: 'French Polynesia' },
  TF: { flag: '🇹🇫', name: 'French Southern Territories' },
  GA: { flag: '🇬🇦', name: 'Gabon' },
  GM: { flag: '🇬🇲', name: 'Gambia' },
  GE: { flag: '🇬🇪', name: 'Georgia' },
  DE: { flag: '🇩🇪', name: 'Germany' },
  GH: { flag: '🇬🇭', name: 'Ghana' },
  GI: { flag: '🇬🇮', name: 'Gibraltar' },
  GR: { flag: '🇬🇷', name: 'Greece' },
  GL: { flag: '🇬🇱', name: 'Greenland' },
  GD: { flag: '🇬🇩', name: 'Grenada' },
  GP: { flag: '🇬🇵', name: 'Guadeloupe' },
  GU: { flag: '🇬🇺', name: 'Guam' },
  GT: { flag: '🇬🇹', name: 'Guatemala' },
  GG: { flag: '🇬🇬', name: 'Guernsey' },
  GN: { flag: '🇬🇳', name: 'Guinea' },
  GW: { flag: '🇬🇼', name: 'Guinea-Bissau' },
  GY: { flag: '🇬🇾', name: 'Guyana' },
  HT: { flag: '🇭🇹', name: 'Haiti' },
  HM: { flag: '🇭🇲', name: 'Heard Island and Mcdonald Islands' },
  VA: { flag: '🇻🇦', name: 'Holy See (Vatican City State)' },
  HN: { flag: '🇭🇳', name: 'Honduras' },
  HK: { flag: '🇭🇰', name: 'Hong Kong' },
  HU: { flag: '🇭🇺', name: 'Hungary' },
  IS: { flag: '🇮🇸', name: 'Iceland' },
  IN: { flag: '🇮🇳', name: 'India' },
  ID: { flag: '🇮🇩', name: 'Indonesia' },
  IR: { flag: '🇮🇷', name: 'Iran, Islamic Republic Of' },
  IQ: { flag: '🇮🇶', name: 'Iraq' },
  IE: { flag: '🇮🇪', name: 'Ireland' },
  IM: { flag: '🇮🇲', name: 'Isle of Man' },
  IL: { flag: '🇮🇱', name: 'Israel' },
  IT: { flag: '🇮🇹', name: 'Italy' },
  JM: { flag: '🇯🇲', name: 'Jamaica' },
  JP: { flag: '🇯🇵', name: 'Japan' },
  JE: { flag: '🇯🇪', name: 'Jersey' },
  JO: { flag: '🇯🇴', name: 'Jordan' },
  KZ: { flag: '🇰🇿', name: 'Kazakhstan' },
  KE: { flag: '🇰🇪', name: 'Kenya' },
  KI: { flag: '🇰🇮', name: 'Kiribati' },
  KP: { flag: '🇰🇵', name: "Korea, Democratic People's Republic of" },
  KR: { flag: '🇰🇷', name: 'Korea, Republic of' },
  KW: { flag: '🇰🇼', name: 'Kuwait' },
  KG: { flag: '🇰🇬', name: 'Kyrgyzstan' },
  LA: { flag: '🇱🇦', name: "Lao People's Democratic Republic" },
  LV: { flag: '🇱🇻', name: 'Latvia' },
  LB: { flag: '🇱🇧', name: 'Lebanon' },
  LS: { flag: '🇱🇸', name: 'Lesotho' },
  LR: { flag: '🇱🇷', name: 'Liberia' },
  LY: { flag: '🇱🇾', name: 'Libyan Arab Jamahiriya' },
  LI: { flag: '🇱🇮', name: 'Liechtenstein' },
  LT: { flag: '🇱🇹', name: 'Lithuania' },
  LU: { flag: '🇱🇺', name: 'Luxembourg' },
  MO: { flag: '🇲🇴', name: 'Macao' },
  MK: { flag: '🇲🇰', name: 'Macedonia, The Former Yugoslav Republic of' },
  MG: { flag: '🇲🇬', name: 'Madagascar' },
  MW: { flag: '🇲🇼', name: 'Malawi' },
  MY: { flag: '🇲🇾', name: 'Malaysia' },
  MV: { flag: '🇲🇻', name: 'Maldives' },
  ML: { flag: '🇲🇱', name: 'Mali' },
  MT: { flag: '🇲🇹', name: 'Malta' },
  MH: { flag: '🇲🇭', name: 'Marshall Islands' },
  MQ: { flag: '🇲🇶', name: 'Martinique' },
  MR: { flag: '🇲🇷', name: 'Mauritania' },
  MU: { flag: '🇲🇺', name: 'Mauritius' },
  YT: { flag: '🇾🇹', name: 'Mayotte' },
  MX: { flag: '🇲🇽', name: 'Mexico' },
  FM: { flag: '🇫🇲', name: 'Micronesia, Federated States of' },
  MD: { flag: '🇲🇩', name: 'Moldova, Republic of' },
  MC: { flag: '🇲🇨', name: 'Monaco' },
  MN: { flag: '🇲🇳', name: 'Mongolia' },
  ME: { flag: '🇲🇪', name: 'Montenegro' },
  MS: { flag: '🇲🇸', name: 'Montserrat' },
  MA: { flag: '🇲🇦', name: 'Morocco' },
  MZ: { flag: '🇲🇿', name: 'Mozambique' },
  MM: { flag: '🇲🇲', name: 'Myanmar' },
  NA: { flag: '🇳🇦', name: 'Namibia' },
  NR: { flag: '🇳🇷', name: 'Nauru' },
  NP: { flag: '🇳🇵', name: 'Nepal' },
  NL: { flag: '🇳🇱', name: 'Netherlands' },
  AN: { flag: '🇦🇳', name: 'Netherlands Antilles' },
  NC: { flag: '🇳🇨', name: 'New Caledonia' },
  NZ: { flag: '🇳🇿', name: 'New Zealand' },
  NI: { flag: '🇳🇮', name: 'Nicaragua' },
  NE: { flag: '🇳🇪', name: 'Niger' },
  NG: { flag: '🇳🇬', name: 'Nigeria' },
  NU: { flag: '🇳🇺', name: 'Niue' },
  NF: { flag: '🇳🇫', name: 'Norfolk Island' },
  MP: { flag: '🇲🇵', name: 'Northern Mariana Islands' },
  NO: { flag: '🇳🇴', name: 'Norway' },
  OM: { flag: '🇴🇲', name: 'Oman' },
  PK: { flag: '🇵🇰', name: 'Pakistan' },
  PW: { flag: '🇵🇼', name: 'Palau' },
  PS: { flag: '🇵🇸', name: 'Palestinian Territory, Occupied' },
  PA: { flag: '🇵🇦', name: 'Panama' },
  PG: { flag: '🇵🇬', name: 'Papua New Guinea' },
  PY: { flag: '🇵🇾', name: 'Paraguay' },
  PE: { flag: '🇵🇪', name: 'Peru' },
  PH: { flag: '🇵🇭', name: 'Philippines' },
  PN: { flag: '🇵🇳', name: 'Pitcairn' },
  PL: { flag: '🇵🇱', name: 'Poland' },
  PT: { flag: '🇵🇹', name: 'Portugal' },
  PR: { flag: '🇵🇷', name: 'Puerto Rico' },
  QA: { flag: '🇶🇦', name: 'Qatar' },
  RE: { flag: '🇷🇪', name: 'Reunion' },
  RO: { flag: '🇷🇴', name: 'Romania' },
  RU: { flag: '🇷🇺', name: 'Russian Federation' },
  RW: { flag: '🇷🇼', name: 'Rwanda' },
  SH: { flag: '🇸🇭', name: 'Saint Helena' },
  KN: { flag: '🇰🇳', name: 'Saint Kitts and Nevis' },
  LC: { flag: '🇱🇨', name: 'Saint Lucia' },
  PM: { flag: '🇵🇲', name: 'Saint Pierre and Miquelon' },
  VC: { flag: '🇻🇨', name: 'Saint Vincent and the Grenadines' },
  WS: { flag: '🇼🇸', name: 'Samoa' },
  SM: { flag: '🇸🇲', name: 'San Marino' },
  ST: { flag: '🇸🇹', name: 'Sao Tome and Principe' },
  SA: { flag: '🇸🇦', name: 'Saudi Arabia' },
  SN: { flag: '🇸🇳', name: 'Senegal' },
  RS: { flag: '🇷🇸', name: 'Serbia' },
  SC: { flag: '🇸🇨', name: 'Seychelles' },
  SL: { flag: '🇸🇱', name: 'Sierra Leone' },
  SG: { flag: '🇸🇬', name: 'Singapore' },
  SK: { flag: '🇸🇰', name: 'Slovakia' },
  SI: { flag: '🇸🇮', name: 'Slovenia' },
  SB: { flag: '🇸🇧', name: 'Solomon Islands' },
  SO: { flag: '🇸🇴', name: 'Somalia' },
  ZA: { flag: '🇿🇦', name: 'South Africa' },
  GS: { flag: '🇬🇸', name: 'South Georgia and the South Sandwich Islands' },
  ES: { flag: '🇪🇸', name: 'Spain' },
  LK: { flag: '🇱🇰', name: 'Sri Lanka' },
  SD: { flag: '🇸🇩', name: 'Sudan' },
  SR: { flag: '🇸🇷', name: 'Suriname' },
  SJ: { flag: '🇸🇯', name: 'Svalbard and Jan Mayen' },
  SZ: { flag: '🇸🇿', name: 'Swaziland' },
  SE: { flag: '🇸🇪', name: 'Sweden' },
  CH: { flag: '🇨🇭', name: 'Switzerland' },
  SY: { flag: '🇸🇾', name: 'Syrian Arab Republic' },
  TW: { flag: '🇹🇼', name: 'Taiwan, Province of China' },
  TJ: { flag: '🇹🇯', name: 'Tajikistan' },
  TZ: { flag: '🇹🇿', name: 'Tanzania, United Republic of' },
  TH: { flag: '🇹🇭', name: 'Thailand' },
  TL: { flag: '🇹🇱', name: 'Timor-Leste' },
  TG: { flag: '🇹🇬', name: 'Togo' },
  TK: { flag: '🇹🇰', name: 'Tokelau' },
  TO: { flag: '🇹🇴', name: 'Tonga' },
  TT: { flag: '🇹🇹', name: 'Trinidad and Tobago' },
  TN: { flag: '🇹🇳', name: 'Tunisia' },
  TR: { flag: '🇹🇷', name: 'Turkey' },
  TM: { flag: '🇹🇲', name: 'Turkmenistan' },
  TC: { flag: '🇹🇨', name: 'Turks and Caicos Islands' },
  TV: { flag: '🇹🇻', name: 'Tuvalu' },
  UG: { flag: '🇺🇬', name: 'Uganda' },
  UA: { flag: '🇺🇦', name: 'Ukraine' },
  AE: { flag: '🇦🇪', name: 'United Arab Emirates' },
  GB: { flag: '🇬🇧', name: 'United Kingdom' },
  'GB-ENG': { flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿', name: 'England' },
  US: { flag: '🇺🇸', name: 'United States' },
  UM: { flag: '🇺🇲', name: 'United States Minor Outlying Islands' },
  UY: { flag: '🇺🇾', name: 'Uruguay' },
  UZ: { flag: '🇺🇿', name: 'Uzbekistan' },
  VU: { flag: '🇻🇺', name: 'Vanuatu' },
  VE: { flag: '🇻🇪', name: 'Venezuela' },
  VN: { flag: '🇻🇳', name: 'Viet Nam' },
  VG: { flag: '🇻🇬', name: 'Virgin Islands, British' },
  VI: { flag: '🇻🇮', name: 'Virgin Islands, U.S.' },
  WF: { flag: '🇼🇫', name: 'Wallis and Futuna' },
  EH: { flag: '🇪🇭', name: 'Western Sahara' },
  YE: { flag: '🇾🇪', name: 'Yemen' },
  ZM: { flag: '🇿🇲', name: 'Zambia' },
  ZW: { flag: '🇿🇼', name: 'Zimbabwe' },
};

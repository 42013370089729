import React, { useState, useImperativeHandle, useRef } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import styles from './AccountRegistrationForm.scss';
import {
  isValidEmail,
  isValidPhone,
  postToFunctionsWithDefaultData,
} from '../../utils/utils';

const createValidator = (...validators) => {
  return val => {
    let mostRecentErrorMsg = null;
    for (let i = 0; i < validators.length; i += 1) {
      if (mostRecentErrorMsg) break;
      const validator = validators[i];
      mostRecentErrorMsg = validator(val);
    }
    return mostRecentErrorMsg;
  };
};

const getRequireValidator = fieldName => val => {
  return !val || val.trim() === '' ? `${fieldName} is required` : null;
};
const getMinLengthValidator = (
  fieldName,
  minLength,
  charType = 'characters'
) => val =>
  val.length < minLength
    ? `${fieldName} should be at least ${minLength} ${charType}`
    : null;
const phoneValidator = val =>
  isValidPhone(val) ? null : 'Invalid phone number';
const emailValidator = val => (isValidEmail(val) ? null : 'Email is not valid');
const getConfirmPasswordValidator = password => confirmPassword =>
  password === confirmPassword
    ? null
    : 'Confirm password is not matching password';

const getStateValidator = country => state =>
  country === 'US' && (!state || state.trim() === '')
    ? 'State  is required'
    : null;

const allValidators = {};
const AccountRegistrationFormBase = ({ shouldShowShippingDetails }, ref) => {
  const [formState, setFormState] = useState({
    values: sessionStorage.formValues
      ? JSON.parse(sessionStorage.formValues)
      : {
          firstName: '',
          shippingCountry: 'US',
        },
    errors: {},
    dirty: {},
    isSubmitted: false,
  });

  const validate = (fieldName, val, validator) => {
    return setFormState(state => {
      const { errors } = state;
      const validationMsg = validator(val);

      const derivedErrors = { ...errors };
      if (!validationMsg) {
        delete derivedErrors[fieldName];
      } else {
        derivedErrors[fieldName] = validationMsg;
      }
      return { ...state, errors: derivedErrors };
    });
  };

  const createChangeCallback = (
    fieldName,
    shippingFromConnection = '',
    validator = () => null
  ) => {
    allValidators[fieldName] = validator;
    return event => {
      const val = event.target.value;

      if (fieldName === 'shippingCountry') {
        window.onCountryChange(val);
      }

      setFormState(state => {
        const { values, dirty } = state;

        return {
          ...state,
          values: {
            ...values,
            [fieldName]: val,
            ...(shouldShowShippingDetails &&
            shippingFromConnection &&
            !formState.dirty[shippingFromConnection]
              ? { [shippingFromConnection]: val }
              : {}),
          },
          dirty: {
            ...dirty,
            [fieldName]: true,
          },
        };
      });
      validate(fieldName, val, validator);
      if (
        shouldShowShippingDetails &&
        shippingFromConnection &&
        !formState.dirty[shippingFromConnection]
      ) {
        validate(
          shippingFromConnection,
          val,
          allValidators[shippingFromConnection]
        );
      }
    };
  };

  const { errors, values } = formState;
  const rootElementRef = useRef(null);

  const validateIfEmailIsUsed = async email => {
    const doesUserExistRequest = await postToFunctionsWithDefaultData({
      path: 'doesUserExist',
      data: { email, isClinic: true },
    });

    if (doesUserExistRequest.exists) {
      setFormState(state => ({
        ...state,
        errors: { ...state.errors, email: 'Email is already used' },
      }));
      return false;
    }
    return true;
  };

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let allFieldsAreValid = true;
      Object.entries(allValidators)
        .filter(([fieldName]) =>
          shouldShowShippingDetails ? true : !fieldName.startsWith('shipping')
        )
        .forEach(([fieldName, validator]) => {
          validate(fieldName, values[fieldName], validator);
          if (validator(values[fieldName]) !== null) {
            allFieldsAreValid = false;
          }
        });
      setFormState(state => ({ ...state, isSubmitted: true }));

      return (
        allFieldsAreValid &&
        Object.values(errors).length === 0 &&
        validateIfEmailIsUsed(values.email)
      );
    },
    getValues: () => values,
  }));

  return (
    <div ref={rootElementRef}>
      {sessionStorage.userId === undefined && (
        <div className={styles.inputs}>
          <h2 className={styles.form_header}>Create your account</h2>
          <div className={styles.inputs_container}>
            <TextField
              label="First name"
              error={formState.isSubmitted && !!errors.firstName}
              helperText={formState.isSubmitted && errors.firstName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.firstName}
              onChange={createChangeCallback(
                'firstName',
                'shippingFirstName',
                createValidator(
                  getRequireValidator('First Name'),
                  getMinLengthValidator('First Name', 1)
                )
              )}
            />
            <TextField
              label="Last name"
              error={formState.isSubmitted && !!errors.lastName}
              helperText={formState.isSubmitted && errors.lastName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.lastName}
              onChange={createChangeCallback(
                'lastName',
                'shippingLastName',
                createValidator(
                  getRequireValidator('Last Name'),
                  getMinLengthValidator('Last Name', 1)
                )
              )}
            />
          </div>
          <div className={styles.inputs_container}>
            <TextField
              label="Phone"
              error={formState.isSubmitted && !!errors.phoneNumber}
              helperText={formState.isSubmitted && errors.phoneNumber}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.phoneNumber}
              onChange={createChangeCallback(
                'phoneNumber',
                'shippingPhoneNumber',
                createValidator(
                  getRequireValidator('Phone'),
                  getMinLengthValidator('Phone', 5, 'numbers'),
                  phoneValidator
                )
              )}
            />
            <TextField
              label="Clinic Name"
              error={formState.isSubmitted && !!errors.clinicName}
              helperText={formState.isSubmitted && errors.clinicName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.clinicName}
              onChange={createChangeCallback(
                'clinicName',
                '',
                createValidator(
                  getRequireValidator('Clinic name'),
                  getMinLengthValidator('Clinic name', 1)
                )
              )}
            />
          </div>

          <div className={styles.inputs_container}>
            <TextField
              label="Email"
              error={formState.isSubmitted && !!errors.email}
              helperText={formState.isSubmitted && errors.email}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.email}
              onChange={createChangeCallback(
                'email',
                'shippingEmail',
                createValidator(getRequireValidator('email'), emailValidator)
              )}
            />
          </div>

          <div className={styles.inputs_container}>
            <TextField
              label="Password"
              error={formState.isSubmitted && !!errors.password}
              helperText={formState.isSubmitted && errors.password}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                type: 'password',
              }}
              value={values.password}
              onChange={createChangeCallback(
                'password',
                '',
                createValidator(
                  getRequireValidator('Password'),
                  getMinLengthValidator('Password', 6)
                )
              )}
            />

            <TextField
              label="Repeat Password"
              error={formState.isSubmitted && !!errors.confirmPassword}
              helperText={formState.isSubmitted && errors.confirmPassword}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                type: 'password',
              }}
              value={values.confirmPassword}
              onChange={createChangeCallback(
                'confirmPassword',
                '',
                createValidator(
                  getRequireValidator('Confirm password'),
                  getConfirmPasswordValidator(values.password)
                )
              )}
            />
          </div>
        </div>
      )}

      {shouldShowShippingDetails && (
        <div className={styles.inputs}>
          <h2 className={styles.form_header}>Shipping details</h2>
          <div className={styles.inputs_container}>
            <TextField
              label="First name"
              error={formState.isSubmitted && !!errors.shippingFirstName}
              helperText={formState.isSubmitted && errors.shippingFirstName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingFirstName}
              onChange={createChangeCallback(
                'shippingFirstName',
                '',
                getRequireValidator('First name')
              )}
            />
            <TextField
              label="Last name"
              error={formState.isSubmitted && !!errors.shippingLastName}
              helperText={formState.isSubmitted && errors.shippingLastName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingLastName}
              onChange={createChangeCallback(
                'shippingLastName',
                '',
                getRequireValidator('Last name')
              )}
            />
          </div>
          <div className={styles.inputs_container}>
            <TextField
              label="Email"
              error={formState.isSubmitted && !!errors.shippingEmail}
              helperText={formState.isSubmitted && errors.shippingEmail}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingEmail}
              onChange={createChangeCallback(
                'shippingEmail',
                '',
                createValidator(getRequireValidator('email'), emailValidator)
              )}
            />
            <TextField
              label="Phone"
              error={formState.isSubmitted && !!errors.shippingPhoneNumber}
              helperText={formState.isSubmitted && errors.shippingPhoneNumber}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingPhoneNumber}
              onChange={createChangeCallback(
                'shippingPhoneNumber',
                '',
                createValidator(
                  getRequireValidator('Phone'),
                  getMinLengthValidator('Phone', 5, 'numbers'),
                  phoneValidator
                )
              )}
            />
          </div>

          <div className={styles.inputs_container}>
            <TextField
              label="Country"
              error={formState.isSubmitted && !!errors.shippingCountry}
              helperText={formState.isSubmitted && errors.shippingCountry}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingCountry}
              select
              SelectProps={{
                native: true,
              }}
              onChange={createChangeCallback('shippingCountry')}
            >
              <option value="AF">Afghanistan</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AG">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AG">Antigua &amp; Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AA">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BL">Bonaire</option>
              <option value="BA">Bosnia &amp; Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BR">Brazil</option>
              <option value="BC">British Indian Ocean Ter</option>
              <option value="BN">Brunei</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="IC">Canary Islands</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CD">Channel Islands</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CI">Christmas Island</option>
              <option value="CS">Cocos Island</option>
              <option value="CO">Colombia</option>
              <option value="CC">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CT">Cote D'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CB">Curacao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="TM">East Timor</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FA">Falkland Islands</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="FS">French Southern Ter</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GB">Great Britain</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GN">Guinea</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HW">Hawaii</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IA">Iran</option>
              <option value="IQ">Iraq</option>
              <option value="IR">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="NK">Korea North</option>
              <option value="KS">Korea South</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Laos</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macau</option>
              <option value="MK">Macedonia</option>
              <option value="MG">Madagascar</option>
              <option value="MY">Malaysia</option>
              <option value="MW">Malawi</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="ME">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="MI">Midway Islands</option>
              <option value="MD">Moldova</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Nambia</option>
              <option value="NU">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="AN">Netherland Antilles</option>
              <option value="NL">Netherlands (Holland, Europe)</option>
              <option value="NV">Nevis</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NW">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau Island</option>
              <option value="PS">Palestine</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PO">Pitcairn Island</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="ME">Republic of Montenegro</option>
              <option value="RS">Republic of Serbia</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russia</option>
              <option value="RW">Rwanda</option>
              <option value="NT">St Barthelemy</option>
              <option value="EU">St Eustatius</option>
              <option value="HE">St Helena</option>
              <option value="KN">St Kitts-Nevis</option>
              <option value="LC">St Lucia</option>
              <option value="MB">St Maarten</option>
              <option value="PM">St Pierre &amp; Miquelon</option>
              <option value="VC">St Vincent &amp; Grenadines</option>
              <option value="SP">Saipan</option>
              <option value="SO">Samoa</option>
              <option value="AS">Samoa American</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome &amp; Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="OI">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syria</option>
              <option value="TA">Tahiti</option>
              <option value="TW">Taiwan</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania</option>
              <option value="TH">Thailand</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad &amp; Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TU">Turkmenistan</option>
              <option value="TC">Turks &amp; Caicos Is</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States of America</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VS">Vatican City State</option>
              <option value="VE">Venezuela</option>
              <option value="VN">Vietnam</option>
              <option value="VB">Virgin Islands (Brit)</option>
              <option value="VA">Virgin Islands (USA)</option>
              <option value="WK">Wake Island</option>
              <option value="WF">Wallis &amp; Futana Is</option>
              <option value="YE">Yemen</option>
              <option value="ZR">Zaire</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </TextField>

            <TextField
              label="State"
              error={formState.isSubmitted && !!errors.shippingState}
              helperText={formState.isSubmitted && errors.shippingState}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingState}
              onChange={createChangeCallback(
                'shippingState',
                '',
                getStateValidator(values.shippingCountry)
              )}
            />
          </div>

          <div className={styles.inputs_container}>
            <TextField
              label="City"
              error={formState.isSubmitted && !!errors.shippingCity}
              helperText={formState.isSubmitted && errors.shippingCity}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingCity}
              onChange={createChangeCallback(
                'shippingCity',
                '',
                getRequireValidator('City')
              )}
            />

            <TextField
              label="Street"
              error={formState.isSubmitted && !!errors.shippingStreet}
              helperText={formState.isSubmitted && errors.shippingStreet}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingStreet}
              placeholder="Street, P.O Box, etc..."
              onChange={createChangeCallback(
                'shippingStreet',
                '',
                getRequireValidator('Street')
              )}
            />
          </div>

          <div className={styles.inputs_container}>
            <TextField
              label="Apartment"
              error={formState.isSubmitted && !!errors.shippingApartment}
              helperText={formState.isSubmitted && errors.shippingApartment}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingApartment}
              placeholder="Ap. Floor, etc.. (optional)"
              onChange={createChangeCallback('shippingApartment')}
            />

            <TextField
              label="Zip"
              error={formState.isSubmitted && !!errors.shippingZip}
              helperText={formState.isSubmitted && errors.shippingZip}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: styles.form_helper_text,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.shippingZip}
              placeholder="Zip/Postal Code"
              onChange={createChangeCallback(
                'shippingZip',
                '',
                getRequireValidator('Zip')
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const AccountRegistrationForm = React.memo(
  React.forwardRef(AccountRegistrationFormBase)
);
AccountRegistrationFormBase.defaultProps = {
  shouldShowShippingDetails: true,
};

AccountRegistrationFormBase.propTypes = exact({
  shouldShowShippingDetails: PropTypes.bool,
});
AccountRegistrationForm.displayName = 'AccountRegistrationForm';

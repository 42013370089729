exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ConfirmDeletingDoneAssessment_size_17{font-size:17px}.ConfirmDeletingDoneAssessment_size_10{font-size:10px}.ConfirmDeletingDoneAssessment_size_11{font-size:11px}.ConfirmDeletingDoneAssessment_size_13{font-size:13px}.ConfirmDeletingDoneAssessment_size_15{font-size:15px}.ConfirmDeletingDoneAssessment_size_14{font-size:14px}.ConfirmDeletingDoneAssessment_size_16{font-size:16px}.ConfirmDeletingDoneAssessment_size_12{font-size:12px}.ConfirmDeletingDoneAssessment_extra_header{font-size:18px;font-weight:500;color:#000000}.ConfirmDeletingDoneAssessment_header{font-size:17px;font-weight:500;color:#000000}.ConfirmDeletingDoneAssessment_header_only_font_size{font-size:17px;font-weight:500}.ConfirmDeletingDoneAssessment_sub_header{font-size:13px;color:#878787}.ConfirmDeletingDoneAssessment_sub_header_black,.ConfirmDeletingDoneAssessment_dialog_header{font-size:13px;color:black}.ConfirmDeletingDoneAssessment_sub_header_only_font_size{font-size:13px}.ConfirmDeletingDoneAssessment_paragraph_only_font_size{font-size:12px}.ConfirmDeletingDoneAssessment_tab_font_size{font-size:13px}.ConfirmDeletingDoneAssessment_pop_out_header{font-size:20px}.ConfirmDeletingDoneAssessment_title{font-size:15px;font-weight:500;color:#484848}.ConfirmDeletingDoneAssessment_title_black{font-size:15px;font-weight:500;color:black}.ConfirmDeletingDoneAssessment_title_only_font_size{font-size:15px}.ConfirmDeletingDoneAssessment_paragraph{font-size:12px;color:#484848}.ConfirmDeletingDoneAssessment_btn{font-size:14px}.ConfirmDeletingDoneAssessment_dialog_header{font-weight:500;text-align:left;margin-bottom:20px}.ConfirmDeletingDoneAssessment_content{padding:24px 70px !important;max-width:420px;box-sizing:content-box}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ConfirmDeletingDoneAssessment_size_17",
	"size_10": "ConfirmDeletingDoneAssessment_size_10",
	"size_11": "ConfirmDeletingDoneAssessment_size_11",
	"size_13": "ConfirmDeletingDoneAssessment_size_13",
	"size_15": "ConfirmDeletingDoneAssessment_size_15",
	"size_14": "ConfirmDeletingDoneAssessment_size_14",
	"size_16": "ConfirmDeletingDoneAssessment_size_16",
	"size_12": "ConfirmDeletingDoneAssessment_size_12",
	"extra_header": "ConfirmDeletingDoneAssessment_extra_header",
	"header": "ConfirmDeletingDoneAssessment_header",
	"header_only_font_size": "ConfirmDeletingDoneAssessment_header_only_font_size",
	"sub_header": "ConfirmDeletingDoneAssessment_sub_header",
	"sub_header_black": "ConfirmDeletingDoneAssessment_sub_header_black",
	"dialog_header": "ConfirmDeletingDoneAssessment_dialog_header",
	"sub_header_only_font_size": "ConfirmDeletingDoneAssessment_sub_header_only_font_size",
	"paragraph_only_font_size": "ConfirmDeletingDoneAssessment_paragraph_only_font_size",
	"tab_font_size": "ConfirmDeletingDoneAssessment_tab_font_size",
	"pop_out_header": "ConfirmDeletingDoneAssessment_pop_out_header",
	"title": "ConfirmDeletingDoneAssessment_title",
	"title_black": "ConfirmDeletingDoneAssessment_title_black",
	"title_only_font_size": "ConfirmDeletingDoneAssessment_title_only_font_size",
	"paragraph": "ConfirmDeletingDoneAssessment_paragraph",
	"btn": "ConfirmDeletingDoneAssessment_btn",
	"content": "ConfirmDeletingDoneAssessment_content"
};
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import { withEntryComponent } from '../../../Core/hocs/withEntryComponent/withEntryComponent';
import startNewSessionCardPng from '../../../../assets/create-or-edit-program.png';
import addClientPng from '../../../../assets/open-add-client.png';
// import viewRunningSessionsPng from '../../../../assets/view-running-sessions.png';
// import goToAcademyPng from '../../../../assets/open-myndlift-academy.png';
import goToShopPng from '../../../../assets/get-hardware.png';
// import goToGrowthToolkitPng from '../../../../assets/go-to-growth-toolkit.png';
import accountAndBillingPng from '../../../../assets/account-billing.png';
import allClientsPng from '../../../../assets/see-clients.png';
import goToCircleJpg from '../../../../assets/communityCardHeader.jpg';
import styles from './HomePageActionCards.scss';
import { PACKAGES_TYPES } from '../../../models/packages/packages';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { SelfRegistrationDialog } from '../../SelfRegistrationDialog/SelfRegistrationDialog';

const homePageEventLogger = new MyEventWrapper('home_page');

const HomePageActionCardsBase = ({ t }) => {
  const [isRegistrationDialogOpen, setIsRegistrationDialogOpen] = useState(
    false
  );
  const [reachedClientsLimit] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/reachedClientsLimit`,
  });

  const [usersIncluded] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/usersIncluded`,
  });

  const [clinicNonfPlan] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}`,
  });

  const [clinicUserInfo] = useFirebaseDB({
    path: `userInfo/${sessionStorage.userId}`,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/nfAddOnPlanId`,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/packageType`,
  });

  const [assessmentAutoDeployableConfigs] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedAssessment/content`,
  });

  const [questionnairesAutoDeployableConfigs] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedQuestionnaires/content`,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  const cards = [
    {
      header: t('create_or_edit_program'),
      subHeader: t('create_or_edit_program_explanation'),
      id: 'react_create_or_edit_program_card',
      imgSrc: startNewSessionCardPng,
      hide: !nfFlag,
    },
    {
      header: t('add-new-client'),
      subHeader: t('fill-client-details-and-go'),
      id: 'react_add_new_client_prev',
      imgSrc: addClientPng,
      onRootClick: () => {
        homePageEventLogger.log('add_new_client');
        if (
          sessionStorage.packageType ===
            PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE ||
          sessionStorage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE
        ) {
          showNotification(
            'danger',
            'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
          );
          return;
        }
        setIsRegistrationDialogOpen(true);
      },
    },
    {
      header: 'See All Clients',
      subHeader:
        'View your full list of clients to see their progress, data, as well as create assessments and programs.',
      id: 'go_to_customers',
      imgSrc: allClientsPng,
      onRootClick: () => {
        if (sessionStorage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE) {
          showNotification(
            'danger',
            'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
          );
          return;
        }
        window.open('/customers.html', '_self');
      },
    },
    {
      header: 'Account & Billing',
      subHeader:
        'View your usage, billing history, upgrade your plan, and more.',
      id: 'go_to_billing',
      imgSrc: accountAndBillingPng,
      onRootClick: () => window.open('/new_account.html', '_self'),
      hide: sessionStorage.isTeamMember === 'true',
    },
    // {
    //   header: t('view-running-sessions'),
    //   subHeader: t('view-running-sessions-desc'),
    //   id: 'view_running_sessions',
    //   imgSrc: viewRunningSessionsPng,
    //   onRootClick: () => {
    //     if (
    //       sessionStorage.packageType ===
    //         PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE ||
    //       sessionStorage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE
    //     ) {
    //       showNotification(
    //         'danger',
    //         'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
    //       );
    //       return;
    //     }
    //     goToRunningSessions();
    //   },
    //   hide: !nfFlag,
    // },
    // {
    //   header: t('growth-toolkit'),
    //   subHeader: t('growth-toolkit-desc'),
    //   id: 'go_to_growth_toolkit',
    //   imgSrc: goToGrowthToolkitPng,
    //   onRootClick: goToResourcesPage,
    //   hide: !nfFlag,
    // },
    // {
    //   header: 'Practice Development',
    //   subHeader: t('myndlift-academy-desc'),
    //   id: 'go_to_academy',
    //   imgSrc: goToAcademyPng,
    //   onRootClick: goToAcademy,
    //   isNew: true,
    // },
    {
      header: 'Community',
      subHeader: t('myndlift-community-desc'),
      id: 'go_to_circle',
      imgSrc: goToCircleJpg,
      // eslint-disable-next-line
      onRootClick: goToCircle,
      isNew: true,
    },
    {
      header: t('get-new-hardware'),
      subHeader: t('get-new-hardware-desc'),
      id: 'go_to_shop',
      imgSrc: goToShopPng,
      onRootClick: () => window.open('/shop/shop-hardware.html', '_self'),
      hide: !nfFlag,
    },
  ];

  const onViewPricingPlansClicked = async () => {
    await new MyEvent('view_pricing_plans_clicked').log({
      from_where: 'home_page',
    });
    window.open('new_account.html', '_self');
  };

  return (
    <div className={styles.cards_container}>
      {cards.map(card => {
        if (!card.hide) {
          return (
            <div id={card.id} className={styles.card_container} key={card.id}>
              <React.Fragment>
                <div
                  onClick={async () => {
                    await new MyEvent('home_page_card_clicked').log({
                      card_type: card.header,
                    });
                    // eslint-disable-next-line no-unused-expressions
                    if (card.onRootClick) {
                      card.onRootClick();
                    }
                  }}
                  className={styles.card_content}
                >
                  <img
                    src={card.imgSrc}
                    loading="lazy"
                    width="255"
                    height="85"
                  />
                  <div className={styles.card_text_content}>
                    <p>
                      {card.header}
                      {card.isNew ? (
                        <span className={styles.card_new_label}>NEW</span>
                      ) : null}
                    </p>
                    <p>{card.subHeader}</p>
                  </div>
                </div>
              </React.Fragment>
            </div>
          );
        }
        return null;
      })}
      {clinicUserInfo && clinicUserInfo.registrationCode && (
        <SelfRegistrationDialog
          isOpen={isRegistrationDialogOpen}
          onClose={() => {
            setIsRegistrationDialogOpen(false);
          }}
          registrationCode={clinicUserInfo && clinicUserInfo.registrationCode}
          onAddClientClicked={() => {
            // eslint-disable-next-line no-undef
            openAddClientModal({
              nfFlag,
              assessmentAutoDeployableConfigs,
              questionnairesAutoDeployableConfigs,
            });
          }}
          reachedClientsLimit={reachedClientsLimit}
          usersIncluded={usersIncluded}
          onViewPricingPlansClicked={onViewPricingPlansClicked}
          clinicNonfPlan={clinicNonfPlan}
        />
      )}
    </div>
  );
};

HomePageActionCardsBase.propTypes = exact({
  t: PropTypes.func.isRequired,
});

export const HomePageActionCards = withEntryComponent(
  withTranslation(HomePageActionCardsBase)
);

import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Button,
  Checkbox,
  TextField,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import { LagoListItem } from '../LagoListItem/LagoListItem';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import placeHolder from '../../../../assets/add-photo.png';

import styles from './LagoGoalConfig.scss';

const lagoEventLogger = new MyEventWrapper('lago');

const LagoGoalConfigBase = ({
  open,
  onClose,
  goalLesson,
  goalLessonId,
  setGoalEditDialogOpen,
  screensItemsData,
  addScreensItemsData,
  lessonsItemsData,
  // setLessonsItemsData,
  // list item
  onItemClicked,
  setKeyToDelete,
  setDisplayDeleteDialog,
  currentStage,
  onEditItemClicked,
  onAddNewVariantClicked,
  editVariant,
  variantClicked,
  tags,
  parseTags,
  setDisplayUploadedImages,
  isLoadingVideo,
  handleOnDragEnd,
  journeyId,
  isFinalizedState,
}) => {
  const [titleValue, setTitleValue] = useState('');
  const [instructionsValue, setInstructionsValue] = useState('');
  const [itemLabelValue, setItemLabelValue] = useState('');
  const [itemLabels, setItemLabels] = useState([]);
  const [reminderQuestionText, setReminderQuestionText] = useState('');
  const [notificationTitleText, setNotificationTitleText] = useState('');
  const [notificationBodyText, setNotificationBodyText] = useState('');
  const [saveReminderTextBtn, setSaveReminderTextBtn] = useState(
    'save reminder'
  );
  const [presetScreenId, setPresetScreenId] = useState('');
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [currentEditorState, setCurrentEditorState] = useState(
    EditorState.createEmpty()
  );
  const [reminderDialog, setReminderDialog] = useState(false);
  const [goalSettingsDialog, setGoalSettingsDialog] = useState(false);
  const [goalViewDialog, setGoalViewDialog] = useState(false);
  const [goalSettingsChecked, setGoalSettingsChecked] = useState(false);

  const resetState = () => {
    setTitleValue('');
    setInstructionsValue('');
    setItemLabelValue('');
    setItemLabels([]);
    setReminderQuestionText('');
    setNotificationTitleText('');
    setNotificationBodyText('');
    setPresetScreenId('');
    setCurrentEditorState(EditorState.createEmpty());
  };

  const [goals, setGoals] = useFirebaseDB({
    path: 'lago/educational/defaults/goals',
  });

  const PRESET_SCREEN_INDEX = 10000;
  const LENGTH_EDITOR_STATE_VALIDATION = 8;

  const [reminders, setReminders, addReminders] = useFirebaseDB({
    path: 'lago/educational/defaults/reminders',
  });

  const [presetScreenData, setPresetScreenData] = useFirebaseDB({
    path: `lago/educational/defaults/screens/${presetScreenId}`,
    initialValue: [],
  });

  const calcSettingsChecked = () => {
    if (goalLesson && goalLesson.screens) {
      const screensIds = Object.keys(goalLesson.screens);
      const numOfScreensCheck = screensIds.filter(
        screensId => goalLesson.screens[screensId] !== PRESET_SCREEN_INDEX
      );
      if (numOfScreensCheck.length >= 1) {
        setGoalSettingsChecked(true);
      } else {
        setGoalSettingsChecked(false);
      }
    } else {
      setGoalSettingsChecked(false);
    }
  };

  const stringToHtml = str =>
    `<p style="font-size: 24px;font-family: Avenir;">${str}</p>`;

  useEffect(() => {
    if (goalLesson && open) {
      calcSettingsChecked();
      if (goals[goalLesson.goalId].reminderId) {
        setNotificationTitleText(
          reminders[goals[goalLesson.goalId].reminderId].title.en[0]
        );
        setNotificationBodyText(
          reminders[goals[goalLesson.goalId].reminderId].body.en[0]
        );

        let presetScreenId1;
        let presetScreenVariantId;
        Object.keys(goalLesson.screens).forEach(screenID => {
          if (goalLesson.screens[screenID] === PRESET_SCREEN_INDEX) {
            presetScreenId1 = screenID;
            // eslint-disable-next-line prefer-destructuring
            presetScreenVariantId = Object.keys(
              screensItemsData[screenID].variants
            )[0];
          }
        });
        setReminderQuestionText(
          screensItemsData[presetScreenId1].variants[presetScreenVariantId]
            .title.en
        );
        setPresetScreenId(presetScreenId1);
      }
      if (goals[goalLesson.goalId]) {
        if (
          goals[goalLesson.goalId].instructions &&
          goals[goalLesson.goalId].instructions.en
        ) {
          setInstructionsValue(goals[goalLesson.goalId].instructions.en);
        }
        if (
          goals[goalLesson.goalId].title &&
          goals[goalLesson.goalId].title.en
        ) {
          setTitleValue(goals[goalLesson.goalId].title.en);
        }
        if (
          goals[goalLesson.goalId].labels &&
          goals[goalLesson.goalId].labels.en
        ) {
          setItemLabels(goals[goalLesson.goalId].labels.en);
          if (
            goals[goalLesson.goalId].labels.en.length === 1 &&
            !goals[goalLesson.goalId].labels.en[0].answer &&
            !goals[goalLesson.goalId].labels.en[0].subTitle
          ) {
            setItemLabels([]);
          }
        }
      }
    }
  }, [goalLesson, open, goalSettingsDialog, goalViewDialog]);

  useEffect(() => {
    if (tags) {
      const suggestions = [];
      Object.keys(tags).forEach(tagKey => {
        if (tags[tagKey].journeyId === journeyId) {
          suggestions.push({
            text: tags[tagKey].tagName.en,
            value: tags[tagKey].tagName.en,
            // eslint-disable-next-line no-script-url
            url: `javascript:function a() {const tagId=${tagKey} return false; }`,
          });
        }
      });
      setMentionSuggestions(suggestions);
    }
  }, [tags]);

  const onAddItemLabel = () => {
    const copy = [...itemLabels];
    copy.push({
      subTitle: itemLabelValue,
      answer: draftToHtml(convertToRaw(currentEditorState.getCurrentContent())),
      answerTag: parseTags(
        convertToRaw(currentEditorState.getCurrentContent()).entityMap
      ),
    });
    setItemLabels(copy);
    setItemLabelValue('');
    setCurrentEditorState(EditorState.createEmpty());
  };

  const onDeleteLabelClicked = index => {
    const copy = [...itemLabels];
    copy.splice(index, 1);
    setItemLabels(copy);
  };

  const saveGoalView = () => {
    const copy = JSON.parse(JSON.stringify(goals));
    copy[goalLesson.goalId].title.en = titleValue;
    copy[goalLesson.goalId].instructions.en = instructionsValue;
    copy[goalLesson.goalId].labels.en = itemLabels;
    if (
      goalLesson &&
      goalLesson.screens &&
      Object.keys(goalLesson.screens).length > 1
    ) {
      copy[goalLesson.goalId].isEnabled = true;
    }
    setGoals(copy);
    lagoEventLogger.log('goal_clicked');
  };

  const REMINDER_SCREEN_ANSWERS = [
    '7:00 AM',
    '10:00 AM',
    '12:00 PM',
    '4:00 PM',
    '8:00 PM',
    "Don't remind me",
  ];

  const onSaveReminder = () => {
    if (!goals[goalLesson.goalId].reminderId) {
      const variantKey = Math.random()
        .toString(36)
        .substring(7);
      const answerOptions = {};
      for (let i = 0; i < REMINDER_SCREEN_ANSWERS.length; i += 1) {
        const answerId = Math.random()
          .toString(36)
          .substring(7);
        answerOptions[answerId] = {
          index: i,
          txt: { en: REMINDER_SCREEN_ANSWERS[i] },
        };
      }
      const screenToAdd = {
        variants: {
          [variantKey]: {
            variantConfig: {
              variantTitle: 'default',
              isDefaultVariant: true,
              conditions: [],
            },
            html: { en: stringToHtml(reminderQuestionText) },
            title: { en: reminderQuestionText },
            screenType: 'quiz',
            btnText: { en: 'Continue' },
            quizDetails: {
              quizType: 'reminder',
              answerOptions,
            },
          },
        },
      };
      const addedItem = addScreensItemsData(screenToAdd);
      const lessonsCopy = JSON.parse(
        JSON.stringify(lessonsItemsData[goalLessonId])
      );
      if (!lessonsCopy.screens) {
        lessonsCopy.screens = {};
      }
      lessonsCopy.screens[addedItem.key] = PRESET_SCREEN_INDEX;
      database
        .ref(`lago/educational/defaults/lessons/${goalLessonId}`)
        .set(lessonsCopy);

      const addedReminder = addReminders({
        title: { en: [notificationTitleText] },
        body: { en: [notificationBodyText] },
        isEnabled: true,
      });
      const goalsCopy = JSON.parse(JSON.stringify(goals));
      goalsCopy[goalLesson.goalId].reminderId = addedReminder.key;
      setGoals(goalsCopy);
    } else {
      const remindersCopy = JSON.parse(JSON.stringify(reminders));
      remindersCopy[goals[goalLesson.goalId].reminderId] = {
        title: { en: [notificationTitleText] },
        body: { en: [notificationBodyText] },
        isEnabled: true,
      };
      setReminders(remindersCopy);
      let presetScreenVariantId;
      Object.keys(goalLesson.screens).forEach(screenID => {
        if (goalLesson.screens[screenID] === PRESET_SCREEN_INDEX) {
          // eslint-disable-next-line prefer-destructuring
          presetScreenVariantId = Object.keys(
            screensItemsData[screenID].variants
          )[0];
        }
      });
      const presetScreenDataCopy = JSON.parse(JSON.stringify(presetScreenData));
      presetScreenDataCopy.variants[
        presetScreenVariantId
      ].title.en = reminderQuestionText;
      presetScreenDataCopy.variants[
        presetScreenVariantId
      ].html.en = stringToHtml(reminderQuestionText);
      setPresetScreenData(presetScreenDataCopy);
    }
    setSaveReminderTextBtn('saved!');
    setTimeout(() => {
      setSaveReminderTextBtn('save reminder');
    }, 1000);
  };

  const IMAGE_PATH_PREFIX = 'lago/educational/defaults/images/';

  const onImageFileChange = async event => {
    const path =
      IMAGE_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const [file] = event.target.files;
    // setImageFile(file);
    const storage = window.firebase.storage();
    const imageRef = storage.ref(path);
    try {
      await imageRef.put(file);
      imageRef
        .getDownloadURL()
        .then(res => {
          const copy = JSON.parse(JSON.stringify(goals));
          copy[goalLesson.goalId].img = res;
          setGoals(copy);
        })
        .catch(() => {});
    } catch (err) {
      // TODO
    }
  };

  const removeImage = () => {
    const copy = JSON.parse(JSON.stringify(goals));
    copy[goalLesson.goalId].img = '';
    setGoals(copy);
    const el = document.getElementById('image-input-goal');
    el.value = '';
  };

  const toggleReminderEnableClicked = checked => {
    const copy = JSON.parse(JSON.stringify(reminders));
    copy[goals[goalLesson.goalId].reminderId].isEnabled = checked;
    setReminders(copy);
  };

  let sortedGoalLessons = [];
  if (goalLesson && goalLesson.screens) {
    sortedGoalLessons = Object.keys(goalLesson.screens);
    sortedGoalLessons.sort(
      (a, b) => goalLesson.screens[a] - goalLesson.screens[b]
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetState();
        onClose();
      }}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '20px' }}>Goal config</span>
      </DialogTitle>
      <DialogContent>
        <div>
          <Checkbox disabled checked={goalSettingsChecked} />
          Goal setting
          <span
            onClick={() => setGoalSettingsDialog(true)}
            style={{ cursor: 'pointer', marginLeft: '5px', color: '#2296f3' }}
          >
            Set
          </span>
        </div>
        <div>
          <Checkbox
            disabled
            checked={
              goalLesson &&
              !!(
                goals[goalLesson.goalId].instructions &&
                goals[goalLesson.goalId].instructions.en &&
                goals[goalLesson.goalId].title &&
                goals[goalLesson.goalId].title.en &&
                goals[goalLesson.goalId].labels &&
                goals[goalLesson.goalId].labels.en &&
                goals[goalLesson.goalId].labels.en.length
              )
            }
          />
          Goal view
          <span
            onClick={() => setGoalViewDialog(true)}
            style={{ cursor: 'pointer', marginLeft: '5px', color: '#2296f3' }}
          >
            Set
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetState();
            onClose();
          }}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          color="primary"
        >
          close
        </Button>
      </DialogActions>
      <Dialog
        open={goalSettingsDialog}
        onClose={() => {
          setGoalSettingsDialog(false);
        }}
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle style={{ textAlign: 'center', paddingBottom: 0 }}>
          <span style={{ fontSize: '20px', textAlign: 'center' }}>
            Goal settings
          </span>
        </DialogTitle>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px',
            }}
          >
            <Button
              onClick={setGoalEditDialogOpen}
              style={{ maxWidth: '100px', margin: '10px' }}
              variant="contained"
              disabled={isLoadingVideo || isFinalizedState}
            >
              add screen
            </Button>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId={screensItemsData}>
                {provided => (
                  <div
                    // className={styles.lago_control_list_container}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: '50%' }}
                  >
                    {goalLesson && goalLesson.screens
                      ? sortedGoalLessons.map((screenId, index) => {
                          return (
                            <Draggable
                              key={screenId}
                              draggableId={screenId}
                              index={
                                goalLesson &&
                                goalLesson.screens &&
                                goalLesson.screens[screenId]
                                  ? goalLesson.screens[screenId]
                                  : index
                              }
                            >
                              {provided1 => (
                                <div
                                  ref={provided1.innerRef}
                                  {...provided1.draggableProps}
                                  {...provided1.dragHandleProps}
                                >
                                  {goalLesson.screens[screenId] ===
                                  PRESET_SCREEN_INDEX ? null : (
                                    <LagoListItem
                                      key={screenId}
                                      element={screensItemsData[screenId]}
                                      onItemClicked={() =>
                                        onItemClicked(screenId)
                                      }
                                      onDeleteItemClicked={e => {
                                        e.stopPropagation();
                                        setKeyToDelete(screenId);
                                        setDisplayDeleteDialog(true);
                                      }}
                                      currentStage={currentStage}
                                      onEditItemClicked={onEditItemClicked}
                                      onAddNewVariantClicked={e =>
                                        onAddNewVariantClicked(e, screenId)
                                      }
                                      editVariant={editVariant}
                                      variantClicked={variantClicked}
                                      // eslint-disable-next-line react/jsx-boolean-value
                                      isGoalEdit={true}
                                      isLoadingVideo={isLoadingVideo}
                                      isFinalizedState={isFinalizedState}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isLoadingVideo ? <CircularProgress /> : null}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Checkbox
                  disabled
                  checked={
                    goalLesson &&
                    !!(
                      goals[goalLesson.goalId] &&
                      goals[goalLesson.goalId].reminderId &&
                      reminders[goals[goalLesson.goalId].reminderId].isEnabled
                    )
                  }
                  color="primary"
                /> */}
                <div>Reminder screen</div>
                {goalLesson && goals[goalLesson.goalId].reminderId ? (
                  <Switch
                    disabled={isFinalizedState}
                    checked={
                      reminders[goals[goalLesson.goalId].reminderId].isEnabled
                    }
                    onChange={e =>
                      toggleReminderEnableClicked(e.target.checked)
                    }
                    color="primary"
                  />
                ) : null}
                <div
                  onClick={() => {
                    if (!isLoadingVideo) {
                      setReminderDialog(true);
                    }
                  }}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                >
                  {goalLesson && goals[goalLesson.goalId].reminderId ? (
                    <EditIcon />
                  ) : (
                    <AddIcon />
                  )}
                </div>
              </div>
              <Dialog
                open={reminderDialog}
                onClose={() => setReminderDialog(false)}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '30px',
                  }}
                >
                  <div style={{ marginBottom: '5px' }}>
                    Set reminder question:
                  </div>
                  <TextField
                    label="Set reminder question"
                    value={reminderQuestionText}
                    onChange={e => setReminderQuestionText(e.target.value)}
                    disabled={isFinalizedState}
                  />
                  <div style={{ marginTop: '20px', marginBottom: '5px' }}>
                    Notification text:
                  </div>
                  <TextField
                    label="Notification text title"
                    value={notificationTitleText}
                    onChange={e => setNotificationTitleText(e.target.value)}
                    disabled={isFinalizedState}
                  />
                  <TextField
                    label="Notification text body"
                    value={notificationBodyText}
                    onChange={e => setNotificationBodyText(e.target.value)}
                    disabled={isFinalizedState}
                  />
                  <Button
                    onClick={() => {
                      onSaveReminder();
                      setReminderDialog(false);
                    }}
                    disabled={
                      !reminderQuestionText ||
                      !notificationTitleText ||
                      isFinalizedState
                    }
                  >
                    {saveReminderTextBtn}
                  </Button>
                  <Button onClick={() => setReminderDialog(false)}>
                    cancel
                  </Button>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            setGoalSettingsDialog(false);
            calcSettingsChecked();
          }}
          style={{
            width: '100px',
            height: '50px',
            fontSize: '16px',
            alignSelf: 'flex-end',
            marginBottom: '10px',
            marginRight: '10px',
          }}
          color="primary"
          disabled={isLoadingVideo}
        >
          close
        </Button>
      </Dialog>
      <Dialog
        open={goalViewDialog}
        onClose={() => {
          setGoalViewDialog(false);
        }}
        fullWidth
      >
        <DialogTitle style={{ textAlign: 'center', paddingBottom: 0 }}>
          <span style={{ fontSize: '20px', textAlign: 'center' }}>
            Goal view
          </span>
        </DialogTitle>
        <div
          style={{ display: 'flex', flexDirection: 'column', padding: '40px' }}
        >
          <div className={styles.upload_image_container}>
            <img
              className={styles.lago_control_img}
              src={
                goalLesson &&
                goals &&
                goals[goalLesson.goalId] &&
                goals[goalLesson.goalId].img
                  ? goals[goalLesson.goalId].img
                  : placeHolder
              }
              alt="placeHolder"
            />
            <div className={styles.upload_image_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-input-goal').click();
                }}
                color="primary"
                variant="contained"
                style={{ maxWidth: '120px' }}
                disabled={isFinalizedState}
              >
                upload image
              </Button>
              <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px', maxWidth: '120px' }}
                disabled={isFinalizedState}
              >
                select uploaded
              </Button>
              {goalLesson &&
              goals &&
              goals[goalLesson.goalId] &&
              goals[goalLesson.goalId].img ? (
                <Button
                  onClick={() => {
                    removeImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px', maxWidth: '120px' }}
                  disabled={isFinalizedState}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
          <input
            accept="image/png, image/gif, image/jpeg"
            hidden
            id="image-input-goal"
            type="file"
            onChange={onImageFileChange}
            style={{ display: 'none' }}
          />
          <TextField
            label="Main title"
            value={titleValue}
            onChange={e => setTitleValue(e.target.value)}
            style={{ marginTop: '10px' }}
            variant="filled"
            disabled={isFinalizedState}
          />
          <TextField
            label="Instructions"
            value={instructionsValue}
            onChange={e => setInstructionsValue(e.target.value)}
            style={{ marginTop: '10px' }}
            multiline
            rows={3}
            variant="filled"
            disabled={isFinalizedState}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ marginTop: '10px' }}>Add label items:</div>
            <div style={{ display: 'flex' }}>
              <TextField
                label="Item label"
                value={itemLabelValue}
                onChange={e => setItemLabelValue(e.target.value)}
                style={{ marginLeft: '10px', marginRight: '10px' }}
                disabled={isFinalizedState}
              />
              <Button
                onClick={onAddItemLabel}
                disabled={
                  !itemLabelValue ||
                  draftToHtml(
                    convertToRaw(currentEditorState.getCurrentContent())
                  ).length <= LENGTH_EDITOR_STATE_VALIDATION ||
                  isFinalizedState
                }
              >
                add item label
              </Button>
            </div>
            <Editor
              editorState={currentEditorState}
              editorStyle={{
                minHeight: '10vh',
                backgroundColor: 'white',
                fontFamily: 'Avenir',
                border: '1px solid black',
                marginBottom: '10px',
              }}
              placeholder="Item value"
              onEditorStateChange={editorNewState =>
                setCurrentEditorState(editorNewState)
              }
              mention={{
                separator: ' ',
                trigger: '@',
                suggestions: mentionSuggestions,
              }}
              toolbar={{
                options: [],
                fontFamily: {
                  options: ['Avenir'],
                },
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                fontSize: { options: [18, 22, 24] },
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {itemLabels.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    padding: '10px',
                  }}
                  key={Math.random()
                    .toString(36)
                    .substring(7)}
                >
                  <div
                    style={{
                      marginRight: '10px',
                      fontWeight: '600',
                      marginBottom: '20px',
                    }}
                  >
                    {item.subTitle}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {' '}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                      }}
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    />
                  </div>
                  <Button
                    style={{
                      maxWidth: '20px',
                      maxHeight: '20px',
                      marginBottom: '15px',
                    }}
                    onClick={() => onDeleteLabelClicked(index)}
                    disabled={isFinalizedState}
                  >
                    X
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
              marginRight: '10px',
              alignSelf: 'flex-end',
            }}
          >
            <Button
              style={{
                width: '100px',
                height: '50px',
                fontSize: '16px',
                marginRight: '10px',
              }}
              color="primary"
              onClick={() => {
                setGoalViewDialog(false);
              }}
            >
              cancel
            </Button>
            <Button
              onClick={() => {
                saveGoalView();
                setGoalViewDialog(false);
              }}
              disabled={
                !titleValue ||
                !instructionsValue ||
                !itemLabels.length ||
                isFinalizedState
              }
              style={{ width: '100px', height: '50px', fontSize: '16px' }}
              color="primary"
              variant="contained"
            >
              save
            </Button>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};

LagoGoalConfigBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  goalLesson: PropTypes.object,
  goalLessonId: PropTypes.string,
  setGoalEditDialogOpen: PropTypes.func,
  screensItemsData: PropTypes.object,
  addScreensItemsData: PropTypes.func,
  lessonsItemsData: PropTypes.object,
  // setLessonsItemsData: PropTypes.func,
  onItemClicked: PropTypes.func,
  setDisplayDeleteDialog: PropTypes.func,
  setKeyToDelete: PropTypes.func,
  currentStage: PropTypes.number,
  onEditItemClicked: PropTypes.func,
  onAddNewVariantClicked: PropTypes.func,
  editVariant: PropTypes.func,
  variantClicked: PropTypes.func,
  tags: PropTypes.object,
  parseTags: PropTypes.func,
  setDisplayUploadedImages: PropTypes.func,
  isLoadingVideo: PropTypes.bool,
  handleOnDragEnd: PropTypes.func,
  journeyId: PropTypes.string,
  isFinalizedState: PropTypes.bool,
});

export const LagoGoalConfig = React.memo(LagoGoalConfigBase);
LagoGoalConfig.displayName = 'LagoGoalConfig';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCodeInput from 'react-code-input';
import styles from './SelfRegistrationEditDialog.scss';

const SelfRegistrationEditDialogBase = ({
  isOpen,
  onClose,
  registrationCode,
}) => {
  const inputEl = useRef(null);

  const [registerCodeInput, setRegisterCodeInput] = useState(registrationCode);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isFinishEditing, setIsFinishEditing] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  useEffect(() => {
    setRegisterCodeInput(registrationCode);
    setIsEditDisabled(true);
    setIsFinishEditing(false);
    setIsLoadingRequest(false);
  }, [isOpen]);

  const onSaveNewCode = async () => {
    setIsLoadingRequest(true);
    try {
      // eslint-disable-next-line no-unused-vars, no-undef
      const data = await fireFunctionPost(
        'clinic_apis-updateRegistrationCode',
        {
          updatedCode: registerCodeInput,
          userId: sessionStorage.userId,
          fbtoken: sessionStorage.fbtoken,
        }
      );
      setIsLoadingRequest(false);
      onClose();
      new MyEvent('self_reg_registration_code_changed').log({
        isSuccess: true,
      });
    } catch (err) {
      showNotification(
        'danger',
        'Sorry, the code you entered is either invalid or has already been used'
      );
      setIsLoadingRequest(false);
      new MyEvent('self_reg_registration_code_changed').log({
        isSuccess: false,
      });
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <div className={styles.content_container}>
          <div className={styles.dialog_title}>
            Customize your own clinic code
          </div>
          <div className={styles.dialog_title_description}>
            You can only do this
            <span style={{ fontWeight: 600, marginLeft: 5 }}>once</span>:
          </div>
          <div className={styles.code_input_container}>
            {/* <input
              className={styles.input_style}
              value={registerCodeInput}
              onChange={e => {
                if (e.target.value && e.target.value.length <= 7) {
                  setRegisterCodeInput(e.target.value);
                }
              }}
              disabled={isEditDisabled}
              ref={inputEl}
            /> */}
            <ReactCodeInput
              type="text"
              fields={7}
              inputStyle={{
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                width: 20,
                margin: 10,
                paddingLeft: 5,
                cursor: 'pointer',
                textTransform: 'capitalize',
              }}
              value={registerCodeInput}
              onChange={value => {
                if (value.length === 7) {
                  setIsFinishEditing(true);
                  setIsEditDisabled(true);
                } else {
                  setIsFinishEditing(false);
                }
                setRegisterCodeInput(value.toUpperCase());
              }}
              ref={inputEl}
              // disabled={isEditDisabled}
              autoFocus={false}
              filterChars={[
                ' ',
                '~',
                '`',
                '!',
                '@',
                '#',
                '$',
                '%',
                '^',
                '&',
                '*',
                '(',
                ')',
                '_',
                '-',
                '{',
                '}',
                '+',
                '=',
                '[',
                ']',
                '|',
                ':',
                ';',
                '<',
                '>',
                ',',
                '.',
                '?',
                '/',
                '\\',
                "'",
                '"',
              ]}
            />
            {isFinishEditing ? (
              <DoneIcon
                style={{
                  color: '#2b99f3',
                }}
              />
            ) : (
              <EditIcon
                style={{
                  color: isEditDisabled ? '#7abef8' : '#2b99f3',
                  // position: 'relative',
                  // top: 8,
                  // left: -30,
                  cursor: isEditDisabled ? 'pointer' : 'auto',
                }}
                onClick={() => {
                  if (isEditDisabled) {
                    inputEl.current.state.input = ['', '', '', '', '', '', ''];
                    setRegisterCodeInput('');
                    setIsEditDisabled(false);
                  }
                }}
              />
            )}
          </div>
          <div>
            Code must consist of 7 characters: letters, numbers, or a mix of
            both.
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="default">
          <span className={styles.btn}>cancel</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isFinishEditing}
          // style={
          //   !isFinishEditing
          //     ? null
          //     : { backgroundColor: '#1f96f3', color: '#ffffff' }
          // }
          onClick={() => {
            onSaveNewCode();
          }}
        >
          <span className={styles.btn}>
            {isLoadingRequest ? (
              <CircularProgress
                style={{ color: 'white', width: 18, height: 18 }}
              />
            ) : (
              'save'
            )}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelfRegistrationEditDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  registrationCode: PropTypes.string,
});

export const SelfRegistrationEditDialog = React.memo(
  SelfRegistrationEditDialogBase
);

import { useState, useEffect } from 'react';

const cachedScripts = {};

export const useScript = src => {
  const [scriptInfo, setScriptInfo] = useState({
    loaded: false,
    error: null,
  });

  useEffect(() => {
    let script;
    if (cachedScripts[src]) {
      script = cachedScripts[src];
    } else {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.head.appendChild(script);
      cachedScripts[src] = script;
    }

    const onScriptLoad = () => setScriptInfo({ loaded: true, error: null });
    const onScriptError = () =>
      setScriptInfo({
        loaded: false,
        error: new Error(`The following script failed to load: ${src}`),
      });
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src]);

  return [scriptInfo.loaded, scriptInfo.error];
};

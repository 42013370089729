import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { OutstandingInvoicePopUp } from './OutstandingInvoicePopUp';
import { stripeScriptURL } from '../../utils/constants';
import { useScript } from '../../Core/hooks/useScript';

const OutstandingInvoicePopUpStripeContainerBase = ({
  outstandingInvoicesAmount,
  isOpen,
  onClose,
}) => {
  const [stripe, setStripe] = useState(null);
  const [isStripeScriptLoaded] = useScript(stripeScriptURL);

  useEffect(() => {
    if (
      (isStripeScriptLoaded || window.isStripeScriptLoaded) &&
      stripe === null
    ) {
      window.isStripeScriptLoaded = true;
      setStripe(window.Stripe(environmentConfig.stripePublicKey));
    }
  }, [isStripeScriptLoaded, stripe]);

  return (
    <StripeProvider stripe={window.stripeForStripePage || stripe}>
      <Elements>
        <OutstandingInvoicePopUp
          outstandingInvoicesAmount={outstandingInvoicesAmount}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Elements>
    </StripeProvider>
  );
};

OutstandingInvoicePopUpStripeContainerBase.propTypes = exact({
  outstandingInvoicesAmount: PropTypes.number,
  isOpen: PropTypes.func,
  onClose: PropTypes.onClose,
});

export const OutstandingInvoicePopUpStripeContainer = React.memo(
  OutstandingInvoicePopUpStripeContainerBase
);
OutstandingInvoicePopUpStripeContainer.displayName =
  'OutstandingInvoicePopUpStripeContainer';

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import dayJS from 'dayjs';
import has from 'lodash/has';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import styles from './SessionsTable.scss';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { secondsToStrMinutes } from '../../../utils/utils';
import { CustomTable } from '../../../Core/Components/CustomTable/CustomTable';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { paragraphFontSize } from '../../../cssInJs/constants';
import { ViewIcon } from '../../../Core/Icons/ViewIcon';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { rapidSessionTypes } from '../constants';
import ThunderPNG from '../../../../assets/thunder.png';
import NoElectrodeYellowPNG from '../../../../assets/noelectrodeyellow.png';

const formatNumber = number => +number;

const SessionsTableBase = React.memo(
  ({
    sessions,
    onSessionClick,
    onLinkToSessionClick,
    tableAlternativeUI,
    isHeadCheckboxChecked,
    onHeadCheckboxClick,
    trainingSessions,
  }) => {
    const t = useTranslation();

    const columnsIds = [
      'checkbox',
      'date',
      'duration',
      'rounds',
      'positiveFeedback',
      'protocol',
      'alerts',
      'noise',
      'zone',
      'deepZone',
      'streak',
      'maxStreak',
      'action',
    ];

    const tableHeads = [
      <Checkbox
        color="primary"
        checked={isHeadCheckboxChecked}
        className={styles.checkbox_root}
        onClick={onHeadCheckboxClick}
      />,
      t('date'),
      t('duration_minutes'),
      t('rounds-upper-case'),
      `${t('success-rate')} %`,
      t('protocol'),
      t('alerts'),
      `${t('noise')} %`,
      `${t('zone')} %`,
      `${t('deep_zone')} %`,
      t('streak_seconds'),
      t('max_streak_seconds'),
      t('action'),
    ].map((text, index) => ({
      id: columnsIds[index],
      node: <span className={styles.table_head}>{text}</span>,
    }));

    const tableRows = sessions.map(session => ({
      id: session.sessionKey,
      onClick: () => onSessionClick(session),
      cells: [
        <Checkbox
          color="primary"
          checked={session.selected}
          className={styles.checkbox_root}
        />,
        dayJS(session.timestamp).format(DAY_JS_DATE_FORMATS.american),
        // secondsToStrMinutes(session.duration),
        <div>
          {secondsToStrMinutes(session.duration)}
          {trainingSessions[session.sessionKey] &&
          trainingSessions[session.sessionKey].isRapidSession ? (
            <Tooltip
              title="The client chose to complete a rapid session"
              placement="bottom"
              classes={{ tooltip: styles.tooltip }}
            >
              <img src={ThunderPNG} style={{ width: 15, height: 15 }} />
            </Tooltip>
          ) : null}
          {trainingSessions[session.sessionKey] &&
          trainingSessions[session.sessionKey].rapidSessionType ===
            rapidSessionTypes.withoutElectrode.val ? (
            <Tooltip
              title="The client completed a rapid session without an electrode."
              placement="bottom"
              classes={{ tooltip: styles.tooltip }}
            >
              <img
                src={NoElectrodeYellowPNG}
                style={{ width: 15, height: 15 }}
              />
            </Tooltip>
          ) : null}
        </div>,
        session.roundNumber,
        `${formatNumber(session.totalSuccessRate.toFixed(2))}%`,
        <TooltipWrapper
          title={
            <span style={{ fontSize: paragraphFontSize }}>
              {session.protocol}
            </span>
          }
          placement="bottom-start"
        >
          <span>{session.protocol}</span>
        </TooltipWrapper>,
        formatNumber(session.alerts),
        `${formatNumber(session.averageNoise.toFixed(2))}%`,
        has(session, 'zonePercentage')
          ? `${formatNumber(session.zonePercentage.toFixed(2))}%`
          : 'N/A',
        has(session, 'deepZonePercentage')
          ? `${formatNumber(session.deepZonePercentage.toFixed(2))}%`
          : 'N/A',
        has(session, 'medianStreak') ? session.medianStreak.toFixed(2) : 'N/A',
        has(session, 'maxStreak') ? session.maxStreak.toFixed(2) : 'N/A',
        <a
          onClick={e => {
            e.stopPropagation();
            onLinkToSessionClick(session);
          }}
          role="button"
          tabIndex={0}
        >
          <ViewIcon />
        </a>,
      ].map((node, index) => ({
        id: columnsIds[index],
        node,
      })),
    }));

    return (
      <Paper>
        <h3 className={styles.title} data-test-id="sessions-table-title">
          {t('choose_relevant_sessions')}{' '}
          {`(${
            sessions.filter(session => session.selected).length
          } Selected Sessions)`}
        </h3>
        <div>
          {!tableAlternativeUI && (
            <CustomTable
              tableHeads={tableHeads}
              tableRows={tableRows}
              tableContainerClass={styles.table_container}
            />
          )}

          {tableAlternativeUI && (
            <div className={styles.table_alternative_ui_container}>
              {tableAlternativeUI}
            </div>
          )}
        </div>
      </Paper>
    );
  }
);

SessionsTableBase.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      sessionKey: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
      roundNumber: PropTypes.number.isRequired,
      totalSuccessRate: PropTypes.number.isRequired,
      protocol: PropTypes.string.isRequired,
      alerts: PropTypes.number.isRequired,
      averageNoise: PropTypes.number.isRequired,
      zonePercentage: PropTypes.number,
      deepZonePercentage: PropTypes.number,
      medianStreak: PropTypes.number,
      maxStreak: PropTypes.number,
      selected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onSessionClick: PropTypes.func.isRequired,
  onLinkToSessionClick: PropTypes.func.isRequired,
  tableAlternativeUI: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.instanceOf(null),
  ]),
  isHeadCheckboxChecked: PropTypes.bool.isRequired,
  onHeadCheckboxClick: PropTypes.func.isRequired,
  trainingSessions: PropTypes.object.isRequired,
};

export const SessionsTable = SessionsTableBase;

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper } from '@material-ui/core';
import { CircularProgressbar } from 'react-circular-progressbar';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import capitalize from 'lodash/capitalize';
import styles from './QuestionnaireResults.scss';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../cssInJs/constants';
import { QuestionnaireScore } from './QuestionnaireScore/QuestionnaireScore';
import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';
import { getQuestionnairesScorePercentage } from '../../models/symptom-tracker/symptom-tracker';
import { ASSESSMENT_COLORS } from '../../models/assessments/assessments';
import increasingArrowPNG from '../../../assets/increasing-arrow.png';
import decreasingArrowPNG from '../../../assets/decreasing-arrow.png';
import dailyReport from '../../../assets/questionaires-icon.png';

const QuestionnaireResultsBase = ({
  questionnaires,
  questionnaireResults,
  postQuestionnaireResults,
  classes,
  allQuestionnaires,
}) => {
  const t = useTranslation();

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  const questionnaireResultsFilteredTotalScores = Object.keys(
    questionnaireResults.totalScores || {}
  ).filter(
    key =>
      !allQuestionnaires ||
      !allQuestionnaires[key] ||
      !allQuestionnaires[key].hideResult
  );
  const postQuestionnaireResultsFilteredTotalScores = Object.keys(
    !postQuestionnaireResults ? {} : postQuestionnaireResults.totalScores || {}
  ).filter(
    key =>
      !allQuestionnaires ||
      !allQuestionnaires[key] ||
      !allQuestionnaires[key].hideResult
  );

  const questionnaireResultsStandardizedTotalScoresFiltered = Object.keys(
    (questionnaireResults && questionnaireResults.standardizedTotalScores) || {}
  ).filter(
    questionnaireId =>
      !questionnaires ||
      !questionnaires[questionnaireId] ||
      !questionnaires[questionnaireId].hideResult
  );
  const postQuestionnaireResultsStandardizedTotalScoresFiltered = Object.keys(
    (postQuestionnaireResults &&
      postQuestionnaireResults.standardizedTotalScores) ||
      {}
  ).filter(
    questionnaireId =>
      !questionnaires ||
      !questionnaires[questionnaireId] ||
      !questionnaires[questionnaireId].hideResult
  );

  const areCustomQuestionnairesInQuestionnaireResults =
    questionnaireResultsStandardizedTotalScoresFiltered.length !==
    questionnaireResultsFilteredTotalScores.length;

  const areCustomQuestionnairesInPostQuestionnairesResults =
    postQuestionnaireResultsStandardizedTotalScoresFiltered.length !==
    postQuestionnaireResultsFilteredTotalScores.length;

  const shouldShowNoteAboutCustomQuestionnaires =
    areCustomQuestionnairesInPostQuestionnairesResults ||
    areCustomQuestionnairesInQuestionnaireResults;

  const totalScorePercentage = getQuestionnairesScorePercentage({
    questionnaireResults,
    questionnaires,
  });

  const renderComparisonResults = () => {
    const postTotalScorePercentage = getQuestionnairesScorePercentage({
      questionnaireResults: postQuestionnaireResults,
      questionnaires,
    });

    const differenceBetweenPostAndPreScore = parseInt(
      postTotalScorePercentage - totalScorePercentage,
      10
    );

    const formattedDifferenceBetweenPostAndPreScore =
      differenceBetweenPostAndPreScore > 0
        ? `+${differenceBetweenPostAndPreScore}%`
        : `${differenceBetweenPostAndPreScore}%`;
    const differenceBetweenPostAndPreScoreIconPNG =
      differenceBetweenPostAndPreScore > 0
        ? increasingArrowPNG
        : decreasingArrowPNG;
    const differenceBetweenPostAndPreScoreTextColor =
      (differenceBetweenPostAndPreScore === 0 && 'grey') ||
      (differenceBetweenPostAndPreScore > 0 && ASSESSMENT_COLORS.POSITIVE) ||
      (differenceBetweenPostAndPreScore < 0 && ASSESSMENT_COLORS.NEGATIVE);

    return (
      <div className={styles.result_change_percentage_container}>
        <div>
          <p>Pre</p>
          <div className={styles.progress_comparison}>
            <CircularProgressbar
              value={totalScorePercentage}
              text={`${totalScorePercentage}%`}
            />
          </div>
        </div>
        <div className={styles.vertical_border} />
        <div>
          <p>Post</p>
          <div className={styles.progress_comparison}>
            <CircularProgressbar
              value={postTotalScorePercentage}
              text={`${postTotalScorePercentage}%`}
            />
          </div>
          {differenceBetweenPostAndPreScore !== 0 &&
            !Number.isNaN(differenceBetweenPostAndPreScore) && (
              <span>
                (
                <img
                  src={differenceBetweenPostAndPreScoreIconPNG}
                  style={{ marginLeft: 1 }}
                />
                <span
                  style={{
                    color: differenceBetweenPostAndPreScoreTextColor,
                    marginRight: 3,
                  }}
                >
                  {formattedDifferenceBetweenPostAndPreScore}
                </span>
                )
              </span>
            )}
        </div>
      </div>
    );
  };

  const renderQuestionnaireResults = () => {
    return (
      <Paper
        classes={{ root: styles.category_expansion_panel_root }}
        defaultExpanded
        className="print-item"
      >
        <div
          // expandIcon={<ExpandMoreIcon />}
          style={{ padding: 20 }}
        >
          <div
            className={
              postQuestionnaireResults
                ? styles.questionnaire_info_comparison_mode
                : styles.questionnaire_info
            }
          >
            {postQuestionnaireResults ? null : (
              <img
                src={dailyReport}
                style={
                  isMatchingXSScreen
                    ? { width: 100, height: 100 }
                    : { width: 100, height: 100, marginRight: 20 }
                }
              />
            )}
            <div className={styles.questionnaire_tooltip_container}>
              <p className={styles.expandable_head}>
                {capitalize(t('questionnaires'))}
              </p>
              {/* <TooltipWrapper
                title={
                  <TooltipTitle>{t('questionnaire-results')}</TooltipTitle>
                }
              >
                <HelpIcon
                  onClick={event => {
                    // We dont want the expandable menu to be opened when clicking on this icon, so hover can be viewed easily in mobile
                    event.stopPropagation();
                  }}
                  className="hide-on-print"
                />
              </TooltipWrapper> */}
            </div>

            {postQuestionnaireResults ? (
              renderComparisonResults()
            ) : (
              <div className={styles.progress}>
                <CircularProgressbar
                  value={totalScorePercentage}
                  text={`${totalScorePercentage}%`}
                  className={styles.circular_progress_bar_text}
                />
              </div>
            )}
          </div>
        </div>

        <div style={{ padding: 20 }}>
          <div style={{ width: '100%', color: 'black' }}>
            {Object.keys(questionnaireResults.standardizedTotalScores || {})
              .filter(
                questionnaireId =>
                  has(questionnaires, questionnaireId) &&
                  !questionnaires[questionnaireId].hideResult
              )
              .map(questionnaireId => {
                const questionnaire = questionnaires[questionnaireId];
                const questionnaireMaxScore = questionnaire.maxScore;
                return (
                  <QuestionnaireScore
                    key={questionnaireId}
                    questionnaire={questionnaire}
                    score={
                      questionnaireResults.standardizedTotalScores[
                        questionnaireId
                      ]
                    }
                    postScore={
                      postQuestionnaireResults &&
                      postQuestionnaireResults.standardizedTotalScores[
                        questionnaireId
                      ]
                    }
                    questionnaireMaxScore={questionnaireMaxScore}
                    classes={classes}
                  />
                );
              })}
          </div>
        </div>
      </Paper>
    );
  };
  return (
    <div>
      {shouldShowNoteAboutCustomQuestionnaires && (
        <div className={styles.questionnaires_alert}>
          <CustomAlert severity="warning">
            {t('custom-questionnaire-data-is-not-supported')}
          </CustomAlert>
        </div>
      )}

      {!isEmpty(questionnaireResults.standardizedTotalScores || {}) &&
        renderQuestionnaireResults()}
    </div>
  );
};

QuestionnaireResultsBase.propTypes = exact({
  questionnaires: PropTypes.object.isRequired,
  questionnaireResults: PropTypes.object.isRequired,
  postQuestionnaireResults: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string),
  allQuestionnaires: PropTypes.object,
});

export const QuestionnaireResults = React.memo(QuestionnaireResultsBase);
QuestionnaireResults.displayName = 'QuestionnaireResults';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import startCase from 'lodash/startCase';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import styles from './AssessmentBrainMapsComparisonGraph.scss';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { sortedFrequencies } from '../../../models/assessments/assessments';

const AssessmentBrainMapsComparisonGraphBase = ({
  frequencyAssessmentBrainMaps,
  allFrequencies,
  selectedFrequencies,
  onSelectedFrequenciesChange,
  isInPreview,
  preAssessment,
  postAssessment,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  noDataIndicator,
  frequenciesToImagesFullNames,
}) => {
  const t = useTranslation();
  const preAssessmentFrequencyBrainMaps =
    frequencyAssessmentBrainMaps[preAssessment];
  const postAssessmentFrequencyBrainMaps =
    frequencyAssessmentBrainMaps[postAssessment];

  return (
    <div className={styles.root}>
      {!isInPreview && !noDataIndicator && (
        <div className={styles.frequencies_select}>
          <CheckboxesSelect
            selectedOptions={selectedFrequencies}
            allOptions={allFrequencies}
            onChange={onSelectedFrequenciesChange}
            inputLabelText={t('frequencies_to_show')}
          />
        </div>
      )}

      <AssessmentComparisonGraph
        assessmentOptions={assessmentOptions}
        preAssessment={preAssessment}
        postAssessment={postAssessment}
        preAssessmentTimestamp={preAssessmentTimestamp}
        postAssessmentTimestamp={postAssessmentTimestamp}
        onPreAssessmentChange={onPreAssessmentChange}
        onPostAssessmentChange={onPostAssessmentChange}
        isInPreview={isInPreview}
      >
        {noDataIndicator || (
          <div className={styles.content}>
            {sortedFrequencies.map(freq => (
              <div
                key={freq}
                style={{
                  display:
                    selectedFrequencies.indexOf(freq) > -1 ? 'block' : 'none',
                }}
              >
                <p className={styles.frequency_name}>
                  {frequenciesToImagesFullNames[freq]}
                </p>
                <div key={freq} className={styles.freq_info_container}>
                  <div>
                    <p>{t('pre')}</p>
                    <div className={styles.images_container}>
                      <div>
                        <p>{startCase(t('eyes-open'))}</p>
                        <img src={preAssessmentFrequencyBrainMaps[freq][0]} />
                      </div>
                      <div className={styles.vertical_border} />
                      <div>
                        <p>{t('eyes_closed')}</p>
                        <img src={preAssessmentFrequencyBrainMaps[freq][1]} />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>{t('post')}</p>
                    <div className={styles.images_container}>
                      <div>
                        <p>{startCase(t('eyes-open'))}</p>
                        <img src={postAssessmentFrequencyBrainMaps[freq][0]} />
                      </div>
                      <div className={styles.vertical_border} />
                      <div>
                        <p>{t('eyes_closed')}</p>
                        <img src={postAssessmentFrequencyBrainMaps[freq][1]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </AssessmentComparisonGraph>
    </div>
  );
};

AssessmentBrainMapsComparisonGraphBase.defaultProps = {
  isInPreview: false,
  noDataIndicator: null,
};

AssessmentBrainMapsComparisonGraphBase.propTypes = exact({
  frequencyAssessmentBrainMaps: PropTypes.object.isRequired,
  allFrequencies: PropTypes.array.isRequired,
  selectedFrequencies: PropTypes.array.isRequired,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  onSelectedFrequenciesChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  noDataIndicator: PropTypes.node,
  frequenciesToImagesFullNames: PropTypes.array,
});

export const AssessmentBrainMapsComparisonGraph = React.memo(
  AssessmentBrainMapsComparisonGraphBase
);
AssessmentBrainMapsComparisonGraph.displayName =
  'AssessmentBrainMapsComparisonGraph';

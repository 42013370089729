import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import styles from './SessionFilters.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const stylesFn = theme => ({
  button: {
    margin: theme.spacing.unit,
    color: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    fontSize: '14px',
  },
});

const FilterBase = ({
  filterLabel,
  filterContent,
  filterId,
  onDoneClick,
  rootClassName,
  classes,
  sideIcon,
  onSideIconClick,
  isSelected,
  filterTitle,
  isDoneBtnEnabled,
  onClickOutside,
}) => {
  const [filterContentAnchorElement, setFilterContentAnchorElement] = useState(
    null
  );

  const t = useTranslation();

  const sideIconId = `side-icon-${filterId}`;
  const openPopover = anchorElement =>
    setFilterContentAnchorElement(anchorElement);
  const onFilterLabelClick = event => {
    // closest is not supported in IE, if we want to support it we should add a pollyfill
    if (!event.target.closest(`#${sideIconId}`) || !isSelected) {
      openPopover(event.currentTarget);
    }
  };
  const closePopover = () => setFilterContentAnchorElement(null);

  return (
    <div className={rootClassName}>
      <div
        onClick={onFilterLabelClick}
        className={styles.filter_label_container}
        data-test-id={filterId}
      >
        <div className={styles.filter_label}>{filterLabel}</div>
        <div
          onClick={onSideIconClick}
          className={styles.arrow_drop_down_container}
          id={sideIconId}
        >
          {sideIcon}
        </div>
      </div>

      <Popover
        id={filterId}
        open={!!filterContentAnchorElement}
        anchorEl={filterContentAnchorElement}
        onClose={() => {
          closePopover();
          onClickOutside();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: styles.popover_paper }}
      >
        <div className={styles.filter_content_container}>
          <div>
            <p className={styles.filter_title}>{filterTitle}</p>
          </div>
          {filterContent}
          <div className={styles.done_button_container}>
            <Button
              variant="contained"
              onClick={event => {
                onDoneClick(event);
                closePopover(event);
              }}
              color="primary"
              disabled={!isDoneBtnEnabled}
              className={classes.button}
            >
              {t('apply')}
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

FilterBase.propTypes = {
  filterId: PropTypes.string.isRequired,
  filterLabel: PropTypes.node.isRequired,
  filterContent: PropTypes.node.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  sideIcon: PropTypes.node.isRequired,
  onSideIconClick: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  filterTitle: PropTypes.node.isRequired,
  isDoneBtnEnabled: PropTypes.bool,
  rootClassName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

FilterBase.defaultProps = {
  isDoneBtnEnabled: true,
};

export const Filter = withStyles(stylesFn)(FilterBase);

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import cloneDeep from 'lodash/cloneDeep';
import { Paper, Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import styles from './SymptomTrackerTab.scss';
import { SymptomTrackerTable } from './SymptomTrackerTable/SymptomTrackerTable';
import { EmptySymptomTrackerTab } from './EmptySymptomTrackerTab/EmptySymptomTrackerTab';
import { SymptomsTrackingGraph } from '../../SessionToSessionReportGenerator/SymptomsTrackingGraph/SymptomsTrackingGraph';
import {
  aggregateSymptomsTrackingAnswersDataSortedByTimestamp,
  turnAggregatedSymptomsTrackingAnswersDataIntoLegendItems,
  getQuestionnairesFromQuestions,
  getQuestionsFromSymptomTrackingAnswers,
  getQuestionnaireIdFromQuestion,
} from '../../../models/symptom-tracker/symptom-tracker';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { BlurOverlayIfContinuityCustomer } from '../BlurOverlayIfContinuityCustomer/BlurOverlayIfContinuityCustomer';
import { usePreviousValue } from '../../../Core/hooks/usePreviousValue';
import { SwitchToNewSymptomTrackerDialog } from './SwitchToNewSymptomTrackerDialog/SwitchToNewSymptomTrackerDialog';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { NoDataIndicator } from '../../NoDataIndicator/NoDataIndicator';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { useCustomerInfo } from '../../../Core/hooks/useCustomerInfo';
import { WrapConditionally } from '../../../Core/Components/WrapConditionally.jsx/WrapConditionally';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { withErrorBoundary } from '../../../Core/hocs/withErrorBoundary/withErrorBoundary';

const SymptomTrackerTabBase = ({
  allSymptomTrackerQuestions,
  customerSymptomTrackerQuestions,
  symptomsTrackingAnswers,
  onDownloadSymptomsTrackingDataAsCSV,
  headsetDetails,
  onHeadsetDetailsUpdated,
  shouldUseNewSymptomTracker,
  onSwitchToNewSymptomTrackerDialogPrimaryBtnClick,
  onSwitchToNewSymptomTrackerDialogSecondaryBtnClick,
  isSwitchToNewSymptomTrackerDialogOpen,
  onSetupSymptomTracker,
  onSwitchToNewSymptomTrackerDialogClose,
  deployedQuestionnaires,
  currentAssessmentStatus,
}) => {
  const t = useTranslation();

  const [
    symptomsTrackingLegendItems,
    setSymptomsTrackingLegendItems,
  ] = useState(null);

  const [allQuestionnaires, setAllQuestionnaires] = useState(null);
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState(null);
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );

  const customerInfo = useCustomerInfo();

  const [customerPresence] = useFirebaseDB({
    path: `userInfo/${customerInfo.id}/presence`,
  });

  const [questionnairesAppCache] = useFirebaseDB({
    path: `symptomTracker/formCache/${customerInfo.id}`,
  });

  useEffect(() => {
    if (
      allQuestionnaires !== null &&
      selectedQuestionnaires !== null &&
      symptomsTrackingLegendItems !== null &&
      !isComponentReadyToRender
    ) {
      setIsComponentReadyToRender(true);
    }
  }, [
    allQuestionnaires,
    isComponentReadyToRender,
    selectedQuestionnaires,
    symptomsTrackingLegendItems,
  ]);

  const filterQuestions = useCallback(
    question => {
      return shouldUseNewSymptomTracker && selectedQuestionnaires
        ? selectedQuestionnaires.includes(
            getQuestionnaireIdFromQuestion({ question })
          )
        : true;
    },
    [selectedQuestionnaires, shouldUseNewSymptomTracker]
  );

  const symptomsTrackingGraphData = useMemo(
    () =>
      aggregateSymptomsTrackingAnswersDataSortedByTimestamp({
        symptomsTrackingAnswers,
        allSymptomTrackerQuestions,
        questionsFilter: question => filterQuestions(question),
      }),
    [symptomsTrackingAnswers, allSymptomTrackerQuestions, filterQuestions]
  );

  const prvSymptomsTrackingGraphData = usePreviousValue(
    symptomsTrackingGraphData
  );

  const onSelectedQuestionnairesChange = useCallback(event => {
    if (event.target.value.length) {
      setSelectedQuestionnaires(event.target.value);
    }
  }, []);

  const onSymptomGraphLegendItemClick = useCallback(legendItemName => {
    setSymptomsTrackingLegendItems(prvLegendItems => {
      const legendItems = cloneDeep(prvLegendItems);
      const legendItemArr = legendItems.find(arr => arr[0] === legendItemName);
      legendItemArr[1] = !legendItemArr[1];
      return legendItems;
    });
  }, []);

  useEffect(() => {
    if (symptomsTrackingLegendItems === null) {
      const initialSymptomsTrackingLegendItems = turnAggregatedSymptomsTrackingAnswersDataIntoLegendItems(
        { aggregatedSymptomsTrackingAnswersData: symptomsTrackingGraphData }
      );

      setSymptomsTrackingLegendItems(initialSymptomsTrackingLegendItems);
    }
  }, [symptomsTrackingGraphData, symptomsTrackingLegendItems]);

  useEffect(() => {
    if (prvSymptomsTrackingGraphData !== symptomsTrackingGraphData) {
      const initialSymptomsTrackingLegendItems = turnAggregatedSymptomsTrackingAnswersDataIntoLegendItems(
        { aggregatedSymptomsTrackingAnswersData: symptomsTrackingGraphData }
      );

      setSymptomsTrackingLegendItems(initialSymptomsTrackingLegendItems);
    }
  }, [
    prvSymptomsTrackingGraphData,
    symptomsTrackingAnswers,
    symptomsTrackingGraphData,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!shouldUseNewSymptomTracker && allQuestionnaires === null) {
      setAllQuestionnaires([]);
      return setSelectedQuestionnaires([]);
    }

    (async () => {
      if (selectedQuestionnaires === null) {
        const allQuestionsInAnswers = getQuestionsFromSymptomTrackingAnswers({
          answersThatHaveQuestionnaireIds: symptomsTrackingAnswers,
          allQuestions: allSymptomTrackerQuestions,
        });

        const questionnaires = await getQuestionnairesFromQuestions({
          questions: allQuestionsInAnswers,
          clinicId: sessionStorage.userId,
        });

        setAllQuestionnaires(questionnaires);
        setSelectedQuestionnaires(
          Object.values(questionnaires).map(questionnaire => questionnaire.id)
        );
      }
    })();
  }, [
    allQuestionnaires,
    allSymptomTrackerQuestions,
    selectedQuestionnaires,
    shouldUseNewSymptomTracker,
    symptomsTrackingAnswers,
  ]);

  const prvShouldUseNewSymptomTracker = usePreviousValue(
    shouldUseNewSymptomTracker
  );
  useEffect(() => {
    if (prvShouldUseNewSymptomTracker !== shouldUseNewSymptomTracker) {
      setIsComponentReadyToRender(false);
      setAllQuestionnaires(null);
      setSelectedQuestionnaires(null);
    }
  }, [prvShouldUseNewSymptomTracker, shouldUseNewSymptomTracker]);

  const renderTabContent = () => {
    const setupSymptomTrackerBtnDisabledMsg =
      questionnairesAppCache && customerPresence === 'online'
        ? t('no-selection-since-client-started-questionnaires')
        : null;
    return (
      <Paper className={styles.root}>
        <div className={styles.header_container}>
          <div>
            <p className={styles.header_font}>{t('symptom-tracking')}</p>
            {Object.values(symptomsTrackingAnswers || {}).length > 0 && (
              <p>{t('questions_based_on_what_you_selected')}</p>
            )}
          </div>
          <div>
            <WrapConditionally
              condition={!!setupSymptomTrackerBtnDisabledMsg}
              wrap={children => (
                <TooltipWrapper
                  title={
                    <TooltipTitle>
                      {setupSymptomTrackerBtnDisabledMsg}
                    </TooltipTitle>
                  }
                >
                  <div>{children}</div>
                </TooltipWrapper>
              )}
            >
              <Button
                variant="contained"
                onClick={onSetupSymptomTracker}
                color="primary"
                disabled={
                  !!setupSymptomTrackerBtnDisabledMsg ||
                  !!currentAssessmentStatus
                }
              >
                <span className={styles.btn}>
                  {shouldUseNewSymptomTracker ? (
                    <>DEPLOY MORE QUESTIONNAIRES</>
                  ) : (
                    <>{t('edit-questions-and-frequency')}</>
                  )}
                </span>
              </Button>
            </WrapConditionally>
          </div>
        </div>
        {!shouldUseNewSymptomTracker && (
          <div className={styles.symptom_tracker_table_container}>
            <SymptomTrackerTable
              allSymptomTrackerQuestions={allSymptomTrackerQuestions}
              customerSymptomTrackerQuestions={customerSymptomTrackerQuestions}
            />
          </div>
        )}
        {symptomsTrackingGraphData.length > 0 ? (
          <>
            <div className={styles.graph_container}>
              <SymptomsTrackingGraph
                data={symptomsTrackingGraphData}
                legendItems={symptomsTrackingLegendItems}
                onLegendItemClick={onSymptomGraphLegendItemClick}
                onSelectedQuestionnairesChange={onSelectedQuestionnairesChange}
                selectedQuestionnaires={selectedQuestionnaires}
                allQuestionnaires={allQuestionnaires}
                shouldUseNewSymptomTracker={shouldUseNewSymptomTracker}
                classes={{
                  questionnaires_select:
                    styles.symptom_graph_questionnaires_select,
                }}
              />

              <div className={styles.download_btn}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onDownloadSymptomsTrackingDataAsCSV}
                >
                  <span className={styles.download_btn_content}>
                    <DownloadIcon />
                    <span className={styles.btn}>{t('download-csv')}</span>
                  </span>
                </Button>
              </div>
            </div>
          </>
        ) : (
          shouldUseNewSymptomTracker && (
            <div className={styles.no_data_indicator_container}>
              <NoDataIndicator text={t('no-data-yet')} />
            </div>
          )
        )}
      </Paper>
    );
  };

  return isComponentReadyToRender ? (
    <BlurOverlayIfContinuityCustomer
      headsetDetails={headsetDetails}
      onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
    >
      {Object.values(symptomsTrackingAnswers || {}).length === 0 &&
      deployedQuestionnaires.length === 0 &&
      Object.values(customerSymptomTrackerQuestions).length === 0 ? (
        <EmptySymptomTrackerTab
          onSetupSymptomTracker={onSetupSymptomTracker}
          shouldUseNewSymptomTracker={shouldUseNewSymptomTracker}
        />
      ) : (
        renderTabContent()
      )}

      <SwitchToNewSymptomTrackerDialog
        isOpen={isSwitchToNewSymptomTrackerDialogOpen}
        onPrimaryBtnClick={onSwitchToNewSymptomTrackerDialogPrimaryBtnClick}
        onSecondaryBtnClick={onSwitchToNewSymptomTrackerDialogSecondaryBtnClick}
        onClose={onSwitchToNewSymptomTrackerDialogClose}
      />
    </BlurOverlayIfContinuityCustomer>
  ) : (
    <Loader />
  );
};

SymptomTrackerTabBase.propTypes = exact({
  allSymptomTrackerQuestions: PropTypes.object.isRequired,
  customerSymptomTrackerQuestions: PropTypes.arrayOf(PropTypes.string),
  symptomsTrackingAnswers: PropTypes.object.isRequired,
  onDownloadSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  onHeadsetDetailsUpdated: PropTypes.func.isRequired,
  headsetDetails: PropTypes.object.isRequired,
  shouldUseNewSymptomTracker: PropTypes.bool.isRequired,
  onSwitchToNewSymptomTrackerDialogPrimaryBtnClick: PropTypes.func.isRequired,
  onSwitchToNewSymptomTrackerDialogSecondaryBtnClick: PropTypes.func.isRequired,
  isSwitchToNewSymptomTrackerDialogOpen: PropTypes.bool.isRequired,
  onSetupSymptomTracker: PropTypes.func.isRequired,
  onSwitchToNewSymptomTrackerDialogClose: PropTypes.func.isRequired,
  deployedQuestionnaires: PropTypes.array.isRequired,
  currentAssessmentStatus: PropTypes.object,
});

export const SymptomTrackerTab = withErrorBoundary(
  React.memo(SymptomTrackerTabBase)
);
SymptomTrackerTab.displayName = 'SymptomTrackerTab';

import React from 'react';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DraggableReportSections } from './DraggableReportSections';
import styles from './ClientReport.scss';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';

const stylesFn = () => ({
  button: {
    color: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    fontSize: '14px',
  },
  discardButton: {
    color: '#9f9f9f',
    borderRadius: '4px',
    fontSize: '14px',
  },
});

const ClientReportBase = React.memo(
  ({
    t,
    classes,
    onViewReportClick,
    onDiscardClick,
    isDiscardBtnEnabled,
    isViewReportBtnEnabled,
    reportCards,
    onReportCardsReordered,
    onDeleteItemClick,
  }) => (
    <Paper>
      <div className={styles.client_report}>
        <div className={styles.client_report_title}>
          <p>{t('client_report')}</p>
        </div>
        <div className={styles.client_report_actions_container}>
          <Button
            variant="contained"
            onClick={onViewReportClick}
            color="primary"
            className={classes.button}
            disabled={!isViewReportBtnEnabled}
          >
            {t('view_report')}
          </Button>
          <Button
            variant="text"
            onClick={onDiscardClick}
            color="default"
            className={classes.discardButton}
            disabled={!isDiscardBtnEnabled}
          >
            {t('discard')}
          </Button>
        </div>
        <div>
          <div className={styles.client_report_description_container}>
            <p>{t('report_items')}</p>
            <p>{t('reorder_report_items_description')}</p>
          </div>

          <div className={styles.draggable_reports_container}>
            <DraggableReportSections
              items={reportCards}
              onItemsReordered={onReportCardsReordered}
              onDeleteItemClick={onDeleteItemClick}
            />
          </div>
        </div>
      </div>
    </Paper>
  )
);

ClientReportBase.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onViewReportClick: PropTypes.func.isRequired,
  onDiscardClick: PropTypes.func.isRequired,
  reportCards: PropTypes.array.isRequired,
  isDiscardBtnEnabled: PropTypes.bool.isRequired,
  isViewReportBtnEnabled: PropTypes.bool.isRequired,
  onReportCardsReordered: PropTypes.func.isRequired,
  onDeleteItemClick: PropTypes.func.isRequired,
};

export const ClientReport = withTranslation(
  withStyles(stylesFn)(ClientReportBase)
);

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoSection_size_17{font-size:17px}.LagoSection_size_10{font-size:10px}.LagoSection_size_11{font-size:11px}.LagoSection_size_13{font-size:13px}.LagoSection_size_15{font-size:15px}.LagoSection_size_14{font-size:14px}.LagoSection_size_16{font-size:16px}.LagoSection_size_12{font-size:12px}.LagoSection_extra_header{font-size:18px;font-weight:500;color:#000000}.LagoSection_header{font-size:17px;font-weight:500;color:#000000}.LagoSection_header_only_font_size{font-size:17px;font-weight:500}.LagoSection_sub_header{font-size:13px;color:#878787}.LagoSection_sub_header_black{font-size:13px;color:black}.LagoSection_sub_header_only_font_size{font-size:13px}.LagoSection_paragraph_only_font_size{font-size:12px}.LagoSection_tab_font_size{font-size:13px}.LagoSection_pop_out_header{font-size:20px}.LagoSection_title{font-size:15px;font-weight:500;color:#484848}.LagoSection_title_black{font-size:15px;font-weight:500;color:black}.LagoSection_title_only_font_size{font-size:15px}.LagoSection_paragraph{font-size:12px;color:#484848}.LagoSection_btn{font-size:14px}.LagoSection_lago_container{width:100%;min-height:70vh}\n", ""]);

// Exports
exports.locals = {
	"size_17": "LagoSection_size_17",
	"size_10": "LagoSection_size_10",
	"size_11": "LagoSection_size_11",
	"size_13": "LagoSection_size_13",
	"size_15": "LagoSection_size_15",
	"size_14": "LagoSection_size_14",
	"size_16": "LagoSection_size_16",
	"size_12": "LagoSection_size_12",
	"extra_header": "LagoSection_extra_header",
	"header": "LagoSection_header",
	"header_only_font_size": "LagoSection_header_only_font_size",
	"sub_header": "LagoSection_sub_header",
	"sub_header_black": "LagoSection_sub_header_black",
	"sub_header_only_font_size": "LagoSection_sub_header_only_font_size",
	"paragraph_only_font_size": "LagoSection_paragraph_only_font_size",
	"tab_font_size": "LagoSection_tab_font_size",
	"pop_out_header": "LagoSection_pop_out_header",
	"title": "LagoSection_title",
	"title_black": "LagoSection_title_black",
	"title_only_font_size": "LagoSection_title_only_font_size",
	"paragraph": "LagoSection_paragraph",
	"btn": "LagoSection_btn",
	"lago_container": "LagoSection_lago_container"
};
import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Input } from '@material-ui/core';

// import styles from './LagoSuccessThresholds.scss';

const LagoSuccessThresholdsBase = ({
  open,
  onClose,
  onSuccessThresholdsSave,
  minimumPracticeDaysInput,
  setMinimumPracticeDaysInput,
  minimumJournalEntriesInput,
  setMinimumJournalEntriesInput,
  isJournalExisct,
  isFinalizedState,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <span style={{ fontSize: '1.5em' }}>Success thresholds</span>
      </DialogTitle>
      <DialogContent>
        <div>
          <div>
            <span>Minimum practice days: </span>
            <Input
              value={minimumPracticeDaysInput}
              onChange={e => setMinimumPracticeDaysInput(+e.target.value)}
              type="number"
              disabled={isFinalizedState}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Minimum journal entries: </span>
            <Input
              value={minimumJournalEntriesInput}
              onChange={e => setMinimumJournalEntriesInput(+e.target.value)}
              type="number"
              disabled={!isJournalExisct || isFinalizedState}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{
            width: '100px',
            height: '50px',
            fontSize: '16px',
            marginRight: '10px',
          }}
          color="primary"
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            onSuccessThresholdsSave();
            onClose();
          }}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          color="primary"
          variant="contained"
          disabled={
            minimumPracticeDaysInput === null ||
            !minimumJournalEntriesInput === null ||
            isFinalizedState
          }
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoSuccessThresholdsBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccessThresholdsSave: PropTypes.func,
  minimumPracticeDaysInput: PropTypes.number,
  setMinimumPracticeDaysInput: PropTypes.func,
  minimumJournalEntriesInput: PropTypes.number,
  setMinimumJournalEntriesInput: PropTypes.func,
  isJournalExisct: PropTypes.bool,
  isFinalizedState: PropTypes.bool,
});

export const LagoSuccessThresholds = React.memo(LagoSuccessThresholdsBase);
LagoSuccessThresholds.displayName = 'LagoSuccessThresholds';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper } from '@material-ui/core';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './FullBrainMapModal.scss';
import { useImagesLoadingInfo } from '../../../Core/hooks/useImagesLoadingInfo';
import { Loader } from '../../../Core/Components/Loader/Loader';
import {
  xsExtraMediaMaxWidth,
  // mdMediaMaxWidth,
} from '../../../cssInJs/constants';
import { useMediaMatch } from '../../../Core/hooks/useMediaMatch';
import ColorBarPNG from '../../../../assets/colorbar_scale.png';

const FullBrainMapBase = ({
  frequenciesToImages,
  frequenciesToImagesFullNames,
  isLoadingPDF,
  // brainMapsNotToShow,
}) => {
  const t = useTranslation();
  const [brainMapsRootStatefulRef, setBrainMapsRootStatefulRef] = useState({
    current: null,
  });
  const brainMapsImagesLoadingInfo = useImagesLoadingInfo(
    brainMapsRootStatefulRef
  );
  const shouldShowLoader =
    !brainMapsImagesLoadingInfo.areAllImagesLoaded && !isLoadingPDF;
  const loaderSize = 100;

  const [isMatchingXSExtraScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsExtraMediaMaxWidth}px)`,
  });

  // const [isMatchingMDScreen] = useMediaMatch({
  //   mediaMatchString: `(max-width: ${mdMediaMaxWidth}px)`,
  // });

  const requiredTitles = ['Eyes Open', 'Eyes Closed'];
  const columnTitles = isMatchingXSExtraScreen
    ? requiredTitles
    : ['hidden', ...requiredTitles];

  const parseFreqName = freq => freq.split('(');

  return (
    <div className={styles.root}>
      <div
        style={{
          display: shouldShowLoader ? 'block' : 'none',
          position: 'absolute',
          left: '50%',
          marginLeft: -loaderSize / 2,
          top: 300,
          marginTop: -loaderSize / 2,
        }}
      >
        <Loader padding="0px 0px" />
      </div>
      <div
        style={{ visibility: shouldShowLoader ? 'hidden' : 'visible' }}
        ref={ref => {
          if (ref !== null && ref !== brainMapsRootStatefulRef.current) {
            setBrainMapsRootStatefulRef({
              current: ref,
            });
          }
        }}
      >
        <Paper>
          {/* <div style={{ padding: 20 }}>
            <h3 className={styles.header}>All Brain Maps</h3>
            <p>
              A comparison of your EEG activity to a normative population. Blue
              represents lower than normal activity, while red represents a
              higher than normal activity. Done via a sequential quantitative
              EEG measurement (sqEEG).
            </p>
          </div> */}
          <div className={styles.content}>
            {!isMatchingXSExtraScreen && (
              <div className="print-item">
                <div
                  className={styles.col_titles_container}
                  data-test-id="frequencies-table-head"
                >
                  {columnTitles.map(colTitle => (
                    <p key={colTitle}>{colTitle}</p>
                  ))}
                </div>
              </div>
            )}
            {Object.keys(frequenciesToImages).map(freq => {
              // if (brainMapsNotToShow.indexOf(freq) !== -1) {
              //   return null;
              // }
              return (
                <React.Fragment key={freq}>
                  <div className="print-item">
                    {isMatchingXSExtraScreen && (
                      <React.Fragment>
                        <div>
                          <p className={styles.frequency_name_mobile}>{freq}</p>
                        </div>
                      </React.Fragment>
                    )}
                    <div
                      className={styles.freq_info_container}
                      data-test-id={`freq-${freq}`}
                    >
                      {!isMatchingXSExtraScreen && (
                        <div>
                          <p>
                            {
                              parseFreqName(
                                frequenciesToImagesFullNames[freq]
                              )[0]
                            }
                          </p>
                          <p>
                            {`(${
                              parseFreqName(
                                frequenciesToImagesFullNames[freq]
                              )[1]
                            }`}
                          </p>
                        </div>
                      )}
                      {isMatchingXSExtraScreen && <p>{columnTitles[0]}</p>}
                      <div>
                        <img
                          src={frequenciesToImages[freq][0]}
                          alt="Brain map"
                        />
                      </div>
                      {isMatchingXSExtraScreen && <p>{columnTitles[1]}</p>}
                      <div>
                        <img
                          src={frequenciesToImages[freq][1]}
                          alt="Brain map"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
                borderBottom: 'none',
              }}
              className="print-item"
            >
              <img src={ColorBarPNG} style={{ width: 265, height: 34 }} />
            </div>
            <div className="print-item">
              <p data-test-id="description">{t('brain-maps-description')}</p>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

FullBrainMapBase.propTypes = exact({
  frequenciesToImages: PropTypes.object.isRequired,
  frequenciesToImagesFullNames: PropTypes.array,
  isLoadingPDF: PropTypes.bool,
  // brainMapsNotToShow: PropTypes.array,
});

export const FullBrainMapModal = React.memo(FullBrainMapBase);
FullBrainMapModal.displayName = 'FullBrainMapModal';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".InsightsBarsGraph_size_17{font-size:17px}.InsightsBarsGraph_size_10{font-size:10px}.InsightsBarsGraph_size_11{font-size:11px}.InsightsBarsGraph_size_13{font-size:13px}.InsightsBarsGraph_size_15{font-size:15px}.InsightsBarsGraph_size_14{font-size:14px}.InsightsBarsGraph_size_16{font-size:16px}.InsightsBarsGraph_size_12{font-size:12px}.InsightsBarsGraph_extra_header{font-size:18px;font-weight:500;color:#000000}.InsightsBarsGraph_header{font-size:17px;font-weight:500;color:#000000}.InsightsBarsGraph_header_only_font_size{font-size:17px;font-weight:500}.InsightsBarsGraph_sub_header{font-size:13px;color:#878787}.InsightsBarsGraph_sub_header_black{font-size:13px;color:black}.InsightsBarsGraph_sub_header_only_font_size{font-size:13px}.InsightsBarsGraph_paragraph_only_font_size{font-size:12px}.InsightsBarsGraph_tab_font_size{font-size:13px}.InsightsBarsGraph_pop_out_header{font-size:20px}.InsightsBarsGraph_title{font-size:15px;font-weight:500;color:#484848}.InsightsBarsGraph_title_black{font-size:15px;font-weight:500;color:black}.InsightsBarsGraph_title_only_font_size{font-size:15px}.InsightsBarsGraph_paragraph{font-size:12px;color:#484848}.InsightsBarsGraph_btn{font-size:14px}.InsightsBarsGraph_info{display:flex;flex-wrap:wrap;padding-right:30px;padding-left:40px}.InsightsBarsGraph_info>*:first-child{margin-right:10px}.InsightsBarsGraph_info>*{margin-bottom:20px}.InsightsBarsGraph_root{width:100%}.InsightsBarsGraph_chip{background-color:#e8f0fe !important;font-weight:500;color:#1967d2 !important;padding:9px 25px;max-width:100%;font-size:12px !important;overflow:hidden}\n", ""]);

// Exports
exports.locals = {
	"size_17": "InsightsBarsGraph_size_17",
	"size_10": "InsightsBarsGraph_size_10",
	"size_11": "InsightsBarsGraph_size_11",
	"size_13": "InsightsBarsGraph_size_13",
	"size_15": "InsightsBarsGraph_size_15",
	"size_14": "InsightsBarsGraph_size_14",
	"size_16": "InsightsBarsGraph_size_16",
	"size_12": "InsightsBarsGraph_size_12",
	"extra_header": "InsightsBarsGraph_extra_header",
	"header": "InsightsBarsGraph_header",
	"header_only_font_size": "InsightsBarsGraph_header_only_font_size",
	"sub_header": "InsightsBarsGraph_sub_header",
	"sub_header_black": "InsightsBarsGraph_sub_header_black",
	"sub_header_only_font_size": "InsightsBarsGraph_sub_header_only_font_size",
	"paragraph_only_font_size": "InsightsBarsGraph_paragraph_only_font_size",
	"tab_font_size": "InsightsBarsGraph_tab_font_size",
	"pop_out_header": "InsightsBarsGraph_pop_out_header",
	"title": "InsightsBarsGraph_title",
	"title_black": "InsightsBarsGraph_title_black",
	"title_only_font_size": "InsightsBarsGraph_title_only_font_size",
	"paragraph": "InsightsBarsGraph_paragraph",
	"btn": "InsightsBarsGraph_btn",
	"info": "InsightsBarsGraph_info",
	"root": "InsightsBarsGraph_root",
	"chip": "InsightsBarsGraph_chip"
};
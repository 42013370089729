import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './DigitalCare.scss';
import journeyImage from '../../../../assets/journey-default.png';
import exercisesImage from '../../../../assets/exercises-resources.png';
import entryImage from '../../../../assets/entry-sharing.png';
import { DigitalCareItem } from './DigitalCareItem/DigitalCareItem';
import { addClientEventLogger } from '../addClientModalUtils';

const DigitalCareBase = ({
  journeys,
  onJourneySelected,
  selectedJourney,
  isJourneyEnabled,
  setIsJourneyEnabled,
  categories,
  onCategorySelected,
  selectedCategories,
  isCategoriesEnabled,
  setIsCategoriesEnabled,
  entrySharingOptions,
  selectedEntrySharingOptions,
  onSharingOptionSelected,
  isEntrySharingEnabled,
  setIsEntrySharingEnabled,
  deployedJourney,
}) => {
  const calcJourneyError = () => {
    if (
      deployedJourney &&
      deployedJourney.percentageDone &&
      (deployedJourney.journeyConfig.journeyId !== selectedJourney ||
        !isJourneyEnabled)
    ) {
      return `By clicking save, this client will lose access to the ${
        journeys[deployedJourney.journeyConfig.journeyId].title.en
      } in-app as only one journey can be visible.`;
    }
    return '';
  };

  const calcJournalError = () => {
    if (
      (!isCategoriesEnabled || !selectedCategories.length) &&
      deployedJourney &&
      deployedJourney.journeyConfig &&
      deployedJourney.journeyConfig.journals &&
      Object.keys(deployedJourney.journeyConfig.journals).find(
        journalId =>
          deployedJourney.journeyConfig.journals[journalId].journalEntries
      )
    ) {
      return 'By clicking save, this client will lose access to their entries and Exercises & Resources in-app.';
    }
    return '';
  };

  const calcSharingError = () => {
    if (
      !isEntrySharingEnabled &&
      deployedJourney &&
      deployedJourney.journeyConfig &&
      deployedJourney.journeyConfig.journals &&
      Object.keys(deployedJourney.journeyConfig.journals).find(
        journalId =>
          deployedJourney.journeyConfig.journals[journalId].journalEntries
      )
    ) {
      return 'By clicking save, this client will no longer be able to share entries, request feedback, or reply via messages in-app.';
    }
    return '';
  };

  const defaultJourneys = journeys;
  const journeysToDisplay =
    defaultJourneys !== null
      ? Object.keys(defaultJourneys)
          .filter(journeyId => !defaultJourneys[journeyId].hide)
          .map(journeyId => {
            return {
              value: journeyId,
              text:
                defaultJourneys[journeyId].title &&
                defaultJourneys[journeyId].title.en,
              groupId: defaultJourneys[journeyId].groupId,
              version: defaultJourneys[journeyId].version,
            };
          })
      : [];

  const groupIdsMap = {};
  journeysToDisplay.forEach(journeyObject => {
    if (journeyObject.groupId) {
      if (!groupIdsMap[journeyObject.groupId]) {
        groupIdsMap[journeyObject.groupId] = [
          { value: journeyObject.value, version: journeyObject.version },
        ];
      } else {
        groupIdsMap[journeyObject.groupId].push({
          value: journeyObject.value,
          version: journeyObject.version,
        });
      }
    }
  });

  if (
    deployedJourney &&
    deployedJourney.journeyConfig &&
    deployedJourney.journeyConfig.journeyId
  ) {
    journeysToDisplay.forEach(journeyObject => {
      if (journeyObject.value === deployedJourney.journeyConfig.journeyId) {
        // eslint-disable-next-line no-param-reassign
        journeyObject.version = Infinity;
      }
    });
  }

  const journeysWithGroupIdToDisplay = Object.keys(groupIdsMap).map(groupId => {
    let latestVersion = 0;
    let journeyIdToReturn = '';
    groupIdsMap[groupId].forEach(versionObject => {
      if (versionObject.version > latestVersion) {
        journeyIdToReturn = versionObject.value;
        latestVersion = versionObject.version;
      }
    });
    return journeyIdToReturn;
  });

  const filteredJourneysToDisplay = journeysToDisplay.filter(
    journeyObject =>
      !journeyObject.groupId ||
      journeysWithGroupIdToDisplay.find(
        journeyId => journeyId === journeyObject.value
      )
  );

  return (
    <div>
      <div className={styles.title}>
        Provide in-app digital care tools that help keep your clients engaged
        through interactive educational material and direct communication on
        entries.
      </div>
      <div className={styles.items_list}>
        <DigitalCareItem
          title="Digital Journey"
          description={
            journeys[selectedJourney] &&
            journeys[selectedJourney].dashboardDescription
              ? journeys[selectedJourney].dashboardDescription
              : 'Placeholder text for a journey without description'
          }
          onSelectItem={onJourneySelected}
          selectItems={filteredJourneysToDisplay}
          selectedItem={selectedJourney}
          leaernMore="https://intercom.help/dashboard-myndlift/en/articles/5593109-in-app-journeys"
          image={
            (journeys[selectedJourney] && journeys[selectedJourney].img) ||
            journeyImage
          }
          warning={
            <span style={{ fontSize: 11 }}>Available on iOS devices only</span>
          }
          toggleValue={isJourneyEnabled}
          onToggleClicked={() => {
            addClientEventLogger.log('on_digital_care_checkbox_change', {
              name: 'digital_journey',
              value_after: !isJourneyEnabled,
            });
            setIsJourneyEnabled(!isJourneyEnabled);
          }}
          error={calcJourneyError()}
        />
        <DigitalCareItem
          title="Exercises & Resources"
          description="Mental wellness journals, meditations, exercises, and educational articles."
          onSelectItem={onCategorySelected}
          selectItems={Object.keys(categories)
            .filter(categoryId => !categories[categoryId].isHidden)
            .map(categoryId => {
              return {
                value: categoryId,
                label:
                  categories[categoryId].name && categories[categoryId].name.en,
              };
            })}
          selectedItem={selectedCategories}
          leaernMore="https://intercom.help/dashboard-myndlift/en/articles/5846970-in-app-explore-resources-exercises"
          image={exercisesImage}
          // warning="Available on iOS devices only"
          multipleSelect={() => {
            if (!isCategoriesEnabled) {
              return 'All categories disabled';
            }
            if (selectedCategories.length === Object.keys(categories).length) {
              return 'All categories enabled';
            }
            if (selectedCategories.length === 1) {
              return '1 category enabled';
            }
            return `${
              selectedCategories.filter(id => !categories[id].isHidden).length
            } categories enabled`;
          }}
          toggleValue={isCategoriesEnabled}
          onToggleClicked={() => {
            addClientEventLogger.log('on_digital_care_checkbox_change', {
              name: 'resources',
              value_after: !isCategoriesEnabled,
            });
            setIsCategoriesEnabled(!isCategoriesEnabled);
          }}
          error={calcJournalError()}
        />
        <DigitalCareItem
          title="Entry Sharing"
          description="Control the client’s ability to share entries, request feedback, and reply to messages."
          onSelectItem={onSharingOptionSelected}
          selectItems={entrySharingOptions}
          selectedItem={selectedEntrySharingOptions}
          leaernMore="https://intercom.help/dashboard-myndlift/en/articles/6120882-two-way-communication-on-entries"
          image={entryImage}
          // warning={
          //   <span style={{ fontSize: 11 }}>Available on iOS devices only</span>
          // }
          // eslint-disable-next-line consistent-return
          multipleSelect={() => {
            if (!isEntrySharingEnabled) {
              return 'All permissions disabled';
            }
            if (
              selectedEntrySharingOptions.length === entrySharingOptions.length
            ) {
              return 'All permissions enabled';
            }
            if (selectedEntrySharingOptions.length === 1) {
              return entrySharingOptions[0].label;
            }
            if (selectedEntrySharingOptions.length === 0) {
              return 'Client can share entries with me';
            }
          }}
          toggleValue={isEntrySharingEnabled}
          onToggleClicked={() => {
            addClientEventLogger.log('on_digital_care_checkbox_change', {
              name: 'sharing',
              value_after: !isEntrySharingEnabled,
            });
            setIsEntrySharingEnabled(!isEntrySharingEnabled);
          }}
          error={calcSharingError()}
        />
      </div>
    </div>
  );
};

DigitalCareBase.propTypes = exact({
  journeys: PropTypes.object.isRequired,
  onJourneySelected: PropTypes.func.isRequired,
  selectedJourney: PropTypes.string.isRequired,
  isJourneyEnabled: PropTypes.bool.isRequired,
  setIsJourneyEnabled: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
  onCategorySelected: PropTypes.func.isRequired,
  selectedCategories: PropTypes.object.isRequired,
  isCategoriesEnabled: PropTypes.bool.isRequired,
  setIsCategoriesEnabled: PropTypes.func.isRequired,
  entrySharingOptions: PropTypes.array.isRequired,
  selectedEntrySharingOptions: PropTypes.array.isRequired,
  onSharingOptionSelected: PropTypes.func.isRequired,
  isEntrySharingEnabled: PropTypes.bool.isRequired,
  setIsEntrySharingEnabled: PropTypes.func.isRequired,
  deployedJourney: PropTypes.object,
});

export const DigitalCare = React.memo(DigitalCareBase);
DigitalCare.displayName = 'DigitalCare';

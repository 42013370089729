exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoJournalDialog_upload_image_container{display:flex;flex-direction:column;justify-content:center}.LagoJournalDialog_upload_image_btns{display:flex;flex-direction:column;justify-content:center;margin-top:10px}.LagoJournalDialog_lago_control_img{width:100px;height:100px;border-radius:50%}.LagoJournalDialog_journal_dialog_container{display:flex;justify-content:space-between}.LagoJournalDialog_journal_inputs_container{display:flex;flex-direction:column}\n", ""]);

// Exports
exports.locals = {
	"upload_image_container": "LagoJournalDialog_upload_image_container",
	"upload_image_btns": "LagoJournalDialog_upload_image_btns",
	"lago_control_img": "LagoJournalDialog_lago_control_img",
	"journal_dialog_container": "LagoJournalDialog_journal_dialog_container",
	"journal_inputs_container": "LagoJournalDialog_journal_inputs_container"
};
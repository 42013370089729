exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ReportsTable_size_17{font-size:17px}.ReportsTable_size_10{font-size:10px}.ReportsTable_size_11{font-size:11px}.ReportsTable_size_13{font-size:13px}.ReportsTable_size_15{font-size:15px}.ReportsTable_size_14{font-size:14px}.ReportsTable_size_16{font-size:16px}.ReportsTable_size_12{font-size:12px}.ReportsTable_extra_header{font-size:18px;font-weight:500;color:#000000}.ReportsTable_header{font-size:17px;font-weight:500;color:#000000}.ReportsTable_header_only_font_size{font-size:17px;font-weight:500}.ReportsTable_sub_header{font-size:13px;color:#878787}.ReportsTable_sub_header_black{font-size:13px;color:black}.ReportsTable_sub_header_only_font_size{font-size:13px}.ReportsTable_paragraph_only_font_size{font-size:12px}.ReportsTable_tab_font_size{font-size:13px}.ReportsTable_pop_out_header{font-size:20px}.ReportsTable_title{font-size:15px;font-weight:500;color:#484848}.ReportsTable_title_black{font-size:15px;font-weight:500;color:black}.ReportsTable_title_only_font_size{font-size:15px}.ReportsTable_paragraph,.ReportsTable_cell{font-size:12px;color:#484848}.ReportsTable_btn{font-size:14px}.ReportsTable_table_container th{text-align:center}@media only screen and (max-width: 768px){.ReportsTable_table_container th{padding:4px 24px}}@media only screen and (max-width: 480px){.ReportsTable_table_container th{padding:4px 12px}}.ReportsTable_table_container td{text-align:center}@media only screen and (max-width: 768px){.ReportsTable_table_container td{padding:4px 24px}}@media only screen and (max-width: 480px){.ReportsTable_table_container td{padding:4px 12px}}.ReportsTable_table_container tr:last-child td{border:none}.ReportsTable_root{overflow:auto}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ReportsTable_size_17",
	"size_10": "ReportsTable_size_10",
	"size_11": "ReportsTable_size_11",
	"size_13": "ReportsTable_size_13",
	"size_15": "ReportsTable_size_15",
	"size_14": "ReportsTable_size_14",
	"size_16": "ReportsTable_size_16",
	"size_12": "ReportsTable_size_12",
	"extra_header": "ReportsTable_extra_header",
	"header": "ReportsTable_header",
	"header_only_font_size": "ReportsTable_header_only_font_size",
	"sub_header": "ReportsTable_sub_header",
	"sub_header_black": "ReportsTable_sub_header_black",
	"sub_header_only_font_size": "ReportsTable_sub_header_only_font_size",
	"paragraph_only_font_size": "ReportsTable_paragraph_only_font_size",
	"tab_font_size": "ReportsTable_tab_font_size",
	"pop_out_header": "ReportsTable_pop_out_header",
	"title": "ReportsTable_title",
	"title_black": "ReportsTable_title_black",
	"title_only_font_size": "ReportsTable_title_only_font_size",
	"paragraph": "ReportsTable_paragraph",
	"cell": "ReportsTable_cell",
	"btn": "ReportsTable_btn",
	"table_container": "ReportsTable_table_container",
	"root": "ReportsTable_root"
};
exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ConfirmCancelingDialog_size_17{font-size:17px}.ConfirmCancelingDialog_size_10{font-size:10px}.ConfirmCancelingDialog_size_11{font-size:11px}.ConfirmCancelingDialog_size_13{font-size:13px}.ConfirmCancelingDialog_size_15{font-size:15px}.ConfirmCancelingDialog_size_14{font-size:14px}.ConfirmCancelingDialog_size_16{font-size:16px}.ConfirmCancelingDialog_size_12{font-size:12px}.ConfirmCancelingDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.ConfirmCancelingDialog_header,.ConfirmCancelingDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.ConfirmCancelingDialog_header_only_font_size{font-size:17px;font-weight:500}.ConfirmCancelingDialog_sub_header{font-size:13px;color:#878787}.ConfirmCancelingDialog_sub_header_black{font-size:13px;color:black}.ConfirmCancelingDialog_sub_header_only_font_size{font-size:13px}.ConfirmCancelingDialog_paragraph_only_font_size{font-size:12px}.ConfirmCancelingDialog_tab_font_size{font-size:13px}.ConfirmCancelingDialog_pop_out_header{font-size:20px}.ConfirmCancelingDialog_title{font-size:15px;font-weight:500;color:#484848}.ConfirmCancelingDialog_title_black{font-size:15px;font-weight:500;color:black}.ConfirmCancelingDialog_title_only_font_size{font-size:15px}.ConfirmCancelingDialog_paragraph{font-size:12px;color:#484848}.ConfirmCancelingDialog_btn{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ConfirmCancelingDialog_size_17",
	"size_10": "ConfirmCancelingDialog_size_10",
	"size_11": "ConfirmCancelingDialog_size_11",
	"size_13": "ConfirmCancelingDialog_size_13",
	"size_15": "ConfirmCancelingDialog_size_15",
	"size_14": "ConfirmCancelingDialog_size_14",
	"size_16": "ConfirmCancelingDialog_size_16",
	"size_12": "ConfirmCancelingDialog_size_12",
	"extra_header": "ConfirmCancelingDialog_extra_header",
	"header": "ConfirmCancelingDialog_header",
	"dialog_header": "ConfirmCancelingDialog_dialog_header",
	"header_only_font_size": "ConfirmCancelingDialog_header_only_font_size",
	"sub_header": "ConfirmCancelingDialog_sub_header",
	"sub_header_black": "ConfirmCancelingDialog_sub_header_black",
	"sub_header_only_font_size": "ConfirmCancelingDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "ConfirmCancelingDialog_paragraph_only_font_size",
	"tab_font_size": "ConfirmCancelingDialog_tab_font_size",
	"pop_out_header": "ConfirmCancelingDialog_pop_out_header",
	"title": "ConfirmCancelingDialog_title",
	"title_black": "ConfirmCancelingDialog_title_black",
	"title_only_font_size": "ConfirmCancelingDialog_title_only_font_size",
	"paragraph": "ConfirmCancelingDialog_paragraph",
	"btn": "ConfirmCancelingDialog_btn"
};
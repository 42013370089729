import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { EasierCustomTable } from '../../../../Core/Components/CustomTable/EasierCustomTable';
import styles from './SymptomTrackerTable.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

const RowCell = ({ content }) => (
  <span className={styles.row_cell}>{content}</span>
);

RowCell.propTypes = exact({
  content: PropTypes.node.isRequired,
});

const HeadCell = ({ content }) => (
  <span className={styles.head_cell}>{content}</span>
);

HeadCell.propTypes = exact({
  content: PropTypes.node.isRequired,
});

const SymptomTrackerTableBase = ({
  allSymptomTrackerQuestions,
  customerSymptomTrackerQuestions,
}) => {
  const t = useTranslation();
  const rawTableHeads = [
    '#',
    t('symptom').toUpperCase(),
    t('question').toUpperCase(),
  ];
  const colsKeys = rawTableHeads;
  const tableHeadsRow = rawTableHeads.map(content => (
    <HeadCell content={content} />
  ));

  const rowsKeys = customerSymptomTrackerQuestions;

  const rows =
    // The first session is an empty session
    customerSymptomTrackerQuestions.reduce((acc, questionId, index) => {
      const row = [];
      row.push(<RowCell content={index} />);
      row.push(
        <RowCell content={allSymptomTrackerQuestions[questionId].symptom.en} />
      );
      row.push(
        <RowCell
          content={allSymptomTrackerQuestions[questionId].translations.en}
        />
      );
      acc.push(row);
      return acc;
    }, []);

  return (
    rows.length > 0 && (
      <div className={styles.root}>
        <EasierCustomTable
          rows={[tableHeadsRow, ...rows]}
          colsKeys={colsKeys}
          rowsKeys={rowsKeys}
        />
      </div>
    )
  );
};

SymptomTrackerTableBase.propTypes = exact({
  allSymptomTrackerQuestions: PropTypes.object.isRequired,
  customerSymptomTrackerQuestions: PropTypes.arrayOf(
    PropTypes.string.isRequired
  ).isRequired,
});

export const SymptomTrackerTable = React.memo(SymptomTrackerTableBase);
SymptomTrackerTable.displayName = 'SymptomTrackerTable';

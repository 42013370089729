import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import styles from './ModalHeader.scss';

export const ModalHeader = ({
  leftContent,
  rightContent,
  isCloseIconShown,
  onCloseIconClick,
}) => (
  <div className={styles.modal_header_container}>
    <div className={styles.modal_header}>
      {isCloseIconShown && (
        <div className={styles.close_icon_container}>
          <CloseIcon onClick={onCloseIconClick} />
        </div>
      )}
      {leftContent && (
        <div className={styles.modal_header_left_side}>{leftContent}</div>
      )}
      {rightContent && (
        <div className={styles.modal_header_right_side}>{rightContent}</div>
      )}
    </div>
  </div>
);

ModalHeader.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  isCloseIconShown: PropTypes.bool,
  onCloseIconClick: PropTypes.func,
};

ModalHeader.defaultProps = {
  isCloseIconShown: true,
};

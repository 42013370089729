exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DeleteNoteDialog_size_17{font-size:17px}.DeleteNoteDialog_size_10{font-size:10px}.DeleteNoteDialog_size_11{font-size:11px}.DeleteNoteDialog_size_13{font-size:13px}.DeleteNoteDialog_size_15{font-size:15px}.DeleteNoteDialog_size_14{font-size:14px}.DeleteNoteDialog_size_16{font-size:16px}.DeleteNoteDialog_size_12{font-size:12px}.DeleteNoteDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.DeleteNoteDialog_header,.DeleteNoteDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.DeleteNoteDialog_header_only_font_size{font-size:17px;font-weight:500}.DeleteNoteDialog_sub_header,.DeleteNoteDialog_dialog_subheader{font-size:13px;color:#878787}.DeleteNoteDialog_sub_header_black{font-size:13px;color:black}.DeleteNoteDialog_sub_header_only_font_size{font-size:13px}.DeleteNoteDialog_paragraph_only_font_size{font-size:12px}.DeleteNoteDialog_tab_font_size{font-size:13px}.DeleteNoteDialog_pop_out_header{font-size:20px}.DeleteNoteDialog_title{font-size:15px;font-weight:500;color:#484848}.DeleteNoteDialog_title_black{font-size:15px;font-weight:500;color:black}.DeleteNoteDialog_title_only_font_size{font-size:15px}.DeleteNoteDialog_paragraph,.DeleteNoteDialog_dialog_text{font-size:12px;color:#484848}.DeleteNoteDialog_btn,.DeleteNoteDialog_button{font-size:14px}.DeleteNoteDialog_dialog_subheader{text-align:center;margin-bottom:10px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "DeleteNoteDialog_size_17",
	"size_10": "DeleteNoteDialog_size_10",
	"size_11": "DeleteNoteDialog_size_11",
	"size_13": "DeleteNoteDialog_size_13",
	"size_15": "DeleteNoteDialog_size_15",
	"size_14": "DeleteNoteDialog_size_14",
	"size_16": "DeleteNoteDialog_size_16",
	"size_12": "DeleteNoteDialog_size_12",
	"extra_header": "DeleteNoteDialog_extra_header",
	"header": "DeleteNoteDialog_header",
	"dialog_header": "DeleteNoteDialog_dialog_header",
	"header_only_font_size": "DeleteNoteDialog_header_only_font_size",
	"sub_header": "DeleteNoteDialog_sub_header",
	"dialog_subheader": "DeleteNoteDialog_dialog_subheader",
	"sub_header_black": "DeleteNoteDialog_sub_header_black",
	"sub_header_only_font_size": "DeleteNoteDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "DeleteNoteDialog_paragraph_only_font_size",
	"tab_font_size": "DeleteNoteDialog_tab_font_size",
	"pop_out_header": "DeleteNoteDialog_pop_out_header",
	"title": "DeleteNoteDialog_title",
	"title_black": "DeleteNoteDialog_title_black",
	"title_only_font_size": "DeleteNoteDialog_title_only_font_size",
	"paragraph": "DeleteNoteDialog_paragraph",
	"dialog_text": "DeleteNoteDialog_dialog_text",
	"btn": "DeleteNoteDialog_btn",
	"button": "DeleteNoteDialog_button"
};
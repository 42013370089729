import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import styles from './AddAdditionalProgramCTA.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

const AddAdditionalProgramCTABase = ({ onAddProgram }) => {
  const t = useTranslation();
  return (
    <div onClick={onAddProgram} className={styles.root}>
      <AddCircleIcon color="primary" style={{ fontSize: 54, marginTop: 0 }} />
      <div className={styles.content_container}>
        <div className={styles.main_title}>
          <p>
            <>{t('add-a-program')}</>
          </p>
          {/* {!isShortVersion && <FeatureChip featureName="PLUS" />} */}
        </div>
        {/* {!isShortVersion && (
          <div>
            <p>{t('add-an-additional-program-description')}</p>
          </div>
        )} */}
      </div>
    </div>
  );
};

AddAdditionalProgramCTABase.propTypes = exact({
  onAddProgram: PropTypes.func.isRequired,
});

export const AddAdditionalProgramCTA = React.memo(AddAdditionalProgramCTABase);
AddAdditionalProgramCTA.displayName = 'AddAdditionalProgramCTA';

import React from 'react';
import exact from 'prop-types-exact';
import Paper from '@material-ui/core/Paper';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import 'react-dates/lib/css/_datepicker.css';
import styles from './SessionFilters.scss';
import { Filter } from './Filter';
import { SliderFilterContent } from './SliderFilterContent';
import fontStyles from '../../../sass/font-styles.scss';
import { xsMediaMaxWidth } from '../../../cssInJs/constants';

const FilterWrapper = ({
  filterLabel,
  filterContent,
  filterId,
  isFilterSelected,
  onDoneClick,
  onClearSelectedFilterClick,
  filterTitle,
  isDoneBtnEnabled,
  onClickOutside,
}) => {
  const sideIcon = isFilterSelected ? <CloseIcon /> : <ArrowDropDownIcon />;

  return (
    <Filter
      filterTitle={filterTitle}
      filterLabel={filterLabel}
      filterContent={filterContent}
      filterId={filterId}
      rootClassName={
        isFilterSelected
          ? styles.selected_filter_container
          : styles.unselected_filter_container
      }
      sideIcon={sideIcon}
      onSideIconClick={isFilterSelected ? onClearSelectedFilterClick : () => {}}
      onDoneClick={onDoneClick}
      isSelected={isFilterSelected}
      isDoneBtnEnabled={isDoneBtnEnabled}
      onClickOutside={onClickOutside}
    />
  );
};

FilterWrapper.propTypes = exact({
  filterLabel: PropTypes.string.isRequired,
  filterContent: PropTypes.node.isRequired,
  filterId: PropTypes.string.isRequired,
  isFilterSelected: PropTypes.bool.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  onClearSelectedFilterClick: PropTypes.func.isRequired,
  filterTitle: PropTypes.string.isRequired,
  isDoneBtnEnabled: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
});

const SessionFiltersBase = ({
  t,
  onResetFiltersClick,
  // Date Filter
  startDate,
  endDate,
  savedStartDate,
  savedEndDate,
  focusedDateInput,
  onDatesChange,
  onDatesFocusChange,
  onDatesDoneClick,
  onDatesClickOutside,
  onClearSelectedDateClick,
  // Time Filter
  startTime,
  endTime,
  savedStartTime,
  savedEndTime,
  // focusedTimeInput,
  onStartTimeChange,
  onEndTimeChange,
  // onTimesFocusChange,
  onTimesDoneClick,
  onTimesClickOutside,
  onClearSelectedTimeClick,
  isTimesDoneBtnEnabled,
  // Protocols Filter
  allProtocols,
  selectedProtocols,
  savedSelectedProtocols,
  shouldProtocolsToggleSelectAll,
  onProtocolsSelectToggleClick,
  onProtocolsDoneClick,
  onProtocolClick,
  onClearSelectedProtocolsClick,
  onProtocolsClickOutside,
  isProtocolsDoneBtnEnabled,
  isDatesDoneBtnEnabled,
  // Programs Filter
  allPrograms,
  selectedPrograms,
  savedSelectedPrograms,
  shouldProgramsToggleSelectAll,
  onProgramsSelectToggleClick,
  onProgramsDoneClick,
  onProgramClick,
  onClearSelectedProgramsClick,
  onProgramsClickOutside,
  isProgramsDoneBtnEnabled,
  // Session Types Filter
  allSessionTypes,
  selectedSessionTypes,
  savedSelectedSessionTypes,
  shouldSessionTypesToggleSelectAll,
  onSessionTypesSelectToggleClick,
  onSessionTypesDoneClick,
  onSessionTypeClick,
  onClearSelectedSessionTypesClick,
  onSessionTypesClickOutside,
  isSessionTypesDoneBtnEnabled,
  shouldHideSessionTypeFilter,
  // Training site Filter
  allTrainingSite,
  selectedTrainingSite,
  savedSelectedTrainingSite,
  shouldTrainingSiteToggleSelectAll,
  onTrainingSiteSelectToggleClick,
  onTrainingSiteDoneClick,
  onTrainingSiteClick,
  onClearSelectedTrainingSiteClick,
  onTrainingSiteClickOutside,
  isTrainingSiteDoneBtnEnabled,
  // Duration Filter
  minDuration,
  maxDuration,
  minDurationValue,
  maxDurationValue,
  formattedMinDurationValue,
  formattedMaxDurationValue,
  minDurationSavedValue,
  maxDurationSavedValue,
  formattedMinDurationSavedValue,
  formattedMaxDurationSavedValue,
  onMinDurationValueChange,
  onMaxDurationValueChange,
  onClearSelectedDurationClick,
  onDurationDoneClick,
  onDurationClickOutside,
  onDurationSliderValuesChange,
  isDurationDoneBtnEnabled,
  // Positive feedback filter
  minPositiveFeedback,
  maxPositiveFeedback,
  minPositiveFeedbackValue,
  maxPositiveFeedbackValue,
  minPositiveFeedbackSavedValue,
  maxPositiveFeedbackSavedValue,
  onMinPositiveFeedbackValueChange,
  onMaxPositiveFeedbackValueChange,
  onClearPositiveFeedbackClick,
  onPositiveFeedbackDoneClick,
  onPositiveFeedbackClickOutside,
  onPositiveFeedbackSliderValuesChange,
  isPositiveFeedbackDoneBtnEnabled,
  // alerts filter
  minAlertsNumber,
  maxAlertsNumber,
  minAlertsNumberValue,
  maxAlertsNumberValue,
  minAlertsNumberSavedValue,
  maxAlertsNumberSavedValue,
  onMinAlertsNumberValueChange,
  onMaxAlertsNumberValueChange,
  onClearAlertsNumberClick,
  onAlertsNumberDoneClick,
  onAlertsNumberClickOutside,
  onAlertsNumberSliderValuesChange,
  isAlertsNumberDoneBtnEnabled,
  // noise filter
  minNoise,
  maxNoise,
  minNoiseValue,
  maxNoiseValue,
  minNoiseSavedValue,
  maxNoiseSavedValue,
  onMinNoiseValueChange,
  onMaxNoiseValueChange,
  onClearNoiseClick,
  onNoiseDoneClick,
  onNoiseClickOutside,
  onNoiseSliderValuesChange,
  isNoiseDoneBtnEnabled,
}) => {
  const isDurationFilterSaved =
    minDurationSavedValue !== minDuration ||
    maxDurationSavedValue !== maxDuration;

  const isPositiveFeedbackFilterSaved =
    minPositiveFeedbackSavedValue !== minPositiveFeedback ||
    maxPositiveFeedbackSavedValue !== maxPositiveFeedback;

  const isAlertsNumberFilterSaved =
    minAlertsNumberSavedValue !== minAlertsNumber ||
    maxAlertsNumberSavedValue !== maxAlertsNumber;

  const isNoiseFilterSaved =
    minNoiseSavedValue !== minNoise || maxNoiseSavedValue !== maxNoise;

  const mediaQueryListExtraSmall = window.matchMedia(
    `(max-width: ${xsMediaMaxWidth}px)`
  );

  const durationStep = 1;

  const filters = [
    {
      filterId: 'date-filter',
      filterTitle: t('date'),
      filterLabel:
        savedStartDate && savedEndDate
          ? `${savedStartDate.format('YYYY-MM-DD')}-${savedEndDate.format(
              'YYYY-MM-DD'
            )}`
          : t('date'),
      filterContent: (
        <DayPickerRangeController
          noBorder
          hideKeyboardShortcutsPanel
          startDate={startDate}
          endDate={endDate}
          focusedInput={focusedDateInput}
          onDatesChange={onDatesChange}
          onFocusChange={onDatesFocusChange}
          numberOfMonths={2}
          orientation={
            mediaQueryListExtraSmall.matches ? 'vertical' : 'horizontal'
          }
        />
      ),
      isFilterSelected: !!(savedStartDate && savedEndDate),
      onDoneClick: onDatesDoneClick,
      onClearSelectedFilterClick: onClearSelectedDateClick,
      isDoneBtnEnabled: isDatesDoneBtnEnabled,
      onClickOutside: onDatesClickOutside,
    },
    {
      filterId: 'time-filter',
      filterTitle: 'Time',
      filterLabel:
        savedStartTime && savedEndTime
          ? `${savedStartTime}-${savedEndTime}`
          : 'Time',
      filterContent: (
        <div style={{ marginRight: 30 }}>
          <TextField
            id="time-picker"
            label="Start time"
            type="time"
            value={startTime}
            onChange={e => onStartTimeChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: 14,
              },
            }}
            inputProps={{
              step: 300, // 5 minute intervals
              style: {
                fontSize: 18,
                width: 80,
              },
            }}
            style={{ marginRight: 10 }}
          />

          <TextField
            id="time-picker"
            label="End time"
            type="time"
            value={endTime}
            onChange={e => onEndTimeChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: 14,
              },
            }}
            inputProps={{
              step: 300, // 5 minute intervals
              style: {
                fontSize: 18,
                width: 80,
              },
            }}
          />
        </div>
      ),
      isFilterSelected: !!(savedStartTime && savedEndTime),
      onDoneClick: onTimesDoneClick,
      onClearSelectedFilterClick: onClearSelectedTimeClick,
      isDoneBtnEnabled: isTimesDoneBtnEnabled,
      onClickOutside: onTimesClickOutside,
    },
    {
      filterId: 'protocols-filter',
      filterTitle: t('protocol'),
      filterLabel:
        savedSelectedProtocols.length !== allProtocols.length
          ? savedSelectedProtocols.join(',')
          : t('protocol'),
      filterContent: (
        <div className={styles.protocol_filter_content}>
          <div
            className={styles.protocols_select_deselect_toggle_switch_container}
          >
            <div>
              <p>{t('select_all_protocols')}</p>
            </div>
            <div className={styles.protocols_select_deselect_toggle_switch}>
              <Switch
                checked={!shouldProtocolsToggleSelectAll}
                onChange={onProtocolsSelectToggleClick}
                color="primary"
              />
            </div>
          </div>
          <div>
            {allProtocols.map(protocol => (
              <div
                className={styles.protocol_checkbox_container}
                key={protocol}
              >
                <div className={styles.protocol_checkbox}>
                  <Checkbox
                    checked={selectedProtocols.some(
                      selectedProtocol => selectedProtocol === protocol
                    )}
                    onChange={event => onProtocolClick(protocol, event)}
                    color="primary"
                    classes={{ root: styles.protocol_checkbox_root }}
                  />
                </div>
                <div className={styles.protocol_name}>
                  <p>{protocol}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
      isFilterSelected: savedSelectedProtocols.length !== allProtocols.length,
      onDoneClick: onProtocolsDoneClick,
      onClearSelectedFilterClick: onClearSelectedProtocolsClick,
      isDoneBtnEnabled: isProtocolsDoneBtnEnabled,
      onClickOutside: onProtocolsClickOutside,
    },
    {
      filterId: 'programs-filter',
      filterTitle: t('program'),
      filterLabel:
        savedSelectedPrograms.length !== allPrograms.length
          ? savedSelectedPrograms.join(',')
          : t('program'),
      filterContent: (
        <div className={styles.protocol_filter_content}>
          <div
            className={styles.protocols_select_deselect_toggle_switch_container}
          >
            <div>
              <p>{t('select_all_programs')}</p>
            </div>
            <div className={styles.protocols_select_deselect_toggle_switch}>
              <Switch
                checked={!shouldProgramsToggleSelectAll}
                onChange={onProgramsSelectToggleClick}
                color="primary"
              />
            </div>
          </div>
          <div>
            {allPrograms.map(program => (
              <div className={styles.protocol_checkbox_container} key={program}>
                <div className={styles.protocol_checkbox}>
                  <Checkbox
                    checked={selectedPrograms.some(
                      selectedProgram => selectedProgram === program
                    )}
                    onChange={event => onProgramClick(program, event)}
                    color="primary"
                    classes={{ root: styles.protocol_checkbox_root }}
                  />
                </div>
                <div className={styles.protocol_name}>
                  <p>{program}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
      isFilterSelected: savedSelectedPrograms.length !== allPrograms.length,
      onDoneClick: onProgramsDoneClick,
      onClearSelectedFilterClick: onClearSelectedProgramsClick,
      isDoneBtnEnabled: isProgramsDoneBtnEnabled,
      onClickOutside: onProgramsClickOutside,
    },
    {
      filterId: 'training-site-filter',
      filterTitle: 'Active Channel',
      filterLabel:
        savedSelectedTrainingSite.length !== allTrainingSite.length
          ? savedSelectedTrainingSite.join(',')
          : 'Active Channel',
      filterContent: (
        <div className={styles.protocol_filter_content}>
          <div
            className={styles.protocols_select_deselect_toggle_switch_container}
          >
            <div>
              <p>Select all Active Channels</p>
            </div>
            <div className={styles.protocols_select_deselect_toggle_switch}>
              <Switch
                checked={!shouldTrainingSiteToggleSelectAll}
                onChange={onTrainingSiteSelectToggleClick}
                color="primary"
              />
            </div>
          </div>
          <div>
            {allTrainingSite.map(program => (
              <div className={styles.protocol_checkbox_container} key={program}>
                <div className={styles.protocol_checkbox}>
                  <Checkbox
                    checked={selectedTrainingSite.some(
                      selectedProgram => selectedProgram === program
                    )}
                    onChange={event => onTrainingSiteClick(program, event)}
                    color="primary"
                    classes={{ root: styles.protocol_checkbox_root }}
                  />
                </div>
                <div className={styles.protocol_name}>
                  <p>{program}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
      isFilterSelected:
        savedSelectedTrainingSite.length !== allTrainingSite.length,
      onDoneClick: onTrainingSiteDoneClick,
      onClearSelectedFilterClick: onClearSelectedTrainingSiteClick,
      isDoneBtnEnabled: isTrainingSiteDoneBtnEnabled,
      onClickOutside: onTrainingSiteClickOutside,
    },
    {
      filterId: 'duration-filter',
      filterTitle: t('duration'),
      filterLabel: isDurationFilterSaved
        ? `${formattedMinDurationSavedValue}-${formattedMaxDurationSavedValue}`
        : t('duration'),
      filterContent: (
        <SliderFilterContent
          minValueFormatted={formattedMinDurationValue}
          maxValueFormatted={formattedMaxDurationValue}
          minValue={minDurationValue}
          maxValue={maxDurationValue}
          min={minDuration}
          max={maxDuration}
          onMinValueChange={onMinDurationValueChange}
          onMaxValueChange={onMaxDurationValueChange}
          onSliderValuesChange={onDurationSliderValuesChange}
          step={durationStep}
        />
      ),
      isFilterSelected: isDurationFilterSaved,
      onDoneClick: onDurationDoneClick,
      onClearSelectedFilterClick: onClearSelectedDurationClick,
      onClickOutside: onDurationClickOutside,
      isDoneBtnEnabled: isDurationDoneBtnEnabled,
    },
    {
      filterId: 'positive-feedback-filter',
      filterTitle: t('success-rate'),
      filterLabel: isPositiveFeedbackFilterSaved
        ? `${minPositiveFeedbackSavedValue}% - ${maxPositiveFeedbackSavedValue}%`
        : t('success-rate'),
      filterContent: (
        <SliderFilterContent
          minValue={minPositiveFeedbackValue}
          maxValue={maxPositiveFeedbackValue}
          min={minPositiveFeedback}
          max={maxPositiveFeedback}
          onMinValueChange={onMinPositiveFeedbackValueChange}
          onMaxValueChange={onMaxPositiveFeedbackValueChange}
          onSliderValuesChange={onPositiveFeedbackSliderValuesChange}
          step={1}
        />
      ),
      isFilterSelected: isPositiveFeedbackFilterSaved,
      onDoneClick: onPositiveFeedbackDoneClick,
      onClickOutside: onPositiveFeedbackClickOutside,
      onClearSelectedFilterClick: onClearPositiveFeedbackClick,
      isDoneBtnEnabled: isPositiveFeedbackDoneBtnEnabled,
    },
    {
      filterId: 'alerts-number-filter',
      filterTitle: t('alerts_number'),
      filterLabel: isAlertsNumberFilterSaved
        ? `${minAlertsNumberSavedValue} - ${maxAlertsNumberSavedValue}`
        : t('alerts_number'),
      filterContent: (
        <SliderFilterContent
          minValue={minAlertsNumberValue}
          maxValue={maxAlertsNumberValue}
          min={minAlertsNumber}
          max={maxAlertsNumber}
          onMinValueChange={onMinAlertsNumberValueChange}
          onMaxValueChange={onMaxAlertsNumberValueChange}
          onSliderValuesChange={onAlertsNumberSliderValuesChange}
          step={1}
        />
      ),
      isFilterSelected: isAlertsNumberFilterSaved,
      onDoneClick: onAlertsNumberDoneClick,
      onClickOutside: onAlertsNumberClickOutside,
      onClearSelectedFilterClick: onClearAlertsNumberClick,
      isDoneBtnEnabled: isAlertsNumberDoneBtnEnabled,
    },
    {
      filterId: 'noise-filter',
      filterTitle: t('noise_percentage'),
      filterLabel: isNoiseFilterSaved
        ? `${minNoiseSavedValue}% - ${maxNoiseSavedValue}%`
        : t('noise_percentage'),
      filterContent: (
        <SliderFilterContent
          minValue={minNoiseValue}
          maxValue={maxNoiseValue}
          min={minNoise}
          max={maxNoise}
          onMinValueChange={onMinNoiseValueChange}
          onMaxValueChange={onMaxNoiseValueChange}
          onSliderValuesChange={onNoiseSliderValuesChange}
          step={1}
        />
      ),
      isFilterSelected: isNoiseFilterSaved,
      onDoneClick: onNoiseDoneClick,
      onClickOutside: onNoiseClickOutside,
      onClearSelectedFilterClick: onClearNoiseClick,
      isDoneBtnEnabled: isNoiseDoneBtnEnabled,
    },
    {
      filterId: 'session-types-filter',
      filterTitle: 'Rapid',
      filterLabel:
        savedSelectedSessionTypes.length &&
        savedSelectedSessionTypes.length !== allSessionTypes.length
          ? savedSelectedSessionTypes.join(',')
          : 'Rapid',
      filterContent: (
        <div className={styles.protocol_filter_content}>
          <div
            className={styles.protocols_select_deselect_toggle_switch_container}
          >
            <div>
              <p>Include rapid sessions</p>
            </div>
            <div className={styles.protocols_select_deselect_toggle_switch}>
              <Switch
                checked={!shouldSessionTypesToggleSelectAll}
                onChange={onSessionTypesSelectToggleClick}
                color="primary"
              />
            </div>
          </div>
          <div>
            {shouldHideSessionTypeFilter
              ? null
              : allSessionTypes.map(sessionType => (
                  <div
                    className={styles.protocol_checkbox_container}
                    key={sessionType}
                  >
                    <div className={styles.protocol_checkbox}>
                      <Checkbox
                        checked={selectedSessionTypes.some(
                          selectedSessionType =>
                            selectedSessionType === sessionType
                        )}
                        onChange={event =>
                          onSessionTypeClick(sessionType, event)
                        }
                        color="primary"
                        classes={{ root: styles.protocol_checkbox_root }}
                        disabled={shouldSessionTypesToggleSelectAll}
                      />
                    </div>
                    <div className={styles.protocol_name}>
                      <p>{sessionType}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      ),
      isFilterSelected:
        savedSelectedSessionTypes.length !== allSessionTypes.length,
      onDoneClick: onSessionTypesDoneClick,
      onClearSelectedFilterClick: onClearSelectedSessionTypesClick,
      isDoneBtnEnabled: isSessionTypesDoneBtnEnabled,
      onClickOutside: onSessionTypesClickOutside,
    },
  ];

  return (
    <React.Fragment>
      <div>
        <Paper>
          <div className={styles.session_filters}>
            <div className={styles.session_filters_header}>
              <div>
                <h3 className={fontStyles.header}>{t('session_filters')}</h3>
                <p className={fontStyles.sub_header}>
                  {t('session_filters_description')}
                </p>
              </div>
              <div
                className={styles.reset_filters_container}
                onClick={onResetFiltersClick}
              >
                <a role="button">{t('reset_filters')}</a>
              </div>
            </div>

            <div className={styles.filters_container}>
              {filters.map(filter => (
                <FilterWrapper key={filter.filterId} {...filter} />
              ))}
            </div>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
};

const dateFilterPropTypes = {
  startDate: PropTypes.oneOfType([() => null, PropTypes.object.isRequired]),
  endDate: PropTypes.oneOfType([() => null, PropTypes.object.isRequired]),
  savedStartDate: PropTypes.oneOfType([
    () => null,
    PropTypes.object.isRequired,
  ]),
  savedEndDate: PropTypes.oneOfType([() => null, PropTypes.object.isRequired]),
  focusedDateInput: PropTypes.string.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onDatesFocusChange: PropTypes.func.isRequired,
  onDatesClickOutside: PropTypes.func.isRequired,
  onDatesDoneClick: PropTypes.func.isRequired,
  onClearSelectedDateClick: PropTypes.func.isRequired,
  isDatesDoneBtnEnabled: PropTypes.bool.isRequired,
};

// const timeFilterPropTypes = {
//   startDate: PropTypes.oneOfType([() => null, PropTypes.object.isRequired]),
//   endDate: PropTypes.oneOfType([() => null, PropTypes.object.isRequired]),
//   savedStartDate: PropTypes.oneOfType([
//     () => null,
//     PropTypes.object.isRequired,
//   ]),
//   savedEndDate: PropTypes.oneOfType([() => null, PropTypes.object.isRequired]),
//   focusedDateInput: PropTypes.string.isRequired,
//   onDatesChange: PropTypes.func.isRequired,
//   onDatesFocusChange: PropTypes.func.isRequired,
//   onDatesClickOutside: PropTypes.func.isRequired,
//   onDatesDoneClick: PropTypes.func.isRequired,
//   onClearSelectedDateClick: PropTypes.func.isRequired,
//   isDatesDoneBtnEnabled: PropTypes.bool.isRequired,
// };

const protocolsFilterPropTypes = {
  allProtocols: PropTypes.array.isRequired,
  selectedProtocols: PropTypes.array.isRequired,
  savedSelectedProtocols: PropTypes.array.isRequired,
  shouldProtocolsToggleSelectAll: PropTypes.bool.isRequired,
  onProtocolsSelectToggleClick: PropTypes.func.isRequired,
  onProtocolsDoneClick: PropTypes.func.isRequired,
  onProtocolClick: PropTypes.func.isRequired,
  onClearSelectedProtocolsClick: PropTypes.func.isRequired,
  onProtocolsClickOutside: PropTypes.func.isRequired,
  isProtocolsDoneBtnEnabled: PropTypes.bool.isRequired,
};

const programsFilterPropTypes = {
  allPrograms: PropTypes.array.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  savedSelectedPrograms: PropTypes.array.isRequired,
  shouldProgramsToggleSelectAll: PropTypes.bool.isRequired,
  onProgramsSelectToggleClick: PropTypes.func.isRequired,
  onProgramsDoneClick: PropTypes.func.isRequired,
  onProgramClick: PropTypes.func.isRequired,
  onClearSelectedProgramsClick: PropTypes.func.isRequired,
  onProgramsClickOutside: PropTypes.func.isRequired,
  isProgramsDoneBtnEnabled: PropTypes.bool.isRequired,
};

const sessionTypesFilterPropTypes = {
  allSessionTypes: PropTypes.array.isRequired,
  selectedSessionTypes: PropTypes.array.isRequired,
  savedSelectedSessionTypes: PropTypes.array.isRequired,
  shouldSessionTypesToggleSelectAll: PropTypes.bool.isRequired,
  onSessionTypesSelectToggleClick: PropTypes.func.isRequired,
  onSessionTypesDoneClick: PropTypes.func.isRequired,
  onSessionTypeClick: PropTypes.func.isRequired,
  onClearSelectedSessionTypesClick: PropTypes.func.isRequired,
  onSessionTypesClickOutside: PropTypes.func.isRequired,
  isSessionTypesDoneBtnEnabled: PropTypes.bool.isRequired,
  shouldHideSessionTypeFilter: PropTypes.bool.isRequired,
};

const trainingSiteFilterPropTypes = {
  allTrainingSite: PropTypes.array.isRequired,
  selectedTrainingSite: PropTypes.array.isRequired,
  savedSelectedTrainingSite: PropTypes.array.isRequired,
  shouldTrainingSiteToggleSelectAll: PropTypes.bool.isRequired,
  onTrainingSiteSelectToggleClick: PropTypes.func.isRequired,
  onTrainingSiteDoneClick: PropTypes.func.isRequired,
  onTrainingSiteClick: PropTypes.func.isRequired,
  onClearSelectedTrainingSiteClick: PropTypes.func.isRequired,
  onTrainingSiteClickOutside: PropTypes.func.isRequired,
  isTrainingSiteDoneBtnEnabled: PropTypes.bool.isRequired,
};

const durationFilterPropTypes = {
  minDuration: PropTypes.number.isRequired,
  maxDuration: PropTypes.number.isRequired,
  minDurationValue: PropTypes.number.isRequired,
  maxDurationValue: PropTypes.number.isRequired,
  formattedMinDurationValue: PropTypes.string.isRequired,
  formattedMaxDurationValue: PropTypes.string.isRequired,
  minDurationSavedValue: PropTypes.number.isRequired,
  maxDurationSavedValue: PropTypes.number.isRequired,
  formattedMinDurationSavedValue: PropTypes.string.isRequired,
  formattedMaxDurationSavedValue: PropTypes.string.isRequired,
  onMinDurationValueChange: PropTypes.func.isRequired,
  onMaxDurationValueChange: PropTypes.func.isRequired,
  onClearSelectedDurationClick: PropTypes.func.isRequired,
  onDurationDoneClick: PropTypes.func.isRequired,
  onDurationClickOutside: PropTypes.func.isRequired,
  isDurationDoneBtnEnabled: PropTypes.bool.isRequired,
};

const positiveFeedbackFilterPropTypes = {
  minPositiveFeedback: PropTypes.number.isRequired,
  maxPositiveFeedback: PropTypes.number.isRequired,
  minPositiveFeedbackValue: PropTypes.number.isRequired,
  maxPositiveFeedbackValue: PropTypes.number.isRequired,
  minPositiveFeedbackSavedValue: PropTypes.number.isRequired,
  maxPositiveFeedbackSavedValue: PropTypes.number.isRequired,
  onMinPositiveFeedbackValueChange: PropTypes.func.isRequired,
  onMaxPositiveFeedbackValueChange: PropTypes.func.isRequired,
  onClearPositiveFeedbackClick: PropTypes.func.isRequired,
  onPositiveFeedbackDoneClick: PropTypes.func.isRequired,
  onPositiveFeedbackClickOutside: PropTypes.func.isRequired,
  onPositiveFeedbackSliderValuesChange: PropTypes.func.isRequired,
  isPositiveFeedbackDoneBtnEnabled: PropTypes.bool.isRequired,
};

const alertsNumberFilterPropTypes = {
  minAlertsNumber: PropTypes.number.isRequired,
  maxAlertsNumber: PropTypes.number.isRequired,
  minAlertsNumberValue: PropTypes.number.isRequired,
  maxAlertsNumberValue: PropTypes.number.isRequired,
  minAlertsNumberSavedValue: PropTypes.number.isRequired,
  maxAlertsNumberSavedValue: PropTypes.number.isRequired,
  onMinAlertsNumberValueChange: PropTypes.func.isRequired,
  onMaxAlertsNumberValueChange: PropTypes.func.isRequired,
  onClearAlertsNumberClick: PropTypes.func.isRequired,
  onAlertsNumberDoneClick: PropTypes.func.isRequired,
  onAlertsNumberClickOutside: PropTypes.func.isRequired,
  onAlertsNumberSliderValuesChange: PropTypes.func.isRequired,
  isAlertsNumberDoneBtnEnabled: PropTypes.bool.isRequired,
};

const noiseFilterPropTypes = {
  minNoise: PropTypes.number.isRequired,
  maxNoise: PropTypes.number.isRequired,
  minNoiseValue: PropTypes.number.isRequired,
  maxNoiseValue: PropTypes.number.isRequired,
  minNoiseSavedValue: PropTypes.number.isRequired,
  maxNoiseSavedValue: PropTypes.number.isRequired,
  onMinNoiseValueChange: PropTypes.func.isRequired,
  onMaxNoiseValueChange: PropTypes.func.isRequired,
  onClearNoiseClick: PropTypes.func.isRequired,
  onNoiseDoneClick: PropTypes.func.isRequired,
  onNoiseClickOutside: PropTypes.func.isRequired,
  onNoiseSliderValuesChange: PropTypes.func.isRequired,
  isNoiseDoneBtnEnabled: PropTypes.bool.isRequired,
};

SessionFiltersBase.propTypes = {
  t: PropTypes.func.isRequired,
  onResetFiltersClick: PropTypes.func.isRequired,
  ...dateFilterPropTypes,
  // ...timeFilterPropTypes,
  ...protocolsFilterPropTypes,
  ...programsFilterPropTypes,
  ...sessionTypesFilterPropTypes,
  ...trainingSiteFilterPropTypes,
  ...durationFilterPropTypes,
  ...positiveFeedbackFilterPropTypes,
  ...alertsNumberFilterPropTypes,
  ...noiseFilterPropTypes,
};

export const SessionFilters = withTranslation(SessionFiltersBase);

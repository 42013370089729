import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import {
  FormControl,
  RootRef,
  withStyles,
  InputLabel,
  Select,
  OutlinedInput,
} from '@material-ui/core';
import { paragraphFontSize } from '../../../cssInJs/constants';
import styles from './AssessmentComparisonGraph.scss';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';

const stylesFn = () => ({
  formControl: {
    margin: 0,
    minWidth: 175,
  },
  inputLabelRoot: {
    fontSize: paragraphFontSize,
  },
  selectRoot: {
    fontSize: paragraphFontSize,
    lineHeight: 'initial',
  },
});

const AssessmentSelectInput = ({
  classes,
  inputLabelRootRef,
  labelWidth,
  inputLabelText,
  assessmentOptions,
  value,
  // disabledValue,
  onChange,
  preTimestamp,
  postTimestamp,
  isPre,
}) => (
  <FormControl variant="outlined" className={classes.formControl}>
    <RootRef rootRef={inputLabelRootRef}>
      <InputLabel
        htmlFor={`select-${inputLabelText}`}
        classes={{ root: classes.inputLabelRoot }}
      >
        {inputLabelText}
      </InputLabel>
    </RootRef>
    <Select
      native
      value={value}
      onChange={event => onChange(event.target.value)}
      input={
        <OutlinedInput
          id={`select-${inputLabelText}`}
          labelWidth={labelWidth}
        />
      }
      classes={{
        root: classes.selectRoot,
      }}
    >
      {assessmentOptions.map(option => (
        <option
          key={option.value}
          value={option.value}
          disabled={
            isPre
              ? postTimestamp < option.timestamp
              : preTimestamp > option.timestamp
          }
        >
          {option.text}
        </option>
      ))}
    </Select>
  </FormControl>
);

AssessmentSelectInput.propTypes = exact({
  classes: PropTypes.object.isRequired,
  inputLabelRootRef: PropTypes.object.isRequired,
  labelWidth: PropTypes.number.isRequired,
  inputLabelText: PropTypes.string.isRequired,
  assessmentOptions: PropTypes.array,
  value: PropTypes.string,
  // disabledValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  preTimestamp: PropTypes.number,
  postTimestamp: PropTypes.number,
  isPre: PropTypes.bool,
});

const AssessmentComparisonGraphBase = ({
  classes,
  isInPreview,
  preAssessment,
  postAssessment,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  children,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  isSomeDataHidden,
  hiddenDataMsg,
}) => {
  const t = useTranslation();
  const preAssessmentInputLabelRef = useRef();
  const postAssessmentInputLabelRef = useRef();
  const [
    preAssessmentInputLabelWidth,
    setPreAssessmentInputLabelWidth,
  ] = useState(0);
  const [
    postAssessmentInputLabelWidth,
    setPostAssessmentInputLabelWidth,
  ] = useState(0);

  useEffect(() => {
    if (!isInPreview) {
      setPreAssessmentInputLabelWidth(
        preAssessmentInputLabelRef.current.offsetWidth
      );
      setPostAssessmentInputLabelWidth(
        postAssessmentInputLabelRef.current.offsetWidth
      );
    }
  }, [isInPreview]);

  return (
    <div className={styles.root}>
      {isInPreview ? (
        <div>
          <p>
            {t('comparing')}{' '}
            {`Assessment: ${dayJS(preAssessmentTimestamp).format(
              DAY_JS_DATE_FORMATS.dayMonthYearSlashesWithTime
            )}`}{' '}
            to{' '}
            {`Assessment: ${dayJS(postAssessmentTimestamp).format(
              DAY_JS_DATE_FORMATS.dayMonthYearSlashesWithTime
            )}`}
          </p>
        </div>
      ) : (
        <div>
          <div className={styles.selects_container}>
            <div>
              <AssessmentSelectInput
                {...{
                  inputLabelRootRef: preAssessmentInputLabelRef,
                  labelWidth: preAssessmentInputLabelWidth,
                  value: preAssessment,
                  // disabledValue: postAssessment,
                  onChange: onPreAssessmentChange,
                  inputLabelText: t('pre_assessment'),
                  assessmentOptions,
                  classes,
                  preTimestamp: preAssessmentTimestamp,
                  postTimestamp: postAssessmentTimestamp,
                  isPre: true,
                }}
              />
            </div>
            <div>
              <AssessmentSelectInput
                {...{
                  inputLabelRootRef: postAssessmentInputLabelRef,
                  labelWidth: postAssessmentInputLabelWidth,
                  value: postAssessment,
                  // disabledValue: preAssessment,
                  onChange: onPostAssessmentChange,
                  inputLabelText: t('post_assessment'),
                  assessmentOptions,
                  classes,
                  preTimestamp: preAssessmentTimestamp,
                  postTimestamp: postAssessmentTimestamp,
                  isPre: false,
                }}
              />
            </div>
          </div>
          {isSomeDataHidden && !isInPreview && (
            <div className={styles.hidden_data_msg}>
              <CustomAlert severity="warning">{hiddenDataMsg}</CustomAlert>
            </div>
          )}
        </div>
      )}

      <div>{children}</div>
    </div>
  );
};

AssessmentComparisonGraphBase.defaultProps = {
  isInPreview: false,
  isSomeDataHidden: false,
  hiddenDataMsg:
    'Some data is not showing since the assessments being compared did not have matching stages.',
};

AssessmentComparisonGraphBase.propTypes = exact({
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string,
  postAssessment: PropTypes.string,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  isSomeDataHidden: PropTypes.bool,
  hiddenDataMsg: PropTypes.string,
});

export const AssessmentComparisonGraph = React.memo(
  withStyles(stylesFn)(AssessmentComparisonGraphBase)
);
AssessmentComparisonGraph.displayName = 'AssessmentComparisonGraph';

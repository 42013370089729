import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Text,
} from 'recharts';
import { useBoundingRect } from '../../../Core/hooks/useBoundingRect';
import { useMediaMatch } from '../../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../../cssInJs/constants';

const PerformanceGraphBase = ({ data }) => {
  const unit = '%';

  const rootElementRef = useRef();
  const rootElementBoundingRect = useBoundingRect(rootElementRef);
  const calcOuterRadius = () => {
    if (rootElementBoundingRect && rootElementBoundingRect.width < 480) {
      return Math.min(rootElementBoundingRect.width / 5, 65);
    }

    return '80%';
  };
  const ticksWidth =
    rootElementBoundingRect && rootElementBoundingRect.width < 480
      ? Math.max(rootElementBoundingRect.width / 3.5, 60)
      : window.Number.POSITIVE_INFINITY;

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  return (
    <div ref={rootElementRef}>
      <ResponsiveContainer width="100%" height={300}>
        <RadarChart data={data} outerRadius={calcOuterRadius()}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="category"
            width={ticksWidth}
            /* eslint-disable react/prop-types */
            tick={props => {
              const y = (() => {
                const oldY = props.y;
                if (props.payload.coordinate === -90) {
                  if (
                    rootElementBoundingRect &&
                    rootElementBoundingRect.width < 466
                  ) {
                    return oldY + 20;
                  }
                  return oldY + 10;
                }

                return oldY;
              })();

              const fontSize = isMatchingXSScreen ? 11 : 13;
              const width = isMatchingXSScreen ? props.width + 48 : props.width;

              return (
                <Text {...props} y={y} width={width} fontSize={fontSize}>
                  {props.payload.value}
                </Text>
              );
            }}
            /* eslint-enable react/prop-types */
          />
          <PolarRadiusAxis
            unit={unit}
            domain={[0, 100]}
            axisLine={false}
            tick={false}
          />
          <Radar
            name="Score"
            dataKey="score"
            stroke="#a0c0bb"
            fill="#a0c0bb"
            fillOpacity={0.6}
            unit={unit}
          />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

PerformanceGraphBase.propTypes = exact({
  data: PropTypes.array.isRequired,
});

export const PerformanceGraph = React.memo(PerformanceGraphBase);
PerformanceGraph.displayName = 'PerformanceGraph';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayJS from 'dayjs';
import exact from 'prop-types-exact';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import styles from './NotesTable.scss';
import { CustomTable } from '../../Core/Components/CustomTable/CustomTable';

export const NotesTable = ({
  customerNotes,
  onEditNote,
  onPinNote,
  onDeleteNoteClicked,
  userPageLogger,
  patientId,
}) => {
  const notesWriters = {};
  Object.keys(customerNotes).forEach(noteId => {
    notesWriters[customerNotes[noteId].by] = 1;
  });
  const shouldShowByColumn = Object.keys(notesWriters).length > 1;

  const stripHtmlTags = htmlString =>
    htmlString.replace(/<[^>]*>|&[^;]+;/g, '');

  const COLLAPSEDCELLHIEGHT = 30;
  const [expandedCells, setExpandedCells] = useState({});
  const [noteOpenMenuId, setNoteOpenMenuId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const columnsIds = shouldShowByColumn
    ? ['date', 'time', 'by', 'note', 'action']
    : ['date', 'time', 'note', 'action'];

  // const tableHeads = (shouldShowByColumn
  //   ? [' ', ' ', ' ', ' ']
  //   : [' ', ' ', ' ']
  // )
  //   .map(tableHeadText => <span className={styles.cell}>{tableHeadText}</span>)
  //   .map((node, index) => ({
  //     id: columnsIds[index],
  //     node,
  //   }));

  const pinnedNotes = Object.keys(customerNotes)
    .filter(noteId => customerNotes[noteId].isPinned)
    .sort((a, b) => customerNotes[b].pinDate - customerNotes[a].pinDate);

  const unPinnedNotes = Object.keys(customerNotes)
    .filter(noteId => !customerNotes[noteId].isPinned)
    .reverse();

  const sortedNotes = pinnedNotes.concat(unPinnedNotes);

  const tableRows = sortedNotes.map(noteId => ({
    id: noteId,
    cells: [
      <span className={styles.cell}>
        {dayJS(customerNotes[noteId].timestamp).format(
          DAY_JS_DATE_FORMATS.americanWithTime
        )}
      </span>,
      <span
        className={styles.cell}
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {customerNotes[noteId].by}
      </span>,
      <span
        className={!expandedCells[noteId] ? styles.collapsed : null}
        style={{ fontSize: 12, textAlign: 'initial' }}
      >
        {expandedCells[noteId] ? (
          <div
            dangerouslySetInnerHTML={{ __html: customerNotes[noteId].text }}
          />
        ) : (
          <div className={styles.collapsed_text}>
            {stripHtmlTags(customerNotes[noteId].text)}
          </div>
        )}
      </span>,
      <div>
        <IconButton
          color="default"
          onClick={() => {
            const copy = { ...expandedCells };
            copy[noteId] = !copy[noteId];
            setExpandedCells(copy);
            userPageLogger.log('clinet_notes_row_action', {
              customerId: patientId,
              action: 'expanded',
              noteId,
              by: customerNotes[noteId].by,
            });
          }}
        >
          {expandedCells[noteId] ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </IconButton>

        <IconButton
          color="default"
          onClick={event => {
            setNoteOpenMenuId(noteId);
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={noteId}
          open={noteOpenMenuId === noteId}
          anchorEl={anchorEl}
          onClose={() => {
            setNoteOpenMenuId(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={styles.note_menu_container}>
            <div
              className={styles.note_menu_item}
              onClick={() => {
                onEditNote(noteId);
                setNoteOpenMenuId(null);
                userPageLogger.log('clinet_notes_row_action', {
                  customerId: patientId,
                  action: 'edit',
                  noteId,
                  by: customerNotes[noteId].by,
                });
              }}
            >
              Edit
            </div>
            <div
              className={styles.note_menu_item}
              onClick={() => {
                onPinNote(noteId);
                setNoteOpenMenuId(null);
                userPageLogger.log('clinet_notes_row_action', {
                  customerId: patientId,
                  action: !customerNotes[noteId].isPinned ? 'unpin' : 'pin',
                  noteId,
                  by: customerNotes[noteId].by,
                });
              }}
            >
              {customerNotes[noteId].isPinned ? 'Unpin' : 'Pin'} this note
            </div>
            <div
              className={styles.note_menu_item}
              style={{ color: '#f85959' }}
              onClick={() => {
                onDeleteNoteClicked(noteId);
                setNoteOpenMenuId(null);
                userPageLogger.log('clinet_notes_row_action', {
                  customerId: patientId,
                  action: 'delete',
                  noteId,
                  by: customerNotes[noteId].by,
                });
              }}
            >
              Delete
            </div>
          </div>
        </Popover>
      </div>,
    ].map((node, i) => ({
      id: columnsIds[i],
      node,
    })),
    maxHeight: expandedCells[noteId] ? 'unset' : COLLAPSEDCELLHIEGHT,
    isMarkedRow: customerNotes[noteId].isPinned,
  }));

  const colgroup = shouldShowByColumn ? (
    <colgroup>
      <col width="30%" />
      <col width="15%" />
      <col width="40%" />
      <col width="15%" />
    </colgroup>
  ) : (
    <colgroup>
      <col width="30%" />
      <col width="50%" />
      <col width="20%" />
    </colgroup>
  );

  if (!shouldShowByColumn) {
    // eslint-disable-next-line array-callback-return
    tableRows.map(row => {
      row.cells.splice(1, 1);
    });
  }

  return (
    <div className={styles.root}>
      <CustomTable
        tableHeads={[]}
        tableRows={tableRows}
        tableContainerClass={styles.table_container}
        colgroup={colgroup}
        shouldAddfixedTable
        rowsPerPageOptions={[5, 10, 15]}
        shouldShowRowsPerPageOptions
        verticalAlign
      />
    </div>
  );
};

NotesTable.propTypes = exact({
  customerNotes: PropTypes.object.isRequired,
  onEditNote: PropTypes.func.isRequired,
  onPinNote: PropTypes.func.isRequired,
  onDeleteNoteClicked: PropTypes.func.isRequired,
  userPageLogger: PropTypes.object,
  patientId: PropTypes.string,
});

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import noReportImage from '../../../../assets/no-report.png';
import styles from './EmptyReportsReportTab.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const EmptyReportsReportTabBase = ({ onReportGenerate }) => {
  const t = useTranslation();

  return (
    <Paper>
      <div className={styles.root}>
        <div className={styles.no_report_image_container}>
          <img src={noReportImage} alt="no-report" />
        </div>
        <div className={styles.content}>
          <h3>{t('generate_customized_reports')}</h3>
          {/* <p>{t('create_a_report_long_description')}</p> */}
          <a
            target="blank"
            href="https://intercom.help/dashboard-myndlift/en/articles/3214892-progress-report-generator"
          >
            {t('learn_more_here')}
          </a>
        </div>
        <div className={styles.button_container}>
          <Button
            variant="contained"
            onClick={onReportGenerate}
            color="primary"
          >
            <span className={styles.btn} data-test-id="generate-report-btn">
              {t('generate_first_report')}
            </span>
          </Button>
        </div>
      </div>
    </Paper>
  );
};

EmptyReportsReportTabBase.propTypes = {
  onReportGenerate: PropTypes.func.isRequired,
};

export const EmptyReportsReportTab = EmptyReportsReportTabBase;

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SelectTags_size_17{font-size:17px}.SelectTags_size_10{font-size:10px}.SelectTags_size_11{font-size:11px}.SelectTags_size_13,.SelectTags_chip_label{font-size:13px}.SelectTags_size_15{font-size:15px}.SelectTags_size_14{font-size:14px}.SelectTags_size_16{font-size:16px}.SelectTags_size_12,.SelectTags_btn_text{font-size:12px}.SelectTags_extra_header{font-size:18px;font-weight:500;color:#000000}.SelectTags_header{font-size:17px;font-weight:500;color:#000000}.SelectTags_header_only_font_size{font-size:17px;font-weight:500}.SelectTags_sub_header{font-size:13px;color:#878787}.SelectTags_sub_header_black{font-size:13px;color:black}.SelectTags_sub_header_only_font_size{font-size:13px}.SelectTags_paragraph_only_font_size{font-size:12px}.SelectTags_tab_font_size{font-size:13px}.SelectTags_pop_out_header{font-size:20px}.SelectTags_title{font-size:15px;font-weight:500;color:#484848}.SelectTags_title_black{font-size:15px;font-weight:500;color:black}.SelectTags_title_only_font_size{font-size:15px}.SelectTags_paragraph{font-size:12px;color:#484848}.SelectTags_btn{font-size:14px}.SelectTags_btn_container{display:flex}.SelectTags_chip_delete_icon{fill:white !important}.SelectTags_tags_container{padding-top:20px;display:flex;flex-wrap:wrap}.SelectTags_tags_container>*{margin-right:10px;margin-bottom:10px}@media only screen and (max-width: 1200px){.SelectTags_chip_root{height:-webkit-fit-content !important;height:-moz-fit-content !important;height:fit-content !important;padding-top:4px !important;padding-bottom:4px !important}}\n", ""]);

// Exports
exports.locals = {
	"size_17": "SelectTags_size_17",
	"size_10": "SelectTags_size_10",
	"size_11": "SelectTags_size_11",
	"size_13": "SelectTags_size_13",
	"chip_label": "SelectTags_chip_label",
	"size_15": "SelectTags_size_15",
	"size_14": "SelectTags_size_14",
	"size_16": "SelectTags_size_16",
	"size_12": "SelectTags_size_12",
	"btn_text": "SelectTags_btn_text",
	"extra_header": "SelectTags_extra_header",
	"header": "SelectTags_header",
	"header_only_font_size": "SelectTags_header_only_font_size",
	"sub_header": "SelectTags_sub_header",
	"sub_header_black": "SelectTags_sub_header_black",
	"sub_header_only_font_size": "SelectTags_sub_header_only_font_size",
	"paragraph_only_font_size": "SelectTags_paragraph_only_font_size",
	"tab_font_size": "SelectTags_tab_font_size",
	"pop_out_header": "SelectTags_pop_out_header",
	"title": "SelectTags_title",
	"title_black": "SelectTags_title_black",
	"title_only_font_size": "SelectTags_title_only_font_size",
	"paragraph": "SelectTags_paragraph",
	"btn": "SelectTags_btn",
	"btn_container": "SelectTags_btn_container",
	"chip_delete_icon": "SelectTags_chip_delete_icon",
	"tags_container": "SelectTags_tags_container",
	"chip_root": "SelectTags_chip_root"
};
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import get from 'lodash/get';
import omit from 'lodash/omit';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns';

import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
// import { useTranslation } from '../../Core/hooks/useTranslation';

import {
  ScheduleAssessmentDialog,
  SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS,
} from '../UserPage/AssessmentsTab/ScheduleAssessmentDialog/ScheduleAssessmentDialog';
import {
  getStandardAssessmentStagesInfo,
  defaultAfterTrainingAssessmentIntervalMinutes,
} from '../../models/assessments/assessments';
import {
  isAnEEGRegionStage,
  getAllQuestionnaires,
} from '../../models/symptom-tracker/symptom-tracker';
import {
  isAppCompilableWithSymptomQuestionnaires,
  switchCustomerToNewSymptomTracker,
} from '../../models/customer/customer';
import { safeParseDate } from '../../utils/utils';
// import { useClinicInfo } from '../../Core/hooks/useClinicInfo';
import {
  androidVersionCompatiableWithSymptomQuestionnaires,
  iOSVersionCompatiableWithSymptomQuestionnaires,
} from '../../models/symptom-tracker/new-symptom-tracker-app-versions';

import {
  AddClientModal,
  addClientSteps,
} from '../AddClientModal/AddClientModal';

import { NeuroPromoDialog } from '../NeuroPromoDialog/NeuroPromoDialog';

import { CustomDialogSuccessMessage } from '../../Core/Components/CustomDialogSuccessMessage/CustomDialogSuccessMessage';
import { ModalFooter } from '../../Core/Components/Modal/ModalFooter';

const userPageLogger = new MyEventWrapper('user_page');
const doneAssessmentsInitialValue = Symbol('IV');
const deployedAssessmentInitialValue = Symbol('IV');
const didClinicSetupAssessmentForThisCustomerBeforeInitialValue = Symbol('IV');
const initialValue = Symbol('iv');
const SCHEDULE_ASSESSMENT_DIALOG_OPENERS = {
  add: 'add',
  edit: 'edit',
};
// eslint-disable-next-line consistent-return
const AssessmentPopupBase = ({ isOpen, userId, onClose, t }) => {
  // ************************************************************************************************
  // *************************************ASSESSMEMT*************************************************
  // ************************************************************************************************

  // const clinicInfo = useClinicInfo();

  // const t = useTranslation();

  const getDefaultAssessmentScheduleState = assessmentStages => ({
    recurring: false,
    beforeNextSession: true,
    afterTrainingDuration: defaultAfterTrainingAssessmentIntervalMinutes,
    selectedStages: assessmentStages && Object.keys(assessmentStages),
    frequency: 0,
    intervalType: 'weeks',
    isAssessmentOpen: true,
  });

  const [assessmentStagesInfo, setAssessmentStagesInfo] = useState(null);
  useEffect(() => {
    (async () => {
      setAssessmentStagesInfo(
        await getStandardAssessmentStagesInfo(sessionStorage.userId)
      );
    })();
  }, []);

  const [customerInfo, setCustomerInfo] = useFirebaseDB({
    path: `userInfo/${userId}`,
  });

  let allAssessmentStages;
  if (customerInfo && assessmentStagesInfo) {
    allAssessmentStages = customerInfo.shouldUseNewSymptomTracker
      ? assessmentStagesInfo.names
      : omit(
          assessmentStagesInfo.names,
          assessmentStagesInfo.ids.QUESTIONNAIRES
        );
  }

  const [assessmentScheduleState, setAssessmentScheduleState] = useState(
    getDefaultAssessmentScheduleState(allAssessmentStages)
  );

  const setSelectedStages = useCallback(
    selectedStages =>
      setAssessmentScheduleState(prvAssessmentScheduleState => ({
        ...prvAssessmentScheduleState,
        selectedStages,
      })),
    []
  );

  const [
    scheduleAssessmentDialogRenderedStep,
    setScheduleAssessmentDialogRenderedStep,
  ] = useState(SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions);

  const [
    scheduleAssessmentDialogOpener,
    setIsScheduleAssessmentDialogOpener,
  ] = useState(null);

  const [
    scheduleAssessmentDialogErrorMsg,
    setScheduleAssessmentDialogErrorMsg,
  ] = useState(null);

  const [
    selectedBrainDisordersInEdit,
    setSelectedBrainDisordersInEdit,
  ] = useState(null);

  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);

  const [addClientModalFinalStep, setAddClientModalFinalStep] = useState(
    undefined
  );

  const [
    mightEnableQuestionnairesInScheduleAssessmentDialog,
    setMightEnableQuestionnairesInScheduleAssessmentDialog,
  ] = useState(false);

  const [addClientModalInitialStep, setAddClientModalInitialStep] = useState(
    undefined
  );

  const [
    questionnairesShouldBeEnabledInScheduleAssessmentDialog,
    setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog,
  ] = useState(false);

  const [addClientReachedLimitModal, setAddClientReachedLimitModal] = useState(
    false
  );

  const closeAddClientModal = useCallback(() => {
    const addClientModalStep = addClientModalInitialStep;
    userPageLogger.log('on_close_edit_user_information_modal');
    setIsAddClientModalOpen(false);
    setAddClientModalInitialStep(undefined);
    setAddClientModalFinalStep(undefined);
    setMightEnableQuestionnairesInScheduleAssessmentDialog(false);
    setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog(false);
    if (addClientModalStep === addClientSteps.selectTags) {
      // eslint-disable-next-line  no-undef, no-restricted-globals
      location.reload();
    }
  }, [addClientModalInitialStep]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (questionnairesShouldBeEnabledInScheduleAssessmentDialog) {
      const prvSelectedStages = assessmentScheduleState.selectedStages || [];
      const newSelectedStages = prvSelectedStages.includes(
        assessmentStagesInfo.ids.QUESTIONNAIRES
      )
        ? prvSelectedStages
        : [assessmentStagesInfo.ids.QUESTIONNAIRES, ...prvSelectedStages];
      if (prvSelectedStages !== newSelectedStages) {
        return setSelectedStages(newSelectedStages);
      }
    }
  }, [
    assessmentScheduleState,
    questionnairesShouldBeEnabledInScheduleAssessmentDialog,
    setSelectedStages,
    assessmentStagesInfo,
  ]);

  const [selectedBrainDisorders, setSelectedBrainDisorders] = useFirebaseDB({
    path: `brainDisordersSymptoms/${userId}`,
  });

  const updateBrainDisordersInEditBasedOnSelectedBrainDisorders = useCallback(() => {
    if (selectedBrainDisorders) {
      setSelectedBrainDisordersInEdit(Object.keys(selectedBrainDisorders));
    } else {
      setSelectedBrainDisordersInEdit([]);
    }
  }, [selectedBrainDisorders]);

  useEffect(() => {
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders();
  }, [updateBrainDisordersInEditBasedOnSelectedBrainDisorders]);

  const [deployedAssessment, setDeployedAssessment] = useFirebaseDB({
    path: `assessments/deployedAssessments/${userId}`,
    initialValue: deployedAssessmentInitialValue,
  });

  const [brainDisorders] = useFirebaseDB({
    path: 'brainDisorders',
    onceListener: true,
  });

  const [
    didClinicSetupAssessmentForThisCustomerBefore,
    setDidClinicSetupAssessmentForThisCustomerBefore,
  ] = useFirebaseDB({
    path: `assessments/didClinicSetupAssessmentForThisCustomerBefore/${sessionStorage.userId}/${userId}`,
    initialValue: didClinicSetupAssessmentForThisCustomerBeforeInitialValue,
  });

  const [canAccessNf] = useFirebaseDB({
    path: `clinicConfig/nfFlag/${sessionStorage.userId}/isEnabled`,
    onceListener: true,
  });

  const [allTags] = useFirebaseDB({
    path: 'symptomTracker/clientTags',
    defaultValue: useRef({}).current,
  });

  const [selectedTags] = useFirebaseDB({
    path: `symptomTracker/selectedTags/${userId}`,
    initialValue,
    defaultValue: useRef([]).current,
  });

  const [minsUsage] = useFirebaseDB({
    path: `achievements/usages/${userId}/totalUsage/minsUsage`,
    defaultValue: 0,
  });

  const [numSessions] = useFirebaseDB({
    path: `achievements/usages/${userId}/totalUsage/numOfSessions`,
    defaultValue: 0,
  });

  const [deployedQuestionnaires] = useFirebaseDB({
    path: `symptomTracker/deployedQuestionnaires/${userId}`,
    initialValue,
    defaultValue: useRef([]).current,
  });

  const [doneAssessments] = useFirebaseDB({
    path: `assessments/results/${userId}`,
    initialValue: doneAssessmentsInitialValue,
  });

  const [usedAssessmentBeforeJune24] = useFirebaseDB({
    path: `clinicConfig/assessments/usedAssessmentBeforeJune24/${sessionStorage.userId}`,
    onceListener: true,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/nfAddOnPlanId`,
    onceListener: true,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/packageType`,
    onceListener: true,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  //   const deployedQuestionnaires = deployedQuestionnaires1 ? deployedQuestionnaires1.map(id => ({
  //     ...allQuestionnaires[id],
  //     id,
  //   })) : []

  const [allQuestionnaires, setAllQuestionnaires] = useState(null);
  useEffect(() => {
    (async () => {
      setAllQuestionnaires(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId })
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnaires(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId })
        );
      });
  }, []);
  const [
    allQuestionnairesOnlyClinic,
    setAllQuestionnairesOnlyClinic,
  ] = useState(null);
  useEffect(() => {
    (async () => {
      setAllQuestionnairesOnlyClinic(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnairesOnlyClinic(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
        );
      });
  }, []);

  const [customerGender, setCustomerGender] = useState(null);
  const [customerDateOfBirth, setCustomerDateOfBirth] = useState(null);

  useEffect(() => {
    if (customerInfo) {
      setCustomerGender(
        customerInfo.privateDetails && customerInfo.privateDetails.gender
      );
      setCustomerDateOfBirth(
        safeParseDate({
          str: get(customerInfo, 'privateDetails.dateOfBirth'),
        })
      );
    }
  }, [customerInfo]);

  useEffect(() => {
    if (deployedAssessment) {
      setAssessmentScheduleState(deployedAssessment);
    }
  }, [deployedAssessment]);

  const [
    areScheduleAssessmentDialogEEGRegionsEnabled,
    setAreScheduleAssessmentDialogEEGRegionsEnabled,
  ] = useState(true);

  useEffect(() => {
    if (
      deployedAssessment &&
      assessmentStagesInfo &&
      deployedAssessment !== deployedAssessmentInitialValue
    ) {
      setAssessmentScheduleState(deployedAssessment);
      setAreScheduleAssessmentDialogEEGRegionsEnabled(
        (deployedAssessment.stagesType === 'headsetStages' &&
          !!(deployedAssessment.selectedStages || []).find(
            stage => stage === 'Headset'
          )) ||
          (deployedAssessment.selectedStages || []).some(stage =>
            Object.values(assessmentStagesInfo.eegIds).includes(stage)
          )
      );
    }
  }, [deployedAssessment, assessmentStagesInfo]);

  const resetScheduleAssessmentDialogState = useCallback(() => {
    if (deployedAssessment) {
      setAssessmentScheduleState(deployedAssessment);
      if (assessmentStagesInfo) {
        setAreScheduleAssessmentDialogEEGRegionsEnabled(
          (deployedAssessment.selectedStages || []).some(stage =>
            Object.values(assessmentStagesInfo.eegIds).includes(stage)
          )
        );
      }
    } else {
      setAssessmentScheduleState(
        getDefaultAssessmentScheduleState(allAssessmentStages)
      );
    }

    setCustomerGender(customerInfo.privateDetails.gender);
    setCustomerDateOfBirth(
      safeParseDate({
        str: get(customerInfo, 'privateDetails.dateOfBirth'),
      })
    );
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders();
    setScheduleAssessmentDialogErrorMsg(null);
  }, [
    deployedAssessment,
    customerInfo,
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders,
    allAssessmentStages,
  ]);

  const doneAssessmentsSortedByDecreasingDate =
    doneAssessments !== doneAssessmentsInitialValue &&
    fromPairs(toPairs(doneAssessments).reverse());

  const sortedReadyDoneAssessments = pickBy(
    doneAssessmentsSortedByDecreasingDate,
    assessment => assessment.isReady && !assessment.isDeleted
  );

  const doesDoneAssessmentsHasData = !isEmpty(sortedReadyDoneAssessments);

  const symptomsTrackingAnswersInitialValueRef = useRef(Symbol('iv'));
  const [symptomsTrackingAnswers] = useFirebaseDB({
    path: `symptomTracker/answers/${userId}`,
    initialValue: symptomsTrackingAnswersInitialValueRef.current,
    defaultValue: useRef({}).current,
  });

  const shouldShowEmptyAssessmentsTab =
    symptomsTrackingAnswers &&
    !doesDoneAssessmentsHasData &&
    !deployedAssessment &&
    isEmpty(symptomsTrackingAnswers);

  const switchToNewSymptomTracker = useCallback(async () => {
    userPageLogger.log('on_customer_switched_to_new_symptom_tracker', {
      userId,
    });
    setIsScheduleAssessmentDialogOpener(null);
    await switchCustomerToNewSymptomTracker(userId);
    setAddClientModalInitialStep(addClientSteps.selectTags);
    setIsAddClientModalOpen(true);
  }, [userId]);

  const onAddAssessment = useCallback(() => {
    userPageLogger.log('on_set_recurring_assessment', { userId });
    if (shouldShowEmptyAssessmentsTab) {
      switchToNewSymptomTracker();
    } else if (
      customerInfo &&
      !customerInfo.shouldUseNewSymptomTracker &&
      didClinicSetupAssessmentForThisCustomerBefore === null
    ) {
      setScheduleAssessmentDialogRenderedStep(
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
      );
      setIsScheduleAssessmentDialogOpener(
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      );
    } else {
      setScheduleAssessmentDialogRenderedStep(
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
      );
      setIsScheduleAssessmentDialogOpener(
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      );
    }
  }, [
    customerInfo,
    userId,
    didClinicSetupAssessmentForThisCustomerBefore,
    shouldShowEmptyAssessmentsTab,
    switchToNewSymptomTracker,
  ]);

  const onEditScheduleAssessmentCard = useCallback(() => {
    userPageLogger.log('on_recurring_assessment_edit', {
      userId,
    });
    setIsScheduleAssessmentDialogOpener(
      SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
    );
  }, [userId]);

  const onScheduleAssessmentDialogSecondaryBtnClick = useCallback(() => {
    userPageLogger.log('on_schedule_assessment_dialog_secondary_btn_click', {
      userId,
      scheduleAssessmentDialogRenderedStep,
    });
    if (
      didClinicSetupAssessmentForThisCustomerBefore ||
      customerInfo.shouldUseNewSymptomTracker
    ) {
      setIsScheduleAssessmentDialogOpener(null);
      resetScheduleAssessmentDialogState();
      onClose();
    } else {
      setScheduleAssessmentDialogErrorMsg(null);
      onClose();
      if (
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
      ) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
        );
      } else if (
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
      ) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
        );
      } else {
        setIsScheduleAssessmentDialogOpener(null);
        resetScheduleAssessmentDialogState();
        onClose();
      }
    }
    // if (tab === 'assessments'){
    //   return window.open(
    //     `customers.html`,
    //     '_self'
    //   );
    // }
  }, [
    userId,
    scheduleAssessmentDialogRenderedStep,
    didClinicSetupAssessmentForThisCustomerBefore,
    customerInfo,
    resetScheduleAssessmentDialogState,
  ]);

  const sortSelectedStages = useCallback(
    (stages, stagesType) => {
      return [
        ...(stages.includes(assessmentStagesInfo.ids.QUESTIONNAIRES)
          ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
          : []),
        // eslint-disable-next-line  no-nested-ternary
        ...(stagesType === 'headsetStages'
          ? areScheduleAssessmentDialogEEGRegionsEnabled
            ? ['Headset']
            : []
          : stages
              .filter(stage =>
                isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
              )
              .sort((stage1, stage2) => {
                const sortedEEGIds = Object.values(assessmentStagesInfo.eegIds);
                return (
                  sortedEEGIds.indexOf(stage1) - sortedEEGIds.indexOf(stage2)
                );
              })),
        ...(stages.includes(assessmentStagesInfo.ids.CPT)
          ? [assessmentStagesInfo.ids.CPT]
          : []),
      ];
    },
    [assessmentStagesInfo, areScheduleAssessmentDialogEEGRegionsEnabled]
  );

  const [
    isCustomerAppCompatiableWithSymptomQuestionnaires,
    setIsCustomerAppCompatiableWithSymptomQuestionnaires,
  ] = useState(null);
  useEffect(() => {
    (async () => {
      const isCompatibleWithSymptomQuestionnaires = await isAppCompilableWithSymptomQuestionnaires(
        { customerId: userId }
      );
      setIsCustomerAppCompatiableWithSymptomQuestionnaires(
        isCompatibleWithSymptomQuestionnaires
      );
    })();
  }, []);

  const isAssessmentScheduleDeployed =
    deployedAssessment &&
    (deployedAssessment.isAssessmentOpen || deployedAssessment.recurring) &&
    !!deployedAssessment.selectedStages;

  useEffect(() => {
    if (isOpen) {
      if (shouldShowEmptyAssessmentsTab) {
        onAddAssessment();
      } else {
        if (!isAssessmentScheduleDeployed) {
          onAddAssessment();
        } else {
          onEditScheduleAssessmentCard();
        }
      }
    }
  }, [isOpen, shouldShowEmptyAssessmentsTab, isAssessmentScheduleDeployed]);

  let filteredDoneAssessments;
  if (doneAssessments == null) {
    filteredDoneAssessments = [];
  } else {
    filteredDoneAssessments = Object.values(doneAssessments).filter(
      doneAssessment => !doneAssessment.isDeleted
    );
  }

  const handleScheduleAssessmentDialogPrimaryBtnClickEditMode = useCallback(() => {
    if (!assessmentScheduleState.recurring) {
      setDeployedAssessment({
        ...assessmentScheduleState,
        selectedStages: sortSelectedStages(
          assessmentScheduleState.selectedStages,
          assessmentScheduleState.stagesType
        ).filter(
          stage =>
            stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
            deployedQuestionnaires.length
        ),
        numSessions,
        creationDate: Date.now(),
        beforeNextSession: true,
        isAssessmentOpen: true,
        // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
      });
    } else {
      setDeployedAssessment({
        ...assessmentScheduleState,
        selectedStages: sortSelectedStages(
          assessmentScheduleState.selectedStages,
          assessmentScheduleState.stagesType
        ).filter(
          stage =>
            stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
            deployedQuestionnaires.length
        ),
        beforeNextSession:
          !filteredDoneAssessments.length &&
          (assessmentScheduleState.intervalType === 'months' ||
            assessmentScheduleState.intervalType === 'weeks' ||
            assessmentScheduleState.intervalType === 'days')
            ? true
            : assessmentScheduleState.beforeNextSession,
        isAssessmentOpen:
          !filteredDoneAssessments.length &&
          (assessmentScheduleState.intervalType === 'months' ||
            assessmentScheduleState.intervalType === 'weeks' ||
            assessmentScheduleState.intervalType === 'days')
            ? true
            : !!assessmentScheduleState.isAssessmentOpen,
        numSessions,
        creationDate: Date.now(),
        // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
      });
    }

    setIsScheduleAssessmentDialogOpener(null);
    onClose();
  }, [
    assessmentScheduleState,
    setDeployedAssessment,
    sortSelectedStages,
    assessmentStagesInfo,
    deployedQuestionnaires,
  ]);

  const handleScheduleAssessmentDialogPrimaryBtnClickAddMode = useCallback(() => {
    if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
    ) {
      if (selectedBrainDisordersInEdit.length > 0) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
        );
        setScheduleAssessmentDialogErrorMsg(null);
      } else {
        setScheduleAssessmentDialogErrorMsg(
          'Please select at least one disorder tag'
        );
      }
    } else if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
    ) {
      if (customerDateOfBirth) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
        );
        setScheduleAssessmentDialogErrorMsg(null);
      } else {
        setScheduleAssessmentDialogErrorMsg('Date of birth is required');
      }
    } else {
      userPageLogger.log('on_schedule_assessment_for_customer', {
        userId,
        minsUsage,
        ...assessmentScheduleState,
        scheduleAssessmentDialogRenderedStep,
      });
      if (!assessmentScheduleState.recurring) {
        setDeployedAssessment({
          ...assessmentScheduleState,
          selectedStages: sortSelectedStages(
            assessmentScheduleState.selectedStages,
            assessmentScheduleState.stagesType
          ).filter(
            stage =>
              stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
              deployedQuestionnaires.length
          ),
          minsUsage,
          numSessions,
          creationDate: Date.now(),
          beforeNextSession: true,
          isAssessmentOpen: true,
          // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
        });
      } else {
        setDeployedAssessment({
          ...assessmentScheduleState,
          selectedStages: sortSelectedStages(
            assessmentScheduleState.selectedStages,
            assessmentScheduleState.stagesType
          ).filter(
            stage =>
              stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
              deployedQuestionnaires.length
          ),
          beforeNextSession:
            !filteredDoneAssessments.length &&
            (assessmentScheduleState.intervalType === 'months' ||
              assessmentScheduleState.intervalType === 'weeks' ||
              assessmentScheduleState.intervalType === 'days')
              ? true
              : assessmentScheduleState.beforeNextSession,
          isAssessmentOpen:
            !filteredDoneAssessments.length &&
            (assessmentScheduleState.intervalType === 'months' ||
              assessmentScheduleState.intervalType === 'weeks' ||
              assessmentScheduleState.intervalType === 'days')
              ? true
              : assessmentScheduleState.isAssessmentOpen,
          minsUsage,
          numSessions,
          creationDate: Date.now(),
          // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
        });
      }

      setDidClinicSetupAssessmentForThisCustomerBefore(true);
      setSelectedBrainDisorders(
        selectedBrainDisordersInEdit.reduce(
          (acc, brainDisorder) => ({
            ...acc,
            [brainDisorder]: true,
          }),
          {}
        )
      );

      setCustomerInfo({
        ...customerInfo,
        privateDetails: {
          ...customerInfo.privateDetails,
          dateOfBirth: format(customerDateOfBirth, 'yyyy-MM-dd'),
          gender: customerGender,
        },
      });

      setIsScheduleAssessmentDialogOpener(null);
      onClose();
    }
  }, [
    scheduleAssessmentDialogRenderedStep,
    selectedBrainDisordersInEdit,
    customerDateOfBirth,
    userId,
    minsUsage,
    assessmentScheduleState,
    setDeployedAssessment,
    sortSelectedStages,
    setDidClinicSetupAssessmentForThisCustomerBefore,
    setSelectedBrainDisorders,
    setCustomerInfo,
    customerInfo,
    customerGender,
    assessmentStagesInfo,
    deployedQuestionnaires,
  ]);

  const onScheduleAssessmentDialogPrimaryBtnClick = useCallback(() => {
    userPageLogger.log('on_schedule_assessment_dialog_primary_btn_click', {
      customerId: userId,
      minsUsage,
      ...assessmentScheduleState,
      scheduleAssessmentDialogRenderedStep,
      entered_from: 'customers.html',
      modification:
        scheduleAssessmentDialogOpener ===
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
          ? 'edit'
          : 'add',
    });
    if (
      scheduleAssessmentDialogOpener === SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
    ) {
      handleScheduleAssessmentDialogPrimaryBtnClickEditMode();
    }
    handleScheduleAssessmentDialogPrimaryBtnClickAddMode();
    // if (tab === 'assessments'){
    //   return window.open(
    //     `customers.html`,
    //     '_self'
    //   );
    // }
  }, [
    scheduleAssessmentDialogOpener,
    handleScheduleAssessmentDialogPrimaryBtnClickEditMode,
    handleScheduleAssessmentDialogPrimaryBtnClickAddMode,
    userId,
    scheduleAssessmentDialogRenderedStep,
    assessmentScheduleState,
    minsUsage,
  ]);

  const isScheduleAssessmentDialogPrimaryBtnDisabled = (() => {
    if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
    ) {
      if (
        scheduleAssessmentDialogOpener ===
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      ) {
        return (
          (assessmentScheduleState.recurring &&
            (!assessmentScheduleState.frequency ||
              assessmentScheduleState.frequency < 1 ||
              !assessmentScheduleState.intervalType)) ||
          (assessmentScheduleState.selectedStages || []).length === 0
        );
      }

      return (
        !assessmentScheduleState.frequency ||
        assessmentScheduleState.frequency < 1 ||
        (assessmentScheduleState.selectedStages || []).length === 0
      );
    }

    return false;
  })();

  const questionnairesStageDisabledReason = (() => {
    if (!isCustomerAppCompatiableWithSymptomQuestionnaires) {
      return `In order to be able to select questionnaires the user needs to update to a version above ${androidVersionCompatiableWithSymptomQuestionnaires} in Android or above ${iOSVersionCompatiableWithSymptomQuestionnaires} in iOS.`;
    }

    if (deployedQuestionnaires.length === 0) {
      return 'You need to setup questionnaires to enable symptom tracking.';
    }

    return null;
  })();

  const onSelectedStagesChange = useCallback(
    newSelectedStages => {
      setSelectedStages(newSelectedStages);
    },
    [setSelectedStages]
  );

  const onAfterTrainingDurationChange = useCallback(
    event =>
      setAssessmentScheduleState({
        ...assessmentScheduleState,
        afterTrainingDuration: parseInt(event.target.value, 10),
      }),
    [assessmentScheduleState]
  );

  const onDisorderClick = useCallback(
    disorder => {
      let newSelectedBrainDisorders;
      if (selectedBrainDisordersInEdit.includes(disorder)) {
        newSelectedBrainDisorders = selectedBrainDisordersInEdit.filter(
          selectedDisorder => selectedDisorder !== disorder
        );
      } else {
        newSelectedBrainDisorders = [...selectedBrainDisordersInEdit, disorder];
      }

      setSelectedBrainDisordersInEdit(newSelectedBrainDisorders);
    },
    [selectedBrainDisordersInEdit]
  );

  const onSetupQuestionnaires = useCallback(() => {
    userPageLogger.log('on_setup_questionnaires', {
      userId,
    });
    setAddClientModalInitialStep(addClientSteps.selectQuestionnaires);
    setAddClientModalFinalStep(addClientSteps.selectQuestionnaires);
    setMightEnableQuestionnairesInScheduleAssessmentDialog(true);
    setIsAddClientModalOpen(true);
  }, [userId]);

  const onViewPricingPlansClicked = async () => {
    await new MyEvent('view_pricing_plans_clicked').log({
      from_where: 'side_menu',
    });
    window.open('new_account.html', '_blank');
  };

  const renderScheduleAssessmentDialog = () => {
    if (brainDisorders && assessmentStagesInfo) {
      const primaryBtnText =
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
          ? t('save')
          : t('next');

      const secondaryBtnText =
        scheduleAssessmentDialogRenderedStep ===
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm ||
        scheduleAssessmentDialogOpener ===
          SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit ||
        didClinicSetupAssessmentForThisCustomerBefore ||
        (customerInfo && customerInfo.shouldUseNewSymptomTracker)
          ? t('cancel')
          : t('back');

      return (
        <ScheduleAssessmentDialog
          assessmentStagesInfo={assessmentStagesInfo}
          isOpen={!!scheduleAssessmentDialogOpener}
          onSecondaryBtnClick={onScheduleAssessmentDialogSecondaryBtnClick}
          onPrimaryBtnClick={onScheduleAssessmentDialogPrimaryBtnClick}
          isPrimaryBtnDisabled={isScheduleAssessmentDialogPrimaryBtnDisabled}
          onSelectedStagesChange={onSelectedStagesChange}
          selectedStages={assessmentScheduleState.selectedStages || []}
          afterTrainingDuration={assessmentScheduleState.afterTrainingDuration}
          onAfterTrainingDurationChange={onAfterTrainingDurationChange}
          onRecurringAssessmentCheckboxChange={event => {
            if (!JSON.parse(event.target.value)) {
              setAssessmentScheduleState({
                ...assessmentScheduleState,
                recurring: JSON.parse(event.target.value),
                isAssessmentOpen: true,
                beforeNextSession: true,
              });
            } else {
              setAssessmentScheduleState({
                ...assessmentScheduleState,
                recurring: JSON.parse(event.target.value),
              });
            }
          }}
          onBeforeNextSessionCheckboxChange={(event, checked) => {
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              beforeNextSession: checked,
              isAssessmentOpen: checked,
            });
          }}
          isRecurringAssessmentChecked={assessmentScheduleState.recurring}
          isBeforeNextSessionAssessmentChecked={
            assessmentScheduleState.beforeNextSession
          }
          frequency={assessmentScheduleState.frequency}
          intervalType={assessmentScheduleState.intervalType || ''}
          onFrequencyChange={event =>
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              frequency:
                event.target.value === '' ? '' : JSON.parse(event.target.value),
            })
          }
          onIntervalTypeChange={event =>
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              intervalType: event.target.value,
            })
          }
          brainDisorders={brainDisorders}
          selectedBrainDisorders={selectedBrainDisordersInEdit}
          renderedStep={scheduleAssessmentDialogRenderedStep}
          onDisorderClick={onDisorderClick}
          customerDateOfBirth={customerDateOfBirth}
          customerGender={customerGender}
          onCustomerDateOfBirthChange={dateOfBirth =>
            setCustomerDateOfBirth(dateOfBirth)
          }
          onCustomerGenderChange={event =>
            setCustomerGender(event.target.value)
          }
          scheduleAssessmentDialogErrorMsg={scheduleAssessmentDialogErrorMsg}
          primaryBtnText={primaryBtnText}
          secondaryBtnText={secondaryBtnText}
          onClose={() => {
            setIsScheduleAssessmentDialogOpener(null);
            resetScheduleAssessmentDialogState();
          }}
          questionnairesStageDisabledReason={questionnairesStageDisabledReason}
          shouldShowSetupQuestionnairesButton={
            deployedQuestionnaires.length === 0 &&
            isCustomerAppCompatiableWithSymptomQuestionnaires
          }
          areEEGRegionsEnabled={areScheduleAssessmentDialogEEGRegionsEnabled}
          onEEGRegionsCheckboxChange={event => {
            if (!nfFlag) {
              if (canAccessNf) {
                new MyEvent('add_neuro_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              } else {
                new MyEvent('contact_sales_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              }
              setAddClientReachedLimitModal(true);
              return;
            }
            setAreScheduleAssessmentDialogEEGRegionsEnabled(
              event.target.checked
            );
            if (!event.target.checked) {
              setSelectedStages(
                (assessmentScheduleState.selectedStages || []).filter(
                  stage =>
                    !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds) &&
                    stage !== 'Headset'
                )
              );
            } else {
              setSelectedStages([
                ...new Set([
                  ...Object.values(assessmentStagesInfo.eegIds).filter(
                    id => id !== 'Back'
                  ),
                  ...(assessmentScheduleState.selectedStages || []),
                ]),
              ]);
            }
          }}
          shouldUseNewSymptomTracker={
            customerInfo && customerInfo.shouldUseNewSymptomTracker
          }
          onSetupQuestionnaires={onSetupQuestionnaires}
          deployedQuestionnaires={
            deployedQuestionnaires && allQuestionnaires
              ? deployedQuestionnaires.map(id => ({
                  ...allQuestionnaires[id],
                  id,
                }))
              : []
          }
          doneAssessments={!!filteredDoneAssessments.length}
          customerId={userId}
          nfFlag={nfFlag}
          isHeadsetOnly={assessmentScheduleState.stagesType === 'headsetStages'}
          setIsHeadsetOnly={isChecked => {
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              stagesType: isChecked ? 'headsetStages' : 'stages',
            });
            if (isChecked) {
              setSelectedStages(
                (assessmentScheduleState.selectedStages || [])
                  .filter(
                    stage =>
                      !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
                  )
                  .concat(['Headset'])
              );
            } else {
              setSelectedStages([
                ...new Set([
                  ...Object.values(assessmentStagesInfo.eegIds).filter(
                    id => id !== 'Back'
                  ),
                  ...(assessmentScheduleState.selectedStages || []).filter(
                    stage => stage !== 'Headset'
                  ),
                ]),
              ]);
            }
          }}
          usedAssessmentBeforeJune24={usedAssessmentBeforeJune24}
        />
      );
    }

    return null;
  };

  const renderAddClientModal = () => {
    const renderCustomizedAddClientModalSuccessMessage =
      // eslint-disable-next-line react/prop-types
      ({ assessmentOptions, selectedQuestionnaires }) => {
        const isAssessmentEnabled =
          // eslint-disable-next-line react/prop-types
          assessmentOptions.shouldBeTrackingBeforeFirstSession ||
          // eslint-disable-next-line react/prop-types
          assessmentOptions.shouldBeTrackingPerMinutes;

        if (
          addClientModalFinalStep === addClientSteps.selectQuestionnaires &&
          (isAssessmentEnabled ||
            mightEnableQuestionnairesInScheduleAssessmentDialog)
        ) {
          return (
            <CustomDialogSuccessMessage
              headerText="Questionnaires updated!"
              content={
                <p style={{ margin: 0 }}>
                  {// eslint-disable-next-line react/prop-types
                  selectedQuestionnaires.length === 0 ? (
                    <>{t('no-questionnaires-will-be-shown')}</>
                  ) : (
                    <>
                      {t(
                        'user-will-be-shown-updated-questionnaires-on-next-assessment-checkpoint'
                      )}
                    </>
                  )}
                  <ModalFooter
                    primaryBtnContent={t('done')}
                    onPrimaryBtnClick={() => {
                      closeAddClientModal();
                    }}
                  />
                </p>
              }
            />
          );
        }

        return (
          <CustomDialogSuccessMessage
            headerText={
              isAssessmentEnabled
                ? t('progress-tracking-is-enabled')
                : t('progress-tracking-is-disabled')
            }
            content={
              <p style={{ margin: 0 }}>
                {isAssessmentEnabled ? (
                  <>{t('user-will-be-asked-to-complete-assessment')}</>
                ) : (
                  <>{t('user-will-not-be-asked-to-complete-assessment')}</>
                )}
                <ModalFooter
                  primaryBtnContent={t('done')}
                  onPrimaryBtnClick={() => {
                    closeAddClientModal();
                  }}
                />
              </p>
            }
          />
        );
      };

    const updateQuestionnairesShouldBeEnabledIfNeeded = ({
      selectedQuestionnaires,
    }) => {
      if (
        mightEnableQuestionnairesInScheduleAssessmentDialog &&
        // eslint-disable-next-line react/prop-types
        selectedQuestionnaires.length > 0
      ) {
        setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog(true);
      }
    };

    const renderSuccessMessage =
      addClientModalInitialStep === addClientSteps.selectQuestionnaires
        ? props => {
            updateQuestionnairesShouldBeEnabledIfNeeded(props);
            return (
              <div style={{ padding: '20px' }}>
                {' '}
                {renderCustomizedAddClientModalSuccessMessage(props)}
              </div>
            );
          }
        : undefined;

    const assessmentSelectedStages =
      (deployedAssessment && deployedAssessment.selectedStages) ||
      Object.values(assessmentStagesInfo.ids);
    const initialAssessmentOptions = deployedAssessment
      ? {
          shouldBeTrackingPerMinutes: deployedAssessment.recurring,
          shouldBeTrackingBeforeFirstSession:
            deployedAssessment.beforeNextSession,
          perMinutesTracking: deployedAssessment.afterTrainingDuration,
          isSymptomQuestionnaireEnabled: assessmentSelectedStages.includes(
            assessmentStagesInfo.ids.QUESTIONNAIRES
          ),
          isCPTEnabled: assessmentSelectedStages.includes(
            assessmentStagesInfo.ids.CPT
          ),
          isEEGEnabled: Object.values(
            assessmentStagesInfo.eegIds
          ).some(sensingRegionId =>
            assessmentSelectedStages.includes(sensingRegionId)
          ),
        }
      : undefined;

    return (
      <AddClientModal
        isEditingMode
        isModalOpen
        userId={userId}
        userInfo={customerInfo}
        brainDisorders={brainDisorders}
        closeModal={closeAddClientModal}
        allQuestionnaires={allQuestionnairesOnlyClinic}
        allTags={allTags}
        initialSelectedTags={selectedTags}
        initialSelectedQuestionnaires={deployedQuestionnaires.map(id => ({
          ...allQuestionnaires[id],
          id,
        }))}
        shouldUseNewSymptomTracker={customerInfo.shouldUseNewSymptomTracker}
        initialAssessmentOptions={initialAssessmentOptions}
        initialSelectedAssessmentRegions={assessmentSelectedStages.filter(
          stage => Object.values(assessmentStagesInfo.eegIds).includes(stage)
        )}
        initialStep={addClientModalInitialStep}
        finalStep={addClientModalFinalStep}
        isCustomerAppCompatiableWithSymptomQuestionnaires={
          isCustomerAppCompatiableWithSymptomQuestionnaires
        }
        renderSuccessMessage={renderSuccessMessage}
        assessmentStagesInfo={assessmentStagesInfo}
        nfFlag={nfFlag}
      />
    );
  };

  // ************************************************************************************************
  // *************************************ASSESSMEMT-END*********************************************
  // ************************************************************************************************

  // eslint-disable-next-line no-extra-boolean-cast
  // if (!!scheduleAssessmentDialogOpener) {
  return (
    <>
      {!!scheduleAssessmentDialogOpener && renderScheduleAssessmentDialog()}
      {isAddClientModalOpen && renderAddClientModal()}
      <NeuroPromoDialog
        addClientReachedLimitModal={addClientReachedLimitModal}
        setAddClientReachedLimitModal={setAddClientReachedLimitModal}
        canAccessNf={canAccessNf}
        onViewPricingPlansClicked={onViewPricingPlansClicked}
        fromWhere="side_menu"
      />
    </>
  );
  // }
};

AssessmentPopupBase.propTypes = exact({
  userId: PropTypes.string,
  isOpen: PropTypes.func,
  onClose: PropTypes.onClose,
  t: PropTypes.func,
});

export const AssessmentPopup = React.memo(AssessmentPopupBase);
AssessmentPopup.displayName = 'AssessmentPopup';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import styles from './ActivateAccountDialog.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';

export const ACTIVATE_ACCOUNT_DIALOG_STEPS = {
  LOADING: 'loading',
  ACCOUNT_ACTIVATED: 'accountActivated',
};

const ActivateAccountDialogBase = ({
  isOpen,
  renderedStep,
  onConfirm,
  onClose,
}) => {
  const shouldRenderDialogActions =
    renderedStep !== ACTIVATE_ACCOUNT_DIALOG_STEPS.LOADING;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        {renderedStep === ACTIVATE_ACCOUNT_DIALOG_STEPS.LOADING && <Loader />}
        {renderedStep === ACTIVATE_ACCOUNT_DIALOG_STEPS.ACCOUNT_ACTIVATED && (
          <div>
            <h3 className={styles.dialog_header}>
              Congratulations! Your Myndlift clinical account has been
              activated.
            </h3>
          </div>
        )}
      </DialogContent>
      {shouldRenderDialogActions && (
        <DialogActions>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            <span className={styles.button}>Done</span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ActivateAccountDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  renderedStep: PropTypes.oneOf([
    ACTIVATE_ACCOUNT_DIALOG_STEPS.LOADING,
    ACTIVATE_ACCOUNT_DIALOG_STEPS.ACCOUNT_ACTIVATED,
  ]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
});

export const ActivateAccountDialog = React.memo(ActivateAccountDialogBase);
ActivateAccountDialog.displayName = 'ActivateAccountDialog';

import { useState, useEffect } from 'react';

export const useMediaMatch = ({ mediaMatchString }) => {
  const mediaMatchList = window.matchMedia(mediaMatchString);
  const [isMatching, setIsMatching] = useState(mediaMatchList.matches);

  useEffect(() => {
    const onResize = () => {
      if (isMatching !== mediaMatchList.matches) {
        setIsMatching(mediaMatchList.matches);
      }
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [isMatching, mediaMatchList]);

  return [isMatching, mediaMatchList];
};

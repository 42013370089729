import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import { Button, CircularProgress } from '@material-ui/core';
import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import { PreviewReportModalContent } from './PreviewReportModalContent/PreviewReportModalContent';
import { mapReportCardsDataToReportCards } from './mapReportCardsDataToReportCards';
import styles from './SessionToSessionReportViewer.scss';
import { ShareReportDialog } from './ShareReportDialog/ShareReportDialog';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import {
  amplitudeAndNoiseGraphType,
  frequenciesSessionComparisonGraphType,
} from './constants';

import myndliftLogoImage from '../../../../img/modern_logo_black.png';
import { getLogoImage } from '../../models/logo/logo';
import { defensiveThrow } from '../../utils/utils';
import { FNS_DATE_FORMATS } from '../../utils/constants';

const centeredTextStyle = { textAlign: 'center' };

const reportViewerEventLogger = new MyEventWrapper(
  'session_to_session_report_viewer'
);

export const SessionToSessionReportViewer = withEntryComponent(
  ({ customerId, reportId, isPatientView, onComponentReady }) => {
    const t = useTranslation();
    const printEl = useRef(null);

    const [report, setReport] = useState(null);
    const [clinicId, setClinicId] = useState(null);
    const [newFrequenciesConfig, setNewFrequenciesConfig] = useState(null);
    const [isShareReportDialogOpen, setIsShareReportDialogOpen] = useState(
      false
    );
    const [patientEmail, setPatientEmail] = useState(null);
    const [reportLogo, setReportLogo] = useState(null);

    const [isLoadingPDF, setIsLoadingPDF] = useState(false);

    const onShareReport = useCallback(() => {
      setIsShareReportDialogOpen(true);
      reportViewerEventLogger.log('on_share_report_click');
    }, []);

    const A4_HIEGHT = 295;
    const A4_WIDTH = 210;

    const hideShowAll = (element, shouldHide = true) => {
      const elementsToHide = element.getElementsByClassName('hide-on-print');
      for (let i = 0; i < elementsToHide.length; i += 1) {
        elementsToHide[i].style.display = shouldHide ? 'none' : 'block';
      }
    };

    const expandAll = async element => {
      const btns = element.getElementsByClassName('expandable-in-report');
      for (let i = 0; i < btns.length; i += 1) {
        if (btns[i].getAttribute('aria-expanded') === 'false') {
          btns[i].click();
        }
      }
      await new Promise(resolve => setTimeout(resolve, 100 * btns.length));
    };

    const onExportPdf = async () => {
      new MyEvent('assessment_download_pdf_clicked').log({
        patientId: customerId,
        reportId,
        from: 'session_to_session_report_viewer',
      });
      const timeStartedLoading = Date.now();
      setIsLoadingPDF(true);
      const { reportCardsData } = report;
      const reportCardsDataWithLogo = [...reportCardsData];
      reportCardsDataWithLogo.unshift({ id: 'logo-image' });
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('p', 'mm', 'a4', true);
      let position = 10;
      hideShowAll(document.getElementById('to-print'), true);
      await expandAll(document.getElementById('to-print'));
      for (let i = 0; i < reportCardsDataWithLogo.length; i += 1) {
        if (
          reportCardsDataWithLogo[i].type === 'AssessmentSwingleCheckGraph' ||
          reportCardsDataWithLogo[i].type === 'AssessmentQuestionnairesResult'
        ) {
          const largeEl = document.getElementById(
            reportCardsDataWithLogo[i].id
          );
          const smallElements = largeEl.getElementsByClassName('print-item');
          for (let j = 0; j < smallElements.length; j += 1) {
            // eslint-disable-next-line no-await-in-loop
            const blob = await domtoimage.toBlob(smallElements[j]);
            // eslint-disable-next-line no-undef
            const img = new Image();
            const imgData = URL.createObjectURL(blob);
            img.src = imgData;
            // eslint-disable-next-line space-before-function-paren, no-loop-func
            img.onload = function() {
              const imgHeight = (this.height * A4_WIDTH) / this.width;
              if (imgHeight > A4_HIEGHT - position) {
                doc.addPage();
                position = 10;
              }

              doc.addImage(
                img,
                'PNG',
                15,
                position,
                A4_WIDTH - 15,
                imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight,
                undefined,
                'FAST'
              );
              position += imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight;
            };
          }
          // eslint-disable-next-line no-continue
          continue;
        }
        // eslint-disable-next-line no-await-in-loop
        const blob = await domtoimage.toBlob(
          document.getElementById(reportCardsDataWithLogo[i].id)
        );
        if (!blob) {
          // eslint-disable-next-line no-continue
          continue;
        }
        // eslint-disable-next-line no-undef
        const img = new Image();
        const imgData = URL.createObjectURL(blob);
        img.src = imgData;
        // eslint-disable-next-line space-before-function-paren, no-loop-func
        img.onload = function() {
          const imgHeight = (this.height * A4_WIDTH) / this.width;
          if (imgHeight > A4_HIEGHT - position) {
            doc.addPage();
            position = 10;
          }

          doc.addImage(
            img,
            'PNG',
            10,
            position,
            A4_WIDTH - 20,
            imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight,
            undefined,
            'FAST'
          );
          position += imgHeight > A4_HIEGHT ? A4_HIEGHT - 10 : imgHeight;
        };
      }
      setTimeout(() => {
        hideShowAll(document.getElementById('to-print'), false);
        doc.save(
          `Progress Report- ${report.patientName}, ${format(
            report.createdAt,
            FNS_DATE_FORMATS.yearMonthDay
          )}`
        );
        new MyEvent('assessment_download_pdf_done').log({
          patientId: customerId,
          reportId,
          from: 'session_to_session_report_viewer',
          delay: Date.now() - timeStartedLoading,
        });
        setIsLoadingPDF(false);
      }, 1000);
    };

    const onToggleShareInAppReport = async e => {
      database
        .ref(`reports/${customerId}/${reportId}/shareInAppWithClient`)
        .set(e.target.checked);

      const reportsDatabaseRef = database.ref(
        `reports/${customerId}/${reportId}`
      );
      // eslint-disable-next-line no-shadow
      const report = await reportsDatabaseRef
        .once('value')
        .then(snapshot => snapshot.val());

      setPatientEmail(report && report.patientEmail);
      setReport(report || {});
    };

    const documentReferrerURL =
      document.referrer !== '' && new URL(document.referrer);
    const shouldShowDoneButton =
      !isPatientView &&
      documentReferrerURL &&
      documentReferrerURL.pathname ===
        '/session-to-session-report-generator.html';
    const onDone = useCallback(
      () =>
        window.open(
          `userPage.html?customerId=${sessionStorage.customerId}`,
          '_self'
        ),
      []
    );

    useEffect(() => {
      const userInfoDatabaseRef = database.ref(`userInfo/${customerId}`);
      const onUserInfoValue = snapshot => {
        const userInfo = snapshot.val();
        setClinicId(userInfo.clinicId);
      };
      userInfoDatabaseRef.once('value', onUserInfoValue);

      return () => userInfoDatabaseRef.off('value', onUserInfoValue);
    }, [customerId]);

    useEffect(() => {
      if (clinicId) {
        const newFrequenciesConfigDatabaseRef = database.ref(
          `clinics/${clinicId}/configurations/frequencies`
        );

        const onFrequenciesConfigValue = snapshot => {
          setNewFrequenciesConfig(snapshot.val() || {});
        };
        newFrequenciesConfigDatabaseRef.once('value', onFrequenciesConfigValue);

        return () =>
          newFrequenciesConfigDatabaseRef.off(
            'value',
            onFrequenciesConfigValue
          );
      }

      return () => {};
    }, [clinicId]);

    useEffect(() => {
      if (clinicId) {
        (async () => {
          const { logoImage, customLogo } = await getLogoImage({ clinicId });
          if (logoImage && customLogo) {
            setReportLogo(logoImage);
          } else {
            setReportLogo(myndliftLogoImage);
          }
        })();
      }
      return () => {};
    }, [clinicId]);

    useEffect(() => {
      const reportsDatabaseRef = database.ref(
        `reports/${customerId}/${reportId}`
      );
      const onReportsValue = snapshot => {
        // eslint-disable-next-line no-shadow
        const report = snapshot.val();
        setPatientEmail(report && report.patientEmail);
        setReport(report || {});
      };
      reportsDatabaseRef.once('value', onReportsValue);

      return () => reportsDatabaseRef.off('value', onReportsValue);
    }, [customerId, reportId]);

    const [
      wasRemoteConfigValuesFetched,
      setWasRemoteConfigValuesFetched,
    ] = useState(false);

    // eslint-disable-next-line no-undef
    const remoteConfig = firebase.remoteConfig();
    useEffect(() => {
      (async () => {
        try {
          await remoteConfig.fetchAndActivate();
          setWasRemoteConfigValuesFetched(true);
        } catch (error) {
          defensiveThrow({ error });
          setWasRemoteConfigValuesFetched(true);
        }
      })();
    }, [remoteConfig]);

    // const shouldShowBrainMaps = remoteConfig
    //   .getValue('showBrainMaps')
    //   .asBoolean();
    const shouldShowBrainMaps = true;

    useEffect(() => {
      if (
        report !== null &&
        clinicId !== null &&
        newFrequenciesConfig !== null &&
        reportLogo !== null &&
        wasRemoteConfigValuesFetched !== false
      ) {
        onComponentReady();
      }
    }, [
      wasRemoteConfigValuesFetched,
      report,
      clinicId,
      newFrequenciesConfig,
      reportLogo,
      onComponentReady,
    ]);

    const renderDeletedReportContent = () => (
      <h3 style={centeredTextStyle}>
        {isPatientView
          ? 'This report was deleted, contact your clinic for more info'
          : 'This report was deleted'}
      </h3>
    );

    const renderIncorrectQueryParamsContent = () => (
      <h3 style={centeredTextStyle}>
        There is no report at this location, please make sure that your url is
        correct
      </h3>
    );

    const renderReportContent = () => {
      const { reportCardsData } = report;
      const oldFrequenciesConfig = report.frequenciesConfig;
      const oldFrequencyNamesToNewFrequencyNames = mapValues(
        oldFrequenciesConfig,
        frequency =>
          extractFrequency(
            frequency.minFrequency,
            frequency.maxFrequency,
            newFrequenciesConfig
          )
      );

      reportCardsData.forEach(reportCardData => {
        if (reportCardData.type === amplitudeAndNoiseGraphType) {
          const { graphPayload } = reportCardData.data;

          // call it oldDataKey here because it can be some frequency or "averageNoise"
          const mapOldDataKeysToNewDataKeys = (value, oldDataKey) => {
            if (oldDataKey.startsWith('trend-')) {
              const oldDataKeyWithoutTrend = oldDataKey.replace('trend-', '');
              const newDateKeyWithoutTrend =
                oldFrequencyNamesToNewFrequencyNames[oldDataKeyWithoutTrend] ||
                oldDataKeyWithoutTrend;
              return `trend-${newDateKeyWithoutTrend}`;
            }
            return (
              oldFrequencyNamesToNewFrequencyNames[oldDataKey] || oldDataKey
            );
          };

          graphPayload.legendItems = mapKeys(
            graphPayload.legendItems,
            mapOldDataKeysToNewDataKeys
          );

          graphPayload.data = graphPayload.data.map(dataObject =>
            mapKeys(dataObject, mapOldDataKeysToNewDataKeys)
          );
        }

        if (reportCardData.type === frequenciesSessionComparisonGraphType) {
          const { graphPayload } = reportCardData.data;
          const mapOldFrequencyNamesToNewFrequencyNames = (
            value,
            oldFrequencyName
          ) => oldFrequencyNamesToNewFrequencyNames[oldFrequencyName];

          graphPayload.comparisonParameters = mapKeys(
            graphPayload.comparisonParameters,
            mapOldFrequencyNamesToNewFrequencyNames
          );

          graphPayload.data = graphPayload.data.map(dataObject =>
            mapKeys(dataObject, mapOldFrequencyNamesToNewFrequencyNames)
          );
        }
      });

      return (
        <React.Fragment>
          <div id="logo-image">
            <div className={styles.logo_container}>
              <div>
                <img src={reportLogo} />
              </div>
            </div>
          </div>
          <PreviewReportModalContent
            reportCards={mapReportCardsDataToReportCards({
              reportCardsData,
              frequenciesConfig: newFrequenciesConfig,
              shouldShowBrainMaps,
            })}
            printMode
          />
        </React.Fragment>
      );
    };

    const renderPDFLoader = () => {
      return (
        <div className={styles.pdf_loading}>
          <div className={styles.pdf_loading_content}>
            <CircularProgress />
            <div style={{ fontSize: 16, fontWeight: 900, marginTop: 10 }}>
              Generating PDF
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              This will take a few seconds. Please don’t close this tab.
            </div>
          </div>
        </div>
      );
    };

    const renderClinicView = () => {
      const reportLink = `${window.location.origin}/session-to-session-report-viewer.html?reportId=${reportId}&customerId=${customerId}&clinicId=${clinicId}&viewer=customer `;

      return (
        <React.Fragment>
          {isLoadingPDF && renderPDFLoader()}
          <div className={styles.button_container}>
            {shouldShowDoneButton && (
              <Button onClick={onDone} variant="text" color="default">
                <span className={styles.button}>{t('done')}</span>
              </Button>
            )}

            <Button onClick={onShareReport} variant="contained" color="primary">
              <span className={styles.button}>Share Report</span>
            </Button>

            {window.innerWidth > 960 ? (
              <Button
                onClick={onExportPdf}
                variant="contained"
                color="primary"
                style={{ marginLeft: 10, width: 170 }}
                disabled={isLoadingPDF}
              >
                <span className={styles.button}>
                  {isLoadingPDF ? (
                    <CircularProgress size={15} color="white" />
                  ) : (
                    'download as PDF'
                  )}
                </span>
              </Button>
            ) : null}
          </div>

          {
            <div id="to-print" ref={printEl}>
              {renderReportContent()}
            </div>
          }

          <ShareReportDialog
            isOpen={isShareReportDialogOpen}
            onClose={() => setIsShareReportDialogOpen(false)}
            patientName={report.patientName}
            reportLink={reportLink}
            onCopyLinkToClipboard={inputElm => {
              inputElm.select();
              document.execCommand('copy');
              showNotification('success', 'Copied to clipboard');
              reportViewerEventLogger.log('on_copy_to_clipboard', {
                reportLink,
              });
            }}
            onNotifyByEmail={async () => {
              await fireFunctionPost('clinic_apis-sessionsReportEmail', {
                link: reportLink,
                email: patientEmail,
              });
              showNotification('success', 'Email was just sent');
              reportViewerEventLogger.log('on_notify_by_email', {
                link: reportLink,
                email: patientEmail,
              });
            }}
            patientEmail={patientEmail}
            shouldShowViewReportBtn={false}
            onPatientEmailChange={event => setPatientEmail(event.target.value)}
            shouldShowNameOnTitle
            onShareInAppWithClientChange={onToggleShareInAppReport}
            shareInAppWithClient={report.shareInAppWithClient}
          />
        </React.Fragment>
      );
    };

    const renderView = () => {
      if (
        report === null ||
        clinicId === null ||
        newFrequenciesConfig === null
      ) {
        return null;
      }

      if (isEmpty(report)) {
        return renderIncorrectQueryParamsContent();
      }

      if (report.isDeleted) {
        return renderDeletedReportContent();
      }

      return (
        <div className={styles.root}>
          {isPatientView ? renderReportContent() : renderClinicView()}
        </div>
      );
    };

    return renderView();
  }
);

SessionToSessionReportViewer.propTypes = {
  customerId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  onComponentReady: PropTypes.func.isRequired,
  isPatientView: PropTypes.bool.isRequired,
};

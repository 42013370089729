import React, { useEffect, useState } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Input } from '@material-ui/core';
import styles from './LagoTags.scss';

const LagoTagsBase = ({
  open,
  onClose,
  tags,
  onSelectTag,
  onAddTag,
  journeyId,
  currentStage,
}) => {
  const [newTagPlaceholder, setNewTagPlaceholder] = useState('');
  const [newTagDescription, setNewTagDescription] = useState('');
  const [newTagName, setNewTagName] = useState('');

  const [tagsToShow, setTagsToShow] = useState([]);
  const [tagsToFilterStr, setTagsToFilterStr] = useState('');

  const [tagNameExistMessage, setTagNameExistMessage] = useState('');
  const [journeyTags, setJourneyTags] = useState([]);

  useEffect(() => {
    const refactoredTags = [];
    // eslint-disable-next-line no-unused-expressions
    tags &&
      Object.keys(tags).forEach(tagKey => {
        refactoredTags.push({
          placeholder: tags[tagKey].placeholder.en,
          internalDescription: tags[tagKey].internalDescription,
          tagName: tags[tagKey].tagName ? tags[tagKey].tagName.en : null,
          key: tagKey,
          journeyId: tags[tagKey].journeyId,
        });
        const filteredByJourney = refactoredTags.filter(
          tag =>
            tag.journeyId === journeyId ||
            (currentStage === 0 && !tag.journeyId)
        );
        setJourneyTags(filteredByJourney);

        if (!tagsToFilterStr) {
          setTagsToShow(filteredByJourney);
        } else {
          const filteredTags = filteredByJourney.filter(
            tag =>
              tag.placeholder.includes(tagsToFilterStr) ||
              tag.internalDescription.includes(tagsToFilterStr) ||
              (tag.tagName && tag.tagName.includes(tagsToFilterStr))
          );
          setTagsToShow(filteredTags);
        }
      });
  }, [tags, tagsToFilterStr]);

  const resetState = () => {
    setNewTagPlaceholder('');
    setNewTagDescription('');
    setNewTagName('');
    setTagsToFilterStr('');
  };

  const isNewTagValid = () => {
    if (!newTagPlaceholder || !newTagDescription || !newTagName) {
      return false;
    }
    // eslint-disable-next-line no-useless-escape
    const format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(newTagName)) {
      return false;
    }
    if (/\s/.test(newTagName)) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '20px' }}>Select tag</span>
      </DialogTitle>
      <DialogContent>
        <div className={styles.dialog_tag_container}>
          <div className={styles.select_tag_container}>
            <Input
              value={tagsToFilterStr}
              onChange={e => setTagsToFilterStr(e.target.value)}
              placeholder="🔍 Find Tag"
              style={{ width: '200px', fontSize: '18px' }}
            />
            <div className={styles.tags_list_container}>
              {tagsToShow.map(tag => (
                <div
                  onClick={() => {
                    onSelectTag(tag.key);
                    onClose();
                    resetState();
                  }}
                  key={tag.key}
                  className={styles.tag_item}
                >
                  <div className={styles.tag_item_name}>{tag.tagName}</div>
                  <div className={styles.tag_item_placeholder}>
                    {tag.placeholder}
                  </div>
                  <div>{tag.internalDescription}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.add_tag_container}>
            <div className={styles.new_tag_title}>new tag</div>
            <Input
              value={newTagName}
              onChange={e => setNewTagName(e.target.value)}
              placeholder="Tag name"
              style={{ width: '200px', fontSize: '18px', marginTop: '10px' }}
            />
            <Input
              value={newTagPlaceholder}
              onChange={e => setNewTagPlaceholder(e.target.value)}
              placeholder="Tag placeholder"
              style={{ width: '200px', fontSize: '18px', marginTop: '10px' }}
            />
            <Input
              value={newTagDescription}
              onChange={e => setNewTagDescription(e.target.value)}
              placeholder="Tag description"
              style={{ width: '200px', fontSize: '18px', marginTop: '10px' }}
            />
            {tagNameExistMessage ? (
              <div style={{ color: 'red', maxWidth: 200 }}>
                {tagNameExistMessage}
              </div>
            ) : null}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            resetState();
          }}
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            let isExist = false;
            Object.keys(journeyTags).forEach(tagId => {
              if (journeyTags[tagId].tagName === newTagName) {
                setTagNameExistMessage('Tag name exist');
                setTimeout(() => {
                  setTagNameExistMessage('');
                }, 1500);
                isExist = true;
              }
            });
            if (!isExist) {
              // eslint-disable-next-line no-unused-expressions
              tags &&
                Object.keys(tags).forEach(tagId => {
                  if (tags[tagId].tagName.en === newTagName) {
                    setTagNameExistMessage(
                      'Tag name is already used in a different journey, please choose a different name'
                    );
                    setTimeout(() => {
                      setTagNameExistMessage('');
                    }, 1500);
                    isExist = true;
                  }
                });
            }
            if (!isExist) {
              onAddTag(newTagPlaceholder, newTagDescription, newTagName);
              onClose();
              resetState();
            }
          }}
          disabled={!isNewTagValid()}
        >
          add tag
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoTagsBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  onSelectTag: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  journeyId: PropTypes.string,
  currentStage: PropTypes.number,
});

export const LagoTags = React.memo(LagoTagsBase);
LagoTags.displayName = 'LagoTags';

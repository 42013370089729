import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import { HeadTopView } from '../../Core/images/HeadTopView';
import styles from './SelectChannelsStep.scss';
import { withTranslation } from '../../Core/hocs/withTranslation/withTranslation';
import { toLowerCase } from '../../utils/utils';

export class SelectChannelsStepBase extends Component {
  render() {
    const {
      onChannelClick,
      currentChannelSelection,
      enabledChannelsToBeSelected,
      disabledChannelsToBeSelected,
      t,
    } = this.props;

    let { activeChannel, referenceChannel } = this.props;

    const channelPrefix = 'channel_';

    const channelStyles = {
      [channelPrefix + activeChannel]: {
        fill: '#279bfd',
        stroke: '#279bfd',
        fillOpacity: 0.4,
      },
      [channelPrefix + referenceChannel]: {
        fill: '#3cd6be',
        stroke: '#3cd6be',
        fillOpacity: 0.4,
      },
    };

    enabledChannelsToBeSelected.forEach(enabledChannel => {
      const enabledChannelStyle = {
        cursor: 'pointer',
      };
      if (channelStyles[channelPrefix + enabledChannel]) {
        channelStyles[channelPrefix + enabledChannel] = {
          ...channelStyles[channelPrefix + enabledChannel],
          ...enabledChannelStyle,
        };
      } else {
        channelStyles[channelPrefix + enabledChannel] = enabledChannelStyle;
      }
    });

    disabledChannelsToBeSelected.forEach(disabledChannel => {
      if (
        disabledChannel !== activeChannel &&
        disabledChannel !== referenceChannel
      ) {
        channelStyles[channelPrefix + disabledChannel] = {
          fill: '#F4CBA6',
          fillOpacity: 0.8,
          strokeOpacity: 0.2,
          cursor: 'default',
        };
      } else {
        channelStyles[channelPrefix + disabledChannel] = {
          fillOpacity: 0.8,
          strokeOpacity: 0.2,
          cursor: 'default',
        };
        if (disabledChannel === activeChannel) {
          channelStyles[channelPrefix + activeChannel] = {
            fill: '#279bfd',
            stroke: '#279bfd',
            fillOpacity: 0.4,
          };
        } else {
          channelStyles[channelPrefix + referenceChannel] = {
            fill: '#3cd6be',
            stroke: '#3cd6be',
            fillOpacity: 0.4,
          };
        }
      }
    });

    const channelNamesForView = transformChannelNamesForView({
      trainingChannel: activeChannel,
      referenceChannel,
    });

    activeChannel = channelNamesForView.trainingChannel;
    ({ referenceChannel } = channelNamesForView);

    return (
      <React.Fragment>
        <div className={styles.root}>
          <div className={styles.channels_buttons_container}>
            <div
              className={classNames(styles.channel_button, {
                [styles.active_channel_not_selected]:
                  currentChannelSelection === 'activeChannel',
              })}
              id="activeChannel"
              onClick={this.props.onChannelTypeTabClick}
            >
              {activeChannel
                ? `${activeChannel} - ${toLowerCase(t('active-channel'))}`
                : t('active-channel')}
            </div>
            <div
              className={classNames(styles.channel_button, {
                [styles.reference_channel_not_selected]:
                  currentChannelSelection === 'referenceChannel',
              })}
              id="referenceChannel"
              onClick={this.props.onChannelTypeTabClick}
            >
              {referenceChannel
                ? `${referenceChannel} - ${toLowerCase(t('reference-channel'))}`
                : t('reference-channel')}
            </div>
          </div>
          <div className={styles.head_top_view_container}>
            <HeadTopView
              channelStyles={channelStyles}
              rootStyle={styles.rootstyle_head_top_down_view}
              onChannelClick={onChannelClick}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SelectChannelsStepBase.propTypes = exact({
  onChannelClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onChannelTypeTabClick: PropTypes.func.isRequired,
  currentChannelSelection: PropTypes.string,
  enabledChannelsToBeSelected: PropTypes.array.isRequired,
  disabledChannelsToBeSelected: PropTypes.array.isRequired,
  activeChannel: PropTypes.string,
  referenceChannel: PropTypes.string,
});

export const SelectChannelsStep = withTranslation(SelectChannelsStepBase);

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import { CustomDialog } from '../../Core/Components/CustomDialog/CustomDialog';
import styles from './SwitchToNormalHeadsetDialogHooker.scss';
import {
  defensiveThrow,
  postToFunctionsWithDefaultData,
} from '../../utils/utils';
import checkedImage from '../../../assets/check-icon.png';
import failedImage from '../../../assets/failed-circle.png';
import { useCustomerInfo } from '../../Core/hooks/useCustomerInfo';
import { useEventLoggerWithDefaultData } from '../../Core/hooks/useEventLoggerWithDefaultData';

const SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS = {
  INTRO: 'intro',
  BILLING: 'billing',
  SWITCH_COMPLETED: 'switchCompleted',
  SWITCH_FAILED: 'switchFailed',
  LOADER: 'loader',
};

const SwitchToNormalHeadsetDialogHookerBase = ({
  headsetDetails,
  buttonRef,
  onSwitchCompleted,
}) => {
  const logDialogEvents = useEventLoggerWithDefaultData({
    prefix: 'switch_to_normal_headset_dialog',
    commonData: {
      page: window.location.pathname,
    },
  });

  const [renderedStep, setRenderedStep] = useState(
    SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.INTRO
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const customerInfo = useCustomerInfo();
  const dispatchedHeadsetId =
    headsetDetails.dispatchedHeadsetId || 'unknown-headset';

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) {
      return defensiveThrow({
        error: new Error('buttonRef does not have a reference to a button'),
      });
    }
    if (button.hasAttribute('data-listener-id')) {
      return defensiveThrow({
        error: new Error('referenced button has a listener already tied to it'),
      });
    }

    button.setAttribute(
      'data-listener-id',
      SwitchToNormalHeadsetDialogHookerBase.name
    );

    const clickListener = () => {
      logDialogEvents({ name: 'on_click' });
      setIsDialogOpen(true);
    };
    button.addEventListener('click', clickListener);

    return () => {
      button.removeEventListener('click', clickListener);
      button.removeAttribute('data-listener-id');
    };
  }, [buttonRef, logDialogEvents]);

  const shouldSwitchWithoutBillingReview = !headsetDetails.isHeadsetActive;

  const switchHeadsetToNormalHeadset = useCallback(async () => {
    try {
      const switchResult = await postToFunctionsWithDefaultData({
        path: 'clinicDispatchUser',
        data: {
          headsetId: dispatchedHeadsetId,
          shouldDispatch: false,
          patientId: customerInfo.id,
        },
      });

      if (switchResult.result) {
        setRenderedStep(
          SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_COMPLETED
        );
      } else {
        throw new Error('Switch failed');
      }
    } catch (err) {
      defensiveThrow({ error: err });
      setRenderedStep(
        SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_FAILED
      );
    }
  }, [customerInfo, dispatchedHeadsetId]);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
    setTimeout(() => {
      setRenderedStep(SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.INTRO);
    }, 500);
  }, []);

  const onDialogPrimaryBtnClick = useCallback(async () => {
    logDialogEvents({
      name: 'on_primary_btn_click',
      data: {
        renderedStep,
      },
    });
    switch (renderedStep) {
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.INTRO:
        if (shouldSwitchWithoutBillingReview) {
          setRenderedStep(SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.LOADER);
          return switchHeadsetToNormalHeadset();
        }
        return setRenderedStep(
          SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.BILLING
        );
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.BILLING:
        setRenderedStep(SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.LOADER);
        return switchHeadsetToNormalHeadset();
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_COMPLETED:
        closeDialog();
        logDialogEvents({
          name: 'on_switch_completed',
        });
        return onSwitchCompleted();
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_FAILED:
        return closeDialog();

      default:
        return defensiveThrow({
          error: new Error(
            `Unknown step for ${SwitchToNormalHeadsetDialogHookerBase.name}`
          ),
        });
    }
  }, [
    logDialogEvents,
    closeDialog,
    onSwitchCompleted,
    renderedStep,
    shouldSwitchWithoutBillingReview,
    switchHeadsetToNormalHeadset,
  ]);

  const onDialogSecondaryBtnClick = useCallback(() => {
    logDialogEvents({
      name: 'on_secondary_btn_click',
      data: {
        renderedStep,
      },
    });
    closeDialog();
  }, [closeDialog, renderedStep, logDialogEvents]);

  const renderIntroStep = () => {
    return (
      <>
        <p className={styles.dialog_header}>
          Switch to view data and make changes
        </p>
        <p>
          {' '}
          Switching to Normal Mode will allow you to view the session data and
          control the training for this user and all other users that are
          currently using headset {dispatchedHeadsetId}.
        </p>
        {shouldSwitchWithoutBillingReview && (
          <p>
            Since the headset hasn’t been active in this billing cycle yet, this
            operation is <span className={styles.bold}>free of charge</span>.
          </p>
        )}
      </>
    );
  };

  const renderSwitchCompletedStep = () => {
    return (
      <>
        <div className={styles.done_image_container}>
          <img src={checkedImage} />
          <p className={classNames(styles.dialog_header, styles.no_margin)}>
            Switch Completed!
          </p>
        </div>

        <p>
          Headset {dispatchedHeadsetId} is now back in Normal Mode.
          <br />
          You can view the training data and change the parameters for all users
          that are using this headset.
        </p>
      </>
    );
  };

  const renderBillingStep = () => {
    return (
      <>
        <p className={styles.dialog_header}>Billing</p>
        <p>
          Since headset {dispatchedHeadsetId} is already active in this billing
          cycle and was previously credited $
          {headsetDetails.dispatchedHeadsetCredit}, switching it to Normal Mode
          would cause this credit to be withdrawn from your balance.
        </p>
      </>
    );
  };

  const renderSwitchFailedStep = () => {
    return (
      <>
        <div className={styles.failed_image_container}>
          <img src={failedImage} />
        </div>
        <p className={styles.dialog_header}>Switch Failed!</p>
        <p>Something went wrong!</p>
      </>
    );
  };

  const [primaryBtnContent, secondaryBtnContent] = (() => {
    switch (renderedStep) {
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.INTRO:
        return shouldSwitchWithoutBillingReview
          ? ['SWITCH TO NORMAL MODE']
          : ['REVIEW BILLING'];
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.BILLING:
        return ['SWITCH TO NORMAL MODE'];
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_COMPLETED:
      case SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_FAILED:
        return ['done', null];
      default:
        return [null, null];
    }
  })();

  return (
    <CustomDialog
      isOpen={isDialogOpen}
      primaryBtnContent={primaryBtnContent}
      secondaryBtnContent={secondaryBtnContent}
      onPrimaryBtnClick={onDialogPrimaryBtnClick}
      onSecondaryBtnClick={onDialogSecondaryBtnClick}
      isShowingLoader={
        renderedStep === SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.LOADER
      }
    >
      <div className={styles.content}>
        {renderedStep === SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.INTRO &&
          renderIntroStep()}
        {renderedStep ===
          SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.BILLING &&
          renderBillingStep()}
        {renderedStep ===
          SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_COMPLETED &&
          renderSwitchCompletedStep()}
        {renderedStep ===
          SWITCH_TO_NORMAL_HEADSET_DIALOG_HOOKER_STEPS.SWITCH_FAILED &&
          renderSwitchFailedStep()}
      </div>
    </CustomDialog>
  );
};

SwitchToNormalHeadsetDialogHookerBase.defaultProps = {
  onSwitchCompleted: () => {},
};

SwitchToNormalHeadsetDialogHookerBase.propTypes = exact({
  headsetDetails: PropTypes.object.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSwitchCompleted: PropTypes.func,
});

export const SwitchToNormalHeadsetDialogHooker = React.memo(
  SwitchToNormalHeadsetDialogHookerBase
);
SwitchToNormalHeadsetDialogHooker.displayName =
  'SwitchToNormalHeadsetDialogHooker';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import get from 'lodash/get';
import has from 'lodash/has';
import { Paper } from '@material-ui/core';
import { StackedHorizontalBarChart } from 'react-stacked-horizontal-bar-chart';
import styles from './QuestionnaireScore.scss';
import brainIndicatorImage from '../../../../assets/brain-indicator-with-arrow.png';
import { ASSESSMENT_COLORS } from '../../../models/assessments/assessments';
import {
  getQuestionnaireScoreColor,
  getIsQuestionnairePassing,
} from '../../../models/symptom-tracker/symptom-tracker';
import { setFixedDecimalsIfNeeded, isDefined } from '../../../utils/utils';
import increasingArrowPNG from '../../../../assets/increasing-arrow.png';
import decreasingArrowPNG from '../../../../assets/decreasing-arrow.png';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const Marker = ({ isFlipped, text }) => (
  <div className={styles.marker_container}>
    {text && !isFlipped && <span>{text}</span>}
    <img
      src={brainIndicatorImage}
      className={classNames(styles.marker, { [styles.flipped]: isFlipped })}
    />
    {text && isFlipped && <span>{text}</span>}
  </div>
);
Marker.defaultProps = {
  isFlipped: false,
};
Marker.propTypes = exact({
  isFlipped: PropTypes.bool,
  text: PropTypes.string,
});

const QuestionnaireScoreBase = ({
  score,
  postScore,
  questionnaire,
  questionnaireMaxScore,
  unitText,
  classes,
}) => {
  const t = useTranslation();

  const [questionnaireRangesPoints, questionnaireRangesColors] = (() => {
    const rangesPoints = [
      has(questionnaire, 'minScore') ? questionnaire.minScore : 0,
    ];
    const colors = [];

    if (questionnaire.lowerCutoff) {
      rangesPoints.push(questionnaire.lowerCutoff);
      colors.push(ASSESSMENT_COLORS.NEGATIVE);
    }

    if (questionnaire.middleLowerCutoff) {
      rangesPoints.push(questionnaire.middleLowerCutoff);
      colors.push(ASSESSMENT_COLORS.ALMOST_POSITIVE);
    }

    colors.push(ASSESSMENT_COLORS.POSITIVE);

    if (questionnaire.middleUpperEqualCutoff) {
      rangesPoints.push(questionnaire.middleUpperEqualCutoff);
      colors.push(ASSESSMENT_COLORS.ALMOST_POSITIVE);
    } else if (questionnaire.middleUpperCutoff) {
      rangesPoints.push(questionnaire.middleUpperCutoff);
      colors.push(ASSESSMENT_COLORS.ALMOST_POSITIVE);
    }

    if (questionnaire.upperCutoff2) {
      rangesPoints.push(questionnaire.upperCutoff2);
      colors.push(ASSESSMENT_COLORS.BETWEEN_NEGATIVE_AND_ALMOST_POSITIVE);
    } else if (questionnaire.upperEqualCutoff2) {
      rangesPoints.push(questionnaire.upperEqualCutoff2);
      colors.push(ASSESSMENT_COLORS.BETWEEN_NEGATIVE_AND_ALMOST_POSITIVE);
    }

    if (questionnaire.upperCutoff) {
      rangesPoints.push(questionnaire.upperCutoff);
      colors.push(ASSESSMENT_COLORS.NEGATIVE);
    } else if (questionnaire.upperEqualCutoff) {
      rangesPoints.push(questionnaire.upperEqualCutoff);
      colors.push(ASSESSMENT_COLORS.NEGATIVE);
    }

    rangesPoints.push(questionnaireMaxScore);

    return [rangesPoints, colors];
  })();

  const renderComparisonMode = () => {
    const differenceBetweenPostResultAndResult = postScore - score;

    const formattedDifferenceBetweenPostResultAndResulte = setFixedDecimalsIfNeeded(
      {
        number: differenceBetweenPostResultAndResult,
      }
    );
    const differenceBetweenPostResultAndResultText =
      differenceBetweenPostResultAndResult > 0
        ? `+${formattedDifferenceBetweenPostResultAndResulte}${unitText}`
        : `${formattedDifferenceBetweenPostResultAndResulte}${unitText}`;

    const isResultPassing = getIsQuestionnairePassing({
      questionnaire,
      questionnaireResult: score,
    });
    const isPostResultPassing = getIsQuestionnairePassing({
      questionnaire,
      questionnaireResult: postScore,
    });

    const differenceBetweenPostResultAndResultIcon = (() => {
      if (isResultPassing === isPostResultPassing) {
        return null;
      }
      const style = { marginLeft: 1 };
      return isPostResultPassing ? (
        <img src={increasingArrowPNG} style={style} />
      ) : (
        <img src={decreasingArrowPNG} style={style} />
      );
    })();

    const differenceBetweenPostResultAndResultTextColor = (() => {
      if (isResultPassing === isPostResultPassing) {
        return ASSESSMENT_COLORS.NEUTRAL;
      }
      return isPostResultPassing
        ? ASSESSMENT_COLORS.POSITIVE
        : ASSESSMENT_COLORS.NEGATIVE;
    })();

    return (
      <div className={styles.result_change_percentage_container}>
        <div>
          <p>{t('pre')}</p>
          <p
            style={{
              color: getQuestionnaireScoreColor({
                questionnaire,
                questionnaireResult: score,
              }),
            }}
          >
            {score}
            {unitText}
          </p>
        </div>
        <div className={styles.vertical_border} />
        <div>
          <p>{t('post')}</p>
          <p
            style={{
              color: getQuestionnaireScoreColor({
                questionnaire,
                questionnaireResult: postScore,
              }),
            }}
          >
            {postScore}
            {unitText}
          </p>
          {differenceBetweenPostResultAndResult !== 0 &&
            !Number.isNaN(differenceBetweenPostResultAndResult) && (
              <span>
                ({differenceBetweenPostResultAndResultIcon}
                <span
                  style={{
                    color: differenceBetweenPostResultAndResultTextColor,
                    marginRight: 3,
                  }}
                >
                  {differenceBetweenPostResultAndResultText}
                </span>
                )
              </span>
            )}
        </div>
      </div>
    );
  };

  const scorePoint = {
    value: score,
    marker: <Marker text={isDefined(postScore) ? t('pre') : undefined} />,
  };
  const points = isDefined(postScore)
    ? [
        scorePoint,
        {
          value: postScore,
          marker: <Marker isFlipped text="Post" />,
          markerPosition: 'bottom',
        },
      ]
    : [scorePoint];

  return (
    <div className={styles.check}>
      <Paper defaultExpanded>
        <div
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="expandable-in-report"
          style={{ padding: 20 }}
        >
          <div
            className={
              classes.checkContainer
                ? classes.checkContainer
                : styles.check_container
            }
          >
            <div className={styles.check_info}>
              <p>
                {questionnaire.name_v3 || questionnaire.name}
                {/* <span>
                  <TooltipWrapper
                    title={
                      <TooltipTitle>{questionnaire.fullName}</TooltipTitle>
                    }
                  >
                    <HelpIcon
                      onClick={event => {
                        // We dont want the expandable menu to be opened when clicking on this icon, so hover can be viewed easily in mobile
                        event.stopPropagation();
                      }}
                      className="hide-on-print"
                    />
                  </TooltipWrapper>
                </span> */}
              </p>
              {/* <p>Total Score</p> */}
            </div>
            {typeof postScore === 'undefined' ? (
              <div
                style={{
                  color: getQuestionnaireScoreColor({
                    questionnaire,
                    questionnaireResult: score,
                  }),
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    margin: 0,
                  }}
                >
                  {score}
                  {unitText && (
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: 500,
                      }}
                    >
                      {unitText}
                    </span>
                  )}
                </p>
              </div>
            ) : (
              renderComparisonMode()
            )}

            <div
              className={
                classes.checkGraph ? classes.checkGraph : styles.check_graph
              }
            >
              <div style={{ paddingTop: 20, paddingBottom: 20, width: '100%' }}>
                <StackedHorizontalBarChart
                  rangesPoints={questionnaireRangesPoints}
                  backgroundColors={questionnaireRangesColors}
                  points={points}
                  classes={{
                    alleviated_marker: styles.alleviated_marker,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: 20 }}>
          <div style={{ width: '100%' }}>
            <div className={styles.swingle_check_description_container}>
              <span>{get(questionnaire, 'description', '')}</span>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

QuestionnaireScoreBase.propTypes = exact({
  score: PropTypes.number.isRequired,
  postScore: PropTypes.number,
  questionnaire: PropTypes.object.isRequired,
  questionnaireMaxScore: PropTypes.number.isRequired,
  unitText: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
});

QuestionnaireScoreBase.defaultProps = {
  unitText: '',
  classes: {},
};

export const QuestionnaireScore = React.memo(QuestionnaireScoreBase);
QuestionnaireScore.displayName = 'QuestionnaireScore';

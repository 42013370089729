exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoEditScreenDialog_lago_dialog_container{align-self:center}.LagoEditScreenDialog_modal_title_container{justify-content:center}.LagoEditScreenDialog_modal_title_label{margin-right:10px}.LagoEditScreenDialog_video_file_label{border-bottom:1px black solid;height:25px;overflow:hidden;text-overflow:ellipsis}.LagoEditScreenDialog_text_editor_container{border:1px #5e5e5e solid;margin-top:10px;margin-bottom:10px;padding:5px;border-radius:10px}.LagoEditScreenDialog_add_btn_label{margin-top:10px}.LagoEditScreenDialog_editor_img{width:100px;height:100px;margin-top:10px;margin-right:10px}.LagoEditScreenDialog_choises_container{display:flex;flex-direction:column;margin-top:10px}.LagoEditScreenDialog_select_container{margin-bottom:10px}.LagoEditScreenDialog_small_editor_title{font-size:1.5rem;margin-bottom:5px}.LagoEditScreenDialog_choise_item_container{display:flex;align-items:center}.LagoEditScreenDialog_upload_image_editor_container{display:flex}.LagoEditScreenDialog_upload_image_editor_btns{display:flex;flex-direction:column;justify-content:center}.LagoEditScreenDialog_editorClassName{font-size:24px !important;color:#676A81 !important}.LagoEditScreenDialog_quiz_inputs_container{display:flex;flex-direction:column}.LagoEditScreenDialog_data_quiz_btns_container{display:flex;justify-content:space-between}.LagoEditScreenDialog_missing_fields_container{display:flex;flex-direction:column;margin-top:15px}.LagoEditScreenDialog_missing_fields_item{color:red}\n", ""]);

// Exports
exports.locals = {
	"lago_dialog_container": "LagoEditScreenDialog_lago_dialog_container",
	"modal_title_container": "LagoEditScreenDialog_modal_title_container",
	"modal_title_label": "LagoEditScreenDialog_modal_title_label",
	"video_file_label": "LagoEditScreenDialog_video_file_label",
	"text_editor_container": "LagoEditScreenDialog_text_editor_container",
	"add_btn_label": "LagoEditScreenDialog_add_btn_label",
	"editor_img": "LagoEditScreenDialog_editor_img",
	"choises_container": "LagoEditScreenDialog_choises_container",
	"select_container": "LagoEditScreenDialog_select_container",
	"small_editor_title": "LagoEditScreenDialog_small_editor_title",
	"choise_item_container": "LagoEditScreenDialog_choise_item_container",
	"upload_image_editor_container": "LagoEditScreenDialog_upload_image_editor_container",
	"upload_image_editor_btns": "LagoEditScreenDialog_upload_image_editor_btns",
	"editorClassName": "LagoEditScreenDialog_editorClassName",
	"quiz_inputs_container": "LagoEditScreenDialog_quiz_inputs_container",
	"data_quiz_btns_container": "LagoEditScreenDialog_data_quiz_btns_container",
	"missing_fields_container": "LagoEditScreenDialog_missing_fields_container",
	"missing_fields_item": "LagoEditScreenDialog_missing_fields_item"
};
import React, { useCallback, useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import styles from './ManageFrequenciesPage.scss';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { CustomTable } from '../../Core/Components/CustomTable/CustomTable';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import {
  pushToArrAndReturnIt,
  doesStrContainAnyFirebaseForbiddenChar,
} from '../../utils/utils';
import {
  FrequencyDetailsDialog,
  FREQUENCY_DETAILS_DIALOG_MODES,
} from '../FrequencyDetailsDialog/FrequencyDetailsDialog';
import { useTranslation } from '../../Core/hooks/useTranslation';
import {
  FREQUENCY_MIN_RANGE,
  FREQUENCY_MAX_RANGE,
  getPresetFrequencyDescription,
  getDerivedPresetFrequencyName,
} from '../../models/frequencies/frequencies';

const FREQUENCY_DETAILS_DIALOG_OPENERS = {
  ADD_FREQUENCY: 'addFrequency',
  EDIT_FREQUENCY: 'editFrequency',
};

const manageFrequenciesPageLogger = new MyEventWrapper(
  'manage_frequencies_page'
);

const customWordLength = 'Custom '.length;
const frequencyNameMaximumLength = 50 + customWordLength;

const ManageFrequenciesPageBase = () => {
  const t = useTranslation();

  const FREQUENCY_RANGE_ERRORS = {
    aFrequencyAlreadyHasTheseRanges:
      'There is already a frequency with these ranges',
  };

  const MIN_FREQUENCY_ERRORS = {
    minFrequencyNotInRange: t('min_frequency_must_be_in_range'),
    minFrequencyNotSmallerThanMaxFrequency: t(
      'min_frequency_must_be_smaller_than_max_frequency'
    ),
  };

  const MAX_FREQUENCY_ERRORS = {
    maxFrequencyNotInRange: t('max_frequency_must_be_in_range'),
    maxFrequencyNotHigherThanMinFrequency: t(
      'max_frequency_must_be_higher_than_min_frequency'
    ),
  };

  const [
    frequencyDetailsDialogOpener,
    setFrequencyDetailsDialogOpener,
  ] = useState(null);

  const frequencyDetailsDialogMode =
    (frequencyDetailsDialogOpener ===
      FREQUENCY_DETAILS_DIALOG_OPENERS.ADD_FREQUENCY &&
      FREQUENCY_DETAILS_DIALOG_MODES.ADDING_FREQUENCY) ||
    (frequencyDetailsDialogOpener ===
      FREQUENCY_DETAILS_DIALOG_OPENERS.EDIT_FREQUENCY &&
      FREQUENCY_DETAILS_DIALOG_MODES.EDITING_FREQUENCY) ||
    null;

  const [frequencyName, setFrequencyName] = useState('');
  const [frequencyNameError, setFrequencyNameError] = useState(null);
  const [frequencyDescription, setFrequencyDescription] = useState('');
  const [frequencyDescriptionError, setFrequencyDescriptionError] = useState(
    null
  );
  const [
    wasFrequencyDescriptionChangedByClinican,
    setWasFrequencyDescriptionChangedByClinican,
  ] = useState(false);
  const [minFrequency, setMinFrequency] = useState('');
  const [minFrequencyError, setMinFrequencyError] = useState(null);
  const [maxFrequency, setMaxFrequency] = useState('');
  const [maxFrequencyError, setMaxFrequencyError] = useState(null);
  const [frequencyBeforeEditing, setFrequencyBeforeEditing] = useState(null);
  const [frequencyRangeError, setFrequencyRangeError] = useState(null);

  const isFrequencyDetailsDialogPrimaryBtnEnabled =
    frequencyName !== '' &&
    frequencyDescription !== '' &&
    minFrequency !== '' &&
    maxFrequency !== '' &&
    frequencyNameError === null &&
    frequencyDescriptionError === null &&
    minFrequencyError === null &&
    maxFrequencyError === null &&
    frequencyRangeError === null;

  const onMinFrequencyChange = useCallback(
    event => {
      const numberMinFrequency = parseInt(event.target.value, 10);
      setMinFrequency(numberMinFrequency);
      setFrequencyRangeError(null);

      if (
        numberMinFrequency < FREQUENCY_MIN_RANGE ||
        numberMinFrequency > FREQUENCY_MAX_RANGE
      ) {
        setMinFrequencyError(MIN_FREQUENCY_ERRORS.minFrequencyNotInRange);
      } else if (maxFrequency && numberMinFrequency >= maxFrequency) {
        setMinFrequencyError(
          MIN_FREQUENCY_ERRORS.minFrequencyNotSmallerThanMaxFrequency
        );
      } else {
        setMinFrequencyError(null);
        if (
          maxFrequencyError ===
          MAX_FREQUENCY_ERRORS.maxFrequencyNotHigherThanMinFrequency
        ) {
          setMaxFrequencyError(null);
        }
      }
    },
    [
      maxFrequency,
      MIN_FREQUENCY_ERRORS,
      maxFrequencyError,
      MAX_FREQUENCY_ERRORS,
    ]
  );
  const onMaxFrequencyChange = useCallback(
    event => {
      const numberMaxFrequency = parseInt(event.target.value, 10);
      setMaxFrequency(numberMaxFrequency);
      setFrequencyRangeError(null);

      if (
        numberMaxFrequency < FREQUENCY_MIN_RANGE ||
        numberMaxFrequency > FREQUENCY_MAX_RANGE
      ) {
        setMaxFrequencyError(MAX_FREQUENCY_ERRORS.maxFrequencyNotInRange);
      } else if (minFrequency && minFrequency >= numberMaxFrequency) {
        setMaxFrequencyError(
          MAX_FREQUENCY_ERRORS.maxFrequencyNotHigherThanMinFrequency
        );
      } else {
        setMaxFrequencyError(null);

        if (
          minFrequencyError ===
          MIN_FREQUENCY_ERRORS.minFrequencyNotSmallerThanMaxFrequency
        ) {
          setMinFrequencyError(null);
        }
      }
    },
    [
      MAX_FREQUENCY_ERRORS,
      minFrequency,
      minFrequencyError,
      MIN_FREQUENCY_ERRORS,
    ]
  );
  const onFrequencyNameChange = useCallback(
    event => {
      const freqName = event.target.value;

      if (freqName.toLowerCase().trim() === 'custom') {
        if (freqName.toLowerCase() !== 'custom') {
          setFrequencyName(freqName);
        }
        setFrequencyNameError(t('frequency_name_is_required'));
      } else if (freqName.toLowerCase().startsWith('custom ')) {
        setFrequencyName(freqName);
        if (doesStrContainAnyFirebaseForbiddenChar(freqName)) {
          setFrequencyNameError(
            'Frequency name cannot contain any of the following characters (. $ # [ ] / \\)'
          );
        } else if (freqName.length > frequencyNameMaximumLength) {
          setFrequencyNameError(
            'Frequency name should be lower than 50 characters'
          );
        } else {
          setFrequencyNameError(null);
        }
      } else if (freqName.length <= 1) {
        setFrequencyName(`Custom ${freqName}`);
        if (freqName.trim() === '') {
          setFrequencyNameError(t('frequency_name_is_required'));
        }
      }
    },
    [t]
  );
  const onFrequencyDescriptionChange = useCallback(
    event => {
      const freqDescription = event.target.value;
      setFrequencyDescription(freqDescription);
      setWasFrequencyDescriptionChangedByClinican(true);

      if (freqDescription.trim() === '') {
        setFrequencyDescriptionError(t('frequency_description_is_required'));
      } else {
        setFrequencyDescriptionError(null);
      }
    },
    [t]
  );

  const closeFrequencyDetailsDialog = () => {
    setFrequencyDetailsDialogOpener(null);
    setFrequencyName('');
    setFrequencyDescription('');
    setMinFrequency('');
    setMaxFrequency('');
    setMinFrequencyError(null);
    setMaxFrequencyError(null);
    setFrequencyDescriptionError(null);
    setFrequencyNameError(null);
    setFrequencyBeforeEditing(null);
    setFrequencyRangeError(null);
    setWasFrequencyDescriptionChangedByClinican(false);
  };

  const onFrequencyDetailsDialogClose = useCallback(() => {
    manageFrequenciesPageLogger.log('on_frequency_details_dialog_close');
    closeFrequencyDetailsDialog();
  }, []);
  const onFrequencyDetailsDialogCancel = onFrequencyDetailsDialogClose;

  const onAddFrequency = useCallback(() => {
    manageFrequenciesPageLogger.log('on_add_frequency');
    setFrequencyDetailsDialogOpener(
      FREQUENCY_DETAILS_DIALOG_OPENERS.ADD_FREQUENCY
    );
  }, []);

  // eslint-disable-next-line consistent-return
  const onFrequencyDetailsDialogConfirm = useCallback(async () => {
    manageFrequenciesPageLogger.log('on_frequency_details_dialog_confirm', {
      name: frequencyName,
      description: frequencyDescription,
      minFrequency,
      maxFrequency,
    });

    if (
      frequencyDetailsDialogOpener ===
      FREQUENCY_DETAILS_DIALOG_OPENERS.ADD_FREQUENCY
    ) {
      const frequencyDatabaseRef = database.ref(
        `clinics/${sessionStorage.userId}/configurations/frequencies/${frequencyName}`
      );

      if (
        await frequencyDatabaseRef
          .once('value')
          .then(snapshot => snapshot.val())
      ) {
        setFrequencyNameError(t('frequency_name_already_exists'));
      } else if (
        extractFrequency(
          minFrequency,
          maxFrequency,
          omitBy(frequenciesConfig, frequency => frequency.show === false)
        )
      ) {
        setFrequencyRangeError(
          FREQUENCY_RANGE_ERRORS.aFrequencyAlreadyHasTheseRanges
        );
      } else {
        frequencyDatabaseRef.set({
          isPreset: false,
          minFrequency,
          maxFrequency,
          description: frequencyDescription,
        });
        closeFrequencyDetailsDialog();
      }
    } else {
      const frequencyBeforeEditingDatabaseRef = database.ref(
        `clinics/${sessionStorage.userId}/configurations/frequencies/${frequencyBeforeEditing.name}`
      );

      const frequencyBeforeEditingDatabaseSnapshot = await frequencyBeforeEditingDatabaseRef
        .once('value')
        .then(snapshot => snapshot.val());

      if (frequencyBeforeEditingDatabaseSnapshot === null) {
        showNotification(
          'danger',
          'This frequency no longer exists, it was edited/deleted by someone else'
        );
        return closeFrequencyDetailsDialog();
      }

      if (frequencyBeforeEditing.isPreset) {
        frequencyBeforeEditingDatabaseRef.update({
          description: frequencyDescription,
        });
        closeFrequencyDetailsDialog();
      } else {
        if (frequencyBeforeEditing.name === frequencyName) {
          frequencyBeforeEditingDatabaseRef.update({
            maxFrequency,
            minFrequency,
            description: frequencyDescription,
          });
          closeFrequencyDetailsDialog();
        } else {
          const newFrequencyDatabaseRef = database.ref(
            `clinics/${sessionStorage.userId}/configurations/frequencies/${frequencyName}`
          );

          if (
            await newFrequencyDatabaseRef
              .once('value')
              .then(snapshot => snapshot.val())
          ) {
            setFrequencyNameError(t('frequency_name_already_exists'));
          } else {
            // we omit what we added to frequencies in the frequencies arr
            const originalFrequency = omit(frequencyBeforeEditing, [
              'name',
              'id',
            ]);

            frequencyBeforeEditingDatabaseRef.set(null);

            newFrequencyDatabaseRef.set({
              ...originalFrequency,
              description: frequencyDescription,
              minFrequency,
              maxFrequency,
            });
            closeFrequencyDetailsDialog();
          }
        }
      }
    }
  }, [
    frequencyDetailsDialogOpener,
    frequencyName,
    minFrequency,
    maxFrequency,
    frequencyDescription,
    frequencyBeforeEditing,
    FREQUENCY_RANGE_ERRORS,
    t,
  ]);

  const [frequencies] = useFirebaseDB({
    path: `clinics/${sessionStorage.userId}/configurations/frequencies`,
  });

  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  useEffect(() => {
    if (frequencies !== null && isLoaderVisible) {
      // eslint-disable-next-line no-undef
      hideLoading();
      setIsLoaderVisible(true);
    }
  }, [frequencies, isLoaderVisible]);

  const frequenciesArr =
    frequencies !== null
      ? Object.entries(frequencies)
          .map(([frequencyKey, frequency]) => ({
            ...frequency,
            id: frequencyKey,
            name: frequencyKey,
          }))
          .filter(
            frequency =>
              frequency.show !== false && frequency.isArchived !== true
          )
      : [];

  const rawTableHeads = [
    t('frequency_name'),
    t('frequency_range'),
    t('description'),
    t('edit'),
  ];

  const tableHeads = rawTableHeads.map(tableHeadText => ({
    node: <span className={styles.head_cell}>{tableHeadText}</span>,
    id: tableHeadText,
  }));

  const onEditFrequency = useCallback(frequency => {
    manageFrequenciesPageLogger.log('on_edit_frequency', {
      name: frequency.name,
      description: frequency.description,
      minFrequency: frequency.minFrequency,
      maxFrequency: frequency.maxFrequency,
    });

    if (
      frequency.name.toLowerCase().startsWith('custom ') ||
      frequency.isPreset
    ) {
      setFrequencyName(frequency.name);
    } else {
      setFrequencyName(`Custom ${frequency.name}`);
    }

    setFrequencyDescription(frequency.description);
    setMinFrequency(frequency.minFrequency);
    setMaxFrequency(frequency.maxFrequency);
    setFrequencyBeforeEditing(frequency);
    setFrequencyDetailsDialogOpener(
      FREQUENCY_DETAILS_DIALOG_OPENERS.EDIT_FREQUENCY
    );
  }, []);

  const onResetDescriptionToDefault = useCallback(() => {
    const presetFrequencyDescription = getPresetFrequencyDescription({
      frequencyName,
      t,
    });
    setFrequencyDescription(presetFrequencyDescription);
  }, [frequencyName, t]);

  const rawTableRows = frequenciesArr.map(frequency => [
    frequency.name,
    `${frequency.minFrequency}- ${frequency.maxFrequency} Hz`,
    frequency.description,
  ]);

  const tableRows = frequenciesArr.map((frequency, frequencyIndex) => ({
    id: frequency.id,
    cells: pushToArrAndReturnIt({
      arr: rawTableRows[frequencyIndex].map(cellContent => (
        <span className={styles.cell}>{cellContent}</span>
      )),
      element: (
        <a
          role="button"
          onClick={() => onEditFrequency(frequency)}
          tabIndex={0}
        >
          <EditIcon style={{ fontSize: 17 }} />
        </a>
      ),
    }).map((node, index) => ({
      id: rawTableHeads[index],
      node,
    })),
  }));

  useEffect(() => {
    if (
      minFrequency &&
      maxFrequency &&
      !wasFrequencyDescriptionChangedByClinican &&
      frequencyDetailsDialogOpener ===
        FREQUENCY_DETAILS_DIALOG_OPENERS.ADD_FREQUENCY
    ) {
      const derivedFrequencyName = getDerivedPresetFrequencyName({
        minFrequency,
        maxFrequency,
      });

      if (derivedFrequencyName) {
        const currentFrequencyDescription = getPresetFrequencyDescription({
          frequencyName: derivedFrequencyName,
          t,
        });
        setFrequencyDescription(currentFrequencyDescription);
      } else {
        setFrequencyDescription('');
      }
    }
  }, [
    minFrequency,
    maxFrequency,
    wasFrequencyDescriptionChangedByClinican,
    t,
    frequencyDetailsDialogOpener,
  ]);

  return (
    <div>
      <Paper className={styles.notices}>
        <ul>
          <li>
            We have already pre-filled the descriptions for some or all of the
            frequencies you have set up. Having a description for each frequency
            is very important for the experience of your clients, as{' '}
            <span className={styles.common_strong_font}>
              these descriptions will be shown in many places in the app and in
              the reports
            </span>
            .
          </li>
          <li>
            <span className={styles.common_strong_font}>{t('note')}</span>:{' '}
            {t('dots_are_disabled_for_frequencies_without_description')}
          </li>
        </ul>
      </Paper>

      <Paper>
        <div className={styles.frequencies_table_header}>
          <div>
            <h3>{t('manage-frequencies')}</h3>
            <p>{t('manage-frequencies-description')}</p>
          </div>
          <div>
            <Button
              onClick={onAddFrequency}
              color="primary"
              variant="contained"
            >
              <span className={styles.btn}>{t('add-new-frequency')}</span>
            </Button>
          </div>
        </div>

        <div className={styles.table_container}>
          <CustomTable
            tableHeads={tableHeads}
            tableRows={tableRows}
            shouldShowRowsPerPageOptions={false}
          />
        </div>
      </Paper>

      <FrequencyDetailsDialog
        isOpen={frequencyDetailsDialogOpener !== null}
        onClose={onFrequencyDetailsDialogClose}
        onCancel={onFrequencyDetailsDialogCancel}
        onConfirm={onFrequencyDetailsDialogConfirm}
        mode={frequencyDetailsDialogMode}
        isPrimaryBtnDisabled={!isFrequencyDetailsDialogPrimaryBtnEnabled}
        isResetDescriptionToDefaultBtnShown={
          frequencyBeforeEditing && frequencyBeforeEditing.isPreset
        }
        isResetDescriptionToDefaultBtnDisabled={
          frequencyBeforeEditing &&
          frequencyBeforeEditing.isPreset &&
          frequencyDescription ===
            getPresetFrequencyDescription({
              frequencyName: frequencyBeforeEditing.name,
              t,
            })
        }
        onResetDescriptionToDefault={onResetDescriptionToDefault}
        {...{
          minFrequency,
          maxFrequency,
          frequencyName,
          frequencyDescription,
          minFrequencyError,
          maxFrequencyError,
          frequencyDescriptionError,
          frequencyNameError,
          onMinFrequencyChange,
          onMaxFrequencyChange,
          onFrequencyNameChange,
          onFrequencyDescriptionChange,
          isFrequencyNameInputDisabled:
            frequencyBeforeEditing && frequencyBeforeEditing.isPreset,
          frequencyRangeError,
        }}
      />
    </div>
  );
};

ManageFrequenciesPageBase.propTypes = exact({});

export const ManageFrequenciesPage = withEntryComponent(
  React.memo(ManageFrequenciesPageBase)
);

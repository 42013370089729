import React from 'react';
import dayjs from 'dayjs';
import { UserCard } from './UserCard/UserCard';
import { ProtocolsUsedInTraining } from './ProtocolsUsedInTraining/ProtocolsUsedInTraining';
import {
  userCardReportCardType,
  protocolsUsedInTrainingReportCardType,
  progressReportTitleType,
  protocolsUsedInTrainingTitleType,
  sessionComparisonGraphType,
  symptomsTrackingGraphType,
  zoneGraphType,
  frequenciesSessionComparisonGraphType,
  streakSessionComparisonGraphType,
  amplitudeAndNoiseGraphType,
  inDepthSessionAnalysisGraphType,
  averageAndMaxStreakGraphType,
  userAddedReportsCardsSectionTitleType,
  assessmentPerformanceGraphType,
  assessmentCPTPerformanceGraphType,
  assessmentBrainMapsGraphType,
  assessmentNoiseGraphType,
  assessmentAmplitudePerFrequencyGraphType,
  assessmentSwingleChecksGraphType,
  assessmentNeuromarkersGraphType,
  assessmentQuestionnairesResultGraphType,
  freeTextCardType,
  insightGraphType,
  overallSymptomsTrackingGraphType,
} from './constants';
import { AddableContent } from './AddableContent/AddableContent';
import { SymptomsTrackingGraph } from './SymptomsTrackingGraph/SymptomsTrackingGraph';
import { ZoneGraph } from './ZoneGraph/ZoneGraph';
import { AmplitudeAndNoiseGraph } from './AmplitudeAndNoiseGraph/AmplitudeAndNoiseGraph';
import { InDepthSessionAnalysisGraph } from './InDepthSessionAnalysisGraph/InDepthSessionAnalysisGraph';
import { AverageAndMaxStreakGraph } from './AverageAndMaxStreakGraph/AverageAndMaxStreakGraph';
import { OverallSymptomsTrackingGraph } from './OverallSymptomsTrackingGraph/OverallSymptomsTrackingGraph';
import styles from './SessionToSessionReportGenerator.scss';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import { PercentageSessionComparisonGraph } from './SessionComparisonGraph/PercentageSessionComparisonGraph';
import { SecondsSessionComparisonGraph } from './SessionComparisonGraph/SecondsSessionComparisonGraph';
import { UVSessionComparisonGraph } from './SessionComparisonGraph/UVSessionComparisonGraph';
import { defensiveThrow } from '../../utils/utils';
import { AssessmentPerformanceComparisonGraph } from './AssessmentPerformanceComparisonGraph/AssessmentPerformanceComparisonGraph';
import { AssessmentBrainMapsComparisonGraph } from './AssessmentBrainMapsComparisonGraph/AssessmentBrainMapsComparisonGraph';
import { AssessmentNoiseComparisonGraph } from './AssessmentNoiseComparisonGraph/AssessmentNoiseComparisonGraph';
import { AssessmentAmplitudePerFrequencyComparisonGraph } from './AssessmentAmplitudePerFrequencyComparisonGraph/AssessmentAmplitudePerFrequencyComparisonGraph';
import { AssessmentSwingleChecks } from './AssessmentSwingleChecks/AssessmentSwingleChecks';
import { AssessmentNeuromarkersChecks } from './AssessmentNeuromarkersChecks/AssessmentNeuromarkersChecks';
import { AssessmentCPTPerformanceComparisonGraph } from './AssessmentCPTPerformanceComparisonGraph/AssessmentCPTPerformanceComparisonGraph';
import { FreeTextCard } from './FreeTextCard/FreeTextCard';
import { AssessmentQuestionnaireResults } from './AssessmentQuestionnaireResults/AssessmentQuestionnaireResults';
import { InsightsBarsGraph } from '../InsightsGraphs/InsightsBarsGraph/InsightsBarsGraph';

const sessionGraphTypes = [
  sessionComparisonGraphType,
  zoneGraphType,
  frequenciesSessionComparisonGraphType,
  streakSessionComparisonGraphType,
  amplitudeAndNoiseGraphType,
  inDepthSessionAnalysisGraphType,
  averageAndMaxStreakGraphType,
];

const assessmentGraphTypes = [
  assessmentPerformanceGraphType,
  assessmentCPTPerformanceGraphType,
  assessmentBrainMapsGraphType,
  assessmentNoiseGraphType,
  assessmentAmplitudePerFrequencyGraphType,
  assessmentSwingleChecksGraphType,
  assessmentQuestionnairesResultGraphType,
  overallSymptomsTrackingGraphType,
  assessmentNeuromarkersGraphType,
];

const allGraphTypes = [
  insightGraphType,
  freeTextCardType,
  symptomsTrackingGraphType,
  ...sessionGraphTypes,
  ...assessmentGraphTypes,
];

const getProtocolsUsedInTraining = (sessions, frequenciesConfig) => {
  const protocols = [];

  sessions.forEach(session => {
    const newProtocol = {
      name: session.protocol,
      trainedSessionsNum: 0,
      trainedDurationInMinutes: 0,
      trainingChannel: session.trainingChannel,
      frequencies: [],
      frequencyNames: [],
    };

    const protocol =
      protocols.find(aProtocol => aProtocol.name === session.protocol) ||
      (protocols.push(newProtocol), newProtocol);

    protocol.trainedSessionsNum += 1;
    protocol.trainedDurationInMinutes += session.duration / 60;
    protocol.trainingChannel = session.training_channel;
    protocol.frequencies = session.frequencies || [];
    protocol.frequencyNames =
      session.frequencies.map(freq =>
        extractFrequency(freq.rangeStart, freq.rangeEnd, frequenciesConfig)
      ) || [];
  });

  return protocols;
};

const getReportCardsDataSessions = reportCardsData => {
  const sessions = {};
  reportCardsData.forEach(reportCardData => {
    if (sessionGraphTypes.indexOf(reportCardData.type) > -1) {
      if (reportCardData.selectedSessions) {
        reportCardData.selectedSessions.forEach(session => {
          const { sessionKey } = session;
          sessions[sessionKey] = session;
        });
      }
    }
  });

  return Object.values(sessions);
};

export const mapReportCardsDataToReportCards = ({
  reportCardsData,
  addableContentProps = {},
  frequenciesConfig,
  shouldShowBrainMaps = true,
}) => {
  const reportCardsDataSessions = getReportCardsDataSessions(reportCardsData);

  return reportCardsData
    .filter(reportCardData => {
      const reportCardDataType = reportCardData.type;
      if (
        reportCardDataType === assessmentBrainMapsGraphType &&
        !shouldShowBrainMaps
      ) {
        return false;
      }
      return true;
    })
    .map(reportCardData => {
      const reportCardDataType = reportCardData.type;

      if (reportCardDataType === userCardReportCardType) {
        return {
          id: reportCardData.id,
          type: reportCardDataType,
          content: <UserCard {...reportCardData.data} />,
        };
      }

      if (reportCardDataType === protocolsUsedInTrainingReportCardType) {
        return {
          id: reportCardData.id,
          type: reportCardDataType,
          content: (
            <div
              style={{
                display: reportCardData.shouldBeHidden ? 'none' : 'initial',
              }}
            >
              <ProtocolsUsedInTraining
                protocols={getProtocolsUsedInTraining(
                  reportCardsDataSessions,
                  frequenciesConfig
                )}
                frequenciesConfig={frequenciesConfig}
              />
            </div>
          ),
        };
      }

      if (reportCardDataType === progressReportTitleType) {
        return {
          id: reportCardData.id,
          type: reportCardDataType,
          content: (
            <div className={styles.report_cards_section_title}>
              <p data-test-id="report-title">
                Progress Report:{' '}
                {dayjs(reportCardData.data.timestamp).format(
                  DAY_JS_DATE_FORMATS.americanWithTime
                )}
              </p>
            </div>
          ),
        };
      }

      if (reportCardDataType === protocolsUsedInTrainingTitleType) {
        return {
          id: reportCardData.id,
          type: reportCardDataType,
          content:
            reportCardsDataSessions.length > 0 ? (
              <div
                style={{
                  display: reportCardData.shouldBeHidden ? 'none' : 'initial',
                }}
                className={styles.report_cards_section_title}
              >
                <p>Protocols Used In Training:</p>
              </div>
            ) : null,
        };
      }

      if (reportCardDataType === userAddedReportsCardsSectionTitleType) {
        return {
          id: reportCardData.id,
          type: reportCardDataType,
          content: (
            <div
              style={{
                display: reportCardData.shouldBeHidden ? 'none' : 'initial',
              }}
              className={styles.report_cards_section_title}
            >
              <p>Progress Visualization:</p>
            </div>
          ),
        };
      }

      if (
        allGraphTypes.indexOf(reportCardDataType) > -1 ||
        reportCardDataType.startsWith(insightGraphType)
      ) {
        let Graph = () => null;

        if (reportCardDataType === symptomsTrackingGraphType) {
          Graph = SymptomsTrackingGraph;
        }
        if (reportCardDataType === zoneGraphType) {
          Graph = ZoneGraph;
        }
        if (reportCardDataType === sessionComparisonGraphType) {
          Graph = PercentageSessionComparisonGraph;
        }
        if (reportCardDataType === streakSessionComparisonGraphType) {
          Graph = SecondsSessionComparisonGraph;
        }
        if (reportCardDataType === frequenciesSessionComparisonGraphType) {
          Graph = UVSessionComparisonGraph;
        }

        if (reportCardDataType === amplitudeAndNoiseGraphType) {
          Graph = AmplitudeAndNoiseGraph;
        }
        if (reportCardDataType === inDepthSessionAnalysisGraphType) {
          Graph = InDepthSessionAnalysisGraph;
        }
        if (reportCardDataType === averageAndMaxStreakGraphType) {
          Graph = AverageAndMaxStreakGraph;
        }
        if (reportCardDataType === assessmentPerformanceGraphType) {
          Graph = AssessmentPerformanceComparisonGraph;
        }

        if (reportCardDataType === assessmentCPTPerformanceGraphType) {
          Graph = AssessmentCPTPerformanceComparisonGraph;
        }

        if (reportCardDataType === assessmentBrainMapsGraphType) {
          Graph = AssessmentBrainMapsComparisonGraph;
        }

        if (reportCardDataType === assessmentAmplitudePerFrequencyGraphType) {
          Graph = AssessmentAmplitudePerFrequencyComparisonGraph;
        }

        if (reportCardDataType === assessmentSwingleChecksGraphType) {
          Graph = AssessmentSwingleChecks;
        }

        if (reportCardDataType === assessmentNeuromarkersGraphType) {
          Graph = AssessmentNeuromarkersChecks;
        }

        if (reportCardDataType === assessmentQuestionnairesResultGraphType) {
          Graph = AssessmentQuestionnaireResults;
        }

        if (reportCardDataType === freeTextCardType) {
          Graph = FreeTextCard;
        }

        if (reportCardDataType.startsWith(insightGraphType)) {
          Graph = InsightsBarsGraph;
        }

        if (reportCardDataType === overallSymptomsTrackingGraphType) {
          Graph = OverallSymptomsTrackingGraph;
        }

        if (reportCardDataType === assessmentNoiseGraphType) {
          Graph = AssessmentNoiseComparisonGraph;
        }

        return {
          id: reportCardData.id,
          type: reportCardDataType,
          title: reportCardData.title,
          content: (
            <AddableContent
              content={<Graph {...reportCardData.data.graphPayload} />}
              {...reportCardData.data}
              {...addableContentProps}
            />
          ),
        };
      }

      return defensiveThrow({
        error:
          'Can not map this report card data to report card: unsupported card data type',
      });
    });
};

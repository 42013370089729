/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
import styles from './CustomerStoriesPagesStyles.scss';

export const symptomTrackerStorySequences = symptom_tracker_improvement_percentage => {
  // const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  // const backgroundAnimationDelay = 2500;
  const backgroundAnimationDuration = 2000; // this numbers is not correct but it works

  const seq1WordsLength = 'Woah!'.split(' ').length;
  const seq2WordsLength = 'Your questionnaires say you’ve also felt better'.split(
    ' '
  ).length;

  const seq3WordsLength = 'Your self-reported symptoms improved by up to'.split(
    ' '
  ).length;

  const seq4WordsLength = `${symptom_tracker_improvement_percentage}%`.split('')
    .length;

  const seq1Variants = {
    initial: { opacity: 0, y: -20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      x: 20,
      transition: {
        ease: 'easeInOut',
      },
    },
  };

  const seq2Variants = {
    initial: { opacity: 0 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: (2 * textAnimationDuration) / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  // const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  // const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  // const seq4EnterTime = seq4WordsLength * textAnimationDuration;
  // const seq4ExitTime = seq4WordsLength * textAnimationDuration;
  // const seq4TotalTime = seq4EnterTime + seq4ExitTime;

  const seq1 = {
    id: 'seq1',
    enterDelay: 0,
    exitDelay: seq1EnterTime + seq2TotalTime + 15 * textAnimationDuration,
    words: [
      {
        id: `seq1-word${1}`,
        text: 'Woah!',
        enterDelay: 0,
        exitDelay: seq1EnterTime + seq2EnterTime + 15 * textAnimationDuration,
        variants: {
          initial: {
            opacity: 0,
            y: 20,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
            },
          },
          exit: {
            opacity: 0,
            x: -20,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
              //   staggerDirection: -1,
            },
          },
        },
        // style: { display: 'flex' },
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          exit: {
            opacity: 0,
            x: -20,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.symptomTracker_seq_1,
    variants: {
      initial: { opacity: 0, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' },
    },
    extraAnimations: [],
  };

  const seq2 = {
    id: 'seq2',
    // words: seq1Words,
    sentence: 'Your questionnaires say you’ve also felt better',
    // wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay: seq1EnterTime + 2 * textAnimationDuration,
    exitDelay: seq1EnterTime + seq2EnterTime + 12 * textAnimationDuration,
    style: {},
    className: styles.symptomTracker_seq_2,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        x: 20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.1,
          staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq3 = {
    id: 'seq3',
    // words: seq2Words,
    sentence: 'Your self-reported symptoms improved by up to',
    wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq2Variants,
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      backgroundAnimationDuration +
      4 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.symptomTracker_seq_3,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
        },
      },
      exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq4 = {
    id: 'seq4',
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      backgroundAnimationDuration +
      4 * textAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq4-word${1}`,
        text: `${symptom_tracker_improvement_percentage}%`,
        enterDelay:
          seq1TotalTime +
          seq2TotalTime +
          seq3EnterTime +
          backgroundAnimationDuration +
          4 * textAnimationDuration,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            y: 20,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
            },
          },
          // exit: {},
        },
        // style: { display: 'flex' },
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.symptomTracker_seq_4,
    variants: {
      initial: { opacity: 0, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  return [seq1, seq2, seq3, seq4];
};

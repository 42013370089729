import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import styles from './DeactivateAddonsDialog.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';

const DeactivateAddonsDialogBase = ({
  isOpen,
  step,
  onConfirm,
  onClose,
  deactivationReasons,
  onDeactivationReasonClick,
  deactivationReasonsError,
  deactivationComment,
  onDeactivationCommentChange,
  deactivationCommentError,
  formattedNextBillingCycle,
  creditOrChargeExtraNf,
  isAnnual,
  currentPackage,
}) => {
  if (step === 0) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <div style={{ textAlign: 'center', fontSize: 20 }}>
            Deactivate the Neuro add-on
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: 15,
            }}
          >
            <div>
              All of the current neurofeedback programs will be canceled on{' '}
              {formattedNextBillingCycle} and you will no longer have access to
              EEG sensing.
            </div>
            <div style={{ marginTop: 10 }}>
              You can choose to add it back whenever you’d like in the future.
            </div>
            <div style={{ marginTop: 20, fontWeight: 900 }}>
              Are you sure you want to deactivate the add-on?
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            <span className={styles.button}>No, nevermind</span>
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            // variant="contained"
          >
            <span className={styles.button}>Deactivate now</span>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (step === 1) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <div style={{ textAlign: 'center', fontSize: 20 }}>
            Can you please tell us why you’re deactivating the Neuro add-on?
          </div>
        </DialogTitle>
        <DialogContent>
          {deactivationReasonsError && (
            <p className={styles.error_msg}>{deactivationReasonsError}</p>
          )}
          <div className={styles.deactivation_reasons} style={{ fontSize: 15 }}>
            {Object.keys(deactivationReasons).map(reasonKey => (
              <Chip
                key={reasonKey}
                label={reasonKey}
                classes={{
                  root: deactivationReasons[reasonKey]
                    ? styles.deactivation_reason_chip_root_selected
                    : styles.deactivation_reason_chip_root,
                }}
                onClick={() => onDeactivationReasonClick(reasonKey)}
              />
            ))}
          </div>
          {deactivationCommentError && (
            <p className={styles.error_msg}>{deactivationCommentError}</p>
          )}
          <div>
            <FormControl classes={{ root: styles.form_control_root }}>
              <TextField
                placeholder="Can you please share more?"
                variant="outlined"
                value={deactivationComment}
                onChange={onDeactivationCommentChange}
                error={!!deactivationCommentError}
              />
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            <span className={styles.button}>Nevermind</span>
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            // variant="contained"
          >
            <span className={styles.button}>Deactivate now</span>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (step === 2) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <Loader />
      </Dialog>
    );
  }
  if (step === 3) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <div style={{ textAlign: 'center', fontSize: 20 }}>
            The Neuro add-on was removed successfully!
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: 15,
            }}
          >
            {!isAnnual ? (
              <div>
                Starting from your next billing cycle{' '}
                {formattedNextBillingCycle}, your charge will exclude the Neuro
                add-on, making the total ${currentPackage.basePrice}/month.
              </div>
            ) : (
              <div>
                {creditOrChargeExtraNf ? (
                  <div>
                    You’ll get ${creditOrChargeExtraNf} in credit for the unused
                    days in the current billing cycle.
                  </div>
                ) : null}
                <div>
                  Starting from your next billing cycle{' '}
                  {formattedNextBillingCycle}, your charge will exclude the
                  Neuro add-on, making the total $
                  {currentPackage.basePrice * 12}.
                </div>
              </div>
            )}
            <br />
            <div>
              You can still use neurofeedback training until{' '}
              {formattedNextBillingCycle}.{' '}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            <span className={styles.button}>Done</span>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

DeactivateAddonsDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deactivationReasons: PropTypes.object.isRequired,
  onDeactivationReasonClick: PropTypes.func.isRequired,
  deactivationReasonsError: PropTypes.string,
  deactivationComment: PropTypes.string,
  onDeactivationCommentChange: PropTypes.func.isRequired,
  deactivationCommentError: PropTypes.string,
  formattedNextBillingCycle: PropTypes.string,
  creditOrChargeExtraNf: PropTypes.number,
  isAnnual: PropTypes.bool,
  currentPackage: PropTypes.object,
});

export const DeactivateAddonsDialog = React.memo(DeactivateAddonsDialogBase);
DeactivateAddonsDialog.displayName = 'DeactivateAddonsDialog';

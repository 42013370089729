import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import styles from './CancelAccountDeactivationDialog.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';

const CancelAccountDeactivationDialogBase = ({
  isOpen,
  step,
  onConfirm,
  onClose,
}) => {
  const shouldRenderDialogActions = step !== 'loading';
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        {step === 'loading' && <Loader />}
        {step === 'deactivationCanceled' && (
          <div>
            <h3 className={styles.dialog_header}>
              Your account deactivation has been canceled
            </h3>
          </div>
        )}
      </DialogContent>
      {shouldRenderDialogActions && (
        <DialogActions>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            <span className={styles.button}>Done</span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

CancelAccountDeactivationDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.oneOf(['loading', 'deactivationCanceled']).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
});

export const CancelAccountDeactivationDialog = React.memo(
  CancelAccountDeactivationDialogBase
);
CancelAccountDeactivationDialog.displayName = 'CancelAccountDeactivationDialog';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  Button,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import giftIcon from '../../../../assets/gift-icon.png';

import { ChangeCreditCardDetailsIfNeededWrapper } from '../../ChangeCreditCardDetailsIfNeeded/ChangeCreditCardDetailsIfNeededWrapper';

import styles from './ActivateBlockedClinicDialog.scss';

const ActivateBlockedClinicDialogBase = ({
  isOpen,
  defaultBlockedUsageCredit,
  userPrice,
  dormantPackageName,
}) => {
  const [isLoadingActivation, setIsLoadingActivation] = useState(false);
  const [isCreditCardStep, setIsCreditCardStep] = useState(false);

  const onActivateFreePlan = async () => {
    new MyEvent('blocked_clinic_activate_free_plan_clicked').log();
    try {
      // eslint-disable-next-line no-undef
      const { balance } = await fireFunctionPost('billing-accountBalance');
      if (balance < 0) {
        setIsCreditCardStep(true);
        return;
      }
    } catch (err) {
      setIsCreditCardStep(false);
    }
    const dormantPlans = await database
      .ref('config/dormantPlans')
      .once('value')
      .then(snapshot => snapshot.val());
    setIsLoadingActivation(true);
    try {
      // eslint-disable-next-line no-undef
      const data = await fireFunctionPost('billing-moveBlockedToDormantPlan', {
        planConfigs: { ...dormantPlans },
      });
      if (data.result === true) {
        // eslint-disable-next-line no-undef, no-restricted-globals
        location.reload();
      }
    } catch (e) {
      // TODO handle error
      setIsLoadingActivation(false);
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className={styles.content}>
          <div className={styles.content_title_container}>
            <p className={styles.content_title}>Activate a plan to continue</p>
            {defaultBlockedUsageCredit ? (
              <div className={styles.gift_banner}>
                <img
                  src={giftIcon}
                  style={{
                    marginRight: 10,
                    width: 14,
                    height: 14,
                    position: 'relative',
                    top: -2,
                  }}
                />
                You have ${defaultBlockedUsageCredit} in usage credit
              </div>
            ) : null}
          </div>
          <p style={{ marginBottom: 0, marginTop: 10 }}>
            You previously deactivated your Myndlift subscription. To continue
            using Myndlift, you can choose to activate the {dormantPackageName}{' '}
            plan:
          </p>
        </div>
        <div className={styles.free_benefits_container}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontWeight: 500, fontSize: 18 }}>
              {dormantPackageName}
            </div>
            <div style={{ fontSize: 16 }}>$0/year</div>
          </div>
          <div>
            <div className={styles.deactivate_description_2_items_container}>
              <div className={styles.deactivate_description_item_check}>
                <CheckIcon className={styles.check_icon} />
                <div>Max of 3 active clients</div>
              </div>
              <div className={styles.deactivate_description_item_check}>
                <CheckIcon className={styles.check_icon} />
                <div>Symptom tracking tools</div>
              </div>
            </div>
            <div className={styles.deactivate_description_2_items_container}>
              <div className={styles.deactivate_description_item_check}>
                <CheckIcon className={styles.check_icon} />
                <div>Neurofeedback training (prorated usage-only fees)*</div>
              </div>
              <div className={styles.deactivate_description_item_check}>
                <CheckIcon className={styles.check_icon} />
                <div>Comprehensive assessments</div>
              </div>
            </div>
            <div className={styles.deactivate_description_2_items_container}>
              <div className={styles.deactivate_description_item_check} />
              <div className={styles.deactivate_description_item_check}>
                <CloseIcon style={{ color: '#B2B2B2', marginRight: 10 }} />
                <div>No team member access</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: 20 }}>
          *A prorated usage fee of ${userPrice} per client per month is
          applicable for clients who exceed 5 minutes of neurofeedback training.
        </div>

        <DialogActions>
          <Button
            variant="contained"
            onClick={async () => {
              await new MyEvent('blocked_clinic_view_all_plans_clicked').log();
              window.open('/new_account.html', '_self');
            }}
            disabled={isLoadingActivation}
          >
            <span className={styles.btn}>View all plans</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onActivateFreePlan}
            disabled={isLoadingActivation}
          >
            <span className={styles.btn}>
              {isLoadingActivation ? (
                <CircularProgress size={14} />
              ) : (
                `Activate ${dormantPackageName} plan`
              )}
            </span>
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderCreditCard = () => {
    return (
      <div className={styles.content}>
        <ChangeCreditCardDetailsIfNeededWrapper
          onClose={() => {
            setIsCreditCardStep(false);
          }}
          isFreePlan
          onCreditCardUpdated={async () => {
            const dormantPlans = await database
              .ref('config/dormantPlans')
              .once('value')
              .then(snapshot => snapshot.val());
            setIsLoadingActivation(true);
            try {
              // eslint-disable-next-line no-undef
              const data = await fireFunctionPost(
                'billing-moveBlockedToDormantPlan',
                {
                  planConfigs: { ...dormantPlans },
                }
              );
              if (data.result === true) {
                // eslint-disable-next-line no-undef, no-restricted-globals
                location.reload();
              }
            } catch (e) {
              // TODO handle error
              setIsLoadingActivation(false);
            }
          }}
        />
      </div>
    );
  };

  return (
    <Dialog open={isOpen}>
      {isCreditCardStep ? renderCreditCard() : renderContent()}
    </Dialog>
  );
};

ActivateBlockedClinicDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  defaultBlockedUsageCredit: PropTypes.number,
  userPrice: PropTypes.number,
  dormantPackageName: PropTypes.string,
});

export const ActivateBlockedClinicDialog = React.memo(
  ActivateBlockedClinicDialogBase
);
ActivateBlockedClinicDialog.displayName = 'ActivateBlockedClinicDialog';

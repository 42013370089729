exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ShareReportDialog_size_17{font-size:17px}.ShareReportDialog_size_10{font-size:10px}.ShareReportDialog_size_11{font-size:11px}.ShareReportDialog_size_13{font-size:13px}.ShareReportDialog_size_15{font-size:15px}.ShareReportDialog_size_14{font-size:14px}.ShareReportDialog_size_16{font-size:16px}.ShareReportDialog_size_12{font-size:12px}.ShareReportDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.ShareReportDialog_header,.ShareReportDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.ShareReportDialog_header_only_font_size{font-size:17px;font-weight:500}.ShareReportDialog_sub_header{font-size:13px;color:#878787}.ShareReportDialog_sub_header_black{font-size:13px;color:black}.ShareReportDialog_sub_header_only_font_size{font-size:13px}.ShareReportDialog_paragraph_only_font_size{font-size:12px}.ShareReportDialog_tab_font_size{font-size:13px}.ShareReportDialog_pop_out_header{font-size:20px}.ShareReportDialog_title{font-size:15px;font-weight:500;color:#484848}.ShareReportDialog_title_black{font-size:15px;font-weight:500;color:black}.ShareReportDialog_title_only_font_size{font-size:15px}.ShareReportDialog_paragraph,.ShareReportDialog_dialog_text{font-size:12px;color:#484848}.ShareReportDialog_btn,.ShareReportDialog_button{font-size:14px}.ShareReportDialog_form_control{margin:0 !important;width:100% !important}.ShareReportDialog_input_container{display:flex;flex-wrap:wrap;align-items:center}.ShareReportDialog_input_container>div:first-child{flex:1;margin-right:15px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ShareReportDialog_size_17",
	"size_10": "ShareReportDialog_size_10",
	"size_11": "ShareReportDialog_size_11",
	"size_13": "ShareReportDialog_size_13",
	"size_15": "ShareReportDialog_size_15",
	"size_14": "ShareReportDialog_size_14",
	"size_16": "ShareReportDialog_size_16",
	"size_12": "ShareReportDialog_size_12",
	"extra_header": "ShareReportDialog_extra_header",
	"header": "ShareReportDialog_header",
	"dialog_header": "ShareReportDialog_dialog_header",
	"header_only_font_size": "ShareReportDialog_header_only_font_size",
	"sub_header": "ShareReportDialog_sub_header",
	"sub_header_black": "ShareReportDialog_sub_header_black",
	"sub_header_only_font_size": "ShareReportDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "ShareReportDialog_paragraph_only_font_size",
	"tab_font_size": "ShareReportDialog_tab_font_size",
	"pop_out_header": "ShareReportDialog_pop_out_header",
	"title": "ShareReportDialog_title",
	"title_black": "ShareReportDialog_title_black",
	"title_only_font_size": "ShareReportDialog_title_only_font_size",
	"paragraph": "ShareReportDialog_paragraph",
	"dialog_text": "ShareReportDialog_dialog_text",
	"btn": "ShareReportDialog_btn",
	"button": "ShareReportDialog_button",
	"form_control": "ShareReportDialog_form_control",
	"input_container": "ShareReportDialog_input_container"
};
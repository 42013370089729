import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper, Button } from '@material-ui/core';
import noReportPNG from '../../../../../assets/no_sessions_icon.png';
import styles from './NoDeployedProgramCard.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

const NoDeployedProgramCardBase = ({ onCreateProgram }) => {
  const t = useTranslation();
  return (
    <Paper className={styles.root}>
      <img src={noReportPNG} />
      <p>{t('client_does_not_have_training_program')}</p>
      <div className={styles.btn_container}>
        <Button variant="contained" color="primary" onClick={onCreateProgram}>
          <span className={styles.btn}>{t('create_program')}</span>
        </Button>
      </div>
    </Paper>
  );
};

NoDeployedProgramCardBase.propTypes = exact({
  onCreateProgram: PropTypes.func.isRequired,
});

export const NoDeployedProgramCard = React.memo(NoDeployedProgramCardBase);
NoDeployedProgramCard.displayName = 'NoDeployedProgramCard';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import startCase from 'lodash/startCase';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import styles from './AssessmentNoiseComparisonGraph.scss';
// import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { electrodesNameAndOrder } from '../../../models/channels/channels';

const AssessmentNoiseComparisonGraphBase = ({
  // frequencyAssessmentBrainMaps,
  // allFrequencies,
  // selectedFrequencies,
  // onSelectedFrequenciesChange,
  isInPreview,
  preAssessment,
  postAssessment,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  noDataIndicator,
  preAssessmentNoise,
  postAssessmentNoise,
  // frequenciesToImagesFullNames,
}) => {
  const calcNoiseGraphData = brainMapsNoiseData => {
    const eyesClosedGraphData =
      !brainMapsNoiseData || brainMapsNoiseData === {}
        ? null
        : Object.keys(brainMapsNoiseData.eyesClosed)
            .sort(
              (a, b) =>
                electrodesNameAndOrder[a].order -
                electrodesNameAndOrder[b].order
            )
            .map(electrode => {
              return {
                name: electrodesNameAndOrder[electrode].name,
                value: brainMapsNoiseData.eyesClosed[electrode] * 100,
              };
            });

    const eyesOpenGraphData =
      !brainMapsNoiseData || brainMapsNoiseData === {}
        ? null
        : Object.keys(brainMapsNoiseData.eyesOpened)
            .sort(
              (a, b) =>
                electrodesNameAndOrder[a].order -
                electrodesNameAndOrder[b].order
            )
            .map(electrode => {
              return {
                name: electrodesNameAndOrder[electrode].name,
                value: brainMapsNoiseData.eyesOpened[electrode] * 100,
              };
            });
    return [eyesOpenGraphData, eyesClosedGraphData];
  };

  const renderNoiseGraph = graphData => {
    return (
      <div>
        <BarChart
          data={graphData}
          width={window.innerWidth < 960 ? 250 : 400}
          height={140}
          // style={{marginRight: -40}}
        >
          <XAxis dataKey="name" interval={0} fontSize={8} />
          <YAxis
            domain={[0, 100]}
            tickFormatter={tick => `${tick}%`}
            fontSize={10}
          />
          <Bar dataKey="value" barSize={10} fill="#3c78d7" />
        </BarChart>
      </div>
    );
  };

  const preAssessmentNoiseData =
    preAssessmentNoise && calcNoiseGraphData(preAssessmentNoise);
  const postAssessmentNoiseData =
    postAssessmentNoise && calcNoiseGraphData(postAssessmentNoise);

  const t = useTranslation();

  return (
    <div className={styles.root}>
      <AssessmentComparisonGraph
        assessmentOptions={assessmentOptions}
        preAssessment={preAssessment}
        postAssessment={postAssessment}
        preAssessmentTimestamp={preAssessmentTimestamp}
        postAssessmentTimestamp={postAssessmentTimestamp}
        onPreAssessmentChange={onPreAssessmentChange}
        onPostAssessmentChange={onPostAssessmentChange}
        isInPreview={isInPreview}
      >
        {noDataIndicator || (
          <div className={styles.content}>
            <div style={{ padding: '20px 0' }}>
              {/* <p className={styles.frequency_name}>Noise</p> */}
              <div className={styles.freq_info_container}>
                <div>
                  <p>{startCase(t('eyes-open'))}</p>
                  <div className={styles.images_container}>
                    <div>
                      <p>{t('pre')}</p>
                      {renderNoiseGraph(preAssessmentNoiseData[0])}
                    </div>
                    <div className={styles.vertical_border} />
                    <div>
                      <p>{t('post')}</p>
                      {renderNoiseGraph(postAssessmentNoiseData[0])}
                    </div>
                  </div>
                </div>
                <div>
                  <p>{t('eyes_closed')}</p>
                  <div className={styles.images_container}>
                    <div>
                      <p>{t('pre')}</p>
                      {renderNoiseGraph(preAssessmentNoiseData[1])}
                    </div>
                    <div className={styles.vertical_border} />
                    <div>
                      <p>{t('post')}</p>
                      {renderNoiseGraph(postAssessmentNoiseData[1])}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AssessmentComparisonGraph>
    </div>
  );
};

AssessmentNoiseComparisonGraphBase.defaultProps = {
  isInPreview: false,
  noDataIndicator: null,
};

AssessmentNoiseComparisonGraphBase.propTypes = exact({
  // frequencyAssessmentBrainMaps: PropTypes.object.isRequired,
  // allFrequencies: PropTypes.array.isRequired,
  // selectedFrequencies: PropTypes.array.isRequired,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  // onSelectedFrequenciesChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  noDataIndicator: PropTypes.node,
  preAssessmentNoise: PropTypes.object,
  postAssessmentNoise: PropTypes.object,
  // frequenciesToImagesFullNames: PropTypes.array,
});

export const AssessmentNoiseComparisonGraph = React.memo(
  AssessmentNoiseComparisonGraphBase
);
AssessmentNoiseComparisonGraph.displayName = 'AssessmentNoiseComparisonGraph';

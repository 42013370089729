import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import dayJS from 'dayjs';
import { Paper } from '@material-ui/core';
import styles from './OverallSymptomsTrackingGraph.scss';
import { DeepEqualityComponent } from '../../../Core/DeepEqualityComponent/DeepEqualityComponent';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { graphHeight } from '../constants';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { getQuestionnaireLabel } from '../../../models/symptom-tracker/symptom-tracker';

class OverallSymptomsTrackingGraphBase extends DeepEqualityComponent {
  render() {
    const {
      filteredOverallQuestionnaires,
      allOverallQuestionnaires,
      onSelectedQuestionnairesDataChange,
      graphData,
      isInPreview,
      isFullWidht,
    } = this.props;

    const filteredAllOverallQuestionnaires = {};
    Object.keys(allOverallQuestionnaires).forEach(id => {
      if (!allOverallQuestionnaires[id].hideResult) {
        filteredAllOverallQuestionnaires[id] = allOverallQuestionnaires[id];
      }
    });

    const dataList = filteredOverallQuestionnaires
      .filter(id => !allOverallQuestionnaires[id].hideResult)
      .map(id => {
        const dataItems = graphData
          .filter(el => el[id] || el[id] === 0)
          .map(el => {
            return {
              id,
              timestamp: el.timestamp,
              Value: el[id],
            };
            // return null;
          });
        return dataItems;
      });

    return (
      <div>
        {!isInPreview && (
          <div className={styles.questionnaires_select}>
            <CheckboxesSelect
              inputLabelText="Questionnaires"
              selectedOptions={filteredOverallQuestionnaires}
              allOptions={Object.values(filteredAllOverallQuestionnaires).map(
                questionnaire => ({
                  label: getQuestionnaireLabel(questionnaire),
                  value: questionnaire.id,
                })
              )}
              onChange={onSelectedQuestionnairesDataChange}
              renderValue={questionnairesIds => {
                return Object.values(filteredOverallQuestionnaires).length > 1
                  ? 'Multiple questionnaires'
                  : getQuestionnaireLabel(
                      allOverallQuestionnaires[questionnairesIds[0]],
                      {
                        shouldUseSubScale: true,
                      }
                    );
              }}
              fullWidth
            />
          </div>
        )}
        <div className={styles.graphs_container}>
          {dataList.map(graphDataItem => {
            return (
              <div
                key={graphDataItem[0].id}
                className={styles.overall_single_graph}
                style={isFullWidht ? { width: '100%' } : null}
              >
                <Paper style={{ padding: 20 }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: 500,
                      marginBottom: 10,
                      color: 'black',
                    }}
                  >
                    {getQuestionnaireLabel(
                      allOverallQuestionnaires[graphDataItem[0].id]
                    )}
                  </div>
                  <ResponsiveContainer width="100%" height={graphHeight}>
                    <BarChart data={graphDataItem}>
                      <XAxis
                        dataKey="timestamp"
                        tickFormatter={val =>
                          dayJS(val).format(
                            DAY_JS_DATE_FORMATS.namedMonthAndDay
                          )
                        }
                      />
                      <YAxis
                        label={{
                          value: 'Score',
                          angle: -90,
                          position: 'insideLeft',
                        }}
                        domain={[
                          0,
                          allOverallQuestionnaires[graphDataItem[0].id]
                            .maxScore,
                        ]}
                      />
                      <Tooltip
                        formatter={value => [value, 'Client score']}
                        labelFormatter={val =>
                          dayJS(val).format(
                            DAY_JS_DATE_FORMATS.namedMonthDayYear
                          )
                        }
                      />
                      <Bar dataKey="Value" fill="#90B6F3" barSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

// OverallSymptomsTrackingGraphBase.defaultProps = {
//   isInPreview: false,
//   // onLegendItemClick: () => {},
//   classes: {},
// };

OverallSymptomsTrackingGraphBase.propTypes = {
  filteredOverallQuestionnaires: PropTypes.array,
  allOverallQuestionnaires: PropTypes.object,
  onSelectedQuestionnairesDataChange: PropTypes.func,
  graphData: PropTypes.array,
  isInPreview: PropTypes.bool,
  isFullWidht: PropTypes.bool,
};

export const OverallSymptomsTrackingGraph = OverallSymptomsTrackingGraphBase;

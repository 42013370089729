import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { HeadTopView } from '../../Core/images/HeadTopView';
import styles from './SignalQualityTopDownHead.scss';

import { bifurcateArrBy } from '../../utils/utils';
import { CHANNELS, isAuxiliaryChannel } from '../../models/channels/channels';

export const SignalQualityTopDownHead = ({ signals, auxiliaryChannel }) => {
  const channelPrefix = 'channel_';
  const textPrefix = 'channel_text_';
  let [hiddenChannels, visibleChannels] = bifurcateArrBy(
    Object.keys(CHANNELS),
    isAuxiliaryChannel
  );

  hiddenChannels.push('Fpz');
  hiddenChannels = hiddenChannels.filter(
    channel => channel !== auxiliaryChannel
  );
  const hiddenChannelsStyles = hiddenChannels.reduce(
    (acc, cur) => ({
      ...acc,
      [`${channelPrefix}${cur}`]: {
        fillOpacity: 0,
        strokeOpacity: 0,
        cursor: 'default',
      },
      [`${textPrefix}${cur}`]: {
        fillOpacity: 0,
        strokeOpacity: 0,
        cursor: 'default',
      },
    }),
    {}
  );

  const getVisibleChannelStyle = isNoisy => ({
    fill: isNoisy ? '#ff6e6e' : '#52e8b6 ',
    stroke: isNoisy ? '#ff6e6e' : '#52e8b6 ',
    fillOpacity: 0.4,
  });

  visibleChannels = visibleChannels.filter(channel => channel !== 'Fpz');
  visibleChannels.push(auxiliaryChannel);

  const visibleChannelsStyles = visibleChannels.reduce(
    (acc, cur, index) => ({
      ...acc,
      [`${channelPrefix}${cur}`]: getVisibleChannelStyle(signals[index + 1]),
    }),
    {}
  );

  const channelStyles = {
    ...hiddenChannelsStyles,
    ...visibleChannelsStyles,
  };

  return (
    <div>
      <HeadTopView
        rootStyle={styles.rootstyle_head_top_down_view}
        channelStyles={channelStyles}
      />
    </div>
  );
};

SignalQualityTopDownHead.propTypes = exact({
  signals: PropTypes.array,
  auxiliaryChannel: PropTypes.string,
});

SignalQualityTopDownHead.defaultProps = {
  auxiliaryChannel: null,
  signals: [],
};

import myndliftLogoImage from '../../../assets/logo.png';

export const getLogoImage = async ({ clinicId }) => {
  const isWhiteLabelingAvailable = await database
    .ref(
      `clinicConfig/additionalFeatures/whiteLabeling/${clinicId}/isAvailable`
    )
    .once('value')
    .then(snapshot => snapshot.val());

  if (isWhiteLabelingAvailable) {
    const clinicLogoName = await database
      .ref(`appLogoSettings/${clinicId}/logoName`)
      .once('value')
      .then(snapshot => snapshot.val());

    if (clinicLogoName) {
      // eslint-disable-next-line no-undef
      const clinicLogoImage = await firebase
        .storage()
        .ref(`app_logo/${clinicId}/${clinicLogoName}`)
        .getDownloadURL();

      return {
        logoImage: clinicLogoImage,
        customLogo: true,
      };
    }

    return {
      logoImage: null,
      customLogo: true,
    };
  }

  return { logoImage: myndliftLogoImage, customLogo: false };
};

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SymptomsTrackingGraph_size_17{font-size:17px}.SymptomsTrackingGraph_size_10{font-size:10px}.SymptomsTrackingGraph_size_11{font-size:11px}.SymptomsTrackingGraph_size_13{font-size:13px}.SymptomsTrackingGraph_size_15{font-size:15px}.SymptomsTrackingGraph_size_14{font-size:14px}.SymptomsTrackingGraph_size_16{font-size:16px}.SymptomsTrackingGraph_size_12{font-size:12px}.SymptomsTrackingGraph_extra_header{font-size:18px;font-weight:500;color:#000000}.SymptomsTrackingGraph_header{font-size:17px;font-weight:500;color:#000000}.SymptomsTrackingGraph_header_only_font_size{font-size:17px;font-weight:500}.SymptomsTrackingGraph_sub_header{font-size:13px;color:#878787}.SymptomsTrackingGraph_sub_header_black{font-size:13px;color:black}.SymptomsTrackingGraph_sub_header_only_font_size{font-size:13px}.SymptomsTrackingGraph_paragraph_only_font_size{font-size:12px}.SymptomsTrackingGraph_tab_font_size{font-size:13px}.SymptomsTrackingGraph_pop_out_header{font-size:20px}.SymptomsTrackingGraph_title{font-size:15px;font-weight:500;color:#484848}.SymptomsTrackingGraph_title_black{font-size:15px;font-weight:500;color:black}.SymptomsTrackingGraph_title_only_font_size{font-size:15px}.SymptomsTrackingGraph_paragraph,.SymptomsTrackingGraph_legend_name{font-size:12px;color:#484848}.SymptomsTrackingGraph_btn{font-size:14px}.SymptomsTrackingGraph_legend{display:flex}.SymptomsTrackingGraph_legend_container{display:flex;flex-wrap:wrap;margin-top:10px;margin-left:30px;justify-content:center}@media only screen and (max-width: 768px){.SymptomsTrackingGraph_legend_container{margin-left:12px;justify-content:flex-start}}.SymptomsTrackingGraph_legend_circle{width:15px;height:15px;border-radius:50%;margin-right:10px;margin-left:10px;margin-top:3px;flex-shrink:0}.SymptomsTrackingGraph_questionnaires_select{max-width:260px;margin-bottom:30px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "SymptomsTrackingGraph_size_17",
	"size_10": "SymptomsTrackingGraph_size_10",
	"size_11": "SymptomsTrackingGraph_size_11",
	"size_13": "SymptomsTrackingGraph_size_13",
	"size_15": "SymptomsTrackingGraph_size_15",
	"size_14": "SymptomsTrackingGraph_size_14",
	"size_16": "SymptomsTrackingGraph_size_16",
	"size_12": "SymptomsTrackingGraph_size_12",
	"extra_header": "SymptomsTrackingGraph_extra_header",
	"header": "SymptomsTrackingGraph_header",
	"header_only_font_size": "SymptomsTrackingGraph_header_only_font_size",
	"sub_header": "SymptomsTrackingGraph_sub_header",
	"sub_header_black": "SymptomsTrackingGraph_sub_header_black",
	"sub_header_only_font_size": "SymptomsTrackingGraph_sub_header_only_font_size",
	"paragraph_only_font_size": "SymptomsTrackingGraph_paragraph_only_font_size",
	"tab_font_size": "SymptomsTrackingGraph_tab_font_size",
	"pop_out_header": "SymptomsTrackingGraph_pop_out_header",
	"title": "SymptomsTrackingGraph_title",
	"title_black": "SymptomsTrackingGraph_title_black",
	"title_only_font_size": "SymptomsTrackingGraph_title_only_font_size",
	"paragraph": "SymptomsTrackingGraph_paragraph",
	"legend_name": "SymptomsTrackingGraph_legend_name",
	"btn": "SymptomsTrackingGraph_btn",
	"legend": "SymptomsTrackingGraph_legend",
	"legend_container": "SymptomsTrackingGraph_legend_container",
	"legend_circle": "SymptomsTrackingGraph_legend_circle",
	"questionnaires_select": "SymptomsTrackingGraph_questionnaires_select"
};
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { string as yubString } from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, TextField, Switch } from '@material-ui/core';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './ShareReportDialog.scss';
import { paragraphFontSize } from '../../../cssInJs/constants';

const emailSchema = yubString()
  .email()
  .required();

export const ShareReportDialog = ({
  isOpen,
  onClose,
  patientName,
  reportLink,
  onCopyLinkToClipboard,
  onNotifyByEmail,
  patientEmail,
  onPatientEmailChange,
  // onViewReport,
  // shouldShowViewReportBtn,
  onShareInAppWithClientChange,
  shareInAppWithClient,
  shouldHideShareInAppToggle,
  shouldShowNameOnTitle,
}) => {
  const t = useTranslation();
  const linkInputRef = useRef(null);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span className={styles.dialog_header}>
          {shouldShowNameOnTitle
            ? `Share the report with ${patientName}!`
            : 'Report generated successfully!'}
        </span>
      </DialogTitle>
      <DialogContent>
        <div>
          <p className={styles.dialog_text} style={{ marginBottom: 10 }}>
            You can choose to share it with {patientName} via the following
            options:
          </p>
        </div>
        {!shouldHideShareInAppToggle ? (
          <div>
            <span style={{ fontWeight: 500 }}>Share in-app with client</span>
            <Switch
              onChange={onShareInAppWithClientChange}
              checked={shareInAppWithClient}
              color="primary"
            />
          </div>
        ) : null}
        <div className={styles.input_container}>
          <div>
            <FormControl
              classes={{
                root: styles.form_control,
              }}
            >
              <TextField
                value={reportLink}
                inputRef={linkInputRef}
                margin="normal"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: paragraphFontSize,
                    lineHeight: 1.5,
                  },
                }}
              />
            </FormControl>
          </div>
          <div>
            <Button
              onClick={() => onCopyLinkToClipboard(linkInputRef.current)}
              variant="contained"
              color="primary"
            >
              <span className={styles.button}>Copy to Clipboard</span>
            </Button>
          </div>
        </div>

        <div className={styles.input_container}>
          <div>
            <FormControl
              classes={{
                root: styles.form_control,
              }}
            >
              <TextField
                value={patientEmail}
                onChange={onPatientEmailChange}
                margin="normal"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: paragraphFontSize,
                    lineHeight: 1.5,
                  },
                }}
                error={!emailSchema.isValidSync(patientEmail)}
              />
            </FormControl>
          </div>
          <div>
            <Button
              onClick={onNotifyByEmail}
              variant="contained"
              color="primary"
              disabled={!emailSchema.isValidSync(patientEmail)}
            >
              <span className={styles.button}>Notify via Email</span>
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          <span className={styles.button}>{t('done')}</span>
        </Button>
        {/* {shouldShowViewReportBtn && (
          <Button onClick={onViewReport} variant="contained" color="primary">
            <span className={styles.button}>{t('view_report')}</span>
          </Button>
        )} */}
      </DialogActions>
    </Dialog>
  );
};
// ShareReportDialog.defaultProps = {
//   shouldShowViewReportBtn: true,
// };

ShareReportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  reportLink: PropTypes.string,
  onCopyLinkToClipboard: PropTypes.func.isRequired,
  onNotifyByEmail: PropTypes.func.isRequired,
  patientEmail: PropTypes.string.isRequired,
  onPatientEmailChange: PropTypes.func.isRequired,
  // onViewReport: PropTypes.func,
  // shouldShowViewReportBtn: PropTypes.bool,
  onShareInAppWithClientChange: PropTypes.func,
  shareInAppWithClient: PropTypes.bool,
  shouldHideShareInAppToggle: PropTypes.bool,
  shouldShowNameOnTitle: PropTypes.bool,
};

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import RedoIcon from '@material-ui/icons/Redo';
import { injectStripe } from 'react-stripe-elements';
import dayJS from 'dayjs';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import styles from './OutstandingPaymentPopUp.scss';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { CreditCardDetailsDialogWithValidation } from '../../CreditCardDetailsDialog/CreditCardDetailsDialogWithValidation';
import doneAnimationImageSrc from '../../../../assets/done-animation.gif';
import failedImageSrc from '../../../../assets/failed-circle.png';

const OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS = {
  info: 'info',
  paymentFailed: 'paymentFailed',
  paymentSucceeded: 'paymentSucceeded',
  paymentMightHaveSucceededPartially: 'paymentMightHaveSucceededPartially',
  loadingRetryRecharge: 'loadingRetryRecharge',
};

const OutstandingPaymentPopUpBase = ({ stripe }) => {
  const clinicId = sessionStorage.userId;
  const isTeamMember = sessionStorage.isTeamMember === 'true';

  const [outstandingPaymentAmount] = useFirebaseDB({
    path: `billing/currentPackages/${clinicId}/outstandingPaymentsAmount`,
    onceListener: true,
  });

  const [isDormantPlan] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${clinicId}/isDormantPlan`,
    onceListener: true,
  });

  const [isCCRequired] = useFirebaseDB({
    path: `userInfo/${clinicId}/isCCRequired`,
    onceListener: true,
  });

  const [
    currentOutstandingPaymentAmount,
    setCurrentOutstandingPaymentAmount,
  ] = useState(null);

  const [last4Digits, setLast4Digits] = useState(null);
  const [didSuccsfullRetryCharge, setDidSuccsfullRetryCharge] = useState(false);

  const [currentPackage] = useFirebaseDB({
    path: `billing/currentPackages/${clinicId}`,
    onceListener: true,
  });

  const currentPackageEndDate = dayJS(
    currentPackage && currentPackage.expiration
  ).format(DAY_JS_DATE_FORMATS.dayMonthYearSlashes);

  const [
    outstandingPaymentDialogRenderedStep,
    setOutstandingPaymentDialogRenderedStep,
  ] = useState(null);

  const [
    creditCardDetailsRenderedStep,
    setCreditCardDetailsRenderedStep,
  ] = useState(null);

  const onCreditCardDetailsDialogConfirm = useCallback(() => {
    const handleSuccessfulRegisterPayment = async () => {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded
      );
      setCreditCardDetailsRenderedStep(null);
      new MyEvent('clinic_payment_failed_popup').log({
        type: 'blocked',
        action: 'edit_success',
      });
    };

    const handleFailedRegisterPayment = () => {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
      );
      setCreditCardDetailsRenderedStep(null);
      new MyEvent('clinic_payment_failed_popup').log({
        type: 'blocked',
        action: 'edit_failed',
      });
    };

    const handlePossiblePartialSuccessfulPayment = () => {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentMightHaveSucceededPartially
      );
      setCreditCardDetailsRenderedStep(null);
    };

    setCreditCardDetailsRenderedStep('loading');
    stripe.createToken().then(async result => {
      if (result.error) {
        if (result.error.type === 'validation_error') {
          setCreditCardDetailsRenderedStep('cardDetailsInput');
        } else {
          handleFailedRegisterPayment();
        }
      } else {
        try {
          // eslint-disable-next-line no-undef
          const registerPaymentMethodResponse = await fireFunctionPost(
            'billing-registerPaymentMethod',
            {
              details: result,
            }
          );
          // eslint-disable-next-line no-undef
          const retryOutstandingInvoicesResponse = await fireFunctionPost(
            'billing-retryOutstandingInvoices'
          );

          if (
            registerPaymentMethodResponse.result === true &&
            retryOutstandingInvoicesResponse.result === true
          ) {
            if (retryOutstandingInvoicesResponse.totalDue === 0) {
              handleSuccessfulRegisterPayment();
            } else {
              setCurrentOutstandingPaymentAmount(
                retryOutstandingInvoicesResponse.totalDue
              );
              handlePossiblePartialSuccessfulPayment();
            }
          } else {
            handleFailedRegisterPayment();
          }
        } catch (err) {
          handleFailedRegisterPayment();
        }
      }
    });
  }, [stripe]);

  useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('billing-paymentSourceInfo');
        setLast4Digits(data.cardInfo.last4);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    })();
  }, [last4Digits]);

  useEffect(() => {
    if (outstandingPaymentAmount !== null) {
      setCurrentOutstandingPaymentAmount(outstandingPaymentAmount);
    }
  }, [outstandingPaymentAmount]);

  useEffect(() => {
    if (
      stripe !== null &&
      currentPackage !== null &&
      outstandingPaymentAmount !== null &&
      outstandingPaymentAmount > 0
    ) {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info
      );
    }
  }, [currentPackage, outstandingPaymentAmount, stripe]);

  useEffect(() => {
    new MyEvent('clinic_payment_failed_popup').log({
      type: 'blocked',
      action: 'seen',
    });
  }, []);

  const onCloseOutstandingPaymentDialog = useCallback(
    () => setOutstandingPaymentDialogRenderedStep(null),
    []
  );

  const onEditPaymentMethod = useCallback(() => {
    new MyEvent('clinic_payment_failed_popup').log({
      type: 'blocked',
      action: 'edit_clicked',
    });
    setCreditCardDetailsRenderedStep('cardDetailsInput');
  }, []);

  const onRetryCharge = async () => {
    new MyEvent('clinic_payment_failed_popup').log({
      type: 'blocked',
      action: 'retry_clicked',
    });
    setOutstandingPaymentDialogRenderedStep(
      OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.loadingRetryRecharge
    );
    try {
      // eslint-disable-next-line no-undef
      const data = await fireFunctionPost('billing-retryCharge');
      if (data.result === true) {
        setDidSuccsfullRetryCharge(true);
        setOutstandingPaymentDialogRenderedStep(
          OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded
        );
        new MyEvent('clinic_payment_failed_popup').log({
          type: 'blocked',
          action: 'retry_success',
        });
      } else {
        setOutstandingPaymentDialogRenderedStep(
          OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
        );
        new MyEvent('clinic_payment_failed_popup').log({
          type: 'blocked',
          action: 'retry_failed',
        });
      }
    } catch (e) {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
      );
      new MyEvent('clinic_payment_failed_popup').log({
        type: 'blocked',
        action: 'retry_failed',
      });
    }
  };

  const renderOutstandingPaymentDialogContent = () => {
    switch (outstandingPaymentDialogRenderedStep) {
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info:
        return (
          <div className={styles.content}>
            <h3>Account blocked due to outstanding invoices</h3>
            {isTeamMember ? (
              <p>
                We have restricted the access to Myndlift due to billing errors.
                Please contact your account administrator to update this
                account's payment method.
              </p>
            ) : (
              <React.Fragment>
                <p>
                  {// eslint-disable-next-line  no-nested-ternary
                  !isDormantPlan
                    ? `Your account has been blocked due an invalid payment of your US$${currentOutstandingPaymentAmount} outstanding balance. Failure to pay outstanding invoices by ${currentPackageEndDate} will cause your subscription and all deployed sessions to be canceled.`
                    : isCCRequired
                    ? `Your account has been blocked and all deployed sessions were canceled due an invalid payment of your $${currentOutstandingPaymentAmount} outstanding balance.`
                    : `Your account has been blocked due an invalid payment of your $${currentOutstandingPaymentAmount} outstanding balance. Failure to pay outstanding invoices by ${currentPackageEndDate} will cause all deployed sessions to be canceled.`}
                </p>
                <p>
                  {last4Digits
                    ? `Please retry your card ending with ${last4Digits} or provide an alternative payment method.`
                    : null}
                </p>
              </React.Fragment>
            )}
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded:
        return (
          <div className={styles.info_container}>
            <Avatar
              alt="done"
              src={doneAnimationImageSrc}
              className={styles.avatar}
            />

            <p className={styles.info_text}>
              {didSuccsfullRetryCharge
                ? `Credit card ending with ${last4Digits} was charged and the payment was processed successfully.`
                : 'Credit card was changed and payment was processed successfully'}
            </p>
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed:
        return (
          <div className={styles.info_container}>
            <Avatar alt="done" src={failedImageSrc} className={styles.avatar} />

            <p className={styles.info_text}>Payment failed!</p>
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentMightHaveSucceededPartially:
        return (
          <div className={styles.info_container}>
            <Avatar alt="done" src={failedImageSrc} className={styles.avatar} />

            <p className={styles.info_text}>
              Could not close all outstanding invoices, amount due is: US$
              {currentOutstandingPaymentAmount}.
            </p>
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.loadingRetryRecharge:
        return (
          <div className={styles.info_container}>
            <CircularProgress />
          </div>
        );
      default:
        return null;
    }
  };

  const renderOutstandingPaymentDialogActions = () => {
    switch (outstandingPaymentDialogRenderedStep) {
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info:
        return (
          !isTeamMember && (
            <>
              <Button
                color="primary"
                autoFocus
                variant="contained"
                onClick={onRetryCharge}
                style={{ marginRight: 10 }}
              >
                <span className={styles.btn}>
                  <RedoIcon
                    style={{
                      fontSize: 18,
                      position: 'relative',
                      top: 3,
                      right: 5,
                    }}
                  />
                  Retry charge
                </span>
              </Button>
              <Button
                color="primary"
                autoFocus
                variant="contained"
                onClick={onEditPaymentMethod}
              >
                <span className={styles.btn}>Edit payment method</span>
              </Button>
            </>
          )
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded:
        return (
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={onCloseOutstandingPaymentDialog}
          >
            <span className={styles.btn}>done</span>
          </Button>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentMightHaveSucceededPartially:
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed:
        return (
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={onEditPaymentMethod}
          >
            <span className={styles.btn}>Edit payment method</span>
          </Button>
        );
      default:
        return null;
    }
  };

  if (sessionStorage.isLogged === 'true') {
    return (
      <React.Fragment>
        <Dialog open={!!outstandingPaymentDialogRenderedStep}>
          <DialogContent>
            {renderOutstandingPaymentDialogContent()}
          </DialogContent>
          <DialogActions>
            {renderOutstandingPaymentDialogActions()}
          </DialogActions>
        </Dialog>

        <CreditCardDetailsDialogWithValidation
          isOpen={!!creditCardDetailsRenderedStep}
          renderedStep={creditCardDetailsRenderedStep}
          onConfirm={onCreditCardDetailsDialogConfirm}
          dialogHeader="Update Your Credit Card"
          isSecondaryBtnShown={false}
          isCloseIconShown={false}
        />
      </React.Fragment>
    );
  }

  return null;
};

OutstandingPaymentPopUpBase.propTypes = exact({
  stripe: PropTypes.object,
});

export const OutstandingPaymentPopUp = React.memo(
  injectStripe(OutstandingPaymentPopUpBase)
);
OutstandingPaymentPopUp.displayName = 'OutstandingPaymentPopUp';

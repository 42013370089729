exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DiscardReportDialog_size_17{font-size:17px}.DiscardReportDialog_size_10{font-size:10px}.DiscardReportDialog_size_11{font-size:11px}.DiscardReportDialog_size_13{font-size:13px}.DiscardReportDialog_size_15{font-size:15px}.DiscardReportDialog_size_14{font-size:14px}.DiscardReportDialog_size_16{font-size:16px}.DiscardReportDialog_size_12{font-size:12px}.DiscardReportDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.DiscardReportDialog_header,.DiscardReportDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.DiscardReportDialog_header_only_font_size{font-size:17px;font-weight:500}.DiscardReportDialog_sub_header{font-size:13px;color:#878787}.DiscardReportDialog_sub_header_black{font-size:13px;color:black}.DiscardReportDialog_sub_header_only_font_size{font-size:13px}.DiscardReportDialog_paragraph_only_font_size{font-size:12px}.DiscardReportDialog_tab_font_size{font-size:13px}.DiscardReportDialog_pop_out_header{font-size:20px}.DiscardReportDialog_title{font-size:15px;font-weight:500;color:#484848}.DiscardReportDialog_title_black{font-size:15px;font-weight:500;color:black}.DiscardReportDialog_title_only_font_size{font-size:15px}.DiscardReportDialog_paragraph,.DiscardReportDialog_dialog_text{font-size:12px;color:#484848}.DiscardReportDialog_btn,.DiscardReportDialog_button{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "DiscardReportDialog_size_17",
	"size_10": "DiscardReportDialog_size_10",
	"size_11": "DiscardReportDialog_size_11",
	"size_13": "DiscardReportDialog_size_13",
	"size_15": "DiscardReportDialog_size_15",
	"size_14": "DiscardReportDialog_size_14",
	"size_16": "DiscardReportDialog_size_16",
	"size_12": "DiscardReportDialog_size_12",
	"extra_header": "DiscardReportDialog_extra_header",
	"header": "DiscardReportDialog_header",
	"dialog_header": "DiscardReportDialog_dialog_header",
	"header_only_font_size": "DiscardReportDialog_header_only_font_size",
	"sub_header": "DiscardReportDialog_sub_header",
	"sub_header_black": "DiscardReportDialog_sub_header_black",
	"sub_header_only_font_size": "DiscardReportDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "DiscardReportDialog_paragraph_only_font_size",
	"tab_font_size": "DiscardReportDialog_tab_font_size",
	"pop_out_header": "DiscardReportDialog_pop_out_header",
	"title": "DiscardReportDialog_title",
	"title_black": "DiscardReportDialog_title_black",
	"title_only_font_size": "DiscardReportDialog_title_only_font_size",
	"paragraph": "DiscardReportDialog_paragraph",
	"dialog_text": "DiscardReportDialog_dialog_text",
	"btn": "DiscardReportDialog_btn",
	"button": "DiscardReportDialog_button"
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import { Collapse } from '@material-ui/core';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../cssInJs/constants';

const ListingItemBase = ({
  listing,
  onSendMsg,
  rootClassName,
  customChipClassName,
  extraContent,
  styles,
  logEvent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isBeingAnimated, setIsBeingAnimated] = useState(false);

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  const CustomChip = ({ label }) => (
    <div className={customChipClassName}>
      <span>{label}</span>
    </div>
  );
  CustomChip.propTypes = {
    label: PropTypes.string.isRequired,
  };

  return (
    <div
      key={listing.listingId}
      onClick={() => {
        const action = isExpanded ? 'Hide Profile' : 'View Profile';
        logEvent('providers_search_page_on_card_button_click', {
          action,
        });
        setIsExpanded(prvIsExpanded => !prvIsExpanded);
      }}
      className={rootClassName}
    >
      {extraContent && (
        <div className={styles.mobile_extra_content}>{extraContent}</div>
      )}
      <div className={styles.image_info_address}>
        <div className={styles.info_and_image}>
          <div className={styles.clinic_image}>
            <img src={listing.imageSrc} />
          </div>
          <div className={styles.clinic_info_mobile}>
            <p className={styles.name}>
              {listing.firstName} {listing.lastName}
            </p>
            <p className={styles.clinic_name}>{listing.clinicName}</p>
            <div className={styles.divider}> </div>
            <div className={styles.clinic_address_mobile_in_info}>
              <p>
                {listing.state}, {listing.country}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.break_mobile} />

        <div className={styles.break_mobile} />
        <div
          className={styles.clinic_info}
          style={{ marginBottom: isExpanded ? 25 : 10 }}
        >
          <p className={styles.name}>
            {listing.firstName} {listing.lastName}
          </p>
          <p className={styles.clinic_name}>{listing.clinicName}</p>
          <Collapse
            in={isExpanded}
            collapsedHeight="70px"
            onEnter={() => setIsBeingAnimated(true)}
            onExited={() => setIsBeingAnimated(false)}
          >
            <p
              data-internal-role="about-text"
              className={classNames({
                [styles.hide_text]: !isExpanded && !isBeingAnimated,
              })}
              style={{
                maxWidth: 462,
              }}
            >
              {listing.biography}
            </p>
          </Collapse>
        </div>

        <div className={styles.clinic_address}>
          {extraContent && (
            <div className={styles.extra_content}>{extraContent}</div>
          )}
          <p>{listing.address}</p>
          <p>
            {listing.city}, {listing.state}
          </p>
          <p>
            {listing.zipCode}, {listing.country}
          </p>
        </div>
      </div>

      <div className={styles.tags_buttons}>
        <div className={styles.all_tags_container}>
          <div
            className={classNames({
              [styles.no_margin_bottom]: !isExpanded,
            })}
          >
            {(!isMatchingXSScreen || isExpanded) && (
              <div className={styles.label_container}>
                <p>Specialties:</p>
              </div>
            )}
            <div className={styles.break_mobile} />
            <div className={styles.tags_container}>
              {listing.topSpecialties.map(specialty => (
                <CustomChip label={specialty} key={specialty} />
              ))}
            </div>
          </div>

          <div
            className={classNames(styles.all_tags_container)}
            style={isExpanded ? {} : { height: 0, overflow: 'hidden' }}
          >
            <div>
              <div className={styles.label_container}>
                <p>More Specialties:</p>
              </div>
              <div className={styles.break_mobile} />
              <div className={styles.tags_container}>
                {listing.otherSpecialties.map(specialty => (
                  <CustomChip label={specialty} key={specialty} />
                ))}
              </div>
            </div>
            <div>
              <div className={styles.label_container}>
                <p>Languages:</p>
              </div>
              <div className={styles.break_mobile} />
              <div className={styles.tags_container}>
                {listing.languages.map(language => (
                  <CustomChip label={language} key={language} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div>
          <p>{isExpanded ? 'Hide Profile' : 'View Profile'}</p>
          {onSendMsg && (
            <button
              type="button"
              onClick={onSendMsg}
              style={{ textTransform: 'capitalize' }}
            >
              Send Message
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ListingItemBase.propTypes = exact({
  listing: PropTypes.object.isRequired,
  onSendMsg: PropTypes.func,
  rootClassName: PropTypes.string,
  customChipClassName: PropTypes.string,
  extraContent: PropTypes.node,
  styles: PropTypes.object,
  logEvent: PropTypes.func,
});

export const ListingItem = React.memo(ListingItemBase);
ListingItem.displayName = 'ListingItem';

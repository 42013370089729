exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SwitchToNewSymptomTrackerDialog_size_17{font-size:17px}.SwitchToNewSymptomTrackerDialog_size_10{font-size:10px}.SwitchToNewSymptomTrackerDialog_size_11{font-size:11px}.SwitchToNewSymptomTrackerDialog_size_13{font-size:13px}.SwitchToNewSymptomTrackerDialog_size_15{font-size:15px}.SwitchToNewSymptomTrackerDialog_size_14{font-size:14px}.SwitchToNewSymptomTrackerDialog_size_16{font-size:16px}.SwitchToNewSymptomTrackerDialog_size_12{font-size:12px}.SwitchToNewSymptomTrackerDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.SwitchToNewSymptomTrackerDialog_header,.SwitchToNewSymptomTrackerDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.SwitchToNewSymptomTrackerDialog_header_only_font_size{font-size:17px;font-weight:500}.SwitchToNewSymptomTrackerDialog_sub_header{font-size:13px;color:#878787}.SwitchToNewSymptomTrackerDialog_sub_header_black{font-size:13px;color:black}.SwitchToNewSymptomTrackerDialog_sub_header_only_font_size{font-size:13px}.SwitchToNewSymptomTrackerDialog_paragraph_only_font_size{font-size:12px}.SwitchToNewSymptomTrackerDialog_tab_font_size{font-size:13px}.SwitchToNewSymptomTrackerDialog_pop_out_header{font-size:20px}.SwitchToNewSymptomTrackerDialog_title{font-size:15px;font-weight:500;color:#484848}.SwitchToNewSymptomTrackerDialog_title_black{font-size:15px;font-weight:500;color:black}.SwitchToNewSymptomTrackerDialog_title_only_font_size{font-size:15px}.SwitchToNewSymptomTrackerDialog_paragraph{font-size:12px;color:#484848}.SwitchToNewSymptomTrackerDialog_btn{font-size:14px}.SwitchToNewSymptomTrackerDialog_dialog_header{margin-bottom:28px}.SwitchToNewSymptomTrackerDialog_dialog_header.SwitchToNewSymptomTrackerDialog_no_margin{margin-bottom:0}\n", ""]);

// Exports
exports.locals = {
	"size_17": "SwitchToNewSymptomTrackerDialog_size_17",
	"size_10": "SwitchToNewSymptomTrackerDialog_size_10",
	"size_11": "SwitchToNewSymptomTrackerDialog_size_11",
	"size_13": "SwitchToNewSymptomTrackerDialog_size_13",
	"size_15": "SwitchToNewSymptomTrackerDialog_size_15",
	"size_14": "SwitchToNewSymptomTrackerDialog_size_14",
	"size_16": "SwitchToNewSymptomTrackerDialog_size_16",
	"size_12": "SwitchToNewSymptomTrackerDialog_size_12",
	"extra_header": "SwitchToNewSymptomTrackerDialog_extra_header",
	"header": "SwitchToNewSymptomTrackerDialog_header",
	"dialog_header": "SwitchToNewSymptomTrackerDialog_dialog_header",
	"header_only_font_size": "SwitchToNewSymptomTrackerDialog_header_only_font_size",
	"sub_header": "SwitchToNewSymptomTrackerDialog_sub_header",
	"sub_header_black": "SwitchToNewSymptomTrackerDialog_sub_header_black",
	"sub_header_only_font_size": "SwitchToNewSymptomTrackerDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "SwitchToNewSymptomTrackerDialog_paragraph_only_font_size",
	"tab_font_size": "SwitchToNewSymptomTrackerDialog_tab_font_size",
	"pop_out_header": "SwitchToNewSymptomTrackerDialog_pop_out_header",
	"title": "SwitchToNewSymptomTrackerDialog_title",
	"title_black": "SwitchToNewSymptomTrackerDialog_title_black",
	"title_only_font_size": "SwitchToNewSymptomTrackerDialog_title_only_font_size",
	"paragraph": "SwitchToNewSymptomTrackerDialog_paragraph",
	"btn": "SwitchToNewSymptomTrackerDialog_btn",
	"no_margin": "SwitchToNewSymptomTrackerDialog_no_margin"
};
import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const WrapConditionallyBase = ({ condition, wrap, children }) => {
  return condition ? wrap(<>{children}</>) : children;
};

WrapConditionallyBase.propTypes = exact({
  condition: PropTypes.bool,
  wrap: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
});

export const WrapConditionally = React.memo(WrapConditionallyBase);
WrapConditionally.displayName = 'WrapConditionally';

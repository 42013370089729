exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CustomAlert_size_17{font-size:17px}.CustomAlert_size_10{font-size:10px}.CustomAlert_size_11{font-size:11px}.CustomAlert_size_13{font-size:13px}.CustomAlert_size_15{font-size:15px}.CustomAlert_size_14{font-size:14px}.CustomAlert_size_16{font-size:16px}.CustomAlert_size_12{font-size:12px}.CustomAlert_extra_header{font-size:18px;font-weight:500;color:#000000}.CustomAlert_header{font-size:17px;font-weight:500;color:#000000}.CustomAlert_header_only_font_size{font-size:17px;font-weight:500}.CustomAlert_sub_header{font-size:13px;color:#878787}.CustomAlert_sub_header_black{font-size:13px;color:black}.CustomAlert_sub_header_only_font_size{font-size:13px}.CustomAlert_paragraph_only_font_size,.CustomAlert_root{font-size:12px}.CustomAlert_tab_font_size{font-size:13px}.CustomAlert_pop_out_header{font-size:20px}.CustomAlert_title{font-size:15px;font-weight:500;color:#484848}.CustomAlert_title_black{font-size:15px;font-weight:500;color:black}.CustomAlert_title_only_font_size{font-size:15px}.CustomAlert_paragraph{font-size:12px;color:#484848}.CustomAlert_btn{font-size:14px}.CustomAlert_root{padding:6px 16px;font-weight:500;border-radius:4px;display:flex}.CustomAlert_root>p{margin:0px 0px;align-self:center}.CustomAlert_root.CustomAlert_warning{background:#fdf2cc;color:#bf9000}.CustomAlert_root.CustomAlert_info{background:#6d9eeb;color:white}.CustomAlert_root.CustomAlert_error{background:#FFC4C4;color:#C80000}.CustomAlert_root.CustomAlert_success{background:#e5fffc;color:#17b7a6}\n", ""]);

// Exports
exports.locals = {
	"size_17": "CustomAlert_size_17",
	"size_10": "CustomAlert_size_10",
	"size_11": "CustomAlert_size_11",
	"size_13": "CustomAlert_size_13",
	"size_15": "CustomAlert_size_15",
	"size_14": "CustomAlert_size_14",
	"size_16": "CustomAlert_size_16",
	"size_12": "CustomAlert_size_12",
	"extra_header": "CustomAlert_extra_header",
	"header": "CustomAlert_header",
	"header_only_font_size": "CustomAlert_header_only_font_size",
	"sub_header": "CustomAlert_sub_header",
	"sub_header_black": "CustomAlert_sub_header_black",
	"sub_header_only_font_size": "CustomAlert_sub_header_only_font_size",
	"paragraph_only_font_size": "CustomAlert_paragraph_only_font_size",
	"root": "CustomAlert_root",
	"tab_font_size": "CustomAlert_tab_font_size",
	"pop_out_header": "CustomAlert_pop_out_header",
	"title": "CustomAlert_title",
	"title_black": "CustomAlert_title_black",
	"title_only_font_size": "CustomAlert_title_only_font_size",
	"paragraph": "CustomAlert_paragraph",
	"btn": "CustomAlert_btn",
	"warning": "CustomAlert_warning",
	"info": "CustomAlert_info",
	"error": "CustomAlert_error",
	"success": "CustomAlert_success"
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from './VerticalMoreMenu.scss';

const VerticalMoreMenuBase = ({ menuItems }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => setAnchorElement(event.currentTarget)}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={!!anchorElement}
        onClose={() => setAnchorElement(null)}
      >
        {menuItems.map(item => (
          <MenuItem onClick={item.onClick} key={item.key}>
            <span className={styles.item_text}>{item.text}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

VerticalMoreMenuBase.propTypes = exact({
  menuItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
});

export const VerticalMoreMenu = React.memo(VerticalMoreMenuBase);
VerticalMoreMenu.displayName = 'VerticalMoreMenu';

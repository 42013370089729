import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
} from 'recharts';
import regression from 'regression';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import { DeepEqualityComponent } from '../../../Core/DeepEqualityComponent/DeepEqualityComponent';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { graphHeight } from '../constants';
import { ClickableLegendItems } from '../../ClickableLegendItems/ClickableLegendItems';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';

const colors = {
  zone: '#21e5d7',
  deepZone: '#6fa0f0',
  trend: '#8884d8',
};

class ZoneGraphBase extends DeepEqualityComponent {
  render() {
    const { data, legendItems, t, onLegendItemClick, isInPreview } = this.props;

    const trendLineDataPoints = data.map((dataObject, index) => [
      index,
      (legendItems.zone ? dataObject.zonePercentage : 0) +
        (legendItems.deepZone ? dataObject.deepZonePercentage : 0),
    ]);

    const trendLineDataPointsRegressionResult = regression.linear([
      ...trendLineDataPoints,
    ]);

    const trendLineData = trendLineDataPointsRegressionResult.points.map(
      points => ({
        Trend: points[1],
      })
    );

    const dataWithTrendLineData = data.map((dataObject, index) => ({
      ...dataObject,
      Trend: trendLineData[index].Trend,
    }));

    return (
      <React.Fragment>
        <ResponsiveContainer width="100%" height={graphHeight}>
          <ComposedChart
            data={dataWithTrendLineData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickMargin={7}
              tick={{ fontSize: 12 }}
              tickFormatter={tick => tick.slice(0, -5)}
            />
            <YAxis
              ticks={[20, 40, 60, 80, 100]}
              tickFormatter={tick => `${tick}%`}
              tickMargin={7}
              allowDataOverflow
              domain={[0, 'dataMax']}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              formatter={value =>
                value === 0 ? '0%' : `${parseFloat(value, 10).toFixed(2)}%`
              }
            />

            <Bar
              key="deepZonePercentage"
              dataKey="deepZonePercentage"
              stackId="1"
              fill={colors.deepZone}
              hide={!legendItems.deepZone}
              name={t('deep_zone_label')}
              maxBarSize={90}
            />
            <Bar
              key="zonePercentage"
              dataKey="zonePercentage"
              stackId="1"
              fill={colors.zone}
              hide={!legendItems.zone}
              name={t('zone_label')}
              maxBarSize={90}
            />
            <Line
              type="monotone"
              dataKey="Trend"
              stroke={colors.trend}
              hide={
                !legendItems.trend ||
                (!legendItems.zone && !legendItems.deepZone)
              }
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>

        <ClickableLegendItems
          legendItems={legendItems}
          legendItemsKeys={['zone', 'deepZone', 'trend']}
          onLegendItemClick={onLegendItemClick}
          isInPreview={isInPreview}
          colors={colors}
          legendItemFormatter={legendItem =>
            (legendItem === 'zone' && t('zone_label')) ||
            (legendItem === 'deepZone' && t('deep_zone_label')) ||
            (legendItem === 'trend' && 'Trend')
          }
        />
      </React.Fragment>
    );
  }
}

ZoneGraphBase.defaultProps = {
  isInPreview: false,
  onLegendItemClick: () => {},
};

ZoneGraphBase.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      zonePercentage: PropTypes.number.isRequired,
      deepZonePercentage: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
  legendItems: PropTypes.object.isRequired,
  onLegendItemClick: PropTypes.func,
  isInPreview: PropTypes.bool,
};

export const ZoneGraph = withTranslation(ZoneGraphBase);

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './UserInfoAvatar.scss';
import museOnMaleHeadPNG from '../../../assets/muse-on-male-head.png';
import museOnFemaleHeadPNG from '../../../assets/muse-on-female-head.png';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';

const UserInfoAvatarBase = ({ name, gender, email }) => {
  return (
    <div className={styles.user_avatar_container}>
      <img
        className={styles.avatar}
        src={gender === 'Male' ? museOnMaleHeadPNG : museOnFemaleHeadPNG}
      />
      <div>
        <TooltipWrapper
          title={<TooltipTitle>{name}</TooltipTitle>}
          classes={{ tooltip: styles.tooltip }}
        >
          <p className={styles.title_black}>{name}</p>
        </TooltipWrapper>

        <TooltipWrapper
          title={<TooltipTitle>{email}</TooltipTitle>}
          classes={{ tooltip: styles.tooltip }}
        >
          <p>{email}</p>
        </TooltipWrapper>
      </div>
    </div>
  );
};

UserInfoAvatarBase.propTypes = exact({
  name: PropTypes.string.isRequired,
  gender: PropTypes.oneOf(['Male', 'Female']).isRequired,
  email: PropTypes.string.isRequired,
});

export const UserInfoAvatar = React.memo(UserInfoAvatarBase);
UserInfoAvatar.displayName = 'UserInfoAvatar';

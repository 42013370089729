exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AddAdditionalProgramCTA_size_17{font-size:17px}.AddAdditionalProgramCTA_size_10{font-size:10px}.AddAdditionalProgramCTA_size_11{font-size:11px}.AddAdditionalProgramCTA_size_13{font-size:13px}.AddAdditionalProgramCTA_size_15{font-size:15px}.AddAdditionalProgramCTA_size_14{font-size:14px}.AddAdditionalProgramCTA_size_16{font-size:16px}.AddAdditionalProgramCTA_size_12{font-size:12px}.AddAdditionalProgramCTA_extra_header{font-size:18px;font-weight:500;color:#000000}.AddAdditionalProgramCTA_header{font-size:17px;font-weight:500;color:#000000}.AddAdditionalProgramCTA_header_only_font_size{font-size:17px;font-weight:500}.AddAdditionalProgramCTA_sub_header{font-size:13px;color:#878787}.AddAdditionalProgramCTA_sub_header_black{font-size:13px;color:black}.AddAdditionalProgramCTA_sub_header_only_font_size{font-size:13px}.AddAdditionalProgramCTA_paragraph_only_font_size{font-size:12px}.AddAdditionalProgramCTA_tab_font_size{font-size:13px}.AddAdditionalProgramCTA_pop_out_header{font-size:20px}.AddAdditionalProgramCTA_title,.AddAdditionalProgramCTA_main_title>p{font-size:15px;font-weight:500;color:#484848}.AddAdditionalProgramCTA_title_black{font-size:15px;font-weight:500;color:black}.AddAdditionalProgramCTA_title_only_font_size{font-size:15px}.AddAdditionalProgramCTA_paragraph{font-size:12px;color:#484848}.AddAdditionalProgramCTA_btn{font-size:14px}.AddAdditionalProgramCTA_root{padding:30px 30px;border:1px dotted black;display:flex;align-items:center;cursor:pointer}.AddAdditionalProgramCTA_content_container{display:flex;flex-direction:column;justify-content:center;margin-left:20px}.AddAdditionalProgramCTA_content_container p{margin:0px}.AddAdditionalProgramCTA_main_title{margin-right:20px;display:flex;align-items:center}.AddAdditionalProgramCTA_main_title>p{font-weight:600;margin-right:20px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "AddAdditionalProgramCTA_size_17",
	"size_10": "AddAdditionalProgramCTA_size_10",
	"size_11": "AddAdditionalProgramCTA_size_11",
	"size_13": "AddAdditionalProgramCTA_size_13",
	"size_15": "AddAdditionalProgramCTA_size_15",
	"size_14": "AddAdditionalProgramCTA_size_14",
	"size_16": "AddAdditionalProgramCTA_size_16",
	"size_12": "AddAdditionalProgramCTA_size_12",
	"extra_header": "AddAdditionalProgramCTA_extra_header",
	"header": "AddAdditionalProgramCTA_header",
	"header_only_font_size": "AddAdditionalProgramCTA_header_only_font_size",
	"sub_header": "AddAdditionalProgramCTA_sub_header",
	"sub_header_black": "AddAdditionalProgramCTA_sub_header_black",
	"sub_header_only_font_size": "AddAdditionalProgramCTA_sub_header_only_font_size",
	"paragraph_only_font_size": "AddAdditionalProgramCTA_paragraph_only_font_size",
	"tab_font_size": "AddAdditionalProgramCTA_tab_font_size",
	"pop_out_header": "AddAdditionalProgramCTA_pop_out_header",
	"title": "AddAdditionalProgramCTA_title",
	"main_title": "AddAdditionalProgramCTA_main_title",
	"title_black": "AddAdditionalProgramCTA_title_black",
	"title_only_font_size": "AddAdditionalProgramCTA_title_only_font_size",
	"paragraph": "AddAdditionalProgramCTA_paragraph",
	"btn": "AddAdditionalProgramCTA_btn",
	"root": "AddAdditionalProgramCTA_root",
	"content_container": "AddAdditionalProgramCTA_content_container"
};
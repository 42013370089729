exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".VerticalMoreMenu_size_17{font-size:17px}.VerticalMoreMenu_size_10{font-size:10px}.VerticalMoreMenu_size_11{font-size:11px}.VerticalMoreMenu_size_13{font-size:13px}.VerticalMoreMenu_size_15{font-size:15px}.VerticalMoreMenu_size_14{font-size:14px}.VerticalMoreMenu_size_16{font-size:16px}.VerticalMoreMenu_size_12{font-size:12px}.VerticalMoreMenu_extra_header{font-size:18px;font-weight:500;color:#000000}.VerticalMoreMenu_header{font-size:17px;font-weight:500;color:#000000}.VerticalMoreMenu_header_only_font_size{font-size:17px;font-weight:500}.VerticalMoreMenu_sub_header{font-size:13px;color:#878787}.VerticalMoreMenu_sub_header_black{font-size:13px;color:black}.VerticalMoreMenu_sub_header_only_font_size{font-size:13px}.VerticalMoreMenu_paragraph_only_font_size,.VerticalMoreMenu_item_text{font-size:12px}.VerticalMoreMenu_tab_font_size{font-size:13px}.VerticalMoreMenu_pop_out_header{font-size:20px}.VerticalMoreMenu_title{font-size:15px;font-weight:500;color:#484848}.VerticalMoreMenu_title_black{font-size:15px;font-weight:500;color:black}.VerticalMoreMenu_title_only_font_size{font-size:15px}.VerticalMoreMenu_paragraph{font-size:12px;color:#484848}.VerticalMoreMenu_btn{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "VerticalMoreMenu_size_17",
	"size_10": "VerticalMoreMenu_size_10",
	"size_11": "VerticalMoreMenu_size_11",
	"size_13": "VerticalMoreMenu_size_13",
	"size_15": "VerticalMoreMenu_size_15",
	"size_14": "VerticalMoreMenu_size_14",
	"size_16": "VerticalMoreMenu_size_16",
	"size_12": "VerticalMoreMenu_size_12",
	"extra_header": "VerticalMoreMenu_extra_header",
	"header": "VerticalMoreMenu_header",
	"header_only_font_size": "VerticalMoreMenu_header_only_font_size",
	"sub_header": "VerticalMoreMenu_sub_header",
	"sub_header_black": "VerticalMoreMenu_sub_header_black",
	"sub_header_only_font_size": "VerticalMoreMenu_sub_header_only_font_size",
	"paragraph_only_font_size": "VerticalMoreMenu_paragraph_only_font_size",
	"item_text": "VerticalMoreMenu_item_text",
	"tab_font_size": "VerticalMoreMenu_tab_font_size",
	"pop_out_header": "VerticalMoreMenu_pop_out_header",
	"title": "VerticalMoreMenu_title",
	"title_black": "VerticalMoreMenu_title_black",
	"title_only_font_size": "VerticalMoreMenu_title_only_font_size",
	"paragraph": "VerticalMoreMenu_paragraph",
	"btn": "VerticalMoreMenu_btn"
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import doneGif from '../../../assets/done-animation.gif';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import styles from './TeachableIntegrationSignup.scss';

export const TeachableIntegrationSignup = ({ isOpen, onClose }) => {
  const [name, setName] = useState(
    sessionStorage.teamMemberId
      ? `${JSON.parse(sessionStorage.intercomInfo).firstName} ${
          JSON.parse(sessionStorage.intercomInfo).lastName
        }`
      : `${JSON.parse(sessionStorage.userInfo).firstName} ${
          JSON.parse(sessionStorage.userInfo).lastName
        }`
  );
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoadingSignup, setIsLoadingSignup] = useState(false);
  const [isSignedup, setIsSignedup] = useState(false);
  const [signupError, setSignupError] = useState(false);

  const validatePassword = pass => {
    const re = new RegExp(/^[a-z0-9]+$/i);
    if (re.test(pass) && pass.length >= 6 && pass.length <= 12) {
      setIsPasswordValid(true);
      return true;
    }
    setIsPasswordValid(false);
    return false;
  };

  const onSignup = async () => {
    if (!validatePassword(password)) {
      return;
    }
    setIsLoadingSignup(true);
    try {
      // eslint-disable-next-line no-undef
      const response = await fireFunctionPost('teachable-signup', {
        client_id: sessionStorage.teamMemberId
          ? sessionStorage.teamMemberId
          : sessionStorage.userId,
        email: sessionStorage.teamMemberId
          ? JSON.parse(sessionStorage.intercomInfo).email
          : JSON.parse(sessionStorage.userInfo).email,
        name,
        password,
      });
      if (response.result) {
        setIsSignedup(true);
        setIsLoadingSignup(false);
        sessionStorage.isEligibleForTeachableContent = true;
      } else {
        setSignupError(true);
        setIsLoadingSignup(false);
      }
    } catch (e) {
      setSignupError(true);
      setIsLoadingSignup(false);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="sm">
      {!isSignedup ? (
        <DialogContent style={{ padding: 20 }}>
          <div className={styles.dialog_header}>
            Create a Teachable account to access this course!
          </div>
          <div>
            Myndlift partners with Teachable to deliver on-demand mentorship
            material, exclusive to active Myndlift practitioners.
          </div>
          <div className={styles.inputs_container}>
            <div className={styles.dialog_input_container}>
              <Tooltip
                title={
                  <TooltipTitle>
                    {
                      'The email can’t be changed. For help, contact the Care Team.'
                    }
                  </TooltipTitle>
                }
              >
                <TextField
                  value={
                    sessionStorage.teamMemberId
                      ? JSON.parse(sessionStorage.intercomInfo).email
                      : JSON.parse(sessionStorage.userInfo).email
                  }
                  onChange={() => {}}
                  disabled
                  classes={{}}
                  label="Email"
                  inputProps={{ style: { fontSize: 14 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                />
              </Tooltip>
            </div>
            <div className={styles.dialog_input_container}>
              <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                classes={{}}
                label="Name"
                inputProps={{ style: { fontSize: 14 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
              />
              <FormControl>
                <TextField
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  classes={{
                    root: styles.dialog_input,
                  }}
                  label="Password"
                  style={{ marginLeft: 20 }}
                  inputProps={{ style: { fontSize: 14 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                />
                <FormHelperText
                  classes={{ root: styles.form_helper_text_error }}
                  style={!isPasswordValid ? { color: 'red' } : null}
                >
                  (Must be 6 - 12 alphanumeric characters)
                </FormHelperText>
              </FormControl>
            </div>
            {signupError ? (
              <div style={{ color: 'red', marginTop: 10 }}>
                Something went wrong
              </div>
            ) : null}
          </div>
          <div className={styles.buttons_container}>
            <Button
              onClick={onClose}
              classes={{
                root: styles.dialog_button,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onSignup}
              color="primary"
              variant="contained"
              classes={{
                root: styles.dialog_button,
              }}
              disabled={!name || !name.trim()}
            >
              {isLoadingSignup ? (
                <CircularProgress size={14} color="white" />
              ) : (
                'Next'
              )}
            </Button>
          </div>
        </DialogContent>
      ) : (
        <DialogContent style={{ padding: 20 }}>
          <div
            onClick={onClose}
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <CloseIcon />
          </div>
          <div className={styles.succsess_message_container}>
            <img
              src={doneGif}
              width={75}
              height={75}
              style={{ marginBottom: 20 }}
            />
            <div className={styles.dialog_header}>
              Account successfully created!
            </div>
            <Button
              color="primary"
              variant="contained"
              classes={{
                root: styles.dialog_success_button,
              }}
              onClick={() => {
                window.open(
                  'https://sso.teachable.com/secure/1382889/identity/login/password',
                  '_new'
                );
              }}
            >
              ACCESS THE COURSE NOW!
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

TeachableIntegrationSignup.propTypes = exact({
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  // gotoFunc: PropTypes.string,
});

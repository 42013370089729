import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';

import { Paper, Button } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import styles from '../ClinicAccountPage.scss';
import { CustomTable } from '../../../Core/Components/CustomTable/CustomTable';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { DAY_JS_DATE_FORMATS, countriesJSON } from '../../../utils/constants';

import trackingNotAvailableAnymoreImg from '../../../../assets/tracking-not-available-anymore.png';
import trackingNotAvailableYetImg from '../../../../assets/tracking-not-available-yet.png';

const OrdersHistoryBase = ({ ordersHistory }) => {
  const isNingthyDaysOld = timestamp =>
    (Date.now() - timestamp) / (1000 * 60 * 60 * 24) > 90;

  const tableHeadsOrders = [
    {
      id: 'date',
      node: (
        <span className={styles.head_cell} style={{ paddingLeft: 0 }}>
          Order placed
        </span>
      ),
    },
    {
      id: 'total',
      node: <span className={styles.head_cell}>Total</span>,
    },
    {
      id: 'destination',
      node: <span className={styles.head_cell}>Ship to</span>,
    },
    {
      id: 'items',
      node: <span className={styles.head_cell}>Items</span>,
    },
    {
      id: 'tracking',
      node: <span className={styles.head_cell}>Tracking</span>,
    },
    {
      id: 'receipt',
      node: <span className={styles.head_cell}>Receipt</span>,
    },
  ];

  const tableRowsOrders =
    ordersHistory &&
    Object.keys(ordersHistory)
      .filter(
        orderKey =>
          ordersHistory[orderKey].timestamp && ordersHistory[orderKey].items
      )
      .sort((a, b) => ordersHistory[b].timestamp - ordersHistory[a].timestamp)
      .map(orderKey => {
        const order = ordersHistory[orderKey];
        const isOrderOlderThanNingthyDays = isNingthyDaysOld(order.timestamp);

        return {
          cells: [
            {
              id: 'date',
              node: (
                <span className={styles.cell} style={{ paddingLeft: 0 }}>
                  {dayJS(order.timestamp).format(
                    DAY_JS_DATE_FORMATS.namedMonthDayYear
                  )}
                </span>
              ),
            },
            {
              id: 'total',
              node: <span className={styles.cell}>${order.total || 0}</span>,
            },
            {
              id: 'destination',
              node: (
                <span className={styles.cell}>
                  <div>{order.shippingInfo.Name}</div>
                  <div>
                    {`${order.shippingInfo.StreetAddressLine1} ${
                      order.shippingInfo.StreetAddressLine2
                        ? `,  ${order.shippingInfo.StreetAddressLine2}`
                        : ''
                    }`}
                  </div>
                  <div>
                    {`${order.shippingInfo.City}, ${
                      order.shippingInfo.State
                        ? `${order.shippingInfo.State},`
                        : ''
                    } ${order.shippingInfo.Zipcode}`}
                  </div>
                  <div>
                    {`${countriesJSON[order.shippingInfo.Country].name}`}
                  </div>
                  <div style={{ marginTop: 5 }}>
                    Phone: {order.shippingInfo.PhoneNumber}
                  </div>
                </span>
              ),
            },
            {
              id: 'items',
              node: (
                <span className={styles.cell}>
                  {order.items
                    ? Object.keys(order.items).map(itemId => {
                        const item = order.items[itemId];
                        return (
                          <div style={{ marginBottom: 10 }}>
                            <div>{item.name}</div>
                            <div>Quantity: {item.quantity}</div>
                          </div>
                        );
                      })
                    : null}
                </span>
              ),
            },
            {
              id: 'tracking',
              node: (
                <span className={styles.cell} style={{ cursor: 'pointer' }}>
                  {// eslint-disable-next-line no-nested-ternary
                  isOrderOlderThanNingthyDays ? (
                    <TooltipWrapper
                      title={
                        <TooltipTitle>
                          Tracking information has expired for this order.
                          Please contact the Care Team for further inquiries.
                        </TooltipTitle>
                      }
                    >
                      <img
                        src={trackingNotAvailableAnymoreImg}
                        style={{
                          width: 20,
                          height: 20,
                          position: 'relative',
                          top: 5,
                        }}
                      />
                    </TooltipWrapper>
                  ) : order.trackingUrl ? (
                    <LocalShippingIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: '#2197f3',
                        position: 'relative',
                        top: 10,
                      }}
                      onClick={() => {
                        window.open(order.trackingUrl, '_blank');
                      }}
                    />
                  ) : (
                    <TooltipWrapper
                      title={
                        <TooltipTitle>
                          Your order is being prepared for shipment. Tracking
                          information will be updated soon.
                        </TooltipTitle>
                      }
                    >
                      <img
                        src={trackingNotAvailableYetImg}
                        style={{
                          width: 20,
                          height: 20,
                          position: 'relative',
                          top: 5,
                        }}
                      />
                    </TooltipWrapper>
                  )}
                </span>
              ),
            },
            {
              id: 'receipt',
              node: (
                <span
                  className={styles.cell}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(order.receipt, '_blank');
                  }}
                >
                  <ReceiptIcon
                    style={{
                      width: 20,
                      height: 20,
                      position: 'relative',
                      top: 10,
                    }}
                  />
                </span>
              ),
            },
          ],
          id: `${order.timestamp} `,
        };
      });

  if (
    !ordersHistory ||
    !Object.keys(ordersHistory).filter(key => ordersHistory[key].timestamp)
      .length
  ) {
    return (
      <Paper style={{ marginTop: 20 }}>
        <div className={styles.empty_orders_table_container}>
          <div className={styles.empty_orders_table_title}>
            No orders placed yet
          </div>
          <div className={styles.empty_orders_table_description}>
            Make a hardware purchase now
          </div>
          <Button
            onClick={() => {
              window.open('shop/shop-hardware.html', '_blank');
            }}
            color="primary"
            variant="contained"
            style={{ marginBottom: 60 }}
          >
            <ShoppingCartIcon style={{ marginRight: 10 }} />
            Get new kits
          </Button>
        </div>
      </Paper>
    );
  }

  return (
    <div>
      <div
        className={styles.table_container}
        style={{ backgroundColor: 'white', marginTop: 30 }}
      >
        <CustomTable
          tableHeads={tableHeadsOrders}
          tableRows={tableRowsOrders}
          colgroup={
            <colgroup>
              <col width="15%" />
              <col width="15%" />
              <col width="25%" />
              <col width="25%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
          }
          verticalAlign
        />
      </div>
    </div>
  );
};

OrdersHistoryBase.propTypes = exact({
  ordersHistory: PropTypes.object,
});

export const OrdersHistory = React.memo(OrdersHistoryBase);

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button, Dialog } from '@material-ui/core';
import styles from './ReferralPopOut.scss';
import clinicianPatientImage from '../../../assets/clinician-patient.png';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';

const ReferralPopOutBase = ({
  isOpen,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onClose,
  credit,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: styles.dialog_root }}
    >
      <div className={styles.root}>
        <div className={styles.image_container}>
          <div className={styles.image_content}>
            <p>REFER ONE</p>
            <p>Earn ${credit}.</p>
          </div>
          <img src={clinicianPatientImage} />
        </div>
        <div className={styles.content_container}>
          <div className={styles.content}>
            <p>Earn rewards for being part of something better</p>

            <p>Help us bring remote neurofeedback to more practices.</p>
            <p>
              For every clinic that signs up for Myndlift,{' '}
              <span style={{ fontWeight: 'bold' }}>
                you’ll both earn{' '}
                <span style={{ fontSize: '17px', color: '#4be0d0' }}>
                  ${credit}
                </span>{' '}
                in credit
              </span>
              .
            </p>
          </div>

          <div className={styles.buttons_container}>
            <Button
              variant="text"
              color="inherit"
              onClick={onSecondaryBtnClick}
            >
              <span className={styles.btn}>DON’T NEED ${credit} CREDIT</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onPrimaryBtnClick}
            >
              <span className={styles.btn}>REFER A CLINIC</span>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ReferralPopOutBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  credit: PropTypes.number,
});

export const ReferralPopOut = withEntryComponent(
  React.memo(ReferralPopOutBase)
);
ReferralPopOut.displayName = 'ReferralPopOut';

export const PACKAGES_TYPES = {
  BLOCKED_PACKAGE: 'blockedPackage',
  PRE_ACTIVATION_PACKAGE: 'preActivation',
  HEADSET_PACKAGE: 'headsetPackage',
  BASE_HEADSET_PACKAGE: 'base-headsetPackage',
  TRIAL_PACKAGE: 'trialPackage',
};

export const FORMATTED_PACKAGES_TYPES = {
  [PACKAGES_TYPES.BLOCKED_PACKAGE]: 'Blocked',
  [PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE]: 'Pre activated',
  [PACKAGES_TYPES.HEADSET_PACKAGE]: 'Normal',
  [PACKAGES_TYPES.BASE_HEADSET_PACKAGE]: 'Base',
  [PACKAGES_TYPES.TRIAL_PACKAGE]: 'Trial',
};

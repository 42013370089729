import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ReactStars from 'react-rating-stars-component';
import { Chip } from '@material-ui/core';
import styles from './FeedbackBox.scss';
import { isDefined } from '../../utils/utils';

const FeedbackBoxBase = ({ rating, tags, comment, sessionMentalTags }) => {
  const mentalTags =
    sessionMentalTags && sessionMentalTags.tags
      ? Object.keys(sessionMentalTags.tags).map(mentalTagId => {
          return [
            sessionMentalTags.tags[mentalTagId].emoji,
            sessionMentalTags.tags[mentalTagId].name,
          ];
        })
      : [];

  const notes =
    sessionMentalTags && sessionMentalTags.notes ? sessionMentalTags.notes : '';

  return (
    <div className={styles.root}>
      {mentalTags.length || notes ? (
        <p className={styles.card_header}>Pre-session check-in</p>
      ) : null}
      {mentalTags.length ? (
        <div className={styles.mental_tags_container}>
          {mentalTags.map(mentalTag => (
            <div>
              <span>{mentalTag[0]} </span>
              <span>{mentalTag[1]}</span>
            </div>
          ))}
        </div>
      ) : null}
      {notes ? (
        <div className={styles.comment_container}>
          <p className={styles.label}>Note:</p>
          <p>{notes}</p>
        </div>
      ) : null}
      {isDefined(rating) || tags.length > 0 || comment ? (
        <p
          className={styles.card_header}
          style={mentalTags.length || notes ? { marginTop: 20 } : null}
        >
          Post-session feedback
        </p>
      ) : null}
      {isDefined(rating) || tags.length > 0 || comment ? (
        <>
          {isDefined(rating) && (
            <div className={styles.rating_container}>
              <p className={styles.label}>Rating: </p>
              <ReactStars count={5} value={rating} edit={false} />
            </div>
          )}

          {tags.length > 0 && (
            <div className={styles.tags_container}>
              <p className={styles.label}>Tags:</p>
              <div className={styles.tags}>
                {tags.map(tag => (
                  <Chip label={tag} className={styles.tag} key={tag} />
                ))}
              </div>
            </div>
          )}

          {comment && (
            <div className={styles.comment_container}>
              <p className={styles.label}>Comment:</p>
              <p>{comment}</p>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

FeedbackBoxBase.propTypes = exact({
  rating: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  comment: PropTypes.string,
  sessionMentalTags: PropTypes.object,
});

export const FeedbackBox = React.memo(FeedbackBoxBase);
FeedbackBox.displayName = 'FeedbackBox';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './TooltipTitle.scss';

const TooltipTitleBase = ({ children }) => {
  return <span className={styles.tooltip_title}>{children}</span>;
};

TooltipTitleBase.propTypes = exact({ children: PropTypes.node.isRequired });

export const TooltipTitle = React.memo(TooltipTitleBase);

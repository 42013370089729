import React, { useState, useEffect } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { ChangeCreditCardDetailsIfNeeded } from './ChangeCreditCardDetailsIfNeeded';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { stripeScriptURL } from '../../utils/constants';
import { useScript } from '../../Core/hooks/useScript';

const ChangeCreditCardDetailsIfNeededWrapperBase = ({ ...props }) => {
  const [stripe, setStripe] = useState(null);
  const [isStripeScriptLoaded] = useScript(stripeScriptURL);

  useEffect(() => {
    if (
      (isStripeScriptLoaded || window.isStripeScriptLoaded) &&
      stripe === null
    ) {
      window.isStripeScriptLoaded = true;
      setStripe(window.Stripe(environmentConfig.stripePublicKey));
    }
  }, [isStripeScriptLoaded, stripe]);

  return (
    <StripeProvider stripe={window.stripeForStripePage || stripe}>
      <Elements>
        <ChangeCreditCardDetailsIfNeeded {...props} />
      </Elements>
    </StripeProvider>
  );
};

ChangeCreditCardDetailsIfNeededWrapperBase.propTypes = {};

export const ChangeCreditCardDetailsIfNeededWrapper = withEntryComponent(
  ChangeCreditCardDetailsIfNeededWrapperBase
);

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './DeleteNoteDialog.scss';

export const DeleteNoteDialog = ({ isOpen, onDelete, onCancel }) => {
  const t = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 10 }}>
        <span className={styles.dialog_header}>
          Are you sure you want to delete this note?
        </span>
      </DialogTitle>
      <span className={styles.dialog_subheader}>
        The note will be lost and can’t be retrieved.
      </span>
      <DialogActions>
        <Button onClick={onCancel} variant="text" color="default">
          <span className={styles.button}>{t('cancel')}</span>
        </Button>
        <Button onClick={onDelete} color="primary" variant="contained">
          <span className={styles.button}>{t('delete')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteNoteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DynamicAPIs_two_aligned_columns_layout{display:flex;flex-wrap:wrap}.DynamicAPIs_two_aligned_columns_layout>div:nth-child(2){margin-left:auto}.DynamicAPIs_centered_content{display:flex;justify-content:center;align-items:center}.DynamicAPIs_common_strong_font{font-weight:500}.DynamicAPIs_common_wrap_row{display:flex;flex-wrap:wrap}.DynamicAPIs_flex_col_centered{display:flex;flex-direction:column;justify-content:center;align-items:center}.DynamicAPIs_page{padding-left:75px;padding-right:75px}@media only screen and (max-width: 3600px){.DynamicAPIs_page{width:80%;margin:auto;padding:0px 0px}}@media only screen and (max-width: 1600px){.DynamicAPIs_page{width:100%;margin:auto;padding-left:75px;padding-right:75px}}@media only screen and (max-width: 768px){.DynamicAPIs_page{width:100%;margin:auto;padding-left:15px;padding-right:15px}}.DynamicAPIs_paper,.DynamicAPIs_content_container{padding:20px 30px}.DynamicAPIs_user_profile>p{font-weight:bold;margin-bottom:10px}.DynamicAPIs_content_container{margin-bottom:25px}.DynamicAPIs_content_container *{font-size:14px !important}.DynamicAPIs_fields_container{display:flex;flex-wrap:wrap}.DynamicAPIs_fields_container>*{margin-right:20px !important;margin-bottom:20px !important}.DynamicAPIs_buttons_container{display:flex;flex-wrap:wrap}.DynamicAPIs_buttons_container>button{margin:5px 20px 5px 0px}.DynamicAPIs_buttons_container>div>button{margin:5px 20px 5px 0px}.DynamicAPIs_form_control_label_checkbox{margin-left:0px !important;margin-right:0px !important}.DynamicAPIs_form_control_label_checkbox *{font-size:22px !important}.DynamicAPIs_form_font{font-size:14px !important}.DynamicAPIs_dialog_root *{font-size:14px !important}.DynamicAPIs_label_checkbox{font-size:14px !important}\n", ""]);

// Exports
exports.locals = {
	"two_aligned_columns_layout": "DynamicAPIs_two_aligned_columns_layout",
	"centered_content": "DynamicAPIs_centered_content",
	"common_strong_font": "DynamicAPIs_common_strong_font",
	"common_wrap_row": "DynamicAPIs_common_wrap_row",
	"flex_col_centered": "DynamicAPIs_flex_col_centered",
	"page": "DynamicAPIs_page",
	"paper": "DynamicAPIs_paper",
	"content_container": "DynamicAPIs_content_container",
	"user_profile": "DynamicAPIs_user_profile",
	"fields_container": "DynamicAPIs_fields_container",
	"buttons_container": "DynamicAPIs_buttons_container",
	"form_control_label_checkbox": "DynamicAPIs_form_control_label_checkbox",
	"form_font": "DynamicAPIs_form_font",
	"dialog_root": "DynamicAPIs_dialog_root",
	"label_checkbox": "DynamicAPIs_label_checkbox"
};
import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {
  LocalizationProvider,
  ClinicInfoProvider,
} from '../Core/contexts/contexts';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.SHOULD_DEBUG_REACT_PERFORMANCE
) {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

export const EntryComponent = props => {
  const muTheme = createMuiTheme({
    palette: { primary: blue },
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MuiChip: {
        label: {
          whiteSpace: 'initial',
          minHeight: 'fit-content',
        },
      },
    },
  });

  const translate = key => $.i18n(key);
  const userInfo = sessionStorage.userInfo
    ? JSON.parse(sessionStorage.userInfo)
    : {};
  const clinicInfo = {
    id: userInfo.userId || sessionStorage.userId || null,
  };

  return (
    <LocalizationProvider value={translate}>
      <ClinicInfoProvider value={clinicInfo}>
        <MuiThemeProvider theme={muTheme}>{props.children}</MuiThemeProvider>
      </ClinicInfoProvider>
    </LocalizationProvider>
  );
};

EntryComponent.propTypes = exact({
  children: PropTypes.node.isRequired,
});

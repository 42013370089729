exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DigitalCare_size_17{font-size:17px}.DigitalCare_size_10{font-size:10px}.DigitalCare_size_11{font-size:11px}.DigitalCare_size_13{font-size:13px}.DigitalCare_size_15{font-size:15px}.DigitalCare_size_14{font-size:14px}.DigitalCare_size_16{font-size:16px}.DigitalCare_size_12{font-size:12px}.DigitalCare_extra_header{font-size:18px;font-weight:500;color:#000000}.DigitalCare_header{font-size:17px;font-weight:500;color:#000000}.DigitalCare_header_only_font_size{font-size:17px;font-weight:500}.DigitalCare_sub_header{font-size:13px;color:#878787}.DigitalCare_sub_header_black{font-size:13px;color:black}.DigitalCare_sub_header_only_font_size{font-size:13px}.DigitalCare_paragraph_only_font_size{font-size:12px}.DigitalCare_tab_font_size{font-size:13px}.DigitalCare_pop_out_header{font-size:20px}.DigitalCare_title{font-size:15px;font-weight:500;color:#484848}.DigitalCare_title_black{font-size:15px;font-weight:500;color:black}.DigitalCare_title_only_font_size{font-size:15px}.DigitalCare_paragraph{font-size:12px;color:#484848}.DigitalCare_btn{font-size:14px}.DigitalCare_items_list{display:flex;justify-content:space-between}@media only screen and (max-width: 960px){.DigitalCare_items_list{flex-direction:column;align-items:center}}.DigitalCare_title{margin-bottom:20px;font-size:14px;font-weight:400}@media only screen and (max-width: 960px){.DigitalCare_title{text-align:center;padding:0 50px}}@media only screen and (max-width: 768px){.DigitalCare_title{padding:0}}\n", ""]);

// Exports
exports.locals = {
	"size_17": "DigitalCare_size_17",
	"size_10": "DigitalCare_size_10",
	"size_11": "DigitalCare_size_11",
	"size_13": "DigitalCare_size_13",
	"size_15": "DigitalCare_size_15",
	"size_14": "DigitalCare_size_14",
	"size_16": "DigitalCare_size_16",
	"size_12": "DigitalCare_size_12",
	"extra_header": "DigitalCare_extra_header",
	"header": "DigitalCare_header",
	"header_only_font_size": "DigitalCare_header_only_font_size",
	"sub_header": "DigitalCare_sub_header",
	"sub_header_black": "DigitalCare_sub_header_black",
	"sub_header_only_font_size": "DigitalCare_sub_header_only_font_size",
	"paragraph_only_font_size": "DigitalCare_paragraph_only_font_size",
	"tab_font_size": "DigitalCare_tab_font_size",
	"pop_out_header": "DigitalCare_pop_out_header",
	"title": "DigitalCare_title",
	"title_black": "DigitalCare_title_black",
	"title_only_font_size": "DigitalCare_title_only_font_size",
	"paragraph": "DigitalCare_paragraph",
	"btn": "DigitalCare_btn",
	"items_list": "DigitalCare_items_list"
};
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './AddNoteDialog.scss';

export const AddNoteDialog = ({
  isOpen,
  onSave,
  onCancel,
  onClose,
  noteContent,
  setNoteContent,
  isEdit,
  extractContent,
}) => {
  const t = useTranslation();

  useEffect(() => {
    const onWindowBeforeUnLoad = e => {
      if (
        extractContent(
          draftToHtml(convertToRaw(noteContent.getCurrentContent()))
        ).trim(' ')
      ) {
        e.preventDefault();

        e.returnValue = ''; // needed by chrome
      }
    };

    window.addEventListener('beforeunload', onWindowBeforeUnLoad);
    return () =>
      window.removeEventListener('beforeunload', onWindowBeforeUnLoad);
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <span className={styles.dialog_header}>
          {isEdit ? 'Edit note' : 'Add a new note'}
        </span>
      </DialogTitle>
      <div className={styles.editor_container}>
        {/* <TextField
          multiline
          rows={8}
          variant="outlined"
          value={noteContent}
          onChange={e => {
            setNoteContent(e.target.value);
          }}
          fullWidth
        /> */}
        <Editor
          editorState={noteContent}
          onEditorStateChange={editorStateContent => {
            setNoteContent(editorStateContent);
          }}
          toolbar={{
            options: ['inline', 'list', 'link'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            link: {
              popupClassName: styles.popupInDraft,
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
          editorClassName={styles.editorClassName}
          wrapperClassName={styles.wrapperClassName}
          toolbarClassName={styles.toolbarClassName}
          // wrapperStyle={{display: 'flex'}}
          handlePastedText={() => false}
        />
      </div>

      <DialogActions>
        <Button onClick={onCancel} variant="text" color="default">
          <span className={styles.button}>{t('cancel')}</span>
        </Button>
        <Button
          onClick={() => {
            onSave(draftToHtml(convertToRaw(noteContent.getCurrentContent())));
            onClose();
          }}
          color="primary"
          variant="contained"
          disabled={
            !extractContent(
              draftToHtml(convertToRaw(noteContent.getCurrentContent()))
            ).trim(' ')
          }
        >
          <span className={styles.button}>{t('save')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  noteContent: PropTypes.string,
  setNoteContent: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  extractContent: PropTypes.func,
};

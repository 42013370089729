import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button } from '@material-ui/core';
import { getCurrentURLParams } from '../../utils/utils';
import { MFADialog } from './MFADialog';
import { RemoveMFADialog } from './RemoveMFADialog';
// import styles from './SettingsSection.scss';

const MFASettingsBase = () => {
  // eslint-disable-next-line no-undef
  const authUser = firebase.auth().currentUser;

  // const [authUser,setAuthUser] = useState(authUserGlobal)
  const [isMFADialogOpen, setIsMFADialogOpen] = useState(false);
  const [isLodingMFADialog, setIsLodingMFADialog] = useState(false);
  const [verificationId, setVerificationId] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [clinicPhoneNumber, setClinicPhoneNumber] = useState('');
  const [isPhoneError, setIsPhoneError] = useState('');
  const [isEnrolledSuccessfully, setIsEnrolledSuccessfully] = useState(false);
  const [isCodeError, setIsCodeError] = useState('');
  const [isRemoveMFADialogOpen, setIsRemoveMFADialogOpen] = useState(false);
  const [isEmailError, setIsEmailError] = useState('');

  const { authFlow } = getCurrentURLParams();

  const onVerifyEmail = () => {
    setIsLodingMFADialog(true);
    authUser
      .sendEmailVerification({
        url: `${window.location.href}?authFlow=true`,
      })
      .then(() => {
        setIsEmailSent(true);
        setIsLodingMFADialog(false);
      })
      .catch(e => {
        setIsLodingMFADialog(false);
        setIsEmailError(e.toString());
        // console.log(e);
      });
  };

  const onEnrollMFA = () => {
    setIsLodingMFADialog(true);
    authUser.multiFactor
      .getSession()
      .then(multiFactorSession => {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: clinicPhoneNumber.startsWith('+')
            ? clinicPhoneNumber
            : `+${clinicPhoneNumber}`,
          session: multiFactorSession,
        };
        // eslint-disable-next-line no-undef
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        return phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          window.recaptchaVerifier
        );
      })
      .then(verificationIdVar => {
        setVerificationId(verificationIdVar);
        setIsLodingMFADialog(false);
      })
      .catch(e => {
        setIsPhoneError(e.toString());
        setIsLodingMFADialog(false);
      });
  };

  const onVerificationCodeSent = () => {
    setIsCodeError('');
    setIsLodingMFADialog(true);
    // eslint-disable-next-line no-undef
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    // eslint-disable-next-line no-undef
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
      cred
    );
    // Complete enrollment.
    authUser.multiFactor
      .enroll(multiFactorAssertion, 'My personal phone number')
      .then(() => {
        setIsLodingMFADialog(false);
        setIsEnrolledSuccessfully(true);
      })
      .catch(e => {
        setIsLodingMFADialog(false);
        setIsCodeError(e.toString());
        // console.log(e);
      });
  };

  const onMFAClicked = () => {
    setIsMFADialogOpen(true);
  };

  const removeMFA = async () => {
    // eslint-disable-next-line no-undef
    await fireFunctionPost('firebase_auth-remove2FAuth');
    // eslint-disable-next-line no-undef, no-restricted-globals
    location.reload();
  };

  const submitPhoneNumber = async () => {
    try {
      setIsPhoneError('');

      setIsLodingMFADialog(true);
      await database
        .ref(`userInfo/${sessionStorage.userId}/privateDetails/phoneNumber`)
        .set(
          clinicPhoneNumber.startsWith('+')
            ? clinicPhoneNumber
            : `+${clinicPhoneNumber}`
        );
      onEnrollMFA();
    } catch (e) {
      setIsPhoneError(e.toString());
    }
  };

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(`userInfo/${sessionStorage.userId}/privateDetails/phoneNumber`)
        .once('value', v => v);
      setClinicPhoneNumber(snapshot.val());
    })();
  }, []);

  useEffect(() => {
    if (authFlow) {
      setIsMFADialogOpen(true);
    }
  }, []);

  return (
    <>
      <div style={{ marginTop: 20 }}>
        {authUser && !authUser.multiFactor.enrolledFactors.length ? (
          <div>
            <Button
              onClick={onMFAClicked}
              color="primary"
              variant="contained"
              style={{ fontSize: 14 }}
            >
              Enroll two factor authentication to this clinic
            </Button>
          </div>
        ) : (
          <div>
            <Button
              onClick={() => {
                setIsRemoveMFADialogOpen(true);
              }}
              color="primary"
              variant="contained"
              style={{ fontSize: 14 }}
            >
              Remove two factor authentication to this clinic
            </Button>
          </div>
        )}
      </div>
      <MFADialog
        isOpen={isMFADialogOpen}
        onClose={() => {
          setIsMFADialogOpen(false);
        }}
        onVerifyEmail={onVerifyEmail}
        onEnrollMFA={onEnrollMFA}
        isEmailVerified={authUser && authUser.emailVerified}
        clinicPhoneNumber={clinicPhoneNumber}
        setClinicPhoneNumber={setClinicPhoneNumber}
        isLodingMFADialog={isLodingMFADialog}
        setVerificationCode={setVerificationCode}
        verificationCode={verificationCode}
        isEmailSent={isEmailSent}
        verificationId={verificationId}
        isPhoneError={isPhoneError}
        submitPhoneNumber={submitPhoneNumber}
        isEnrolledSuccessfully={isEnrolledSuccessfully}
        onVerificationCodeSent={onVerificationCodeSent}
        isCodeError={isCodeError}
        isEmailError={isEmailError}
      />
      <RemoveMFADialog
        isOpen={isRemoveMFADialogOpen}
        onClose={() => {
          setIsRemoveMFADialogOpen(false);
        }}
        removeMFA={removeMFA}
      />
    </>
  );
};

MFASettingsBase.propTypes = exact({});

export const MFASettings = React.memo(MFASettingsBase);
MFASettings.displayName = 'MFASettings';

import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import FormControl from '@material-ui/core/FormControl';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { capitalize, omit } from 'lodash';
import styles from './CreateProgramPopUp.scss';
import { ModalHeader } from '../../Core/Components/Modal/ModalHeader';
import { ModalFooter } from '../../Core/Components/Modal/ModalFooter';
import { ProgramSettingsStep } from './ProgramSettingsStep';
import { SelectChannelsStep } from './SelectChannelsStep';
import { PlacementRecommendationStep } from './PlacementRecommendationStep';
import doneAnimationAsset from '../../../assets/done-animation.gif';
import clientHasSessionAsset from '../../../assets/client_has_session.png';
import { withTranslation } from '../../Core/hocs/withTranslation/withTranslation';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import {
  CHANNELS,
  CHANNEL_IDS,
  isAuxiliaryChannel,
} from '../../models/channels/channels';
import { fetchHeadsetDetails } from '../../utils/utils';
import { DeployedProgramsStep } from './DeployedProgramsStep/DeployedProgramsStep';
import { ConfirmCancelingDialog } from './ConfirmCancelingDialog/ConfirmCancelingDialog';
import {
  getFirebaseData,
  listenForFirebaseData,
} from '../../models/firebase/firebase';
import { FeatureNotAllowedDialog } from '../FeatureNotAllowedDialog/FeatureNotAllowedDialog';
import { isAppCompilableWithMultiProtocol } from '../../models/customer/customer';
import { CustomDialog } from '../../Core/Components/CustomDialog/CustomDialog';
import { SelfRegistrationDialog } from '../SelfRegistrationDialog/SelfRegistrationDialog';
import { ChangeCreditCardDetailsIfNeededWrapper } from '../ChangeCreditCardDetailsIfNeeded/ChangeCreditCardDetailsIfNeededWrapper';

const minFrequency = 11;
const maxFrequency = 89;
const minimumOverallSuccessRate = 35;
const maximumOverallSuccessRate = 80;
const programNameMaxLength = 45;
const longProgramNameErrorMsg = `Program name must be less than ${programNameMaxLength} characters`;

const basicPopupSteps = [
  'programSettings',
  'placementRecommendation',
  'sessionOptions',
];

const advancedPopupSteps = [
  'programSettings',
  'channelsSelect',
  'sessionOptions',
];

const formmatedSteps = ['Protocol', 'Setup', 'Deploy'];

const defaultClinicalTrainingProgram = {
  roundDuration: 4,
  roundsNumber: 4,
  homeSessionProgram: {
    maxNumOfHomeSessions: 1,
    homeSessionsUsed: 0,
    homeSessionCreated: true,
  },
};
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */

const updatePatientProtocolDetailsChannels = ({
  patientProtocolDetails,
  channelName,
  channelType,
}) => {
  const newPatientProtocolDetails = cloneDeep(patientProtocolDetails);
  if (typeof CHANNELS[channelName] === 'string') {
    newPatientProtocolDetails.updateChannelDetails(
      `${channelName} (headset)`,
      channelType
    );
  } else {
    newPatientProtocolDetails.updateChannelDetails(
      CHANNELS[channelName].name,
      channelType,
      CHANNELS[channelName].auxiliaryLocation
    );
  }

  return newPatientProtocolDetails;
};

const extendClinicalTrainingProgramByOneSessionIfNeeded = clinicalTrainingProgram => {
  if (
    clinicalTrainingProgram.homeSessionProgram &&
    clinicalTrainingProgram.homeSessionProgram.homeSessionsUsed ===
      clinicalTrainingProgram.homeSessionProgram.maxNumOfHomeSessions
  ) {
    // eslint-disable-next-line no-param-reassign
    clinicalTrainingProgram.homeSessionProgram.maxNumOfHomeSessions += 1;
  }
};

const stylesFn = theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    maxWidth: '800px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    maxHeight: '85%',
    borderRadius: '3px',
  },
  chip: {
    margin: theme.spacing.unit,
    fontSize: '13px',
  },
  newSessionAvatar: {
    marginRight: 20,
    width: 40,
    height: 40,
  },
  button: {
    margin: theme.spacing.unit,
    color: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    fontSize: '14px',
  },
});

const deployedProgramsOrders = ['SEQUENTIAL', 'ALTERNATING', 'USER_CHOICE'];
const deployedProgramsExpirationOptions = [
  { value: 'SELECT_DATE', text: 'Select date (optional)', disabled: true },
  {
    value: 'NEXT_BC',
    text: 'The end of the clinic’s current billing cycle',
    disabled:
      sessionStorage.userInfo &&
      JSON.parse(sessionStorage.userInfo).expiration < Date.now(),
  },
  { value: 'CUSTOM', text: 'Set custom date' },
];

const createDefaultState = () => ({
  suggestions: [],
  userId: '',
  displayName: '',
  renderedStep: 'userSelect',
  prvRenderedStep: null,
  nextStepToBeRendered: null,
  isNextBtnShown: false,
  patientProtocolDetails: null,
  currentChannelSelection: 'activeChannel',
  activeChannel: null,
  referenceChannel: null,
  selectedClinicalProtocol: null,
  clinicalTrainingProgram: null,
  errors: {
    programSettings: {},
  },
  isEditingMode: false,
  isMultiProgramFeatureAllowed: false,
  isFeatureNotAllowedDialogOpen: false,
  prvIsProgramSettingsAdvancedMode: null,
  disabledChannelsToBeSelected: [CHANNEL_IDS.Fpz],
  prvUserId: null,
  shouldLoadAndProcessUserData: false,
  patientInfo: null,
  isNextBtnLoading: false,
  headsetDetails: null,
  dispatchedHeadsetCredit: null,
  currentProgramName: '',
  currentPrograms: [],
  initialCurrentPrograms: [],
  deployedProgramsOrder: deployedProgramsOrders[2],
  deployedProgramsExpiration: deployedProgramsExpirationOptions[0].value,
  deployedProgramsExpirationTimestamp: null,
  shouldNotifyByEmail: true,
  currentProgramIndex: null,
  isConfirmCancelingDialogOpen: false,
  numOfSessions: 20,
  numOfCompletedSessions: 0,
  isCustomerAppCompatibleWithMultiProtocol: true,
  toggleCompleteSetupModal: false,
  userSuggestion: null,
  addClientReachedLimitModal: false,
  creditCardDetailsRenderedStep: 'cardDetailsInput',
});
class CreateProgramPopUpModal extends Component {
  constructor(props) {
    super(props);

    this.state = createDefaultState(props);
    this.autoComplete = React.createRef();
    this.creditCardDetailsDialogRef = React.createRef();
  }

  async componentDidMount() {
    await database.ref('simpleDashboardConfig').on('value', snapshot => {
      const simpleDashboardConfig = snapshot.val();
      // eslint-disable-next-line prefer-destructuring, no-undef, dot-notation
      placements = simpleDashboardConfig['placements'];
    });
    this.cancelListenerForMultiProgramFeature = listenForFirebaseData(
      `clinicConfig/additionalFeatures/multiProgramFeature/${this.props.clinicId}/isAvailable`,
      snapshot => {
        this.setState({ isMultiProgramFeatureAllowed: snapshot.val() });
      }
    );
    const registrationCode = await this.loadRegistrationCode(
      this.props.clinicId
    );
    this.setState({ registrationCode });
    const reachedClientsLimit = await this.loadReachedClientsLimit();
    const usersIncluded = await this.loadUsersIncluded();
    const clinicNonfPlan = await this.loadClinicNonfPlan();
    const nfFlag = await this.loadNfFlag();
    const assessmentAutoDeployableConfigs = await this.loadAssessmentAutoDeployableConfigs();
    const questionnairesAutoDeployableConfigs = await this.loadQuestionnairesAutoDeployableConfigs();
    const clinicExpiration = await this.loadClinicExpiration();
    const clinicExpirationEnabled = await this.loadClinicExpirationEnabled();
    const toggleRounds = !!(await this.loadToggleRounds());
    const userPrice = await this.loadUserPrice();
    // const trainingSessions = await this.loadTrainingSessions();
    const defaultBlockedUsageCredit = await this.loadDefaultBlockedUsageCredit();
    const clinicUserInfo = await this.loadUserInfo(this.props.clinicId);

    this.setState({
      reachedClientsLimit,
      usersIncluded,
      clinicNonfPlan,
      nfFlag,
      // trainingSessions,
      assessmentAutoDeployableConfigs,
      questionnairesAutoDeployableConfigs,
      clinicExpiration,
      clinicExpirationEnabled,
      toggleRounds,
      defaultBlockedUsageCredit,
      clinicUserInfo,
      userPrice,
    });
  }

  componentWillUnmount() {
    if (this.cancelListenerForMultiProgramFeature) {
      this.cancelListenerForMultiProgramFeature();
    }
  }

  componentDidUpdate(prvProps, prvState) {
    if (this.state.shouldLoadAndProcessUserData) {
      this.loadAndUpdateUserData();
    }
    if (
      prvState.shouldLoadAndProcessUserData &&
      !this.state.shouldLoadAndProcessUserData
    ) {
      const { clinicalTrainingProgram, patientProtocolDetails } = this.state;
      if (this.props.onDataChange) {
        this.props.onDataChange({
          clinicalTrainingProgram,
          patientProtocolDetails,
          patientId: this.state.userId,
          headsetDetails: this.state.headsetDetails,
        });
      }
    }

    if (this.state.renderedStep !== prvState.renderedStep) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ prvRenderedStep: prvState.renderedStep });
    }
    if (
      this.state.currentChannelSelection === 'activeChannel' &&
      this.state.renderedStep === 'channelsSelect' &&
      !this.state.disabledChannelsToBeSelected.includes(CHANNEL_IDS.Fpz)
    ) {
      const copy = [...this.state.disabledChannelsToBeSelected];
      copy.push(CHANNEL_IDS.Fpz);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        disabledChannelsToBeSelected: copy,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.isModalOpen === false) {
      return createDefaultState(nextProps);
    }

    let derivedState = null;
    // If the user changes from simple to advanced or from advanced to simple mode
    if (
      nextProps.isProgramSettingsAdvancedMode !==
      state.prvIsProgramSettingsAdvancedMode
    ) {
      if (nextProps.isProgramSettingsAdvancedMode) {
        // here clinic is switching from simple to advanced, which requires no changes
        derivedState = {
          prvIsProgramSettingsAdvancedMode:
            nextProps.isProgramSettingsAdvancedMode,
        };
      } else {
        const { clinicalTrainingProgram } = state;
        if (clinicalTrainingProgram) {
          // here clinic switches from advanced to simple, in this case we need to modify data based on a simple protocol
          const { selectedClinicalProtocol } = state;
          const newClinicalTrainingProgram = CreateProgramPopUpModal.setClinicalTrainingProgramTime(
            {
              clinicalTrainingProgram,
              selectedClinicalProtocol,
            }
          );
          const patientProtocolDetails = nextProps.modifyProtocol(
            state.patientProtocolDetails,
            selectedClinicalProtocol.name
          );
          derivedState = {
            prvIsProgramSettingsAdvancedMode:
              nextProps.isProgramSettingsAdvancedMode,
            clinicalTrainingProgram: newClinicalTrainingProgram,
            patientProtocolDetails,
          };
        } else {
          derivedState = {
            prvIsProgramSettingsAdvancedMode:
              nextProps.isProgramSettingsAdvancedMode,
          };
        }
      }
    }

    if (
      typeof nextProps.patientId !== 'undefined' &&
      nextProps.patientId !== state.prvUserId
    ) {
      // here we are handling the case when we open the modal for a certain user (from lets say the user page)
      const isEditingMode = has(nextProps, 'isEditingMode')
        ? nextProps.isEditingMode
        : true;

      derivedState = {
        prvUserId: nextProps.patientId,
        userId: nextProps.patientId,
        isEditingMode,
        shouldLoadAndProcessUserData: true,
        ...derivedState,
      };
    }

    if (state.isEditingMode) {
      // here we are fixing some legacy data related to auxiliary channels
      if (
        state.patientProtocolDetails.channelDetails.training.name ===
          'Right auxiliary' &&
        !has(
          state,
          'patientProtocolDetails.channelDetails.training.auxiliaryLocation'
        )
      ) {
        const patientProtocolDetails = updatePatientProtocolDetailsChannels({
          patientProtocolDetails: state.patientProtocolDetails,
          channelName: 'Cz',
          channelType: 'training',
        });
        derivedState = {
          patientProtocolDetails,
          ...derivedState,
        };
      } else if (
        state.patientProtocolDetails.channelDetails.reference.name ===
          'Right auxiliary' &&
        !has(
          state,
          'patientProtocolDetails.channelDetails.reference.auxiliaryLocation'
        )
      ) {
        const patientProtocolDetails = updatePatientProtocolDetailsChannels({
          patientProtocolDetails: state.patientProtocolDetails,
          channelName: 'Cz',
          channelType: 'reference',
        });
        derivedState = {
          patientProtocolDetails,
          ...derivedState,
        };
      }
    }

    return derivedState;
  }

  getIsEyesClosedState = () => {
    if (has(this, 'state.patientProtocolDetails.eyeState')) {
      if (this.state.patientProtocolDetails.eyeState === 'Eyes open') {
        return false;
      }
      if (this.state.patientProtocolDetails.eyeState === 'Eyes closed') {
        return true;
      }
      return false;
    }
    return false;
  };

  getSuggestionValue = suggestion =>
    suggestion.firstName
      ? `${suggestion.firstName} ${suggestion.lastName} (${suggestion.email})`
      : this.props.t('add_a_new_client');

  renderSuggestion = suggestion =>
    suggestion.firstName ? (
      <div className={styles.autocomplete_user_item_container}>
        <p>{`${suggestion.firstName} ${suggestion.lastName} `}</p>
        <p>{suggestion.email}</p>
      </div>
    ) : (
      <div className={styles.autocomplete_user_item_container}>
        <p>{this.props.t('dont_see_the_client_you_are_looking_for')}</p>
        <a href="#">{this.props.t('add_a_new_client')}</a>
      </div>
    );

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    // const inputLength = inputValue.length;
    const suggestedUsers = this.props.users.filter(user => {
      const userFullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return (
        (user.email && user.email.includes(inputValue)) ||
        userFullName.includes(inputValue)
      );
    });
    // .slice(0, 3);

    // add an empty suggestion that will be used as a placeholder to render the add client link
    suggestedUsers.push({});

    this.suggestedUsers = suggestedUsers;
    return suggestedUsers;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  getOverAllSuccessRate = () => {
    const overAllSuccessRate =
      this.props.isProgramSettingsAdvancedMode ||
      this.state.currentProgramIndex !== null
        ? this.props.getOverAllSuccessRate(this.state.patientProtocolDetails)
        : this.props.getOverAllSuccessRate(this.state.selectedClinicalProtocol);

    return overAllSuccessRate;
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  getDerivedUserData = ({
    patientProtocolDetails,
    clinicalTrainingProgram,
  }) => {
    const firstSimpleProtocol = this.props.protocols.find(
      protocol => protocol.isSimple
    );

    const protocolName = this.getProtocolNameBasedOnPatientProtocol(
      patientProtocolDetails
    );
    const selectedAdvancedProtocol = this.props.protocols.find(
      protocol => protocol.name === protocolName
    );

    const selectedClinicalProtocol =
      selectedAdvancedProtocol && selectedAdvancedProtocol.isSimple
        ? selectedAdvancedProtocol
        : firstSimpleProtocol;

    let newClinicalTrainingProgram = clinicalTrainingProgram;
    let newPatientProtocolDetails = patientProtocolDetails;
    if (!this.props.isProgramSettingsAdvancedMode) {
      newPatientProtocolDetails = this.props.modifyProtocol(
        patientProtocolDetails,
        selectedClinicalProtocol.name
      );

      newClinicalTrainingProgram = CreateProgramPopUpModal.setClinicalTrainingProgramTime(
        {
          selectedClinicalProtocol,
          clinicalTrainingProgram,
        }
      );
    }

    return {
      patientProtocolDetails: newPatientProtocolDetails,
      selectedClinicalProtocol,
      clinicalTrainingProgram: newClinicalTrainingProgram,
    };
  };

  updatePatientProtocolBasedOnChosenPlacement = ({
    patientProtocolDetails,
    chosenPlacement,
  }) => {
    const activeChannel = chosenPlacement.trainingChannel;
    const { referenceChannel } = chosenPlacement;

    patientProtocolDetails.updateChannelDetails(
      activeChannel,
      'training',
      chosenPlacement.auxiliaryLocation
    );
    patientProtocolDetails.updateChannelDetails(
      referenceChannel,
      'reference',
      chosenPlacement.auxiliaryLocation
    );

    return patientProtocolDetails;
  };

  onNextBtnClick = async () => {
    const { t } = this.props;
    const {
      renderedStep,
      currentProgramIndex,
      currentProgramName,
      errors,
    } = this.state;

    if (
      this.state.currentProgramIndex === null &&
      this.state.patientProtocolDetails
    ) {
      this.setState({
        currentProgramName: this.getProtocolNameBasedOnPatientProtocol(
          this.state.patientProtocolDetails
        ).replace('Custom ', ''),
      });
    }

    switch (renderedStep) {
      case 'userSelect': {
        if (
          this.state.currentPrograms.length === 0 ||
          (this.state.currentPrograms.length > 0 &&
            this.state.currentPrograms.every(program => program.isComplete))
        ) {
          const newRenderedStep = 'programSettings';

          const {
            patientProtocolDetails,
            clinicalTrainingProgram,
            selectedClinicalProtocol,
          } = this.getDerivedUserData({
            patientProtocolDetails: this.state.patientProtocolDetails,
            clinicalTrainingProgram: this.state.clinicalTrainingProgram,
          });

          this.setState(
            {
              renderedStep: newRenderedStep,
              selectedClinicalProtocol,
              clinicalTrainingProgram,
              patientProtocolDetails,
            },
            () => this.validateOverAllSuccessRate()
          );
        } else {
          const newRenderedStep = 'userHasSessionDeployed';
          this.setState({
            renderedStep: newRenderedStep,
          });
        }

        break;
      }
      case 'programSettings':
        if (this.state.currentProgramIndex !== null) {
          if (sessionStorage.disconnection) {
            showNotification('danger', 'Internet connectivity unavailable');
            return;
          }
          this.editProgram(this.state.currentProgramIndex);
          this.setState({
            renderedStep: 'deployedPrograms',
            currentProgramIndex: null,
          });
        } else {
          if (this.props.isProgramSettingsAdvancedMode) {
            const isNextBtnShown = !!(
              this.state.activeChannel && this.state.referenceChannel
            );
            this.setState({
              renderedStep: 'channelsSelect',
              isNextBtnShown,
            });
          } else {
            const chosenPlacement = this.props.getChosenPlacement(
              this.state.selectedClinicalProtocol
            );

            let { patientProtocolDetails } = this.state;
            patientProtocolDetails = this.updatePatientProtocolBasedOnChosenPlacement(
              {
                patientProtocolDetails,
                chosenPlacement,
              }
            );

            this.setState({
              renderedStep: 'placementRecommendation',
              chosenPlacement,
              recommendedChosenPlacement: chosenPlacement,
              patientProtocolDetails,
            });
          }
        }
        break;
      case 'placementRecommendation':
        if (this.state.currentProgramIndex !== null) {
          const chosenPlacement = this.props.isProgramSettingsAdvancedMode
            ? this.props.getChosenPlacement(this.state.selectedClinicalProtocol)
            : this.state.chosenPlacementName ||
              this.props.getChosenPlacement(
                this.state.selectedClinicalProtocol
              );

          let { patientProtocolDetails } = this.state;
          patientProtocolDetails = this.updatePatientProtocolBasedOnChosenPlacement(
            {
              patientProtocolDetails,
              chosenPlacement,
            }
          );

          this.setState({
            renderedStep: 'programSettings',
            patientProtocolDetails,
          });
        } else {
          this.setState({
            renderedStep: 'sessionOptions',
          });
        }
        break;
      case 'channelsSelect':
        if (this.state.currentProgramIndex !== null) {
          let { patientProtocolDetails } = this.state;
          const { activeChannel, referenceChannel } = this.state;

          if (
            isAuxiliaryChannel(activeChannel) &&
            isAuxiliaryChannel(referenceChannel)
          ) {
            errors.selectChannels = t('error_pick_one_headset_channel');
            this.setState({ errors });
            break;
          }
          delete errors.selectChannels;
          this.setState({ errors });

          patientProtocolDetails = updatePatientProtocolDetailsChannels({
            patientProtocolDetails,
            channelName: activeChannel,
            channelType: 'training',
          });
          patientProtocolDetails = updatePatientProtocolDetailsChannels({
            patientProtocolDetails,
            channelName: referenceChannel,
            channelType: 'reference',
          });

          this.setState({
            renderedStep: 'programSettings',
            patientProtocolDetails,
          });
        } else {
          this.setState({
            renderedStep: 'sessionOptions',
          });
        }
        break;
      case 'sessionOptions': {
        if (currentProgramName.trim() === '') {
          errors.programSettings.currentProgramName =
            'Program name is required';
          this.setState({ errors });
          break;
        } else if (currentProgramName.length > programNameMaxLength) {
          errors.programSettings.currentProgramName = longProgramNameErrorMsg;
          this.setState({ errors });
          break;
        } else {
          delete errors.programSettings.currentProgramName;
          this.setState({ errors });
        }
        if (currentProgramIndex === null) {
          if (sessionStorage.disconnection) {
            showNotification('danger', 'Internet connectivity unavailable');
            return;
          }
          this.createNewProgram();
        }

        this.setState({
          renderedStep: 'deployedPrograms',
        });
        break;
      }
      case 'deployedPrograms':
        this.closeModal();
        break;
      default:
        break;
    }

    new MyEvent('fast_create_session_next_btn_click').log({
      rendered_step: renderedStep,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  onAddProgram = () => {
    if (sessionStorage.disconnection) {
      showNotification('danger', 'Internet connectivity unavailable');
      return;
    }
    const { isMultiProgramFeatureAllowed, currentPrograms } = this.state;
    if (isMultiProgramFeatureAllowed || currentPrograms.length === 0) {
      this.setState({
        currentChannelSelection: 'activeChannel',
        referenceChannel: null,
        activeChannel: null,
        currentProgramIndex: null,
        numOfCompletedSessions: 0,
        disabledChannelsToBeSelected: [CHANNEL_IDS.Fpz],
        currentProgramName: this.getProtocolNameBasedOnPatientProtocol(
          this.state.patientProtocolDetails
        ),
        renderedStep: 'programSettings',
      });
    } else {
      this.setState({
        isFeatureNotAllowedDialogOpen: true,
      });
    }
  };

  savePrograms = async (newPrograms, oldPrograms, addEditFlag) => {
    const {
      userId,
      deployedProgramsOrder,
      shouldNotifyByEmail,
      deployedProgramsExpiration,
      deployedProgramsExpirationTimestamp,
      patientProtocolDetails,
    } = this.state;
    const programs = newPrograms.reduce((acc, program, index) => {
      const { id } = program;
      const { roundDuration, roundsNumber } = program;
      acc[id] = omit(
        {
          ...program,
          programIndex: index,
          roundDuration: parseInt(roundDuration, 10),
          roundsNumber: parseInt(roundsNumber, 10),
        },
        'id'
      );
      return acc;
    }, {});

    Object.keys(programs).forEach(programId => {
      programs[programId].protocolDetails.programId = programId;
    });

    const shouldEndSesion =
      !addEditFlag &&
      this.state.trainingSessions &&
      this.state.lastSessionId &&
      this.state.trainingSessions[this.state.lastSessionId] &&
      this.state.trainingSessions[this.state.lastSessionId].setDuration >
        this.state.trainingSessions[this.state.lastSessionId].totalDuration &&
      Object.keys(this.state.trainingSessions[this.state.lastSessionId].rounds)
        .length > 1 &&
      this.state.clinicalTrainingProgram.roundsNumber <
        Object.keys(
          this.state.trainingSessions[this.state.lastSessionId].rounds
        ).length;

    if (shouldEndSesion) {
      // eslint-disable-next-line no-undef
      fireFunctionPost('sessions-endTrainingSession', {
        sessionId: this.state.lastSessionId,
      });
    }

    const shouldSaveSessionStatus =
      oldPrograms.every(
        program => program.sessionsOfProgramDone >= program.sessionsInProgram
      ) &&
      newPrograms.some(
        program => program.sessionsOfProgramDone < program.sessionsInProgram
      ) &&
      (!this.state.deployedProgramsExpirationTimestamp ||
        this.state.deployedProgramsExpirationTimestamp > Date.now());

    const deployedSessionDatabaseRef = database.ref(
      this.props.autoDeployableConfigs
        ? `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables`
        : `deployedSession/${this.props.clinicId}/${userId}`
    );
    await deployedSessionDatabaseRef
      .child(
        this.props.autoDeployableConfigs
          ? 'deployedPrograms/content'
          : 'programs'
      )
      .set(programs);
    await this.saveDeployedProgramsOrder({ userId, deployedProgramsOrder });
    await this.saveDeployedProgramsExpiration({
      userId,
      deployedProgramsExpiration,
      deployedProgramsExpirationTimestamp,
    });
    await this.saveShouldNotifyByEmail({ userId, shouldNotifyByEmail });

    const protocolsDatabaseRef = `/clinics/${this.props.clinicId}/configurations/protocols/${patientProtocolDetails.name}/lastDeployed`;
    database.ref(protocolsDatabaseRef).set(Date.now());

    if (shouldSaveSessionStatus) {
      const sessionStatus = {
        fromClient: false,
        text: 'WAITING_FOR_USER',
      };

      database
        .ref(
          this.props.autoDeployableConfigs
            ? `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables/sessionStatus/content`
            : `clinics/${this.props.clinicId}/currentSessions/${userId}/status`
        )
        .set(sessionStatus, error => {
          if (error) {
            new MyEvent('fast_create_session_status_error').log({
              customerId: userId,
              clinicId: sessionStorage.userId,
            });
          } else {
            new MyEvent('fast_create_session_status_created').log({
              customerId: userId,
              clinicId: sessionStorage.userId,
            });
          }
        });
      if (!this.props.autoDeployableConfigs) {
        database
          .ref(
            `deployedSession/${this.props.clinicId}/${userId}/lastProgramCreationTimestamp`
          )
          .set(Date.now());
      }
    }

    new MyEvent('fast_create_session_programs_saved').log({
      customerId: userId,
      entered_from: window.location.pathname.substring(1),
      modification: addEditFlag ? 'add' : 'edit',
      clinicId: sessionStorage.userId,
    });

    return programs;
  };

  createNewProgram() {
    const {
      clinicalTrainingProgram,
      patientProtocolDetails,
      currentPrograms,
      currentProgramName,
    } = this.state;

    const programKey = database
      .ref(
        this.props.autoDeployableConfigs
          ? `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables/deployedPrograms`
          : `deployedSession/${this.props.clinicId}/${this.state.userId}`
      )
      .push().key;
    const sessionsInProgram = parseInt(this.getNumOfSessions(), 10);
    const newProgram = {
      id: programKey,
      name: { en: currentProgramName },
      programIndex: 0,
      protocolDetails: {
        eyeState: 'Eyes open',
        ...cloneDeep(patientProtocolDetails),
      },
      roundDuration: clinicalTrainingProgram.roundDuration,
      roundsNumber: clinicalTrainingProgram.roundsNumber,
      sessionsInProgram,
      sessionsOfProgramDone: 0,
      sessionKeys: [],
      isProgramSettingsAdvancedMode: this.props.isProgramSettingsAdvancedMode,
    };
    const newCurrentPrograms = cloneDeep(currentPrograms).concat([newProgram]);
    this.savePrograms(newCurrentPrograms, currentPrograms, true);
    this.setState({ currentPrograms: newCurrentPrograms });

    showNotification('success', 'New program successfully added.');
  }

  editProgram = programIndex => {
    if (sessionStorage.disconnection) {
      showNotification('danger', 'Internet connectivity unavailable');
      return;
    }
    const {
      clinicalTrainingProgram,
      patientProtocolDetails,
      currentPrograms,
      currentProgramName,
    } = this.state;
    const sessionsInProgram = parseInt(this.getNumOfSessions(), 10);
    const newPrograms = cloneDeep(currentPrograms);
    const program = newPrograms[programIndex];
    program.protocolDetails = cloneDeep(patientProtocolDetails);
    const { roundDuration, roundsNumber } = clinicalTrainingProgram;
    Object.assign(program, {
      sessionsInProgram,
      roundDuration,
      roundsNumber,
      name: { en: currentProgramName },
      isProgramSettingsAdvancedMode: this.props.isProgramSettingsAdvancedMode,
    });

    this.savePrograms(newPrograms, currentPrograms, false);
    this.setState({ currentPrograms: newPrograms });

    showNotification('success', 'Program changes were saved.');
  };

  onBackBtnClick = async () => {
    const {
      renderedStep,
      prvRenderedStep,
      isNextBtnShown,
      userSelectedBySuggestion,
    } = this.state;

    switch (renderedStep) {
      case 'userSelect':
        this.closeModal();
        break;
      case 'programSettings': {
        this.setState({ shouldLoadAndProcessUserData: true });
        await this.loadAndUpdateUserData();
        this.setState({ shouldLoadAndProcessUserData: false });
        const isPrvStepDeployedPrograms =
          prvRenderedStep === 'deployedPrograms' || !userSelectedBySuggestion;
        this.setState({
          renderedStep: isPrvStepDeployedPrograms
            ? 'deployedPrograms'
            : 'userSelect',
          isNextBtnShown: isPrvStepDeployedPrograms ? isNextBtnShown : true,
          errors: { programSettings: {} },
        });
        break;
      }
      case 'channelsSelect':
        if (this.state.currentProgramIndex !== null) {
          this.setState({ shouldLoadAndProcessUserData: true });
          await this.loadAndUpdateUserData();
          this.setState({ shouldLoadAndProcessUserData: false });
        }
        this.setState({ renderedStep: 'programSettings' });
        break;
      case 'placementRecommendation':
        if (this.state.currentProgramIndex !== null) {
          this.setState({ shouldLoadAndProcessUserData: true });
          await this.loadAndUpdateUserData();
          this.setState({ shouldLoadAndProcessUserData: false });
        }
        this.setState({ renderedStep: 'programSettings' });
        break;
      case 'sessionOptions': {
        const { errors, clinicalTrainingProgram } = this.state;
        delete errors.programSettings.currentProgramName;
        if (errors.programSettings.numOfSessions) {
          delete errors.programSettings.numOfSessions;
          clinicalTrainingProgram.homeSessionProgram = this.createDefaultHomeSessionProgram();
          this.setState({ clinicalTrainingProgram });
        }
        if (this.props.isProgramSettingsAdvancedMode) {
          this.setState({
            renderedStep: 'channelsSelect',
            currentChannelSelection: 'activeChannel',
          });
        } else {
          this.setState({ renderedStep: 'placementRecommendation' });
        }
        break;
      }
      case 'deployedPrograms':
        this.setState({ isConfirmCancelingDialogOpen: true });
        break;
      default:
        break;
    }

    new MyEvent('fast_create_session_back_btn_click').log({
      rendered_step: renderedStep,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  onUserChange = (event, { newValue }) => {
    this.setState({
      displayName: newValue,
      isNextBtnShown: false,
    });
  };

  loadClinicalTrainingProgram = async userId => {
    const snapshot = await this.props.database
      .ref('clinicalTrainingProgram')
      .child(userId)
      .once('value', v => v);

    return snapshot.val();
  };

  loadDefaultBlockedUsageCredit = async () => {
    const snapshot = await this.props.database
      .ref(`billing/customPlans/nonfPlan/${sessionStorage.userId}/usageCredit`)
      .once('value', v => v);

    return snapshot.val();
  };

  loadSessionStatus = async userId => {
    const snapshot = await this.props.database
      .ref(`clinics/${this.props.clinicId}/currentSessions/${userId}/status`)
      .once('value', v => v);

    return snapshot.val();
  };

  loadUserInfo = async userId => {
    const snapshot = await this.props.database
      .ref(`userInfo/${userId}`)
      .once('value', v => v);

    return snapshot.val();
  };

  loadRegistrationCode = async userId => {
    const snapshot = await this.props.database
      .ref(`userInfo/${userId}/registrationCode`)
      .once('value', v => v);

    return snapshot.val();
  };

  loadReachedClientsLimit = async () => {
    const snapshot = await this.props.database
      .ref(
        `billing/currentPackages/${sessionStorage.userId}/reachedClientsLimit`
      )
      .once('value', v => v);

    return snapshot.val();
  };

  loadUsersIncluded = async () => {
    const snapshot = await this.props.database
      .ref(
        `billing/customPlans/nonfPlan/${sessionStorage.userId}/usersIncluded`
      )
      .once('value', v => v);

    return snapshot.val();
  };

  loadClinicNonfPlan = async () => {
    const snapshot = await this.props.database
      .ref(`billing/customPlans/nonfPlan/${sessionStorage.userId}`)
      .once('value', v => v);

    return snapshot.val();
  };

  loadNfFlag = async () => {
    const snapshot = await this.props.database
      .ref(
        `billing/customPlans/nonfPlan/${sessionStorage.userId}/nfAddOnPlanId`
      )
      .once('value', v => v);

    const snapshot2 = await this.props.database
      .ref(`billing/customPlans/baseHeadset/${sessionStorage.userId}`)
      .once('value', v => v);

    return snapshot.val() || snapshot2.val();
  };

  loadAssessmentAutoDeployableConfigs = async () => {
    const snapshot = await this.props.database
      .ref(
        `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedAssessment/content`
      )
      .once('value', v => v);

    return snapshot.val();
  };

  loadQuestionnairesAutoDeployableConfigs = async () => {
    const snapshot = await this.props.database
      .ref(
        `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedQuestionnaires/content`
      )
      .once('value', v => v);

    return snapshot.val();
  };

  loadClinicExpiration = async () => {
    const snapshot = await this.props.database
      .ref(`billing/currentPackages/${sessionStorage.userId}/expiration`)
      .once('value', v => v);

    return snapshot.val();
  };

  loadTrainingSessions = async () => {
    try {
      const snapshot = await this.props.database
        .ref(`trainingSessions/${this.state.userId}`)
        .once('value', v => v);
      return snapshot.val();
    } catch (e) {
      return null;
    }
  };

  loadClinicExpirationEnabled = async () => {
    try {
      const snapshot = await this.props.database
        .ref(`clinicConfig/expiration/${sessionStorage.userId}/isEnabled`)
        .once('value', v => v);
      return snapshot.val();
    } catch (e) {
      return null;
    }
  };

  loadToggleRounds = async () => {
    try {
      const snapshot = await this.props.database
        .ref(`clinicConfig/toggleRounds/${sessionStorage.userId}`)
        .once('value', v => v);
      return snapshot.val();
    } catch (e) {
      return null;
    }
  };

  loadUserPrice = async () => {
    try {
      const dormantPlans = await database
        .ref('config/dormantPlans')
        .once('value')
        .then(snapshot1 => snapshot1.val());

      const userPriceRef = await database
        .ref(
          `billing/packages/customNONFPackages/${dormantPlans.groupId}/${dormantPlans.packageIndex}/addOnOptions/nfAddOns/${dormantPlans.nfGroupId}/userPrice`
        )
        .once('value')
        .then(snapshot => snapshot.val());

      return userPriceRef;
    } catch (e) {
      return null;
    }
  };

  loadInitialUserData = async userId => {
    const patientProtocolDetails = await this.loadPatientProtocolDetails(
      userId
    );

    const clinicalTrainingProgram =
      (await this.loadClinicalTrainingProgram(userId)) ||
      defaultClinicalTrainingProgram;

    const patientInfo = await this.loadUserInfo(userId);

    const headsetDetails = await fetchHeadsetDetails({ customerId: userId });

    const deployedSession = await database
      .ref(`deployedSession/${this.props.clinicId}/${userId}`)
      .once('value')
      .then(snapshot => snapshot.val() || {});
    let isMultiProgramFeatureAllowed = false;
    try {
      isMultiProgramFeatureAllowed = await getFirebaseData(
        `clinicConfig/additionalFeatures/multiProgramFeature/${this.props.clinicId}/isAvailable`
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const isCustomerAppCompatibleWithMultiProtocol = await isAppCompilableWithMultiProtocol(
      {
        customerId: userId,
      }
    );

    const {
      programs = [],
      notifyByEmail = true,
      programSwitchType = deployedProgramsOrders[2],
      trainingExpiration,
    } = deployedSession;

    const currentPrograms = Object.entries(programs)
      .map(([id, program]) => ({
        id,
        ...program,
      }))
      .sort((p1, p2) => p1.programIndex - p2.programIndex);
    const initialCurrentPrograms = currentPrograms;

    const trainingSessions = await this.loadTrainingSessions();

    return {
      patientProtocolDetails,
      clinicalTrainingProgram,
      patientInfo,
      headsetDetails,
      currentPrograms,
      initialCurrentPrograms,
      shouldNotifyByEmail: notifyByEmail,
      deployedProgramsOrder: programSwitchType,
      isMultiProgramFeatureAllowed,
      isCustomerAppCompatibleWithMultiProtocol,
      trainingSessions,
      trainingExpiration,
    };
  };

  loadInitialClinicData = async () => {
    const patientProtocolDetails = await this.loadClinicProtocolDetails();

    const clinicalTrainingProgram = defaultClinicalTrainingProgram;

    const patientInfo = {
      firstName: 'Clinic',
      lastName: 'Settings',
      email: 'clinic@settings.com',
    };

    const headsetDetails = await fetchHeadsetDetails({ customerId: '' });

    const programs = await database
      .ref(
        `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables/deployedPrograms/content`
      )
      .once('value')
      .then(snapshot => snapshot.val() || []);

    const programSwitchType = await database
      .ref(
        `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables/programSwitchType/content`
      )
      .once('value')
      .then(snapshot => snapshot.val());

    // const deployedProgramsExpiration = await database
    //   .ref(
    //     `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables/deployedProgramsExpiration/content`
    //   )
    //   .once('value')
    //   .then(snapshot => snapshot.val());
    let isMultiProgramFeatureAllowed = false;
    try {
      isMultiProgramFeatureAllowed = await getFirebaseData(
        `clinicConfig/additionalFeatures/multiProgramFeature/${this.props.clinicId}/isAvailable`
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const notifyByEmail = await database
      .ref(
        `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables/notifyByEmail/content`
      )
      .once('value')
      .then(snapshot => snapshot.val());

    const isCustomerAppCompatibleWithMultiProtocol = true;

    const currentPrograms = Object.entries(programs)
      .map(([id, program]) => ({
        id,
        ...program,
      }))
      .sort((p1, p2) => p1.programIndex - p2.programIndex);
    const initialCurrentPrograms = currentPrograms;

    const trainingSessions = null;

    return {
      patientProtocolDetails,
      clinicalTrainingProgram,
      patientInfo,
      headsetDetails,
      currentPrograms,
      initialCurrentPrograms,
      shouldNotifyByEmail: !!notifyByEmail,
      deployedProgramsOrder: programSwitchType || deployedProgramsOrders[2],
      // deployedProgramsExpiration: deployedProgramsExpiration || deployedProgramsExpirationOptions[0],
      isMultiProgramFeatureAllowed,
      isCustomerAppCompatibleWithMultiProtocol,
      trainingSessions,
    };
  };

  onEditSelfRegistration = async () => {
    const patientInfo = await this.loadUserInfo(
      this.state.userSuggestion.userId
    );
    const id = this.state.userSuggestion.userId;
    this.closeModal();
    return this.props.openAddClientModal({
      isEditingMode: true,
      userId: id,
      userInfo: patientInfo,
      clinicId: sessionStorage.userId,
      shouldUseNewSymptomTracker: true,
      isSelfRegistration: true,
      initialStep: 'createAccount',
      finalStep: 'digitalCare',
    });
  };

  onSuggestionSelected = async (event, { suggestion }) => {
    if (!suggestion.userId) {
      new MyEvent('fast_create_session_add_new_client').log({
        suggestions_num: this.suggestedUsers.length,
        clinicId: sessionStorage.userId,
      });
      this.closeModal();
      return this.setState({ isSelfRegisterModalOpen: true });
      // return this.props.openAddClientModal();
    }

    if (suggestion.userInfo && suggestion.userInfo.clinicianSetUpRequired) {
      return this.setState({
        toggleCompleteSetupModal: true,
        userSuggestion: suggestion,
      });
    }

    new MyEvent('fast_create_session_select_client').log({
      patientId: suggestion.userId,
      clinicId: sessionStorage.userId,
    });

    if (this.autoComplete.current) {
      //  it seems that when this function is running the input will always be in focus,
      //  so we blur async
      setTimeout(() => this.autoComplete.current.input.blur(), 50);
    }

    this.setState({
      isNextBtnShown: true,
      isNextBtnLoading: true,
    });

    const {
      patientProtocolDetails,
      clinicalTrainingProgram,
      patientInfo,
      headsetDetails,
      currentPrograms,
      deployedProgramsOrder,
      shouldNotifyByEmail,
      isMultiProgramFeatureAllowed,
      initialCurrentPrograms,
      isCustomerAppCompatibleWithMultiProtocol,
      trainingSessions,
      trainingExpiration,
    } = await this.loadInitialUserData(suggestion.userId);

    const firstSimpleProtocol = this.props.protocols.find(
      protocol => protocol.isSimple
    );
    const selectedClinicalProtocol = firstSimpleProtocol;

    const baseNewState = {
      userId: suggestion.userId,
      displayName: `${suggestion.firstName} ${suggestion.lastName} (${suggestion.email})`,
      headsetDetails,
      patientInfo,
      patientProtocolDetails,
      isNextBtnLoading: false,
      currentPrograms,
      initialCurrentPrograms,
      deployedProgramsOrder,
      shouldNotifyByEmail,
      userSelectedBySuggestion: true,
      isMultiProgramFeatureAllowed,
      isCustomerAppCompatibleWithMultiProtocol,
      selectedClinicalProtocol,
      trainingSessions,
      deployedProgramsExpiration:
        trainingExpiration && trainingExpiration.type
          ? trainingExpiration.type
          : deployedProgramsExpirationOptions[0].value,
      deployedProgramsExpirationTimestamp:
        trainingExpiration && trainingExpiration.expiration
          ? trainingExpiration.expiration
          : null,
    };

    if (headsetDetails.isDispatched) {
      extendClinicalTrainingProgramByOneSessionIfNeeded(
        clinicalTrainingProgram
      );

      return this.setState({
        ...baseNewState,
        clinicalTrainingProgram,
        isEditingMode: true,
      });
    }

    return this.setState({
      ...baseNewState,
      clinicalTrainingProgram,
    });
  };

  getProtocolNameBasedOnPatientProtocol = patientProtocolDetails => {
    if (
      patientProtocolDetails.name &&
      Object.keys(this.props.protocols).find(
        protocolId =>
          patientProtocolDetails.name ===
          (this.props.protocols[protocolId] &&
            this.props.protocols[protocolId].name)
      )
    ) {
      return patientProtocolDetails.name;
    }
    const [protocolIndex] = this.props.searchProtocol(
      patientProtocolDetails,
      this.props.protocols
    );

    return this.props.protocols[protocolIndex]
      ? this.props.protocols[protocolIndex].name
      : patientProtocolDetails.name;
  };

  loadPatientProtocolDetails = async userId => {
    const snapshot = await this.props.database
      .ref('thresholdDetails')
      .child(userId)
      .once('value', v => v);
    return new this.props.PatientProtocolDetails(snapshot.val());
  };

  loadClinicProtocolDetails = async () => {
    const snapshot = await this.props.database
      .ref('defaults/demoUser/thresholdDetails')
      .once('value', v => v);

    return new this.props.PatientProtocolDetails(snapshot.val());
  };

  static setClinicalTrainingProgramTime = ({
    clinicalTrainingProgram,
    selectedClinicalProtocol,
  }) => {
    const newClinicalTrainingProgram = cloneDeep(clinicalTrainingProgram);
    const middleIndex = Math.round(
      (selectedClinicalProtocol.durations.length - 1) / 2
    );
    newClinicalTrainingProgram.roundDuration =
      selectedClinicalProtocol.durations[middleIndex].minutes;

    newClinicalTrainingProgram.roundsNumber =
      selectedClinicalProtocol.durations[middleIndex].rounds;

    return newClinicalTrainingProgram;
  };

  onProtocolChange = event => {
    const { isProgramSettingsAdvancedMode } = this.props;

    if (isProgramSettingsAdvancedMode) {
      const selectedProtocol = event.target.value;
      const prvPatientProtocolDetails = this.state.patientProtocolDetails;
      const patientProtocolDetails = this.props.modifyProtocol(
        prvPatientProtocolDetails,
        selectedProtocol
      );
      this.setState({
        patientProtocolDetails,
      });
      this.validateOverAllSuccessRate();
    } else {
      const selectedClinicalProtocol = event.target.value;
      const prvPatientProtocolDetails = this.state.patientProtocolDetails;
      const patientProtocolDetails = this.props.modifyProtocol(
        prvPatientProtocolDetails,
        selectedClinicalProtocol
      );
      const newSelectedClinicalProtocol = this.props.protocols.find(
        protocol => protocol.name === selectedClinicalProtocol
      );

      const { clinicalTrainingProgram } = this.state;
      const newClinicalTrainingProgram = CreateProgramPopUpModal.setClinicalTrainingProgramTime(
        {
          clinicalTrainingProgram,
          selectedClinicalProtocol: newSelectedClinicalProtocol,
        }
      );

      if (this.state.currentProgramIndex !== null) {
        const chosenPlacement = this.props.getChosenPlacement(
          newSelectedClinicalProtocol
        );
        // eslint-disable-next-line no-shadow
        let { patientProtocolDetails } = this.state;
        patientProtocolDetails = this.updatePatientProtocolBasedOnChosenPlacement(
          {
            patientProtocolDetails,
            chosenPlacement,
          }
        );

        this.setState({
          // renderedStep: 'placementRecommendation',
          chosenPlacement,
          recommendedChosenPlacement: chosenPlacement,
          patientProtocolDetails,
        });
      }

      this.setState({
        patientProtocolDetails,
        clinicalTrainingProgram: newClinicalTrainingProgram,
        selectedClinicalProtocol: newSelectedClinicalProtocol,
      });
    }

    new MyEvent('fast_create_session_change_protocol').log({
      protocol_name: event.target.value,
      advanced_mode: this.state.isProgramSettingsAdvancedMode,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  updateFrequency(frequencyType, frequencyIndex, frequencyValue) {
    const { patientProtocolDetails } = this.state;
    if (frequencyType === 'inhibiting') {
      patientProtocolDetails.inhibitingFrequencies[
        frequencyIndex
      ].calibrationPercentile = frequencyValue;
    }

    if (frequencyType === 'rewarding') {
      patientProtocolDetails.rewardFrequencies[
        frequencyIndex
      ].calibrationPercentile = frequencyValue;
    }

    this.setState({ patientProtocolDetails });
  }

  renderStepper() {
    const steps = this.props.isProgramSettingsAdvancedMode
      ? advancedPopupSteps
      : basicPopupSteps;

    return (
      <div className={styles.stepper_container}>
        <Stepper alternativeLabel classes={{ root: styles.stepper_root }}>
          {steps.map((label, i) => {
            return (
              <Step
                key={label}
                // className={styles.step}
                classes={{ alternativeLabel: styles.step }}
                active={this.state.renderedStep === label}
                completed={
                  steps.indexOf(this.state.renderedStep) > steps.indexOf(label)
                }
              >
                <StepLabel>
                  <span className={styles.step_label}>{formmatedSteps[i]}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }

  renderUserSelectStep() {
    const { suggestions, displayName } = this.state;
    const { t } = this.props;

    const inputProps = {
      placeholder: t('type_client_name_email'),
      value: displayName,
      onChange: this.onUserChange,
    };

    let primaryBtnContent;
    let isPrimaryBtnDisabled;
    let primarySecondBtnContent;
    if (!this.state.isNextBtnShown) {
      primaryBtnContent = '';
    } else if (this.state.isNextBtnLoading) {
      primaryBtnContent = <CircularProgress size={26} />;
      isPrimaryBtnDisabled = true;
    } else {
      primaryBtnContent = 'Training';
      primarySecondBtnContent = 'Assessment';
    }

    return (
      <React.Fragment>
        <ModalHeader
          leftContent={
            <div className={styles.modal_header_left_side_content}>
              <h4>Create or Edit Training/Assessment</h4>
              {/* <p>{t('which_client')}</p> */}
            </div>
          }
          isCloseIconShown={false}
        />

        <div className={styles.modal_body} style={{ overflow: 'visible' }}>
          <div className={styles.user_select_input_container}>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
              onSuggestionSelected={this.onSuggestionSelected}
              theme={{
                input: styles.input,
                suggestionsList: styles.autocomplete_suggestion_list,
                suggestionsContainerOpen:
                  styles.autocomplete_suggestion_list_open_container,
                inputFocused: styles.autocomplete_suggestion_focused,
              }}
              ref={this.autoComplete}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
            />
            <div className={styles.search_icon_container}>
              <SearchIcon />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {this.renderModalFooter({
            primaryBtnContent,
            secondaryBtnContent: t('cancel'),
            isPrimaryBtnDisabled,
          })}
          {primarySecondBtnContent ? (
            <Button
              color="primary"
              variant="contained"
              style={{
                fontSize: 14,
                padding: '6px 16px',
                height: 37,
                position: 'relative',
                top: 12,
                marginRight: 25,
              }}
              onClick={() => {
                // eslint-disable-next-line no-undef
                openAssessmentPopup({ userId: this.state.userId, t });
                this.closeModal();
              }}
            >
              {primarySecondBtnContent}
            </Button>
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  loadAndUpdateUserData = async (shouldRenderProgramSettings = false) => {
    const {
      patientProtocolDetails: initialPatientProtocolDetails,
      clinicalTrainingProgram: initialClinicalTrainingProgram,
      patientInfo,
      headsetDetails,
      currentPrograms,
      initialCurrentPrograms,
      deployedProgramsOrder,
      shouldNotifyByEmail,
      isMultiProgramFeatureAllowed,
      isCustomerAppCompatibleWithMultiProtocol,
      trainingSessions,
      trainingExpiration,
    } = this.props.autoDeployableConfigs
      ? // eslint-disable-next-line no-use-before-define
        await this.loadInitialClinicData(initialPatientProtocolDetails)
      : await this.loadInitialUserData(this.state.userId);
    const {
      patientProtocolDetails,
      clinicalTrainingProgram,
      selectedClinicalProtocol,
    } = this.getDerivedUserData({
      patientProtocolDetails: initialPatientProtocolDetails,
      clinicalTrainingProgram: initialClinicalTrainingProgram,
    });

    if (headsetDetails.isDispatched) {
      extendClinicalTrainingProgramByOneSessionIfNeeded(
        clinicalTrainingProgram
      );
    }
    const clinicUserInfo = await this.loadUserInfo(this.props.clinicId);

    this.setState({
      patientProtocolDetails,
      clinicalTrainingProgram,
      selectedClinicalProtocol,
      patientInfo,
      shouldLoadAndProcessUserData: false,
      headsetDetails,
      currentPrograms,
      initialCurrentPrograms,
      deployedProgramsOrder,
      shouldNotifyByEmail,
      isMultiProgramFeatureAllowed,
      isCustomerAppCompatibleWithMultiProtocol,
      renderedStep:
        currentPrograms.length === 0 || shouldRenderProgramSettings
          ? 'programSettings'
          : 'deployedPrograms',
      trainingSessions,
      deployedProgramsExpiration:
        trainingExpiration && trainingExpiration.type
          ? trainingExpiration.type
          : deployedProgramsExpirationOptions[0].value,
      deployedProgramsExpirationTimestamp:
        trainingExpiration && trainingExpiration.expiration
          ? trainingExpiration.expiration
          : null,
      clinicUserInfo,
    });
  };

  renderProgramSettingsStep = () => {
    const { t } = this.props;
    const {
      userSelectedBySuggestion,
      initialCurrentPrograms,
      currentPrograms,
    } = this.state;
    const isPrimaryBtnDisabled =
      Object.keys(this.state.errors.programSettings).length > 0;

    const primaryBtnContent =
      this.state.currentProgramIndex !== null ? t('save') : t('next');

    const renderProgramSettingsBodyContent = () =>
      this.props.isProgramSettingsAdvancedMode
        ? // ||
          // this.state.currentProgramIndex !== null
          this.renderAdvancedProgramSettingsStep()
        : this.renderBasicProgramSettingsStep();

    const shouldShowCancelButton =
      !userSelectedBySuggestion &&
      currentPrograms === initialCurrentPrograms &&
      initialCurrentPrograms.length === 0;
    return (
      <React.Fragment>
        <ModalHeader
          leftContent={<h4>{t('program_settings')}</h4>}
          rightContent={
            true && (
              <div className={styles.protocol_mode_toggle_container}>
                <p>{t('advance-mode')}</p>
                <div className={styles.protocol_mode_toggle}>
                  <Switch
                    checked={this.props.isProgramSettingsAdvancedMode}
                    onChange={async e => {
                      this.props.onProgramSettingsAdvancedModeChange(e);
                      // when switching to basic from advanced on edit switch channels by protocol
                      if (
                        this.props.isProgramSettingsAdvancedMode &&
                        this.state.currentProgramIndex !== null
                      ) {
                        const chosenPlacement = this.props.getChosenPlacement(
                          this.state.selectedClinicalProtocol
                        );
                        // eslint-disable-next-line no-shadow
                        let { patientProtocolDetails } = this.state;
                        patientProtocolDetails = this.updatePatientProtocolBasedOnChosenPlacement(
                          {
                            patientProtocolDetails,
                            chosenPlacement,
                          }
                        );

                        this.setState({
                          // renderedStep: 'placementRecommendation',
                          chosenPlacement,
                          recommendedChosenPlacement: chosenPlacement,
                          patientProtocolDetails,
                        });
                      }
                    }}
                    color="primary"
                  />
                </div>
              </div>
            )
          }
          isCloseIconShown={false}
        />
        {this.state.currentProgramIndex === null ? this.renderStepper() : null}

        <div className={styles.modal_body}>
          {renderProgramSettingsBodyContent()}
        </div>

        {this.renderModalFooter({
          isPrimaryBtnDisabled,
          secondaryBtnContent: shouldShowCancelButton ? t('cancel') : t('back'),
          onSecondaryBtnClick: shouldShowCancelButton
            ? this.closeModal
            : undefined,
          primaryBtnContent,
        })}
      </React.Fragment>
    );
  };

  t = this.props.t;

  renderModalFooter = ({
    primaryBtnContent = this.t('next'),
    secondaryBtnContent = this.t('back'),
    onSecondaryBtnClick = this.onBackBtnClick,
    onPrimaryBtnClick = this.onNextBtnClick,
    isPrimaryBtnDisabled = false,
    isSecondaryBtnDisabled = false,
  } = {}) => (
    <ModalFooter
      primaryBtnContent={primaryBtnContent}
      onPrimaryBtnClick={onPrimaryBtnClick}
      secondaryBtnContent={secondaryBtnContent}
      onSecondaryBtnClick={onSecondaryBtnClick}
      isPrimaryBtnDisabled={isPrimaryBtnDisabled}
      isSecondaryBtnDisabled={isSecondaryBtnDisabled}
    />
  );

  onEyesClosedChangeAdvancedMode = event => {
    const isEyesClosed = event.target.checked;
    this.updateIsEyesClosed(isEyesClosed);

    new MyEvent('fast_create_session_change_eyes_closed').log({
      isEyesClosed,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  onEyesClosedChangeBasicMode = event => {
    const isEyesClosed = event.target.value === 'true';
    this.updateIsEyesClosed(isEyesClosed);

    new MyEvent('fast_create_session_basic_eye_state_change').log({
      isEyesClosed,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  updateIsEyesClosed = isEyesClosed => {
    const eyeState = isEyesClosed ? 'Eyes closed' : 'Eyes open';
    this.state.patientProtocolDetails.eyeState = eyeState;
    this.setState({
      patientProtocolDetails: this.state.patientProtocolDetails,
    });
  };

  closeModal = () => {
    this.setState(createDefaultState(this.props));
    this.props.closeModal();
  };

  renderAdvancedProgramSettingsStep() {
    const { t } = this.props;
    const onFrequencyChange = event => {
      const { value } = event.target;
      const parsedValue = parseFloat(value, 10);

      const freqType = event.target.getAttribute('data-frequency-type');
      const freqName = event.target.getAttribute('data-frequency-name');
      const freqIndex = parseInt(
        event.target.getAttribute('data-frequency-index'),
        10
      );
      this.updateFrequency(freqType, freqIndex, parsedValue);

      new MyEvent('fast_create_session_advanced_change_success_rate').log({
        freq_type: freqType,
        freq_name: freqName,
        freq_index: freqIndex,
        value,
        clinicId: sessionStorage.userId,
        customerId: this.state.userId,
      });

      const frequencyId = `${freqName}-${freqType}`;
      const { errors } = this.state;
      if (
        parsedValue < minFrequency ||
        parsedValue > maxFrequency ||
        Number.isNaN(parsedValue)
      ) {
        errors.programSettings[frequencyId] = t('error_frequency');
      } else if (parsedValue % 1 !== 0) {
        errors.programSettings[frequencyId] = t(
          'error_frequency_number_must_be_int'
        );
      } else {
        delete errors.programSettings[frequencyId];
      }

      this.validateOverAllSuccessRate();
      this.setState({ errors });
    };

    const onRoundDurationChange = value => {
      // const { value } = event.target;
      const parsedValue = parseInt(value, 10);

      const { clinicalTrainingProgram, errors } = this.state;
      clinicalTrainingProgram.roundDuration = parsedValue;
      if (parsedValue < 1 || Number.isNaN(parsedValue)) {
        errors.programSettings.roundDuration = t('error_greater_than_zero');
      } else if (parsedValue > 1000) {
        errors.programSettings.roundDuration = t(
          'error_smaller_equal_one_thousand'
        );
      } else {
        delete errors.programSettings.roundDuration;
      }
      this.setState({ errors, clinicalTrainingProgram });

      new MyEvent('fast_create_session_advanced_change_round_duration').log({
        round_duration: value,
        clinicId: sessionStorage.userId,
        customerId: this.state.userId,
      });
    };

    const onChoseDifferentChannelsClick = () => {
      const { channelDetails } = this.state.patientProtocolDetails;
      const activeChannel =
        channelDetails.training.auxiliaryLocation ||
        channelDetails.training.name;
      const referenceChannel =
        channelDetails.reference.auxiliaryLocation ||
        channelDetails.reference.name;

      const disabledChannelsToBeSelected =
        referenceChannel === CHANNEL_IDS.Fpz ? [] : [CHANNEL_IDS.Fpz];

      this.setState({
        renderedStep: 'channelsSelect',
        currentChannelSelection: 'activeChannel',
        activeChannel,
        referenceChannel,
        disabledChannelsToBeSelected,
      });

      new MyEvent('fast_create_session_editing_choose_different_channels').log({
        clinicId: sessionStorage.userId,
        customerId: this.state.userId,
      });
    };

    const onNumOfRoundsChange = value => {
      // const { value } = event.target;
      const parsedValue = parseInt(value, 10);

      const { clinicalTrainingProgram, errors } = this.state;
      clinicalTrainingProgram.roundsNumber = parsedValue;
      if (parsedValue < 1 || Number.isNaN(parsedValue)) {
        errors.programSettings.numOfRounds = t('error_greater_than_zero');
      } else if (parsedValue > 1000) {
        errors.programSettings.numOfRounds = t(
          'error_smaller_equal_one_thousand'
        );
      } else if (parsedValue % 1 !== 0) {
        errors.programSettings.numOfRounds = t(
          'error_rounds_number_must_be_int'
        );
      } else {
        delete errors.programSettings.numOfRounds;
      }
      this.setState({ errors, clinicalTrainingProgram });

      new MyEvent('fast_create_session_advanced_change_number_of_rounds').log({
        num_of_rounds: value,
        clinicId: sessionStorage.userId,
        customerId: this.state.userId,
      });
    };

    const getClosestFact = val => {
      let testNum = Math.floor(Math.sqrt(val));
      if (testNum < 2) {
        return [1, +val];
      }
      while (val % testNum !== 0) {
        testNum -= 1;
      }
      return [testNum, val / testNum];
    };

    const onSessionDurationChanged = value => {
      if (!value) {
        onNumOfRoundsChange('');
        onRoundDurationChange('');
        return;
      }
      const durations = getClosestFact(value);
      onNumOfRoundsChange(durations[0]);
      onRoundDurationChange(durations[1]);
    };

    const onToggleRoundsClicked = () => {
      const clinicConfigDatabaseRef = database.ref(
        `clinicConfig/toggleRounds/${sessionStorage.userId}`
      );
      clinicConfigDatabaseRef.set(!this.state.toggleRounds);
      this.setState({ toggleRounds: !this.state.toggleRounds });
    };

    const numOfRounds = this.state.clinicalTrainingProgram.roundsNumber;
    const { roundDuration } = this.state.clinicalTrainingProgram;

    return (
      <ProgramSettingsStep
        isProgramSettingsAdvancedMode={this.props.isProgramSettingsAdvancedMode}
        protocols={this.props.protocols}
        patientProtocolDetails={this.state.patientProtocolDetails}
        patientProtocolDetailsName={this.getProtocolNameBasedOnPatientProtocol(
          this.state.patientProtocolDetails
        )}
        onProtocolChange={this.onProtocolChange}
        onFrequencyChange={onFrequencyChange}
        numOfRounds={numOfRounds}
        roundDuration={roundDuration}
        onRoundDurationChange={onRoundDurationChange}
        onNumOfRoundsChange={onNumOfRoundsChange}
        isEyesClosed={this.getIsEyesClosedState()}
        onEyesClosedChange={this.onEyesClosedChangeAdvancedMode}
        extractFrequencyName={this.props.extractFrequencyName}
        minFrequency={minFrequency}
        maxFrequency={maxFrequency}
        errors={this.state.errors.programSettings}
        overAllSuccessRate={this.getOverAllSuccessRate()}
        isEditingMode={this.state.currentProgramIndex !== null}
        onChoseDifferentChannelsClick={onChoseDifferentChannelsClick}
        numOfCompletedSessions={this.state.numOfCompletedSessions}
        onNumOfSessionsChange={this.onNumOfSessionsChange}
        numOfSessions={this.getNumOfSessions()}
        headsetDetails={this.state.headsetDetails}
        patientId={this.state.userId}
        onSwitchHeadsetToNormalModeCompleted={() => {
          this.setState({ shouldLoadAndProcessUserData: true });
        }}
        onSessionDurationChanged={onSessionDurationChanged}
        toggleRounds={this.state.toggleRounds}
        onToggleRoundsClicked={onToggleRoundsClicked}
      />
    );
  }

  validateOverAllSuccessRate = () => {
    const { t } = this.props;
    const { errors } = this.state;
    const overAllSuccessRate = this.getOverAllSuccessRate();
    if (
      overAllSuccessRate < minimumOverallSuccessRate ||
      overAllSuccessRate > maximumOverallSuccessRate ||
      Number.isNaN(overAllSuccessRate)
    ) {
      errors.programSettings.estimatedPositiveFeedback = t(
        'error_estimated_positive_feedback'
      );
    } else {
      delete errors.programSettings.estimatedPositiveFeedback;
    }
  };

  renderBasicProgramSettingsStep() {
    const { clinicalTrainingProgram } = this.state;
    const sessionDuration =
      clinicalTrainingProgram.roundDuration *
      clinicalTrainingProgram.roundsNumber;

    const onChoseDifferentPlacementClick = () => {
      const chosenPlacement = this.props.getChosenPlacement(
        this.state.selectedClinicalProtocol
      );

      let { patientProtocolDetails } = this.state;
      patientProtocolDetails = this.updatePatientProtocolBasedOnChosenPlacement(
        {
          patientProtocolDetails,
          chosenPlacement,
        }
      );

      this.setState({
        renderedStep: 'placementRecommendation',
        chosenPlacement,
        recommendedChosenPlacement: chosenPlacement,
        patientProtocolDetails,
      });
    };

    const onSessionDurationChange = event => {
      // eslint-disable-next-line no-shadow
      const sessionDuration = parseInt(event.target.value, 10);

      const durationObject = this.state.selectedClinicalProtocol.durations.find(
        duration => duration.minutes * duration.rounds === sessionDuration
      );

      const { patientProtocolDetails } = this.state;
      patientProtocolDetails.duration = sessionDuration;

      // eslint-disable-next-line no-shadow
      const { clinicalTrainingProgram } = this.state;
      clinicalTrainingProgram.roundDuration = durationObject.minutes;
      clinicalTrainingProgram.roundsNumber = durationObject.rounds;

      this.setState({
        patientProtocolDetails,
        clinicalTrainingProgram,
      });

      new MyEvent('fast_create_session_change_duration_simple').log({
        session_duration: sessionDuration,
        clinicId: sessionStorage.userId,
        customerId: this.state.userId,
      });
    };

    return (
      <ProgramSettingsStep
        selectedProtocol={this.state.selectedClinicalProtocol}
        isProgramSettingsAdvancedMode={this.props.isProgramSettingsAdvancedMode}
        onProtocolChange={this.onProtocolChange}
        protocols={this.props.protocols}
        sessionDuration={sessionDuration}
        onSessionDurationChange={onSessionDurationChange}
        overAllSuccessRate={this.getOverAllSuccessRate()}
        isEditingMode={this.state.currentProgramIndex !== null}
        numOfCompletedSessions={this.state.numOfCompletedSessions}
        onNumOfSessionsChange={this.onNumOfSessionsChange}
        numOfSessions={this.getNumOfSessions()}
        onChoseDifferentChannelsClick={onChoseDifferentPlacementClick}
        patientProtocolDetails={this.state.patientProtocolDetails}
        isEyesClosed={this.getIsEyesClosedState()}
        errors={this.state.errors.programSettings}
      />
    );
  }

  getDisabledChannelsToBeSelected = channelName =>
    Object.keys(CHANNELS).filter(
      channel => isAuxiliaryChannel(channel) && channel !== channelName
    );

  onCloseIconClick = () => {
    this.closeModal();
    new MyEvent('fast_create_session_on_close_icon_click').log({
      renderedStep: this.state.renderedStep,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  renderLoadingStep = () => (
    <div className={styles.progress_container}>
      <CircularProgress />
    </div>
  );

  renderChannelsSelectStep() {
    const { t } = this.props;
    const { activeChannel, referenceChannel } = this.state;

    const onChannelClick = event => {
      const channelName = event.target.id.replace('channel_', '');

      if (
        this.state.disabledChannelsToBeSelected.includes(channelName) ||
        !this.state.currentChannelSelection
      ) {
        return;
      }

      if (
        this.state.activeChannel === channelName ||
        this.state.referenceChannel === channelName
      ) {
        return;
      }

      if (isAuxiliaryChannel(channelName)) {
        const disabledChannelsToBeSelected = this.getDisabledChannelsToBeSelected(
          channelName
        );
        this.setState({ disabledChannelsToBeSelected });
      } else {
        const previousSelectedChannel =
          this.state.currentChannelSelection === 'activeChannel'
            ? referenceChannel
            : activeChannel;

        if (!isAuxiliaryChannel(previousSelectedChannel)) {
          this.setState({ disabledChannelsToBeSelected: [] });
        }
      }

      let { patientProtocolDetails } = this.state;
      if (
        this.state.currentChannelSelection === 'activeChannel' &&
        referenceChannel !== channelName
      ) {
        patientProtocolDetails = updatePatientProtocolDetailsChannels({
          patientProtocolDetails: this.state.patientProtocolDetails,
          channelName,
          channelType: 'training',
        });

        let { disabledChannelsToBeSelected } = this.state;
        if (
          disabledChannelsToBeSelected.length > 0 &&
          !isAuxiliaryChannel(channelName)
        ) {
          disabledChannelsToBeSelected = [];
          this.setState({
            disabledChannelsToBeSelected,
          });
        }

        this.setState({
          currentChannelSelection: 'referenceChannel',
          activeChannel: channelName,
        });

        new MyEvent('fast_create_session_advanced_change_channel').log({
          channel_type: 'training',
          channel_name: channelName,
          clinicId: sessionStorage.userId,
          customerId: this.state.userId,
        });

        if (referenceChannel !== null) {
          this.setState({
            isNextBtnShown: true,
          });
        }
      }
      if (
        this.state.currentChannelSelection === 'referenceChannel' &&
        activeChannel !== channelName
      ) {
        patientProtocolDetails = updatePatientProtocolDetailsChannels({
          patientProtocolDetails: this.state.patientProtocolDetails,
          channelName,
          channelType: 'reference',
        });

        new MyEvent('fast_create_session_advanced_change_channel').log({
          channel_type: 'reference',
          channel_name: channelName,
          clinicId: sessionStorage.userId,
          customerId: this.state.userId,
        });

        this.setState({
          referenceChannel: channelName,
          currentChannelSelection: null,
        });

        if (activeChannel !== null) {
          this.setState({
            isNextBtnShown: true,
          });
        }
      }

      this.setState({ patientProtocolDetails });
    };

    const primaryBtnContent = t('next');
    const secondaryBtnContent = t('back');

    return (
      <React.Fragment>
        <ModalHeader
          leftContent={
            <div className={styles.modal_header_left_side_content}>
              <h4>{t('select_channels')}</h4>
              <p>{t('select_channels_sub_header')}</p>
            </div>
          }
          isCloseIconShown={false}
        />
        {this.state.currentProgramIndex === null ? this.renderStepper() : null}

        <div className={styles.modal_body}>
          <SelectChannelsStep
            onChannelClick={onChannelClick}
            activeChannel={activeChannel}
            referenceChannel={referenceChannel}
            currentChannelSelection={this.state.currentChannelSelection}
            onChannelTypeTabClick={event => {
              const currentChannelSelection = event.target.id;
              let { disabledChannelsToBeSelected } = this.state;
              if (
                currentChannelSelection === 'activeChannel' &&
                isAuxiliaryChannel(this.state.activeChannel)
              ) {
                disabledChannelsToBeSelected = [];
              }

              if (
                currentChannelSelection === 'referenceChannel' &&
                isAuxiliaryChannel(this.state.referenceChannel)
              ) {
                disabledChannelsToBeSelected = [];
              }

              if (
                currentChannelSelection === 'referenceChannel' &&
                isAuxiliaryChannel(this.state.activeChannel)
              ) {
                disabledChannelsToBeSelected = this.getDisabledChannelsToBeSelected(
                  this.state.activeChannel
                );
              }

              if (
                currentChannelSelection === 'activeChannel' &&
                isAuxiliaryChannel(this.state.referenceChannel)
              ) {
                disabledChannelsToBeSelected = this.getDisabledChannelsToBeSelected(
                  this.state.referenceChannel
                );
              }

              if (
                currentChannelSelection === 'activeChannel' &&
                this.state.referenceChannel !== CHANNEL_IDS.Fpz
              ) {
                disabledChannelsToBeSelected.push(CHANNEL_IDS.Fpz);
              }

              if (currentChannelSelection === 'referenceChannel') {
                disabledChannelsToBeSelected = disabledChannelsToBeSelected.filter(
                  channel => channel !== CHANNEL_IDS.Fpz
                );
              }

              this.setState({
                currentChannelSelection,
                disabledChannelsToBeSelected,
              });

              new MyEvent(
                'fast_create_session_advanced_change_channel_selection_type'
              ).log(
                {
                  selection_type: currentChannelSelection,
                  clinicId: sessionStorage.userId,
                  customerId: this.state.userId,
                },
                true
              );
            }}
            disabledChannelsToBeSelected={
              this.state.disabledChannelsToBeSelected
            }
            enabledChannelsToBeSelected={
              this.state.currentChannelSelection ? Object.keys(CHANNELS) : []
            }
          />
        </div>

        <div className={styles.modal_footer_container_select_channel}>
          {this.state.errors.selectChannels && (
            <div className={styles.select_channel_error_container}>
              <p>{this.state.errors.selectChannels}</p>
            </div>
          )}
          <div style={{ marginLeft: 'auto' }}>
            {this.renderModalFooter({
              primaryBtnContent: this.state.isNextBtnShown
                ? primaryBtnContent
                : '',
              secondaryBtnContent,
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPlacementRecommendationStep() {
    const { t } = this.props;
    const onChosenPlacementNameChange = placementName => {
      const chosenPlacement = this.props.placements[placementName];
      let { patientProtocolDetails } = this.state;

      patientProtocolDetails = this.updatePatientProtocolBasedOnChosenPlacement(
        {
          patientProtocolDetails,
          chosenPlacement,
        }
      );
      this.setState({
        chosenPlacement,
        patientProtocolDetails,
        chosenPlacementName: this.props.placements[placementName],
      });

      if (chosenPlacement !== null) {
        this.setState({
          isNextBtnShown: true,
        });
      }

      new MyEvent('fast_create_session_basic_change_placement').log({
        ...chosenPlacement,
        customerId: this.state.userId,
        clinicId: sessionStorage.userId,
      });
    };

    const chosenPlacementName = this.props.getRecommendedPlacementNameBasedOnPlacement(
      this.state.chosenPlacement
    );

    const recommendedChosenPlacementName = this.props.getRecommendedPlacementNameBasedOnPlacement(
      this.state.recommendedChosenPlacement
    );

    const placementDescriptionHTML = this.props.getChoosenPlacementDescriptionHTML(
      this.state.chosenPlacement
    );

    const primaryBtnContent = t('next');
    const secondaryBtnContent = t('back');

    return (
      <React.Fragment>
        <ModalHeader
          leftContent={
            <div className={styles.modal_header_left_side_content}>
              <h4>{t('training-set-up')}</h4>
            </div>
          }
          isCloseIconShown={false}
        />
        {this.state.currentProgramIndex === null ? this.renderStepper() : null}

        <div className={styles.modal_body}>
          <PlacementRecommendationStep
            onEyesClosedChange={this.onEyesClosedChangeBasicMode}
            isEyesClosed={this.getIsEyesClosedState()}
            chosenPlacementName={chosenPlacementName}
            onChosenPlacementNameChange={onChosenPlacementNameChange}
            placementDescriptionHTML={placementDescriptionHTML}
            placements={this.props.placements}
            recommendedChosenPlacementName={recommendedChosenPlacementName}
            originalEyeState={this.state.selectedClinicalProtocol.eyeState}
            customerId={this.state.userId}
            isEditingMode={this.state.currentProgramIndex !== null}
          />
        </div>

        <div style={{ marginLeft: 'auto' }}>
          {this.renderModalFooter({
            primaryBtnContent,
            secondaryBtnContent,
          })}
        </div>
      </React.Fragment>
    );
  }

  onNumOfSessionsChange = event => {
    const { t } = this.props;
    const { value } = event.target;
    const parsedValue = parseFloat(value, 10);

    const { numOfCompletedSessions, errors } = this.state;

    if (parsedValue <= numOfCompletedSessions || Number.isNaN(parsedValue)) {
      errors.programSettings.numOfSessions = `${t(
        'error_num_sessions_greater_than_completed'
      )} (${numOfCompletedSessions})`;
      if (numOfCompletedSessions === 0) {
        errors.programSettings.numOfSessions = t('error_num_of_sessions');
      }
    } else if (parsedValue % 1 !== 0) {
      errors.programSettings.numOfSessions = t(
        'error_session_number_must_be_int'
      );
    } else if (parsedValue > 1000) {
      errors.programSettings.numOfSessions = t(
        'error_smaller_equal_one_thousand'
      );
    } else {
      delete errors.programSettings.numOfSessions;
    }

    this.setState({ numOfSessions: parsedValue });

    new MyEvent('fast_create_session_change_nums_of_sessions').log({
      value,
      clinicId: sessionStorage.userId,
      customerId: this.state.userId,
    });
  };

  onCurrentProgramChange = e => {
    const currentProgramName = e.target.value;
    const { errors } = this.state;
    if (currentProgramName.trim() === '') {
      errors.programSettings.currentProgramName = 'Program name is required';
    } else if (currentProgramName.length > programNameMaxLength) {
      errors.programSettings.currentProgramName = longProgramNameErrorMsg;
    } else {
      delete errors.programSettings.currentProgramName;
    }
    this.setState({ currentProgramName, errors });
  };

  createDefaultHomeSessionProgram = ({ maxNumOfHomeSessions = 1 } = {}) => ({
    maxNumOfHomeSessions,
    homeSessionsUsed: 0,
    homeSessionCreated: true,
  });

  getNumOfSessions = () => this.state.numOfSessions;

  renderSessionOptionsStep() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <ModalHeader
          leftContent={
            <div className={styles.modal_header_left_side_content}>
              <h4>{t('program_options')}</h4>
            </div>
          }
          isCloseIconShown={false}
        />
        {this.renderStepper()}

        <div className={styles.modal_body}>
          <div className={styles.session_options_body_container}>
            <div className={styles.session_option}>
              <p className={styles.session_options_main_option}>
                {t('number-of-sessions')}
              </p>
              <FormControl
                classes={{
                  root: styles.mu_input_form_control,
                }}
                error={!!this.state.errors.programSettings.numOfSessions}
              >
                <Input
                  type="number"
                  value={this.getNumOfSessions()}
                  onChange={this.onNumOfSessionsChange}
                  inputProps={{
                    min: 1,
                  }}
                  classes={{
                    input: styles.program_options_input,
                  }}
                />
                {this.state.errors.programSettings.numOfSessions ? (
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {this.state.errors.programSettings.numOfSessions}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <p>{t('total-number-of-sessions')}</p>
            </div>

            <div className={styles.session_option}>
              <p className={styles.session_options_main_option}>Program name</p>
              <FormControl
                classes={{
                  root: styles.mu_input_form_control,
                }}
                error={!!this.state.errors.programSettings.currentProgramName}
              >
                <Input
                  value={this.state.currentProgramName}
                  onChange={this.onCurrentProgramChange}
                  classes={{
                    input: styles.program_options_input,
                  }}
                />
                {this.state.errors.programSettings.currentProgramName ? (
                  <FormHelperText
                    classes={{ root: styles.form_helper_text_error }}
                  >
                    {this.state.errors.programSettings.currentProgramName}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <p>{t('program-name-description')}</p>
            </div>
          </div>
        </div>

        {this.renderModalFooter({
          primaryBtnContent: t('set_program'),
          isPrimaryBtnDisabled:
            !!this.state.errors.programSettings.numOfSessions ||
            !!this.state.errors.programSettings.currentProgramName ||
            this.state.currentProgramName.trim() === '',
        })}
      </React.Fragment>
    );
  }

  renderSavedSessionStep() {
    const { t } = this.props;
    const { currentPrograms } = this.state;
    return (
      <>
        <div className={styles.modal_body}>
          <div className={styles.new_session_step_container}>
            <Avatar
              alt={t('new_session_created')}
              className={this.props.classes.newSessionAvatar}
              src={doneAnimationAsset}
            />
            <p className={styles.new_session_created_text}>
              {currentPrograms.length > 0
                ? t('programs_are_set')
                : t('programs_are_removed')}
            </p>
          </div>
        </div>

        {this.renderModalFooter({
          primaryBtnContent: t('done'),
          secondaryBtnContent: t('view-client'),
          onPrimaryBtnClick: () => {
            this.closeModal();
            new MyEvent('fast_create_session_done_click').log({
              clinicId: sessionStorage.userId,
              customerId: this.state.userId,
            });
          },
          onSecondaryBtnClick: () => {
            sessionStorage.customerId = this.state.userId;
            sessionStorage.customerEmail = this.state.patientInfo.email;
            sessionStorage.customerInfo = JSON.stringify(
              this.state.patientInfo
            );
            window.open(
              `/userPage.html?customerId=${this.state.userId}`,
              '_self'
            );
          },
        })}
      </>
    );
  }

  renderUserHasASessionDeployed() {
    const { t } = this.props;

    return (
      <>
        <div className={styles.modal_body}>
          <div className={styles.user_has_session_deployed}>
            <img
              src={clientHasSessionAsset}
              alt={t('user_has_session_deployed')}
            />
            <h3 className={styles.dialog_header}>
              {t('program_deployed_what_would_you_do')}
            </h3>
          </div>
        </div>

        {this.renderModalFooter({
          primaryBtnContent: t('view-edit-programs'),
          secondaryBtnContent: t('cancel'),
          onPrimaryBtnClick: () => {
            this.setState({ renderedStep: 'deployedPrograms' });
          },
          onSecondaryBtnClick: () => {
            this.closeModal();
            new MyEvent(
              'fast_create_session_cancel_from_deployed_session_click'
            ).log({
              clinicId: sessionStorage.userId,
              customerId: this.state.userId,
            });
          },
        })}
      </>
    );
  }

  saveDeployedProgramsOrder = ({ userId, deployedProgramsOrder }) => {
    const deployedSessionDatabaseRef = database.ref(
      this.props.autoDeployableConfigs
        ? `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables`
        : `deployedSession/${this.props.clinicId}/${userId}`
    );
    return deployedSessionDatabaseRef
      .child(
        this.props.autoDeployableConfigs
          ? 'programSwitchType/content'
          : 'programSwitchType'
      )
      .set(deployedProgramsOrder);
  };

  saveDeployedProgramsExpiration = ({
    userId,
    deployedProgramsExpiration,
    deployedProgramsExpirationTimestamp,
  }) => {
    const trainingExpiration = deployedProgramsExpirationTimestamp
      ? {
          type: deployedProgramsExpiration,
          expiration: deployedProgramsExpirationTimestamp,
        }
      : null;
    const deployedSessionDatabaseRef = database.ref(
      this.props.autoDeployableConfigs
        ? `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables`
        : `deployedSession/${this.props.clinicId}/${userId}`
    );
    return deployedSessionDatabaseRef
      .child(
        this.props.autoDeployableConfigs
          ? 'trainingExpiration/content'
          : 'trainingExpiration'
      )
      .set(trainingExpiration);
  };

  onDeployedProgramsOrderChange = event => {
    const { userId } = this.state;
    const deployedProgramsOrder = event.target.value;
    this.setState({ deployedProgramsOrder });
    this.saveDeployedProgramsOrder({ userId, deployedProgramsOrder });
  };

  onExpirationChange = event => {
    this.setState({ deployedProgramsExpiration: event.target.value });
    if (event.target.value === 'CUSTOM') {
      this.setState({ isCalendarFocused: true });
    }
    if (event.target.value === 'NEXT_BC') {
      setTimeout(() => {
        this.onExpirationDateSelected(
          {
            date: this.state.clinicExpiration,
          },
          false
        );
      }, 100);
    }
  };

  onExpirationDateSelected = ({ date }, shouldDecreaseTwelveHours = true) => {
    const TWELVEHOURS = 43200000;
    this.setState({ deployedProgramsExpirationTimestamp: date });
    if (!date) {
      this.setState({
        deployedProgramsExpiration: deployedProgramsExpirationOptions[0].value,
      });
    }
    const { userId } = this.state;
    this.saveDeployedProgramsExpiration({
      userId,
      // eslint-disable-next-line no-nested-ternary
      deployedProgramsExpiration: !date
        ? deployedProgramsExpirationOptions[0].value
        : shouldDecreaseTwelveHours
        ? deployedProgramsExpirationOptions[2].value
        : deployedProgramsExpirationOptions[1].value,
      deployedProgramsExpirationTimestamp: date
        ? date.valueOf() - (shouldDecreaseTwelveHours ? TWELVEHOURS : 0)
        : null,
    });
  };

  saveShouldNotifyByEmail = ({ userId, shouldNotifyByEmail }) => {
    return database
      .ref(
        this.props.autoDeployableConfigs
          ? `clinicConfig/autoDeployableConfigs/${this.props.clinicId}/programs/deployables`
          : `deployedSession/${this.props.clinicId}/${userId}`
      )
      .child(
        this.props.autoDeployableConfigs
          ? 'notifyByEmail/content'
          : 'notifyByEmail'
      )
      .set(shouldNotifyByEmail);
  };

  onNotifyByEmailChange = event => {
    const { userId } = this.state;
    const shouldNotifyByEmail = event.target.checked;
    this.setState({ shouldNotifyByEmail });
    this.saveShouldNotifyByEmail({ shouldNotifyByEmail, userId });
  };

  onChangeProgramName = (newProgramName, programId) => {
    if (sessionStorage.disconnection) {
      showNotification('danger', 'Internet connectivity unavailable');
      return;
    }
    const { currentPrograms } = this.state;
    const programIndex = currentPrograms.findIndex(
      program => program.id === programId
    );

    const newCurrentPrograms = cloneDeep(currentPrograms);
    const program = newCurrentPrograms[programIndex];
    newCurrentPrograms.splice(programIndex, 1, {
      ...program,
      name: { en: newProgramName },
    });
    this.savePrograms(newCurrentPrograms, currentPrograms, false);
    this.setState({ currentPrograms: newCurrentPrograms });
  };

  onCancelProgram = async programId => {
    const { currentPrograms, userId } = this.state;
    const programIndex = currentPrograms.findIndex(
      program => program.id === programId
    );
    const programToBeDeleted = currentPrograms[programIndex];
    const newCurrentPrograms = [...currentPrograms];
    newCurrentPrograms.splice(programIndex, 1);

    // to make fireFunctionPost work since it uses sessionStorage
    sessionStorage.customerId = userId;

    this.setState({ renderedStep: 'loading' });
    if (!this.props.autoDeployableConfigs) {
      // eslint-disable-next-line no-undef
      await fireFunctionPost('programs-archiveProgram', {
        programId: programToBeDeleted.id,
      });
    } else {
      const copy = JSON.parse(JSON.stringify(this.props.autoDeployableConfigs));
      delete copy.deployables.deployedPrograms.content[programToBeDeleted.id];
      if (!Object.keys(copy.deployables.deployedPrograms.content).length) {
        copy.enabled = false;
      }
      this.props.setAutoDeployableConfigs(copy);
    }
    this.setState({
      currentPrograms: newCurrentPrograms,
      renderedStep: 'deployedPrograms',
    });

    new MyEvent('fast_create_session_programs_canceled').log({
      customerId: userId,
      entered_from: window.location.pathname.substring(1),
      clinicId: sessionStorage.userId,
    });
  };

  onEditProgram = (programId, checkAdvancedToglle = true) => {
    const { PatientProtocolDetails } = this.props;
    const { currentPrograms } = this.state;
    const programIndex = currentPrograms.findIndex(
      program => program.id === programId
    );
    const currentProgram = currentPrograms[programIndex];
    const { roundDuration, roundsNumber } = currentProgram;
    const clinicalTrainingProgram = {
      roundDuration,
      roundsNumber,
    };
    const patientProtocolDetails =
      currentProgram.protocolDetails instanceof PatientProtocolDetails
        ? currentProgram.protocolDetails
        : new PatientProtocolDetails(currentProgram.protocolDetails);
    if (checkAdvancedToglle) {
      if (currentProgram.isProgramSettingsAdvancedMode === false) {
        this.props.onProgramSettingsAdvancedModeChange({
          target: { checked: false },
        });
      } else {
        this.props.onProgramSettingsAdvancedModeChange({
          target: { checked: true },
        });
      }
    }

    let lastSessionId = null;
    if (currentProgram.sessionKeys && currentProgram.sessionKeys.length) {
      lastSessionId =
        currentProgram.sessionKeys[currentProgram.sessionKeys.length - 1];
    }

    const { selectedClinicalProtocol } = this.getDerivedUserData({
      patientProtocolDetails,
      clinicalTrainingProgram,
    });

    this.setState({
      currentProgramIndex: programIndex,
      clinicalTrainingProgram,
      patientProtocolDetails,
      currentChannelSelection: 'activeChannel',
      renderedStep: 'programSettings',
      currentProgramName: currentProgram.name.en,
      numOfSessions: currentProgram.sessionsInProgram,
      numOfCompletedSessions: currentProgram.sessionsOfProgramDone,
      lastSessionId,
      selectedClinicalProtocol,
    });
  };

  onChangeItemOrder = async (itemId, isUp) => {
    const { currentPrograms } = this.state;
    const programIndex = currentPrograms.findIndex(
      program => program.id === itemId
    );

    const newCurrentPrograms = cloneDeep(currentPrograms);
    const program = { ...newCurrentPrograms[programIndex] };
    if (isUp) {
      newCurrentPrograms[programIndex] = {
        ...newCurrentPrograms[programIndex - 1],
      };
      newCurrentPrograms[programIndex - 1] = { ...program };
    } else {
      newCurrentPrograms[programIndex] = {
        ...newCurrentPrograms[programIndex + 1],
      };
      newCurrentPrograms[programIndex + 1] = { ...program };
    }
    new MyEvent('on_program_order_change').log({
      // eslint-disable-next-line react/prop-types
      customerId: this.props.userId,
      clinicId: sessionStorage.userId,
      programsBeforeOrder: currentPrograms,
      programsAfterOrder: newCurrentPrograms,
      time: Date.now(),
      programClicked: itemId,
      isUp,
    });

    this.setState({ renderedStep: 'loading' });

    const updatedPrograms = await this.savePrograms(
      newCurrentPrograms,
      currentPrograms,
      false
    );

    const updatedProgramIdForProtocolDetails = Object.keys(updatedPrograms)
      .filter(programId => !updatedPrograms[programId].isComplete)
      .reduce((prev, curr) =>
        updatedPrograms[prev].programIndex < updatedPrograms[curr].programIndex
          ? prev
          : curr
      );

    // eslint-disable-next-line no-undef
    await fireFunctionPost(
      'sessions-switchProgram',
      {
        programId: updatedProgramIdForProtocolDetails,
        clinicId: sessionStorage.userId,
        userId: this.state.userId,
        // eslint-disable-next-line no-undef
        fbtoken: await getToken(),
      },
      true
    );

    this.setState({ currentPrograms: newCurrentPrograms });
    this.setState({ renderedStep: 'deployedPrograms' });
  };

  onConfirmCancelingDialogConfirm = () => {
    this.closeModal();
  };

  onConfirmCancelingDialogCancel = () => {
    this.setState({ isConfirmCancelingDialogOpen: false });
  };

  onViewPricingPlansClicked = async () => {
    await new MyEvent('view_pricing_plans_clicked').log({
      from_where: 'create_program',
    });
    window.open('new_account.html', '_self');
  };

  renderDeployedPrograms() {
    const { t } = this.props;
    const {
      currentPrograms,
      deployedProgramsOrder,
      deployedProgramsExpiration,
      shouldNotifyByEmail,
      isConfirmCancelingDialogOpen,
      patientInfo,
      userId,
      headsetDetails,
      isCustomerAppCompatibleWithMultiProtocol,
    } = this.state;

    const programsWithProtocolNames = currentPrograms.map(program => {
      const protocolName = this.getProtocolNameBasedOnPatientProtocol(
        program.protocolDetails
      );
      return { ...program, protocolName };
    });

    const warningMsg =
      isCustomerAppCompatibleWithMultiProtocol ||
      programsWithProtocolNames.length < 2
        ? null
        : 'This feature works best on app version 2.1.4 and above on iOS and 3.0.7.0 and above on Android. Please instruct your clients to update their app to get the full experience';

    const updatedDeployedProgramsExpirationOptions = [
      ...deployedProgramsExpirationOptions,
    ];
    updatedDeployedProgramsExpirationOptions[1].disabled =
      this.state.clinicExpiration < Date.now();

    return (
      <>
        <ModalHeader
          leftContent={
            <div className={styles.modal_header_left_side_content}>
              <h4>Deployed Programs</h4>
              {this.props.autoDeployableConfigs ? null : (
                <a href={`userPage.html?customerId=${userId}`}>
                  {patientInfo.firstName} {patientInfo.lastName}
                </a>
              )}
            </div>
          }
          isCloseIconShown={false}
          rightContent={
            <div className={styles.input_container}>
              <p
                className={styles.label}
                style={{ marginBottom: 4 }}
                htmlFor="notify-by-email"
              >
                Notify me on activity
              </p>

              <Switch
                checked={shouldNotifyByEmail}
                onChange={this.onNotifyByEmailChange}
                color="primary"
                id="notify-by-email"
                classes={{ switchBase: styles.switch_base }}
              />
            </div>
          }
        />
        <DeployedProgramsStep
          programs={programsWithProtocolNames}
          onAddProgram={this.onAddProgram}
          order={deployedProgramsOrder}
          shouldShowAddProgram={!headsetDetails.isDispatched}
          onOrderChange={this.onDeployedProgramsOrderChange}
          orderOptions={deployedProgramsOrders.map(value => ({
            value,
            text: value
              .replace('_', ' ')
              .split(' ')
              .map(text => capitalize(text))
              .join(' '),
          }))}
          // shouldNotifyByEmail={shouldNotifyByEmail}
          // onNotifyByEmailChange={this.onNotifyByEmailChange}
          onChangeProgramName={this.onChangeProgramName}
          onCancelProgram={this.onCancelProgram}
          onEditProgram={this.onEditProgram}
          programNameMaxLength={programNameMaxLength}
          longProgramNameErrorMsg={longProgramNameErrorMsg}
          warningMsg={warningMsg}
          onChangeItemOrder={this.onChangeItemOrder}
          patientId={this.state.userId}
          isSwitching={this.state.isSwitchingOrder}
          expiration={deployedProgramsExpiration}
          resetExpiration={() =>
            this.setState({
              deployedProgramsExpiration:
                deployedProgramsExpirationOptions[0].value,
            })
          }
          onExpirationChange={this.onExpirationChange}
          expirationOptions={updatedDeployedProgramsExpirationOptions}
          onDateSelected={this.onExpirationDateSelected}
          deployedProgramsExpirationTimestamp={
            this.state.deployedProgramsExpirationTimestamp
          }
          isCalendarFocused={this.state.isCalendarFocused}
          setIsCalendarFocused={val => {
            this.setState({ isCalendarFocused: val });
          }}
          clinicExpirationEnabled={this.state.clinicExpirationEnabled}
          autoDeployableConfigs={this.props.autoDeployableConfigs}
        />
        {this.renderModalFooter({
          primaryBtnContent: t('close'),
          secondaryBtnContent: '',
        })}

        <ConfirmCancelingDialog
          isOpen={isConfirmCancelingDialogOpen}
          onPrimaryBtnClick={this.onConfirmCancelingDialogConfirm}
          onSecondaryBtnClick={this.onConfirmCancelingDialogCancel}
        />
      </>
    );
  }

  renderCreditCardStep() {
    const { classes, isModalOpen, modalId } = this.props;
    const { defaultBlockedUsageCredit } = this.state;

    return (
      <Modal open={isModalOpen}>
        <div className={classNames(classes.paper, styles.modal)} id={modalId}>
          <ChangeCreditCardDetailsIfNeededWrapper
            isFreePlan
            onClose={() => {
              new MyEvent('on_demand_use_nf_modal_action').log({
                action: 'canceled',
                cc_required: true,
              });
              this.closeModal();
            }}
            defaultBlockedUsageCredit={defaultBlockedUsageCredit}
            onCreditCardUpdated={() => {
              // eslint-disable-next-line no-undef, no-restricted-globals
              location.reload();
            }}
          />
        </div>
      </Modal>
    );
  }

  renderShowUsageWarning() {
    const { classes, isModalOpen, modalId } = this.props;

    return (
      <Modal open={isModalOpen}>
        <div
          className={classNames(classes.paper, styles.modal)}
          id={modalId}
          style={{ fontSize: 14, padding: 20 }}
        >
          <div style={{ fontSize: 20, fontWeight: 900 }}>Heads up</div>
          With your On-Demand plan, there are no recurring subscription fees.
          You'll only incur a ${this.state.userPrice} charge per active client
          per month if a client's neurofeedback training exceeds 5 minutes per
          billing cycle.
          <a href="/new_account.html" target="_self">
            {' '}
            View other plans
            <OpenInNewIcon
              style={{ fontSize: 12, position: 'relative', top: 2, left: 2 }}
            />
          </a>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={async () => {
                new MyEvent('on_demand_use_nf_modal_action').log({
                  action: 'canceled',
                  cc_required: false,
                });
                this.closeModal();
              }}
              color="primary"
              // variant='contained'
              style={{ fontSize: 14, marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                database
                  .ref(`userInfo/${sessionStorage.userId}/showUsageWarning`)
                  .set(null);
                new MyEvent('on_demand_use_nf_modal_action').log({
                  action: 'approved',
                  cc_required: false,
                });
                this.closeModal();
                const clinicUserInfo = await this.loadUserInfo(
                  this.props.clinicId
                );
                this.setState({ clinicUserInfo });
              }}
              color="primary"
              variant="contained"
              style={{ fontSize: 14, marginRight: 10 }}
            >
              I understand
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { classes, isModalOpen, modalId, isForcedLoading } = this.props;
    const {
      renderedStep,
      isFeatureNotAllowedDialogOpen,
      isMultiProgramFeatureAllowed,
      registrationCode,
      assessmentAutoDeployableConfigs,
      questionnairesAutoDeployableConfigs,
      clinicUserInfo,
    } = this.state;

    if (clinicUserInfo && clinicUserInfo.isCCRequired) {
      return this.renderCreditCardStep();
    }

    if (clinicUserInfo && clinicUserInfo.showUsageWarning) {
      return this.renderShowUsageWarning();
    }

    return isForcedLoading || this.state.shouldLoadAndProcessUserData ? (
      <Modal open={isModalOpen}>
        <div className={classNames(classes.paper, styles.modal)} id={modalId}>
          {this.renderLoadingStep()}
        </div>
      </Modal>
    ) : (
      <>
        {' '}
        <Modal open={isModalOpen}>
          <div className={classNames(classes.paper, styles.modal)} id={modalId}>
            {renderedStep === 'loading' && this.renderLoadingStep()}
            {renderedStep === 'userSelect' && this.renderUserSelectStep()}
            {renderedStep === 'programSettings' &&
              this.renderProgramSettingsStep()}
            {renderedStep === 'channelsSelect' &&
              this.renderChannelsSelectStep()}
            {renderedStep === 'placementRecommendation' &&
              this.renderPlacementRecommendationStep()}
            {renderedStep === 'sessionOptions' &&
              this.renderSessionOptionsStep()}
            {renderedStep === 'savedSession' && this.renderSavedSessionStep()}
            {renderedStep === 'deployedPrograms' &&
              this.renderDeployedPrograms()}
            {renderedStep === 'userHasSessionDeployed' &&
              this.renderUserHasASessionDeployed()}
          </div>
        </Modal>
        <FeatureNotAllowedDialog
          isOpen={
            isFeatureNotAllowedDialogOpen && !isMultiProgramFeatureAllowed
          }
          text="This feature is available if you have a Plus plan or above."
          onDismiss={() =>
            this.setState({ isFeatureNotAllowedDialogOpen: false })
          }
        />
        <CustomDialog
          isOpen={this.state.toggleCompleteSetupModal}
          onPrimaryBtnClick={() => {
            this.onEditSelfRegistration();
            new MyEvent('self_reg_setup_now_clicked').log({
              patientId: this.state.userSuggestion.userId,
              from: 'add_edit_program',
            });
          }}
          onSecondaryBtnClick={() => {
            this.setState({ toggleCompleteSetupModal: false });
          }}
          primaryBtnContent="SET UP NOW"
        >
          <p className={styles.dialog_header}>
            This client self-registered using your clinic code!
          </p>
          <p className={styles.dialog_paragraph}>
            You’ll need to complete client setup to be able to view their data
            and create programs.
          </p>
        </CustomDialog>
        <SelfRegistrationDialog
          isOpen={!!this.state.isSelfRegisterModalOpen}
          onClose={() => {
            this.setState({ isSelfRegisterModalOpen: false });
          }}
          registrationCode={registrationCode}
          onAddClientClicked={() => {
            this.props.openAddClientModal({
              clinicId: sessionStorage.userId,
              shouldUseNewSymptomTracker: true,
              nfFlag: this.state.nfFlag,
              assessmentAutoDeployableConfigs,
              questionnairesAutoDeployableConfigs,
            });
          }}
          reachedClientsLimit={this.state.reachedClientsLimit}
          usersIncluded={this.state.usersIncluded}
          onViewPricingPlansClicked={this.onViewPricingPlansClicked}
          clinicNonfPlan={this.state.clinicNonfPlan}
        />
      </>
    );
  }
}

CreateProgramPopUpModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool,
  users: PropTypes.array,
  closeModal: PropTypes.func,
  database: PropTypes.object,
  PatientProtocolDetails: PropTypes.func,
  openAddClientModal: PropTypes.func,
  t: PropTypes.func.isRequired,
  isProgramSettingsAdvancedMode: PropTypes.bool,
  extractFrequencyName: PropTypes.func,
  getRecommendedPlacementNameBasedOnPlacement: PropTypes.func,
  getChoosenPlacementDescriptionHTML: PropTypes.func,
  modifyProtocol: PropTypes.func,
  getOverAllSuccessRate: PropTypes.func,
  searchProtocol: PropTypes.func,
  onProgramSettingsAdvancedModeChange: PropTypes.func,
  getChosenPlacement: PropTypes.func,
  protocols: PropTypes.array,
  placements: PropTypes.object,
  modalId: PropTypes.string,
  clinicId: PropTypes.string.isRequired,
  onDataChange: PropTypes.func,
  isForcedLoading: PropTypes.bool,
  autoDeployableConfigs: PropTypes.object,
  setAutoDeployableConfigs: PropTypes.func,
};

CreateProgramPopUpModal.defaultProps = {
  openAddClientModal: () => {},
};

export const CreateProgramPopUp = withStyles(stylesFn)(
  withEntryComponent(withTranslation(CreateProgramPopUpModal))
);

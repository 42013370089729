import React from 'react';
import PropTypes from 'prop-types';
import dayJS from 'dayjs';
import exact from 'prop-types-exact';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import ShareIcon from '@material-ui/icons/Share';
import { Switch } from '@material-ui/core';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import styles from './ReportsTable.scss';
import { CustomTable } from '../../Core/Components/CustomTable/CustomTable';

export const ReportsTable = ({
  reports,
  onReportDelete,
  onReportView,
  isDeleteIconShown,
  isShareReportIconShown,
  onToggleShareInAppReport,
  onReportShare,
}) => {
  const columnsIds = [
    'index',
    'reportTitle',
    'dateCreated',
    'shareInApp',
    'action',
  ];

  const tableHeads = [
    '#',
    'Report Title',
    'Date Created',
    'Share In-App',
    'Action',
  ]
    .map(tableHeadText => <span className={styles.cell}>{tableHeadText}</span>)
    .map((node, index) => ({
      id: columnsIds[index],
      node,
    }));

  const tableRows = reports.map((report, index) => ({
    id: report.id,
    cells: [
      <span className={styles.cell}>{reports.length - index}</span>,
      <span className={styles.cell}>
        {`${report.patientName} ${dayJS(report.createdAt).format(
          DAY_JS_DATE_FORMATS.dayMonthYearHyphensWithTIme
        )}`}
      </span>,
      <span className={styles.cell}>
        {dayJS(report.createdAt).format(DAY_JS_DATE_FORMATS.american)}
      </span>,
      <span>
        <Switch
          checked={report.shareInAppWithClient}
          onChange={e => onToggleShareInAppReport(e.target.checked, report.id)}
          color="primary"
        />
      </span>,
      <div>
        <IconButton color="primary" onClick={() => onReportView(report)}>
          <ViewIcon />
        </IconButton>

        {isShareReportIconShown && (
          <IconButton color="primary" onClick={() => onReportShare(report)}>
            <ShareIcon />
          </IconButton>
        )}

        {isDeleteIconShown && (
          <IconButton color="default" onClick={() => onReportDelete(report)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>,
    ].map((node, i) => ({
      id: columnsIds[i],
      node,
    })),
  }));

  return (
    <div className={styles.root}>
      <CustomTable
        tableHeads={tableHeads}
        tableRows={tableRows}
        tableContainerClass={styles.table_container}
      />
    </div>
  );
};

ReportsTable.defaultProps = {
  isDeleteIconShown: false,
  onReportDelete: () => {},
};

ReportsTable.propTypes = exact({
  reports: PropTypes.array.isRequired,
  onReportDelete: PropTypes.func,
  onReportView: PropTypes.func.isRequired,
  isDeleteIconShown: PropTypes.bool,
  isShareReportIconShown: PropTypes.bool,
  onToggleShareInAppReport: PropTypes.func.isRequired,
  onReportShare: PropTypes.func.isRequired,
});

import { oneHourInSeconds } from '../../utils/constants';
import { presetFrequenciesNames } from '../../models/frequencies/frequencies';

export const defaultMinDuration = 0;
export const defaultMaxDuration = oneHourInSeconds * 2;
export const defaultMinAlertsNumber = 0;
export const defaultMaxAlertsNumber = 150;

export const userCardReportCardID = 'built-in-report-card-user-card';
export const protocolsUsedInTrainingReportCardID =
  'built-in-report-card-protocols-used-in-training';
export const userAddedReportCardsSectionTitleId =
  'built-in-user-added-reports-section-title';
export const protocolsUsedInTrainingSectionTitleId =
  'built-in-protocols-used-in-training-section-title';
export const progressReportTitleId = 'built-in-progress-report-title';

export const sessionComparisonGraphType = 'SessionComparisonGraph';
export const symptomsTrackingGraphType = 'SymptomsTrackingGraph';
export const overallSymptomsTrackingGraphType = 'OverallSymptomsTrackingGraph';
export const zoneGraphType = 'ZoneGraph';
export const frequenciesSessionComparisonGraphType =
  'FrequenciesSessionComparisonGraph';
export const streakSessionComparisonGraphType = 'StreakSessionComparisonGraph';
export const amplitudeAndNoiseGraphType = 'AmplitudeAndNoiseGraph';
export const inDepthSessionAnalysisGraphType = 'InDepthSessionAnalysisGraph';
export const averageAndMaxStreakGraphType = 'AverageAndMaxStreakGraph';
export const userCardReportCardType = 'UserCard';
export const protocolsUsedInTrainingReportCardType = 'ProtocolsUsedInTraining';
export const protocolsUsedInTrainingTitleType = 'ProtocolsUsedInTrainingTitle';
export const userAddedReportsCardsSectionTitleType = 'UserAddReportCardsTitle';
export const progressReportTitleType = 'ProgressReportTitle';
export const assessmentPerformanceGraphType = 'AssessmentPerformanceGraph';
export const assessmentCPTPerformanceGraphType =
  'AssessmentCPTPerformanceGraph';
export const assessmentBrainMapsGraphType = 'AssessmentBrainMapsGraph';
export const assessmentNoiseGraphType = 'assessmentNoiseGraphType';
export const assessmentSwingleChecksGraphType = 'AssessmentSwingleCheckGraph';
export const assessmentNeuromarkersGraphType = 'assessmentNeuromarkersGraph';
export const assessmentAmplitudePerFrequencyGraphType =
  'assessmentAmplitudePerFrequencyGraph';
export const freeTextCardType = 'freeText';
export const insightGraphType = 'insightItemGraph_';
export const assessmentQuestionnairesResultGraphType =
  'AssessmentQuestionnairesResult';

export const SSRGraphWidth = Math.min(
  Math.max((82 * window.innerWidth) / 100, 800),
  1400
);

export const predefinedColors = [
  '#90B6F3',
  '#91F2EB',
  '#F0C228',
  '#D9D9D9',
  '#6FA0F0',
  '#FFE1C8',
  '#C8F9F5',
  '#B1CBF6',
  '#DAFBD8',
  '#FF9A9A',
];

export const shadesOfGreyColors = [
  '#727272',
  '#969696',
  '#a8a8a8',
  '#c4c4c4',
  '#e0e0e0',
  '#808080',
];

export const graphHeight = 300;

export const averageNoiseColor = '#FF9A9A';
export const frequenciesColors = {
  [presetFrequenciesNames.delta]: [
    '#8e6cea',
    '#947bef',
    '#9a88f2',
    '#a79cf7',
    '#c4c0ff',
  ],
  [presetFrequenciesNames.theta]: [
    '#4be0d0',
    '#61f2dd',
    '#85ffe8',
    '#abffef',
    '#cffff6',
  ],
  [presetFrequenciesNames.alpha]: [
    '#69acf7',
    '#78b9f4',
    '#8dc9f7',
    '#9fd5f7',
    '#bde9ff',
  ],
  [presetFrequenciesNames.loBeta]: [
    '#edd964',
    '#f4e589',
    '#f9eaa5',
    '#fcf0be',
    '#fff9de',
  ],
  [presetFrequenciesNames.beta]: [
    '#c053db',
    '#c868e0',
    '#cf77e5',
    '#dc8eef',
    '#f0b3ff',
  ],
  [presetFrequenciesNames.hiBeta]: [
    '#dd5280',
    '#e56193',
    '#ea78a7',
    '#ef89b8',
    '#ef9ac5',
  ],
};

export const sessionToSessionReportGeneratorTabs = {
  sessionGraphs: 'sessions',
  symptomTrackerGraphs: 'symptomTracker',
  assessmentGraphs: 'assessments',
  freeText: 'freeText',
  insights: 'insights',
};

export const frequenciesSessionComparisonAggregationMethods = {
  median: 'Median',
  average: 'Average',
};

export const rapidSessionTypes = {
  withoutElectrode: {
    name: 'Rapid session without electrode',
    val: 'withoutElectrode',
  },
  withElectrode: { name: 'Rapid session with electrode', val: 'withElectrode' },
};

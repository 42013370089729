import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { defensiveThrow } from '../../../utils/utils';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    defensiveThrow({ error });
  }

  render() {
    const { children, componentName } = this.props;
    const { hasError } = this.state;
    return hasError ? (
      <Paper>
        <div style={{ padding: '20px 30px' }}>
          <span>
            Something went wrong while rendering {componentName}. Error was
            reported to Myndlift and will be handled.
          </span>
        </div>
      </Paper>
    ) : (
      children
    );
  }
}

ErrorBoundary.defaultProps = {
  componentName: 'this component',
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string,
};

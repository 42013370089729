import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button } from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import AddIcon from '@material-ui/icons/Add';
import styles from './SelectTags.scss';
import { CascadingMenu } from '../../../Core/Components/CascadingMenu/CascadingMenu';
import { DisorderChip } from '../../../Core/Components/DisorderChip/DisorderChip';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const SelectTagsBase = ({
  tags,
  onAddTag,
  selectedTags,
  onDeleteTag,
  shouldShowDescription,
  nfFlag,
  isSelfRegThatDoneAssessment,
}) => {
  const t = useTranslation();
  const groupedTags = groupBy(tags, 'category');

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const menuItems = Object.keys(groupedTags).map(group => {
    const hasNoSubMenuItems = groupedTags[group].length === 1;
    return {
      key: group,
      caption: group,
      onClick: hasNoSubMenuItems
        ? () => {
            onAddTag(groupedTags[group][0]);
          }
        : undefined,
      subMenuItems: hasNoSubMenuItems
        ? []
        : groupedTags[group]
            .map(tagObject => ({
              key: tagObject.id,
              caption: tagObject.name,
              order: tagObject.order,
              onClick: () => {
                onAddTag(tagObject);
              },
              isSubMenuItem: true,
            }))
            .sort((tag1, tag2) => tag1.order - tag2.order),
    };
  });

  const onOpenTagsMenu = event => setMenuAnchorElement(event.currentTarget);

  return (
    <div>
      {isSelfRegThatDoneAssessment ? (
        <CustomAlert
          severity="success"
          styleToAdd={{ position: 'relative', top: -30 }}
        >
          This client completed the default assessment.
        </CustomAlert>
      ) : null}
      {shouldShowDescription && (
        <p>
          {!nfFlag
            ? 'The tags you select will help us recommend the right questionnaires for your client and make progress tracking easier for you. Please select at least one tag to continue.'
            : t('select-tags-description')}
        </p>
      )}
      <div className={styles.btn_container}>
        <Button variant="text" color="primary" onClick={onOpenTagsMenu}>
          <AddIcon />
          <span className={styles.btn_text}>{t('add-new-tag')}</span>
        </Button>
      </div>

      <CascadingMenu
        anchorElement={menuAnchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        menuItems={menuItems}
        onClose={() => setMenuAnchorElement(null)}
        open={!!menuAnchorElement}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />

      {selectedTags.length > 0 && (
        <div className={styles.tags_container}>
          {selectedTags.map(tagObject => (
            <DisorderChip
              classes={{
                label: styles.chip_label,
                root: styles.chip_root,
                deleteIcon: styles.chip_delete_icon,
              }}
              label={
                tagObject.name
                  ? `${tagObject.category}: ${tagObject.name}`
                  : tagObject.category
              }
              onDelete={() => onDeleteTag(tagObject)}
              key={tagObject.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

SelectTagsBase.propTypes = exact({
  tags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  shouldShowDescription: PropTypes.bool.isRequired,
  nfFlag: PropTypes.bool,
  isSelfRegThatDoneAssessment: PropTypes.bool,
});

export const SelectTags = React.memo(SelectTagsBase);
SelectTags.displayName = 'SelectTags';

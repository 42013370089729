import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Select, Switch, MenuItem } from '@material-ui/core';
import styles from './DigitalCareItem.scss';
import { CheckboxesSelect } from '../../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { CustomAlert } from '../../../../Core/Components/CustomAlert/CustomAlert';

const DigitalCareItemBase = ({
  title,
  description,
  leaernMore,
  image,
  toggleValue,
  onToggleClicked,
  selectItems,
  onSelectItem,
  selectedItem,
  warning,
  error,
  multipleSelect,
}) => {
  return (
    <div className={styles.item_container_with_warning}>
      <div className={styles.item_container}>
        <div className={styles.item_image_container}>
          <img
            src={image}
            className={!toggleValue ? styles.item_image : null}
            style={{
              width: '30%',
              height: 100,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingTop: 10,
            }}
          />
          <div className={styles.item_image_text}>
            {warning ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomAlert severity="warning" showIcon={false}>
                  {warning}
                </CustomAlert>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.item_data_container}>
          <div className={styles.item_data_text}>
            <div className={styles.item_title}>{title}</div>
            <div className={styles.item_description}>
              {description}{' '}
              <a
                href={leaernMore}
                className={styles.item_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
          <div
            className={styles.item_btns_container}
            // style={multipleSelect ? null : { position: 'relative', top: 10 }}
          >
            {multipleSelect ? (
              <CheckboxesSelect
                allOptions={selectItems}
                selectedOptions={selectedItem}
                onChange={onSelectItem}
                renderValue={multipleSelect}
                classes={{
                  formControl: styles.multi_select_input,
                }}
                inputType="outlinedInputs"
                disabled={!toggleValue}
                displayEmpty
              />
            ) : (
              <Select
                onChange={onSelectItem}
                value={selectedItem}
                style={{ width: '75%', fontSize: 13, top: 6 }}
                disabled={!toggleValue}
              >
                {selectItems.map(selectItem => (
                  <MenuItem value={selectItem.value} style={{ fontSize: 13 }}>
                    {selectItem.text}
                  </MenuItem>
                ))}
              </Select>
            )}
            <div style={{ marginTop: 20 }}>
              <Switch
                checked={toggleValue}
                onChange={onToggleClicked}
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      {error ? (
        <div>
          <CustomAlert severity="error">{error}</CustomAlert>
        </div>
      ) : null}
    </div>
  );
};

DigitalCareItemBase.propTypes = exact({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  leaernMore: PropTypes.string,
  image: PropTypes.string,
  toggleValue: PropTypes.bool.isRequired,
  onToggleClicked: PropTypes.func.isRequired,
  selectItems: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.array,
  warning: PropTypes.string,
  error: PropTypes.string,
  multipleSelect: PropTypes.func,
});

export const DigitalCareItem = React.memo(DigitalCareItemBase);
DigitalCareItem.displayName = 'DigitalCareItem';

/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
/*eslint-disable spaced-comment */
import styles from './CustomerStoriesPagesStyles.scss';

export const insightsStorySequences = (
  most_sessions_time_of_day = 'morning',
  most_sessions_percentage = 100,
  best_score_time_of_day = 'morning'
) => {
  // const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  const backgroundAnimationDelay = 1200; // this numbers is not correct but it works
  const backgroundAnimationDuration = 3940; // this numbers is not correct but it works

  const insightText1 =
    //eslint-disable-next-line no-nested-ternary
    most_sessions_time_of_day === 'morning'
      ? "We've got an early bird brainiac here!"
      : most_sessions_time_of_day === 'afternoon'
      ? "We've got an afternoon ace here!"
      : 'Nightfall? Meet our brainwave warrior!';
  // const insightText1 = "We've got an afternoon ace here!";

  const bestScoreTimeOFTheDay =
    best_score_time_of_day === most_sessions_time_of_day
      ? 'same!'
      : `${best_score_time_of_day}!`;

  // const seq1duration = 200;

  const seq1WordsLength = insightText1.split(' ').length;
  const seq2WordsLength = `${most_sessions_percentage}%`.split('').length;
  const seq3WordsLength = 'of your sessions were done in'.split(' ').length;
  const seq4WordsLength = `the ${most_sessions_time_of_day}`.split(' ').length;
  const seq5WordsLength = 'Your best scores?'.split(' ').length;
  // const seq6WordsLength = bestScoreTimeOFTheDay.split(' ').length;

  const seq1Variants = {
    initial: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: (2 * textAnimationDuration) / 1000,
        ease: 'easeInOut',
      },
    },
  };

  let seq3Variants;
  let seq4Variants;
  seq3Variants = seq4Variants = {
    initial: { opacity: 0 }, // Starts visible with no change
    enter: {
      opacity: 1,
      transition: {
        // duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq5Variants = {
    initial: { opacity: 0, y: -50 }, // Starts visible with no change
    enter: {
      opacity: 1,
      y: -50,
      transition: {
        // duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  // const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  // const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  // const seq1Words = insightText1.split(' ').map((word, idx) => {
  //   return {
  //     id: `seq1word${idx + 1}`,
  //     text: word,
  //     enterDelay: seq1duration * (idx + 1),
  //     exitDelay: seq1duration * seq1WordsLength + seq1duration * (idx + 1),
  //     variants: seq1Variants,
  //     style: {
  //       fontSize: '35px',
  //       display: 'flex',
  //       flexDirection: 'column',
  //       // justifyContent: 'space-between',
  //       // height: '30%',
  //       margin: 'auto',
  //     },
  //   };
  // });

  // const seq3Words = 'of your sessions were done in'
  //   .split(' ')
  //   .map((word, idx) => {
  //     return {
  //       id: `seq3word${idx + 1}`,
  //       text: word,
  //       enterDelay:
  //         seq1TotalTime +
  //         seq2TotalTime +
  //         seq1duration * (idx + 1) +
  //         seq1duration,
  //       // enterDelay: seq1duration * (idx + 1),
  //       // exitDelay: seq1duration * seq1WordsLength + seq1duration * (idx + 1),
  //       variants: seq3Variants,
  //       style: {
  //         fontSize: '40px',
  //         margin: '7px auto',
  //         color: '#fffbc9',
  //         display: 'flex',
  //       },
  //     };
  //   });

  // const seq4Words = `the ${most_sessions_time_of_day}`
  //   .split(' ')
  //   .map((word, idx) => {
  //     return {
  //       id: `seq4word${idx + 1}`,
  //       text: word,
  //       enterDelay:
  //         seq1TotalTime +
  //         seq2TotalTime +
  //         seq3TotalTime / 4 +
  //         seq1duration * (idx + 1),
  //       // exitDelay: seq1duration * seq1WordsLength + seq1duration * (idx + 1),
  //       variants: seq3Variants,
  //       style: {
  //         fontSize: '40px',
  //         margin: '5px',
  //         color: '#fffbc9',
  //         display: 'flex',
  //       },
  //     };
  //   });
  // const seq5Words = 'Your best scores?'.split(' ').map((word, idx) => {
  //   return {
  //     id: `seq5word${idx + 1}`,
  //     text: word,
  //     // enterDelay: extraDuration + seq1duration * (idx + 1),
  //     // exitDelay: seq1duration * seq1WordsLength + seq1duration * (idx + 1),
  //     variants: seq3Variants,
  //     style: {
  //       fontSize: '40px',
  //       margin: '2px',
  //       color: '#fffbc9',
  //       display: 'flex',
  //     },
  //   };
  // });

  const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  // const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  // const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  const seq4EnterTime = seq4WordsLength * textAnimationDuration;
  // const seq4ExitTime = seq4WordsLength * textAnimationDuration;
  // const seq4TotalTime = seq4EnterTime + seq4ExitTime;

  const extraAnimationDelay =
    seq1TotalTime +
    seq2EnterTime +
    seq3EnterTime +
    seq4EnterTime +
    backgroundAnimationDelay +
    10 * textAnimationDuration;

  const extraAnimationDuration = 3 * textAnimationDuration;

  const seq5EnterTime = seq5WordsLength * textAnimationDuration;
  // const seq5ExitTime = seq5WordsLength * textAnimationDuration;
  // const seq5TotalTime = seq5EnterTime + seq5ExitTime;

  // const seq2Variants = {
  //   initial: { opacity: 0, y: 20 },
  //   enter: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       duration: textAnimationDuration / 1000,
  //       ease: 'easeInOut',
  //     },
  //   },
  //   exit: {
  //     opacity: 0,
  //     x: 20,
  //     transition: {
  //       duration: textAnimationDuration / 1000,
  //       ease: 'easeInOut',
  //     },
  //   },
  // };

  // const seq3Variants = {
  //   initial: { opacity: 0, y: 0 },
  //   enter: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       duration: textAnimationDuration / 1000,
  //       ease: 'easeInOut',
  //     },
  //   },
  //   exit: {
  //     opacity: 0,
  //     x: -20,
  //     transition: {
  //       duration: textAnimationDuration / 1000,
  //       ease: 'easeInOut',
  //     },
  //   },
  // };

  const seq1 = {
    id: 'seq1',
    // words: seq1Words,
    sentence: insightText1,
    // wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay: 0,
    exitDelay:
      seq1EnterTime + backgroundAnimationDuration - 8 * textAnimationDuration,
    style: {},
    className: styles.insights_seq_1,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      exit: {
        opacity: 0,
        y: -20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.1,
          // staggerDirection: -1,
          when: 'afterChildren',
        },
      },
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq2 = {
    id: 'seq2',
    enterDelay:
      seq1TotalTime + backgroundAnimationDelay + 5 * textAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq2-word${1}`,
        text: `${most_sessions_percentage}%`,
        enterDelay:
          seq1TotalTime + backgroundAnimationDelay + 5 * textAnimationDuration,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            // transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            transition: {
              duration: textAnimationDuration / 1000,
              // ease: 'easeInOut',
              staggerChildren: 0.3, // Stagger by 0.1 seconds between each child
            },
          },
          // exit: {},
        },
        // style: {},
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 100,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              // ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
      },
    ],
    // style: {},
    className: styles.insights_seq_2,
    variants: {
      initial: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
        },
      },
    },
    extraAnimations: [
      // {
      //   keyframes: { y: -100 },
      //   delay: extraAnimationDelay,
      //   transition: {
      //     duration: extraAnimationDuration, // Duration in seconds
      //     ease: 'easeInOut', // Optional easing function
      //   },
      // },
    ],
  };

  const seq3 = {
    id: 'seq3',
    // words: seq3Words,
    sentence: 'of your sessions were done in',
    wordsVariants: seq3Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay:
      seq1TotalTime +
      seq2EnterTime +
      backgroundAnimationDelay +
      4 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.insights_seq_3,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [
      // {
      //   keyframes: { y: -100 },
      //   delay: extraAnimationDelay,
      //   transition: {
      //     duration: extraAnimationDuration, // Duration in seconds
      //     ease: 'easeInOut', // Optional easing function
      //   },
      // },
    ],
    staggerChildren: true,
  };

  const seq4 = {
    id: 'seq4',
    // words: seq4Words,
    sentence: `the ${most_sessions_time_of_day}`,
    wordsVariants: seq4Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay:
      seq1TotalTime +
      seq2EnterTime +
      seq3EnterTime +
      backgroundAnimationDelay +
      4 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.insights_seq_4,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      },
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [
      // {
      //   keyframes: { y: -100 },
      //   delay: extraAnimationDelay,
      //   transition: {
      //     duration: extraAnimationDuration, // Duration in seconds
      //     ease: 'easeInOut', // Optional easing function
      //   },
      // },
    ],
    staggerChildren: true,
  };

  // const seq4 = {
  //   id: 'seq4',
  //   enterDelay:
  //     seq1TotalTime +
  //     seq2TotalTime +
  //     seq3EnterTime +
  //     backgroundAnimationDuration -
  //     6 * textAnimationDuration,
  //   exitDelay:
  //     seq1TotalTime +
  //     seq2TotalTime +
  //     backgroundAnimationDuration +
  //     // seq3EnterTime +
  //     // seq4EnterTime +
  //     seq3ExitTime +
  //     seq4ExitTime +
  //     23 * textAnimationDuration,
  //   words: [
  //     {
  //       id: `seq4-word${1}`,
  //       text: year,
  //       enterDelay:
  //         seq1TotalTime +
  //         seq2TotalTime +
  //         seq3EnterTime +
  //         backgroundAnimationDuration -
  //         6 * textAnimationDuration,
  //       exitDelay:
  //         seq1TotalTime +
  //         seq2TotalTime +
  //         backgroundAnimationDuration -
  //         // seq3EnterTime +
  //         // seq4EnterTime -
  //         23 * textAnimationDuration,
  //       variants: {
  //         initial: {
  //           opacity: 0,
  //           y: 20,
  //           //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
  //         },
  //         enter: {
  //           opacity: 1,
  //           y: 0,
  //           transition: {
  //             // duration: textAnimationDuration / 1000,
  //             ease: 'easeInOut',
  //             staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
  //           },
  //         },
  //         exit: {
  //           opacity: 0,
  //           x: 20,
  //           transition: {
  //             // duration: textAnimationDuration / 1000,
  //             ease: 'easeInOut',
  //             staggerChildren: 0.1, // Stagger by 0.1 seconds between each child
  //             staggerDirection: -1,
  //           },
  //         },
  //       },
  //       style: {},
  //       letterByLetter: true,
  //       lettersVariants: {
  //         initial: {
  //           opacity: 0,
  //           y: 20,
  //         },
  //         enter: {
  //           opacity: 1,
  //           y: 0,
  //           transition: {
  //             // duration: textAnimationDuration / 1000,
  //             ease: 'easeInOut',
  //           },
  //         },
  //         exit: {
  //           opacity: 0,
  //           x: 20,
  //           transition: {
  //             // duration: textAnimationDuration / 1000,
  //             ease: 'easeInOut',
  //           },
  //         },
  //       },
  //       lettersStyle: { marginRight: '5px' },
  //     },
  //   ],
  //   style: {},
  //   className: styles.welcome_seq_4,
  //   variants: {
  //     initial: { opacity: 1, display: 'none' }, // Starts visible with no change
  //     enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
  //     exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
  //   },
  //   extraAnimations: [],
  // };

  const seq5 = {
    id: 'seq5',
    // words: seq5Words,
    sentence: 'Your best scores?',
    wordsVariants: seq5Variants,
    // wordsClassName: '',
    wordsStyles: { margin: '5px' },
    enterDelay: extraAnimationDelay + extraAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.insights_seq_5,
    variants: {
      initial: {
        opacity: 0,
        y: -50,
        display: 'none',
      },
      enter: {
        opacity: 1,
        y: -50,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
          when: 'beforeChildren',
        },
      },
      // exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq6 = {
    id: 'seq6',
    enterDelay: extraAnimationDelay + extraAnimationDuration + seq5EnterTime,
    // exitDelay: 0,
    words: [
      {
        id: `seq6-word${1}`,
        text: bestScoreTimeOFTheDay,
        enterDelay:
          extraAnimationDelay + extraAnimationDuration + seq5EnterTime,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            // y: 100,
            // transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            // y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              staggerChildren: 0.1,
              ease: 'easeInOut',
            },
          },
        },
        style: {},
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
      },
    ],
    style: {},
    className: styles.insights_seq_6,
    variants: {
      initial: {
        opacity: 0,
        y: -100,
      },
      enter: {
        opacity: 1,
        y: -100,
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
        },
      },
    },
    extraAnimations: [],
  };

  return [
    seq1,
    seq2,
    seq3,
    seq4,
    // seq5,
    // seq6
  ];
};

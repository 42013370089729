import React, { useState } from 'react';
import exact from 'prop-types-exact';
// import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import styles from './ProtocolDescriptionWidget.scss';

const ProtocolDescriptionWidgetBase = () => {
  const t = useTranslation();

  const [description, setDescription] = useState('');

  return (
    <>
      <div
        className={styles.widget_container}
        id="protocol-description-widget-root"
      >
        <div className={styles.card_header}>
          <h2>{t('protocol-description-widget-title')}</h2>
          <small>{t('protocol-description-widget-description')}</small>
        </div>
        <div className={styles.card_body}>
          <div className={classNames(styles.textarea_container)}>
            <textarea
              type="text"
              id="protocol-description-edit"
              placeholder={t('add-a-description-placeholder')}
              value={description}
              onChange={e => {
                setDescription(e.target.value);
              }}
              maxLength="300"
            />
          </div>
          <div className={styles.btn_container}>
            <Button
              variant="contained"
              color="primary"
              style={{
                padding: '8px 40px',
                // borderRadius: '5px',
                fontSize: '13px',
              }}
              id="save"
              // eslint-disable-next-line
              onClick={showDialogBeforeSaveEdit}
            >
              {t('save-success')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

ProtocolDescriptionWidgetBase.propTypes = exact({});

export const ProtocolDescriptionWidget = withEntryComponent(
  React.memo(ProtocolDescriptionWidgetBase)
);

import React from 'react';
import exact from 'prop-types-exact';
import styles from './ContinuityChip.scss';

const ContinuityChipBase = () => {
  return (
    <span className={styles.root}>
      <span>Continuity</span>
    </span>
  );
};

ContinuityChipBase.propTypes = exact({});

export const ContinuityChip = React.memo(ContinuityChipBase);
ContinuityChip.displayName = 'ContinuityChip';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import has from 'lodash/has';
import snakeCase from 'lodash/snakeCase';
import Paper from '@material-ui/core/Paper';
import HelpIcon from '@material-ui/icons/Help';
import increasingArrowPNG from '../../../assets/increasing-arrow.png';
import decreasingArrowPNG from '../../../assets/decreasing-arrow.png';

import { setFixedDecimalsIfNeeded, defensiveThrow } from '../../utils/utils';

import styles from './CPTPerformanceGraph.scss';
import { CustomTable } from '../../Core/Components/CustomTable/CustomTable';
import {
  ASSESSMENT_COLORS,
  CPTCategories,
  CPTCategoriesFormattedNames,
} from '../../models/assessments/assessments';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { mdMediaMaxWidth } from '../../cssInJs/constants';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';

const RowCell = ({ content }) => (
  <span className={styles.row_cell}>{content}</span>
);
RowCell.propTypes = exact({
  content: PropTypes.node.isRequired,
});

const CategoryLabel = ({ isInTableHead, category }) => {
  const t = useTranslation();

  const categoryDescriptionTooltip = (
    <TooltipWrapper
      title={
        <TooltipTitle>
          {t(`cpt_category_${snakeCase(category)}_description`)}
        </TooltipTitle>
      }
    >
      <HelpIcon style={{ fontSize: 16 }} className="hide-on-print" />
    </TooltipWrapper>
  );

  return isInTableHead ? (
    <span className={styles.head_cell}>
      <span>{CPTCategoriesFormattedNames[category]}</span>
      {categoryDescriptionTooltip}
    </span>
  ) : (
    <TooltipWrapper
      title={
        <TooltipTitle>
          {t(`cpt_category_${snakeCase(category)}_description`)}
        </TooltipTitle>
      }
    >
      <span className={styles.row_cell}>
        <span style={{ marginRight: 5 }}>
          {CPTCategoriesFormattedNames[category]}
        </span>
        <HelpIcon style={{ fontSize: 16 }} className="hide-on-print" />
      </span>
    </TooltipWrapper>
  );
};

CategoryLabel.propTypes = exact({
  isInTableHead: PropTypes.bool,
  category: PropTypes.string.isRequired,
});

const getUnit = ({ CPTCategory }) => {
  if (CPTCategory === CPTCategories.accuracy) {
    return '%';
  }

  if (
    CPTCategory === CPTCategories.reactionTime ||
    CPTCategory === CPTCategories.responsesSD
  ) {
    return 'ms';
  }

  return '';
};

const changeIndicators = {
  positive: 'positive',
  negative: 'negative',
};

const getChangeIndicator = ({ category, percentageOfChange, propertyName }) => {
  if (
    category === CPTCategories.accuracy ||
    propertyName === 'postPerformanceScore'
  ) {
    if (percentageOfChange > 0) {
      return changeIndicators.positive;
    }

    return changeIndicators.negative;
  }

  return percentageOfChange < 0
    ? changeIndicators.positive
    : changeIndicators.negative;
};

const getChangeTextColorAndIconPNG = ({
  category,
  percentageOfChange,
  propertyName,
}) => {
  const changeIndicator = getChangeIndicator({
    category,
    percentageOfChange,
    propertyName,
  });
  if (changeIndicator === changeIndicators.positive) {
    return {
      textColor: ASSESSMENT_COLORS.POSITIVE,
      iconPNG: increasingArrowPNG,
    };
  }

  return {
    textColor: ASSESSMENT_COLORS.NEGATIVE,
    iconPNG: decreasingArrowPNG,
  };
};

const getSubtractionValueText = (subtractionValue, unit, valueType) => {
  const formattedSubtractionValue =
    valueType === 'performance'
      ? setFixedDecimalsIfNeeded({ number: subtractionValue })
      : parseInt(subtractionValue, 10);
  const subtractionValueText =
    subtractionValue > 0
      ? `+${formattedSubtractionValue}${unit}`
      : `${formattedSubtractionValue}${unit}`;

  return subtractionValueText;
};

const getFormattedScoreText = ({ scoreObject, propertyName, valueType }) => {
  if (valueType === 'absolute') {
    const unit = getUnit({ CPTCategory: scoreObject.category });
    const score = setFixedDecimalsIfNeeded({
      number: scoreObject[propertyName],
    });
    const formattedScore = parseInt(score, 10);
    return `${formattedScore}${unit}`;
  }

  return setFixedDecimalsIfNeeded({
    number: scoreObject[propertyName],
  });
};

const ScoreContent = ({ scoreObject, propertyName }) => {
  const valueType =
    ['score', 'preScore', 'postScore'].indexOf(propertyName) > -1
      ? 'absolute'
      : 'performance';

  const subtractionValue =
    valueType === 'absolute'
      ? parseInt(scoreObject.postScore, 10) - parseInt(scoreObject.preScore, 10)
      : scoreObject.postPerformanceScore - scoreObject.prePerformanceScore;

  const unit =
    valueType === 'performance'
      ? ''
      : getUnit({ CPTCategory: scoreObject.category });
  const subtractionValueText = getSubtractionValueText(
    subtractionValue,
    unit,
    valueType
  );

  const {
    textColor: subtractionValueTextColor,
    iconPNG: changeIconPNG,
  } = getChangeTextColorAndIconPNG({
    category: scoreObject.category,
    percentageOfChange: subtractionValue,
    propertyName,
  });

  const changeIcon = <img src={changeIconPNG} style={{ maxWidth: 12 }} />;
  const formattedScoreText = getFormattedScoreText({
    scoreObject,
    propertyName,
    valueType,
  });

  const renderPerformanceRankSpan = performanceRankPropertyName => {
    const performanceRank = scoreObject[performanceRankPropertyName];
    const color = (() => {
      switch (performanceRank) {
        case 'Very Superior':
          return '#04AA89';
        case 'Superior':
          return '#04CC89';
        case 'Very High':
          return '#04DD89';
        case 'High':
          return '#04EE89';
        case 'Medium':
          return '#DAB363';
        case 'Low':
          return '#FF8383';
        default:
          defensiveThrow({
            error: new Error(
              `Unknown performance rank string identifier: ${performanceRank}`
            ),
          });
          return 'inherit';
      }
    })();
    return <span style={{ color, fontWeight: 'bold' }}>{performanceRank}</span>;
  };

  if (propertyName === 'performanceScore') {
    return (
      <span className={styles.data_span}>
        {renderPerformanceRankSpan('performanceRank')}
        <span>({scoreObject.performanceScore})</span>
      </span>
    );
  }

  if (propertyName === 'prePerformanceScore') {
    return (
      <span className={styles.data_span}>
        {renderPerformanceRankSpan('prePerformanceRank')}
        <span>({scoreObject.prePerformanceScore})</span>
      </span>
    );
  }

  if (propertyName === 'postPerformanceScore') {
    return (
      <span className={styles.data_span}>
        {renderPerformanceRankSpan('postPerformanceRank')}
        <span>({scoreObject.postPerformanceScore})</span>
        {subtractionValue && Number.isFinite(subtractionValue) ? (
          <span
            style={{
              display: 'flex',
            }}
          >
            (<span style={{ marginLeft: 1 }}>{changeIcon}</span>
            <span
              style={{
                marginLeft: 3,
                color: subtractionValueTextColor,
                marginRight: 3,
              }}
            >
              {subtractionValueText}
            </span>
            )
          </span>
        ) : null}
      </span>
    );
  }

  return propertyName === 'postScore' ? (
    <span className={styles.data_span}>
      <span
        className={
          propertyName === 'postScore' ? styles.post_score_text : undefined
        }
      >
        {formattedScoreText}
      </span>
      {subtractionValue && Number.isFinite(subtractionValue) ? (
        <span
          style={{
            display: 'flex',
          }}
        >
          (<span style={{ marginLeft: 1 }}>{changeIcon}</span>
          <span
            style={{
              marginLeft: 3,
              color: subtractionValueTextColor,
              marginRight: 3,
            }}
          >
            {subtractionValueText}
          </span>
          )
        </span>
      ) : null}
    </span>
  ) : (
    formattedScoreText
  );
};

ScoreContent.propTypes = exact({
  scoreObject: PropTypes.object.isRequired,
  propertyName: PropTypes.string.isRequired,
});

const CPTPerformanceGraphBase = ({ data, tableTitle }) => {
  const [isMatchingMDScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${mdMediaMaxWidth}px)`,
  });

  const isComparisonMode = !has(data[0], 'score');
  const tableHeads = [
    {
      node: ' ',
      id: 'emptyColumn',
    },
    ...data.map(dataObject => ({
      node: <CategoryLabel isInTableHead category={dataObject.category} />,
      id: dataObject.category,
    })),
  ];

  const mobileTableHeads = !isComparisonMode
    ? [
        {
          node: '',
          id: 'emptyCell',
        },
        {
          node: <span className={styles.head_cell}>Raw</span>,
          id: 'raw',
        },
        {
          node: (
            <TooltipWrapper
              title={
                <TooltipTitle>
                  Standardized performance is categorized by percentile (Low:
                  &lt;25th; Medium: 25-49th; High: 50-74th; Very High: 75-89th;
                  Superior: 90-94th; Very Superior: &ge;95th) compared to
                  Myndlift’s database. Z-scores are shown in parentheses.
                </TooltipTitle>
              }
            >
              <span className={styles.head_cell}>
                <span>Standardized </span>

                <HelpIcon style={{ fontSize: 16 }} className="hide-on-print" />
              </span>
            </TooltipWrapper>
          ),
          id: 'performance',
        },
      ]
    : [
        {
          node: '',
          id: 'emptyCell',
        },
        {
          node: <span className={styles.head_cell}>Pre</span>,
          id: 'preScoreId',
        },
        {
          node: <span className={styles.head_cell}>Post</span>,
          id: 'postScoreId',
        },
      ];

  const getRowCells = ({ propertyName }) =>
    data.map(scoreObject => {
      const content = (
        <ScoreContent propertyName={propertyName} scoreObject={scoreObject} />
      );
      return {
        node: <RowCell content={content} />,
        id: scoreObject.category,
      };
    });

  const comparisonModeTableRows = [
    {
      cells: [
        {
          node: <RowCell content="Pre" />,
          id: 'pre',
        },
        ...getRowCells({
          propertyName: has(data[0], 'prePerformanceScore')
            ? 'prePerformanceScore'
            : 'preScore',
        }),
      ],
      id: 'prePerformanceScoreId',
    },
    {
      cells: [
        {
          node: <RowCell content="Post" />,
          id: 'post',
        },
        ...getRowCells({
          propertyName: has(data[0], 'postPerformanceScore')
            ? 'postPerformanceScore'
            : 'postScore',
        }),
      ],
      id: 'postPerformanceScoreId',
    },
  ];

  const tableRows = !isComparisonMode
    ? [
        {
          cells: [
            {
              node: <RowCell content="Raw" />,
              id: 'rawData',
            },
            ...getRowCells({ propertyName: 'score' }),
          ],
          id: 'rawData',
        },
        {
          cells: [
            {
              node: (
                <RowCell
                  content={
                    <React.Fragment>
                      <span>Standardized </span>
                      <TooltipWrapper
                        title={
                          <TooltipTitle>
                            Standardized performance is categorized by
                            percentile (Low: &lt;25th; Medium: 25-49th; High:
                            50-74th; Very High: 75-89th; Superior: 90-94th; Very
                            Superior: &ge;95th) compared to Myndlift’s database.
                            Z-scores are shown in parentheses.
                          </TooltipTitle>
                        }
                      >
                        <HelpIcon
                          style={{ fontSize: 16 }}
                          className="hide-on-print"
                        />
                      </TooltipWrapper>
                    </React.Fragment>
                  }
                />
              ),
              id: 'performanceData',
            },
            ...getRowCells({ propertyName: 'performanceScore' }),
          ],
          id: 'performanceData',
        },
      ]
    : comparisonModeTableRows;

  const mobileTableRows = !isComparisonMode
    ? data.map(scoreObject => ({
        cells: [
          {
            node: (
              <RowCell
                content={<CategoryLabel category={scoreObject.category} />}
              />
            ),
            id: scoreObject.category,
          },
          {
            node: (
              <RowCell
                content={
                  <ScoreContent
                    propertyName="score"
                    scoreObject={scoreObject}
                  />
                }
              />
            ),
            id: 'score',
          },
          {
            node: (
              <RowCell
                content={
                  <ScoreContent
                    propertyName="performanceScore"
                    scoreObject={scoreObject}
                  />
                }
              />
            ),
            id: 'performanceScore',
          },
        ],
        id: scoreObject.category,
      }))
    : data.map(scoreObject => ({
        cells: [
          {
            node: (
              <RowCell
                content={<CategoryLabel category={scoreObject.category} />}
              />
            ),
            id: scoreObject.category,
          },
          {
            node: (
              <RowCell
                content={
                  <ScoreContent
                    propertyName={
                      has(data[0], 'prePerformanceScore')
                        ? 'prePerformanceScore'
                        : 'preScore'
                    }
                    scoreObject={scoreObject}
                  />
                }
              />
            ),
            id: 'preScore',
          },
          {
            node: (
              <RowCell
                content={
                  <ScoreContent
                    propertyName={
                      has(data[0], 'postPerformanceScore')
                        ? 'postPerformanceScore'
                        : 'postScore'
                    }
                    scoreObject={scoreObject}
                  />
                }
              />
            ),
            id: 'postScoreId',
          },
        ],
        id: scoreObject.category,
      }));

  return (
    <div
      className={isComparisonMode ? styles.root_comparison_mode : styles.root}
    >
      <Paper style={{ width: '100%' }}>
        {tableTitle && (
          <div>
            <p className={styles.table_title}>{tableTitle}</p>
          </div>
        )}
        <div className={styles.table_container}>
          <CustomTable
            tableHeads={isMatchingMDScreen ? mobileTableHeads : tableHeads}
            tableRows={isMatchingMDScreen ? mobileTableRows : tableRows}
            shouldShowRowsPerPageOptions={false}
          />
        </div>
      </Paper>
    </div>
  );
};

CPTPerformanceGraphBase.propTypes = exact({
  data: PropTypes.array.isRequired,
  tableTitle: PropTypes.string,
});

export const CPTPerformanceGraph = React.memo(CPTPerformanceGraphBase);
CPTPerformanceGraph.displayName = 'CPTPerformanceGraph';

import React, { useEffect } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  TextField,
  CircularProgress,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import placeHolder from '../../../../assets/add-photo.png';
import { LagoListItem } from '../LagoListItem/LagoListItem';
import styles from './LagoJournalDialog.scss';

const LagoJournalDialogBase = ({
  open,
  onClose,
  setJournalEditDialogOpen,
  selectedJournal,
  journals,
  onItemClicked,
  screensItemsData,
  currentStage,
  onEditItemClicked,
  onAddNewVariantClicked,
  editVariant,
  variantClicked,
  setKeyToDelete,
  setDisplayDeleteDialog,
  setJournals,
  onSaveJournal,
  journalTitle,
  setJournalTitle,
  journalAllowFeedback,
  setJournalAllowFeedback,
  journalType,
  setJournalType,
  // journalEstimatedMinutes,
  // setJournalEstimatedMinutes,
  setDisplayUploadedImages,
  isLoadingVideo,
  handleOnDragEnd,
  isFinalizedState,
}) => {
  useEffect(() => {
    if (journals && journals[selectedJournal]) {
      if (journals[selectedJournal].title) {
        setJournalTitle(journals[selectedJournal].title.en);
      }
      if (journals[selectedJournal].allowFeedback !== undefined) {
        setJournalAllowFeedback(
          journals[selectedJournal].allowFeedback.clinicFeedback
        );
      }
      if (journals[selectedJournal].journalType !== undefined) {
        setJournalType(journals[selectedJournal].journalType);
      }
      // if (journals[selectedJournal].estimatedMins) {
      //   setJournalEstimatedMinutes(+journals[selectedJournal].estimatedMins);
      // }
    }
  }, [open]);

  const IMAGE_PATH_PREFIX = 'lago/educational/defaults/images/';

  const onImageFileChange = async event => {
    const path =
      IMAGE_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const [file] = event.target.files;
    // setImageFile(file);
    const storage = window.firebase.storage();
    const imageRef = storage.ref(path);
    try {
      await imageRef.put(file);
      imageRef
        .getDownloadURL()
        .then(res => {
          const copy = JSON.parse(JSON.stringify(journals));
          copy[selectedJournal].img = res;
          setJournals(copy);
        })
        .catch(() => {});
    } catch (err) {
      // TODO
    }
  };

  const removeImage = () => {
    const copy = JSON.parse(JSON.stringify(journals));
    copy[selectedJournal].img = '';
    setJournals(copy);
    const el = document.getElementById('image-input-journal');
    el.value = '';
  };

  // const calcNumOfEmojiQuiz = () => {
  //   let numOfEmojiQuiz = 0;
  //   if (
  //     journals &&
  //     journals[selectedJournal] &&
  //     journals[selectedJournal].screens &&
  //     Object.keys(journals[selectedJournal].screens).length
  //   ) {
  //     Object.keys(journals[selectedJournal].screens).forEach(screenId => {
  //       if (
  //         screensItemsData[screenId].variants[
  //           Object.keys(screensItemsData[screenId].variants)[0]
  //         ].screenType === 'quiz'
  //       ) {
  //         if (
  //           screensItemsData[screenId].variants[
  //             Object.keys(screensItemsData[screenId].variants)[0]
  //           ].quizDetails.quizType === 'emoji'
  //         ) {
  //           numOfEmojiQuiz += 1;
  //         }
  //       }
  //     });
  //   }
  //   return numOfEmojiQuiz;
  // };

  let sortedJournalScreens = [];
  if (
    journals &&
    journals[selectedJournal] &&
    journals[selectedJournal].screens
  ) {
    sortedJournalScreens = Object.keys(journals[selectedJournal].screens);
    sortedJournalScreens.sort(
      (a, b) =>
        journals[selectedJournal].screens[a] -
        journals[selectedJournal].screens[b]
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      //   className={styles.lago_dialog_container}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '20px', textAlign: 'center' }}>
          Work settings
        </span>
      </DialogTitle>
      <DialogContent>
        <div className={styles.journal_dialog_container}>
          <div style={{ width: '70%' }}>
            <div className={styles.journal_inputs_container}>
              <TextField
                value={journalTitle}
                onChange={e => setJournalTitle(e.target.value)}
                label="Work title"
                style={{ width: '200px' }}
                disabled={isFinalizedState}
              />
              {/* <TextField
                value={journalEstimatedMinutes}
                onChange={e => setJournalEstimatedMinutes(+e.target.value)}
                label="Estimated minutes"
                type="number"
              /> */}
              <Button
                onClick={setJournalEditDialogOpen}
                style={{ maxWidth: '100px', margin: '10px', marginTop: '10px' }}
                variant="contained"
                disabled={isFinalizedState}
              >
                add screen
              </Button>
            </div>
            <input
              accept="image/png, image/gif, image/jpeg"
              hidden
              id="image-input-journal"
              type="file"
              onChange={onImageFileChange}
              style={{ display: 'none' }}
            />
            <div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={screensItemsData}>
                  {provided => (
                    <div
                      // className={styles.lago_control_list_container}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      // style={{ width: '50%' }}
                    >
                      {journals &&
                      journals[selectedJournal] &&
                      journals[selectedJournal].screens
                        ? sortedJournalScreens.map((screenId, index) => {
                            return (
                              <Draggable
                                key={screenId}
                                draggableId={screenId}
                                index={
                                  journals &&
                                  journals[selectedJournal] &&
                                  journals[selectedJournal].screens &&
                                  journals[selectedJournal].screens[screenId]
                                    ? journals[selectedJournal].screens[
                                        screenId
                                      ]
                                    : index
                                }
                              >
                                {provided1 => (
                                  <div
                                    ref={provided1.innerRef}
                                    {...provided1.draggableProps}
                                    {...provided1.dragHandleProps}
                                  >
                                    <LagoListItem
                                      key={screenId}
                                      element={screensItemsData[screenId]}
                                      onItemClicked={() =>
                                        onItemClicked(screenId)
                                      }
                                      onDeleteItemClicked={e => {
                                        e.stopPropagation();
                                        setKeyToDelete(screenId);
                                        setDisplayDeleteDialog(true);
                                        // delete journal
                                      }}
                                      currentStage={currentStage}
                                      onEditItemClicked={onEditItemClicked}
                                      onAddNewVariantClicked={e =>
                                        onAddNewVariantClicked(e, screenId)
                                      }
                                      editVariant={editVariant}
                                      variantClicked={variantClicked}
                                      isFromJournal
                                      isFinalizedState={isFinalizedState}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        : null}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {isLoadingVideo ? <CircularProgress /> : null}
            </div>
          </div>
          <div className={styles.upload_image_container}>
            <img
              className={styles.lago_control_img}
              src={
                selectedJournal &&
                journals &&
                journals[selectedJournal] &&
                journals[selectedJournal].img
                  ? journals[selectedJournal].img
                  : placeHolder
              }
              alt="placeHolder"
            />
            <div className={styles.upload_image_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-input-journal').click();
                }}
                color="primary"
                variant="contained"
                style={{ maxWidth: '120px' }}
                disabled={isFinalizedState}
              >
                upload image
              </Button>
              <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px', maxWidth: '120px' }}
                disabled={isFinalizedState}
              >
                select uploaded
              </Button>
              {selectedJournal &&
              journals &&
              journals[selectedJournal] &&
              journals[selectedJournal].img ? (
                <Button
                  onClick={() => {
                    removeImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                  disabled={isFinalizedState}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        {!journalTitle ? (
          <div style={{ color: 'red' }}>Missing title</div>
        ) : null}
        {/* {journalEstimatedMinutes <= 0 ? (
          <div style={{ color: 'red' }}>Missing estimated minutes</div>
        ) : null} */}
        {journalType === 'journal' ? (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Allow provider feedback
            </div>
            <Switch
              color="primary"
              checked={journalAllowFeedback}
              onChange={e => {
                setJournalAllowFeedback(e.target.checked);
              }}
            />
          </div>
        ) : null}
        <Select
          value={journalType}
          onChange={e => setJournalType(e.target.value)}
        >
          <MenuItem value="exercise">Exercise</MenuItem>
          <MenuItem value="journal">Journal</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          size="large"
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          disabled={isLoadingVideo}
        >
          close
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() =>
            onSaveJournal(journalTitle, journalAllowFeedback, journalType)
          }
          disabled={
            !journalTitle ||
            // journalEstimatedMinutes <= 0 ||
            isLoadingVideo ||
            isFinalizedState
          }
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoJournalDialogBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setJournalEditDialogOpen: PropTypes.func,
  selectedJournal: PropTypes.string,
  journals: PropTypes.object,
  onItemClicked: PropTypes.func,
  screensItemsData: PropTypes.object,
  currentStage: PropTypes.number,
  onEditItemClicked: PropTypes.func,
  onAddNewVariantClicked: PropTypes.func,
  editVariant: PropTypes.func,
  variantClicked: PropTypes.func,
  setKeyToDelete: PropTypes.func,
  setDisplayDeleteDialog: PropTypes.func,
  setJournals: PropTypes.func,
  onSaveJournal: PropTypes.func,
  journalTitle: PropTypes.string,
  setJournalTitle: PropTypes.func,
  journalAllowFeedback: PropTypes.bool,
  setJournalAllowFeedback: PropTypes.func,
  journalType: PropTypes.string,
  setJournalType: PropTypes.func,
  // journalEstimatedMinutes: PropTypes.number,
  // setJournalEstimatedMinutes: PropTypes.func,
  setDisplayUploadedImages: PropTypes.func,
  isLoadingVideo: PropTypes.bool,
  handleOnDragEnd: PropTypes.func,
  isFinalizedState: PropTypes.bool,
});

export const LagoJournalDialog = React.memo(LagoJournalDialogBase);
LagoJournalDialog.displayName = 'LagoJournalDialog';

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import exact from 'prop-types-exact';
import { CreateProgramPopUp } from '../CreateProgramPopUp/CreateProgramPopUp';
import styles from './CreateProgramForUserWrapper.scss';

const CreateProgramForUserWrapperBase = ({
  customerId,
  wrappedComponent = (
    <span style={{ color: '#1a7ff8', cursor: 'pointer' }}>
      + Create program
    </span>
  ),
  onWrappedComponentClick = () => {},
  isWrappedComponentHidden = false,
}) => {
  const [
    isCreateProgramPopUpInAdvancedMode,
    setIsCreateProgramPopUpInAdvancedMode,
  ] = useState(sessionStorage.isSimpleDashboard !== 'true');

  const [isCreateProgramPopUpOpen, setIsCreateProgramPopUpOpen] = useState(
    false
  );

  const onProgramSettingsAdvancedModeChange = event => {
    const mode = event.target.checked;

    setIsCreateProgramPopUpInAdvancedMode(mode);
    sessionStorage.isSimpleDashboard = !mode;
    database
      .ref('clinics')
      .child(sessionStorage.userId)
      .child('dashboardType')
      .set(mode ? 'advanced' : 'basic');

    const eventName = mode
      ? 'advanced_mode_on_clicked'
      : 'basic_mode_on_clicked';
    new MyEvent(eventName).log();
  };

  const closeCreateProgramPopUpModal = useCallback(() => {
    setIsCreateProgramPopUpOpen(false);
  }, [setIsCreateProgramPopUpOpen]);

  return (
    <>
      <CreateProgramPopUp
        // eslint-disable-next-line no-undef
        protocols={protocolsObjectToArrWithNamesInjected(protocolsConfig)}
        database={database}
        // eslint-disable-next-line no-undef
        modifyProtocol={modifyProtocol}
        // eslint-disable-next-line no-undef
        getOverAllSuccessRate={getOverAllSuccessRate}
        // eslint-disable-next-line no-undef
        searchProtocol={searchProtocol}
        // eslint-disable-next-line no-undef
        PatientProtocolDetails={ProtocolDetails}
        getRecommendedPlacementNameBasedOnPlacement={
          // eslint-disable-next-line no-undef
          getRecommendedPlacementNameBasedOnPlacement
        }
        // eslint-disable-next-line no-undef
        getChosenPlacement={getChosenPlacement}
        // eslint-disable-next-line no-undef
        getChoosenPlacementDescriptionHTML={getChoosenPlacementDescriptionHTML}
        // eslint-disable-next-line no-undef
        placements={placements}
        extractFrequencyName={(min, max) =>
          extractFrequency(min, max, frequenciesConfig)
        }
        clinicId={sessionStorage.userId}
        patientId={customerId}
        isModalOpen={isCreateProgramPopUpOpen}
        closeModal={closeCreateProgramPopUpModal}
        isEditingMode={false}
        isProgramSettingsAdvancedMode={isCreateProgramPopUpInAdvancedMode}
        onProgramSettingsAdvancedModeChange={
          onProgramSettingsAdvancedModeChange
        }
      />

      <div
        onClick={() => {
          setIsCreateProgramPopUpOpen(true);
          onWrappedComponentClick();
        }}
        className={classNames(styles.wrapped_component_wrapper, {
          [styles.hidden]: isWrappedComponentHidden,
        })}
      >
        {wrappedComponent}
      </div>
    </>
  );
};

CreateProgramForUserWrapperBase.propTypes = exact({
  customerId: PropTypes.string.isRequired,
  wrappedComponent: PropTypes.node,
  // defaultWrappedComponentProps: PropTypes.object,
  onWrappedComponentClick: PropTypes.func,
  isWrappedComponentHidden: PropTypes.bool,
});

export const CreateProgramForUserWrapper = React.memo(
  CreateProgramForUserWrapperBase
);
CreateProgramForUserWrapper.displayName = 'CreateProgramForUserWrapper';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SendIcon from '@material-ui/icons/Send';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { CopyIcon } from '../../Core/Icons/CopyIcon';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { xsExtraMediaMaxWidth } from '../../cssInJs/constants';
import {
  isValidEmail,
  postToFunctionsWithDefaultData,
} from '../../utils/utils';
// import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';
// import registrationCodeImage from '../../../assets/registration-code.gif';
// import museOnMaleHeadImage from '../../../assets/muse-on-male-head.png';
import archiveClientImage from '../../../assets/archive-client.png';
import styles from './SelfRegistrationDialog.scss';
import { SelfRegistrationEditDialog } from '../SelfRegistrationEditDialog/SelfRegistrationEditDialog';
import UpgradeDialogImg from '../CustomersList/upgrade-dialog.png';

const SelfRegistrationDialogBase = ({
  isOpen,
  onClose,
  registrationCode,
  onAddClientClicked,
  noAddClient,
  reachedClientsLimit,
  usersIncluded,
  onViewPricingPlansClicked,
  clinicNonfPlan,
}) => {
  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsExtraMediaMaxWidth}px)`,
  });

  const [clipboardClicked, setClipboardClicked] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  // const [includeLinkToggle, setIncludeLinkToggle] = useState(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [showError, setShowError] = useState('');

  const [registrationCodeData] = useFirebaseDB({
    path: `clinicConfig/activationCodes/${registrationCode}`,
    // errorListener: ()=>{}
  });

  const [includeLinkToggle, setIncludeLinkToggle] = useFirebaseDB({
    path: `userInfo/${sessionStorage.userId}/includeLinkToggle`,
  });

  const copyToClipboard = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(registrationCode);
    setClipboardClicked(true);
    setTimeout(() => {
      setClipboardClicked(false);
    }, 2000);
    new MyEvent('self_reg_registration_code_copied').log();
  };

  const sendEmail = async () => {
    if (!isValidEmail(emailInput)) {
      setShowError('Please enter a valid email address');
      return;
    }
    setIsLoadingSendEmail(true);
    try {
      // eslint-disable-next-line no-unused-vars, no-undef
      const res = await postToFunctionsWithDefaultData({
        path: 'manage_users-emailInfo',
        data: { email: emailInput },
      });
      if (!res.data) {
        setShowError('Something went wrong.');
        setIsLoadingSendEmail(false);
        return;
      }
      if (res.data.exists) {
        if (res.data.doesPatientBelongToClinic) {
          setShowError('This client is already linked to your clinic');
        } else {
          setShowError('This client is linked to another provider');
        }
        setIsLoadingSendEmail(false);
        return;
      }
    } catch (e) {
      setShowError('Something went wrong.');
      setIsLoadingSendEmail(false);
      return;
    }
    try {
      // eslint-disable-next-line no-unused-vars, no-undef
      const data = await fireFunctionPost('clinic_apis-sendClinicCode', {
        hardwareIncluded: !!includeLinkToggle,
        emails: [emailInput],
        userId: sessionStorage.userId,
        fbtoken: sessionStorage.fbtoken,
      });

      if (!data.result) {
        if (data.message) {
          setShowError(data.message);
          setIsLoadingSendEmail(false);
          return;
        }
        setShowError('Something went wrong.');
        setIsLoadingSendEmail(false);
        return;
      }
    } catch (e) {
      if (e.message) {
        setShowError(e.message);
      } else {
        setShowError('Something went wrong.');
      }
      setIsLoadingSendEmail(false);
      return;
    }

    showNotification('success', 'Success! Message sent');
    // new MyEvent('share_code_email_sent').log({equipment_link: includeLinkToggle});
    setIsLoadingSendEmail(false);
    setEmailInput('');
  };

  const renderLeftContent = () => {
    if (clinicNonfPlan && reachedClientsLimit) {
      return (
        <div className={styles.reached_limit_container}>
          <div style={{ fontSize: 15, fontWeight: 900 }}>
            Your current plan supports up to {usersIncluded} clients only
          </div>
          <img
            src={UpgradeDialogImg}
            width={80}
            height={98}
            style={{ alignSelf: 'center', marginTop: 20 }}
          />
          <div style={{ fontSize: 18, fontWeight: 600, marginTop: 20 }}>
            Upgrade your plan
          </div>
          <div className={styles.points_texts}>
            <div className={styles.points_text}>
              <DoneIcon style={{ color: '#4be0d0' }} />
              To deploy assessments and programs for new clients.
            </div>
            <div className={styles.points_text}>
              <DoneIcon style={{ color: '#4be0d0' }} />
              Give them access to the full Myndlift app.
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={async () => {
              onViewPricingPlansClicked();
            }}
            style={{ fontSize: '1.1em' }}
          >
            view pricing plans
          </Button>
          <div className={styles.circle_devider}>OR</div>
        </div>
      );
    }
    return (
      <div className={styles.add_new_client_container}>
        <div
          className={styles.add_new_client_content}
          onClick={() => {
            onClose();
            onAddClientClicked();
            setEmailInput('');
          }}
        >
          <PersonAddIcon />
          <div>Manually add a new client</div>
        </div>
        <div className={styles.circle_devider}>OR</div>
      </div>
    );
  };

  if (clinicNonfPlan && reachedClientsLimit && !noAddClient) {
    return (
      <Dialog open={isOpen} fullWidth maxWidth="md">
        <DialogContent style={{ padding: 20 }}>
          <div
            onClick={() => {
              onClose();
              setEmailInput('');
            }}
            className={styles.close_dialog}
            // style={{ marginTop: 20, marginRight: 20 }}
          >
            <CloseIcon />
          </div>
          <div
            style={{
              fontSize: 22,
              fontWeight: 900,
              textAlign: 'center',
              margin: 10,
            }}
          >
            Your current plan supports up to {usersIncluded} clients only
          </div>
          <div style={{ display: 'flex' }}>
            <div
              className={styles.reached_limit_container}
              style={{
                width: '50%',
                backgroundColor: 'unset',
                borderRight: '1px solid #ded9d9',
              }}
            >
              <img
                src={archiveClientImage}
                width={96}
                height={102}
                style={{ alignSelf: 'center', marginTop: 20 }}
              />
              <div style={{ fontSize: 18, fontWeight: 600, marginTop: 20 }}>
                Archive an existing client
              </div>
              <div
                className={styles.points_texts}
                style={{ textAlign: 'center', padding: '0 50px' }}
              >
                You can archive a client to make room for a new one by selecting
                one from the list of clients → archive
              </div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={async () => {
                  window.open('./customers.html', '_self');
                }}
                style={{ fontSize: '1.1em', marginBottom: 20 }}
              >
                VIEW LIST OF CLIENTS
              </Button>
              <div className={styles.circle_devider}>OR</div>
            </div>
            <div
              className={styles.reached_limit_container}
              style={{ width: '50%', backgroundColor: 'unset' }}
            >
              <img
                src={UpgradeDialogImg}
                width={80}
                height={98}
                style={{ alignSelf: 'center', marginTop: 20 }}
              />
              <div style={{ fontSize: 18, fontWeight: 600, marginTop: 20 }}>
                Upgrade your plan
              </div>
              <div
                className={styles.points_texts}
                style={{ textAlign: 'center', padding: '0 50px' }}
              >
                To increase the maximum number of clients that can be added to
                your dashboard
              </div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={async () => {
                  onViewPricingPlansClicked();
                }}
                style={{ fontSize: '1.1em', marginBottom: 20 }}
              >
                view pricing plans
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} fullWidth={!noAddClient} maxWidth="md">
      <DialogContent style={{ padding: 0 }}>
        <div className={styles.container}>
          {noAddClient ? null : renderLeftContent()}
          <div
            style={
              noAddClient || isMatchingXSScreen
                ? { width: '100%', paddingLeft: 40, paddingRight: 40 }
                : { width: '70%', paddingLeft: 40, paddingRight: 40 }
            }
          >
            <div
              onClick={() => {
                onClose();
                setEmailInput('');
              }}
              className={styles.close_dialog}
              // style={{ marginTop: 20 }}
            >
              <CloseIcon />
            </div>
            <div className={styles.content_contaiiner}>
              <div className={styles.content_no_image}>
                <div
                  style={
                    noAddClient || isMatchingXSScreen
                      ? { display: 'flex', width: '100%' }
                      : { display: 'flex' }
                  }
                >
                  <div className={styles.title_container}>
                    <div className={styles.main_title}>Invite a client</div>
                    <div className={styles.title_description}>
                      Have your clients download the Myndlift app and
                      self-register with your unique practice code:
                    </div>
                  </div>
                </div>
                <div className={styles.input_and_code_container}>
                  <div style={{ marginRight: 20, position: 'relative' }}>
                    <div
                      className={styles.register_code}
                      // style={noAddClient ? { left: -40 } : null}
                      onClick={copyToClipboard}
                    >
                      {registrationCode}
                      <span className={styles.copy_icon}>
                        <CopyIcon fill="#ffffff" />
                      </span>
                      <span
                        className={styles.clipboard_message}
                        style={
                          clipboardClicked ? { opacity: 1 } : { opacity: 0 }
                        }
                      >
                        Copied to clipboard
                      </span>
                    </div>
                    {registrationCodeData &&
                    registrationCodeData.dateUpdated ? null : (
                      <div
                        className={styles.link_text}
                        onClick={() => {
                          setIsEditDialogOpen(true);
                          new MyEvent(
                            'self_reg_registration_code_customize_clicked'
                          ).log();
                        }}
                      >
                        Customize code
                      </div>
                    )}
                  </div>
                  <div className={styles.email_input_container}>
                    <TextField
                      value={emailInput}
                      onChange={e => {
                        if (showError) {
                          setShowError('');
                        }
                        setEmailInput(e.target.value);
                      }}
                      label={<span style={{ fontSize: 13 }}>Send to</span>}
                      variant="outlined"
                      placeholder="Email address"
                      fullWidth
                      inputProps={{ style: { fontSize: 12 } }}
                      InputLabelProps={{ shrink: true }}
                      error={showError}
                    />
                    {showError ? (
                      <div style={{ marginTop: 5, color: 'red', fontSize: 11 }}>
                        {showError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.self_reg_email_message}>
                  <span className={styles.self_reg_email_message_label}>
                    Message
                  </span>
                  <div style={{ marginBottom: 15 }}>Hi there,</div>

                  <div style={{ marginBottom: 15 }}>
                    You’ve been invited to use Myndlift!
                  </div>
                  <div style={{ marginBottom: 15 }}>
                    Follow these steps to start:
                  </div>

                  <div>
                    1.{' '}
                    <a
                      href="https://myndapp.page.link/app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>{' '}
                    the Myndlift app
                  </div>
                  <div>2. Tap on "Get started"</div>
                  <div style={{ marginBottom: 15 }}>
                    3. Enter the following activation code:{' '}
                    <span style={{ fontWeight: 900 }}>{registrationCode}</span>
                  </div>

                  <div style={{ marginBottom: 15 }}>
                    You will then be successfully linked to your provider.
                  </div>

                  {includeLinkToggle ? (
                    <div style={{ marginBottom: 15 }}>
                      <span style={{ fontWeight: 900 }}>
                        Don't have the equipment?
                      </span>{' '}
                      <a
                        href="https://myndlift.myshopify.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Buy it from here
                      </a>
                      .
                    </div>
                  ) : null}

                  <div>Happy Myndlifting!</div>
                </div>

                <div
                  // style={{
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  //   width: '100%',
                  //   alignItems: 'center',
                  //   marginBottom: 10,
                  // }}
                  className={styles.self_reg_bottom_buttons_container}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={styles.include_equipment}>
                      Include a link to buy equipment
                    </div>
                    <Switch
                      checked={!!includeLinkToggle}
                      onChange={e => setIncludeLinkToggle(e.target.checked)}
                      color="primary"
                    />
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={sendEmail}
                      variant="contained"
                      color="primary"
                      disabled={!emailInput || isLoadingSendEmail || showError}
                    >
                      <span style={{ fontSize: 14, marginRight: 5 }}>Send</span>
                      {!isLoadingSendEmail ? (
                        <SendIcon fontSize="12px" />
                      ) : (
                        <CircularProgress size={14} color="white" />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <SelfRegistrationEditDialog
        isOpen={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
        }}
        registrationCode={registrationCode}
      />
    </Dialog>
  );
};

SelfRegistrationDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  registrationCode: PropTypes.string,
  onAddClientClicked: PropTypes.func,
  noAddClient: PropTypes.bool,
  reachedClientsLimit: PropTypes.bool,
  usersIncluded: PropTypes.number,
  onViewPricingPlansClicked: PropTypes.func,
  clinicNonfPlan: PropTypes.object,
});

export const SelfRegistrationDialog = React.memo(SelfRegistrationDialogBase);

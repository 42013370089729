exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AssignedUsers_team_members_container{display:flex;position:relative}.AssignedUsers_team_member_item{border-radius:50%;font-weight:900;font-size:14px;margin-right:5px;width:40px;height:40px;display:flex;justify-content:center;align-items:center}.AssignedUsers_team_member_item:hover{box-shadow:0px 0px 6px #0000001f}.AssignedUsers_team_member_menu_item{display:flex;background-color:white;padding:5px;align-items:center;padding-right:20px}.AssignedUsers_everyone_team_member_item{background-color:#ededed;color:#626262;border-radius:5px;padding:10px;font-weight:500;display:flex;justify-content:center}.AssignedUsers_empty_team_member_item{width:40px;height:40px;display:flex;justify-content:center;align-items:center;border-radius:50%;font-weight:500;font-size:18px;border:dashed 1px black}.AssignedUsers_empty_team_member_item:hover{box-shadow:0px 0px 6px #0000001f}.AssignedUsers_everyone_team_member_item:hover{box-shadow:0px 0px 6px #0000001f}\n", ""]);

// Exports
exports.locals = {
	"team_members_container": "AssignedUsers_team_members_container",
	"team_member_item": "AssignedUsers_team_member_item",
	"team_member_menu_item": "AssignedUsers_team_member_menu_item",
	"everyone_team_member_item": "AssignedUsers_everyone_team_member_item",
	"empty_team_member_item": "AssignedUsers_empty_team_member_item"
};
import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Backdrop,
} from '@material-ui/core';
// import { format } from 'date-fns';
import dayJS from 'dayjs';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import HelpIcon from '@material-ui/icons/Help';
import InfoMUIIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import { useMediaMatch } from '../../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../../cssInJs/constants';
import { ModalFooter } from '../../../Core/Components/Modal/ModalFooter';
import styles from './CustomerCard.scss';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';

const CustomerCardRegularBase = ({
  userAdditionalData,
  clientPageEventLogger,
  selfRegEventLogger,
  // eslint-disable-next-line camelcase
  search_box_value,
  PACKAGES_TYPES,
  archiveToggle,
  displayUnarchiveUserModal,
  setDisplayUnarchiveUserModal,
  clinicNonfPlan,
  reachedClientsLimit,
  setDisplayRichedLimitModal,
  toggleCompleteSetupModal,
  setToggleCompleteSetupModal,
  isShownCheckbox,
  setIsShownCheckbox,
  userId,
  archiveCheckboxes,
  setArchiveCheckboxes,
  isSample,
  isNameMasking,
  maskText,
  renderEmail,
  renderDate,
  prettifySessionStatus,
  renderNotifications,
  // doneAssessments,
  beforeSelfRegisterDialog,
  setBeforeSelfRegisterDialog,
  timeConverter,
  calcNextAssessment,
  DAY_JS_DATE_FORMATS,
  isSelfRegisterModalOpen,
  setIsSelfRegisterModalOpen,
  renderSelfRegister,
  assessmentStagesInfo,
  setupSelfRegister,
  customerInfo,
  onEditScheduleAssessmentCard,
  onAddAssessment,
  nfFlag,
  canAccessNf,
  setAddClientReachedLimitModal,
  onEditPrograms,
  scheduleAssessmentDialogOpener,
  renderScheduleAssessmentDialog,
  isAddClientModalOpen,
  renderAddClientModal,
  onUnarchiveUserClicked,
  isLoadingAssessmentData,
  setIsLoadingAssessmentData,
  brainDisorders,
  renderAssignedUsers,
  userHasTeamMembers,
  isTeamMembersMenuOpen,
  setIsTeamMembersMenuOpen,
}) => {
  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  if (userAdditionalData.clinicianSetUpRequired) {
    return (
      <div className={styles.self_reg_container}>
        <div
          className={classNames(
            styles.customer_card_container_self_reg,
            archiveCheckboxes[userId] && styles.mobile_view_selected_background
          )}
        >
          <div
            style={{
              width: userHasTeamMembers ? '13%' : '16%',
              cursor: 'pointer',
              display: 'flex',
            }}
            onClick={() => {
              sessionStorage.customerId = userId;
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'client_name',
                team_member_id: sessionStorage.teamMemberId,
                search_box_value,
                customerId: userId,
                client_name: `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
              });
              if (
                sessionStorage.packageType ===
                PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
              ) {
                showNotification(
                  'danger',
                  'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                );
                return;
              }
              if (archiveToggle) {
                setDisplayUnarchiveUserModal(true);
                return;
              }
              if (clinicNonfPlan && reachedClientsLimit) {
                setDisplayRichedLimitModal(true);
                return;
              }
              setToggleCompleteSetupModal(true);
            }}
            onMouseEnter={() => setIsShownCheckbox(true)}
            onMouseLeave={() => setIsShownCheckbox(false)}
          >
            <div
              className={styles.hide_on_max_960}
              style={{ justifyContent: 'center' }}
            >
              <Checkbox
                color="primary"
                style={
                  isShownCheckbox || archiveCheckboxes[userId]
                    ? { visibility: 'visible' }
                    : { visibility: 'hidden' }
                }
                checked={archiveCheckboxes[userId]}
                onClick={e => e.stopPropagation()}
                onChange={e => {
                  const copy = JSON.parse(JSON.stringify(archiveCheckboxes));
                  copy[userId] = e.target.checked;
                  setArchiveCheckboxes(copy);
                  clientPageEventLogger.log('item_clicked', {
                    button_clicked: 'client_check_box',
                    checkbox_value: e.target.checked,
                    checkbox_user_id: userId,
                    team_member_id: sessionStorage.teamMemberId,
                    search_box_value,
                    customerId: userId,
                  });
                }}
              />
            </div>
            <div className={styles.name_mail_container}>
              <div className={styles.name_text_h}>
                {isNameMasking && !isSample
                  ? maskText(
                      `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
                      'name'
                    )
                  : `${userAdditionalData.firstName} ${userAdditionalData.lastName}`}
              </div>
              {renderEmail(userAdditionalData.email)}
            </div>
            {isShownCheckbox ? (
              <LaunchIcon
                style={{
                  width: 15,
                  height: 15,
                  color: '#2196f3',
                  alignSelf: 'center',
                  marginLeft: 5,
                }}
                className={styles.hide_on_max_960}
                onClick={e => {
                  sessionStorage.customerId = userId;
                  e.stopPropagation();
                  clientPageEventLogger.log('item_clicked', {
                    button_clicked: 'client_name',
                    team_member_id: sessionStorage.teamMemberId,
                    search_box_value,
                    customerId: userId,
                  });
                  if (
                    sessionStorage.packageType ===
                    PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
                  ) {
                    showNotification(
                      'danger',
                      'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                    );
                    return;
                  }
                  if (archiveToggle) {
                    setDisplayUnarchiveUserModal(true);
                    return;
                  }
                  setToggleCompleteSetupModal(true);
                }}
              />
            ) : null}
          </div>
          <div
            style={{ width: userHasTeamMembers ? '13%' : '16%' }}
            className={styles.name_text}
          >
            {userAdditionalData.signupTimestamp
              ? renderDate(userAdditionalData.signupTimestamp)
              : ''}
          </div>
          {// eslint-disable-next-line no-nested-ternary
          userAdditionalData.userLastSeen === 'online' &&
          userAdditionalData.statusState &&
          userAdditionalData.statusState === 'offline' ? (
            <div style={{ width: userHasTeamMembers ? '13%' : '16%' }} />
          ) : userAdditionalData.userLastSeen !== 'online' ||
            (userAdditionalData.statusState &&
              userAdditionalData.statusState === 'offline') ? (
            <div
              style={{
                width: userHasTeamMembers ? '13%' : '16%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {renderDate(userAdditionalData.userLastSeen)}
            </div>
          ) : (
            <div
              style={{
                width: userHasTeamMembers ? '13%' : '16%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  backgroundColor: '#4BE0D0',
                  width: 10,
                  height: 10,
                  marginRight: 5,
                  opacity: userAdditionalData.statusState ? 1 : 0,
                }}
              />
              {userAdditionalData.statusState
                ? `Now: ${prettifySessionStatus(
                    userAdditionalData.statusState
                  )}`
                : null}
            </div>
          )}
          <div
            style={{ width: userHasTeamMembers ? '13%' : '16%' }}
            className={styles.name_text}
          >
            {renderNotifications()}
          </div>
          {/* {userAdditionalData.isJourneyEnabled ? (
            <div
              style={{ width: userHasTeamMembers ? '13%' : '16%' }}
              className={styles.journey_container}
              onClick={() => {
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setBeforeSelfRegisterDialog(true);
              }}
            >
              <div>
                {userAdditionalData.journeyName
                  ? userAdditionalData.journeyName
                  : null}
              </div>
              <div>
                {userAdditionalData.precentageDone
                  ? userAdditionalData.precentageDone.toFixed(0)
                  : '0'}
                % journey completion
              </div>
              {userAdditionalData.currentModule ? (
                <div>Current: {userAdditionalData.currentModule}</div>
              ) : null}
            </div>
          ) : (
            <div style={{ width: userHasTeamMembers ? '13%' : '16%' }} />
          )} */}
          {userAdditionalData.numOfDoneAssessments ? (
            <div
              style={{ width: userHasTeamMembers ? '13%' : '16%' }}
              className={styles.assessment_container}
              onClick={() => {
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setBeforeSelfRegisterDialog(true);
              }}
            >
              {userAdditionalData.usersLastAssessmentTime ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  Last:{' '}
                  {timeConverter(userAdditionalData.usersLastAssessmentTime)}
                  <EditIcon style={{ width: 14, height: 14 }} />
                </div>
              ) : null}
              {userAdditionalData.usersLastAssessmentTime ? (
                <div>Next: {calcNextAssessment()}</div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div>Next: {calcNextAssessment()}</div>
                  <EditIcon style={{ width: 14, height: 14 }} />
                </div>
              )}
              {userAdditionalData.numOfDoneAssessments ? (
                <div>{userAdditionalData.numOfDoneAssessments} completed</div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                width: userHasTeamMembers ? '13%' : '16%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setBeforeSelfRegisterDialog(true);
              }}
            >
              <div className={styles.add_btn} style={{ color: '#0076F5' }}>
                ADD +
              </div>
            </div>
          )}
          {userAdditionalData.usersNumOfPrograms ? (
            <div
              style={
                userAdditionalData.isExpired
                  ? {
                      color: '#fa625d',
                      backgroundColor: '#feddcf',
                      width: userHasTeamMembers ? '13%' : '16%',
                    }
                  : { width: userHasTeamMembers ? '13%' : '16%' }
              }
              className={styles.neurofeedback_container}
              onClick={() => {
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setBeforeSelfRegisterDialog(true);
              }}
            >
              {userAdditionalData.isExpired ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  The client lost access to programs on{' '}
                  {dayJS(userAdditionalData.isExpired).format(
                    DAY_JS_DATE_FORMATS.american
                  )}
                  <EditIcon style={{ width: 14, height: 14 }} />
                </div>
              ) : null}
              {userAdditionalData.lastSessionDone ? (
                <div
                  style={
                    userAdditionalData.isExpired
                      ? { display: 'none' }
                      : {
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }
                  }
                >
                  Last: {timeConverter(userAdditionalData.lastSessionDone)}
                  <EditIcon style={{ width: 14, height: 14 }} />
                </div>
              ) : null}
              {userAdditionalData.lastSessionDone ? (
                <div
                  style={
                    userAdditionalData.isExpired ? { display: 'none' } : null
                  }
                >
                  {`${userAdditionalData.usersTotalSessions -
                    userAdditionalData.usersProgramsSessionsLeft} / ${
                    userAdditionalData.usersTotalSessions
                  }`}{' '}
                  sessions done
                </div>
              ) : (
                <div
                  style={
                    userAdditionalData.isExpired
                      ? { display: 'none' }
                      : {
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }
                  }
                >
                  <div>
                    {`${userAdditionalData.usersTotalSessions -
                      userAdditionalData.usersProgramsSessionsLeft} / ${
                      userAdditionalData.usersTotalSessions
                    }`}{' '}
                    sessions done
                  </div>
                  <EditIcon style={{ width: 14, height: 14 }} />
                </div>
              )}
              {userAdditionalData.usersNumOfPrograms > 1 ? (
                <div
                  style={
                    userAdditionalData.isExpired ? { display: 'none' } : null
                  }
                >
                  {`${userAdditionalData.usersNumOfPrograms -
                    userAdditionalData.usersProgramsLeft} / ${
                    userAdditionalData.usersNumOfPrograms
                  }`}{' '}
                  programs completed
                </div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                width: userHasTeamMembers ? '13%' : '16%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setBeforeSelfRegisterDialog(true);
              }}
            >
              <div className={styles.add_btn} style={{ color: '#4BE0D0' }}>
                ADD +
              </div>
            </div>
          )}
          {userHasTeamMembers ? (
            <div
              style={{
                width: userHasTeamMembers ? '13%' : '16%',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
                cursor: 'pointer',
              }}
              className={styles.hide_on_max_860}
              onClick={() => {
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setBeforeSelfRegisterDialog(true);
              }}
            >
              {renderAssignedUsers()}
            </div>
          ) : null}
          {isSelfRegisterModalOpen &&
            !isMatchingXSScreen &&
            renderSelfRegister()}
          <CustomDialog
            isOpen={toggleCompleteSetupModal}
            onPrimaryBtnClick={() => {
              setToggleCompleteSetupModal(false);
              setIsSelfRegisterModalOpen(true);
              selfRegEventLogger.log('setup_now_clicked', {
                patientId: userId,
                from: 'clients',
              });
            }}
            onSecondaryBtnClick={() => {
              setToggleCompleteSetupModal(false);
            }}
            primaryBtnContent={assessmentStagesInfo ? 'SET UP NOW' : ''}
            secondaryBtnContent={assessmentStagesInfo ? 'CANCEL' : ''}
          >
            {assessmentStagesInfo ? (
              <>
                <p className={styles.dialog_header}>
                  This client self-registered using your clinic code!
                </p>
                <p className={styles.dialog_paragraph}>
                  You’ll need to complete client setup to be able to view their
                  data and create programs.
                </p>
              </>
            ) : (
              <div className={styles.progress_container}>
                <CircularProgress />
              </div>
            )}
          </CustomDialog>
        </div>
        <div
          className={classNames(
            styles.self_reg_label,
            archiveCheckboxes[userId] && styles.mobile_view_selected_background
          )}
        >
          This client has self-registered using your clinic code.
          <TooltipWrapper
            style={{ width: 16, height: 16, position: 'absolute' }}
            title={
              <TooltipTitle>
                This client currently has access to the basic version of the
                Myndlift app. To unlock more capabilities, please fill in the
                client’s details and set them up with the needed program.
              </TooltipTitle>
            }
          >
            <HelpIcon />
          </TooltipWrapper>
        </div>
        <Dialog
          open={beforeSelfRegisterDialog}
          onClose={() => {
            setBeforeSelfRegisterDialog(false);
          }}
        >
          {assessmentStagesInfo ? (
            <>
              <DialogTitle style={{ alignSelf: 'center' }}>
                <div style={{ fontSize: 16, fontWeight: 400 }}>
                  This client self registered using your clinic code!
                </div>
              </DialogTitle>
              <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
                <div style={{ fontSize: 13, textAlign: 'center' }}>
                  You'll need to complete client setup to be able to view their
                  data and create programs.
                </div>
              </DialogContent>
              <ModalFooter
                primaryBtnContent={assessmentStagesInfo ? 'set up now' : ''}
                secondaryBtnContent={assessmentStagesInfo ? 'cancel' : ''}
                onPrimaryBtnClick={() => {
                  setBeforeSelfRegisterDialog(false);
                  setupSelfRegister();
                }}
                onSecondaryBtnClick={() => {
                  setBeforeSelfRegisterDialog(false);
                }}
              />
            </>
          ) : (
            <div className={styles.progress_container}>
              <CircularProgress />
            </div>
          )}
        </Dialog>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        styles.customer_card_container,
        archiveCheckboxes[userId] && styles.mobile_view_selected_background
      )}
      style={isSample ? { paddingLeft: 0 } : null}
    >
      <div
        style={{
          height: !isSample ? '100%' : '',
          width: userHasTeamMembers ? '13%' : '16%',
        }}
        className={styles.name_mail_wrapper_regular}
      >
        <div
          style={
            isSample
              ? {
                  // width: userHasTeamMembers ? '13%' : '16%',
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  backgroundColor: '#f0e8ff',
                  // height: '126%',
                  color: '#7d3ff0',
                  alignItems: 'center',
                  borderRadius: '0 50px 50px 0',
                  height: '100%',
                }
              : {
                  // width: userHasTeamMembers ? '13%' : '16%',
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  overflow: 'hidden',
                }
          }
          className={isSample ? styles.responsive_min_width_960 : ''}
          onClick={() => {
            sessionStorage.customerId = userId;
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'client_name',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value,
              customerId: userId,
              client_name: `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
            });
            if (archiveToggle) {
              setDisplayUnarchiveUserModal(true);
              return;
            }
            setTimeout(() => {
              sessionStorage.customerId = userId;
              return window.open(
                `userPage.html?customerId=${sessionStorage.customerId}`,
                '_self'
              );
            }, 300);
          }}
          onMouseEnter={() => setIsShownCheckbox(true)}
          onMouseLeave={() => setIsShownCheckbox(false)}
        >
          <div
            style={{
              // display: 'flex',
              justifyContent: 'center',
              overflow: !isSample ? 'hidden' : '',
            }}
            className={styles.hide_on_max_960}
          >
            <Checkbox
              color="primary"
              style={
                isShownCheckbox || archiveCheckboxes[userId]
                  ? { visibility: 'visible' }
                  : { visibility: 'hidden' }
              }
              checked={archiveCheckboxes[userId]}
              onClick={e => e.stopPropagation()}
              onChange={e => {
                const copy = JSON.parse(JSON.stringify(archiveCheckboxes));
                copy[userId] = e.target.checked;
                setArchiveCheckboxes(copy);
                clientPageEventLogger.log('item_clicked', {
                  button_clicked: 'client_check_box',
                  checkbox_value: e.target.checked,
                  checkbox_user_id: userId,
                  team_member_id: sessionStorage.teamMemberId,
                  search_box_value,
                  customerId: userId,
                });
              }}
            />
          </div>
          <div className={styles.name_mail_container}>
            <div className={styles.name_text_h}>
              {isNameMasking && !isSample
                ? maskText(
                    `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
                    'name'
                  )
                : `${userAdditionalData.firstName} ${userAdditionalData.lastName}`}
            </div>
            {renderEmail(userAdditionalData.email)}
          </div>
          {isSample ? (
            <TooltipWrapper
              style={{
                width: 16,
                height: 16,
                position: 'relative',
                right: -10,
              }}
              title={
                <TooltipTitle>
                  This is an auto-filled client profile designed to help you
                  explore various features and functionalities of the clinical
                  dashboard.
                </TooltipTitle>
              }
            >
              <InfoMUIIcon />
            </TooltipWrapper>
          ) : null}
          {isShownCheckbox ? (
            <LaunchIcon
              style={{
                width: 15,
                height: 15,
                color: '#2196f3',
                alignSelf: 'center',
                marginLeft: isSample ? 15 : 5,
              }}
              className={styles.hide_on_max_960}
              onClick={e => {
                sessionStorage.customerId = userId;
                e.stopPropagation();
                clientPageEventLogger.log('item_clicked', {
                  button_clicked: 'client_name',
                  team_member_id: sessionStorage.teamMemberId,
                  search_box_value,
                  customerId: userId,
                });
                if (archiveToggle) {
                  setDisplayUnarchiveUserModal(true);
                  return;
                }
                sessionStorage.customerId = userId;
                // eslint-disable-next-line consistent-return
                return window.open(
                  `userPage.html?customerId=${sessionStorage.customerId}`,
                  '_blank'
                );
              }}
            />
          ) : null}
        </div>
      </div>
      <div
        style={
          isSample
            ? {
                width: userHasTeamMembers ? '13%' : '16%',
                position: 'relative',
                left: 20,
              }
            : { width: userHasTeamMembers ? '13%' : '16%' }
        }
        className={styles.name_text}
      >
        {userAdditionalData.signupTimestamp
          ? renderDate(userAdditionalData.signupTimestamp)
          : ''}
      </div>
      {// eslint-disable-next-line no-nested-ternary
      userAdditionalData.userLastSeen === 'online' &&
      userAdditionalData.statusState &&
      userAdditionalData.statusState === 'offline' ? (
        <div style={{ width: userHasTeamMembers ? '13%' : '16%' }} />
      ) : userAdditionalData.userLastSeen !== 'online' ||
        (userAdditionalData.statusState &&
          userAdditionalData.statusState === 'offline') ? (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {renderDate(userAdditionalData.userLastSeen)}
        </div>
      ) : (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              borderRadius: '50%',
              backgroundColor: '#4BE0D0',
              width: 10,
              height: 10,
              marginRight: 5,
              opacity: userAdditionalData.statusState ? 1 : 0,
            }}
          />
          {userAdditionalData.statusState
            ? `Now: ${prettifySessionStatus(userAdditionalData.statusState)}`
            : null}
        </div>
      )}
      <div
        style={{ width: userHasTeamMembers ? '13%' : '16%' }}
        className={styles.name_text}
      >
        {renderNotifications()}
      </div>
      {/* {userAdditionalData.isJourneyEnabled ? (
        <div
          style={{ width: userHasTeamMembers ? '13%' : '16%' }}
          className={styles.journey_container}
          onClick={() => {
            sessionStorage.customerId = userId;
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'education',
              assessment_click_type: 'edit',
              team_member_id: sessionStorage.teamMemberId,
              customerId: userId,
            });
            window.open(
              `userPage.html?customerId=${userId}&tab=behaviouralProgress`,
              '_blank'
            );
          }}
        >
          <div>
            {userAdditionalData.journeyName
              ? userAdditionalData.journeyName
              : null}
          </div>
          <div>
            {userAdditionalData.precentageDone
              ? userAdditionalData.precentageDone.toFixed(0)
              : '0'}
            % journey completion
          </div>
          {userAdditionalData.currentModule ? (
            <div>Current: {userAdditionalData.currentModule}</div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            sessionStorage.customerId = userId;
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'education',
              assessment_click_type: 'add',
              team_member_id: sessionStorage.teamMemberId,
              customerId: userId,
            });
            return window.open(
              `userPage.html?customerId=${sessionStorage.customerId}&tab=behaviouralProgress`,
              '_blank'
            );
          }}
        >
          <div className={styles.add_btn} style={{ color: '#7F39FB' }}>
            ADD +
          </div>
        </div>
      )} */}
      {// eslint-disable-next-line no-nested-ternary
      userAdditionalData.assessmentCurrentStage ? (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            cursor: 'auto',
            boxShadow: 'none',
          }}
          className={styles.assessment_container}
        >
          <div>
            There’s an assessment in progress currently in the{' '}
            {userAdditionalData.assessmentCurrentStage.stage === 'cpt'
              ? 'Task'
              : userAdditionalData.assessmentCurrentStage.stage}{' '}
            stage.
          </div>
        </div>
      ) : userAdditionalData.beforeNextSession ||
        userAdditionalData.recurring ||
        userAdditionalData.numOfDoneAssessments ? (
        <div
          style={{ width: userHasTeamMembers ? '13%' : '16%' }}
          className={styles.assessment_container}
          onClick={() => {
            sessionStorage.customerId = userId;
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'assessment',
              assessment_click_type: 'edit',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value,
              customerId: userId,
            });
            if (archiveToggle) {
              setDisplayUnarchiveUserModal(true);
              return;
            }
            if (!customerInfo || !brainDisorders) {
              setIsLoadingAssessmentData(true);
              setTimeout(() => {
                onEditScheduleAssessmentCard();
              }, 3000);
              setTimeout(() => {
                setIsLoadingAssessmentData(false);
              }, 5000);
              return;
            }
            onEditScheduleAssessmentCard();
            //   setIsScheduleAssessmentDialogOpener(true)
            //   setTimeout(() => {
            //     sessionStorage.customerId = userId;
            //     return window.open(
            //       `userPage.html?customerId=${sessionStorage.customerId}&tab=assessments`,
            //       '_self'
            //     );
            //   }, 300);
          }}
        >
          {userAdditionalData.usersLastAssessmentTime ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              Last: {timeConverter(userAdditionalData.usersLastAssessmentTime)}
              <EditIcon style={{ width: 14, height: 14 }} />
            </div>
          ) : null}
          {userAdditionalData.usersLastAssessmentTime ? (
            <div>Next: {calcNextAssessment()}</div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>Next: {calcNextAssessment()}</div>
              <EditIcon style={{ width: 14, height: 14 }} />
            </div>
          )}
          {userAdditionalData.numOfDoneAssessments ? (
            <div>{userAdditionalData.numOfDoneAssessments} completed</div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            sessionStorage.customerId = userId;
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'assessment',
              assessment_click_type: 'add',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value,
              customerId: userId,
            });
            //   setTimeout(() => {
            //     sessionStorage.customerId = userId;
            //     return window.open(
            //       `userPage.html?customerId=${sessionStorage.customerId}&tab=assessments`,
            //       '_self'
            //     );
            //   }, 300);
            // setIsScheduleAssessmentDialogOpener(true)
            if (archiveToggle) {
              setDisplayUnarchiveUserModal(true);
              return;
            }
            if (!customerInfo || !brainDisorders) {
              setIsLoadingAssessmentData(true);
              setTimeout(() => {
                onAddAssessment();
              }, 3000);
              setTimeout(() => {
                setIsLoadingAssessmentData(false);
              }, 5000);
              return;
            }
            onAddAssessment();
          }}
        >
          <div className={styles.add_btn} style={{ color: '#0076F5' }}>
            ADD +
          </div>
        </div>
      )}
      {userAdditionalData.usersNumOfPrograms ? (
        <div
          style={
            userAdditionalData.isExpired
              ? {
                  color: '#fa625d',
                  backgroundColor: '#feddcf',
                  width: userHasTeamMembers ? '13%' : '16%',
                }
              : { width: userHasTeamMembers ? '13%' : '16%' }
          }
          className={styles.neurofeedback_container}
          onClick={() => {
            sessionStorage.customerId = userId;
            if (
              sessionStorage.packageType ===
              PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
            ) {
              showNotification(
                'danger',
                'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
              );
              return;
            }
            if (archiveToggle) {
              setDisplayUnarchiveUserModal(true);
              return;
            }
            if (!nfFlag) {
              if (canAccessNf) {
                new MyEvent('add_neuro_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              } else {
                new MyEvent('contact_sales_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              }
              setAddClientReachedLimitModal(true);
              return;
            }
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'neurofeedback',
              neurofeedback_click_type: 'edit',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value,
              customerId: userId,
            });
            onEditPrograms();
          }}
        >
          {userAdditionalData.isExpired ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              The client lost access to programs on{' '}
              {dayJS(userAdditionalData.isExpired).format(
                DAY_JS_DATE_FORMATS.american
              )}
              <EditIcon style={{ width: 14, height: 14 }} />
            </div>
          ) : null}
          {userAdditionalData.lastSessionDone ? (
            <div
              style={
                userAdditionalData.isExpired
                  ? { display: 'none' }
                  : {
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }
              }
            >
              Last: {timeConverter(userAdditionalData.lastSessionDone)}
              <EditIcon style={{ width: 14, height: 14 }} />
            </div>
          ) : null}
          {userAdditionalData.lastSessionDone ? (
            <div
              style={userAdditionalData.isExpired ? { display: 'none' } : null}
            >
              {`${userAdditionalData.usersTotalSessions -
                userAdditionalData.usersProgramsSessionsLeft} / ${
                userAdditionalData.usersTotalSessions
              }`}{' '}
              sessions done
            </div>
          ) : (
            <div
              style={
                userAdditionalData.isExpired
                  ? { display: 'none' }
                  : {
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }
              }
            >
              <div>
                {`${userAdditionalData.usersTotalSessions -
                  userAdditionalData.usersProgramsSessionsLeft} / ${
                  userAdditionalData.usersTotalSessions
                }`}{' '}
                sessions done
              </div>
              <EditIcon style={{ width: 14, height: 14 }} />
            </div>
          )}
          {userAdditionalData.usersNumOfPrograms > 1 ? (
            <div
              style={userAdditionalData.isExpired ? { display: 'none' } : null}
            >
              {`${userAdditionalData.usersNumOfPrograms -
                userAdditionalData.usersProgramsLeft} / ${
                userAdditionalData.usersNumOfPrograms
              }`}{' '}
              programs completed
            </div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            sessionStorage.customerId = userId;
            if (
              sessionStorage.packageType ===
              PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
            ) {
              showNotification(
                'danger',
                'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
              );
              return;
            }
            if (archiveToggle) {
              setDisplayUnarchiveUserModal(true);
              return;
            }
            if (!nfFlag) {
              if (canAccessNf) {
                new MyEvent('add_neuro_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              } else {
                new MyEvent('contact_sales_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              }
              setAddClientReachedLimitModal(true);
              return;
            }
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'neurofeedback',
              neurofeedback_click_type: 'add',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value,
              customerId: userId,
            });
            onEditPrograms();
          }}
        >
          <div className={styles.add_btn} style={{ color: '#4BE0D0' }}>
            ADD +
          </div>
        </div>
      )}
      {userHasTeamMembers ? (
        <div
          style={{
            width: userHasTeamMembers ? '13%' : '16%',
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            cursor: 'pointer',
          }}
          className={styles.hide_on_max_860}
          onClick={() => {
            if (isTeamMembersMenuOpen) {
              return;
            }
            setIsTeamMembersMenuOpen(userId);
          }}
        >
          {renderAssignedUsers()}
        </div>
      ) : null}
      {// eslint-disable-next-line no-extra-boolean-cast
      !!scheduleAssessmentDialogOpener
        ? renderScheduleAssessmentDialog()
        : null}
      {isAddClientModalOpen && renderAddClientModal()}
      <Dialog
        open={displayUnarchiveUserModal}
        onClose={() => {
          setDisplayUnarchiveUserModal(false);
        }}
      >
        <DialogTitle style={{ alignSelf: 'center' }}>
          <div style={{ fontSize: 16, fontWeight: 400 }}>
            Unarchive this client to continue
          </div>
        </DialogTitle>
        <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
          <div style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}>
            Unarchive this client to see the their page, deploy{' '}
            {nfFlag ? 'programs,' : ''} assessments, give them access to the
            full Myndlift app and more.
          </div>
        </DialogContent>
        <ModalFooter
          primaryBtnContent="unarchive now"
          secondaryBtnContent="cancel"
          onPrimaryBtnClick={onUnarchiveUserClicked}
          onSecondaryBtnClick={() => {
            setDisplayUnarchiveUserModal(false);
          }}
        />
      </Dialog>

      {isLoadingAssessmentData ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 100,
            height: 100,
            position: 'absolute',
            zIndex: 100,

            top: '50vh',
            right: '50vw',
            // border: '1px solid gray'
          }}
        >
          <Backdrop open classes={{ root: styles.backdrop }} />
          <div
            className={styles.progress_container}
            style={{ zIndex: 1000, backgroundColor: 'white' }}
          >
            <CircularProgress />
          </div>
        </div>
      ) : null}
    </div>
  );
};

CustomerCardRegularBase.propTypes = exact({
  userAdditionalData: PropTypes.object,
  clientPageEventLogger: PropTypes.object,
  selfRegEventLogger: PropTypes.object,
  search_box_value: PropTypes.string,
  PACKAGES_TYPES: PropTypes.object,
  archiveToggle: PropTypes.bool,
  displayUnarchiveUserModal: PropTypes.bool,
  setDisplayUnarchiveUserModal: PropTypes.func,
  clinicNonfPlan: PropTypes.object,
  reachedClientsLimit: PropTypes.object,
  setDisplayRichedLimitModal: PropTypes.func,
  toggleCompleteSetupModal: PropTypes.bool,
  setToggleCompleteSetupModal: PropTypes.func,
  isShownCheckbox: PropTypes.bool,
  setIsShownCheckbox: PropTypes.func,
  userId: PropTypes.string,
  archiveCheckboxes: PropTypes.object,
  setArchiveCheckboxes: PropTypes.func,
  isSample: PropTypes.bool,
  isNameMasking: PropTypes.bool,
  maskText: PropTypes.func,
  renderEmail: PropTypes.func,
  renderDate: PropTypes.func,
  prettifySessionStatus: PropTypes.func,
  renderNotifications: PropTypes.func,
  // doneAssessments: PropTypes.oneOfType([PropTypes.symbol, PropTypes.object]),
  beforeSelfRegisterDialog: PropTypes.bool,
  setBeforeSelfRegisterDialog: PropTypes.func,
  timeConverter: PropTypes.func,
  calcNextAssessment: PropTypes.func,
  DAY_JS_DATE_FORMATS: PropTypes.object,
  isSelfRegisterModalOpen: PropTypes.bool,
  setIsSelfRegisterModalOpen: PropTypes.func,
  renderSelfRegister: PropTypes.func,
  assessmentStagesInfo: PropTypes.object,
  setupSelfRegister: PropTypes.func,
  customerInfo: PropTypes.object,
  onEditScheduleAssessmentCard: PropTypes.func,
  onAddAssessment: PropTypes.func,
  nfFlag: PropTypes.bool,
  canAccessNf: PropTypes.bool,
  setAddClientReachedLimitModal: PropTypes.func,
  onEditPrograms: PropTypes.func,
  scheduleAssessmentDialogOpener: PropTypes.bool,
  renderScheduleAssessmentDialog: PropTypes.func,
  isAddClientModalOpen: PropTypes.bool,
  renderAddClientModal: PropTypes.func,
  onUnarchiveUserClicked: PropTypes.func,
  isLoadingAssessmentData: PropTypes.bool,
  setIsLoadingAssessmentData: PropTypes.func,
  brainDisorders: PropTypes.array,
  renderAssignedUsers: PropTypes.func,
  userHasTeamMembers: PropTypes.bool,
  isTeamMembersMenuOpen: PropTypes.bool,
  setIsTeamMembersMenuOpen: PropTypes.func,
});

export const CustomerCardRegular = React.memo(CustomerCardRegularBase);
CustomerCardRegular.displayName = 'CustomerCardRegular';

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import RedoIcon from '@material-ui/icons/Redo';
import CloseIcon from '@material-ui/icons/Close';
import { injectStripe } from 'react-stripe-elements';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import styles from './OutstandingInvoicePopUp.scss';
import { CreditCardDetailsDialogWithValidation } from '../CreditCardDetailsDialog/CreditCardDetailsDialogWithValidation';
import doneAnimationImageSrc from '../../../assets/done-animation.gif';
import failedImageSrc from '../../../assets/failed-circle.png';

const OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS = {
  info: 'info',
  paymentFailed: 'paymentFailed',
  paymentSucceeded: 'paymentSucceeded',
  paymentMightHaveSucceededPartially: 'paymentMightHaveSucceededPartially',
  loadingRetryRecharge: 'loadingRetryRecharge',
};

const OutstandingInvoicePopUpBase = ({
  stripe,
  outstandingInvoicesAmount,
  isOpen,
  onClose,
}) => {
  const clinicId = sessionStorage.userId;
  const isTeamMember = sessionStorage.isTeamMember === 'true';

  const [outstandingPaymentAmount] = useFirebaseDB({
    path: `billing/currentPackages/${clinicId}/outstandingPaymentsAmount`,
    onceListener: true,
  });

  const [
    currentOutstandingPaymentAmount,
    setCurrentOutstandingPaymentAmount,
  ] = useState(null);

  const [last4Digits, setLast4Digits] = useState(null);
  const [didSuccsfullRetryCharge, setDidSuccsfullRetryCharge] = useState(false);

  const [
    outstandingPaymentDialogRenderedStep,
    setOutstandingPaymentDialogRenderedStep,
  ] = useState(null);

  const [
    creditCardDetailsRenderedStep,
    setCreditCardDetailsRenderedStep,
  ] = useState(null);

  const onCreditCardDetailsDialogConfirm = useCallback(() => {
    const handleSuccessfulRegisterPayment = async () => {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded
      );
      setCreditCardDetailsRenderedStep(null);
      new MyEvent('clinic_payment_failed_popup').log({
        type: 'invoice',
        action: 'edit_success',
      });
    };

    const handleFailedRegisterPayment = () => {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
      );
      setCreditCardDetailsRenderedStep(null);
      new MyEvent('clinic_payment_failed_popup').log({
        type: 'invoice',
        action: 'edit_failed',
      });
    };

    const handlePossiblePartialSuccessfulPayment = () => {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentMightHaveSucceededPartially
      );
      setCreditCardDetailsRenderedStep(null);
    };

    setCreditCardDetailsRenderedStep('loading');
    stripe.createToken().then(async result => {
      if (result.error) {
        if (result.error.type === 'validation_error') {
          setCreditCardDetailsRenderedStep('cardDetailsInput');
        } else {
          handleFailedRegisterPayment();
        }
      } else {
        try {
          // eslint-disable-next-line no-undef
          const registerPaymentMethodResponse = await fireFunctionPost(
            'billing-registerPaymentMethod',
            {
              details: result,
            }
          );
          // eslint-disable-next-line no-undef
          const retryOutstandingInvoicesResponse = await fireFunctionPost(
            'billing-retryOutstandingInvoices'
          );

          if (
            registerPaymentMethodResponse.result === true &&
            retryOutstandingInvoicesResponse.result === true
          ) {
            if (retryOutstandingInvoicesResponse.totalDue === 0) {
              handleSuccessfulRegisterPayment();
            } else {
              setCurrentOutstandingPaymentAmount(
                retryOutstandingInvoicesResponse.totalDue
              );
              handlePossiblePartialSuccessfulPayment();
            }
          } else {
            handleFailedRegisterPayment();
          }
        } catch (err) {
          handleFailedRegisterPayment();
        }
      }
    });
  }, [stripe]);

  useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('billing-paymentSourceInfo');
        setLast4Digits(data.cardInfo.last4);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    })();
  }, [last4Digits]);

  useEffect(() => {
    if (outstandingPaymentAmount !== null) {
      setCurrentOutstandingPaymentAmount(outstandingPaymentAmount);
    }
  }, [outstandingPaymentAmount]);

  useEffect(() => {
    if (stripe !== null) {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info
      );
    }
  }, [stripe]);

  useEffect(() => {
    new MyEvent('clinic_payment_failed_popup').log({
      type: 'invoice',
      action: 'seen',
    });
  }, []);

  const onCloseOutstandingPaymentDialog = useCallback(() => {
    onClose();
    // eslint-disable-next-line no-undef, no-restricted-globals
    location.reload();
  }, []);

  const onEditPaymentMethod = useCallback(() => {
    new MyEvent('clinic_payment_failed_popup').log({
      type: 'invoice',
      action: 'edit_clicked',
    });
    setCreditCardDetailsRenderedStep('cardDetailsInput');
  }, []);

  const onRetryCharge = async () => {
    new MyEvent('clinic_payment_failed_popup').log({
      type: 'invoice',
      action: 'retry_clicked',
    });
    setOutstandingPaymentDialogRenderedStep(
      OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.loadingRetryRecharge
    );
    try {
      // eslint-disable-next-line no-undef
      const data = await fireFunctionPost('billing-retryCharge');
      if (data.result === true) {
        setDidSuccsfullRetryCharge(true);
        setOutstandingPaymentDialogRenderedStep(
          OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded
        );
        new MyEvent('clinic_payment_failed_popup').log({
          type: 'invoice',
          action: 'retry_success',
        });
      } else {
        setOutstandingPaymentDialogRenderedStep(
          OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
        );
        new MyEvent('clinic_payment_failed_popup').log({
          type: 'invoice',
          action: 'retry_failed',
        });
      }
    } catch (e) {
      setOutstandingPaymentDialogRenderedStep(
        OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
      );
      new MyEvent('clinic_payment_failed_popup').log({
        type: 'invoice',
        action: 'retry_failed',
      });
    }
  };

  const renderOutstandingPaymentDialogContent = () => {
    switch (outstandingPaymentDialogRenderedStep) {
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info:
        return (
          <div className={styles.content}>
            <h3>Invoice payment unsuccessful</h3>
            {isTeamMember ? (
              <p>
                We have restricted the access to Myndlift due to billing errors.
                Please contact your account administrator to update this
                account's payment method.
              </p>
            ) : (
              <React.Fragment>
                <p>
                  You have an outstanding invoice in the amount of $
                  {outstandingInvoicesAmount} that we’ve tried to charge your
                  card (ending with {last4Digits}) with but failed. We will
                  retry the charge again later.
                </p>
                <p>
                  To avoid losing access to Myndlift, please retry charging your
                  existing card or provide an alternative payment method.
                </p>
              </React.Fragment>
            )}
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded:
        return (
          <div className={styles.info_container}>
            <Avatar
              alt="done"
              src={doneAnimationImageSrc}
              className={styles.avatar}
            />

            <p className={styles.info_text}>
              {didSuccsfullRetryCharge
                ? `Credit card ending with ${last4Digits} was charged and the payment was processed successfully.`
                : 'Credit card was changed and payment was processed successfully'}
            </p>
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed:
        return (
          <div className={styles.info_container}>
            <Avatar alt="done" src={failedImageSrc} className={styles.avatar} />

            <p className={styles.info_text}>Payment failed!</p>
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentMightHaveSucceededPartially:
        return (
          <div className={styles.info_container}>
            <Avatar alt="done" src={failedImageSrc} className={styles.avatar} />

            <p className={styles.info_text}>
              Could not close all outstanding invoices, amount due is: US$
              {currentOutstandingPaymentAmount}.
            </p>
          </div>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.loadingRetryRecharge:
        return (
          <div className={styles.info_container}>
            <CircularProgress />
          </div>
        );
      default:
        return null;
    }
  };

  const renderOutstandingPaymentDialogActions = () => {
    switch (outstandingPaymentDialogRenderedStep) {
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info:
        return (
          !isTeamMember && (
            <>
              <Button
                color="primary"
                autoFocus
                variant="contained"
                onClick={onRetryCharge}
                style={{ marginRight: 10, backgroundColor: '#2196f3' }}
              >
                <span className={styles.btn}>
                  <RedoIcon
                    style={{
                      fontSize: 18,
                      position: 'relative',
                      top: 3,
                      right: 5,
                    }}
                  />
                  Retry charge
                </span>
              </Button>
              <Button
                color="primary"
                autoFocus
                variant="contained"
                onClick={onEditPaymentMethod}
                style={{ backgroundColor: '#2196f3' }}
              >
                <span className={styles.btn}>Edit payment method</span>
              </Button>
            </>
          )
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentSucceeded:
        return (
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={onCloseOutstandingPaymentDialog}
            style={{ backgroundColor: '#2196f3' }}
          >
            <span className={styles.btn}>done</span>
          </Button>
        );
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentMightHaveSucceededPartially:
      case OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed:
        return (
          <Button
            color="primary"
            autoFocus
            variant="contained"
            onClick={onEditPaymentMethod}
            style={{ backgroundColor: '#2196f3' }}
          >
            <span className={styles.btn}>Edit payment method</span>
          </Button>
        );
      default:
        return null;
    }
  };

  if (sessionStorage.isLogged === 'true') {
    return (
      <React.Fragment>
        <Dialog open={isOpen}>
          {outstandingPaymentDialogRenderedStep ===
          OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.loadingRetryRecharge ? null : (
            <div style={{ display: 'flex', justifyContent: 'end', margin: 5 }}>
              <CloseIcon
                onClick={() => {
                  if (
                    outstandingPaymentDialogRenderedStep ===
                    OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.paymentFailed
                  ) {
                    setOutstandingPaymentDialogRenderedStep(
                      OUTSTANDING_PAYMENT_DIALOG_RENDERED_STEPS.info
                    );
                  }
                  onClose();
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          <DialogContent>
            {renderOutstandingPaymentDialogContent()}
          </DialogContent>
          <DialogActions>
            {renderOutstandingPaymentDialogActions()}
          </DialogActions>
        </Dialog>

        <CreditCardDetailsDialogWithValidation
          isOpen={!!creditCardDetailsRenderedStep}
          renderedStep={creditCardDetailsRenderedStep}
          onConfirm={onCreditCardDetailsDialogConfirm}
          dialogHeader="Update Your Credit Card"
          isSecondaryBtnShown={false}
          isCloseIconShown
          onClose={() => setCreditCardDetailsRenderedStep(null)}
        />
      </React.Fragment>
    );
  }

  return null;
};

OutstandingInvoicePopUpBase.propTypes = exact({
  stripe: PropTypes.object,
  outstandingInvoicesAmount: PropTypes.number,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
});

export const OutstandingInvoicePopUp = React.memo(
  injectStripe(OutstandingInvoicePopUpBase)
);
OutstandingInvoicePopUp.displayName = 'OutstandingInvoicePopUp';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import get from 'lodash/get';
import WarningIcon from '@material-ui/icons/Warning';
import HelpIcon from '@material-ui/icons/Help';
import { Paper } from '@material-ui/core';

import styles from './SwingleCheck.scss';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { RangeGraph } from './RangeGraph/RangeGraph';
import { QuestionnaireScore } from '../../QuestionnaireResults/QuestionnaireScore/QuestionnaireScore';
import { ASSESSMENT_COLORS } from '../../../models/assessments/assessments';
import increasingArrowPNG from '../../../../assets/increasing-arrow.png';
import decreasingArrowPNG from '../../../../assets/decreasing-arrow.png';
import {
  getSwingleResultColor,
  getIsSwingleCheckPassing,
} from '../../../models/swingle-checks/swingle-checks';
import { setFixedDecimalsIfNeeded } from '../../../utils/utils';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';

const SwingleCheckBase = ({
  check,
  result,
  postResult,
  classes,
  swingleNoise,
  postSwingleNoise,
}) => {
  const [isSwingleExpanded, setIsSwingleExpanded] = useState(true);

  if (!Object.keys(check).length) return null;

  const checkStatesLower = check.states.toLowerCase();
  const checkStates =
    checkStatesLower.charAt(0).toUpperCase() + checkStatesLower.slice(1);

  const MAX_ALOUD_NOISE = 0.75;

  const unitText =
    (check.unit === 'percentage' && '%') || (check.unit === 'Hz' && 'Hz') || '';

  const differenceBetweenPostResultAndResult = postResult - result;

  const formattedDifferenceBetweenPostResultAndResulte = setFixedDecimalsIfNeeded(
    {
      number: differenceBetweenPostResultAndResult,
    }
  );
  const differenceBetweenPostResultAndResultText =
    differenceBetweenPostResultAndResult > 0
      ? `+${formattedDifferenceBetweenPostResultAndResulte}${unitText}`
      : `${formattedDifferenceBetweenPostResultAndResulte}${unitText}`;

  const isResultPassing = getIsSwingleCheckPassing({ check, result });
  const isPostResultPassing =
    postResult !== undefined &&
    getIsSwingleCheckPassing({
      check,
      result: postResult,
    });

  const differenceBetweenPostResultAndResultIcon = (() => {
    if (isResultPassing === isPostResultPassing) {
      return null;
    }
    const style = { marginLeft: 1 };
    return isPostResultPassing ? (
      <img src={increasingArrowPNG} style={style} />
    ) : (
      <img src={decreasingArrowPNG} style={style} />
    );
  })();

  const differenceBetweenPostResultAndResultTextColor = (() => {
    if (isResultPassing === isPostResultPassing) {
      return ASSESSMENT_COLORS.NEUTRAL;
    }
    return isPostResultPassing
      ? ASSESSMENT_COLORS.POSITIVE
      : ASSESSMENT_COLORS.NEGATIVE;
  })();

  const isCheckNoisy =
    (swingleNoise && swingleNoise > MAX_ALOUD_NOISE) ||
    (postSwingleNoise && postSwingleNoise > MAX_ALOUD_NOISE);

  return (
    <div key={check.id} className={styles.check}>
      <Paper
        expanded={isSwingleExpanded}
        onChange={() => setIsSwingleExpanded(!isSwingleExpanded)}
        style={{ position: 'relative' }}
      >
        <div
          // expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          // id="panel1a-header"
          // classes={{ expandIcon: styles.check_expand_icon }}
          // className="expandable-in-report"
          // style={{ display: 'block' }}
          style={{ padding: 20 }}
        >
          <div
            className={
              classes.checkContainer
                ? classes.checkContainer
                : styles.check_container
            }
          >
            <div className={styles.check_info}>
              <p>
                {check.name.en}
                <span>
                  <TooltipWrapper
                    title={<TooltipTitle>{check.formulaText}</TooltipTitle>}
                  >
                    <HelpIcon
                      onClick={event => {
                        // We dont want the expandable menu to be opened when clicking on this icon, so hover can be viewed easily in mobile
                        event.stopPropagation();
                      }}
                      className="hide-on-print"
                    />
                  </TooltipWrapper>
                </span>
              </p>
              <p style={{ fontWeight: 300, fontSize: 12 }}>
                {check.location} - {checkStates}
              </p>
            </div>
            {typeof postResult === 'undefined' && (
              <div
                style={{
                  color: getSwingleResultColor({ check, result }),
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    margin: 0,
                  }}
                >
                  {result}
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  >
                    {unitText}
                  </span>
                </p>
              </div>
            )}
            {typeof postResult !== 'undefined' && (
              <div className={styles.result_change_percentage_container}>
                <div>
                  <p>Pre</p>
                  <p
                    style={{ color: getSwingleResultColor({ check, result }) }}
                  >
                    {result}
                    {unitText}
                  </p>
                </div>
                <div className={styles.vertical_border} />
                <div>
                  <p>Post</p>
                  <p
                    style={{
                      color: getSwingleResultColor({
                        check,
                        result: postResult,
                      }),
                    }}
                  >
                    {postResult}
                    {unitText}
                  </p>
                  {differenceBetweenPostResultAndResult !== 0 &&
                    !Number.isNaN(differenceBetweenPostResultAndResult) && (
                      <span>
                        ({differenceBetweenPostResultAndResultIcon}
                        <span
                          style={{
                            color: differenceBetweenPostResultAndResultTextColor,
                            marginRight: 3,
                          }}
                        >
                          {differenceBetweenPostResultAndResultText}
                        </span>
                        )
                      </span>
                    )}
                </div>
              </div>
            )}
            <div
              className={
                classes.checkGraph ? classes.checkGraph : styles.check_graph
              }
              style={{ position: 'relative' }}
            >
              <div>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                  {check.upperCutoff2 ||
                  check.upperEqualCutoff2 ||
                  check.lowerCutoff2 ||
                  check.lowerEqualCutoff2 ? (
                    <QuestionnaireScore
                      score={result}
                      postScore={postResult}
                      questionnaire={{
                        ...check,
                        name: check.name.en,
                        minScore: check.checkRange[0].start,
                      }}
                      questionnaireMaxScore={check.checkRange[0].end}
                      unitText={unitText}
                      swingle
                    />
                  ) : (
                    <RangeGraph
                      data={{
                        min: check.lowerCutoff,
                        max: check.upperCutoff,
                        midMin: check.middleLowerCutoff,
                        midMax: check.middleUpperCutoff,
                        value: result,
                        postValue: postResult,
                      }}
                      checkRange={check.checkRange[0]}
                      unit={unitText}
                    />
                  )}
                </div>
              </div>
              {isCheckNoisy ? (
                <div>
                  <div
                    style={{
                      // color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <WarningIcon />
                    Noisy EEG signal detected and filtered out.
                  </div>
                </div>
              ) : null}
            </div>
            {/* {((swingleNoise && swingleNoise > MAX_ALOUD_NOISE) ||
              (postSwingleNoise && postSwingleNoise > MAX_ALOUD_NOISE)) && (
              <div className={styles.alert_container_summary}>
                <CustomAlert severity="warning">
                  Noisy EEG signal detected and filtered out. This might impact
                  the result.
                </CustomAlert>
              </div>
            )} */}
          </div>
        </div>
        <div style={{ padding: 20, paddingTop: 0 }}>
          <div style={{ width: '100%' }}>
            <div>
              <div style={{ fontWeight: 900 }}>
                {get(check, 'text.header.en', '')}
              </div>
              <span>{get(check, 'text.description.en', '')}</span>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

SwingleCheckBase.defaultProps = {
  classes: {},
};

SwingleCheckBase.propTypes = exact({
  check: PropTypes.object.isRequired,
  result: PropTypes.number.isRequired,
  postResult: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.string),
  swingleNoise: PropTypes.number,
  postSwingleNoise: PropTypes.number,
});

export const SwingleCheck = React.memo(SwingleCheckBase);
SwingleCheck.displayName = 'SwingleCheck';

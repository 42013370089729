import React from 'react';
import { EntryComponent } from '../../../Components/EntryComponent';

export const withEntryComponent = Component => {
  const WithEntryComponentHOC = props => (
    <EntryComponent>
      <Component {...props} />
    </EntryComponent>
  );
  return WithEntryComponentHOC;
};

/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
import styles from './CustomerStoriesPagesStyles.scss';

export const overAllAlphaPeakStorySequences = alpha_peak_improvement_percentage => {
  // const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  const backgroundAnimationDelay = 2200;
  const backgroundAnimationDuration = 1220;

  // const seq1WordsLength = 'Your self-reported symptoms improved by up to'.split(
  //   ' '
  // ).length;

  const seq1WordsLength = 'Overall, your Alpha Peak improved by'.split(' ')
    .length;

  const seq2WordsLength = `${alpha_peak_improvement_percentage}%`.split('')
    .length;

  const seq1Variants = {
    initial: { opacity: 0 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: (2 * textAnimationDuration) / 1000,
        ease: 'easeInOut',
      },
    },
  };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  // const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  // const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  // const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  // const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  // const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  const seq1 = {
    id: 'seq1',
    // words: seq1Words,
    sentence: 'Overall, your Alpha Peak improved by',
    wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay:
      backgroundAnimationDelay +
      backgroundAnimationDuration -
      9 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.overAllAlphaPeak_seq_1,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      },
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2,
        },
      },
      exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq2 = {
    id: 'seq2',
    enterDelay:
      seq1EnterTime +
      backgroundAnimationDelay +
      backgroundAnimationDuration -
      10 * textAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq2-word${1}`,
        text: `${alpha_peak_improvement_percentage}%`,
        enterDelay:
          seq1EnterTime +
          backgroundAnimationDelay +
          backgroundAnimationDuration -
          10 * textAnimationDuration,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            y: 20,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
            },
          },
          // exit: {},
        },
        // style: { display: 'flex' },
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.overAllAlphaPeak_seq_2,
    variants: {
      initial: { opacity: 0, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  return [seq1, seq2];
};

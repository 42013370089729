exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoTags_add_tag_container{margin-top:10px;display:flex;flex-direction:column}.LagoTags_tag_item{cursor:pointer;border:solid 1px black;border-radius:10px;margin-top:10px;padding:10px;width:250px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.LagoTags_tag_item_placeholder{font-weight:600;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.LagoTags_tag_item_name{font-weight:900;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.LagoTags_new_tag_title{font-size:2rem;font-weight:600}.LagoTags_dialog_tag_container{display:flex;justify-content:space-around;min-height:450px}\n", ""]);

// Exports
exports.locals = {
	"add_tag_container": "LagoTags_add_tag_container",
	"tag_item": "LagoTags_tag_item",
	"tag_item_placeholder": "LagoTags_tag_item_placeholder",
	"tag_item_name": "LagoTags_tag_item_name",
	"new_tag_title": "LagoTags_new_tag_title",
	"dialog_tag_container": "LagoTags_dialog_tag_container"
};
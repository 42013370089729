import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Switch } from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import CallSlashGrayPNG from '../../../assets/vuesax-linear-call-slash-2.png';
import CallSlashBluePNG from '../../../assets/vuesax-linear-call-slash-1.png';
import CallRecivedBluePNG from '../../../assets/vuesax-linear-call-received-1.png';
import CallRecivedGrayPNG from '../../../assets/vuesax-linear-call-received.png';
import LinearCallBluePNG from '../../../assets/vuesax-linear-call-1.png';
import LinearCallGrayPNG from '../../../assets/vuesax-linear-call.png';
import LinearCakeGrayPNG from '../../../assets/vuesax-linear-cake-1.png';
import LinearCakeBluePNG from '../../../assets/vuesax-linear-cake.png';
import LinearUserCircleAddBluePNG from '../../../assets/vuesax-linear-user-cirlce-add-1.png';
import LinearUserCircleAddGrayPNG from '../../../assets/vuesax-linear-user-cirlce-add.png';
import LinearProfileTickBluePNG from '../../../assets/vuesax-linear-profile-tick-1.png';
import LinearProfileTickGrayPNG from '../../../assets/vuesax-linear-profile-tick.png';
import LinearProfileDeleteBluePNG from '../../../assets/vuesax-linear-profile-delete-1.png';
import LinearProfileDeleteGrayPNG from '../../../assets/vuesax-linear-profile-delete.png';

import styles from './Automations.scss';

const AutomationsLogger = new MyEventWrapper('human_centric');

const iconsMap = {
  CallSlash: { disabled: CallSlashGrayPNG, enabled: CallSlashBluePNG },
  CallRecived: { disabled: CallRecivedGrayPNG, enabled: CallRecivedBluePNG },
  LinearCall: { disabled: LinearCallGrayPNG, enabled: LinearCallBluePNG },
  LinearCake: { disabled: LinearCakeGrayPNG, enabled: LinearCakeBluePNG },
  LinearUserCircleAdd: {
    disabled: LinearUserCircleAddGrayPNG,
    enabled: LinearUserCircleAddBluePNG,
  },
  LinearProfileTick: {
    disabled: LinearProfileTickGrayPNG,
    enabled: LinearProfileTickBluePNG,
  },
  LinearProfileDelete: {
    disabled: LinearProfileDeleteGrayPNG,
    enabled: LinearProfileDeleteBluePNG,
  },
};

const TriggerItemBase = ({
  setSelectedTriggerIndex,
  setSelectedTriggerId,
  triggerId,
  index,
  selectedTriggerIndex,
  defaultTriggers,
  onToggleTrigger,
  clinicTriggers,
  setEdittedDynamicTriggerID,
  isEdittngTrigger,
  saveNewClinicTrigger,
  cancelEditingTrigger,
  isEdittedTriggerEnabled,
  triggerVariantId,
  setSelectedTriggerVariantId,
  onDeleteVariant,
  selectedTriggerVariantId,
  isEditingMode,
  setChangesLostPopupOpen,
  setSelectedTriggerIndexToChangeTo,
  setSelectedTriggerIdToChangeTo,
  setSelectedTriggerVariantIdToChangeTo,
  messageBeforeEdit,
  editedMessage,
  setIsEditingMode,
}) => {
  const replacePlaceholdersWithInput = str => {
    const regex = /\{[^}]+\}/g;
    const result = str.replace(
      regex,
      `
            <input 
                id="editted_trigger_input"
                type="number" 
                min=${defaultTriggers[triggerId].dynamicVariableDetails.min} 
                max=${defaultTriggers[triggerId].dynamicVariableDetails.max}
                style="
                    border: none;
                    border-bottom: 1px red solid;
                    width: 50px;
                    margin-right: 5px;
                    text-align: center;
                "
                value=${defaultTriggers[triggerId].dynamicVariableDetails.defaultValue}
                oninput="
                    if (this.value !== '') {
                        if (+this.value < +this.min) this.value = +this.min;
                        if (+this.value > +this.max) this.value = +this.max;
                    }
                "
            />
        `
    );
    return result;
  };

  const replaceValueInString = str => {
    const regex = /\{[^}]+\}/g;
    const result = str.replace(
      regex,
      `${clinicTriggers.customizedTriggersProperties[triggerId].variants[triggerVariantId].value}`
    );
    return result;
  };

  // eslint-disable-next-line  no-nested-ternary
  const title = isEdittngTrigger
    ? `<p style="margin-bottom: 0;">${replacePlaceholdersWithInput(
        defaultTriggers[triggerId].title
        //   .split('{')
        //   .join('')
        //   .split('}')
        //   .join('')
      )}</p>`
    : triggerVariantId
    ? `<p style="margin-bottom: 0;">${replaceValueInString(
        defaultTriggers[triggerId].title
      )
        .split('{')
        .join('')
        .split('}')
        .join('')}</p>`
    : `<p style="margin-bottom: 0;">${defaultTriggers[triggerId].title
        .split('{')
        .join('')
        .split('}')
        .join('')}</p>`;

  const variantsForCurrentTrigger =
    clinicTriggers?.customizedTriggersProperties?.[triggerId]?.variants;
  const numberOfVariantsForCurrentTrigger = Object.keys(
    variantsForCurrentTrigger || {}
  ).length;

  return (
    <div
      className={styles.trigger_item}
      onClick={() => {
        if (
          isEditingMode &&
          (selectedTriggerIndex !== index ||
            triggerVariantId !== selectedTriggerVariantId) &&
          messageBeforeEdit !== editedMessage
        ) {
          setChangesLostPopupOpen(true);
          setSelectedTriggerIndexToChangeTo(index);
          setSelectedTriggerIdToChangeTo(triggerId);
          if (triggerVariantId) {
            setSelectedTriggerVariantIdToChangeTo(triggerVariantId);
          } else {
            setSelectedTriggerVariantIdToChangeTo(null);
          }
          return;
        }
        setIsEditingMode(false);
        setSelectedTriggerIndex(index);
        setSelectedTriggerId(triggerId);
        if (triggerVariantId) {
          setSelectedTriggerVariantId(triggerVariantId);
        } else {
          setSelectedTriggerVariantId(null);
        }
      }}
      key={triggerId}
      style={
        // eslint-disable-next-line  no-nested-ternary
        selectedTriggerVariantId &&
        triggerVariantId &&
        selectedTriggerVariantId === triggerVariantId
          ? {
              border: 'solid 2px #65acfa',
            }
          : index === selectedTriggerIndex &&
            !triggerVariantId &&
            !selectedTriggerVariantId
          ? {
              border: 'solid 2px #65acfa',
            }
          : null
      }
    >
      <div className={styles.trigger_item_title_icon}>
        <img
          src={
            // eslint-disable-next-line  no-nested-ternary
            selectedTriggerVariantId &&
            triggerVariantId &&
            selectedTriggerVariantId === triggerVariantId
              ? iconsMap[defaultTriggers[triggerId].icon].enabled
              : index === selectedTriggerIndex &&
                !triggerVariantId &&
                !selectedTriggerVariantId
              ? iconsMap[defaultTriggers[triggerId].icon].enabled
              : iconsMap[defaultTriggers[triggerId].icon].disabled
          }
          className={styles.trigger_item_icon}
        />
        <div dangerouslySetInnerHTML={{ __html: title }} />
        {isEdittngTrigger ? (
          <>
            <CheckIcon
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                const inputVal = document.getElementById(
                  'editted_trigger_input'
                ).value;
                if (!inputVal) {
                  return;
                }
                AutomationsLogger.log('dynamic_trigger_action', {
                  action: 'set_value',
                  triggerTitle: defaultTriggers[triggerId].title,
                  value: inputVal,
                  triggerId,
                  user_id: sessionStorage.userId,
                });
                saveNewClinicTrigger(inputVal);
              }}
            />
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={cancelEditingTrigger}
            />
          </>
        ) : null}
      </div>
      <div className={styles.trigger_actions_container}>
        {// eslint-disable-next-line  no-nested-ternary
        defaultTriggers[triggerId].dynamicVariableDetails &&
        !isEdittngTrigger &&
        !triggerVariantId ? (
          <AddToPhotosIcon
            style={
              numberOfVariantsForCurrentTrigger >=
              defaultTriggers[triggerId].dynamicVariableDetails.maxVariants
                ? { cursor: 'auto', fontSize: 16 }
                : { cursor: 'pointer', fontSize: 16 }
            }
            onClick={() => {
              if (isEditingMode) {
                return;
              }
              if (
                numberOfVariantsForCurrentTrigger >=
                defaultTriggers[triggerId].dynamicVariableDetails.maxVariants
              ) {
                showNotification(
                  'danger',
                  'You reached maximum number of variants for this trigger'
                );
                return;
              }
              AutomationsLogger.log('dynamic_trigger_action', {
                action: 'copy',
                triggerTitle: defaultTriggers[triggerId].title,
                triggerId,
                user_id: sessionStorage.userId,
              });
              setEdittedDynamicTriggerID(triggerId);
            }}
          />
        ) : null}
        {triggerVariantId ? (
          <DeleteIcon
            style={{ cursor: 'pointer', fontSize: 16 }}
            onClick={() => {
              if (isEditingMode) {
                return;
              }
              onDeleteVariant(triggerId, triggerVariantId);
              AutomationsLogger.log('dynamic_trigger_action', {
                action: 'delete',
                triggerTitle: defaultTriggers[triggerId].title,
                triggerId,
                user_id: sessionStorage.userId,
              });
            }}
          />
        ) : null}
        <Switch
          color="primary"
          onChange={e => {
            if (isEditingMode) {
              return;
            }
            onToggleTrigger(triggerId, e.target.checked, triggerVariantId);
            AutomationsLogger.log('auto_message_action', {
              action: e.target.checked ? 'enabled' : 'disabled',
              triggerTitle: defaultTriggers[triggerId].title,
              triggerId,
            });
          }}
          checked={
            // eslint-disable-next-line  no-nested-ternary
            isEdittngTrigger
              ? isEdittedTriggerEnabled
              : triggerVariantId
              ? !!clinicTriggers?.customizedTriggersProperties?.[triggerId]
                  ?.variants?.[triggerVariantId]?.isEnable
              : !!clinicTriggers?.customizedTriggersProperties?.[triggerId]
                  ?.isEnable
          }
        />
      </div>
    </div>
  );
};

TriggerItemBase.propTypes = exact({
  setSelectedTriggerIndex: PropTypes.func.isRequired,
  setSelectedTriggerId: PropTypes.func.isRequired,
  triggerId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedTriggerIndex: PropTypes.number.isRequired,
  defaultTriggers: PropTypes.object.isRequired,
  onToggleTrigger: PropTypes.func.isRequired,
  clinicTriggers: PropTypes.object.isRequired,
  setEdittedDynamicTriggerID: PropTypes.func.isRequired,
  isEdittngTrigger: PropTypes.bool,
  saveNewClinicTrigger: PropTypes.func,
  cancelEditingTrigger: PropTypes.func,
  isEdittedTriggerEnabled: PropTypes.bool,
  triggerVariantId: PropTypes.string,
  setSelectedTriggerVariantId: PropTypes.func,
  onDeleteVariant: PropTypes.func,
  selectedTriggerVariantId: PropTypes.string,
  isEditingMode: PropTypes.bool,
  setChangesLostPopupOpen: PropTypes.func,
  setSelectedTriggerIndexToChangeTo: PropTypes.func,
  setSelectedTriggerIdToChangeTo: PropTypes.func,
  setSelectedTriggerVariantIdToChangeTo: PropTypes.func,
  messageBeforeEdit: PropTypes.string,
  editedMessage: PropTypes.string,
  setIsEditingMode: PropTypes.func,
});

export const TriggerItem = React.memo(TriggerItemBase);
TriggerItem.displayName = 'TriggerItem';

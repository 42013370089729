import React from 'react';
import { SessionComparisonGraph } from './SessionComparisonGraph';

export const PercentageSessionComparisonGraph = props => {
  const yAxisTicks = [20, 40, 60, 80, 100];

  const colors = ['#FF9A9A', '#6fa0f0', '#21e5d7'];

  const unitTemplate = '%';

  return (
    <SessionComparisonGraph
      yAxisTicks={yAxisTicks}
      colors={colors}
      unitTemplate={unitTemplate}
      {...props}
    />
  );
};

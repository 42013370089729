import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CustomTable } from './CustomTable';

const EasierCustomTableBase = ({
  tableContainerClass,
  rows,
  rowsKeys,
  colsKeys,
  rowsPerPageOptions,
  shouldShowRowsPerPageOptions,
  onRowClick,
}) => {
  const tableHeads = rows[0].map((node, index) => ({
    id: colsKeys[index],
    node,
  }));

  const tableRows = rows.slice(1).map((cellNodes, index) => ({
    id: rowsKeys[index],
    cells: cellNodes.map((node, cellNodeIndex) => ({
      id: colsKeys[cellNodeIndex],
      node,
    })),
    onClick: () => onRowClick(rowsKeys[index]),
  }));

  return (
    <CustomTable
      tableHeads={tableHeads}
      tableRows={tableRows}
      rowsPerPageOptions={rowsPerPageOptions}
      shouldShowRowsPerPageOptions={shouldShowRowsPerPageOptions}
      tableContainerClass={tableContainerClass}
    />
  );
};

EasierCustomTableBase.defaultProps = {
  onRowClick: () => {},
};

EasierCustomTableBase.propTypes = exact({
  tableContainerClass: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  rowsKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  colsKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  shouldShowRowsPerPageOptions: PropTypes.bool,
  onRowClick: PropTypes.func,
});

export const EasierCustomTable = React.memo(EasierCustomTableBase);
EasierCustomTable.displayName = 'EasierCustomTable';

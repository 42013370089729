import { useState, useEffect } from 'react';

export const useImagesLoadingInfo = ref => {
  const [imagesLoadingInfo, setImagesLoadingInfo] = useState({
    numberOfImages: -1,
    areAllImagesLoaded: false,
  });

  useEffect(() => {
    if (ref.current) {
      const images = [...ref.current.getElementsByTagName('img')];
      const numberOfImages = images.length;
      let numberOfLoadedImages = 0;

      const setImagesLoadingInfoIfNeeded = () => {
        if (numberOfImages === numberOfLoadedImages) {
          setImagesLoadingInfo({
            numberOfImages,
            areAllImagesLoaded: true,
          });
        }
      };

      images.forEach(img => {
        if (img.complete) {
          numberOfLoadedImages += 1;
        } else {
          const onImageLoad = () => {
            numberOfLoadedImages += 1;
            setImagesLoadingInfoIfNeeded();
          };
          img.addEventListener('load', onImageLoad, true);
        }
      });

      setImagesLoadingInfoIfNeeded();
    }
  }, [ref]);

  return imagesLoadingInfo;
};

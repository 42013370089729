import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './DiscardReportDialog.scss';

export const DiscardReportDialog = ({ isOpen, onDiscard, onCancel }) => {
  const t = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span className={styles.dialog_header}>
          Do you want to discard the whole report?
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span className={styles.dialog_text}>
            This will remove all the graphs that you added. Are you sure you
            want to do that?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text" color="default">
          <span className={styles.button}>{t('cancel')}</span>
        </Button>
        <Button onClick={onDiscard} color="primary" variant="contained">
          <span className={styles.button}>{t('discard')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DiscardReportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from '@material-ui/core';
import upgradeMyndliftPNG from '../../../assets/upgrade-myndlift.png';
import styles from './FeatureNotAllowedDialog.scss';

const FeatureNotAllowedDialogBase = ({ text, isOpen, onDismiss }) => {
  const onSeePlans = useCallback(
    () => window.open(`${window.location.origin}/new_account.html`),
    []
  );

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <div className={styles.content}>
          <p className={styles.header}>This is a premium feature</p>
          <img src={upgradeMyndliftPNG} />
          <p>{text}</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="default" variant="text" autoFocus>
          <span className={styles.btn}>dismiss</span>
        </Button>
        <Button
          onClick={onSeePlans}
          color="primary"
          variant="contained"
          autoFocus
          style={{ backgroundColor: '#2196f3' }}
        >
          <span className={styles.btn}>see plans</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FeatureNotAllowedDialogBase.propTypes = exact({
  text: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
});

export const FeatureNotAllowedDialog = React.memo(FeatureNotAllowedDialogBase);

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LiveCounterPage_root{background-color:#232430;height:100vh;display:flex;justify-content:space-around;align-items:center;position:relative;overflow:hidden;flex-direction:column}.LiveCounterPage_counter_title{display:flex;align-items:flex-start;justify-content:center;color:#d5defa;font-size:6vw;width:90%;padding:0;margin:auto;position:absolute;padding-top:20vw}.LiveCounterPage_counter_container{display:flex;justify-content:flex-start;align-items:center;width:90%;margin:auto;color:#d5defa;height:60%;flex-direction:column}.LiveCounterPage_digit_container{display:flex;flex-direction:column;justify-content:center}.LiveCounterPage_upper_digit{background:transparent;font-size:12vw;margin:0 5px;display:flex;justify-content:center;align-items:center}.LiveCounterPage_lower_digit{background:transparent;font-size:12vw;margin:0 5px;opacity:0}.LiveCounterPage_commaSeparator{width:-moz-fit-content;width:fit-content}.LiveCounterPage_fade_out_up{animation:LiveCounterPage_fadeOutUp 0.5s ease-out forwards, LiveCounterPage_reAppear 0s 3s forwards}@keyframes LiveCounterPage_fadeOutUp{0%{opacity:1;transform:translateY(0)}50%{opacity:0;transform:translateY(-12vw)}100%{opacity:0;transform:translateY(0)}}@keyframes LiveCounterPage_reAppear{to{opacity:1}}.LiveCounterPage_fade_in_up{opacity:0;animation:LiveCounterPage_fadeInUp 0.5s ease-out forwards, LiveCounterPage_disappear 0s 3s forwards, LiveCounterPage_reposition 0s 3s forwards}@keyframes LiveCounterPage_fadeInUp{to{opacity:1;transform:translateY(-16.4188vw)}}.LiveCounterPage_fade_in_up_new_digit{opacity:0;animation:LiveCounterPage_fadeInUpNewDigit 0.5s ease-out forwards, LiveCounterPage_disappear 0s 3s forwards, LiveCounterPage_reposition 0s 3s forwards}@keyframes LiveCounterPage_fadeInUpNewDigit{to{opacity:1;transform:translateY(-8.23vw)}}@keyframes LiveCounterPage_disappear{to{opacity:0}}@keyframes LiveCounterPage_reposition{to{transform:translateY(0)}}\n", ""]);

// Exports
exports.locals = {
	"root": "LiveCounterPage_root",
	"counter_title": "LiveCounterPage_counter_title",
	"counter_container": "LiveCounterPage_counter_container",
	"digit_container": "LiveCounterPage_digit_container",
	"upper_digit": "LiveCounterPage_upper_digit",
	"lower_digit": "LiveCounterPage_lower_digit",
	"commaSeparator": "LiveCounterPage_commaSeparator",
	"fade_out_up": "LiveCounterPage_fade_out_up",
	"fadeOutUp": "LiveCounterPage_fadeOutUp",
	"reAppear": "LiveCounterPage_reAppear",
	"fade_in_up": "LiveCounterPage_fade_in_up",
	"fadeInUp": "LiveCounterPage_fadeInUp",
	"disappear": "LiveCounterPage_disappear",
	"reposition": "LiveCounterPage_reposition",
	"fade_in_up_new_digit": "LiveCounterPage_fade_in_up_new_digit",
	"fadeInUpNewDigit": "LiveCounterPage_fadeInUpNewDigit"
};
import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  Dot,
} from 'recharts';
import PropTypes from 'prop-types';
import regression from 'regression';
import mapValues from 'lodash/mapValues';
import findLastIndex from 'lodash/findLastIndex';
import mapKeys from 'lodash/mapKeys';
import pickBy from 'lodash/pickBy';
import Switch from '@material-ui/core/Switch';
import { graphHeight, predefinedColors } from '../constants';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { DeepEqualityComponent } from '../../../Core/DeepEqualityComponent/DeepEqualityComponent';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import { ClickableLegendItems } from '../../ClickableLegendItems/ClickableLegendItems';
import { generateColors } from '../utils';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';

class AmplitudeAndNoiseGraphBase extends DeepEqualityComponent {
  render() {
    const {
      t,
      data,
      legendItems,
      onLegendItemClick,
      areTrendLinesEnabled,
      onTrendLinesEnabledChange,
      isInPreview,
      isAnimationActive,
      colors: storedColors,
    } = this.props;

    const numOfLegendItems = Object.keys(legendItems).length + 1;

    const amplitudeColors =
      numOfLegendItems > predefinedColors.length
        ? generateColors(numOfLegendItems)
        : predefinedColors;

    // we remove the last color because it should be the red used for average noise
    const amplitudeColorsWithoutNoiseColor =
      (amplitudeColors.pop(), amplitudeColors);

    const colorsByLegendItemName = mapValues(
      legendItems,
      (isLegendItemEnabled, legendItem) =>
        legendItem === 'averageNoise'
          ? '#FF9A9A'
          : amplitudeColorsWithoutNoiseColor[
              Object.keys(legendItems)
                .sort()
                .indexOf(legendItem.replace('trend-', ''))
            ]
    );

    const colors = storedColors || colorsByLegendItemName;

    const AVERAGE_NOISE_DATA_KEY = 'averageNoise';
    const NOISE_AXIS_ID = 'noise';
    const AMPLITUDE_AXIS_ID = 'amplitude';
    const DATE_KEY = 'date';

    const amplitudesKeys = Object.keys(legendItems).filter(
      key => !key.startsWith('trend-') && key !== AVERAGE_NOISE_DATA_KEY
    );

    const trendLineDataPoints = amplitudesKeys.reduce(
      (acc, cur) => ({ ...acc, [cur]: [] }),
      {}
    );

    amplitudesKeys.forEach(amplitudeKey => {
      data.forEach((dataObject, index) => {
        trendLineDataPoints[amplitudeKey].push([
          index,
          dataObject[amplitudeKey] || null,
        ]);
      });
    });

    const trimmedTrendLineDataPoints = mapValues(
      trendLineDataPoints,
      trendLineData => {
        const lastIndexOfNonZeroPoint = findLastIndex(
          trendLineData,
          pointArr => pointArr[1] !== 0
        );

        const shouldTrimZeroPoints =
          lastIndexOfNonZeroPoint !== -1 &&
          trendLineData
            .slice(lastIndexOfNonZeroPoint + 1)
            .every(pointArr => pointArr[1] === 0);

        if (shouldTrimZeroPoints) {
          return trendLineData.slice(0, lastIndexOfNonZeroPoint + 1);
        }

        return trendLineData;
      }
    );

    const trendLineDataPointsRegressionResults = mapValues(
      trimmedTrendLineDataPoints,
      trendLineDataArr =>
        regression.linear(trendLineDataArr, { order: 4, precision: 20 })
    );

    const trendLineData = mapValues(
      trendLineDataPointsRegressionResults,
      trendLineDataPointsRegressionResult =>
        trendLineDataPointsRegressionResult.points.map(points => points[1])
    );

    const trendLineDataWithTrendKeys = mapKeys(
      trendLineData,
      (value, key) => `trend-${key}`
    );

    const dataWithTrendLinesData = data.map((dataObject, index) => {
      const newDataObject = { ...dataObject };
      amplitudesKeys.forEach(amplitudeKey => {
        newDataObject[`trend-${amplitudeKey}`] =
          trendLineDataWithTrendKeys[`trend-${amplitudeKey}`][index];
      });

      return newDataObject;
    });

    const amplitudeTrendKeys = amplitudesKeys.map(
      amplitudeKey => `trend-${amplitudeKey}`
    );

    const filteredLegendItems = pickBy(
      legendItems,
      areTrendLinesEnabled
        ? () => true
        : (isLegendItemEnabled, legendItem) => !legendItem.startsWith('trend-')
    );
    const sortedFilteredLegendItems = [
      'averageNoise',
      ...Object.keys(filteredLegendItems)
        .filter(key => key !== 'averageNoise')
        .sort(),
    ];

    return (
      <div>
        <div
          style={{
            display: isInPreview ? 'none' : 'flex',
            flexWrap: 'wraps',
            marginBottom: 20,
          }}
        >
          <p
            style={{
              margin: '0px 10px 0px 0px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('enable_trend_lines')}
          </p>
          <Switch
            checked={areTrendLinesEnabled}
            onChange={onTrendLinesEnabledChange}
            color="primary"
          />
        </div>

        <div>
          <ResponsiveContainer width="100%" height={graphHeight}>
            <ComposedChart
              data={dataWithTrendLinesData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={DATE_KEY}
                tickMargin={7}
                tick={{ fontSize: 12 }}
                tickFormatter={tick => tick.slice(0, -5)}
              />

              <YAxis
                yAxisId={AMPLITUDE_AXIS_ID}
                orientation="left"
                tickFormatter={tick => `${tick} (µV)`}
                tickMargin={7}
                type="number"
                allowDataOverflow
                domain={[0, 'dataMax']}
                tick={{ fontSize: 12 }}
              />

              {legendItems[AVERAGE_NOISE_DATA_KEY] && (
                <YAxis
                  yAxisId={NOISE_AXIS_ID}
                  ticks={[20, 40, 60, 80, 100]}
                  tickFormatter={tick => `${tick}%`}
                  orientation="right"
                  tickMargin={7}
                  tick={{ fontSize: 12 }}
                />
              )}

              <Tooltip
                formatter={(value, name) => {
                  const suffix = name === 'Average Noise' ? '%' : ' (µV)';
                  return value === 0
                    ? `0${suffix}`
                    : `${parseFloat(value, 10).toFixed(2)}${suffix}`;
                }}
                isAnimationActive={false}
              />

              {data[0] && legendItems[AVERAGE_NOISE_DATA_KEY] && (
                <Bar
                  dataKey={AVERAGE_NOISE_DATA_KEY}
                  fill={colors[AVERAGE_NOISE_DATA_KEY]}
                  yAxisId={NOISE_AXIS_ID}
                  name="Average Noise"
                  maxBarSize={90}
                  isAnimationActive={!isInPreview && isAnimationActive}
                />
              )}

              {amplitudeTrendKeys.map(trendKey => (
                <Line
                  type="monotone"
                  dataKey={trendKey}
                  key={trendKey}
                  hide={!legendItems[trendKey]}
                  stroke={colors[trendKey]}
                  yAxisId={AMPLITUDE_AXIS_ID}
                  isAnimationActive={!isInPreview && isAnimationActive}
                  strokeWidth={2}
                  dot={false}
                  strokeDasharray="2"
                />
              ))}

              {amplitudesKeys.map(dataKey => (
                <Line
                  type="monotone"
                  key={dataKey}
                  dataKey={dataKey}
                  stroke={colors[dataKey]}
                  hide={!legendItems[dataKey]}
                  yAxisId={AMPLITUDE_AXIS_ID}
                  isAnimationActive={!isInPreview && isAnimationActive}
                  strokeWidth={2}
                  dot={props => (
                    <Dot
                      {...props}
                      r={props.r - 2}
                      strokeWidth={props.strokeWidth - 1}
                    />
                  )}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <ClickableLegendItems
          legendItems={filteredLegendItems}
          legendItemsKeys={sortedFilteredLegendItems}
          onLegendItemClick={onLegendItemClick}
          isInPreview={isInPreview}
          legendItemFormatter={legendItem =>
            (legendItem === 'averageNoise' && 'Average Noise') ||
            capitalizeFirstLetter(legendItem)
          }
          colors={colors}
        />
      </div>
    );
  }
}

AmplitudeAndNoiseGraphBase.defaultProps = {
  isInPreview: false,
  onLegendItemClick: () => {},
  onTrendLinesEnabledChange: () => {},
};

AmplitudeAndNoiseGraphBase.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  legendItems: PropTypes.object.isRequired,
  onLegendItemClick: PropTypes.func,
  areTrendLinesEnabled: PropTypes.bool.isRequired,
  onTrendLinesEnabledChange: PropTypes.func,
  isInPreview: PropTypes.bool.isRequired,
  isAnimationActive: PropTypes.bool,
  colors: PropTypes.object.isRequired,
};

export const AmplitudeAndNoiseGraph = withTranslation(
  AmplitudeAndNoiseGraphBase
);

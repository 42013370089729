import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './GeneratedReportDialog.scss';
import doneGif from '../../../../assets/done-animation.gif';

export const GeneratedReportDialog = ({
  isOpen,
  onDone,
  onViewReport,
  onShareReport,
}) => {
  const t = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onDone}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span className={styles.dialog_header}>
          {t('report-successfully-generated')}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className={styles.done_gif_container}>
          <img src={doneGif} alt="done" />
          <p className={styles.dialog_text}>
            {t('client-can-access-report-directly-from-app')}
            <br />
            {t('share-report-using-other-mediums')}
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDone} variant="text" color="default">
          <span className={styles.button}>{t('done')}</span>
        </Button>
        <Button onClick={onViewReport} variant="contained" color="primary">
          <span className={styles.button}>{t('view-report')}</span>
        </Button>
        <Button onClick={onShareReport} variant="contained" color="primary">
          <span className={styles.button}>{t('share-report')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GeneratedReportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
  onViewReport: PropTypes.func.isRequired,
  onShareReport: PropTypes.func.isRequired,
};

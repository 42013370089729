import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import RootRef from '@material-ui/core/RootRef';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { withStyles } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import random from 'lodash/random';
import { debounce } from 'lodash';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import styles from './SessionComparisonGraph.scss';
import { DeepEqualityComponent } from '../../../Core/DeepEqualityComponent/DeepEqualityComponent';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { graphHeight } from '../constants';
import { ClickableLegendItems } from '../../ClickableLegendItems/ClickableLegendItems';
import {
  paragraphFontSize,
  xsExtraMediaMaxWidth,
} from '../../../cssInJs/constants';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';

const stylesFn = () => ({
  formControl: {
    margin: 0,
    minWidth: 175,
  },
  inputLabelRoot: {
    fontSize: paragraphFontSize,
  },
  selectRoot: {
    fontSize: paragraphFontSize,
    lineHeight: 'initial',
  },
});

export class SessionComparisonGraphBase extends DeepEqualityComponent {
  constructor(props) {
    super(props);
    this.sessionSlicesInputLabel = React.createRef();
    this.comparisonParameterInputLabel = React.createRef();
    this.state = {
      sessionSlicesInputLabelWidth: 0,
      innerWidth: window.innerWidth,
    };
    this.onResize = debounce(
      () => this.setState({ innerWidth: window.innerWidth }),
      100
    );
  }

  componentDidMount() {
    const { isInPreview } = this.props;
    if (!isInPreview) {
      this.setState({
        sessionSlicesInputLabelWidth: this.sessionSlicesInputLabel.current
          .offsetWidth,
      });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const {
      classes,
      t,
      onSessionSliceChange,
      sessionSlice,
      sessionSliceOptions,
      comparisonParameters,
      onComparisonParameterClick,
      dataFormatedNames,
      yAxisTicks,
      isInPreview,
      data,
      unitTemplate,
      numberOfSessionsInComparison,
      yAxisWidth,
    } = this.props;

    const { innerWidth } = this.state;

    const PERCENTAGE_CHANGE = '_Percentage_Change';

    const comparisonParametersSortedKeys = Object.keys(
      comparisonParameters
    ).sort();

    const { sessionSlicesInputLabelWidth } = this.state;

    const { colors } = this.props;

    // const tooltipFormatter = (value, name) => [
    //   value === 0 ? `0${unitTemplate}` : `${value.toFixed(2)}${unitTemplate}`,
    //   dataFormatedNames[name] || name,
    // ];

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div style={{ backgroundColor: 'white', padding: 10 }}>
            <div style={{ color: '#5e5e5e', marginBottom: 5 }}>{label}</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {payload.map(pld =>
                pld.payload[pld.dataKey + PERCENTAGE_CHANGE] ? (
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: 5,
                      color: pld.fill,
                    }}
                  >
                    <div style={{ marginRight: 5 }}>
                      {dataFormatedNames[pld.dataKey] || pld.dataKey}:
                    </div>
                    <div>{pld.payload[pld.dataKey + PERCENTAGE_CHANGE]}</div>
                  </div>
                ) : null
              )}
            </div>
          </div>
        );
      }

      return null;
    };

    const yAxisTickFormatter = tick => `${tick}${unitTemplate}`;

    const graphData = cloneDeep(data);

    Object.keys(graphData[0]).forEach(key => {
      if (graphData[0][key] && !(innerWidth <= xsExtraMediaMaxWidth)) {
        // eslint-disable-next-line prefer-template
        graphData[0][key + PERCENTAGE_CHANGE] = `${graphData[0][key].toFixed(
          2
        )}${unitTemplate === '%' ? unitTemplate : ''}`;
      }
    });

    Object.keys(graphData[1]).forEach(key => {
      if (!(innerWidth <= xsExtraMediaMaxWidth) && graphData[1][key]) {
        // eslint-disable-next-line prefer-template
        graphData[1][key + PERCENTAGE_CHANGE] = `${graphData[1][key].toFixed(
          2
        )}${unitTemplate === '%' ? unitTemplate : ''}`;
      }
      const difference = graphData[1][key] - graphData[0][key];
      if (
        graphData[0][key] &&
        !(innerWidth <= xsExtraMediaMaxWidth) &&
        graphData[1][key]
      ) {
        // eslint-disable-next-line prefer-template
        graphData[1][key + PERCENTAGE_CHANGE] = `${graphData[1][key].toFixed(
          2
        )}${unitTemplate === '%' ? unitTemplate : ''} (${
          difference > 0 ? '+' : ''
        }${((difference / graphData[0][key]) * 100).toFixed(
          unitTemplate !== ' (µV)' ? 2 : 0
        )}%)`;
      }
    });

    const xAxisDataKey = 'comparator';
    graphData[0][xAxisDataKey] = `First ${sessionSlice}%`;
    graphData[1][xAxisDataKey] = `Last ${sessionSlice}%`;

    return (
      <React.Fragment>
        <div className={styles.select_sessions_slices_container}>
          <div>
            {isInPreview && (
              <p>
                Sessions compared ({numberOfSessionsInComparison} vs{' '}
                {numberOfSessionsInComparison})
              </p>
            )}
            {!isInPreview && (
              <FormControl variant="outlined" className={classes.formControl}>
                <RootRef rootRef={this.sessionSlicesInputLabel}>
                  <InputLabel
                    htmlFor="select-sessions-slices"
                    classes={{ root: classes.inputLabelRoot }}
                  >
                    {t('sessions_to_compare')}
                  </InputLabel>
                </RootRef>
                <Select
                  native
                  value={sessionSlice}
                  onChange={onSessionSliceChange}
                  input={
                    <OutlinedInput
                      name="sessions-slices"
                      id="select-sessions-slices"
                      labelWidth={sessionSlicesInputLabelWidth}
                    />
                  }
                  classes={{
                    root: classes.selectRoot,
                  }}
                >
                  {sessionSliceOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        </div>

        <ResponsiveContainer
          width={
            `${random(0, 0.9) + 99.1}%`
            /* we set dynamic width to cause the animation to restart even if it is the same data,
             * or else the label won't appear on second render
             * https://github.com/recharts/recharts/issues/1135
             */
          }
          height={graphHeight}
        >
          <BarChart
            data={graphData}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey={xAxisDataKey} tick={{ fontSize: 12 }} />
            <YAxis
              {...(yAxisTicks.length > 0 ? { ticks: yAxisTicks } : {})}
              {...(yAxisTicks.length > 0 ? {} : { width: 100 })}
              tickFormatter={yAxisTickFormatter}
              tick={{ fontSize: 12 }}
              {...(yAxisWidth && { width: yAxisWidth })}
            />
            <Tooltip content={<CustomTooltip />} isAnimationActive={false} />

            {comparisonParametersSortedKeys.map(
              (comparisonParameter, index) => (
                <Bar
                  dataKey={comparisonParameter}
                  stackId={index}
                  fill={colors[index] || colors[comparisonParameter]}
                  maxBarSize={90}
                  hide={!comparisonParameters[comparisonParameter]}
                  legendType="circle"
                  key={comparisonParameter}
                >
                  <LabelList
                    // eslint-disable-next-line prefer-template
                    dataKey={comparisonParameter + PERCENTAGE_CHANGE}
                    position="top"
                    formatter={value => value}
                  />
                  {/* <LabelList
                    dataKey={comparisonParameter + '_Percentage_Change'}
                    position="inside"
                    formatter={value => {value > 0 ? `+${value}%` : `${value}%`}}
                  /> */}
                </Bar>
              )
            )}
          </BarChart>
        </ResponsiveContainer>

        <ClickableLegendItems
          legendItems={comparisonParameters}
          legendItemsKeys={comparisonParametersSortedKeys}
          onLegendItemClick={onComparisonParameterClick}
          isInPreview={isInPreview}
          legendItemFormatter={legendItem =>
            dataFormatedNames[legendItem] || legendItem
          }
          colors={colors}
        />
      </React.Fragment>
    );
  }
}

SessionComparisonGraphBase.defaultProps = {
  onComparisonParameterClick: () => {},
  onSessionSliceChange: () => {},
};

SessionComparisonGraphBase.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onComparisonParameterClick: PropTypes.func,
  dataFormatedNames: PropTypes.object,
  onSessionSliceChange: PropTypes.func,
  sessionSlice: PropTypes.number.isRequired,
  sessionSliceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  comparisonParameters: PropTypes.objectOf(PropTypes.bool.isRequired)
    .isRequired,
  yAxisTicks: PropTypes.array,
  unitTemplate: PropTypes.string,
  isInPreview: PropTypes.bool,
  colors: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ]),
  data: PropTypes.array.isRequired,
  numberOfSessionsInComparison: PropTypes.number.isRequired,
  yAxisWidth: PropTypes.number,
};

SessionComparisonGraphBase.defaultProps = {
  unitTemplate: '',
  dataFormatedNames: {},
  yAxisTicks: [],
  yAxisTickFormatter: tick => tick,
  tooltipFormatter: value => value,
  isInPreview: false,
};

export const SessionComparisonGraph = withTranslation(
  withStyles(stylesFn)(SessionComparisonGraphBase)
);

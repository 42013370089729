import React from 'react';
import PropTypes from 'prop-types';
import { SessionComparisonGraph } from './SessionComparisonGraph';
import {
  frequenciesSessionComparisonAggregationMethods,
  predefinedColors,
} from '../constants';
import { generateColors } from '../utils';
import { CustomSelect } from '../../../Core/Components/CustomSelect/CustomSelect';
import styles from './UVSessionComparisonGraph.scss';

export const UVSessionComparisonGraph = props => {
  const {
    numOfComparisonParameters,
    aggregationMethod,
    onAggregationMethodChange,
    isInPreview,
  } = props;

  const unitTemplate = ' (µV)';
  const yAxisWidth = 75;
  const localColors =
    numOfComparisonParameters > predefinedColors.length
      ? generateColors(numOfComparisonParameters)
      : predefinedColors;
  const colors = props.colors || localColors;

  return (
    <div className={styles.root}>
      {!isInPreview && (
        <div className={styles.aggregation_method_select}>
          <CustomSelect
            allOptions={Object.entries(
              frequenciesSessionComparisonAggregationMethods
            ).map(([, value]) => ({ value, text: value }))}
            selectedOption={aggregationMethod}
            onChange={onAggregationMethodChange}
            inputLabelText="Aggregation method"
          />
        </div>
      )}
      <SessionComparisonGraph
        unitTemplate={unitTemplate}
        yAxisWidth={yAxisWidth}
        {...props}
        colors={colors}
      />
    </div>
  );
};

UVSessionComparisonGraph.propTypes = {
  numOfComparisonParameters: PropTypes.number.isRequired,
  aggregationMethod: PropTypes.string.isRequired,
  onAggregationMethodChange: PropTypes.func,
  colors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isInPreview: PropTypes.bool,
};

import React from 'react';
import { ErrorBoundary } from '../../Components/ErrorBoundary/ErrorBoundary';

export const withErrorBoundary = (Component, errorBoundaryProps) => {
  class WrapperErrorBoundary extends React.Component {
    render() {
      return (
        <ErrorBoundary {...errorBoundaryProps}>
          <Component {...this.props} />
        </ErrorBoundary>
      );
    }
  }

  return WrapperErrorBoundary;
};

import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './LagoPreview.scss';

const LagoPreviewBase = ({
  htmlText,
  htmlBtnValue,
  htmlImgPath,
  videoPrev,
  isLoadingVideo,
  quizTextQuestion,
  quizCoises,
  quizBtnValue,
  screenType,
}) => {
  const renderPreviewHtml = () => {
    return (
      <div className={styles.html_preview_container}>
        {htmlImgPath ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{ width: '100px', height: '100px' }}
              src={htmlImgPath}
            />
          </div>
        ) : null}
        <div
          className={styles.editor_preview_container}
          dangerouslySetInnerHTML={{ __html: htmlText }}
        />
        {htmlBtnValue ? (
          <div className={styles.html_btn_preview}>{htmlBtnValue}</div>
        ) : null}
      </div>
    );
  };

  const renderPreviewVideo = () => {
    if (!isLoadingVideo) {
      return (
        <div>
          <video key={videoPrev} width="100%" height="240" controls>
            <source src={videoPrev} type="video/mp4" />
            <track kind="captions" srcLang="en" label="english_captions" />
          </video>
        </div>
      );
    }
    return (
      <div className={styles.spinner_container}>
        <CircularProgress />
      </div>
    );
  };

  const renderPreviewQuiz = () => {
    return (
      <div className={styles.quiz_preview_container}>
        <div>
          {htmlImgPath ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                style={{ width: '100px', height: '100px' }}
                src={htmlImgPath}
              />
            </div>
          ) : null}
          <div
            className={styles.editor_preview_container}
            dangerouslySetInnerHTML={{ __html: quizTextQuestion }}
          />
          <div className={styles.quiz_choises_container}>
            {quizCoises
              ? quizCoises.map(choise => (
                  <div key={choise.key}>• {choise.text}</div>
                ))
              : null}
          </div>
        </div>
        {quizBtnValue ? (
          <div className={styles.quiz_btn_preview}>{quizBtnValue}</div>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.lago_preview_container}>
      {screenType === 'content' ? renderPreviewHtml() : null}
      {screenType === 'vid' || isLoadingVideo ? renderPreviewVideo() : null}
      {screenType === 'quiz' ? renderPreviewQuiz() : null}
    </div>
  );
};

LagoPreviewBase.propTypes = exact({
  htmlText: PropTypes.string,
  htmlBtnValue: PropTypes.string,
  htmlImgPath: PropTypes.string,
  videoPrev: PropTypes.string,
  isLoadingVideo: PropTypes.bool,
  quizTextQuestion: PropTypes.string,
  quizCoises: PropTypes.array,
  quizBtnValue: PropTypes.string,
  screenType: PropTypes.string,
});

export const LagoPreview = React.memo(LagoPreviewBase);
LagoPreview.displayName = 'LagoPreview';

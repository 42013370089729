import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { RegisterAssessmentClinic } from '../RegisterAssessmentClinic/RegisterAssessmentClinic';

const RegisterAssessmentClinicWrapperBase = ({ dormantPlan }) => {
  const { stripe } = window;
  // const nodeURL = environmentConfig.appengine.node;

  return (
    <div>
      <StripeProvider stripe={stripe} key={stripe ? 'stripe' : 'null'}>
        <Elements>
          <RegisterAssessmentClinic stripe={stripe} dormantPlan={dormantPlan} />
        </Elements>
      </StripeProvider>
    </div>
  );
};

RegisterAssessmentClinicWrapperBase.propTypes = exact({
  dormantPlan: PropTypes.object.isRequired,
});

export const RegisterAssessmentClinicWrapper = React.memo(
  RegisterAssessmentClinicWrapperBase
);
RegisterAssessmentClinicWrapper.displayName = 'RegisterAssessmentClinicWrapper';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".GeneratedReportDialog_size_17{font-size:17px}.GeneratedReportDialog_size_10{font-size:10px}.GeneratedReportDialog_size_11{font-size:11px}.GeneratedReportDialog_size_13{font-size:13px}.GeneratedReportDialog_size_15{font-size:15px}.GeneratedReportDialog_size_14{font-size:14px}.GeneratedReportDialog_size_16{font-size:16px}.GeneratedReportDialog_size_12{font-size:12px}.GeneratedReportDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.GeneratedReportDialog_header,.GeneratedReportDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.GeneratedReportDialog_header_only_font_size{font-size:17px;font-weight:500}.GeneratedReportDialog_sub_header{font-size:13px;color:#878787}.GeneratedReportDialog_sub_header_black{font-size:13px;color:black}.GeneratedReportDialog_sub_header_only_font_size{font-size:13px}.GeneratedReportDialog_paragraph_only_font_size{font-size:12px}.GeneratedReportDialog_tab_font_size{font-size:13px}.GeneratedReportDialog_pop_out_header{font-size:20px}.GeneratedReportDialog_title{font-size:15px;font-weight:500;color:#484848}.GeneratedReportDialog_title_black{font-size:15px;font-weight:500;color:black}.GeneratedReportDialog_title_only_font_size{font-size:15px}.GeneratedReportDialog_paragraph,.GeneratedReportDialog_dialog_text{font-size:12px;color:#484848}.GeneratedReportDialog_btn,.GeneratedReportDialog_button{font-size:14px}.GeneratedReportDialog_dialog_header{display:block;text-align:center}.GeneratedReportDialog_dialog_text{margin:0;max-width:480px}.GeneratedReportDialog_form_control{margin:0 !important;width:100% !important}.GeneratedReportDialog_done_gif_container{text-align:center}.GeneratedReportDialog_done_gif_container>img{width:109px;height:109px;margin-bottom:10px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "GeneratedReportDialog_size_17",
	"size_10": "GeneratedReportDialog_size_10",
	"size_11": "GeneratedReportDialog_size_11",
	"size_13": "GeneratedReportDialog_size_13",
	"size_15": "GeneratedReportDialog_size_15",
	"size_14": "GeneratedReportDialog_size_14",
	"size_16": "GeneratedReportDialog_size_16",
	"size_12": "GeneratedReportDialog_size_12",
	"extra_header": "GeneratedReportDialog_extra_header",
	"header": "GeneratedReportDialog_header",
	"dialog_header": "GeneratedReportDialog_dialog_header",
	"header_only_font_size": "GeneratedReportDialog_header_only_font_size",
	"sub_header": "GeneratedReportDialog_sub_header",
	"sub_header_black": "GeneratedReportDialog_sub_header_black",
	"sub_header_only_font_size": "GeneratedReportDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "GeneratedReportDialog_paragraph_only_font_size",
	"tab_font_size": "GeneratedReportDialog_tab_font_size",
	"pop_out_header": "GeneratedReportDialog_pop_out_header",
	"title": "GeneratedReportDialog_title",
	"title_black": "GeneratedReportDialog_title_black",
	"title_only_font_size": "GeneratedReportDialog_title_only_font_size",
	"paragraph": "GeneratedReportDialog_paragraph",
	"dialog_text": "GeneratedReportDialog_dialog_text",
	"btn": "GeneratedReportDialog_btn",
	"button": "GeneratedReportDialog_button",
	"form_control": "GeneratedReportDialog_form_control",
	"done_gif_container": "GeneratedReportDialog_done_gif_container"
};
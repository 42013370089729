import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CircularProgress, TextField } from '@material-ui/core';
import styles from './BillingSummary.scss';
import HipaaPng from '../../../../assets/iso-hipaa-grey.png';

const BillingSummaryBase = ({
  onContinueClicked,
  isLoading,
  couponCode,
  setCouponCode,
  onApllyCoupon,
  isLoadingCoupon,
  couponCodeError,
  couponDiscount,
  dormantPlan,
  appliedCouponCode,
}) => {
  const [displayCouponInput, setDisplayCouponInput] = useState(false);

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const calcTotalPrice = () => {
    return dormantPlan.registrationPrice;
  };

  const calcButtonText = () => {
    return isLoading ? (
      <CircularProgress style={{ color: 'white', width: 18, height: 18 }} />
    ) : (
      'PAY'
    );
  };

  // eslint-disable-next-line no-shadow
  const calcCuoponDiscount = couponDiscount => {
    if (couponDiscount.amount) {
      return couponDiscount.amount;
    }
    return 0;
  };

  const calcTotalPriceWithDiscount = () => {
    return calcTotalPrice() - calcCuoponDiscount(couponDiscount);
  };

  const calcDueToday = () => {
    const finalPrice = couponDiscount
      ? calcTotalPriceWithDiscount()
      : calcTotalPrice();
    if (finalPrice > 0) {
      return numberWithCommas(finalPrice.toFixed(2));
    }
    return 0;
  };

  return (
    <div className={styles.billing_summary_body}>
      <div>
        <div className={styles.billing_summary_container}>
          <div
            className={styles.billing_summary_dual_item}
            style={{ fontWeight: 900 }}
          >
            <div>{dormantPlan.registrationName}</div>
            <div>${calcTotalPrice()}</div>
          </div>

          {couponDiscount && couponDiscount.amount ? (
            <div
              className={styles.billing_summary_dual_item}
              style={{ fontWeight: 900 }}
            >
              <div>Coupon: {appliedCouponCode}</div>
              <div>
                -$
                {calcCuoponDiscount(couponDiscount) > 0
                  ? calcCuoponDiscount(couponDiscount).toFixed(2)
                  : 0}
              </div>
            </div>
          ) : null}

          {!displayCouponInput ? (
            <div
              style={{
                fontWeight: 900,
                color: '#4be0d0',
                cursor: 'pointer',
                width: '100%',
                marginBottom: 20,
              }}
              onClick={() => setDisplayCouponInput(true)}
            >
              Have a coupon code?
            </div>
          ) : (
            <div className={styles.inputs}>
              <div className={styles.inputs_container}>
                <TextField
                  label="Coupon Code"
                  error={!!couponCodeError}
                  helperText={couponCodeError}
                  placeholder="Enter coupon code"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={couponCode}
                  onChange={e => {
                    setCouponCode(e.target.value);
                  }}
                />
              </div>
              <span className={styles.coupun_button} onClick={onApllyCoupon}>
                {isLoadingCoupon ? (
                  <CircularProgress
                    style={{ color: '#4BE0D0', width: 18, height: 18 }}
                  />
                ) : (
                  'APPLY'
                )}
              </span>
            </div>
          )}

          <div className={styles.billing_summary_divider} />

          {couponDiscount && couponDiscount.amount ? (
            <div
              className={styles.billing_summary_dual_item}
              style={{ fontWeight: 900, color: '#4BE0D0' }}
            >
              <div>Your savings today</div>
              <div>${calcCuoponDiscount(couponDiscount).toFixed(2)}</div>
            </div>
          ) : null}
          <div
            className={styles.billing_summary_dual_item}
            style={{ fontWeight: 900 }}
          >
            <div>Due today</div>
            <div>${calcDueToday()}</div>
          </div>
          <div
            className={styles.billing_summary_button}
            onClick={onContinueClicked}
          >
            {calcButtonText()}
          </div>
        </div>
        <div style={{ marginTop: 20, fontSize: 14, textAlign: 'center' }}>
          All transactions are secure and encrypted.
        </div>
        {/* {registrationError ? (
          <div style={{ color: 'red', textAlign: 'center' }}>
            Something went wrong
          </div>
        ) : null} */}
        <div className={styles.terms_and_conditions}>
          By continuing, you’re agreeing to our
          <a
            href="/privacy/terms-of-service-for-practitioners.pdf"
            target="_blank"
          >
            {' '}
            Terms of Service
          </a>{' '}
          and
          <a
            href="/privacy/privacy-notice-for-practitioners.pdf"
            target="_blank"
          >
            {' '}
            Privacy Policy
          </a>
        </div>
      </div>
      <div className={styles.iso_logo_container}>
        <img src={HipaaPng} className={styles.iso_logo_image} />
      </div>
    </div>
  );
};

BillingSummaryBase.propTypes = exact({
  onContinueClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  couponCode: PropTypes.string,
  setCouponCode: PropTypes.func,
  onApllyCoupon: PropTypes.func,
  isLoadingCoupon: PropTypes.bool,
  couponCodeError: PropTypes.string,
  couponDiscount: PropTypes.object,
  dormantPlan: PropTypes.object,
  appliedCouponCode: PropTypes.string,
});

export const BillingSummary = React.memo(BillingSummaryBase);
BillingSummary.displayName = 'BillingSummary';

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NFAddonsDialog_price_dialog_view{display:flex;flex-direction:column;justify-content:center;align-items:center}.NFAddonsDialog_price_dialog_title{font-size:22px;font-weight:900}@media only screen and (max-width: 1200px){.NFAddonsDialog_price_dialog_title{text-align:center}}.NFAddonsDialog_price_dialog_container{border:1px solid #DBDBDB;margin:22px 120px;border-radius:17px}@media only screen and (max-width: 1200px){.NFAddonsDialog_price_dialog_container{margin:22px 20px}}.NFAddonsDialog_price_dialog_container_top{padding:20px}.NFAddonsDialog_price_dialog_container_buttom{padding:20px;background-color:#fafafa;border-bottom-left-radius:17px;border-bottom-right-radius:17px}.NFAddonsDialog_price_dialog_dual_item{display:flex;justify-content:space-between}.NFAddonsDialog_price_dialog_dual_item_left{font-weight:900;font-size:18px;display:flex;align-items:center}.NFAddonsDialog_price_dialog_dual_item_right_top{color:#829EDE;font-weight:900;font-size:18px;text-align:end}.NFAddonsDialog_divider{border:1px solid #DBDBDB;margin-top:10px}.NFAddonsDialog_feature_item{display:flex;align-items:center;margin-top:15px}\n", ""]);

// Exports
exports.locals = {
	"price_dialog_view": "NFAddonsDialog_price_dialog_view",
	"price_dialog_title": "NFAddonsDialog_price_dialog_title",
	"price_dialog_container": "NFAddonsDialog_price_dialog_container",
	"price_dialog_container_top": "NFAddonsDialog_price_dialog_container_top",
	"price_dialog_container_buttom": "NFAddonsDialog_price_dialog_container_buttom",
	"price_dialog_dual_item": "NFAddonsDialog_price_dialog_dual_item",
	"price_dialog_dual_item_left": "NFAddonsDialog_price_dialog_dual_item_left",
	"price_dialog_dual_item_right_top": "NFAddonsDialog_price_dialog_dual_item_right_top",
	"divider": "NFAddonsDialog_divider",
	"feature_item": "NFAddonsDialog_feature_item"
};
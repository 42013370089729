exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DeleteReportDialog_size_17{font-size:17px}.DeleteReportDialog_size_10{font-size:10px}.DeleteReportDialog_size_11{font-size:11px}.DeleteReportDialog_size_13{font-size:13px}.DeleteReportDialog_size_15{font-size:15px}.DeleteReportDialog_size_14{font-size:14px}.DeleteReportDialog_size_16{font-size:16px}.DeleteReportDialog_size_12{font-size:12px}.DeleteReportDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.DeleteReportDialog_header,.DeleteReportDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.DeleteReportDialog_header_only_font_size{font-size:17px;font-weight:500}.DeleteReportDialog_sub_header{font-size:13px;color:#878787}.DeleteReportDialog_sub_header_black{font-size:13px;color:black}.DeleteReportDialog_sub_header_only_font_size{font-size:13px}.DeleteReportDialog_paragraph_only_font_size{font-size:12px}.DeleteReportDialog_tab_font_size{font-size:13px}.DeleteReportDialog_pop_out_header{font-size:20px}.DeleteReportDialog_title{font-size:15px;font-weight:500;color:#484848}.DeleteReportDialog_title_black{font-size:15px;font-weight:500;color:black}.DeleteReportDialog_title_only_font_size{font-size:15px}.DeleteReportDialog_paragraph,.DeleteReportDialog_dialog_text{font-size:12px;color:#484848}.DeleteReportDialog_btn,.DeleteReportDialog_button{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "DeleteReportDialog_size_17",
	"size_10": "DeleteReportDialog_size_10",
	"size_11": "DeleteReportDialog_size_11",
	"size_13": "DeleteReportDialog_size_13",
	"size_15": "DeleteReportDialog_size_15",
	"size_14": "DeleteReportDialog_size_14",
	"size_16": "DeleteReportDialog_size_16",
	"size_12": "DeleteReportDialog_size_12",
	"extra_header": "DeleteReportDialog_extra_header",
	"header": "DeleteReportDialog_header",
	"dialog_header": "DeleteReportDialog_dialog_header",
	"header_only_font_size": "DeleteReportDialog_header_only_font_size",
	"sub_header": "DeleteReportDialog_sub_header",
	"sub_header_black": "DeleteReportDialog_sub_header_black",
	"sub_header_only_font_size": "DeleteReportDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "DeleteReportDialog_paragraph_only_font_size",
	"tab_font_size": "DeleteReportDialog_tab_font_size",
	"pop_out_header": "DeleteReportDialog_pop_out_header",
	"title": "DeleteReportDialog_title",
	"title_black": "DeleteReportDialog_title_black",
	"title_only_font_size": "DeleteReportDialog_title_only_font_size",
	"paragraph": "DeleteReportDialog_paragraph",
	"dialog_text": "DeleteReportDialog_dialog_text",
	"btn": "DeleteReportDialog_btn",
	"button": "DeleteReportDialog_button"
};
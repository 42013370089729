import React from 'react';

export const ViewIcon = props => (
  <svg width={24} height={24} {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill="#2196f3"
      d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
    />
  </svg>
);

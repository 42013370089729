import { getEventLogger } from '../../utils/utils';

export const useEventLoggerWithDefaultData = ({
  prefix = '',
  commonData = {},
  commonRequestExtraData = {},
}) => {
  return getEventLogger({
    prefix,
    commonData,
    commonRequestExtraData: {
      // TODO use react context instead of storage, once all components are wrapped with providers
      userId: sessionStorage.userId,
      patientId: sessionStorage.customerId,
      ...commonRequestExtraData,
    },
  });
};

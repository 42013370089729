import React from 'react';
import PropTypes from 'prop-types';
import styles from './ClickableLegendItems.scss';

export const ClickableLegendItems = ({
  legendItems,
  legendItemsKeys,
  isInPreview,
  onLegendItemClick,
  legendItemFormatter,
  colors,
  isCirclesHidden,
  customLegendIcons,
}) => (
  <div className={styles.legend_container}>
    {/* legend items keys is used to ensure deterministic color when using colors as array */}
    {(legendItemsKeys || Object.keys(legendItems)).map((legendItem, index) => (
      <div
        key={legendItem}
        className={styles.legend}
        style={{
          display: isInPreview && !legendItems[legendItem] ? 'none' : '',
          cursor: isInPreview ? 'default' : 'pointer',
        }}
        onClick={() => onLegendItemClick(legendItem)}
      >
        {customLegendIcons ? (
          <div>{customLegendIcons[legendItem]}</div>
        ) : (
          <div
            className={styles.legend_circle}
            style={{
              background: legendItems[legendItem]
                ? colors[index] || colors[legendItem]
                : 'black',
              ...(isCirclesHidden ? { display: 'none' } : {}),
            }}
          />
        )}

        <div className={styles.legend_name}>
          <span>{legendItemFormatter(legendItem)}</span>
        </div>
      </div>
    ))}
  </div>
);

ClickableLegendItems.defaultProps = {
  legendItemFormatter: legendItem => legendItem,
  isInPreview: false,
  onLegendItemClick: () => {},
  isCirclesHidden: false,
  customLegendIcons: null,
};

ClickableLegendItems.propTypes = {
  legendItems: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
  isInPreview: PropTypes.bool,
  onLegendItemClick: PropTypes.func,
  legendItemFormatter: PropTypes.func,
  colors: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ]),
  legendItemsKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  isCirclesHidden: PropTypes.bool,
  customLegendIcons: PropTypes.objectOf(PropTypes.node),
};

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CustomDialogSuccessMessage_size_17{font-size:17px}.CustomDialogSuccessMessage_size_10{font-size:10px}.CustomDialogSuccessMessage_size_11{font-size:11px}.CustomDialogSuccessMessage_size_13{font-size:13px}.CustomDialogSuccessMessage_size_15{font-size:15px}.CustomDialogSuccessMessage_size_14{font-size:14px}.CustomDialogSuccessMessage_size_16{font-size:16px}.CustomDialogSuccessMessage_size_12{font-size:12px}.CustomDialogSuccessMessage_extra_header{font-size:18px;font-weight:500;color:#000000}.CustomDialogSuccessMessage_header,.CustomDialogSuccessMessage_dialog_header{font-size:17px;font-weight:500;color:#000000}.CustomDialogSuccessMessage_header_only_font_size{font-size:17px;font-weight:500}.CustomDialogSuccessMessage_sub_header{font-size:13px;color:#878787}.CustomDialogSuccessMessage_sub_header_black{font-size:13px;color:black}.CustomDialogSuccessMessage_sub_header_only_font_size{font-size:13px}.CustomDialogSuccessMessage_paragraph_only_font_size{font-size:12px}.CustomDialogSuccessMessage_tab_font_size{font-size:13px}.CustomDialogSuccessMessage_pop_out_header{font-size:20px}.CustomDialogSuccessMessage_title{font-size:15px;font-weight:500;color:#484848}.CustomDialogSuccessMessage_title_black{font-size:15px;font-weight:500;color:black}.CustomDialogSuccessMessage_title_only_font_size{font-size:15px}.CustomDialogSuccessMessage_paragraph{font-size:12px;color:#484848}.CustomDialogSuccessMessage_btn{font-size:14px}.CustomDialogSuccessMessage_dialog_header{margin-bottom:0px;margin-top:0px}.CustomDialogSuccessMessage_avatar_container{display:flex;margin-bottom:28px;align-items:center}.CustomDialogSuccessMessage_avatar{width:40px;height:40px;margin-right:20px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "CustomDialogSuccessMessage_size_17",
	"size_10": "CustomDialogSuccessMessage_size_10",
	"size_11": "CustomDialogSuccessMessage_size_11",
	"size_13": "CustomDialogSuccessMessage_size_13",
	"size_15": "CustomDialogSuccessMessage_size_15",
	"size_14": "CustomDialogSuccessMessage_size_14",
	"size_16": "CustomDialogSuccessMessage_size_16",
	"size_12": "CustomDialogSuccessMessage_size_12",
	"extra_header": "CustomDialogSuccessMessage_extra_header",
	"header": "CustomDialogSuccessMessage_header",
	"dialog_header": "CustomDialogSuccessMessage_dialog_header",
	"header_only_font_size": "CustomDialogSuccessMessage_header_only_font_size",
	"sub_header": "CustomDialogSuccessMessage_sub_header",
	"sub_header_black": "CustomDialogSuccessMessage_sub_header_black",
	"sub_header_only_font_size": "CustomDialogSuccessMessage_sub_header_only_font_size",
	"paragraph_only_font_size": "CustomDialogSuccessMessage_paragraph_only_font_size",
	"tab_font_size": "CustomDialogSuccessMessage_tab_font_size",
	"pop_out_header": "CustomDialogSuccessMessage_pop_out_header",
	"title": "CustomDialogSuccessMessage_title",
	"title_black": "CustomDialogSuccessMessage_title_black",
	"title_only_font_size": "CustomDialogSuccessMessage_title_only_font_size",
	"paragraph": "CustomDialogSuccessMessage_paragraph",
	"btn": "CustomDialogSuccessMessage_btn",
	"avatar_container": "CustomDialogSuccessMessage_avatar_container",
	"avatar": "CustomDialogSuccessMessage_avatar"
};
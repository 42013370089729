import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Text,
} from 'recharts';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import { graphHeight } from '../constants';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';
import { useBoundingRect } from '../../../Core/hooks/useBoundingRect';
import { useMediaMatch } from '../../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../../cssInJs/constants';

const AssessmentPerformanceComparisonGraphBase = ({
  data,
  isSomeDataHidden,
  isInPreview,
  preAssessment,
  postAssessment,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  noDataIndicator,
}) => {
  const unit = '%';

  const graphContainerElementRef = useRef();
  const graphContainerBoundingRect = useBoundingRect(graphContainerElementRef);
  const calcOuterRadius = () => {
    if (graphContainerBoundingRect && graphContainerBoundingRect.width < 480) {
      return Math.min(graphContainerBoundingRect.width / 5, 70);
    }

    return '80%';
  };
  const ticksWidth =
    graphContainerBoundingRect && graphContainerBoundingRect.width < 480
      ? Math.max(graphContainerBoundingRect.width / 3, 60)
      : window.Number.POSITIVE_INFINITY;

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  return (
    <div style={{ marginBottom: 48 }}>
      <AssessmentComparisonGraph
        assessmentOptions={assessmentOptions}
        preAssessment={preAssessment}
        postAssessment={postAssessment}
        onPreAssessmentChange={onPreAssessmentChange}
        onPostAssessmentChange={onPostAssessmentChange}
        isInPreview={isInPreview}
        isSomeDataHidden={isSomeDataHidden}
        preAssessmentTimestamp={preAssessmentTimestamp}
        postAssessmentTimestamp={postAssessmentTimestamp}
      >
        {noDataIndicator ? (
          <div ref={graphContainerElementRef}>{noDataIndicator}</div>
        ) : (
          <div ref={graphContainerElementRef}>
            <ResponsiveContainer width="100%" height={graphHeight}>
              <RadarChart
                data={data}
                margin={{ bottom: -30 }}
                outerRadius={calcOuterRadius()}
              >
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="category"
                  width={ticksWidth}
                  /* eslint-disable react/prop-types */

                  tick={props => {
                    const y = (() => {
                      const oldY = props.y;
                      if (props.payload.coordinate === -90) {
                        if (
                          graphContainerElementRef &&
                          graphContainerElementRef.width < 466
                        ) {
                          return oldY + 20;
                        }
                        return oldY + 10;
                      }

                      return oldY;
                    })();

                    const fontSize = isMatchingXSScreen ? 11 : 13;
                    const width = isMatchingXSScreen
                      ? props.width + 10
                      : props.width;
                    return (
                      <Text {...props} y={y} fontSize={fontSize} width={width}>
                        {props.payload.value}
                      </Text>
                    );
                  }}
                  /* eslint-enable react/prop-types */
                />
                <PolarRadiusAxis
                  unit={unit}
                  domain={[0, 100]}
                  axisLine={false}
                  tick={false}
                />

                <Radar
                  name="Pre"
                  dataKey="preScore"
                  stroke="#918AEA"
                  fill="#918AEA"
                  fillOpacity={0.6}
                  unit={unit}
                />

                <Radar
                  name="Post"
                  dataKey="postScore"
                  stroke="#F469CE"
                  fill="#F469CE"
                  fillOpacity={0.6}
                  unit={unit}
                />
                <Legend iconType="circle" />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        )}
      </AssessmentComparisonGraph>
    </div>
  );
};

AssessmentPerformanceComparisonGraphBase.defaultProps = {
  isInPreview: false,
  isSomeDataHidden: false,
  noDataIndicator: null,
};

AssessmentPerformanceComparisonGraphBase.propTypes = exact({
  data: PropTypes.array,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string,
  postAssessment: PropTypes.string,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  isSomeDataHidden: PropTypes.bool,
  noDataIndicator: PropTypes.node,
});

export const AssessmentPerformanceComparisonGraph = React.memo(
  AssessmentPerformanceComparisonGraphBase
);
AssessmentPerformanceComparisonGraph.displayName =
  'AssessmentPerformanceComparisonGraph';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Text,
} from 'recharts';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './AmplitudePerFrequencyGraph.scss';
import { ClickableLegendItems } from '../../ClickableLegendItems/ClickableLegendItems';
import { presetFrequenciesNames } from '../../../models/frequencies/frequencies';
import { sortedFrequencies } from '../../../models/assessments/assessments';
import { setFixedDecimalsIfNeeded } from '../../../utils/utils';
import { useMediaMatch } from '../../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../../cssInJs/constants';

const colors = {
  eyesOpened: '#00aa89',
  eyesClosed: '#8b54c6',
  underTask: '#444466',
};

const dataFormattedNames = {
  eyesOpened: 'Eyes Open',
  eyesClosed: 'Eyes Closed',
  underTask: 'Under Task',
  ...presetFrequenciesNames,
};

const CustomizedTick = props => {
  const { x, y, payload } = props;

  return (
    <Text
      x={x}
      y={y}
      width={45}
      textAnchor="middle"
      verticalAnchor="start"
      style={{
        fontWeight: 400,
        fontFamily: 'roboto',
        fill: 'rgb(102, 102, 102)',
      }}
    >
      {payload.value}
    </Text>
  );
};
CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

const AmplitudePerFrequencyGraphBase = ({
  data,
  legendItems,
  isInPreview,
  dataMax,
  dataMin,
  unit,
  yAxisTickFormatter,
  channelNoise,
}) => {
  const sortedData = data.sort(
    (dataObject1, dataObject2) =>
      sortedFrequencies.indexOf(dataObject1.name) -
      sortedFrequencies.indexOf(dataObject2.name)
  );

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  const isChannelGraphNoisy =
    channelNoise && channelNoise.eyesOpened && channelNoise.eyesClosed;

  return (
    <div style={{ position: 'relative' }}>
      {isChannelGraphNoisy ? (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            position: isMatchingXSScreen ? 'inital' : 'absolute',
            right: 10,
            top: -70,
          }}
        >
          <WarningIcon />
          Noisy EEG signal detected and filtered out.
        </p>
      ) : null}
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={sortedData}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <XAxis
            dataKey="name"
            tickFormatter={tick => dataFormattedNames[tick] || tick}
            tick={<CustomizedTick />}
            interval={isMatchingXSScreen ? 5 : 0}
          />

          <YAxis
            domain={[dataMin, dataMax]}
            interval="preserveEnd"
            unit={unit}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip
            formatter={(value, name) => [
              setFixedDecimalsIfNeeded({ number: value }),
              dataFormattedNames[name] || name,
            ]}
            labelFormatter={label => dataFormattedNames[label] || label}
          />
          <Line dataKey="eyesClosed" stroke={colors.eyesClosed} unit={unit} />
          <Line dataKey="eyesOpened" stroke={colors.eyesOpened} unit={unit} />
          {sortedData && sortedData[0].underTask && (
            <Line dataKey="underTask" stroke={colors.underTask} unit={unit} />
          )}
        </LineChart>
      </ResponsiveContainer>

      <div className={styles.clickable_legend_items_container}>
        <ClickableLegendItems
          legendItems={legendItems}
          colors={colors}
          isInPreview={isInPreview}
          legendItemFormatter={legendItem =>
            dataFormattedNames[legendItem] || legendItem
          }
        />
      </div>
    </div>
  );
};

AmplitudePerFrequencyGraphBase.propTypes = exact({
  data: PropTypes.array.isRequired,
  legendItems: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
  isInPreview: PropTypes.bool.isRequired,
  dataMax: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  dataMin: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  unit: PropTypes.string.isRequired,
  yAxisTickFormatter: PropTypes.func.isRequired,
  channelNoise: PropTypes.object,
});

export const AmplitudePerFrequencyGraph = React.memo(
  AmplitudePerFrequencyGraphBase
);
AmplitudePerFrequencyGraph.displayName = 'AmplitudePerFrequencyGraph';

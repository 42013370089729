import React, { useState, useEffect } from 'react';
// import ReactDom
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  // Switch,
  // Select,
  // MenuItem,
  // DialogContentText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import placeHolder from '../../../../assets/add-photo.png';
import { LagoListItem } from '../LagoListItem/LagoListItem';
import styles from './LagoArticleLessonDialog.scss';

const CustomSelectInput = withStyles(theme => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    width: '179px',
    padding: '10px',

    '&:focus': {
      border: '1px solid transparent',
      borderRadius: 4,
    },
  },
}))(OutlinedInput);

const LagoArticleLessonDialogBase = ({
  open,
  onClose,
  articleData,
  setArticleEditDialogOpen,
  screensItemsData,
  onItemClicked,
  onEditItemClicked,
  onAddNewVariantClicked,
  editVariant,
  variantClicked,
  setKeyToDelete,
  setDisplayDeleteDialog,
  setArticleData,
  handleOnDragEnd,
  // setDisplayUploadedImages,
  isLoadingVideo,
  publishArticleClicked,
  parseDate,
  unpublishArticleClicked,
  updateLastEditArticle,
  setIsFromArticleEdit,
  selectCategoryClicked,
  categories,
}) => {
  const [toggleEditTitle, setToggleEditTitle] = useState(false);
  const [titleInput, setTitleInput] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  // const [toggleAddArticleDialog, setToggleAddArticleDialog] = useState(false);
  // const [newArticeTitle, setNewArticeTitle] = useState('');

  const [audience, setAudience] = useState(articleData?.audience ?? 'all');

  useEffect(() => {
    setAudience(articleData?.audience ?? 'all');
  }, [articleData]);

  const IMAGE_PATH_PREFIX = 'lago/educational/defaults/images/';

  const onImageFileChange = async event => {
    setIsLoadingImage(true);
    const path =
      IMAGE_PATH_PREFIX +
      Math.random()
        .toString(36)
        .substring(7);
    const [file] = event.target.files;
    // setImageFile(file);
    const storage = window.firebase.storage();
    const imageRef = storage.ref(path);
    try {
      await imageRef.put(file);
      imageRef
        .getDownloadURL()
        .then(res => {
          const copy = JSON.parse(JSON.stringify(articleData));
          copy.img = res;
          setArticleData(copy);
          setIsLoadingImage(false);
          updateLastEditArticle();
        })
        .catch(() => {
          setIsLoadingImage(false);
        });
    } catch (err) {
      // TODO
    }
  };

  const removeImage = () => {
    const copy = JSON.parse(JSON.stringify(articleData));
    copy.img = '';
    setArticleData(copy);
    const el = document.getElementById('image-input-article');
    el.value = '';
    updateLastEditArticle();
  };

  const saveNewTitle = () => {
    const copy = JSON.parse(JSON.stringify(articleData));
    copy.title.en = titleInput;
    setArticleData(copy);
    setToggleEditTitle(false);
    updateLastEditArticle();
  };

  const onChangerAudience = event => {
    setAudience(event.target.value);
    const copy = JSON.parse(JSON.stringify(articleData));
    copy.audience = event.target.value;
    // if (copy.audience) {
    //   copy.audience.en = event.target.value;
    // } else {
    //   copy.audience = { en: event.target.value };
    // }
    setArticleData(copy);
    updateLastEditArticle();
  };

  let sortedArticleScreens = [];
  if (articleData && articleData.screens) {
    sortedArticleScreens = Object.keys(articleData.screens).sort((a, b) => {
      return articleData.screens[a] - articleData.screens[b];
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={styles.lago_dialog_container}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {toggleEditTitle ? (
          <div>
            <TextField
              value={titleInput}
              onChange={e => {
                setTitleInput(e.target.value);
              }}
            />
            <Button onClick={saveNewTitle} disabled={!titleInput}>
              <SaveIcon />
            </Button>
            <Button
              onClick={() => {
                setToggleEditTitle(false);
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        ) : (
          <div>
            {articleData && articleData.title && articleData.title.en}
            <Button
              onClick={() => {
                setTitleInput(articleData.title.en);
                setToggleEditTitle(true);
              }}
            >
              <EditIcon />
            </Button>
          </div>
        )}
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={selectCategoryClicked}
              variant="contained"
              color="primary"
              style={{ width: 200, marginBottom: 5, marginTop: 10 }}
            >
              select category
            </Button>
            {articleData && articleData.categoryIds && (
              <div>
                <div>Selected categories:</div>
                <div>
                  {articleData.categoryIds.map(categoryId => {
                    return (
                      categories[categoryId] && (
                        <div>{categories[categoryId].name.en}</div>
                      )
                    );
                  })}
                </div>
              </div>
            )}
            <div className={styles.selectRoot}>
              <FormControl className={styles.formControl} variant="outlined">
                <InputLabel
                  htmlFor="audience-select"
                  className={styles.selectFormLabel}
                >
                  AUDIENCE
                </InputLabel>
                <Select
                  value={audience}
                  label="AUDIENCE"
                  onChange={onChangerAudience}
                  input={
                    <CustomSelectInput
                      name="audience"
                      id="audience-select"
                      labelWidth={0}
                    />
                  }
                >
                  <MenuItem value="all" className={styles.menuItem}>
                    All
                  </MenuItem>
                  <MenuItem value="MTR" className={styles.menuItem}>
                    MTR Only
                  </MenuItem>
                  <MenuItem value="clinicUsers" className={styles.menuItem}>
                    Clinic-Users Only
                  </MenuItem>
                  <MenuItem value="freeUsers" className={styles.menuItem}>
                    Free-Users Only
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginBottom: 5, width: 200, marginTop: 10 }}
              onClick={publishArticleClicked}
              // disabled={
              //   (articleData && !articleData.screens) ||
              //   (articleData && !Object.keys(articleData.screens).length)
              // }
              disabled={
                articleData &&
                (!articleData.categoryIds || !articleData.categoryIds.length)
              }
            >
              publish
            </Button>
            {articleData && articleData.lastPublishDate ? (
              <Button
                variant="contained"
                color="secondary"
                style={{ marginBottom: 5, width: 200, marginTop: 10 }}
                onClick={unpublishArticleClicked}
              >
                unpublish
              </Button>
            ) : null}
          </div>
          {articleData &&
          (!articleData.lastPublishDate ||
            articleData.lastPublishDate < articleData.lastEdited) ? (
            <div>You have unpublished changes</div>
          ) : null}
          {articleData && articleData.lastPublishDate ? (
            <div>Last published: {parseDate(articleData.lastPublishDate)}</div>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.journal_dialog_container}>
          <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId={screensItemsData}>
                {provided => (
                  <div
                    // className={styles.lago_control_list_container}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // style={{ width: '50%' }}
                  >
                    {articleData &&
                      articleData.screens &&
                      sortedArticleScreens.map((screenId, index) => {
                        return (
                          <Draggable
                            key={screenId}
                            draggableId={screenId}
                            index={
                              articleData &&
                              articleData.screens &&
                              articleData.screens[screenId]
                                ? articleData.screens[screenId]
                                : index
                            }
                          >
                            {provided1 => (
                              <div
                                ref={provided1.innerRef}
                                {...provided1.draggableProps}
                                {...provided1.dragHandleProps}
                              >
                                <LagoListItem
                                  key={screenId}
                                  element={screensItemsData[screenId]}
                                  isFromArticle
                                  onItemClicked={() => onItemClicked(screenId)}
                                  onEditItemClicked={onEditItemClicked}
                                  onAddNewVariantClicked={e => {
                                    onAddNewVariantClicked(e, screenId);
                                    setIsFromArticleEdit(true);
                                  }}
                                  editVariant={editVariant}
                                  variantClicked={variantClicked}
                                  onDeleteItemClicked={e => {
                                    e.stopPropagation();
                                    setKeyToDelete(screenId);
                                    setDisplayDeleteDialog(true);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isLoadingVideo ? <CircularProgress /> : null}
            <Button
              onClick={setArticleEditDialogOpen}
              disabled={isLoadingVideo}
            >
              add screen
            </Button>
          </div>

          <div className={styles.upload_image_container}>
            {isLoadingImage ? (
              <CircularProgress />
            ) : (
              <img
                className={styles.lago_control_img}
                src={
                  articleData && articleData.img ? articleData.img : placeHolder
                }
                alt="placeHolder"
              />
            )}
            <div className={styles.upload_image_btns}>
              <Button
                onClick={() => {
                  document.getElementById('image-input-article').click();
                }}
                color="primary"
                variant="contained"
                style={{ maxWidth: '120px' }}
                disabled={isLoadingVideo}
              >
                upload image
              </Button>
              {/* <Button
                onClick={() => {
                  setDisplayUploadedImages(true);
                }}
                color="primary"
                variant="contained"
                style={{ marginTop: '5px', maxWidth: '120px' }}
                disabled={isLoadingVideo}
              >
                select uploaded
              </Button> */}
              {articleData && articleData.img ? (
                <Button
                  onClick={() => {
                    removeImage();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: '5px' }}
                  disabled={isLoadingVideo}
                >
                  remove image
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <input
          accept="image/png, image/gif, image/jpeg"
          hidden
          id="image-input-article"
          type="file"
          onChange={onImageFileChange}
          style={{ display: 'none' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          size="large"
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          disabled={isLoadingVideo}
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoArticleLessonDialogBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  articleData: PropTypes.object,
  setArticleEditDialogOpen: PropTypes.func,
  screensItemsData: PropTypes.object,
  onItemClicked: PropTypes.func,
  onEditItemClicked: PropTypes.func,
  onAddNewVariantClicked: PropTypes.func,
  editVariant: PropTypes.func,
  variantClicked: PropTypes.func,
  setKeyToDelete: PropTypes.func,
  setDisplayDeleteDialog: PropTypes.func,
  setArticleData: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  // setDisplayUploadedImages: PropTypes.func,
  isLoadingVideo: PropTypes.bool,
  publishArticleClicked: PropTypes.func,
  parseDate: PropTypes.func,
  unpublishArticleClicked: PropTypes.func,
  updateLastEditArticle: PropTypes.func,
  setIsFromArticleEdit: PropTypes.func,
  selectCategoryClicked: PropTypes.func,
  categories: PropTypes.object,
});

export const LagoArticleLessonDialog = React.memo(LagoArticleLessonDialogBase);
LagoArticleLessonDialog.displayName = 'LagoArticleLessonDialog';

import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

const TooltipWrapperBase = props => {
  const propsIfDisabled = props.disabled
    ? {
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
      }
    : {};
  return (
    <Tooltip
      enterTouchDelay={100}
      leaveTouchDelay={6000}
      {...propsIfDisabled}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

TooltipWrapperBase.defaultProps = {
  disabled: false,
};

TooltipWrapperBase.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export const TooltipWrapper = React.memo(TooltipWrapperBase);
TooltipWrapper.displayName = 'TooltipWrapper';

/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
// notes:
// 1-if staggerChildren is true in a parent(sequence) means =>
// exit and enter delay in words array and the words array wont be used and timing will be handled by framer-motion
import styles from './CustomerStoriesPagesStyles.scss';
import { formatNumberWithCommas } from '../../../utils/utils';

export const zoneStorySequences = zone_seconds => {
  // const reverseDelay = (idx, n) => n * 2 - 2 - idx * 2;

  const textAnimationDuration = 200;
  // const backgroundAnimationDelay = 1200;
  // const backgroundAnimationDuration = 1800; // this numbers is not correct but it works actual number is ≈ 1500

  const seq1WordsLength = 'You made'.split(' ').length;
  const seq2WordsLength = 'every session count'.split(' ').length;
  //   const seq2WordsLength = 'You trained your brain for'.split(' ').length;
  const seq3WordsLength = 'Your brainwaves'.split(' ').length;
  const seq4WordsLength = 'hit the zone'.split(' ').length;
  const seq5WordsLength = zone_seconds.toString().split('').length;
  // const seq6WordsLength = `${zone_seconds > 1 ? 'times' : 'time'}`.split('')
  //   .length;
  //   const seq5WordsLength = `That’s top ${zone_seconds}% worldwide`.split(' ')
  //     .length;

  let seq1Variants;
  let seq2Variants;
  seq1Variants = seq2Variants = {
    initial: { opacity: 0, y: -20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        // duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      y: 20,
      transition: {
        // duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
  };

  let seq3Variants;
  let seq4Variants;
  seq3Variants = seq4Variants = {
    initial: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: textAnimationDuration / 1000,
        ease: 'easeInOut',
      },
    },
    // exit: {},
  };

  // const seq5Variants = {
  //   initial: { opacity: 0 },
  //   enter: {
  //     opacity: 1,
  //     transition: {
  //       duration: textAnimationDuration / 1000,
  //       ease: 'easeInOut',
  //     },
  //   },
  // };

  const seq1EnterTime = seq1WordsLength * textAnimationDuration;
  // const seq1Words = 'Let’s explore what you did'.split(' ').map((word, idx) => {
  //   return {
  //     id: `seq1-word${idx + 1}`,
  //     text: word,
  //     enterDelay: textAnimationDuration * (idx + 1),
  //     exitDelay:
  //       seq1EnterTime +
  //       (2 / 3) * backgroundAnimationDuration +
  //       textAnimationDuration * reverseDelay(idx, seq1WordsLength) -
  //       14 * textAnimationDuration, // this textAnimationDuration is subtracted because backgroundAnimationDuration starts with the last word of seq2 so it covers the time
  //     variants: seq1Variants,
  //     style: {
  //       margin: '7px 5px',
  //     },
  //   };
  // });
  const seq1ExitTime = seq1WordsLength * textAnimationDuration;
  const seq1TotalTime = seq1EnterTime + seq1ExitTime;

  const seq2EnterTime = seq2WordsLength * textAnimationDuration;
  // const seq2Words = 'You trained your brain for'.split(' ').map((word, idx) => {
  //   return {
  //     id: `seq2-word${idx + 1}`,
  //     text: word,
  //     enterDelay: seq1EnterTime + textAnimationDuration * (idx + 1),
  //     exitDelay:
  //       seq1EnterTime +
  //       // seq2EnterTime + // this was removed because the order of the setTimeout is Scheduled after render each render
  //       (2 / 3) * backgroundAnimationDuration +
  //       textAnimationDuration * reverseDelay(idx, seq2WordsLength) -
  //       3 * textAnimationDuration, // see seq1 for this subtraction, multiplied to match seq1 words because if timeout order
  //     variants: seq2Variants,
  //     style: {
  //       margin: '5px',
  //     },
  //   };
  // });
  const seq2ExitTime = seq2WordsLength * textAnimationDuration;
  const seq2TotalTime = seq2EnterTime + seq2ExitTime;

  const seq3EnterTime = seq3WordsLength * textAnimationDuration;
  // const seq3ExitTime = seq3WordsLength * textAnimationDuration;
  // const seq3TotalTime = seq3EnterTime + seq3ExitTime;

  const seq4EnterTime = seq4WordsLength * textAnimationDuration;
  // const seq4ExitTime = seq4WordsLength * textAnimationDuration;
  // const seq4TotalTime = seq4EnterTime + seq4ExitTime;

  const seq5EnterTime = seq5WordsLength * textAnimationDuration;
  // const seq5Words = `That’s top ${zone_seconds}% worldwide`
  //   .split(' ')
  //   .map((word, idx) => {
  //     return {
  //       id: `seq5-word${idx + 1}`,
  //       text: word,
  //       enterDelay:
  //         seq1TotalTime +
  //         seq2EnterTime +
  //         seq3EnterTime +
  //         // seq4EnterTime +
  //         backgroundAnimationDuration +
  //         textAnimationDuration * (idx + 1) -
  //         13.5 * textAnimationDuration,
  //       // exitDelay: 0,
  //       variants: seq5Variants,
  //       style: {
  //         margin: '7px 5px',
  //         color: idx === 2 ? '#F8DE7E' : 'white',
  //       },
  //     };
  //   });
  // const seq5ExitTime = 0;
  // const seq5TotalTime = seq5EnterTime + seq5ExitTime;

  const seq1 = {
    id: 'seq1',
    // words: seq1Words,
    sentence: 'You made',
    // wordsClassName: '',
    wordsStyles: { margin: '7px 5px' },
    wordsVariants: seq1Variants,
    enterDelay: 0,
    exitDelay: seq1EnterTime + seq2EnterTime + 6 * textAnimationDuration,
    // style: {},
    className: styles.zone_seq_1,
    variants: {
      initial: { opacity: 1, display: 'flex' }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
          when: 'beforeChildren',
        },
      }, // No transition for entry, just stays visible
      exit: {
        opacity: 0,
        y: 20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
          staggerDirection: -1,
          when: 'afterChildren',
        },
      }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq2 = {
    id: 'seq2',
    // words: seq2Words,
    sentence: 'every session count',
    wordsClassName: '',
    wordsStyles: { margin: '5px' },
    wordsVariants: seq2Variants,
    enterDelay: seq1EnterTime,
    exitDelay: seq1EnterTime + seq2EnterTime + 3 * textAnimationDuration,
    style: {},
    className: styles.zone_seq_2,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
        },
      }, // No transition for entry, just stays visible
      exit: {
        opacity: 0,
        y: 20,
        display: 'none',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
          staggerDirection: -1,
          when: 'afterChildren',
        },
      }, // Fades out on exit (if you want to fade out)},
      // }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq3 = {
    id: 'seq3',
    // words: seq3Words,
    sentence: 'Your brainwaves',
    wordsClassName: '',
    wordsStyles: { margin: '5px' },
    wordsVariants: seq3Variants,
    enterDelay: seq1TotalTime + seq2TotalTime + 3.5 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.zone_seq_3,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
        },
      },
      exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq4 = {
    id: 'seq4',
    // words: seq4Words,
    sentence: 'hit the zone',
    wordsClassName: '',
    wordsStyles: { margin: '5px' },
    wordsVariants: seq4Variants,
    enterDelay: seq1TotalTime + seq2TotalTime + 3.5 * textAnimationDuration,
    // exitDelay: 0,
    style: {},
    className: styles.zone_seq_4,
    variants: {
      initial: {
        opacity: 0,
        display: 'none',
      }, // Starts visible with no change
      enter: {
        opacity: 1,
        display: 'flex',
        transition: {
          // duration: textAnimationDuration / 1000,
          ease: 'easeInOut',
          staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
        },
      }, // No transition for entry, just stays visible
      //   exit: {},
    },
    extraAnimations: [],
    staggerChildren: true,
  };

  const seq5 = {
    id: 'seq5',
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      seq4EnterTime +
      2 * textAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq5-word${1}`,
        text: formatNumberWithCommas(zone_seconds).toString(),
        enterDelay:
          seq1TotalTime +
          seq2TotalTime +
          seq3EnterTime +
          seq4EnterTime +
          2 * textAnimationDuration,
        // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            y: 20,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
            },
          },
          // exit: {},
        },
        // style: { display: 'flex' },
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            y: 20,
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        // lettersClassname: '',
        // lettersStyle: { margin: '5px' },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.zone_seq_5,
    variants: {
      initial: { opacity: 0, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  const seq6 = {
    id: 'seq6',
    enterDelay:
      seq1TotalTime +
      seq2TotalTime +
      seq3EnterTime +
      seq4EnterTime +
      seq5EnterTime +
      2 * textAnimationDuration,
    // exitDelay: 0,
    words: [
      {
        id: `seq6-word${1}`,
        text: `${zone_seconds > 1 ? 'times' : 'time'}`,
        enterDelay:
          seq1TotalTime +
          seq2TotalTime +
          seq3EnterTime +
          seq4EnterTime +
          seq5EnterTime +
          2 * textAnimationDuration,

        //   13.5 * textAnimationDuration,
        // // exitDelay: 0,
        variants: {
          initial: {
            opacity: 0,
            x: 0,
            //   transition: { duration: textAnimationDuration / 1000, ease: 'easeInOut' },
          },
          enter: {
            opacity: 1,
            y: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
              staggerChildren: 0.2, // Stagger by 0.1 seconds between each child
              // staggerDirection: -1,
              when: 'beforeChildren',
            },
          },
          // exit: {},
        },
        // style: { display: 'flex', justifyContent: 'center' },
        letterByLetter: true,
        lettersVariants: {
          initial: {
            opacity: 0,
            x: 20,
          },
          enter: {
            opacity: 1,
            x: 0,
            transition: {
              // duration: textAnimationDuration / 1000,
              ease: 'easeInOut',
            },
          },
          // exit: {},
        },
        lettersStyle: { marginRight: '5px' },
      },
    ],
    style: {},
    className: styles.zone_seq_6,
    variants: {
      initial: { opacity: 1, display: 'none' }, // Starts visible with no change
      enter: { opacity: 1, display: 'flex' }, // No transition for entry, just stays visible
      exit: { opacity: 0, display: 'none' }, // Fades out on exit (if you want to fade out)
    },
    extraAnimations: [],
  };

  return [seq1, seq2, seq3, seq4, seq5, seq6];
};

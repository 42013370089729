import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { Button, IconButton, Paper } from '@material-ui/core';
import styles from './DeployedPrograms.scss';
import { CreateProgramPopUp } from '../CreateProgramPopUp/CreateProgramPopUp';
import { DeployedProgramCard } from '../DeployedProgramCard/DeployedProgramCard';
import { NoDeployedProgramCard } from '../UserPage/SessionsTab/NoDeployedProgramCard/NoDeployedProgramCard';

const userPageLogger = new MyEventWrapper('user_page');

const DeployedProgramsBase = ({
  programs,
  customerId,
  shouldShowGoToUserProfileLink,
  autoDeployableConfigs,
  setAutoDeployableConfigs,
  isExpired,
}) => {
  const [createProgramPopUpOpener, setCreateProgramPopUpOpener] = useState(
    null
  );

  const [currentSelectedProgram, setCurrentSelectedProgram] = useState(0);

  const closeCreateProgramPopUpModal = useCallback(() => {
    userPageLogger.log('on_close_create_program_pop_modal');
    setCreateProgramPopUpOpener(false);
  }, [setCreateProgramPopUpOpener]);

  const onCreateProgram = useCallback(() => {
    userPageLogger.log('on_create_program');
    setCreateProgramPopUpOpener({ isEditingMode: false });
  }, [setCreateProgramPopUpOpener]);

  const onEditPrograms = useCallback(() => {
    setCreateProgramPopUpOpener({ isEditingMode: false });
  }, []);

  const [
    isCreateProgramPopUpInAdvancedMode,
    setIsCreateProgramPopUpInAdvancedMode,
  ] = useState(sessionStorage.isSimpleDashboard !== 'true');

  const onProgramSettingsAdvancedModeChange = event => {
    const mode = event.target.checked;

    setIsCreateProgramPopUpInAdvancedMode(mode);
    sessionStorage.isSimpleDashboard = !mode;
    database
      .ref('clinics')
      .child(sessionStorage.userId)
      .child('dashboardType')
      .set(mode ? 'advanced' : 'basic');

    const eventName = mode
      ? 'advanced_mode_on_clicked'
      : 'basic_mode_on_clicked';
    new MyEvent(eventName).log();
  };

  const onMoveCarouselForward = useCallback(() => {
    const nextIndex = (currentSelectedProgram + 1) % programs.length;
    setCurrentSelectedProgram(nextIndex);
  }, [currentSelectedProgram, programs]);

  const onMoveCarouselBackward = useCallback(() => {
    const nextIndex = (currentSelectedProgram - 1) % programs.length;
    const safeIndex = nextIndex < 0 ? programs.length - 1 : nextIndex;
    setCurrentSelectedProgram(safeIndex);
  }, [currentSelectedProgram, programs]);

  const renderCarouselControl = () => {
    if (autoDeployableConfigs) {
      return (
        <div className={styles.controls_container}>
          <Button onClick={onEditPrograms}>
            <span className={styles.btn}>
              <EditIcon style={{ color: '#2196f3' }} />
            </span>
          </Button>
          <div className={styles.controls}>
            <IconButton onClick={onMoveCarouselBackward}>
              <ArrowBackIcon style={{ fontSize: 14 }} />
            </IconButton>
            {Math.min(currentSelectedProgram + 1, programs.length)} /{' '}
            {programs.length}
            <IconButton onClick={onMoveCarouselForward}>
              <ArrowForwardIcon style={{ fontSize: 14 }} />
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.controls_container}>
        <Button onClick={onEditPrograms}>
          <span className={styles.btn}>EDIT</span>
        </Button>
        <div className={styles.controls}>
          <IconButton onClick={onMoveCarouselBackward}>
            <ArrowBackIcon style={{ fontSize: 14 }} />
          </IconButton>
          {Math.min(currentSelectedProgram + 1, programs.length)} /{' '}
          {programs.length}
          <IconButton onClick={onMoveCarouselForward}>
            <ArrowForwardIcon style={{ fontSize: 14 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <>
      {programs.length > 0 ? (
        <Paper style={autoDeployableConfigs ? { maxWidth: 730 } : null}>
          <div
            className={styles.deployed_programs}
            style={autoDeployableConfigs ? { padding: 0 } : null}
          >
            <div className={styles.carousel_header}>
              {autoDeployableConfigs ? null : (
                <p>
                  Deployed Programs
                  {isExpired ? (
                    <span
                      style={{ color: 'red', fontSize: 12, fontWeight: 400 }}
                    >
                      <span
                        style={{
                          position: 'relative',
                          top: -2,
                          marginRight: 5,
                          marginLeft: 5,
                          fontSize: '0.5em',
                        }}
                      >
                        ⬤
                      </span>
                      The client lost access to programs on {isExpired}.
                    </span>
                  ) : null}
                </p>
              )}
              {shouldShowGoToUserProfileLink && (
                <a
                  href={`userPage.html?customerId=${customerId}`}
                  className={styles.go_to_profile_link}
                >
                  <span> Go to profile</span>
                </a>
              )}
            </div>
            <div className={styles.carousel_container}>
              <div className={styles.carousel}>
                <Carousel
                  showIndicators={false}
                  showArrows={false}
                  showStatus={false}
                  selectedItem={Math.min(
                    currentSelectedProgram,
                    programs.length - 1
                  )}
                  // onSwipeEnd={(e, b) => console.log(e, b)}
                  // emulateTouch
                  showThumbs={false}
                >
                  {programs.map(program => (
                    <DeployedProgramCard
                      deployedProgram={program}
                      key={program.id}
                      renderCarouselControl={renderCarouselControl}
                      autoDeployableConfigs={autoDeployableConfigs}
                      isExpired={isExpired}
                    />
                  ))}
                </Carousel>
              </div>
              {/* <div className={styles.divider} /> */}
            </div>
          </div>
        </Paper>
      ) : (
        <NoDeployedProgramCard onCreateProgram={onCreateProgram} />
      )}

      <CreateProgramPopUp
        // eslint-disable-next-line no-undef
        protocols={protocolsObjectToArrWithNamesInjected(protocolsConfig)}
        database={database}
        // eslint-disable-next-line no-undef
        modifyProtocol={modifyProtocol}
        // eslint-disable-next-line no-undef
        getOverAllSuccessRate={getOverAllSuccessRate}
        // eslint-disable-next-line no-undef
        searchProtocol={searchProtocol}
        // eslint-disable-next-line no-undef
        PatientProtocolDetails={ProtocolDetails}
        getRecommendedPlacementNameBasedOnPlacement={
          // eslint-disable-next-line no-undef
          getRecommendedPlacementNameBasedOnPlacement
        }
        // eslint-disable-next-line no-undef
        getChosenPlacement={getChosenPlacement}
        // eslint-disable-next-line no-undef
        getChoosenPlacementDescriptionHTML={getChoosenPlacementDescriptionHTML}
        // eslint-disable-next-line no-undef
        placements={placements}
        extractFrequencyName={(min, max) =>
          extractFrequency(min, max, frequenciesConfig)
        }
        clinicId={sessionStorage.userId}
        patientId={autoDeployableConfigs ? '' : customerId}
        isModalOpen={!!createProgramPopUpOpener}
        closeModal={closeCreateProgramPopUpModal}
        isEditingMode={
          createProgramPopUpOpener && createProgramPopUpOpener.isEditingMode
        }
        isProgramSettingsAdvancedMode={isCreateProgramPopUpInAdvancedMode}
        onProgramSettingsAdvancedModeChange={
          onProgramSettingsAdvancedModeChange
        }
        autoDeployableConfigs={autoDeployableConfigs}
        setAutoDeployableConfigs={setAutoDeployableConfigs}
      />
    </>
  );
};

DeployedProgramsBase.defaultProps = {
  customerId: sessionStorage.customerId,
  shouldShowGoToUserProfileLink: false,
};

DeployedProgramsBase.propTypes = exact({
  programs: PropTypes.array.isRequired,
  customerId: PropTypes.string,
  shouldShowGoToUserProfileLink: PropTypes.bool,
  autoDeployableConfigs: PropTypes.object,
  setAutoDeployableConfigs: PropTypes.func,
  isExpired: PropTypes.string,
});

export const DeployedPrograms = React.memo(DeployedProgramsBase);
DeployedPrograms.displayName = 'DeployedPrograms';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CustomDialog } from '../../../../Core/Components/CustomDialog/CustomDialog';
import styles from './SwitchToNewSymptomTrackerDialog.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

const SwitchToNewSymptomTrackerDialogBase = ({
  isOpen,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onClose,
}) => {
  const t = useTranslation();
  return (
    <CustomDialog
      primaryBtnContent={t('proceed-sure')}
      secondaryBtnContent={t('keep-using-old-version')}
      {...{
        isOpen,
        onPrimaryBtnClick,
        onSecondaryBtnClick,
        onClose,
      }}
    >
      <div>
        <p className={styles.dialog_header}>
          {t('switch-to-new-symptom-questionnaires')}
        </p>
        <p>{t('switch-to-new-symptom-questionnaires-description')}</p>
      </div>
    </CustomDialog>
  );
};

SwitchToNewSymptomTrackerDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
});

export const SwitchToNewSymptomTrackerDialog = React.memo(
  SwitchToNewSymptomTrackerDialogBase
);
SwitchToNewSymptomTrackerDialog.displayName = 'SwitchToNewSymptomTrackerDialog';

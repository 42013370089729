import React, { useRef, useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { doesStrContainAnyFirebaseForbiddenChar } from '../../utils/utils';
import { FrequencySelectionCard } from '../FrequencySelectionCard/FrequencySelectionCard';
import styles from './CreateNewProtocolPage.scss';
import { SaveProtocolWidget } from '../SaveProtocolWidget/SaveProtocolWidget';

const CreateNewProtocolPageBase = () => {
  const checkedBoxesRef = useRef(null);
  const protocolNameRef = useRef(null);
  const protocolDescriptionRef = useRef(null);

  const inhibitCheckboxesRef = useRef(null);
  const enhanceCheckboxesRef = useRef(null);

  const [frequenciesConfig, setFrequenciesConfig] = useState(null);
  const [receivedFrequenciesMap, setReceivedFrequenciesMap] = useState(null);

  const [inhibitCheckboxes, setInhibitCheckboxes] = useState(null);
  const [enhanceCheckboxes, setEnhanceCheckboxes] = useState(null);

  const [checkedBoxes, setCheckedBoxes] = useState({
    inhibit: [],
    enhance: [],
  });

  const [protocolName, setProtocolName] = useState('');
  const [protocolDescription, setProtocolDescription] = useState('');

  const filterFrequencies = freqConfig => {
    // for (const freq in frequenciesConfig) {
    //   if (
    //     frequenciesConfig[freq].hasOwnProperty('show') &&
    //     frequenciesConfig[freq]['show'] === false
    //   ) {
    //     delete frequenciesConfig[freq];
    //   }
    // }
    // return frequenciesConfig;
    const filteredFrequencies = Object.assign(freqConfig);
    // Use Object.entries to iterate over key-value pairs
    Object.entries(filteredFrequencies).forEach(([key, value]) => {
      // Check if 'show' property exists and is false
      if (Object.hasOwn(value, 'show') && value.show === false) {
        delete filteredFrequencies[key]; // Remove entries where 'show' is false
      }
    });

    return filteredFrequencies;
  };

  // to-do: when navigate between create protocol and modify protocol save states
  // this is the initial code:
  // useEffect(() => {
  //   const checkedFreqData = sessionStorage.getItem('prevStatesData');
  //   if (checkedFreqData) {
  //     const parsedData = JSON.parse(checkedFreqData);
  //     const prevCheckedStateState = parsedData.prevCheckedStateState;
  //     const prevEnhanceCheckboxesState = parsedData.prevEnhanceCheckboxesState;
  //     const prevInhibitCheckboxesState = parsedData.prevInhibitCheckboxesState;
  //     const prevProtocolNameState = parsedData.prevProtocolNameState;
  //     setInhibitCheckboxes(prevInhibitCheckboxesState);
  //     setEnhanceCheckboxes(prevEnhanceCheckboxesState);
  //     setCheckedBoxes(prevCheckedStateState);
  //     setProtocolName(prevProtocolNameState);
  //   }
  // }, []);

  useEffect(() => {
    checkedBoxesRef.current = checkedBoxes;
    protocolNameRef.current = protocolName;
    protocolDescriptionRef.current = protocolDescription;
    inhibitCheckboxesRef.current = inhibitCheckboxes;
    enhanceCheckboxesRef.current = enhanceCheckboxes;
  }, [
    checkedBoxes,
    protocolName,
    protocolDescription,
    inhibitCheckboxes,
    enhanceCheckboxes,
  ]);

  useEffect(() => {
    // eslint-disable-next-line
    loadFrequencies(function(frequenciesConfig) {
      const prevProtocolName = protocolNameRef.current;
      const prevProtocolDescription = protocolDescriptionRef.current;
      const prevCheckedState = checkedBoxesRef.current;
      const prevInhibitCheckboxes = inhibitCheckboxesRef.current;
      const prevEnhanceCheckboxes = enhanceCheckboxesRef.current;

      setFrequenciesConfig(frequenciesConfig);
      setReceivedFrequenciesMap(filterFrequencies(frequenciesConfig));
      const initialInhibitState = {};
      const initialEnhanceState = {};

      Object.keys(frequenciesConfig).forEach(freq => {
        initialInhibitState[`inhibit_${freq}`] = {
          checked: false,
          disabled: false,
        };
        initialEnhanceState[`enhance_${freq}`] = {
          checked: false,
          disabled: false,
        };
      });

      if (
        prevCheckedState.inhibit.length > 0 ||
        prevCheckedState.enhance.length > 0
      ) {
        setInhibitCheckboxes(prevInhibitCheckboxes);
        setEnhanceCheckboxes(prevEnhanceCheckboxes);
      } else {
        setInhibitCheckboxes(initialInhibitState);
        setEnhanceCheckboxes(initialEnhanceState);
      }

      setCheckedBoxes(prevCheckedState);
      setProtocolName(prevProtocolName);
      setProtocolDescription(prevProtocolDescription);

      // eslint-disable-next-line
      hideLoading();
    }, true);
  }, [loadFrequencies]);

  const convertToFrequencyName = (frequenciesMap, name) => {
    const freqName = name;
    const freqLow = frequenciesMap[name].minFrequency;
    const freqHigh = frequenciesMap[name].maxFrequency;
    return `${freqName} (${freqLow}-${freqHigh}hz)`;
    // freqName + ' (' + freqLow + '-' + freqHigh + 'hz)';
  };

  const updateCheckBoxState = (checkboxId, parentId, freq) => {
    if (parentId === 'inhibit') {
      const disableCheckboxId = `enhance_${freq}`;
      setInhibitCheckboxes(prevState => ({
        ...prevState,
        [checkboxId]: {
          ...prevState[checkboxId],
          checked: prevState[checkboxId]
            ? !prevState[checkboxId].checked
            : true, // toggle checked or default to true
          disabled: prevState[checkboxId]?.disabled || false, // retain current or default to false
        },
      }));

      setEnhanceCheckboxes(prevState => ({
        ...prevState,
        [disableCheckboxId]: {
          ...prevState[disableCheckboxId],
          checked: prevState[disableCheckboxId]?.checked || false, // retain current or default to false
          disabled: prevState[disableCheckboxId]
            ? !prevState[disableCheckboxId].disabled
            : true, // toggle disabled or default to true
        },
      }));
    } else if (parentId === 'enhance') {
      const disableCheckboxId = `inhibit_${freq}`;
      setEnhanceCheckboxes(prevState => ({
        ...prevState,
        [checkboxId]: {
          ...prevState[checkboxId],
          checked: prevState[checkboxId]
            ? !prevState[checkboxId].checked
            : true, // toggle checked or default to true
          disabled: prevState[checkboxId]?.disabled || false, // retain current or default to false
        },
      }));

      setInhibitCheckboxes(prevState => ({
        ...prevState,
        [disableCheckboxId]: {
          ...prevState[disableCheckboxId],
          checked: prevState[disableCheckboxId]?.checked || false, // retain current or default to false
          disabled: prevState[disableCheckboxId]
            ? !prevState[disableCheckboxId].disabled
            : true, // toggle disabled or default to true
        },
      }));
    }
  };

  const updateCheckedBoxes = (parentId, freq) => {
    setCheckedBoxes(prevCheckedBoxes => {
      const currentArray = prevCheckedBoxes[parentId] || [];
      const updatedParentArray = currentArray.includes(freq)
        ? currentArray.filter(item => item !== freq)
        : [freq, ...currentArray]; // Add element at the top to indicate action to the user (improves UX)

      return {
        ...prevCheckedBoxes,
        [parentId]: updatedParentArray,
      };
    });
  };

  const generateProtocolName = () => {
    const getFreqNameFromMapByRange = (itemName, item) => {
      const entries = Object.entries(receivedFrequenciesMap);
      // eslint-disable-next-line
      const result = entries.find(([freqName, freqObject]) => {
        const { maxFrequency, minFrequency } = freqObject;
        return (
          freqName === itemName &&
          maxFrequency === item.maxFrequency &&
          minFrequency === item.minFrequency
        );
      });

      return result ? result[0] : null;
    };

    const getFreqNamesSeparatedWithCommas = arr => {
      // return arr.map(getFreqNameFromMapByRange).join(', ');
      return arr
        .map(item => getFreqNameFromMapByRange(item, frequenciesConfig[item]))
        .join(', ');
    };

    const inhibitFrequencies = getFreqNamesSeparatedWithCommas(
      // copy the array and reverse it show protocol name by selection order
      checkedBoxesRef.current.inhibit.slice().reverse()
    );

    const enhanceFrequencies = getFreqNamesSeparatedWithCommas(
      // copy the array and reverse it show protocol name by selection order
      checkedBoxesRef.current.enhance.slice().reverse()
    );

    const inhibit = inhibitFrequencies ? `Inhibit ${inhibitFrequencies}` : '';
    const reward = enhanceFrequencies
      ? `${inhibit ? ' | ' : ''}Enhance ${enhanceFrequencies}`
      : '';

    return inhibit + reward;
  };

  const addOrRemoveFrequency = (checkboxId, parentId, freq) => {
    updateCheckBoxState(checkboxId, parentId, freq);
    updateCheckedBoxes(parentId, freq);

    setTimeout(() => {
      const newProtocolName = generateProtocolName();
      setProtocolName(newProtocolName);
    }, 0);
  };

  const onSaveProtocolClicked = () => {
    const trimmedProtocolName = protocolName.trim();
    const trimmedProtocolDescription = protocolDescription.trim();

    const isSameFrequencyItem = (freq1, freq2) => {
      return (
        freq1.minFrequency === freq2.minFrequency &&
        freq1.maxFrequency === freq2.maxFrequency
      );
    };

    const isSameProtocol = (firstProtocol, secondProtocol) => {
      const {
        inhibitingFrequencies: firstProtocolInhibitingFrequencies,
        rewardFrequencies: firstProtocolRewardFrequencies,
      } = firstProtocol;
      const {
        inhibitingFrequencies: secondProtocolInhibitingFrequencies,
        rewardFrequencies: secondProtocolRewardFrequencies,
      } = secondProtocol;

      const isSameInhibitingFrequencies =
        firstProtocolInhibitingFrequencies.length ===
          secondProtocolInhibitingFrequencies.length &&
        firstProtocolInhibitingFrequencies.every((freq, index) =>
          isSameFrequencyItem(freq, secondProtocolInhibitingFrequencies[index])
        );

      const isSameRewardingFrequencies =
        firstProtocolRewardFrequencies.length ===
          secondProtocolRewardFrequencies.length &&
        firstProtocolRewardFrequencies.every((freq, index) =>
          isSameFrequencyItem(freq, secondProtocolRewardFrequencies[index])
        );

      return isSameInhibitingFrequencies && isSameRewardingFrequencies;
    };

    // eslint-disable-next-line
    loadProtocols(protocols => {
      if (Object.keys(protocols).indexOf(trimmedProtocolName) > -1) {
        showNotification(
          'danger',
          'There is already a protocol with this name'
        );
        return;
      }

      if (doesStrContainAnyFirebaseForbiddenChar(trimmedProtocolName)) {
        showNotification(
          'danger',
          'Protocol name cannot contain any of the following characters (. $ # [ ] / \\)'
        );
        return;
      }

      // if (!trimmedProtocolName.startsWith('Custom')) {
      //   showNotification(
      //     'danger',
      //     'Protocol name must start with the word Custom, please add your name after it'
      //   );
      //   return;
      // }

      if (trimmedProtocolName === '') {
        showNotification('danger', 'Protocol name cannot be empty');
        return;
      }
      if (
        checkedBoxesRef.current.enhance.length === 0 &&
        checkedBoxesRef.current.inhibit.length === 0
      ) {
        showNotification(
          'danger',
          'You must have at least one frequency selected'
        );
        return;
      }

      // const inhibitingCheckedValues = inhibitingChecked.map(obj => {
      //   const [, value] = Object.entries(obj)[0];
      //   return value;
      // });

      // const rewardCheckedValues = rewardChecked.map(obj => {
      //   const [, value] = Object.entries(obj)[0];
      //   return value;
      // });

      const inhibitingCheckedValues = checkedBoxesRef.current.inhibit
        .slice()
        .reverse()
        .map(freq => frequenciesConfig[freq]);
      const rewardCheckedValues = checkedBoxesRef.current.enhance
        .slice()
        .reverse()
        .map(freq => frequenciesConfig[freq]);

      const protocolToBeSaved = {
        inhibitingFrequencies: inhibitingCheckedValues,
        rewardFrequencies: rewardCheckedValues,
        name: trimmedProtocolName,
        description:
          trimmedProtocolDescription === '' ? null : trimmedProtocolDescription,
      };

      const anotherEqualProtocol = Object.values(protocols)
        .filter(protocol => !protocol.archived)
        .find(protocol => isSameProtocol(protocol, protocolToBeSaved));

      if (anotherEqualProtocol) {
        showNotification(
          'danger',
          `You have another protocol (${anotherEqualProtocol.trimmedProtocolName}) with same frequencies`
        );
        return;
      }

      // sessionStorage.isCreateProtocol = true;
      // sessionStorage.protocolToBeSaved = JSON.stringify(protocolToBeSaved);
      sessionStorage.setItem('isCreateProtocol', 'true');
      sessionStorage.setItem(
        'protocolToBeSaved',
        JSON.stringify(protocolToBeSaved)
      );
      sessionStorage.setItem('checkedBoxes', checkedBoxesRef.current);
      sessionStorage.setItem('checkedBoxes', checkedBoxesRef.current);
      sessionStorage.setItem(
        'prevStatesData',
        JSON.stringify({
          prevProtocolNameState: protocolNameRef.current,
          prevProtocolDescription: protocolDescriptionRef.current,
          prevCheckedStateState: checkedBoxesRef.current,
          prevInhibitCheckboxesState: inhibitCheckboxesRef.current,
          prevEnhanceCheckboxesState: enhanceCheckboxesRef.current,
        })
      );
      // eslint-disable-next-line
      goToModifyProtocolWeightsPage(trimmedProtocolName);
    });
  };

  const freqCardProps = {
    convertToFrequencyName,
    receivedFrequenciesMap,
    frequenciesConfig,
    addOrRemoveFrequency,
    checkedBoxes,
  };

  return (
    <div className={styles.page_container}>
      <div className={styles.freq_cards_container}>
        <div className={styles.freq_card}>
          <FrequencySelectionCard
            ids={{ parentId: 'inhibit', parentTitle: 'Inhibit' }}
            backgroundColor="#f18d9d"
            freqCardProps={freqCardProps}
            checkboxStates={inhibitCheckboxes}
          />
        </div>
        <div className={styles.freq_card}>
          <FrequencySelectionCard
            ids={{ parentId: 'enhance', parentTitle: 'Enhance' }}
            backgroundColor="#009688"
            freqCardProps={freqCardProps}
            checkboxStates={enhanceCheckboxes}
          />
        </div>
      </div>
      <div>
        <SaveProtocolWidget
          protocolName={protocolName}
          setProtocolName={setProtocolName}
          protocolDescription={protocolDescription}
          setProtocolDescription={setProtocolDescription}
          onSaveProtocolClicked={onSaveProtocolClicked}
        />
      </div>
    </div>
  );
};

CreateNewProtocolPageBase.propTypes = exact({});

export const CreateNewProtocolPage = withEntryComponent(
  React.memo(CreateNewProtocolPageBase)
);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper } from '@material-ui/core';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import {
  FIREBASE_FILTERING_FUNCTIONS,
  FIREBASE_ORDER_BY_FUNCTIONS,
} from '../../models/firebase/firebase';
import { FeedbackBox } from '../FeedbackBox/FeedbackBox';
import { isDefined } from '../../utils/utils';

const initialValue = Symbol('iv');
const FeedbackBoxInSessionPageBase = ({
  sessionNumber,
  customerId,
  sessionId,
}) => {
  const [sessionFeedback] = useFirebaseDB({
    path: `sessionFeedback/patientAnswers/${customerId}`,
    orderByType: FIREBASE_ORDER_BY_FUNCTIONS.ORDER_BY_CHILD,
    orderByArguments: useRef(['sessionNumber']).current,
    filterType: FIREBASE_FILTERING_FUNCTIONS.EQUAL_TO,
    filterTypeArguments: useRef([sessionNumber]).current,
    valueProcessor: useRef(val => (val === null ? null : Object.values(val)[0]))
      .current,
    initialValue,
  });

  const [sessionMentalTags] = useFirebaseDB({
    path: `sessionMentalTags/patientTags/${customerId}`,
    initialValue,
  });

  const [feedbackTags] = useFirebaseDB({
    path: 'sessionFeedback/views/allAnswers',
    initialValue,
  });

  const isReadyToRender =
    sessionFeedback !== initialValue &&
    feedbackTags !== initialValue &&
    sessionMentalTags !== initialValue;

  if (!sessionFeedback && !sessionMentalTags[sessionId]) return null;

  return (
    <Paper>
      {isReadyToRender ? (
        <FeedbackBox
          rating={
            isDefined(sessionFeedback?.rating)
              ? sessionFeedback.rating + 1
              : undefined
          }
          comment={sessionFeedback?.comment}
          tags={Object.keys(sessionFeedback?.answers || {})
            .filter(key => sessionFeedback.answers[key])
            .map(answer => feedbackTags[answer].en)}
          sessionMentalTags={sessionMentalTags[sessionId]}
        />
      ) : null}
    </Paper>
  );
};

FeedbackBoxInSessionPageBase.propTypes = exact({
  sessionNumber: PropTypes.number.isRequired,
  customerId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
});

export const FeedbackBoxInSessionPage = React.memo(
  FeedbackBoxInSessionPageBase
);
FeedbackBoxInSessionPage.displayName = 'FeedbackBoxInSessionPage';

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Cropper from 'react-easy-crop';
import { Dialog, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './AutomationsLogoDialog.scss';
import LogoPlaceholder from '../../../assets/logo_placeholder.png';

const AutomationsLogoDialogBase = ({
  isOpen,
  onClose,
  setClinicImageUrlInput,
  clinicImageUrlInput,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  // const [croppedImage,setCroppedImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onImageFileChange = async event => {
    if (event.target.files.length) {
      setZoom(1);
      const imgId = Math.random()
        .toString(36)
        .substring(7);
      const path = `app_logo/${sessionStorage.userId}/${imgId}`;
      const [file] = event.target.files;
      const storage = window.firebase.storage();
      const imageRef = storage.ref(path);
      try {
        await imageRef.put(file);
        // setImgPrev(file);
        // const logoSettingsData = {...logoSettingsDefaultData};
        // logoSettingsData.logoName = imgId;
        // database.ref(`appLogoSettings/${sessionStorage.userId}`).set(logoSettingsData);
        imageRef
          .getDownloadURL()
          .then(res => {
            setClinicImageUrlInput(res);
          })
          .catch(() => {});
      } catch (err) {
        // TODO
        // console.log(err);
      }
    }
  };

  useEffect(() => {
    setZoom(1);
  }, [isOpen]);

  // eslint-disable-next-line no-shadow
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    //   console.log(croppedArea, croppedAreaPixels)
  }, []);

  const createImage = url =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        resolve(file);
      }, 'image/png');
    });
  }

  const saveCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        clinicImageUrlInput,
        croppedAreaPixels,
        0
      );
      //   console.log('donee', { croppedImage })
      //   setCroppedImage(croppedImage)

      // ***********************************  ---  HANDLE FIRBASE  ---  ***********************************
      const imgId = Math.random()
        .toString(36)
        .substring(7);
      const path = `app_logo/${sessionStorage.userId}/${imgId}`;
      //   const [file] = event.target.files;
      const storage = window.firebase.storage();
      const imageRef = storage.ref(path);
      try {
        await imageRef.put(croppedImage);
        imageRef
          .getDownloadURL()
          .then(res => {
            setClinicImageUrlInput(res);
          })
          .catch(() => {});
      } catch (err) {
        // TODO
        // console.log(err);
      }
      // ****************************************************************************************************
    } catch (e) {
      //   console.error(e);
    }
  };

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: styles.dialog_paper }}
      fullWidth
      maxWidth="md"
    >
      <div className={styles.icon_continer}>
        <CloseIcon
          style={{
            cursor: 'pointer',
            color: '#808080',
            marginTop: 10,
            marginRight: 10,
          }}
          onClick={onClose}
        />
      </div>
      <div className={styles.title_container}>
        <div style={{ fontSize: 17, fontWeight: 700 }}>Your Logo</div>
        <div className={styles.buttons_container}>
          <Button
            onClick={() => {
              document.getElementById('image-input').click();
            }}
            color="primary"
            variant="outlined"
            style={{ fontSize: 14 }}
          >
            Upload a new logo
          </Button>
          <input
            accept="image/png, image/gif, image/jpeg"
            hidden
            id="image-input"
            type="file"
            onChange={onImageFileChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      <div className={styles.content_continer}>
        <div className={styles.App}>
          {clinicImageUrlInput && (
            <div
              className={styles.crop}
              style={window.innerHeight > 900 ? { bottom: 150 } : null}
            >
              <Cropper
                image={clinicImageUrlInput}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                restrictPosition={false}
                zoomWithScroll={false}
                // showGrid={false}
                // cropSize={{ width: 360, height: 180 }}
              />
            </div>
          )}

          <div
            style={{
              width: '100%',
              height: '80%',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: clinicImageUrlInput ? 'none' : 'solid 1px gray',
            }}
          >
            {clinicImageUrlInput ? null : (
              <img
                src={LogoPlaceholder}
                style={{ margin: '20px 0 0 60px', width: 210, height: 170 }}
              />
            )}
          </div>

          <div
            className={styles.controls}
            style={window.innerHeight > 850 ? { top: -20 } : null}
          >
            <Button
              className={styles.operator}
              onClick={() => {
                if (zoom > 0) {
                  setZoom(zoom - 0.1);
                }
              }}
              disabled={!clinicImageUrlInput}
              style={{ margin: 10 }}
            >
              <RemoveIcon />
            </Button>
            <input
              type="range"
              value={zoom}
              min={0}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={e => {
                setZoom(Math.round(e.target.value * 100) / 100);
              }}
              className={styles.zoom}
              disabled={!clinicImageUrlInput}
            />
            <Button
              className={styles.operator}
              onClick={() => {
                if (zoom < 3) {
                  setZoom(zoom + 0.1);
                }
              }}
              disabled={!clinicImageUrlInput}
              style={{ margin: 10 }}
            >
              <AddIcon />
            </Button>
          </div>
          <div className={styles.texts}>
            <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 10 }}>
              The logo is adapted to a 1:1 ratio regardless of the original
              size. A white background is added in case the logo has a
              transparent background or doesn’t fill the 1:1 view.
            </div>
          </div>
          <div className={styles.dialog_buttons_container}>
            <Button
              onClick={onClose}
              // color='primary'
              // variant='contained'
              style={{ fontSize: 14, margin: 5 }}
            >
              cancel
            </Button>
            <Button
              onClick={() => {
                saveCroppedImage();
                onClose();
              }}
              color="primary"
              variant="contained"
              style={{ fontSize: 14, margin: 5 }}
            >
              save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

AutomationsLogoDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setClinicImageUrlInput: PropTypes.func,
  clinicImageUrlInput: PropTypes.string,
});

export const AutomationsLogoDialog = React.memo(AutomationsLogoDialogBase);
AutomationsLogoDialog.displayName = 'AutomationsLogoDialog';

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayjs from 'dayjs';
import { YearWrap } from './YearWrap';
import screen_1 from '../../../assets/wrapped_backgrounds/screen_1.json';
import screen_2 from '../../../assets/wrapped_backgrounds/screen_2.json';
import screen_3 from '../../../assets/wrapped_backgrounds/screen_3.json';
import screen_4 from '../../../assets/wrapped_backgrounds/screen_4.json';
import screen_5 from '../../../assets/wrapped_backgrounds/screen_5.json';
import screen_5_1 from '../../../assets/wrapped_backgrounds/screen_5_1.json';
import screen_6 from '../../../assets/wrapped_backgrounds/screen_6.json';
import screen_7 from '../../../assets/wrapped_backgrounds/screen_7.json';
import screen_8 from '../../../assets/wrapped_backgrounds/screen_8.json';
import screen_9 from '../../../assets/wrapped_backgrounds/screen_9.json';
import screen_10 from '../../../assets/wrapped_backgrounds/screen_10.json';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';

import styles from './CustomerStoriesPages/CustomerStoriesPagesStyles.scss';

import { welcomeStorySequences } from './CustomerStoriesPages/welcomeStorySequences';
import { nfMinutesStorySequences } from './CustomerStoriesPages/nfMinutesStroySequences';
import { insightsStorySequences } from './CustomerStoriesPages/insightsStorySequences';
import { zoneStorySequences } from './CustomerStoriesPages/zoneStorySequences';
import { brainwaveStorySequences } from './CustomerStoriesPages/brainwaveStorySequences';
import { focusScoreStorySequences } from './CustomerStoriesPages/focusScoreStorySequence';
import { symptomTrackerStorySequences } from './CustomerStoriesPages/symptomTrackerStorySequences';
import { overAllAlphaPeakStorySequences } from './CustomerStoriesPages/overAllAlphaPeakStorySequences';
import { bestAlphaPeakStorySequences } from './CustomerStoriesPages/bestAlphaPeakStorySequences';
// import { worstAlphaPeakStorySequences } from './CustomerStoriesPages/worstAlphaPeakStorySequences';

const CustomerYearWrapBase = ({ patientId, onComponentReady }) => {
  const lottieSources = [
    screen_1,
    screen_2,
    screen_3,
    screen_4,
    screen_5,
    screen_6,
    screen_7,
    screen_8,
    screen_9,
    screen_10,
  ];

  const year = '2024';
  const viewerType = 'patients';

  const absoluteParentDivClassName = styles.absolute_parent_div;
  const relativeParentDivClassName = styles.relative_parent_div;
  const absoluteParentDivStyle = {};
  const relativeParentDivStyle = {};

  // const [isStroyActive] = useFirebaseDB({
  //   path: `yearWrapped/${year}/${viewerType}/config/active`,
  // });
  // const isStroyActive = true;

  const [customerWrappedData] = useFirebaseDB({
    path: `yearWrapped/${year}/${viewerType}/data/${patientId}`,
  });
  const [isAppStartingAnimation, setIsAppStartingAnimation] = useState(
    !window.webkit
  );
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );
  const [data, setData] = useState({});
  const [showLogo, setShowLogo] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line func-names
    // eslint-disable-next-line space-before-function-paren
    window.startAnimations = function() {
      // console.log('app Started Animation');
      setIsAppStartingAnimation(true);
    };
    return () => {
      delete window.startAnimations; // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    // get data from firebase and change data array content
    if (
      // isStroyActive
      //  &&
      customerWrappedData !== null
    ) {
      // console.log(customerWrappedData);
      const {
        nf_minutes,
        nf_minutes_rank,
        zone_seconds,
        most_sessions_time_of_day,
        most_sessions_percentage,
        best_score_time_of_day,
        superstar_brainwave_frequency,
        superstar_brainwave_improvement_percentage,
        best_alpha_peak_date,
        best_alpha_peak_mental_tags,
        best_alpha_peak_value,
        // worst_alpha_peak_date,
        // worst_alpha_peak_value,
        // worst_alpha_peak_mental_tags,
        alpha_peak_improvement_percentage,
        focus_improvement_percentage,
        symptom_tracker_improvement_percentage,
        show_logo,
      } = customerWrappedData;

      const welcomeStroyObj = () => {
        const sequences = welcomeStorySequences(year);
        return {
          sequences,
          background: screen_1,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 1200,
          backgroundAnimationPlaySpeed: 0.5,
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 10900,
        };
      };

      const nfMinutesStroyObj = () => {
        const sequences = nfMinutesStorySequences(nf_minutes, nf_minutes_rank);
        return {
          sequences,
          background: screen_2,
          backgroundAnimationAutoPlay: true,
          backgroundAnimationDelay: 0,
          backgroundAnimationPlaySpeed: 0.5,
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 7500,
        };
      };

      const zoneStoryObj = () => {
        const sequences = zoneStorySequences(zone_seconds);
        return {
          sequences,
          background: screen_3,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 2300,
          backgroundAnimationPlaySpeed: 1, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 8100,
        };
      };

      const insightsStoryObj = () => {
        const sequences = insightsStorySequences(
          most_sessions_time_of_day,
          most_sessions_percentage,
          best_score_time_of_day
        );

        return {
          sequences,
          background: screen_4,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 700,
          backgroundAnimationPlaySpeed: 1.1, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 10600,
        };
      };

      const brainwaveStoryObj = () => {
        const sequences = brainwaveStorySequences(
          superstar_brainwave_frequency,
          superstar_brainwave_improvement_percentage
        );
        return {
          sequences,
          background: screen_5_1,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 2000,
          backgroundAnimationPlaySpeed: 0.3, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 6800,
        };
      };

      const bestAlphaPeakStoryObj = () => {
        const bestDateFormatted = dayjs(best_alpha_peak_date).format(
          DAY_JS_DATE_FORMATS.namedMonthDayYear
        );
        const sequences = bestAlphaPeakStorySequences(
          bestDateFormatted,
          best_alpha_peak_value,
          best_alpha_peak_mental_tags
        );

        return {
          sequences,
          background: screen_6,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 0,
          backgroundAnimationPlaySpeed: 0.8, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: best_alpha_peak_mental_tags ? 18000 : 15400,
        };
      };

      // const worstAlphaPeakStoryObj = () => {
      //   const worstDateFormatted = dayjs(worst_alpha_peak_date).format(
      //     DAY_JS_DATE_FORMATS.namedMonthAndDay
      //   );

      //   const sequences = worstAlphaPeakStorySequences(
      //     worstDateFormatted,
      //     worst_alpha_peak_value,
      //     worst_alpha_peak_mental_tags
      //   );

      //   return {
      //     sequences,
      //     background: screen_7,
      //     backgroundAnimationAutoPlay: false,
      //     backgroundAnimationDelay: 0,
      //     backgroundAnimationPlaySpeed: 0.8, // bigger is faster
      //     containers: {
      //       absoluteParentDivStyle,
      //       absoluteParentDivClassName,
      //       relativeParentDivStyle: {
      //         ...relativeParentDivStyle,
      //         width: 'fit-content',
      //         margin: 'auto',
      //       },
      //       relativeParentDivClassName,
      //     },
      //     storyDuration: 11800,
      //   };
      // };

      const overAllAlphaPeakStoryObj = () => {
        const sequences = overAllAlphaPeakStorySequences(
          alpha_peak_improvement_percentage
        );
        return {
          sequences,
          background: screen_8,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 700,
          backgroundAnimationPlaySpeed: 1.1, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 5800,
        };
      };

      const focusScoreStroyObj = () => {
        const sequences = focusScoreStorySequences(
          focus_improvement_percentage
        );
        return {
          sequences,
          background: screen_9,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 1000,
          backgroundAnimationPlaySpeed: 0.7, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 6800,
        };
      };

      const symptomTrackerStoryObj = () => {
        const sequences = symptomTrackerStorySequences(
          symptom_tracker_improvement_percentage
        );
        return {
          sequences,
          background: screen_10,
          backgroundAnimationAutoPlay: false,
          backgroundAnimationDelay: 2500,
          backgroundAnimationPlaySpeed: 0.9, // bigger is faster
          containers: {
            absoluteParentDivStyle,
            absoluteParentDivClassName,
            relativeParentDivStyle: {
              ...relativeParentDivStyle,
              width: 'fit-content',
              margin: 'auto',
            },
            relativeParentDivClassName,
          },
          storyDuration: 10800,
        };
      };

      const storyData = [
        welcomeStroyObj(),
        ...(nf_minutes && nf_minutes_rank ? [nfMinutesStroyObj()] : []),
        ...(zone_seconds ? [zoneStoryObj()] : []),
        ...(most_sessions_time_of_day &&
        most_sessions_percentage &&
        best_score_time_of_day
          ? [insightsStoryObj()]
          : []),
        ...(superstar_brainwave_frequency &&
        superstar_brainwave_improvement_percentage
          ? [brainwaveStoryObj()]
          : []),
        ...(best_alpha_peak_value && best_alpha_peak_date
          ? [bestAlphaPeakStoryObj()]
          : []),
        // ...(worst_alpha_peak_value && worst_alpha_peak_date
        //   ? [worstAlphaPeakStoryObj()]
        //   : []),
        ...(alpha_peak_improvement_percentage
          ? [overAllAlphaPeakStoryObj()]
          : []),
        ...(focus_improvement_percentage ? [focusScoreStroyObj()] : []),
        ...(symptom_tracker_improvement_percentage
          ? [symptomTrackerStoryObj()]
          : []),
      ];
      setData(storyData);
      setShowLogo(show_logo);
      setIsComponentReadyToRender(true);
      onComponentReady();
    }
  }, [patientId, customerWrappedData]);

  return (
    isComponentReadyToRender &&
    isAppStartingAnimation && (
      <>
        <YearWrap
          data={data}
          showLogo={showLogo}
          lottieSources={lottieSources}
        />
      </>
    )
  );
};

CustomerYearWrapBase.propTypes = exact({
  patientId: PropTypes.string,
  onComponentReady: PropTypes.func,
});

export const CustomerYearWrap = React.memo(CustomerYearWrapBase);
CustomerYearWrap.displayName = 'CustomerYearWrap';

import React from 'react';
import { LocalizationConsumer } from '../../contexts/contexts';

export const withTranslation = Component => {
  const WithTranslationHOC = props => (
    <LocalizationConsumer>
      {t => <Component t={t} {...props} />}
    </LocalizationConsumer>
  );
  return WithTranslationHOC;
};

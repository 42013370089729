import React, { useState, useEffect } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { ClinicAccountPage } from './ClinicAccountPage';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { stripeScriptURL } from '../../utils/constants';
import { useScript } from '../../Core/hooks/useScript';

const ClinicAccountPageWrapperBase = ({ ...props }) => {
  const [stripe, setStripe] = useState(null);
  const [isStripeScriptLoaded] = useScript(stripeScriptURL);

  useEffect(() => {
    if (isStripeScriptLoaded && stripe === null) {
      setStripe(window.Stripe(environmentConfig.stripePublicKey));
      window.stripeForStripePage = window.Stripe(
        environmentConfig.stripePublicKey
      );
    }
  }, [isStripeScriptLoaded, stripe]);
  // eslint-disable-next-line react/prop-types
  if (!props.clinicId) {
    return null;
  }

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <ClinicAccountPage {...props} />
      </Elements>
    </StripeProvider>
  );
};

ClinicAccountPageWrapperBase.propTypes = {};

export const ClinicAccountPageWrapper = withEntryComponent(
  ClinicAccountPageWrapperBase
);

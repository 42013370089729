import React from 'react';
import exact from 'prop-types-exact';
import { OutstandingPaymentPopUpStripeContainer } from './OutstandingPaymentPopUp/OutstandingPaymentPopUpStripeContainer';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

const EveryPageComponentsBase = () => {
  const clinicId = sessionStorage.userId;
  const [outstandingPaymentAmount] = useFirebaseDB({
    path: `billing/currentPackages/${clinicId}/outstandingPaymentsAmount`,
    onceListener: true,
  });
  const shouldRenderOutstandingPaymentPopUpContainer =
    outstandingPaymentAmount !== null && outstandingPaymentAmount > 0;
  return (
    <>
      {shouldRenderOutstandingPaymentPopUpContainer && (
        <OutstandingPaymentPopUpStripeContainer />
      )}
    </>
  );
};

EveryPageComponentsBase.propTypes = exact({});

export const EveryPageComponents = React.memo(EveryPageComponentsBase);
EveryPageComponents.displayName = 'EveryPageComponents';

import { kebabCase, startCase } from 'lodash';

export const SESSION_STATUSES = {
  RESUMED: 'RESUMED',
  WAITING_FOR_USER: 'WAITING_FOR_USER',
  PAUSED: 'PAUSED',
  CANCELED: 'SESSION_CANCELED',
  STOPPED: 'STOPPED',
  ONLINE: 'online',
  OFFLINE: 'offline',
  CALIBRATION: 'calibration',
  SIGNAL_CHECK: 'signalCheck',
  BREAK_TIME: 'BREAK_TIME',
  FINISHED: 'FINISHED',
};

const PRETTY_SESSION_STATUSES = Object.assign(
  { ...SESSION_STATUSES },
  {
    [SESSION_STATUSES.RESUMED]: 'Training',
    [SESSION_STATUSES.STOPPED]: 'Stopped training',
    [SESSION_STATUSES.FINISHED]: 'Online',
    [SESSION_STATUSES.BREAK_TIME]: 'Finished training round',
    [SESSION_STATUSES.WAITING_FOR_USER]: 'Waiting to start training',
    [SESSION_STATUSES.CANCELED]: 'CANCELED',
    [SESSION_STATUSES.PAUSED]: 'Paused training',

    // Questionnaires: 'Asmnt: Questionniares',
    // Central: 'Asmnt: Central Sensing',
    // Back: 'Asmnt: Back Sensing',
    // Left: 'Asmnt: Left Sensing',
    // Right: 'Asmnt: Right Sensing',
    // Frontal: 'Asmnt: Frontal Sensing',
    // Task: 'Asmnt: Task',

    'Today Tab': 'Main screen',
    'Journey Tab': 'Main screen',
    'Explore Tab': 'Main screen',
    'More Tab': 'Main screen',
    'Train Tab': 'Main screen',

    Exercise: 'Journaling',
    Journal: 'Journaling',

    practice: 'Training tutorial',
    breakTime: 'Finished training round',
    waitingForUser: 'Waiting to start training',
    stopped: 'Stopped training',
    paused: 'Paused training',
    resumed: 'Training',
  }
);

export const prettifySessionStatus = status => {
  const prettyStatus = PRETTY_SESSION_STATUSES[status] || status;
  if (status === 'Questionnaires') {
    return 'Asmnt: Questionnaires';
  }
  if (status === 'Central') {
    return 'Asmnt: Central sensing';
  }
  if (status === 'Back') {
    return 'Asmnt: Back sensing';
  }
  if (status === 'Left') {
    return 'Asmnt: Left Sensing';
  }
  if (status === 'Right') {
    return 'Asmnt: Right Sensing';
  }
  if (status === 'Frontal') {
    return 'Asmnt: Frontal Sensing';
  }
  if (status === 'Task') {
    return 'Asmnt: Task';
  }
  return startCase(kebabCase(prettyStatus));
};

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styles from './NonfPackagePlan.scss';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';

const NonfPackagePlanBase = ({
  packagePlan,
  color,
  isAnnual,
  onPackageSelected,
  packageIndex,
  selectedPackage,
  isFromClinicAccount,
  currentPackageIndexNonfPlan,
  nextPlanNonf,
  currentPlan,
  isMostPopular,
  deactivationRequested,
  formattedNextBillingCycle,
  shouldShowAnnualPrice,
  commitment,
  discountAmount,
  isDormant,
  isCurrentPlanDormant,
}) => {
  //   const [toggleAnual, setToggleAnual] = useState(false);
  const calcTitle = () => {
    // Indication for MTR
    if (commitment) {
      return 'SELECT';
    }
    if (isDormant && isCurrentPlanDormant) {
      return 'ACTIVE PLAN';
    }
    if (isCurrentPlanDormant) {
      return 'UPGRADE';
    }
    if (!isFromClinicAccount) {
      return selectedPackage === packageIndex
        ? 'SELECTED PLAN'
        : 'TRY FOR FREE';
    }
    if (
      !currentPlan ||
      !currentPlan.selectedPlan ||
      (deactivationRequested && currentPlan.selectedPlan.isAnnual === isAnnual)
    ) {
      return 'ACTIVATE';
    }
    if (packageIndex < currentPackageIndexNonfPlan) {
      return 'DOWNGRADE';
    }
    if (packageIndex === currentPackageIndexNonfPlan) {
      if (currentPlan.selectedPlan.isAnnual === isAnnual) {
        return 'ACTIVE PLAN';
      }
      if (nextPlanNonf) {
        return 'ACTIVE UPON RENEWAL';
      }
      return `SWITCH TO ${isAnnual ? 'ANNUAL' : 'MONTHLY'}`;
    }
    if (packageIndex > currentPackageIndexNonfPlan) {
      return 'UPGRADE';
    }
    return '';
  };

  return (
    <div
      className={
        // eslint-disable-next-line no-nested-ternary
        isFromClinicAccount
          ? styles.nonf_package_container_dashboard
          : commitment
          ? styles.nonf_package_container_mtr
          : styles.nonf_package_container
      }
      style={
        selectedPackage === packageIndex && !isDormant
          ? { border: `2px solid ${color}` }
          : { border: '2px solid rgba(0,0,0,0)' }
      }
    >
      <div
        style={{ backgroundColor: color }}
        className={styles.package_plans_top}
      />
      <div
        className={styles.nonf_package_data}
        style={isFromClinicAccount ? { padding: 20 } : null}
      >
        <div
          className={styles.nonf_package_title}
          style={
            // eslint-disable-next-line no-nested-ternary
            commitment
              ? window.innerWidth < 768
                ? { fontSize: 18, maxWidth: 100 }
                : { fontSize: 22, maxWidth: 120 }
              : isFromClinicAccount
              ? { fontSize: 17 }
              : null
          }
        >
          {packagePlan.bundleName}
          {isMostPopular ? (
            <div
              className={styles.title_label}
              style={
                isFromClinicAccount
                  ? {
                      backgroundColor: color,
                      left: 150,
                      fontSize: 10,
                      width: 106,
                      height: 18,
                    }
                  : {
                      backgroundColor: color,
                      left: 135,
                    }
              }
            >
              MOST POPULAR
            </div>
          ) : null}
        </div>
        <div
          className={styles.nonf_package_description}
          style={
            isFromClinicAccount
              ? { fontSize: 12, marginTop: 5 }
              : { marginTop: 5, marginBottom: 10 }
          }
        >
          {packagePlan.planDescription}
        </div>
        {shouldShowAnnualPrice ? (
          <div
            className={styles.nonf_package_price}
            style={isFromClinicAccount ? { fontSize: 24 } : null}
          >
            ${Math.round(packagePlan.annualMonthlyBasePrice * 12)}/
            <span className={styles.nonf_package_price_label}>year</span>
          </div>
        ) : (
          <div
            className={styles.nonf_package_price}
            style={isFromClinicAccount ? { fontSize: 24 } : null}
          >
            $
            {isAnnual
              ? packagePlan.annualMonthlyBasePrice
              : packagePlan.basePrice}
            /<span className={styles.nonf_package_price_label}>mo</span>
            {discountAmount && !isAnnual ? (
              <span
                style={{
                  color,
                  fontWeight: 900,
                  fontSize: 14,
                }}
              >
                {' '}
                save ${discountAmount}
              </span>
            ) : null}
          </div>
        )}
        {shouldShowAnnualPrice ? null : (
          <div
            className={commitment ? styles.commitment : null}
            style={isFromClinicAccount ? null : { fontSize: 15 }}
          >
            {// eslint-disable-next-line no-nested-ternary
            commitment
              ? // eslint-disable-next-line no-unneeded-ternary
                commitment
              : isAnnual && !isDormant
              ? 'Billed annually'
              : 'Billed monthly'}
            {discountAmount && isAnnual ? (
              <span
                style={{
                  color,
                  fontWeight: 900,
                }}
              >
                {' '}
                save ${discountAmount}
              </span>
            ) : null}
          </div>
        )}
        <TooltipWrapper
          title={
            <TooltipTitle>
              {calcTitle() === 'ACTIVE PLAN'
                ? `This plan is active until the end of your billing cycle ${formattedNextBillingCycle}`
                : `This plan will become active upon renewal of your billing cycle on ${formattedNextBillingCycle}`}
            </TooltipTitle>
          }
          disabled={
            !(
              calcTitle() === 'ACTIVE PLAN' ||
              calcTitle() === 'ACTIVE UPON RENEWAL'
            )
          }
        >
          <div
            className={
              isFromClinicAccount
                ? styles.nonf_package_button_dashboard
                : styles.nonf_package_button
            }
            style={
              // eslint-disable-next-line no-nested-ternary
              (currentPackageIndexNonfPlan === packageIndex &&
                currentPlan &&
                currentPlan.selectedPlan &&
                currentPlan.selectedPlan.isAnnual === isAnnual) ||
              (currentPackageIndexNonfPlan === packageIndex &&
                currentPlan &&
                currentPlan.selectedPlan &&
                currentPlan.selectedPlan.isAnnual !== isAnnual &&
                !!nextPlanNonf) ||
              isDormant
                ? { border: '2px solid #6A6A6A', cursor: 'pointer' }
                : selectedPackage === packageIndex
                ? {
                    color: 'white',
                    backgroundColor: color,
                    border: `2px solid ${color}`,
                  }
                : { color, border: `2px solid ${color}` }
            }
            onClick={() => {
              onPackageSelected(packageIndex);
            }}
          >
            {calcTitle()}
          </div>
        </TooltipWrapper>
        {packagePlan.features.map(feature => {
          return (
            <div key={feature.featureKey || Math.random()}>
              <TooltipWrapper
                title={<TooltipTitle>{feature.description}</TooltipTitle>}
                arrow
              >
                <div className={styles.feature_item}>
                  {feature.isX ? (
                    <CloseIcon style={{ color, marginRight: 10 }} />
                  ) : (
                    <CheckIcon style={{ color, marginRight: 10 }} />
                  )}
                  {/* <div>{feature.feature}</div> */}
                  <div dangerouslySetInnerHTML={{ __html: feature.feature }} />
                </div>
              </TooltipWrapper>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NonfPackagePlanBase.propTypes = exact({
  packagePlan: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  onPackageSelected: PropTypes.func.isRequired,
  packageIndex: PropTypes.number.isRequired,
  selectedPackage: PropTypes.number,
  isFromClinicAccount: PropTypes.bool,
  currentPackageIndexNonfPlan: PropTypes.number,
  nextPlanNonf: PropTypes.object,
  currentPlan: PropTypes.object,
  isMostPopular: PropTypes.bool,
  deactivationRequested: PropTypes.number,
  formattedNextBillingCycle: PropTypes.string,
  shouldShowAnnualPrice: PropTypes.bool,
  commitment: PropTypes.number,
  discountAmount: PropTypes.number,
  isDormant: PropTypes.bool,
  isCurrentPlanDormant: PropTypes.bool,
});

export const NonfPackagePlan = React.memo(NonfPackagePlanBase);
NonfPackagePlan.displayName = 'NonfPackagePlan';

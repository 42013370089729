import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Switch,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { useCustomerInfo } from '../../../Core/hooks/useCustomerInfo';
import styles from './EntriesTab.scss';
import warningIcon from '../../../../assets/warning_icon.png';
import noSelected from '../../../../assets/journal_not_selected_placeholder.png';
// import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';

const lagoDashboardLogger = new MyEventWrapper('lago_dashboard');
const lagoDashboardContentLogger = new MyEventWrapper('lago_dashboard_content');
const lagoDashboardEntriesLogger = new MyEventWrapper('lago_dashboard_entries');
dayjs.extend(dayjsCustomParseFormat);

const initialValue = Symbol('iv');

const EntriesTabBase = ({
  isLagoEnabled,
  calcCategoriesStatus,
  notificationsAlerts,
  editNotificationsAlerts,
}) => {
  const customerInfo = useCustomerInfo();
  const EMOJIMAP = [null, '🙁', '😕', '😐', '🙂', '😁'];

  const scrollableRef = useRef(null);

  const [selectedItemId, setSelectedItemId] = useState('');
  const [selectedItemEntry, setSelectedItemEntry] = useState('');
  const [selectedItemType, setSelectedItemType] = useState('');
  const [therapistComment, setTherapistComment] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState('');
  const [hoveredComment, setHoveredComment] = useState('');

  const [userDataCheckins] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/journeyConfig/checkins`,
  });

  const [userDataJournals] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/journeyConfig/journals`,
  });

  const [journalsData] = useFirebaseDB({
    path: 'lago/educational/defaults/journals',
  });

  const [enableJournalSharing, setEnableJournalSharing] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/EnableJournalSharing`,
    initialValue,
  });

  const [journeyEnabledClinic] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${sessionStorage.userId}/enableJournalSharing`,
  });

  const [enableTextBasedFeedback, setEnableTextBasedFeedback] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/journalSharingSettings/enableTextBasedFeedback`,
    initialValue,
  });

  const [enableTextBasedFeedbackClinic] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${sessionStorage.userId}/journalSharingSettings/enableTextBasedFeedback`,
  });

  const [enableJournalReply, setEnableJournalReply] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/journalSharingSettings/enableJournalReply`,
    initialValue,
  });

  const [enableJournalReplyClinic] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${sessionStorage.userId}/journalSharingSettings/enableJournalReply`,
  });

  const [selectedJournalEntryData, setSelectedJournalEntryData] = useFirebaseDB(
    {
      path: `lago/educational/users/${customerInfo.id}/journeyConfig/journals/${selectedItemId}/journalEntries/${selectedItemEntry}`,
    }
  );

  const [selectedCheckinEntryData, setSelectedCheckinEntryData] = useFirebaseDB(
    {
      path: `lago/educational/users/${customerInfo.id}/journeyConfig/checkins/${selectedItemId}/journalEntries/${selectedItemEntry}`,
    }
  );

  const [userInfo] = useFirebaseDB({
    path: `userInfo/${customerInfo.id}`,
  });

  const parseDate = (unixTimestamp, showHours = true) => {
    const a = new Date(unixTimestamp);

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const day = days[a.getDay()];
    const date = a.getDate();
    let hour = a.getHours();
    if (hour < 10) {
      // eslint-disable-next-line prefer-template
      hour = '0' + hour;
    }
    let min = a.getMinutes();
    if (min < 10) {
      // eslint-disable-next-line prefer-template
      min = '0' + min;
    }
    // let sec = a.getSeconds();
    // if (sec < 10) {
    //   // eslint-disable-next-line prefer-template
    //   sec = '0' + sec;
    // }
    let time;
    if (showHours) {
      time =
        // eslint-disable-next-line prefer-template
        day +
        ', ' +
        month +
        ' ' +
        date +
        ', ' +
        year +
        ' at ' +
        hour +
        ':' +
        min;
      // +
      // ':' +
      // sec;
    } else {
      time =
        // eslint-disable-next-line prefer-template
        day + ', ' + month + ' ' + date + ', ' + year;
    }

    return time;
  };

  const sendCommentClicked = () => {
    const commentId = Math.random()
      .toString(36)
      .substring(7);
    if (selectedItemType === 'journal') {
      const copy = JSON.parse(JSON.stringify(selectedJournalEntryData));
      if (!copy.therapistComments) {
        copy.therapistComments = {};
        copy.therapistComments[commentId] = {};
      }
      copy.therapistComments[commentId] = {
        txt: therapistComment,
        timestamp: Date.now(),
      };
      setSelectedJournalEntryData(copy);
    }
    if (selectedItemType === 'checkin') {
      const copy = JSON.parse(JSON.stringify(selectedCheckinEntryData));
      if (!copy.therapistComments) {
        copy.therapistComments = {};
        copy.therapistComments[commentId] = {};
      }
      copy.therapistComments[commentId] = {
        txt: therapistComment,
        timestamp: Date.now(),
      };
      setSelectedCheckinEntryData(copy);
    }

    setTherapistComment('');
    lagoDashboardContentLogger.log('client_entry_send_answer', {
      patientId: customerInfo.id,
      entry_timestamp: selectedItemEntry,
      entry_name:
        selectedItemType === 'checkin'
          ? 'Check-in'
          : selectedJournalEntryData.title ||
            journalsData[selectedItemId].title.en,
      type: selectedItemType,
      commentId,
    });
  };

  const deleteComment = () => {
    if (selectedItemType === 'journal') {
      const copy = JSON.parse(JSON.stringify(selectedJournalEntryData));
      lagoDashboardLogger.log('client_entry_feedback_deleted', {
        patientId: customerInfo.id,
        entry_timestamp: selectedItemEntry,
        entry_name:
          selectedItemType === 'checkin'
            ? 'Check-in'
            : selectedJournalEntryData.title ||
              journalsData[selectedItemId].title.en,
        type: selectedItemType,
        commentId: commentToDelete,
        text: copy.therapistComments[commentToDelete].txt,
      });
      delete copy.therapistComments[commentToDelete];
      setSelectedJournalEntryData(copy);
    }
    if (selectedItemType === 'checkin') {
      const copy = JSON.parse(JSON.stringify(selectedCheckinEntryData));
      lagoDashboardLogger.log('client_entry_feedback_deleted', {
        patientId: customerInfo.id,
        entry_timestamp: selectedItemEntry,
        entry_name:
          selectedItemType === 'checkin'
            ? 'Check-in'
            : selectedJournalEntryData.title ||
              journalsData[selectedItemId].title.en,
        type: selectedItemType,
        commentId: commentToDelete,
        text: copy.therapistComments[commentToDelete].txt,
      });
      delete copy.therapistComments[commentToDelete];
      setSelectedCheckinEntryData(copy);
    }
    setCommentToDelete('');
    setIsDeleteDialogOpen(false);
  };

  const deleteCommentClicked = commentId => {
    setIsDeleteDialogOpen(true);
    setCommentToDelete(commentId);
  };

  const setSeen = async (itemId, itemEntrieTimestamp, type) => {
    if (type === 'journal') {
      if (
        !userDataJournals[itemId].journalEntries[itemEntrieTimestamp].isSeen
      ) {
        const journalEntryDataRef = database.ref(
          `lago/educational/users/${customerInfo.id}/journeyConfig/journals/${itemId}/journalEntries/${itemEntrieTimestamp}`
        );
        await journalEntryDataRef.child('isSeen').set(true);
      }
    }
    if (type === 'checkin') {
      if (
        !userDataCheckins[itemId].journalEntries[itemEntrieTimestamp].isSeen
      ) {
        const journalEntryDataRef = database.ref(
          `lago/educational/users/${customerInfo.id}/journeyConfig/checkins/${itemId}/journalEntries/${itemEntrieTimestamp}`
        );
        await journalEntryDataRef.child('isSeen').set(true);
      }
    }
  };

  const itemEntryClicked = async (
    itemId,
    itemEntrieTimestamp,
    type,
    entry,
    renderJournalTitle
  ) => {
    if (selectedItemId && selectedItemEntry && selectedItemType) {
      await setSeen(selectedItemId, selectedItemEntry, selectedItemType);
    }
    await setSeen(itemId, itemEntrieTimestamp, type);
    setSelectedItemId(itemId);
    setSelectedItemEntry(itemEntrieTimestamp);
    setSelectedItemType(type);

    lagoDashboardContentLogger.log('client_entry_click_entry', {
      patientId: customerInfo.id,
      entry_timestamp: itemEntrieTimestamp,
      entry_name: renderJournalTitle(entry),
      type,
    });
  };

  const scrollMessages = () => {
    if (scrollableRef && scrollableRef.current) {
      scrollableRef.current.scrollIntoView({ block: 'end' });
    }
  };

  useEffect(() => {
    const updatedNotifications = {};
    // eslint-disable-next-line no-unused-expressions
    notificationsAlerts &&
      Object.keys(notificationsAlerts).forEach(notificationId => {
        if (
          notificationsAlerts[notificationId] === 'redFlag' ||
          notificationsAlerts[notificationId] === 'greenFlag'
        ) {
          updatedNotifications[notificationId] =
            notificationsAlerts[notificationId];
        }
      });
    editNotificationsAlerts(updatedNotifications);
  }, []);

  useEffect(() => {
    scrollMessages();
  }, [
    selectedItemEntry,
    selectedJournalEntryData,
    selectedCheckinEntryData,
    userDataJournals,
    userDataCheckins,
  ]);

  useEffect(() => {
    if (
      selectedItemEntry &&
      !(
        userDataJournals &&
        userDataJournals[selectedItemId] &&
        userDataJournals[selectedItemId].journalEntries &&
        userDataJournals[selectedItemId].journalEntries[selectedItemEntry]
      ) &&
      selectedItemEntry &&
      !(
        userDataCheckins &&
        userDataCheckins[selectedItemId] &&
        userDataCheckins[selectedItemId].journalEntries &&
        userDataCheckins[selectedItemId].journalEntries[selectedItemEntry]
      )
    ) {
      setSelectedItemId('');
      setSelectedItemEntry('');
      setSelectedItemType('');
    }
  }, [userDataJournals, userDataCheckins]);

  let isJourneyEnabledClinic = false;
  if (journeyEnabledClinic || journeyEnabledClinic === null) {
    isJourneyEnabledClinic = true;
  }

  let isTextBasedFeedbackEnabledClinic = false;
  if (enableTextBasedFeedbackClinic || enableTextBasedFeedbackClinic === null) {
    isTextBasedFeedbackEnabledClinic = true;
  }

  let isJournalReplyEnabledClinic = false;
  if (enableJournalReplyClinic || enableJournalReplyClinic === null) {
    isJournalReplyEnabledClinic = true;
  }

  const renderEntries = (journals, checkins) => {
    const allJournalEntries = [];
    // eslint-disable-next-line no-unused-expressions
    journals &&
      Object.keys(journals).forEach(journalId => {
        if (journals[journalId].journalEntries) {
          Object.keys(journals[journalId].journalEntries).forEach(
            journalEntrieTimestamp => {
              allJournalEntries.push({
                itemId: journalId,
                itemEntrieTimestamp: journalEntrieTimestamp,
                data:
                  journals[journalId].journalEntries[journalEntrieTimestamp],
                type: 'journal',
              });
            }
          );
        }
      });

    // filter by shareSettings.isAvailableToClinic where null/undefined not filtered
    const filteredJournalEntries = allJournalEntries.filter(journalEntrie => {
      if (!journalEntrie.data.shareSettings) {
        return true;
      }
      if (
        journalEntrie.data.shareSettings.isAvailableToClinic === null ||
        journalEntrie.data.shareSettings.isAvailableToClinic === undefined
      ) {
        return true;
      }
      return journalEntrie.data.shareSettings.isAvailableToClinic;
    });

    // const sortedJournalEntries = filteredJournalEntries.sort(
    //   (a, b) => b.journalEntrieTimestamp - a.journalEntrieTimestamp
    // );

    const allCheckinEntries = [];
    // eslint-disable-next-line no-unused-expressions
    checkins &&
      Object.keys(checkins).forEach(checkinId => {
        if (checkins[checkinId].journalEntries) {
          Object.keys(checkins[checkinId].journalEntries).forEach(
            checkinEntrieTimestamp => {
              allCheckinEntries.push({
                itemId: checkinId,
                itemEntrieTimestamp: checkinEntrieTimestamp,
                data:
                  checkins[checkinId].journalEntries[checkinEntrieTimestamp],
                type: 'checkin',
              });
            }
          );
        }
      });

    // filter by shareSettings.isAvailableToClinic where null/undefined not filtered
    const filteredCheckinsEntries = allCheckinEntries.filter(checkinEntrie => {
      if (!checkinEntrie.data.shareSettings) {
        return true;
      }
      if (
        checkinEntrie.data.shareSettings.isAvailableToClinic === null ||
        checkinEntrie.data.shareSettings.isAvailableToClinic === undefined
      ) {
        return true;
      }
      return checkinEntrie.data.shareSettings.isAvailableToClinic;
    });

    // const sortedCheckinEntries = filteredCheckinsEntries.sort(
    //   (a, b) => b.checkinEntrieTimestamp - a.checkinEntrieTimestamp
    // );

    const allSortedEntries = filteredJournalEntries
      .concat(filteredCheckinsEntries)
      .sort((a, b) => b.itemEntrieTimestamp - a.itemEntrieTimestamp);

    if (!allSortedEntries.length) {
      return (
        <div className={styles.goals_description}>
          This client didn’t share any entries yet.
        </div>
      );
    }

    const checkinsAndJournalsData = {
      ...journals,
      ...checkins,
    };

    let sortedAnswers = [];

    if (
      checkinsAndJournalsData[selectedItemId] &&
      checkinsAndJournalsData[selectedItemId].journalEntries &&
      checkinsAndJournalsData[selectedItemId].journalEntries[
        selectedItemEntry
      ] &&
      checkinsAndJournalsData[selectedItemId].journalEntries[selectedItemEntry]
        .otherQuizAnswers
    ) {
      sortedAnswers = Object.keys(
        checkinsAndJournalsData[selectedItemId].journalEntries[
          selectedItemEntry
        ].otherQuizAnswers
      ).sort((a, b) => {
        // eslint-disable-next-line no-nested-ternary
        return !!checkinsAndJournalsData[selectedItemId].journalEntries[
          selectedItemEntry
        ].otherQuizAnswers[a].skills ===
          !!checkinsAndJournalsData[selectedItemId].journalEntries[
            selectedItemEntry
          ].otherQuizAnswers[b].skills
          ? 0
          : // eslint-disable-next-line no-extra-boolean-cast
          !!checkinsAndJournalsData[selectedItemId].journalEntries[
              selectedItemEntry
            ].otherQuizAnswers[a].skills
          ? -1
          : 1;
      });
    }

    let sortedFeedbacks = [];

    if (
      checkinsAndJournalsData[selectedItemId] &&
      checkinsAndJournalsData[selectedItemId].journalEntries &&
      checkinsAndJournalsData[selectedItemId].journalEntries[
        selectedItemEntry
      ] &&
      checkinsAndJournalsData[selectedItemId].journalEntries[selectedItemEntry]
        .therapistComments
    ) {
      sortedFeedbacks = Object.keys(
        checkinsAndJournalsData[selectedItemId].journalEntries[
          selectedItemEntry
        ].therapistComments
      ).sort(
        (a, b) =>
          checkinsAndJournalsData[selectedItemId].journalEntries[
            selectedItemEntry
          ].therapistComments[a].timestamp -
          checkinsAndJournalsData[selectedItemId].journalEntries[
            selectedItemEntry
          ].therapistComments[b].timestamp
      );
    }

    const renderJournalTitle = entry => {
      if (entry.type === 'checkin') {
        return 'Check-in';
      }

      if (
        journalsData &&
        journalsData[entry.itemId] &&
        journalsData[entry.itemId].title &&
        journalsData[entry.itemId].title.en
      ) {
        return journalsData[entry.itemId].title.en;
      }
      if (entry.data.title) {
        return entry.data.title;
      }
      return null;
    };

    const renderMessageText = txt => {
      const splitted = txt.split('\n');
      return (
        <div>
          {splitted.map(str => (
            <div>{str}</div>
          ))}
        </div>
      );
    };

    return (
      <div className={styles.journal_details_container}>
        <div className={styles.journal_details_items}>
          {allSortedEntries.map(entry => {
            return (
              <div
                key={entry.itemEntrieTimestamp}
                className={styles.journal_entry_container}
                onClick={() => {
                  itemEntryClicked(
                    entry.itemId,
                    entry.itemEntrieTimestamp,
                    entry.type,
                    entry,
                    renderJournalTitle
                  );
                }}
                style={
                  entry.itemEntrieTimestamp === selectedItemEntry
                    ? { border: '1px solid #bcddf8' }
                    : null
                }
              >
                <div className={styles.emoji_container}>
                  {EMOJIMAP[entry.data.emojiRating]}
                </div>
                <div className={styles.date_title_container}>
                  <div className={styles.journal_entry_title}>
                    {renderJournalTitle(entry)}
                    {!entry.data.isSeen ? (
                      <span style={{ color: 'red', marginLeft: '10px' }}>
                        ⬤
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={styles.date_text}
                    style={false ? { justifyContent: 'space-between' } : null}
                  >
                    {entry.data.shareSettings &&
                    entry.data.shareSettings.isFeedbackReplyEnabled ? (
                      <div className={styles.feedback_asked_label}>
                        FEEDBACK ASKED
                      </div>
                    ) : (
                      <div />
                    )}
                    {parseDate(+entry.itemEntrieTimestamp, true)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={styles.journal_details_description}
          style={
            !selectedItemEntry
              ? { display: 'flex', justifyContent: 'center' }
              : null
          }
        >
          {selectedItemEntry ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                // maxHeight: 400,
                // overflowY: 'scroll',
                height: '100%',
              }}
            >
              {!isLagoEnabled &&
                (journalsData[selectedItemId] ||
                  selectedItemType === 'checkin') && (
                  <div className={styles.sticky_warning}>
                    <WarningIcon />
                    <div>
                      The client won’t be able to read your feedback on this
                      entry because you’ve disabled their access to the Journey
                      tab in-app. Enable Journeys from the Digital Care tab
                      above to give them access.
                    </div>
                  </div>
                )}
              {calcCategoriesStatus(selectedItemId) &&
                !(
                  journalsData[selectedItemId] || selectedItemType === 'checkin'
                ) && (
                  <div className={styles.sticky_warning}>
                    <WarningIcon />
                    <div>
                      {`The client won’t be able to read your feedback on this entry because you’ve limited their access to content in the Explore tab in-app. Enable ${calcCategoriesStatus(
                        selectedItemId
                      ).substring(
                        2
                      )} under Exercises & Resources from the Digital Care tab above to give them access.`}
                    </div>
                  </div>
                )}
              <div
                style={{
                  paddingLeft: 25,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  maxHeight: 300,
                  overflowY: 'scroll',
                  height: '100%',
                }}
              >
                {/* <div className={styles.chat_user_name}>{`${userInfo.firstName} ${userInfo.lastName}:`}</div> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                  ref={scrollableRef}
                >
                  <div
                    style={{
                      backgroundColor: '#F0F0F0',
                      width: '75%',
                      borderRadius: 5,
                    }}
                  >
                    {checkinsAndJournalsData[selectedItemId] &&
                      checkinsAndJournalsData[selectedItemId].journalEntries &&
                      checkinsAndJournalsData[selectedItemId].journalEntries[
                        selectedItemEntry
                      ] &&
                      checkinsAndJournalsData[selectedItemId].journalEntries[
                        selectedItemEntry
                      ].otherQuizAnswers &&
                      sortedAnswers.map(quizAnswerId => {
                        if (selectedItemType === 'journal') {
                          return (
                            <div
                              key={quizAnswerId}
                              className={styles.quiz_qna_container}
                            >
                              <div className={styles.quiz_question_container}>
                                {
                                  checkinsAndJournalsData[selectedItemId]
                                    .journalEntries[selectedItemEntry]
                                    .otherQuizAnswers[quizAnswerId].question
                                }
                              </div>
                              <div>
                                {checkinsAndJournalsData[
                                  selectedItemId
                                ].journalEntries[
                                  selectedItemEntry
                                ].otherQuizAnswers[quizAnswerId].answer.replace(
                                  'cust0m:',
                                  ''
                                )}
                              </div>
                            </div>
                          );
                        }
                        if (selectedItemType === 'checkin') {
                          if (
                            checkinsAndJournalsData[selectedItemId]
                              .journalEntries[selectedItemEntry]
                              .otherQuizAnswers[quizAnswerId].skills
                          ) {
                            const skillsArray = Object.keys(
                              checkinsAndJournalsData[selectedItemId]
                                .journalEntries[selectedItemEntry]
                                .otherQuizAnswers[quizAnswerId].skills
                            ).map(skillName => {
                              return {
                                name: skillName,
                                value:
                                  checkinsAndJournalsData[selectedItemId]
                                    .journalEntries[selectedItemEntry]
                                    .otherQuizAnswers[quizAnswerId].skills[
                                    skillName
                                  ],
                              };
                            });
                            return (
                              <div className={styles.quiz_qna_container}>
                                <div className={styles.quiz_question_container}>
                                  {
                                    checkinsAndJournalsData[selectedItemId]
                                      .journalEntries[selectedItemEntry]
                                      .otherQuizAnswers[quizAnswerId].question
                                  }
                                </div>
                                <div>
                                  {skillsArray.map(skill => {
                                    return (
                                      <div
                                        className={
                                          styles.checkin_skills_container
                                        }
                                      >
                                        <div
                                          style={{
                                            width: 100,
                                            marginBottom: 10,
                                          }}
                                        >
                                          {`• ${skill.name}`}
                                        </div>
                                        <div>
                                          {skill.value ? (
                                            <CheckIcon
                                              style={{ color: '#4BE0D0' }}
                                            />
                                          ) : (
                                            <CloseIcon
                                              style={{ color: '#FF7D7D' }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              key={quizAnswerId}
                              className={styles.quiz_qna_container}
                            >
                              <div className={styles.quiz_question_container}>
                                {
                                  checkinsAndJournalsData[selectedItemId]
                                    .journalEntries[selectedItemEntry]
                                    .otherQuizAnswers[quizAnswerId].question
                                }
                              </div>
                              <div>
                                {
                                  checkinsAndJournalsData[selectedItemId]
                                    .journalEntries[selectedItemEntry]
                                    .otherQuizAnswers[quizAnswerId].answer
                                }
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                  <div className={styles.chat_date_message}>
                    {parseDate(+selectedItemEntry)}
                  </div>
                  {checkinsAndJournalsData &&
                    checkinsAndJournalsData[selectedItemId] &&
                    checkinsAndJournalsData[selectedItemId].journalEntries &&
                    checkinsAndJournalsData[selectedItemId].journalEntries[
                      selectedItemEntry
                    ] &&
                    checkinsAndJournalsData[selectedItemId].journalEntries[
                      selectedItemEntry
                    ].therapistComments &&
                    sortedFeedbacks.map(commentId => {
                      return (
                        <div
                          className={styles.chat_message_container}
                          style={
                            !checkinsAndJournalsData[selectedItemId]
                              .journalEntries[selectedItemEntry]
                              .therapistComments[commentId].fromUser
                              ? { alignSelf: 'end', marginRight: 15 }
                              : null
                          }
                        >
                          <div className={styles.chat_user_name}>
                            {!checkinsAndJournalsData[selectedItemId]
                              .journalEntries[selectedItemEntry]
                              .therapistComments[commentId].fromUser
                              ? 'You:'
                              : `${userInfo.firstName} ${userInfo.lastName}:`}
                          </div>
                          <div>
                            <div
                              className={styles.chat_message_text}
                              style={
                                !checkinsAndJournalsData[selectedItemId]
                                  .journalEntries[selectedItemEntry]
                                  .therapistComments[commentId].fromUser
                                  ? { backgroundColor: '#E3F5FD' }
                                  : null
                              }
                              onMouseEnter={() => setHoveredComment(commentId)}
                              onMouseLeave={() => setHoveredComment('')}
                            >
                              {renderMessageText(
                                checkinsAndJournalsData[selectedItemId]
                                  .journalEntries[selectedItemEntry]
                                  .therapistComments[commentId].txt
                              )}
                              {checkinsAndJournalsData[selectedItemId]
                                .journalEntries[selectedItemEntry]
                                .therapistComments[commentId].fromUser ||
                              hoveredComment !== commentId ? null : (
                                <div className={styles.delete_comment_icon}>
                                  <div
                                    style={{
                                      backgroundColor: 'white',
                                      borderRadius: '50%',
                                      padding: 5,
                                      height: 30,
                                      width: 30,
                                      boxShadow:
                                        '0px 0px 5px 0px rgba(0,0,0,0.2)',
                                    }}
                                  >
                                    <DeleteIcon
                                      onClick={() =>
                                        deleteCommentClicked(commentId)
                                      }
                                      // color="primary"
                                      style={{
                                        cursor: 'pointer',
                                        height: 20,
                                        width: 20,
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={styles.chat_date_message}
                            style={
                              !checkinsAndJournalsData[selectedItemId]
                                .journalEntries[selectedItemEntry]
                                .therapistComments[commentId].fromUser
                                ? { alignSelf: 'end' }
                                : null
                            }
                          >
                            {parseDate(
                              checkinsAndJournalsData[selectedItemId]
                                .journalEntries[selectedItemEntry]
                                .therapistComments[commentId].timestamp
                            )}
                          </div>
                          {/* <div>
                          <div
                            style={{
                              color: '#d5d5d5',
                              fontSize: '0.9em',
                              marginLeft: '15px',
                            }}
                          >
                            {parseDate(
                              checkinsAndJournalsData[selectedItemId]
                                .journalEntries[selectedItemEntry]
                                .therapistComments[commentId].timestamp
                            )}
                          </div>
                          <div style={{ marginLeft: '15px' }}>
                            {
                              checkinsAndJournalsData[selectedItemId]
                                .journalEntries[selectedItemEntry]
                                .therapistComments[commentId].txt
                            }
                          </div>
                        </div>
                        <DeleteIcon
                          onClick={() => deleteCommentClicked(commentId)}
                          color="primary"
                          style={{ cursor: 'pointer' }}
                        /> */}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                style={{ backgroundColor: '#f5f5f560', marginTop: 10 }}
                className={styles.feedback_container}
              >
                {/* <div
                  style={{
                    margin: '20px 10px 20px 20px',
                    fontWeight: 'bold',
                  }}
                >
                  Feedback:{' '}
                </div> */}
                <TextField
                  multiline
                  value={therapistComment}
                  onChange={e => setTherapistComment(e.target.value)}
                  // label="Feedback"
                  placeholder="Write your feedback on this entry here…"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  style={{
                    margin: '10px 15px 10px',
                    maxWidth: '90%',
                  }}
                  InputProps={{
                    classes: {
                      input: styles.resize,
                      root: styles.outlinedInput,
                    },
                  }}
                  margin="dense"
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={sendCommentClicked}
                    color="primary"
                    variant="contained"
                    style={{ fontSize: '1.1em', margin: '20px' }}
                    disabled={!therapistComment.trim()}
                  >
                    send feedback
                    <SendIcon style={{ marginLeft: '5px' }} />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.not_selected_placeholder_container}>
              <img src={noSelected} width={100} height={75} />
              <div className={styles.not_selected_placeholder_container_text}>
                <div>Select an entry to be able to view</div>
                <div>and give feedback to your client</div>
              </div>
            </div>
          )}
        </div>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          // maxWidth="false"
        >
          <div style={{ width: 420 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: '30px',
              }}
            >
              <img
                src={warningIcon}
                style={{ width: '70px', height: '70px' }}
              />
            </div>
            <div style={{ padding: '0 30px' }}>
              <DialogTitle style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '1.5em' }}>
                  Are you sure you want to delete this feedback?
                </span>
              </DialogTitle>
              <DialogContent style={{ textAlign: 'center' }}>
                Your client may have already seen it, and it will be permanently
                deleted from the Myndlift app
              </DialogContent>
            </div>
            <DialogActions>
              <Button
                style={{
                  height: '40px',
                  width: '120px',
                  fontSize: '1.05em',
                  color: '#8d8d8d',
                }}
                onClick={() => {
                  setIsDeleteDialogOpen(false);
                  setCommentToDelete('');
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  backgroundColor: '#f67166',
                  color: 'white',
                  height: '40px',
                  width: '120px',
                  fontSize: '1.05em',
                }}
                variant="contained"
                onClick={deleteComment}
              >
                yes, delete!
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  };

  if (
    enableJournalSharing !== initialValue &&
    enableJournalReply !== initialValue &&
    enableTextBasedFeedback !== initialValue
  ) {
    return (
      <div>
        {isJourneyEnabledClinic &&
          (enableJournalSharing || enableJournalSharing === null) && (
            <Paper className={styles.root_paper}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 20,
                }}
              >
                <div
                  className={styles.goals_title}
                  style={{ marginBottom: 10, fontSize: 17 }}
                >
                  Client Entries
                  {/* <span
                  style={{
                    color:
                      isJourneyEnabledClinic &&
                      (enableJournalSharing || enableJournalSharing === null)
                        ? '#52a09f'
                        : '#fb3732',
                  }}
                  className={styles.toggle_lago_enable_operator}
                >
                  {isJourneyEnabledClinic &&
                  (enableJournalSharing || enableJournalSharing === null)
                    ? 'ON'
                    : 'OFF'}
                </span> */}
                </div>
                {/* <div className={styles.goals_description}>
            {isJourneyEnabledClinic &&
            (enableJournalSharing || enableJournalSharing === null)
              ? 'This client can share journal/check-in entries.'
              : 'This client can’t share journal/check-in entries.'}
          </div> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={styles.goals_title}>Share with my provider</div>
              <div className={styles.goals_description}>
                This client can choose to share their entries and allow you to
                provide text-based feedback.
              </div>
            </div>
          </div> */}
                {journalsData && (userDataJournals || userDataCheckins)
                  ? renderEntries(userDataJournals, userDataCheckins)
                  : null}
                {/* <div className={styles.alert_container} style={{ marginTop: 20 }}>
                <div className={styles.alert_size}>
                  <CustomAlert severity="warning">
                    Available on iOS devices only (2.4.9 and later). Will be
                    available on Android soon.
                  </CustomAlert>
                </div>
              </div> */}
              </div>
            </Paper>
          )}
        <Paper className={styles.root_paper}>
          <div className={styles.paper_header}>Entries Preferences</div>
          <div>
            <div className={styles.switch_item_title_switch}>
              <div className={styles.switch_item_title}>
                Allow client to share entries with me
              </div>
              <TooltipWrapper
                disabled={isJourneyEnabledClinic}
                title={
                  <TooltipTitle>
                    You disabled Journal Sharing for all users. To turn it on,
                    please enable Journal Sharing from the Preferences.
                  </TooltipTitle>
                }
              >
                <div>
                  <Switch
                    checked={
                      enableJournalSharing || enableJournalSharing === null
                    }
                    onChange={() => {
                      setEnableJournalSharing(
                        enableJournalSharing === null
                          ? false
                          : !enableJournalSharing
                      );
                      if (
                        enableJournalSharing ||
                        enableJournalSharing === null
                      ) {
                        setEnableTextBasedFeedback(false);
                        setEnableJournalReply(false);
                      }
                      lagoDashboardContentLogger.log(
                        'client_entry_toggle-clicked',
                        {
                          patientId: customerInfo.id,
                          isEnabled:
                            enableJournalSharing === null
                              ? false
                              : !enableJournalSharing,
                        }
                      );
                    }}
                    disabled={!isJourneyEnabledClinic}
                    color="primary"
                  />
                </div>
              </TooltipWrapper>
            </div>
            <div className={styles.switch_item_description}>
              When enabled, this client can choose to share their entries with
              you to read.
            </div>
            <div className={styles.divider} />
          </div>
          <div>
            <div className={styles.switch_item_title_switch}>
              <div className={styles.switch_item_title}>
                Allow client to request text-based feedback on their entries
              </div>
              <TooltipWrapper
                disabled={isTextBasedFeedbackEnabledClinic}
                title={
                  <TooltipTitle>
                    You disabled Client Text-based for all users. To turn it on,
                    please enable Client Text-based from the Preferences.
                  </TooltipTitle>
                }
              >
                <div>
                  <Switch
                    checked={
                      enableTextBasedFeedback ||
                      enableTextBasedFeedback === null
                    }
                    onChange={() => {
                      setEnableTextBasedFeedback(
                        enableTextBasedFeedback === null
                          ? false
                          : !enableTextBasedFeedback
                      );
                      // if (
                      //   enableTextBasedFeedback ||
                      //   enableTextBasedFeedback === null
                      // ) {
                      //   setEnableJournalReply(false);
                      // }
                      if (enableTextBasedFeedback === false) {
                        setEnableJournalSharing(true);
                      }
                      lagoDashboardEntriesLogger.log(
                        'allow_client_request_feedback_toggled',
                        {
                          patientId: customerInfo.id,
                          isEnabled:
                            enableTextBasedFeedback === null
                              ? false
                              : !enableTextBasedFeedback,
                        }
                      );
                    }}
                    disabled={!isTextBasedFeedbackEnabledClinic}
                    color="primary"
                  />
                </div>
              </TooltipWrapper>
            </div>
            <div className={styles.switch_item_description}>
              When enabled, this client can request that you provide text-based
              feedback on entries of their choosing.
            </div>
            <div className={styles.divider} />
          </div>
          <div>
            <div className={styles.switch_item_title_switch}>
              <div className={styles.switch_item_title}>
                Allow client to reply to my feedback on their entries
              </div>
              <TooltipWrapper
                disabled={isJournalReplyEnabledClinic}
                title={
                  <TooltipTitle>
                    You disabled Client Reply To Feedback for all users. To turn
                    it on, please enable Client Reply To Feedback from the
                    Preferences.
                  </TooltipTitle>
                }
              >
                <div>
                  <Switch
                    checked={enableJournalReply || enableJournalReply === null}
                    onChange={() => {
                      setEnableJournalReply(
                        enableJournalReply === null
                          ? false
                          : !enableJournalReply
                      );
                      if (enableJournalReply === false) {
                        setEnableJournalSharing(true);
                        // setEnableTextBasedFeedback(true);
                      }
                      lagoDashboardEntriesLogger.log(
                        'allow_client_feedback_reply_toggled',
                        {
                          patientId: customerInfo.id,
                          isEnabled:
                            enableJournalReply === null
                              ? false
                              : !enableJournalReply,
                        }
                      );
                    }}
                    disabled={!isJournalReplyEnabledClinic}
                    color="primary"
                  />
                </div>
              </TooltipWrapper>
            </div>
            <div className={styles.switch_item_description}>
              When enabled, this client can reply to the text-based feedback
              that you provide.
            </div>
          </div>
          {/* <div className={styles.alert_container} style={{ marginTop: 20 }}>
          <div className={styles.alert_size}>
            <CustomAlert severity="warning">
              Available on iOS devices only (2.4.9 and later). Will be available
              on Android soon.
            </CustomAlert>
          </div>
        </div> */}
        </Paper>
      </div>
    );
  }
  return null;
};

EntriesTabBase.propTypes = exact({
  isLagoEnabled: PropTypes.bool,
  calcCategoriesStatus: PropTypes.func,
  notificationsAlerts: PropTypes.object,
  editNotificationsAlerts: PropTypes.func,
});

export const EntriesTab = React.memo(EntriesTabBase);
EntriesTab.displayName = 'EntriesTab';

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Stories from 'react-insta-stories';
import html2canvas from 'html2canvas';
import ShareIcon from '@material-ui/icons/Share';
import { Button } from '@material-ui/core';
import { StoryPage } from './StoryPage';
import { detectOS, notifyAppThatStoryShareWasClicked } from '../../utils/utils';
// import { CSSTransition } from 'react-transition-group';
// import styles from './YearWrap.scss';

const YearWrapBase = ({
  data,
  showLogo,
  //  lottieSources
}) => {
  const printRef = useRef(null);

  const os = detectOS();

  // const [isLoadingGIFs, setIsLoadingGIFs] = useState(true);
  // const [isPaused, setIsPaused] = useState(false);
  // const [playLottie, setPLayLottie] = useState(0);
  // const [
  //   backgroundAnimationDelayState,
  //   setBackgroundAnimationDelayState,
  // ] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  const stories = data.map((dataItem, idx) => {
    return {
      content: ({ action }) => {
        useEffect(() => {
          setTimeout(() => action('play'));
        }, []);
        return (
          <StoryPage
            background={dataItem.background}
            backgroundAnimationAutoPlay={dataItem.backgroundAnimationAutoPlay}
            backgroundAnimationDelay={dataItem.backgroundAnimationDelay}
            backgroundAnimationPlaySpeed={dataItem.backgroundAnimationPlaySpeed}
            action={action}
            containers={dataItem.containers}
            sequences={dataItem.sequences}
            storyIndex={idx}
            setCurrentIndex={setCurrentIndex}
            showLogo={showLogo}
            // setBackgroundAnimationDelayState={setBackgroundAnimationDelayState}
            // exposeToParent={playLottie}
          />
        );
      },
      duration: dataItem.storyDuration,
    };
  });

  useEffect(() => {
    // eslint-disable-next-line func-names
    // eslint-disable-next-line space-before-function-paren
    window.resetStoriesIndex = function() {
      // console.log('resetStoriesIndex() was called from Swift!');
      setCurrentIndex(0);
    };
    return () => {
      delete window.resetStoriesIndex; // Cleanup on unmount
    };
  }, []);

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data1 = canvas.toDataURL();
    // eslint-disable-next-line no-undef
    const blob = await (await fetch(data1)).blob();
    // eslint-disable-next-line no-undef
    const file = new File([blob], 'myndlift_summary.png', { type: blob.type });
    // eslint-disable-next-line no-undef
    if (navigator.share) {
      try {
        // eslint-disable-next-line no-undef
        await navigator.share({
          title: 'Myndlift Summary',
          text: 'Check out my neurofeedback performance!!',
          files: [file],
        });
        // console.log('Sharing successful');
      } catch (error) {
        // console.error('Error sharing:', error);
      }
    }
  };

  const onShareStory = e => {
    e.stopPropagation();
    notifyAppThatStoryShareWasClicked();
    handleDownloadImage();
  };

  // useEffect(() => {
  //   // Pre load assets before rendering
  //   const loadImages = gifSources.map(src => {
  //     return new Promise((resolve, reject) => {
  //       // eslint-disable-next-line no-undef
  //       const img = new Image();
  //       img.src = src;
  //       img.onload = () => resolve();
  //       // eslint-disable-next-line prefer-promise-reject-errors
  //       img.onerror = () => reject(`Failed to load GIF: ${src}`);
  //     });
  //   });

  //   Promise.all(loadImages)
  //     .then(() => setIsLoadingGIFs(false))
  //     .catch(() => setIsLoadingGIFs(false));
  // }, []);

  // useEffect(() => {
  //   const loadImages = lottieSources.map(src => {
  //     return new Promise((resolve, reject) => {
  //       fetch(src)
  //         .then(response => {
  //           if (!response.ok) {
  //             reject(`Failed to fetch Lottie JSON: ${src}`);
  //             return;
  //           }
  //           return response.json();
  //         })
  //         .then(() => resolve())
  //         .catch(error => reject(error));
  //     });
  //   });
  // }, []);

  // if (isLoadingGIFs) {
  //   return <>Loading GIFs...</>;
  // }

  // const handlePreviousClicked = () => {
  //   // useEffect(() => {
  //   // const timer =
  //   setTimeout(() => {
  //     console.log(backgroundAnimationDelayState / 8);

  //     setPLayLottie(prev => prev + 1);
  //   }, backgroundAnimationDelayState);

  //   // return () => clearTimeout(timer); // Cleanup timer on unmount
  //   // }, []);
  // };

  return (
    <>
      <div ref={printRef}>
        <Stories
          stories={stories}
          defaultInterval={11700}
          width="100%"
          height="100vh"
          keyboardNavigation
          loop
          // header
          // onPrevious={() => {
          // if (lottieRef) lottieRef.current.play();
          // handlePreviousClicked();
          // setPLayLottie(prev => prev + 1);
          // }}
          // isPaused={isPaused}
          currentIndex={currentIndex}
          // onNext={(story, index) => console.log(story)}
          // onStoryStart={(story, index) => {
          //   console.log('a story started ');
          //   setCurrentIndex(story);
          //   console.log('Story started:', story, index, currentIndex);
          // }}
          // onStoryEnd={(s, i) => console.log(s, i)}
          // onAllStoriesEnd={() =>
          //   document.getElementById('story-container')?.focus()
          // }
          // onAllStoriesEnd={() => (window.location.href = '/')}
          // onAllStoriesEnd={() => {
          //   console.log('entered all end');
          //   setCurrentIndex(0);
          // }}
          // onPrevious={() => console.log('logged')}
          // onAllStoriesEnd={(s, st) => {
          //   console.log('all stories ended', s, st);
          //   if (currentIndex > 0) {
          //     setCurrentIndex(prev => prev - 1);
          //   }
          // }}
        />
      </div>
      {os === 'iOS' && (
        <Button
          style={{
            position: 'absolute',
            bottom: 10,
            right: 10,
            zIndex: 9999,
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: 20,
          }}
          onClick={onShareStory}
        >
          <ShareIcon />
        </Button>
      )}

      {/* <Button
        style={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          zIndex: 9999,
          backgroundColor: 'white',
          borderRadius: '50%',
          padding: 20,
        }}
        onClick={() => {
          console.log(currentIndex);
          // if (currentIndex > 0) {
          // setCurrentIndex(prev => prev - 1);
          // setCurrentIndex(0);
          // setCurrentIndex(currentIndex);
          // }
          resetStoriesIndex();
        }}
      >
        hit me
      </Button> */}
    </>
  );
};

YearWrapBase.propTypes = exact({
  data: PropTypes.array,
  // lottieSources: PropTypes.array,
});

export const YearWrap = React.memo(YearWrapBase);
YearWrap.displayName = 'YearWrap';

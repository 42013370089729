import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
import countryTelData from 'country-telephone-data';
import { TextField, Backdrop, CircularProgress } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { BillingSummary } from './BillingSummary/BillingSummary';
import Logo from '../../../assets/modern_logo_black.png';
import ConfettiGif from '../../../assets/confetti-animation.gif';
import styles from './RegisterAssessmentClinic.scss';
import {
  isValidEmail,
  isValidPhone,
  postToFunctionsWithDefaultData,
  removeAllAfterLastChar,
  getEventLogger,
} from '../../utils/utils';
import {
  usaStates,
  canadaProvinces,
} from '../../models/mtr-subscription/countriesData';

const countriesDataList = cloneDeep(countryTelData).allCountries.map(
  country => {
    const newCountry = cloneDeep(country);
    newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
    newCountry.iso2 = country.iso2;
    return newCountry;
  }
);

const stripeElementStyle = {
  base: {
    color: '#585858',
  },
};

const logClinicRegistrationEvent = getEventLogger({
  prefix: 'register_assessment_clinic',
});

const RegisterAssessmentClinicBase = ({ dormantPlan, stripe }) => {
  const cardElementRef = useRef();
  const containerEl = useRef();

  // choose plan inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  // choose plan input errors
  const [phoneError, setPhoneError] = useState(false);
  const [phoneLengthError, setPhoneLengthError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailExistError, setEmailExistError] = useState(false);
  const [paswordLengthError, setPaswordLengthError] = useState(false);
  const [paswordMatchError, setPaswordMatchError] = useState(false);

  const [displayFirstStepError, setDisplayFirstStepError] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // cuopon
  const [couponCode, setCouponCode] = useState('');
  const [appliedCouponCode, setAppliedCouponCode] = useState('');
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);

  const [couponDiscount, setCouponDiscount] = useState(null);
  const [couponCodeError, setCouponCodeError] = useState(null);

  // payment stage inputs
  const [namePayment, setNamePayment] = useState('');
  const [country, setCountry] = useState('us');
  const [stateInput, setStateInput] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [apt, setApt] = useState('');

  // payment stage errors
  const [creditCardError, setCreditCardError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationPage, setIsConfirmationPage] = useState(false);

  const handlePayment = () => {
    logClinicRegistrationEvent({
      name: 'on_pay',
    });
    stripe.createToken().then(async result => {
      if (result.error) {
        setIsLoading(false);
        setCreditCardError(result.error.message);
      } else {
        let res;
        try {
          // eslint-disable-next-line no-undef
          res = await fireFunctionPost('clinics-registerOnDormantPlans', {
            form: {
              email,
              password,
              firstName,
              lastName,
              clinicName,
              PhoneNumber: phoneNumber,
              City: city,
              Country: country,
              State: stateInput,
              StreetAddressLine1: `${street} ${apt}`,
              Zipcode: zipCode,
            },
            paymentToken: result.token.id,
            couponCode:
              couponDiscount && couponDiscount.amount ? appliedCouponCode : '',
          });
          if (res.result === true) {
            setIsLoading(false);
            const { gtoken, fbtoken, intercomInfo } = res;
            sessionStorage.fbtoken = fbtoken;
            sessionStorage.userId = gtoken;
            sessionStorage.intercomInfo = JSON.stringify(intercomInfo);
            const userInfo = res[gtoken];
            sessionStorage.userInfo = JSON.stringify(userInfo);
            await new Promise(resolve =>
              // eslint-disable-next-line no-undef
              loginToFirebaseWithToken(res, resolve)
            );
            setIsConfirmationPage(true);
            logClinicRegistrationEvent({
              name: 'payment_success',
            });
          } else {
            if (res.message === 'Payment Issue') {
              // setCreditCardError(e.message);
              Swal.fire({
                title: 'Error processing credit card',
                html:
                  '<span style="font-size: 14px;">Unfortunately, we were unable to charge your credit card. Please contact your credit card provider or enter a different card to continue.\nIf the issue persists, please contact support@myndlift.com</span>',
                icon: 'warning',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'OK',
                closeOnConfirm: true,
              });
            } else {
              setDisplayFirstStepError(true);
            }
            logClinicRegistrationEvent({
              name: 'payment_failed',
            });
          }
        } catch (e) {
          if (e.message === 'Payment Issue') {
            // setCreditCardError(e.message);
            Swal.fire({
              title: 'Error processing credit card',
              html:
                '<span style="font-size: 14px;">Unfortunately, we were unable to charge your credit card. Please contact your credit card provider or enter a different card to continue.\nIf the issue persists, please contact support@myndlift.com</span>',
              icon: 'warning',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            });
          }
          setIsLoading(false);
          setDisplayFirstStepError(true);
          logClinicRegistrationEvent({
            name: 'payment_failed',
          });
        }
      }
    });
  };

  // eslint-disable-next-line no-shadow
  const validateIfEmailIsUsed = async email => {
    const doesUserExistRequest = await postToFunctionsWithDefaultData({
      path: 'doesUserExist',
      data: { email, isClinic: true },
    });
    return doesUserExistRequest.exists;
  };

  const clearErrors = () => {
    setPhoneError(false);
    setPhoneLengthError(false);
    setEmailError(false);
    setPaswordLengthError(false);
    setPaswordMatchError(false);
    setEmailExistError(false);
  };

  const getFirstErrorId = () => {
    if (!firstName.trim()) {
      return 'first_name_input';
    }
    if (!lastName.trim()) {
      return 'last_name_input';
    }
    if (!phoneNumber || phoneLengthError) {
      return 'phone_input';
    }
    if (!clinicName.trim()) {
      return 'clinic_name_input';
    }
    if (!email || emailError || emailExistError) {
      return 'email_input';
    }
    if (!password || paswordLengthError) {
      return 'password_input';
    }
    if (!passwordRepeat || paswordMatchError) {
      return 'repeat_password_input';
    }
    if (!stateInput && country === 'us') {
      return 'state_input';
    }
    if (!city) {
      return 'city_input';
    }
    if (!street) {
      return 'streat_input';
    }
    if (!zipCode) {
      return 'zip_input';
    }
    return null;
  };

  const choosePlanInputValidation = async () => {
    let answer = true;
    setIsFormSubmitted(true);
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !phoneNumber ||
      !clinicName.trim() ||
      !email ||
      !password ||
      !passwordRepeat ||
      !city ||
      !street ||
      !zipCode ||
      (!stateInput && country === 'us')
    ) {
      answer = false;
    }
    if (phoneNumber.length < 5) {
      setPhoneLengthError(true);
      answer = false;
    } else if (!isValidPhone(phoneNumber)) {
      setPhoneError(true);
      answer = false;
    }
    if (!isValidEmail(email)) {
      setEmailError(true);
      answer = false;
    } else if (await validateIfEmailIsUsed(email)) {
      setEmailExistError(true);
      answer = false;
    }
    if (password.length < 6) {
      setPaswordLengthError(true);
      answer = false;
    } else if (password !== passwordRepeat) {
      setPaswordMatchError(true);
      answer = false;
    }
    return answer;
  };

  const onContinueClicked = async () => {
    logClinicRegistrationEvent({
      name: 'on_continue_clicked',
    });
    if (isLoading) {
      return;
    }
    clearErrors();
    setIsLoading(true);
    const isInputValid = await choosePlanInputValidation();
    if (isInputValid) {
      await handlePayment();
    } else {
      if (getFirstErrorId()) {
        const element = document.getElementById(getFirstErrorId());
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      setIsLoading(false);
    }
  };

  const onApllyCoupon = async () => {
    logClinicRegistrationEvent({
      name: 'on_apply_coupon',
    });
    // const additionalHardware = arrangeHardware();
    // eslint-disable-next-line no-undef
    // const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
    setIsLoadingCoupon(true);
    let response;
    try {
      // eslint-disable-next-line no-undef
      response = await fireFunctionPost('shop-clinicRegisterDiscountAmount', {
        nonf: true,
        couponCode,
        planDetails: {
          packageIndex: dormantPlan.packageIndex,
          isAnnual: dormantPlan.isAnnual,
          groupId: dormantPlan.groupId,
        },
      });
    } catch (e) {
      setCouponCodeError('Invalid Coupon');
    }

    // const responseData = await response.json();
    setIsLoadingCoupon(false);
    if (
      response &&
      response.result &&
      response.discountedAmount !== undefined &&
      response.discountedAmount > 0
    ) {
      setCouponDiscount({
        type: response.discountType,
        amount: response.discountedAmount,
      });

      setAppliedCouponCode(couponCode);

      if (!response.result) {
        setCouponCodeError('Invalid Coupon');
      } else {
        setCouponCodeError(null);
      }
    } else {
      setCouponCodeError(response.message || 'Invalid Coupon');
    }
  };

  const onCreditCardChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardError(e.error.message);
    } else if (e.empty) {
      setCreditCardError('Credit card details are required');
    } else {
      setCreditCardError(null);
    }
  }, []);

  const onCountryChange = event => {
    const newValue = event.target.value;
    setCountry(newValue);
    setStateInput('');
  };

  const loginToDashboard = async () => {
    window.history.pushState({}, null, '/');
    window.open('/shop/shop-hardware.html', '_self');
  };

  const renderCreateAccount = () => {
    return (
      <div className={styles.choose_plan_container}>
        <div>
          <div
            className={styles.account_info_container}
            style={{ paddingBottom: 1 }}
          >
            <div className={styles.account_info_title}>Create your account</div>
            <div className={styles.inputs}>
              <div className={styles.inputs_container}>
                <TextField
                  label="First name"
                  error={isFormSubmitted && !firstName.trim()}
                  helperText={
                    isFormSubmitted &&
                    !firstName.trim() &&
                    'First Name is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                  id="first_name_input"
                />
                <TextField
                  label="Last name"
                  error={isFormSubmitted && !lastName.trim()}
                  helperText={
                    isFormSubmitted &&
                    !lastName.trim() &&
                    'Last Name is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                  id="last_name_input"
                />
              </div>
              <div className={styles.inputs_container}>
                <TextField
                  label="Phone"
                  error={
                    isFormSubmitted &&
                    (!phoneNumber || phoneError || phoneLengthError)
                  }
                  helperText={
                    phoneLengthError
                      ? 'Phone should be at least 5 numbers'
                      : phoneError && 'Invalid phone number'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                  }}
                  id="phone_input"
                />
                <TextField
                  label="Clinic Name"
                  error={isFormSubmitted && !clinicName.trim()}
                  helperText={
                    isFormSubmitted &&
                    !clinicName.trim() &&
                    'Clinic name is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={clinicName}
                  onChange={e => {
                    setClinicName(e.target.value);
                  }}
                  id="clinic_name_input"
                />
              </div>

              <div className={styles.inputs_container}>
                <TextField
                  label="Email"
                  error={
                    isFormSubmitted && (!email || emailError || emailExistError)
                  }
                  helperText={
                    emailError
                      ? 'Email is not valid'
                      : emailExistError && 'Email already exists'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  id="email_input"
                />
              </div>

              <div className={styles.inputs_container}>
                <TextField
                  label="Choose Password"
                  error={isFormSubmitted && (!password || paswordLengthError)}
                  helperText={
                    paswordLengthError &&
                    'Password should be at least 6 characters'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  inputProps={{
                    type: 'password',
                    autocomplete: 'new-password',
                  }}
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  id="password_input"
                />

                <TextField
                  label="Repeat Password"
                  error={
                    isFormSubmitted && (!passwordRepeat || paswordMatchError)
                  }
                  helperText={
                    paswordMatchError &&
                    'Confirm password is not matching password'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  inputProps={{
                    type: 'password',
                  }}
                  value={passwordRepeat}
                  onChange={e => {
                    setPasswordRepeat(e.target.value);
                  }}
                  id="repeat_password_input"
                />
              </div>
              <div className={styles.inputs_container}>
                <TextField
                  label="Country"
                  select
                  name="Country"
                  onChange={onCountryChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  value={country}
                  inputProps={{
                    name: 'country',
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    classes: {
                      root: styles.select_country_root,
                    },
                  }}
                  id="country_input"
                >
                  {countriesDataList.map(countryItem => (
                    <option key={countryItem.iso2} value={countryItem.iso2}>
                      {countryItem.name}
                    </option>
                  ))}
                </TextField>
                {!(country === 'us' || country === 'ca') ? (
                  <TextField
                    label="State"
                    error={isFormSubmitted && !stateInput && country === 'us'}
                    helperText={
                      isFormSubmitted &&
                      !stateInput &&
                      country === 'us' &&
                      'State is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={stateInput}
                    onChange={e => {
                      setStateInput(e.target.value);
                    }}
                  />
                ) : (
                  <TextField
                    label=""
                    error={isFormSubmitted && !stateInput && country === 'us'}
                    helperText={
                      isFormSubmitted &&
                      !stateInput &&
                      country === 'us' &&
                      'State is required'
                    }
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        root: styles.form_helper_text,
                      },
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      classes: {
                        root: styles.label_text,
                      },
                    }}
                    value={stateInput}
                    onChange={e => {
                      setStateInput(e.target.value);
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="state_input"
                  >
                    {(country === 'us' ? usaStates : canadaProvinces).map(
                      state => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      )
                    )}
                  </TextField>
                )}
              </div>
              <div className={styles.inputs_container}>
                <TextField
                  label="City"
                  error={isFormSubmitted && !city}
                  helperText={isFormSubmitted && !city && 'City is required'}
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={city}
                  onChange={e => {
                    setCity(e.target.value);
                  }}
                  id="city_input"
                />
                <TextField
                  label="Street"
                  error={isFormSubmitted && !street}
                  helperText={
                    isFormSubmitted && !street && 'Street is required'
                  }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  value={street}
                  onChange={e => {
                    setStreet(e.target.value);
                  }}
                  id="street_input"
                />
              </div>
              <div className={styles.inputs_container}>
                <TextField
                  label="Apt, Floor"
                  // error={isPaymentFormSubmitted && !aptPayment}
                  // helperText={
                  //   paswordLengthError &&
                  //   'Password should be at least 6 characters'
                  // }
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  // inputProps={{
                  //   type: 'password',
                  //   autocomplete: "new-password"
                  // }}
                  value={apt}
                  onChange={e => {
                    setApt(e.target.value);
                  }}
                />
                <TextField
                  label="Zip/Postal Code"
                  error={isFormSubmitted && !zipCode}
                  helperText={isFormSubmitted && !zipCode && 'Zip is required'}
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  FormHelperTextProps={{
                    classes: {
                      root: styles.form_helper_text,
                    },
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    classes: {
                      root: styles.label_text,
                    },
                  }}
                  // inputProps={{
                  //   type: 'password',
                  //   autocomplete: "new-password"
                  // }}
                  value={zipCode}
                  onChange={e => {
                    setZipCode(e.target.value);
                  }}
                  id="zip_input"
                />
              </div>
            </div>
            {displayFirstStepError ? (
              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Something went wrong
              </div>
            ) : null}
          </div>
          <div className={styles.choose_plan_container}>
            <div>
              <div className={styles.account_info_container}>
                <div className={styles.start_trial_title}>
                  <div>Payment Information</div>
                  <div className={styles.start_trial_title_label}>
                    <LockIcon />
                    Secured by Stripe
                  </div>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.stripe_element}>
                    <CardElement
                      onChange={onCreditCardChange}
                      onReady={element => {
                        cardElementRef.current = element;
                      }}
                      style={stripeElementStyle}
                      hidePostalCode
                    />
                    <p className={styles.error}>{creditCardError}</p>
                  </div>
                  <div className={styles.inputs_container}>
                    <TextField
                      label="Name on card"
                      error={isFormSubmitted && !namePayment}
                      helperText={
                        isFormSubmitted &&
                        !namePayment &&
                        'Name on card is required'
                      }
                      variant="outlined"
                      classes={{ root: styles.textfield }}
                      fullWidth
                      FormHelperTextProps={{
                        classes: {
                          root: styles.form_helper_text,
                        },
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        classes: {
                          root: styles.label_text,
                        },
                      }}
                      value={namePayment}
                      onChange={e => {
                        setNamePayment(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BillingSummary
          onContinueClicked={onContinueClicked}
          isLoading={isLoading}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          onApllyCoupon={onApllyCoupon}
          isLoadingCoupon={isLoadingCoupon}
          couponCodeError={couponCodeError}
          couponDiscount={couponDiscount}
          dormantPlan={dormantPlan}
          appliedCouponCode={appliedCouponCode}
        />
        {isLoading && (
          <div>
            <Backdrop open classes={{ root: styles.backdrop }} />

            <div className={styles.progress_container}>
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className={styles.confirmation_container}>
        <img src={ConfettiGif} className={styles.confirmation_image} />
        <div className={styles.confirmation_title}>
          Congratulations! You’ve just created a Myndlift dashboard.
        </div>
        <div
          className={styles.confirmation_description}
          style={{ marginTop: 15 }}
        >
          Next: order your hardware by logging in
        </div>
        {/* <div className={styles.ifrmae_wrapper}>
          <iframe
            src={`https://calendly.com/myndlift-care/dashboard-overview?utm_campaign=registered&utm_source=dashboard&utm_content=${sessionStorage.userId}`}
            className={styles.confirmation_iframe}
            title="Schedule a call"
          />
        </div> */}
        <div className={styles.confirmation_button} onClick={loginToDashboard}>
          LOG INTO YOUR DASHBOARD
        </div>
      </div>
    );
  };

  return (
    <div className={styles.registration_flow_container} ref={containerEl}>
      <div className={styles.registration_flow_header}>
        <img src={Logo} className={styles.registration_flow_header_img} />
      </div>
      {isConfirmationPage ? renderConfirmation() : renderCreateAccount()}
    </div>
  );
};

RegisterAssessmentClinicBase.propTypes = exact({
  stripe: PropTypes.object.isRequired,
  dormantPlan: PropTypes.object.isRequired,
});

export const RegisterAssessmentClinic = React.memo(
  injectStripe(RegisterAssessmentClinicBase)
);
RegisterAssessmentClinic.displayName = 'RegisterAssessmentClinic';

// constants declared here should match rules declared in src/sass files
export const paragraphFontSize = 12;
export const subHeaderFontSize = 13;

export const xsExtraMediaMaxWidth = 480;
export const xsMediaMaxWidth = 768;
export const mdMediaMaxWidth = 960;
export const mdExtraMediaMaxWidth = 1200;

export const defaultHrefColor = '#2196f3';
export const defaultHrefHoverColor = '#0a6ebd';

export const defaultLineHeight = 1.42857143;

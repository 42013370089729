import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { convertObjectToArrWithIds } from '../../utils/utils';
import { DeployedPrograms } from '../DeployedPrograms/DeployedPrograms';
import { Loader } from '../../Core/Components/Loader/Loader';

const initialValue = Symbol('IV');
const DeployedProgramsLoaderContainerBase = ({
  customerId,
  shouldShowGoToUserProfileLink,
}) => {
  const [deployedSession] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${customerId}`,
    initialValue,
  });

  const [protocols, setProtocols] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    loadProtocols(p => {
      setProtocols(p);
    });
  }, []);

  const renderCarousel = () => {
    const { programs = {} } = deployedSession;

    const programsArr = convertObjectToArrWithIds(programs)
      .map(program => ({
        // eslint-disable-next-line no-undef
        protocolName: extractProtocolName(program.protocolDetails, protocols),
        ...program,
      }))
      .sort((p1, p2) => p1.programIndex - p2.programIndex);

    return (
      <DeployedPrograms
        programs={programsArr}
        customerId={customerId}
        shouldShowGoToUserProfileLink={shouldShowGoToUserProfileLink}
      />
    );
  };

  return (
    <div>
      {deployedSession !== initialValue && protocols ? (
        renderCarousel()
      ) : (
        <Loader />
      )}
    </div>
  );
};

DeployedProgramsLoaderContainerBase.defaultProps = {
  shouldShowGoToUserProfileLink: false,
};

DeployedProgramsLoaderContainerBase.propTypes = exact({
  customerId: PropTypes.string.isRequired,
  shouldShowGoToUserProfileLink: PropTypes.bool,
});

export const DeployedProgramsLoaderContainer = React.memo(
  DeployedProgramsLoaderContainerBase
);
DeployedProgramsLoaderContainer.displayName = 'DeployedProgramsLoaderContainer';

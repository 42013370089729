import dayJS from 'dayjs';
import { startCase } from 'lodash';
import { devices } from '../devices/devices';
import {
  androidVersionCompatiableWithSymptomQuestionnaires,
  iOSVersionCompatiableWithSymptomQuestionnaires,
} from '../symptom-tracker/new-symptom-tracker-app-versions';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import {
  getFirebaseData,
  moveFirebaseData,
  listenForFirebaseData,
} from '../firebase/firebase';
import { prettifySessionStatus } from '../sessions/session';
import { snakeUpperCase } from '../../utils/utils';
import {
  androidVersionCompatibleWithMultiProtocol,
  iOSVersionCompatibleWithMultiProtocol,
} from '../protocols/protocols';

const appVersionCompatibleWithDeployJourneyIos = '2.5.6';
const appVersionCompatibleWithDeployJourneyAndroid = '9.9.9.9';

const getOnlyNumbersFromString = string => {
  const notANumberRegex = new RegExp(/[^0-9]/g);
  return string.replace(notANumberRegex, '');
};

export const getDeviceAndAppVersion = async ({ customerId }) => {
  const appVersion = await database
    .ref(`userAppVersion/${customerId}`)
    .once('value')
    .then(snapshot => snapshot.val());

  const device = (() => {
    if (appVersion) {
      const onlyNumbersAppVersion = getOnlyNumbersFromString(appVersion);
      return onlyNumbersAppVersion.length === 3 ? devices.iOS : devices.android;
    }
    return null;
  })();
  return [device, appVersion];
};

const convertAppVersionToNumber = version => {
  const onlyNumbersString = getOnlyNumbersFromString(version);
  return parseInt(onlyNumbersString, 10);
};

export const isAppCompilableWithSymptomQuestionnaires = async ({
  customerId,
}) => {
  const [device, appVersion] = await getDeviceAndAppVersion({ customerId });

  if (device !== null) {
    const numberAppVersion = convertAppVersionToNumber(appVersion);
    if (device === devices.android) {
      return (
        numberAppVersion >
        convertAppVersionToNumber(
          androidVersionCompatiableWithSymptomQuestionnaires
        )
      );
    }

    if (device === devices.iOS) {
      return (
        numberAppVersion >
        convertAppVersionToNumber(
          iOSVersionCompatiableWithSymptomQuestionnaires
        )
      );
    }
    return true;
  }
  return true;
};

export const isAppCompilableWithMultiProtocol = async ({ customerId }) => {
  const [device, appVersion] = await getDeviceAndAppVersion({ customerId });

  if (device !== null) {
    const numberAppVersion = convertAppVersionToNumber(appVersion);
    if (device === devices.android) {
      return (
        numberAppVersion >
        convertAppVersionToNumber(androidVersionCompatibleWithMultiProtocol)
      );
    }

    if (device === devices.iOS) {
      return (
        numberAppVersion >
        convertAppVersionToNumber(iOSVersionCompatibleWithMultiProtocol)
      );
    }
    return true;
  }
  return true;
};

export const isAppCompilableWithDeployJourney = async ({ customerId }) => {
  const [device, appVersion] = await getDeviceAndAppVersion({ customerId });

  if (device !== null) {
    const numberAppVersion = convertAppVersionToNumber(appVersion);
    if (device === devices.android) {
      return (
        numberAppVersion >
        convertAppVersionToNumber(appVersionCompatibleWithDeployJourneyAndroid)
      );
    }

    if (device === devices.iOS) {
      return (
        numberAppVersion >
        convertAppVersionToNumber(appVersionCompatibleWithDeployJourneyIos)
      );
    }
    return true;
  }
  return true;
};

export const getCustomerFullName = ({ customerInfo, separator = ' ' }) => {
  return `${customerInfo.firstName}${separator}${customerInfo.lastName}`;
};

export const getDateNameSuffix = ({ nameHyphensSeparated }) => {
  const date = dayJS().format(DAY_JS_DATE_FORMATS.dayMonthYearHyphens);
  const time = dayJS().format(DAY_JS_DATE_FORMATS.timeHyphens);
  return `${nameHyphensSeparated}_${date}_${time}`;
};

export const getCustomerInfo = async customerId => {
  if (sessionStorage.customerId === customerId && sessionStorage.customerInfo) {
    return JSON.parse(sessionStorage.customerInfo);
  }
  const customerInfo = await database
    .ref(`userInfo/${customerId}`)
    .once('value')
    .then(snapshot => snapshot.val());
  return customerInfo;
};

export const switchCustomerToNewSymptomTracker = async customerId => {
  // TODO after making sure new symptom tracker feature is stable we just have to delete all data in OldSymptomTrackerData, and just delete data instead of moving it
  await moveFirebaseData({
    source: `symptomTracker/patients/${customerId}`,
    target: `oldSymptomTrackerData/patients/${customerId}`,
  });
  return database
    .ref(`userInfo/${customerId}/shouldUseNewSymptomTracker`)
    .set(true);
};

export const getCustomerXP = async ({ customerId }) => {
  const xp = await getFirebaseData(`progress/${customerId}/xp`);
  return xp || 0;
};

const getCustomerLevel = async ({ xp, levels }) => {
  const levelsKeys = Object.keys(levels);
  const maxLevel = levelsKeys.reduce(
    (acc, cur) => Math.max(acc, +cur),
    +levelsKeys[0]
  );

  const customerLevel = parseInt(Math.max(0.2 * Math.sqrt(xp) - 1, 0), 10);
  return Math.min(customerLevel, maxLevel);
};

export const getCustomerTextLevel = async ({ customerId }) => {
  const xp = await getCustomerXP({ customerId });
  const levels = await getFirebaseData('gamification/levels');
  const levelsArray = Object.values(levels);
  const customerLevel = await getCustomerLevel({ xp, levels });

  return levelsArray[customerLevel].name;
};

export const listenForCustomerSessionStatusUpdates = async ({
  customerId,
  listener,
}) => {
  const deployedSessionStatusPath = `deployedSession/${sessionStorage.userId}/${customerId}/status`;
  const statusFromDeployedSession = await getFirebaseData(
    deployedSessionStatusPath
  );

  if (statusFromDeployedSession) {
    return listenForFirebaseData(deployedSessionStatusPath, snapshot => {
      const { state, substage } = snapshot.val();

      listener({
        state: prettifySessionStatus(snakeUpperCase(state)),
        substage: substage && startCase(substage),
      });
    });
  }
  return listenForFirebaseData(
    `clinics/${sessionStorage.userId}/currentSessions/${customerId}/status/text`,
    snapshot => {
      const val = snapshot.val();
      if (val === null) {
        // make sure that we return an object that matches the structure of statusFromDeployedSession
        listener({});
      } else {
        listener({ state: prettifySessionStatus(snakeUpperCase(val)) });
      }
    }
  );
};

export const isThereNoProgramDeployed = deployedProgram =>
  !deployedProgram ||
  deployedProgram.nothingSetUp !== false ||
  deployedProgram.isComplete;

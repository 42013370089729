import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './DeleteReportDialog.scss';

export const DeleteReportDialog = ({ isOpen, onDelete, onCancel }) => {
  const t = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span className={styles.dialog_header}>
          {t('confirm_delete_report_text')}
        </span>
      </DialogTitle>

      <DialogActions>
        <Button onClick={onCancel} variant="text" color="default">
          <span className={styles.button}>{t('cancel')}</span>
        </Button>
        <Button onClick={onDelete} color="primary" variant="contained">
          <span className={styles.button}>{t('delete')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteReportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

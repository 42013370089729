import React, { useState, useCallback, useEffect } from 'react';
import omitBy from 'lodash/omitBy';
import exact from 'prop-types-exact';
import { Button } from '@material-ui/core';
import { doesStrContainAnyFirebaseForbiddenChar } from '../../utils/utils';
import { useTranslation } from '../../Core/hooks/useTranslation';
import {
  FrequencyDetailsDialog,
  FREQUENCY_DETAILS_DIALOG_MODES,
} from '../FrequencyDetailsDialog/FrequencyDetailsDialog';

import styles from './AddFrequencyWidget.scss';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import {
  FREQUENCY_MAX_RANGE,
  FREQUENCY_MIN_RANGE,
  getDerivedPresetFrequencyName,
  getPresetFrequencyDescription,
} from '../../models/frequencies/frequencies';

const addFrequencyWidgetLogger = new MyEventWrapper('add_frequency_widget');

const customWordLength = 'Custom '.length;
const frequencyNameMaximumLength = 50 + customWordLength;

const AddFrequencyWidgetBase = () => {
  const t = useTranslation();

  const FREQUENCY_RANGE_ERRORS = {
    aFrequencyAlreadyHasTheseRanges:
      'There is already a frequency with these ranges',
    spaceToIndicateAnError: ' ',
  };

  const MIN_FREQUENCY_ERRORS = {
    minFrequencyNotInRange: t('min_frequency_must_be_in_range'),
    minFrequencyNotSmallerThanMaxFrequency: t(
      'min_frequency_must_be_smaller_than_max_frequency'
    ),
  };

  const MAX_FREQUENCY_ERRORS = {
    maxFrequencyNotInRange: t('max_frequency_must_be_in_range'),
    maxFrequencyNotHigherThanMinFrequency: t(
      'max_frequency_must_be_higher_than_min_frequency'
    ),
  };

  const [
    isFrequencyDetailsDialogOpen,
    setIsFrequencyDetailsDialogOpen,
  ] = useState(false);

  const [frequencyName, setFrequencyName] = useState('');
  const [frequencyNameError, setFrequencyNameError] = useState(null);
  const [frequencyDescription, setFrequencyDescription] = useState('');
  const [frequencyDescriptionError, setFrequencyDescriptionError] = useState(
    null
  );
  const [
    wasFrequencyDescriptionChangedByClinican,
    setWasFrequencyDescriptionChangedByClinican,
  ] = useState(false);
  const [minFrequency, setMinFrequency] = useState('');
  const [minFrequencyError, setMinFrequencyError] = useState(null);
  const [maxFrequency, setMaxFrequency] = useState('');
  const [maxFrequencyError, setMaxFrequencyError] = useState(null);
  const [frequencyRangeError, setFrequencyRangeError] = useState(null);

  const isFrequencyDetailsDialogPrimaryBtnEnabled =
    frequencyName !== '' &&
    frequencyDescription !== '' &&
    minFrequency !== '' &&
    maxFrequency !== '' &&
    frequencyNameError === null &&
    frequencyDescriptionError === null &&
    minFrequencyError === null &&
    maxFrequencyError === null &&
    frequencyRangeError === null;

  const onMinFrequencyChange = useCallback(
    event => {
      const numberMinFrequency = parseInt(event.target.value, 10);
      setMinFrequency(numberMinFrequency);
      setFrequencyRangeError(null);

      if (
        numberMinFrequency < FREQUENCY_MIN_RANGE ||
        numberMinFrequency > FREQUENCY_MAX_RANGE
      ) {
        setMinFrequencyError(MIN_FREQUENCY_ERRORS.minFrequencyNotInRange);
      } else if (maxFrequency && numberMinFrequency >= maxFrequency) {
        setMinFrequencyError(
          MIN_FREQUENCY_ERRORS.minFrequencyNotSmallerThanMaxFrequency
        );
      } else {
        setMinFrequencyError(null);
        if (
          maxFrequencyError ===
          MAX_FREQUENCY_ERRORS.maxFrequencyNotHigherThanMinFrequency
        ) {
          setMaxFrequencyError(null);
        }
      }
    },
    [
      maxFrequency,
      MIN_FREQUENCY_ERRORS,
      maxFrequencyError,
      MAX_FREQUENCY_ERRORS,
    ]
  );
  const onMaxFrequencyChange = useCallback(
    event => {
      const numberMaxFrequency = parseInt(event.target.value, 10);
      setMaxFrequency(numberMaxFrequency);
      setFrequencyRangeError(null);

      if (
        numberMaxFrequency < FREQUENCY_MIN_RANGE ||
        numberMaxFrequency > FREQUENCY_MAX_RANGE
      ) {
        setMaxFrequencyError(MAX_FREQUENCY_ERRORS.maxFrequencyNotInRange);
      } else if (minFrequency && minFrequency >= numberMaxFrequency) {
        setMaxFrequencyError(
          MAX_FREQUENCY_ERRORS.maxFrequencyNotHigherThanMinFrequency
        );
      } else {
        setMaxFrequencyError(null);

        if (
          minFrequencyError ===
          MIN_FREQUENCY_ERRORS.minFrequencyNotSmallerThanMaxFrequency
        ) {
          setMinFrequencyError(null);
        }
      }
    },
    [
      MAX_FREQUENCY_ERRORS,
      minFrequency,
      minFrequencyError,
      MIN_FREQUENCY_ERRORS,
    ]
  );

  const onFrequencyNameChange = useCallback(
    event => {
      const freqName = event.target.value;

      if (freqName.toLowerCase().trim() === 'custom') {
        if (freqName.toLowerCase() !== 'custom') {
          setFrequencyName(freqName);
        }
        setFrequencyNameError(t('frequency_name_is_required'));
      } else if (freqName.toLowerCase().startsWith('custom ')) {
        setFrequencyName(freqName);
        if (doesStrContainAnyFirebaseForbiddenChar(freqName)) {
          setFrequencyNameError(
            'Frequency name cannot contain any of the following characters (. $ # [ ] / \\)'
          );
        } else if (freqName.length > frequencyNameMaximumLength) {
          setFrequencyNameError(
            'Frequency name should be lower than 50 characters'
          );
        } else {
          setFrequencyNameError(null);
        }
      } else if (freqName.length <= 1) {
        setFrequencyName(`Custom ${freqName}`);
        if (freqName.trim() === '') {
          setFrequencyNameError(t('frequency_name_is_required'));
        }
      }
    },
    [t]
  );

  const onFrequencyDescriptionChange = useCallback(
    event => {
      const freqDescription = event.target.value;
      setFrequencyDescription(freqDescription);
      setWasFrequencyDescriptionChangedByClinican(true);

      if (freqDescription.trim() === '') {
        setFrequencyDescriptionError(t('frequency_description_is_required'));
      } else {
        setFrequencyDescriptionError(null);
      }
    },
    [t]
  );

  const closeFrequencyDetailsDialog = () => {
    setIsFrequencyDetailsDialogOpen(false);
    setFrequencyName('');
    setFrequencyDescription('');
    setMinFrequency('');
    setMaxFrequency('');
    setMinFrequencyError(null);
    setMaxFrequencyError(null);
    setFrequencyDescriptionError(null);
    setFrequencyNameError(null);
    setFrequencyRangeError(null);
    setWasFrequencyDescriptionChangedByClinican(false);
  };

  const onFrequencyDetailsDialogClose = useCallback(() => {
    addFrequencyWidgetLogger.log('on_frequency_details_dialog_close');
    closeFrequencyDetailsDialog();
  }, []);
  const onFrequencyDetailsDialogCancel = onFrequencyDetailsDialogClose;

  const onAddFrequency = useCallback(() => {
    addFrequencyWidgetLogger.log('on_add_frequency');
    setIsFrequencyDetailsDialogOpen(true);
  }, []);

  const onFrequencyDetailsDialogConfirm = useCallback(async () => {
    addFrequencyWidgetLogger.log('on_frequency_details_dialog_confirm', {
      name: frequencyName,
      description: frequencyDescription,
      minFrequency,
      maxFrequency,
    });

    const frequencyDatabaseRef = database.ref(
      `clinics/${sessionStorage.userId}/configurations/frequencies/${frequencyName}`
    );

    if (
      await frequencyDatabaseRef.once('value').then(snapshot => snapshot.val())
    ) {
      setFrequencyNameError(t('frequency_name_already_exists'));
    } else if (
      extractFrequency(
        minFrequency,
        maxFrequency,
        omitBy(frequenciesConfig, frequency => frequency.show === false)
      )
    ) {
      setFrequencyRangeError(
        FREQUENCY_RANGE_ERRORS.aFrequencyAlreadyHasTheseRanges
      );
    } else {
      frequencyDatabaseRef.set({
        isPreset: false,
        minFrequency,
        maxFrequency,
        description: frequencyDescription,
      });
      closeFrequencyDetailsDialog();
    }
  }, [
    frequencyName,
    frequencyDescription,
    minFrequency,
    maxFrequency,
    FREQUENCY_RANGE_ERRORS,
    t,
  ]);

  useEffect(() => {
    if (
      minFrequency &&
      maxFrequency &&
      !wasFrequencyDescriptionChangedByClinican
    ) {
      const derivedFrequencyName = getDerivedPresetFrequencyName({
        minFrequency,
        maxFrequency,
      });

      if (derivedFrequencyName) {
        const currentFrequencyDescription = getPresetFrequencyDescription({
          frequencyName: derivedFrequencyName,
          t,
        });
        setFrequencyDescription(currentFrequencyDescription);
      } else {
        setFrequencyDescription('');
      }
    }
  }, [minFrequency, maxFrequency, wasFrequencyDescriptionChangedByClinican, t]);

  return (
    <React.Fragment>
      {/* <Paper> */}
      <div className={styles.widget_container}>
        <h3>{t("can't_find_frequency_to_target")}</h3>
        <Button
          onClick={onAddFrequency}
          color="primary"
          variant="text"
          autoFocus
        >
          <span className={styles.button}>+ {t('add-new-frequency')}</span>
        </Button>
      </div>
      {/* </Paper> */}

      <FrequencyDetailsDialog
        isOpen={isFrequencyDetailsDialogOpen}
        onClose={onFrequencyDetailsDialogClose}
        onCancel={onFrequencyDetailsDialogCancel}
        onConfirm={onFrequencyDetailsDialogConfirm}
        mode={FREQUENCY_DETAILS_DIALOG_MODES.ADDING_FREQUENCY}
        isPrimaryBtnDisabled={!isFrequencyDetailsDialogPrimaryBtnEnabled}
        {...{
          minFrequency,
          maxFrequency,
          frequencyName,
          frequencyDescription,
          minFrequencyError,
          maxFrequencyError,
          frequencyDescriptionError,
          frequencyNameError,
          onMinFrequencyChange,
          onMaxFrequencyChange,
          onFrequencyNameChange,
          onFrequencyDescriptionChange,
          frequencyRangeError,
        }}
      />
    </React.Fragment>
  );
};

AddFrequencyWidgetBase.propTypes = exact({});

export const AddFrequencyWidget = withEntryComponent(
  React.memo(AddFrequencyWidgetBase)
);

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import styles from './AddReactivateAddonsDialog.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';
import NeuroPromoPng from '../../../../assets/neuro_promo.png';

const AddReactivateAddonsDialogBase = ({
  isOpen,
  onConfirm,
  onClose,
  creditCardLashFourDigits,
  onUseDifferentCard,
  addReactivateAddonsSuccess,
  isloadingAddreactivate,
  isReactivation,
  formattedNextBillingCycle,
  creditOrChargeExtraNf,
  NFPackages,
  isAnnual,
}) => {
  if (isloadingAddreactivate) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <Loader />
      </Dialog>
    );
  }
  if (addReactivateAddonsSuccess) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <div style={{ textAlign: 'center', fontSize: 20 }}>
            {isReactivation
              ? 'Neuro add-on was reactivated successfully!'
              : 'Neuro was successfully added!'}
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center', fontSize: 15 }}>
            {isReactivation ? null : (
              <div>
                You can now start deploying EEG assessments and neurofeedback
                programs.
              </div>
            )}
            {isReactivation || !creditOrChargeExtraNf ? null : (
              <div style={{ marginTop: 20, fontWeight: 900 }}>
                Your were charged ${creditOrChargeExtraNf} to cover the
                remainder of the current billing cycle.
              </div>
            )}
            {isReactivation ? (
              <div style={{ marginTop: 10, fontWeight: 900 }}>
                Starting from your next billing cycle{' '}
                {formattedNextBillingCycle}, you will be charged $
                {isAnnual
                  ? NFPackages.annualMonthlyBasePrice * 12
                  : // eslint-disable-next-line prefer-template
                    NFPackages.basePrice + '/mo'}
                .
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            <span className={styles.button}>DONE</span>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          <img src={NeuroPromoPng} style={{ width: 163, height: 177 }} />
        </div>
        <div style={{ textAlign: 'center', fontSize: 20 }}>
          {isReactivation
            ? 'Reactivating is a no brainer!'
            : 'Add Neurofeedback'}
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: 'center', fontSize: 15 }}>
          <div>
            {isReactivation
              ? 'By reactivating the add-on, you will be able to use EEG sensing assessments, deploy neurofeedback training programs as well as access any that you’ve deployed in the past.'
              : 'With this add-on, you’ll be able to deploy EEG sensing assessments and neurofeedback programs that will make your program holistic and accelerate progress.'}
          </div>
          {isReactivation || !creditOrChargeExtraNf ? null : (
            <div style={{ marginTop: 20, fontWeight: 900 }}>
              You will now get charged ${creditOrChargeExtraNf} to cover the
              remainder of the current billing cycle.
            </div>
          )}
          {/* {isReactivation ? null : (
            <div style={{ marginTop: 10, fontWeight: 900 }}>
              You will be charged $
              {isAnnual
                ? NFPackages.annualMonthlyBasePrice * 12
                : // eslint-disable-next-line prefer-template
                  NFPackages.basePrice + '/mo'}{' '}
              starting from your next billing cycle {formattedNextBillingCycle}.
            </div>
          )} */}
          <div className={styles.card_number_container}>
            <p>
              We will use credit card ending with {creditCardLashFourDigits} to
              process the payment
            </p>
            <div>
              <a role="button" onClick={onUseDifferentCard} tabIndex={0}>
                + Use different credit card
              </a>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant="contained">
          <span className={styles.button}>
            {isReactivation ? 'reactivate now' : 'activate now'}
          </span>
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          // variant="contained"
        >
          <span className={styles.button}>Cancel</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddReactivateAddonsDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  creditCardLashFourDigits: PropTypes.string,
  onUseDifferentCard: PropTypes.func.isRequired,
  addReactivateAddonsSuccess: PropTypes.bool,
  isloadingAddreactivate: PropTypes.bool,
  isReactivation: PropTypes.bool,
  formattedNextBillingCycle: PropTypes.string,
  creditOrChargeExtraNf: PropTypes.number,
  NFPackages: PropTypes.object,
  isAnnual: PropTypes.bool,
});

export const AddReactivateAddonsDialog = React.memo(
  AddReactivateAddonsDialogBase
);
AddReactivateAddonsDialog.displayName = 'AddReactivateAddonsDialog';

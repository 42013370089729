import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { paragraphFontSize } from '../../../cssInJs/constants';
import { isATouchDevice } from '../../../utils/utils';

const stylesFn = theme => ({
  menuItem: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'visible',
    position: 'relative',
    '& a': {
      color: theme.palette.common.black,
    },
  },
  caption: {
    alignItems: 'center',
    display: 'flex',
    fontSize: paragraphFontSize,
  },
  arrowIcon: {
    paddingLeft: 24,
    width: 48,
  },
  sub_menu_modal_root: {
    pointerEvents: 'none',
  },
  sub_menu_paper_root: {
    pointerEvents: 'all',
  },
  menu_item_root: {
    '&:focus': {
      backgroundColor: 'revert',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
});

class CascadingMenuBase extends React.Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
    this.state = {
      currentSubMenuState: {
        anchorElement: null,
        items: [],
      },
    };
  }

  onItemClick = (event, menuItem) => {
    if (menuItem.onClick) {
      menuItem.onClick(event);
      this.closeAllMenus();
    }
  };

  onPointerEnter = (event, menuItem) => {
    const hasSubMenu = !!(
      menuItem.subMenuItems && menuItem.subMenuItems.length
    );

    // need to save reference because even.currentTarget gets set to null after some time
    const originalEventTarget = event.currentTarget;
    const openSubMenu = () => {
      this.setState(
        {
          currentSubMenuState: {
            anchorElement: originalEventTarget,
            items: menuItem.subMenuItems,
          },
        },
        () => {
          setTimeout(() => {
            window.dispatchEvent(new window.Event('resize'));
          }, 50);
        }
      );
    };

    if (hasSubMenu) {
      if (isATouchDevice()) {
        setTimeout(openSubMenu, 100);
      } else {
        openSubMenu();
      }
    } else if (!menuItem.isSubMenuItem) {
      this.closeSubMenu();
    }
  };

  closeSubMenu = () => {
    this.setState({
      currentSubMenuState: {
        anchorElement: null,
        items: [],
      },
    });
  };

  closeAllMenus = () => {
    this.closeSubMenu();
    this.props.onClose();
  };

  renderMenuItem = menuItem => {
    const { classes } = this.props;
    const hasSubMenu = !!(
      menuItem.subMenuItems && menuItem.subMenuItems.length
    );

    return (
      <MenuItem
        onMouseEnter={event => this.onPointerEnter(event, menuItem)}
        onClick={event => this.onItemClick(event, menuItem)}
        className={classes.menuItem}
        key={menuItem.key}
        tabIndex="-1"
        classes={{ root: classes.menu_item_root }}
      >
        <div className={classes.caption}>{menuItem.caption}</div>
        {hasSubMenu && <ArrowRightIcon className={classes.arrowIcon} />}
      </MenuItem>
    );
  };

  render() {
    const {
      anchorElement,
      open,
      onClose,
      menuItems,
      classes,
      ...others
    } = this.props;

    const { currentSubMenuState } = this.state;
    // console.log(currentSubMenuState.anchorElement);
    return (
      <>
        <Menu
          {...others}
          anchorEl={anchorElement}
          elevation={2}
          classes={{
            paper: classes.rootMenu,
          }}
          open={!!open}
          onClose={this.closeAllMenus}
          disableAutoFocus
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {menuItems.map(menuItem => this.renderMenuItem(menuItem))}
        </Menu>

        <Menu
          key={
            currentSubMenuState.anchorElement &&
            currentSubMenuState.anchorElement.innerText
          }
          anchorEl={currentSubMenuState.anchorElement}
          open={!!anchorElement && !!currentSubMenuState.anchorElement}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          ModalClasses={{
            root: classes.sub_menu_modal_root,
          }}
          classes={{
            paper: classes.sub_menu_paper_root,
          }}
          disableAutoFocus
        >
          <MenuList>
            {currentSubMenuState.items.map(subMenuItem => {
              return this.renderMenuItem(subMenuItem);
            })}
          </MenuList>
        </Menu>
      </>
    );
  }
}

CascadingMenuBase.propTypes = {
  anchorElement: PropTypes.any,
  classes: PropTypes.any,
  menuItems: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export const CascadingMenu = withStyles(stylesFn)(CascadingMenuBase);

exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".font-styles_size_17{font-size:17px}.font-styles_size_10{font-size:10px}.font-styles_size_11{font-size:11px}.font-styles_size_13{font-size:13px}.font-styles_size_15{font-size:15px}.font-styles_size_14{font-size:14px}.font-styles_size_16{font-size:16px}.font-styles_size_12{font-size:12px}.font-styles_extra_header{font-size:18px;font-weight:500;color:#000000}.font-styles_header{font-size:17px;font-weight:500;color:#000000}.font-styles_header_only_font_size{font-size:17px;font-weight:500}.font-styles_sub_header{font-size:13px;color:#878787}.font-styles_sub_header_black{font-size:13px;color:black}.font-styles_sub_header_only_font_size{font-size:13px}.font-styles_paragraph_only_font_size{font-size:12px}.font-styles_tab_font_size{font-size:13px}.font-styles_pop_out_header{font-size:20px}.font-styles_title{font-size:15px;font-weight:500;color:#484848}.font-styles_title_black{font-size:15px;font-weight:500;color:black}.font-styles_title_only_font_size{font-size:15px}.font-styles_paragraph{font-size:12px;color:#484848}.font-styles_btn{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "font-styles_size_17",
	"size_10": "font-styles_size_10",
	"size_11": "font-styles_size_11",
	"size_13": "font-styles_size_13",
	"size_15": "font-styles_size_15",
	"size_14": "font-styles_size_14",
	"size_16": "font-styles_size_16",
	"size_12": "font-styles_size_12",
	"extra_header": "font-styles_extra_header",
	"header": "font-styles_header",
	"header_only_font_size": "font-styles_header_only_font_size",
	"sub_header": "font-styles_sub_header",
	"sub_header_black": "font-styles_sub_header_black",
	"sub_header_only_font_size": "font-styles_sub_header_only_font_size",
	"paragraph_only_font_size": "font-styles_paragraph_only_font_size",
	"tab_font_size": "font-styles_tab_font_size",
	"pop_out_header": "font-styles_pop_out_header",
	"title": "font-styles_title",
	"title_black": "font-styles_title_black",
	"title_only_font_size": "font-styles_title_only_font_size",
	"paragraph": "font-styles_paragraph",
	"btn": "font-styles_btn"
};
import alphaPNG from '../../../assets/alpha.png';
import thetaPNG from '../../../assets/theta.png';
import betaPNG from '../../../assets/beta.png';
import deltaPNG from '../../../assets/delta.png';
import hiBetaPNG from '../../../assets/hi-beta.png';

export const presetFrequenciesNames = {
  alpha: 'Alpha',
  beta: 'Beta',
  delta: 'Delta',
  hiBeta: 'Hi-Beta',
  hiBetaB: 'Hi-Beta-B',
  loBeta: 'Lo-Beta',
  smr: 'SMR',
  theta: 'Theta',
  thetaB: 'Theta-B',
  thetAlpha: 'Thetalpha',
  alpha13: 'Alpha-B',
  gamma: 'Gamma',
};

const frequencyNamesByRanges = {
  1: presetFrequenciesNames.delta,
  2: presetFrequenciesNames.delta,
  3: presetFrequenciesNames.delta,
  4: presetFrequenciesNames.delta,
  5: presetFrequenciesNames.theta,
  6: presetFrequenciesNames.theta,
  7: presetFrequenciesNames.theta,
  8: presetFrequenciesNames.alpha,
  9: presetFrequenciesNames.alpha,
  10: presetFrequenciesNames.alpha,
  11: presetFrequenciesNames.alpha,
  12: presetFrequenciesNames.loBeta,
  13: presetFrequenciesNames.loBeta,
  14: presetFrequenciesNames.loBeta,
  15: presetFrequenciesNames.loBeta,
  16: presetFrequenciesNames.beta,
  17: presetFrequenciesNames.beta,
  18: presetFrequenciesNames.beta,
  19: presetFrequenciesNames.beta,
  20: presetFrequenciesNames.beta,
  21: presetFrequenciesNames.hiBeta,
  22: presetFrequenciesNames.hiBeta,
  23: presetFrequenciesNames.hiBeta,
  24: presetFrequenciesNames.hiBeta,
  25: presetFrequenciesNames.hiBeta,
  26: presetFrequenciesNames.hiBeta,
  27: presetFrequenciesNames.hiBeta,
  28: presetFrequenciesNames.hiBeta,
  29: presetFrequenciesNames.hiBeta,
  30: presetFrequenciesNames.hiBeta,
  31: presetFrequenciesNames.hiBeta,
  32: presetFrequenciesNames.hiBeta,
  33: presetFrequenciesNames.hiBeta,
  34: presetFrequenciesNames.hiBeta,
  35: presetFrequenciesNames.hiBeta,
  36: presetFrequenciesNames.hiBeta,
};

export const getDerivedPresetFrequencyName = ({
  minFrequency,
  maxFrequency,
}) => {
  const frequencyScores = {};
  for (
    let i = Math.floor(minFrequency);
    i <= Math.floor(maxFrequency);
    i += 1
  ) {
    const freqName = frequencyNamesByRanges[i];
    if (freqName) {
      frequencyScores[freqName] = frequencyScores[freqName] + 1 || 0;
    }
  }

  const frequencyNames = Object.keys(frequencyScores).sort(
    (freqName1, freqName2) =>
      frequencyScores[freqName2] - frequencyScores[freqName1]
  );

  let maxFreqVal = 0;
  let maxFreqName = '';

  for (let i = 0; i < frequencyNames.length; i += 1) {
    const frequencyName = frequencyNames[i];
    const frequencyScore = frequencyScores[frequencyName];
    const frequencyRange = Math.floor(maxFrequency) - Math.floor(minFrequency);
    if (frequencyScore / frequencyRange >= 0.4) {
      return frequencyName;
    }
    if (frequencyScore >= maxFreqVal) {
      maxFreqVal = frequencyScore;
      maxFreqName = frequencyName;
    }
  }

  if (maxFreqName) {
    return maxFreqName;
  }

  return undefined;
};

export const getDerivedFrequencyImageSrc = ({ minFrequency, maxFrequency }) => {
  const frequencyName = getDerivedPresetFrequencyName({
    minFrequency,
    maxFrequency,
  });

  switch (frequencyName) {
    case presetFrequenciesNames.alpha:
      return alphaPNG;
    case presetFrequenciesNames.loBeta:
    case presetFrequenciesNames.beta:
    case presetFrequenciesNames.smr:
      return betaPNG;
    case presetFrequenciesNames.delta:
      return deltaPNG;
    case presetFrequenciesNames.theta:
      return thetaPNG;
    case presetFrequenciesNames.hiBeta:
      return hiBetaPNG;
    default:
      return hiBetaPNG;
  }
};

export const getPresetFrequencyDescription = ({ t, frequencyName }) => {
  switch (frequencyName) {
    case presetFrequenciesNames.alpha:
    case presetFrequenciesNames.alpha13:
      return t('frequency_description_alpha');

    case presetFrequenciesNames.beta:
      return t('frequency_description_beta');

    case presetFrequenciesNames.delta:
      return t('frequency_description_delta');

    case presetFrequenciesNames.hiBeta:
    case presetFrequenciesNames.hiBetaB:
      return t('frequency_description_hi_beta');

    case presetFrequenciesNames.loBeta:
    case presetFrequenciesNames.smr:
      return t('frequency_description_lo_beta');

    case presetFrequenciesNames.theta:
    case presetFrequenciesNames.thetAlpha:
    case presetFrequenciesNames.thetaB:
      return t('frequency_description_theta');

    default:
      return Sentry.captureException(
        new Error(`Unknown preset frequency name: ${frequencyName}`)
      );
  }
};

export const FREQUENCY_MAX_RANGE = 40;
export const FREQUENCY_MIN_RANGE = 1;

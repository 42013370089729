import { defensiveThrow } from '../../utils/utils';

export const FIREBASE_ORDER_BY_FUNCTIONS = {
  ORDER_BY_KEY: 'orderByKey',
  ORDER_BY_CHILD: 'orderByChild',
};

export const FIREBASE_LIMIT_TO_FUNCTIONS = {
  LIMIT_TO_LAST: 'limitToLast',
};

export const FIREBASE_FILTERING_FUNCTIONS = {
  EQUAL_TO: 'equalTo',
};

export const moveFirebaseData = async ({ source, target }) => {
  const oldRef = database.ref(source);
  const newRef = database.ref(target);

  const oldData = await oldRef.once('value').then(snapshot => snapshot.val());
  await newRef.set(oldData);
  await oldRef.set(null);
  return true;
};

export const getFirebaseData = path => {
  return database
    .ref(path)
    .once('value')
    .then(snapshot => snapshot.val());
};

export const listenForFirebaseData = (path, listener) => {
  const databaseRef = database.ref(path);
  databaseRef.on('value', listener, error => {
    defensiveThrow({ error });
  });
  return () => databaseRef.off('value', listener);
};

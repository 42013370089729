import React, { useEffect, useState } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Input } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './LagoCategories.scss';

const LagoCategoriesBase = ({
  open,
  onClose,
  categories,
  onAddCategory,
  onSaveCategoriesClicked,
  itemData,
  resourcesDrafts,
  resourcesPublished,
}) => {
  const [newTagName, setNewTagName] = useState('');

  const [tagsToShow, setTagsToShow] = useState([]);
  const [tagsToFilterStr, setTagsToFilterStr] = useState('');

  const [tagNameExistMessage, setTagNameExistMessage] = useState('');
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

  const [categoryIdToDelete, setCategoryIdToDelete] = useState('');
  const [displayDeleteError, setDisplayDeleteError] = useState(false);

  useEffect(() => {
    if (categories) {
      if (!tagsToFilterStr.legth) {
        setTagsToShow(Object.keys(categories));
      }
      const filteredCategorys = Object.keys(categories).filter(categoryId => {
        return categories[categoryId].name.en.includes(tagsToFilterStr);
      });
      setTagsToShow(filteredCategorys);
    }
  }, [categories, tagsToFilterStr]);

  useEffect(() => {
    if (itemData && itemData.categoryIds) {
      setSelectedCategoriesIds(itemData.categoryIds);
    }
    if (itemData && !itemData.categoryIds) {
      setSelectedCategoriesIds([]);
    }
  }, [itemData, open]);

  const resetState = () => {
    setNewTagName('');
    setTagsToFilterStr('');
    setSelectedCategoriesIds([]);
  };

  const onSelectCategory = categoryId => {
    const copy = [...selectedCategoriesIds];
    if (selectedCategoriesIds.indexOf(categoryId) === -1) {
      copy.push(categoryId);
    } else {
      copy.splice(selectedCategoriesIds.indexOf(categoryId), 1);
    }
    setSelectedCategoriesIds(copy);
  };

  const deleteCategoryClicked = id => {
    setCategoryIdToDelete(id);
  };

  const deleteCategory = () => {
    let shouldDelete = true;
    Object.keys(resourcesDrafts).forEach(resourcesId => {
      if (resourcesDrafts[resourcesId].articles) {
        Object.keys(resourcesDrafts[resourcesId].articles).forEach(
          articleId => {
            if (resourcesDrafts[resourcesId].articles[articleId].categoryIds) {
              resourcesDrafts[resourcesId].articles[
                articleId
              ].categoryIds.forEach(categoryId => {
                if (categoryId === categoryIdToDelete) {
                  shouldDelete = false;
                }
              });
            }
          }
        );
      }
      if (resourcesDrafts[resourcesId].journals) {
        Object.keys(resourcesDrafts[resourcesId].journals).forEach(
          journalId => {
            if (resourcesDrafts[resourcesId].journals[journalId].categoryIds) {
              resourcesDrafts[resourcesId].journals[
                journalId
              ].categoryIds.forEach(categoryId => {
                if (categoryId === categoryIdToDelete) {
                  shouldDelete = false;
                }
              });
            }
          }
        );
      }
    });
    Object.keys(resourcesPublished).forEach(resourcesId => {
      if (resourcesPublished[resourcesId].articles) {
        Object.keys(resourcesPublished[resourcesId].articles).forEach(
          articleId => {
            if (
              resourcesPublished[resourcesId].articles[articleId].categoryIds
            ) {
              resourcesPublished[resourcesId].articles[
                articleId
              ].categoryIds.forEach(categoryId => {
                if (categoryId === categoryIdToDelete) {
                  shouldDelete = false;
                }
              });
            }
          }
        );
      }
      if (resourcesPublished[resourcesId].journals) {
        Object.keys(resourcesPublished[resourcesId].journals).forEach(
          journalId => {
            if (
              resourcesPublished[resourcesId].journals[journalId].categoryIds
            ) {
              resourcesPublished[resourcesId].journals[
                journalId
              ].categoryIds.forEach(categoryId => {
                if (categoryId === categoryIdToDelete) {
                  shouldDelete = false;
                }
              });
            }
          }
        );
      }
    });
    if (shouldDelete) {
      database
        .ref(`lago/educational/defaults/categories/${categoryIdToDelete}`)
        .set(null);
      setCategoryIdToDelete('');
    } else {
      setDisplayDeleteError(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '20px' }}>Select categories</span>
      </DialogTitle>
      <DialogContent>
        <div className={styles.dialog_tag_container}>
          <div className={styles.select_tag_container}>
            <Input
              value={tagsToFilterStr}
              onChange={e => setTagsToFilterStr(e.target.value)}
              placeholder="🔍 Find Category"
              style={{ width: '200px', fontSize: '18px' }}
            />
            <div className={styles.tags_list_container}>
              {categories &&
                tagsToShow.map(categoryId => (
                  <div
                    onClick={() => {
                      onSelectCategory(categoryId);
                    }}
                    key={categoryId}
                    className={styles.tag_item}
                    style={
                      selectedCategoriesIds.indexOf(categoryId) > -1
                        ? { border: '2px solid black' }
                        : null
                    }
                  >
                    <div className={styles.tag_item_name}>
                      {categories[categoryId] && categories[categoryId].name.en}
                    </div>
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        deleteCategoryClicked(categoryId);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.add_tag_container}>
            <div className={styles.new_tag_title}>new category</div>
            <Input
              value={newTagName}
              onChange={e => setNewTagName(e.target.value)}
              placeholder="Category name"
              style={{ width: '200px', fontSize: '18px', marginTop: '10px' }}
            />

            {tagNameExistMessage ? (
              <div style={{ color: 'red', maxWidth: 200 }}>
                {tagNameExistMessage}
              </div>
            ) : null}
            <Button
              style={{ marginTop: 20 }}
              onClick={() => {
                let isCategoryExist = false;
                Object.keys(categories).forEach(categoryId => {
                  if (categories[categoryId].name.en === newTagName) {
                    isCategoryExist = true;
                  }
                });
                if (isCategoryExist) {
                  setTagNameExistMessage('Category name exist');
                  setTimeout(() => {
                    setTagNameExistMessage('');
                  }, 1500);
                } else {
                  onAddCategory(newTagName);
                  setNewTagName('');
                }
              }}
              disabled={!newTagName.length}
            >
              add category
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            resetState();
          }}
        >
          close
        </Button>
        <Button
          onClick={() => {
            onSaveCategoriesClicked(selectedCategoriesIds, true);
            onClose();
          }}
          // disabled={!newTagName.length}
        >
          save
        </Button>
      </DialogActions>
      <Dialog
        open={!!categoryIdToDelete}
        onClose={() => {
          setCategoryIdToDelete('');
        }}
      >
        <DialogTitle>Are you sure you want to delete the category?</DialogTitle>
        {displayDeleteError ? (
          <div style={{ color: 'red', textAlign: 'center' }}>
            This category is in use
          </div>
        ) : null}
        <DialogActions>
          <Button
            onClick={() => {
              setCategoryIdToDelete('');
              setDisplayDeleteError(false);
            }}
          >
            cancel
          </Button>
          <Button onClick={deleteCategory}>yes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

LagoCategoriesBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  onSaveCategoriesClicked: PropTypes.func.isRequired,
  itemData: PropTypes.array,
  resourcesDrafts: PropTypes.object,
  resourcesPublished: PropTypes.object,
});

export const LagoCategories = React.memo(LagoCategoriesBase);
LagoCategories.displayName = 'LagoCategories';

/* eslint-disable react/prop-types */
/* eslint-disable spaced-comment */
import React, {
  // useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Lottie from 'lottie-react';
import { motion, useAnimation } from 'framer-motion';
import WrappedLogo from '../../../assets/wrapped_backgrounds/myndliftLogoWhite.png';
import styles from './YearWrap.scss';

const StoryPageBase = ({
  background,
  backgroundAnimationAutoPlay,
  backgroundAnimationDelay = 0,
  backgroundAnimationPlaySpeed = 1,
  // action,
  containers,
  sequences,
  storyIndex,
  setCurrentIndex,
  showLogo,
  // exposeToParent,
  // setBackgroundAnimationDelayState,
}) => {
  // -------------------------------------------------------- v14

  // let timerBegin;
  const lottieRef = useRef(null);

  useEffect(() => {
    // setBackgroundAnimationDelayState(backgroundAnimationDelay);
    setCurrentIndex(storyIndex);
  }, []);

  // play animation
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    lottieRef?.current?.setSpeed(backgroundAnimationPlaySpeed);
    const timer = setTimeout(() => {
      // timerBegin = Date.now();
      if (!backgroundAnimationAutoPlay) {
        // console.log('play');
        // eslint-disable-next-line no-unused-expressions
        lottieRef?.current?.play();
      }
      // console.log(lottieRef.current);
    }, backgroundAnimationDelay);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  // useEffect(() => {
  //   if (exposeToParent) {
  //     // exposeToParent(lottieRef);
  //     lottieRef?.current?.play();
  //   }
  // }, [exposeToParent]);

  const AnimatedSequence = ({ animationObj, type, children }) => {
    const controls = useAnimation();

    // useEffect(() => {
    //   console.log('here is pause');

    //   action('pause');
    //   lottieRef?.current?.pause();
    //   controls.stop();
    // }, [controls]);

    const { staggerDirection } = animationObj;

    // pause/resume animation animation
    // useEffect(() => {
    // const timer = setTimeout(() => {
    //   action('pause');
    //   lottieRef?.current?.pause();
    //   controls.stop();
    //   console.log(lottieRef.current);
    // }, 1000);
    // const timer2 = setTimeout(() => {
    //   action('play');
    //   lottieRef?.current?.play();
    //   console.log(lottieRef.current);
    // }, 3000);
    // return () => clearTimeout(timer); // Cleanup timer on unmount
    // }, []);

    useEffect(() => {
      let enterTimer;
      if (!staggerDirection) {
        enterTimer = setTimeout(() => {
          // console.log(Date.now(), 'enter time', children);

          controls.start('enter'); // Starts the main "enter" animation
        }, animationObj.enterDelay);
      }

      // Handle extra animations
      if (
        animationObj.extraAnimations &&
        animationObj.extraAnimations.length > 0
      ) {
        animationObj.extraAnimations.forEach(extra => {
          setTimeout(() => {
            controls.start(extra.keyframes); // Trigger the extra keyframes animations
          }, extra.delay);
        });
      }

      // Handle exit animations
      let exitTimer;
      if (animationObj.exitDelay !== undefined && !staggerDirection) {
        exitTimer = setTimeout(() => {
          // console.log(Date.now(), 'exit time', children);
          controls.start('exit'); // Starts the "exit" animation
        }, animationObj.enterDelay + animationObj.exitDelay);
      }

      // Cleanup the timeouts when component unmounts or delays change
      return () => {
        clearTimeout(enterTimer);
        if (exitTimer) clearTimeout(exitTimer);
      };
    }, [animationObj, controls, children]);

    // console.log(animationObj);

    const LetterByLetter = ({
      text,
      letterVariants,
      letterClassname,
      lettersStyle,
    }) => {
      const letters = text.split('');
      return (
        <motion.div
          // key={animationObj.id}
          initial="initial"
          animate={controls}
          exit="exit"
          variants={animationObj.variants}
          style={animationObj.style ?? {}}
          className={animationObj.className ?? ''}
          id={animationObj.id}
        >
          {letters.map((letter, index) => (
            <motion.span
              id={`${animationObj.id}-letter${index + 1}`}
              key={`${animationObj.id}-letter${index + 1}`}
              variants={letterVariants}
              // transition={{
              //   duration: 0.5,
              //   delay: baseDelay + index * 0.1, // Base delay + stagger for each letter
              // }}
              style={{
                ...lettersStyle,
                display: 'inline-block', // Ensure vertical animations work
                // display: 'block', // Ensure vertical animations work
                // position: 'relative' /* If needed for alignment */,
              }}
              className={letterClassname}
            >
              {letter}
            </motion.span>
          ))}
        </motion.div>
      );
    };

    const WordBywordStagger = ({
      sentence,
      wordsVariants,
      wordsClassname,
      wordsStyles,
      wordSpanAttribute,
    }) => {
      const words = sentence.split(' ');
      // console.log(wordsVariants, wordsClassname, animationObj.variants);

      return (
        <motion.div
          // key={animationObj.id}
          initial="initial"
          animate={controls}
          exit="exit"
          variants={animationObj.variants}
          style={animationObj.style ?? {}}
          className={animationObj.className ?? ''}
          id={animationObj.id}
        >
          {words.map((word, index) => (
            <motion.span
              id={`${animationObj.id}-word${index + 1}`}
              key={`${animationObj.id}-word${index + 1}`}
              variants={wordsVariants}
              // transition={{
              //   duration: 0.5,
              //   delay: baseDelay + index * 0.1, // Base delay + stagger for each letter
              // }}
              style={{
                ...wordsStyles,
                // display: 'inline-block', // Ensure vertical animations work
              }}
              className={wordsClassname}
              {...wordSpanAttribute}
            >
              {word}
            </motion.span>
          ))}
        </motion.div>
      );
    };
    //eslint-disable-next-line  no-nested-ternary
    return type === 'word' ? (
      <motion.span
        id={animationObj.id}
        initial="initial"
        animate={controls}
        exit="exit"
        variants={animationObj.variants}
        style={animationObj.style ?? {}}
        className={animationObj.className ?? ''}
      >
        {children}
      </motion.span>
    ) : //eslint-disable-next-line  no-nested-ternary
    type === 'letter' ? (
      <LetterByLetter
        text={animationObj.text}
        letterVariants={animationObj.lettersVariants}
        letterClassname={animationObj.letterClassname ?? ''}
        lettersStyle={animationObj.lettersStyle}
        baseDelay={animationObj.enterDelay ?? {}}
      />
    ) : animationObj.staggerChildren ? (
      <WordBywordStagger
        sentence={animationObj.sentence}
        wordsVariants={animationObj.wordsVariants}
        wordsClassname={animationObj.wordsClassname ?? ''}
        wordsStyles={animationObj.wordsStyles ?? {}}
        wordSpanAttribute={animationObj.wordSpanAttribute ?? {}}
      />
    ) : (
      <motion.div
        // key={animationObj.id}
        initial="initial"
        animate={controls}
        exit="exit"
        variants={animationObj.variants}
        style={animationObj.style ?? {}}
        className={animationObj.className ?? ''}
        id={animationObj.id}
      >
        {children}
      </motion.div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Lottie
        lottieRef={lottieRef}
        animationData={background}
        loop={false}
        autoplay={backgroundAnimationAutoPlay}
        className={styles.lottie_background}
        onComplete={() => {
          // const endTime = Date.now();
          // console.log(Date.now());
          // console.log('time till end:', endTime - timerBegin);
          // console.log(timerBegin);
        }}
      />
      <div
        className={containers.absoluteParentDivClassName}
        style={containers.absoluteParentDivStyle}
      >
        {showLogo && (
          <div className={styles.logo_wrapper}>
            <img src={WrappedLogo} className={styles.logo_img} />
          </div>
        )}
        <div
          className={containers.relativeParentDivClassName}
          style={containers.relativeParentDivStyle}
        >
          {sequences.map(sequence => {
            return (
              <AnimatedSequence
                key={sequence.id}
                animationObj={sequence}
                type="sequence"
                // id={sequence.id}
              >
                {sequence.staggerChildren
                  ? sequence.sentence
                  : sequence.words?.map(word => {
                      return (
                        <AnimatedSequence
                          key={word.id}
                          animationObj={word}
                          type={word.letterByLetter ? 'letter' : 'word'}
                          // id={word.id}
                        >
                          {word.text}
                        </AnimatedSequence>
                      );
                    })}
              </AnimatedSequence>
            );
          })}
        </div>
      </div>
    </div>
  );
};

StoryPageBase.propTypes = exact({
  background: PropTypes.object,
  backgroundAnimationAutoPlay: PropTypes.bool,
  backgroundAnimationDelay: PropTypes.number,
  backgroundAnimationPlaySpeed: PropTypes.number,
  containers: PropTypes.object,
  sequences: PropTypes.array,
  storyIndex: PropTypes.number,
  setCurrentIndex: PropTypes.func,
  // action: PropTypes.func,
  // exposeToParent,
  // setBackgroundAnimationDelayState,
});

export const StoryPage = React.memo(StoryPageBase);
StoryPage.displayName = 'StoryPage';

import { useState, useEffect, useCallback } from 'react';

const defaultOrderByArguments = [];
const defaultLimitToArguments = [];
const defaultFilterTypeArguments = [];
const defaultErrorListener = err => {
  throw err;
};
const defaultValueProcessor = value => value;
export const useFirebaseDB = ({
  path,
  defaultValue = null,
  initialValue = null,
  onceListener = false,
  listenerType = 'value',
  orderByType = null,
  orderByArguments = defaultOrderByArguments,
  limitToType = null,
  limitToArguments = defaultLimitToArguments,
  filterType = null,
  filterTypeArguments = defaultFilterTypeArguments,
  errorListener = defaultErrorListener,
  valueProcessor = defaultValueProcessor,
  shouldConnect = true,
}) => {
  const [snapshotVal, setSnapshotVal] = useState(initialValue);

  const setValueInDB = useCallback(value => database.ref(path).set(value), [
    path,
  ]);

  const pushValueToDB = useCallback(value => database.ref(path).push(value), [
    path,
  ]);

  const removeValueFromDB = useCallback(
    key =>
      database
        .ref(path)
        .child(key)
        .set(null),
    [path]
  );

  useEffect(() => {
    if (!shouldConnect) {
      return () => {};
    }

    const databaseRef = database.ref(path);
    const databaseListener = snapshot =>
      setSnapshotVal(
        snapshot.val() === null ? defaultValue : valueProcessor(snapshot.val())
      );

    /* Unfortunately because of a certain bug in firebase i could not use the builder pattern to
     * to build my query incrementally. When i tried that the query did not work
     */
    if (limitToType && orderByType) {
      /* eslint-disable no-unexpected-multiline */
      databaseRef[orderByType](...orderByArguments)
        [limitToType](...limitToArguments)
        [onceListener ? 'once' : 'on'](
          listenerType,
          databaseListener,
          errorListener
        );
      /* eslint-enable no-unexpected-multiline */
    } else if (filterType && orderByType) {
      /* eslint-disable no-unexpected-multiline */
      databaseRef[orderByType](...orderByArguments)
        [filterType](...filterTypeArguments)
        [onceListener ? 'once' : 'on'](
          listenerType,
          databaseListener,
          errorListener
        );
      /* eslint-enable no-unexpected-multiline */
    } else if (orderByType) {
      databaseRef[orderByType](...orderByArguments)[
        onceListener ? 'once' : 'on'
      ](listenerType, databaseListener, errorListener);
    } else {
      databaseRef[onceListener ? 'once' : 'on'](
        listenerType,
        databaseListener,
        errorListener
      );
    }

    return () => databaseRef.off(listenerType, databaseListener);
  }, [
    path,
    onceListener,
    defaultValue,
    listenerType,
    orderByType,
    orderByArguments,
    limitToType,
    limitToArguments,
    filterType,
    filterTypeArguments,
    errorListener,
    valueProcessor,
    shouldConnect,
  ]);

  return [snapshotVal, setValueInDB, pushValueToDB, removeValueFromDB];
};

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classNames from 'classnames';
import styles from './SwitchToContinuityDialog.scss';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import checkedImage from '../../../../assets/check-icon.png';
import maintenanceProgramImage from '../../../../assets/maintenance-program.png';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { cacheImagesByURIs } from '../../../utils/utils';

cacheImagesByURIs(maintenanceProgramImage);

export const SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS = {
  INTRO: 'INTRO',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  BILLING: 'BILLING',
  SWITCH_SCHEDULED: 'SWITCH_SCHEDULED',
  LOADER: 'LOADER',
  SWITCH_FAILED: 'SWITCH_FAILED',
};

const SwitchToContinuityDialogBase = ({
  isOpen,
  renderedStep,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  headsetDetails,
  nextBillingCycleDate,
  creditForContinuityHeadset,
}) => {
  const renderIntroStep = () => {
    return (
      <>
        <p className={styles.dialog_header}>Let your clients train for less</p>
        <p>
          This is a great option if this user no longer needs your supervision.
          <br />
          In Continuity Mode, you pay less for this user’s headset usage.
        </p>
      </>
    );
  };

  const renderHowItWorksStep = () => {
    return (
      <>
        <p className={styles.dialog_header}>How it works?</p>
        <p className={styles.max_width_desktop}>
          1. Switching the headset to Continuity Mode activates it for all users
          that use it.
        </p>
        <p>
          2. You can extend their current training program, but cannot change
          other parameters or view their data.{' '}
        </p>

        <p>
          3. You will receive ${creditForContinuityHeadset} in credits added to
          your balance every month for every headset that is active in
          Continuity Mode.
        </p>
      </>
    );
  };

  const renderBillingStep = () => {
    return (
      <>
        <p className={styles.dialog_header}>Billing</p>
        <p>
          Upon your confirmation, headset {headsetDetails.lastUsedHeadsetId}{' '}
          will switch to Continuity Mode starting from the next billing cycle (
          {nextBillingCycleDate}).
        </p>
        <p>You can switch it back to Normal Mode anytime.</p>{' '}
      </>
    );
  };

  const renderSwitchScheduledStep = () => {
    return (
      <>
        <div className={styles.done_image_container}>
          <img src={checkedImage} />
          <p className={classNames(styles.dialog_header, styles.no_margin)}>
            Switch Scheduled!
          </p>
        </div>
        <p>
          {' '}
          Headset {headsetDetails.lastUsedHeadsetId} will switch to Continuity
          Mode starting from the next billing cycle ({nextBillingCycleDate}).
        </p>
        <p>You can switch it back to Normal Mode anytime.</p>{' '}
      </>
    );
  };

  const renderSwitchFailedStep = () => {
    return (
      <>
        <p className={styles.dialog_header}>Switch failed</p>
        <p>Something went wrong...</p>
      </>
    );
  };

  const renderLoaderStep = () => {
    return (
      <div className={styles.loader_container}>
        <Loader padding="0px 0px" />
      </div>
    );
  };

  const primaryBtnContent = (() => {
    switch (renderedStep) {
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_SCHEDULED:
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_FAILED:
        return 'done';
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.BILLING:
        return 'confirm';
      default:
        return 'next';
    }
  })();

  const secondaryBtnContent =
    renderedStep ===
      SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_SCHEDULED ||
    renderedStep === SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_FAILED
      ? null
      : 'cancel';

  return (
    <CustomDialog
      isOpen={isOpen}
      primaryBtnContent={primaryBtnContent}
      secondaryBtnContent={secondaryBtnContent}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={onSecondaryBtnClick}
      customClasses={{
        footer_container: styles.footer_container,
        content_container: styles.content_container,
        dialog_paper: styles.dialog_paper,
        footer: {
          secondary_button: styles.secondary_button,
        },
      }}
      shouldShowButtons={
        renderedStep !== SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.LOADER
      }
      maxWidth="md"
    >
      <div className={styles.content}>
        <div className={styles.image_container}>
          <img src={maintenanceProgramImage} />
        </div>
        <div className={styles.step_content}>
          {renderedStep === SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.INTRO &&
            renderIntroStep()}
          {renderedStep ===
            SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.HOW_IT_WORKS &&
            renderHowItWorksStep()}
          {renderedStep ===
            SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.BILLING &&
            renderBillingStep()}
          {renderedStep ===
            SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_SCHEDULED &&
            renderSwitchScheduledStep()}
          {renderedStep ===
            SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_FAILED &&
            renderSwitchFailedStep()}
          {renderedStep === SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.LOADER &&
            renderLoaderStep()}
        </div>
      </div>
    </CustomDialog>
  );
};

SwitchToContinuityDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  renderedStep: PropTypes.string.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
  headsetDetails: PropTypes.object.isRequired,
  nextBillingCycleDate: PropTypes.string.isRequired,
  creditForContinuityHeadset: PropTypes.number.isRequired,
});

export const SwitchToContinuityDialog = React.memo(
  SwitchToContinuityDialogBase
);
SwitchToContinuityDialog.displayName = 'SwitchToContinuityDialog';

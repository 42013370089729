import React from 'react';

export const NoDataSvg = props => (
  <svg id="prefix__Layer_1" data-name="Layer 1" viewBox="0 0 107 93" {...props}>
    <defs>
      <style>{'.prefix__cls-1{opacity:.1;isolation:isolate}'}</style>
    </defs>
    <rect
      id="prefix__Rectangle_217"
      data-name="Rectangle 217"
      className="prefix__cls-1"
      width={17}
      height={93}
      rx={3}
    />
    <rect
      id="prefix__Rectangle_218"
      data-name="Rectangle 218"
      className="prefix__cls-1"
      x={30}
      y={42}
      width={17}
      height={51}
      rx={3}
    />
    <rect
      id="prefix__Rectangle_219"
      data-name="Rectangle 219"
      className="prefix__cls-1"
      x={60}
      y={22}
      width={17}
      height={71}
      rx={3}
    />
    <rect
      id="prefix__Rectangle_220"
      data-name="Rectangle 220"
      className="prefix__cls-1"
      x={90}
      y={48}
      width={17}
      height={45}
      rx={3}
    />
  </svg>
);

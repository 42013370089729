exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NotesTable_size_17{font-size:17px}.NotesTable_size_10{font-size:10px}.NotesTable_size_11{font-size:11px}.NotesTable_size_13{font-size:13px}.NotesTable_size_15{font-size:15px}.NotesTable_size_14{font-size:14px}.NotesTable_size_16{font-size:16px}.NotesTable_size_12{font-size:12px}.NotesTable_extra_header{font-size:18px;font-weight:500;color:#000000}.NotesTable_header{font-size:17px;font-weight:500;color:#000000}.NotesTable_header_only_font_size{font-size:17px;font-weight:500}.NotesTable_sub_header{font-size:13px;color:#878787}.NotesTable_sub_header_black{font-size:13px;color:black}.NotesTable_sub_header_only_font_size{font-size:13px}.NotesTable_paragraph_only_font_size{font-size:12px}.NotesTable_tab_font_size{font-size:13px}.NotesTable_pop_out_header{font-size:20px}.NotesTable_title{font-size:15px;font-weight:500;color:#484848}.NotesTable_title_black{font-size:15px;font-weight:500;color:black}.NotesTable_title_only_font_size{font-size:15px}.NotesTable_paragraph,.NotesTable_cell{font-size:12px;color:#484848}.NotesTable_btn{font-size:14px}.NotesTable_table_container th{text-align:center}@media only screen and (max-width: 768px){.NotesTable_table_container th{padding:4px 24px}}@media only screen and (max-width: 480px){.NotesTable_table_container th{padding:4px 12px}}.NotesTable_table_container td{text-align:center}@media only screen and (max-width: 768px){.NotesTable_table_container td{padding:4px 24px}}@media only screen and (max-width: 480px){.NotesTable_table_container td{padding:4px 12px}}.NotesTable_table_container tr:last-child td{border:none}.NotesTable_root{overflow:auto}.NotesTable_collapsed{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-height:30px}.NotesTable_note_menu_container{padding:10px 10px 0 10px}.NotesTable_note_menu_item{cursor:pointer;margin-bottom:10px}.NotesTable_collapsed_text{text-overflow:ellipsis;overflow:hidden;white-space:nowrap}\n", ""]);

// Exports
exports.locals = {
	"size_17": "NotesTable_size_17",
	"size_10": "NotesTable_size_10",
	"size_11": "NotesTable_size_11",
	"size_13": "NotesTable_size_13",
	"size_15": "NotesTable_size_15",
	"size_14": "NotesTable_size_14",
	"size_16": "NotesTable_size_16",
	"size_12": "NotesTable_size_12",
	"extra_header": "NotesTable_extra_header",
	"header": "NotesTable_header",
	"header_only_font_size": "NotesTable_header_only_font_size",
	"sub_header": "NotesTable_sub_header",
	"sub_header_black": "NotesTable_sub_header_black",
	"sub_header_only_font_size": "NotesTable_sub_header_only_font_size",
	"paragraph_only_font_size": "NotesTable_paragraph_only_font_size",
	"tab_font_size": "NotesTable_tab_font_size",
	"pop_out_header": "NotesTable_pop_out_header",
	"title": "NotesTable_title",
	"title_black": "NotesTable_title_black",
	"title_only_font_size": "NotesTable_title_only_font_size",
	"paragraph": "NotesTable_paragraph",
	"cell": "NotesTable_cell",
	"btn": "NotesTable_btn",
	"table_container": "NotesTable_table_container",
	"root": "NotesTable_root",
	"collapsed": "NotesTable_collapsed",
	"note_menu_container": "NotesTable_note_menu_container",
	"note_menu_item": "NotesTable_note_menu_item",
	"collapsed_text": "NotesTable_collapsed_text"
};
import React, { useRef } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { SubjectIcon } from '../../../Core/Icons/SubjectIcon';
import { AssignmentIcon } from '../../../Core/Icons/AssignmentIcon';
import { VideoIcon } from '../../../Core/Icons/VideoIcon';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './LagoListItem.scss';
import placeHolder from '../../../../assets/add-photo.png';
// import { LagoExpandedList } from '../LagoExpandedList/LagoExpandedList';

const LagoListItemBase = ({
  id,
  element,
  onItemClicked,
  onDeleteItemClicked,
  currentStage,
  onEditItemClicked,
  onAddNewVariantClicked,
  editVariant,
  variantClicked,
  isGoalEdit,
  isFromJournal,
  noVariantClicked,
  isCheckingEdit,
  isLoadingVideo,
  onMoveModuleClicked,
  isFinalizedState,
  selectedScreen,
  setPreviewHieght,
  displayNotificationsModal,
  setSelectedNotificationsLesson,
  onLessonHover,
  lessonNotificationTitleTooltip,
  lessonNotificationBodyTooltip,
  isFromArticle,
}) => {
  // const [toggleExpanded, setToggleExpanded] = useState(false);

  let itemDefaultVariant = '';
  if (
    currentStage === 4 ||
    isFromJournal ||
    isGoalEdit ||
    isCheckingEdit ||
    isFromArticle
  ) {
    Object.keys(element.variants).forEach(variantId => {
      if (element.variants[variantId].variantConfig.isDefaultVariant) {
        itemDefaultVariant = variantId;
      }
    });
    // if (isGoalEdit || isCheckingEdit) {
    //   // eslint-disable-next-line prefer-destructuring
    //   itemDefaultVariant = Object.keys(element.variants)[0];
    // }
  }
  const renderSideIcon = () => {
    if (
      currentStage !== 0 &&
      currentStage !== 4 &&
      !isFromJournal &&
      !isGoalEdit &&
      !isCheckingEdit &&
      !isFromArticle
    ) {
      const imagePath = element.img;
      // if (
      //   (isFromJournal || isGoalEdit || isCheckingEdit) &&
      //   element.variants[itemDefaultVariant].img
      // ) {
      //   imagePath = element.variants[itemDefaultVariant].img;
      // }
      return (
        <img
          src={imagePath || placeHolder}
          width={30}
          height={30}
          style={{ marginRight: 5 }}
        />
      );
    }
    if (currentStage === 0) {
      if (element.isFinalized) {
        return <LockIcon />;
      }
      return '• ';
    }
    if (element.variants[itemDefaultVariant].screenType === 'content') {
      let imagePath = '';
      if (
        element &&
        element.variants &&
        element.variants[itemDefaultVariant] &&
        element.variants[itemDefaultVariant].img
      ) {
        imagePath = element.variants[itemDefaultVariant].img;
      }
      return (
        <span
          style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
        >
          <AssignmentIcon />
          <img
            src={imagePath || placeHolder}
            width={30}
            height={30}
            style={{ marginRight: 5, marginLeft: 5 }}
          />
        </span>
      );
    }
    if (element.variants[itemDefaultVariant].screenType === 'vid') {
      let imagePath = '';
      if (
        element &&
        element.variants &&
        element.variants[itemDefaultVariant] &&
        element.variants[itemDefaultVariant].img
      ) {
        imagePath = element.variants[itemDefaultVariant].img;
      }
      return (
        <span
          style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
        >
          <VideoIcon />
          <img
            src={imagePath || placeHolder}
            width={30}
            height={30}
            style={{ marginRight: 5, marginLeft: 5 }}
          />
        </span>
      );
    }
    if (element.variants[itemDefaultVariant].screenType === 'quiz') {
      let imagePath = '';
      if (
        element &&
        element.variants &&
        element.variants[itemDefaultVariant] &&
        element.variants[itemDefaultVariant].img
      ) {
        imagePath = element.variants[itemDefaultVariant].img;
      }
      return (
        <span
          style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
        >
          <SubjectIcon />
          <img
            src={imagePath || placeHolder}
            width={30}
            height={30}
            style={{ marginRight: 5, marginLeft: 5 }}
          />
        </span>
      );
    }
    return '• ';
  };

  const renderVariants = el => {
    if (
      currentStage === 4 ||
      isFromJournal ||
      isGoalEdit ||
      isCheckingEdit ||
      isFromArticle
    ) {
      if (el.variants) {
        const orderedVariants = Object.keys(el.variants).sort(
          (a, b) => el.variants[a].dateAdded - el.variants[b].dateAdded
        );
        return (
          <div style={{ marginLeft: '15px' }}>
            {Object.keys(el.variants).length > 1
              ? orderedVariants.map(variantKey => {
                  return (
                    <div
                      key={variantKey}
                      className={styles.variant_item_container}
                      onClick={() => variantClicked(variantKey)}
                    >
                      • {el.variants[variantKey].variantConfig.variantTitle}
                      {!el.variants[variantKey].variantConfig
                        .isDefaultVariant ? (
                        <span
                          className={styles.variant_item_edit}
                          onClick={() => {
                            editVariant(variantKey);
                          }}
                        >
                          ✎
                        </span>
                      ) : null}
                    </div>
                  );
                })
              : null}
            <Button
              onClick={onAddNewVariantClicked}
              disabled={isFinalizedState}
            >
              + Add variant
            </Button>
          </div>
        );
      }
      return (
        <div style={{ marginLeft: '15px' }}>
          <Button onClick={onAddNewVariantClicked} disabled={isFinalizedState}>
            + Add variant
          </Button>
        </div>
      );
    }
    return null;
  };

  const renderTitle = el => {
    if (currentStage !== 4 && !isFromJournal && !isFromArticle) {
      return el && el.title ? el.title.en : '';
    }
    return el.variants[itemDefaultVariant].title.en;
  };

  // DONT SHOW GOAL INSIDE LIST
  if (currentStage === 3 && element.goalId) {
    return null;
  }

  // const renderExpanded = () => (
  //   <div style={{ padding: '5px' }}>
  //     <LagoExpandedList stage={currentStage} element={element} />
  //   </div>
  // );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const el = useRef(null);
  if (
    id === selectedScreen &&
    currentStage === 4 &&
    el &&
    el.current &&
    setPreviewHieght
  ) {
    setPreviewHieght(el.current.offsetTop);
  }

  return (
    <div>
      {/* {!isGoalEdit &&
      !isCheckingEdit &&
      !isFromJournal &&
      currentStage !== 4 ? (
        <span
          onClick={() => setToggleExpanded(!toggleExpanded)}
          style={{
            position: 'relative',
            left: '-5px',
            top: '35px',
            cursor: 'pointer',
          }}
        >
          {toggleExpanded ? '-' : '+'}
        </span>
      ) : null} */}
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        onClick={onItemClicked}
        ref={el}
      >
        <div
          className={styles.lago_list_item_container}
          onClick={noVariantClicked}
        >
          <span className={styles.lago_list_item_text}>
            {renderSideIcon()}
            <span
              style={
                id === selectedScreen && currentStage === 4
                  ? {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontWeight: 'bolder',
                    }
                  : { textOverflow: 'ellipsis', overflow: 'hidden' }
              }
            >
              {renderTitle(element)}
            </span>
          </span>
          <div className={styles.lago_list_btn_container}>
            <Button
              onClick={onDeleteItemClicked}
              style={{ minWidth: '40px' }}
              disabled={isLoadingVideo || isFinalizedState}
            >
              <DeleteIcon />
            </Button>
            {currentStage === 4 || isFromJournal || isFromArticle ? (
              <Button
                onClick={onEditItemClicked}
                style={{ minWidth: '40px' }}
                disabled={isLoadingVideo}
              >
                <EditIcon />
              </Button>
            ) : null}
            {currentStage === 2 && !isFromJournal ? (
              <Button
                onClick={e => onMoveModuleClicked(e, id)}
                style={{ minWidth: '40px' }}
                disabled={isLoadingVideo || isFinalizedState}
              >
                <TrendingFlatIcon />
              </Button>
            ) : null}
            {currentStage === 3 && !isFromJournal ? (
              <Tooltip
                disableTriggerFocus
                title={
                  lessonNotificationTitleTooltip &&
                  lessonNotificationBodyTooltip ? (
                    <span style={{ fontSize: '1.5rem' }}>
                      <p />
                      <p>{`Title: ${lessonNotificationTitleTooltip}`}</p>
                      <p>{`Body: ${lessonNotificationBodyTooltip}`}</p>
                    </span>
                  ) : (
                    <span style={{ fontSize: '1.5rem' }}>
                      Add lesson notification here
                    </span>
                  )
                }
              >
                <Button
                  onClick={e => {
                    setSelectedNotificationsLesson(id);
                    displayNotificationsModal(e, id);
                  }}
                  onMouseEnter={() => {
                    onLessonHover(id);
                  }}
                  style={{ minWidth: '40px' }}
                  disabled={isLoadingVideo || isFinalizedState}
                >
                  <NotificationsIcon
                    style={
                      element.notification && element.notification.isEnabled
                        ? { color: '#3f8f95' }
                        : { color: '#9b9b9b' }
                    }
                  />
                </Button>
              </Tooltip>
            ) : null}
          </div>
        </div>
        {renderVariants(element)}
      </div>
      {/* {!isGoalEdit &&
      !isCheckingEdit &&
      !isFromJournal &&
      currentStage !== 4 &&
      toggleExpanded
        ? renderExpanded()
        : null} */}
    </div>
  );
};

LagoListItemBase.propTypes = exact({
  id: PropTypes.string,
  element: PropTypes.object.isRequired,
  onItemClicked: PropTypes.func,
  onDeleteItemClicked: PropTypes.func,
  onEditItemClicked: PropTypes.func,
  onAddNewVariantClicked: PropTypes.func,
  currentStage: PropTypes.number,
  editVariant: PropTypes.func,
  variantClicked: PropTypes.func,
  isGoalEdit: PropTypes.bool,
  isFromJournal: PropTypes.bool,
  noVariantClicked: PropTypes.func,
  isCheckingEdit: PropTypes.bool,
  isLoadingVideo: PropTypes.bool,
  onMoveModuleClicked: PropTypes.func,
  isFinalizedState: PropTypes.bool,
  selectedScreen: PropTypes.string,
  setPreviewHieght: PropTypes.func,
  displayNotificationsModal: PropTypes.func,
  setSelectedNotificationsLesson: PropTypes.func,
  onLessonHover: PropTypes.func,
  lessonNotificationTitleTooltip: PropTypes.string,
  lessonNotificationBodyTooltip: PropTypes.string,
  isFromArticle: PropTypes.bool,
});

export const LagoListItem = React.memo(LagoListItemBase);
LagoListItem.displayName = 'LagoListItem';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import pick from 'lodash/pick';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Paper } from '@material-ui/core';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';
import { CPTPerformanceGraph } from '../../CPTPerformanceGraph/CPTPerformanceGraph';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';
import increasingArrowPNG from '../../../../assets/increasing-arrow.png';
import decreasingArrowPNG from '../../../../assets/decreasing-arrow.png';
import styles from './AssessmentCPTPerformanceComparisonGraph.scss';
import { ASSESSMENT_COLORS } from '../../../models/assessments/assessments';

const AssessmentCPTPerformanceComparisonGraphBase = ({
  data,
  preOverallScore,
  postOverallScore,
  isInPreview,
  preAssessment,
  postAssessment,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  noDataIndicator,
}) => {
  const subtractionValue = parseInt(postOverallScore - preOverallScore, 10);

  const formattedSubtractionValueText =
    subtractionValue > 0 ? `+${subtractionValue}%` : `${subtractionValue}%`;

  const subtractionValueIconPNG =
    subtractionValue > 0 ? increasingArrowPNG : decreasingArrowPNG;

  const subtractionValueTextColor =
    (subtractionValue === 0 && 'grey') ||
    (subtractionValue > 0 && ASSESSMENT_COLORS.POSITIVE) ||
    (subtractionValue < 0 && ASSESSMENT_COLORS.NEGATIVE);

  return (
    <div style={{ marginBottom: 30 }}>
      <AssessmentComparisonGraph
        assessmentOptions={assessmentOptions}
        preAssessment={preAssessment}
        postAssessment={postAssessment}
        preAssessmentTimestamp={preAssessmentTimestamp}
        postAssessmentTimestamp={postAssessmentTimestamp}
        onPreAssessmentChange={onPreAssessmentChange}
        onPostAssessmentChange={onPostAssessmentChange}
        isInPreview={isInPreview}
      >
        {noDataIndicator || (
          <div className={styles.main_content}>
            <Paper>
              <div className={styles.comparison_between_overall_scores}>
                <p className={styles.overall_score_title}>Total Score</p>
                <div className={styles.result_change_percentage_container}>
                  <div>
                    <p>Pre</p>
                    <div className={styles.progress_comparison}>
                      <CircularProgressbar
                        value={preOverallScore}
                        text={`${preOverallScore}%`}
                      />
                    </div>
                  </div>
                  <div className={styles.vertical_border} />
                  <div>
                    <p>Post</p>
                    <div className={styles.progress_comparison}>
                      <CircularProgressbar
                        value={postOverallScore}
                        text={`${postOverallScore}%`}
                      />
                    </div>
                    {subtractionValue !== 0 &&
                      Number.isFinite(subtractionValue) && (
                        <span>
                          (
                          <img
                            src={subtractionValueIconPNG}
                            style={{ marginLeft: 1 }}
                          />
                          <span
                            style={{
                              color: subtractionValueTextColor,
                              marginRight: 3,
                            }}
                          >
                            {formattedSubtractionValueText}
                          </span>
                          )
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </Paper>
            <CPTPerformanceGraph
              data={data.map(scoreObject =>
                pick(scoreObject, ['category', 'preScore', 'postScore'])
              )}
              tableTitle="Raw Values"
            />
            <CPTPerformanceGraph
              data={data.map(scoreObject =>
                pick(scoreObject, [
                  'category',
                  'prePerformanceScore',
                  'postPerformanceScore',
                  'prePerformanceRank',
                  'postPerformanceRank',
                ])
              )}
              tableTitle="Standardized Values"
            />
          </div>
        )}
      </AssessmentComparisonGraph>
    </div>
  );
};

AssessmentCPTPerformanceComparisonGraphBase.defaultProps = {
  isInPreview: false,
  noDataIndicator: null,
};

AssessmentCPTPerformanceComparisonGraphBase.propTypes = exact({
  data: PropTypes.array.isRequired,
  preOverallScore: PropTypes.number,
  postOverallScore: PropTypes.number,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  assessmentOptions: PropTypes.array,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  noDataIndicator: PropTypes.node,
});

export const AssessmentCPTPerformanceComparisonGraph = React.memo(
  AssessmentCPTPerformanceComparisonGraphBase
);
AssessmentCPTPerformanceComparisonGraph.displayName =
  'AssessmentCPTPerformanceComparisonGraph';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import dayJS from 'dayjs';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core';
import styles from './CallLogs.scss';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';

const planTypes = {
  continuityPlans: 'continuityPlans',
  corePlans: 'corePlans',
  familyMemberPlans: 'familyMemberPlans',
};

const formattedPlanTypes = {
  [planTypes.corePlans]: 'Core Training',
  [planTypes.continuityPlans]: 'Continuity Training',
  [planTypes.familyMemberPlans]: 'Family Member Training',
};

const CustomLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#EFEFEF',
  },
  barColorPrimary: {
    backgroundColor: '#51e5d6',
    borderRadius: '8px',
  },
  root: {
    borderRadius: '8px',
    height: '16px',
    marginTop: '10px',
    marginRight: '18px',
  },
})(LinearProgress);

const CallLogsBase = ({ customerId, userInfo }) => {
  const [isCallLogHovered, setIsCallLogHovered] = useState(false);

  const [callsCapacityInfo] = useFirebaseDB({
    path: `remoteUsers/callsCapacity/${customerId}`,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
  });

  const [scheduledCalls] = useFirebaseDB({
    path: `remoteUsers/scheduledCalls/${customerId}`,
    initialValue: useRef({}).current,
    defaultValue: useRef({}).current,
  });

  const [planType] = useFirebaseDB({
    path: `/remoteBilling/billingConfigs/${userInfo.remoteAdminUserId}/codes/${userInfo.activationCode}/selectedPlan/planType`,
  });

  const calcStatus = callObject => {
    if (callObject.canceled) {
      return <div style={{ color: '#f16868' }}>Canceled</div>;
    }
    return callObject.timestamp <= Date.now() ? (
      <div style={{ color: '#62cabe' }}>Completed</div>
    ) : (
      <div>Upcoming</div>
    );
  };

  const sortedCalls = Object.keys(scheduledCalls).sort(
    (a, b) => scheduledCalls[b].timestamp - scheduledCalls[a].timestamp
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{formattedPlanTypes[planType]}</div>
      <div className={styles.info_row}>
        <div>
          {callsCapacityInfo.callsAvailable - callsCapacityInfo.callsUsed}/
          {callsCapacityInfo.callsAvailable} Unused Call Credits
        </div>
        {Object.keys(scheduledCalls).length ? (
          <div
            className={styles.hoverable}
            onMouseEnter={() => {
              setIsCallLogHovered(true);
            }}
            // onMouseLeave={() => {
            //   setIsCallLogHovered(false);
            // }}
          >
            CALL LOG
          </div>
        ) : null}
      </div>
      <CustomLinearProgress
        variant="determinate"
        value={
          (callsCapacityInfo.callsUsed / callsCapacityInfo.callsAvailable) * 100
        }
      />
      {isCallLogHovered ? (
        <div className={styles.tooltip_container}>
          <div
            className={styles.blocker}
            onClick={() => {
              setIsCallLogHovered(false);
            }}
          />
          <div className={styles.tooltip_arrow} />
          <div
            className={styles.info_row_tooltip}
            style={{ fontWeight: 900, color: '#727070' }}
          >
            <div>Date</div>
            <div>Status</div>
          </div>
          <div className={styles.divider} />
          {// eslint-disable-next-line array-callback-return, consistent-return, no-unused-vars
          sortedCalls.map(callId => {
            if (scheduledCalls[callId].isNeuroCoachEvent) {
              return (
                <div className={styles.info_row_tooltip}>
                  <div>
                    {dayJS(scheduledCalls[callId].timestamp).format(
                      DAY_JS_DATE_FORMATS.american
                    )}
                  </div>
                  <div>{calcStatus(scheduledCalls[callId])}</div>
                </div>
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

CallLogsBase.propTypes = exact({
  customerId: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
});

export const CallLogs = React.memo(CallLogsBase);
CallLogs.displayName = 'CallLogs';

import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './PreviewReportModalContent.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const stylesFn = theme => ({
  button: {
    margin: theme.spacing.unit,
    borderRadius: '4px',
    fontSize: '14px',
  },
  doneButton: {
    color: 'black',
    margin: theme.spacing.unit,
    borderRadius: '4px',
    fontSize: '14px',
  },
});

const PreviewReportModalContentBase = ({
  classes,
  reportCards,
  onClickAway,
  onDoneClick,
  onGenerateReport,
  printMode,
}) => {
  const t = useTranslation();

  const renderReportCards = () => (
    <div className={styles.report_cards_container} id="report">
      {reportCards.map(reportCard => (
        <div id={reportCard.id} key={reportCard.id}>
          {reportCard.content}
        </div>
      ))}
    </div>
  );

  return printMode ? (
    renderReportCards()
  ) : (
    <ClickAwayListener onClickAway={onClickAway}>
      <Paper classes={{ root: styles.paper_root }}>
        <div className={styles.header_container}>
          <p>{t('report_will_look_like_description')}</p>
          <div>
            <Button
              variant="text"
              className={classes.doneButton}
              color="default"
              onClick={onDoneClick}
            >
              {t('continue_editing')}
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              onClick={onGenerateReport}
              classes={{ root: styles.cancel_button }}
              color="primary"
            >
              {t('generate_report')}
            </Button>
          </div>
        </div>

        <div className={styles.content}>{renderReportCards()}</div>
      </Paper>
    </ClickAwayListener>
  );
};

PreviewReportModalContentBase.propTypes = {
  classes: PropTypes.object.isRequired,
  reportCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      content: PropTypes.node,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  onClickAway: PropTypes.func,
  onGenerateReport: PropTypes.func,
  onDoneClick: PropTypes.func,
  printMode: PropTypes.bool,
};

export const PreviewReportModalContent = withStyles(stylesFn)(
  PreviewReportModalContentBase
);

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { RegisterNonfClinicRegistration } from '../RegisterNonfClinicRegistration/RegisterNonfClinicRegistration';

const RegisterNonfClinicRegistrationWrapperBase = ({
  hardware,
  NFPackages,
  hardwareBanner,
  shippingPrice,
  addonGroupId,
}) => {
  const { stripe } = window;
  // const nodeURL = environmentConfig.appengine.node;

  return (
    <div>
      <StripeProvider stripe={stripe} key={stripe ? 'stripe' : 'null'}>
        <Elements>
          <RegisterNonfClinicRegistration
            stripe={stripe}
            // nodeURL={nodeURL}
            hardware={hardware}
            NFPackages={NFPackages}
            hardwareBanner={hardwareBanner}
            shippingPrice={shippingPrice}
            addonGroupId={addonGroupId}
          />
        </Elements>
      </StripeProvider>
    </div>
  );
};

RegisterNonfClinicRegistrationWrapperBase.propTypes = exact({
  hardware: PropTypes.object.isRequired,
  NFPackages: PropTypes.object.isRequired,
  hardwareBanner: PropTypes.string,
  shippingPrice: PropTypes.object.isRequired,
  addonGroupId: PropTypes.string.isRequired,
});

export const RegisterNonfClinicRegistrationWrapper = React.memo(
  RegisterNonfClinicRegistrationWrapperBase
);
RegisterNonfClinicRegistrationWrapper.displayName =
  'RegisterNonfClinicRegistrationWrapper';

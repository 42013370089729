import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import { Dialog, TextField, Switch, Button } from '@material-ui/core';
import styles from './ReferralForm.scss';
import referralCallToActionImage from '../../../assets/lady-holding-myndlift-tablet.png';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { isValidEmail } from '../../utils/utils';
import { Loader } from '../../Core/Components/Loader/Loader';

const referralFormRenderedSteps = {
  form: 'form',
  emailWasSent: 'emailWasSent',
  loader: 'loader',
};

const ReferralFormBase = ({ isOpen, onClose, credit, referralCoupon }) => {
  const { handleSubmit, register, errors, reset: resetForm } = useForm();

  const [renderedStep, setRenderedStep] = useState(
    referralFormRenderedSteps.form
  );

  const [shouldSendReminder, setShouldSendReminder] = useState(true);
  const onShouldSendReminderChange = useCallback(
    event => setShouldSendReminder(event.target.checked),
    []
  );

  const onSubmit = useCallback(
    values => {
      (async () => {
        setRenderedStep(referralFormRenderedSteps.loader);

        const sendEmailRequest = await fireFunctionPost(
          'clinic_apis-clinicReferrals',
          {
            emails: values.to.split(',').map(email => email.trim()),
            personalNote: values.note,
            withReminders: shouldSendReminder,
          }
        );

        if (sendEmailRequest.result && sendEmailRequest.result === true) {
          setRenderedStep(referralFormRenderedSteps.emailWasSent);
        }

        new MyEvent('on_referral_form_sending_email').log({
          emails: values.to,
          personalNote: values.note,
          withReminders: shouldSendReminder,
        });
      })();
    },
    [shouldSendReminder]
  );

  const closeReferralForm = useCallback(() => {
    onClose();
    resetForm();
    // a hack to make the close more smooth
    setTimeout(() => setRenderedStep(referralFormRenderedSteps.form), 250);
    new MyEvent('on_referral_form_close').log();
  }, [onClose, resetForm]);

  const renderLoaderStep = () => (
    <div className={styles.loader_root}>
      <Loader padding="0px 0px" />
    </div>
  );

  const renderEmailWasSentStep = () => (
    <div className={styles.email_was_sent_root}>
      <p>Invite Sent!</p>
      <p>
        We will let you know once your friend signs up so you can enjoy the
        credit!
      </p>
      <div className={styles.button_container}>
        <Button variant="contained" color="primary" onClick={closeReferralForm}>
          <span className={styles.btn}> Ok</span>
        </Button>
      </div>
    </div>
  );

  const renderFormStep = () => (
    <div className={styles.root}>
      <div className={styles.content_container}>
        <div className={styles.content}>
          <p>
            Get <span style={{ color: '#4be0d0' }}>${credit}</span> in credit
            for every new clinician you invite to Myndlift!
          </p>
          <p>
            Your peers can use this special promo code to get a ${credit}{' '}
            discount when they register. Once they do, you’ll get ${credit} in
            credit. Note that credit must all be used in the same purchase and
            does not roll over.
          </p>

          <p>
            Promo Code:{' '}
            <span style={{ color: '#4be0d0', fontWeight: 500 }}>
              {referralCoupon}
            </span>
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputs_container}>
            <TextField
              label="To:"
              placeholder="Enter your peers’ emails separated by commas"
              fullWidth
              margin="dense"
              inputProps={{
                name: 'to',
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  shrink: styles.input_label_shrinked,
                },
              }}
              inputRef={register({
                required: 'Email is required',
                validate: {
                  invalidEmail: emailsSeparatedByComma =>
                    emailsSeparatedByComma
                      .split(',')
                      .map(email => email.trim())
                      .every(email => isValidEmail(email)) || 'Invalid email/s',
                },
              })}
            />
            <p
              style={{ visibility: errors.to ? 'visible' : 'hidden' }}
              className={styles.error}
            >
              {get(errors, 'to.message', 'error message to set element height')}
            </p>
            <TextField
              label="Note:"
              placeholder="Your personal message goes here. Promo code offer will be inserted automatically"
              fullWidth
              multiline
              margin="dense"
              inputProps={{
                name: 'note',
                style: { minHeight: '1.1em' },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  shrink: styles.input_label_shrinked,
                },
              }}
              inputRef={register({
                validate: {
                  maxLength: value =>
                    value.length < 1000 || 'Max length is 1000 characters',
                },
              })}
            />
            <p
              style={{ visibility: errors.note ? 'visible' : 'hidden' }}
              className={styles.error}
            >
              {get(
                errors,
                'note.message',
                'error message to set element height'
              )}
            </p>

            <div className={styles.switch_container}>
              <div>
                <Switch
                  checked={shouldSendReminder}
                  onChange={onShouldSendReminderChange}
                  color="primary"
                  disableRipple
                  disableTouchRipple
                />
              </div>
              <p>Send my peers reminder emails about this</p>
            </div>

            <div className={styles.button_container}>
              <Button variant="contained" color="primary" type="submit">
                <span className={styles.btn}> SEND EMAIL</span>
              </Button>
            </div>
          </div>
        </form>
      </div>

      <div className={styles.image_container}>
        <img src={referralCallToActionImage} />
      </div>
    </div>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeReferralForm}
      classes={{ paper: styles.dialog_paper_root }}
    >
      {renderedStep === referralFormRenderedSteps.form && renderFormStep()}
      {renderedStep === referralFormRenderedSteps.emailWasSent &&
        renderEmailWasSentStep()}
      {renderedStep === referralFormRenderedSteps.loader && renderLoaderStep()}
    </Dialog>
  );
};

ReferralFormBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  credit: PropTypes.number,
  referralCoupon: PropTypes.string,
});

export const ReferralForm = withEntryComponent(React.memo(ReferralFormBase));
ReferralForm.displayName = 'ReferralForm';

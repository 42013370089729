import dayJS from 'dayjs';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';

export const getInsightItemDetails = ({ insightItem, insightsConfig }) => {
  const { type, protocol, timestamp } = insightItem;
  const graphConfig = insightsConfig.content[type];

  if (graphConfig) {
    const fillValues = string => {
      const variablesWithCurlyBrackets = string.match(/\{(.*?)\}/g) || [];
      const variables = variablesWithCurlyBrackets.map(curlyBracketsVariables =>
        curlyBracketsVariables.replace('{', '').replace('}', '')
      );
      const variablesValues = variables
        .map(
          variableName => insightItem[variableName] || graphConfig[variableName]
        )
        .map(val => insightsConfig.presentableStrings[val] || val)
        .map(val => (typeof val === 'number' ? Math.round(val) : val));
      const stringWithValues = variablesWithCurlyBrackets.reduce(
        (acc, cur, index) => {
          return acc.replace(cur, variablesValues[index]);
        },
        string
      );
      return stringWithValues;
    };

    const {
      title,
      message,
      icon,
      unit = '',
      value = '',
      iconWidth = '49px',
      iconTopMargin = '0px',
      yAxisDashboard = '',
      // eslint-disable-next-line camelcase
      value_color,
    } = graphConfig;

    const titleWithValues = fillValues(title);
    const messageWithValues = fillValues(message);
    const valueWithValues = fillValues(value);

    return {
      title: `${titleWithValues} - ${valueWithValues}`,
      titleParts: [titleWithValues, valueWithValues],
      message: messageWithValues,
      graphData: insightItem.graph,
      protocolName: protocol?.name,
      icon,
      iconWidth,
      iconTopMargin,
      date: dayJS(timestamp).format(DAY_JS_DATE_FORMATS.dayMonthYearSlashes),
      unit,
      yAxisDashboard,
      valueColor: value_color,
      isGraphHidden: graphConfig.graphHidden,
    };
  }

  return null;
};

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import xor from 'lodash/xor';

import {
  Chip,
  NativeSelect,
  TextField,
  Checkbox,
  Button,
  Paper,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';

import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

import {
  getStandardAssessmentStagesInfo,
  defaultAfterTrainingAssessmentIntervalMinutes,
  eegMinutesDuration,
} from '../../models/assessments/assessments';
import {
  isAnEEGRegionStage,
  getAllQuestionnaires,
  legacyQuestionsQuestionnaireId,
} from '../../models/symptom-tracker/symptom-tracker';

import { injectIDs } from '../../utils/utils';

import { ScheduleAssessmentsCard } from '../UserPage/AssessmentsTab/ScheduleAssessmentsCard/ScheduleAssessmentsCard';

// import { WrapConditionally } from '../../Core/Components/WrapConditionally.jsx/WrapConditionally';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';

import { paragraphFontSize } from '../../cssInJs/constants';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { CheckboxesSelect } from '../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';
import { SelectQuestionnaires } from '../AddClientModal/SelectQuestionnaires/SelectQuestionnaires';

import symtpomQuestionnairesIcon from '../../../assets/symtpom_questionnaires_icon.png';
import eegSensingIcon from '../../../assets/eeg_sensing_icon.png';
import cptIcon from '../../../assets/cpt_icon.png';
import symtpomQuestionnairesDisabledIcon from '../../../assets/symtpom_questionnaires_disabled_icon.png';
import eegSensingDisabledIcon from '../../../assets/eeg_sensing_disabled_icon.png';
import cptDisabledIcon from '../../../assets/cpt_disabled_icon.png';
import signalIcon from '../../../assets/open-audio.png';

import styles from './SettingsSection.scss';

const defaultQuestionnaires = [
  '-MErOA0mosgJcptREuBL',
  '-MErOA0vWgL2iH5bXBCG',
  '-MErOA22I6ODUAp3M4bX',
  '-MErOA1CUyJPdJT0-wCa',
];

const AssessmentSettingsBase = () => {
  const t = useTranslation();

  const CPT_DURATION = 8;

  const getDefaultAssessmentScheduleState = assessmentStages => ({
    recurring: true,
    beforeNextSession: true,
    afterTrainingDuration: defaultAfterTrainingAssessmentIntervalMinutes,
    selectedStages: assessmentStages
      ? Object.values(assessmentStages).filter(id => id !== 'Back')
      : [],
    frequency: 6,
    intervalType: 'weeks',
    isAssessmentOpen: true,
    stagesType: 'stages',
  });

  const [allQuestionnaires, setAllQuestionnaires] = useState(null);
  useEffect(() => {
    (async () => {
      setAllQuestionnaires(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnaires(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
        );
      });
  }, []);

  const [isAssessmentDialogOpen, setIsAssessmentDialogOpen] = useState(false);
  const [isQuestionnairesDialogOpen, setIsQuestionnairesDialogOpen] = useState(
    false
  );

  const [isFirstOpenCancel, setIsFirstOpenCancel] = useState(false);

  const [deployedQuestionnaires, setDeployedQuestionnaires] = useState([]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [assessmentStagesInfo, setAssessmentStagesInfo] = useState(null);
  useEffect(() => {
    (async () => {
      setAssessmentStagesInfo(
        await getStandardAssessmentStagesInfo(sessionStorage.userId)
      );
    })();
  }, []);

  const [assessmentScheduleState, setAssessmentScheduleState] = useState(
    getDefaultAssessmentScheduleState(null)
  );

  const [autoDeployableConfigs, setAutoDeployableConfigs] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment`,
  });

  const [
    autoDeployableConfigsAssessment,
    setAutoDeployableConfigsAssessment,
  ] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedAssessment/content`,
  });

  const [
    autoDeployableConfigsQuestionnaire,
    setAutoDeployableConfigsQuestionnaire,
  ] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/assessment/deployables/deployedQuestionnaires/content`,
  });

  const [usedAssessmentBeforeJune24] = useFirebaseDB({
    path: `clinicConfig/assessments/usedAssessmentBeforeJune24/${sessionStorage.userId}`,
    onceListener: true,
  });

  const [
    areScheduleAssessmentDialogEEGRegionsEnabled,
    setAreScheduleAssessmentDialogEEGRegionsEnabled,
  ] = useState(true);

  useEffect(() => {
    if (assessmentStagesInfo) {
      setAssessmentScheduleState(
        getDefaultAssessmentScheduleState(assessmentStagesInfo.ids)
      );
    }
    if (autoDeployableConfigsAssessment) {
      setAssessmentScheduleState(autoDeployableConfigsAssessment);
      setAreScheduleAssessmentDialogEEGRegionsEnabled(
        (assessmentScheduleState.stagesType === 'headsetStages' &&
          !!assessmentScheduleState.selectedStages.find(
            stage => stage === 'Headset'
          )) ||
          (assessmentScheduleState.selectedStages || []).some(stage =>
            isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
          )
      );
    }
  }, [assessmentStagesInfo]);

  useEffect(() => {
    if (autoDeployableConfigsAssessment) {
      setAssessmentScheduleState(autoDeployableConfigsAssessment);
    }
    if (autoDeployableConfigsQuestionnaire) {
      setDeployedQuestionnaires(autoDeployableConfigsQuestionnaire || []);
    }
  }, [autoDeployableConfigsQuestionnaire, autoDeployableConfigsAssessment]);

  useEffect(() => {
    if (autoDeployableConfigsAssessment && isFirstLoad) {
      setIsFirstLoad(false);
      setAreScheduleAssessmentDialogEEGRegionsEnabled(
        (autoDeployableConfigsAssessment.stagesType === 'headsetStages' &&
          !!(autoDeployableConfigsAssessment.selectedStages || []).find(
            stage => stage === 'Headset'
          )) ||
          (autoDeployableConfigsAssessment.selectedStages || []).some(stage =>
            isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
          )
      );
    }
  }, [autoDeployableConfigsAssessment, isFirstLoad]);

  if (!assessmentStagesInfo) return null;

  const { selectedStages } = assessmentScheduleState;

  const isSymptomQuestionnaireChecked =
    assessmentStagesInfo &&
    selectedStages &&
    selectedStages.includes(assessmentStagesInfo.ids.QUESTIONNAIRES);
  const isCPTChecked =
    assessmentStagesInfo &&
    selectedStages &&
    selectedStages.includes(assessmentStagesInfo.ids.CPT);
  // const isEEGChecked = assessmentStagesInfo && selectedStages.some(stage =>
  //     isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
  // );

  const eegRegions = Object.values(assessmentStagesInfo.eegIds);
  const selectedEEGRegions = Object.values(selectedStages || []).filter(stage =>
    eegRegions.includes(stage)
  );
  const allEEGRegionsOptions = Object.entries(
    assessmentStagesInfo.eegNames
  ).map(([value, label]) => ({ value, label }));

  let eegMinutes = 0;
  (selectedStages || []).forEach(el => {
    if (eegMinutesDuration[el]) {
      eegMinutes += eegMinutesDuration[el] + 2;
    }
  });

  let questionsMins = 0;
  // eslint-disable-next-line no-unused-expressions
  allQuestionnaires &&
    deployedQuestionnaires.forEach(id => {
      questionsMins += allQuestionnaires[id].questions.length;
    });

  const questionnaires = Object.values(injectIDs(allQuestionnaires))
    .sort((questionnaire1, questionnaire2) =>
      questionnaire1.name.localeCompare(questionnaire2.name)
    )
    .filter(
      questionnaire =>
        questionnaire.id !== legacyQuestionsQuestionnaireId &&
        !questionnaire.upperAgeCutoff
    );

  const selectedQuestionnaires =
    allQuestionnaires &&
    deployedQuestionnaires.map(id => {
      return {
        ...allQuestionnaires[id],
        id,
      };
    });

  const onHeadsetTypeChanged = isChecked => {
    setAssessmentScheduleState({
      ...assessmentScheduleState,
      stagesType: isChecked ? 'headsetStages' : 'stages',
    });
  };

  const onRecurringAssessmentCheckboxChange = event => {
    if (!JSON.parse(event.target.value)) {
      setAssessmentScheduleState({
        ...assessmentScheduleState,
        recurring: JSON.parse(event.target.value),
        isAssessmentOpen: true,
        beforeNextSession: true,
      });
    } else {
      setAssessmentScheduleState({
        ...assessmentScheduleState,
        recurring: JSON.parse(event.target.value),
      });
    }
  };

  const onFrequencyChange = event => {
    setAssessmentScheduleState({
      ...assessmentScheduleState,
      frequency:
        event.target.value === '' ? '' : JSON.parse(event.target.value),
    });
  };

  const onIntervalTypeChange = event => {
    setAssessmentScheduleState({
      ...assessmentScheduleState,
      intervalType: event.target.value,
    });
  };

  const onBeforeNextSessionCheckboxChange = (event, checked) => {
    setAssessmentScheduleState({
      ...assessmentScheduleState,
      beforeNextSession: checked,
      isAssessmentOpen: checked,
    });
  };

  // eslint-disable-next-line no-shadow
  const setSelectedStages = selectedStages =>
    setAssessmentScheduleState(prvAssessmentScheduleState => ({
      ...prvAssessmentScheduleState,
      selectedStages,
    }));

  const onEEGRegionsCheckboxChange = event => {
    setAreScheduleAssessmentDialogEEGRegionsEnabled(event.target.checked);
    if (!event.target.checked) {
      setSelectedStages(
        (assessmentScheduleState.selectedStages || []).filter(
          stage =>
            !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds) &&
            stage !== 'Headset'
        )
      );
    } else {
      if (assessmentScheduleState.stagesType === 'headsetStages') {
        setSelectedStages([
          ...new Set([
            ...['Headset'],
            ...(assessmentScheduleState.selectedStages || []),
          ]),
        ]);
      } else {
        setSelectedStages([
          ...new Set([
            ...Object.values(assessmentStagesInfo.eegIds).filter(
              id => id !== 'Back'
            ),
            ...(assessmentScheduleState.selectedStages || []),
          ]),
        ]);
      }
    }
  };

  const onSelectedStagesChange = newSelectedStages => {
    setSelectedStages(newSelectedStages);
  };

  const calcEegWarning = () => {
    if (!areScheduleAssessmentDialogEEGRegionsEnabled) {
      return null;
    }
    if (
      usedAssessmentBeforeJune24 &&
      !selectedEEGRegions.find(region => region === 'Back')
    ) {
      return 'Due to its challenging placement, O1 is no longer included by default in the EEG sensing. Client guidance is recommended when including O1.';
    }
    if (selectedEEGRegions.find(region => region === 'Back')) {
      return 'Due to its challenging placement, O1 is no longer included by default in the EEG sensing. Client guidance is recommended when including O1.';
    }
    return null;
  };

  const renderWarningIfSomeOptionsAreDisabled = () => {
    const enabledOptions = [
      isSymptomQuestionnaireChecked,
      areScheduleAssessmentDialogEEGRegionsEnabled,
      isCPTChecked,
    ];
    const enabledOptionsFormattedLabels = [
      'questionnaires',
      'EEG sensing',
      'continuous performance task',
    ];

    let warningMessage = null;
    if (enabledOptions.some(val => !val)) {
      if (enabledOptions.every(val => !val)) {
        warningMessage = 'New clients won’t get assessments.';
      } else {
        const disabledOptionsLabels = enabledOptions
          .map((option, index) =>
            option ? '' : enabledOptionsFormattedLabels[index]
          )
          .filter(text => text !== '');
        warningMessage = `Assessments for new clients won’t include ${disabledOptionsLabels.join(
          ', '
        )}.`;
      }
    }

    return (
      warningMessage && (
        <div
          className={styles.alert_wrapper}
          style={calcEegWarning() ? { marginTop: 80 } : null}
        >
          <CustomAlert severity="warning">{warningMessage}</CustomAlert>
        </div>
      )
    );
  };

  const onSetupQuestionnaires = () => {
    setIsQuestionnairesDialogOpen(true);
  };

  const onAddQuestionnaire = questionnaire => {
    const copy = [...deployedQuestionnaires];
    copy.push(questionnaire.id);
    setDeployedQuestionnaires(copy);
  };

  const onDeleteQuestionnaire = questionnaire => {
    const copy = [...deployedQuestionnaires];
    const index = copy.indexOf(questionnaire.id);
    if (index !== -1) {
      copy.splice(index, 1);
    }
    setDeployedQuestionnaires(copy);
  };

  const onDiscardAllQuestionnaires = () => {
    setDeployedQuestionnaires([]);
  };

  const saveQuestionnaires = () => {
    const copyAssessment = { ...assessmentScheduleState };

    if (!deployedQuestionnaires.length) {
      const selctedStagesCopy = [...copyAssessment.selectedStages];
      const index = selctedStagesCopy.indexOf(
        assessmentStagesInfo.ids.QUESTIONNAIRES
      );
      if (index !== -1) {
        selctedStagesCopy.splice(index, 1);
        if (!selctedStagesCopy.length) {
          setAutoDeployableConfigs(null);
          const copy = { ...assessmentScheduleState };
          copy.selectedStages = [];
          setAssessmentScheduleState(copy);
          setIsQuestionnairesDialogOpen(false);
          return;
        }
      }
      copyAssessment.selectedStages = selctedStagesCopy || [];
    } else {
      const selctedStagesCopy = [...copyAssessment.selectedStages];
      const index = selctedStagesCopy.indexOf(
        assessmentStagesInfo.ids.QUESTIONNAIRES
      );
      if (index === -1) {
        selctedStagesCopy.push(assessmentStagesInfo.ids.QUESTIONNAIRES);
      }
      copyAssessment.selectedStages = selctedStagesCopy || [];
    }
    setAutoDeployableConfigsAssessment(copyAssessment);
    setAutoDeployableConfigsQuestionnaire(deployedQuestionnaires);
    setIsQuestionnairesDialogOpen(false);
  };

  const saveAssessment = () => {
    setAutoDeployableConfigsAssessment(assessmentScheduleState);
    setIsAssessmentDialogOpen(false);
  };

  const calcEegCheckboxRenderedValue = () => {
    if (assessmentScheduleState.stagesType === 'headsetStages') {
      return 'Headband-Only (Rapid)';
    }
    if (selectedEEGRegions.length === allEEGRegionsOptions.length) {
      return 'All regions selected';
    }
    if (!selectedEEGRegions.length) {
      return 'Recommended regions selected';
    }
    if (
      selectedEEGRegions.length === allEEGRegionsOptions.length - 1 &&
      !selectedEEGRegions.find(region => region === 'Back')
    ) {
      return 'Recommended regions selected';
    }
    return `${selectedEEGRegions.length} ${
      selectedEEGRegions.length === 1 ? 'region' : 'regions'
    }`;
  };

  // const isScheduleAssessmentDialogPrimaryBtnDisabled = (() => {
  //   return (assessmentScheduleState.selectedStages || []).length === 0;
  // })();

  const renderAssessmentCard = () => {
    if (
      !autoDeployableConfigsAssessment ||
      !assessmentStagesInfo ||
      !(autoDeployableConfigs && autoDeployableConfigs.enabled)
    ) {
      return null;
    }

    const assessmentData = autoDeployableConfigsAssessment;

    const calcAssessmentFrequency = () => {
      if (!assessmentData.recurring) {
        return 'on next app access';
      }
      return `${
        assessmentData.beforeNextSession
          ? 'immediately before the next app access, and'
          : ''
      } every ${assessmentData.frequency} ${
        assessmentData.frequency === 1
          ? assessmentData.intervalType.substring(
              0,
              assessmentData.intervalType.length - 1
            )
          : assessmentData.intervalType
      }`;
    };

    return (
      <Paper style={{ padding: 20, marginBottom: 20, maxWidth: 730 }}>
        <div className={styles.assessment_card_top}>
          {assessmentData.selectedStages ? (
            <div className={styles.assessment_card_chips}>
              {(assessmentData.selectedStages || []).includes(
                assessmentStagesInfo.ids.QUESTIONNAIRES
              ) ? (
                <div className={styles.chip_container}>
                  <Chip
                    classes={{ label: styles.chip_label }}
                    label="Symptom questionnaires"
                  />
                </div>
              ) : null}
              {(assessmentData.selectedStages || []).filter(
                stage =>
                  stage !== assessmentStagesInfo.ids.QUESTIONNAIRES &&
                  stage !== assessmentStagesInfo.ids.CPT
              ).length ? (
                <div className={styles.chip_container}>
                  <Chip
                    classes={{ label: styles.chip_label }}
                    label={
                      assessmentData.stagesType === 'headsetStages'
                        ? 'Headband Only'
                        : 'EEG sensing'
                    }
                  />
                </div>
              ) : null}
              {(assessmentData.selectedStages || []).includes(
                assessmentStagesInfo.ids.CPT
              ) ? (
                <div className={styles.chip_container}>
                  <Chip
                    classes={{ label: styles.chip_label }}
                    label="Continuous performance task"
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <CustomAlert severity="warning">
                New clients won’t get assessments
              </CustomAlert>
            </div>
          )}
          <EditIcon
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsAssessmentDialogOpen(true);
            }}
          />
        </div>
        {assessmentData.selectedStages ? (
          <div style={{ marginTop: 10 }}>
            The assessment is scheduled to occur{' '}
            {
              <span style={{ fontWeight: 900 }}>
                {calcAssessmentFrequency()}
              </span>
            }
          </div>
        ) : null}
      </Paper>
    );
  };

  const warningQuestionnaire = (selectedQuestionnaires || []).filter(
    questionnaire => questionnaire.alternativeQuestionnaire
  );
  const renderWarningQuestionnaire = () => {
    if (warningQuestionnaire.length === 1) {
      return `Clients who are 16 years of age or younger will get ${
        allQuestionnaires[warningQuestionnaire[0].alternativeQuestionnaire]
          .fullName
      }.`;
    }
    let listStr = 'Clients who are 16 years of age or younger will get ';
    warningQuestionnaire.forEach(questionnaire => {
      listStr += `${
        allQuestionnaires[questionnaire.alternativeQuestionnaire].fullName
      }, `;
    });
    listStr = listStr.substring(0, listStr.length - 2);
    return `${listStr}.`;
  };

  return (
    <div>
      <div className={styles.switch_setting_unit}>
        <div>
          <p>
            Deploy In-App Assessment by Default
            {/* <span className={styles.card_new_label}>NEW</span> */}
          </p>

          <div className="toggle-switch" data-ts-color="blue">
            <input
              id="assessment_switch"
              type="checkbox"
              hidden="hidden"
              onChange={() => {
                if (autoDeployableConfigs) {
                  const copy = JSON.parse(
                    JSON.stringify(autoDeployableConfigs)
                  );
                  copy.enabled = !copy.enabled;
                  setAutoDeployableConfigs(copy);
                } else {
                  setIsFirstOpenCancel(true);
                  setAutoDeployableConfigs({
                    selfRegister: true,
                    manualRegister: false,
                    enabled: true,
                    deployables: {
                      deployedAssessment: {
                        // eslint-disable-next-line no-template-curly-in-string
                        path: 'assessments/deployedAssessments/${patientId}',
                        content: assessmentScheduleState,
                      },
                      deployedQuestionnaires: {
                        path:
                          // eslint-disable-next-line no-template-curly-in-string
                          'symptomTracker/deployedQuestionnaires/${patientId}',
                        content: defaultQuestionnaires,
                      },
                    },
                  });
                  setDeployedQuestionnaires(defaultQuestionnaires);
                  setIsAssessmentDialogOpen(true);
                }
              }}
              checked={
                !!(autoDeployableConfigs && autoDeployableConfigs.enabled)
              }
              // disabled={disabled}
            />

            {/* eslint-disable */}
            <label
              id="assessment_switch"
              htmlFor="assessment_switch"
              className="ts-helper"
              style={{ marginBottom: 0 }}
            />
            {/* eslint-enable */}
          </div>
        </div>
        <p style={{ whiteSpace: 'pre-wrap' }}>
          When enabled, newly added and self-registered clients will be prompted
          to complete an assessment of your choice by default.
        </p>
      </div>
      {renderAssessmentCard()}
      <Dialog
        open={isAssessmentDialogOpen}
        onClose={() => {
          setIsAssessmentDialogOpen(false);
        }}
        maxWidth="lg"
      >
        <DialogContent>
          <React.Fragment>
            <h3 className={styles.dialog_header}>
              {t('when_to_schedule_assessment')}
            </h3>
            <div className={styles.switches_container}>
              <div>
                <div className={styles.recurring_container}>
                  <NativeSelect
                    classes={{
                      icon: styles.mu_native_select_icon,
                      root: styles.mu_select_menu,
                    }}
                    IconComponent={ArrowDropDownIcon}
                    value={assessmentScheduleState.recurring}
                    onChange={onRecurringAssessmentCheckboxChange}
                  >
                    <option value="false">Once</option>
                    <option value="true">Recurring</option>
                  </NativeSelect>
                </div>
                {assessmentScheduleState.recurring && (
                  <div className={styles.input_container}>
                    <p>
                      <span>every</span>
                    </p>
                    <TextField
                      id="standard-basic"
                      margin="normal"
                      onChange={onFrequencyChange}
                      value={assessmentScheduleState.frequency}
                      inputProps={{
                        min: 1,
                        type: 'number',
                        style: {
                          fontSize: paragraphFontSize,
                          width: 60,
                        },
                      }}
                    />

                    <NativeSelect
                      classes={{
                        icon: styles.mu_native_select_icon,
                        root: styles.mu_select_menu,
                      }}
                      IconComponent={ArrowDropDownIcon}
                      value={assessmentScheduleState.intervalType || ''}
                      onChange={onIntervalTypeChange}
                    >
                      <option
                        value=""
                        disabled
                        selected={!assessmentScheduleState.intervalType}
                      >
                        Choose from below
                      </option>
                      <option value="days">days</option>
                      <option value="weeks">weeks</option>
                      <option value="months">months</option>
                      <option value="minutes">minutes of neurofeedback</option>
                      <option value="sessions">
                        sessions of neurofeedback
                      </option>
                    </NativeSelect>
                  </div>
                )}
                {(!assessmentScheduleState.recurring ||
                  !(
                    assessmentScheduleState.intervalType === 'minutes' ||
                    assessmentScheduleState.intervalType === 'sessions'
                  )) && ( // if day/week/month and not done assasement
                  <p className={styles.after_select_text}>
                    starting on next app access
                  </p>
                )}
              </div>
              {assessmentScheduleState.recurring &&
                (assessmentScheduleState.intervalType === 'minutes' ||
                  assessmentScheduleState.intervalType === 'sessions') && (
                  <div>
                    <Checkbox
                      checked={assessmentScheduleState.beforeNextSession}
                      onChange={onBeforeNextSessionCheckboxChange}
                      color="primary"
                      style={{ paddingLeft: 0, left: '-3px' }}
                    />
                    <div className={styles.input_container}>
                      <p>
                        <span>Starting on next app access</span>
                      </p>
                    </div>
                  </div>
                )}
              <div className={styles.select_stages_container}>
                <h3 className={styles.dialog_header}>
                  {t('what-to-include-assessment')}
                </h3>
                <div className={styles.assessments_cards_container}>
                  {/* <WrapConditionally
                    condition={!deployedQuestionnaires.length}
                    wrap={children => (
                      <TooltipWrapper
                        title={
                          <TooltipTitle>
                            You need to setup questionnaires to enable symptom
                            tracking.
                          </TooltipTitle>
                        }
                      >
                        <div>{children}</div>
                      </TooltipWrapper>
                    )}
                  > */}
                  <ScheduleAssessmentsCard
                    title="Symptom questionnaries"
                    cardText={() => (
                      <div>
                        Standardized questionnaires are brief, multiple choice
                        scales for various conditions that have been validated
                        in the scientific literature.
                        <a
                          target="_blank"
                          href="https://intercom.help/dashboard-myndlift/en/articles/4488496-symptom-questionnaires-for-tracking-progress"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          See our complete list of questionnaires here.
                        </a>
                      </div>
                    )}
                    imgSrc={symtpomQuestionnairesIcon}
                    checked={isSymptomQuestionnaireChecked}
                    onChange={() => {
                      if (!deployedQuestionnaires.length) {
                        onSetupQuestionnaires();
                      } else {
                        onSelectedStagesChange(
                          xor(assessmentScheduleState.selectedStages || [], [
                            assessmentStagesInfo.ids.QUESTIONNAIRES,
                          ])
                        );
                      }
                    }}
                    buttomLeft={() =>
                      !deployedQuestionnaires.length ? null : (
                        <div>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={onSetupQuestionnaires}
                            className={styles.setup_questionnaire_btn}
                          >
                            <span
                              className={styles.setup_questionnaire_btn_text}
                            >
                              Edit
                            </span>
                          </Button>
                        </div>
                      )
                    }
                    mins={Math.ceil((questionsMins * 4) / 60)}
                    // disabled={!deployedQuestionnaires.length}
                    disabledImgSrc={symtpomQuestionnairesDisabledIcon}
                    customerId="clinic"
                    smallCard
                  />
                  {/* </WrapConditionally> */}
                  <ScheduleAssessmentsCard
                    title="Continuous performance task"
                    cardText={() => (
                      <div>
                        An 8-minute continuous performance task, during which
                        the user is instructed to tap only when the target
                        object is shown. It is used to measure sustained
                        attention and inhibition.
                        <a
                          target="_blank"
                          href="https://intercom.help/dashboard-myndlift/en/articles/3951667-myndlift-assessments"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          Learn more here.
                        </a>
                      </div>
                    )}
                    imgSrc={cptIcon}
                    checked={isCPTChecked}
                    onChange={() => {
                      onSelectedStagesChange(
                        xor(assessmentScheduleState.selectedStages || [], [
                          assessmentStagesInfo.ids.CPT,
                        ])
                      );
                    }}
                    mins={CPT_DURATION}
                    disabledImgSrc={cptDisabledIcon}
                    customerId="clinic"
                    smallCard
                    imgLabel={
                      <TooltipWrapper
                        title={
                          <TooltipTitle>
                            This task includes sounds of explosions, aircraft,
                            and car honking, which may be triggering for some
                            individuals.
                          </TooltipTitle>
                        }
                      >
                        <div>
                          <img
                            src={signalIcon}
                            style={{ width: 15, height: 10, marginRight: 5 }}
                          />
                          Trigger warning
                        </div>
                      </TooltipWrapper>
                    }
                  />

                  <ScheduleAssessmentsCard
                    title="EEG sensing"
                    cardText={() => (
                      <div>
                        A meaningful report will be generated based on the users
                        EEG data. Unless the "Headband-only" option is selected,
                        the user will be instructed to place the electrode on
                        multiple sites.
                        <a
                          target="_blank"
                          href="https://intercom.help/dashboard-myndlift/en/articles/3951667-myndlift-assessments"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          Learn more here.
                        </a>
                      </div>
                    )}
                    imgSrc={eegSensingIcon}
                    checked={areScheduleAssessmentDialogEEGRegionsEnabled}
                    onChange={onEEGRegionsCheckboxChange}
                    buttomLeft={() => (
                      <div
                        className={styles.select_regions_container}
                        style={{ marginBottom: '8px' }}
                      >
                        <CheckboxesSelect
                          selectedOptions={selectedEEGRegions}
                          allOptions={allEEGRegionsOptions}
                          onChange={event => {
                            if (event.target.value.length > 0) {
                              onSelectedStagesChange([
                                ...(selectedStages || []).filter(
                                  stage =>
                                    !isAnEEGRegionStage(
                                      stage,
                                      assessmentStagesInfo.eegIds
                                    )
                                ),
                                ...event.target.value,
                              ]);
                            }
                          }}
                          fullWidth
                          inputType="input"
                          renderValue={calcEegCheckboxRenderedValue}
                          disabled={
                            !areScheduleAssessmentDialogEEGRegionsEnabled
                          }
                          classes={{
                            selectRoot: styles.regions_select_input,
                          }}
                          displayEmpty="true"
                          selectTitle="Headband + Electrode"
                          extraToggleText={
                            <div>
                              Headband-Only (Rapid)
                              <span
                                style={{
                                  fontSize: '0.75em',
                                  color: '#ffffff',
                                  marginLeft: 5,
                                  backgroundColor: '#4ce0d0',
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  borderRadius: 15,
                                }}
                              >
                                BETA
                              </span>
                            </div>
                          }
                          onExtraToggleChange={e => {
                            onHeadsetTypeChanged(e.target.checked);
                            if (e.target.checked) {
                              setSelectedStages(
                                (assessmentScheduleState.selectedStages || [])
                                  .filter(
                                    stage =>
                                      !isAnEEGRegionStage(
                                        stage,
                                        assessmentStagesInfo.eegIds
                                      )
                                  )
                                  .concat(['Headset'])
                              );
                            } else {
                              setSelectedStages([
                                ...new Set([
                                  ...Object.values(
                                    assessmentStagesInfo.eegIds
                                  ).filter(id => id !== 'Back'),
                                  ...(
                                    assessmentScheduleState.selectedStages || []
                                  ).filter(stage => stage !== 'Headset'),
                                ]),
                              ]);
                            }
                          }}
                          extraToggleValue={
                            assessmentScheduleState.stagesType ===
                            'headsetStages'
                          }
                          isCheckboxesDisabled={
                            assessmentScheduleState.stagesType ===
                            'headsetStages'
                          }
                        />
                      </div>
                    )}
                    mins={
                      assessmentScheduleState.stagesType === 'headsetStages'
                        ? 5
                        : Math.ceil(eegMinutes) || 14
                    }
                    disabledImgSrc={eegSensingDisabledIcon}
                    customerId="clinic"
                    imgLabel="Hardware required"
                    isLast="true"
                    smallCard
                    warning={calcEegWarning()}
                  />
                </div>
                <div />
                {renderWarningIfSomeOptionsAreDisabled()}
                <div style={{ marginTop: 20 }}>
                  Note: You won’t incur any extra charges when using
                  assessments.
                </div>
              </div>
            </div>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (autoDeployableConfigs) {
                setAssessmentScheduleState(autoDeployableConfigsAssessment);
              }
              setIsAssessmentDialogOpen(false);
              setAreScheduleAssessmentDialogEEGRegionsEnabled(
                (autoDeployableConfigsAssessment.stagesType ===
                  'headsetStages' &&
                  !!autoDeployableConfigsAssessment.selectedStages.find(
                    stage => stage === 'Headset'
                  )) ||
                  (
                    autoDeployableConfigsAssessment.selectedStages || []
                  ).some(stage =>
                    isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
                  )
              );
              if (isFirstOpenCancel) {
                const copy = JSON.parse(JSON.stringify(autoDeployableConfigs));
                copy.enabled = false;
                setAutoDeployableConfigs(copy);
                setIsFirstOpenCancel(false);
              }
            }}
            variant="text"
            color="default"
          >
            <span className={styles.btn}>Cancel</span>
          </Button>
          <Button
            onClick={saveAssessment}
            color="primary"
            variant="contained"
            // disabled={isScheduleAssessmentDialogPrimaryBtnDisabled}
          >
            <span className={styles.btn}>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isQuestionnairesDialogOpen}
        onClose={() => {
          setIsQuestionnairesDialogOpen(false);
        }}
      >
        <div style={{ padding: 10 }}>
          <SelectQuestionnaires
            selectedQuestionnaires={selectedQuestionnaires}
            onAddQuestionnaire={onAddQuestionnaire}
            onDeleteQuestionnaire={onDeleteQuestionnaire}
            onDiscardAllQuestionnaires={onDiscardAllQuestionnaires}
            questionnaires={questionnaires}
            shouldShowDescription={false}
          />
        </div>
        {warningQuestionnaire.length ? (
          <div className={styles.alert_question}>
            <CustomAlert severity="warning">
              {renderWarningQuestionnaire()}
            </CustomAlert>
          </div>
        ) : null}
        <DialogActions>
          <Button
            onClick={() => {
              setIsQuestionnairesDialogOpen(false);
              setDeployedQuestionnaires(
                autoDeployableConfigsQuestionnaire || []
              );
            }}
            variant="text"
            color="default"
          >
            <span className={styles.btn}>Cancel</span>
          </Button>
          <Button
            onClick={saveQuestionnaires}
            color="primary"
            variant="contained"
          >
            <span className={styles.btn}>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AssessmentSettingsBase.propTypes = exact({});

export const AssessmentSettings = React.memo(AssessmentSettingsBase);
AssessmentSettings.displayName = 'AssessmentSettings';

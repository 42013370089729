exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AddNoteDialog_size_17{font-size:17px}.AddNoteDialog_size_10{font-size:10px}.AddNoteDialog_size_11{font-size:11px}.AddNoteDialog_size_13{font-size:13px}.AddNoteDialog_size_15{font-size:15px}.AddNoteDialog_size_14{font-size:14px}.AddNoteDialog_size_16{font-size:16px}.AddNoteDialog_size_12{font-size:12px}.AddNoteDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.AddNoteDialog_header,.AddNoteDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.AddNoteDialog_header_only_font_size{font-size:17px;font-weight:500}.AddNoteDialog_sub_header{font-size:13px;color:#878787}.AddNoteDialog_sub_header_black{font-size:13px;color:black}.AddNoteDialog_sub_header_only_font_size{font-size:13px}.AddNoteDialog_paragraph_only_font_size{font-size:12px}.AddNoteDialog_tab_font_size{font-size:13px}.AddNoteDialog_pop_out_header{font-size:20px}.AddNoteDialog_title{font-size:15px;font-weight:500;color:#484848}.AddNoteDialog_title_black{font-size:15px;font-weight:500;color:black}.AddNoteDialog_title_only_font_size{font-size:15px}.AddNoteDialog_paragraph,.AddNoteDialog_dialog_text{font-size:12px;color:#484848}.AddNoteDialog_btn,.AddNoteDialog_button{font-size:14px}.AddNoteDialog_editor_container{margin-left:10px;margin-right:10px;border-radius:10px;border:1px #a2a2a2 solid;padding:5px}.AddNoteDialog_editorClassName{font-size:14px !important;color:black !important;order:1 !important;min-height:200px !important;padding-left:20px !important;padding-right:20px !important}.AddNoteDialog_wrapperClassName{display:flex !important;flex-direction:column !important;justify-content:flex-end !important}.AddNoteDialog_toolbarClassName{order:2 !important}.AddNoteDialog_popupInDraft{top:-240px !important;height:235px !important}\n", ""]);

// Exports
exports.locals = {
	"size_17": "AddNoteDialog_size_17",
	"size_10": "AddNoteDialog_size_10",
	"size_11": "AddNoteDialog_size_11",
	"size_13": "AddNoteDialog_size_13",
	"size_15": "AddNoteDialog_size_15",
	"size_14": "AddNoteDialog_size_14",
	"size_16": "AddNoteDialog_size_16",
	"size_12": "AddNoteDialog_size_12",
	"extra_header": "AddNoteDialog_extra_header",
	"header": "AddNoteDialog_header",
	"dialog_header": "AddNoteDialog_dialog_header",
	"header_only_font_size": "AddNoteDialog_header_only_font_size",
	"sub_header": "AddNoteDialog_sub_header",
	"sub_header_black": "AddNoteDialog_sub_header_black",
	"sub_header_only_font_size": "AddNoteDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "AddNoteDialog_paragraph_only_font_size",
	"tab_font_size": "AddNoteDialog_tab_font_size",
	"pop_out_header": "AddNoteDialog_pop_out_header",
	"title": "AddNoteDialog_title",
	"title_black": "AddNoteDialog_title_black",
	"title_only_font_size": "AddNoteDialog_title_only_font_size",
	"paragraph": "AddNoteDialog_paragraph",
	"dialog_text": "AddNoteDialog_dialog_text",
	"btn": "AddNoteDialog_btn",
	"button": "AddNoteDialog_button",
	"editor_container": "AddNoteDialog_editor_container",
	"editorClassName": "AddNoteDialog_editorClassName",
	"wrapperClassName": "AddNoteDialog_wrapperClassName",
	"toolbarClassName": "AddNoteDialog_toolbarClassName",
	"popupInDraft": "AddNoteDialog_popupInDraft"
};
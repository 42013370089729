import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';

const DisorderChipBase = ({ label, ...props }) => {
  return (
    <Chip
      label={label}
      style={{
        fontSize: 'inherit',
        background: 'rgb(86, 109, 163)',
        color: 'white',
      }}
      {...props}
    />
  );
};

DisorderChipBase.propTypes = {
  label: PropTypes.node.isRequired,
};

export const DisorderChip = React.memo(DisorderChipBase);
DisorderChip.displayName = 'DisorderChip';

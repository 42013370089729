import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import styles from './ConfirmGenerateReportDialog.scss';

const ConfirmGenerateReportDialogBase = ({
  isOpen,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
}) => {
  return (
    <CustomDialog {...{ isOpen, onPrimaryBtnClick, onSecondaryBtnClick }}>
      <p className={styles.dialog_header}>Before you proceed</p>
      <p className={styles.dialog_paragraph}>
        Please note that once you generate the report, your client will be able
        to access it directly from the app, whether or not you decide to share
        it with them.
      </p>
    </CustomDialog>
  );
};

ConfirmGenerateReportDialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
});

export const ConfirmGenerateReportDialog = React.memo(
  ConfirmGenerateReportDialogBase
);
ConfirmGenerateReportDialog.displayName = 'ConfirmGenerateReportDialog';

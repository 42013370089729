exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoPreview_lago_preview_container{border:1px solid black;width:300px;height:450px;padding:10px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.LagoPreview_spinner_container{display:flex;width:100%;justify-content:center;align-items:center;margin-top:20px}.LagoPreview_html_btn_preview{border:1px black solid;display:flex;justify-content:center}.LagoPreview_html_preview_container{display:flex;flex-direction:column;justify-content:space-between;height:100%}.LagoPreview_quiz_preview_container{display:flex;flex-direction:column;justify-content:space-between;height:100%}.LagoPreview_quiz_choises_container{margin-top:10px}.LagoPreview_quiz_btn_preview{border:1px black solid;padding-left:5px}.LagoPreview_editor_preview_container{overflow:hidden;white-space:normal}\n", ""]);

// Exports
exports.locals = {
	"lago_preview_container": "LagoPreview_lago_preview_container",
	"spinner_container": "LagoPreview_spinner_container",
	"html_btn_preview": "LagoPreview_html_btn_preview",
	"html_preview_container": "LagoPreview_html_preview_container",
	"quiz_preview_container": "LagoPreview_quiz_preview_container",
	"quiz_choises_container": "LagoPreview_quiz_choises_container",
	"quiz_btn_preview": "LagoPreview_quiz_btn_preview",
	"editor_preview_container": "LagoPreview_editor_preview_container"
};
import React from 'react';
import { SessionComparisonGraph } from './SessionComparisonGraph';

export const SecondsSessionComparisonGraph = props => {
  const colors = ['#F469CE', '#918AEA'];
  const unitTemplate = ' (Seconds)';

  return (
    <SessionComparisonGraph
      colors={colors}
      unitTemplate={unitTemplate}
      {...props}
    />
  );
};

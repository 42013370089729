import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from '../../../../Core/hooks/useTranslation';
import styles from './ConfirmDeletingDoneAssessment.scss';
import { Loader } from '../../../../Core/Components/Loader/Loader';

export const confirmDeletingDoneAssessmentDialogRenderedSteps = {
  promptForDeletingAssessment: 'promptForDeletingAssessment',
  loading: 'loading',
  assessmentDeleted: 'assessmentDeleted',
};

const ConfirmDeletingDoneAssessmentBase = ({
  onConfirm,
  onClose,
  onCancel,
  isBaselineAssessment,
  renderedStep,
}) => {
  const t = useTranslation();
  return (
    <Dialog
      open={!!confirmDeletingDoneAssessmentDialogRenderedSteps[renderedStep]}
      onClose={onClose}
    >
      <DialogContent className={styles.content}>
        {renderedStep ===
          confirmDeletingDoneAssessmentDialogRenderedSteps.promptForDeletingAssessment && (
          <React.Fragment>
            <h3 className={styles.dialog_header}>{t('sure')}</h3>
            <p>{t('delete_assessment_prompt_text')}</p>
            {isBaselineAssessment && (
              <p>
                After removal the baseline assessment will switch to be the next
                assessment after this one and all existing assessments will be
                modified to correspond to the new baseline.
              </p>
            )}
          </React.Fragment>
        )}

        {renderedStep ===
          confirmDeletingDoneAssessmentDialogRenderedSteps.loading && (
          <Loader padding="15px 100px" />
        )}

        {renderedStep ===
          confirmDeletingDoneAssessmentDialogRenderedSteps.assessmentDeleted && (
          <h3 className={styles.dialog_header}>
            {t('assessment_was_deleted')}
          </h3>
        )}
      </DialogContent>
      {renderedStep !==
        confirmDeletingDoneAssessmentDialogRenderedSteps.loading && (
        <DialogActions>
          {renderedStep ===
            confirmDeletingDoneAssessmentDialogRenderedSteps.promptForDeletingAssessment && (
            <Button onClick={onCancel} variant="text" color="default">
              <span className={styles.btn}>{t('cancel')}</span>
            </Button>
          )}
          <Button onClick={onConfirm} color="primary" variant="contained">
            <span className={styles.btn}>
              {renderedStep ===
              confirmDeletingDoneAssessmentDialogRenderedSteps.promptForDeletingAssessment
                ? t('remove')
                : t('ok')}
            </span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmDeletingDoneAssessmentBase.propTypes = exact({
  isBaselineAssessment: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  renderedStep: PropTypes.string,
});

export const ConfirmDeletingDoneAssessment = React.memo(
  ConfirmDeletingDoneAssessmentBase
);
ConfirmDeletingDoneAssessment.displayName = 'ConfirmDeletingDoneAssessment';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import styles from './ChangePlanDialog.scss';
import upgradeImageSrc from '../../../../assets/upgrade-icon.png';
import upgradedSuccessfullyImageSrc from '../../../../assets/upgrade-successful-icon.png';
import failedCircleImageSrc from '../../../../assets/failed-circle.png';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { CreditCardDetailsDialogWithValidation } from '../../CreditCardDetailsDialog/CreditCardDetailsDialogWithValidation';

export const CHANGE_PLAN_DIALOG_RENDERED_STEPS = {
  CONFIRM_CHANGE: Symbol('Confirm Change'),
  LOADING: Symbol('Loading'),
  CHANGED_SUCCESSFULLY: Symbol('Changed Successfully'),
  CHANGED_FAILED: Symbol('Change Failed'),
};

export const CHANGE_TYPES = {
  UPGRADE: Symbol('Upgrade'),
  DOWNGRADE: Symbol('Downgrade'),
  SWITCH: Symbol('Switch'),
};

const ChangePlanDialogBase = ({
  onConfirm,
  onClose,
  onUseDifferentCard,
  changeType,
  planMonthlyPrice,
  creditCardLashFourDigits,
  renderedStep,
  planType,
  planName,
  headsetsIncluded,
  isOpen,
  nextBillingCycle,
  lostFeatures,
  isNonfPlan,
  addedFeatures,
  isCurrentPlan,
  creditOrChargeExtra,
  chargeDate,
  isCurrentPlanDurmant,
  showNextPlan,
  isLoadingUpgradeWithCard,
  balance,
}) => {
  const t = useTranslation();

  const getPrimaryBtnText = () => {
    if (isLoadingUpgradeWithCard) {
      return (
        <CircularProgress style={{ color: 'white', width: 20, height: 20 }} />
      );
    }
    if (
      renderedStep === CHANGE_PLAN_DIALOG_RENDERED_STEPS.CONFIRM_CHANGE &&
      isCurrentPlanDurmant &&
      changeType === CHANGE_TYPES.SWITCH
    ) {
      return 'upgrade';
    }
    if (renderedStep === CHANGE_PLAN_DIALOG_RENDERED_STEPS.CONFIRM_CHANGE) {
      return changeType && changeType.description;
    }

    return t('done');
  };

  const primaryBtnText = getPrimaryBtnText();

  const renderCreditOrCharge = () => {
    if (!creditOrChargeExtra) {
      return null;
    }

    if (changeType === CHANGE_TYPES.UPGRADE || isCurrentPlanDurmant) {
      return (
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          You will now get charged a one-time payment of ${creditOrChargeExtra}{' '}
          to cover the remainder of your current billing cycle.
        </p>
      );
    }
    return (
      <p style={{ fontSize: 14, fontWeight: 500 }}>
        You’ll get ${creditOrChargeExtra * -1} in credit for the unused days in
        the current billing cycle.
      </p>
    );
  };

  const renderDialogContent = () => {
    switch (renderedStep) {
      case CHANGE_PLAN_DIALOG_RENDERED_STEPS.CONFIRM_CHANGE:
        if (isNonfPlan) {
          return (
            <div className={styles.content_container}>
              {isCurrentPlan ? (
                <p
                  className={styles.title}
                  style={{ fontSize: 18, color: 'black' }}
                >
                  {isCurrentPlanDurmant ? 'Upgrade to' : 'Switch to'} {planName}
                </p>
              ) : (
                <p
                  className={styles.title}
                  style={{ fontSize: 18, color: 'black' }}
                >
                  {changeType === CHANGE_TYPES.UPGRADE
                    ? 'Upgrade'
                    : 'Downgrade'}{' '}
                  to {planName}
                </p>
              )}
              {!isCurrentPlan &&
                changeType === CHANGE_TYPES.DOWNGRADE &&
                lostFeatures.length > 0 && (
                  <div className={styles.different_features}>
                    <p style={{ fontSize: 14 }}>
                      You will{' '}
                      <span style={{ fontWeight: 500 }}>lose access</span> to
                      these features:
                    </p>
                    <div>
                      {lostFeatures.map(lostFeature => (
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CloseIcon style={{ marginRight: 10 }} />{' '}
                          {lostFeature}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              {!isCurrentPlan &&
                changeType === CHANGE_TYPES.UPGRADE &&
                addedFeatures.length > 0 && (
                  <div className={styles.different_features}>
                    <p style={{ fontSize: 14 }}>
                      You will{' '}
                      <span style={{ fontWeight: 500 }}>gain access</span> to
                      these features:
                    </p>
                    <div>
                      {addedFeatures.map(addedFeature => (
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CheckIcon style={{ marginRight: 10 }} />{' '}
                          {addedFeature}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              {isCurrentPlan &&
                isCurrentPlanDurmant &&
                changeType === CHANGE_TYPES.SWITCH &&
                addedFeatures.length > 0 && (
                  <div className={styles.different_features}>
                    <p style={{ fontSize: 14 }}>
                      You will{' '}
                      <span style={{ fontWeight: 500 }}>gain access</span> to
                      these features:
                    </p>
                    <div>
                      {addedFeatures.map(addedFeature => (
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CheckIcon style={{ marginRight: 10 }} />{' '}
                          {addedFeature}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              {renderCreditOrCharge()}
              {planType === 'annually' || showNextPlan ? (
                <p style={{ fontSize: 14, fontWeight: 500 }}>
                  You’ll be charged ${Math.round(planMonthlyPrice * 12)} at the
                  start of your next billing cycle (
                  {chargeDate || nextBillingCycle})
                </p>
              ) : (
                <p style={{ fontSize: 14, fontWeight: 500 }}>
                  You’ll be charged ${planMonthlyPrice}/month from your next
                  billing cycle ({chargeDate || nextBillingCycle})
                </p>
              )}
              {creditCardLashFourDigits ? (
                <div className={styles.card_number_container}>
                  <p className={styles.small_margin_bottom}>
                    We will use credit card ending with{' '}
                    {creditCardLashFourDigits} to process the payment
                  </p>
                  <div>
                    <a role="button" onClick={onUseDifferentCard} tabIndex={0}>
                      + Use different credit card
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  {balance < 0 && (
                    <div
                      className={styles.extra_charge_text}
                      style={{ color: 'red', marginBottom: 10 }}
                    >
                      - You owe a balance of ${balance * -1} , which will be
                      deducted now.
                    </div>
                  )}
                  <CreditCardDetailsDialogWithValidation
                    renderedStep="cardDetailsInput"
                    shouldRenderAsComponentNotDialog
                    // ref={creditCardDetailsDialogRef}
                  />
                </>
              )}
            </div>
          );
        }
        return (
          <div className={styles.content_container}>
            {changeType === CHANGE_TYPES.UPGRADE && (
              <img src={upgradeImageSrc} />
            )}

            <p className={styles.title}>
              {changeType === CHANGE_TYPES.UPGRADE ? 'Upgrade' : 'Downgrade'}{' '}
              your plan to {planName === 'Annual Plan' ? 'an' : ''} {planName}
            </p>

            {planType === 'annually' ? (
              <p>
                You’ll be charged ${Math.round(planMonthlyPrice * 12)} at the
                start of your next billing cycle (
                {chargeDate || nextBillingCycle})
              </p>
            ) : (
              <p>
                You’ll be charged ${planMonthlyPrice}/month from your next
                billing cycle ({chargeDate || nextBillingCycle})
              </p>
            )}

            {changeType === CHANGE_TYPES.DOWNGRADE && lostFeatures.length > 0 && (
              <div className={styles.different_features}>
                <p style={{ fontWeight: 'bold' }}>
                  You will lose the access to the following features:
                </p>
                <div>
                  {lostFeatures.map(lostFeature => (
                    <p>- {lostFeature}</p>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.card_number_container}>
              <p className={styles.small_margin_bottom}>
                We will use credit card ending with {creditCardLashFourDigits}{' '}
                to process the payment
              </p>
              <div>
                <a role="button" onClick={onUseDifferentCard} tabIndex={0}>
                  + Use different credit card
                </a>
              </div>
            </div>
          </div>
        );

      case CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY:
        if (isNonfPlan) {
          return (
            <div className={styles.content_container}>
              {changeType === CHANGE_TYPES.UPGRADE && (
                <img src={upgradedSuccessfullyImageSrc} />
              )}

              <p className={styles.title}>
                Plan{' '}
                {// eslint-disable-next-line no-nested-ternary
                changeType === CHANGE_TYPES.SWITCH
                  ? 'switched'
                  : changeType === CHANGE_TYPES.UPGRADE
                  ? 'upgraded'
                  : 'downgraded'}{' '}
                successfully!
              </p>
              {changeType === CHANGE_TYPES.UPGRADE ||
              changeType === CHANGE_TYPES.SWITCH ||
              !creditOrChargeExtra ? null : (
                <p style={{ fontSize: 14, fontWeight: 500 }}>
                  You’ll get ${creditOrChargeExtra * -1} in credit for the
                  unused days in the current billing cycle.
                </p>
              )}
              {planType === 'annually' ? (
                <p style={{ fontSize: 14, fontWeight: 500 }}>
                  You’ll be charged ${Math.round(planMonthlyPrice * 12)} at the
                  start of your next billing cycle (
                  {chargeDate || nextBillingCycle})
                </p>
              ) : (
                <p style={{ fontSize: 14, fontWeight: 500 }}>
                  You’ll be charged ${planMonthlyPrice}/month from your next
                  billing cycle ({chargeDate || nextBillingCycle})
                </p>
              )}
            </div>
          );
        }
        return (
          <div className={styles.content_container}>
            {changeType === CHANGE_TYPES.UPGRADE && (
              <img src={upgradedSuccessfullyImageSrc} />
            )}

            <p className={styles.title}>
              Plan{' '}
              {changeType === CHANGE_TYPES.UPGRADE ? 'upgraded' : 'downgraded'}{' '}
              successfully!
            </p>

            {planType === 'annually' ? (
              <p className={styles.small_margin_bottom}>
                You will have {headsetsIncluded} included{' '}
                {headsetsIncluded === 1 ? 'headset' : 'headsets'} and will be
                charged ${planMonthlyPrice * 12} at the start of your next
                billing cycle
              </p>
            ) : (
              <p className={styles.small_margin_bottom}>
                You will have {headsetsIncluded} included{' '}
                {headsetsIncluded === 1 ? 'headset' : 'headsets'} and will be
                charged ${planMonthlyPrice} a month starting from your next
                billing cycle
              </p>
            )}
          </div>
        );

      case CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED:
        return (
          <div
            className={classNames(
              styles.info_container,
              styles.credit_card_was_not_updated
            )}
          >
            <Avatar
              alt="fail"
              src={failedCircleImageSrc}
              className={styles.avatar}
            />

            <p className={styles.info_text}>Payment failed</p>
            <p>We were unable to process the payment.</p>
          </div>
        );
      case CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING:
      default:
        return <Loader />;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        {renderedStep !==
          CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY &&
          renderedStep !== CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING &&
          !isLoadingUpgradeWithCard && (
            <div className={styles.close_icon_container}>
              <CloseIcon onClick={onClose} />
            </div>
          )}
        {renderDialogContent()}
      </DialogContent>
      {renderedStep !== CHANGE_PLAN_DIALOG_RENDERED_STEPS.LOADING && (
        <DialogActions>
          {renderedStep ===
            CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_SUCCESSFULLY ||
          CHANGE_PLAN_DIALOG_RENDERED_STEPS.CHANGED_FAILED ? null : (
            <Button onClick={onClose}>
              <span className={styles.button}>cancel</span>
            </Button>
          )}
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
            disabled={isLoadingUpgradeWithCard}
          >
            <span className={styles.button}>{primaryBtnText}</span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ChangePlanDialogBase.propTypes = exact({
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUseDifferentCard: PropTypes.func.isRequired,
  changeType: PropTypes.oneOf([
    CHANGE_TYPES.UPGRADE,
    CHANGE_TYPES.DOWNGRADE,
    CHANGE_TYPES.SWITCH,
  ]),
  planMonthlyPrice: PropTypes.number,
  creditCardLashFourDigits: PropTypes.string,
  renderedStep: PropTypes.symbol,
  planType: PropTypes.string,
  planName: PropTypes.node,
  headsetsIncluded: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  nextBillingCycle: PropTypes.string,
  lostFeatures: PropTypes.array,
  isNonfPlan: PropTypes.bool,
  addedFeatures: PropTypes.array,
  isCurrentPlan: PropTypes.bool,
  creditOrChargeExtra: PropTypes.number,
  chargeDate: PropTypes.string,
  isCurrentPlanDurmant: PropTypes.bool,
  showNextPlan: PropTypes.bool,
  isLoadingUpgradeWithCard: PropTypes.bool,
  balance: PropTypes.number,
});

export const ChangePlanDialog = React.memo(ChangePlanDialogBase);
ChangePlanDialog.displayName = 'ChangePlanDialog';

import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import { HeadTopView } from '../../../Core/images/HeadTopView';
import styles from './ProtocolsUsedInTraining.scss';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { getDerivedFrequencyImageSrc } from '../../../models/frequencies/frequencies';

const stylesFn = () => ({
  protocolDescriptionPaper: {
    height: 'fit-content',
  },
});

const trainingChannelStyle = {
  fill: '#279bfd',
  stroke: '#279bfd',
  fillOpacity: 0.4,
};

const hiddenChannelStyle = {
  fillOpacity: 0,
  strokeOpacity: 0,
};

export const ProtocolsUsedInTrainingBase = ({
  protocols,
  classes,
  frequenciesConfig,
}) => {
  return frequenciesConfig ? (
    <div className={styles.protocols_used_in_training_root}>
      {protocols.map(protocol => {
        const sessionText =
          protocol.trainedSessionsNum === 1 ? 'session' : 'sessions';
        const minutesText =
          protocol.trainedDurationInMinutes === 1 ? 'minute' : 'minutes';

        return (
          <div className={styles.protocol_root} key={protocol.name}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expandIcon: styles.expand_icon }}
                className="expandable-in-report"
              >
                <div className={styles.protocol_overview}>
                  <p>{protocol.name}</p>
                  <p>
                    {`${
                      protocol.trainedSessionsNum
                    } ${sessionText}, ${protocol.trainedDurationInMinutes.toFixed(
                      0
                    )} ${minutesText}`}
                  </p>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={styles.expansion_panel_details}>
                <div className={styles.protocol_description_container}>
                  <Paper classes={{ root: classes.protocolDescriptionPaper }}>
                    <div className={styles.training_channel_container}>
                      <p>{protocol.trainingChannel} Training Location</p>

                      <div>
                        <HeadTopView
                          // hide Fpz as it can't be a training channel
                          channelStyles={{
                            [`channel_${protocol.trainingChannel}`]: trainingChannelStyle,
                            channel_text_Fpz: hiddenChannelStyle,
                            channel_Fpz: hiddenChannelStyle,
                          }}
                          rootStyle={styles.root_style_head_top_down_view}
                        />
                      </div>
                    </div>
                  </Paper>

                  <div className={styles.vertical_divider} />

                  <div className={styles.frequencies_cards_container}>
                    {protocol.frequencies.map((frequency, index) => {
                      const deletedFrequencySymbol = Symbol(
                        'deleted-frequency'
                      );
                      const frequencyName =
                        extractFrequency(
                          frequency.rangeStart,
                          frequency.rangeEnd,
                          frequenciesConfig
                        ) || deletedFrequencySymbol;

                      if (frequencyName === deletedFrequencySymbol) {
                        Sentry.captureException(
                          new Error(
                            `deleted frequency: ${frequency.rangeStart} - ${frequency.rangeEnd}`
                          )
                        );
                      }

                      return (
                        <Paper
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${
                            frequencyName === deletedFrequencySymbol
                              ? frequencyName.description
                              : frequencyName
                          }-${index}`}
                        >
                          <div className={styles.frequency_card}>
                            <p className={styles.frequency_name_container}>
                              <span>
                                {`${capitalizeFirstLetter(
                                  frequency.type.replace('reward', 'enhance')
                                )}: ${
                                  frequencyName === deletedFrequencySymbol
                                    ? 'Deleted Frequency'
                                    : frequencyName
                                }`}
                              </span>
                            </p>

                            <div className={styles.frequency_image_container}>
                              <div>
                                {frequency.type === 'inhibit' ? (
                                  <ArrowDownwardIcon
                                    style={{ color: '#ed8484' }}
                                  />
                                ) : (
                                  <ArrowUpwardIcon
                                    style={{ color: '#3dbdb0' }}
                                  />
                                )}
                              </div>
                              <div>
                                <img
                                  src={getDerivedFrequencyImageSrc({
                                    minFrequency: frequency.rangeStart,
                                    maxFrequency: frequency.rangeEnd,
                                  })}
                                  alt={
                                    frequencyName.description || frequencyName
                                  }
                                />
                              </div>
                            </div>

                            <p className={styles.frequency_description}>
                              {frequencyName !== deletedFrequencySymbol &&
                                frequenciesConfig[frequencyName].description}
                            </p>
                          </div>
                        </Paper>
                      );
                    })}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      })}
    </div>
  ) : null;
};

ProtocolsUsedInTrainingBase.propTypes = {
  protocols: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  frequenciesConfig: PropTypes.object.isRequired,
};

export const ProtocolsUsedInTraining = withStyles(stylesFn)(
  ProtocolsUsedInTrainingBase
);

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ConfirmationDialog_size_17{font-size:17px}.ConfirmationDialog_size_10{font-size:10px}.ConfirmationDialog_size_11{font-size:11px}.ConfirmationDialog_size_13{font-size:13px}.ConfirmationDialog_size_15{font-size:15px}.ConfirmationDialog_size_14{font-size:14px}.ConfirmationDialog_size_16{font-size:16px}.ConfirmationDialog_size_12{font-size:12px}.ConfirmationDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.ConfirmationDialog_header,.ConfirmationDialog_dialog_header{font-size:17px;font-weight:500;color:#000000}.ConfirmationDialog_header_only_font_size{font-size:17px;font-weight:500}.ConfirmationDialog_sub_header{font-size:13px;color:#878787}.ConfirmationDialog_sub_header_black{font-size:13px;color:black}.ConfirmationDialog_sub_header_only_font_size{font-size:13px}.ConfirmationDialog_paragraph_only_font_size{font-size:12px}.ConfirmationDialog_tab_font_size{font-size:13px}.ConfirmationDialog_pop_out_header{font-size:20px}.ConfirmationDialog_title{font-size:15px;font-weight:500;color:#484848}.ConfirmationDialog_title_black{font-size:15px;font-weight:500;color:black}.ConfirmationDialog_title_only_font_size{font-size:15px}.ConfirmationDialog_paragraph,.ConfirmationDialog_dialog_text{font-size:12px;color:#484848}.ConfirmationDialog_btn,.ConfirmationDialog_button{font-size:14px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ConfirmationDialog_size_17",
	"size_10": "ConfirmationDialog_size_10",
	"size_11": "ConfirmationDialog_size_11",
	"size_13": "ConfirmationDialog_size_13",
	"size_15": "ConfirmationDialog_size_15",
	"size_14": "ConfirmationDialog_size_14",
	"size_16": "ConfirmationDialog_size_16",
	"size_12": "ConfirmationDialog_size_12",
	"extra_header": "ConfirmationDialog_extra_header",
	"header": "ConfirmationDialog_header",
	"dialog_header": "ConfirmationDialog_dialog_header",
	"header_only_font_size": "ConfirmationDialog_header_only_font_size",
	"sub_header": "ConfirmationDialog_sub_header",
	"sub_header_black": "ConfirmationDialog_sub_header_black",
	"sub_header_only_font_size": "ConfirmationDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "ConfirmationDialog_paragraph_only_font_size",
	"tab_font_size": "ConfirmationDialog_tab_font_size",
	"pop_out_header": "ConfirmationDialog_pop_out_header",
	"title": "ConfirmationDialog_title",
	"title_black": "ConfirmationDialog_title_black",
	"title_only_font_size": "ConfirmationDialog_title_only_font_size",
	"paragraph": "ConfirmationDialog_paragraph",
	"dialog_text": "ConfirmationDialog_dialog_text",
	"btn": "ConfirmationDialog_btn",
	"button": "ConfirmationDialog_button"
};
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */

const resetPassword = async () => {
  // eslint-disable-next-line no-undef
  showPageLoader();
  // eslint-disable-next-line no-undef
  const response = await new Patient().generatePassword();
  // eslint-disable-next-line no-undef
  hideLoading();
  if (response.result) {
    // eslint-disable-next-line no-undef
    swal(`New temporary password is: ${response.password}`);
  } else {
    // eslint-disable-next-line no-undef
    swal('Error, please try again');
  }
};

export const onResetPassword = () => {
  // eslint-disable-next-line no-undef
  swal(
    {
      title: 'Are you sure you want to reset this customer password?',
      text: 'You will not be able to log in with the previous password.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#009688',
      confirmButtonText: 'I am sure, proceed.',
      closeOnConfirm: false,
    },
    resetPassword
  );
};

export const promptForScheduleDeleteCustomer = ({
  fullName,
  path,
  actionTimestamp,
}) => {
  const scheduleDeletionDaysRef = database.ref('config/scheduleDeletionDays');

  let scheduleDeletionDays;
  scheduleDeletionDaysRef.on(
    'value',
    snapshot => {
      const days = snapshot.val();
      scheduleDeletionDays = days;
    },
    () => {
      // in case of error
      // console.error('Error reading scheduleDeletionDays:', error);
      scheduleDeletionDays = 30;
    }
  );
  // eslint-disable-next-line no-undef
  swal(
    {
      title: 'Are you sure?',
      text: `You are about to schedule the deletion of user ${fullName}. Their data will be permanently deleted in ${scheduleDeletionDays} ${
        scheduleDeletionDays > 1 ? ' days' : ' day'
      }.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete!',
      closeOnConfirm: false,
    },
    () => {
      scheduleDeleteUser(fullName, path, actionTimestamp);
    }
  );
};

export const promptForDeleteCustomer = ({ fullName }) => {
  // eslint-disable-next-line no-undef
  swal(
    {
      title: 'Are you sure?',
      text: `You are about to delete user ${fullName}, if you press "Yes", all of the data will be deleted and cannot be recovered. Are you sure?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete!',
      closeOnConfirm: false,
    },
    () => {
      // eslint-disable-next-line no-undef
      detachOnData(`trainingSessions/${sessionStorage.customerId}`);
      // eslint-disable-next-line no-undef
      deleteUser();
    }
  );
};

const extractFrequencyNameFromFreqObject = freq =>
  freq.name ? freq.name.toLowerCase() : '';

const getSortedAllFrequncyNames = records => {
  let allFreqs = [];
  for (const record of records) {
    allFreqs = allFreqs.concat(record.frequencies);
  }
  allFreqs.sort((x, y) => x.rangeStart - y.rangeStart);
  const names = new Set(allFreqs.map(extractFrequencyNameFromFreqObject));
  return names;
};

const getCSVTitlesMap = records => {
  const sameKey = title => title;
  const addPercentage = title => `${title} (%)`;
  const addSeconds = title => `${title} (seconds)`;

  const map = {
    session_number: sameKey,
    round_number: sameKey,
    timestamp: sameKey,
    duration: addSeconds,
    protocol: sameKey,
    training_channel: sameKey,
    reference_channel: sameKey,
    game_type: sameKey,
    score: sameKey,
    alerts: sameKey,
    deep_zone_percentage: addPercentage,
    zone_percentage: addPercentage,
    median_streak: () => addSeconds('average_streak'),
    max_streak: addSeconds,
    noisePercentage: () => addPercentage('noise_percentage'),
    totalSuccessRate: () => addPercentage('total_success_rate'),
    processing_version: sameKey,
  };

  const freqNames = getSortedAllFrequncyNames(records);
  for (const freqName of freqNames) {
    for (const freqKey of [
      'inhibit/enhance',
      'avg_amplitude',
      'standard_deviation_amplitude',
      'avg_threshold',
    ]) {
      map[`${freqName}_${freqKey}`] = title => title;
    }
  }

  return map;
};

// eslint-disable-next-line consistent-return
const getRepeatedKeyIfExists = key => {
  if (key.includes('avg_amplitude')) return 'avg_amplitudes';
  if (key.includes('standard_deviation_amplitude')) {
    return 'standard_deviation_amplitudes';
  }

  if (key.includes('avg_threshold')) return 'avg_thresholds';
  if (key.includes('inhibit/enhance')) return 'inhibit/enhance';
};

const getRounded = val => {
  if (val === undefined || val === '') {
    return '';
  }
  // eslint-disable-next-line no-restricted-globals
  const isNumber = !isNaN(val);
  if (typeof val === 'object') {
    return Object.values(val)[0];
  }
  if (!isNumber) {
    return val.replace(/,/g, '').replace('IMAGE_DIM', 'YOUTUBE');
  }
  return Number.parseFloat(val).toFixed(2);
};

const loadProcessedRecordRows = async () => {
  const rows = [];
  // eslint-disable-next-line no-undef
  const records = await new CustomerRoundsAnalysis().waitAndGetForAllRoundsToBeProcessedWithTheLatestVersion(
    sessionStorage.customerId
  );

  const keysMap = getCSVTitlesMap(records);
  const keys = Object.keys(keysMap);
  const titles = keys.map(key => keysMap[key](key));

  rows.push(titles); // title

  for (const record of records.reverse()) {
    const rowValue = [];
    for (const key of keys) {
      const val = record[key];

      const repeated = getRepeatedKeyIfExists(key);
      if (repeated) {
        const splittedKey = key.split('_');

        const [freqName] = splittedKey;
        const freqIdx = record.frequencies.findIndex(
          freq => extractFrequencyNameFromFreqObject(freq) == freqName
        );
        const frequencyUsedInRecord = freqIdx != -1;
        let colValue;
        if (repeated == 'inhibit/enhance' && frequencyUsedInRecord) {
          colValue = record.frequencies[freqIdx].type.replace(
            'reward',
            'enhance'
          );
        } else {
          colValue = frequencyUsedInRecord ? record[repeated][freqIdx] : '';
        }
        rowValue.push(getRounded(colValue));
      } else if (key == 'timestamp') {
        rowValue.push(getRounded(new Date(val * 1000).toLocaleString()));
      } else if (key == 'round_number') {
        rowValue.push(val + 1);
      } else {
        rowValue.push(getRounded(val));
      }
    }
    rows.push(rowValue);
  }

  return rows;
};

export const onDownloadDataAsCSV = async () => {
  // eslint-disable-next-line no-undef
  showPageLoader();
  const rows = await loadProcessedRecordRows();
  // eslint-disable-next-line no-undef
  hideLoading();
  const name = `${
    JSON.parse(sessionStorage.customerInfo).email
    // eslint-disable-next-line no-undef
  }_${getTodaysDate()}`;

  convertToCSVAndDownload(rows, name);
};

export const userPageTabs = {
  sessions: 'sessions',
  overview: 'overview',
  assessments: 'assessments',
  insights: 'insights',
  behaviouralProgress: 'behavioural-progress',
  entries: 'entries',
};

const cancelFailedError = () => {
  // eslint-disable-next-line no-undef
  swal(
    {
      title: $.i18n('cancel-failed'),
      text: $.i18n('failed-cancel-program'),
      type: 'error',
      confirmButtonColor: '#2196f3',
      confirmButtonText: 'OK',
      closeOnConfirm: true,
    },
    () => {}
  );
};

const legacyBackupHomeSession = async () => {
  // eslint-disable-next-line no-undef
  return fireFunctionPost('backupHomeSession').catch(cancelFailedError);
};

const programStopped = () => {
  // eslint-disable-next-line no-undef
  swal(
    {
      title: '',
      text: $.i18n('home-program-canceled'),
      type: 'success',
      confirmButtonColor: '#2196f3',
      confirmButtonText: 'OK',
      closeOnConfirm: true,
    },
    () => {}
  );
};

export const cancelSession = userId => {
  new MyEvent('deployed_program_cancel_click').log();

  // eslint-disable-next-line no-undef
  swal(
    {
      title: '',
      text: $.i18n('are-you-sure-cancel-home-session'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: $.i18n('yes-cancel'),
      cancelButtonText: $.i18n('never-mind'),
      closeOnConfirm: true,
    },
    // eslint-disable-next-line func-names
    async () => {
      // eslint-disable-next-line no-undef
      showPageLoader();
      await Promise.all([
        new MyEvent('deployed_cancel_confirm').log(),
        // eslint-disable-next-line no-undef
        legacyBackupHomeSession(userId),
      ]);

      // eslint-disable-next-line no-undef
      hideLoading();
      // eslint-disable-next-line no-undef
      programStopped();
    }
  );
};

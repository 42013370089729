exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".PlacementRecommendationStep_mu_form_control_root{max-width:420px;width:100%}.PlacementRecommendationStep_mu_select_menu{font-size:13px;line-height:1.1875em}.PlacementRecommendationStep_mu_native_select_icon{transform:rotate(45deg);height:30px;width:30px}.PlacementRecommendationStep_clickable_paragraph{color:#2196f3}.PlacementRecommendationStep_root{display:flex}.PlacementRecommendationStep_images_container{width:100%;text-align:center;margin-left:15px}.PlacementRecommendationStep_root_style_head_top_down_view{max-width:300px;min-width:230px}.PlacementRecommendationStep_eyes_state_image{margin-bottom:15px;max-width:80px}\n", ""]);

// Exports
exports.locals = {
	"mu_form_control_root": "PlacementRecommendationStep_mu_form_control_root",
	"mu_select_menu": "PlacementRecommendationStep_mu_select_menu",
	"mu_native_select_icon": "PlacementRecommendationStep_mu_native_select_icon",
	"clickable_paragraph": "PlacementRecommendationStep_clickable_paragraph",
	"root": "PlacementRecommendationStep_root",
	"images_container": "PlacementRecommendationStep_images_container",
	"root_style_head_top_down_view": "PlacementRecommendationStep_root_style_head_top_down_view",
	"eyes_state_image": "PlacementRecommendationStep_eyes_state_image"
};
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import styles from './UserCard.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { capitalizeFirstLetter } from '../../../utils/utils';
import museOnMaleHeadImageSrc from '../../../../assets/muse-on-male-head.png';
import museOnFemaleHeadImageSrc from '../../../../assets/muse-on-female-head.png';

const stylesFn = () => ({
  avatar: {
    height: 84,
    marginRight: 24,
  },
});

const UserCardBase = React.memo(
  ({
    name,
    age,
    email,
    gender,
    totalNumberOfSessions,
    totalDurationInMinutes,
    classes,
  }) => {
    const t = useTranslation();
    return (
      <Paper>
        <div className={styles.card}>
          <div className={styles.avatar_container}>
            <img
              alt={name}
              className={classes.avatar}
              src={
                gender === 'male'
                  ? museOnMaleHeadImageSrc
                  : museOnFemaleHeadImageSrc
              }
            />
            <div className={styles.name_container}>
              <p className={styles.name}>{name}</p>
              <p className={styles.age}>
                {`${age} ${t('years-old').toLowerCase()}`}
              </p>
            </div>
          </div>
          <div className={styles.email_info_container}>
            <div className={styles.email_container}>
              <p>{email}</p>
            </div>
          </div>
          <div className={styles.number_of_sessions_container}>
            <div>
              <p className={styles.number}>{totalNumberOfSessions}</p>
              <p className={styles.number_label}>{t('sessions')}</p>
            </div>
            <div className={styles.divider} />
            <div>
              <p className={styles.number}>{totalDurationInMinutes}</p>
              <p className={styles.number_label}>
                {capitalizeFirstLetter(t('minutes'))}
              </p>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
);

UserCardBase.defaultProps = {
  gender: 'male',
};

UserCardBase.propTypes = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  totalNumberOfSessions: PropTypes.number.isRequired,
  totalDurationInMinutes: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  gender: PropTypes.oneOf(['male', 'female']),
};

export const UserCard = withStyles(stylesFn)(UserCardBase);

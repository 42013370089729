import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import styles from './EmptyNotes.scss';

const EmptyNotesBase = ({ onAddNoteClicked }) => {
  return (
    <Paper style={{ marginTop: 10, marginBottom: 10 }}>
      <div
        className={styles.root}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className={styles.content}>
          <div style={{ fontSize: 16, color: 'black', fontWeight: 500 }}>
            Notes
          </div>
        </div>
        <div className={styles.button_container}>
          <Button
            variant="contained"
            onClick={onAddNoteClicked}
            color="primary"
          >
            <span className={styles.btn} data-test-id="generate-report-btn">
              Add a note
            </span>
          </Button>
        </div>
      </div>
    </Paper>
  );
};

EmptyNotesBase.propTypes = {
  onAddNoteClicked: PropTypes.func.isRequired,
};

export const EmptyNotes = EmptyNotesBase;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MTRCallsLog_size_17{font-size:17px}.MTRCallsLog_size_10{font-size:10px}.MTRCallsLog_size_11{font-size:11px}.MTRCallsLog_size_13{font-size:13px}.MTRCallsLog_size_15{font-size:15px}.MTRCallsLog_size_14{font-size:14px}.MTRCallsLog_size_16{font-size:16px}.MTRCallsLog_size_12{font-size:12px}.MTRCallsLog_extra_header{font-size:18px;font-weight:500;color:#000000}.MTRCallsLog_header{font-size:17px;font-weight:500;color:#000000}.MTRCallsLog_header_only_font_size{font-size:17px;font-weight:500}.MTRCallsLog_sub_header{font-size:13px;color:#878787}.MTRCallsLog_sub_header_black{font-size:13px;color:black}.MTRCallsLog_sub_header_only_font_size{font-size:13px}.MTRCallsLog_paragraph_only_font_size{font-size:12px}.MTRCallsLog_tab_font_size{font-size:13px}.MTRCallsLog_pop_out_header{font-size:20px}.MTRCallsLog_title{font-size:15px;font-weight:500;color:#484848}.MTRCallsLog_title_black{font-size:15px;font-weight:500;color:black}.MTRCallsLog_title_only_font_size{font-size:15px}.MTRCallsLog_paragraph,.MTRCallsLog_cell{font-size:12px;color:#484848}.MTRCallsLog_btn{font-size:14px}.MTRCallsLog_two_aligned_columns_layout{display:flex;flex-wrap:wrap}.MTRCallsLog_two_aligned_columns_layout>div:nth-child(2){margin-left:auto}.MTRCallsLog_centered_content{display:flex;justify-content:center;align-items:center}.MTRCallsLog_common_strong_font{font-weight:500}.MTRCallsLog_common_wrap_row{display:flex;flex-wrap:wrap}.MTRCallsLog_flex_col_centered{display:flex;flex-direction:column;justify-content:center;align-items:center}.MTRCallsLog_page{padding-left:75px;padding-right:75px}@media only screen and (max-width: 3600px){.MTRCallsLog_page{width:80%;margin:auto;padding:0px 0px}}@media only screen and (max-width: 1600px){.MTRCallsLog_page{width:100%;margin:auto;padding-left:75px;padding-right:75px}}@media only screen and (max-width: 768px){.MTRCallsLog_page{width:100%;margin:auto;padding-left:15px;padding-right:15px}}.MTRCallsLog_paper{padding:20px 30px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "MTRCallsLog_size_17",
	"size_10": "MTRCallsLog_size_10",
	"size_11": "MTRCallsLog_size_11",
	"size_13": "MTRCallsLog_size_13",
	"size_15": "MTRCallsLog_size_15",
	"size_14": "MTRCallsLog_size_14",
	"size_16": "MTRCallsLog_size_16",
	"size_12": "MTRCallsLog_size_12",
	"extra_header": "MTRCallsLog_extra_header",
	"header": "MTRCallsLog_header",
	"header_only_font_size": "MTRCallsLog_header_only_font_size",
	"sub_header": "MTRCallsLog_sub_header",
	"sub_header_black": "MTRCallsLog_sub_header_black",
	"sub_header_only_font_size": "MTRCallsLog_sub_header_only_font_size",
	"paragraph_only_font_size": "MTRCallsLog_paragraph_only_font_size",
	"tab_font_size": "MTRCallsLog_tab_font_size",
	"pop_out_header": "MTRCallsLog_pop_out_header",
	"title": "MTRCallsLog_title",
	"title_black": "MTRCallsLog_title_black",
	"title_only_font_size": "MTRCallsLog_title_only_font_size",
	"paragraph": "MTRCallsLog_paragraph",
	"cell": "MTRCallsLog_cell",
	"btn": "MTRCallsLog_btn",
	"two_aligned_columns_layout": "MTRCallsLog_two_aligned_columns_layout",
	"centered_content": "MTRCallsLog_centered_content",
	"common_strong_font": "MTRCallsLog_common_strong_font",
	"common_wrap_row": "MTRCallsLog_common_wrap_row",
	"flex_col_centered": "MTRCallsLog_flex_col_centered",
	"page": "MTRCallsLog_page",
	"paper": "MTRCallsLog_paper"
};
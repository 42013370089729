exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ModalHeader_modal_header{background-color:#efefef;padding:15px 26px;display:flex;position:relative}.ModalHeader_modal_header_left_side{display:flex;flex-direction:column;justify-content:center}.ModalHeader_modal_header_right_side{margin-left:auto;margin-right:15px;display:flex}.ModalHeader_close_icon_container{position:absolute;top:10px;right:10px;cursor:pointer}.ModalHeader_modal_header_container{overflow:hidden;border-top-left-radius:3px;border-top-right-radius:3px;min-height:-moz-fit-content;min-height:fit-content;flex-shrink:0;will-change:transform}\n", ""]);

// Exports
exports.locals = {
	"modal_header": "ModalHeader_modal_header",
	"modal_header_left_side": "ModalHeader_modal_header_left_side",
	"modal_header_right_side": "ModalHeader_modal_header_right_side",
	"close_icon_container": "ModalHeader_close_icon_container",
	"modal_header_container": "ModalHeader_modal_header_container"
};
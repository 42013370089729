import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EdiText from 'react-editext';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import styles from './AddableContent.scss';
import { subHeaderFontSize } from '../../../cssInJs/constants';
import { defensiveThrow } from '../../../utils/utils';

const stylesFn = () => ({
  button: {
    margin: 0,
    color: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    fontSize: '14px',
  },
  chip: {
    backgroundColor: '#e8f0fe',
    fontSize: subHeaderFontSize,
    fontWeight: 500,
    lineHeight: 19,
    textAlign: 'center',
    color: '#1967d2',
    padding: '9px 25px',
    maxWidth: '100%',
    overflow: 'hidden',
  },
});

const validateHeaderAndSubHeader = value => value.trim() !== '';

const EditTextWrapper = props => (
  <EdiText
    saveButtonContent={<CheckIcon style={{ color: '#2196f3' }} />}
    editButtonContent={<EditIcon style={{ fontSize: 17, color: '#2196f3' }} />}
    cancelButtonContent={<CloseIcon style={{ color: '#2196f3' }} />}
    saveButtonClassName={styles.edit_text_button}
    editButtonClassName={styles.edit_text_button}
    cancelButtonClassName={styles.edit_text_button}
    editOnViewClick
    hideIcons
    submitOnEnter
    submitOnUnfocus
    cancelOnEscape
    startEditingOnFocus
    tabIndex={0}
    {...props}
  />
);

EditTextWrapper.propTypes = {
  editTextRef: PropTypes.object,
};
class AddableContentBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    defensiveThrow({ error });
  }

  render() {
    const {
      header,
      subHeader,
      content,
      shouldShowAddToReport,
      isAddToReportDisabled,
      onAddToReportClick,
      filterSavedValues,
      t,
      classes,
      areTextsEditable,
      onHeaderSave,
      onSubHeaderSave,
      reportCardDataId,
      headerErrorMsg,
      headerRightContent,
      isExpandable,
      headerIcon,
      headerIconTopMargin,
      headerIconWidth,
    } = this.props;

    const { hasError } = this.state;

    const headerContent = areTextsEditable ? (
      <EditTextWrapper
        type="text"
        value={header}
        onSave={value => onHeaderSave({ reportCardDataId, value })}
        viewContainerClassName={styles.edit_text_container_header}
        validation={validateHeaderAndSubHeader}
        validationMessage={
          <div className={styles.edit_text_validation_msg}>
            {headerErrorMsg}
          </div>
        }
      />
    ) : (
      <h3 className={styles.addable_content_header}>{header}</h3>
    );

    const subHeaderContent = areTextsEditable ? (
      <EditTextWrapper
        type="textarea"
        value={subHeader}
        onSave={value => onSubHeaderSave({ reportCardDataId, value })}
        viewContainerClassName={styles.edit_text_container_sub_header}
        validation={validateHeaderAndSubHeader}
        validationMessage={
          <div className={styles.edit_text_validation_msg}>
            Graph description can not be empty
          </div>
        }
        rows={2}
      />
    ) : (
      <p className={styles.addable_content_sub_header}>{subHeader}</p>
    );

    const renderMainInfo = () => (
      <div className={styles.header_container}>
        {headerIcon && (
          <div
            className={styles.header_icon}
            style={{ marginTop: areTextsEditable ? 0 : 11 }}
          >
            <img
              src={headerIcon}
              style={{
                width: headerIconWidth,
                marginTop: headerIconTopMargin,
              }}
            />
          </div>
        )}
        <div className={styles.main_info}>
          {header && headerContent}
          {subHeader && subHeaderContent}
        </div>
        {headerRightContent && (
          <div className={styles.right_content}>{headerRightContent}</div>
        )}
      </div>
    );

    const renderFilters = () =>
      Object.keys(filterSavedValues).length > 0 && (
        <div className={styles.filters_container}>
          {Object.entries(filterSavedValues).map(
            ([filterName, filterStringValue]) => (
              <Chip
                label={filterStringValue}
                key={filterName}
                className={classes.chip}
              />
            )
          )}
        </div>
      );

    const renderContent = () => <div className={styles.content}>{content}</div>;

    const renderAddToReportBtn = () => (
      <div className={styles.content_container_with_add_button}>
        {shouldShowAddToReport && (
          <div className={styles.button}>
            <Button
              variant="contained"
              onClick={onAddToReportClick}
              color="primary"
              className={classes.button}
              disabled={isAddToReportDisabled}
            >
              {t('add_to_report')}
            </Button>
          </div>
        )}
      </div>
    );

    const renderAddableContent = () =>
      isExpandable ? (
        <div>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{ expanded: styles.expansion_panel_expanded }}
            >
              {renderMainInfo()}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                {renderFilters()}
                {renderContent()}
                {renderAddToReportBtn()}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      ) : (
        <Paper>
          <div className={styles.root}>
            {renderMainInfo()}

            {renderFilters()}

            {renderContent()}
          </div>

          {renderAddToReportBtn()}
        </Paper>
      );

    return hasError ? (
      <Paper>
        <div className={styles.root}>
          <div className={styles.main_info}>
            {header && headerContent}
            {subHeader && subHeaderContent}
          </div>
          <div>
            <p>
              Something went wrong while rendering this graph. Error was
              reported to Myndlift and will be handled.
            </p>
          </div>
        </div>
      </Paper>
    ) : (
      renderAddableContent()
    );
  }
}

AddableContentBase.defaultProps = {
  shouldShowAddToReport: true,
  isAddToReportDisabled: false,
  filterSavedValues: {},
  areTextsEditable: false,
  headerErrorMsg: 'Graph title can not be empty',
  headerRightContent: null,
  headerIcon: null,
  isExpandable: false,
  headerIconWidth: '49px',
  headerIconTopMargin: '0px',
};

AddableContentBase.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  content: PropTypes.node.isRequired,
  shouldShowAddToReport: PropTypes.bool,
  isAddToReportDisabled: PropTypes.bool,
  onAddToReportClick: PropTypes.func,
  filterSavedValues: PropTypes.objectOf(PropTypes.string.isRequired),
  areTextsEditable: PropTypes.bool,
  reportCardDataId: PropTypes.string,
  onHeaderSave: PropTypes.func,
  onSubHeaderSave: PropTypes.func,
  headerErrorMsg: PropTypes.string,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  headerRightContent: PropTypes.node,
  isExpandable: PropTypes.bool,
  headerIcon: PropTypes.node,
  headerIconWidth: PropTypes.string,
  headerIconTopMargin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export const AddableContent = withTranslation(
  withStyles(stylesFn)(AddableContentBase)
);

exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ScheduleAssessmentsCard_card_container{display:flex;flex-direction:column;justify-content:start;align-items:flex-start !important;box-shadow:0 0 6px 0 rgba(0,0,0,0.16);margin-right:15px;margin-top:10px;border-radius:10px;transition:height 0.25s}.ScheduleAssessmentsCard_small_card_container{width:300px;height:235px}.ScheduleAssessmentsCard_large_card_container{width:235px;height:205px}.ScheduleAssessmentsCard_expanded{height:360px}.ScheduleAssessmentsCard_last{margin-right:0}.ScheduleAssessmentsCard_card_data_container{padding:10px;width:100%;display:flex;flex-direction:column;justify-content:space-between;height:100%}.ScheduleAssessmentsCard_card_img_large{width:235px}.ScheduleAssessmentsCard_card_img_small{width:300px}.ScheduleAssessmentsCard_card_title_container{width:100%;display:flex;justify-content:space-between;cursor:pointer}.ScheduleAssessmentsCard_card_title{font-weight:bold}.ScheduleAssessmentsCard_card_text_container{margin-top:10px}.ScheduleAssessmentsCard_card_footer_container{display:flex;flex-direction:row-reverse;justify-content:space-between;align-items:flex-end}.ScheduleAssessmentsCard_card_switch{align-self:end}.ScheduleAssessmentsCard_opacity_zero{opacity:0;height:0}.ScheduleAssessmentsCard_opacity_one{opacity:1;height:auto}.ScheduleAssessmentsCard_card_text{transition:all 1s;overflow:hidden}.ScheduleAssessmentsCard_image_label{width:120px;position:absolute;left:197px;background-color:rgba(0,0,0,0.5);padding:5px;color:white;font-size:11px;border-radius:4px;font-weight:500}.ScheduleAssessmentsCard_card_warning{background-color:#fdf2cc;color:#caa23b;border-radius:0 0 5px 5px;box-shadow:0 3px 3px 3px rgba(0,0,0,0.16);padding:5px;font-size:13px}\n", ""]);

// Exports
exports.locals = {
	"card_container": "ScheduleAssessmentsCard_card_container",
	"small_card_container": "ScheduleAssessmentsCard_small_card_container",
	"large_card_container": "ScheduleAssessmentsCard_large_card_container",
	"expanded": "ScheduleAssessmentsCard_expanded",
	"last": "ScheduleAssessmentsCard_last",
	"card_data_container": "ScheduleAssessmentsCard_card_data_container",
	"card_img_large": "ScheduleAssessmentsCard_card_img_large",
	"card_img_small": "ScheduleAssessmentsCard_card_img_small",
	"card_title_container": "ScheduleAssessmentsCard_card_title_container",
	"card_title": "ScheduleAssessmentsCard_card_title",
	"card_text_container": "ScheduleAssessmentsCard_card_text_container",
	"card_footer_container": "ScheduleAssessmentsCard_card_footer_container",
	"card_switch": "ScheduleAssessmentsCard_card_switch",
	"opacity_zero": "ScheduleAssessmentsCard_opacity_zero",
	"opacity_one": "ScheduleAssessmentsCard_opacity_one",
	"card_text": "ScheduleAssessmentsCard_card_text",
	"image_label": "ScheduleAssessmentsCard_image_label",
	"card_warning": "ScheduleAssessmentsCard_card_warning"
};
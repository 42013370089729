import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { TextField } from '@material-ui/core';
import styles from './FreeTextCard.scss';

const FreeTextCardBase = ({ content, onChange, isInPreview }) => {
  return (
    <div className={styles.root}>
      {isInPreview ? (
        <p>{content}</p>
      ) : (
        <TextField
          multiline
          rows={4}
          variant="outlined"
          value={content}
          onChange={onChange}
          fullWidth
        />
      )}
    </div>
  );
};

FreeTextCardBase.defaultProps = {
  isInPreview: false,
};

FreeTextCardBase.propTypes = exact({
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isInPreview: PropTypes.bool,
});

export const FreeTextCard = React.memo(FreeTextCardBase);
FreeTextCard.displayName = 'FreeTextCard';

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button, CircularProgress } from '@material-ui/core';
import {
  postToFunctionsWithDefaultData,
  getCurrentURLParams,
} from '../../utils/utils';
import styles from './CircleSso.scss';

/* eslint-disable camelcase */

const CircleSsoBase = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isApprovedUser, setIsApprovedUser] = useState(null);

  const {
    client_id,
    redirect_uri,
    state,
    response_type,
  } = getCurrentURLParams();

  const onLogin = () => {
    postToFunctionsWithDefaultData({
      path: 'myndlift_sso-authorize',
      data: {
        userId: sessionStorage.teamMemberId || sessionStorage.userId,
        client_id,
        redirect_uri,
        state,
        response_type,
      },
    }).then(res => {
      window.open(res.callback_uri, '_self');
    });
  };

  const onDeny = () => {
    window.open('./', '_self');
  };

  useEffect(() => {
    (async () => {
      await new MyEvent('community_page_entered').log();
      const snapshot2 = await database
        .ref(
          `/myndliftSSO/vault/patients/${sessionStorage.teamMemberId ||
            sessionStorage.userId}/circle_so`
        )
        .once('value', v => v);
      setIsApprovedUser(snapshot2.val());
      if (!snapshot2.val()) {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isApprovedUser && client_id && redirect_uri && state && response_type) {
      setIsLoading(true);
      onLogin();
    }
  }, [isApprovedUser]);

  return (
    <div className={styles.root}>
      {!isLoading ? (
        <div className={styles.login_container}>
          <div className={styles.login_text}>
            <div className={styles.login_title}>Grant Permission</div>
            <div>
              Your Myndlift login will be used in order to access the Forum and
              Help Center.
            </div>
          </div>
          <div className={styles.btns_container}>
            <Button
              onClick={onDeny}
              variant="contained"
              style={{
                marginRight: 20,
                backgroundColor: 'white',
                fontSize: 14,
              }}
            >
              Return
            </Button>
            <Button
              onClick={() => {
                setIsLoading(true);
                onLogin();
              }}
              color="primary"
              variant="contained"
              style={{ backgroundColor: '#2096f3', fontSize: 14 }}
            >
              Proceed
            </Button>
          </div>
          {sessionStorage.userInfo ? (
            <div>
              Logged in as{' '}
              {sessionStorage.teamMemberId && sessionStorage.intercomInfo
                ? JSON.parse(sessionStorage.intercomInfo).email
                : JSON.parse(sessionStorage.userInfo).email}
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <CircularProgress style={{ marginTop: 100 }} />
          <div style={{ fontSize: 16, marginTop: 10 }}>
            Your Myndlift Forum profile is now being created. Please wait a few
            moments for this page to load.
          </div>
        </>
      )}
    </div>
  );
};

CircleSsoBase.propTypes = exact({});

export const CircleSso = React.memo(CircleSsoBase);
CircleSso.displayName = 'CircleSso';

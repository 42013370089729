exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UserInfo_size_17{font-size:17px}.UserInfo_size_10{font-size:10px}.UserInfo_size_11{font-size:11px}.UserInfo_size_13{font-size:13px}.UserInfo_size_15{font-size:15px}.UserInfo_size_14{font-size:14px}.UserInfo_size_16{font-size:16px}.UserInfo_size_12{font-size:12px}.UserInfo_extra_header{font-size:18px;font-weight:500;color:#000000}.UserInfo_header{font-size:17px;font-weight:500;color:#000000}.UserInfo_header_only_font_size{font-size:17px;font-weight:500}.UserInfo_sub_header{font-size:13px;color:#878787}.UserInfo_sub_header_black{font-size:13px;color:black}.UserInfo_sub_header_only_font_size{font-size:13px}.UserInfo_paragraph_only_font_size,.UserInfo_user_details>div{font-size:12px}.UserInfo_tab_font_size{font-size:13px}.UserInfo_pop_out_header{font-size:20px}.UserInfo_title{font-size:15px;font-weight:500;color:#484848}.UserInfo_title_black{font-size:15px;font-weight:500;color:black}.UserInfo_title_only_font_size{font-size:15px}.UserInfo_paragraph{font-size:12px;color:#484848}.UserInfo_btn{font-size:14px}.UserInfo_user_details{display:flex;justify-content:space-between;padding-left:15px}.UserInfo_user_details>div{width:50%}.UserInfo_user_details>div>button{margin-left:-20px}.UserInfo_user_details>div>p{margin-bottom:5px}.UserInfo_age{overflow:hidden;text-overflow:ellipsis}.UserInfo_phone_number{max-width:90%;overflow:hidden;text-overflow:ellipsis}.UserInfo_tooltip{max-width:-webkit-max-content !important;max-width:-moz-max-content !important;max-width:max-content !important}\n", ""]);

// Exports
exports.locals = {
	"size_17": "UserInfo_size_17",
	"size_10": "UserInfo_size_10",
	"size_11": "UserInfo_size_11",
	"size_13": "UserInfo_size_13",
	"size_15": "UserInfo_size_15",
	"size_14": "UserInfo_size_14",
	"size_16": "UserInfo_size_16",
	"size_12": "UserInfo_size_12",
	"extra_header": "UserInfo_extra_header",
	"header": "UserInfo_header",
	"header_only_font_size": "UserInfo_header_only_font_size",
	"sub_header": "UserInfo_sub_header",
	"sub_header_black": "UserInfo_sub_header_black",
	"sub_header_only_font_size": "UserInfo_sub_header_only_font_size",
	"paragraph_only_font_size": "UserInfo_paragraph_only_font_size",
	"user_details": "UserInfo_user_details",
	"tab_font_size": "UserInfo_tab_font_size",
	"pop_out_header": "UserInfo_pop_out_header",
	"title": "UserInfo_title",
	"title_black": "UserInfo_title_black",
	"title_only_font_size": "UserInfo_title_only_font_size",
	"paragraph": "UserInfo_paragraph",
	"btn": "UserInfo_btn",
	"age": "UserInfo_age",
	"phone_number": "UserInfo_phone_number",
	"tooltip": "UserInfo_tooltip"
};
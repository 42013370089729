import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SendIcon from '@material-ui/icons/Send';
import LockIcon from '@material-ui/icons/Lock';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import { Button, Dialog, Fade, Slide } from '@material-ui/core';
import styles from './ClientSuccessfullyRegistered.scss';
import { CustomDialogSuccessMessage } from '../../../Core/Components/CustomDialogSuccessMessage/CustomDialogSuccessMessage';
import { useTranslation } from '../../../Core/hooks/useTranslation';
// import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import doneAnimationAsset from '../../../../assets/done-animation.gif';
import { CreateProgramForUserWrapper } from '../../CreateProgramForUserWrapper/CreateProgramForUserWrapper';
import { addClientEventLogger } from '../addClientModalUtils';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import checkedImage from '../../../../assets/checked.png';
import { getLogoImage } from '../../../models/logo/logo';
import { WhiteLabelingPopOut } from '../../WhiteLabelingPopOut/WhiteLabelingPopOut';
import { CopyIcon } from '../../../Core/Icons/CopyIcon';

const ClientSuccessfullyRegisteredBase = ({
  userPassword,
  userEmail,
  header,
  // isDeployedAssessmentMessageShown,
  isEEGEnabled,
  isSymptomQuestionnaireEnabled,
  isCPTEnabled,
  newRegisteredUserId,
  clinicId,
  closeModalAndResetState,
  renderDeployAssessment,
  setAssessmentOptions,
  assessmentOptions,
  updateDeployedAssessmentStages,
  renderSelectQuestionnaires,
  displaySetupQuestionnaires,
  setDisplaySetupQuestionnaires,
  updateDeployedQuestionnaires,
  isSelfRegistration,
  userId,
  nfFlag,
  journeyName,
  isJourneyEnabled,
  isEntrySharingEnabled,
  selectedEntrySharingOptions,
  isCategoriesEnabled,
  teamMembersUserAssignedTo,
}) => {
  const [isWhiteLabelingAvailable] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/whiteLabeling/${clinicId}/isAvailable`,
  });
  const [isYoutubeSearchAvailable] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/youtubeSearch/${clinicId}/isAvailable`,
  });
  const [isProgressReportAvailable] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/progressReportFeature/${clinicId}/isAvailable`,
  });
  const [updatedAssessment, setUpdatedAssessment] = useFirebaseDB({
    path: `assessments/deployedAssessments/${
      isSelfRegistration ? userId : newRegisteredUserId
    }`,
  });

  const [isYoutubeSearchEnabledForClinic] = useFirebaseDB({
    path: `clinicConfig/youtubeSearchEnabled/${clinicId}/isEnabled`,
  });

  const [isClientRealEmail] = useFirebaseDB({
    path: `userInfo/${
      isSelfRegistration ? userId : newRegisteredUserId
    }/isRealEmail`,
  });

  const [isClientHasProgram] = useFirebaseDB({
    path: `deployedSession/${clinicId}/${
      isSelfRegistration ? userId : newRegisteredUserId
    }/programs`,
  });

  const [clinicLogoInfo, setClinicLogoInfo] = useState(null);
  useEffect(() => {
    (async () => {
      if (clinicLogoInfo === null) {
        const logoImageInfo = await getLogoImage({
          clinicId,
        });

        setClinicLogoInfo(logoImageInfo);
      }
    })();
  });
  const shouldShowWhiteLabelingCustomImage =
    clinicLogoInfo && clinicLogoInfo.customLogo && clinicLogoInfo.logoImage;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [shouldShowWhiteLabelPopup, setShouldShowWhiteLabelPopup] = useState(
    false
  );
  const [isSendCredentialsOpen, setIsSendCredentialsOpen] = useState(false);
  const [sendToEmail, setSendToEmail] = useState('');
  const [
    shouldRenderDeployAssessment,
    setShouldRenderDeployAssessment,
  ] = useState(false);
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);
  const [
    emailSentSuccessfullyMessage,
    setEmailSentSuccessfullyMessage,
  ] = useState(false);
  const [showTextCopyMessage, setShowTextCopyMessage] = useState(false);

  useEffect(() => {
    if (isClientRealEmail) {
      setSendToEmail(userEmail);
    }
  }, [isClientRealEmail]);

  const sendEmailClientCredentials = async () => {
    setEmailSentSuccessfully(true);
    // eslint-disable-next-line no-undef
    await fireFunctionPost('clinic_apis-sendUserCredentials', {
      patientId: newRegisteredUserId,
      userId: clinicId,
      email: sendToEmail,
      password: userPassword,
    });
    setEmailSentSuccessfullyMessage(true);
    setIsSendCredentialsOpen(false);
    // setEmailSentSuccessfully(false);
  };

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const copyMessaageToClipboard = () => {
    setShowTextCopyMessage(true);
    setTimeout(() => {
      setShowTextCopyMessage(false);
    }, 2000);
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(`
    Hi there!
    Your provider has created a Myndlift account for you.
    Here's how to activate it:

    Step1: Click here to download the app.
    Step2: Once the app is downloaded, use these credentials:

    Login email: ${userEmail}
    Password: ${userPassword}

    You will be asked to change your password on your first login.
    Enjoy Myndlifting.
    `);
  };

  const calcSharingPermission = () => {
    if (selectedEntrySharingOptions.length === 0) {
      return 'Ability to share entries';
    }
    if (selectedEntrySharingOptions.length === 1) {
      return 'Ability to share entries and request feedback';
    }
    if (selectedEntrySharingOptions.length === 2) {
      return 'Ability to share entries, request feedback, and reply';
    }
    return '';
  };

  let calculatedHiegth = 180;
  if (isWhiteLabelingAvailable) {
    calculatedHiegth -= 30;
  }
  if (isYoutubeSearchAvailable) {
    calculatedHiegth -= 30;
  }
  if (isProgressReportAvailable) {
    calculatedHiegth -= 30;
  }

  const availableFeaturesList = [
    {
      shouldRender: true,
      text: 'In-app support from our team',
      isChecked: true,
    },
    {
      shouldRender: true,
      text: 'Symptom questionnaires assessment',
      isChecked: isSymptomQuestionnaireEnabled,
      uncheckedText: '+ Add',
      unCheckedFunction: () => setShouldRenderDeployAssessment(true),
    },
    {
      shouldRender: true,
      text: 'Continuous performance task assessment',
      isChecked: isCPTEnabled,
      uncheckedText: '+ Add',
      unCheckedFunction: () => setShouldRenderDeployAssessment(true),
    },
    {
      shouldRender: nfFlag,
      text: 'EEG-sensing assessment',
      isChecked: isEEGEnabled,
      uncheckedText: '+ Add',
      unCheckedFunction: () => setShouldRenderDeployAssessment(true),
    },
    {
      text: `${journeyName} Journey`,
      isChecked: isJourneyEnabled,
      shouldRender: true,
    },
    {
      shouldRender: true,
      text: 'Mental wellbeing resources & exercises',
      isChecked: isCategoriesEnabled,
      // isNew: true,
    },
    {
      shouldRender: isEntrySharingEnabled,
      text: calcSharingPermission(),
      isChecked: true,
    },
    {
      shouldRender: isProgressReportAvailable,
      text: 'Access to provider-created reports',
      isChecked: true,
    },
    {
      shouldRender: nfFlag,
      text: 'Training progress insights',
      isChecked: true,
    },
    {
      shouldRender: nfFlag && isYoutubeSearchEnabledForClinic,
      text: isYoutubeSearchAvailable
        ? 'Neurofeedback training (Games & Youtube)'
        : 'Neurofeedback training (Games)',
      isChecked: isClientHasProgram,
      unCheckedComponent: () => (
        <CreateProgramForUserWrapper
          customerId={newRegisteredUserId || userId}
          onWrappedComponentClick={() => {
            addClientEventLogger.log('on_create_program_click');
            // setIsShowingGoToUserProfileBtn(true);
          }}
          // isWrappedComponentHidden={isShowingGoToUserProfileBtn}
        />
      ),
    },
    {
      shouldRender: isWhiteLabelingAvailable,
      text: 'Your own branding',
      isChecked: shouldShowWhiteLabelingCustomImage,
      uncheckedText: '+ Add your branding',
      unCheckedFunction: () => setShouldShowWhiteLabelPopup(true),
    },
  ];

  const renderAvailableFeature = item => {
    if (item.shouldRender) {
      if (item.isChecked) {
        return (
          <div className={styles.available_feature_item}>
            <div className={styles.available_feature_checkbox}>
              <img src={checkedImage} />
            </div>
            <div className={styles.available_feature_text}>{item.text}</div>
            {item.isNew ? (
              <span style={{ fontSize: 10, color: '#5bc5b8' }}>NEW</span>
            ) : null}
            {item.unCheckedComponent ? item.unCheckedComponent() : null}
          </div>
        );
      }
      return (
        <div className={styles.available_feature_item}>
          <div className={styles.available_feature_checkbox_unchecked} />
          <div className={styles.available_feature_text}>{item.text}</div>
          {item.uncheckedText ? (
            <span
              onClick={item.unCheckedFunction}
              className={styles.add_feature}
            >
              {item.uncheckedText}
            </span>
          ) : null}
          {item.unCheckedComponent ? item.unCheckedComponent() : null}
        </div>
      );
    }
    return null;
  };

  availableFeaturesList.sort((x, y) => {
    if (x.isChecked === y.isChecked) {
      return 0;
    }
    if (x.isChecked) {
      return -1;
    }
    return 1;
  });

  const t = useTranslation();

  if (userEmail || userPassword || isSelfRegistration) {
    return (
      <div className={styles.successfull_registered_contianer}>
        <div className={styles.package_info_container}>
          <div className={styles.success_header_alert}>
            <Avatar
              alt="success-image"
              src={doneAnimationAsset}
              className={styles.avatar}
            />
            <div className={styles.success_message_text_container}>
              <div>Great!</div>
              <div>Here's what your client gets:</div>
            </div>
          </div>
          <div className={styles.available_features_list}>
            {availableFeaturesList.map(item => (
              <div key={item.text}>{renderAvailableFeature(item)}</div>
            ))}
          </div>
          {(!isYoutubeSearchAvailable && nfFlag) ||
          !isWhiteLabelingAvailable ||
          !isProgressReportAvailable ? (
            <div
              className={styles.additional_options}
              style={isExpanded ? { height: `${calculatedHiegth}px` } : null}
            >
              <div
                onClick={() => {
                  setIsExpanded(!isExpanded);
                  if (!isExpanded) {
                    setTimeout(() => {
                      setIsExpanded2(!isExpanded2);
                    }, 500);
                  } else {
                    setIsExpanded2(!isExpanded2);
                  }
                }}
                className={styles.additional_options_title}
              >
                <div>See additional options</div>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
              {isExpanded2 ? (
                <div className={styles.additional_options_items_container}>
                  {!isYoutubeSearchAvailable && nfFlag ? (
                    <div className={styles.additional_options_item}>
                      <LockIcon
                        fontSize="small"
                        style={{ marginRight: '5px' }}
                      />
                      <div className={styles.additional_options_item_text}>
                        Search Youtube for any video during training
                      </div>
                    </div>
                  ) : null}
                  {!isWhiteLabelingAvailable ? (
                    <div className={styles.additional_options_item}>
                      <LockIcon
                        fontSize="small"
                        style={{ marginRight: '5px' }}
                      />
                      <div className={styles.additional_options_item_text}>
                        Your own branding
                      </div>
                    </div>
                  ) : null}
                  {!isProgressReportAvailable ? (
                    <div className={styles.additional_options_item}>
                      <LockIcon
                        fontSize="small"
                        style={{ marginRight: '5px' }}
                      />
                      <div className={styles.additional_options_item_text}>
                        Access to provider-created reports
                      </div>
                    </div>
                  ) : null}
                  <div
                    onClick={() =>
                      window.open(`${window.location.origin}/new_account.html`)
                    }
                    className={styles.additional_options_upgrade}
                  >
                    Upgrade to unlock →
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {!isSendCredentialsOpen ? (
          <div className={styles.client_credentials_container}>
            <div />
            {isSelfRegistration ? (
              <div style={{ minWidth: 300 }} />
            ) : (
              <div className={styles.client_credentials_content_container}>
                <div className={styles.client_credentials_title}>
                  Client's app credentials
                </div>
                <div className={styles.client_credentials_item}>
                  Username: <span style={{ fontWeight: 500 }}>{userEmail}</span>
                </div>
                <div className={styles.client_credentials_item}>
                  Password:{' '}
                  <span style={{ fontWeight: 500 }}>{userPassword}</span>
                </div>
                {emailSentSuccessfullyMessage ? (
                  <div className={styles.credentials_sent_message}>
                    <DoneIcon /> Credentials sent
                  </div>
                ) : null}
                <div
                  className={styles.client_credentials_item}
                  style={{
                    color: '#1a7ff8',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setEmailSentSuccessfully(false);
                    setIsSendCredentialsOpen(true);
                  }}
                >
                  <SendIcon fontSize="small" style={{ marginRight: '5px' }} />
                  Send credentials to client
                </div>
              </div>
            )}
            <div className={styles.client_credentials_btns_container}>
              <Button
                variant="text"
                color="default"
                onClick={async () => {
                  addClientEventLogger.log('on_done_click');
                  if (isSelfRegistration) {
                    await database
                      .ref(`userInfo/${userId}/clinicianSetUpRequired`)
                      .set(false);
                    await database
                      .ref(
                        `deployedSession/${sessionStorage.userId}/${userId}/assignedTo`
                      )
                      .set(teamMembersUserAssignedTo.assignedTo);
                    try {
                      // eslint-disable-next-line no-undef
                      localStorage.setItem(
                        `${
                          sessionStorage.isTeamMember === 'true'
                            ? sessionStorage.teamMemberId
                            : sessionStorage.userId
                        }-teamMemberAssign`,
                        JSON.stringify(teamMembersUserAssignedTo)
                      );
                      // eslint-disable-next-line no-empty
                    } catch (e) {}
                  }
                  closeModalAndResetState();
                }}
                style={{ marginRight: '10px' }}
              >
                <span className={styles.btn}>{t('done')}</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (isSelfRegistration) {
                    await database
                      .ref(`userInfo/${userId}/clinicianSetUpRequired`)
                      .set(false);
                    await database
                      .ref(
                        `deployedSession/${sessionStorage.userId}/${userId}/assignedTo`
                      )
                      .set(teamMembersUserAssignedTo.assignedTo);
                    try {
                      // eslint-disable-next-line no-undef
                      localStorage.setItem(
                        `${
                          sessionStorage.isTeamMember === 'true'
                            ? sessionStorage.teamMemberId
                            : sessionStorage.userId
                        }-teamMemberAssign`,
                        JSON.stringify(teamMembersUserAssignedTo)
                      );
                      // eslint-disable-next-line no-empty
                    } catch (e) {}
                  }
                  window.open(
                    `/userPage.html?customerId=${
                      isSelfRegistration ? userId : newRegisteredUserId
                    }`,
                    '_self'
                  );
                }}
              >
                <span className={styles.btn}>client profile</span>
              </Button>
            </div>
          </div>
        ) : null}
        <Slide
          direction="left"
          in={isSendCredentialsOpen}
          mountOnEnter
          unmountOnExit
          timeout={1000}
        >
          {!emailSentSuccessfully ? (
            <div className={styles.send_credentials_container}>
              <div className={styles.send_credentials_content_container}>
                <div className={styles.send_credentials_title}>
                  This is the message your client will receive from Myndlift
                </div>
                <TextField
                  variant="outlined"
                  label="Send to"
                  value={sendToEmail}
                  placeholder="Email address"
                  onChange={e => setSendToEmail(e.target.value)}
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                    color: '#939393',
                  }}
                  classes={{
                    root: styles.textfield,
                  }}
                  InputProps={{
                    classes: {
                      input: styles.text_input,
                    },
                  }}
                />
                <div className={styles.copy_clipboard_container}>
                  Message
                  <div
                    onClick={copyMessaageToClipboard}
                    style={{ cursor: 'pointer' }}
                  >
                    <CopyIcon />
                  </div>
                  {showTextCopyMessage ? (
                    <div style={{ position: 'absolute', right: '60px' }}>
                      Copied!
                    </div>
                  ) : null}
                </div>
                <div className={styles.credentials_message}>
                  Hi there! <br />
                  Your provider has created a Myndlift account for you.
                  <br />
                  Here's how to activate it: <br />
                  <br />
                  <span style={{ fontWeight: 500 }}>Step1:</span> Click{' '}
                  <a
                    href="http://www.myndlift.com/download"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    here
                  </a>{' '}
                  to download the app. <br />
                  <span style={{ fontWeight: 500 }}>Step2:</span> Once the app
                  is downloaded, use these credentials:
                  <br />
                  <br />
                  Login email:
                  <span style={{ fontWeight: 500, marginLeft: '5px' }}>
                    {userEmail}{' '}
                  </span>
                  <br />
                  Password:
                  <span style={{ fontWeight: 500, marginLeft: '5px' }}>
                    {userPassword}
                  </span>{' '}
                  <br />
                  <br />
                  You will be asked to change your password on your first login.
                  <br />
                  Enjoy Myndlifting.
                </div>
              </div>
              <div className={styles.send_credentials_btns}>
                <Button
                  variant="text"
                  color="default"
                  onClick={() => {
                    setIsSendCredentialsOpen(false);
                  }}
                  style={{ marginRight: '10px' }}
                >
                  <span className={styles.btn}>back</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    sendEmailClientCredentials();
                  }}
                  disabled={!validateEmail(sendToEmail)}
                >
                  <span
                    className={styles.btn}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    send <SendIcon style={{ marginLeft: '5px' }} />
                  </span>
                </Button>
              </div>
            </div>
          ) : (
            <Fade in={emailSentSuccessfully}>
              <div
                className={styles.send_credentials_container}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className={styles.success_header_alert}>
                  <Avatar
                    alt="success-image"
                    src={doneAnimationAsset}
                    className={styles.avatar}
                  />
                  <div className={styles.success_message_text_container}>
                    <div>Success!</div>
                    <div>Credentials sent</div>
                  </div>
                </div>
              </div>
            </Fade>
          )}
        </Slide>
        <Dialog open={shouldRenderDeployAssessment} maxWidth={false}>
          <div style={{ padding: '20px', width: '700px' }}>
            {renderDeployAssessment(true)}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  setAssessmentOptions(prvAssessmentOptions => ({
                    ...prvAssessmentOptions,
                    shouldBeTrackingBeforeFirstSession: false,
                    shouldBeTrackingPerMinutes: false,
                  }));
                  if (updatedAssessment) {
                    // for eslint
                  }
                  setUpdatedAssessment(assessmentOptions);
                  updateDeployedAssessmentStages(
                    isSelfRegistration ? userId : newRegisteredUserId
                  );
                  setShouldRenderDeployAssessment(false);
                }}
                variant="contained"
                color="primary"
                style={{ width: '100px', height: '40px', fontSize: '14px' }}
              >
                save
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog open={displaySetupQuestionnaires}>
          <div style={{ padding: '20px' }}>
            {renderSelectQuestionnaires()}
            <Button
              onClick={() => {
                updateDeployedQuestionnaires(
                  isSelfRegistration ? userId : newRegisteredUserId
                );
                setDisplaySetupQuestionnaires(false);
              }}
              variant="contained"
              color="primary"
              style={{ width: '100px', height: '40px', fontSize: '14px' }}
            >
              save
            </Button>
          </div>
        </Dialog>
        <WhiteLabelingPopOut
          isOpen={shouldShowWhiteLabelPopup}
          shouldPromptWhiteLabelingFeature={false}
          onClose={() => setShouldShowWhiteLabelPopup(false)}
        />
        {/* <CustomDialogSuccessMessage
          headerText={header}
          content={
            (userEmail || userPassword) && (
              <div>
                <div className={styles.general_info_container}>
                  <p>{t('client_registered_main_info')}</p>
                  <p>{t('client_registered_secondary_info')}</p>
                </div>
                <div className={styles.user_info_container}>
                  {userEmail && (
                    <p>
                      <span className={styles.option_label}>
                        {`${t('username')}:`}
                      </span>
                      <span>{userEmail}</span>
                    </p>
                  )}
                  {userPassword && (
                    <p>
                      <span className={styles.option_label}>
                        {`${t('password')}:`}
                      </span>
                      <span>{userPassword}</span>
                    </p>
                  )}
                </div>
                {isDeployedAssessmentMessageShown && (
                  <div>
                    <CustomAlert severity="info">
                      {t('assessment-has-been-deployed')}
                    </CustomAlert>
                  </div>
                )}
              </div>
            )
          }
        /> */}
      </div>
    );
  }
  // edit mode
  return (
    <div
      style={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomDialogSuccessMessage headerText={header} content={null} column />
      <Button
        onClick={closeModalAndResetState}
        variant="contained"
        color="primary"
        style={{ width: '100px', height: '40px' }}
      >
        close
      </Button>
    </div>
  );
};

ClientSuccessfullyRegisteredBase.propTypes = exact({
  userPassword: PropTypes.string,
  userEmail: PropTypes.string,
  header: PropTypes.node.isRequired,
  // isDeployedAssessmentMessageShown: PropTypes.bool.isRequired,
  isEEGEnabled: PropTypes.bool,
  isSymptomQuestionnaireEnabled: PropTypes.bool,
  isCPTEnabled: PropTypes.bool,
  newRegisteredUserId: PropTypes.string,
  clinicId: PropTypes.string,
  closeModalAndResetState: PropTypes.func,
  renderDeployAssessment: PropTypes.func,
  setAssessmentOptions: PropTypes.func,
  assessmentOptions: PropTypes.object,
  updateDeployedAssessmentStages: PropTypes.func,
  renderSelectQuestionnaires: PropTypes.func,
  displaySetupQuestionnaires: PropTypes.bool,
  setDisplaySetupQuestionnaires: PropTypes.func,
  updateDeployedQuestionnaires: PropTypes.func,
  isSelfRegistration: PropTypes.bool,
  userId: PropTypes.string,
  nfFlag: PropTypes.bool,
  journeyName: PropTypes.string,
  isJourneyEnabled: PropTypes.bool,
  isEntrySharingEnabled: PropTypes.bool,
  selectedEntrySharingOptions: PropTypes.array,
  isCategoriesEnabled: PropTypes.bool,
  teamMembersUserAssignedTo: PropTypes.object,
});

export const ClientSuccessfullyRegistered = React.memo(
  ClientSuccessfullyRegisteredBase
);

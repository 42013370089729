import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import pickBy from 'lodash/pickBy';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from 'recharts';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import { Paper } from '@material-ui/core';
import { presetFrequenciesNames } from '../../../models/frequencies/frequencies';
import { ClickableLegendItems } from '../../ClickableLegendItems/ClickableLegendItems';
import { AssessmentComparisonGraph } from '../AssessmentComparisonGraph/AssessmentComparisonGraph';

import styles from './AssessmentAmplitudePerFrequencyComparisonGraph.scss';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import {
  sortedFrequencies,
  amplitudesPerFrequencyValueTypes,
  amplitudesStatesFormattedNames,
  amplitudesStates,
} from '../../../models/assessments/assessments';
import { CustomSelect } from '../../../Core/Components/CustomSelect/CustomSelect';
import { setFixedDecimalsIfNeeded } from '../../../utils/utils';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const colorsByDataType = {
  eyesOpened: '#00aa89',
  eyesClosed: '#8b54c6',
  underTask: '#444466',
};
const colors = {
  [amplitudesStates.preEyesOpened]: colorsByDataType.eyesOpened,
  [amplitudesStates.preEyesClosed]: colorsByDataType.eyesClosed,
  [amplitudesStates.preUnderTask]: colorsByDataType.underTask,
  [amplitudesStates.postEyesOpened]: colorsByDataType.eyesOpened,
  [amplitudesStates.postEyesClosed]: colorsByDataType.eyesClosed,
  [amplitudesStates.postUnderTask]: colorsByDataType.underTask,
};
const legendItemsColors = colors;

const dataFormattedNames = {
  ...amplitudesStatesFormattedNames,
  ...presetFrequenciesNames,
};

const oldVersionDataLegendItemsFormattedNames = {
  eyesClosed: 'Eyes Closed',
  eyesOpened: 'Eyes Opened',
  underTask: 'Under Task',
};

const MAX_ALOUD_NOISE = 0.75;

const AssessmentAmplitudePerFrequencyComparisonGraphBase = ({
  data,
  unit,
  dataMax,
  dataMin,
  valuesType,
  valuesTypeFormattedText,
  allValueTypes,
  onValuesTypeChange,
  legendItems,
  onLegendItemClick,
  isInPreview,
  preAssessment,
  postAssessment,
  onPreAssessmentChange,
  onPostAssessmentChange,
  assessmentOptions,
  selectedChannels,
  allChannels,
  formattedChannelNames,
  onSelectedChannelsChange,
  preAssessmentTimestamp,
  postAssessmentTimestamp,
  isSomeDataHidden,
  noDataIndicator,
  preAssessmentNoise,
  postAssessmentNoise,
}) => {
  const t = useTranslation();

  const renderGraphContent = () => {
    const sortedData = mapValues(data, arrOfDataObjects =>
      arrOfDataObjects.sort(
        (dataObject1, dataObject2) =>
          sortedFrequencies.indexOf(dataObject1.name) -
          sortedFrequencies.indexOf(dataObject2.name)
      )
    );

    const yAxisTickFormatter =
      valuesType === amplitudesPerFrequencyValueTypes.relative
        ? tick => parseInt(tick, 10)
        : tick => setFixedDecimalsIfNeeded({ number: tick });

    /* old version data refer to data where  legendItems where of the form
        {
          eyesClosed: boolean
          ...
        }
        instead of
        {
          preEyesClosed: boolean
          postEyesClosed: boolean
        }
    */
    const oldVersionData = has(Object.values(legendItems)[0], 'eyesClosed');

    return (
      <div>
        {!isInPreview && (
          <React.Fragment>
            <div className={styles.channels_select}>
              <CheckboxesSelect
                selectedOptions={selectedChannels}
                allOptions={allChannels}
                onChange={onSelectedChannelsChange}
                inputLabelText="Electrodes to Show"
              />
            </div>
            <div className={styles.values_types_select}>
              <CustomSelect
                inputLabelText={t('select_values_types')}
                selectedOption={valuesType}
                allOptions={allValueTypes}
                onChange={onValuesTypeChange}
              />
              <TooltipWrapper
                title={
                  <TooltipTitle>
                    {t('relative-amplitudes-description')}
                  </TooltipTitle>
                }
              >
                <HelpIcon />
              </TooltipWrapper>
            </div>
          </React.Fragment>
        )}
        <div className={styles.graph_container}>
          {Object.entries(sortedData).map(([channel, dataUnit]) => {

          const isChannelGraphNoisy = preAssessmentNoise && preAssessmentNoise.eyesOpened && preAssessmentNoise.eyesOpened[channel] && preAssessmentNoise.eyesOpened[channel] > MAX_ALOUD_NOISE 
          && postAssessmentNoise && postAssessmentNoise.eyesOpened && postAssessmentNoise.eyesOpened[channel] && postAssessmentNoise.eyesOpened[channel] > MAX_ALOUD_NOISE && 
          preAssessmentNoise.eyesClosed && preAssessmentNoise.eyesClosed[channel] && preAssessmentNoise.eyesClosed[channel] > MAX_ALOUD_NOISE &&
          postAssessmentNoise.eyesClosed && postAssessmentNoise.eyesClosed[channel] && postAssessmentNoise.eyesClosed[channel] > MAX_ALOUD_NOISE;

            return (
              selectedChannels.indexOf(channel) > -1 && (
                <Paper key={channel} style={{position: 'relative'}}>
                  <div className={styles.channel_info}>
                    <h4>{formattedChannelNames[channel]}</h4>
                    <h5>{valuesTypeFormattedText}</h5>
                  </div>
                  {isChannelGraphNoisy ? (
                    <div style={{position: 'absolute', top: 20, right: 10}}>
                      <p style={{display: 'flex', alignItems: 'center', color: 'black'}}>
                        <WarningIcon/>
                        Signal was not properly captured in indicated region
                      </p>
                    </div>
                  ) : null}
                  <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                      data={dataUnit}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                        bottom: 30,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        tickFormatter={tick => dataFormattedNames[tick] || tick}
                      />
                      <YAxis
                        domain={[dataMin, dataMax]}
                        interval="preserveEnd"
                        unit={unit}
                        tickFormatter={yAxisTickFormatter}
                      />
                      <Tooltip
                        formatter={(value, name) => [
                          setFixedDecimalsIfNeeded({ number: value }),
                          dataFormattedNames[name] || name,
                        ]}
                        labelFormatter={label =>
                          dataFormattedNames[label] || label
                        }
                      />
                      <Line
                        dataKey="preEyesClosed"
                        stroke={colors.preEyesClosed}
                        unit={unit}
                        hide={
                          !legendItems[channel].preEyesClosed &&
                          !legendItems[channel].eyesClosed
                        }
                        strokeDasharray="5 5"
                      />
                      <Line
                        dataKey="postEyesClosed"
                        stroke={colors.postEyesClosed}
                        unit={unit}
                        hide={
                          !legendItems[channel].postEyesClosed &&
                          !legendItems[channel].eyesClosed
                        }
                      />
                      <Line
                        dataKey="preEyesOpened"
                        stroke={colors.preEyesOpened}
                        unit={unit}
                        hide={
                          !legendItems[channel].preEyesOpened &&
                          !legendItems[channel].eyesOpened
                        }
                        strokeDasharray="5 5"
                      />
                      <Line
                        dataKey="postEyesOpened"
                        stroke={colors.postEyesOpened}
                        unit={unit}
                        hide={
                          !legendItems[channel].postEyesOpened &&
                          !legendItems[channel].eyesOpened
                        }
                      />
                      {dataUnit && dataUnit[0].preUnderTask && (
                        <Line
                          dataKey="preUnderTask"
                          stroke={colors.preUnderTask}
                          unit={unit}
                          hide={
                            !legendItems[channel].preUnderTask &&
                            !legendItems[channel].underTask
                          }
                          strokeDasharray="5 5"
                        />
                      )}
                      {dataUnit && dataUnit[0].postUnderTask && (
                        <Line
                          dataKey="postUnderTask"
                          stroke={colors.postUnderTask}
                          unit={unit}
                          hide={
                            !legendItems[channel].postUnderTask &&
                            !legendItems[channel].underTask
                          }
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>

                  {oldVersionData ? (
                    <div className={styles.clickable_legend_items_container}>
                      <ClickableLegendItems
                        legendItems={legendItems[channel]}
                        colors={colorsByDataType}
                        isInPreview={isInPreview}
                        legendItemFormatter={legendItem =>
                          oldVersionDataLegendItemsFormattedNames[legendItem] ||
                          legendItem
                        }
                        onLegendItemClick={legendItem =>
                          onLegendItemClick(legendItem, channel)
                        }
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className={styles.clickable_legend_items_container}>
                        <ClickableLegendItems
                          legendItems={pickBy(
                            legendItems[channel],
                            (value, key) => key.startsWith('pre')
                          )}
                          colors={legendItemsColors}
                          isInPreview={isInPreview}
                          legendItemFormatter={legendItem =>
                            amplitudesStatesFormattedNames[legendItem] ||
                            legendItem
                          }
                          onLegendItemClick={legendItem =>
                            onLegendItemClick(legendItem, channel)
                          }
                          customLegendIcons={mapValues(
                            pickBy(legendItems[channel], (value, key) =>
                              key.startsWith('pre')
                            ),
                            (value, key) => {
                              return (
                                <div style={{ marginTop: -3 }}>
                                  <span
                                    style={{
                                      fontWeight: 900,
                                      fontSize: '120%',
                                      marginLeft: 10,
                                      marginRight: 10,
                                      color: legendItems[channel][key]
                                        ? legendItemsColors[key]
                                        : 'black',
                                    }}
                                  >
                                    - - -
                                  </span>
                                </div>
                              );
                            }
                          )}
                        />
                      </div>

                      <div className={styles.clickable_legend_items_container}>
                        <ClickableLegendItems
                          legendItems={pickBy(
                            legendItems[channel],
                            (value, key) => key.startsWith('post')
                          )}
                          colors={legendItemsColors}
                          isInPreview={isInPreview}
                          legendItemFormatter={legendItem =>
                            amplitudesStatesFormattedNames[legendItem] ||
                            legendItem
                          }
                          onLegendItemClick={legendItem =>
                            onLegendItemClick(legendItem, channel)
                          }
                        />
                      </div>
                    </React.Fragment>
                  )}
                </Paper>
              )
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <AssessmentComparisonGraph
      assessmentOptions={assessmentOptions}
      preAssessment={preAssessment}
      postAssessment={postAssessment}
      onPreAssessmentChange={onPreAssessmentChange}
      onPostAssessmentChange={onPostAssessmentChange}
      isInPreview={isInPreview}
      isSomeDataHidden={isSomeDataHidden}
      preAssessmentTimestamp={preAssessmentTimestamp}
      postAssessmentTimestamp={postAssessmentTimestamp}
    >
      {noDataIndicator || renderGraphContent()}
    </AssessmentComparisonGraph>
  );
};

AssessmentAmplitudePerFrequencyComparisonGraphBase.defaultProps = {
  isInPreview: false,
  noDataIndicator: null,
};

AssessmentAmplitudePerFrequencyComparisonGraphBase.propTypes = exact({
  data: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  dataMax: PropTypes.number.isRequired,
  valuesType: PropTypes.string.isRequired,
  valuesTypeFormattedText: PropTypes.string.isRequired,
  allValueTypes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
  onValuesTypeChange: PropTypes.func,
  legendItems: PropTypes.object.isRequired,
  selectedChannels: PropTypes.array.isRequired,
  allChannels: PropTypes.array,
  onSelectedChannelsChange: PropTypes.func,
  isInPreview: PropTypes.bool,
  preAssessment: PropTypes.string.isRequired,
  postAssessment: PropTypes.string.isRequired,
  preAssessmentTimestamp: PropTypes.number,
  postAssessmentTimestamp: PropTypes.number,
  onPreAssessmentChange: PropTypes.func,
  onPostAssessmentChange: PropTypes.func,
  onLegendItemClick: PropTypes.func,
  assessmentOptions: PropTypes.array,
  dataMin: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  formattedChannelNames: PropTypes.object.isRequired,
  isSomeDataHidden: PropTypes.bool.isRequired,
  noDataIndicator: PropTypes.node,
  preAssessmentNoise: PropTypes.object,
  postAssessmentNoise: PropTypes.object,
});

export const AssessmentAmplitudePerFrequencyComparisonGraph = React.memo(
  AssessmentAmplitudePerFrequencyComparisonGraphBase
);
AssessmentAmplitudePerFrequencyComparisonGraph.displayName =
  'AssessmentAmplitudePerFrequencyComparisonGraph';

import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
// import has from 'lodash/has';
import dayJS from 'dayjs';
import {
  Paper,
  Tooltip,
  Popover,
  Switch,
  Checkbox,
  Button,
} from '@material-ui/core';
import { keyBy, cloneDeep } from 'lodash';
import CommentIcon from '@material-ui/icons/Comment';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { EasierCustomTable } from '../../../Core/Components/CustomTable/EasierCustomTable';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import {
  convertObjectToArrWithIds,
  formatSecondsToMMSS,
  isDefined,
} from '../../../utils/utils';
import styles from './SessionsTab.scss';
import ThunderPNG from '../../../../assets/thunder.png';
import NoElectrodeYellowPNG from '../../../../assets/noelectrodeyellow.png';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { BlurOverlayIfContinuityCustomer } from '../BlurOverlayIfContinuityCustomer/BlurOverlayIfContinuityCustomer';
import { DeployedPrograms } from '../../DeployedPrograms/DeployedPrograms';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { FeedbackBox } from '../../FeedbackBox/FeedbackBox';
import { rapidSessionTypes } from '../../SessionToSessionReportGenerator/constants';
import { useTranslation } from '../../../Core/hooks/useTranslation';

const RowCell = ({ content }) => (
  <span className={styles.row_cell}>{content}</span>
);

RowCell.propTypes = exact({
  content: PropTypes.node.isRequired,
});

const HeadCell = ({ content }) => (
  <span className={styles.head_cell}>{content}</span>
);

HeadCell.propTypes = exact({
  content: PropTypes.node.isRequired,
});

const sessionsTableRowsPerPageOptions = [20, 30, 50];

const initialValue = Symbol('iv');

const styledApplyButton = {
  // margin: theme.spacing.unit,
  color: '#ffffff',
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  fontSize: '14px',
};

const SessionsTabBase = ({
  deployedProgram,
  protocols,
  trainingSessions,
  customerId,
  headsetDetails,
  onHeadsetDetailsUpdated,
  // sessionAnalysis,
  isExpired,

  /* sessions table and filter states and aconstants */

  currentTableData,
  setCurrentTableData,
  trainingSessionsReversedEntries,
  isSessionsTableFiltered,
  setIsSessionsTableFiltered,
  isPopoverOpen,
  setIsPopOverOpen,
  isFilterSelectToggleSwitchChecked,
  setIsFilterSelectToggleSwitchChecked,
  sessionsFilterSelectedProtocls,
  setSessionsFilterSelectedProtocls,
  isFilterSelectButtonDisabled,
  setIsFilterSelectButtonDisabled,
  rowsKeys,
  sessionsFilterSavedSelectedProtocols,
  setSessionsFilterSavedSelectedProtocls,
  completeSessionsTableData,
  sessionsFilterProtocols,
}) => {
  const t = useTranslation();
  const isComponentMountedRef = useRef(false);
  const sessionFilterSelectRef = useRef(null);

  useEffect(() => {
    isComponentMountedRef.current = true;
    return () => {
      isComponentMountedRef.current = false;
    };
  }, []);

  // eslint-disable-next-line no-undef
  // const sessionsAnalyzerRef = useRef(new AnalyzedSession());
  // const sessionsAnalyzer = sessionsAnalyzerRef.current;
  // const [isSessionAnalyzerReady, setIsSessionAnalyzerReady] = useState(false);

  const [sessionsFeedback] = useFirebaseDB({
    path: `sessionFeedback/patientAnswers/${customerId}`,
    defaultValue: useRef({}).current,
    initialValue,
  });

  const [sessionsMentalTags] = useFirebaseDB({
    path: `sessionMentalTags/patientTags/${customerId}`,
    defaultValue: useRef({}).current,
    initialValue,
  });

  const sessionsNumbersToSessionsFeedback = useMemo(
    () =>
      keyBy(
        Object.values(
          sessionsFeedback === initialValue ? {} : sessionsFeedback
        ),
        'sessionNumber'
      ),
    [sessionsFeedback]
  );

  const sessionsNumbersToSessionsMentalTags = {};
  if (sessionsMentalTags !== initialValue) {
    Object.keys(sessionsMentalTags).forEach(sessionId => {
      if (trainingSessions[sessionId]) {
        sessionsNumbersToSessionsMentalTags[
          trainingSessions[sessionId].number
        ] = { ...sessionsMentalTags[sessionId] };
      }
    });
  }

  const [feedbackTags] = useFirebaseDB({
    path: 'sessionFeedback/views/allAnswers',
    initialValue,
  });

  const isSessionsTableReadyToRender =
    // isSessionAnalyzerReady &&
    sessionsFeedback !== initialValue && feedbackTags !== initialValue;

  // useEffect(() => {
  //   sessionsAnalyzer
  //     .init(sessionStorage.customerId)
  //     .then(
  //       () => isComponentMountedRef.current && setIsSessionAnalyzerReady(true)
  //     );
  // }, [sessionsAnalyzer]);

  const onSessionRowClick = useCallback(
    sessionId => {
      sessionStorage.customerId = customerId;
      sessionStorage.sessionId = sessionId;
      sessionStorage.session = JSON.stringify(trainingSessions[sessionId]);
      sessionStorage.goBackTo = 3;
      window.open('sessionPages.html', '_blank');
    },
    [trainingSessions, customerId]
  );

  const mapSelectedFilterProtocols = () => {
    const tempArr = sessionsFilterSavedSelectedProtocols
      .filter(protocolObj => protocolObj.checked)
      .map(item => item.protocolName);

    const joinedSelectedProtocolsString = tempArr.join(', ');
    return joinedSelectedProtocolsString;
  };

  const resetProtocolFilter = () => {
    setIsSessionsTableFiltered(false);
    setIsFilterSelectToggleSwitchChecked(true);
    const tempArr = sessionsFilterProtocols.map(item =>
      Object.assign({}, item)
    );
    const tempArr2 = sessionsFilterProtocols.map(item =>
      Object.assign({}, item)
    );
    setSessionsFilterSelectedProtocls(tempArr);
    setSessionsFilterSavedSelectedProtocls(tempArr2);
    setIsFilterSelectButtonDisabled(true);
    setCurrentTableData(cloneDeep(completeSessionsTableData));
  };

  const handleCloseSessionsFilterSelectPopover = () => {
    setIsPopOverOpen(false);
    setTimeout(() => {
      setIsFilterSelectButtonDisabled(true);

      const tempArr = sessionsFilterSavedSelectedProtocols.map(item =>
        Object.assign({}, item)
      );
      setSessionsFilterSelectedProtocls(tempArr);
      setIsFilterSelectToggleSwitchChecked(true);
    }, 200);
  };

  const handleOpenSessionsFilterSelectPopover = () => {
    setIsPopOverOpen(true);
  };

  const handleFilterSelectButtonDisable = () => {
    const temp1 = sessionsFilterSavedSelectedProtocols.map(
      protocolObj => protocolObj.checked
    );
    const temp2 = sessionsFilterSelectedProtocls.map(
      protocolObj => protocolObj.checked
    );
    const noChnageInProtocolsSelection =
      temp1.length === temp2.length &&
      temp1.every((value, index) => value === temp2[index]);
    const allProtocolsUnChecked = temp2.every(value => value === false);
    if (noChnageInProtocolsSelection || allProtocolsUnChecked) {
      setIsFilterSelectButtonDisabled(true);
    } else {
      setIsFilterSelectButtonDisabled(false);
    }
  };

  const handleSelectSwitchToggle = () => {
    const tempArr = [...sessionsFilterSelectedProtocls].map(protocolObj => {
      const newProtocolObj = { ...protocolObj };
      newProtocolObj.checked = !isFilterSelectToggleSwitchChecked;
      return newProtocolObj;
    });
    setSessionsFilterSelectedProtocls(tempArr);
    setIsFilterSelectToggleSwitchChecked(!isFilterSelectToggleSwitchChecked);
  };

  useEffect(() => {
    handleFilterSelectButtonDisable();
  }, [isFilterSelectToggleSwitchChecked, isPopoverOpen]);

  const handleFilterSelectCheckbox = protocol => {
    const tempArr = [...sessionsFilterSelectedProtocls];
    tempArr.forEach(tempProtocolObj => {
      if (protocol.protocolName === tempProtocolObj.protocolName) {
        const newTempProtocolObj = { ...tempProtocolObj };
        newTempProtocolObj.checked = !newTempProtocolObj.checked;
        Object.assign(tempProtocolObj, newTempProtocolObj);
      }
    });

    setSessionsFilterSelectedProtocls(tempArr);
    handleFilterSelectButtonDisable();
  };

  const handleFilteringTableData = () => {
    const tempArr = sessionsFilterSelectedProtocls.map(item =>
      Object.assign({}, item)
    );
    setSessionsFilterSavedSelectedProtocls(tempArr);
    const filteredTrainingSessionsReversedEntries = [
      ...completeSessionsTableData,
    ].filter(([, session]) => {
      return sessionsFilterSelectedProtocls.some(protocolObj => {
        return (
          protocolObj.protocolName === session.protocol && protocolObj.checked
        );
      });
    });
    setCurrentTableData(filteredTrainingSessionsReversedEntries);
    setIsPopOverOpen(false);

    handleFilterSelectButtonDisable();

    const temp1 = sessionsFilterSelectedProtocls.map(
      protocolObj => protocolObj.checked
    );
    const temp2 = sessionsFilterProtocols.map(
      protocolObj => protocolObj.checked
    );

    const isEqual =
      temp1.length === temp2.length &&
      temp1.every((value, index) => value === temp2[index]);
    if (isEqual) {
      resetProtocolFilter();
    } else {
      setIsSessionsTableFiltered(true);
    }
  };

  const renderFeedbackBox = sessionNumber => {
    const sessionFeedback = sessionsNumbersToSessionsFeedback[sessionNumber];
    const sessionMentalTags =
      sessionsNumbersToSessionsMentalTags[sessionNumber];

    return (
      <Paper>
        {' '}
        <FeedbackBox
          rating={
            isDefined(sessionFeedback?.rating)
              ? sessionFeedback.rating + 1
              : undefined
          }
          comment={sessionFeedback?.comment}
          tags={Object.keys(sessionFeedback?.answers || {})
            .filter(key => sessionFeedback.answers[key])
            .map(answer => feedbackTags[answer]?.en)}
          sessionMentalTags={sessionMentalTags}
        />
      </Paper>
    );
  };

  const rawTableHeads = [
    '#',
    'Date',
    'Duration',
    'Rounds',
    'Avg Score',
    'Protocol',
    'Alerts',
    'Positive Feedback(%)',
    'Session Check-In & Rating',
  ];
  const colsKeys = rawTableHeads;
  const tableHeadsRow = rawTableHeads.map(content => (
    <HeadCell content={content} />
  ));

  // const trainingSessionsReversedEntries = Object.entries(sessionAnalysis)
  //   .reverse()
  //   .filter(
  //     ([, session]) => !session.isDeleted && Object.keys(session).length > 1
  //   );

  // const rowsKeys = trainingSessionsReversedEntries.map(entryArr => entryArr[0]);

  const rows = currentTableData.map(([key, session]) => {
    // const rounds = Object.values(session.rounds);
    // const [firstRound] = rounds;
    const sessionNumber =
      trainingSessions && trainingSessions[key] && trainingSessions[key].number;
    const sessionFeedback = sessionsNumbersToSessionsFeedback[sessionNumber];
    const sessionMentalTags =
      sessionsNumbersToSessionsMentalTags[sessionNumber];
    const row = [
      <RowCell content={sessionNumber} />,
      <RowCell
        content={dayJS(session.timestamp).format(DAY_JS_DATE_FORMATS.american)}
      />,
      <RowCell
        content={
          <>
            <span>
              {// eslint-disable-next-line prefer-template
              formatSecondsToMMSS({
                seconds: session.duration,
              }) +
                `${
                  trainingSessions[session.sessionKey] &&
                  trainingSessions[session.sessionKey].setDuration
                    ? // eslint-disable-next-line prefer-template
                      ' / ' +
                      formatSecondsToMMSS({
                        seconds:
                          trainingSessions[session.sessionKey].setDuration,
                      })
                    : // eslint-disable-next-line prefer-template
                      ' / ' + formatSecondsToMMSS({ seconds: session.duration })
                }`}
            </span>
            {trainingSessions[key] && trainingSessions[key].isRapidSession ? (
              <Tooltip
                title="The client chose to complete a rapid session"
                placement="bottom"
                classes={{ tooltip: styles.tooltip2 }}
              >
                <img src={ThunderPNG} style={{ width: 15, height: 15 }} />
              </Tooltip>
            ) : null}
            {trainingSessions[key] &&
            trainingSessions[key].rapidSessionType ===
              rapidSessionTypes.withoutElectrode.val ? (
              <Tooltip
                title="The client completed a rapid session without an electrode."
                placement="bottom"
                classes={{ tooltip: styles.tooltip2 }}
              >
                <img
                  src={NoElectrodeYellowPNG}
                  style={{ width: 15, height: 15 }}
                />
              </Tooltip>
            ) : null}
          </>
        }
      />,
      <RowCell content={session.roundNumber} />,
      // eslint-disable-next-line no-undef
      <RowCell content={Math.round(session.score / (session.duration / 60))} />,
      // eslint-disable-next-line no-undef
      <RowCell
        content={`${session.training_channel}-${session.reference_channel} | ${session.protocol}`}
      />,
      // eslint-disable-next-line no-undef
      <RowCell content={session.alerts} />,
      <RowCell content={`${Math.round(session.totalSuccessRate)}%`} />,
      <RowCell
        content={
          sessionFeedback || sessionMentalTags ? (
            <Tooltip
              title={renderFeedbackBox(sessionNumber)}
              classes={{ tooltip: styles.tooltip, popper: styles.popper }}
              placement="bottom"
            >
              <div className={styles.rating_container}>
                <span>
                  {// eslint-disable-next-line no-nested-ternary
                  isDefined(sessionFeedback?.rating) ? (
                    sessionFeedback.rating + 1
                  ) : sessionFeedback && sessionFeedback.comment ? null : (
                    <CommentIcon style={{ fontSize: 20 }} />
                  )}
                </span>
                {sessionFeedback && sessionFeedback.comment && (
                  <CommentIcon style={{ fontSize: 20 }} />
                )}
              </div>
            </Tooltip>
          ) : (
            'None'
          )
        }
      />,
    ];

    return row;
  });

  const { programs = {} } = deployedProgram || {};
  const programsArr = convertObjectToArrWithIds(programs)
    .map(program => ({
      // eslint-disable-next-line no-undef
      protocolName: extractProtocolName(program.protocolDetails, protocols),
      ...program,
    }))
    .sort((p1, p2) => p1.programIndex - p2.programIndex);

  return (
    <React.Fragment>
      <div className={styles.root}>
        <DeployedPrograms
          programs={programsArr}
          customerId={customerId}
          isExpired={isExpired}
        />

        {trainingSessionsReversedEntries.length > 0 &&
          (isSessionsTableReadyToRender ? (
            <BlurOverlayIfContinuityCustomer
              headsetDetails={headsetDetails}
              onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
            >
              <Paper className={styles.sessions_table}>
                <div className={styles.table_header}>
                  <p>{t('Completed Sessions')}</p>

                  <div
                    className={
                      isSessionsTableFiltered
                        ? styles.session_table_filter_select_after_filter
                        : styles.session_table_filter_select
                    }
                    ref={sessionFilterSelectRef}
                    onClick={handleOpenSessionsFilterSelectPopover}
                  >
                    <div className={styles.select_filter_popover_content}>
                      <p
                        className={
                          isSessionsTableFiltered
                            ? styles.session_select_title_after_filter
                            : styles.session_select_title
                        }
                      >
                        {isSessionsTableFiltered
                          ? t(mapSelectedFilterProtocols())
                          : t('Protocol')}
                      </p>
                    </div>
                    {isSessionsTableFiltered ? (
                      <CloseIcon
                        color="primary"
                        onClick={event => {
                          event.stopPropagation();
                          resetProtocolFilter();
                        }}
                      />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </div>
                  <Popover
                    id="session_filter_select_popover"
                    open={isPopoverOpen}
                    anchorEl={sessionFilterSelectRef.current}
                    onClose={handleCloseSessionsFilterSelectPopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    classes={{ paper: styles.select_filter_popover }}
                  >
                    <p>{t('Protocol')}</p>
                    <div>
                      <div className={styles.select_filter_switch_conatainer}>
                        <p>{t('Select all Protocols')}</p>
                        <Switch
                          color="primary"
                          checked={isFilterSelectToggleSwitchChecked}
                          onChange={handleSelectSwitchToggle}
                        />
                      </div>

                      {sessionsFilterSelectedProtocls.map(protocol => {
                        return (
                          <div
                            className={
                              styles.select_filter_popover_checkbox_container
                            }
                          >
                            <Checkbox
                              key={protocol.protocolName}
                              onClick={() =>
                                handleFilterSelectCheckbox(protocol)
                              }
                              checked={protocol.checked}
                              color="primary"
                            />
                            <p>{protocol.protocolName}</p>
                          </div>
                        );
                      })}
                      <div
                        className={
                          styles.select_filter_popover_apply_button_container
                        }
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={styledApplyButton}
                          onClick={handleFilteringTableData}
                          disabled={isFilterSelectButtonDisabled}
                        >
                          {t('Apply')}
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>

                <EasierCustomTable
                  rows={[tableHeadsRow, ...rows]}
                  colsKeys={colsKeys}
                  rowsKeys={rowsKeys}
                  onRowClick={onSessionRowClick}
                  rowsPerPageOptions={sessionsTableRowsPerPageOptions}
                />
              </Paper>
            </BlurOverlayIfContinuityCustomer>
          ) : (
            <Loader />
          ))}
      </div>
    </React.Fragment>
  );
};

SessionsTabBase.propTypes = exact({
  deployedProgram: PropTypes.object,
  protocols: PropTypes.object.isRequired,
  trainingSessions: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired,
  headsetDetails: PropTypes.object.isRequired,
  onHeadsetDetailsUpdated: PropTypes.func.isRequired,
  // sessionAnalysis: PropTypes.object,
  isExpired: PropTypes.string,

  /* ///// */

  currentTableData: PropTypes.array.isRequired,
  setCurrentTableData: PropTypes.func.isRequired,
  trainingSessionsReversedEntries: PropTypes.array.isRequired,
  isSessionsTableFiltered: PropTypes.bool.isRequired,
  setIsSessionsTableFiltered: PropTypes.func.isRequired,
  isPopoverOpen: PropTypes.bool.isRequired,
  setIsPopOverOpen: PropTypes.func.isRequired,
  isFilterSelectToggleSwitchChecked: PropTypes.bool.isRequired,
  setIsFilterSelectToggleSwitchChecked: PropTypes.func.isRequired,
  sessionsFilterSelectedProtocls: PropTypes.array.isRequired,
  setSessionsFilterSelectedProtocls: PropTypes.func.isRequired,
  sessionsFilterSavedSelectedProtocols: PropTypes.array.isRequired,
  setSessionsFilterSavedSelectedProtocls: PropTypes.func.isRequired,
  isFilterSelectButtonDisabled: PropTypes.bool.isRequired,
  setIsFilterSelectButtonDisabled: PropTypes.func.isRequired,
  rowsKeys: PropTypes.array.isRequired,
  sessionsFilterProtocols: PropTypes.array.isRequired,
  completeSessionsTableData: PropTypes.array.isRequired,
});

export const SessionsTab = React.memo(SessionsTabBase);
SessionsTab.displayName = 'SessionsTab';

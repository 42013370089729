import React from 'react';

export const RightDownArrow = () => (
  <div
    style={{
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 0 12px 12px',
      borderColor: 'transparent transparent #999999 transparent',
      position: 'absolute',
      zIndex: -99,
      right: 0,
      top: 'calc(50% - 4px)',
    }}
  />
);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import styles from './UserInfoAvatar.scss';
import museOnMaleHeadPNG from '../../../assets/muse-on-male-head.png';
import museOnFemaleHeadPNG from '../../../assets/muse-on-female-head.png';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

const UserInfoAvatarBase = ({ name, gender, email }) => {
  const userInfoAvatarLogger = new MyEventWrapper('wrapped_dashboard');

  const year = 2024;
  const viewerType = 'patients';

  const patientId = sessionStorage.customerId;
  const [customerWrappedData] = useFirebaseDB({
    path: `yearWrapped/${year}/${viewerType}/data/${patientId}`,
  });

  const url = `/customer-year-wrap.html?patientId=${patientId}`;

  const [isWrappedDialogOpen, setIsWrappedDialogOpen] = useState(false);
  return (
    <div className={styles.user_avatar_container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img
          className={styles.avatar}
          src={gender === 'Male' ? museOnMaleHeadPNG : museOnFemaleHeadPNG}
        />
        {customerWrappedData && (
          <div className={styles.button_container}>
            <button
              className={styles.wrapped_button}
              onClick={() => {
                userInfoAvatarLogger.log('button_clicked');
                setIsWrappedDialogOpen(true);
              }}
              type="button"
            >
              <span role="img" aria-label="drum" style={{ margin: '0 5px' }}>
                ⭐️
              </span>
              2024 IN REVIEW
            </button>
          </div>
        )}
      </div>
      <div>
        <TooltipWrapper
          title={<TooltipTitle>{name}</TooltipTitle>}
          classes={{ tooltip: styles.tooltip }}
        >
          <p className={styles.title_black}>{name}</p>
        </TooltipWrapper>

        <TooltipWrapper
          title={<TooltipTitle>{email}</TooltipTitle>}
          classes={{ tooltip: styles.tooltip }}
        >
          <p>{email}</p>
        </TooltipWrapper>
      </div>
      <Dialog
        open={isWrappedDialogOpen}
        onClose={() => {
          setIsWrappedDialogOpen(false);
        }}
        style={{ width: '600px', height: '700px', margin: 'auto' }}
      >
        {customerWrappedData ? (
          <>
            <DialogContent
              style={{
                alignSelf: 'center',
                minWidth: 400,
                margin: '0',
                padding: '0',
                width: '500px',
                height: '823px',
                backgroundColor: 'transparent',
                overflow: 'hidden',
              }}
            >
              <iframe
                src={url}
                title="External Webpage"
                width="100%"
                height="105%"
                style={{ border: 'none' }}
              />
            </DialogContent>
          </>
        ) : (
          <div className={styles.progress_container}>
            <CircularProgress />
          </div>
        )}
      </Dialog>
    </div>
  );
};

UserInfoAvatarBase.propTypes = exact({
  name: PropTypes.string.isRequired,
  gender: PropTypes.oneOf(['Male', 'Female']).isRequired,
  email: PropTypes.string.isRequired,
});

export const UserInfoAvatar = React.memo(UserInfoAvatarBase);
UserInfoAvatar.displayName = 'UserInfoAvatar';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".LagoArticleLessonDialog_upload_image_container{display:flex;flex-direction:column;justify-content:center}.LagoArticleLessonDialog_upload_image_btns{display:flex;flex-direction:column;justify-content:center;margin-top:10px}.LagoArticleLessonDialog_lago_control_img{width:100px;height:100px;border-radius:50%}.LagoArticleLessonDialog_journal_dialog_container{display:flex;justify-content:space-between}.LagoArticleLessonDialog_journal_inputs_container{display:flex;flex-direction:column}.LagoArticleLessonDialog_selectRoot{display:flex;flex-wrap:wrap;margin:10px 0;width:200px;box-sizing:border-box}.LagoArticleLessonDialog_formControl{min-width:100%;padding:0 !important}.LagoArticleLessonDialog_selectEmpty{margin-top:16px}.LagoArticleLessonDialog_selectFormLabel{font-size:17px !important;color:rgba(0,0,0,0.87) !important;font-weight:500;background-color:#fff;padding:0 5px !important}.LagoArticleLessonDialog_menuItem{font-size:14px !important}\n", ""]);

// Exports
exports.locals = {
	"upload_image_container": "LagoArticleLessonDialog_upload_image_container",
	"upload_image_btns": "LagoArticleLessonDialog_upload_image_btns",
	"lago_control_img": "LagoArticleLessonDialog_lago_control_img",
	"journal_dialog_container": "LagoArticleLessonDialog_journal_dialog_container",
	"journal_inputs_container": "LagoArticleLessonDialog_journal_inputs_container",
	"selectRoot": "LagoArticleLessonDialog_selectRoot",
	"formControl": "LagoArticleLessonDialog_formControl",
	"selectEmpty": "LagoArticleLessonDialog_selectEmpty",
	"selectFormLabel": "LagoArticleLessonDialog_selectFormLabel",
	"menuItem": "LagoArticleLessonDialog_menuItem"
};
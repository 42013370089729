import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import CheckIcon from '@material-ui/icons/Check';
import styles from './StartTrialBanner.scss';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { NFAddonsDialog } from '../NFAddonsDialog/NFAddonsDialog';
import NeuroPromoPng from '../../../assets/neuro_promo.png';
import { getEventLogger } from '../../utils/utils';

const logClinicRegistrationEvent = getEventLogger({
  prefix: 'clinic_register',
});

const StartTrialBannerBase = ({
  isNf,
  isNfAdded,
  setIsNfAdded,
  isFromDashboard,
  isAnnual,
  nfFlagPendingDeactivation,
  NFPackages,
  isInactive,
  addonGroupId,
}) => {
  const [priceDialogOpen, setPriceDialogOpen] = useState(false);

  const calcPrice = () => {
    if (isAnnual) {
      return NFPackages.annualMonthlyBasePrice;
    }
    return NFPackages.basePrice;
  };

  const calcButtonText = () => {
    if (isNfAdded && !nfFlagPendingDeactivation) {
      return 'REMOVE';
    }
    if (isFromDashboard) {
      if (nfFlagPendingDeactivation) {
        return 'REACTIVATE';
      }
      return 'ADD';
    }
    return 'ADD TO TRIAL';
  };

  if (isNf) {
    return (
      <div
        className={styles.start_trial_banner_container_nf}
        style={
          isFromDashboard
            ? {
                width: '100%',
                padding: 0,
                backgroundColor: 'white',
                maxWidth: 900,
                margin: '0 auto',
              }
            : null
        }
      >
        {isFromDashboard ? null : (
          <div className={styles.start_trial_banner_nf_title}>
            Offer your clients Myndlift’s neurofeedback to accelerate progress
          </div>
        )}
        <div
          className={styles.start_trial_banner_container_nf_data}
          style={
            // eslint-disable-next-line no-nested-ternary
            isFromDashboard
              ? { width: '100%' }
              : isNfAdded
              ? { borderColor: '#829EDE' }
              : null
          }
        >
          <div className={styles.start_trial_banner_nf_data_left}>
            <div className={styles.start_trial_banner_nf_data_left_flex}>
              <div>
                <div
                  className={styles.neuro_label_nf}
                  style={
                    isFromDashboard
                      ? { width: 122, height: 61, fontSize: 17 }
                      : null
                  }
                >
                  Neuro
                </div>
                <div
                  className={styles.start_trial_banner_nf_data_text}
                  style={{ marginTop: 10 }}
                >
                  <div
                    className={styles.start_trial_banner_nf_data_text_1}
                    style={isFromDashboard ? { fontSize: 12 } : null}
                  >
                    Starting from
                  </div>
                  <div
                    className={styles.start_trial_banner_nf_data_text_2}
                    style={isFromDashboard ? { fontSize: 22 } : null}
                  >
                    ${calcPrice()}/
                    <span
                      style={
                        isFromDashboard
                          ? { fontSize: 13 }
                          : { fontSize: 18, fontWeight: 400 }
                      }
                    >
                      mo
                    </span>
                  </div>
                  <TooltipWrapper
                    disabled={!isInactive}
                    title={
                      <TooltipTitle>
                        To be able to add Neuro, you need to activate a plan
                        first.
                      </TooltipTitle>
                    }
                  >
                    <div
                      className={
                        isFromDashboard
                          ? styles.start_trial_banner_nf_data_btn_dashboard
                          : styles.start_trial_banner_nf_data_btn
                      }
                      onClick={() => {
                        if (!isFromDashboard) {
                          const planInfoStorage = JSON.parse(
                            // eslint-disable-next-line no-undef
                            localStorage.getItem('planInfo')
                          );
                          logClinicRegistrationEvent({
                            name: 'nf_add_on_add_remove',
                            data: {
                              session_id: planInfoStorage.sessionId,
                              from_where: 'select_plan',
                              nfGroupId: addonGroupId,
                              nfPlanInd: 0,
                              include_nf_add_on: !isNfAdded,
                              isNF: true,
                            },
                          });
                        }
                        if (isInactive) {
                          return;
                        }
                        setIsNfAdded(!isNfAdded);
                      }}
                      style={
                        // eslint-disable-next-line no-nested-ternary
                        isInactive
                          ? {
                              borderColor: '#a1a1a1',
                              color: '#a1a1a1',
                              cursor: 'auto',
                            }
                          : isNfAdded && calcButtonText() !== 'REMOVE'
                          ? { color: 'white', backgroundColor: '#829EDE' }
                          : null
                      }
                    >
                      {calcButtonText()}
                    </div>
                  </TooltipWrapper>
                </div>
              </div>
              <img src={NeuroPromoPng} className={styles.promo_img} />
            </div>
          </div>
          <div className={styles.start_trial_banner_nf_data_right}>
            <div style={{ marginTop: 15 }}>
              {NFPackages.features.map(feature => {
                return (
                  <div
                    className={styles.feature_item}
                    key={feature.feature}
                    style={isFromDashboard ? { fontSize: 13 } : null}
                  >
                    <CheckIcon style={{ color: '#829EDE', marginRight: 15 }} />
                    {feature.feature}
                  </div>
                );
              })}
            </div>
            <div
              className={styles.start_trial_banner_nf_data_text_3}
              onClick={() => {
                setPriceDialogOpen(true);
                const planInfoStorage = JSON.parse(
                  // eslint-disable-next-line no-undef
                  localStorage.getItem('planInfo')
                );
                logClinicRegistrationEvent({
                  name: 'how_price_calculated_clicked',
                  data: {
                    session_id: isFromDashboard
                      ? sessionStorage.userId
                      : planInfoStorage.sessionId,
                    from_where: isFromDashboard ? 'dashboard' : 'select_plan',
                    isNF: true,
                  },
                });
              }}
              style={isFromDashboard ? { fontSize: 11 } : null}
            >
              How the price is calculated
            </div>
            {/* {isFromDashboard ? null : (
              <div
                className={styles.start_trial_banner_nf_data_right_link}
                onClick={() => {
                  const planInfoStorage = JSON.parse(
                    // eslint-disable-next-line no-undef
                    localStorage.getItem('planInfo')
                  );
                  logClinicRegistrationEvent({
                    name: 'how_does_it_work_clicked',
                    data: {
                      session_id: planInfoStorage.sessionId,
                      from_where: isFromDashboard ? 'dashboard' : 'select_plan',
                      isNF: true,
                    },
                  });
                  window.open('https://www.myndlift.com/neuro', '_blank');
                }}
                style={isFromDashboard ? { fontSize: 12 } : null}
              >
                How does it work?
              </div>
            )} */}
          </div>
        </div>
        <NFAddonsDialog
          priceDialogOpen={priceDialogOpen}
          setPriceDialogOpen={setPriceDialogOpen}
          NFPackages={NFPackages}
        />
      </div>
    );
  }
  return (
    <div
      className={styles.start_trial_banner_container}
      style={
        isFromDashboard ? { maxWidth: 900, margin: '0 auto' } : { marginTop: 0 }
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          className={styles.start_trial_banner_title}
          style={isFromDashboard ? { fontSize: 17 } : null}
        >
          Offer neurofeedback training and EEG sensing with Myndlift
        </div>
        <div className={styles.start_trial_banner_description}>
          <div>
            Myndlift allows you to offer seamless neurofeeedback with fully
            customizable training protocols,
          </div>
          <div>
            a comprehensive clinical dashboard, and a consumer-grade app.
          </div>
        </div>
        <div
          className={styles.start_trial_banner_button}
          style={isFromDashboard ? { fontSize: 13 } : null}
          onClick={() => {
            const planInfoStorage = JSON.parse(
              // eslint-disable-next-line no-undef
              localStorage.getItem('planInfo')
            );
            window.open(
              `https://calendly.com/myndlift-demo/myndlift-demo-v2?month=2022-06&utm_campaign=cliniccheckout&utm_source=dashboard&utm_content=${
                sessionStorage.userId
                  ? sessionStorage.userId
                  : planInfoStorage.sessionId
              }`,
              '_blank'
            );
            logClinicRegistrationEvent({
              name: 'contact_sales_clicked',
              data: {
                session_id: planInfoStorage.sessionId,
                isNF: false,
                from_where: isFromDashboard ? 'dashboard' : 'select_plan',
              },
            });
          }}
        >
          CONTACT SALES
        </div>
      </div>
      <img src={NeuroPromoPng} style={{ width: 163, height: 177 }} />
    </div>
  );
};

StartTrialBannerBase.propTypes = exact({
  isNf: PropTypes.bool.isRequired,
  isNfAdded: PropTypes.bool,
  setIsNfAdded: PropTypes.func,
  isFromDashboard: PropTypes.bool,
  isAnnual: PropTypes.bool,
  nfFlagPendingDeactivation: PropTypes.number,
  NFPackages: PropTypes.object,
  isInactive: PropTypes.bool,
  addonGroupId: PropTypes.string,
});

export const StartTrialBanner = withEntryComponent(
  React.memo(StartTrialBannerBase)
);
StartTrialBanner.displayName = 'StartTrialBanner';

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CreateProgramForUserWrapper_size_17{font-size:17px}.CreateProgramForUserWrapper_size_10{font-size:10px}.CreateProgramForUserWrapper_size_11{font-size:11px}.CreateProgramForUserWrapper_size_13{font-size:13px}.CreateProgramForUserWrapper_size_15{font-size:15px}.CreateProgramForUserWrapper_size_14{font-size:14px}.CreateProgramForUserWrapper_size_16{font-size:16px}.CreateProgramForUserWrapper_size_12{font-size:12px}.CreateProgramForUserWrapper_extra_header{font-size:18px;font-weight:500;color:#000000}.CreateProgramForUserWrapper_header{font-size:17px;font-weight:500;color:#000000}.CreateProgramForUserWrapper_header_only_font_size{font-size:17px;font-weight:500}.CreateProgramForUserWrapper_sub_header{font-size:13px;color:#878787}.CreateProgramForUserWrapper_sub_header_black{font-size:13px;color:black}.CreateProgramForUserWrapper_sub_header_only_font_size{font-size:13px}.CreateProgramForUserWrapper_paragraph_only_font_size{font-size:12px}.CreateProgramForUserWrapper_tab_font_size{font-size:13px}.CreateProgramForUserWrapper_pop_out_header{font-size:20px}.CreateProgramForUserWrapper_title{font-size:15px;font-weight:500;color:#484848}.CreateProgramForUserWrapper_title_black{font-size:15px;font-weight:500;color:black}.CreateProgramForUserWrapper_title_only_font_size{font-size:15px}.CreateProgramForUserWrapper_paragraph{font-size:12px;color:#484848}.CreateProgramForUserWrapper_btn{font-size:14px}.CreateProgramForUserWrapper_wrapped_component_wrapper{display:flex;align-items:center}.CreateProgramForUserWrapper_hidden{display:none}\n", ""]);

// Exports
exports.locals = {
	"size_17": "CreateProgramForUserWrapper_size_17",
	"size_10": "CreateProgramForUserWrapper_size_10",
	"size_11": "CreateProgramForUserWrapper_size_11",
	"size_13": "CreateProgramForUserWrapper_size_13",
	"size_15": "CreateProgramForUserWrapper_size_15",
	"size_14": "CreateProgramForUserWrapper_size_14",
	"size_16": "CreateProgramForUserWrapper_size_16",
	"size_12": "CreateProgramForUserWrapper_size_12",
	"extra_header": "CreateProgramForUserWrapper_extra_header",
	"header": "CreateProgramForUserWrapper_header",
	"header_only_font_size": "CreateProgramForUserWrapper_header_only_font_size",
	"sub_header": "CreateProgramForUserWrapper_sub_header",
	"sub_header_black": "CreateProgramForUserWrapper_sub_header_black",
	"sub_header_only_font_size": "CreateProgramForUserWrapper_sub_header_only_font_size",
	"paragraph_only_font_size": "CreateProgramForUserWrapper_paragraph_only_font_size",
	"tab_font_size": "CreateProgramForUserWrapper_tab_font_size",
	"pop_out_header": "CreateProgramForUserWrapper_pop_out_header",
	"title": "CreateProgramForUserWrapper_title",
	"title_black": "CreateProgramForUserWrapper_title_black",
	"title_only_font_size": "CreateProgramForUserWrapper_title_only_font_size",
	"paragraph": "CreateProgramForUserWrapper_paragraph",
	"btn": "CreateProgramForUserWrapper_btn",
	"wrapped_component_wrapper": "CreateProgramForUserWrapper_wrapped_component_wrapper",
	"hidden": "CreateProgramForUserWrapper_hidden"
};
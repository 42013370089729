import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper, Tabs, Tab, Popover, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import dayJS from 'dayjs';
import {
  // formatSecondsToMMSS,
  defensiveThrow,
  postToFunctionsWithDefaultData,
  getCurrentURLParams,
} from '../../utils/utils';
import styles from './UserPage.scss';
import { UserInfo } from './UserInfo/UserInfo';
import { UserLinks } from './UserLinks/UserLinks';
import {
  AddClientModal,
  addClientSteps,
} from '../AddClientModal/AddClientModal';

import { FeatureNotAllowedDialog } from '../FeatureNotAllowedDialog/FeatureNotAllowedDialog';
import {
  promptForDeleteCustomer,
  promptForScheduleDeleteCustomer,
  onResetPassword,
  onDownloadDataAsCSV,
  userPageTabs,
} from './userPageUtils';
import { SessionsTab } from './SessionsTab/SessionsTab';
import { AssessmentsTab } from './AssessmentsTab/AssessmentsTab';
import { ReportsTab } from './ReportsTab/ReportsTab';
import { useTranslation } from '../../Core/hooks/useTranslation';
import {
  SwitchToContinuityDialog,
  SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS,
} from './SwitchToContinuityDialog/SwitchToContinuityDialog';
import { DAY_JS_DATE_FORMATS } from '../../utils/constants';
import {
  PACKAGES_TYPES,
  FORMATTED_PACKAGES_TYPES,
} from '../../models/packages/packages';
import { getQuestionnairesQuestions } from '../../models/symptom-tracker/symptom-tracker';
import { CustomDialogSuccessMessage } from '../../Core/Components/CustomDialogSuccessMessage/CustomDialogSuccessMessage';
import { DisorderChip } from '../../Core/Components/DisorderChip/DisorderChip';
import {
  isThereNoProgramDeployed,
  switchCustomerToNewSymptomTracker,
} from '../../models/customer/customer';
import { InsightsTab } from './InsightsTab/InsightsTab';
import { BehaviouralProgressTab } from './BehaviouralProgressTab/BehaviouralProgressTab';
import { EntriesTab } from './EntriesTab/EntriesTab';
import { CallLogs } from './CallLogs/CallLogs';
import { SESSION_STATUSES } from '../../models/sessions/session';
import { ModalFooter } from '../../Core/Components/Modal/ModalFooter';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { getAge } from '../../models/person/person';
import { CoppaPopUp } from '../AddClientModal/CoppaPopUp/CoppaPopUp';
import { AssignedUsers } from '../AssignedUsers/AssignedUsers';

const userPageLogger = new MyEventWrapper('user_page');

const UserPageBase = ({
  customerId,
  userInfo,
  trainingSessions,
  brainDisorders,
  customerBrainDisorders,
  isClinicAllowedToUseYoutubeSearch,
  isYoutubeSearchEnabledForClinic,
  isYoutubeSearchEnabledForCustomer,
  setIsYoutubeSearchEnabledForCustomer,
  deployedProgram,
  protocolsConfig,
  allSymptomTrackerQuestions,
  customerSymptomTrackerQuestions,
  symptomsTrackingAnswers,
  onDownloadSymptomsTrackingDataAsCSV,
  onDownloadOverallSymptomsTrackingDataAsCSV,
  numberOfCompletedSessions,
  headsetDetails,
  clinicCurrentPackage,
  creditForContinuityHeadset,
  onHeadsetDetailsUpdated,
  isContinuityEnabled,
  allQuestionnaires,
  allTags,
  selectedTags,
  deployedQuestionnaires,
  deployedAssessment,
  setUserInfo,
  isCustomerAppCompatiableWithSymptomQuestionnaires,
  assessmentStagesInfo,
  sessionStatus,
  isUnseenEntries,
  sessionAnalysis,
  isStreamingEnabledForClinic,
  isStreamingEnabledForCustomer,
  setIsStreamingEnabledForCustomer,
  setIsInternalStreamingEnabledForCustomer,
  customerNotes,
  setCustomerNotes,
  addCustomerNote,
  removeCustomerNote,
  minsUsage,
  isRapidSessionEnabledForClinic,
  setIsRapidSessionEnabledForCustomer,
  isRapidSessionEnabledForCustomer,
  isAlphaPeakEnabledForClinic,
  isAlphaPeakEnabledForCustomer,
  setIsAlphaPeakEnabledForCustomer,
  alphaWeightedPeak,
  isAlphaPeakGraphSeen,
  setIsAlphaPeakGraphSeen,
  isAlphaPeakBaseline,
  allQuestionnairesOnlyClinic,
  patientMesseges,
  onNotifyCustomerOnMessegesChange,
  notifyAutomationsMessagesByEmail,
  customerCoppaConsent,
  setCustomerCoppaConsent,
  coppaConfig,
  clinicTeamMembers,
}) => {
  const userHasTeamMembers =
    clinicTeamMembers &&
    Object.keys(clinicTeamMembers).find(id => clinicTeamMembers[id].userId);

  const allQuestionnairesQuestions = useMemo(
    () =>
      getQuestionnairesQuestions({
        questionnaires: allQuestionnaires,
      }),
    [allQuestionnaires]
  );
  const t = useTranslation();

  const { tab } = getCurrentURLParams();
  const [selectedMainTab, setSelectedMainTab] = useState(
    (tab && userPageTabs[tab]) || userPageTabs.overview
  );

  const [notesPopoverAnchorElement, setNotesPopoverAnchorElement] = useState(
    null
  );

  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [addClientModalInitialStep, setAddClientModalInitialStep] = useState(
    undefined
  );
  const [addClientModalFinalStep, setAddClientModalFinalStep] = useState(
    undefined
  );
  const [
    mightEnableQuestionnairesInScheduleAssessmentDialog,
    setMightEnableQuestionnairesInScheduleAssessmentDialog,
  ] = useState(false);
  const [
    questionnairesShouldBeEnabledInScheduleAssessmentDialog,
    setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog,
  ] = useState(false);

  const [shouldHideBack, setShouldHideBack] = useState(false);

  const [isCoppaGetConsentPopUpOpen, setIsCoppaGetConsentPopUpOpen] = useState(
    false
  );
  const [
    isCoppaDeleteClientDataPopUpOpen,
    setIsCoppaDeleteClientDataPopUpOpen,
  ] = useState(false);

  const [dataDeletionTimeStamp, setDataDeletionTimeStamp] = useState(null);
  const [isSetForDeletion, setIsSetForDeletion] = useState(false);

  const [isTeamMembersMenuOpen, setIsTeamMembersMenuOpen] = useState(false);

  const closeAddClientModal = useCallback(() => {
    userPageLogger.log('on_close_edit_user_information_modal');
    setIsAddClientModalOpen(false);
    setAddClientModalInitialStep(undefined);
    setAddClientModalFinalStep(undefined);
    setMightEnableQuestionnairesInScheduleAssessmentDialog(false);
    setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog(false);
    setShouldHideBack(false);
  }, []);

  const onEditUserInformation = useCallback(() => {
    userPageLogger.log('on_open_edit_user_information_modal');
    if (userInfo.shouldUseNewSymptomTracker === true) {
      setAddClientModalFinalStep(addClientSteps.selectTags);
    } else {
      setAddClientModalFinalStep(addClientSteps.createAccount);
    }
    setIsAddClientModalOpen(true);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo.clinicianSetUpRequired) {
      setAddClientModalFinalStep(addClientSteps.digitalCare);
      setIsAddClientModalOpen(true);
      setShouldHideBack(true);
    }
  }, [userInfo]);

  useEffect(() => {
    const checkAgeForConsent = () => {
      const userBirthday = userInfo.privateDetails.dateOfBirth;
      const age = getAge(userBirthday);
      const complianceAge = (coppaConfig && coppaConfig.complianceAge) || 13;
      if (age < complianceAge) {
        if (customerCoppaConsent) {
          if (customerCoppaConsent.consentGiven === false) {
            if (customerCoppaConsent?.scheduledDeletionTimestamp) {
              setIsCoppaGetConsentPopUpOpen(false);
              setIsCoppaDeleteClientDataPopUpOpen(true);
              setDataDeletionTimeStamp(
                customerCoppaConsent.scheduledDeletionTimestamp
              );
              setIsSetForDeletion(true);
            }
          } else {
            setIsCoppaDeleteClientDataPopUpOpen(false);
            setIsCoppaGetConsentPopUpOpen(false);
            setIsSetForDeletion(false);
            setDataDeletionTimeStamp(null);
          }
        } else {
          setIsCoppaDeleteClientDataPopUpOpen(false);
          setIsCoppaGetConsentPopUpOpen(true);
        }
      }
    };

    const timer = setTimeout(() => {
      checkAgeForConsent();
    }, 250);

    return () => clearTimeout(timer);
  }, [customerCoppaConsent, userInfo, coppaConfig]);

  const [
    isSwitchToNewSymptomTrackerDialogOpen,
    setIsSwitchToNewSymptomTrackerDialogOpen,
  ] = useState(false);

  const switchToNewSymptomTracker = useCallback(async () => {
    userPageLogger.log('on_customer_switched_to_new_symptom_tracker', {
      customerId,
    });
    await switchCustomerToNewSymptomTracker(customerId);
    setAddClientModalInitialStep(addClientSteps.selectTags);
    setIsAddClientModalOpen(true);
  }, [customerId]);

  const onSwitchToNewSymptomTrackerDialogPrimaryBtnClick = useCallback(async () => {
    await switchToNewSymptomTracker();
    // make better smooth experience for closing dialog
    setTimeout(() => {
      setIsSwitchToNewSymptomTrackerDialogOpen(false);
    }, 100);
  }, [switchToNewSymptomTracker]);

  const onSwitchToNewSymptomTrackerDialogSecondaryBtnClick = useCallback(async () => {
    userPageLogger.log('on_customer_cancel_switch_to_new_symptom_tracker', {
      customerId,
    });
    setIsSwitchToNewSymptomTrackerDialogOpen(false);
    window.open('createQuestionnaire.html', '_self');
  }, [customerId]);

  const onSwitchToNewSymptomTrackerDialogClose = useCallback(async () => {
    userPageLogger.log('on_customer_close_switch_to_new_symptom_tracker', {
      customerId,
    });
    setIsSwitchToNewSymptomTrackerDialogOpen(false);
  }, [customerId]);

  const onSetupSymptomTracker = useCallback(() => {
    if (userInfo.shouldUseNewSymptomTracker === undefined) {
      setIsSwitchToNewSymptomTrackerDialogOpen(true);
    } else if (userInfo.shouldUseNewSymptomTracker === false) {
      window.open('createQuestionnaire.html', '_self');
    } else if (userInfo.shouldUseNewSymptomTracker === true) {
      userPageLogger.log('on_customer_setup_new_symptom_tracker', {
        customerId,
      });
      setAddClientModalInitialStep(addClientSteps.selectQuestionnaires);
      if (
        deployedAssessment &&
        (deployedAssessment.beforeNextSession ||
          deployedAssessment.recurring) &&
        (deployedAssessment.selectedStages || []).includes(
          assessmentStagesInfo.ids.QUESTIONNAIRES
        )
      ) {
        setAddClientModalFinalStep(addClientSteps.selectQuestionnaires);
      } else {
        setAddClientModalFinalStep(addClientSteps.digitalCare);
      }
      setIsAddClientModalOpen(true);
    }
  }, [deployedAssessment, userInfo, customerId, assessmentStagesInfo]);

  const onSetupQuestionnaires = useCallback(() => {
    userPageLogger.log('on_setup_questionnaires', {
      customerId,
    });
    setAddClientModalInitialStep(addClientSteps.selectQuestionnaires);
    setAddClientModalFinalStep(addClientSteps.selectQuestionnaires);
    setMightEnableQuestionnairesInScheduleAssessmentDialog(true);
    setIsAddClientModalOpen(true);
  }, [customerId]);

  const [
    isSwitchToContinuityDialogOpen,
    setIsSwitchToContinuityDialogOpen,
  ] = useState(false);
  const [
    switchToDialogRenderedStep,
    setSwitchToContinuityDialogRenderedStep,
  ] = useState(SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.INTRO);

  const onSwitchToContinuity = useCallback(() => {
    userPageLogger.log('on_switch_to_continuity_click');
    setIsSwitchToContinuityDialogOpen(true);
  }, []);

  const dispatchUserToContinuity = useCallback(async () => {
    try {
      const dispatchResult = await postToFunctionsWithDefaultData({
        path: 'clinicDispatchUser',
        data: {
          headsetId: headsetDetails.lastUsedHeadsetId,
          shouldDispatch: true,
          patientId: customerId,
        },
      });

      if (dispatchResult.result) {
        onHeadsetDetailsUpdated();
        setSwitchToContinuityDialogRenderedStep(
          SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_SCHEDULED
        );
      } else {
        throw new Error('Dispatch failed');
      }
    } catch (err) {
      setSwitchToContinuityDialogRenderedStep(
        SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_FAILED
      );
    }
  }, [customerId, headsetDetails, onHeadsetDetailsUpdated]);

  const closeSwitchToContinuityDialog = () => {
    setIsSwitchToContinuityDialogOpen(false);
    setTimeout(() => {
      setSwitchToContinuityDialogRenderedStep(
        SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.INTRO
      );
    }, 500);
  };

  const onSwitchToContinuityDialogPrimaryBtnClick = useCallback(() => {
    userPageLogger.log('on_switch_to_continuity_dialog_primary_click', {
      renderedStep: switchToDialogRenderedStep,
    });
    switch (switchToDialogRenderedStep) {
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.INTRO:
        return setSwitchToContinuityDialogRenderedStep(
          SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.HOW_IT_WORKS
        );
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.HOW_IT_WORKS:
        return setSwitchToContinuityDialogRenderedStep(
          SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.BILLING
        );
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.BILLING:
        setSwitchToContinuityDialogRenderedStep(
          SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.LOADER
        );
        return dispatchUserToContinuity();
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_SCHEDULED:
      case SWITCH_TO_CONTINUITY_DIALOG_RENDERED_STEPS.SWITCH_FAILED:
        return closeSwitchToContinuityDialog();
      default:
        return defensiveThrow({
          error: new Error('Unknown step for SwitchToContinuity dialog'),
        });
    }
  }, [dispatchUserToContinuity, switchToDialogRenderedStep]);

  const onSwitchToContinuityDialogSecondaryBtnClick = useCallback(() => {
    userPageLogger.log('on_switch_to_continuity_dialog_secondary_click', {
      renderedStep: switchToDialogRenderedStep,
    });
    closeSwitchToContinuityDialog();
  }, [switchToDialogRenderedStep]);

  const onNotesBtnClick = useCallback(event => {
    userPageLogger.log('on_view_notes');
    setNotesPopoverAnchorElement(event.currentTarget);
  }, []);

  const onNotesPopoverClose = useCallback(() => {
    userPageLogger.log('on_close_notes');
    setNotesPopoverAnchorElement(null);
  }, []);

  const onSelectedMainTabChange = useCallback(
    (event, newTab) => {
      const tabNames = {
        overview: 'overview',
        sessions: 'training',
        'behavioural progress': 'content',
        insights: 'insights',
        assessments: 'assessments',
        entries: 'entries',
      };

      userPageLogger.log('on_main_tab_change', {
        newTab: tabNames[newTab],
        hasNotification: newTab === 'entries' ? isUnseenEntries : null,
      });
      setSelectedMainTab(newTab);
    },
    [setSelectedMainTab, isUnseenEntries]
  );

  const onGoToLiveSession = useCallback(
    (shouldOpenNewTab = false) => {
      sessionStorage.customerId = customerId;
      sessionStorage.customerInfo = JSON.stringify(userInfo);
      window.open('/liveSession.html', shouldOpenNewTab ? '_blank' : '_self');
    },
    [customerId, userInfo]
  );

  const [
    isFeatureNotAllowedDialogOpen,
    setIsFeatureNotAllowedDialogOpen,
  ] = useState(false);

  /* --------------------- training tab states and calculation logic ------------------ */

  const [completeSessionsTableData, setCompleteSessionsTableData] = useState(
    []
  );
  const [currentTableData, setCurrentTableData] = useState([]);
  const [isPopoverOpen, setIsPopOverOpen] = useState(false);
  const [sessionsFilterProtocols, setSessionsFilterProtocols] = useState([]);
  const [
    sessionsFilterSelectedProtocls,
    setSessionsFilterSelectedProtocls,
  ] = useState([]);

  const [
    sessionsFilterSavedSelectedProtocols,
    setSessionsFilterSavedSelectedProtocls,
  ] = useState([]);

  const [
    isFilterSelectToggleSwitchChecked,
    setIsFilterSelectToggleSwitchChecked,
  ] = useState(true);

  const [
    isFilterSelectButtonDisabled,
    setIsFilterSelectButtonDisabled,
  ] = useState(true);

  const [isSessionsTableFiltered, setIsSessionsTableFiltered] = useState(false);

  const [
    showScheduledDeletionCanceledSuccessfully,
    setShowScheduledDeletionCanceledSuccessfully,
  ] = useState(false);

  useEffect(() => {
    const filteredSessionsFilterProtocols = Object.entries(sessionAnalysis)
      .reverse()
      .filter(
        ([, session]) => !session.isDeleted && Object.keys(session).length > 1
      )
      .map(session => {
        return { protocolName: session[1].protocol, checked: true };
      });
    const sessionsFilterProtocolsNoRedundency = [];
    filteredSessionsFilterProtocols.forEach(protocol => {
      if (
        !sessionsFilterProtocolsNoRedundency.find(
          item => protocol.protocolName === item.protocolName
        )
      ) {
        sessionsFilterProtocolsNoRedundency.push(protocol);
      }
    });
    setSessionsFilterSelectedProtocls(sessionsFilterProtocolsNoRedundency);
    if (sessionsFilterSavedSelectedProtocols.length === 0) {
      const tempArr = sessionsFilterProtocolsNoRedundency.map(item =>
        Object.assign({}, item)
      );
      setSessionsFilterProtocols(tempArr);
      setSessionsFilterSavedSelectedProtocls(tempArr);
    }
  }, [protocolsConfig, sessionAnalysis]);

  const trainingSessionsReversedEntries = Object.entries(sessionAnalysis)
    .reverse()
    .filter(
      ([, session]) => !session.isDeleted && Object.keys(session).length > 1
    );

  const rowsKeys = trainingSessionsReversedEntries.map(entryArr => entryArr[0]);

  useEffect(() => {
    setCompleteSessionsTableData(trainingSessionsReversedEntries);
    setCurrentTableData(trainingSessionsReversedEntries);
  }, []);

  /* --------------------------------- */

  // const totalDurationOfSessions =
  //   trainingSessions &&
  //   Object.values(trainingSessions).reduce(
  //     (acc, cur) => acc + cur.totalDuration || 0,
  //     0
  //   );

  const totalDurationOfSessionsInMinutes = minsUsage && parseInt(minsUsage, 10);

  const onDeleteNow = useCallback(() => {
    userPageLogger.log('on_trying_to_delete_user_now', {
      deleted_user_ids: sessionStorage.customerId,
    });
    promptForDeleteCustomer({
      fullName: `${userInfo.firstName} ${userInfo.lastName}`,
    });
  }, [userInfo]);

  const onDeleteUser = useCallback(() => {
    userPageLogger.log('on_trying_to_delete_user', {
      deleted_user_ids: sessionStorage.customerId,
    });
    const actionTimestamp = Date.now();
    promptForScheduleDeleteCustomer({
      fullName: `${userInfo.firstName} ${userInfo.lastName}`,
      path: `deployedSession/${sessionStorage.userId}/${customerId}/scheduledForDeletion`,
      actionTimestamp,
    });
  }, [userInfo]);

  const onNotifyByEmailChange = (event, isChecked) => {
    userPageLogger.log('on_notify_by_email_change', { isChecked });
    database
      .ref(
        `deployedSession/${sessionStorage.userId}/${customerId}/notifyByEmail`
      )
      .set(isChecked);
  };

  const disabledReasonForSwitchToContinuityBtn = (() => {
    if (headsetDetails.upcomingDispatchedHeadset) {
      return 'This client will switch to Continuity on the next billing cycle';
    }

    if (headsetDetails.lastUsedHeadsetId === null) {
      return 'Users can be switched to Continuity only after they’ve completed at least one session.';
    }
    if (userInfo.isDemo) {
      return 'Demo users cannot be switched to Continuity.';
    }
    if (
      [
        PACKAGES_TYPES.BLOCKED_PACKAGE,
        PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE,
        PACKAGES_TYPES.TRIAL_PACKAGE,
      ].includes(clinicCurrentPackage.packageType)
    ) {
      const formattedPackage =
        FORMATTED_PACKAGES_TYPES[clinicCurrentPackage.packageType];
      return `${formattedPackage} accounts can not switch users to continuity`;
    }

    if (isThereNoProgramDeployed(deployedProgram)) {
      return 'This user needs to have an active training program in order to switch to Continuity. Please create one and try again.';
    }
    return null;
  })();

  const disableReasonForDownloadAsCSV = (() => {
    if (
      Object.values(trainingSessions || {}).every(
        session => session.full === false
      )
    ) {
      return 'There is no data to download';
    }
    if (headsetDetails.isDispatched) {
      return 'To download their data, switch the headset back to Normal Mode';
    }

    return null;
  })();

  const disableReasonForGoToLiveSession =
    sessionStatus === null ||
    sessionStatus === SESSION_STATUSES.FINISHED ||
    sessionStatus === SESSION_STATUSES.CANCELED
      ? 'To go to live session, user needs to have an open program'
      : null;

  const [isUser] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${sessionStorage.customerId}`,
  });

  // const [isUserInfoArchived] = useFirebaseDB({
  //   path: `/archive/userInfo/${sessionStorage.customerId}`,
  // });

  const [
    notificationsAlerts,
    editNotificationsAlerts,
    // eslint-disable-next-line no-unused-vars
    // addNotificationsAlerts,
    // deleteNotificationsAlerts,
  ] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${sessionStorage.customerId}/notifications`,
  });

  const [notificationsData] = useFirebaseDB({
    path: `logs/notifications/${sessionStorage.customerId}`,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${sessionStorage.userId}/nfAddOnPlanId`,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/packageType`,
  });

  const [isUserArchived] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${sessionStorage.customerId}/archived`,
  });

  const [lagoEnabledClinic] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${sessionStorage.userId}/isEnabled`,
  });

  const [lagoEnabledUser] = useFirebaseDB({
    path: `lago/educational/users/${sessionStorage.customerId}/isEnabled`,
  });

  const [usersCategories] = useFirebaseDB({
    path: `lago/educational/users/${sessionStorage.customerId}/journeyConfig/categories`,
  });

  const [clinicCategories] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${sessionStorage.userId}/clinicFeatureDefaults/categories`,
  });

  const [compactLessonsReplica] = useFirebaseDB({
    path: 'lago/educational/defaults/compactLessonsReplica',
  });

  const [exploreData] = useFirebaseDB({
    path: 'lago/educational/defaults/resources/published',
  });

  const [defaultCategories] = useFirebaseDB({
    path: 'lago/educational/defaults/categories',
  });

  const [notifyByEmail] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${customerId}/notifyByEmail`,
  });

  // eslint-disable-next-line no-unused-vars
  const [assignedTo, setAssignedTo] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${customerId}/assignedTo`,
    defaultValue: 0,
  });

  const [
    isUserScheduledForDeletion,
    setIsUserScheduledForDeletion,
  ] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${customerId}/scheduledForDeletion`,
  });

  const [scheduleDeletionDays] = useFirebaseDB({
    path: 'config/scheduleDeletionDays',
    defaultValue: 30,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  let isLagoEnabledClinic = false;
  if (lagoEnabledClinic || lagoEnabledClinic === null) {
    isLagoEnabledClinic = true;
  }
  let isLagoEnabledUser = false;
  if (lagoEnabledUser || lagoEnabledUser === null) {
    isLagoEnabledUser = true;
  }
  const isLagoEnabled = isLagoEnabledUser && isLagoEnabledClinic;

  const calcCategoriesStatus = selectedItemId => {
    let categoriesToCheck = null;
    if (
      compactLessonsReplica[selectedItemId] &&
      exploreData[compactLessonsReplica[selectedItemId].resourceId]
    ) {
      categoriesToCheck =
        exploreData[compactLessonsReplica[selectedItemId].resourceId].journals[
          selectedItemId
        ].categoryIds;
    }
    if (!categoriesToCheck) return '';
    if (!usersCategories && !clinicCategories) {
      return '';
    }
    if (!usersCategories && clinicCategories) {
      let status = '';
      categoriesToCheck.forEach(categoryId => {
        if (
          clinicCategories[categoryId] &&
          clinicCategories[categoryId].isEnabled === false
        ) {
          status += `, ${defaultCategories[categoryId].name.en}`;
        }
      });
      return status;
    }

    if (usersCategories && !clinicCategories) {
      let status = '';
      categoriesToCheck.forEach(categoryId => {
        if (
          usersCategories[categoryId] &&
          usersCategories[categoryId].isEnabled === false
        ) {
          status += `, ${defaultCategories[categoryId].name.en}`;
        }
      });
      return status;
    }

    let status = '';
    categoriesToCheck.forEach(categoryId => {
      if (
        (usersCategories[categoryId] &&
          usersCategories[categoryId].isEnabled === false) ||
        (clinicCategories[categoryId] &&
          clinicCategories[categoryId].isEnabled === false)
      ) {
        status += `, ${defaultCategories[categoryId].name.en}`;
      }
    });
    return status;
  };

  const isExpired =
    deployedProgram &&
    deployedProgram.trainingExpiration &&
    deployedProgram.trainingExpiration.expiration &&
    deployedProgram.trainingExpiration.expiration < Date.now()
      ? dayJS(deployedProgram.trainingExpiration.expiration).format(
          DAY_JS_DATE_FORMATS.american
        )
      : '';

  const renderAddClientModal = () => {
    const renderCustomizedAddClientModalSuccessMessage =
      // eslint-disable-next-line react/prop-types
      ({ assessmentOptions, selectedQuestionnaires }) => {
        const isAssessmentEnabled =
          // eslint-disable-next-line react/prop-types
          assessmentOptions.shouldBeTrackingBeforeFirstSession ||
          // eslint-disable-next-line react/prop-types
          assessmentOptions.shouldBeTrackingPerMinutes;

        if (
          addClientModalFinalStep === addClientSteps.selectQuestionnaires &&
          (isAssessmentEnabled ||
            mightEnableQuestionnairesInScheduleAssessmentDialog)
        ) {
          return (
            <CustomDialogSuccessMessage
              headerText="Questionnaires updated!"
              content={
                <p style={{ margin: 0 }}>
                  {// eslint-disable-next-line react/prop-types
                  selectedQuestionnaires.length === 0 ? (
                    <>{t('no-questionnaires-will-be-shown')}</>
                  ) : (
                    <>
                      {t(
                        'user-will-be-shown-updated-questionnaires-on-next-assessment-checkpoint'
                      )}
                    </>
                  )}
                  <ModalFooter
                    primaryBtnContent={t('done')}
                    onPrimaryBtnClick={() => {
                      closeAddClientModal();
                    }}
                  />
                </p>
              }
            />
          );
        }

        return (
          <CustomDialogSuccessMessage
            headerText={
              isAssessmentEnabled
                ? t('progress-tracking-is-enabled')
                : t('progress-tracking-is-disabled')
            }
            content={
              <p style={{ margin: 0 }}>
                {isAssessmentEnabled ? (
                  <>{t('user-will-be-asked-to-complete-assessment')}</>
                ) : (
                  <>{t('user-will-not-be-asked-to-complete-assessment')}</>
                )}
                <ModalFooter
                  primaryBtnContent={t('done')}
                  onPrimaryBtnClick={() => {
                    closeAddClientModal();
                  }}
                />
              </p>
            }
          />
        );
      };

    const updateQuestionnairesShouldBeEnabledIfNeeded = ({
      selectedQuestionnaires,
    }) => {
      if (
        mightEnableQuestionnairesInScheduleAssessmentDialog &&
        // eslint-disable-next-line react/prop-types
        selectedQuestionnaires.length > 0
      ) {
        setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog(true);
      }
    };

    const renderSuccessMessage =
      addClientModalInitialStep === addClientSteps.selectQuestionnaires
        ? props => {
            updateQuestionnairesShouldBeEnabledIfNeeded(props);
            return (
              <div style={{ padding: '20px' }}>
                {' '}
                {renderCustomizedAddClientModalSuccessMessage(props)}
              </div>
            );
          }
        : undefined;

    const assessmentSelectedStages =
      (deployedAssessment && deployedAssessment.selectedStages) ||
      Object.values(assessmentStagesInfo.ids);
    const initialAssessmentOptions = deployedAssessment
      ? {
          shouldBeTrackingPerMinutes: deployedAssessment.recurring,
          shouldBeTrackingBeforeFirstSession:
            deployedAssessment.beforeNextSession,
          perMinutesTracking: deployedAssessment.afterTrainingDuration,
          isSymptomQuestionnaireEnabled: assessmentSelectedStages.includes(
            assessmentStagesInfo.ids.QUESTIONNAIRES
          ),
          isCPTEnabled: assessmentSelectedStages.includes(
            assessmentStagesInfo.ids.CPT
          ),
          isEEGEnabled: Object.values(
            assessmentStagesInfo.eegIds
          ).some(sensingRegionId =>
            assessmentSelectedStages.includes(sensingRegionId)
          ),
        }
      : undefined;

    return (
      <AddClientModal
        isEditingMode
        isModalOpen
        userId={sessionStorage.customerId}
        userInfo={userInfo}
        brainDisorders={brainDisorders}
        closeModal={closeAddClientModal}
        allQuestionnaires={allQuestionnairesOnlyClinic}
        allTags={allTags}
        initialSelectedTags={selectedTags}
        initialSelectedQuestionnaires={deployedQuestionnaires}
        shouldUseNewSymptomTracker={userInfo.shouldUseNewSymptomTracker}
        initialAssessmentOptions={initialAssessmentOptions}
        initialSelectedAssessmentRegions={assessmentSelectedStages.filter(
          stage => Object.values(assessmentStagesInfo.eegIds).includes(stage)
        )}
        initialStep={addClientModalInitialStep}
        finalStep={addClientModalFinalStep}
        isCustomerAppCompatiableWithSymptomQuestionnaires={
          isCustomerAppCompatiableWithSymptomQuestionnaires
        }
        renderSuccessMessage={renderSuccessMessage}
        assessmentStagesInfo={assessmentStagesInfo}
        nfFlag={nfFlag}
        shouldHideBack={shouldHideBack}
      />
    );
  };

  const getTimeRemaining = (
    timestamp,
    { getFullTime = false, roundUp = false } = {}
  ) => {
    const currentTimestamp = Date.now(); // current timestamp in milliseconds
    const futureTimestamp = timestamp; // Future timestamp provided in milliseconds

    const isRoundup = getFullTime ? false : roundUp;

    // Calculate the difference in milliseconds
    const differenceInMilliSeconds = futureTimestamp - currentTimestamp;

    // if (differenceInMilliSeconds <= 0) {
    //   return 'The timestamp is in the past';
    // }

    // Convert milliseconds to days, hours, minutes and seconds
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const millisecondsInAnHour = 60 * 60 * 1000;
    const millisecondsInAMinute = 60 * 1000;
    const millisecondsInASecond = 1000;

    let daysRemaining = Math.floor(
      differenceInMilliSeconds / millisecondsInADay
    );
    let hoursRemaining = Math.floor(
      (differenceInMilliSeconds % millisecondsInADay) / millisecondsInAnHour
    );
    let minutesRemaining = Math.floor(
      (differenceInMilliSeconds % millisecondsInAnHour) / millisecondsInAMinute
    );
    let secondsRemaining = Math.floor(
      (differenceInMilliSeconds % millisecondsInAMinute) / millisecondsInASecond
    );

    // Rounding logic if isRoundup is true
    if (isRoundup) {
      // Round up only if there is any remaining portion
      if (secondsRemaining > 0 && minutesRemaining >= 1) {
        minutesRemaining += 1;
        secondsRemaining = 0; // Reset seconds after rounding up minutes
      }
      if (minutesRemaining > 0 && hoursRemaining >= 1) {
        hoursRemaining += 1;
        minutesRemaining = 0; // Reset minutes after rounding up hours
      }
      if (hoursRemaining > 0 && daysRemaining >= 1) {
        daysRemaining += 1;
        hoursRemaining = 0; // Reset hours after rounding up days
      }
    }

    let toReturn;

    if (daysRemaining >= 1) {
      toReturn = `${daysRemaining} day${daysRemaining > 1 ? 's' : ''}`;
      if (getFullTime) {
        toReturn += ` ${hoursRemaining} hour${
          hoursRemaining > 1 ? 's' : ''
        } ${minutesRemaining} minute${
          minutesRemaining > 1 ? 's' : ''
        } ${secondsRemaining} second${secondsRemaining > 1 ? 's' : ''}`;
      }
    } else if (hoursRemaining >= 1) {
      toReturn = `${hoursRemaining} hour${hoursRemaining > 1 ? 's' : ''}`;
      if (getFullTime) {
        toReturn = `0 days ${hoursRemaining} hour${
          hoursRemaining > 1 ? 's' : ''
        } ${minutesRemaining} minute${
          minutesRemaining > 1 ? 's' : ''
        } ${secondsRemaining} second${secondsRemaining > 1 ? 's' : ''}`;
      }
    } else if (minutesRemaining >= 1) {
      toReturn = `${minutesRemaining} minute${minutesRemaining > 1 ? 's' : ''}`;
      if (getFullTime) {
        toReturn = `0 days 0 hours ${minutesRemaining} minute${
          minutesRemaining > 1 ? 's' : ''
        } ${secondsRemaining} second${secondsRemaining > 1 ? 's' : ''}`;
      }
    } else {
      if (getFullTime) {
        toReturn = ` ${secondsRemaining} second${
          secondsRemaining > 1 ? 's' : ''
        }`;
      } else {
        toReturn = 'Less than a minute';
      }
    }
    return toReturn;
  };

  const getConsentDefaults = {
    dialogContent:
      "I received parental consent to collect and handle this child's information, as required by law.",
    primaryBtnContent: 'YES, I CONFIRM',
    secondaryBtnContent: 'NO, I HAVEN’T',
  };

  const deleteClientDataDefaults = {
    dialogContent: `Client data will be deleted in ${(dataDeletionTimeStamp &&
      getTimeRemaining(dataDeletionTimeStamp)) ||
      `${coppaConfig.deletionDelayDays} ${
        coppaConfig.deletionDelayDays > 1 ? 'days' : 'day'
      }` ||
      '30 days'} since no parental consent was received.`,
    primaryBtnContent: 'OK, I UNDERSTAND',
    secondaryBtnContent: 'I RECEIVED PARENTAL CONSENT',
  };

  const getConsentPrimaryBtnClick = () => {
    userPageLogger.log('coppa_existing_user_consent_dialog', {
      action: 'given',
      user_id: userInfo.clinicId,
    });
    const currentTimestamp = Date.now();
    /* set the patient to coppa and set consent to true and timestamp */
    const patientToAddOrChange = {
      consentGiven: true,
      consentTimestamp: currentTimestamp,
    };
    setCustomerCoppaConsent(patientToAddOrChange);
    setIsCoppaGetConsentPopUpOpen(false);
  };

  const getConsentSecondaryBtnClick = () => {
    userPageLogger.log('coppa_existing_user_consent_dialog', {
      action: 'rejected',
      user_id: userInfo.clinicId,
    });
    if (!isSetForDeletion) {
      setDataDeletionTimeStamp(null);
    }
    setIsCoppaGetConsentPopUpOpen(false);
    setIsCoppaDeleteClientDataPopUpOpen(true);
  };

  const deleteClientDataPrimaryBtnClick = () => {
    userPageLogger.log('coppa_existing_user_delete_dialog', {
      action: 'understand',
      user_id: userInfo.clinicId,
    });
    const currentTimestamp = Date.now();
    if (!isSetForDeletion) {
      // create id and set consent to false and timestamp
      const newCoppaPatient = {
        consentGiven: false,
        consentTimestamp: currentTimestamp,
      };
      setCustomerCoppaConsent(newCoppaPatient);
    }
    window.open('/customers.html', '_self');
  };

  const deleteClientDataSecondaryBtnClick = () => {
    userPageLogger.log('coppa_existing_user_delete_dialog', {
      action: 'given_concent',
      user_id: userInfo.clinicId,
    });
    setIsCoppaDeleteClientDataPopUpOpen(false);
    setIsCoppaGetConsentPopUpOpen(true);
  };

  const renderCoppaPopUp = (
    isDialogOpen,
    defaults,
    onPrimaryBtnClick,
    onSecondaryBtnClick
  ) => {
    return (
      <CoppaPopUp
        isDialogOpen={isDialogOpen}
        dialogContent={defaults.dialogContent}
        primaryBtnContent={defaults.primaryBtnContent}
        secondaryBtnContent={defaults.secondaryBtnContent}
        onPrimaryBtnClick={onPrimaryBtnClick}
        onSecondaryBtnClick={onSecondaryBtnClick}
      />
    );
  };

  const renderNotesAndTagsPopOver = () => {
    const renderTagChip = label => <DisorderChip label={label} />;

    const renderTags = () => {
      if (userInfo.shouldUseNewSymptomTracker) {
        return selectedTags.map(tag => {
          const tagLabel = tag.name
            ? `${tag.category}/${tag.name}`
            : tag.category;
          return <div key={tagLabel}>{renderTagChip(tagLabel)}</div>;
        });
      }

      return customerBrainDisorders.map(brainDisorder => (
        <div key={brainDisorder}>{renderTagChip(brainDisorder)}</div>
      ));
    };
    return (
      <Popover
        open={!!notesPopoverAnchorElement}
        anchorEl={notesPopoverAnchorElement}
        onClose={onNotesPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popover_content}>
          {/* <div
            style={{
              overflow: 'hidden',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              hyphens: 'auto',
            }}
          >
            <p data-test-id="user-notes">
              {userInfo.privateDetails.assessment}
            </p>
          </div> */}
          <div className={styles.brain_disorders}>{renderTags()}</div>
        </div>
      </Popover>
    );
  };

  const renderUserIsScheduledForDeletionMsg = () => {
    const millisecondsInADay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    // const millisecondsInMinute = 60 * 1000; // 1 minute in milliseconds

    const actionTimestampForScheduledDeletion = isUserScheduledForDeletion;
    // const futureTimestampIn30Days =
    //   actionTimestampForScheduledDeletion + 30 * millisecondsInADay;
    // const futureTimestampIn5mins =
    //   actionTimestampForScheduledDeletion + 5 * millisecondsInMinute;

    const futureTimestamp =
      actionTimestampForScheduledDeletion +
      scheduleDeletionDays * millisecondsInADay;

    return (
      <div className={styles.scheduled_for_deletion_warning}>
        <div>
          <ErrorOutlineIcon style={{ color: '#db5d5d' }} />
          <p>
            {`This user is scheduled for deletion in ${getTimeRemaining(
              futureTimestamp,
              { getFullTime: false, roundUp: true }
            )}`}
          </p>
        </div>
        <Button
          onClick={() => {
            setIsUserScheduledForDeletion(null);
            setShowScheduledDeletionCanceledSuccessfully(true);
          }}
        >
          UNDO
        </Button>
      </div>
    );
  };
  const renderScheduledDeletionCanceledSuccessfullyMsg = () => {
    setTimeout(() => {
      setShowScheduledDeletionCanceledSuccessfully(false);
    }, 30000);
    return (
      <div className={styles.successfully_canceled_scheduled_deletion_msg}>
        <CheckIcon style={{ color: '#4f9054' }} />
        <p>User deletion has been successfully canceled.</p>
      </div>
    );
  };

  if (isUserArchived) {
    return <div className={styles.root}>This user is archived!</div>;
  }

  if (!isUser) {
    return <div className={styles.root}>This user was deleted!</div>;
  }

  return (
    <React.Fragment>
      <div className={styles.root}>
        <Paper
          className={styles.user_info_and_links}
          data-test-id="user_info_and_links"
        >
          <UserInfo
            userInfo={userInfo}
            customerId={customerId}
            numberOfSessions={numberOfCompletedSessions}
            sessionsDuration={totalDurationOfSessionsInMinutes}
            onNotesBtnClick={onNotesBtnClick}
            isNotesBtnDisabled={
              userInfo.privateDetails.assessment.trim() === '' &&
              customerBrainDisorders.length === 0 &&
              selectedTags.length === 0
            }
            // sessionStatus={sessionStatus}
          />
          <div className={styles.user_info_and_links_divider} />
          {userHasTeamMembers ? (
            <div className={styles.assign_user_box}>
              <div>Assigned to</div>
              <div
                onClick={() => {
                  if (isTeamMembersMenuOpen) {
                    return;
                  }
                  setIsTeamMembersMenuOpen(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <AssignedUsers
                  isTeamMembersMenuOpen={isTeamMembersMenuOpen}
                  setIsTeamMembersMenuOpen={setIsTeamMembersMenuOpen}
                  teamMembersList={clinicTeamMembers}
                  teamMembersUserAssignedTo={deployedProgram}
                  setAssignedTo={setAssignedTo}
                  componentLocation="user_page"
                />
              </div>
            </div>
          ) : null}
          {userHasTeamMembers ? (
            <div className={styles.user_info_and_links_divider} />
          ) : null}
          {userInfo.isRemoteUser ? (
            <CallLogs customerId={customerId} userInfo={userInfo} />
          ) : null}
          {userInfo.isRemoteUser ? (
            <div className={styles.user_info_and_links_divider} />
          ) : null}
          <UserLinks
            onResetPassword={onResetPassword}
            onEditUserInformation={onEditUserInformation}
            onGoToLiveSession={onGoToLiveSession}
            // onNotifyByEmailChange={onNotifyByEmailChange}
            isYoutubeSearchEnabledForClinic={!!isYoutubeSearchEnabledForClinic}
            isYoutubeSearchEnabled={
              isYoutubeSearchEnabledForCustomer === null
                ? isYoutubeSearchEnabledForClinic
                : isClinicAllowedToUseYoutubeSearch &&
                  isYoutubeSearchEnabledForCustomer
            }
            onIsYoutubeSearchEnabledChange={(event, isChecked) => {
              if (!isClinicAllowedToUseYoutubeSearch) {
                setIsFeatureNotAllowedDialogOpen(true);
              } else {
                setIsYoutubeSearchEnabledForCustomer(isChecked);
                userPageLogger.log('client_training_preference_toggled', {
                  preference: 'youtube_search',
                  isEnabled: isChecked,
                });
              }
            }}
            onDeleteClient={onDeleteUser}
            onDeleteNow={onDeleteNow}
            onDownloadDataAsCSV={onDownloadDataAsCSV}
            onSwitchToContinuity={onSwitchToContinuity}
            shouldHideSwitchToContinuityBtn={
              !isContinuityEnabled || headsetDetails.isDispatched
            }
            disabledReasonForSwitchToContinuityBtn={
              disabledReasonForSwitchToContinuityBtn
            }
            disableReasonForDownloadAsCSV={disableReasonForDownloadAsCSV}
            // deployedProgram={deployedProgram}
            disableReasonForGoToLiveSession={disableReasonForGoToLiveSession}
            sessionStatus={sessionStatus}
            isStreamingEnabled={
              // eslint-disable-next-line no-nested-ternary
              isStreamingEnabledForClinic === false
                ? false
                : isStreamingEnabledForCustomer === null
                ? isStreamingEnabledForClinic
                : isStreamingEnabledForCustomer
            }
            onIsStreamingEnabledChange={(event, isChecked) => {
              setIsStreamingEnabledForCustomer(isChecked);
              setIsInternalStreamingEnabledForCustomer(isChecked);
              userPageLogger.log('client_training_preference_toggled', {
                preference: 'streaming',
                isEnabled: isChecked,
              });
            }}
            isStreamingEnabledForClinic={!!isStreamingEnabledForClinic}
            userHasDeployedProgram={!isThereNoProgramDeployed(deployedProgram)}
            isRapidSessionEnabledForClinic={!!isRapidSessionEnabledForClinic}
            onIsRapidSessionEnabledChange={(event, isChecked) => {
              setIsRapidSessionEnabledForCustomer(isChecked);
              userPageLogger.log('client_training_preference_toggled', {
                preference: 'rapid_session',
                isEnabled: isChecked,
              });
            }}
            isRapidSessionEnabled={
              // eslint-disable-next-line no-nested-ternary
              isRapidSessionEnabledForClinic === false
                ? false
                : isRapidSessionEnabledForCustomer === null
                ? isRapidSessionEnabledForCustomer
                : isRapidSessionEnabledForCustomer
            }
            isAlphaPeakEnabledForClinic={!!isAlphaPeakEnabledForClinic}
            onIsAlphaPeakEnabledChange={(event, isChecked) => {
              setIsAlphaPeakEnabledForCustomer(isChecked);
              userPageLogger.log('client_training_preference_toggled', {
                preference: 'alpha_peak',
                isEnabled: isChecked,
              });
            }}
            isAlphaPeakEnabled={
              // eslint-disable-next-line no-nested-ternary
              isAlphaPeakEnabledForCustomer === null
                ? isAlphaPeakEnabledForClinic === null
                  ? true
                  : isAlphaPeakEnabledForClinic
                : isAlphaPeakEnabledForCustomer
            }
            isDemo={userInfo.isDemo}
            isUserScheduledForDeletion={isUserScheduledForDeletion}
            setIsUserScheduledForDeletion={setIsUserScheduledForDeletion}
          />
        </Paper>

        <div className={styles.content}>
          <Paper
            style={{
              paddingBottom:
                isUserScheduledForDeletion ||
                showScheduledDeletionCanceledSuccessfully
                  ? '10px'
                  : '',
            }}
          >
            <div className={styles.tabs_container}>
              <Tabs
                value={selectedMainTab}
                onChange={onSelectedMainTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant={window.innerWidth < 1200 ? 'scrollable' : 'fullWidth'}
                // visibleScrollbar={true}
                // scrollButtons="off"
                // allowScrollButtonsMobile={true}
              >
                <Tab
                  label="overview"
                  value={userPageTabs.overview}
                  classes={{ root: styles.tab_root }}
                />
                {!nfFlag ? null : (
                  <Tab
                    label={
                      isExpired ? (
                        <span>
                          Training
                          <span
                            style={{
                              color: 'red',
                              position: 'relative',
                              top: -10,
                              fontSize: '0.5em',
                            }}
                          >
                            ⬤
                          </span>
                        </span>
                      ) : (
                        'Training'
                      )
                    }
                    value={userPageTabs.sessions}
                    classes={{ root: styles.tab_root }}
                  />
                )}

                <Tab
                  label={t('assessments')}
                  value={userPageTabs.assessments}
                  classes={{ root: styles.tab_root }}
                />

                {!nfFlag ? null : (
                  <Tab
                    label={
                      <>
                        {t('insights')}
                        {isAlphaPeakBaseline && !isAlphaPeakGraphSeen && (
                          <span
                            style={{
                              color: 'red',
                              position: 'relative',
                              top: -10,
                              fontSize: '0.5em',
                            }}
                          >
                            ⬤
                          </span>
                        )}
                      </>
                    }
                    value={userPageTabs.insights}
                    classes={{ root: styles.tab_root }}
                  />
                )}

                <Tab
                  label={
                    isUnseenEntries ? (
                      <span>
                        Entries
                        <span
                          style={{
                            color: 'red',
                            position: 'relative',
                            top: -10,
                            fontSize: '0.5em',
                          }}
                        >
                          ⬤
                        </span>
                      </span>
                    ) : (
                      <span>
                        Entries
                        {/* <span
                          style={{
                            fontSize: '0.75em',
                            color: '#5bc5b8',
                            marginLeft: 5,
                          }}
                        >
                          new
                        </span> */}
                      </span>
                    )
                  }
                  value={userPageTabs.entries}
                  classes={{ root: styles.tab_root }}
                />

                <Tab
                  label="Digital care"
                  value={userPageTabs.behaviouralProgress}
                  classes={{ root: styles.tab_root }}
                />
              </Tabs>
            </div>
            {scheduleDeletionDays && isUserScheduledForDeletion
              ? renderUserIsScheduledForDeletionMsg()
              : null}

            {scheduleDeletionDays &&
            !isUserScheduledForDeletion &&
            showScheduledDeletionCanceledSuccessfully
              ? renderScheduledDeletionCanceledSuccessfullyMsg()
              : null}
          </Paper>

          {selectedMainTab === userPageTabs.sessions && (
            <div>
              <SessionsTab
                customerId={customerId}
                deployedProgram={deployedProgram}
                trainingSessions={trainingSessions}
                protocols={protocolsConfig}
                headsetDetails={headsetDetails}
                onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
                sessionAnalysis={sessionAnalysis}
                isExpired={isExpired}
                /* training tab table and filter states */

                currentTableData={currentTableData}
                setCurrentTableData={setCurrentTableData}
                trainingSessionsReversedEntries={
                  trainingSessionsReversedEntries
                }
                isSessionsTableFiltered={isSessionsTableFiltered}
                setIsSessionsTableFiltered={setIsSessionsTableFiltered}
                isPopoverOpen={isPopoverOpen}
                setIsPopOverOpen={setIsPopOverOpen}
                isFilterSelectToggleSwitchChecked={
                  isFilterSelectToggleSwitchChecked
                }
                setIsFilterSelectToggleSwitchChecked={
                  setIsFilterSelectToggleSwitchChecked
                }
                sessionsFilterSelectedProtocls={sessionsFilterSelectedProtocls}
                setSessionsFilterSelectedProtocls={
                  setSessionsFilterSelectedProtocls
                }
                isFilterSelectButtonDisabled={isFilterSelectButtonDisabled}
                setIsFilterSelectButtonDisabled={
                  setIsFilterSelectButtonDisabled
                }
                rowsKeys={rowsKeys}
                sessionsFilterSavedSelectedProtocols={
                  sessionsFilterSavedSelectedProtocols
                }
                setSessionsFilterSavedSelectedProtocls={
                  setSessionsFilterSavedSelectedProtocls
                }
                completeSessionsTableData={completeSessionsTableData}
                sessionsFilterProtocols={sessionsFilterProtocols}
              />
            </div>
          )}

          {selectedMainTab === userPageTabs.overview && (
            <div>
              <ReportsTab
                patientId={customerId}
                headsetDetails={headsetDetails}
                onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
                notificationsAlerts={notificationsAlerts}
                editNotificationsAlerts={editNotificationsAlerts}
                notificationsData={notificationsData}
                // deleteNotificationsAlerts={deleteNotificationsAlerts}
                // clearAllNotifications={() => {
                //   editNotificationsAlerts(null);
                // }}
                customerNotes={customerNotes}
                setCustomerNotes={setCustomerNotes}
                addCustomerNote={addCustomerNote}
                removeCustomerNote={removeCustomerNote}
                trainingSessions={trainingSessions}
                patientMesseges={patientMesseges}
                onNotifyByEmailChange={onNotifyByEmailChange}
                // deployedProgram={deployedProgram}
                onNotifyCustomerOnMessegesChange={
                  onNotifyCustomerOnMessegesChange
                }
                notifyAutomationsMessagesByEmail={
                  notifyAutomationsMessagesByEmail
                }
                notifyByEmail={notifyByEmail}
              />
            </div>
          )}

          {selectedMainTab === userPageTabs.assessments && (
            <div>
              <AssessmentsTab
                assessmentStagesInfo={assessmentStagesInfo}
                customerId={customerId}
                headsetDetails={headsetDetails}
                onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
                customerInfo={userInfo}
                setCustomerInfo={setUserInfo}
                deployedQuestionnaires={deployedQuestionnaires}
                allSymptomTrackerQuestions={
                  userInfo.shouldUseNewSymptomTracker
                    ? allQuestionnairesQuestions
                    : allSymptomTrackerQuestions
                }
                customerSymptomTrackerQuestions={
                  customerSymptomTrackerQuestions
                }
                symptomsTrackingAnswers={symptomsTrackingAnswers}
                onDownloadSymptomsTrackingDataAsCSV={
                  onDownloadSymptomsTrackingDataAsCSV
                }
                onDownloadOverallSymptomsTrackingDataAsCSV={
                  onDownloadOverallSymptomsTrackingDataAsCSV
                }
                shouldUseNewSymptomTracker={userInfo.shouldUseNewSymptomTracker}
                {...{
                  onSwitchToNewSymptomTrackerDialogPrimaryBtnClick,
                  onSwitchToNewSymptomTrackerDialogSecondaryBtnClick,
                  isSwitchToNewSymptomTrackerDialogOpen,
                  onSwitchToNewSymptomTrackerDialogClose,
                  onSetupSymptomTracker,
                  onSetupQuestionnaires,
                  questionnairesShouldBeEnabledInScheduleAssessmentDialog,
                  isCustomerAppCompatiableWithSymptomQuestionnaires,
                  switchToNewSymptomTracker,
                }}
                allQuestionnariesData={allQuestionnaires}
              />
            </div>
          )}

          {selectedMainTab === userPageTabs.insights && (
            <InsightsTab
              userPageLogger={userPageLogger}
              alphaWeightedPeak={alphaWeightedPeak}
              isAlphaPeakGraphSeen={isAlphaPeakGraphSeen}
              setIsAlphaPeakGraphSeen={setIsAlphaPeakGraphSeen}
              isAlphaPeakBaseline={isAlphaPeakBaseline}
            />
          )}
          {selectedMainTab === userPageTabs.behaviouralProgress && (
            <BehaviouralProgressTab />
          )}
          {selectedMainTab === userPageTabs.entries && (
            <EntriesTab
              isLagoEnabled={isLagoEnabled}
              calcCategoriesStatus={calcCategoriesStatus}
              editNotificationsAlerts={editNotificationsAlerts}
              notificationsAlerts={notificationsAlerts}
            />
          )}
        </div>
      </div>

      <React.Fragment>
        {isCoppaGetConsentPopUpOpen &&
          renderCoppaPopUp(
            isCoppaGetConsentPopUpOpen,
            getConsentDefaults,
            getConsentPrimaryBtnClick,
            getConsentSecondaryBtnClick
          )}
        {isCoppaDeleteClientDataPopUpOpen &&
          renderCoppaPopUp(
            isCoppaDeleteClientDataPopUpOpen,
            deleteClientDataDefaults,
            deleteClientDataPrimaryBtnClick,
            deleteClientDataSecondaryBtnClick
          )}
        {isAddClientModalOpen && renderAddClientModal()}

        <FeatureNotAllowedDialog
          text={t('upgrade-plan-to-use-youtube-search')}
          isOpen={
            isFeatureNotAllowedDialogOpen && !isClinicAllowedToUseYoutubeSearch
          }
          onDismiss={() => setIsFeatureNotAllowedDialogOpen(false)}
        />

        <SwitchToContinuityDialog
          isOpen={isSwitchToContinuityDialogOpen}
          onPrimaryBtnClick={onSwitchToContinuityDialogPrimaryBtnClick}
          onSecondaryBtnClick={onSwitchToContinuityDialogSecondaryBtnClick}
          renderedStep={switchToDialogRenderedStep}
          headsetDetails={headsetDetails}
          nextBillingCycleDate={dayJS(clinicCurrentPackage.expiration).format(
            DAY_JS_DATE_FORMATS.dayMonthYearSlashes
          )}
          creditForContinuityHeadset={creditForContinuityHeadset}
        />

        {renderNotesAndTagsPopOver()}
      </React.Fragment>
    </React.Fragment>
  );
};

UserPageBase.propTypes = exact({
  customerId: PropTypes.string,
  userInfo: PropTypes.object,
  trainingSessions: PropTypes.object,
  brainDisorders: PropTypes.array,
  customerBrainDisorders: PropTypes.array,
  isYoutubeSearchEnabledForClinic: PropTypes.bool,
  isYoutubeSearchEnabledForCustomer: PropTypes.bool,
  isClinicAllowedToUseYoutubeSearch: PropTypes.bool,
  deployedProgram: PropTypes.object,
  protocolsConfig: PropTypes.object,
  symptomsTrackingAnswers: PropTypes.object,
  allSymptomTrackerQuestions: PropTypes.object,
  customerSymptomTrackerQuestions: PropTypes.array,
  setIsYoutubeSearchEnabledForCustomer: PropTypes.func,
  onDownloadSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  onDownloadOverallSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  numberOfCompletedSessions: PropTypes.number,
  headsetDetails: PropTypes.object,
  clinicCurrentPackage: PropTypes.object,
  creditForContinuityHeadset: PropTypes.number,
  onHeadsetDetailsUpdated: PropTypes.func,
  isContinuityEnabled: PropTypes.bool,
  allQuestionnaires: PropTypes.object,
  allTags: PropTypes.object,
  selectedTags: PropTypes.array,
  deployedQuestionnaires: PropTypes.array.isRequired,
  deployedAssessment: PropTypes.object,
  setUserInfo: PropTypes.func,
  isCustomerAppCompatiableWithSymptomQuestionnaires: PropTypes.bool.isRequired,
  assessmentStagesInfo: PropTypes.object.isRequired,
  sessionStatus: PropTypes.string,
  isUnseenEntries: PropTypes.bool,
  sessionAnalysis: PropTypes.object,
  isStreamingEnabledForClinic: PropTypes.bool,
  isStreamingEnabledForCustomer: PropTypes.bool,
  setIsStreamingEnabledForCustomer: PropTypes.func,
  setIsInternalStreamingEnabledForCustomer: PropTypes.func,
  customerNotes: PropTypes.object,
  setCustomerNotes: PropTypes.func,
  addCustomerNote: PropTypes.func,
  removeCustomerNote: PropTypes.func,
  minsUsage: PropTypes.number,
  isRapidSessionEnabledForClinic: PropTypes.bool,
  setIsRapidSessionEnabledForCustomer: PropTypes.func,
  isRapidSessionEnabledForCustomer: PropTypes.bool,
  isAlphaPeakEnabledForClinic: PropTypes.bool,
  isAlphaPeakEnabledForCustomer: PropTypes.bool,
  setIsAlphaPeakEnabledForCustomer: PropTypes.func,
  alphaWeightedPeak: PropTypes.object,
  isAlphaPeakGraphSeen: PropTypes.bool,
  setIsAlphaPeakGraphSeen: PropTypes.func,
  isAlphaPeakBaseline: PropTypes.bool,
  allQuestionnairesOnlyClinic: PropTypes.object,
  patientMesseges: PropTypes.object,
  onNotifyCustomerOnMessegesChange: PropTypes.func,
  notifyAutomationsMessagesByEmail: PropTypes.bool,
  customerCoppaConsent: PropTypes.object,
  setCustomerCoppaConsent: PropTypes.func,
  coppaConfig: PropTypes.object,
  clinicTeamMembers: PropTypes.object,
});

export const UserPage = React.memo(UserPageBase);
UserPage.displayName = 'UserPage';

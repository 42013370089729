import React from 'react';
import exact from 'prop-types-exact';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styles from './LagoSection.scss';
import { LagoControl } from './LagoControl/LagoControl';

const LagoSectionBase = () => {
  return (
    <div className={styles.lago_container}>
      <Router>
        <Switch>
          <Route path="/lago.html">
            <LagoControl />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

LagoSectionBase.propTypes = exact({});

export const LagoSection = React.memo(LagoSectionBase);
LagoSection.displayName = 'LagoSection';

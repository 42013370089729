import pickBy from 'lodash/pickBy';
import {
  getFrequencyAssessmentBrainMaps,
  getFrequencyAmplitudesAssessment,
  // getStandardAssessmentStagesInfo,
  // frequenciesToImagesFullNames,
  getFrequenciesToImagesFullNames,
} from '../assessments/assessments';
import { getAge } from '../person/person';
import {
  getCurrentURLParams,
  notifyAppThatComponentIsLoaded,
  ifNeededRedirectToLoginPage,
  defensiveThrow,
} from '../../utils/utils';
import { getAllQuestionnaires } from '../symptom-tracker/symptom-tracker';
import { getLogoImage } from '../logo/logo';

export const initAssessmentPage = async ({
  shouldNotifyAndroid = true,
} = {}) => {
  ifNeededRedirectToLoginPage();
  if (shouldNotifyAndroid) {
    notifyAppThatComponentIsLoaded();
  }

  // eslint-disable-next-line no-undef
  await stringsPromise;
  // eslint-disable-next-line no-undef
  const remoteConfig = firebase.remoteConfig();
  try {
    await remoteConfig.fetchAndActivate();
  } catch (error) {
    defensiveThrow({ error });
  }

  // const shouldShowBrainMaps = remoteConfig
  //   .getValue('showBrainMaps')
  //   .asBoolean();
  const shouldShowBrainMaps = true;

  const {
    customerId,
    assessmentId,
    // assessmentVersion
  } = getCurrentURLParams();

  const customerInfo = await database
    .ref(`userInfo/${customerId}`)
    .once('value')
    .then(snapshot => snapshot.val());

  const logoImageInfo = await getLogoImage({
    clinicId: sessionStorage.userId,
  });

  const frequenciesToImagesFullNames = await getFrequenciesToImagesFullNames();

  // const standardAssessmentStagesInfo = await getStandardAssessmentStagesInfo(
  //   customerInfo.clinicId
  // );

  // const assessmentStagesRequiredForBrainMaps = Object.keys(
  //   standardAssessmentStagesInfo.names
  // ).filter(
  //   stage =>
  //     standardAssessmentStagesInfo.names[stage] !==
  //     standardAssessmentStagesInfo.names.CPT
  // );

  const swingleChecksOrder = await database
    .ref('assessments/swingle/order')
    .once('value')
    .then(snapshot => snapshot.val());

  const questionnaires = await getAllQuestionnaires({
    clinicId: customerInfo.clinicId,
  });

  const symptomTrackerQuestionnaires = await database
    .ref('symptomTracker/questionnaires')
    .once('value')
    .then(snapshot => snapshot.val());

  const answerScales = await database
    .ref('symptomTracker/answerScales')
    .once('value')
    .then(snapshot => snapshot.val());

  // const isClinicInsightsEnbled = await database
  //   .ref(
  //     `/clinicConfig/assessments/enabledClinicsForInsights/${sessionStorage.userId}`
  //   )
  //   .once('value')
  //   .then(snapshot => snapshot.val());

  const insightsAllowedVersions = await database
    .ref(
      '/assessments/textGenerationGuide/insightsConfig/summary/allowedVersions'
    )
    .once('value')
    .then(snapshot => snapshot.val());

  database
    .ref(`assessments/results/${customerId}`)
    .on('value', async snapshot => {
      const assessmentResults = snapshot.val() || {};

      const nonDeletedAssessments = pickBy(
        assessmentResults,
        assessment => !assessment.isDeleted
      );

      const shouldTakeHeadsetOnly =
        assessmentResults[assessmentId].stagesType === 'headsetStages';

      const swingleChecks = await database
        .ref(
          shouldTakeHeadsetOnly
            ? 'assessments/swingle/headsetChecks'
            : 'assessments/swingle/checks'
        )
        .once('value')
        .then(snapshot2 => snapshot2.val());

      const questionnaireResults = await database
        .ref(`symptomTracker/answers/${customerId}`)
        .orderByChild('assessmentId')
        .equalTo(assessmentId)
        .once('value')
        .then(s => (s.val() === null ? null : Object.values(s.val())[0]));

      if (questionnaireResults) {
        questionnaireResults.standardizedTotalScores = pickBy(
          questionnaireResults.totalScores,
          (value, questionnaireId) =>
            questionnaires[questionnaireId].isStandardized
        );
      }
      const isFirstAssessment =
        assessmentId === Object.keys(nonDeletedAssessments)[0];

      const assessment = assessmentResults[assessmentId];

      if (!assessment) {
        return;
      }

      let assessmentNumber = 1;
      for (
        let i = 0;
        i < Object.keys(assessmentResults).indexOf(assessmentId);
        i += 1
      ) {
        if (!assessmentResults[Object.keys(assessmentResults)[i]].isDeleted) {
          assessmentNumber += 1;
        }
      }

      const swingleResults = assessment.swingle;

      const CPTResults = assessment.cpt;

      const assessmentHasEEGData = !!assessment.eeg;

      const shouldWaitForBrainMaps =
        shouldNotifyAndroid &&
        shouldShowBrainMaps &&
        !assessment.isReady &&
        assessment.selectedStages &&
        !assessment.selectedStages.find(stage => stage === 'CPT');

      if (shouldWaitForBrainMaps && assessmentHasEEGData) {
        // HACK: wait for few seconds to render images in the brain maps card
        await new Promise(resolve => setTimeout(resolve, 60000));
      }
      const frequenciesToImages =
        shouldShowBrainMaps &&
        assessmentHasEEGData &&
        assessment.eegProcessed &&
        // (!assessment.selectedStages ||
        //   assessmentStagesRequiredForBrainMaps.every(
        //     stage => assessment.selectedStages.indexOf(stage) > -1
        //   )) &&
        (await getFrequencyAssessmentBrainMaps({
          customerId,
          assessmentId,
        }));

      const frequenciesAmplitudesByChannel =
        assessmentHasEEGData &&
        assessment.eegProcessed &&
        (await getFrequencyAmplitudesAssessment({
          customerId,
          assessmentId,
        }));

      const brainMapsNoiseData = await database
        .ref(
          `assessments/results/${customerId}/${assessmentId}/assessment_v2/normalized_noise`
        )
        .once('value')
        .then(snapshot1 => snapshot1.val());

      const brainMapsNoiseDataAlt = await database
        .ref(
          `assessments/results/${customerId}/${assessmentId}/assessment_v2/noise`
        )
        .once('value')
        .then(snapshot1 => snapshot1.val());

      const shouldShowEegGraph = await database
        .ref(
          `/clinicConfig/assessments/eegGraph/${customerInfo.clinicId}/shouldShowEegGraph`
        )
        .once('value')
        .then(snapshot1 => snapshot1.val());

      // eslint-disable-next-line no-undef
      hideLoading();

      // eslint-disable-next-line no-undef
      renderComponent({
        componentName:
          // assessmentVersion === 'v2' ? 'AssessmentPageV2' :
          'AssessmentPage',
        elementId: 'react-assessment',
        props: {
          patientEmail: customerInfo.email,
          patientFullName: `${customerInfo.firstName} ${customerInfo.lastName}`,
          patientAge: getAge(customerInfo.privateDetails.dateOfBirth),
          patientGender: customerInfo.privateDetails.gender.toLowerCase(),
          assessment,
          shouldShowBrainMaps,
          swingleChecks,
          swingleResults,
          swingleChecksOrder,
          CPTResults,
          questionnaires,
          questionnaireResults,
          answerScales,
          isFirstAssessment,
          ...(assessmentHasEEGData ? { frequenciesAmplitudesByChannel } : {}),
          ...(frequenciesToImages ? { frequenciesToImages } : {}),
          // eslint-disable-next-line no-nested-ternary
          brainMapsNoiseData: brainMapsNoiseData
            ? { ...brainMapsNoiseData }
            : brainMapsNoiseDataAlt
            ? { ...brainMapsNoiseDataAlt }
            : {},
          isNoiseNormalized: !!brainMapsNoiseData,
          frequenciesToImagesFullNames,
          symptomTrackerQuestionnaires,
          isLoadingBrainmaps:
            shouldWaitForBrainMaps &&
            !assessment.eegProcessed &&
            assessment.selectedStages.filter(
              stage => stage !== 'CPT' && stage !== 'Questionnaires'
            ).length,
          shouldTakeHeadsetOnly,
          // shouldShowNoiseGraphForHeadsetAssessment,
          assessmentNumber,
          // isClinicInsightsEnbled,
          isClinicView: !shouldNotifyAndroid,
          insightsAllowedVersions,
          logoImageInfo,
          shouldShowEegGraph,
        },
      });
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './ConfirmCancelingScheduledAssessmentDialog.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';

export const confirmCancelingScheduledAssessmentDialogSteps = {
  confirm: 'confirm',
  clientIsAlreadyDoingAssessment: 'clientIsAlreadyDoingAssessment',
};

const ConfirmCancelingScheduledAssessmentDialogBase = ({
  renderedStep,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const t = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogContent className={styles.content}>
        {renderedStep ===
        confirmCancelingScheduledAssessmentDialogSteps.confirm ? (
          <>
            <h3 className={styles.dialog_header}>{t('sure')}</h3>
            <p>{t('cancel_recurring_assessment_prompt_text')}</p>
          </>
        ) : (
          <>
            <h3 className={styles.dialog_header}>
              {t('caution-client-already-started-assessment')}
            </h3>
            <p>
              {t('canceling-assessment-now-will-interrupt-user-experience')}
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text" color="default">
          <span className={styles.btn}>{t('cancel')}</span>
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          <span className={styles.btn}>
            {renderedStep ===
            confirmCancelingScheduledAssessmentDialogSteps.confirm
              ? t('stop')
              : t('yes-proceed')}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmCancelingScheduledAssessmentDialogBase.propTypes = exact({
  renderedStep: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
});

export const ConfirmCancelingScheduledAssessmentDialog = React.memo(
  ConfirmCancelingScheduledAssessmentDialogBase
);
ConfirmCancelingScheduledAssessmentDialog.displayName =
  'ConfirmCancelingScheduledAssessmentDialog';

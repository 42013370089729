import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Rectangle,
} from 'recharts';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import random from 'lodash/random';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
// import { withUpdateOnlyInViewPort } from '../../../Core/hocs/withUpdateOnlyInViewPort/withUpdateOnlyInViewPort';
import { graphHeight } from '../constants';
import { ClickableLegendItems } from '../../ClickableLegendItems/ClickableLegendItems';
// import { withRenderOnceNearViewPort } from '../../../Core/hocs/withRenderOnceNearViewPort/withRenderOnceNearViewPort';
import { isDeepEqualWithTrueForFunctions } from '../../../utils/utils';

const getDefaultState = () => ({
  shouldDrawReferenceLines: false,
  maxStreakTimeScatterInternalPoints: [],
  averageStreakTimeScatterInternalPoints: [],
});

const AverageAndMaxStreakGraphBase = ({
  t,
  data,
  legendItems,
  onLegendItemClick,
  isInPreview,
}) => {
  const [state, setState] = useState(getDefaultState(data));
  const maxStreakRef = useRef(null);

  const {
    shouldDrawReferenceLines,
    maxStreakTimeScatterInternalPoints,
    averageStreakTimeScatterInternalPoints,
  } = state;

  const maxStreakTimeKey = 'maxStreakTime';
  const averageStreakTimeKey = 'averageStreakTime';

  const dataNames = {
    [maxStreakTimeKey]: t('max_streak_time'),
    [averageStreakTimeKey]: t('average_streak_time'),
  };

  useEffect(() => {
    const onWindowResize = () => setState(getDefaultState(data));

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [data]);

  const colors = ['#F469CE', '#918AEA'];
  const sortedLegendItemsKeys = Object.keys(legendItems).sort();

  return (
    <React.Fragment>
      <ResponsiveContainer
        width={
          `${random(0, 0.2) + 99.8}%`
          /* we set dynamic width to cause the animation to restart because it is causing a bug t=
           * that causes the scatter chart to take shift the legends to up
           * https://github.com/recharts/recharts/issues/1135
           */
        }
        height={graphHeight}
      >
        <ComposedChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          data={data}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickMargin={7}
            tick={{ fontSize: 12 }}
            tickFormatter={tick => tick.slice(0, -5)}
          />
          <YAxis
            tickFormatter={tick => `${tick} (Seconds)`}
            width={100}
            tickMargin={7}
            tick={{ fontSize: 12 }}
          />
          <Tooltip
            formatter={(value, name, props) => [
              value === 0
                ? '0 (Seconds)'
                : `${parseFloat(value, 10).toFixed(2)} (Seconds)`,
              dataNames[props.dataKey],
            ]}
          />

          {shouldDrawReferenceLines &&
            legendItems.maxStreak &&
            legendItems.averageStreak &&
            maxStreakTimeScatterInternalPoints.length === data.length &&
            data.map((dataObject, index) => {
              const maxStreakPoint = maxStreakTimeScatterInternalPoints[index];
              const averageStreakPoint =
                averageStreakTimeScatterInternalPoints[index];

              const { x, width } = maxStreakPoint;
              const y = Math.min(maxStreakPoint.cy, averageStreakPoint.cy);
              const height =
                Math.max(maxStreakPoint.y, averageStreakPoint.y) -
                Math.min(maxStreakPoint.y, averageStreakPoint.y);

              return (
                <ReferenceLine
                  ifOverflow="visible"
                  x={0}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  shape={() => (
                    <Rectangle
                      x={x}
                      y={y}
                      width={width}
                      strokeWidth={0}
                      height={height}
                      fill="#E6E6E6"
                    />
                  )}
                />
              );
            })}

          <Scatter
            name={t('max_streak_time')}
            dataKey={maxStreakTimeKey}
            fill={colors[1]}
            ref={maxStreakRef}
            hide={!legendItems.maxStreak}
          />
          <Scatter
            dataKey={averageStreakTimeKey}
            name={t('average_streak_time')}
            hide={!legendItems.averageStreak}
            fill={colors[0]}
            ref={node => {
              if (node) {
                const neededKeysFromPoint = [
                  'averageStreakTime',
                  'cx',
                  'cy',
                  'date',
                  'maxStreakTime',
                  'width',
                  'x',
                  'y',
                  'size',
                ];
                const pickFromPoint = point => pick(point, neededKeysFromPoint);
                const maxStreakPoints = maxStreakRef.current.props.points.map(
                  pickFromPoint
                );
                const averageStreakPoints = node.props.points.map(
                  pickFromPoint
                );

                if (
                  !isEqual(maxStreakTimeScatterInternalPoints, maxStreakPoints)
                ) {
                  setState({
                    maxStreakTimeScatterInternalPoints: maxStreakPoints,
                    averageStreakTimeScatterInternalPoints: averageStreakPoints,
                    shouldDrawReferenceLines: true,
                  });
                }
              }
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <ClickableLegendItems
        legendItems={legendItems}
        legendItemsKeys={sortedLegendItemsKeys}
        onLegendItemClick={onLegendItemClick}
        isInPreview={isInPreview}
        colors={colors}
        legendItemFormatter={legendItem =>
          legendItem === 'maxStreak'
            ? t('max_streak_time')
            : t('average_streak_time')
        }
      />
    </React.Fragment>
  );
};

AverageAndMaxStreakGraphBase.defaultProps = {
  isInPreview: false,
};

AverageAndMaxStreakGraphBase.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  legendItems: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
  onLegendItemClick: PropTypes.func,
  isInPreview: PropTypes.bool,
  dataKey: PropTypes.string,
};

export const AverageAndMaxStreakGraph = withTranslation(
  React.memo(AverageAndMaxStreakGraphBase, isDeepEqualWithTrueForFunctions)
);

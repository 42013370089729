import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import uuidv4 from 'uuid/v4';
import Switch from 'react-switch';
import styles from './RegisterNonfClinic.scss';
import { FAQS } from '../../StandAloneComponents/MTRSubscriptionPage/FAQS/FAQS';
import { NonfPackagePlan } from '../NonfPackagePlan/NonfPackagePlan';
import { StartTrialBanner } from '../StartTrialBanner/StartTrialBanner';
import { getCurrentURLParams, getEventLogger } from '../../utils/utils';

const logClinicRegistrationEvent = getEventLogger({
  prefix: 'clinic_register',
});

const RegisterNonfClinicBase = ({
  faqs,
  defaultPackages,
  NFPackages,
  groupId,
  addonGroupId,
}) => {
  const params = getCurrentURLParams();
  const isNf = params.nf === 'true';

  const [toggleAnual, setToggleAnual] = useState(false);
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isNfAdded, setIsNfAdded] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const planInfoStorage = localStorage.getItem('planInfo');
    if (
      planInfoStorage &&
      JSON.parse(planInfoStorage).isAnnual !== null &&
      JSON.parse(planInfoStorage).isAnnual !== undefined
    ) {
      const planInfo = JSON.parse(planInfoStorage);
      setToggleAnual(planInfo.isAnnual);
      setIsNfAdded(planInfo.isNfAdded);
      logClinicRegistrationEvent({
        name: 'entered',
        data: {
          session_id: JSON.parse(planInfoStorage).sessionId,
          isNF: isNf,
        },
      });
    } else {
      const generatedId = uuidv4();
      // eslint-disable-next-line no-undef
      localStorage.setItem(
        'planInfo',
        JSON.stringify({
          sessionId: generatedId,
        })
      );
      logClinicRegistrationEvent({
        name: 'entered',
        data: {
          session_id: generatedId,
          isNF: isNf,
        },
      });
    }
  }, []);

  const faqsOnSearch = useCallback(
    event => {
      const searchString = event.target.value;
      setFilteredFaqs(
        faqs.filter(
          faq =>
            faq.question.toLowerCase().includes(searchString.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchString.toLowerCase())
        )
      );
    },
    [faqs]
  );
  const onClearSearch = useCallback(() => {
    setFilteredFaqs(faqs);
  }, [faqs]);

  const onPackageSelected = async packageIndex => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('hardwareItems', null);
    // eslint-disable-next-line no-undef
    const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
    if (isNf) {
      await new MyEvent('register_plan_chosen').log({
        isAnnual: toggleAnual,
        // price: defaultPackages[packageIndex].basePrice,
        groupId,
        nfGroupId: addonGroupId,
        nfPlanInd: 0,
        planInd: packageIndex,
        session_id: planInfoStorage.sessionId,
        isNF: true,
      });
      if (selectedPackage === packageIndex) {
        setSelectedPackage(null);
        return;
      }
      setSelectedPackage(packageIndex);
      return;
    }
    await new MyEvent('register_plan_chosen').log({
      isAnnual: toggleAnual,
      // price: defaultPackages[packageIndex].basePrice,
      groupId,
      nfGroupId: addonGroupId,
      nfPlanInd: 0,
      planInd: packageIndex,
      session_id: planInfoStorage.sessionId,
      isNF: false,
    });
    await logClinicRegistrationEvent({
      name: 'start_trial_clicked',
      data: {
        isAnnual: toggleAnual,
        // price: defaultPackages[selectedPackage].basePrice,
        groupId,
        nfGroupId: addonGroupId,
        nfPlanInd: 0,
        session_id: planInfoStorage.sessionId,
        planInd: packageIndex,
        include_nf_add_on: isNfAdded,
        isNF: false,
      },
    });
    // eslint-disable-next-line no-undef
    localStorage.setItem(
      'planInfo',
      JSON.stringify({
        packageIndex,
        groupId,
        isAnnual: toggleAnual,
        isNf: false,
        isNfAdded: false,
        sessionId: planInfoStorage.sessionId,
      })
    );
    window.open('start-trial-register.html', '_self');
  };

  const onFaqExpanded = (question, expanded) => {
    if (expanded) {
      // eslint-disable-next-line no-undef
      const planInfoStorage = JSON.parse(localStorage.getItem('planInfo'));
      logClinicRegistrationEvent({
        name: 'faq_expanded',
        data: {
          session_id: planInfoStorage.sessionId,
          isNF: isNf,
          question_title: question,
        },
      });
    }
  };

  return (
    <div className={styles.start_trial_container}>
      <div
        className={styles.start_trial_title}
        style={isNf && window.innerWidth < 1200 ? { marginTop: 50 } : null}
      >
        Choose the plan that works for your practice
      </div>
      <div className={styles.start_trial_switch_container}>
        <div
          style={!toggleAnual ? { fontWeight: 900, textAlign: 'end' } : null}
        >
          Monthly Billing
        </div>
        <div className={styles.start_trial_switch}>
          <Switch
            onColor="#C3E0FF"
            offColor="#C3E0FF"
            offHandleColor="#0079FA"
            onHandleColor="#0079FA"
            onChange={() => {
              setToggleAnual(!toggleAnual);
              const planInfoStorage = JSON.parse(
                // eslint-disable-next-line no-undef
                localStorage.getItem('planInfo')
              );
              logClinicRegistrationEvent({
                name: 'interval_toggled',
                data: {
                  session_id: planInfoStorage.sessionId,
                  isNF: isNf,
                  isAnnual: !toggleAnual,
                  from_where: 'select_plan',
                },
              });
            }}
            checked={toggleAnual}
            uncheckedIcon={false}
            checkedIcon={false}
            height={14}
            width={28}
          />
        </div>
        <div
          className={styles.toggle_title}
          style={toggleAnual ? { fontWeight: 900 } : null}
        >
          Annual Billing
          <span className={styles.start_trial_switch_discount}>
            {' '}
            (Save 16%)
          </span>
        </div>
      </div>
      <div className={styles.nonf_package_plans_container}>
        {defaultPackages &&
          Object.keys(defaultPackages).map((packagePlan, index) => {
            return (
              <NonfPackagePlan
                key={defaultPackages[packagePlan].bundleName}
                packagePlan={defaultPackages[packagePlan]}
                // eslint-disable-next-line no-undef
                color={packagePlansColors[index % packagePlansColors.length]}
                isAnnual={toggleAnual}
                onPackageSelected={onPackageSelected}
                packageIndex={index}
                selectedPackage={selectedPackage}
                isMostPopular={
                  index === Math.floor(Object.keys(defaultPackages).length / 2)
                }
              />
            );
          })}
      </div>
      <StartTrialBanner
        isNf={isNf}
        isNfAdded={isNfAdded}
        setIsNfAdded={setIsNfAdded}
        isAnnual={toggleAnual}
        NFPackages={NFPackages}
        addonGroupId={addonGroupId}
      />
      <div className={styles.faqs_container}>
        <FAQS
          faqs={filteredFaqs}
          onSearch={faqsOnSearch}
          onClearSearch={onClearSearch}
          styles={styles}
          onFaqExpanded={onFaqExpanded}
          resizeSearch
        />
      </div>
      {isNf && (selectedPackage !== null || isNfAdded) ? (
        <div className={styles.nf_sticky_footer}>
          <div
            style={{
              maxWidth: 1050,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className={styles.nf_sticky_footer_text}>
              {selectedPackage === null && isNfAdded ? (
                <div style={{ fontSize: 14 }}>
                  You’ve only selected an add-on…
                </div>
              ) : null}
              {selectedPackage === null ? (
                <div style={{ fontWeight: 400 }}>
                  Please select a plan to continue
                </div>
              ) : (
                <div>
                  <div style={{ fontSize: 14 }}>Free Trial Includes:</div>
                  <div style={{ fontSize: 20 }}>
                    {defaultPackages[selectedPackage].bundleName} Plan{' '}
                    {isNfAdded ? ' + Neuro add-on' : null}
                    <span style={{ fontWeight: 400 }}>
                      {toggleAnual ? ' (annual)' : ' (monthly)'}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div
              className={styles.select_package_button}
              style={
                selectedPackage === null
                  ? { opacity: 0.5, cursor: 'auto' }
                  : null
              }
              onClick={async () => {
                if (selectedPackage !== null) {
                  const planInfoStorage = JSON.parse(
                    // eslint-disable-next-line no-undef
                    localStorage.getItem('planInfo')
                  );

                  await logClinicRegistrationEvent({
                    name: 'start_trial_clicked',
                    data: {
                      isAnnual: toggleAnual,
                      // price: defaultPackages[selectedPackage].basePrice,
                      groupId,
                      nfGroupId: addonGroupId,
                      nfPlanInd: 0,
                      session_id: planInfoStorage.sessionId,
                      planInd: selectedPackage,
                      include_nf_add_on: isNfAdded,
                      isNF: true,
                    },
                  });

                  // eslint-disable-next-line no-undef
                  localStorage.setItem(
                    'planInfo',
                    JSON.stringify({
                      packageIndex: selectedPackage,
                      groupId,
                      isAnnual: toggleAnual,
                      isNf: true,
                      isNfAdded,
                      sessionId: planInfoStorage.sessionId,
                    })
                  );
                  window.open('start-trial-register.html', '_self');
                }
              }}
            >
              START FREE TRIAL
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

RegisterNonfClinicBase.propTypes = exact({
  faqs: PropTypes.array.isRequired,
  defaultPackages: PropTypes.array.isRequired,
  NFPackages: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  addonGroupId: PropTypes.string.isRequired,
});

export const RegisterNonfClinic = React.memo(RegisterNonfClinicBase);
RegisterNonfClinic.displayName = 'RegisterNonfClinic';

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './NoDataIndicator.scss';
import { NoDataSvg } from '../SessionToSessionReportGenerator/NoDataSvg/NoDataSvg';

const NoDataIndicatorBase = ({ text }) => {
  return (
    <div>
      <div className={styles.no_data_indicator}>
        <NoDataSvg />
      </div>
      <div>
        <p className={styles.no_data_indicator_text_container}>{text}</p>
      </div>
    </div>
  );
};

NoDataIndicatorBase.propTypes = exact({
  text: PropTypes.string.isRequired,
});

export const NoDataIndicator = React.memo(NoDataIndicatorBase);
NoDataIndicator.displayName = 'NoDataIndicator';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReorderIcon from '@material-ui/icons/Reorder';
import CloseIcon from '@material-ui/icons/Close';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './DraggableReportSections.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  height: 60,
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
  marginTop: 2,
  marginBottom: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.44,
  color: 'rgba(67, 67, 67, 0.87)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  width: '100%',
  background: 'white',
});

export const DraggableReportSections = ({
  items,
  onItemsReordered,
  onDeleteItemClick,
}) => {
  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      onItemsReordered(
        reorder(items, result.source.index, result.destination.index)
      );
    },
    [items, onItemsReordered]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <TransitionGroup>
              {items.map((item, index) => (
                <CSSTransition
                  key={item.id}
                  // this is required prop, but the actual value is the one in the css
                  timeout={750}
                  classNames={{
                    enter: styles['item-enter'],
                    enterActive: styles['item-enter-active'],
                    enterDone: styles['item-enter-done'],
                    exit: styles['item-exit'],
                    exitActive: styles['item-exit-active'],
                  }}
                >
                  <Draggable draggableId={item.id} index={index}>
                    {(itemProvided, itemSnapshot) => (
                      <div
                        ref={itemProvided.innerRef}
                        {...itemProvided.draggableProps}
                        {...itemProvided.dragHandleProps}
                        style={getItemStyle(
                          itemSnapshot.isDragging,
                          itemProvided.draggableProps.style
                        )}
                        data-test-id={item.id}
                      >
                        <div className={styles.report_card_container}>
                          <div className={styles.close_icon_container}>
                            <CloseIcon
                              onClick={() => {
                                onDeleteItemClick(item);
                              }}
                              style={{ fontSize: 20 }}
                            />
                          </div>
                          <p>{item.title}</p>
                          <div>
                            <ReorderIcon style={{ fontSize: 20 }} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                </CSSTransition>
              ))}
            </TransitionGroup>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableReportSections.propTypes = {
  items: PropTypes.array.isRequired,
  onItemsReordered: PropTypes.func.isRequired,
  onDeleteItemClick: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

export const HeadTopView = props => (
  <svg viewBox="0 0 320.04 303.62" className={props.rootStyle}>
    <defs>
      <style>
        {
          '.prefix__cls-1{fill:#e9c7a6}.prefix__cls-3,.prefix__cls-8{fill:red; fill-opacity: 0}.prefix__cls-5{fill:#2e2f3d;isolation:isolate;font-family:Roboto-Bold,Roboto;font-weight:700;font-size:11px}.prefix__cls-8{stroke:#393949;stroke-miterlimit:10;stroke-width:.5px;stroke-dasharray:4}.prefix__cls-10{fill:#e5e5e5}.prefix__cls-11{fill:#575865}'
        }
      </style>
    </defs>
    <g id="prefix__base">
      <path
        className="prefix__cls-1"
        d="M301.23 133s18.63-4 18.8 15.69-2.34 49.25-20.61 39.43 1.81-55.12 1.81-55.12zM20.81 132.91S0 128.82 0 148.47s3 49.29 23.24 39.61-2.43-55.17-2.43-55.17zM174.05 11.14H174a13.43 13.43 0 0 0-26.46 0h-.4c-11.74 0-14 9.67-14 9.67 8.4-2 26.59-3.22 27.5-3.28s19.07 1.29 27.47 3.29c-.04 0-2.32-9.67-14.06-9.68z"
        transform="translate(0 -.02)"
      />
      <ellipse cx={160.68} cy={159.86} rx={139.29} ry={143.76} fill="#f4cba6" />
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 96.2 49.62)"
        style={props.channelStyles.channel_text_AF7}
      >
        {'AF7'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 208.99 49.62)"
        style={props.channelStyles.channel_text_AF8}
      >
        {'AF8'}
      </text>
      <text
        transform="matrix(.97 0 0 1 269.38 152.31)"
        fontSize={9}
        fontFamily="Roboto-Bold,Roboto"
        fontWeight={700}
        fill="#2e2f3d"
        style={{
          isolation: 'isolate',
          ...props.channelStyles.channel_text_Tp10,
        }}
      >
        {'Tp10'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 31.04 153.81)"
        style={props.channelStyles.channel_text_Tp9}
      >
        {'Tp9'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 200.24 105.19)"
        style={props.channelStyles.channel_text_F4}
      >
        {'F4'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 155.05 105.19)"
        style={props.channelStyles.channel_text_Fz}
      >
        {'Fz'}
      </text>
      <text
        transform="matrix(.97 0 0 1 153.24 39.54)"
        fontSize={9.52}
        fontFamily="Roboto-Bold,Roboto"
        fontWeight={700}
        fill="#2e2f3d"
        style={{
          isolation: 'isolate',
          ...props.channelStyles.channel_text_Fpz,
        }}
      >
        {'Fpz'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 109.39 105.64)"
        style={props.channelStyles.channel_text_F3}
      >
        {'F3'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 98.63 163.18)"
        style={props.channelStyles.channel_text_C3}
      >
        {'C3'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 154.48 163.18)"
        style={props.channelStyles.channel_text_Cz}
      >
        {'Cz'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 209.44 163.18)"
        style={props.channelStyles.channel_text_C4}
      >
        {'C4'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 199.65 222.48)"
        style={props.channelStyles.channel_text_P4}
      >
        {'P4'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 154.53 221.47)"
        style={props.channelStyles.channel_text_Pz}
      >
        {'Pz'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 109.33 222.48)"
        style={props.channelStyles.channel_text_P3}
      >
        {'P3'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 118.81 271.83)"
        style={props.channelStyles.channel_text_O1}
      >
        {'O1'}
      </text>
      <text
        className="prefix__cls-5"
        transform="matrix(.97 0 0 1 188.9 271.83)"
        style={props.channelStyles.channel_text_O2}
      >
        {'O2'}
      </text>
    </g>
    <ellipse
      className="prefix__cls-8"
      cx={206.15}
      cy={101.41}
      rx={13.61}
      ry={14.05}
      data-name="location F4"
      id="channel_F4"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_F4}
    />
    <ellipse
      className="prefix__cls-8"
      cx={160.68}
      cy={102.38}
      rx={13.61}
      ry={14.05}
      data-name="location Fz"
      id="channel_Fz"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Fz}
    />
    <ellipse
      className="prefix__cls-8"
      cx={160.68}
      cy={36.9}
      rx={13.61}
      ry={14.05}
      data-name="location Fpz"
      id="channel_Fpz"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Fpz}
    />
    <ellipse
      className="prefix__cls-8"
      cx={115.36}
      cy={102.38}
      rx={13.61}
      ry={14.05}
      data-name="location F3"
      id="channel_F3"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_F3}
    />
    <ellipse
      className="prefix__cls-8"
      cx={215.98}
      cy={159.86}
      rx={13.61}
      ry={14.05}
      data-name="location C4"
      id="channel_C4"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_C4}
    />
    <ellipse
      className="prefix__cls-8"
      cx={160.68}
      cy={159.86}
      rx={13.61}
      ry={14.05}
      data-name="location Cz"
      id="channel_Cz"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Cz}
    />
    <ellipse
      className="prefix__cls-8"
      cx={105.04}
      cy={159.86}
      rx={13.61}
      ry={14.05}
      data-name="location C3"
      id="channel_C3"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_C3}
    />
    <ellipse
      className="prefix__cls-8"
      cx={206.15}
      cy={218.74}
      rx={13.61}
      ry={14.05}
      data-name="location P4"
      id="channel_P4"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_P4}
    />
    <ellipse
      className="prefix__cls-8"
      cx={160.68}
      cy={217.33}
      rx={13.61}
      ry={14.05}
      data-name="location Pz"
      id="channel_Pz"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Pz}
    />
    <ellipse
      className="prefix__cls-8"
      cx={115.83}
      cy={218.74}
      rx={13.61}
      ry={14.05}
      data-name="location P3"
      id="channel_P3"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_P3}
    />
    <ellipse
      className="prefix__cls-8"
      cx={195.63}
      cy={268.67}
      rx={13.61}
      ry={14.05}
      data-name="location O2"
      id="channel_O2"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_O2}
    />
    <ellipse
      className="prefix__cls-8"
      cx={125.55}
      cy={268.67}
      rx={13.61}
      ry={14.05}
      data-name="location O1"
      id="channel_O1"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_O1}
    />
    <ellipse
      className="prefix__cls-8"
      cx={40.16}
      cy={149.02}
      rx={13.61}
      ry={14.05}
      data-name="location Tp9"
      id="channel_Tp9"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Tp9}
    />
    <ellipse
      className="prefix__cls-8"
      cx={279.34}
      cy={149.02}
      rx={13.61}
      ry={14.05}
      data-name="location Tp10"
      id="channel_Tp10"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Tp10}
    />
    <ellipse
      className="prefix__cls-8"
      cx={105.18}
      cy={46.45}
      rx={13.61}
      ry={14.05}
      data-name="location Fp1"
      id="channel_Fp1"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Fp1 || props.channelStyles.channel_AF7}
    />
    <ellipse
      className="prefix__cls-8"
      cx={217.97}
      cy={46.45}
      rx={13.61}
      ry={14.05}
      data-name="location Fp2"
      id="channel_Fp2"
      onClick={props.onChannelClick}
      style={props.channelStyles.channel_Fp2 || props.channelStyles.channel_AF8}
    />
    <g id="prefix__muse">
      <path
        d="M161.1 14.75c76.09 0 138 59.86 139.49 134.28h3.67c0-75.73-64.1-137.13-143.16-137.13S17.94 73.3 17.94 149h3.65C23.15 74.61 85 14.75 161.1 14.75z"
        transform="translate(0 -.02)"
        fill="#393a47"
      />
      <path
        className="prefix__cls-10"
        d="M65.52 52l-2.43-2.8-11.31 13.09 2.1 1.84A139.12 139.12 0 0 1 65.52 52zM99.09 29q5.93-2.9 12.16-5.24l-1-3q-6 2.19-11.72 4.85zM150.59 12.27l.1 2.86c3.42-.25 6.89-.38 10.38-.38 3.91 0 7.78.17 11.62.48l-.1-2.89c-3.8-.29-7.64-.43-11.52-.44-3.48 0-6.99.1-10.48.37zM212.13 24.22q6 2.31 11.68 5.13v-3.62q-5.7-2.67-11.68-4.85zM259.71 49.63l-2.59 2.77a140 140 0 0 1 11.16 11.69l2.85-1.84-4.59-6c-2.19-2.25-4.47-4.43-6.83-6.62z"
        transform="translate(0 -.02)"
      />
      <path
        className="prefix__cls-11"
        d="M161.1 11.88c76.09 0 138 59.86 139.49 134.28h3.67C304.26 70.42 240.16 9 161.1 9S17.94 70.42 17.94 146.16h3.65C23.15 71.74 85 11.88 161.1 11.88z"
        transform="translate(0 -.02)"
      />
      <path
        className="prefix__cls-11"
        d="M30.59 100.5l-1.45-.56a1.75 1.75 0 0 0-.55-.12h-5.81a1.68 1.68 0 0 0-1.67 1.3c-2 9.42-14.08 67.61-9.78 97.92a8.11 8.11 0 0 0 2 4.25l4.46 5a4.52 4.52 0 0 0 6.38.33l.12-.11 1.39-1.37a3.7 3.7 0 0 0 1.1-3c-1.08-10.16-5.9-62.61 4.89-101.72a1.62 1.62 0 0 0-1.08-1.92zM289.65 100.5l1.46-.56a1.94 1.94 0 0 1 .63-.11h5.76a1.68 1.68 0 0 1 1.67 1.3c2 9.42 14.08 67.61 9.79 97.92a8.19 8.19 0 0 1-2 4.25l-4.45 5a4.52 4.52 0 0 1-6.38.33l-.12-.11-1.42-1.34a3.73 3.73 0 0 1-1.11-3c1.08-10.16 5.9-62.61-4.88-101.72a1.63 1.63 0 0 1 1.05-1.96z"
        transform="translate(0 -.02)"
      />
      <path
        d="M299.43 193.21L298 210.32a2.75 2.75 0 0 0 3.26-.61l.65-16.36a1.24 1.24 0 0 0-1.23-1.25 1.25 1.25 0 0 0-1.25 1.11z"
        transform="translate(0 -.02)"
        fill="#fff"
      />
    </g>
  </svg>
);

HeadTopView.propTypes = exact({
  channelStyles: PropTypes.object,
  onChannelClick: PropTypes.func,
  rootStyle: PropTypes.string,
});

HeadTopView.defaultProps = {
  channelStyles: {},
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import Hidden from '@material-ui/core/Hidden';
import styles from './PlacementRecommendationStep.scss';
import { RightDownArrow } from '../../Core/Icons/RightDownArrow';
import { HeadTopView } from '../../Core/images/HeadTopView';
import eyesClosedAsset from '../../../assets/eyes_closed.png';
import eyesOpenedAsset from '../../../assets/eyes_open.png';
import { withTranslation } from '../../Core/hocs/withTranslation/withTranslation';

class PlacementRecommendationStepBase extends Component {
  state = {
    isPlacementsSelectShown: this.props.isEditingMode,
  };

  renderEyeStateRecommendation = () => {
    const { t, originalEyeState } = this.props;

    if (originalEyeState === 'Eyes closed') {
      return (
        <p>
          {t('we-recommend-that')}{' '}
          <span style={{ fontWeight: 'bold' }}>{t('closed')}</span>
          {t('during-the-training')}
        </p>
      );
    }

    if (originalEyeState === 'Eyes open') {
      return (
        <p>
          {t('we-recommend-that')}{' '}
          <span style={{ fontWeight: 'bold' }}>{t('open')}</span>
          {t('during-the-training')}
        </p>
      );
    }

    if (originalEyeState === 'Opened or Closed') {
      return (
        <React.Fragment>
          <div>
            <p>{t('opened_or_closed_recommendation')}</p>
          </div>
          <div>
            <FormControl
              classes={{
                root: styles.mu_form_control_root,
              }}
            >
              <NativeSelect
                value={this.props.isEyesClosed}
                onChange={this.props.onEyesClosedChange}
                classes={{
                  icon: styles.mu_native_select_icon,
                  root: styles.mu_select_menu,
                }}
                IconComponent={RightDownArrow}
              >
                <option value="false">{t('eyes_opened_option')}</option>
                <option value="true">{t('eyes_closed_option')}</option>
              </NativeSelect>
            </FormControl>
          </div>
        </React.Fragment>
      );
    }

    throw new Error('unexpected eye state');
  };

  renderHeadTopView() {
    const chosenPlacement = this.props.placements[
      this.props.chosenPlacementName
    ];

    const activeChannel = chosenPlacement.trainingChannel
      .replace('(headset)', '')
      .trim();
    const referenceChannel = chosenPlacement.referenceChannel
      .replace('(headset)', '')
      .trim();
    const activeChannelName =
      activeChannel === 'Right auxiliary'
        ? chosenPlacement.auxiliaryLocation
        : activeChannel;

    const channelPrefix = 'channel_';
    const activeAndReferenceChannelsStyle = {
      [channelPrefix + activeChannelName]: {
        fill: '#279bfd',
        stroke: '#279bfd',
        fillOpacity: 0.4,
      },
      [channelPrefix + referenceChannel]: {
        fill: '#3cd6be',
        stroke: '#3cd6be',
        fillOpacity: 0.4,
      },
    };

    const hiddenChannelsStyles = {};

    const channelStyles = {
      ...activeAndReferenceChannelsStyle,
      ...hiddenChannelsStyles,
    };

    return (
      <HeadTopView
        channelStyles={channelStyles}
        rootStyle={styles.root_style_head_top_down_view}
      />
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.root}>
        <div>
          <div>
            <h5>{t('eye_state_label')}</h5>
            {this.renderEyeStateRecommendation()}
          </div>

          <div>
            <h5>{t('recommended-placement')}</h5>
            <p>
              {t('based-on-the-training-protocol')}
              <span style={{ fontWeight: 'bold' }}>
                {this.props.recommendedChosenPlacementName}
              </span>
            </p>

            {!this.state.isPlacementsSelectShown && (
              <p>
                <a
                  onClick={() => {
                    this.setState({
                      isPlacementsSelectShown: true,
                    });
                    new MyEvent(
                      'fast_create_session_basic_choose_different_placement'
                    ).log({
                      clinicId: sessionStorage.userId,
                      customerId: this.props.customerId,
                    });
                  }}
                  href="#"
                >
                  {t('choose-a-different-placement')}
                </a>
              </p>
            )}

            {this.state.isPlacementsSelectShown && (
              <React.Fragment>
                <FormControl
                  classes={{
                    root: styles.mu_form_control_root,
                  }}
                >
                  <NativeSelect
                    value={this.props.chosenPlacementName}
                    onChange={event =>
                      this.props.onChosenPlacementNameChange(event.target.value)
                    }
                    classes={{
                      icon: styles.mu_native_select_icon,
                      root: styles.mu_select_menu,
                    }}
                    IconComponent={RightDownArrow}
                  >
                    {Object.keys(this.props.placements).map(placementName => (
                      <option key={placementName} value={placementName}>
                        {placementName}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
                <p>
                  <a
                    onClick={() => {
                      this.props.onChosenPlacementNameChange(
                        this.props.recommendedChosenPlacementName
                      );
                      this.setState({
                        isPlacementsSelectShown: false,
                      });
                      new MyEvent(
                        'fast_create_session_click_back_to_recommended_placement'
                      ).log({
                        clinicId: sessionStorage.userId,
                        customerId: this.props.customerId,
                      });
                    }}
                    href="#"
                  >
                    {t('back_to_recommended_placement')}
                  </a>
                </p>
              </React.Fragment>
            )}
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: this.props.placementDescriptionHTML,
            }}
          />
        </div>

        <Hidden xsDown>
          <div className={styles.images_container}>
            <img
              className={styles.eyes_state_image}
              src={this.props.isEyesClosed ? eyesClosedAsset : eyesOpenedAsset}
              alt={
                this.props.isEyesClosed
                  ? t('eyes_closed_option')
                  : t('eyes_opened_option')
              }
            />
            {this.renderHeadTopView()}
          </div>
        </Hidden>
      </div>
    );
  }
}

PlacementRecommendationStepBase.propTypes = exact({
  t: PropTypes.func.isRequired,
  onEyesClosedChange: PropTypes.func.isRequired,
  onChosenPlacementNameChange: PropTypes.func.isRequired,
  originalEyeState: PropTypes.string.isRequired,
  chosenPlacementName: PropTypes.string.isRequired,
  recommendedChosenPlacementName: PropTypes.string.isRequired,
  isEyesClosed: PropTypes.bool.isRequired,
  placements: PropTypes.object.isRequired,
  placementDescriptionHTML: PropTypes.string.isRequired,
  customerId: PropTypes.string,
  isEditingMode: PropTypes.bool,
});

export const PlacementRecommendationStep = withTranslation(
  PlacementRecommendationStepBase
);

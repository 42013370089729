import React, { useState, useRef, useEffect, useCallback } from 'react';
import uuidv4 from 'uuid/v4';
import exact from 'prop-types-exact';
import { Paper, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import has from 'lodash/has';
import styles from './ManageQuestionnairesPage.scss';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import {
  FIREBASE_ORDER_BY_FUNCTIONS,
  FIREBASE_FILTERING_FUNCTIONS,
} from '../../models/firebase/firebase';
import { EasierCustomTable } from '../../Core/Components/CustomTable/EasierCustomTable';
import { CustomDialog } from '../../Core/Components/CustomDialog/CustomDialog';
import {
  QuestionnaireDialog,
  QUESTIONNAIRE_DIALOG_RENDERED_STEPS,
} from './QuestionnaireDialog/QuestionnaireDialog';
import { injectIDs } from '../../utils/utils';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { useEventLoggerWithDefaultData } from '../../Core/hooks/useEventLoggerWithDefaultData';
import { answersScalesTypes } from '../../models/symptom-tracker/symptom-tracker';
import { isQuestionDeletableKey } from './manageQuestionnairesPageUtils';

const initialValue = Symbol('iv');
const answersScalesInitialValue = {};

const answerScalesValueProcessor = scales =>
  Object.values(injectIDs(scales || {})).filter(
    scale => !scale.clinicId || scale.clinicId === sessionStorage.userId
  );
const getDefaultQuestionnaireQuestion = ({
  title = ' New question text',
} = {}) => ({
  title: {
    en: title,
  },
  instruction: {
    en: 'now does not appear',
  },
  answerScaleId: 'id',
  questionId: uuidv4(),
  [isQuestionDeletableKey]: true,
});

const getDefaultNewQuestionnaire = () => ({
  name: 'New questionnaire',
  instruction:
    'Please answer these questions based on how you felt in the last 4 weeks.',
  questions: [
    getDefaultQuestionnaireQuestion({ title: 'First question text' }),
    getDefaultQuestionnaireQuestion({ title: 'Second question text' }),
    getDefaultQuestionnaireQuestion({ title: 'Third question text' }),
  ],
  clinicId: sessionStorage.userId,
});

const ManageQuestionnairesPageBase = () => {
  const t = useTranslation();
  const logManageQuestionnairesEvent = useEventLoggerWithDefaultData({
    prefix: 'manage_questionnaires_page',
  });

  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );

  const [questionnaireInEdit, setQuestionnaireInEdit] = useState(null);
  const [questionnaireDialogHeader, setQuestionnaireDialogHeader] = useState(
    null
  );
  const [selectedAnswersScale, setSelectedAnswersScale] = useState(undefined);
  const [
    questionnaireDialogRenderedStep,
    setQuestionnaireDialogRenderedStep,
  ] = useState(QUESTIONNAIRE_DIALOG_RENDERED_STEPS.FORM);
  const [questionnaireNameErrorMsg, setQuestionnaireNameErrorMsg] = useState(
    null
  );
  const [
    questionnaireInstructionErrorMsg,
    setQuestionnaireInstructionErrorMsg,
  ] = useState(null);

  const [questionnaireToDelete, setQuestionnaireToDelete] = useState('');

  const [toggleDiffrentScale, setToggleDiffrentScale] = useState(false);
  const [scalePerQuestion, setScalePerQuestion] = useState({});

  const [clinicQuestionnaires] = useFirebaseDB({
    path: 'symptomTracker/questionnaires',
    orderByType: FIREBASE_ORDER_BY_FUNCTIONS.ORDER_BY_CHILD,
    orderByArguments: useRef(['clinicId']).current,
    filterType: FIREBASE_FILTERING_FUNCTIONS.EQUAL_TO,
    filterTypeArguments: useRef([sessionStorage.userId]).current,
    initialValue,
    defaultValue: useRef({}).current,
    valueProcessor: useRef(questionnaires => injectIDs(questionnaires)).current,
  });

  const [answerScales] = useFirebaseDB({
    path: 'symptomTracker/answerScales',
    onceListener: true,
    valueProcessor: answerScalesValueProcessor,
    initialValue: answersScalesInitialValue,
  });

  const getSortedAnswersScales = useCallback(
    () =>
      Object.values(answerScales).sort((scale1, scale2) => {
        if (
          scale1.type === answersScalesTypes.numbers &&
          scale2.type === answersScalesTypes.numbers
        ) {
          const sortedNumbersLabels = ['0-5', '0-10', '1-10'];
          return (
            sortedNumbersLabels.indexOf(scale1.label) -
            sortedNumbersLabels.indexOf(scale2.label)
          );
        }
        if (scale1.type === answersScalesTypes.numbers) {
          return -1;
        }
        if (scale2.type === answersScalesTypes.numbers) {
          return 1;
        }
        return scale1.label.localeCompare(scale2.label);
      }),
    [answerScales]
  );

  useEffect(() => {
    if (answerScales !== answersScalesInitialValue) {
      const sortedAnswersScales = getSortedAnswersScales();
      setSelectedAnswersScale(sortedAnswersScales[0].id);
    }
  }, [answerScales, getSortedAnswersScales]);

  useEffect(() => {
    if (!isComponentReadyToRender && clinicQuestionnaires !== initialValue) {
      setIsComponentReadyToRender(true);
      // eslint-disable-next-line no-undef
      hideLoading();
    }
  }, [clinicQuestionnaires, isComponentReadyToRender]);

  const onAddQuestionnaire = () => {
    setQuestionnaireDialogHeader(t('add-a-new-questionnaire'));
    setQuestionnaireInEdit(getDefaultNewQuestionnaire());
    logManageQuestionnairesEvent({ name: 'on_add_questionnaire' });
  };
  const onEditQuestionnaire = questionnaire => {
    logManageQuestionnairesEvent({
      name: 'on_edit_questionnaire',
      data: {
        ...questionnaire,
      },
    });
    setQuestionnaireDialogHeader(t('edit-questionnaire'));
    setQuestionnaireInEdit(questionnaire);
    setSelectedAnswersScale(
      Object.values(questionnaire.questions)[0].answerScaleId
    );
    const copy = {};
    Object.values(questionnaire.questions).forEach(question => {
      copy[question.questionId] = question.answerScaleId;
    });
    setToggleDiffrentScale(!!questionnaire.toggleDiffrentScale);
    setScalePerQuestion(copy);
  };

  const onDeleteQuestionnaire = () => {
    database
      .ref(`symptomTracker/questionnaires/${questionnaireToDelete}/isDeleted`)
      .set(true);
    setQuestionnaireToDelete('');
  };

  const onAddQuestionToQuestionnaire = () => {
    logManageQuestionnairesEvent({ name: 'on_add_question' });
    setQuestionnaireInEdit(prvQuestionnaire => ({
      ...prvQuestionnaire,
      questions: [
        ...prvQuestionnaire.questions,
        getDefaultQuestionnaireQuestion(),
      ],
    }));
  };

  const resetQuestionnaireDialogState = () => {
    setQuestionnaireInEdit(null);
    setQuestionnaireNameErrorMsg(null);
    setQuestionnaireInstructionErrorMsg(null);
    const sortedAnswersScales = getSortedAnswersScales();
    setSelectedAnswersScale(sortedAnswersScales[0].id);
    setQuestionnaireDialogRenderedStep(
      QUESTIONNAIRE_DIALOG_RENDERED_STEPS.FORM
    );
    setToggleDiffrentScale(false);
    setScalePerQuestion({});
  };

  const validateQuestionnaireName = name => {
    if (name.trim().length > 40) {
      setQuestionnaireNameErrorMsg(t('questionnaire-name-no-longer-than-40'));
      return false;
    }
    if (name.trim().length === 0) {
      setQuestionnaireNameErrorMsg(t('questionnaire-name-should-not-be-empty'));
      return false;
    }
    if (
      !questionnaireInEdit.id &&
      Object.values(clinicQuestionnaires).find(
        questionnaire => questionnaire.name === name.trim()
      )
    ) {
      setQuestionnaireNameErrorMsg(t('another-questionnaire-with-this-name'));
      return false;
    }

    setQuestionnaireNameErrorMsg(null);
    return true;
  };

  const validateQuestionnaireInstruction = instruction => {
    if (instruction.trim().length === 0) {
      setQuestionnaireInstructionErrorMsg(
        t('questionnaire-instruction-should-not-be-empty')
      );
      return false;
    }
    setQuestionnaireInstructionErrorMsg(null);
    return true;
  };

  const onQuestionnaireDialogPrimaryBtnClick = async () => {
    logManageQuestionnairesEvent({
      name: 'on_questionnaire_dialog_primary_btn_click',
    });
    if (
      questionnaireDialogRenderedStep ===
        QUESTIONNAIRE_DIALOG_RENDERED_STEPS.FORM &&
      validateQuestionnaireName(questionnaireInEdit.name) &&
      validateQuestionnaireInstruction(questionnaireInEdit.instruction)
    ) {
      setQuestionnaireDialogRenderedStep(
        QUESTIONNAIRE_DIALOG_RENDERED_STEPS.LOADING
      );
      questionnaireInEdit.questions = questionnaireInEdit.questions.map(
        question => ({
          ...question,
          instruction: { en: questionnaireInEdit.instruction },
          answerScaleId: !toggleDiffrentScale
            ? selectedAnswersScale
            : scalePerQuestion[question.questionId] || selectedAnswersScale,
          [isQuestionDeletableKey]: null,
        })
      );
      questionnaireInEdit.toggleDiffrentScale = toggleDiffrentScale;
      if (has(questionnaireInEdit, 'id')) {
        await database
          .ref(`symptomTracker/questionnaires/${questionnaireInEdit.id}`)
          .update(questionnaireInEdit);
      } else {
        const newQuestionnaireRef = database
          .ref('symptomTracker/questionnaires')
          .push();

        await newQuestionnaireRef.set(questionnaireInEdit);
      }
      setQuestionnaireDialogRenderedStep(
        QUESTIONNAIRE_DIALOG_RENDERED_STEPS.SUCCESS
      );
    }

    if (
      questionnaireDialogRenderedStep ===
      QUESTIONNAIRE_DIALOG_RENDERED_STEPS.SUCCESS
    ) {
      resetQuestionnaireDialogState();
    }
  };

  const renderTable = () => {
    const rows = Object.values(clinicQuestionnaires)
      .filter(questionnaire => !questionnaire.isDeleted)
      .map(questionnaire => {
        return [
          <span className={styles.cell}>{questionnaire.name}</span>,
          <span className={styles.cell}>{questionnaire.questions.length}</span>,
          <span className={styles.actions_container}>
            <a
              role="button"
              onClick={() => onEditQuestionnaire(questionnaire)}
              tabIndex={0}
            >
              <EditIcon style={{ fontSize: 17 }} color="primary" />
            </a>
            <a
              role="button"
              onClick={() => setQuestionnaireToDelete(questionnaire.id)}
              tabIndex={0}
            >
              <DeleteIcon
                style={{ fontSize: 17, marginLeft: 10, color: '#757575' }}
              />
            </a>
          </span>,
        ];
      });

    const rawTableHeads = [
      'Custom Questionnaire Name',
      'Number of Questions',
      'Edit',
    ];
    const colsKeys = rawTableHeads;
    const rowsKeys = ['head', ...Object.keys(clinicQuestionnaires)];
    const tableHeadsRow = rawTableHeads.map(content => (
      <span className={styles.head_cell}>{content}</span>
    ));
    const rowsWithHead = [tableHeadsRow, ...rows];

    return (
      <EasierCustomTable
        rows={rowsWithHead}
        rowsKeys={rowsKeys}
        colsKeys={colsKeys}
      />
    );
  };

  return (
    isComponentReadyToRender && (
      <Paper>
        <div className={styles.questionnaires_table_header}>
          <div>
            <h3>{t('manage-questionnaires')}</h3>
            <p>
              Edit or add a custom questionnaire. Questionnaires listed here can
              be selected for your client to complete as part of an assessment.
              To learn more about questionnaires, click{' '}
              <a
                href="https://intercom.help/dashboard-myndlift/en/articles/4488496-symptom-questionnaires-for-tracking-progress"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
          <div>
            <Button
              onClick={onAddQuestionnaire}
              color="primary"
              variant="contained"
            >
              <span className={styles.btn}>{t('add-new-questionnaire')}</span>
            </Button>
          </div>
        </div>

        <div className={styles.table_container}>
          {Object.keys(clinicQuestionnaires).filter(
            clinicQuestionnaireId =>
              !clinicQuestionnaires[clinicQuestionnaireId].isDeleted
          ).length > 0 ? (
            renderTable()
          ) : (
            <p className={styles.no_questionnaires_text}>
              {t('no-custom-questionnaires')}
            </p>
          )}
        </div>

        <QuestionnaireDialog
          isOpen={!!questionnaireInEdit}
          questionnaire={questionnaireInEdit}
          dialogHeader={questionnaireDialogHeader}
          onAddNewQuestion={onAddQuestionToQuestionnaire}
          onEditQuestionnaire={questionnaire => {
            setQuestionnaireInEdit({ ...questionnaire });
          }}
          onPrimaryBtnClick={onQuestionnaireDialogPrimaryBtnClick}
          isPrimaryBtnDisabled={
            questionnaireInEdit && questionnaireInEdit.questions.length === 0
          }
          onClose={() => {
            if (
              questionnaireDialogRenderedStep !==
              QUESTIONNAIRE_DIALOG_RENDERED_STEPS.LOADING
            ) {
              logManageQuestionnairesEvent({
                name: 'on_questionnaire_dialog_close',
              });
              resetQuestionnaireDialogState();
            }
          }}
          onCancel={() => {
            logManageQuestionnairesEvent({
              name: 'on_questionnaire_dialog_close',
            });
            resetQuestionnaireDialogState();
          }}
          renderedStep={questionnaireDialogRenderedStep}
          answerScalesOptions={getSortedAnswersScales().map(scale => ({
            value: scale.id,
            label: scale.label,
          }))}
          selectedAnswersScale={selectedAnswersScale}
          onSelectedAnswersScaleChange={(newScale, questionId) => {
            logManageQuestionnairesEvent({
              name: 'on_answers_scale_change',
              data: {
                newScale,
              },
            });
            if (questionId) {
              const copy = JSON.parse(JSON.stringify(scalePerQuestion));
              copy[questionId] = newScale;
              setScalePerQuestion(copy);
              return;
            }
            setSelectedAnswersScale(newScale);
          }}
          validateQuestionnaireName={validateQuestionnaireName}
          validateQuestionnaireInstruction={validateQuestionnaireInstruction}
          questionnaireInstructionErrorMsg={questionnaireInstructionErrorMsg}
          questionnaireNameErrorMsg={questionnaireNameErrorMsg}
          toggleDiffrentScale={toggleDiffrentScale}
          setToggleDiffrentScale={setToggleDiffrentScale}
          scalePerQuestion={scalePerQuestion}
        />
        <CustomDialog
          isOpen={questionnaireToDelete}
          onPrimaryBtnClick={onDeleteQuestionnaire}
          onSecondaryBtnClick={() => setQuestionnaireToDelete('')}
          primaryBtnContent="DELETE"
          secondaryBtnContent="CANCEL"
        >
          <p className={styles.dialog_header}>
            Are you sure you want to delete this custom questionnaire? If you
            delete it, you won’t be able to access it anymore
          </p>
        </CustomDialog>
      </Paper>
    )
  );
};

ManageQuestionnairesPageBase.propTypes = exact({});

export const ManageQuestionnairesPage = React.memo(
  ManageQuestionnairesPageBase
);
ManageQuestionnairesPage.displayName = 'ManageQuestionnairesPage';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ClientReport_size_17{font-size:17px}.ClientReport_size_10{font-size:10px}.ClientReport_size_11{font-size:11px}.ClientReport_size_13{font-size:13px}.ClientReport_size_15{font-size:15px}.ClientReport_size_14{font-size:14px}.ClientReport_size_16{font-size:16px}.ClientReport_size_12{font-size:12px}.ClientReport_extra_header{font-size:18px;font-weight:500;color:#000000}.ClientReport_header,.ClientReport_client_report_title p{font-size:17px;font-weight:500;color:#000000}.ClientReport_header_only_font_size{font-size:17px;font-weight:500}.ClientReport_sub_header{font-size:13px;color:#878787}.ClientReport_sub_header_black{font-size:13px;color:black}.ClientReport_sub_header_only_font_size{font-size:13px}.ClientReport_paragraph_only_font_size{font-size:12px}.ClientReport_tab_font_size{font-size:13px}.ClientReport_pop_out_header{font-size:20px}.ClientReport_title,.ClientReport_client_report_description_container>p:first-child{font-size:15px;font-weight:500;color:#484848}.ClientReport_title_black{font-size:15px;font-weight:500;color:black}.ClientReport_title_only_font_size{font-size:15px}.ClientReport_paragraph,.ClientReport_client_report_description_container>p:nth-child(2){font-size:12px;color:#484848}.ClientReport_btn{font-size:14px}.ClientReport_client_report{padding:29px 32px}.ClientReport_client_report_title{margin-bottom:27px}.ClientReport_client_report_actions_container{display:flex;flex-wrap:wrap;justify-content:space-between;margin-bottom:27px}.ClientReport_client_report_description_container{margin-bottom:20px}.ClientReport_client_report_description_container>p{margin:0px}.ClientReport_client_report_description_container>p:first-child{margin-bottom:8px}.ClientReport_draggable_reports_container{max-height:46vh;padding:4px 4px;overflow-y:auto}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ClientReport_size_17",
	"size_10": "ClientReport_size_10",
	"size_11": "ClientReport_size_11",
	"size_13": "ClientReport_size_13",
	"size_15": "ClientReport_size_15",
	"size_14": "ClientReport_size_14",
	"size_16": "ClientReport_size_16",
	"size_12": "ClientReport_size_12",
	"extra_header": "ClientReport_extra_header",
	"header": "ClientReport_header",
	"client_report_title": "ClientReport_client_report_title",
	"header_only_font_size": "ClientReport_header_only_font_size",
	"sub_header": "ClientReport_sub_header",
	"sub_header_black": "ClientReport_sub_header_black",
	"sub_header_only_font_size": "ClientReport_sub_header_only_font_size",
	"paragraph_only_font_size": "ClientReport_paragraph_only_font_size",
	"tab_font_size": "ClientReport_tab_font_size",
	"pop_out_header": "ClientReport_pop_out_header",
	"title": "ClientReport_title",
	"client_report_description_container": "ClientReport_client_report_description_container",
	"title_black": "ClientReport_title_black",
	"title_only_font_size": "ClientReport_title_only_font_size",
	"paragraph": "ClientReport_paragraph",
	"btn": "ClientReport_btn",
	"client_report": "ClientReport_client_report",
	"client_report_actions_container": "ClientReport_client_report_actions_container",
	"draggable_reports_container": "ClientReport_draggable_reports_container"
};
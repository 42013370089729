export const CHANNELS = Object.freeze({
  Tp9: 'Tp9 (headset)',
  Fp1: 'Fp1 (headset)',
  Fp2: 'Fp2 (headset)',
  Tp10: 'Tp10 (headset)',
  Fpz: 'Fpz (headset)',
  F3: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'F3',
  },
  Fz: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'Fz',
  },
  F4: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'F4',
  },
  C3: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'C3',
  },
  Cz: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'Cz',
  },
  C4: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'C4',
  },
  P3: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'P3',
  },
  Pz: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'Pz',
  },
  P4: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'P4',
  },
  O1: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'O1',
  },
  O2: {
    name: 'Right auxiliary',
    auxiliaryLocation: 'O2',
  },
});

export const CHANNEL_IDS = {
  AF7: 'AF7',
  AF8: 'AF8',
  Tp9: 'Tp9',
  Fp1: 'Fp1',
  Fp2: 'Fp2',
  Tp10: 'Tp10',
  Fpz: 'Fpz',
  F3: 'F3',
  Fz: 'Fz',
  F4: 'F4',
  C3: 'C3',
  Cz: 'Cz',
  C4: 'C4',
  P3: 'P3',
  Pz: 'Pz',
  P4: 'P4',
  O1: 'O1',
  O2: 'O2',
};

export const isAuxiliaryChannel = channel =>
  !(typeof CHANNELS[channel] === 'string' || channel === null);

export const CHANNEL_IDS_TO_NAMES = {
  [CHANNEL_IDS.Cz]: 'Central (Cz)',
  [CHANNEL_IDS.O1]: 'Posterior (O1)',
  [CHANNEL_IDS.F3]: 'Frontal Left (F3)',
  [CHANNEL_IDS.F4]: 'Frontal Right (F4)',
  [CHANNEL_IDS.Fz]: 'Frontal (Fz)',
  [CHANNEL_IDS.Pz]: 'Parietal (Pz)',
  [CHANNEL_IDS.AF7]: 'Frontal Left (Af7)',
  [CHANNEL_IDS.AF8]: 'Frontal Right (Af8)',
  [CHANNEL_IDS.Tp9]: 'Temporal Left (Tp9)',
  [CHANNEL_IDS.Tp10]: 'Temporal Right (Tp10)',
};

export const electrodesNameAndOrder = {
  F3: {
    name: 'F3',
    order: 3,
  },
  F4: {
    name: 'F4',
    order: 5,
  },
  Fp1: {
    name: 'AF7',
    order: 1,
  },
  Fp2: {
    name: 'AF8',
    order: 2,
  },
  Fz: {
    name: 'Fz',
    order: 4,
  },
  Tp9: {
    name: 'Tp9',
    order: 7,
  },
  Tp10: {
    name: 'Tp10',
    order: 8,
  },
  O1: {
    name: 'O1',
    order: 9,
  },
  Cz: {
    name: 'Cz',
    order: 6,
  },
  Pz: {
    name: 'Pz',
    order: 10,
  },
};

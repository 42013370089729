import has from 'lodash/has';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';
import { defensiveThrow, throwForRequiredParam } from '../../utils/utils';
import { ASSESSMENT_COLORS } from '../assessments/assessments';

const isNumberInRange = ({
  number,
  range,
  inclusiveStart = false,
  inclusiveEnd = false,
}) => {
  if (typeof range[0] === 'undefined' || typeof range[1] === 'undefined') {
    return false;
  }

  if (inclusiveStart && inclusiveEnd) {
    return number >= range[0] && number <= range[1];
  }

  if (inclusiveStart) {
    return number >= range[0] && number < range[1];
  }

  if (inclusiveEnd) {
    return number > range[0] && number <= range[1];
  }

  return number > range[0] && number < range[1];
};

export const getSwingleCheckScoreAndColor = ({ check, result }) => {
  if (
    !(has(check, 'lowerCutoff') || has(check, 'lowerEqualCutoff')) &&
    !(has(check, 'upperCutoff') || has(check, 'upperEqualCutoff'))
  ) {
    defensiveThrow({ error: new Error('Incorrect check object ') });
  }

  const getScoreBasedOnDistance = (
    point1 = throwForRequiredParam('point1'),
    point2 = throwForRequiredParam('point2')
  ) => {
    if (Math.abs(point1 - point2) <= (5 / 100) * point2) {
      return 0.75;
    }
    if (Math.abs(point1 - point2) <= (10 / 100) * point2) {
      return 0.5;
    }

    return 0;
  };

  const getScoreBasedOnRange = (
    score = throwForRequiredParam('score'),
    range1 = throwForRequiredParam('range1'),
    range2 = throwForRequiredParam('range2')
  ) => {
    return +((score - range1) / (range2 - range1)).toFixed(2);
  };

  if (
    isNumberInRange({
      number: result,
      range: [check.lowerCutoff, check.lowerCutoff2],
      inclusiveStart: true,
      inclusiveEnd: false,
    })
  ) {
    return [
      getScoreBasedOnRange(
        result,
        check.checkRange[0].start,
        // eslint-disable-next-line no-nested-ternary
        check.middleLowerEqualCutoff
          ? check.middleLowerEqualCutoff
          : check.middleLowerCutoff
          ? check.middleLowerCutoff
          : check.lowerEqualCutoff2
      ),
      ASSESSMENT_COLORS.BETWEEN_NEGATIVE_AND_ALMOST_POSITIVE,
    ];
  }

  if (
    isNumberInRange({
      number: result,
      range: [
        has(check, 'lowerEqualCutoff')
          ? check.lowerEqualCutoff
          : check.lowerCutoff,
        check.middleLowerCutoff,
      ],
      inclusiveEnd: false,
      inclusiveStart: !has(check, 'lowerEqualCutoff'),
    })
  ) {
    return [
      getScoreBasedOnRange(
        result,
        check.checkRange ? check.checkRange[0].start : 0,
        check.middleLowerCutoff
      ),
      ASSESSMENT_COLORS.ALMOST_POSITIVE,
    ];
  }

  if (
    isNumberInRange({
      number: result,
      range: [check.middleLowerCutoff, check.middleUpperCutoff],
      inclusiveStart: false,
      inclusiveEnd: false,
    })
  ) {
    return [1, ASSESSMENT_COLORS.POSITIVE];
  }

  if (
    isNumberInRange({
      number: result,
      range: [check.middleUpperCutoff, check.upperCutoff2],
      inclusiveStart: false,
      inclusiveEnd: false,
    })
  ) {
    return [
      getScoreBasedOnRange(
        result,
        check.middleUpperCutoff,
        check.checkRange ? check.checkRange[0].end : check.maxScore
      ),
      ASSESSMENT_COLORS.ALMOST_POSITIVE,
    ];
  }

  if (
    isNumberInRange({
      number: result,
      range: [check.middleUpperEqualCutoff, check.upperCutoff2],
      inclusiveStart: true,
      inclusiveEnd: false,
    })
  ) {
    return [
      1 -
        getScoreBasedOnRange(
          result,
          check.middleUpperEqualCutoff,
          check.checkRange ? check.checkRange[0].end : check.maxScore
        ),
      ASSESSMENT_COLORS.ALMOST_POSITIVE,
    ];
  }

  if (
    isNumberInRange({
      number: result,
      range: [
        check.upperCutoff2 || check.upperEqualCutoff2,
        has(check, 'upperEqualCutoff')
          ? check.upperEqualCutoff
          : check.upperCutoff,
      ],
      inclusiveStart: has(check, 'upperEqualCutoff2'),
      inclusiveEnd: !has(check, 'upperEqualCutoff'),
    })
  ) {
    return [
      1 -
        getScoreBasedOnRange(
          result,
          // eslint-disable-next-line no-nested-ternary
          check.middleUpperEqualCutoff
            ? check.middleUpperEqualCutoff
            : check.middleUpperCutoff
            ? check.middleUpperCutoff
            : check.upperEqualCutoff2,
          check.checkRange ? check.checkRange[0].end : check.maxScore
        ),
      ASSESSMENT_COLORS.BETWEEN_NEGATIVE_AND_ALMOST_POSITIVE,
    ];
  }

  if (
    isNumberInRange({
      number: result,
      range: [
        check.middleUpperCutoff,
        has(check, 'upperEqualCutoff')
          ? check.upperEqualCutoff
          : check.upperCutoff,
      ],
      inclusiveStart: false,
      inclusiveEnd: !has(check, 'upperEqualCutoff'),
    })
  ) {
    return [
      1 -
        getScoreBasedOnRange(
          result,
          check.middleUpperCutoff,
          check.checkRange ? check.checkRange[0].end : check.maxScore
        ),
      ASSESSMENT_COLORS.ALMOST_POSITIVE,
    ];
  }

  if (
    isNumberInRange({
      number: result,
      range: [
        check.middleUpperEqualCutoff,
        has(check, 'upperEqualCutoff')
          ? check.upperEqualCutoff
          : check.upperCutoff,
      ],
      inclusiveStart: true,
      inclusiveEnd: !has(check, 'upperEqualCutoff'),
    })
  ) {
    return [
      1 -
        getScoreBasedOnRange(
          result,
          check.middleUpperEqualCutoff,
          check.checkRange ? check.checkRange[0].end : check.maxScore
        ),
      ASSESSMENT_COLORS.ALMOST_POSITIVE,
    ];
  }

  if (has(check, 'lowerEqualCutoff') && has(check, 'upperEqualCutoff')) {
    if (result > check.lowerEqualCutoff && result < check.upperEqualCutoff) {
      return [1, ASSESSMENT_COLORS.POSITIVE];
    }

    if (
      result <= check.lowerEqualCutoff &&
      result >= (check.checkRange ? check.checkRange[0].start : 0)
    ) {
      return [
        getScoreBasedOnRange(
          result,
          check.checkRange ? check.checkRange[0].start : 0,
          check.middleLowerCutoff
            ? check.middleLowerCutoff
            : check.lowerEqualCutoff
        ),
        ASSESSMENT_COLORS.NEGATIVE,
      ];
    }

    if (
      result >= check.upperEqualCutoff &&
      result <= (check.checkRange ? check.checkRange[0].end : check.maxScore)
    ) {
      return [
        1 -
          getScoreBasedOnRange(
            result,
            check.middleUpperCutoff
              ? check.middleUpperCutoff
              : check.upperEqualCutoff,
            check.checkRange ? check.checkRange[0].end : check.maxScore
          ),
        ASSESSMENT_COLORS.NEGATIVE,
      ];
    }

    const scoreBasedOnLowerEqualCutoff = getScoreBasedOnDistance(
      result,
      check.lowerEqualCutoff
    );

    const scoreBasedOnUpperEqualCutoff = getScoreBasedOnDistance(
      result,
      check.upperEqualCutoff
    );

    return [
      Math.max(scoreBasedOnLowerEqualCutoff, scoreBasedOnUpperEqualCutoff),
      ASSESSMENT_COLORS.NEGATIVE,
    ];
  }

  if (has(check, 'lowerEqualCutoff')) {
    if (
      result > check.lowerEqualCutoff &&
      result < get(check, 'upperCutoff', Number.POSITIVE_INFINITY)
    ) {
      return [1, ASSESSMENT_COLORS.POSITIVE];
    }

    if (
      result <= check.lowerEqualCutoff &&
      result >= (check.checkRange ? check.checkRange[0].start : 0)
    ) {
      return [
        getScoreBasedOnRange(
          result,
          check.checkRange ? check.checkRange[0].start : 0,
          check.middleLowerCutoff
            ? check.middleLowerCutoff
            : check.lowerEqualCutoff
        ),
        ASSESSMENT_COLORS.NEGATIVE,
      ];
    }

    const scoreBasedOnLowerEqualCutoff = getScoreBasedOnDistance(
      result,
      check.lowerEqualCutoff
    );
    const scoreBasedOnUpperCutoff = has(check, 'upperCutoff')
      ? getScoreBasedOnDistance(result, check.upperCutoff)
      : 0;

    return [
      Math.max(scoreBasedOnLowerEqualCutoff, scoreBasedOnUpperCutoff),
      ASSESSMENT_COLORS.NEGATIVE,
    ];
  }

  if (has(check, 'upperEqualCutoff')) {
    if (
      result > get(check, 'lowerCutoff', Number.NEGATIVE_INFINITY) &&
      result < check.upperEqualCutoff
    ) {
      return [1, ASSESSMENT_COLORS.POSITIVE];
    }

    if (
      result >= check.upperEqualCutoff &&
      result <= (check.checkRange ? check.checkRange[0].end : check.maxScore)
    ) {
      return [
        1 -
          getScoreBasedOnRange(
            result,
            // eslint-disable-next-line no-nested-ternary
            check.middleUpperEqualCutoff
              ? check.middleUpperEqualCutoff
              : // eslint-disable-next-line no-nested-ternary
              check.middleUpperCutoff
              ? check.middleUpperCutoff
              : check.upperEqualCutoff2
              ? check.upperEqualCutoff2
              : check.upperEqualCutoff,
            check.checkRange ? check.checkRange[0].end : check.maxScore
          ),
        ASSESSMENT_COLORS.NEGATIVE,
      ];
    }

    const scoreBasedOnUpperEqualCutoff = getScoreBasedOnDistance(
      result,
      check.upperEqualCutoff
    );
    const scoreBasedOnLowerCutoff = has(check, 'lowerCutoff')
      ? getScoreBasedOnDistance(result, check.lowerCutoff)
      : 0;

    return [
      Math.max(scoreBasedOnUpperEqualCutoff, scoreBasedOnLowerCutoff),
      ASSESSMENT_COLORS.NEGATIVE,
    ];
  }

  if (
    result >= get(check, 'lowerCutoff', Number.NEGATIVE_INFINITY) &&
    result <= get(check, 'upperCutoff', Number.POSITIVE_INFINITY)
  ) {
    return [1, ASSESSMENT_COLORS.POSITIVE];
  }

  if (
    result <= check.lowerCutoff &&
    result >= (check.checkRange ? check.checkRange[0].start : 0)
  ) {
    return [
      getScoreBasedOnRange(
        result,
        check.checkRange ? check.checkRange[0].start : 0,
        check.middleLowerCutoff ? check.middleLowerCutoff : check.lowerCutoff
      ),
      ASSESSMENT_COLORS.NEGATIVE,
    ];
  }

  if (
    result >= check.upperCutoff &&
    result <= (check.checkRange ? check.checkRange[0].end : check.maxScore)
  ) {
    return [
      1 -
        getScoreBasedOnRange(
          result,
          check.middleUpperCutoff ? check.middleUpperCutoff : check.upperCutoff,
          check.checkRange ? check.checkRange[0].end : check.maxScore
        ),
      ASSESSMENT_COLORS.NEGATIVE,
    ];
  }

  const scoreBasedOnLowerCutoff = has(check, 'lowerCutoff')
    ? getScoreBasedOnDistance(result, check.lowerCutoff)
    : 0;

  const scoreBasedOnUpperCutoff = has(check, 'upperCutoff')
    ? getScoreBasedOnDistance(result, check.upperCutoff)
    : 0;

  return [
    Math.max(scoreBasedOnLowerCutoff, scoreBasedOnUpperCutoff),
    ASSESSMENT_COLORS.NEGATIVE,
  ];
};

export const getSwingleResultColor = ({ check, result }) => {
  const [, color] = getSwingleCheckScoreAndColor({ check, result });
  return color;
};

export const getIsSwingleCheckPassing = ({ check, result }) => {
  const [score] = getSwingleCheckScoreAndColor({ check, result });
  return score === 1;
};

export const getSwingleCatagoriesFromChecks = ({ checks }) =>
  Object.keys(groupBy(checks, check => check.category.en));

export const getSwingleCategoriesScores = ({ checks, results }) => {
  const relevantSwingleChecks = pick(checks, Object.keys(results));
  const checksGroupedByCategory = groupBy(
    relevantSwingleChecks,
    check => check.category.en
  );

  return mapValues(checksGroupedByCategory, swingleChecks => {
    const totalScore = swingleChecks.reduce((acc, check) => {
      return (
        acc +
        getSwingleCheckScoreAndColor({ check, result: results[check.id] })[0]
      );
    }, 0);

    return Math.floor((totalScore / swingleChecks.length) * 100);
  });
};

export const getMainSwinglesScores = ({ checks, results }, ageBin) => {
  const relevantSwingleChecks = pick(checks, Object.keys(results));
  if (!Object.keys(relevantSwingleChecks).length) {
    return 0;
  }
  let acc = 0;

  Object.keys(relevantSwingleChecks).forEach(id => {
    const check = { ...relevantSwingleChecks[id] };
    Object.keys(check.cutoffs_v3).forEach(cutoff => {
      check[cutoff] = check.cutoffs_v3[cutoff][ageBin];
    });

    acc += getSwingleCheckScoreAndColor({
      check,
      result: results[id],
    })[0];
  });
  return Math.round((acc / Object.keys(relevantSwingleChecks).length) * 100);
};

export const getMainSwinglesScoresRaw = ({ checks, results }) => {
  const relevantSwingleChecks = pick(checks, Object.keys(results));
  if (!Object.keys(relevantSwingleChecks).length) {
    return 0;
  }
  let acc = 0;
  Object.keys(relevantSwingleChecks).forEach(id => {
    acc += getSwingleCheckScoreAndColor({
      check: relevantSwingleChecks[id],
      result: results[id].raw,
    })[0];
  });
  return Math.round((acc / Object.keys(relevantSwingleChecks).length) * 100);
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import exact from 'prop-types-exact';
import { TextField } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import styles from './EditClinicInformationDialog.scss';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import doneAnimatedImage from '../../../../assets/done-animation.gif';
import failedImage from '../../../../assets/failed-circle.png';
import { isValidPhone } from '../../../utils/utils';
import { Loader } from '../../../Core/Components/Loader/Loader';

const newAccountPageLogger = new MyEventWrapper('new_account_page');

export const EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS = {
  FORM: 'form',
  LOADER: 'loader',
  INFO_WAS_UPDATED: 'infoWasUpdated',
  FAILEd_TO_UPDATE: 'failedToUpdate',
};

const editClinicInformationFormId = 'edit-clinic-information-form';
const EditClinicInformationDialogBase = ({
  onSave,
  onCancel,
  onClose,
  renderedStep,
  isOpen,
  clinicInformation,
  onPrimaryBtnClick,
}) => {
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = useCallback(
    values => {
      onSave(values);
    },
    [onSave]
  );

  const submitHandler = handleSubmit(onSubmit);

  const renderForm = () => (
    <div className={styles.fade_in}>
      <form onSubmit={submitHandler} id={editClinicInformationFormId}>
        <p className={styles.dialog_header}>Edit clinic information</p>
        <div className={styles.inputs_container}>
          <div className={errors.name ? styles.error : ''}>
            <PersonIcon />
            <TextField
              label="First name"
              placeholder="Please enter your first name"
              margin="dense"
              inputProps={{
                name: 'firstName',
              }}
              inputRef={register({
                required: 'First name is required',
              })}
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
              defaultValue={clinicInformation.firstName}
              onFocus={() => {
                newAccountPageLogger.log('on_edit_account_info', {
                  engaged_element: 'first_name',
                });
              }}
            />
          </div>

          <div className={errors.lastName ? styles.error : ''}>
            <PersonIcon />
            <TextField
              label="Last name"
              placeholder="Please enter your last name"
              margin="dense"
              inputProps={{
                name: 'lastName',
              }}
              inputRef={register({
                required: 'Last name is required',
              })}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              defaultValue={clinicInformation.lastName}
              onFocus={() => {
                newAccountPageLogger.log('on_edit_account_info', {
                  engaged_element: 'last_name',
                });
              }}
            />
          </div>
          <div className={errors.phone ? styles.error : ''}>
            <PhoneIcon />
            <TextField
              label="Phone number"
              placeholder="Please enter your phone number"
              margin="dense"
              inputProps={{
                name: 'phone',
              }}
              inputRef={register({
                required: 'Phone is required',
                validate: isValidPhone,
              })}
              error={!!errors.phone}
              helperText={
                errors.phone && (errors.phone.message || 'Invalid phone')
              }
              defaultValue={clinicInformation.phone}
              onFocus={() => {
                newAccountPageLogger.log('on_edit_account_info', {
                  engaged_element: 'phone',
                });
              }}
            />
          </div>

          <div className={errors.clinicName ? styles.error : ''}>
            <LocalHospitalIcon />
            <TextField
              label="Clinic name"
              placeholder="Please enter your clinic name"
              margin="dense"
              inputProps={{
                name: 'clinicName',
              }}
              inputRef={register({
                required: 'Clinic name is required',
              })}
              error={!!errors.clinicName}
              helperText={errors.clinicName && errors.clinicName.message}
              defaultValue={clinicInformation.clinicName}
              onFocus={() => {
                newAccountPageLogger.log('on_edit_account_info', {
                  engaged_element: 'clinic_name',
                });
              }}
            />
            <img src={doneAnimatedImage} style={{ display: 'none' }} />
          </div>
        </div>
      </form>
    </div>
  );

  const renderInfoWasUpdated = () => (
    <div className={classNames(styles.successful_update_info, styles.fade_in)}>
      <img src={doneAnimatedImage} />
      <p>Your clinic info was updated</p>
    </div>
  );

  const renderFailedToUpdateInfo = () => (
    <div className={classNames(styles.failed_update_info, styles.fade_in)}>
      <img src={failedImage} />
      <p>Failed to update clinic info</p>
    </div>
  );

  const renderLoader = () => {
    return (
      <div className={styles.loader_container}>
        <Loader />
      </div>
    );
  };

  const primaryBtnContent =
    renderedStep === EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FORM
      ? 'save'
      : 'done';
  const secondaryBtnContent =
    renderedStep === EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FORM
      ? 'cancel'
      : null;

  return (
    <CustomDialog
      isOpen={isOpen}
      primaryBtnProps={{
        form: editClinicInformationFormId,
        type: 'submit',
        key: renderedStep,
      }}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onClose={onClose}
      onSecondaryBtnClick={onCancel}
      primaryBtnContent={primaryBtnContent}
      secondaryBtnContent={secondaryBtnContent}
      shouldShowButtons={
        renderedStep !== EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.LOADER
      }
    >
      {renderedStep === EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FORM &&
        renderForm()}
      {renderedStep ===
        EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.INFO_WAS_UPDATED &&
        renderInfoWasUpdated()}
      {renderedStep ===
        EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.FAILEd_TO_UPDATE &&
        renderFailedToUpdateInfo()}
      {renderedStep === EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS.LOADER &&
        renderLoader()}
    </CustomDialog>
  );
};

EditClinicInformationDialogBase.defaultProps = {
  onClose: () => {},
};

EditClinicInformationDialogBase.propTypes = exact({
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  renderedStep: PropTypes.oneOf(
    Object.values(EDIT_CLINIC_INFORMATION_DIALOG_RENDERED_STEPS)
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  clinicInformation: PropTypes.object.isRequired,
});

export const EditClinicInformationDialog = React.memo(
  EditClinicInformationDialogBase
);
EditClinicInformationDialog.displayName = 'EditClinicInformationDialog';

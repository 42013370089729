import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import get from 'lodash/get';
import brainIndicatorImage from '../../../../../assets/brain-indicator-with-arrow.png';
import { useBoundingRect } from '../../../../Core/hooks/useBoundingRect';
import { setFixedDecimalsIfNeeded, isDefined } from '../../../../utils/utils';
import { ASSESSMENT_COLORS } from '../../../../models/assessments/assessments';

import styles from './RangeGraph.scss';

const RangeGraphBase = ({ data, markerPNG, checkRange, unit }) => {
  const graphData = {
    ...data,
  };
  graphData.leftEnd = checkRange.start;
  graphData.rightEnd = checkRange.end;

  const formattedLeftEnd =
    graphData.leftEnd &&
    setFixedDecimalsIfNeeded({
      number: graphData.leftEnd,
      numberOfFixedDecimals: 1,
    });

  const formattedRightEnd =
    graphData.rightEnd &&
    setFixedDecimalsIfNeeded({
      number: graphData.rightEnd,
      numberOfFixedDecimals: 1,
    });

  const rootElementRef = useRef();
  const rootElementBoundingRect = useBoundingRect(rootElementRef);
  const graphWidth = get(rootElementBoundingRect, 'width');
  const distanceUnit = graphWidth / (graphData.rightEnd - graphData.leftEnd);
  const dataMinBlockWidth = (data.min - graphData.leftEnd) * distanceUnit;
  const dataMidMinBlockWidth = (data.midMin - data.min) * distanceUnit;
  const dataMidMaxBlockWidth = (data.max - data.midMax) * distanceUnit;
  const normalRangeBlockWidth = (() => {
    let normalRangeWidth;
    if (typeof data.max !== 'undefined' && typeof data.min !== 'undefined') {
      normalRangeWidth = (data.max - data.min) * distanceUnit;
    } else if (typeof data.max !== 'undefined') {
      normalRangeWidth = (data.max - graphData.leftEnd) * distanceUnit;
    } else {
      normalRangeWidth = (graphData.rightEnd - data.min) * distanceUnit;
    }

    if (dataMidMinBlockWidth) {
      normalRangeWidth -= dataMidMinBlockWidth;
    }

    if (dataMidMaxBlockWidth) {
      normalRangeWidth -= dataMidMaxBlockWidth;
    }

    return normalRangeWidth;
  })();
  const dataMaxBlockWidth = (graphData.rightEnd - data.max) * distanceUnit;

  const getValuePosition = value => {
    const percentageOfDistance =
      (value - graphData.leftEnd) / (graphData.rightEnd - graphData.leftEnd);
    return percentageOfDistance * graphWidth;
  };

  const getSafeValuePosition = value =>
    Math.max(Math.min(getValuePosition(value), graphWidth + 15), -15);

  const valuePosition = getSafeValuePosition(data.value);
  const postValuePosition = getSafeValuePosition(data.postValue);

  const barHeight = 10;
  const negativeRangeColor = ASSESSMENT_COLORS.NEGATIVE;
  const positiveRangeColor = ASSESSMENT_COLORS.POSITIVE;
  const almostPositiveRangeColor = ASSESSMENT_COLORS.ALMOST_POSITIVE;
  const halfWidthMarker = 13.5;

  const graphLeftEndText = `${formattedLeftEnd}${unit}`;
  const dataMinText = `${data.min}${unit}`;
  const dataMidMinText = `${data.midMin}${unit}`;
  const dataMidMaxText = `${data.midMax}${unit}`;
  const dataMaxText = `${data.max}${unit}`;
  const graphRightEndText = `${formattedRightEnd}${unit}`;
  const charAverageSizeInPixels = 3;

  const graphLeftEndTextPosition =
    0 - graphLeftEndText.length * charAverageSizeInPixels;
  const dataMinTextPosition =
    getValuePosition(data.min) - dataMinText.length * charAverageSizeInPixels;
  const dataMidMinTextPosition =
    getValuePosition(data.midMin) -
    dataMidMinText.length * charAverageSizeInPixels;
  const dataMidMaxTextPosition =
    getValuePosition(data.midMax) -
    dataMidMaxText.length * charAverageSizeInPixels;
  const dataMaxTextPosition =
    getValuePosition(data.max) - dataMaxText.length * charAverageSizeInPixels;
  const graphRightEndTextPosition =
    graphWidth - graphRightEndText.length * charAverageSizeInPixels;
  const textPositions = [
    graphLeftEndTextPosition,
    isDefined(data.min) && dataMinTextPosition,
    isDefined(data.midMin) && dataMidMinTextPosition,
    isDefined(data.midMax) && dataMidMaxTextPosition,
    isDefined(data.max) && dataMaxTextPosition,
    graphRightEndTextPosition,
  ];
  const postValueMarkerPosition = postValuePosition - halfWidthMarker;
  const postValueMarkerTopPosition = textPositions.some(
    position =>
      isDefined(position) && Math.abs(postValueMarkerPosition - position) <= 22
  )
    ? 24
    : 10;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        height: barHeight,
        position: 'relative',
      }}
      ref={rootElementRef}
    >
      {graphWidth && (
        <React.Fragment>
          <p
            style={{
              position: 'absolute',
              top: 10,
              color: 'black',
              width: 'fit-content',
              left: graphLeftEndTextPosition,
            }}
          >
            {graphLeftEndText}
          </p>
          {typeof data.min !== 'undefined' && (
            <div
              style={{
                width: dataMinBlockWidth,
                background: negativeRangeColor,
              }}
            >
              <p
                style={{
                  top: '10px',
                  position: 'absolute',
                  color: 'black',
                  left: dataMinTextPosition,
                  width: 'fit-content',
                }}
              >
                {dataMinText}
              </p>
            </div>
          )}
          {typeof data.midMin !== 'undefined' && (
            <div
              style={{
                width: dataMidMinBlockWidth,
                background: almostPositiveRangeColor,
              }}
            >
              <p
                style={{
                  top: '10px',
                  position: 'absolute',
                  color: 'black',
                  left: dataMidMinTextPosition,
                  width: 'fit-content',
                }}
              >
                {dataMidMinText}
              </p>
            </div>
          )}
          <div
            style={{
              width: normalRangeBlockWidth,
              background: positiveRangeColor,
            }}
          />
          {typeof data.midMax !== 'undefined' && (
            <div
              style={{
                width: dataMidMaxBlockWidth,
                background: almostPositiveRangeColor,
              }}
            >
              <p
                style={{
                  top: '10px',
                  position: 'absolute',
                  color: 'black',
                  left: dataMidMaxTextPosition,
                  width: 'fit-content',
                }}
              >
                {dataMidMaxText}
              </p>
            </div>
          )}
          {typeof data.max !== 'undefined' && (
            <div
              style={{
                width: dataMaxBlockWidth,
                background: negativeRangeColor,
              }}
            >
              <p
                style={{
                  top: '10px',
                  position: 'absolute',
                  color: 'black',
                  left: dataMaxTextPosition,
                  width: 'fit-content',
                }}
              >
                {dataMaxText}
              </p>
            </div>
          )}
          <div
            className={styles.marker_container}
            style={{
              top: typeof data.postValue !== 'undefined' ? -44 : -25,
              left: valuePosition - halfWidthMarker,
            }}
          >
            {typeof data.postValue !== 'undefined' && <span>Pre</span>}
            <img src={markerPNG} style={{ width: 28 }} />
          </div>
          {typeof data.postValue !== 'undefined' && (
            <div
              className={styles.marker_container}
              style={{
                top: postValueMarkerTopPosition,
                left: postValueMarkerPosition,
              }}
            >
              <img
                src={markerPNG}
                style={{ width: 28, transform: 'rotate(180deg)' }}
              />
              <span style={{ marginLeft: 3 }}>Post</span>
            </div>
          )}

          <p
            style={{
              position: 'absolute',
              top: 10,
              left: graphRightEndTextPosition,
              color: 'black',
              width: 'fit-content',
            }}
          >
            {graphRightEndText}
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

RangeGraphBase.defaultProps = {
  markerPNG: brainIndicatorImage,
};

RangeGraphBase.propTypes = exact({
  data: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number.isRequired,
    midMin: PropTypes.number,
    midMax: PropTypes.number,
    postValue: PropTypes.number,
  }).isRequired,
  markerPNG: PropTypes.string,
  checkRange: PropTypes.object.isRequired,
  unit: PropTypes.string,
});

export const RangeGraph = React.memo(RangeGraphBase);
RangeGraph.displayName = 'RangeGraph';

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import dayJS from 'dayjs';
// import chroma from 'chroma-js';
import { CircularProgress, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Switch from '@material-ui/core/Switch';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { graphHeight } from '../constants';
import { DeepEqualityComponent } from '../../../Core/DeepEqualityComponent/DeepEqualityComponent';
import { withTranslation } from '../../../Core/hocs/withTranslation/withTranslation';
import { paragraphFontSize } from '../../../cssInJs/constants';

const stylesFn = () => ({
  formControl: {
    margin: 0,
    minWidth: 175,
  },
  inputLabelRoot: {
    fontSize: paragraphFontSize,
  },
  selectRoot: {
    fontSize: paragraphFontSize,
    lineHeight: 'initial',
  },
});

class InDepthSessionAnalysisGraphBase extends DeepEqualityComponent {
  render() {
    const {
      classes,
      data,
      isInPreview,
      sessions,
      selectedInDepthSession,
      onSelectedSessionChange,
      frequencyNamesArr,
      onToggleFrequencyAmplitudes,
      onToggleThresholds,
      toggleFrequencyAmplitudes,
      toggleThresholds,
      renderNoDataIndicator,
      shouldRenderGraph,
      isLoadingCurrentSesion,
      trainingSessions,
      frequencyColors,
    } = this.props;

    // const colors = chroma
    //   .scale(['red', 'green', 'blue'])
    //   .colors(frequencyNamesArr.length * 2);

    return (
      <div>
        {isInPreview && (
          <p>
            {dayJS(
              new Date(+trainingSessions[selectedInDepthSession].endTimestamp)
            ).format(DAY_JS_DATE_FORMATS.americanWithTime)}{' '}
            | Session #{trainingSessions[selectedInDepthSession].number}
          </p>
        )}
        {!isInPreview && trainingSessions && !!sessions.length && (
          <div style={{ display: 'flex', marginTop: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              {/* <RootRef rootRef={this.sessionSlicesInputLabel}> */}
              <InputLabel
                htmlFor="select-sessions-slices"
                classes={{ root: classes.inputLabelRoot }}
              >
                Session
              </InputLabel>
              {/* </RootRef> */}
              <Select
                native
                value={selectedInDepthSession}
                onChange={onSelectedSessionChange}
                input={
                  <OutlinedInput
                    name="sessions-slices"
                    id="select-sessions-slices"
                    labelWidth={50}
                  />
                }
                classes={{
                  root: classes.selectRoot,
                }}
              >
                {sessions.map(option => (
                  <option key={option.sessionKey} value={option.sessionKey}>
                    {option.sessionLabel}
                  </option>
                ))}
              </Select>
            </FormControl>
            <div>
              <Switch
                onChange={onToggleFrequencyAmplitudes}
                checked={toggleFrequencyAmplitudes}
                color="primary"
              />
              Frequency Amplitudes
            </div>
            <div>
              <Switch
                onChange={onToggleThresholds}
                checked={toggleThresholds}
                color="primary"
              />
              Thresholds
            </div>
          </div>
        )}

        {// eslint-disable-next-line no-nested-ternary
        isLoadingCurrentSesion ? (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress />
          </div>
        ) : shouldRenderGraph ? (
          <ResponsiveContainer
            width={`${Math.random() + 99}%`}
            height={graphHeight}
          >
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 45,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip isAnimationActive={false} />
              <Legend />
              {frequencyNamesArr.map(frequency =>
                toggleFrequencyAmplitudes ? (
                  <Line
                    type="monotone"
                    dataKey={frequency}
                    stroke={
                      frequencyColors[
                        frequency.substring(0, frequency.indexOf(' ('))
                      ]
                    }
                  />
                ) : null
              )}
              {frequencyNamesArr.map(frequency =>
                toggleThresholds ? (
                  <Line
                    type="monotone"
                    dataKey={`${frequency} threshold`}
                    stroke={
                      frequencyColors[
                        frequency.substring(0, frequency.indexOf(' ('))
                      ]
                    }
                  />
                ) : null
              )}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          renderNoDataIndicator && renderNoDataIndicator()
        )}
      </div>
    );
  }
}

InDepthSessionAnalysisGraphBase.defaultProps = {
  isInPreview: false,
  onSelectedSessionChange: () => {},
  onToggleFrequencyAmplitudes: () => {},
  onToggleThresholds: () => {},
};

InDepthSessionAnalysisGraphBase.propTypes = {
  data: PropTypes.array.isRequired,
  isInPreview: PropTypes.bool.isRequired,
  sessions: PropTypes.array.isRequired,
  selectedInDepthSession: PropTypes.string,
  onSelectedSessionChange: PropTypes.func.isRequired,
  frequencyNamesArr: PropTypes.array.isRequired,
  onToggleFrequencyAmplitudes: PropTypes.func.isRequired,
  onToggleThresholds: PropTypes.func.isRequired,
  toggleFrequencyAmplitudes: PropTypes.bool.isRequired,
  toggleThresholds: PropTypes.bool.isRequired,
  renderNoDataIndicator: PropTypes.func.isRequired,
  shouldRenderGraph: PropTypes.bool.isRequired,
  isLoadingCurrentSesion: PropTypes.bool,
};

export const InDepthSessionAnalysisGraph = withTranslation(
  withStyles(stylesFn)(InDepthSessionAnalysisGraphBase)
);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ReactCodeInput from 'react-code-input';
import {
  Dialog,
  Button,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styles from './SettingsSection.scss';

const NUM_OF_FIELDS_VERFICATION_INPUT = 6;

const MFADialogBase = ({
  isOpen,
  onClose,
  onVerifyEmail,
  isEmailVerified,
  clinicPhoneNumber,
  setClinicPhoneNumber,
  isLodingMFADialog,
  setVerificationCode,
  verificationCode,
  isEmailSent,
  verificationId,
  submitPhoneNumber,
  isEnrolledSuccessfully,
  onVerificationCodeSent,
  isPhoneError,
  isCodeError,
  isEmailError,
}) => {
  const inputEl = useRef(null);

  const calcContent = () => {
    if (isLodingMFADialog) {
      return <CircularProgress />;
    }
    if (!isEmailVerified) {
      return (
        <div>
          {isEmailSent
            ? 'Please check your email for verification link'
            : 'In order to enable 2 factor authentication your email needs to be verfied.'}
          {isEmailError ? (
            <div style={{ color: 'red', marginTop: 20, fontSize: 12 }}>
              {isEmailError}
            </div>
          ) : null}
        </div>
      );
    }
    if (!verificationId) {
      return (
        <div>
          <div style={{ marginBottom: 20 }}>
            This is the phone that will be used to verify the user
          </div>
          <PhoneInput
            value={clinicPhoneNumber}
            onChange={phone => {
              setClinicPhoneNumber(phone);
            }}
            inputStyle={{ width: '100%' }}
          />
          {isPhoneError ? (
            <div style={{ color: 'red', marginTop: 20, fontSize: 12 }}>
              {isPhoneError}
            </div>
          ) : null}
        </div>
      );
    }
    if (!isEnrolledSuccessfully) {
      return (
        <div>
          <ReactCodeInput
            type="number"
            fields={NUM_OF_FIELDS_VERFICATION_INPUT}
            inputStyle={{
              width: window.innerWidth < 1200 ? 45 : 65,
              height: 80,
              margin: 0,
              border: '0.1px solid #E0E0E0',
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: window.innerWidth < 1200 ? 18 : 36,
              textTransform: 'capitalize',
            }}
            inputStyleInvalid={{
              color: 'red',
              border: '1px solid red',
              width: window.innerWidth < 1200 ? 45 : 65,
              height: 80,
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: window.innerWidth < 1200 ? 18 : 36,
              textTransform: 'capitalize',
            }}
            isValid={!isCodeError}
            value={verificationCode}
            onChange={async value => {
              setVerificationCode(value);
            }}
            ref={inputEl}
            autoFocus={false}
          />
          {isCodeError ? (
            <div style={{ color: 'red', marginTop: 20, fontSize: 12 }}>
              {isCodeError}
            </div>
          ) : null}
        </div>
      );
    }
    return '2 Factor authentication enrolled successfully';
  };

  const calcButtonText = () => {
    if (!isEmailVerified) {
      return isEmailSent ? 'Send again' : 'Send me an Email';
    }
    if (!verificationId) {
      return 'Next';
    }
    if (!isEnrolledSuccessfully) {
      return 'Send';
    }
    return 'Close';
  };

  const calcAction = () => {
    if (!isEmailVerified) {
      return onVerifyEmail;
    }
    if (!verificationId) {
      return submitPhoneNumber;
    }
    if (!isEnrolledSuccessfully) {
      return onVerificationCodeSent;
    }
    return onClose;
  };

  return (
    <Dialog open={isOpen}>
      <div className={styles.dialog_content}>
        <p className={styles.dialog_content_title}>
          Enroll 2 factor authentication to this clinic
        </p>
        <div>{calcContent()}</div>
      </div>

      <DialogActions>
        {isEnrolledSuccessfully ? null : (
          <Button color="primary" onClick={onClose}>
            <span className={styles.btn}>Cancel</span>
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={calcAction()}
          disabled={
            isLodingMFADialog ||
            (isEmailVerified &&
              verificationId &&
              !isEnrolledSuccessfully &&
              verificationCode.length !== NUM_OF_FIELDS_VERFICATION_INPUT)
          }
        >
          <span className={styles.btn}>{calcButtonText()}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MFADialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onVerifyEmail: PropTypes.func.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  clinicPhoneNumber: PropTypes.string.isRequired,
  setClinicPhoneNumber: PropTypes.func.isRequired,
  isLodingMFADialog: PropTypes.bool.isRequired,
  setVerificationCode: PropTypes.func.isRequired,
  verificationCode: PropTypes.string.isRequired,
  isEmailSent: PropTypes.bool.isRequired,
  verificationId: PropTypes.string.isRequired,
  submitPhoneNumber: PropTypes.func.isRequired,
  isEnrolledSuccessfully: PropTypes.bool.isRequired,
  onVerificationCodeSent: PropTypes.func.isRequired,
  isPhoneError: PropTypes.string.isRequired,
  isCodeError: PropTypes.string.isRequired,
  isEmailError: PropTypes.bool.isRequired,
});

export const MFADialog = React.memo(MFADialogBase);
MFADialog.displayName = 'MFADialog';

import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Button,
  Input,
  Checkbox,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import styles from './LagoVariants.scss';

const LagoVariantsBase = ({
  open,
  onClose,
  tags,
  // onSaveVariantClicked,
  variantToEdit,
  selectedScreen,
  deleteVariantClicked,
  setIsDialogOpen,
  setIsScreenEditor,
  setIsVariantEditor,
  // variantTitle,
  setVariantTitle,
  tempScreenFilters,
  setTempScreenFilters,
  setVariantToEdit,
  isNewVariant,
  setIsNewVariant,
  journeyId,
  isFinalizedState,
  currentStage,
}) => {
  const OPERATORS = [
    'is',
    'is-not',
    'contains',
    'greater-than',
    'less-than',
    'equals',
  ];

  const [variant] = useFirebaseDB({
    path: `lago/educational/defaults/screens/${selectedScreen}/variants/${variantToEdit}`,
  });

  const [displayOperators, setDisplayOperators] = useState(false);
  const [operatorInputValue, setOperatorInputValue] = useState('');
  const [displayTags, setDisplayTags] = useState(false);
  const [operatorsState, setOperatorsState] = useState(
    OPERATORS.map(op => ({ name: op, isSelected: false }))
  );
  const [filterTagsInput, setFilterTagsInput] = useState('');

  const resetState = () => {
    setVariantTitle('');
    setDisplayTags(false);
    setFilterTagsInput('');
    setDisplayOperators(false);
    setOperatorsState(OPERATORS.map(op => ({ name: op, isSelected: false })));
    setOperatorInputValue('');
    setTempScreenFilters([]);
    setVariantToEdit('');
  };

  useEffect(() => {
    if (
      variant &&
      variant.variantConfig &&
      variantToEdit &&
      variant.variantConfig.conditions
    ) {
      setVariantTitle(variant.variantConfig.variantTitle);
      const conditions = variant.variantConfig.conditions.map(
        (condition, i) => {
          return {
            tagKey: condition.tagId,
            filterKey: i,
            operator: {
              name: condition.operator,
              inputValue: condition.val,
            },
          };
        }
      );
      setTempScreenFilters(conditions);
    }
  }, [selectedScreen, variant, variantToEdit]);

  const onAddFilterClicked = () => {
    setDisplayTags(true);
  };

  const onTagSelected = tagKey => {
    const copy = [...tempScreenFilters];
    copy.push({
      tagKey,
      operator: null,
      filterKey: Math.random()
        .toString(36)
        .substring(7),
    });
    setTempScreenFilters(copy);
    setDisplayTags(false);
    setFilterTagsInput('');
    setDisplayOperators(true);
  };

  const deleteFilter = filterKey => {
    const copy = [...tempScreenFilters];
    const indexToRemove = tempScreenFilters.findIndex(
      item => item.filterKey === filterKey
    );
    copy.splice(indexToRemove, 1);
    setTempScreenFilters(copy);
  };

  // const addOperator = () => {
  //   setDisplayOperators(true);
  // };

  const setSelectedOperator = (operator, checked) => {
    const copy = [...operatorsState];
    const indexToChange = copy.findIndex(item => item.name === operator.name);
    copy[indexToChange].isSelected = checked;
    setOperatorsState(copy);
    setOperatorInputValue('');
  };

  const addOperatorClicked = filterKey => {
    const copy = [...tempScreenFilters];
    const indexToChange = tempScreenFilters.findIndex(
      item => item.filterKey === filterKey
    );
    const operatorIndex = operatorsState.findIndex(item => item.isSelected);
    copy[indexToChange].operator = {
      name: operatorsState[operatorIndex].name,
      inputValue: operatorInputValue,
    };
    setVariantTitle(
      // eslint-disable-next-line prefer-template
      tags[copy[0].tagKey].tagName.en +
        ' ' +
        copy[0].operator.name +
        ' ' +
        copy[0].operator.inputValue
    );
    setTempScreenFilters(copy);
    setDisplayOperators(false);
    setOperatorInputValue('');
    setOperatorsState(OPERATORS.map(op => ({ name: op, isSelected: false })));
  };

  const checkSaveVariantDisabled = () => {
    if (tempScreenFilters.length) {
      if (
        tempScreenFilters.filter(tempScreenFilter => !tempScreenFilter.operator)
          .length
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  let numOfSelected = 0;
  operatorsState.forEach(operator => {
    if (operator.isSelected) {
      numOfSelected += 1;
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={{ paper: styles.dialogPaper }}
      onClick={() => {
        if (displayTags) {
          setDisplayTags(false);
          setFilterTagsInput('');
        }
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '20px' }}>Screen variant</span>
      </DialogTitle>
      <DialogContent>
        <div className={styles.variant_filters_container}>
          {/* <Input
            value={variantTitle}
            onChange={e => setVariantTitle(e.target.value)}
            placeholder="Variant title"
            style={{ fontSize: '1.5rem' }}
          /> */}
          {tempScreenFilters.map(tempScreenFilter => (
            <div
              className={styles.variant_filter_item_container}
              key={tempScreenFilter.tagKey}
            >
              <Button
                variant="contained"
                style={{ padding: '5px', minWidth: '20px', margin: '5px' }}
                onClick={() => deleteFilter(tempScreenFilter.filterKey)}
                disabled={isFinalizedState}
              >
                X
              </Button>
              <span>{tags[tempScreenFilter.tagKey].tagName.en}</span>
              {tempScreenFilter.operator ? (
                <span style={{ marginLeft: '5px' }}>
                  {tempScreenFilter.operator.name}{' '}
                  {tempScreenFilter.operator.inputValue}
                </span>
              ) : // <Button
              //   style={{ padding: '5px', minWidth: '20px', margin: '5px' }}
              //   onClick={addOperator}
              // >
              //   +
              // </Button>
              null}

              {displayOperators ? (
                <div className={styles.variant_operators_container}>
                  {operatorsState.map(operator => (
                    <div key={operator.name}>
                      <Checkbox
                        checked={operator.isSelected}
                        onChange={e =>
                          setSelectedOperator(operator, e.target.checked)
                        }
                        color="primary"
                        disabled={!operator.isSelected && !!numOfSelected}
                      />
                      {operator.name}
                      {operator.isSelected ? (
                        <Input
                          value={operatorInputValue}
                          onChange={e => setOperatorInputValue(e.target.value)}
                          style={{ marginLeft: '10px' }}
                        />
                      ) : null}
                    </div>
                  ))}
                  <div>
                    <Button
                      variant="contained"
                      style={{ margin: '10px' }}
                      onClick={() =>
                        addOperatorClicked(tempScreenFilter.filterKey)
                      }
                      disabled={!operatorInputValue}
                    >
                      add operator
                    </Button>
                    <Button
                      variant="contained"
                      style={{ margin: '10px' }}
                      onClick={() => {
                        setOperatorInputValue('');
                        setOperatorsState(
                          OPERATORS.map(op => ({ name: op, isSelected: false }))
                        );
                        setDisplayOperators(false);
                      }}
                    >
                      cancel
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div
          className={styles.add_filter_btn}
          onClick={() => {
            if (!tempScreenFilters.length) {
              onAddFilterClicked();
            }
          }}
          style={
            tempScreenFilters.length ? { color: 'gray', cursor: 'auto' } : null
          }
        >
          Add filter +
        </div>
        {displayTags ? (
          <div className={styles.variant_tags_container}>
            <div className={styles.variant_select_tag_title}>Select tag</div>
            {/* <SearchIcon/> */}
            <Input
              style={{ paddingLeft: '10px' }}
              value={filterTagsInput}
              onChange={e => {
                setFilterTagsInput(e.target.value);
              }}
              onClick={e => e.stopPropagation()}
              endAdornment={
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {Object.keys(tags).map(tagKey => {
              if (
                (tags[tagKey].tagName.en
                  .toLowerCase()
                  .includes(filterTagsInput) ||
                  !filterTagsInput) &&
                (tags[tagKey].journeyId === journeyId ||
                  (currentStage === 0 && !tags[tagKey].journeyId))
              ) {
                return (
                  <div
                    className={styles.variant_tag_item_container}
                    onClick={() => onTagSelected(tagKey)}
                    key={tagKey}
                  >
                    {tags[tagKey].tagName.en}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        {variantToEdit && !isNewVariant ? (
          <Button
            onClick={() => {
              deleteVariantClicked(variantToEdit);
              onClose();
              resetState();
            }}
            disabled={isFinalizedState}
          >
            <DeleteIcon />
          </Button>
        ) : null}
        <Button
          onClick={() => {
            onClose();
            resetState();
            setIsNewVariant(false);
          }}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          color="primary"
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            setIsDialogOpen(true);
            setIsScreenEditor(true);
            setIsVariantEditor(true);
            // onSaveVariantClicked(tempScreenFilters, variantTitle);
            // resetState();
          }}
          disabled={checkSaveVariantDisabled()}
          style={{ width: '100px', height: '50px', fontSize: '16px' }}
          color="primary"
          variant="contained"
        >
          edit variant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LagoVariantsBase.propTypes = exact({
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tags: PropTypes.object,
  // onSaveVariantClicked: PropTypes.func,
  variantToEdit: PropTypes.string,
  selectedScreen: PropTypes.string,
  deleteVariantClicked: PropTypes.func,
  setIsDialogOpen: PropTypes.func,
  setIsScreenEditor: PropTypes.func,
  setIsVariantEditor: PropTypes.func,
  // variantTitle: PropTypes.string,
  setVariantTitle: PropTypes.func,
  tempScreenFilters: PropTypes.array,
  setTempScreenFilters: PropTypes.func,
  setVariantToEdit: PropTypes.func,
  setIsNewVariant: PropTypes.func,
  isNewVariant: PropTypes.bool,
  journeyId: PropTypes.string,
  isFinalizedState: PropTypes.bool,
  currentStage: PropTypes.number,
});

export const LagoVariants = React.memo(LagoVariantsBase);
LagoVariants.displayName = 'LagoVariants';

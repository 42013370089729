exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ClickableLegendItems_size_17{font-size:17px}.ClickableLegendItems_size_10{font-size:10px}.ClickableLegendItems_size_11{font-size:11px}.ClickableLegendItems_size_13{font-size:13px}.ClickableLegendItems_size_15{font-size:15px}.ClickableLegendItems_size_14{font-size:14px}.ClickableLegendItems_size_16{font-size:16px}.ClickableLegendItems_size_12{font-size:12px}.ClickableLegendItems_extra_header{font-size:18px;font-weight:500;color:#000000}.ClickableLegendItems_header{font-size:17px;font-weight:500;color:#000000}.ClickableLegendItems_header_only_font_size{font-size:17px;font-weight:500}.ClickableLegendItems_sub_header{font-size:13px;color:#878787}.ClickableLegendItems_sub_header_black{font-size:13px;color:black}.ClickableLegendItems_sub_header_only_font_size{font-size:13px}.ClickableLegendItems_paragraph_only_font_size{font-size:12px}.ClickableLegendItems_tab_font_size{font-size:13px}.ClickableLegendItems_pop_out_header{font-size:20px}.ClickableLegendItems_title{font-size:15px;font-weight:500;color:#484848}.ClickableLegendItems_title_black{font-size:15px;font-weight:500;color:black}.ClickableLegendItems_title_only_font_size{font-size:15px}.ClickableLegendItems_paragraph,.ClickableLegendItems_legend_name{font-size:12px;color:#484848}.ClickableLegendItems_btn{font-size:14px}.ClickableLegendItems_legend{display:flex;margin-bottom:15px}.ClickableLegendItems_legend_container{display:flex;flex-wrap:wrap;margin-top:10px;margin-left:30px;justify-content:center}.ClickableLegendItems_legend_circle{width:15px;height:15px;border-radius:50%;margin-right:10px;margin-left:10px;margin-top:3px}\n", ""]);

// Exports
exports.locals = {
	"size_17": "ClickableLegendItems_size_17",
	"size_10": "ClickableLegendItems_size_10",
	"size_11": "ClickableLegendItems_size_11",
	"size_13": "ClickableLegendItems_size_13",
	"size_15": "ClickableLegendItems_size_15",
	"size_14": "ClickableLegendItems_size_14",
	"size_16": "ClickableLegendItems_size_16",
	"size_12": "ClickableLegendItems_size_12",
	"extra_header": "ClickableLegendItems_extra_header",
	"header": "ClickableLegendItems_header",
	"header_only_font_size": "ClickableLegendItems_header_only_font_size",
	"sub_header": "ClickableLegendItems_sub_header",
	"sub_header_black": "ClickableLegendItems_sub_header_black",
	"sub_header_only_font_size": "ClickableLegendItems_sub_header_only_font_size",
	"paragraph_only_font_size": "ClickableLegendItems_paragraph_only_font_size",
	"tab_font_size": "ClickableLegendItems_tab_font_size",
	"pop_out_header": "ClickableLegendItems_pop_out_header",
	"title": "ClickableLegendItems_title",
	"title_black": "ClickableLegendItems_title_black",
	"title_only_font_size": "ClickableLegendItems_title_only_font_size",
	"paragraph": "ClickableLegendItems_paragraph",
	"legend_name": "ClickableLegendItems_legend_name",
	"btn": "ClickableLegendItems_btn",
	"legend": "ClickableLegendItems_legend",
	"legend_container": "ClickableLegendItems_legend_container",
	"legend_circle": "ClickableLegendItems_legend_circle"
};
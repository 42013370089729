import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './ReferralBanner.scss';
import creditGiftIcon from '../../../assets/credit-gift-icon.png';
// eslint-disable-next-line no-unused-vars
const ReferralBannerBase = ({ onLearnMore, credit }) => {
  return (
    <div
      className={styles.root}
      // onClick={onLearnMore}
      style={{ opacity: 0, cursor: 'auto' }}
    >
      <div>
        <img src={creditGiftIcon} />
      </div>
      <div>
        <p data-test-id="invitation-text">
          Refer a clinic and get ${credit} in credits
        </p>
      </div>
      <div>
        <a
          // href="#"
          // onClick={onLearnMore}
          data-test-id="learn-more-link"
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

ReferralBannerBase.propTypes = exact({
  onLearnMore: PropTypes.func.isRequired,
  credit: PropTypes.number,
});

export const ReferralBanner = React.memo(ReferralBannerBase);
ReferralBanner.displayName = 'ReferralBanner';

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CustomDialog_size_17{font-size:17px}.CustomDialog_size_10{font-size:10px}.CustomDialog_size_11{font-size:11px}.CustomDialog_size_13{font-size:13px}.CustomDialog_size_15{font-size:15px}.CustomDialog_size_14{font-size:14px}.CustomDialog_size_16{font-size:16px}.CustomDialog_size_12{font-size:12px}.CustomDialog_extra_header{font-size:18px;font-weight:500;color:#000000}.CustomDialog_header{font-size:17px;font-weight:500;color:#000000}.CustomDialog_header_only_font_size{font-size:17px;font-weight:500}.CustomDialog_sub_header{font-size:13px;color:#878787}.CustomDialog_sub_header_black{font-size:13px;color:black}.CustomDialog_sub_header_only_font_size{font-size:13px}.CustomDialog_paragraph_only_font_size{font-size:12px}.CustomDialog_tab_font_size{font-size:13px}.CustomDialog_pop_out_header{font-size:20px}.CustomDialog_title{font-size:15px;font-weight:500;color:#484848}.CustomDialog_title_black{font-size:15px;font-weight:500;color:black}.CustomDialog_title_only_font_size{font-size:15px}.CustomDialog_paragraph{font-size:12px;color:#484848}.CustomDialog_btn{font-size:14px}.CustomDialog_dialog_header{font-weight:500;text-align:left;margin-bottom:20px}.CustomDialog_content_container{padding:35px 26px;display:flex;flex-direction:column;padding-bottom:16px}.CustomDialog_loader_container{position:absolute;top:50%;left:50%;margin-left:-20px;margin-top:-20px}.CustomDialog_content_and_footer{position:relative}\n", ""]);

// Exports
exports.locals = {
	"size_17": "CustomDialog_size_17",
	"size_10": "CustomDialog_size_10",
	"size_11": "CustomDialog_size_11",
	"size_13": "CustomDialog_size_13",
	"size_15": "CustomDialog_size_15",
	"size_14": "CustomDialog_size_14",
	"size_16": "CustomDialog_size_16",
	"size_12": "CustomDialog_size_12",
	"extra_header": "CustomDialog_extra_header",
	"header": "CustomDialog_header",
	"header_only_font_size": "CustomDialog_header_only_font_size",
	"sub_header": "CustomDialog_sub_header",
	"sub_header_black": "CustomDialog_sub_header_black",
	"sub_header_only_font_size": "CustomDialog_sub_header_only_font_size",
	"paragraph_only_font_size": "CustomDialog_paragraph_only_font_size",
	"tab_font_size": "CustomDialog_tab_font_size",
	"pop_out_header": "CustomDialog_pop_out_header",
	"title": "CustomDialog_title",
	"title_black": "CustomDialog_title_black",
	"title_only_font_size": "CustomDialog_title_only_font_size",
	"paragraph": "CustomDialog_paragraph",
	"btn": "CustomDialog_btn",
	"dialog_header": "CustomDialog_dialog_header",
	"content_container": "CustomDialog_content_container",
	"loader_container": "CustomDialog_loader_container",
	"content_and_footer": "CustomDialog_content_and_footer"
};
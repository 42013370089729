import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  LinearProgress,
  Tooltip,
  Switch,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { useCustomerInfo } from '../../../Core/hooks/useCustomerInfo';
import styles from './BehaviouralProgressTab.scss';
import behavoirTracking from '../../../../assets/behavoir-tracking.png';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { isAppCompilableWithDeployJourney } from '../../../models/customer/customer';

const lagoDashboardContentLogger = new MyEventWrapper('Lago_dashboard_content');
dayjs.extend(dayjsCustomParseFormat);
const initialValue = Symbol('iv');

const BehaviouralProgressTabBase = () => {
  const customerInfo = useCustomerInfo();

  const [journeyToSwapTo, setJourneyToSwapTo] = useState('');
  const [isSwappingJourney, setIsSwappingJourney] = useState(false);

  const [isAppCompatible, setIsAppCompatible] = useState(true);

  const [lagoEnabledClinic] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${sessionStorage.userId}/isEnabled`,
  });

  const [lagoEnabledUser, setLagoEnabledUser] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/isEnabled`,
    initialValue,
  });

  const [userData] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}`,
  });

  const [goalsData] = useFirebaseDB({
    path: 'lago/educational/defaults/goals',
  });

  const [tagsData] = useFirebaseDB({
    path: 'lago/educational/defaults/tags',
  });

  const [remindersData] = useFirebaseDB({
    path: 'lago/educational/defaults/reminders',
  });

  const [minicoursesData] = useFirebaseDB({
    path: 'lago/educational/defaults/minicourses',
  });

  const [modulesData] = useFirebaseDB({
    path: 'lago/educational/defaults/modules',
  });

  const [usersCategories, setUsersCategories] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/journeyConfig/categories`,
  });

  const [resourceEnabled, setResourceEnabled] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/enableResources`,
    initialValue,
  });

  const [defaultCategories] = useFirebaseDB({
    path: 'lago/educational/defaults/categories',
    initialValue,
  });

  const [defaultJourneys] = useFirebaseDB({
    path: 'lago/educational/defaults/journeys',
    initialValue,
  });

  const [currentJourneyId] = useFirebaseDB({
    path: `lago/educational/users/${customerInfo.id}/journeyConfig/journeyId`,
  });

  const [archivedJourneys] = useFirebaseDB({
    path: `/archive/journeyConfig/${customerInfo.id}`,
  });

  const [percentageDoneJourney] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${customerInfo.id}/journeyDetails/percentageDone`,
  });

  useEffect(() => {
    (async () => {
      const isAppCompatibleDB = await isAppCompilableWithDeployJourney({
        customerId: customerInfo.id,
      });
      if (!isAppCompatibleDB) {
        setIsAppCompatible(false);
      }
    })();
  });

  const parseDate = (unixTimestamp, showHours = true) => {
    const a = new Date(unixTimestamp);

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const day = days[a.getDay()];
    const date = a.getDate();
    let hour = a.getHours();
    if (hour < 10) {
      // eslint-disable-next-line prefer-template
      hour = '0' + hour;
    }
    let min = a.getMinutes();
    if (min < 10) {
      // eslint-disable-next-line prefer-template
      min = '0' + min;
    }
    // let sec = a.getSeconds();
    // if (sec < 10) {
    //   // eslint-disable-next-line prefer-template
    //   sec = '0' + sec;
    // }
    let time;
    if (showHours) {
      time =
        // eslint-disable-next-line prefer-template
        day +
        ', ' +
        month +
        ' ' +
        date +
        ', ' +
        year +
        ' at ' +
        hour +
        ':' +
        min;
      // +
      // ':' +
      // sec;
    } else {
      time =
        // eslint-disable-next-line prefer-template
        day + ', ' + month + ' ' + date + ', ' + year;
    }

    return time;
  };

  const filteredDefaultCategories = Object.keys(defaultCategories).filter(
    catgoryId => !defaultCategories[catgoryId].isHidden
  );

  const onCategorySelected = e => {
    let catgoryIdEvent;
    const updatedCategories = {};
    filteredDefaultCategories.forEach(categoryId => {
      if (!e.target.value.find(newCategoryId => newCategoryId === categoryId)) {
        updatedCategories[categoryId] = { isEnabled: false };
        catgoryIdEvent = categoryId;
      }
    });
    setUsersCategories(updatedCategories);
    lagoDashboardContentLogger.log(
      'exercises_and_resources_category_toggle-clicked',
      {
        patientId: customerInfo.id,
        categoryId: catgoryIdEvent,
      }
    );
  };

  const calcSelectedCategories = () => {
    if (!usersCategories) {
      // eslint-disable-next-line no-unneeded-ternary
      return filteredDefaultCategories ? filteredDefaultCategories : [];
    }
    return filteredDefaultCategories
      ? filteredDefaultCategories.filter(
          categoryId =>
            !usersCategories[categoryId] ||
            usersCategories[categoryId].isEnabled
        )
      : [];
  };

  const calcNumberOfSelctedCategories = () => {
    if (!usersCategories) {
      return filteredDefaultCategories ? filteredDefaultCategories.length : 0;
    }
    return (filteredDefaultCategories
      ? filteredDefaultCategories.filter(
          categoryId =>
            !usersCategories[categoryId] ||
            usersCategories[categoryId].isEnabled
        )
      : []
    ).length;
  };

  const swapJourney = async journeyId => {
    setIsSwappingJourney(true);
    setTimeout(() => {
      setIsSwappingJourney(false);
    }, 2000);
    const journeyConfigData = {
      journeyId,
      lagoSettings: {
        isEnabled: lagoEnabledUser,
      },
    };
    await database
      .ref(`lago/educational/tempJourneyPath/${customerInfo.id}`)
      .set(journeyConfigData);

    setJourneyToSwapTo('');
  };

  const onJourneySelected = e => {
    if (percentageDoneJourney) {
      setJourneyToSwapTo(e.target.value);
    } else {
      swapJourney(e.target.value);
    }
  };

  const renderCategoriesSelect = () => {
    if (
      calcNumberOfSelctedCategories() ===
      (filteredDefaultCategories ? filteredDefaultCategories.length : [])
    ) {
      return 'All categories enabled';
    }
    if (calcNumberOfSelctedCategories() === 1) {
      return '1 category enabled';
    }
    return `${calcNumberOfSelctedCategories()} categories enabled`;
  };

  const renderAnswer = htmlString => {
    const tagIndex = htmlString.indexOf('@');
    if (tagIndex !== -1) {
      const tagIdIndex = htmlString.indexOf('tagId=');
      const tagId = htmlString.substring(tagIdIndex + 6, tagIdIndex + 26);
      const endTagIndex = htmlString.indexOf('<', tagIndex);
      const stringToReplace = htmlString.substring(tagIndex, endTagIndex);
      let stringToPut = '';
      if (
        userData &&
        userData.journeyConfig &&
        userData.journeyConfig.tags &&
        userData.journeyConfig.tags[tagId]
      ) {
        for (
          let i = 0;
          i < userData.journeyConfig.tags[tagId].lastAnswers.length;
          i += 1
        ) {
          if (i < userData.journeyConfig.tags[tagId].lastAnswers.length - 1) {
            stringToPut =
              // eslint-disable-next-line prefer-template
              stringToPut +
              userData.journeyConfig.tags[tagId].lastAnswers[i] +
              ', ';
          } else {
            stringToPut =
              // eslint-disable-next-line prefer-template
              stringToPut +
              userData.journeyConfig.tags[tagId].lastAnswers[i] +
              '.';
          }
        }
        return htmlString.replace(stringToReplace, stringToPut);
      }
      return (
        tagsData &&
        tagsData[tagId] &&
        tagsData[tagId].placeholder &&
        htmlString.replace(stringToReplace, tagsData[tagId].placeholder.en)
      );
    }
    return htmlString;
  };

  const renderGoals = goals => {
    // const sortedGoals = Object.keys(goals).filter(
    //   goalId => goals[goalId].isUnlocked
    // );

    const sortedGoals = Object.keys(goals)
      .sort((a, b) => goals[b].timestamp - goals[a].timestamp)
      .filter(goalId => goals[goalId].isEnabled);
    return sortedGoals.map(goalId => {
      return (
        <ExpansionPanel
          key={goalId}
          classes={{
            rounded: styles.expansion_panel_rounded,
            disabled: styles.expansion_panel_disabled,
          }}
          disabled={!goals[goalId].isUnlocked}
        >
          <ExpansionPanelSummary
            expandIcon={goals[goalId].isUnlocked ? <ExpandMoreIcon /> : null}
            classes={{
              root: styles.expansion_panel_root,
              expanded: styles.expansion_panel_expanded,
              content: styles.expansion_panel_summary_content,
              disabled: styles.expansion_panel_summary_disabled,
            }}
          >
            <img
              src={
                goalsData[goalId].img ? goalsData[goalId].img : behavoirTracking
              }
              className={styles.panel_img_container}
            />
            <div className={styles.item_summary_container}>
              <div className={styles.item_summary_title}>
                Goal: {goalsData[goalId].title.en}
              </div>
              <div>
                {goalsData[goalId].labels.en.length}{' '}
                {goalsData[goalId].labels.en.length === 1 ? 'item' : 'items'}
              </div>
              {// eslint-disable-next-line no-nested-ternary
              goals[goalId].isUnlocked ? (
                remindersData &&
                goalsData &&
                goalsData[goalId] &&
                goalsData[goalId].reminderId &&
                remindersData[goalsData[goalId].reminderId].isEnabled &&
                userData &&
                userData.journeyConfig &&
                userData.journeyConfig.reminders &&
                userData.journeyConfig.reminders[
                  goalsData[goalId].reminderId
                ] &&
                userData.journeyConfig.reminders[goalsData[goalId].reminderId]
                  .remindedAt !== "Don't remind me" ? (
                  <Tooltip
                    title={
                      <span
                        style={{ fontSize: '2em' }}
                      >{`Will be reminded every day at ${
                        userData.journeyConfig.reminders[
                          goalsData[goalId].reminderId
                        ].remindedAt
                        // eslint-disable-next-line react/jsx-closing-tag-location
                      }`}</span>
                    }
                    arrow
                  >
                    <AlarmIcon style={{ fill: '#93ebe1' }} />
                  </Tooltip>
                ) : (
                  <AlarmOffIcon style={{ fill: '#dcdcdb' }} />
                )
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    left: -5,
                  }}
                >
                  <LockIcon style={{ fill: '#dcdcdb' }} />
                  Client has to finish more minutes of learning to unlock this
                  goal
                </div>
              )}
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <div className={styles.goals_details_container}>
              {Object.keys(goalsData[goalId].labels.en).map(i => {
                return (
                  <div
                    className={styles.goals_item}
                    key={goalsData[goalId].labels.en[i].subTitle}
                  >
                    • {goalsData[goalId].labels.en[i].subTitle}:
                    <div
                      className={styles.goals_item_answer}
                      dangerouslySetInnerHTML={{
                        __html: renderAnswer(
                          goalsData[goalId].labels.en[i].answer
                        ),
                      }}
                    />
                  </div>
                );
              })}
              <div className={styles.goals_instructions_container}>
                <div className={styles.goals_instructions_title}>
                  Instructions:
                </div>
                <div>{goalsData[goalId].instructions.en}</div>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  const calcStatus = moduleId => {
    if (
      userData &&
      userData.journeyConfig &&
      userData.journeyConfig.modules &&
      userData.journeyConfig.modules[moduleId] &&
      userData.journeyConfig.modules[moduleId].percentageDone
    ) {
      if (userData.journeyConfig.modules[moduleId].percentageDone === 0) {
        return 'Pending';
      }
      if (userData.journeyConfig.modules[moduleId].percentageDone === 100) {
        return 'Completed';
      }
      return 'In progress';
    }
    return 'Pending';
  };

  const calcCurseStatus = minicourseId => {
    if (
      userData &&
      userData.journeyConfig &&
      userData.journeyConfig.minicourses &&
      userData.journeyConfig.minicourses[minicourseId]
    ) {
      if (userData.journeyConfig.minicourses[minicourseId].endTimestamp) {
        return `Finished on ${parseDate(
          userData.journeyConfig.minicourses[minicourseId].endTimestamp,
          false
        )}`;
      }
      if (userData.journeyConfig.minicourses[minicourseId].startTimestamp) {
        return `In progress - started ${parseDate(
          userData.journeyConfig.minicourses[minicourseId].startTimestamp,
          false
        )}`;
      }
      return 'Pending';
    }
    return '';
  };

  const renderModules = minicourseId => {
    let sortedModules = [];
    if (
      minicoursesData &&
      minicoursesData[minicourseId] &&
      minicoursesData[minicourseId].modules
    ) {
      sortedModules = Object.keys(minicoursesData[minicourseId].modules);
      sortedModules.sort(
        (a, b) =>
          minicoursesData[minicourseId].modules[a] -
          minicoursesData[minicourseId].modules[b]
      );
    }
    return sortedModules.map(moduleId => {
      return (
        <div key={moduleId} className={styles.module_item_container}>
          <div>{modulesData[moduleId].title.en}</div>
          <div style={{ width: '100px' }}>{calcStatus(moduleId)}</div>
        </div>
      );
    });
  };

  const renderMiniCourses = () => {
    const courseClicked = id => {
      lagoDashboardContentLogger.log('journey_course_clicked', {
        patientId: customerInfo.id,
        courseId: id,
        course_name:
          minicoursesData &&
          minicoursesData[id] &&
          minicoursesData[id].title.en,
        course_progress: userData.journeyConfig.minicourses[id].percentageDone,
      });
    };
    const sortedMiniCourses = Object.keys(
      userData.journeyConfig.minicourses
    ).sort((a, b) => {
      return (
        userData.journeyConfig.minicourses[a].index -
        userData.journeyConfig.minicourses[b].index
      );
    });
    return sortedMiniCourses.map(minicourseId => {
      return (
        <ExpansionPanel
          key={minicourseId}
          classes={{
            rounded: styles.expansion_panel_rounded,
          }}
          onChange={() => courseClicked(minicourseId)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              root: styles.expansion_panel_root,
              expanded: styles.expansion_panel_expanded,
              content: styles.expansion_panel_summary_content,
            }}
          >
            <div className={styles.journey_summary_container}>
              <div className={styles.journey_summary_img_title}>
                <img
                  src={
                    minicoursesData &&
                    minicoursesData[minicourseId] &&
                    minicoursesData[minicourseId].img
                      ? minicoursesData[minicourseId].img
                      : behavoirTracking
                  }
                  className={styles.panel_img_container_smaller}
                />
                <div className={styles.journey_item_summary_container}>
                  <div className={styles.item_summary_title}>
                    {minicoursesData &&
                      minicoursesData[minicourseId] &&
                      minicoursesData[minicourseId].title.en}
                  </div>
                  <div>{calcCurseStatus(minicourseId)}</div>
                </div>
              </div>
              <div className={styles.journey_progresss_container}>
                <LinearProgress
                  variant="determinate"
                  value={
                    userData.journeyConfig.minicourses[minicourseId]
                      .percentageDone
                  }
                  style={{ width: '200px', marginTop: '15px' }}
                />
                <div style={{ marginTop: '8px', marginLeft: '10px' }}>
                  {
                    userData.journeyConfig.minicourses[minicourseId]
                      .percentageDone
                  }
                  %
                </div>
              </div>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <div className={styles.module_items}>
              <div className={styles.module_item_container}>
                <div style={{ fontWeight: 'bold' }}>Module</div>
                <div style={{ fontWeight: 'bold', width: '100px' }}>Status</div>
              </div>
              {renderModules(minicourseId)}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  let isLagoEnabledClinic = false;
  if (lagoEnabledClinic || lagoEnabledClinic === null) {
    isLagoEnabledClinic = true;
  }
  let isLagoEnabledUser = false;
  if (lagoEnabledUser || lagoEnabledUser === null) {
    isLagoEnabledUser = true;
  }
  const isLagoEnabled = isLagoEnabledUser && isLagoEnabledClinic;

  const calcStatusString = () => {
    if (isLagoEnabled) {
      return 'ON';
    }
    return 'OFF';
  };

  const calcStatusColor = () => {
    if (isLagoEnabled) {
      return '#52a09f';
    }
    return '#fb3732';
  };

  const calcCategoriesStatus = () => {
    return resourceEnabled === null || !!resourceEnabled;
  };

  const expanansionClicked = (expanded, name) => {
    lagoDashboardContentLogger.log('section_click', {
      patientId: customerInfo.id,
      section_name: name,
      is_expanded: expanded,
    });
  };

  if (
    resourceEnabled !== initialValue &&
    lagoEnabledUser !== initialValue &&
    defaultCategories !== initialValue &&
    defaultJourneys !== initialValue
  ) {
    const journeysToDisplay = defaultJourneys
      ? Object.keys(defaultJourneys)
          .filter(journeyId => !defaultJourneys[journeyId].hide)
          .map(journeyId => {
            return {
              value: journeyId,
              text:
                defaultJourneys[journeyId].title &&
                defaultJourneys[journeyId].title.en,
              groupId: defaultJourneys[journeyId].groupId,
              version: defaultJourneys[journeyId].version,
            };
          })
      : [];

    let journeysThatUserDidOrDoing = archivedJourneys
      ? Object.keys(archivedJourneys)
      : [];
    if (
      userData &&
      userData.journeyConfig &&
      userData.journeyConfig.journeyId
    ) {
      journeysThatUserDidOrDoing.push(userData.journeyConfig.journeyId);
    }

    journeysThatUserDidOrDoing = journeysThatUserDidOrDoing.filter(
      journeyId => defaultJourneys && !defaultJourneys[journeyId].hide
    );

    journeysThatUserDidOrDoing.forEach(journeyId => {
      journeysToDisplay.forEach(journeyObject => {
        if (journeyId === journeyObject.value) {
          // eslint-disable-next-line no-param-reassign
          journeyObject.version = Infinity;
        }
      });
    });

    const groupIdsMap = {};
    journeysToDisplay.forEach(journeyObject => {
      if (journeyObject.groupId) {
        if (!groupIdsMap[journeyObject.groupId]) {
          groupIdsMap[journeyObject.groupId] = [
            { value: journeyObject.value, version: journeyObject.version },
          ];
        } else {
          groupIdsMap[journeyObject.groupId].push({
            value: journeyObject.value,
            version: journeyObject.version,
          });
        }
      }
    });

    const journeysWithGroupIdToDisplay = Object.keys(groupIdsMap).map(
      groupId => {
        let latestVersion = 0;
        let journeyIdToReturn = '';
        groupIdsMap[groupId].forEach(versionObject => {
          if (versionObject.version > latestVersion) {
            journeyIdToReturn = versionObject.value;
            latestVersion = versionObject.version;
          }
        });
        return journeyIdToReturn;
      }
    );

    const filteredJourneysToDisplay = journeysToDisplay.filter(
      journeyObject =>
        !journeyObject.groupId ||
        journeysWithGroupIdToDisplay.find(
          journeyId => journeyId === journeyObject.value
        )
    );

    const swappingJourney =
      (userData && userData.swappingJourney) || isSwappingJourney;

    return (
      <div>
        {/* <Paper className={styles.paper}> */}

        <ExpansionPanel
          classes={{
            rounded: styles.expansion_panel_rounded,
          }}
          // onChange={(e, expanded) =>
          //   expanansionClicked(expanded, 'exercise_resources')
          // }
          expanded
          style={{ cursor: 'auto' }}
        >
          <ExpansionPanelSummary
            // expandIcon={<ExpandMoreIcon />}
            classes={{
              root: styles.expansion_panel_root_always_expanded,
              expanded: styles.expansion_panel_summary_expanded,
              // content: styles.expansion_panel_summary_content,
            }}
          >
            <div style={{ marginTop: 20 }}>
              <div className={styles.goals_title}>
                EXERCISES & RESOURCES:
                <span
                  style={
                    calcCategoriesStatus()
                      ? { color: '#52a09f', marginLeft: 5 }
                      : { color: '#fb3732', marginLeft: 5 }
                  }
                >
                  {calcCategoriesStatus() ? 'ON' : 'OFF'}
                </span>
              </div>
              <div className={styles.goals_description}>
                {calcCategoriesStatus()
                  ? 'This client has access to exercises & resources in-app.'
                  : 'This client can’t access exercises & resources in-app.'}
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <div className={styles.goals_title}>Categories</div>
                <div className={styles.goals_description}>
                  Improve your client’s experience by letting them access mental
                  wellness exercises and resources that fall under these
                  categories. Disabling any of the categories will hide the
                  related resources and exercises from your client:
                </div>
              </div>
              <div>
                <CheckboxesSelect
                  allOptions={
                    filteredDefaultCategories
                      ? filteredDefaultCategories.map(categoryId => {
                          return {
                            value: categoryId,
                            label:
                              defaultCategories[categoryId].name &&
                              defaultCategories[categoryId].name.en,
                          };
                        })
                      : []
                  }
                  onChange={onCategorySelected}
                  selectedOptions={calcSelectedCategories()}
                  inputType="outlinedInputs"
                  displayEmpty
                  renderValue={renderCategoriesSelect}
                  disabled={!calcCategoriesStatus()}
                />
                <span style={{ position: 'relative', top: 8 }}>
                  <Switch
                    checked={resourceEnabled === null || !!resourceEnabled}
                    onChange={() => {
                      if (resourceEnabled === null) {
                        setResourceEnabled(false);
                      } else {
                        setResourceEnabled(!resourceEnabled);
                      }
                      lagoDashboardContentLogger.log(
                        'exercises_and_resources_disable_toggle-clicked',
                        {
                          patientId: customerInfo.id,
                          isEnabled: !resourceEnabled,
                          journey_progress: percentageDoneJourney,
                        }
                      );
                    }}
                    color="primary"
                  />
                </span>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* </Paper> */}
        {defaultJourneys ? (
          <ExpansionPanel
            classes={{
              rounded: styles.expansion_panel_rounded,
            }}
            onChange={(e, expanded) => expanansionClicked(expanded, 'journeys')}
            expanded
          >
            <ExpansionPanelSummary
              // expandIcon={<ExpandMoreIcon />}
              classes={{
                root: styles.expansion_panel_root_always_expanded,
                expanded: styles.expansion_panel_summary_expanded,
                // content: styles.expansion_panel_summary_content,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 20,
                }}
              >
                <div className={styles.goals_title}>
                  DIGITAL JOURNEY:
                  <span
                    style={{ color: calcStatusColor() }}
                    className={styles.toggle_lago_enable_operator}
                  >
                    {calcStatusString()}
                  </span>
                </div>
                <div className={styles.goals_description}>
                  {calcStatusString() === 'ON'
                    ? `This client has access to the ${defaultJourneys &&
                        defaultJourneys[currentJourneyId] &&
                        defaultJourneys[currentJourneyId].title &&
                        defaultJourneys[currentJourneyId].title.en} journey.`
                    : 'The client doesn’t have access to a digital journey.'}
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: 20 }}>
                      <TooltipWrapper
                        disabled={isAppCompatible}
                        title={
                          <span style={{ fontSize: 12, lineHeight: '2em' }}>
                            To change this user's digital journey, they will
                            need to update their Myndlift app to v2.5.7 or later
                            on iOS, which is currently not available on Android.
                          </span>
                        }
                      >
                        <Select
                          onChange={onJourneySelected}
                          value={currentJourneyId}
                          style={{ fontSize: 13 }}
                          disabled={
                            lagoEnabledUser === false ||
                            swappingJourney ||
                            !isAppCompatible
                          }
                          renderValue={val =>
                            (defaultJourneys &&
                              defaultJourneys[val] &&
                              defaultJourneys[val].title.en) ||
                            'Select a digital journey'
                          }
                          displayEmpty
                        >
                          {filteredJourneysToDisplay.map(journey => {
                            return (
                              <MenuItem
                                value={journey.value}
                                style={{ fontSize: 13 }}
                              >
                                {journey.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TooltipWrapper>
                      <Switch
                        checked={
                          lagoEnabledUser === null ? true : lagoEnabledUser
                        }
                        onChange={() => {
                          if (lagoEnabledUser === null) {
                            setLagoEnabledUser(false);
                          } else {
                            setLagoEnabledUser(!lagoEnabledUser);
                          }
                          lagoDashboardContentLogger.log(
                            'journey_disable_toggle-clicked',
                            {
                              patientId: customerInfo.id,
                              isEnabled: !lagoEnabledUser,
                              journey_progress: percentageDoneJourney,
                            }
                          );
                        }}
                        color="primary"
                        disabled={swappingJourney}
                      />
                      {swappingJourney ? <CircularProgress size={20} /> : null}
                    </div>
                    {/* <div className={styles.goals_description}>
                      {lagoEnabledUser
                        ? `The client has access to the ${defaultJourneys &&
                            defaultJourneys[currentJourneyId] &&
                            defaultJourneys[currentJourneyId].title &&
                            defaultJourneys[currentJourneyId].title.en}.`
                        : 'The client doesn’t have access to a digital journey.'}
                    </div> */}
                  </div>
                </div>
                {!isLagoEnabled || swappingJourney ? null : (
                  <div>
                    {userData &&
                      userData.journeyConfig &&
                      userData.journeyConfig.minicourses && (
                        <div>
                          <div className={styles.goals_title}>
                            Client’s Progress
                          </div>
                          <div className={styles.goals_description}>
                            Note: The client is able to consume a limited amount
                            of lessons per day.
                          </div>
                        </div>
                      )}
                    {modulesData &&
                    minicoursesData &&
                    userData &&
                    userData.journeyConfig &&
                    userData.journeyConfig.minicourses
                      ? renderMiniCourses()
                      : null}
                    {userData &&
                      userData.journeyConfig &&
                      userData.journeyConfig.goals && (
                        <div>
                          <div
                            className={styles.goals_title}
                            style={{ marginTop: 40 }}
                          >
                            Journey Goals
                          </div>
                          <div className={styles.goals_description}>
                            Clients unlock goals as they progress in their
                            journey.
                          </div>
                        </div>
                      )}
                    {goalsData &&
                    userData &&
                    userData.journeyConfig &&
                    userData.journeyConfig.goals
                      ? renderGoals(userData.journeyConfig.goals)
                      : null}
                  </div>
                )}
                <div className={styles.alert_container}>
                  <div className={styles.alert_size}>
                    <CustomAlert severity="warning">
                      Available on iOS devices only (2.3.9 and later). Will be
                      available on Android soon.
                    </CustomAlert>
                  </div>
                </div>
              </div>
              <CustomDialog
                isOpen={journeyToSwapTo}
                onPrimaryBtnClick={() => {
                  swapJourney(journeyToSwapTo);
                }}
                onSecondaryBtnClick={() => {
                  setJourneyToSwapTo('');
                }}
                onClose={() => {
                  setJourneyToSwapTo('');
                }}
                primaryBtnContent="YES, CHANGE NOW"
                secondaryBtnContent="CANCEL"
                shouldShowCloseIcon={false}
              >
                <h4>
                  Are you sure you’d like to change the client’s Digital
                  Journey?
                </h4>
                <p>
                  {`
                  Clients can only access one journey in-app. If you choose to change the journey now, this client will lose access to the 
                  ${defaultJourneys &&
                    defaultJourneys[currentJourneyId] &&
                    defaultJourneys[currentJourneyId].title &&
                    defaultJourneys[currentJourneyId].title.en} 
                  and will get notified about the new 
                  ${defaultJourneys &&
                    defaultJourneys[journeyToSwapTo] &&
                    defaultJourneys[journeyToSwapTo].title &&
                    defaultJourneys[journeyToSwapTo].title.en}.`}
                </p>
              </CustomDialog>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : null}
      </div>
    );
  }
  return null;
};

BehaviouralProgressTabBase.propTypes = exact({});

export const BehaviouralProgressTab = React.memo(BehaviouralProgressTabBase);
BehaviouralProgressTab.displayName = 'BehaviouralProgressTab';

import React, { useEffect, useState } from 'react';
import exact from 'prop-types-exact';
// import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import Logo from '../../../../img/modern_logo.png';
import styles from './EmailVerifcationPage.scss';

const EmailVerifcationPageBase = () => {
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const actionCode = queryParams.get('oobCode');

    if (actionCode) {
      // eslint-disable-next-line no-undef
      firebase
        .auth()
        .applyActionCode(actionCode)
        .then(() => {
          setMessage('Your email has been successfully verified!');
          setIsError(false);
          setIsLoading(false);
        })
        .catch(() => {
          setMessage('Invalid or expired verification link.');
          setIsLoading(false);
        });
    } else {
      setMessage('No verification code found.');
      setIsLoading(false);
    }
  }, []);

  return (
    <div className={styles.page_container}>
      {isLoading ? (
        <div className={styles.page_container}>
          <CircularProgress style={{ fontSize: 14, marginTop: 30 }} />
        </div>
      ) : (
        <div className={styles.content_container}>
          <img src={Logo} className={styles.logo_image} />
          <div style={{ marginBottom: 20 }}>{message}</div>
          {isError ? null : (
            <Button
              onClick={() => {
                window.open('settings.html?authFlow=true', '_self');
              }}
              color="primary"
              variant="contained"
            >
              Continue
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

EmailVerifcationPageBase.propTypes = exact({});

export const EmailVerifcationPage = React.memo(EmailVerifcationPageBase);
EmailVerifcationPage.displayName = 'EmailVerifcationPage';

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { convertObjectToArrWithIds } from '../../utils/utils';
import { CreateProgramPopUp } from '../CreateProgramPopUp/CreateProgramPopUp';
import { DeployedPrograms } from '../DeployedPrograms/DeployedPrograms';

// import { useTranslation } from '../../Core/hooks/useTranslation';

import styles from './SettingsSection.scss';

const ProgramsSettingsBase = () => {
  // const t = useTranslation();

  const [createProgramPopUpOpener, setCreateProgramPopUpOpener] = useState(
    null
  );

  const [
    isCreateProgramPopUpInAdvancedMode,
    setIsCreateProgramPopUpInAdvancedMode,
  ] = useState(sessionStorage.isSimpleDashboard !== 'true');

  const [autoDeployableConfigs, setAutoDeployableConfigs] = useFirebaseDB({
    path: `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/programs`,
  });

  const [protocolsConfig] = useFirebaseDB({
    path: `clinics/${sessionStorage.userId}/configurations/protocols`,
  });

  const closeCreateProgramPopUpModal = () => {
    if (
      !autoDeployableConfigs ||
      !autoDeployableConfigs.deployables ||
      !autoDeployableConfigs.deployables.deployedPrograms ||
      !autoDeployableConfigs.deployables.deployedPrograms.content ||
      !Object.keys(autoDeployableConfigs.deployables.deployedPrograms.content)
        .length
    ) {
      database
        .ref(
          `clinicConfig/autoDeployableConfigs/${sessionStorage.userId}/programs`
        )
        .child('enabled')
        .set(false);
    }
    setCreateProgramPopUpOpener(false);
  };

  const onEditPrograms = () => {
    setCreateProgramPopUpOpener({ isEditingMode: false });
  };

  const onProgramSettingsAdvancedModeChange = event => {
    const mode = event.target.checked;

    setIsCreateProgramPopUpInAdvancedMode(mode);
    sessionStorage.isSimpleDashboard = !mode;
    database
      .ref('clinics')
      .child(sessionStorage.userId)
      .child('dashboardType')
      .set(mode ? 'advanced' : 'basic');

    const eventName = mode
      ? 'advanced_mode_on_clicked'
      : 'basic_mode_on_clicked';
    new MyEvent(eventName).log();
  };

  const programsArr =
    protocolsConfig &&
    autoDeployableConfigs &&
    autoDeployableConfigs.deployables &&
    autoDeployableConfigs.deployables.deployedPrograms &&
    autoDeployableConfigs.deployables.deployedPrograms.content
      ? convertObjectToArrWithIds(
          autoDeployableConfigs.deployables.deployedPrograms.content
        )
          .map(program => ({
            // eslint-disable-next-line no-undef
            protocolName: extractProtocolName(
              program.protocolDetails,
              protocolsConfig
            ),
            ...program,
          }))
          .sort((p1, p2) => p1.programIndex - p2.programIndex)
      : [];

  return (
    <div>
      <div className={styles.switch_setting_unit}>
        <div>
          <p>
            Deploy Training Program by Default
            {/* <span className={styles.card_new_label}>NEW</span> */}
          </p>

          <div className="toggle-switch" data-ts-color="blue">
            <input
              id="program_switch"
              type="checkbox"
              hidden="hidden"
              onChange={() => {
                if (autoDeployableConfigs) {
                  const copy = JSON.parse(
                    JSON.stringify(autoDeployableConfigs)
                  );
                  const isChecked = !copy.enabled;
                  copy.enabled = isChecked;
                  setAutoDeployableConfigs(copy);
                  if (
                    isChecked &&
                    (!autoDeployableConfigs ||
                      !autoDeployableConfigs.deployables ||
                      !autoDeployableConfigs.deployables.deployedPrograms ||
                      !autoDeployableConfigs.deployables.deployedPrograms
                        .content ||
                      !Object.keys(
                        autoDeployableConfigs.deployables.deployedPrograms
                          .content
                      ).length)
                  ) {
                    onEditPrograms();
                  }
                } else {
                  setAutoDeployableConfigs({
                    selfRegister: true,
                    manualRegister: true,
                    enabled: true,
                    deployables: {
                      deployedPrograms: {
                        path:
                          // eslint-disable-next-line no-template-curly-in-string
                          'deployedSession/${clinicId}/${patientId}/programs',
                        content: {},
                      },
                      programSwitchType: {
                        path:
                          // eslint-disable-next-line no-template-curly-in-string
                          'deployedSession/${clinicId}/${patientId}/programSwitchType',
                        content: 'USER_CHOICE',
                      },
                      sessionStatus: {
                        path:
                          // eslint-disable-next-line no-template-curly-in-string
                          'clinics/${clinicId}/currentSessions/${patientId}/status',
                        content: {
                          fromClient: false,
                          text: 'WAITING_FOR_USER',
                        },
                      },
                      notifyByEmail: {
                        path:
                          // eslint-disable-next-line no-template-curly-in-string
                          'deployedSession/${clinicId}/${patientId}/notifyByEmail',
                        content: false,
                      },
                    },
                  });
                  onEditPrograms();
                }
              }}
              checked={autoDeployableConfigs && autoDeployableConfigs.enabled}
              // disabled={disabled}
            />

            {/* eslint-disable */}
            <label
              id="program_switch"
              htmlFor="program_switch"
              className="ts-helper"
              style={{ marginBottom: 0 }}
            />
            {/* eslint-enable */}
          </div>
        </div>
        <p style={{ whiteSpace: 'pre-wrap' }}>
          When enabled, newly added and self-registered clients will have access
          to training programs of your choice by default.
        </p>
        {autoDeployableConfigs &&
        autoDeployableConfigs.enabled &&
        programsArr.length ? (
          <div>
            <DeployedPrograms
              programs={programsArr}
              customerId={null}
              autoDeployableConfigs={autoDeployableConfigs}
              setAutoDeployableConfigs={setAutoDeployableConfigs}
            />
          </div>
        ) : null}
      </div>
      <CreateProgramPopUp
        // eslint-disable-next-line no-undef
        protocols={protocolsObjectToArrWithNamesInjected(protocolsConfig)}
        database={database}
        // eslint-disable-next-line no-undef
        modifyProtocol={modifyProtocol}
        // eslint-disable-next-line no-undef
        getOverAllSuccessRate={getOverAllSuccessRate}
        // eslint-disable-next-line no-undef
        searchProtocol={searchProtocol}
        // eslint-disable-next-line no-undef
        PatientProtocolDetails={ProtocolDetails}
        getRecommendedPlacementNameBasedOnPlacement={
          // eslint-disable-next-line no-undef
          getRecommendedPlacementNameBasedOnPlacement
        }
        // eslint-disable-next-line no-undef
        getChosenPlacement={getChosenPlacement}
        // eslint-disable-next-line no-undef
        getChoosenPlacementDescriptionHTML={
          // eslint-disable-next-line no-undef
          getChoosenPlacementDescriptionHTML
        }
        // eslint-disable-next-line no-undef
        placements={placements}
        extractFrequencyName={(min, max) =>
          extractFrequency(min, max, frequenciesConfig)
        }
        clinicId={sessionStorage.userId}
        patientId=""
        isModalOpen={!!createProgramPopUpOpener}
        closeModal={closeCreateProgramPopUpModal}
        isEditingMode={
          createProgramPopUpOpener && createProgramPopUpOpener.isEditingMode
        }
        isProgramSettingsAdvancedMode={isCreateProgramPopUpInAdvancedMode}
        onProgramSettingsAdvancedModeChange={
          onProgramSettingsAdvancedModeChange
        }
        autoDeployableConfigs={autoDeployableConfigs}
        setAutoDeployableConfigs={setAutoDeployableConfigs}
      />
    </div>
  );
};

ProgramsSettingsBase.propTypes = exact({});

export const ProgramsSettings = React.memo(ProgramsSettingsBase);
ProgramsSettings.displayName = 'ProgramsSettings';
